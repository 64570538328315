import React from 'react';
import './CardSize.scss';
import { useI18n } from '@shopify/react-i18n';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import { CardSizeComponentDto } from 'core/api/adminWidgets/adminWidgetsApi';
import {
  PaddingsDtoEnum,
  SizeTypeDtoEnum,
  SpacingTypeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';

type CardSizeProps = {
  size: CardSizeComponentDto;
  setSize: (value: CardSizeComponentDto) => void;
  onSystemReset?: () => void;
};

export const CardSize: React.FC<CardSizeProps> = ({
  size,
  setSize,
  onSystemReset,
}) => {
  const [i18n] = useI18n();

  const spacingOptions = Object.values(SpacingTypeDtoEnum)
    .filter((value) => value !== SpacingTypeDtoEnum.CUSTOM)
    .map((value) => {
      return {
        value: value,
        label: i18n.translate(`${value}`),
      };
    });
  const sizeOptions = Object.values(SizeTypeDtoEnum).map((value) => {
    return {
      value: value,
      label: i18n.translate(`${value}`),
    };
  });

  const paddingOptions = Object.values(PaddingsDtoEnum).map((value) => {
    return {
      value: value,
      label: i18n.translate(`${value}`),
    };
  });

  return (
    <div className='CardSize'>
      <StyleComponentHeader
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            onAction: () => onSystemReset?.(),
          },
        ]}
        label={i18n.translate(`Size`)}
      >
        <div className='CardSizeRow'>
          {i18n.translate(`Width`)}
          <div className='RightContent'>
            <SelectOptions
              options={sizeOptions}
              onOptionSelect={(value) =>
                setSize({
                  ...size,
                  width: value as SizeTypeDtoEnum,
                })
              }
              selectedOption={size.width as SizeTypeDtoEnum}
              label=''
            />
          </div>
        </div>
        <div className='CardSizeRow'>
          {i18n.translate(`Spacing`)}
          <div className='RightContent'>
            <SelectOptions
              options={spacingOptions}
              onOptionSelect={(value) =>
                setSize({
                  ...size,
                  spacing: value as SpacingTypeDtoEnum,
                })
              }
              selectedOption={size.spacing as SpacingTypeDtoEnum}
              label=''
            />
          </div>
        </div>
        <div className='CardSizeRow'>
          {i18n.translate(`Paddings`)}
          <div className='RightContent'>
            <SelectOptions
              options={paddingOptions}
              onOptionSelect={(value) =>
                setSize({
                  ...size,
                  paddings: value as PaddingsDtoEnum,
                })
              }
              selectedOption={size.paddings as PaddingsDtoEnum}
              label=''
            />
          </div>
        </div>
      </StyleComponentHeader>
    </div>
  );
};
