import React from 'react';
import {
  Card,
  BlockStack,
  Text,
  Checkbox,
  Divider,
  ChoiceList,
  Banner,
  Link,
} from '@shopify/polaris';
import CodeMirror from '@uiw/react-codemirror';
import { javascript as JS } from '@codemirror/lang-javascript';
import { useI18n } from '@shopify/react-i18n';
import { RefreshBehaviorCartBehaviorSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import './RefreshBehavior.scss';
import { useFormatCode } from 'core/hooks';
type RefreshBehavior = {
  data: RefreshBehaviorCartBehaviorSettingsDto | undefined;
  section: string;
  handleCartBehaviorLocaleState: (
    section: string,
    value: string | boolean,
    field?: string,
    subField?: string
  ) => void;
};

export const RefreshBehavior: React.FC<RefreshBehavior> = ({
  data,
  section,
  handleCartBehaviorLocaleState,
}) => {
  const [i18n] = useI18n();

  // Andriy fix or get rid of this
  const { handleFormatCode } = useFormatCode();

  const options = [
    {
      label: i18n.translate('DrawerCart'),
      value: 'DRAWER_CART',
    },
    { label: i18n.translate('CartPage'), value: 'CART_PAGE' },
    { label: i18n.translate('Both'), value: 'DRAWER_CART_AND_CART_PAGE' },
  ];

  return (
    <div className='RefreshBehavior'>
      <Card roundedAbove='sm' padding='400'>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='h2' variant='headingSm'>
              {i18n.translate('RefreshBehaviorTitle')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('RefreshBehaviorDescription')}
            </Text>
          </BlockStack>
          <Banner tone='info'>
            {i18n.translate('Tip')}{' '}
            <Link url='http://www.google.com' target='_blank'>
              {i18n.translate('TipLink')}
            </Link>
          </Banner>
          <Checkbox
            label={i18n.translate('Replace')}
            checked={data?.replaceStandardBehavior}
            onChange={(value) => {
              handleCartBehaviorLocaleState(
                section,
                value,
                'replaceStandardBehavior'
              );
            }}
          />
          {data?.replaceStandardBehavior && (
            <>
              <Divider />
              <ChoiceList
                title={i18n.translate('OptionTitle')}
                choices={options}
                selected={[`${data?.javascriptMethod?.mode || 'DRAWER_CART'}`]}
                onChange={(value) =>
                  handleCartBehaviorLocaleState(
                    section,
                    value[0],
                    'javascriptMethod',
                    'mode'
                  )
                }
              />
              <BlockStack>
                <Text as='p' variant='bodyMd'>
                  {i18n.translate('JSMethod')}
                </Text>
                <div className='CSSEditor'>
                  <CodeMirror
                    value={data?.javascriptMethod?.code || ''}
                    height='120px'
                    extensions={[JS()]}
                    onChange={(value) =>
                      handleCartBehaviorLocaleState(
                        section,
                        value,
                        'javascriptMethod',
                        'code'
                      )
                    }
                    editable={true}
                  />
                </div>
              </BlockStack>
            </>
          )}
        </BlockStack>
      </Card>
    </div>
  );
};
