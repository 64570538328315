import React, { useCallback } from 'react';
import {
  MessageDto,
  NotificationExpandedStateFooterPresetDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { TextEntries, TextStyle } from '../../../../../components';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { useI18n } from '@shopify/react-i18n';
import { useAppDispatch } from 'core/hooks';
import { setNotificationFooterMessages } from 'core/store/widgetsSlice';
import { isEqual } from 'lodash';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';

type NotificationFooterProps = {
  footer: NotificationExpandedStateFooterPresetDto;
  defaultFooter: NotificationExpandedStateFooterPresetDto;
  shopDefaultFooter: NotificationExpandedStateFooterPresetDto;
  defaultLanguage?: string;
  isPresetEditor?: boolean;
  setFooter: (data: NotificationExpandedStateFooterPresetDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
  offerId?: string;
};

const NotificationFooter: React.FC<NotificationFooterProps> = ({
  footer,
  defaultFooter,
  shopDefaultFooter,
  defaultLanguage,
  isPresetEditor,
  setFooter,
  onShowHide,
  handleLeftMenuSelection,
  offerId,
}) => {
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();
  const handleFooterText = useCallback(
    (field: keyof MessageDto, data: MessageDto[keyof MessageDto]) =>
      setFooter({
        ...footer,
        footerText: {
          ...footer.footerText,
          value: {
            ...footer.footerText?.value,
            [field]: data,
          },
        },
      }),
    [footer, setFooter]
  );

  const resetAllToSystemDefault = useCallback(() => {
    const { countdownClock, footerText } = shopDefaultFooter;
    setFooter({
      ...footer,
      footerText,
      countdownClock: {
        text: countdownClock?.text,
        //Ask Piotr why it was deleted (for Andriy)
        // clockStyle: defaultFooter.countdownClock?.clockStyle,
        textStyle: countdownClock?.textStyle,
      },
    });
  }, [shopDefaultFooter, footer, defaultFooter]);

  return (
    <div className='NotificationFooter'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={() => setFooter(defaultFooter)}
        onResetToSystem={resetAllToSystemDefault}
        name={WidgetListOptionsDtoEnum.FOOTER}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <TextEntries
          textMessage={footer.footerText?.value?.message || {}}
          defaultLanguage={defaultLanguage || ''}
          inherited={
            !isPresetEditor
              ? isEqual(footer.footerText, defaultFooter.footerText)
              : undefined
          }
          disableColor={
            (footer.footerText?.value?.style || {}).color !== undefined
          }
          disableGoal
          disableCollapsed
          offerId={offerId}
          enableText={i18n.translate('EnableFooterText')}
          enabled={footer.footerText?.enabled}
          title={i18n.translate('FooterText')}
          setEnabled={(value) =>
            setFooter({
              ...footer,
              footerText: {
                ...footer.footerText,
                enabled: value,
              },
            })
          }
          setTextMessage={(data) => handleFooterText('message', data)}
          onSystemReset={() =>
            handleFooterText(
              'message',
              shopDefaultFooter.footerText?.value?.message
            )
          }
          setPreviewEntry={(value) =>
            dispatch(setNotificationFooterMessages(value))
          }
        />
        <TextStyle
          textStyle={footer.footerText?.value?.style || {}}
          onSystemReset={() =>
            handleFooterText(
              'style',
              shopDefaultFooter.footerText?.value?.style
            )
          }
          hideHeader
          setTextStyle={(data) => handleFooterText('style', data)}
        />
      </div>
    </div>
  );
};

export default NotificationFooter;
