import {
  OfferRulePopupCallToActionButtonOfferDto,
  OfferRulePopupGeneralOfferDto,
  OfferRulePopupGeneralPresetDto,
  OfferRulePopupHeadlineTextOfferDto,
  OfferRulePopupLevelPresetDto,
  OfferRulePopupOfferLevelDto,
  OfferRulePopupRuleTextOfferDto,
  OfferRulePopupSystemDefaultsDto,
  OfferWidgetSetupDtoOfferRulePopupOfferDtoRead,
  PresetsSetupDto,
  PromotionSetupDtoRead,
  PromotionWidgetSetupMultiLevelDtoOfferRulePopupPromotionDtoOfferRulePopupFirstLevelPromotionDtoOfferRulePopupPromotionEntryTypeDto as PromotionWidgetSetupDtoOfferRulePopupPromotionDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import React, { useCallback, useMemo } from 'react';
import { SelectedOptionPath } from '../../../../RightSideBarWrapper';
import { WidgetTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { getLastSelectedOption } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import OfferRulePopupGeneral from '../../../EditPresetRightBar/components/PresetOfferRulePopup/components/OfferRulePopupGeneral/OfferRulePopupGeneral';
import OfferRulePopupOffer from './components/OfferRulePopupOffer/OfferRulePopupOffer';
import OfferRulePopupCTA from '../../../EditPresetRightBar/components/PresetOfferRulePopup/components/OfferRulePopupCTA/OfferRulePopupCTA';
import OfferRulePopupHeadline from './components/OfferRulePopupHeadline/OfferRulePopupHeadline';
import OfferRulePopupText from './components/OfferRulePopupText/OfferRulePopupText';
import {
  WidgetListOptionsDtoEnum,
  WidgetListTypeDtoEnum,
} from 'features/settings/components/EditPresetSkeleton/enums/enums';

type PromotionOfferRulePopupProps = {
  editPromotionState: PromotionSetupDtoRead;
  defaultPromotionData: PromotionSetupDtoRead;
  shopDefaultData: OfferRulePopupSystemDefaultsDto;
  selectedOptionPath: SelectedOptionPath;
  updateEditPromotionState: (
    field: keyof PromotionSetupDtoRead,
    data: PromotionSetupDtoRead[keyof PromotionSetupDtoRead],
    presetSetup?: PresetsSetupDto
  ) => void;
  filterSupportedWidgets: (value: WidgetTypeDtoEnum) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const PromotionOfferRulePopup: React.FC<PromotionOfferRulePopupProps> = ({
  editPromotionState,
  defaultPromotionData,
  shopDefaultData,
  selectedOptionPath,
  updateEditPromotionState,
  filterSupportedWidgets,
  handleLeftMenuSelection,
}) => {
  const { offerRulePopup, presetSetup } = editPromotionState;
  const offersData = editPromotionState.offers?.offerRulePopup;
  const defaultOffersData = defaultPromotionData.offers?.offerRulePopup;
  const defaultOfferRulePopup = defaultPromotionData.offerRulePopup;
  const defaultPresetSetup = defaultPromotionData.presetSetup?.offerRulePopup;
  const widgetPreset = presetSetup?.offerRulePopup;
  const defaultLanguage = editPromotionState.offers?.defaultLanguage || 'en';
  const updateOfferRulePopup = useCallback(
    (
      field: keyof PromotionWidgetSetupDtoOfferRulePopupPromotionDto,
      data: PromotionWidgetSetupDtoOfferRulePopupPromotionDto[keyof PromotionWidgetSetupDtoOfferRulePopupPromotionDto]
    ) =>
      updateEditPromotionState('offerRulePopup', {
        ...offerRulePopup,
        [field]: data,
      }),
    [offerRulePopup, updateEditPromotionState]
  );

  const updateOffer = useCallback(
    (
      data: OfferWidgetSetupDtoOfferRulePopupOfferDtoRead,
      offerId: string,
      preset?: OfferRulePopupGeneralPresetDto
    ) =>
      updateEditPromotionState(
        'offers',
        {
          ...editPromotionState.offers,
          offerRulePopup: editPromotionState.offers?.offerRulePopup?.map(
            (offerData) => {
              if (offerData?.offerId === offerId) {
                return data;
              }
              return offerData;
            }
          ),
        },
        {
          ...presetSetup,
          offerRulePopup: {
            ...presetSetup?.offerRulePopup,
            general: preset,
          },
        }
      ),
    [updateEditPromotionState, editPromotionState, presetSetup]
  );

  const updateOfferComponent = useCallback(
    (
      field: keyof OfferRulePopupOfferLevelDto,
      data: OfferRulePopupOfferLevelDto[keyof OfferRulePopupOfferLevelDto],
      offerId: string,
      preset?: OfferRulePopupLevelPresetDto
    ) =>
      updateEditPromotionState(
        'offers',
        {
          ...editPromotionState.offers,
          offerRulePopup: editPromotionState.offers?.offerRulePopup?.map(
            (offerData) => {
              if (offerData?.offerId === offerId) {
                return {
                  ...offerData,
                  setup: {
                    ...offerData.setup,
                    options: {
                      ...offerData.setup?.options,
                      [field]: data,
                    },
                  },
                };
              }
              return offerData;
            }
          ),
        },
        {
          ...presetSetup,
          offerRulePopup: {
            ...presetSetup?.offerRulePopup,
            options: preset,
          },
        }
      ),
    [updateEditPromotionState, editPromotionState, presetSetup]
  );

  const offerRulePopupOptions = useMemo(() => {
    const offerData = offersData?.find(
      (offer) => offer.offerId === selectedOptionPath?.selectedOption?.offerId
    ) as OfferWidgetSetupDtoOfferRulePopupOfferDtoRead;

    const defaultOfferData = defaultOffersData?.find(
      (offer) => offer.offerId === selectedOptionPath?.selectedOption?.offerId
    ) as OfferWidgetSetupDtoOfferRulePopupOfferDtoRead;

    const offerId = offerData?.offerId || '';

    const selectedOption = getLastSelectedOption(selectedOptionPath);

    const updateOfferGeneral = (data: OfferRulePopupGeneralOfferDto) => {
      const { background, cornerRadius, shadow } = data;
      updateOffer(
        {
          ...offerData,
          setup: {
            ...offerData.setup,
            general: {
              ...offerData.setup?.general,
              size: data.size,
            },
          },
        },
        offerId,
        {
          ...presetSetup?.offerRulePopup?.general,
          background,
          cornerRadius,
          shadow,
          shopSettings: {
            ...presetSetup?.offerRulePopup?.general?.shopSettings,
            closeButton: data.shopSettings?.closeButton,
          },
        }
      );
    };

    const updateCTAComponent = (
      data: OfferRulePopupCallToActionButtonOfferDto
    ) => {
      const { size, label, buttonStyle } = data;
      updateOfferComponent('callToActionButton', { link: data.link }, offerId, {
        ...widgetPreset?.options,
        callToActionButton: {
          ...widgetPreset?.options?.callToActionButton,
          size,
          label,
          buttonStyle,
        },
      });
    };
    const updateHeadlineComponent = (
      data: OfferRulePopupHeadlineTextOfferDto
    ) => {
      updateOfferComponent(
        'headlineText',
        { headlineText: { message: data.headlineText?.message } },
        offerId,
        {
          ...widgetPreset?.options,
          offerRule: {
            ...widgetPreset?.options?.offerRule,
            headlineText: data.headlineText?.style,
          },
        }
      );
    };
    const updateRuleTextComponent = (data: OfferRulePopupRuleTextOfferDto) => {
      updateOfferComponent(
        'ruleText',
        { ruleText: { message: data.ruleText?.message } },
        offerId,
        {
          ...widgetPreset?.options,
          offerRule: {
            ...widgetPreset?.options?.offerRule,
            ruleText: data.ruleText?.style,
          },
        }
      );
    };

    switch (true) {
      case selectedOption?.name === WidgetListTypeDtoEnum.OFFER_RULE_POPUP:
        return (
          <OfferRulePopupGeneral
            general={offerRulePopup?.general || {}}
            defaultGeneral={defaultOfferRulePopup?.general || {}}
            shopDefaultGeneral={shopDefaultData.general || {}}
            setGeneral={(data) => updateOfferRulePopup('general', data)}
            onShowHide={
              offerRulePopup?.canBeHidden
                ? () =>
                    updateOfferRulePopup('isHidden', !offerRulePopup?.isHidden)
                : undefined
            }
            onRemove={() =>
              filterSupportedWidgets(WidgetTypeDtoEnum.OFFER_RULE_POPUP)
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );

      case !!selectedOption?.offerId:
        return (
          <OfferRulePopupOffer
            offer={{
              ...widgetPreset?.general,
              size: offerData.setup?.general?.size,
            }}
            offerName={selectedOption?.name || ''}
            defaultOffer={defaultOfferData.setup?.general || {}}
            defaultPresetSetup={defaultPresetSetup?.general || {}}
            shopDefaultOffer={shopDefaultData.general || {}}
            handleLeftMenuSelection={handleLeftMenuSelection}
            onShowHide={
              offerData.canBeHidden
                ? () =>
                    updateOffer(
                      { ...offerData, isHidden: !offerData?.isHidden },
                      offerId
                    )
                : undefined
            }
            setOffer={updateOfferGeneral}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.CTA_BUTTON:
        return (
          <OfferRulePopupCTA
            ctaButton={{
              ...widgetPreset?.options?.callToActionButton,
              link: offerData.setup?.options?.callToActionButton?.link,
            }}
            defaultCTAButton={
              defaultPresetSetup?.options?.callToActionButton || {}
            }
            defaultLanguage={defaultLanguage}
            shopDefaultCTA={shopDefaultData.callToActionButton || {}}
            setCTAButton={updateCTAComponent}
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.HEADLINE_TEXT:
        return (
          <OfferRulePopupHeadline
            message={offerData.setup?.options?.headlineText || {}}
            defaultMessage={defaultOfferData.setup?.options?.headlineText || {}}
            defaultPresetSetup={
              defaultPresetSetup?.options?.offerRule?.headlineText || {}
            }
            shopDefaultMessage={shopDefaultData.offerRule?.headlineText || {}}
            presetSetup={widgetPreset?.options?.offerRule?.headlineText || {}}
            defaultLanguage={defaultLanguage}
            handleLeftMenuSelection={handleLeftMenuSelection}
            setMessage={updateHeadlineComponent}
          />
        );

      case selectedOption?.name === WidgetListOptionsDtoEnum.RULE_TEXT:
        return (
          <OfferRulePopupText
            message={offerData.setup?.options?.ruleText || {}}
            key={offerId}
            defaultMessage={defaultOfferData.setup?.options?.ruleText || {}}
            defaultPresetSetup={
              defaultPresetSetup?.options?.offerRule?.ruleText || {}
            }
            offerId={offerId}
            shopDefaultMessage={shopDefaultData.offerRule?.ruleText || {}}
            presetSetup={widgetPreset?.options?.offerRule?.ruleText || {}}
            defaultLanguage={defaultLanguage}
            handleLeftMenuSelection={handleLeftMenuSelection}
            setMessage={updateRuleTextComponent}
          />
        );
    }
  }, [
    offerRulePopup,
    defaultOfferRulePopup,
    offersData,
    defaultOffersData,
    editPromotionState,
    selectedOptionPath,
    handleLeftMenuSelection,
    updateOffer,
  ]);

  return <div className='PromotionOfferRulePopup'>{offerRulePopupOptions}</div>;
};

export default PromotionOfferRulePopup;
