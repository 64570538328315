import React from 'react';
import './CustomJS.scss';
import { useI18n } from '@shopify/react-i18n';
import { BlockStack, Link, Text } from '@shopify/polaris';
import CodeMirror from '@uiw/react-codemirror';
import { javascript as JS } from '@codemirror/lang-javascript';
import classNames from 'classnames';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';

type CustomJSProps = {
  code: string;
  disabled?: boolean;
  setCode: (value: string) => void;
  onSystemReset?: () => void;
};

export const CustomJS: React.FC<CustomJSProps> = ({
  code,
  disabled,
  setCode,
  onSystemReset,
}) => {
  const [i18n] = useI18n();

  return (
    <div className='CustomJS'>
      <StyleComponentHeader
        label={i18n.translate(`EvaluateJS`)}
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            disabled: disabled,
            onAction: () => onSystemReset?.(),
          },
        ]}
      >
        <BlockStack gap='300'>
          <BlockStack gap='100'>
            <Text tone='subdued' as='p'>
              {i18n.translate('AddCustom')}
            </Text>
            <Text as='p'>
              <Link>{i18n.translate('LearnMore')}</Link>
            </Text>
          </BlockStack>
          <div
            className={classNames('JSEditor', {
              Disabled: disabled,
            })}
          >
            <CodeMirror
              value={code || ''}
              height='120px'
              extensions={[JS()]}
              onChange={setCode}
              editable={true}
            />
          </div>
        </BlockStack>
      </StyleComponentHeader>
    </div>
  );
};
