import {
  OfferWidgetSetupDtoProductBannerOfferDtoRead,
  ProductBannerPresetDtoRead,
  PromotionWidgetSetupDtoProductBannerPromotionDtoRead,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { LineWeightTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';

export type PromotionWidgetSetupDtoProductBannerPromotionDtoReadWithOffers =
  PromotionWidgetSetupDtoProductBannerPromotionDtoRead & {
    offers?: OfferWidgetSetupDtoProductBannerOfferDtoRead[] | null;
  };

export const productBannerMergeFunc = (
  widget: PromotionWidgetSetupDtoProductBannerPromotionDtoReadWithOffers,
  setup?: ProductBannerPresetDtoRead
) => {
  const {
    isShopLevelWidget,
    canBeDragged,
    canBeRemoved,
    offers,
    ...newWidget
  } = widget;
  const { general: generalSetup, options: optionsSetup } =
    setup?.general && setup?.options ? setup : { general: null, options: null };
  return {
    ...newWidget,
    ...(generalSetup
      ? {
          general: {
            ...newWidget.general,
            background: {
              ...generalSetup?.background,
              border: {
                ...generalSetup?.background?.border,
                value: {
                  ...generalSetup?.background?.border?.value,
                  lineWeight: {
                    ...generalSetup?.background?.border?.value?.lineWeight,
                    customValue:
                      generalSetup?.background?.border?.value?.lineWeight
                        ?.type === LineWeightTypeDtoEnum.CUSTOM
                        ? generalSetup?.background?.border?.value?.lineWeight
                            ?.customValue
                        : undefined,
                  },
                },
              },
            },
            cornerRadius: {
              ...generalSetup?.cornerRadius,
              customValue:
                generalSetup.cornerRadius?.type === LineWeightTypeDtoEnum.CUSTOM
                  ? generalSetup.cornerRadius.customValue
                  : undefined,
            },
          },
        }
      : {
          general: {
            ...newWidget.general,
            background: {
              ...(newWidget as ProductBannerPresetDtoRead).general?.background,
              border: {
                ...(newWidget as ProductBannerPresetDtoRead).general?.background
                  ?.border,
                value: {
                  ...(newWidget as ProductBannerPresetDtoRead).general
                    ?.background?.border?.value,
                  lineWeight: {
                    ...(newWidget as ProductBannerPresetDtoRead).general
                      ?.background?.border?.value?.lineWeight,
                    customValue:
                      (newWidget as ProductBannerPresetDtoRead).general
                        ?.background?.border?.value?.lineWeight?.type ===
                      LineWeightTypeDtoEnum.CUSTOM
                        ? (newWidget as ProductBannerPresetDtoRead).general
                            ?.background?.border?.value?.lineWeight?.customValue
                        : undefined,
                  },
                },
              },
            },
            cornerRadius: {
              ...(newWidget as ProductBannerPresetDtoRead).general
                ?.cornerRadius,
              customValue:
                (newWidget as ProductBannerPresetDtoRead).general?.cornerRadius
                  ?.type === LineWeightTypeDtoEnum.CUSTOM
                  ? (newWidget as ProductBannerPresetDtoRead).general
                      ?.cornerRadius?.customValue
                  : undefined,
            },
          },
        }),
    availableEntries: undefined,
    ...(optionsSetup
      ? {
          offers: offers?.map((offer) => {
            const {
              canBeDragged,
              canBeRemoved,
              parentKey,
              canBeHidden,
              isHidden,
              setup,
              ...newOffer
            } = offer;
            return {
              ...(canBeHidden && { isHidden }),
              ...newOffer,
              setup: {
                ...setup,
                availableEntries: undefined,
                general: {
                  ...setup?.general,
                  isEntitledAndPrerequisiteProductsSupported: undefined,
                  size: setup?.general?.size?.size,
                  background: undefined,
                  cornerRadius: undefined,
                },
                options: {
                  ...(setup?.general
                    ?.isEntitledAndPrerequisiteProductsSupported &&
                  setup?.general?.supportEntitledAndPrerequisiteProducts
                    ? {
                        entitled: {
                          ...setup.options?.entitled,
                          availableEntries: undefined,
                          canBeDragged: undefined,
                          canBeHidden: undefined,
                          canBeRemoved: undefined,
                          isHidden: undefined,
                          general: {
                            ...setup.options?.entitled?.general,
                            size: setup.options?.entitled?.general?.size?.size,
                            background: undefined,
                            cornerRadius: undefined,
                          },
                          options: {
                            icon: {
                              ...setup.options?.entitled?.options?.icon,
                              color: optionsSetup?.icon?.color,
                              canBeDragged: undefined,
                              canBeRemoved: undefined,
                              canBeHidden: undefined,
                            },
                            image: {
                              ...setup.options?.entitled?.options?.image,
                              border: optionsSetup?.image?.border,
                              cornerRadius: {
                                ...optionsSetup?.image?.cornerRadius,
                                customValue:
                                  optionsSetup?.image?.cornerRadius?.type ===
                                  LineWeightTypeDtoEnum.CUSTOM
                                    ? optionsSetup?.image?.cornerRadius
                                        .customValue
                                    : undefined,
                              },
                              image: {
                                ...setup.options?.entitled?.options?.image
                                  ?.image,
                                fit: optionsSetup?.image?.image?.fit,
                                size: optionsSetup?.image?.image?.size,
                              },
                              canBeDragged: undefined,
                              canBeRemoved: undefined,
                              canBeHidden: undefined,
                            },
                            message: {
                              ...setup.options?.entitled?.options?.message,
                              style: {
                                ...optionsSetup?.message?.style,
                                style: {
                                  ...optionsSetup?.message?.style?.style,
                                  fontSize: {
                                    ...optionsSetup?.message?.style?.style
                                      ?.fontSize,
                                    mobile: optionsSetup?.message?.style?.style
                                      ?.differentOnMobile
                                      ? optionsSetup?.message?.style?.style
                                          ?.fontSize?.mobile
                                      : undefined,
                                  },
                                  lineHeight: {
                                    ...optionsSetup?.message?.style?.style
                                      ?.lineHeight,
                                    mobile: optionsSetup?.message?.style?.style
                                      ?.differentOnMobile
                                      ? optionsSetup?.message?.style?.style
                                          ?.lineHeight?.mobile
                                      : undefined,
                                  },
                                  fontWeight: {
                                    ...optionsSetup?.message?.style?.style
                                      ?.fontWeight,
                                    mobile: optionsSetup?.message?.style?.style
                                      ?.differentOnMobile
                                      ? optionsSetup?.message?.style?.style
                                          ?.fontWeight?.mobile
                                      : undefined,
                                  },
                                },
                              },
                              message: {
                                ...setup.options?.entitled?.options?.message
                                  ?.message,
                                settings: undefined,
                              },
                              canBeDragged: undefined,
                              canBeRemoved: undefined,
                              canBeHidden: undefined,
                            },
                          },
                        },
                        prerequisite: {
                          ...setup.options?.prerequisite,
                          availableEntries: undefined,
                          canBeDragged: undefined,
                          canBeHidden: undefined,
                          canBeRemoved: undefined,
                          isHidden: undefined,
                          general: {
                            ...setup.options?.prerequisite?.general,
                            size: setup.options?.prerequisite?.general?.size
                              ?.size,
                            background: undefined,
                            cornerRadius: undefined,
                          },
                          options: {
                            icon: {
                              ...setup.options?.prerequisite?.options?.icon,
                              color: optionsSetup?.icon?.color,
                              canBeDragged: undefined,
                              canBeRemoved: undefined,
                              canBeHidden: undefined,
                            },
                            image: {
                              ...setup.options?.prerequisite?.options?.image,
                              border: optionsSetup?.image?.border,
                              cornerRadius: {
                                ...optionsSetup?.image?.cornerRadius,
                                customValue:
                                  optionsSetup?.image?.cornerRadius?.type ===
                                  LineWeightTypeDtoEnum.CUSTOM
                                    ? optionsSetup?.image?.cornerRadius
                                        .customValue
                                    : undefined,
                              },
                              image: {
                                ...setup.options?.prerequisite?.options?.image
                                  ?.image,
                                fit: optionsSetup?.image?.image?.fit,
                                size: optionsSetup?.image?.image?.size,
                              },
                              canBeDragged: undefined,
                              canBeRemoved: undefined,
                              canBeHidden: undefined,
                            },
                            message: {
                              ...setup.options?.prerequisite?.options?.message,
                              style: {
                                ...optionsSetup?.message?.style,
                                style: {
                                  ...optionsSetup?.message?.style?.style,
                                  fontSize: {
                                    ...optionsSetup?.message?.style?.style
                                      ?.fontSize,
                                    mobile: optionsSetup?.message?.style?.style
                                      ?.differentOnMobile
                                      ? optionsSetup?.message?.style?.style
                                          ?.fontSize?.mobile
                                      : undefined,
                                  },
                                  lineHeight: {
                                    ...optionsSetup?.message?.style?.style
                                      ?.lineHeight,
                                    mobile: optionsSetup?.message?.style?.style
                                      ?.differentOnMobile
                                      ? optionsSetup?.message?.style?.style
                                          ?.lineHeight?.mobile
                                      : undefined,
                                  },
                                  fontWeight: {
                                    ...optionsSetup?.message?.style?.style
                                      ?.fontWeight,
                                    mobile: optionsSetup?.message?.style?.style
                                      ?.differentOnMobile
                                      ? optionsSetup?.message?.style?.style
                                          ?.fontWeight?.mobile
                                      : undefined,
                                  },
                                },
                              },
                              message: {
                                ...setup.options?.prerequisite?.options?.message
                                  ?.message,
                                settings: undefined,
                              },
                              canBeDragged: undefined,
                              canBeRemoved: undefined,
                              canBeHidden: undefined,
                            },
                          },
                        },
                      }
                    : {
                        icon: {
                          ...setup?.options?.icon,
                          color: optionsSetup?.icon?.color,
                          canBeDragged: undefined,
                          canBeRemoved: undefined,
                          canBeHidden: undefined,
                        },
                        image: {
                          ...setup?.options?.image,
                          border: optionsSetup?.image?.border,
                          cornerRadius: {
                            ...optionsSetup?.image?.cornerRadius,
                            customValue:
                              optionsSetup?.image?.cornerRadius?.type ===
                              LineWeightTypeDtoEnum.CUSTOM
                                ? optionsSetup?.image?.cornerRadius.customValue
                                : undefined,
                          },
                          image: {
                            ...setup?.options?.image?.image,
                            fit: optionsSetup?.image?.image?.fit,
                            size: optionsSetup?.image?.image?.size,
                          },
                          canBeDragged: undefined,
                          canBeRemoved: undefined,
                          canBeHidden: undefined,
                        },
                        message: {
                          ...setup?.options?.message,
                          style: {
                            ...optionsSetup?.message?.style,
                            style: {
                              ...optionsSetup?.message?.style?.style,
                              fontSize: {
                                ...optionsSetup?.message?.style?.style
                                  ?.fontSize,
                                mobile: optionsSetup?.message?.style?.style
                                  ?.differentOnMobile
                                  ? optionsSetup?.message?.style?.style
                                      ?.fontSize?.mobile
                                  : undefined,
                              },
                              lineHeight: {
                                ...optionsSetup?.message?.style?.style
                                  ?.lineHeight,
                                mobile: optionsSetup?.message?.style?.style
                                  ?.differentOnMobile
                                  ? optionsSetup?.message?.style?.style
                                      ?.lineHeight?.mobile
                                  : undefined,
                              },
                              fontWeight: {
                                ...optionsSetup?.message?.style?.style
                                  ?.fontWeight,
                                mobile: optionsSetup?.message?.style?.style
                                  ?.differentOnMobile
                                  ? optionsSetup?.message?.style?.style
                                      ?.fontWeight?.mobile
                                  : undefined,
                              },
                            },
                          },
                          message: {
                            ...setup?.options?.message?.message,
                            settings: undefined,
                          },
                          canBeDragged: undefined,
                          canBeRemoved: undefined,
                          canBeHidden: undefined,
                        },
                      }),
                },
              },
            };
          }),
        }
      : {
          options: {
            icon: {
              ...(newWidget as ProductBannerPresetDtoRead).options?.icon,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              isHidden: undefined,
            },
            image: {
              ...(newWidget as ProductBannerPresetDtoRead).options?.image,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              isHidden: undefined,
            },
            message: {
              ...(newWidget as ProductBannerPresetDtoRead).options?.message,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              isHidden: undefined,
              style: {
                ...(newWidget as ProductBannerPresetDtoRead).options?.message
                  ?.style,
                style: {
                  ...(newWidget as ProductBannerPresetDtoRead).options?.message
                    ?.style?.style,
                  fontSize: {
                    ...(newWidget as ProductBannerPresetDtoRead).options
                      ?.message?.style?.style?.fontSize,
                    mobile: (newWidget as ProductBannerPresetDtoRead).options
                      ?.message?.style?.style?.differentOnMobile
                      ? (newWidget as ProductBannerPresetDtoRead).options
                          ?.message?.style?.style?.fontSize?.mobile
                      : undefined,
                  },
                  lineHeight: {
                    ...(newWidget as ProductBannerPresetDtoRead).options
                      ?.message?.style?.style?.lineHeight,
                    mobile: (newWidget as ProductBannerPresetDtoRead).options
                      ?.message?.style?.style?.differentOnMobile
                      ? (newWidget as ProductBannerPresetDtoRead).options
                          ?.message?.style?.style?.lineHeight?.mobile
                      : undefined,
                  },
                  fontWeight: {
                    ...(newWidget as ProductBannerPresetDtoRead).options
                      ?.message?.style?.style?.fontWeight,
                    mobile: (newWidget as ProductBannerPresetDtoRead).options
                      ?.message?.style?.style?.differentOnMobile
                      ? (newWidget as ProductBannerPresetDtoRead).options
                          ?.message?.style?.style?.fontWeight?.mobile
                      : undefined,
                  },
                },
              },
            },
          },
        }),
  };
};
