import React from 'react';
import { useI18n } from '@shopify/react-i18n';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import { Checkbox } from '@shopify/polaris';

type EntitledVsPrerequisiteProps = {
  entitledVsPrerequisite: boolean;
  setEntitledVsPrerequisite: (value: boolean) => void;
  onSystemReset: () => void;
};

export const EntitledVsPrerequisite: React.FC<EntitledVsPrerequisiteProps> = ({
  entitledVsPrerequisite,
  setEntitledVsPrerequisite,
  onSystemReset,
}) => {
  const [i18n] = useI18n();

  return (
    <div className='EntitledVsPrerequisite'>
      <StyleComponentHeader
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            onAction: () => onSystemReset(),
          },
        ]}
        label={i18n.translate(`EntitledVsPrerequisite`)}
      >
        <Checkbox
          helpText={i18n.translate('EntitledVsPrerequisiteInfo')}
          label={i18n.translate('SupportEntitledVsPrerequisite')}
          checked={entitledVsPrerequisite}
          onChange={(value) => setEntitledVsPrerequisite(value)}
        />
      </StyleComponentHeader>
    </div>
  );
};
