import { LegacyStack } from '@shopify/polaris';
import { Pager, PagerOptionsType, SearchField } from 'core/components';
import React from 'react';

type PromotionResultsFilersBarProps = {
  entriesToShow: PagerOptionsType;
  onChangeEntriesToShow?: (entriesToShow: PagerOptionsType) => void;
  onChangeSearchTerm?: (searchTerm: string) => void;
};

export const PromotionResultsFilersBar: React.FC<
  PromotionResultsFilersBarProps
> = (props) => {
  const { entriesToShow, onChangeEntriesToShow, onChangeSearchTerm } = props;

  return (
    <LegacyStack distribution='equalSpacing'>
      <Pager entriesToShow={entriesToShow} onChange={onChangeEntriesToShow} />
      <SearchField onChangeSearchTerm={onChangeSearchTerm} prefix='Search:' />
    </LegacyStack>
  );
};
