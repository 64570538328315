import { NavTabs } from 'core/enums/NavTabsEnum';
import { clearActivePage, updateActivePage } from 'core/store/mainNavSlice';
import { useEffect } from 'react';
import { useAppDispatch } from './useRedux';

export const usePageName = (pageName: NavTabs) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updateActivePage(pageName));

    return () => {
      dispatch(clearActivePage());
    };
  }, [pageName, dispatch]);
};
