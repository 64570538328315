import {
  PromotionCodeFieldSystemDefaultsDto,
  PromotionSetupDtoRead,
  PromotionWidgetSetupMultiLevelDtoPromotionCodeFieldPromotionDtoPromotionCodeFieldFirstLevelPromotionDtoPromotionCodeFieldPromotionEntryTypeDtoRead as PromotionWidgetSetupDtoPromotionCodeFieldDtoRead,
  PromotionCodeFieldFirstLevelPromotionDto,
  PresetsSetupDto,
  PromotionCodeFieldGeneralPresetDto,
  PromotionCodeFieldFirstLevelPresetDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import React, { useCallback, useMemo } from 'react';
import { SelectedOptionPath } from '../../../../RightSideBarWrapper';
import { WidgetTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { getLastSelectedOption } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import PromotionCodeInputCode from '../../../EditPresetRightBar/components/PresetPromotionCode/components/PromotionCodeInputCode/PromotionCodeInputCode';
import PromotionCodeGeneral from '../../../EditPresetRightBar/components/PresetPromotionCode/components/PromotionCodeGeneral/PromotionCodeGeneral';
import PromotionCodeValidationCode from '../../../EditPresetRightBar/components/PresetPromotionCode/components/PromotionCodeValidationCode/PromotionCodeValidationCode';
import PromotionCodeCTA from '../../../EditPresetRightBar/components/PresetPromotionCode/components/PromotionCodeCTA/PromotionCodeCTA';
import {
  WidgetListOptionsDtoEnum,
  WidgetListTypeDtoEnum,
} from 'features/settings/components/EditPresetSkeleton/enums/enums';

type PromotionPromotionCodeProps = {
  editPromotionState: PromotionSetupDtoRead;
  defaultPromotionData: PromotionSetupDtoRead;
  shopDefaultData: PromotionCodeFieldSystemDefaultsDto;
  selectedOptionPath: SelectedOptionPath;
  type:
    | WidgetListTypeDtoEnum.DRAWER_CART_PROMOTION_CODE_FIELD
    | WidgetListTypeDtoEnum.CART_PROMOTION_CODE_FIELD;
  updateEditPromotionState: (
    field: keyof PromotionSetupDtoRead,
    data: PromotionSetupDtoRead[keyof PromotionSetupDtoRead],
    presetSetup?: PresetsSetupDto
  ) => void;
  filterSupportedWidgets: (value: WidgetTypeDtoEnum) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const PromotionPromotionCode: React.FC<PromotionPromotionCodeProps> = ({
  type,
  editPromotionState,
  defaultPromotionData,
  shopDefaultData,
  selectedOptionPath,
  updateEditPromotionState,
  filterSupportedWidgets,
  handleLeftMenuSelection,
}) => {
  const presetSetup = editPromotionState.presetSetup;
  const promotionCodeField = editPromotionState?.[type];
  const defaultPromotionCodeField = defaultPromotionData?.[type];
  const widgetPreset = presetSetup?.[type];
  const defaultPresetSetup = defaultPromotionData?.presetSetup?.[type];

  const updatePromotionCodeField = useCallback(
    (
      field: keyof PromotionWidgetSetupDtoPromotionCodeFieldDtoRead,
      data: PromotionWidgetSetupDtoPromotionCodeFieldDtoRead[keyof PromotionWidgetSetupDtoPromotionCodeFieldDtoRead],
      preset?: PromotionCodeFieldGeneralPresetDto
    ) =>
      updateEditPromotionState(
        type,
        {
          ...promotionCodeField,
          [field]: data,
        },
        {
          ...presetSetup,
          [type]: {
            ...presetSetup?.[type],
            general: preset,
          },
        }
      ),
    [promotionCodeField, updateEditPromotionState, presetSetup]
  );

  const updateComponent = useCallback(
    (
      field: keyof PromotionCodeFieldFirstLevelPromotionDto,
      data: PromotionCodeFieldFirstLevelPromotionDto[keyof PromotionCodeFieldFirstLevelPromotionDto],
      preset?: PromotionCodeFieldFirstLevelPresetDto
    ) =>
      updateEditPromotionState(
        type,
        {
          ...promotionCodeField,
          options: {
            ...promotionCodeField?.options,
            [field]: data,
          },
        },
        {
          ...presetSetup,
          [type]: {
            ...presetSetup?.[type],
            options: preset,
          },
        }
      ),
    [updateEditPromotionState, promotionCodeField, presetSetup]
  );

  const promotionCodeOptions = useMemo(() => {
    const selectedOption = getLastSelectedOption(selectedOptionPath);
    const defaultLanguage = editPromotionState.offers?.defaultLanguage || '';
    switch (true) {
      case selectedOption?.name === type:
        return (
          <PromotionCodeGeneral
            general={widgetPreset?.general || {}}
            defaultGeneral={defaultPresetSetup?.general || {}}
            defaultLanguage={defaultLanguage}
            shopDefaultGeneral={shopDefaultData.general || {}}
            type={type}
            setGeneral={(data) =>
              updatePromotionCodeField(
                'general',
                promotionCodeField?.general,
                data
              )
            }
            onShowHide={
              promotionCodeField?.canBeHidden
                ? () =>
                    updatePromotionCodeField(
                      'isHidden',
                      !promotionCodeField?.isHidden
                    )
                : undefined
            }
            onRemove={() =>
              filterSupportedWidgets(
                type === WidgetListTypeDtoEnum.DRAWER_CART_PROMOTION_CODE_FIELD
                  ? WidgetTypeDtoEnum.DRAWER_CART_PROMOTION_CODE_FIELD
                  : WidgetTypeDtoEnum.CART_PROMOTION_CODE_FIELD
              )
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.CODE_INPUT:
        return (
          <PromotionCodeInputCode
            codeInput={widgetPreset?.options?.codeInput || {}}
            defaultLanguage={defaultLanguage}
            defaultCodeInput={defaultPresetSetup?.options?.codeInput || {}}
            shopDefaultCodeInput={shopDefaultData.codeInput || {}}
            setCodeInput={(data) =>
              updateComponent(
                'codeInput',
                promotionCodeField?.options?.codeInput,
                { ...widgetPreset?.options, codeInput: data }
              )
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.CODE_VALIDATION:
        return (
          <PromotionCodeValidationCode
            codeValidation={widgetPreset?.options?.codeValidation || {}}
            defaultCodeValidation={
              defaultPresetSetup?.options?.codeValidation || {}
            }
            defaultLanguage={defaultLanguage}
            shopDefaultCodeValidation={shopDefaultData.codeValidation || {}}
            setCodeValidation={(data) =>
              updateComponent(
                'codeValidation',
                promotionCodeField?.options?.codeValidation,
                { ...widgetPreset?.options, codeValidation: data }
              )
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.CTA_BUTTON:
        return (
          <PromotionCodeCTA
            ctaButton={widgetPreset?.options?.callToActionButton || {}}
            defaultCTAButton={
              defaultPresetSetup?.options?.callToActionButton || {}
            }
            shopDefaultCTA={shopDefaultData.callToActionButton || {}}
            defaultLanguage={defaultLanguage}
            setCTAButton={(data) =>
              updateComponent(
                'callToActionButton',
                promotionCodeField?.options?.callToActionButton,
                { ...widgetPreset?.options, callToActionButton: data }
              )
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
    }
  }, [
    promotionCodeField,
    defaultPromotionCodeField,
    selectedOptionPath,
    widgetPreset,
    updateComponent,
    updatePromotionCodeField,
    handleLeftMenuSelection,
  ]);

  return <div className='PromotionPromotionCode'>{promotionCodeOptions}</div>;
};

export default PromotionPromotionCode;
