import React, { useCallback, useMemo, useState } from 'react';
import {
  BlockStack,
  Box,
  Checkbox,
  InlineStack,
  Modal,
  RadioButton,
  Text,
  TextField,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { TriggerVisitorBehaviorsDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { TriggerVisitorBehaviorCriteriaTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
type VisitorBehaviorModalProps = {
  isOpen: boolean;
  visitorBehavior?: TriggerVisitorBehaviorsDto;
  onClose: () => void;
  onSave: (data: TriggerVisitorBehaviorsDto) => void;
};
const VisitorBehaviorModal: React.FC<VisitorBehaviorModalProps> = ({
  isOpen,
  visitorBehavior,
  onClose,
  onSave,
}) => {
  const [i18n] = useI18n();
  const [setup, setSetup] = useState<TriggerVisitorBehaviorsDto>(
    visitorBehavior || {}
  );
  const rulesOptions = useMemo(
    () =>
      Object.values(TriggerVisitorBehaviorCriteriaTypeDtoEnum).map((value) => (
        <RadioButton
          key={value}
          label={i18n.translate(value)}
          checked={setup.criteriaType === value}
          onChange={() => handleUpdateSetup('criteriaType', value)}
        />
      )),
    [i18n, setup.criteriaType]
  );

  const checkIsValid = useCallback(
    (
      field:
        | 'timeSpentOnPage'
        | 'numberOfPagesVisited'
        | 'percentageOfPageViewed'
    ) =>
      (setup?.[field]?.enabled && (setup?.[field]?.value as number) > 0) ||
      !setup?.[field]?.enabled,
    [setup]
  );

  const isAllValid =
    checkIsValid('numberOfPagesVisited') &&
    checkIsValid('percentageOfPageViewed') &&
    checkIsValid('timeSpentOnPage');

  const handleUpdateSetup = useCallback(
    (
      field: keyof TriggerVisitorBehaviorsDto,
      data: TriggerVisitorBehaviorsDto[keyof TriggerVisitorBehaviorsDto]
    ) => {
      setSetup({ ...setup, [field]: data });
    },
    [setup]
  );

  const validateInt = useCallback(
    (
      option:
        | 'timeSpentOnPage'
        | 'numberOfPagesVisited'
        | 'percentageOfPageViewed'
    ) => {
      const value = setup?.[option]?.value || 1;
      handleUpdateSetup(option, {
        ...setup?.[option],
        value:
          value > 100 && option === 'percentageOfPageViewed'
            ? 100
            : value < 1
            ? 1
            : value,
      });
    },
    [setup]
  );

  return (
    <div className='VisitorBehaviorModal'>
      <Modal
        open={isOpen}
        title={i18n.translate('ManageVisitorBehavior')}
        onClose={onClose}
        primaryAction={{
          content: i18n.translate('Save'),
          disabled: !isAllValid,
          onAction: () => {
            onSave(setup);
            onClose();
          },
        }}
        secondaryActions={[
          { content: i18n.translate('Cancel'), onAction: onClose },
        ]}
      >
        <Modal.Section>
          <InlineStack blockAlign='center' gap='400'>
            <Text as='p'>{i18n.translate('VisitorsMustMeet')}</Text>
            {rulesOptions}
          </InlineStack>
        </Modal.Section>
        <Modal.Section>
          <BlockStack gap='600'>
            <InlineStack gap='500' wrap={false} align='space-between'>
              <Checkbox
                checked={setup.timeSpentOnPage?.enabled}
                onChange={(value) =>
                  handleUpdateSetup('timeSpentOnPage', {
                    ...setup.timeSpentOnPage,
                    enabled: value,
                  })
                }
                label={i18n.translate('TimeSpent')}
                helpText={i18n.translate('TimeSpentSubtitle')}
              />
              <Box width='160px'>
                <TextField
                  label=''
                  value={setup.timeSpentOnPage?.value?.toString()}
                  onChange={(value) =>
                    handleUpdateSetup('timeSpentOnPage', {
                      ...setup.timeSpentOnPage,
                      value: +value,
                    })
                  }
                  error={
                    checkIsValid('timeSpentOnPage')
                      ? false
                      : i18n.translate('NotValid')
                  }
                  disabled={!setup.timeSpentOnPage?.enabled}
                  type='number'
                  suffix={i18n.translate('Seconds')}
                  autoComplete=''
                  onBlur={() => validateInt('timeSpentOnPage')}
                />
              </Box>
            </InlineStack>
            <InlineStack gap='500' wrap={false} align='space-between'>
              <Checkbox
                checked={setup.numberOfPagesVisited?.enabled}
                onChange={(value) =>
                  handleUpdateSetup('numberOfPagesVisited', {
                    ...setup.numberOfPagesVisited,
                    enabled: value,
                  })
                }
                label={i18n.translate('NumberOfPages')}
                helpText={i18n.translate('NumberOfPagesSubtitle')}
              />
              <Box width='160px'>
                <TextField
                  label=''
                  value={setup.numberOfPagesVisited?.value?.toString()}
                  onChange={(value) =>
                    handleUpdateSetup('numberOfPagesVisited', {
                      ...setup.numberOfPagesVisited,
                      value: +value,
                    })
                  }
                  error={
                    checkIsValid('numberOfPagesVisited')
                      ? false
                      : i18n.translate('NotValid')
                  }
                  disabled={!setup.numberOfPagesVisited?.enabled}
                  type='number'
                  suffix={i18n.translate('Pages')}
                  autoComplete=''
                  onBlur={() => validateInt('numberOfPagesVisited')}
                />
              </Box>
            </InlineStack>
            <InlineStack gap='500' wrap={false} align='space-between'>
              <Checkbox
                checked={setup.percentageOfPageViewed?.enabled}
                onChange={(value) =>
                  handleUpdateSetup('percentageOfPageViewed', {
                    ...setup.percentageOfPageViewed,
                    enabled: value,
                  })
                }
                label={i18n.translate('PercentageOfPage')}
                helpText={i18n.translate('PercentageOfPageSubtitle')}
              />
              <Box width='160px'>
                <TextField
                  label=''
                  value={setup.percentageOfPageViewed?.value?.toString()}
                  onChange={(value) =>
                    handleUpdateSetup('percentageOfPageViewed', {
                      ...setup.percentageOfPageViewed,
                      value: +value,
                    })
                  }
                  error={
                    checkIsValid('percentageOfPageViewed')
                      ? false
                      : i18n.translate('NotValid')
                  }
                  disabled={!setup.percentageOfPageViewed?.enabled}
                  type='number'
                  suffix={'%'}
                  autoComplete=''
                  onBlur={() => validateInt('percentageOfPageViewed')}
                />
              </Box>
            </InlineStack>
            <Checkbox
              checked={setup.exitIntentEnabled}
              onChange={(value) =>
                handleUpdateSetup('exitIntentEnabled', value)
              }
              label={i18n.translate('ExitIntent')}
            />
          </BlockStack>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default VisitorBehaviorModal;
