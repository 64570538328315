import { OfferTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { IconSource } from '@shopify/polaris';
import {
  DiscountIcon,
  CartDiscountIcon,
  DeliveryIcon,
  GiftCardIcon,
  CartDownIcon,
  DiscountCodeIcon,
} from '@shopify/polaris-icons';

export type handleOfferTemplateDto = {
  icon: IconSource;
  isShopifyPlus?: boolean;
  isNew?: boolean;
};

export const handleOfferTemplate = (
  type: OfferTypeDtoEnum | undefined
): handleOfferTemplateDto => {
  if (
    type === OfferTypeDtoEnum.SALES_FIXED_AMOUNT ||
    type === OfferTypeDtoEnum.SALES_PERCENTAGE ||
    type === OfferTypeDtoEnum.SALES_FIXED_PRICE
  ) {
    return { icon: DiscountIcon };
  } else if (
    type === OfferTypeDtoEnum.CROSS_SELL ||
    type === OfferTypeDtoEnum.BOGO_REGULAR ||
    type === OfferTypeDtoEnum.BOGO_STRICT_MATCH
  ) {
    if (type === OfferTypeDtoEnum.CROSS_SELL) {
      return { icon: CartDiscountIcon, isShopifyPlus: true };
    } else {
      return { icon: CartDiscountIcon };
    }
  } else if (
    type === OfferTypeDtoEnum.TIERED_BOGO ||
    type === OfferTypeDtoEnum.TIERED_SPEND_X_GET_Y ||
    type === OfferTypeDtoEnum.VOLUME_DISCOUNT ||
    type === OfferTypeDtoEnum.BUNDLE_ORDER_DISCOUNT
  ) {
    if (type === OfferTypeDtoEnum.VOLUME_DISCOUNT) {
      return { icon: CartDownIcon, isNew: true };
    } else {
      return { icon: CartDownIcon };
    }
  } else if (
    type === OfferTypeDtoEnum.FREE_SHIPPING ||
    type === OfferTypeDtoEnum.SHIPPING_PERCENTAGE ||
    type === OfferTypeDtoEnum.SHIPPING_FIXED_AMOUNT
  ) {
    return { icon: DeliveryIcon };
  } else if (
    type === OfferTypeDtoEnum.GIFT_AUTOMATIC ||
    type === OfferTypeDtoEnum.GIFT_MANUAL
  ) {
    return { icon: GiftCardIcon };
  } else if (
    type === OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_REGULAR ||
    type === OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_GROUP ||
    type === OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_APP
  ) {
    return { icon: DiscountCodeIcon };
  } else {
    return { icon: DiscountCodeIcon };
  }
};
