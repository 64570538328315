import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './TextEntries.scss';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import { useI18n } from '@shopify/react-i18n';
import {
  LocalizedStringDto,
  DeviceTypeDto,
  GoalStateDto,
  TextEntryDto,
  MessageSetupDto,
  CollapsedStateDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import {
  CollapsedStateDtoEnum,
  DeviceTypeDtoEnum,
  GoalStateDtoEnum,
  TextTypeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import { RichTextEditor } from 'core/components';
import { Button, Checkbox, Collapsible, Text } from '@shopify/polaris';
import { ChevronUpIcon, ChevronDownIcon } from '@shopify/polaris-icons';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { setActiveDeviceType } from 'core/store/widgetsSlice';
import { isEqual } from 'lodash';

export type Feature =
  | 'bold'
  | 'italic'
  | 'underline'
  | 'code-editor'
  | 'font-family'
  | 'font-size'
  | 'color'
  | 'strike'
  | 'text-align'
  | 'link'
  | 'smartTag';

export type PreviewEntry = {
  desktop: string;
  mobile: string;
};

type TextStyleProps = {
  textMessage: MessageSetupDto;
  disabled?: boolean;
  defaultLanguage: string;
  title?: string;
  disableGoal?: boolean;
  disableCollapsed?: boolean;
  disableDevice?: boolean;
  disableColor?: boolean;
  enabled?: boolean;
  enableText?: string;
  inherited?: boolean;
  offerId?: string;
  priceOnly?: boolean;
  sameAsExpanded?: boolean;
  setSameAsExpanded?: (value: boolean) => void;
  setEnabled?: (value: boolean) => void;
  setPreviewEntry?: (data: PreviewEntry) => void;
  setTextMessage: (data: MessageSetupDto) => void;
  onSystemReset: () => void;
  alignmentFeature?: boolean;
};

export const TextEntries: React.FC<TextStyleProps> = ({
  textMessage,
  disabled,
  defaultLanguage,
  title,
  disableGoal,
  disableCollapsed,
  disableDevice,
  disableColor,
  alignmentFeature = false,
  enabled,
  enableText,
  inherited,
  priceOnly,
  offerId,
  sameAsExpanded,
  setSameAsExpanded,
  setEnabled,
  setPreviewEntry,
  onSystemReset,
  setTextMessage,
}) => {
  const [i18n] = useI18n();
  const dispath = useAppDispatch();
  const { activeDeviceType } = useAppSelector((store) => store.widgets);
  const [goalFilter, setGoalFilter] = useState<GoalStateDtoEnum | null>(
    textMessage.settings?.goalStates === TextTypeDtoEnum.DIFFERENT
      ? GoalStateDtoEnum.AFTER
      : null
  );
  const [collapsedFilter, setCollapsedFilter] =
    useState<CollapsedStateDtoEnum | null>(
      textMessage.settings?.collapsed === TextTypeDtoEnum.DIFFERENT
        ? CollapsedStateDtoEnum.NOT_COLLAPSED
        : null
    );
  const [allEntries, setAllEntries] = useState<TextEntryDto[]>([]);
  const [isAdvancedOpen, setIsAdvancedOpen] = useState<boolean>(false);

  const deviceFilter = useMemo(
    () =>
      textMessage.settings?.device === TextTypeDtoEnum.DIFFERENT
        ? activeDeviceType
        : null,
    [activeDeviceType, textMessage.settings]
  );
  const generateVariants = () => {
    const goalTypes: GoalStateDto[] = ['BEFORE', 'DURING', 'AFTER', null];
    const deviceTypes: DeviceTypeDto[] = ['DESKTOP', 'MOBILE', null];
    const collapsedTypes: CollapsedStateDto[] = [
      'COLLAPSED',
      'NOT_COLLAPSED',
      null,
    ];

    const findCreatedText = textMessage.entries?.find(
      (item) => item.text?.length
    );
    const existingEntries = textMessage.entries?.filter(
      (entry) =>
        goalTypes.includes(entry.goal || null) &&
        deviceTypes.includes(entry.device || null) &&
        collapsedTypes.includes(entry.collapsed || null)
    );

    const generatedEntries = goalTypes
      .flatMap((goal) =>
        deviceTypes.flatMap((device) =>
          collapsedTypes.map((collapsed) => ({
            goal,
            device,
            collapsed,
            text: findCreatedText?.text,
          }))
        )
      )
      .filter(
        (newEntry) =>
          !existingEntries?.some(
            (existingEntry) =>
              existingEntry.goal === newEntry.goal &&
              existingEntry.device === newEntry.device &&
              existingEntry.collapsed === newEntry.collapsed
          )
      );

    const updatedEntries = [...(existingEntries || []), ...generatedEntries];
    setAllEntries(updatedEntries);
  };
  const filteredEntry = useMemo(() => {
    return allEntries.find((entry) => {
      const goalMatch = entry.goal === goalFilter;
      const deviceMatch = entry.device === deviceFilter;
      const collapsedMatch = entry.collapsed === collapsedFilter;
      return goalMatch && deviceMatch && collapsedMatch;
    });
  }, [allEntries, goalFilter, deviceFilter, collapsedFilter]);

  const goalOptions = useMemo(
    () =>
      Object.values(GoalStateDtoEnum).map((value) => {
        return {
          value: value,
          label: i18n.translate(`${value}`),
        };
      }),
    []
  );
  const deviceOptions = useMemo(
    () =>
      Object.values(DeviceTypeDtoEnum).map((value) => {
        return {
          value: value,
          label: i18n.translate(`${value}`),
        };
      }),
    []
  );
  const collapsedOptions = useMemo(
    () =>
      Object.values(CollapsedStateDtoEnum).map((value) => {
        return {
          value: value,
          label: i18n.translate(`${value}`),
        };
      }),
    []
  );

  const previewEntry = useMemo(() => {
    const filteredEntries = allEntries.filter((entry) => {
      const goalMatch =
        entry.goal === goalFilter &&
        entry.collapsed === collapsedFilter &&
        (deviceFilter !== null ? entry.device !== null : true);

      return goalMatch;
    });
    const getPreviewText = (deviceType: DeviceTypeDto | null) =>
      filteredEntries
        .find((entry) => entry.device === deviceType || entry.device === null)
        ?.text?.find((text) => text.language === defaultLanguage)?.text;

    const preview = {
      mobile: getPreviewText(deviceFilter ? DeviceTypeDtoEnum.MOBILE : null),
      desktop: getPreviewText(deviceFilter ? DeviceTypeDtoEnum.DESKTOP : null),
    };
    return preview;
  }, [allEntries, goalFilter, deviceFilter, collapsedFilter]);

  const features: Feature[] = useMemo(() => {
    const baseFeatures: Feature[] = [
      'bold',
      'italic',
      'underline',
      'strike',
      'color',
      'font-size',
      'smartTag',
    ];

    if (priceOnly) {
      return ['smartTag'];
    }

    if (alignmentFeature) {
      const alignmentFeatures: Feature[] = [...baseFeatures, 'text-align'];
      return disableColor
        ? alignmentFeatures.filter((feature) => feature !== 'color')
        : alignmentFeatures;
    }

    return disableColor
      ? baseFeatures.filter((feature) => feature !== 'color')
      : baseFeatures;
  }, [priceOnly, alignmentFeature, disableColor]);

  const hideAllContent = useMemo(() => {
    if (sameAsExpanded !== undefined) return sameAsExpanded;
    if (enabled !== undefined) return !enabled;
    return false;
  }, [sameAsExpanded, enabled]);

  const changeTranslation = useCallback(
    (value: string, language: string) => {
      setAllEntries((prev) => {
        const updatedEntries = prev.map((entry) => {
          if (
            entry.goal === goalFilter &&
            entry.device === deviceFilter &&
            entry.collapsed === collapsedFilter
          ) {
            const updatedText = entry.text?.map((textItem) => {
              if (textItem.language === language) {
                return {
                  ...textItem,
                  text: value,
                };
              }
              return textItem;
            });
            return {
              ...entry,
              text: updatedText,
            };
          }
          return entry;
        });

        return updatedEntries;
      });
    },
    [goalFilter, deviceFilter, collapsedFilter]
  );
  const saveConfiguredEntries = useCallback(() => {
    const entries = allEntries.filter((entry) => {
      const goalState =
        textMessage.settings?.goalStates === TextTypeDtoEnum.UNIFIED ||
        !textMessage.settings?.goalStates
          ? null
          : entry.goal;
      const deviceState =
        textMessage.settings?.device === TextTypeDtoEnum.UNIFIED ||
        !textMessage.settings?.device
          ? null
          : entry.device;
      const collapsedState =
        textMessage.settings?.collapsed === TextTypeDtoEnum.UNIFIED ||
        !textMessage.settings?.collapsed
          ? null
          : entry.collapsed;
      const filterGoal = goalState === entry.goal;
      const filterDevice = deviceState === entry.device;
      const filterCollapsed = collapsedState === entry.collapsed;
      return filterGoal && filterDevice && filterCollapsed;
    });
    const configured = { ...textMessage, entries: entries };

    !isEqual(configured.entries, textMessage.entries) &&
      configured.entries.length &&
      setTextMessage(configured);
  }, [textMessage, allEntries, setTextMessage]);

  const languageEntry = useCallback(
    (entry: LocalizedStringDto, isDefault?: boolean) => (
      <div className='LanguageEntry' key={entry.language}>
        <Text id='language-name' as='p'>
          {i18n.translate(`${entry.language}`)}{' '}
          {isDefault && (
            <Text as='span' tone='subdued'>
              {i18n.translate(`Default`)}
            </Text>
          )}
        </Text>
        <RichTextEditor
          features={features}
          priceOnly={priceOnly}
          offerId={offerId}
          value={entry.text}
          onChange={(value) =>
            changeTranslation(value, entry.language as string)
          }
          disabled={disabled}
        />
      </div>
    ),
    [changeTranslation, i18n, disabled]
  );

  useEffect(() => {
    setPreviewEntry?.(previewEntry as PreviewEntry);
  }, [previewEntry]);

  useEffect(() => {
    saveConfiguredEntries();
  }, [allEntries]);

  useEffect(() => {
    generateVariants();
  }, [textMessage.entries]);

  return (
    <div className='TextEntries'>
      <StyleComponentHeader
        label={title ? title : i18n.translate(`Text`)}
        inherited={inherited}
        removeTopPadding={priceOnly}
        buttons={
          !priceOnly
            ? [
                {
                  external: true,
                  content: i18n.translate(`ResetToSystemDefault`),
                  disabled: disabled,
                  onAction: () => {
                    onSystemReset();
                  },
                },
              ]
            : undefined
        }
      >
        {sameAsExpanded !== undefined && (
          <Checkbox
            onChange={(value) => setSameAsExpanded?.(value)}
            checked={sameAsExpanded}
            label={i18n.translate('SameAsExpanded')}
            disabled={disabled}
          />
        )}
        {enabled !== undefined && !sameAsExpanded && (
          <Checkbox
            onChange={(value) => setEnabled?.(value)}
            checked={enabled}
            label={enableText}
            disabled={disabled}
          />
        )}
        {!hideAllContent && (
          <>
            {!disableDevice && (
              <Checkbox
                onChange={() =>
                  setTextMessage({
                    ...textMessage,
                    settings: {
                      ...textMessage.settings,
                      device:
                        textMessage.settings?.device ===
                        TextTypeDtoEnum.DIFFERENT
                          ? TextTypeDtoEnum.UNIFIED
                          : TextTypeDtoEnum.DIFFERENT,
                    },
                  })
                }
                disabled={disabled}
                checked={
                  textMessage.settings?.device === TextTypeDtoEnum.DIFFERENT
                }
                label={i18n.translate('EnableDifferentMobile')}
              />
            )}
            {!disableGoal && (
              <Checkbox
                onChange={(value) => {
                  setTextMessage({
                    ...textMessage,
                    settings: {
                      ...textMessage.settings,
                      goalStates: value
                        ? TextTypeDtoEnum.DIFFERENT
                        : TextTypeDtoEnum.UNIFIED,
                    },
                  });
                  setGoalFilter(value ? GoalStateDtoEnum.AFTER : null);
                }}
                disabled={disabled}
                checked={
                  textMessage.settings?.goalStates === TextTypeDtoEnum.DIFFERENT
                }
                label={i18n.translate('EnableDifferentGoal')}
              />
            )}
            {!disableCollapsed && (
              <Checkbox
                onChange={(value) => {
                  setTextMessage({
                    ...textMessage,
                    settings: {
                      ...textMessage.settings,
                      collapsed: value
                        ? TextTypeDtoEnum.DIFFERENT
                        : TextTypeDtoEnum.UNIFIED,
                    },
                  });
                  setCollapsedFilter(
                    value ? CollapsedStateDtoEnum.NOT_COLLAPSED : null
                  );
                }}
                disabled={disabled}
                checked={
                  textMessage.settings?.collapsed === TextTypeDtoEnum.DIFFERENT
                }
                label={i18n.translate('EnableDifferentCollapse')}
              />
            )}
            {(textMessage.settings?.device === TextTypeDtoEnum.DIFFERENT ||
              textMessage.settings?.goalStates === TextTypeDtoEnum.DIFFERENT ||
              textMessage.settings?.collapsed ===
                TextTypeDtoEnum.DIFFERENT) && (
              <div className='GoalAndDevice'>
                {textMessage.settings?.goalStates ===
                  TextTypeDtoEnum.DIFFERENT && (
                  <SelectOptions
                    options={goalOptions}
                    onOptionSelect={(value) => {
                      setGoalFilter(value as GoalStateDtoEnum);
                    }}
                    disabled={disabled}
                    selectedOption={goalFilter || GoalStateDtoEnum.AFTER}
                    label=''
                  />
                )}
                {textMessage.settings?.device === TextTypeDtoEnum.DIFFERENT && (
                  <SelectOptions
                    options={deviceOptions}
                    onOptionSelect={(value) => {
                      dispath(setActiveDeviceType(value as DeviceTypeDtoEnum));
                    }}
                    selectedOption={deviceFilter || DeviceTypeDtoEnum.DESKTOP}
                    disabled={disabled}
                    label=''
                  />
                )}
                {textMessage.settings?.collapsed ===
                  TextTypeDtoEnum.DIFFERENT && (
                  <SelectOptions
                    options={collapsedOptions}
                    onOptionSelect={(value) => {
                      setCollapsedFilter(value as CollapsedStateDtoEnum);
                    }}
                    selectedOption={
                      collapsedFilter || CollapsedStateDtoEnum.NOT_COLLAPSED
                    }
                    disabled={disabled}
                    label=''
                  />
                )}
              </div>
            )}
            <div className='TranslationEditor'>
              {filteredEntry?.text?.map(
                (item) =>
                  item.language === defaultLanguage && languageEntry(item, true)
              )}
            </div>
            {(filteredEntry?.text?.length || 0) > 1 && (
              <div className='AdvancedSection'>
                <Button
                  variant='plain'
                  onClick={() => setIsAdvancedOpen(!isAdvancedOpen)}
                  icon={isAdvancedOpen ? ChevronUpIcon : ChevronDownIcon}
                >
                  {i18n.translate(`Translations`)}
                </Button>
                <Collapsible
                  open={isAdvancedOpen}
                  id='basic-collapsible'
                  transition={{
                    duration: '500ms',
                    timingFunction: 'ease-in-out',
                  }}
                  expandOnPrint
                >
                  {filteredEntry?.text?.map(
                    (item) =>
                      item.language !== defaultLanguage && languageEntry(item)
                  )}
                </Collapsible>
              </div>
            )}
          </>
        )}
      </StyleComponentHeader>
    </div>
  );
};
