import React, { useCallback, useMemo, useState } from 'react';
import './LeftSidebarWrapper.scss';
import { useI18n } from '@shopify/react-i18n';
import {
  ActionList,
  Box,
  Button,
  Collapsible,
  Icon,
  Popover,
  Scrollable,
  Text,
} from '@shopify/polaris';
import {
  ChevronRightIcon,
  ChevronDownIcon,
  ThemeIcon,
  DragHandleIcon,
  LayoutBlockIcon,
  LayoutSectionIcon,
  DiscountCodeIcon,
  ViewIcon,
  HideIcon,
  DeleteIcon,
  InfoIcon,
  ChevronLeftIcon,
  PlusCircleIcon,
} from '@shopify/polaris-icons';
import classNames from 'classnames';
import {
  NinjaCartStatesPresetEntryTypeDtoEnum,
  NotificationStatesPromotionEntryTypeDtoEnum,
  PageTypeDtoEnum,
  PresetBoundariesTypeDtoEnum,
  PriceStatesEntryTypeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import {
  LeftMenuWidget,
  transformEnum,
  updateLeftMenuSelection,
} from '../../utils/leftMenuGenerators';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { stringToUpperSnakeCase } from '../../utils/utils';
import PresetChangesBadge, {
  PromotionPresetsChangesDto,
} from './components/PresetChangesBadge/PresetChangesBadge';
import {
  PageSetupEntryDto,
  PageTypeDto,
  PromotionPresetsDto,
  WidgetTypeDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { isEmpty } from 'lodash';
import LeftSideBarWidgetList from './components/LeftSideBarWidgetList/LeftSideBarWidgetList';
import { PriceWidgetTypesDtoEnum } from '../RightSideBarWrapper/RightBarTypes/EditPresetRightBar/EditPresetRightBar';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import { WidgetListTypeDtoEnum } from '../../enums/enums';
import { useAppDispatch } from 'core/hooks';
import { setSelectedPage } from 'core/store/widgetsSlice';

type LeftSidebarWrapperProps = {
  widgetsState: LeftMenuWidget[];
  smallScreen: boolean;
  presets?: PromotionPresetsDto;
  presetsChanges?: PromotionPresetsChangesDto;
  pages: PageSetupEntryDto[];
  handlePresetChangeData: (presetLevel: PresetBoundariesTypeDtoEnum) => void;
  handleWidgetsMenuState: (
    widgetType: string,
    field: string,
    value: any
  ) => void;
  handleAddWidget: (page: PageTypeDto, widgetName: WidgetTypeDto) => void;
  handleRemoveWidget: (widgetName: WidgetTypeDto) => void;
  setLeftWidgetsMenu: (data: LeftMenuWidget[]) => void;
  handleSwitchWidgetState: (widgetName: string, entries: any[]) => void;
  setFlag: (value: boolean | null) => void;
};

export const LeftSidebarWrapper: React.FC<LeftSidebarWrapperProps> = ({
  widgetsState,
  smallScreen,
  presets,
  presetsChanges,
  pages,
  handlePresetChangeData,
  handleWidgetsMenuState,
  handleAddWidget,
  handleRemoveWidget,
  setLeftWidgetsMenu,
  handleSwitchWidgetState,
  setFlag,
}) => {
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();

  const [addBlockPopover, setAddBlockPopover] = useState<string>('');
  const [druggableHoverId, setDruggableHoverId] = useState<string>('');

  const ninjaCartStateOptions = useMemo(
    () =>
      Object.values(NinjaCartStatesPresetEntryTypeDtoEnum).map((option) => ({
        label: i18n.translate(option),
        value: option,
      })),
    [i18n]
  );

  const notificationStateOptions = useMemo(
    () =>
      Object.values(NotificationStatesPromotionEntryTypeDtoEnum).map(
        (option) => ({
          label: i18n.translate(option),
          value: option,
        })
      ),
    [i18n]
  );

  const openedWidget = useMemo(
    () => widgetsState.find((widget) => widget.isOpen),
    [widgetsState]
  );

  const isLeftMenuSplited = useMemo(
    () =>
      openedWidget?.availableEntries?.includes(
        PriceStatesEntryTypeDtoEnum.DISCOUNTED_PRICE
      ),
    [openedWidget?.availableEntries]
  );
  const isNinjaCart = useMemo(
    () => openedWidget?.name === WidgetListTypeDtoEnum.NINJA_CART,
    [openedWidget?.name]
  );
  const isNotification = useMemo(
    () => openedWidget?.name === WidgetListTypeDtoEnum.NOTIFICATION,
    [openedWidget?.name]
  );

  const findNestedLevel = useCallback(
    (obj: any, target: any, currentLevel = 0) => {
      for (const key in obj) {
        if (typeof obj[key] === 'object') {
          if (obj[key] === target) {
            return currentLevel;
          } else {
            const nestedLevel: any = findNestedLevel(
              obj[key],
              target,
              currentLevel + 1
            );
            if (nestedLevel !== -1) {
              return nestedLevel;
            }
          }
        }
      }
      return -1;
    },
    []
  );

  const updateOptionById = useCallback(
    (
      options: LeftMenuWidget[] | undefined,
      targetId: string,
      updateFn: (option: LeftMenuWidget) => LeftMenuWidget
    ): LeftMenuWidget[] | undefined => {
      if (!options) {
        return undefined;
      }
      return options.map((option) => {
        if (option.id === targetId) {
          return updateFn(option);
        } else if (option.options) {
          return {
            ...option,
            options: updateOptionById(option.options, targetId, updateFn),
          };
        } else {
          return option;
        }
      });
    },
    []
  );

  const updateIsHidden = useCallback(
    (arr?: LeftMenuWidget[], value?: boolean) => {
      return arr?.map((obj: LeftMenuWidget) => {
        if (obj.isHidden !== undefined) {
          obj.isSelected = value ? false : obj.isSelected;
          obj.isHidden = value as boolean;
        }
        if (obj.options instanceof Array) {
          obj.options = updateIsHidden(obj.options, value);
        }
        return obj;
      });
    },
    []
  );

  const handleCurrentEntriesIndex = useCallback((options: LeftMenuWidget[]) => {
    const entries = options.map((option) =>
      stringToUpperSnakeCase(option.name)
    );
    return entries;
  }, []);
  const handleUpdateOption = useCallback(
    (targetId: string, fieldToChange: string, value: any) => {
      const isSecondaryBanner =
        fieldToChange === 'options' &&
        value.every(
          (option: LeftMenuWidget) =>
            option.key?.slice(0, -2) ===
            'ANNOUNCEMENT_BAR_SECONDARY_BANNER_SLOT'
        );

      setLeftWidgetsMenu(
        updateOptionById(widgetsState, targetId, (option) => ({
          ...option,
          [fieldToChange]: value,
          isSelected: fieldToChange === 'isHidden' ? false : option.isSelected,
          options:
            fieldToChange === 'isHidden'
              ? updateIsHidden(option.options, value)
              : fieldToChange === 'options'
              ? value
              : option.options,
          currentEntries:
            fieldToChange === 'currentEntries'
              ? value
              : fieldToChange === 'options' && !isSecondaryBanner
              ? handleCurrentEntriesIndex(value)
              : option.currentEntries,
        })) || []
      );
    },
    [
      widgetsState,
      handleWidgetsMenuState,
      updateOptionById,
      openedWidget,
      updateIsHidden,
      handleCurrentEntriesIndex,
    ]
  );
  const handleOptionIcon = useCallback(
    (option: LeftMenuWidget) => {
      switch (true) {
        case druggableHoverId === option.id:
          return DragHandleIcon;
        case !!option.offerId:
          return DiscountCodeIcon;
        case option.name.slice(0, -1) === 'bodyRow':
          return LayoutSectionIcon;
        default:
          return LayoutBlockIcon;
      }
    },
    [druggableHoverId]
  );

  const onDragEnd = useCallback(
    (
      dropResult: DropResult,
      options: LeftMenuWidget[],
      parentOption: LeftMenuWidget
    ) => {
      // dropped outside the list
      if (!dropResult.destination) {
        return;
      }

      const isSecondaryBanner = options.every(
        (option: LeftMenuWidget) =>
          option.key?.slice(0, -2) === 'ANNOUNCEMENT_BAR_SECONDARY_BANNER_SLOT'
      );

      const startIndex = dropResult.source.index;
      const endIndex = dropResult.destination.index;
      const result = options ? [...options] : [];
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      const secondaryBannerResult = result.map((option, index) => ({
        ...option,
        name: !option.offerId ? `slot${index + 1}` : option.name,
        key: `ANNOUNCEMENT_BAR_SECONDARY_BANNER_SLOT_${index + 1}`,
        parentKey: !option.offerId
          ? undefined
          : `ANNOUNCEMENT_BAR_SECONDARY_BANNER_SLOT_${index + 1}`,
      }));

      handleUpdateOption(
        parentOption.id!,
        'options',
        isSecondaryBanner ? secondaryBannerResult : result
      );
    },
    [handleUpdateOption]
  );

  const handleRemoveOption = useCallback(
    (e: any, parentOption: LeftMenuWidget, name: string) => {
      e.stopPropagation();
      handleUpdateOption(
        parentOption.id!,
        'currentEntries',
        parentOption.currentEntries?.filter(
          (entry) => transformEnum(entry, '') !== name
        )
      );
      setFlag(null);
    },
    [handleUpdateOption]
  );

  const handleOptionName = useCallback(
    (option: LeftMenuWidget, parentOption: LeftMenuWidget) => {
      let optionName = option.offerId
        ? option.name
        : i18n.translate(option.name);

      if (option.name === 'entitled' || option.name === 'prerequisite') {
        optionName = `${optionName} ${parentOption.name}`;
      }

      if (parentOption.name === 'secondaryBanner' && option.offerId) {
        const offerIndex = (parentOption.options || []).findIndex(
          (item) => item.offerId === option.offerId
        );
        optionName = `Slot ${(offerIndex || 0) + 1} - ${optionName}`;
      }

      return optionName;
    },
    [i18n]
  );

  const renderAddBlockButton = useCallback(
    (nestedLevel: number, parentOption: LeftMenuWidget) => {
      const currentEntries = parentOption.currentEntries;
      const availableEntries = parentOption.availableEntries;
      const isImageOrIcon =
        currentEntries?.includes('IMAGE') || currentEntries?.includes('ICON');
      let blocksToAdd =
        availableEntries?.filter(
          (entry) =>
            !currentEntries?.includes(entry) &&
            entry !== 'PREREQUISITE' &&
            entry !== 'ENTITLED'
        ) || [];

      if (isImageOrIcon) {
        blocksToAdd = blocksToAdd.filter(
          (entry) => entry !== 'IMAGE' && entry !== 'ICON'
        );
      }

      return (
        <div
          className={classNames('WidgetOption', {
            SecondLvlNesting: nestedLevel === 1,
            ThirdLvlNesting: nestedLevel === 3,
          })}
        >
          <div className='AddBlockButton'>
            <Popover
              active={addBlockPopover === parentOption.id}
              activator={
                <Button
                  onClick={() => setAddBlockPopover(parentOption.id!)}
                  disabled={!blocksToAdd?.length}
                  icon={PlusCircleIcon}
                  variant='plain'
                >
                  {i18n.translate(
                    parentOption.supportsModules ? 'AddModule' : 'AddBlock'
                  )}
                </Button>
              }
              onClose={() => setAddBlockPopover('')}
            >
              <ActionList
                actionRole='menuitem'
                items={blocksToAdd?.map((block) => ({
                  content: i18n.translate(transformEnum(block, '')),
                  icon: LayoutBlockIcon,
                  onAction: () => {
                    handleUpdateOption(parentOption.id!, 'currentEntries', [
                      ...(currentEntries || []),
                      block,
                    ]);
                    setFlag(null);
                  },
                }))}
              />
            </Popover>
          </div>
        </div>
      );
    },
    [addBlockPopover, setAddBlockPopover, handleUpdateOption]
  );

  const showAddBlockButton = useCallback((parentOption: LeftMenuWidget) => {
    return (
      !!parentOption.availableEntries?.length &&
      !(
        (parentOption.availableEntries?.length ===
          parentOption.currentEntries?.length &&
          parentOption.options?.map((option) => !option.canBeRemoved)) ||
        parentOption.currentEntries?.includes('ENTITLED') ||
        parentOption.name === 'ninjaCart' ||
        parentOption.name === 'notification'
      )
    );
  }, []);

  const handleSelectWidget = useCallback(
    (widget: LeftMenuWidget, page: PageTypeDtoEnum) => {
      if (!widget.isHidden) {
        dispatch(setSelectedPage(page));
        if (smallScreen) {
          handleWidgetsMenuState(widget.name, 'isOpen', true);
        } else {
          const isPriceWidget = Object.values(PriceWidgetTypesDtoEnum).includes(
            widget.name as PriceWidgetTypesDtoEnum
          );
          setLeftWidgetsMenu(
            updateLeftMenuSelection(
              widgetsState,
              isPriceWidget ? widget.options?.[0].id || '' : widget.id!
            )
          );
        }
      }
    },
    [
      dispatch,
      handleWidgetsMenuState,
      setLeftWidgetsMenu,
      updateLeftMenuSelection,
      widgetsState,
      smallScreen,
    ]
  );

  const renderOptions = useCallback(
    (options: LeftMenuWidget[], parentOption: LeftMenuWidget) => {
      const nestedLevel =
        findNestedLevel(openedWidget, parentOption) -
        (isLeftMenuSplited || isNinjaCart || isNotification ? 2 : 0);

      return (
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, options, parentOption)}
        >
          <Droppable droppableId='priorities'>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {options.map((option, index) => {
                  const renderNextOptions =
                    !!option.options?.length ||
                    !parentOption.currentEntries?.length ||
                    option.availableEntries?.length;
                  return (
                    <Draggable
                      key={option.id}
                      draggableId={option.id as string}
                      index={index}
                      isDragDisabled={
                        !option.isDraggable || parentOption.isHidden
                      }
                    >
                      {(provided) => (
                        <div
                          key={option.id}
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                          className='OptionWrapper'
                        >
                          <div
                            className={classNames('WidgetOption', {
                              SecondLvlNesting: nestedLevel === 1,
                              ThirdLvlNesting: nestedLevel === 3,
                              isSelected: option.isSelected,
                            })}
                            onClick={() =>
                              !option.isHidden
                                ? setLeftWidgetsMenu(
                                    updateLeftMenuSelection(
                                      widgetsState,
                                      option.id!
                                    )
                                  )
                                : null
                            }
                          >
                            <div className='WidgetOptionSide'>
                              {option.options?.length ||
                              (option.availableEntries?.length &&
                                !option.currentEntries?.length) ? (
                                <div
                                  className='WidgetChevron'
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleUpdateOption(
                                      option.id!,
                                      'isOpen',
                                      !option.isOpen
                                    );
                                  }}
                                >
                                  <Icon
                                    tone='base'
                                    source={
                                      !option.isOpen
                                        ? ChevronRightIcon
                                        : ChevronDownIcon
                                    }
                                  />
                                </div>
                              ) : (
                                <div style={{ minWidth: 20, height: 36 }} />
                              )}
                              <div
                                className={classNames('WidgetOptionIcon', {
                                  DruggableHovered:
                                    druggableHoverId === option.id,
                                })}
                                onMouseEnter={() =>
                                  option.isDraggable && !parentOption.isHidden
                                    ? setDruggableHoverId(option.id!)
                                    : null
                                }
                                onMouseLeave={() => setDruggableHoverId('')}
                                {...provided.dragHandleProps}
                              >
                                <Icon
                                  source={handleOptionIcon(option)}
                                  tone={option.isHidden ? 'subdued' : undefined}
                                />
                              </div>
                              <div
                                className={classNames('WidgetName', {
                                  Disabled: option.isHidden,
                                })}
                              >
                                {handleOptionName(option, parentOption)}
                              </div>
                              <div className='HideDeleteActions'>
                                {option.canBeRemoved && !parentOption.isHidden && (
                                  <div
                                    className='DeleteOptionIcon'
                                    onClick={(e) =>
                                      handleRemoveOption(
                                        e,
                                        parentOption,
                                        option.name
                                      )
                                    }
                                  >
                                    <Icon tone='base' source={DeleteIcon} />
                                  </div>
                                )}
                                {option.canBeHidden && !parentOption.isHidden && (
                                  <div
                                    className='ViewOptionIcon'
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleUpdateOption(
                                        option.id!,
                                        'isHidden',
                                        !option.isHidden
                                      );
                                    }}
                                  >
                                    <Icon
                                      tone={
                                        option.isHidden ? 'subdued' : 'base'
                                      }
                                      source={
                                        !option.isHidden ? ViewIcon : HideIcon
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <Collapsible
                            id={index.toString()}
                            key={index}
                            open={option.isOpen as boolean}
                          >
                            {renderNextOptions
                              ? renderOptions(
                                  option.options?.filter(
                                    (option: LeftMenuWidget) =>
                                      option.name !== 'general'
                                  ) || [],
                                  option
                                )
                              : null}
                          </Collapsible>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
                {showAddBlockButton(parentOption) &&
                  renderAddBlockButton(nestedLevel, parentOption)}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      );
    },
    [
      widgetsState,
      druggableHoverId,
      isLeftMenuSplited,
      openedWidget,
      onDragEnd,
      findNestedLevel,
      setLeftWidgetsMenu,
      updateLeftMenuSelection,
      handleRemoveOption,
      handleUpdateOption,
      setDruggableHoverId,
      renderAddBlockButton,
    ]
  );

  const renderWidget = useCallback(
    (widgetType: string) => {
      const widget = widgetsState.find(
        (widget) => widget.name === widgetType
      ) as LeftMenuWidget;

      const widgetStateOptions =
        isNinjaCart || isNotification ? widget.options?.[0].options : [];

      const currentOptions = isLeftMenuSplited ? widget.options : [widget];

      return currentOptions?.map((option, index) => (
        <div key={option.id}>
          <div className='OptionWrapper'>
            <div
              onClick={() =>
                setLeftWidgetsMenu(
                  updateLeftMenuSelection(widgetsState, option.id!)
                )
              }
              className={classNames('WidgetOption', {
                isSelected: option.isSelected,
              })}
            >
              <div className='WidgetOptionSide'>
                <div>
                  <Icon source={ThemeIcon} />
                </div>
                <div className='WidgetName'>
                  {i18n.translate(`${option.name}`)}
                </div>
              </div>
            </div>

            <Collapsible id={option.id!} key={option.id} open>
              {renderOptions(
                widgetStateOptions?.length
                  ? widgetStateOptions
                  : option.options || [],
                option
              )}
            </Collapsible>
          </div>
          {currentOptions.length !== index + 1 && (
            <div className='LeftMenuSplitter' />
          )}
        </div>
      ));
    },
    [
      widgetsState,
      isLeftMenuSplited,
      i18n,
      isNinjaCart,
      isNotification,
      setLeftWidgetsMenu,
      updateLeftMenuSelection,
      renderOptions,
    ]
  );

  return (
    <div className='LeftSidebar'>
      <div className='LeftSidebarHeader'>
        <div className='HeaderName'>
          {!openedWidget ? (
            <Text as='h1' fontWeight='bold'>
              {i18n.translate('Widgets')}
            </Text>
          ) : (
            <>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  setLeftWidgetsMenu(
                    updateLeftMenuSelection(widgetsState, null, true)
                  )
                }
              >
                <Icon source={ChevronLeftIcon} />
              </div>
              <Text as='h1' fontWeight='bold'>
                {i18n.translate(`${openedWidget.name}_FULLNAME`)}
              </Text>
            </>
          )}
        </div>
        {!openedWidget && (
          <div>
            <Icon tone='base' source={InfoIcon} />
          </div>
        )}
      </div>
      <Scrollable className='ScrollableContent' scrollbarWidth='thin'>
        <div className='LeftSidebarContent'>
          {(isNotification || isNinjaCart) && openedWidget && (
            <Box paddingBlock='300'>
              <SelectOptions
                selectedOption={openedWidget.currentEntries?.[0]}
                label=''
                options={
                  isNotification
                    ? notificationStateOptions
                    : ninjaCartStateOptions
                }
                onOptionSelect={(value) =>
                  handleSwitchWidgetState(openedWidget.name as string, [value])
                }
              />
            </Box>
          )}
          {openedWidget ? (
            renderWidget(openedWidget.name)
          ) : (
            <LeftSideBarWidgetList
              widgetsState={widgetsState}
              pages={pages}
              i18n={i18n}
              handleAddWidget={handleAddWidget}
              handleRemoveWidget={handleRemoveWidget}
              handleSelectWidget={handleSelectWidget}
              handleWidgetsMenuState={handleWidgetsMenuState}
            />
          )}
        </div>
      </Scrollable>

      {!isEmpty(presets) && presetsChanges && (
        <PresetChangesBadge
          presetsChanges={presetsChanges}
          presets={presets}
          handlePresetChangeData={handlePresetChangeData}
        />
      )}
    </div>
  );
};
