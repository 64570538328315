import React, { useCallback } from 'react';
import './InputControls.scss';
import { useI18n } from '@shopify/react-i18n';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import { InputControlsComponentDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { ButtonCornersStyleDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import ColorSelectorBox from 'core/components/WidgetEditor/ColorSelectorBox/ColorSelectorBox';

type InputControlsProps = {
  inputControls: InputControlsComponentDto;
  setInputControls: (data: InputControlsComponentDto) => void;
  onSystemReset?: () => void;
};

export const InputControls: React.FC<InputControlsProps> = ({
  inputControls,
  setInputControls,
  onSystemReset,
}) => {
  const [i18n] = useI18n();

  const cornersOptions = Object.values(ButtonCornersStyleDtoEnum).map(
    (value) => {
      return {
        value: value,
        label: i18n.translate(value),
      };
    }
  );

  const handleUpdateInput = useCallback(
    (
      field: keyof InputControlsComponentDto,
      data: InputControlsComponentDto[keyof InputControlsComponentDto]
    ) => setInputControls({ ...inputControls, [field]: data }),
    [inputControls, setInputControls]
  );

  return (
    <div className='InputControls'>
      <StyleComponentHeader
        label={i18n.translate(`InputControls`)}
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            onAction: () => onSystemReset?.(),
          },
        ]}
      >
        <div className='InputControlsRow'>
          {i18n.translate(`Corners`)}
          <div className='RightContent'>
            <SelectOptions
              options={cornersOptions}
              onOptionSelect={(value) =>
                handleUpdateInput('cornersStyle', value)
              }
              selectedOption={
                inputControls.cornersStyle as ButtonCornersStyleDtoEnum
              }
              label=''
            />
          </div>
        </div>
        <div className='InputControlsRow'>
          {i18n.translate(`BorderColor`)}
          <div className='RightContent'>
            <ColorSelectorBox
              color={inputControls.borderColor || ''}
              setColor={(value) => handleUpdateInput('borderColor', value)}
            />
          </div>
        </div>
        <div className='InputControlsRow'>
          {i18n.translate(`TextColor`)}
          <div className='RightContent'>
            <ColorSelectorBox
              color={inputControls.textColor || ''}
              setColor={(value) => handleUpdateInput('textColor', value)}
            />
          </div>
        </div>
        <div className='InputControlsRow'>
          {i18n.translate(`Placeholder`)}
          <div className='RightContent'>
            <ColorSelectorBox
              color={inputControls.placeholderColor || ''}
              setColor={(value) => handleUpdateInput('placeholderColor', value)}
            />
          </div>
        </div>
      </StyleComponentHeader>
    </div>
  );
};
