import React from 'react';
import {
  Card,
  BlockStack,
  Text,
  Checkbox,
  Divider,
  ChoiceList,
  Banner,
  Link,
  Bleed,
  Box,
  InlineStack,
} from '@shopify/polaris';

import { useI18n } from '@shopify/react-i18n';
import { SubscriptionProductsCheckoutIntegrationsSettingsDto } from 'core/api/adminSettings/adminSettingsApi';

type SubscriptionProductsCheckout = {
  data: SubscriptionProductsCheckoutIntegrationsSettingsDto | undefined;
  section: string;
  handleIntegrationsLocaleState: (
    section: string,
    value: string | boolean,
    field?: string
  ) => void;
};

export const SubscriptionProductsCheckout: React.FC<
  SubscriptionProductsCheckout
> = ({ data, section, handleIntegrationsLocaleState }) => {
  const [i18n] = useI18n();

  const options = [
    {
      label: i18n.translate('AllowSubscription'),
      value: 'ALLOW',
      helpText: i18n.translate('AllowSubscriptionHelpText'),
    },
    { label: i18n.translate('DoNotAllow'), value: 'NOT_ALLOW' },
  ];
  return (
    <Card roundedAbove='sm' padding='400'>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate('SubscriptionProductsCheckoutTitle')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('SubscriptionProductsCheckoutDescription')}
          </Text>
        </BlockStack>
        <Checkbox
          label={i18n.translate('AllowDiscounting')}
          checked={data?.allowDiscountingSubscriptionProducts}
          onChange={(value) =>
            handleIntegrationsLocaleState(
              section,
              value,
              'allowDiscountingSubscriptionProducts'
            )
          }
        />
        {!data?.allowDiscountingSubscriptionProducts && (
          <>
            <Divider />
            <ChoiceList
              title=''
              titleHidden
              choices={options}
              selected={[`${data?.checkoutMode}`]}
              onChange={(value) =>
                handleIntegrationsLocaleState(section, value[0], 'checkoutMode')
              }
            />
            <Banner tone='warning'>
              {i18n.translate('Tip')}{' '}
              <Link url='http://www.google.com' target='_blank'>
                {i18n.translate('TipLink')}
              </Link>
            </Banner>
            <Bleed marginInline='400' marginBlockEnd='400'>
              <Box background='bg-surface-secondary' padding='400'>
                <InlineStack gap='100'>
                  <Text as='p' tone='subdued'>
                    {i18n.translate('Footer')}
                  </Text>

                  <Link url='http://www.google.com' target='_blank'>
                    {' '}
                    {i18n.translate('FooterLink')}
                  </Link>
                </InlineStack>
              </Box>
            </Bleed>
          </>
        )}
      </BlockStack>
    </Card>
  );
};
