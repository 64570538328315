import { BlockStack, Card, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import React, { FC } from 'react';
import { ApplyInputNumber } from '../DiscountNew/components/DiscountApplySection/components/ApplyInputNumber';
import { OfferTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { NumberFormatValues } from 'react-number-format';
import { DiscountValueTypeEnum } from '../../enums/DiscountValueType';

type ShippingDiscountProps = {
    suffix: string;
    offerType: OfferTypeDtoEnum;
    textFieldValue: NumberFormatValues;
    setTextFieldValue: (data: NumberFormatValues) => void;
    activeDiscountValueType: DiscountValueTypeEnum;
};

export const BundleDiscount: FC<ShippingDiscountProps> = (props) => {
  const [i18n] = useI18n();
  const {
    suffix,
    offerType,
    textFieldValue,
    setTextFieldValue,
    activeDiscountValueType,
  } = props;

  return (
    <Card>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate('Title')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('Subtitle')}
          </Text>
        </BlockStack>

        <ApplyInputNumber
          suffix={suffix}
          offerType={offerType}
          setTextFieldValue={setTextFieldValue}
          textFieldValue={textFieldValue}
          activeDiscountValueType={activeDiscountValueType}
        />
      </BlockStack>
    </Card>
  );
};
