import {
  OfferWidgetSetupDtoPromotionCodeFieldOfferDto,
  PromotionCodeFieldPresetDtoRead,
  PromotionWidgetSetupMultiLevelDtoPromotionCodeFieldPromotionDtoPromotionCodeFieldFirstLevelPromotionDtoPromotionCodeFieldPromotionEntryTypeDtoRead,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { LineWeightTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';

export type PromotionWidgetSetupDtoPromotionCodeFieldPromotionDtoReadWithOffers =
  PromotionWidgetSetupMultiLevelDtoPromotionCodeFieldPromotionDtoPromotionCodeFieldFirstLevelPromotionDtoPromotionCodeFieldPromotionEntryTypeDtoRead & {
    offers?: OfferWidgetSetupDtoPromotionCodeFieldOfferDto[] | null;
  };

export const promotionCodeFieldMergeFunc = (
  widget: PromotionWidgetSetupDtoPromotionCodeFieldPromotionDtoReadWithOffers,
  setup?: PromotionCodeFieldPresetDtoRead
) => {
  const {
    isShopLevelWidget,
    canBeDragged,
    canBeRemoved,
    offers,
    options,
    general,
    ...newWidget
  } = widget;

  const { general: generalSetup, options: optionsSetup } =
    setup?.general && setup?.options ? setup : { general: null, options: null };

  return {
    ...newWidget,
    availableEntries: undefined,
    ...(generalSetup
      ? {
          general: {
            ...general,
            background: {
              ...generalSetup?.background,
              border: {
                ...generalSetup?.background?.border,
                value: {
                  ...generalSetup?.background?.border?.value,
                  lineWeight: {
                    ...generalSetup?.background?.border?.value?.lineWeight,
                    customValue:
                      generalSetup?.background?.border?.value?.lineWeight
                        ?.type === LineWeightTypeDtoEnum.CUSTOM
                        ? generalSetup?.background?.border?.value?.lineWeight
                            ?.customValue
                        : undefined,
                  },
                },
              },
            },
            cornerRadius: {
              ...generalSetup?.cornerRadius,
              customValue:
                generalSetup?.cornerRadius?.type ===
                LineWeightTypeDtoEnum.CUSTOM
                  ? generalSetup?.cornerRadius?.customValue
                  : undefined,
            },
            headerText: {
              ...generalSetup?.headerText,
              style: {
                ...generalSetup?.headerText?.style,
                style: {
                  ...generalSetup?.headerText?.style?.style,
                  fontSize: {
                    ...generalSetup?.headerText?.style?.style?.fontSize,
                    mobile: generalSetup?.headerText?.style?.style
                      ?.differentOnMobile
                      ? generalSetup?.headerText?.style?.style?.fontSize?.mobile
                      : undefined,
                  },
                  lineHeight: {
                    ...generalSetup?.headerText?.style?.style?.lineHeight,
                    mobile: generalSetup?.headerText?.style?.style
                      ?.differentOnMobile
                      ? generalSetup?.headerText?.style?.style?.lineHeight
                          ?.mobile
                      : undefined,
                  },
                  fontWeight: {
                    ...generalSetup?.headerText?.style?.style?.fontWeight,
                    mobile: generalSetup?.headerText?.style?.style
                      ?.differentOnMobile
                      ? generalSetup?.headerText?.style?.style?.fontWeight
                          ?.mobile
                      : undefined,
                  },
                },
              },
              message: {
                ...generalSetup?.headerText?.message,
                settings: undefined,
              },
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
            },
            headerStyle: generalSetup.headerStyle,
          },
        }
      : {
          general: {
            ...general,
            background: {
              ...general?.background,
              border: {
                ...general?.background?.border,
                value: {
                  ...general?.background?.border?.value,
                  lineWeight: {
                    ...general?.background?.border?.value?.lineWeight,
                    customValue:
                      general?.background?.border?.value?.lineWeight?.type ===
                      LineWeightTypeDtoEnum.CUSTOM
                        ? general?.background?.border?.value?.lineWeight
                            ?.customValue
                        : undefined,
                  },
                },
              },
            },
            cornerRadius: {
              ...general?.cornerRadius,
              customValue:
                general?.cornerRadius?.type === LineWeightTypeDtoEnum.CUSTOM
                  ? general?.cornerRadius?.customValue
                  : undefined,
            },
            headerText: {
              ...general?.headerText,
              style: {
                ...general?.headerText?.style,
                style: {
                  ...general?.headerText?.style?.style,
                  fontSize: {
                    ...general?.headerText?.style?.style?.fontSize,
                    mobile: general?.headerText?.style?.style?.differentOnMobile
                      ? general?.headerText?.style?.style?.fontSize?.mobile
                      : undefined,
                  },
                  lineHeight: {
                    ...general?.headerText?.style?.style?.lineHeight,
                    mobile: general?.headerText?.style?.style?.differentOnMobile
                      ? general?.headerText?.style?.style?.lineHeight?.mobile
                      : undefined,
                  },
                  fontWeight: {
                    ...general?.headerText?.style?.style?.fontWeight,
                    mobile: general?.headerText?.style?.style?.differentOnMobile
                      ? general?.headerText?.style?.style?.fontWeight?.mobile
                      : undefined,
                  },
                },
              },
              message: {
                ...general?.headerText?.message,
                settings: undefined,
              },
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
            },
          },
        }),
    ...(optionsSetup
      ? {
          options: {
            ...options,
            codeInput: {
              ...options?.codeInput,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              placeholder: {
                ...optionsSetup.codeInput?.placeholder,
                message: {
                  ...optionsSetup.codeInput?.placeholder?.message,
                  settings: undefined,
                },
                style: {
                  ...optionsSetup.codeInput?.placeholder?.style,
                  style: {
                    ...optionsSetup.codeInput?.placeholder?.style?.style,
                    fontSize: {
                      ...optionsSetup.codeInput?.placeholder?.style?.style
                        ?.fontSize,
                      mobile: optionsSetup.codeInput?.placeholder?.style?.style
                        ?.differentOnMobile
                        ? optionsSetup.codeInput?.placeholder?.style?.style
                            ?.fontSize?.mobile
                        : undefined,
                    },
                    lineHeight: {
                      ...optionsSetup.codeInput?.placeholder?.style?.style
                        ?.lineHeight,
                      mobile: optionsSetup.codeInput?.placeholder?.style?.style
                        ?.differentOnMobile
                        ? optionsSetup.codeInput?.placeholder?.style?.style
                            ?.lineHeight?.mobile
                        : undefined,
                    },
                    fontWeight: {
                      ...optionsSetup.codeInput?.placeholder?.style?.style
                        ?.fontWeight,
                      mobile: optionsSetup.codeInput?.placeholder?.style?.style
                        ?.differentOnMobile
                        ? optionsSetup.codeInput?.placeholder?.style?.style
                            ?.fontWeight?.mobile
                        : undefined,
                    },
                  },
                },
              },
            },
            codeValidation: {
              ...options?.codeValidation,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              selectedState: optionsSetup.codeValidation?.selectedState,
              codeAppliedState: {
                ...optionsSetup.codeValidation?.codeAppliedState,
                message: {
                  ...optionsSetup.codeValidation?.codeAppliedState?.message,
                  message: {
                    ...optionsSetup.codeValidation?.codeAppliedState?.message
                      ?.message,
                    settings: undefined,
                  },
                  style: {
                    ...optionsSetup.codeValidation?.codeAppliedState?.message
                      ?.style,
                    style: {
                      ...optionsSetup.codeValidation?.codeAppliedState?.message
                        ?.style?.style,
                      fontSize: {
                        ...optionsSetup.codeValidation?.codeAppliedState
                          ?.message?.style?.style?.fontSize,
                        mobile: optionsSetup.codeValidation?.codeAppliedState
                          ?.message?.style?.style?.differentOnMobile
                          ? optionsSetup.codeValidation?.codeAppliedState
                              ?.message?.style?.style?.fontSize?.mobile
                          : undefined,
                      },
                      lineHeight: {
                        ...optionsSetup.codeValidation?.codeAppliedState
                          ?.message?.style?.style?.lineHeight,
                        mobile: optionsSetup.codeValidation?.codeAppliedState
                          ?.message?.style?.style?.differentOnMobile
                          ? optionsSetup.codeValidation?.codeAppliedState
                              ?.message?.style?.style?.lineHeight?.mobile
                          : undefined,
                      },
                      fontWeight: {
                        ...optionsSetup.codeValidation?.codeAppliedState
                          ?.message?.style?.style?.fontWeight,
                        mobile: optionsSetup.codeValidation?.codeAppliedState
                          ?.message?.style?.style?.differentOnMobile
                          ? optionsSetup.codeValidation?.codeAppliedState
                              ?.message?.style?.style?.fontWeight?.mobile
                          : undefined,
                      },
                    },
                  },
                },
              },
              invalidState: {
                ...optionsSetup.codeValidation?.invalidState,
                message: {
                  ...optionsSetup.codeValidation?.invalidState?.message,
                  message: {
                    ...optionsSetup.codeValidation?.invalidState?.message
                      ?.message,
                    settings: undefined,
                  },
                  style: {
                    ...optionsSetup.codeValidation?.invalidState?.message
                      ?.style,
                    style: {
                      ...optionsSetup.codeValidation?.invalidState?.message
                        ?.style?.style,
                      fontSize: {
                        ...optionsSetup.codeValidation?.invalidState?.message
                          ?.style?.style?.fontSize,
                        mobile: optionsSetup.codeValidation?.invalidState
                          ?.message?.style?.style?.differentOnMobile
                          ? optionsSetup.codeValidation?.invalidState?.message
                              ?.style?.style?.fontSize?.mobile
                          : undefined,
                      },
                      lineHeight: {
                        ...optionsSetup.codeValidation?.invalidState?.message
                          ?.style?.style?.lineHeight,
                        mobile: optionsSetup.codeValidation?.invalidState
                          ?.message?.style?.style?.differentOnMobile
                          ? optionsSetup.codeValidation?.invalidState?.message
                              ?.style?.style?.lineHeight?.mobile
                          : undefined,
                      },
                      fontWeight: {
                        ...optionsSetup.codeValidation?.invalidState?.message
                          ?.style?.style?.fontWeight,
                        mobile: optionsSetup.codeValidation?.invalidState
                          ?.message?.style?.style?.differentOnMobile
                          ? optionsSetup.codeValidation?.invalidState?.message
                              ?.style?.style?.fontWeight?.mobile
                          : undefined,
                      },
                    },
                  },
                },
              },
              notApplicableState: {
                ...optionsSetup.codeValidation?.notApplicableState,
                message: {
                  ...optionsSetup.codeValidation?.notApplicableState?.message,
                  message: {
                    ...optionsSetup.codeValidation?.notApplicableState?.message
                      ?.message,
                    settings: undefined,
                  },
                  style: {
                    ...optionsSetup.codeValidation?.notApplicableState?.message
                      ?.style,
                    style: {
                      ...optionsSetup.codeValidation?.notApplicableState
                        ?.message?.style?.style,
                      fontSize: {
                        ...optionsSetup.codeValidation?.notApplicableState
                          ?.message?.style?.style?.fontSize,
                        mobile: optionsSetup.codeValidation?.notApplicableState
                          ?.message?.style?.style?.differentOnMobile
                          ? optionsSetup.codeValidation?.notApplicableState
                              ?.message?.style?.style?.fontSize?.mobile
                          : undefined,
                      },
                      lineHeight: {
                        ...optionsSetup.codeValidation?.notApplicableState
                          ?.message?.style?.style?.lineHeight,
                        mobile: optionsSetup.codeValidation?.notApplicableState
                          ?.message?.style?.style?.differentOnMobile
                          ? optionsSetup.codeValidation?.notApplicableState
                              ?.message?.style?.style?.lineHeight?.mobile
                          : undefined,
                      },
                      fontWeight: {
                        ...optionsSetup.codeValidation?.notApplicableState
                          ?.message?.style?.style?.fontWeight,
                        mobile: optionsSetup.codeValidation?.notApplicableState
                          ?.message?.style?.style?.differentOnMobile
                          ? optionsSetup.codeValidation?.notApplicableState
                              ?.message?.style?.style?.fontWeight?.mobile
                          : undefined,
                      },
                    },
                  },
                },
              },
            },
            callToActionButton: {
              ...optionsSetup.callToActionButton,
              label: {
                ...optionsSetup.callToActionButton?.label,
                message: {
                  ...optionsSetup.callToActionButton?.label?.message,
                  settings: undefined,
                },
                style: {
                  ...optionsSetup.callToActionButton?.label?.style,
                  style: {
                    ...optionsSetup.callToActionButton?.label?.style?.style,
                    fontSize: {
                      ...optionsSetup.callToActionButton?.label?.style?.style
                        ?.fontSize,
                      mobile: optionsSetup.callToActionButton?.label?.style
                        ?.style?.differentOnMobile
                        ? optionsSetup.callToActionButton?.label?.style?.style
                            ?.fontSize?.mobile
                        : undefined,
                    },
                    lineHeight: {
                      ...optionsSetup.callToActionButton?.label?.style?.style
                        ?.lineHeight,
                      mobile: optionsSetup.callToActionButton?.label?.style
                        ?.style?.differentOnMobile
                        ? optionsSetup.callToActionButton?.label?.style?.style
                            ?.lineHeight?.mobile
                        : undefined,
                    },
                    fontWeight: {
                      ...optionsSetup.callToActionButton?.label?.style?.style
                        ?.fontWeight,
                      mobile: optionsSetup.callToActionButton?.label?.style
                        ?.style?.differentOnMobile
                        ? optionsSetup.callToActionButton?.label?.style?.style
                            ?.fontWeight?.mobile
                        : undefined,
                    },
                  },
                },
              },
            },
          },
          offers: [],
        }
      : {
          options: {
            ...options,
            codeInput: {
              ...options?.codeInput,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              placeholder: {
                ...options?.codeInput?.placeholder,
                message: {
                  ...options?.codeInput?.placeholder?.message,
                  settings: undefined,
                },
                style: {
                  ...options?.codeInput?.placeholder?.style,
                  style: {
                    ...options?.codeInput?.placeholder?.style?.style,
                    fontSize: {
                      ...options?.codeInput?.placeholder?.style?.style
                        ?.fontSize,
                      mobile: options?.codeInput?.placeholder?.style?.style
                        ?.differentOnMobile
                        ? options?.codeInput?.placeholder?.style?.style
                            ?.fontSize?.mobile
                        : undefined,
                    },
                    lineHeight: {
                      ...options?.codeInput?.placeholder?.style?.style
                        ?.lineHeight,
                      mobile: options?.codeInput?.placeholder?.style?.style
                        ?.differentOnMobile
                        ? options?.codeInput?.placeholder?.style?.style
                            ?.lineHeight?.mobile
                        : undefined,
                    },
                    fontWeight: {
                      ...options?.codeInput?.placeholder?.style?.style
                        ?.fontWeight,
                      mobile: options?.codeInput?.placeholder?.style?.style
                        ?.differentOnMobile
                        ? options?.codeInput?.placeholder?.style?.style
                            ?.fontWeight?.mobile
                        : undefined,
                    },
                  },
                },
              },
            },
            codeValidation: {
              ...options?.codeValidation,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              selectedState: options?.codeValidation?.selectedState,
              codeAppliedState: {
                ...options?.codeValidation?.codeAppliedState,
                message: {
                  ...options?.codeValidation?.codeAppliedState?.message,
                  message: {
                    ...options?.codeValidation?.codeAppliedState?.message
                      ?.message,
                    settings: undefined,
                  },
                  style: {
                    ...options?.codeValidation?.codeAppliedState?.message
                      ?.style,
                    style: {
                      ...options?.codeValidation?.codeAppliedState?.message
                        ?.style?.style,
                      fontSize: {
                        ...options?.codeValidation?.codeAppliedState?.message
                          ?.style?.style?.fontSize,
                        mobile: options?.codeValidation?.codeAppliedState
                          ?.message?.style?.style?.differentOnMobile
                          ? options?.codeValidation?.codeAppliedState?.message
                              ?.style?.style?.fontSize?.mobile
                          : undefined,
                      },
                      lineHeight: {
                        ...options?.codeValidation?.codeAppliedState?.message
                          ?.style?.style?.lineHeight,
                        mobile: options?.codeValidation?.codeAppliedState
                          ?.message?.style?.style?.differentOnMobile
                          ? options?.codeValidation?.codeAppliedState?.message
                              ?.style?.style?.lineHeight?.mobile
                          : undefined,
                      },
                      fontWeight: {
                        ...options?.codeValidation?.codeAppliedState?.message
                          ?.style?.style?.fontWeight,
                        mobile: options?.codeValidation?.codeAppliedState
                          ?.message?.style?.style?.differentOnMobile
                          ? options?.codeValidation?.codeAppliedState?.message
                              ?.style?.style?.fontWeight?.mobile
                          : undefined,
                      },
                    },
                  },
                },
              },
              invalidState: {
                ...options?.codeValidation?.invalidState,
                message: {
                  ...options?.codeValidation?.invalidState?.message,
                  message: {
                    ...options?.codeValidation?.invalidState?.message?.message,
                    settings: undefined,
                  },
                  style: {
                    ...options?.codeValidation?.invalidState?.message?.style,
                    style: {
                      ...options?.codeValidation?.invalidState?.message?.style
                        ?.style,
                      fontSize: {
                        ...options?.codeValidation?.invalidState?.message?.style
                          ?.style?.fontSize,
                        mobile: options?.codeValidation?.invalidState?.message
                          ?.style?.style?.differentOnMobile
                          ? options?.codeValidation?.invalidState?.message
                              ?.style?.style?.fontSize?.mobile
                          : undefined,
                      },
                      lineHeight: {
                        ...options?.codeValidation?.invalidState?.message?.style
                          ?.style?.lineHeight,
                        mobile: options?.codeValidation?.invalidState?.message
                          ?.style?.style?.differentOnMobile
                          ? options?.codeValidation?.invalidState?.message
                              ?.style?.style?.lineHeight?.mobile
                          : undefined,
                      },
                      fontWeight: {
                        ...options?.codeValidation?.invalidState?.message?.style
                          ?.style?.fontWeight,
                        mobile: options?.codeValidation?.invalidState?.message
                          ?.style?.style?.differentOnMobile
                          ? options?.codeValidation?.invalidState?.message
                              ?.style?.style?.fontWeight?.mobile
                          : undefined,
                      },
                    },
                  },
                },
              },
              notApplicableState: {
                ...options?.codeValidation?.notApplicableState,
                message: {
                  ...options?.codeValidation?.notApplicableState?.message,
                  message: {
                    ...options?.codeValidation?.notApplicableState?.message
                      ?.message,
                    settings: undefined,
                  },
                  style: {
                    ...options?.codeValidation?.notApplicableState?.message
                      ?.style,
                    style: {
                      ...options?.codeValidation?.notApplicableState?.message
                        ?.style?.style,
                      fontSize: {
                        ...options?.codeValidation?.notApplicableState?.message
                          ?.style?.style?.fontSize,
                        mobile: options?.codeValidation?.notApplicableState
                          ?.message?.style?.style?.differentOnMobile
                          ? options?.codeValidation?.notApplicableState?.message
                              ?.style?.style?.fontSize?.mobile
                          : undefined,
                      },
                      lineHeight: {
                        ...options?.codeValidation?.notApplicableState?.message
                          ?.style?.style?.lineHeight,
                        mobile: options?.codeValidation?.notApplicableState
                          ?.message?.style?.style?.differentOnMobile
                          ? options?.codeValidation?.notApplicableState?.message
                              ?.style?.style?.lineHeight?.mobile
                          : undefined,
                      },
                      fontWeight: {
                        ...options?.codeValidation?.notApplicableState?.message
                          ?.style?.style?.fontWeight,
                        mobile: options?.codeValidation?.notApplicableState
                          ?.message?.style?.style?.differentOnMobile
                          ? options?.codeValidation?.notApplicableState?.message
                              ?.style?.style?.fontWeight?.mobile
                          : undefined,
                      },
                    },
                  },
                },
              },
            },
            callToActionButton: {
              ...options?.callToActionButton,
              label: {
                ...options?.callToActionButton?.label,
                message: {
                  ...options?.callToActionButton?.label?.message,
                  settings: undefined,
                },
                style: {
                  ...options?.callToActionButton?.label?.style,
                  style: {
                    ...options?.callToActionButton?.label?.style?.style,
                    fontSize: {
                      ...options?.callToActionButton?.label?.style?.style
                        ?.fontSize,
                      mobile: options?.callToActionButton?.label?.style?.style
                        ?.differentOnMobile
                        ? options?.callToActionButton?.label?.style?.style
                            ?.fontSize?.mobile
                        : undefined,
                    },
                    lineHeight: {
                      ...options?.callToActionButton?.label?.style?.style
                        ?.lineHeight,
                      mobile: options?.callToActionButton?.label?.style?.style
                        ?.differentOnMobile
                        ? options?.callToActionButton?.label?.style?.style
                            ?.lineHeight?.mobile
                        : undefined,
                    },
                    fontWeight: {
                      ...options?.callToActionButton?.label?.style?.style
                        ?.fontWeight,
                      mobile: options?.callToActionButton?.label?.style?.style
                        ?.differentOnMobile
                        ? options?.callToActionButton?.label?.style?.style
                            ?.fontWeight?.mobile
                        : undefined,
                    },
                  },
                },
              },
            },
          },
        }),
  };
};
