import React, { useCallback, useMemo } from 'react';
import {
  BlockStack,
  Button,
  Card,
  InlineStack,
  Text,
  List,
  Box,
} from '@shopify/polaris';
import { PromotionOverviewSettingsDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { useI18n } from '@shopify/react-i18n';
import { TriggerCountdownClockEndModeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { useNavigate } from 'react-router-dom';
import { EditIcon } from '@shopify/polaris-icons';
import { CopyIcon } from 'core/components';

type PromotionSettingsProps = {
  settings: PromotionOverviewSettingsDto;
  isPromotionEditable: boolean;
};
export const PromotionSettings: React.FC<PromotionSettingsProps> = ({
  settings,
  isPromotionEditable,
}) => {
  const [i18n] = useI18n();
  const navigate = useNavigate();

  const getFixedAmountTime = useCallback(() => {
    const { days, hours, minutes } =
      settings.countdownClock?.fixedClockTime || {};
    switch (true) {
      case !!days:
        return days + i18n.translate(days === 1 ? 'Day' : 'Days');
      case !!hours:
        return hours + i18n.translate(hours === 1 ? 'Hour' : 'Hours');
      case !!minutes:
        return minutes + i18n.translate(minutes === 1 ? 'Minute' : 'Minutes');
    }
  }, [settings.countdownClock?.fixedClockTime, i18n]);

  const showBudget = useMemo(
    () =>
      settings.maximumUses?.limitedUseByAll?.enabled ||
      settings.maximumUses?.limitOfCode?.enabled ||
      settings.maximumUses?.orderCap?.enabled ||
      settings.maximumUses?.limitedUsePerCustomerEnabled,
    [settings.maximumUses]
  );

  const truncateLink = useCallback((link: string): string => {
    const tokenPosition = link.indexOf('?token=');
    if (tokenPosition === -1) {
      return link;
    }
    const baseUrl = link.substring(0, 20);
    const tokenPart = link.substring(tokenPosition);
    return `${baseUrl}...${tokenPart}`;
  }, []);

  return (
    <div className='PromotionSetting'>
      <Card>
        <BlockStack gap='400'>
          <InlineStack align='space-between' blockAlign='center'>
            <Text as='h2' fontWeight='semibold'>
              {i18n.translate('Settings')}
            </Text>
            <Button
              variant='tertiary'
              onClick={() => navigate('settings')}
              disabled={!isPromotionEditable}
              icon={EditIcon}
            />
          </InlineStack>
          <BlockStack gap='200'>
            <Text as='h2' fontWeight='medium'>
              {i18n.translate('Trigger')}
            </Text>
            <List type='bullet'>
              <List.Item>{i18n.translate(`${settings.triggerType}`)}</List.Item>
            </List>
          </BlockStack>
          {settings.discountLink && (
            <BlockStack gap='100'>
              <Text as='p' fontWeight='medium'>
                {i18n.translate('DiscountLink')}
              </Text>
              <Box
                paddingBlock='150'
                paddingInline='300'
                borderRadius='200'
                background='bg-surface-disabled'
              >
                <InlineStack
                  align='space-between'
                  gap='100'
                  blockAlign='center'
                  wrap={false}
                >
                  <Text as='p' tone='subdued' truncate>
                    {truncateLink(settings.discountLink.display as string)}
                  </Text>

                  <CopyIcon copyText={settings.discountLink?.copy} />
                </InlineStack>
              </Box>
            </BlockStack>
          )}
          {!!settings.promotionCodes?.count && (
            <BlockStack gap='100'>
              <Text as='p' fontWeight='medium'>
                {i18n.translate('PromotionCodes')}
              </Text>
              {settings.promotionCodes?.count === 1 ? (
                <Box
                  paddingBlock='150'
                  paddingInline='300'
                  borderRadius='200'
                  background='bg-surface-disabled'
                >
                  <InlineStack
                    gap='100'
                    blockAlign='center'
                    align='space-between'
                    wrap={false}
                  >
                    <Text as='p' tone='subdued' truncate>
                      {settings.promotionCodes.singleCode}
                    </Text>
                    <CopyIcon copyText={settings?.promotionCodes?.singleCode} />
                  </InlineStack>
                </Box>
              ) : (
                <List type='bullet'>
                  <List.Item>
                    {i18n.translate('CodesGenerated', {
                      count: settings?.promotionCodes?.count,
                    })}
                  </List.Item>
                </List>
              )}
            </BlockStack>
          )}
          <BlockStack gap='200'>
            <Text as='h2' fontWeight='medium'>
              {i18n.translate('SalesChannel')}
            </Text>
            <List type='bullet'>
              <List.Item>
                {i18n.translate(`${settings.salesChannel}`)}
              </List.Item>
            </List>
          </BlockStack>
          {settings.intendedAudienceFilter && (
            <BlockStack gap='200'>
              <Text as='h2' fontWeight='medium'>
                {i18n.translate('Audience')}
              </Text>
              <List type='bullet'>
                <List.Item>
                  {i18n.translate(`${settings.intendedAudienceFilter}`)}
                </List.Item>
              </List>
            </BlockStack>
          )}

          {showBudget && (
            <BlockStack gap='200'>
              <Text as='h2' fontWeight='medium'>
                {i18n.translate('Budget')}
              </Text>
              <List type='bullet'>
                {settings.maximumUses?.limitedUseByAll?.enabled && (
                  <List.Item>
                    {i18n.translate('LimitManyTimes', {
                      ordersCount: settings.maximumUses?.limitedUseByAll.value,
                    })}
                  </List.Item>
                )}
                {settings.maximumUses?.limitOfCode?.enabled && (
                  <List.Item>
                    {i18n.translate('LimitPerCode', {
                      ordersCount:
                        settings.maximumUses?.limitOfCode.value?.limit,
                    })}
                  </List.Item>
                )}
                {settings.maximumUses?.orderCap?.enabled && (
                  <List.Item>
                    {i18n.translate('CapCount', {
                      capCount: settings.maximumUses?.orderCap.value,
                    })}
                  </List.Item>
                )}
                {settings.maximumUses?.limitedUsePerCustomerEnabled && (
                  <List.Item>{i18n.translate('LimitToOne')}</List.Item>
                )}
              </List>
            </BlockStack>
          )}

          {settings.exclusivityFilter && (
            <BlockStack gap='200'>
              <Text as='h2' fontWeight='medium'>
                {i18n.translate('ExclusivityFilter')}
              </Text>
              <List type='bullet'>
                <List.Item>
                  {i18n.translate('Priority', {
                    priority: settings.exclusivityFilter,
                  })}
                </List.Item>
              </List>
            </BlockStack>
          )}
          {settings.geolocationFilter && (
            <BlockStack gap='200'>
              <Text as='h2' fontWeight='medium'>
                {i18n.translate('GeolocationFilter')}
              </Text>
              <List type='bullet'>
                <List.Item>
                  {i18n.translate(`${settings.geolocationFilter}_GEO`)}
                </List.Item>
              </List>
            </BlockStack>
          )}
          {settings.referrerFilter && (
            <BlockStack gap='200'>
              <Text as='h2' fontWeight='medium'>
                {i18n.translate('ReferrerFilter')}
              </Text>
              <List type='bullet'>
                <List.Item>
                  {i18n.translate(`${settings.referrerFilter}_REF`)}
                </List.Item>
              </List>
            </BlockStack>
          )}
          {settings.countdownClock?.mode && (
            <BlockStack gap='200'>
              <Text as='h2' fontWeight='medium'>
                {i18n.translate('CountdownClock')}
              </Text>
              <List type='bullet'>
                <List.Item>
                  {settings.countdownClock?.mode ===
                  TriggerCountdownClockEndModeDtoEnum.PROMOTION_END
                    ? i18n.translate(`RunUntilEnds`)
                    : i18n.translate('FixedAmount', {
                        time: getFixedAmountTime(),
                      })}
                </List.Item>
              </List>
            </BlockStack>
          )}
        </BlockStack>
      </Card>
    </div>
  );
};
