import {
  Badge,
  BlockStack,
  Button,
  Card,
  Icon,
  InlineStack,
  Text,
} from '@shopify/polaris';
import React, { useCallback } from 'react';
import { ChevronRightIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import './CardsNavigation.scss';
import { useSearchParams } from 'react-router-dom';

type CardsNavigation = { data: CardsNavigationProps | undefined };

export type CardsNavigationProps = {
  title: string;
  subtitle: string;
  newLinks: boolean;
  items: ItemProps[];
};

type ItemProps = {
  name: string;
  enabled: boolean;
  tab?: string;
  helpText?: string;
};

export const CardsNavigation: React.FC<CardsNavigation> = ({ data }) => {
  const [i18n] = useI18n();
  const [searchParams, setSearchParams] = useSearchParams();

  const onCardClick = useCallback(
    (tabPath: string) => {
      searchParams.set('tab', tabPath);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  return (
    <div className='CardsNavigationStyles'>
      <Card roundedAbove='sm' padding='400'>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {data?.title}
            </Text>
            <Text as='p' tone='subdued'>
              {data?.subtitle}
            </Text>
          </BlockStack>
          <BlockStack gap='200'>
            {data?.items.map((item) =>
              data.newLinks ? (
                <div
                  key={item.name}
                  onClick={() => onCardClick(item.tab || '')}
                  className='CardNavigation'
                >
                  <InlineStack align='space-between'>
                    <Text as='p'>{item.name}</Text>
                    <InlineStack gap='200'>
                      <Badge tone={item.enabled ? 'success' : undefined}>
                        {i18n.translate(!item.enabled ? 'Off' : 'On')}
                      </Badge>
                      <Icon source={ChevronRightIcon} />
                    </InlineStack>
                  </InlineStack>
                </div>
              ) : (
                <Card key={item.name}>
                  <BlockStack gap='200'>
                    <InlineStack align='space-between'>
                      <InlineStack gap='200'>
                        <Text as='p'>{item.name}</Text>
                        <Badge tone={item.enabled ? 'success' : undefined}>
                          {i18n.translate(!item.enabled ? 'Off' : 'On')}
                        </Badge>
                      </InlineStack>
                      <Button>
                        {i18n.translate(item.enabled ? 'Off' : 'On')}
                      </Button>
                    </InlineStack>
                    <Text as='p' tone='subdued'>
                      {item?.helpText}
                    </Text>
                  </BlockStack>
                </Card>
              )
            )}
          </BlockStack>
        </BlockStack>
      </Card>
    </div>
  );
};
