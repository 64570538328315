import { Loader } from 'core/components/Loader/Loader';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import {
  WidgetsFetchTypeEnum,
  useConfigureWidgets,
} from 'features/settings/hooks/useConfigureWidgets';
import './FontSelector.scss';
import React, { useEffect, useMemo } from 'react';
import { setToastMessages } from 'core/store/offersWizardSlice';
import { useAppDispatch } from 'core/hooks';
import { useI18n } from '@shopify/react-i18n';
type FontSelectorProps = {
  fontId: string;
  setFontId: (value: string) => void;
  disabled?: boolean;
};
const FontSelector: React.FC<FontSelectorProps> = ({
  setFontId,
  fontId,
  disabled,
}) => {
  const dispatch = useAppDispatch();
  const [i18n] = useI18n();
  const { fontListData } = useConfigureWidgets(
    WidgetsFetchTypeEnum.PRESET_FONT_LIST
  );

  const fontListOptions = useMemo(() => {
    return fontListData?.map((el: any) => ({
      value: el.id?.toString() as string,
      label: el.name as string,
    }));
  }, [fontListData]);

  const loadFont = async (id: string) => {
    const currentFont = fontListData?.find((item) => item.id === id);
    try {
      if ((document as any).fonts.check(`1em ${currentFont?.name}`)) {
        return;
      } else {
        await fetch(currentFont?.url || '')
          .then((response) => response.text())
          .then((css) => {
            const style = document.createElement('style');
            style.innerHTML = css;
            document.head.appendChild(style);
          })
          .catch(() => {
            dispatch(
              setToastMessages({
                error: true,
                message: 'FontsLoadFailed',
              })
            );
          });
      }
    } catch (error) {
      dispatch(
        setToastMessages({
          error: true,
          message: 'FontFailedToLoad',
        })
      );
    }
  };

  return (
    <div className='FontSelector'>
      {fontListOptions?.length && fontListData ? (
        <SelectOptions
          disabled={disabled}
          options={fontListOptions}
          onOptionSelect={(value) => {
            setFontId(value);
            loadFont(value);
          }}
          selectedOption={fontId}
          placeholder={i18n.translate('SelectAFont')}
          label=''
        />
      ) : (
        <div className='Loader'>
          <Loader size='small' />
        </div>
      )}
    </div>
  );
};

export default FontSelector;
