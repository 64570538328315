import React, { useCallback, useMemo, useState } from 'react';
import './TextRow.scss';
import { Button, Checkbox, Icon, Popover, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { DesktopIcon, MobileIcon, XIcon } from '@shopify/polaris-icons';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import MobileDesktopIcon from '../../assets/MobileDesktop.svg';
import MobileDesktopDisabledIcon from '../../assets/MobileDesktopDisabled.svg';
import classNames from 'classnames';
import { TextStyleDto } from 'core/api/adminWidgets/adminWidgetsApi';
export type TextRowProps = {
  text: TextStyleDto;
  setText: (value: TextStyleDto) => void;
  disabled?: boolean;
};

const TextRow: React.FC<TextRowProps> = ({ text, setText, disabled }) => {
  const [i18n] = useI18n();
  const [activePopover, setActivePopover] = useState<boolean>(false);

  const fontWeightOptions = useMemo(
    () =>
      Array.from({ length: 9 }, (_, index) => (index + 1) * 100).map(
        (value) => ({
          value: value.toString(),
          label: value.toString(),
        })
      ),
    [i18n]
  );
  const fontSizeOptions = useMemo(
    () =>
      [10, 11, 12, 13, 14, 15, 16, 20, 24, 32, 36, 40, 48, 64, 96, 128].map(
        (value) => ({
          value: value.toString(),
          label: value.toString(),
        })
      ),
    []
  );

  const lineHeightOptions = useMemo(
    () =>
      [
        'Auto',
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        20,
        24,
        32,
        36,
        40,
        48,
        64,
        96,
        128,
      ].map((value) => ({
        value: value.toString(),
        label: value.toString(),
      })),
    []
  );
  const deviceIcon = useMemo(
    () =>
      text.differentOnMobile ? (
        <Icon tone={disabled ? 'subdued' : 'base'} source={DesktopIcon} />
      ) : (
        <img
          src={disabled ? MobileDesktopDisabledIcon : MobileDesktopIcon}
          alt='mobile-desktop'
        />
      ),
    [text.differentOnMobile, disabled]
  );

  const updateText = useCallback(
    (field: keyof TextStyleDto, data: TextStyleDto[keyof TextStyleDto]) =>
      setText({
        ...text,
        [field]: data,
      }),
    [setText, text]
  );

  return (
    <div className='TextRow'>
      <Popover
        active={activePopover}
        activator={
          <div
            onClick={() => (!disabled ? setActivePopover(true) : null)}
            className={classNames('TextStylesBox', {
              isDisabled: disabled,
            })}
          >
            <div className='TextProperties'>
              {deviceIcon}
              <Text as='p'>
                {text.fontSize?.desktop} <span>/</span>{' '}
                {text.lineHeight?.desktop} <span>/</span>{' '}
                {text.fontWeight?.desktop}
              </Text>
            </div>
            {text.differentOnMobile && (
              <div className='TextProperties'>
                <Icon
                  tone={disabled ? 'subdued' : 'base'}
                  source={MobileIcon}
                />
                <Text as='p'>
                  {text.fontSize?.mobile} <span>/</span>{' '}
                  {text.lineHeight?.mobile} <span>/</span>{' '}
                  {text.fontWeight?.mobile}
                </Text>
              </div>
            )}
          </div>
        }
        autofocusTarget='first-node'
        onClose={() => {
          setActivePopover(false);
        }}
      >
        <div>
          <div className='EditWidgetText'>
            <div className='EditTextHeader'>
              <Text as='p' fontWeight='semibold'>
                {i18n.translate(`Text`)}
              </Text>
              <Button onClick={() => setActivePopover(false)} icon={XIcon} />
            </div>
            <div className='EditTextContent'>
              <Checkbox
                label={i18n.translate(`DifferentStyle`)}
                checked={text.differentOnMobile || false}
                onChange={(value) => {
                  setText({
                    ...text,
                    differentOnMobile: value,
                    fontSize: {
                      ...text.fontSize,
                      mobile: text.fontSize?.mobile || 14,
                    },
                    fontWeight: {
                      ...text.fontWeight,
                      mobile: text.fontWeight?.mobile || 400,
                    },
                    lineHeight: {
                      ...text.lineHeight,
                      mobile: text.lineHeight?.mobile || '20',
                    },
                  });
                }}
              />
              <div className='SelectorRow'>
                <div className='SelectorLabel'>
                  {i18n.translate(`FontSize`)}
                </div>
                <div className='Selectors'>
                  <div className='SelectorBox'>
                    {deviceIcon}
                    <SelectOptions
                      options={fontSizeOptions}
                      onOptionSelect={(value) => {
                        updateText('fontSize', {
                          ...text.fontSize,
                          desktop: +value,
                        });
                      }}
                      min={1}
                      max={128}
                      allowManual={true}
                      selectedOption={
                        text.fontSize?.desktop?.toString() || '14'
                      }
                      label=''
                    />
                  </div>
                  {text.differentOnMobile && (
                    <div className='SelectorBox'>
                      <Icon tone='base' source={MobileIcon} />
                      <SelectOptions
                        options={fontSizeOptions}
                        onOptionSelect={(value) => {
                          updateText('fontSize', {
                            ...text.fontSize,
                            mobile: +value,
                          });
                        }}
                        min={1}
                        max={128}
                        allowManual={true}
                        selectedOption={
                          text.fontSize?.mobile?.toString() || '14'
                        }
                        label=''
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className='SelectorRow'>
                <div className='SelectorLabel'>
                  {i18n.translate(`LineHeight`)}
                </div>
                <div className='Selectors'>
                  <div className='SelectorBox'>
                    {deviceIcon}
                    <SelectOptions
                      options={lineHeightOptions}
                      onOptionSelect={(value) => {
                        updateText('lineHeight', {
                          ...text.lineHeight,
                          desktop: value === 'Auto' ? 'Auto' : value,
                        });
                      }}
                      min={1}
                      max={128}
                      allowManual={
                        text.lineHeight?.desktop === 'Auto' ? false : true
                      }
                      selectedOption={text.lineHeight?.desktop || '20'}
                      label=''
                    />
                  </div>
                  {text.differentOnMobile && (
                    <div className='SelectorBox'>
                      <Icon tone='base' source={MobileIcon} />
                      <SelectOptions
                        options={lineHeightOptions}
                        onOptionSelect={(value) => {
                          updateText('lineHeight', {
                            ...text.lineHeight,
                            mobile: value === 'Auto' ? 'Auto' : value,
                          });
                        }}
                        min={1}
                        max={128}
                        allowManual={
                          text.lineHeight?.mobile === 'Auto' ? false : true
                        }
                        selectedOption={text.lineHeight?.mobile || '20'}
                        label=''
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className='SelectorRow'>
                <div className='SelectorLabel'>
                  {i18n.translate(`FontWeight`)}
                </div>
                <div className='Selectors'>
                  <div className='SelectorBox'>
                    {deviceIcon}
                    <SelectOptions
                      options={fontWeightOptions}
                      onOptionSelect={(value) => {
                        updateText('fontWeight', {
                          ...text.fontWeight,
                          desktop: +value,
                        });
                      }}
                      min={100}
                      max={900}
                      allowManual={true}
                      selectedOption={
                        text.fontWeight?.desktop?.toString() || '400'
                      }
                      label=''
                    />
                  </div>
                  {text.differentOnMobile && (
                    <div className='SelectorBox'>
                      <Icon tone='base' source={MobileIcon} />
                      <SelectOptions
                        options={fontWeightOptions}
                        onOptionSelect={(value) => {
                          updateText('fontWeight', {
                            ...text.fontWeight,
                            mobile: +value,
                          });
                        }}
                        min={100}
                        max={900}
                        allowManual={true}
                        selectedOption={
                          text.fontWeight?.mobile?.toString() || '400'
                        }
                        label=''
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default TextRow;
