import React, { useState, useMemo } from 'react';
import { Editor } from '@tiptap/react';
import { Popover } from '@shopify/polaris';
import { ToolbarButton } from '../ToolbarButton';
import '../RichTextEditor.scss';
import { textAlignCenterToolbar, textAlignLeftToolbar, textAlignRightToolbar } from '../assets/iconsToolbar';
import { PlatformEnum } from '../RichTextEditor';
import { useI18n } from '@shopify/react-i18n';

export const TextAlignement: React.FC<{
    editor: Editor;
    disabled: boolean;
    platform: PlatformEnum;
}> = (props) => {
    const { editor, disabled, platform } = props;

    const [i18n] = useI18n();

    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

    const currentIcon = useMemo(() =>
        editor.isActive({ textAlign: 'center' })
            ? textAlignCenterToolbar(
                editor.isActive({ textAlign: 'center' })
            )
            : editor.isActive({ textAlign: 'right' })
                ? textAlignRightToolbar(
                    editor.isActive({ textAlign: 'right' })
                )
                : textAlignLeftToolbar(
                    editor.isActive({ textAlign: 'left' })
                )
        , [editor.isActive({ textAlign: 'center' }), editor.isActive({ textAlign: 'right' }), editor.isActive({ textAlign: 'left' })])

    const activator = useMemo(
        () => (
            <ToolbarButton
                name='text-align'
                icon={currentIcon}
                active={editor.isActive({ textAlign: 'left' }) || editor.isActive({ textAlign: 'right' }) || editor.isActive({ textAlign: 'center' })}
                onClick={() =>
                    setIsDropdownOpen(prev => !prev)
                }
                disabled={disabled}
            />
        ),
        [disabled, currentIcon, editor, disabled]
    );

    return (
        <Popover
            active={isDropdownOpen}
            activator={activator}
            onClose={() => setIsDropdownOpen(prev => !prev)}
            preferredPosition='above'
        >
            <Popover.Pane>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <ToolbarButton
                        name='text-align-option'
                        icon={textAlignLeftToolbar(
                            editor.isActive({ textAlign: 'left' })
                        )}
                        active={editor.isActive({ textAlign: 'left' })}
                        onClick={() =>
                            editor.chain().focus().setTextAlign('left').run()
                        }
                        disabled={disabled}
                        tooltipContent={`${i18n?.translate('AlignLeft')} ${platform === PlatformEnum.IOS
                            ? '(⌘+Shift+L)'
                            : '(Ctrl+Shift+L)'
                            }`}
                    />
                    <ToolbarButton
                        name='text-align-option'
                        icon={textAlignCenterToolbar(
                            editor.isActive({ textAlign: 'center' })
                        )}
                        active={editor.isActive({ textAlign: 'center' })}
                        onClick={() =>
                            editor.chain().focus().setTextAlign('center').run()
                        }
                        disabled={disabled}
                        tooltipContent={`${i18n?.translate('AlignCenter')} ${platform === PlatformEnum.IOS
                            ? '(⌘+Shift+E)'
                            : '(Ctrl+Shift+E)'
                            }`}
                    />
                    <ToolbarButton
                        name='text-align-option'
                        icon={textAlignRightToolbar(
                            editor.isActive({ textAlign: 'right' })
                        )}
                        active={editor.isActive({ textAlign: 'right' })}
                        onClick={() =>
                            editor.chain().focus().setTextAlign('right').run()
                        }
                        disabled={disabled}
                        tooltipContent={`${i18n?.translate('AlignRight')} ${platform === PlatformEnum.IOS
                            ? '(⌘+Shift+R)'
                            : '(Ctrl+Shift+R)'
                            }`}
                    />
                </div>
            </Popover.Pane>
        </Popover>
    );

}