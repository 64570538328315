import React, { useCallback, useMemo, useState } from 'react';
import {
  BlockStack,
  Box,
  Card,
  Collapsible,
  Icon,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { ChevronDownIcon, ChevronUpIcon } from '@shopify/polaris-icons';
import './FrequentlyQuestions.scss';

export const FrequentlyQuestions: React.FC = () => {
  const [i18n] = useI18n();
  const [questionsOpened, setQuestionsOpened] = useState<string[]>([]);
  const generalQuestions = useMemo(
    () => Array.from({ length: 6 }, (_, i) => `GeneralQuestion${i + 1}`),
    []
  );
  const setupQuestions = useMemo(
    () => Array.from({ length: 3 }, (_, i) => `SetupQuestion${i + 1}`),
    []
  );

  const handleQuestionsOpened = useCallback(
    (question: string) => {
      setQuestionsOpened(
        questionsOpened.includes(question)
          ? questionsOpened.filter((item) => item !== question)
          : [...questionsOpened, question]
      );
    },
    [questionsOpened]
  );
  const renderQuestionList = useCallback(
    (questions: string[]) => {
      return questions.map((question, index) => {
        const isOpen = questionsOpened.includes(question);
        return (
          <div
            key={question}
            className='QuestionRow'
            onClick={() => handleQuestionsOpened(question)}
          >
            <Box
              borderBlockEndWidth={index + 1 === questions.length ? '0' : '025'}
              borderColor='border'
              paddingBlock='300'
              paddingInline='400'
            >
              <BlockStack gap='400'>
                <InlineStack align='space-between' blockAlign='center'>
                  <Text as='p' fontWeight='medium'>
                    {i18n.translate(`${question}_Title`)}
                  </Text>
                  <Box>
                    <Icon source={isOpen ? ChevronUpIcon : ChevronDownIcon} />
                  </Box>
                </InlineStack>
                <Collapsible open={isOpen} id={question}>
                  <Text as='p' tone='subdued'>
                    {i18n.translate(`${question}_Subtitle`)}
                  </Text>
                </Collapsible>
              </BlockStack>
            </Box>
          </div>
        );
      });
    },
    [questionsOpened]
  );

  return (
    <div className='FrequentlyQuestions'>
      <BlockStack gap='500'>
        <Text variant='headingLg' as='h1'>
          {i18n.translate('FrequentlyAskedQuestions')}
        </Text>
        <BlockStack gap='200'>
          <Text as='p'>{i18n.translate('General')}</Text>
          <Card padding='0'>{renderQuestionList(generalQuestions)}</Card>
        </BlockStack>
        <BlockStack gap='200'>
          <Text as='p'>{i18n.translate('AppSetup')}</Text>
          <Card padding='0'>{renderQuestionList(setupQuestions)}</Card>
        </BlockStack>
      </BlockStack>
    </div>
  );
};
