import React, { useCallback } from 'react';
import {
  MessageStyleComponentDto,
  ProductBannerMessageOfferDto,
  ProductBannerMessagePresetDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { TextStyle, TextEntries } from '../../../../../components';
import { useAppDispatch } from 'core/hooks';
import { setProductBannerMessages } from 'core/store/widgetsSlice';
import { isEqual } from 'lodash';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';

type ProductBannerMessageProps = {
  message: ProductBannerMessageOfferDto;
  offerId?: string;
  defaultMessage: ProductBannerMessageOfferDto;
  defaultLanguage?: string;
  shopDefaultMessage: MessageStyleComponentDto;
  offerType?: 'message' | 'entitled' | 'prerequisite';
  isPresetEditor?: boolean;
  presetSetup?: ProductBannerMessagePresetDto;
  setMessage: (data: ProductBannerMessageOfferDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const ProductBannerMessage: React.FC<ProductBannerMessageProps> = ({
  message,
  offerId,
  defaultMessage,
  defaultLanguage,
  shopDefaultMessage,
  offerType,
  isPresetEditor,
  presetSetup,
  setMessage,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const dispatch = useAppDispatch();
  const resetAllToSystemDefault = useCallback(() => {
    setMessage({
      ...message,
      style: shopDefaultMessage,
      message: defaultMessage?.message,
    });
  }, [shopDefaultMessage, defaultMessage, message]);

  const updateMessageState = useCallback(
    (
      field: keyof ProductBannerMessageOfferDto,
      data: ProductBannerMessageOfferDto[keyof ProductBannerMessageOfferDto]
    ) => {
      setMessage({
        ...message,
        ...(!isPresetEditor && { ...message, style: presetSetup?.style }),
        [field]: data,
      });
    },
    [message, setMessage, presetSetup]
  );

  return (
    <div className='ProductBannerMessage'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={() => setMessage(defaultMessage)}
        onResetToSystem={resetAllToSystemDefault}
        name={WidgetListOptionsDtoEnum.MESSAGE}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        {!isPresetEditor && offerType && (
          <TextEntries
            inherited={isEqual(presetSetup?.style, defaultMessage.style)}
            disableCollapsed={true}
            disableColor={
              ((!isPresetEditor ? presetSetup?.style : message.style) || {})
                .color !== undefined
            }
            textMessage={message.message || {}}
            defaultLanguage={defaultLanguage || ''}
            offerId={offerId}
            setTextMessage={(data) => updateMessageState('message', data)}
            setPreviewEntry={(entry) =>
              dispatch(
                setProductBannerMessages({
                  data: { ...entry, offerId: offerId as string },
                  field: offerType,
                })
              )
            }
            onSystemReset={() =>
              updateMessageState('message', defaultMessage.message)
            }
          />
        )}

        <TextStyle
          setTextStyle={(data) => updateMessageState('style', data)}
          hideHeader={!isPresetEditor}
          textStyle={
            (!isPresetEditor ? presetSetup?.style : message.style) || {}
          }
          offersNote={isPresetEditor}
          onSystemReset={() => updateMessageState('style', shopDefaultMessage)}
        />
      </div>
    </div>
  );
};

export default ProductBannerMessage;
