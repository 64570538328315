import React, { useCallback, useMemo } from 'react';
import {
  AnnouncementBarMessageOfferDto,
  AnnouncementBarMessagePresetDto,
  MessageStyleComponentDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { TextEntries, TextStyle } from '../../../../../components';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useAppDispatch } from 'core/hooks';
import {
  setAnnouncementBarPreLaunchMessages,
  setAnnouncementBarTextMessages,
} from 'core/store/widgetsSlice';
import { useI18n } from '@shopify/react-i18n';
import { isEqual } from 'lodash';

type AnnouncementBarMessageProps = {
  message: AnnouncementBarMessageOfferDto;
  defaultMessage: AnnouncementBarMessageOfferDto;
  shopDefaultMessage: MessageStyleComponentDto;
  defaultLanguage?: string;
  offerId?: string;
  isPresetEditor?: boolean;
  presetSetup?: AnnouncementBarMessagePresetDto;
  defaultPresetSetup?: AnnouncementBarMessagePresetDto;
  setMessage: (data: AnnouncementBarMessageOfferDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const AnnouncementBarMessage: React.FC<AnnouncementBarMessageProps> = ({
  message,
  defaultMessage,
  shopDefaultMessage,
  defaultLanguage,
  offerId,
  presetSetup,
  defaultPresetSetup,
  isPresetEditor,
  setMessage,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const dispatch = useAppDispatch();
  const [i18n] = useI18n();

  const textStyle = useMemo(
    () => (!isPresetEditor ? presetSetup?.style : message.style) || {},
    [presetSetup, message, isPresetEditor]
  );

  const handleUpdateMessage = useCallback(
    (
      field: keyof AnnouncementBarMessageOfferDto,
      data: AnnouncementBarMessageOfferDto[keyof AnnouncementBarMessageOfferDto]
    ) => {
      setMessage({
        ...message,
        ...(!isPresetEditor && { style: presetSetup?.style }),
        [field]: data,
      });
    },
    [message, presetSetup, setMessage]
  );

  const resetAllToSystemDefault = useCallback(() => {
    setMessage({
      ...message,
      style: shopDefaultMessage,
      ...(!isPresetEditor
        ? {
            preLaunchText: defaultMessage.preLaunchText,
            text: defaultMessage.text,
          }
        : {}),
    });
  }, [message, shopDefaultMessage, defaultMessage, isPresetEditor]);

  const onDiscardChanges = useCallback(() => {
    setMessage(
      !isPresetEditor
        ? {
            ...defaultMessage,
            style: defaultPresetSetup?.style,
          }
        : defaultMessage
    );
  }, [defaultPresetSetup, defaultMessage, isPresetEditor]);

  return (
    <div className='AnnouncementBarMessage'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={onDiscardChanges}
        onResetToSystem={resetAllToSystemDefault}
        name={WidgetListOptionsDtoEnum.MESSAGE}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      {!isPresetEditor && (
        <>
          <div className='RightSideSection'>
            <TextEntries
              textMessage={message.preLaunchText?.value || {}}
              title={i18n.translate('PreLaunchText')}
              enableText={i18n.translate('EnablePreLaunch')}
              enabled={message.preLaunchText?.enabled}
              defaultLanguage={defaultLanguage || ''}
              disableCollapsed
              disableGoal
              offerId={offerId}
              onSystemReset={() =>
                handleUpdateMessage(
                  'preLaunchText',
                  defaultMessage.preLaunchText
                )
              }
              setEnabled={(value) =>
                handleUpdateMessage('preLaunchText', {
                  ...message.preLaunchText,
                  enabled: value,
                })
              }
              setTextMessage={(data) =>
                handleUpdateMessage('preLaunchText', {
                  ...message.preLaunchText,
                  value: data,
                })
              }
              setPreviewEntry={(entry) =>
                dispatch(
                  setAnnouncementBarPreLaunchMessages({
                    ...entry,
                    offerId: offerId as string,
                  })
                )
              }
            />
          </div>
          <div className='RightSideSection'>
            <TextEntries
              textMessage={message.text || {}}
              defaultLanguage={defaultLanguage || ''}
              disableCollapsed
              disableColor={textStyle.color !== undefined}
              setTextMessage={(data) => handleUpdateMessage('text', data)}
              onSystemReset={() =>
                handleUpdateMessage('text', defaultMessage.text)
              }
              offerId={offerId}
              setPreviewEntry={(entry) =>
                dispatch(
                  setAnnouncementBarTextMessages({
                    ...entry,
                    offerId: offerId as string,
                  })
                )
              }
            />
          </div>
        </>
      )}
      <div className='RightSideSection'>
        <TextStyle
          textStyle={textStyle}
          inherited={
            !isPresetEditor
              ? isEqual(presetSetup?.style, defaultPresetSetup?.style)
              : undefined
          }
          offersNote
          setTextStyle={(data) => handleUpdateMessage('style', data)}
          onSystemReset={() => handleUpdateMessage('style', shopDefaultMessage)}
        />
      </div>
    </div>
  );
};

export default AnnouncementBarMessage;
