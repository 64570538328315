import { NavTabs } from 'core/enums/NavTabsEnum';
import { usePageName } from 'core/hooks';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Dashboard } from '../features/dashboard/Dashboard';

export const DashboardPage = () => {
  usePageName(NavTabs.Home);
  return (
    <Routes>
      <Route index element={<Dashboard />} />
    </Routes>
  );
};
