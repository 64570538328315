import React, { useCallback } from 'react';
import {
  Background,
  CornerRadius,
  Size,
  TextEntries,
  TextStyle,
} from '../../../../../components';
import { BadgePricingBlockDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { PriceWidgetTypesDtoEnum } from '../../../../EditPresetRightBar';
import { setPricePageEntries } from 'core/store/widgetsSlice';
import { useAppDispatch, useAppSelector } from 'core/hooks';

type BodyRowBadgeProps = {
  badge: BadgePricingBlockDto;
  defaultBadge: BadgePricingBlockDto;
  shopDefaultBadge: BadgePricingBlockDto;
  defaultLanguage?: string;
  priceType: PriceWidgetTypesDtoEnum;
  stateType:
    | WidgetListOptionsDtoEnum.DISCOUNTED_PRICE
    | WidgetListOptionsDtoEnum.NOT_DISCOUNTED_PRICE;
  bodyRowType:
    | WidgetListOptionsDtoEnum.BODY_ROW_1
    | WidgetListOptionsDtoEnum.BODY_ROW_2
    | WidgetListOptionsDtoEnum.BODY_ROW_3;
  setBadge: (data: BadgePricingBlockDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const BodyRowBadge: React.FC<BodyRowBadgeProps> = ({
  badge,
  defaultBadge,
  shopDefaultBadge,
  defaultLanguage,
  priceType,
  stateType,
  bodyRowType,
  setBadge,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const dispatch = useAppDispatch();
  const { pricePageEntries } = useAppSelector((store) => store.widgets);
  const updateBadge = useCallback(
    (
      field: keyof BadgePricingBlockDto,
      data: BadgePricingBlockDto[keyof BadgePricingBlockDto]
    ) => {
      setBadge({
        ...badge,
        [field]: data,
      });
    },
    [badge, setBadge]
  );

  const resetAllToSystemDefault = useCallback(() => {
    setBadge(shopDefaultBadge);
  }, [shopDefaultBadge]);

  return (
    <div className='BodyRowBadge'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={() => setBadge(defaultBadge)}
        onResetToSystem={resetAllToSystemDefault}
        name={WidgetListOptionsDtoEnum.BADGE}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <TextEntries
          textMessage={badge.text?.message || {}}
          defaultLanguage={defaultLanguage || ''}
          disableGoal
          disableCollapsed
          disableColor={(badge.text?.style || {}).color !== undefined}
          setTextMessage={(data) =>
            updateBadge('text', { ...badge.text, message: data })
          }
          onSystemReset={() => updateBadge('text', shopDefaultBadge.text)}
          setPreviewEntry={(value) =>
            dispatch(
              setPricePageEntries({
                priceType,
                stateType,
                field: bodyRowType,
                data: {
                  ...pricePageEntries?.[priceType]?.[stateType]?.[bodyRowType],
                  badge: value,
                },
              })
            )
          }
        />
        <TextStyle
          textStyle={badge.text?.style || {}}
          hideHeader
          onlyFont
          setTextStyle={(data) =>
            updateBadge('text', { ...badge.text, style: data })
          }
        />
      </div>
      <div className='RightSideSection'>
        <Size
          size={badge.size || {}}
          setSize={(data) => updateBadge('size', data)}
          onSystemReset={() => updateBadge('size', shopDefaultBadge.size)}
          disableWidth
        />
      </div>
      <div className='RightSideSection'>
        <CornerRadius
          onSystemReset={() =>
            updateBadge('cornerRadius', shopDefaultBadge.cornerRadius)
          }
          cornerRadius={badge.cornerRadius || {}}
          setCornerRadius={(data) => updateBadge('cornerRadius', data)}
        />
      </div>
      <div className='RightSideSection'>
        <Background
          background={badge.background || {}}
          setBackground={(data) => updateBadge('background', data)}
          onSystemReset={() =>
            updateBadge('background', shopDefaultBadge.background)
          }
        />
      </div>
    </div>
  );
};

export default BodyRowBadge;
