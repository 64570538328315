import React, { useMemo } from 'react';
import {
  findIconDefinition,
  IconLookup,
} from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Insights.module.scss';
import {
  constantArrowIconLookup,
  descendingArrowIconLookup,
  questionMarkIconLookup,
  risingArrowIconLookup,
} from 'core/components/iconsDefinitions';
import { useI18n } from '@shopify/react-i18n';
import { formatPercentage } from 'core/utils';

interface DataGroupProps {
  iconLookup: IconLookup;
  title: string;
  mainData: number;
  percentageOfSessions?: number;
  volume?: number;
  conversion?: number;
}

const DataGroup: React.FC<DataGroupProps> = ({
  iconLookup,
  title,
  mainData,
  percentageOfSessions,
  volume,
  conversion,
}) => {
  const dataIconDef = findIconDefinition(iconLookup);
  const questionMarkIconDef = findIconDefinition(questionMarkIconLookup);
  const descendingArrowIconDef = findIconDefinition(descendingArrowIconLookup);
  const risingArrowIconDef = findIconDefinition(risingArrowIconLookup);
  const constantArrowIconDef = findIconDefinition(constantArrowIconLookup);
  const [i18n] = useI18n();

  const renderVolume = useMemo(() => {
    const percentageVolume = formatPercentage(i18n, volume);

    if (volume === 0) {
      return (
        <li className={styles.red}>
          <FontAwesomeIcon icon={questionMarkIconDef} className={styles.icon} />{' '}
          volume:{' '}
          <FontAwesomeIcon
            icon={constantArrowIconDef}
            className={styles.arrowIcon}
          />{' '}
          {percentageVolume}
        </li>
      );
    } else if (volume !== undefined && volume > 0) {
      return (
        <li className={styles.green}>
          <FontAwesomeIcon icon={questionMarkIconDef} className={styles.icon} />{' '}
          volume:{' '}
          <FontAwesomeIcon
            icon={risingArrowIconDef}
            className={styles.arrowIcon}
          />{' '}
          {percentageVolume}
        </li>
      );
    } else if (volume !== undefined && volume < 0) {
      return (
        <li className={styles.red}>
          <FontAwesomeIcon icon={questionMarkIconDef} className={styles.icon} />{' '}
          volume:{' '}
          <FontAwesomeIcon
            icon={descendingArrowIconDef}
            className={styles.arrowIcon}
          />{' '}
          {percentageVolume}
        </li>
      );
    } else {
      return <li> -- </li>;
    }
  }, [
    volume,
    constantArrowIconDef,
    descendingArrowIconDef,
    questionMarkIconDef,
    risingArrowIconDef,
    i18n,
  ]);

  const renderConversion = useMemo(() => {
    const percentageConversion = formatPercentage(i18n, conversion);
    if (conversion === 0) {
      return (
        <li className={styles.red}>
          conversion:{' '}
          <FontAwesomeIcon
            icon={constantArrowIconDef}
            className={styles.arrowIicon}
          />{' '}
          {percentageConversion}
        </li>
      );
    } else if (conversion !== undefined && conversion > 0) {
      return (
        <li className={styles.green}>
          conversion:{' '}
          <FontAwesomeIcon
            icon={risingArrowIconDef}
            className={styles.arrowIicon}
          />{' '}
          {percentageConversion}
        </li>
      );
    } else if (conversion !== undefined && conversion < 0) {
      return (
        <li className={styles.red}>
          conversion:{' '}
          <FontAwesomeIcon
            icon={descendingArrowIconDef}
            className={styles.arrowIcon}
          />{' '}
          {percentageConversion}
        </li>
      );
    } else {
      return <li></li>;
    }
  }, [
    conversion,
    constantArrowIconDef,
    descendingArrowIconDef,
    risingArrowIconDef,
    i18n,
  ]);

  return (
    <article className={styles.dataGroup}>
      <div>
        <FontAwesomeIcon icon={dataIconDef} className={styles.icon} />
        <h2>{mainData ? mainData : '--'}</h2>
        <h3>{title}</h3>
        {percentageOfSessions !== undefined ? (
          <p>
            {percentageOfSessions
              ? `${formatPercentage(i18n, percentageOfSessions)} of sessions`
              : '--'}
          </p>
        ) : (
          <p></p>
        )}
      </div>
      <ul className={styles.list}>
        {renderVolume}
        {renderConversion}
      </ul>
    </article>
  );
};
export default DataGroup;
