import {
  ActionList,
  Button,
  InlineStack,
  Popover,
  Text,
} from '@shopify/polaris';
import React, { useMemo, useState } from 'react';
import { useI18n } from '@shopify/react-i18n';
import { MenuHorizontalIcon, ChevronLeftIcon } from '@shopify/polaris-icons';
import './WidgetOptionHeader.scss';
import { useScreenResolution } from 'core/hooks/useScreenResolution';
import {
  WidgetListOptionsDtoEnum,
  WidgetListTypeDtoEnum,
} from 'features/settings/components/EditPresetSkeleton/enums/enums';

type WidgetOptionHeaderProps = {
  name: string;
  onSaveAndPublish?: () => void;
  onSaveNewPreset?: () => void;
  onShowHide?: () => void;
  onDiscardChanges?: () => void;
  onResetToSystem?: () => void;
  onRemove?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
export const WidgetOptionHeader: React.FC<WidgetOptionHeaderProps> = ({
  name,
  onSaveAndPublish,
  onSaveNewPreset,
  onShowHide,
  onDiscardChanges,
  onResetToSystem,
  onRemove,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();
  const [isWidgetActions, setIsWidgetActions] = useState<boolean>(false);
  const { width } = useScreenResolution();
  const transformLeftbar = useMemo(() => width < 1440, [width]);

  const asBlock = !Object.values(WidgetListTypeDtoEnum).includes(
    name as WidgetListTypeDtoEnum
  );
  const asOffer =
    !Object.values(WidgetListOptionsDtoEnum).includes(
      name as WidgetListOptionsDtoEnum
    ) &&
    !Object.values(WidgetListTypeDtoEnum).includes(
      name as WidgetListTypeDtoEnum
    );

  const showActions = useMemo(
    () =>
      onSaveAndPublish ||
      onSaveNewPreset ||
      onShowHide ||
      onDiscardChanges ||
      onResetToSystem ||
      onRemove,
    []
  );

  const presetActions = useMemo(
    () => [
      {
        external: true,
        content: i18n.translate(`SaveAndPublish`),
        onAction: onSaveAndPublish,
      },
      {
        external: true,
        content: i18n.translate(`SaveNewPreset`),
        onAction: onSaveNewPreset,
      },
      // {
      //   external: true,
      //   content: i18n.translate(`GoToPresetEditor`),
      //   onAction: inheritance?.id ? () => navigateToPreset() : undefined,
      // },
    ],
    [i18n, onSaveAndPublish, onSaveNewPreset]
  );

  const widgetActions = useMemo(
    () => [
      {
        external: true,
        content: i18n.translate(`ShowHide`),
        onAction: onShowHide
          ? () => {
              onShowHide?.();
              handleLeftMenuSelection?.(!asBlock ? false : undefined);
            }
          : undefined,
      },
      {
        external: true,
        content: i18n.translate(`DiscardChanges`),
        onAction: onDiscardChanges,
      },
      {
        external: true,
        content: i18n.translate(`ResetToSystem`),
        onAction: onResetToSystem,
      },
      {
        external: true,
        content: i18n.translate(`RemoveFromPromotion`),
        onAction: onRemove,
      },
    ],
    [onShowHide, onDiscardChanges, onResetToSystem, onRemove]
  );

  const filteredPresetActions = useMemo(
    () => presetActions.filter((action) => action.onAction !== undefined),
    [presetActions]
  );
  const filteredWidgetActions = useMemo(
    () => widgetActions.filter((action) => action.onAction !== undefined),
    [widgetActions]
  );

  return (
    <div className='WidgetOptionHeader'>
      <InlineStack blockAlign='center' align='space-between'>
        <div className='WidgetNameBox'>
          {transformLeftbar && (
            <Button
              variant='monochromePlain'
              icon={ChevronLeftIcon}
              onClick={() => handleLeftMenuSelection()}
            />
          )}
          <Text truncate as='h1' fontWeight='bold'>
            {asOffer ? name : i18n.translate(name)}
          </Text>
        </div>
        {showActions && (
          <div className='MenuButton'>
            <Popover
              active={isWidgetActions}
              activator={
                <Button
                  variant='tertiary'
                  icon={MenuHorizontalIcon}
                  onClick={() => setIsWidgetActions(true)}
                />
              }
              autofocusTarget='first-node'
              onClose={() => setIsWidgetActions(false)}
            >
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setIsWidgetActions(false);
                }}
              >
                <ActionList
                  actionRole='menuitem'
                  sections={[
                    {
                      title: i18n.translate(asBlock ? 'Block' : 'Widget'),
                      items: filteredWidgetActions,
                    },
                    {
                      title: i18n.translate(`Preset`),
                      items: filteredPresetActions,
                    },
                  ]}
                />
              </div>
            </Popover>
          </div>
        )}
      </InlineStack>
    </div>
  );
};
