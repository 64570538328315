import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BlockStack,
  Box,
  InlineStack,
  Link,
  Modal,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  PromotionFetchTypeEnum,
  useConfigurePromotions,
} from 'features/promotions/hooks/useConfigurePromotion';
import { ClipboardIcon } from '@shopify/polaris-icons';
import { useSearchParams } from 'react-router-dom';
import { CopyIcon } from 'core/components';
import { useAppDispatch } from 'core/hooks';
import { setToastMessages } from 'core/store/offersWizardSlice';
type TestPromotionModalProps = {
  isOpen: boolean;
  isPromotionEngineEnabled: boolean;
  isTestingEnabled: boolean;
  onClose: () => void;
};
export const TestPromotionModal: React.FC<TestPromotionModalProps> = ({
  isOpen,
  onClose,
  isPromotionEngineEnabled,
  isTestingEnabled,
}) => {
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();
  const [, setSearchParams] = useSearchParams();
  const [fetchTestLink, setFetchTestLink] = useState<boolean>(false);
  const { promotionTestLinkData, promotionTestLinkIsFetching } =
    useConfigurePromotions(
      fetchTestLink ? PromotionFetchTypeEnum.PROMOTION_TESTING : undefined
    );

  const onCopyLink = useCallback((value?: string) => {
    navigator.clipboard.writeText(`${value}`);
    dispatch(
      setToastMessages({
        error: false,
        message: 'CopiedToClipboard',
      })
    );
  }, []);

  const handleOpenSettings = useCallback(
    (path: string) => {
      setSearchParams({
        modal: 'settings',
        path: path,
      });
      onClose();
    },
    [setSearchParams]
  );

  const secondaryActionVariants = useMemo(() => {
    switch (true) {
      case !isTestingEnabled:
        return {
          content: i18n.translate(`Cancel`),
          onAction: onClose,
        };
      case !fetchTestLink:
        return {
          content: i18n.translate(`CreateTestLink`),
          onAction: () => setFetchTestLink(true),
        };
      case !!fetchTestLink:
        return {
          content: i18n.translate(`CopyLink`),
          loading: promotionTestLinkIsFetching,
          onAction: () => onCopyLink(promotionTestLinkData?.link),
        };
      default:
        return {
          content: i18n.translate(`Cancel`),
          onAction: onClose,
        };
    }
  }, [
    isTestingEnabled,
    isPromotionEngineEnabled,
    promotionTestLinkIsFetching,
    fetchTestLink,
  ]);

  const primaryActionVariants = useMemo(() => {
    switch (true) {
      case !isTestingEnabled:
        return {
          content: i18n.translate(`EnableTesting`),
          onAction: () => handleOpenSettings('testing'),
        };
      case !isPromotionEngineEnabled && !fetchTestLink:
        return {
          content: i18n.translate(`EnableAppEmbed`),
          onAction: () => handleOpenSettings('manage-widgets'),
        };
      case fetchTestLink && isPromotionEngineEnabled:
        return {
          content: i18n.translate(`StartTest`),
          disabled: promotionTestLinkIsFetching,
          onAction: () => {
            window.open(promotionTestLinkData?.link);
            onClose();
          },
        };
    }
  }, [
    isTestingEnabled,
    fetchTestLink,
    isPromotionEngineEnabled,
    promotionTestLinkIsFetching,
  ]);

  const testContentVariants = useMemo(() => {
    switch (true) {
      case !isTestingEnabled:
        return (
          <Text as='p'>
            {i18n.translate('TestDisabledDescription', {
              readArticle: <Link>{i18n.translate('readArticle')}</Link>,
            })}
          </Text>
        );
      case promotionTestLinkIsFetching:
        return <Text as='p'>{i18n.translate('BuildingLink')}</Text>;
      case !fetchTestLink && !isPromotionEngineEnabled:
        return (
          <Text as='p'>
            {i18n.translate('EmbedDisabled', {
              testSettings: <Link>{i18n.translate('testSettings')}</Link>,
              readArticle: <Link>{i18n.translate('readArticle')}</Link>,
            })}
          </Text>
        );
      case !fetchTestLink && isPromotionEngineEnabled:
        return (
          <Text as='p'>
            {i18n.translate('EmbedEnabled', {
              testSettings: <Link>{i18n.translate('testSettings')}</Link>,
              readArticle: <Link>{i18n.translate('readArticle')}</Link>,
            })}
          </Text>
        );
      case fetchTestLink &&
        !promotionTestLinkIsFetching &&
        !!promotionTestLinkData:
        return (
          <BlockStack gap='400'>
            <Text as='p'>{i18n.translate('YourLinkReady')}</Text>
            <Box
              background='bg-surface-secondary'
              borderColor='border-brand'
              borderWidth='025'
              borderRadius='200'
              padding='400'
            >
              <InlineStack
                wrap={false}
                gap='300'
                align='space-between'
                blockAlign='center'
              >
                <Text as='p' truncate>
                  {promotionTestLinkData?.link}
                </Text>
                <CopyIcon
                  icon={ClipboardIcon}
                  copyText={promotionTestLinkData?.link}
                />
              </InlineStack>
            </Box>
            <Text as='p'>
              {i18n.translate('LinkIsActive', {
                readArticle: <Link>{i18n.translate('readArticle')}</Link>,
              })}
            </Text>
          </BlockStack>
        );
    }
  }, [
    isTestingEnabled,
    fetchTestLink,
    isPromotionEngineEnabled,
    promotionTestLinkData,
    promotionTestLinkIsFetching,
  ]);

  useEffect(() => {
    if (isPromotionEngineEnabled && isTestingEnabled) {
      setFetchTestLink(true);
    }
  }, []);

  return (
    <Modal
      title={i18n.translate(
        isTestingEnabled ? 'TestPromotion' : 'TestDisabled'
      )}
      open={isOpen}
      primaryAction={primaryActionVariants}
      secondaryActions={[secondaryActionVariants]}
      onClose={onClose}
    >
      <Modal.Section>{testContentVariants}</Modal.Section>
    </Modal>
  );
};
