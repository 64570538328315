import React from 'react';
import { BlockStack, Card, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { TimezoneDto } from 'core/api/adminSettings/adminSettingsApi';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
type TimezoneProps = {
  timezoneData: TimezoneDto[];
  data: string;
  handleAccountLocaleState: (value: string) => void;
};

export const Timezone: React.FC<TimezoneProps> = ({
  timezoneData,
  data,
  handleAccountLocaleState,
}) => {
  const [i18n] = useI18n();

  return (
    <div className='TimezoneComponent'>
      <Card roundedAbove='sm' padding='400'>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='h2' variant='headingSm'>
              {i18n.translate('TimezoneTitle')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('TimezoneSubtitle')}
            </Text>
          </BlockStack>
          <SelectOptions
            label={i18n.translate('TimezoneTitle')}
            options={
              timezoneData?.map((item) => ({
                label: item.name || '',
                value: item.id || '',
              })) || []
            }
            selectedOption={data}
            onOptionSelect={(value) => handleAccountLocaleState(value)}
          />
        </BlockStack>
      </Card>
    </div>
  );
};
