import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import './RightSideBarWrapper.scss';
import { PresetSkeletonDto } from '../../EditPresetSkeleton';
import classNames from 'classnames';
import EditPresetRightBar from './RightBarTypes/EditPresetRightBar/EditPresetRightBar';
import {
  PresetDto,
  WidgetTypeDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { ActivityDetector } from '../ActivityDetector';
import EditPromotionRightBar from './RightBarTypes/EditPromotionRightBar/EditPromotionRightBar';
import { LeftMenuWidget } from '../../utils/leftMenuGenerators';
import { EditPresetTypeEnum } from '../../enums/enums';
import { findSelectedOptionPath } from '../../utils/utils';
import { useAppSelector } from 'core/hooks';

type RightSideBarWrapperProps = {
  currentWidget?: LeftMenuWidget;
  smallScreen?: boolean;
  globalPresetState: PresetSkeletonDto;
  skeletonType: EditPresetTypeEnum;
  defaultPresetData: PresetSkeletonDto;
  handleUpdateGlobalState: (
    field: keyof PresetSkeletonDto,
    data: PresetSkeletonDto[keyof PresetSkeletonDto]
  ) => void;
  handlePointerLeave: () => void;
  handlePointerEnter: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
  handleRemoveWidget: (widgetName: WidgetTypeDto) => void;
};

export type SelectedOptionPath = {
  name: string;
  offerId?: string;
  parentName?: string;
  selectedOption: SelectedOptionPath | null;
  currentEntries?: string[];
  isHidden?: boolean;
} | null;

export const RightSideBarWrapper: React.FC<RightSideBarWrapperProps> = ({
  currentWidget,
  smallScreen,
  skeletonType,
  globalPresetState,
  defaultPresetData,
  handleUpdateGlobalState,
  handlePointerLeave,
  handlePointerEnter,
  handleLeftMenuSelection,
  handleRemoveWidget,
}) => {
  const rightBarRef = useRef<HTMLDivElement | null>(null);
  const { isSmartTagConfigOpen } = useAppSelector((store) => store.widgets);
  const updateEditPresetState = useCallback(
    (field: keyof PresetDto, data: PresetDto[keyof PresetDto]) =>
      handleUpdateGlobalState('editPresetData', {
        ...globalPresetState.editPresetData,
        [field]: data,
      }),
    [handleUpdateGlobalState, globalPresetState]
  );

  const selectedOptionPath = useMemo(
    () => findSelectedOptionPath([currentWidget || {}]),
    [currentWidget]
  );

  const rightSideBarType = useMemo(() => {
    switch (skeletonType) {
      case EditPresetTypeEnum.PRESET_EDITOR:
        return (
          <EditPresetRightBar
            selectedOptionPath={selectedOptionPath}
            editPresetState={globalPresetState.editPresetData || {}}
            currentWidget={currentWidget}
            defaultPresetData={defaultPresetData.editPresetData || {}}
            shopDefaultData={defaultPresetData.shopDefaultData || {}}
            smallScreen={smallScreen}
            updateEditPresetState={updateEditPresetState}
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case EditPresetTypeEnum.PROMOTION_EDITOR:
        return (
          <EditPromotionRightBar
            currentWidget={currentWidget}
            selectedOptionPath={selectedOptionPath}
            editPromotionState={globalPresetState.promotionPresetData || {}}
            defaultPromotionData={defaultPresetData.promotionPresetData || {}}
            shopDefaultData={defaultPresetData.shopDefaultData || {}}
            smallScreen={smallScreen}
            setEditPromotionState={(data) =>
              handleUpdateGlobalState('promotionPresetData', data)
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
            filterSupportedWidgets={handleRemoveWidget}
          />
        );
    }
  }, [
    skeletonType,
    globalPresetState,
    currentWidget,
    defaultPresetData,
    updateEditPresetState,
    handleLeftMenuSelection,
    handleRemoveWidget,
    handleUpdateGlobalState,
  ]);

  useEffect(() => {
    if (isSmartTagConfigOpen) {
      rightBarRef.current?.scrollTo({
        top: 0,
      });
    }
  }, [isSmartTagConfigOpen]);

  return (
    <div
      className={classNames('RightSideBar', {
        left: smallScreen,
        DisableScrolling: isSmartTagConfigOpen,
      })}
      ref={rightBarRef}
    >
      <ActivityDetector
        onCursorEnter={handlePointerEnter}
        onCursorLeave={handlePointerLeave}
      >
        {rightSideBarType}
      </ActivityDetector>
    </div>
  );
};
