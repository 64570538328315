import {
  PromotionSetupDtoRead,
  PromotionWidgetSetupMultiLevelDtoPromotionSummaryPromotionDtoPromotionSummaryLevelPromotionDtoPromotionSummaryLevelPromotionEntryTypeDtoRead as PromotionWidgetSetupDtoPromotionSummaryDto,
  PromotionSummarySystemDefaultsDto,
  PromotionSummaryLevelPromotionDto,
  OfferWidgetSetupDtoPromotionSummaryOfferDtoRead,
  PresetsSetupDto,
  PromotionSummaryPromotionDto,
  PromotionSummaryLevelPresetDto,
  MessageStyleComponentDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import React, { useCallback, useMemo } from 'react';
import { SelectedOptionPath } from '../../../../RightSideBarWrapper';
import { WidgetTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { getLastSelectedOption } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import PromotionSummaryGeneral from '../../../EditPresetRightBar/components/PresetPromotionSummary/components/PromotionSummaryGeneral/PromotionSummaryGeneral';
import PromotionSummaryCombinedComponent, {
  PromotionSummaryCombinedComponentTypes,
} from '../../../EditPresetRightBar/components/PresetPromotionSummary/components/PromotionSummaryCombinedComponent/PromotionSummaryCombinedComponent';
import PromotionSummaryDisclaimer from '../../../EditPresetRightBar/components/PresetPromotionSummary/components/PromotionSummaryDisclaimer/PromotionSummaryDisclaimer';
import PromotionSummaryOffer from './components/PromotionSummaryOffer/PromotionSummaryOffer';
import {
  WidgetListOptionsDtoEnum,
  WidgetListTypeDtoEnum,
} from 'features/settings/components/EditPresetSkeleton/enums/enums';

type PromotionPromotionSummaryProps = {
  type:
    | WidgetListTypeDtoEnum.DRAWER_CART_PROMOTION_SUMMARY
    | WidgetListTypeDtoEnum.CART_PROMOTION_SUMMARY;
  editPromotionState: PromotionSetupDtoRead;
  defaultPromotionData: PromotionSetupDtoRead;
  shopDefaultData: PromotionSummarySystemDefaultsDto;
  selectedOptionPath: SelectedOptionPath;
  updateEditPromotionState: (
    field: keyof PromotionSetupDtoRead,
    data: PromotionSetupDtoRead[keyof PromotionSetupDtoRead],
    presetSetup?: PresetsSetupDto
  ) => void;
  filterSupportedWidgets: (value: WidgetTypeDtoEnum) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const PromotionPromotionSummary: React.FC<PromotionPromotionSummaryProps> = ({
  type,
  editPromotionState,
  defaultPromotionData,
  shopDefaultData,
  selectedOptionPath,
  updateEditPromotionState,
  filterSupportedWidgets,
  handleLeftMenuSelection,
}) => {
  const promotionSummary = editPromotionState?.[type];
  const presetSetup = editPromotionState.presetSetup;
  const widgetPreset = presetSetup?.[type];
  const offersData = editPromotionState.offers?.[type];
  const defaultOffersData = defaultPromotionData.offers?.[type];
  const defaultPromotionSummary = defaultPromotionData.presetSetup?.[type];
  const defaultPresetSetup = defaultPromotionData.presetSetup?.[type];
  const defaultLanguage = editPromotionState.offers?.defaultLanguage || '';

  const updatePromotionSummary = useCallback(
    (data: PromotionSummaryPromotionDto) =>
      updateEditPromotionState('presetSetup', presetSetup, {
        ...presetSetup,
        [type]: {
          ...presetSetup?.[type],
          general: data,
        },
      }),
    [promotionSummary, updateEditPromotionState]
  );

  const updatePromotionSummaryOption = useCallback(
    (
      field: keyof PromotionSummaryLevelPresetDto,
      data: PromotionSummaryLevelPresetDto[keyof PromotionSummaryLevelPresetDto],
      textStyle?: MessageStyleComponentDto
    ) =>
      updateEditPromotionState('presetSetup', presetSetup, {
        ...presetSetup,
        [type]: {
          ...presetSetup?.[type],
          general: {
            ...presetSetup?.[type]?.general,
            ...(textStyle && { textStyle: textStyle }),
          },
          options: {
            ...presetSetup?.[type]?.options,
            [field]: data,
          },
        },
      }),
    [presetSetup, updateEditPromotionState]
  );

  const updateOptionVisability = useCallback(
    (field: keyof PromotionSummaryLevelPromotionDto) =>
      updateEditPromotionState(type, {
        ...promotionSummary,
        options: {
          ...promotionSummary?.options,
          [field]: {
            ...promotionSummary?.options?.[field],
            isHidden: !promotionSummary?.options?.[field]?.isHidden,
          },
        },
      }),
    [promotionSummary, updateEditPromotionState]
  );

  const updateOffer = useCallback(
    (data: OfferWidgetSetupDtoPromotionSummaryOfferDtoRead, offerId: string) =>
      updateEditPromotionState('offers', {
        ...editPromotionState.offers,
        [type]: editPromotionState.offers?.[type]?.map((offerData) => {
          if (offerData?.offerId === offerId) {
            return data;
          }
          return offerData;
        }),
      }),

    [updateEditPromotionState, editPromotionState]
  );

  const getPromotionSummaryCombinedComponent = useCallback(
    (sectionType: PromotionSummaryCombinedComponentTypes) => {
      return (
        <PromotionSummaryCombinedComponent
          key={sectionType}
          sectionType={sectionType}
          general={widgetPreset?.options?.[sectionType] || {}}
          defaultGeneral={defaultPresetSetup?.options?.[sectionType] || {}}
          defaultLanguage={defaultLanguage}
          shopDefaultGeneral={shopDefaultData?.[sectionType] || {}}
          textStyle={widgetPreset?.general?.textStyle || {}}
          defaultTextStyle={defaultPresetSetup?.general?.textStyle || {}}
          shopDefaultTextStyle={shopDefaultData.general?.textStyle || {}}
          handleLeftMenuSelection={handleLeftMenuSelection}
          onShowHide={
            promotionSummary?.options?.[sectionType]?.canBeHidden
              ? () => updateOptionVisability(sectionType)
              : undefined
          }
          setGeneral={(data, textStyleData) =>
            updatePromotionSummaryOption(sectionType, data, textStyleData)
          }
        />
      );
    },
    [
      promotionSummary,
      defaultPromotionSummary,
      shopDefaultData,
      updatePromotionSummary,
      handleLeftMenuSelection,
      updatePromotionSummaryOption,
    ]
  );

  const promotionSummaryOptions = useMemo(() => {
    const selectedOption = getLastSelectedOption(selectedOptionPath);

    const offerData = offersData?.find(
      (offer) => offer.offerId === selectedOption?.offerId
    ) as OfferWidgetSetupDtoPromotionSummaryOfferDtoRead;
    const defaultOfferData = defaultOffersData?.find(
      (offer) => offer.offerId === selectedOption?.offerId
    ) as OfferWidgetSetupDtoPromotionSummaryOfferDtoRead;

    switch (true) {
      case selectedOption?.name === type:
        return (
          <PromotionSummaryGeneral
            general={widgetPreset?.general || {}}
            defaultGeneral={defaultPresetSetup?.general || {}}
            shopDefaultGeneral={shopDefaultData.general || {}}
            setGeneral={(data) => updatePromotionSummary(data)}
            type={type}
            onShowHide={
              promotionSummary?.canBeHidden
                ? () =>
                    updateEditPromotionState(type, {
                      ...promotionSummary,
                      isHidden: !promotionSummary?.isHidden,
                    })
                : undefined
            }
            onRemove={() =>
              filterSupportedWidgets(
                type === WidgetListTypeDtoEnum.DRAWER_CART_PROMOTION_SUMMARY
                  ? WidgetTypeDtoEnum.DRAWER_CART_PROMOTION_SUMMARY
                  : WidgetTypeDtoEnum.CART_PROMOTION_SUMMARY
              )
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case !!selectedOption?.offerId:
        return (
          <PromotionSummaryOffer
            key={offerData?.offerId}
            offerId={offerData?.offerId || ''}
            offer={offerData?.setup?.general || {}}
            offerName={selectedOption?.name || ''}
            defaultOffer={defaultOfferData?.setup?.general || {}}
            shopDefaultOffer={shopDefaultData.total || {}}
            handleLeftMenuSelection={handleLeftMenuSelection}
            defaultLanguage={defaultLanguage}
            onShowHide={
              offerData?.canBeHidden
                ? () =>
                    updateOffer(
                      { ...offerData, isHidden: !offerData?.isHidden },
                      offerData?.offerId as string
                    )
                : undefined
            }
            setOffer={(data) =>
              updateOffer(
                {
                  ...offerData,
                  setup: {
                    ...offerData?.setup,
                    general: data,
                  },
                },
                offerData?.offerId as string
              )
            }
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.TOTAL:
        return getPromotionSummaryCombinedComponent(
          WidgetListOptionsDtoEnum.TOTAL
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.SUBTOTAL:
        return getPromotionSummaryCombinedComponent(
          WidgetListOptionsDtoEnum.SUBTOTAL
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.SHIPPING:
        return getPromotionSummaryCombinedComponent(
          WidgetListOptionsDtoEnum.SHIPPING
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.TAXES_AND_DUTIES:
        return getPromotionSummaryCombinedComponent(
          WidgetListOptionsDtoEnum.TAXES_AND_DUTIES
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.DISCOUNT:
        return getPromotionSummaryCombinedComponent(
          WidgetListOptionsDtoEnum.DISCOUNT
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.DISCLAIMER:
        return (
          <PromotionSummaryDisclaimer
            disclaimer={widgetPreset?.options?.disclaimer || {}}
            defaultDisclaimer={defaultPresetSetup?.options?.disclaimer || {}}
            shopDefaultDisclaimer={shopDefaultData.disclaimer || {}}
            defaultLanguage={defaultLanguage}
            pageType={type}
            offerId={offerData?.offerId}
            setDisclaimer={(data) =>
              updatePromotionSummaryOption('disclaimer', data)
            }
            onShowHide={
              promotionSummary?.options?.disclaimer?.canBeHidden
                ? () => updateOptionVisability('disclaimer')
                : undefined
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
    }
  }, [
    promotionSummary,
    defaultPromotionSummary,
    offersData,
    defaultOffersData,
    editPromotionState,
    selectedOptionPath,
    widgetPreset,
    handleLeftMenuSelection,
    updateOffer,
    getPromotionSummaryCombinedComponent,
    updatePromotionSummary,
  ]);

  return (
    <div className='PromotionPromotionSummary'>{promotionSummaryOptions}</div>
  );
};

export default PromotionPromotionSummary;
