import React, { useCallback, useEffect, useMemo } from 'react';
import { useI18n } from '@shopify/react-i18n';
import { BlockStack, Card, Text, RadioButton } from '@shopify/polaris';
import { OfferAppliedCollectionTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { OfferAppliedCollectionTypeDto } from 'core/api/adminPromotions/adminPromotionsApi';

import { SelectCountries } from './components/SelectCountries/SelectCountries';
type ShippingCountriesProps = {
  updateValidity(index: number, isValid: boolean): void;
  selectedCountries: string[];
  setSelectedCountries: (data: string[]) => void;
  shippingCountriesOption: OfferAppliedCollectionTypeDto;
  setShippingCountriesOption: (
    shippingCountriesOption: OfferAppliedCollectionTypeDto
  ) => void;
};
export const ShippingCountries: React.FC<ShippingCountriesProps> = (props) => {
  const {
    shippingCountriesOption,
    setShippingCountriesOption,
    selectedCountries,
    setSelectedCountries,
    updateValidity,
  } = props;

  const [i18n] = useI18n();
  const formIsInvalid: boolean = useMemo(
    () => selectedCountries.length === 0,
    [selectedCountries.length]
  );

  useEffect(() => {
    if (
      shippingCountriesOption === OfferAppliedCollectionTypeDtoEnum.SELECTED
    ) {
      updateValidity(1, !formIsInvalid);
    } else {
      updateValidity(1, true);
    }
  }, [formIsInvalid, shippingCountriesOption]);

  const onRadioButtonValueChange = useCallback(
    (data: OfferAppliedCollectionTypeDtoEnum) =>
      (_newValue: boolean, _id: string) => {
        setShippingCountriesOption(data);
        if (data === OfferAppliedCollectionTypeDtoEnum.ALL) {
          setSelectedCountries([]);
        }
      },
    [setShippingCountriesOption]
  );

  return (
    <>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='h2' variant='headingSm'>
              {i18n.translate('RadioButtonsGroupTitle')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('RadioButtonsGroupSubtitle')}
            </Text>
          </BlockStack>
          <BlockStack gap='025'>
            {Object.entries(OfferAppliedCollectionTypeDtoEnum).map(
              ([key, value]: [string, OfferAppliedCollectionTypeDtoEnum]) => (
                <RadioButton
                  key={key}
                  label={i18n.translate(`${key}`) || value}
                  name={i18n.translate(`${key}`) || value}
                  checked={shippingCountriesOption === value}
                  onChange={onRadioButtonValueChange(value)}
                />
              )
            )}
          </BlockStack>
          {shippingCountriesOption ===
            OfferAppliedCollectionTypeDtoEnum.SELECTED && (
            <SelectCountries
              selectedCountries={selectedCountries}
              formIsInvalid={formIsInvalid}
              setSelectedCountries={setSelectedCountries}
              i18n={i18n}
            />
          )}
        </BlockStack>
      </Card>
    </>
  );
};
