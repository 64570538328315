import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Banner,
  Text,
  BlockStack,
  Button,
  Card,
  Badge,
  Icon,
  InlineStack,
  Page,
  Box,
  Bleed,
  Link,
  OptionList,
  Popover,
} from '@shopify/polaris';
import { InfoIcon, ThemeIcon, ChevronRightIcon } from '@shopify/polaris-icons';
import { Element } from 'react-scroll';
import './ManageWidgets.scss';
import { useI18n } from '@shopify/react-i18n';
import { useDispatch } from 'react-redux';
import {
  ThemeSlotDto,
  WidgetsInstallationStatusSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import classNames from 'classnames';
import ManageThemesModal from './components/ManageThemesModal/ManageThemesModal';
import TogglePromotionEngineModal from './components/TogglePromotionEngineModal/TogglePromotionEngineModal';
import { useSearchParams } from 'react-router-dom';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';
import {
  setIsUnsavedChanges,
  setOnDiscardChanges,
  setOnSaveChanges,
  setWidgetThemeType,
} from 'core/store/settingsSlice';
import {
  InstallationSupportRequestGeneralStatusDtoEnum,
  PageTypeDtoEnum,
  ThemeTypeDtoEnum,
  WidgetInstallationModeDtoEnum,
  WidgetStatusDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import { OptionDescriptor } from '@shopify/polaris/build/ts/src/types';
import { useAppSelector } from 'core/hooks';
import WidgetRefreshModal from './components/WidgetRefreshModal/WidgetRefreshModal';
import SupportDetailsModal from './components/SupportDetailsModal/SupportDetailsModal';
import { format } from 'date-fns';
import { SettingsSkeleton } from '../components/SettingsSkeleton/SettingsSkeleton';

export const ManageWidgets: React.FC = () => {
  const [i18n] = useI18n();
  const dispatch = useDispatch();

  const {
    widgetInstallationData,
    themeSlotsData,
    saveThemeSlotsIsLoading,
    themeSlotsDataIsFetching,
    widgetInstallationDataIsFetching,
    refetchWidgetInstallationData,
    saveThemeSlots,
    saveWidgetInstallation,
  } = useConfigureSettings(SettingsFetchTypeEnum.MANAGE_WIDGETS);
  const { widgetThemeType, isRefreshModalOpen } = useAppSelector(
    (store) => store.settings
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [savedData, setSavedData] =
    useState<WidgetsInstallationStatusSettingsDto>({});
  const [manageWidgetsState, setManageWidgetsState] =
    useState<WidgetsInstallationStatusSettingsDto>();
  const [themeSlots, setThemeSlots] = useState<ThemeSlotDto[]>();

  const [isThemesModalOpen, setIsThemesModalOpen] = useState<boolean>(false);
  const [isEngineModalOpen, setIsEngineModalOpen] = useState<boolean>(false);
  const [popoverActive, setPopoverActive] = useState(false);
  const [isSupportDetailsOpen, setIsSupportDetailsOpen] =
    useState<boolean>(false);

  const isEngineEnabled = manageWidgetsState?.promotionEngine?.enabled;

  const allWidgetsDisabled = manageWidgetsState?.widgets?.every(
    (widget) => widget.status === WidgetStatusDtoEnum.DISABLED
  );

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [popoverActive]
  );

  const toggleThemesModal = useCallback(() => {
    setIsThemesModalOpen(!isThemesModalOpen);
    setPopoverActive(false);
  }, [isThemesModalOpen]);

  const toggleEngineModal = useCallback(
    () => setIsEngineModalOpen(!isEngineModalOpen),
    [isEngineModalOpen]
  );

  const toggleSupportModal = useCallback(
    () => setIsSupportDetailsOpen(!isSupportDetailsOpen),
    [isSupportDetailsOpen]
  );

  const onWidgetClick = useCallback(
    (widgetPath: string) => {
      searchParams.set('subPath', widgetPath);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const getWidgetCardList = useCallback(
    (mode: WidgetInstallationModeDtoEnum) => {
      const widgetList = manageWidgetsState?.widgets?.filter(
        (widget) => widget.installationMode === mode
      );

      const convertWidgetPages = (arr: PageTypeDtoEnum[]) => {
        const formattedArray = arr.map((item) => {
          return i18n.translate(`${item}_PAGE`);
        });
        return formattedArray.join(' & ');
      };
      return (
        <div key={mode} className='WidgetListBox'>
          <div className='WidgetListBoxHeader'>
            <Text as='h2' variant='headingSm'>
              {i18n.translate(`${mode}_TITLE`)}
            </Text>
            <div>
              <Icon tone='subdued' source={InfoIcon} />
            </div>
          </div>
          {widgetList?.map((widget, index) => {
            const isEnabled =
              widget.status === WidgetStatusDtoEnum.ENABLED ||
              widget.status === WidgetStatusDtoEnum.ENABLED_VIA_CODE ||
              widget.status === WidgetStatusDtoEnum.ENABLED_VIA_CSS;
            return (
              <div
                key={widget.type}
                className={classNames('WidgetListItem', {
                  IsLastItem: index + 1 === widgetList.length,
                })}
                onClick={() =>
                  onWidgetClick(i18n.translate(`${widget.type}_LINK`))
                }
              >
                <InlineStack gap={'300'} blockAlign='center'>
                  <Icon tone='base' source={ThemeIcon} />
                  <BlockStack gap='100'>
                    <Text as='p'>
                      {i18n.translate(`${widget.type}`)}{' '}
                      <Badge tone={isEnabled ? 'success' : undefined}>
                        {i18n.translate(isEnabled ? 'On' : 'Off')}
                      </Badge>
                      {widget.isPremium && (
                        <Badge tone='attention'>
                          {i18n.translate('Premium')}
                        </Badge>
                      )}
                    </Text>
                    <Text tone='subdued' as='p'>
                      {i18n.translate(`${widget.installationMode}`)} •{' '}
                      {convertWidgetPages(widget.pages as PageTypeDtoEnum[])}
                    </Text>
                  </BlockStack>
                </InlineStack>
                <InlineStack blockAlign='center' gap='300'>
                  {widget.status === WidgetStatusDtoEnum.LOCKED && (
                    <Button variant='primary'>
                      {i18n.translate('GetTheFeature')}
                    </Button>
                  )}
                  <div className='NavigateArrow'>
                    <Icon tone='base' source={ChevronRightIcon} />
                  </div>
                </InlineStack>
              </div>
            );
          })}
        </div>
      );
    },
    [manageWidgetsState]
  );

  const handleSaveThemeSlots = useCallback(
    (data: ThemeSlotDto[]) => {
      saveThemeSlots({ slots: data }).then(() => {
        setThemeSlots(data);
        setIsThemesModalOpen(false);
      });
    },
    [saveThemeSlots]
  );

  const slotsOptions = useMemo(
    () =>
      themeSlots?.map((slot) => ({
        value: slot.type,
        label: (
          <BlockStack>
            <Text fontWeight={'semibold'} as='p'>
              {i18n.translate(`${slot.type}_THEME`)}
            </Text>
            <Text fontWeight={'medium'} tone='subdued' as='p' truncate>
              {slot.themeName?.slice(0, 24)}
            </Text>
          </BlockStack>
        ),
      })) as OptionDescriptor[],
    [themeSlots, i18n]
  );
  const convertDateCode = useCallback((code: number) => {
    const formattedDate = format(new Date(code), 'dd.MM.yyyy HH:mm');
    return formattedDate;
  }, []);

  useEffect(() => {
    if (
      !isEmpty(manageWidgetsState) &&
      !isEqual(savedData, manageWidgetsState)
    ) {
      dispatch(setIsUnsavedChanges(true));
      dispatch(
        setOnDiscardChanges(
          () => !isEmpty(savedData) && setManageWidgetsState(savedData)
        )
      );
      dispatch(
        setOnSaveChanges(() => {
          setSavedData(manageWidgetsState);
          return saveWidgetInstallation(manageWidgetsState || {});
        })
      );
    } else if (isEqual(savedData, manageWidgetsState)) {
      dispatch(setIsUnsavedChanges(false));
    }
  }, [manageWidgetsState, savedData, dispatch]);

  useEffect(() => {
    if (!isEmpty(widgetInstallationData)) {
      setManageWidgetsState(widgetInstallationData);
      setSavedData(widgetInstallationData);
    }
  }, [widgetInstallationData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  useEffect(() => {
    if (themeSlotsData && !themeSlots?.length) {
      setThemeSlots(themeSlotsData);
    }
  }, [themeSlotsData]);

  return (
    <div className='ManageWidgets'>
      {manageWidgetsState && !widgetInstallationDataIsFetching ? (
        <Page
          title={i18n.translate(`Widgets`)}
          secondaryActions={[
            { content: i18n.translate(`Test`) },
            { content: i18n.translate('Refresh') },
          ]}
          primaryAction={
            <Popover
              active={popoverActive}
              activator={
                <Button onClick={togglePopoverActive} disclosure>
                  {i18n.translate(`${widgetThemeType}_THEME`)}
                </Button>
              }
              onClose={togglePopoverActive}
            >
              <div style={{ width: '220px', height: '220px' }}>
                <OptionList
                  onChange={(value) =>
                    dispatch(setWidgetThemeType(value[0] as ThemeTypeDtoEnum))
                  }
                  verticalAlign='center'
                  options={slotsOptions}
                  selected={[widgetThemeType]}
                />
                <div style={{ padding: '6px 12px 6px 12px' }}>
                  <Button onClick={toggleThemesModal} variant='plain'>
                    {i18n.translate('ManageThemes')}
                  </Button>
                </div>
              </div>
            </Popover>
          }
        >
          <BlockStack gap='600'>
            <>
              <Element name='PromotionEngine'>
                <Card roundedAbove='sm'>
                  <BlockStack gap='200'>
                    <InlineStack align='space-between' blockAlign='center'>
                      <InlineStack gap={'100'}>
                        <Text as='h2' variant='headingSm'>
                          {i18n.translate(`PromotionEngine`)}
                        </Text>
                        <Badge tone={isEngineEnabled ? 'success' : undefined}>
                          {i18n.translate(isEngineEnabled ? `On` : 'Off')}
                        </Badge>
                        <Icon tone='base' source={InfoIcon} />
                      </InlineStack>
                      <Button onClick={toggleEngineModal}>
                        {i18n.translate(isEngineEnabled ? 'TurnOff' : 'TurnOn')}
                      </Button>
                    </InlineStack>
                    <Text as='p' tone='subdued'>
                      {i18n.translate(`ResponsibleForOffering`)}
                    </Text>
                    {!isEngineEnabled && (
                      <Banner tone='warning'>
                        {i18n.translate(`SpecialOffersUpsell`)}
                      </Banner>
                    )}
                    {isEngineEnabled &&
                      manageWidgetsState.multipleVersionsSupport && (
                        <Banner tone='info'>
                          {i18n.translate(`MultipleVersions`)}
                          <Link url='http://www.google.com' target='_blank'>
                            {i18n.translate('Here')}
                          </Link>
                          .
                        </Banner>
                      )}
                  </BlockStack>
                </Card>
              </Element>
              {widgetInstallationData?.supportRequest?.status ===
                InstallationSupportRequestGeneralStatusDtoEnum.SUBMITTED && (
                <Element name='SupportDetails'>
                  <Card>
                    <BlockStack gap='200'>
                      <InlineStack align='space-between' blockAlign='center'>
                        <Text as='h2' variant='headingSm'>
                          {i18n.translate(`RequestSupport`)}
                        </Text>
                        <Button onClick={toggleSupportModal}>
                          {i18n.translate('ShowStatus')}
                        </Button>
                      </InlineStack>
                      <Text tone='subdued' as='p'>
                        {i18n.translate('YouSubmittedRequest')}
                        <Text fontWeight='medium' as='span'>
                          {convertDateCode(
                            widgetInstallationData?.supportRequest
                              ?.submissionDate || 0
                          )}
                        </Text>
                      </Text>
                    </BlockStack>
                  </Card>
                </Element>
              )}
              <Element name='Widgets'>
                <Card roundedAbove='sm'>
                  <BlockStack gap='400'>
                    <BlockStack gap='100'>
                      <Text as='h2' variant='headingSm'>
                        {i18n.translate(`Widgets`)}
                      </Text>

                      <Text as='p' tone='subdued'>
                        {i18n.translate(`BoostPromotion`)}
                      </Text>
                    </BlockStack>
                    {allWidgetsDisabled && (
                      <Banner tone='warning'>
                        {i18n.translate(`AllWidgetsTurned`)}
                      </Banner>
                    )}
                    {[
                      WidgetInstallationModeDtoEnum.APP_EMBED,
                      WidgetInstallationModeDtoEnum.APP_BLOCKS,
                      WidgetInstallationModeDtoEnum.CUSTOM,
                    ].map((mode) => getWidgetCardList(mode))}
                    <Bleed marginBlockEnd='400' marginInline='400'>
                      <Box background='bg-surface-secondary' padding='400'>
                        <BlockStack gap='200'>
                          <Text as='h3' tone='subdued'>
                            {i18n.translate(`ToConfigureVisual`)}
                            <Link url='http://www.google.com' target='_blank'>
                              {i18n.translate(`StylePresets`)}
                            </Link>
                          </Text>
                        </BlockStack>
                      </Box>
                    </Bleed>
                  </BlockStack>
                </Card>
              </Element>
            </>
          </BlockStack>
        </Page>
      ) : (
        <SettingsSkeleton />
      )}
      {isThemesModalOpen && (
        <ManageThemesModal
          themeSlotsData={themeSlots || []}
          themeSlotsDataIsFetching={themeSlotsDataIsFetching}
          isOpen={isThemesModalOpen}
          isSaving={saveThemeSlotsIsLoading}
          onClose={toggleThemesModal}
          onSave={(data) => handleSaveThemeSlots(data)}
        />
      )}
      {isEngineModalOpen && (
        <TogglePromotionEngineModal
          isEngineOn={!!manageWidgetsState?.promotionEngine?.enabled}
          link={manageWidgetsState?.promotionEngine?.installationDeepLink || ''}
          isOpen={isEngineModalOpen}
          hasActiveWidgets={!allWidgetsDisabled}
          onClose={toggleEngineModal}
        />
      )}
      {isSupportDetailsOpen && (
        <SupportDetailsModal
          isOpen={isSupportDetailsOpen}
          onRefetch={refetchWidgetInstallationData}
          onClose={toggleSupportModal}
        />
      )}
      {isRefreshModalOpen && (
        <WidgetRefreshModal
          isOpen={isRefreshModalOpen}
          onRefresh={refetchWidgetInstallationData}
        />
      )}
    </div>
  );
};
