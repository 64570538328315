import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import './CreateLinkModal.scss';
import { ModalCustom } from 'core/components/ModalCustom/ModalCustom';
import {
  ShopifyObjectDto,
  TriggerDiscountLinkDto,
  TriggerDiscountLinkUtmParametersDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { TriggerDiscountLinkLandingPageTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import {
  Divider,
  TextField,
  Text,
  Collapsible,
  Scrollable,
  BlockStack,
  Box,
  Checkbox,
  InlineStack,
  Button,
} from '@shopify/polaris';
import { ClipboardIcon } from '@shopify/polaris-icons';
import {
  ResourceSelectionProps,
  SearchFieldWithGrayBox,
} from 'core/components/SearchFieldWithGrayBox';
import { formateList } from 'core/utils/offerTargetTypeUtils';
import { useConfigureOffers } from 'features/promotions/hooks/useConfigureOffers';
import { debounce, isEqual } from 'lodash';
import { useParams } from 'react-router-dom';
import { usePrevious } from 'core/hooks/usePrevious';
import { useI18n } from '@shopify/react-i18n';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import { useConfigurePromotions } from 'features/promotions/hooks/useConfigurePromotion';

type CreateLinkModalProps = {
  discountLink?: TriggerDiscountLinkDto | null;
  isModalOpen: boolean;
  refetchList: () => void;
  onClose: () => void;
  onCopyLink: (value?: string) => void;
  vanityLinksUsed?: string[];
};

export const CreateLinkModal: React.FC<CreateLinkModalProps> = ({
  discountLink,
  isModalOpen,
  refetchList,
  onCopyLink,
  onClose,
  vanityLinksUsed,
}) => {
  const [i18n] = useI18n();
  const params = useParams();

  const {
    getDiscountLinkPreview,
    discountLinkPreviewData,
    discountLinkPreviewIsLoading,
  } = useConfigureOffers();
  const {
    createDiscountLink,
    createDiscountLinkIsLoading,
    updateDiscountLinkIsLoading,
    updateDiscountLink,
  } = useConfigurePromotions();
  const [isLandingPageValid, setIsLandingPageValid] = useState<boolean>(true);
  const [discountLinkPreviewError, setDiscountLinkPreviewError] =
    useState<boolean>(false);

  const isLandingPageValidRef = useRef<boolean>(isLandingPageValid);
  const isVanityLinkValidRef = useRef<boolean>(true);

  const currentDiscountLinkData: TriggerDiscountLinkDto = useMemo(
    () =>
      discountLink || {
        landingPageType: TriggerDiscountLinkLandingPageTypeDtoEnum.HOME_PAGE,
        blogPost: {
          blog: '',
          post: '',
        },
        customPage: '',
        utmParametersOption: {
          enabled: false,
          source: '',
          medium: '',
          campaign: '',
          term: '',
          content: '',
        },
        vanityLinkOption: {
          enabled: false,
          path: '',
        },
        notes: '',
      },
    [discountLink]
  );

  const [discountLinkState, setDiscountLinkState] =
    useState<TriggerDiscountLinkDto>(currentDiscountLinkData);

  const prevDiscountLinkState = usePrevious(discountLinkState);

  const initialUtmParameters = useMemo(() => {
    const { enabled, ...otherOptions } = discountLinkState.utmParametersOption!;
    return otherOptions;
  }, [discountLinkState.utmParametersOption]);

  const [loadedUtmParametersOption, setLoadedUtmParametersOption] =
    useState<Omit<TriggerDiscountLinkUtmParametersDto, 'enabled'>>(
      initialUtmParameters
    );

  const prevLoadedUtmParametersOption = usePrevious(loadedUtmParametersOption);

  const options = useMemo(
    () => [
      {
        label: i18n.translate(
          `${TriggerDiscountLinkLandingPageTypeDtoEnum.HOME_PAGE}_LABEL`
        ),
        value: TriggerDiscountLinkLandingPageTypeDtoEnum.HOME_PAGE,
      },
      {
        label: i18n.translate(
          `${TriggerDiscountLinkLandingPageTypeDtoEnum.COLLECTION}_LABEL`
        ),
        value: TriggerDiscountLinkLandingPageTypeDtoEnum.COLLECTION,
      },
      {
        label: i18n.translate(
          `${TriggerDiscountLinkLandingPageTypeDtoEnum.PRODUCT}_LABEL`
        ),
        value: TriggerDiscountLinkLandingPageTypeDtoEnum.PRODUCT,
      },
      {
        label: i18n.translate(
          `${TriggerDiscountLinkLandingPageTypeDtoEnum.CUSTOM_PAGE}_LABEL`
        ),
        value: TriggerDiscountLinkLandingPageTypeDtoEnum.CUSTOM_PAGE,
      },
      {
        label: i18n.translate(
          `${TriggerDiscountLinkLandingPageTypeDtoEnum.BLOG_POST}_LABEL`
        ),
        value: TriggerDiscountLinkLandingPageTypeDtoEnum.BLOG_POST,
      },
    ],
    [i18n]
  );

  const selectList: ShopifyObjectDto[] = useMemo(() => {
    if (
      discountLinkState.landingPageType ===
      TriggerDiscountLinkLandingPageTypeDtoEnum.PRODUCT
    ) {
      return discountLinkState?.product?.id ? [discountLinkState.product] : [];
    } else {
      return discountLinkState?.collection?.id
        ? [discountLinkState.collection]
        : [];
    }
  }, [discountLinkState.landingPageType]);

  const isVanityLinkValid = useMemo(() => {
    const conditions = [
      !discountLinkState.vanityLinkOption?.enabled,
      discountLinkState.vanityLinkOption?.enabled &&
        discountLinkState.vanityLinkOption?.path,
    ];
    return conditions.some((condition) => condition);
  }, [discountLinkState.vanityLinkOption]);

  const areAllPropertiesFalsy = useCallback(
    (obj: Omit<TriggerDiscountLinkUtmParametersDto, 'enabled'>): boolean => {
      return obj
        ? Object.values(obj).every((value) => !value || !value.trim())
        : true;
    },
    []
  );

  const shouldNotFetchAfterSwitch = useMemo(() => {
    const { enabled, ...otherOptions } = discountLinkState.utmParametersOption!;
    return !!(
      (areAllPropertiesFalsy(loadedUtmParametersOption) &&
        areAllPropertiesFalsy(prevLoadedUtmParametersOption)) ||
      (!discountLinkState.utmParametersOption?.enabled &&
        areAllPropertiesFalsy(otherOptions))
    );
  }, [
    loadedUtmParametersOption,
    prevLoadedUtmParametersOption,
    discountLinkState.utmParametersOption?.enabled,
  ]);

  const shouldUpdateDiscountLinkPreview = useMemo(
    () =>
      !discountLink ||
      (prevDiscountLinkState &&
        discountLinkState &&
        !isEqual(discountLinkState, prevDiscountLinkState)),
    [discountLink, prevDiscountLinkState, discountLinkState]
  );

  const updateDiscountLinkPreview = useCallback(
    debounce((draftId: string, requestData: TriggerDiscountLinkDto) => {
      if (isLandingPageValidRef.current) {
        getDiscountLinkPreview(
          draftId,
          requestData,
          discountLink as TriggerDiscountLinkDto,
          setDiscountLinkPreviewError
        );
      }
    }, 400),
    [getDiscountLinkPreview]
  );

  const onSearchFieldValidityChange = (formIsInvalid: boolean | null) => {
    formIsInvalid !== null ? setIsLandingPageValid(!formIsInvalid) : null;
  };

  const updateCurrentDiscountLinkState = useCallback(
    (
      field: keyof TriggerDiscountLinkDto,
      data: TriggerDiscountLinkDto[keyof TriggerDiscountLinkDto]
    ) => {
      setDiscountLinkState((prevState: TriggerDiscountLinkDto | null) => ({
        ...prevState,
        [field]: data,
      }));
    },
    [setDiscountLinkState]
  );

  const isCustomPageError = useMemo(
    () =>
      discountLinkState.landingPageType ===
        TriggerDiscountLinkLandingPageTypeDtoEnum.CUSTOM_PAGE &&
      (!discountLinkState.customPage || !discountLinkState.customPage.length),
    [discountLinkState.landingPageType, discountLinkState.customPage]
  );
  const isBlogPostError = useMemo(
    () =>
      discountLinkState.landingPageType ===
        TriggerDiscountLinkLandingPageTypeDtoEnum.BLOG_POST &&
      (!discountLinkState.blogPost?.blog ||
        !discountLinkState.blogPost?.blog.length ||
        !discountLinkState.blogPost?.post ||
        !discountLinkState.blogPost?.post.length),
    [discountLinkState.landingPageType, discountLinkState.blogPost]
  );

  const utmParameters = useMemo(
    () => [
      {
        label: i18n.translate('Source'),
        placeholder: i18n.translate('SourcePlaceholder'),
        field: 'source',
        value: discountLinkState.utmParametersOption?.source,
      },
      {
        label: i18n.translate('Medium'),
        placeholder: i18n.translate('MediumPlaceholder'),
        field: 'medium',
        value: discountLinkState.utmParametersOption?.medium,
      },
      {
        label: i18n.translate('Campaign'),
        placeholder: i18n.translate('CampaignPlaceholder'),
        field: 'campaign',
        value: discountLinkState.utmParametersOption?.campaign,
      },
      {
        label: i18n.translate('Term'),
        placeholder: i18n.translate('TermPlaceholder'),
        field: 'term',
        value: discountLinkState.utmParametersOption?.term,
      },
      {
        label: i18n.translate('Content'),
        placeholder: i18n.translate('ContentPlaceholder'),
        field: 'content',
        value: discountLinkState.utmParametersOption?.content,
      },
    ],
    [discountLinkState.utmParametersOption, i18n]
  );

  const landingPageTypeRender = useMemo(() => {
    switch (discountLinkState.landingPageType) {
      case TriggerDiscountLinkLandingPageTypeDtoEnum.COLLECTION:
        return (
          <SearchFieldWithGrayBox
            isDiscountLinkSearchField
            resourceType={'Collection'}
            selectList={selectList as ResourceSelectionProps[]}
            onSelectedChange={(list: ResourceSelectionProps[]) => {
              const data = formateList(list);
              updateCurrentDiscountLinkState('collection', data[0]);
            }}
            onFormValidityChange={onSearchFieldValidityChange}
          />
        );
      case TriggerDiscountLinkLandingPageTypeDtoEnum.PRODUCT:
        return (
          <SearchFieldWithGrayBox
            isDiscountLinkSearchField
            resourceType={'Product'}
            selectList={selectList as ResourceSelectionProps[]}
            onSelectedChange={(list: ResourceSelectionProps[]) => {
              const data = formateList(list);
              updateCurrentDiscountLinkState('product', data[0]);
            }}
            onFormValidityChange={onSearchFieldValidityChange}
          />
        );
      case TriggerDiscountLinkLandingPageTypeDtoEnum.CUSTOM_PAGE:
        return (
          <TextField
            value={discountLinkState.customPage || ''}
            onChange={(value) => {
              updateCurrentDiscountLinkState('customPage', value);
            }}
            label=''
            labelHidden
            placeholder={i18n.translate('customPagePlaceholder')}
            requiredIndicator
            error={
              (!discountLinkState.customPage ||
                !discountLinkState.customPage.length) &&
              i18n.translate('customPageErrorMessage')
            }
            autoComplete='off'
          />
        );
      case TriggerDiscountLinkLandingPageTypeDtoEnum.BLOG_POST:
        return (
          <BlockStack gap='300'>
            <TextField
              value={discountLinkState.blogPost?.blog || ''}
              onChange={(value) => {
                updateCurrentDiscountLinkState('blogPost', {
                  ...discountLinkState.blogPost,
                  blog: value,
                });
              }}
              label=''
              labelHidden
              placeholder={i18n.translate('blogPlaceholder')}
              requiredIndicator
              error={
                (!discountLinkState.blogPost?.blog ||
                  !discountLinkState.blogPost?.blog.length) &&
                i18n.translate('customPageErrorMessage')
              }
              autoComplete='off'
            />
            <TextField
              value={discountLinkState.blogPost?.post || ''}
              onChange={(value) => {
                updateCurrentDiscountLinkState('blogPost', {
                  ...discountLinkState.blogPost,
                  post: value,
                });
              }}
              label=''
              labelHidden
              placeholder={i18n.translate('blogPostPlaceholder')}
              requiredIndicator
              error={
                (!discountLinkState.blogPost?.post ||
                  !discountLinkState.blogPost?.post.length) &&
                i18n.translate('customPageErrorMessage')
              }
              autoComplete='off'
            />
          </BlockStack>
        );
    }
  }, [
    discountLinkState.landingPageType,
    selectList,
    discountLinkState.customPage,
    discountLinkState.blogPost,
    i18n,
  ]);

  const vanityLinkError = useMemo(() => {
    if (!isVanityLinkValid) {
      return i18n.translate('VanityLinkValid');
    } else if (
      !isVanityLinkValidRef.current ||
      discountLinkPreviewError ||
      (vanityLinksUsed?.includes(
        discountLinkState.vanityLinkOption?.path || ''
      ) &&
        discountLinkState.vanityLinkOption?.path !==
          discountLink?.vanityLinkOption?.path)
    ) {
      return i18n.translate('VanityLinkUniqueValid');
    }
  }, [
    isVanityLinkValidRef.current,
    isVanityLinkValid,
    discountLinkState.vanityLinkOption?.path,
    discountLinkPreviewError,
    vanityLinksUsed,
  ]);

  const isError = useMemo(
    () => isBlogPostError || isCustomPageError,
    [isBlogPostError, isCustomPageError]
  );

  const prevUtmParametersOptionEnabled = usePrevious(
    discountLinkState.utmParametersOption?.enabled
  );

  const payloadData = useMemo(
    () => ({
      ...discountLinkState,
      utmParametersOption: {
        ...discountLinkState.utmParametersOption,
        source: discountLinkState.utmParametersOption?.source || undefined,
        medium: discountLinkState.utmParametersOption?.medium || undefined,
        campaign: discountLinkState.utmParametersOption?.campaign || undefined,
        term: discountLinkState.utmParametersOption?.term || undefined,
        content: discountLinkState.utmParametersOption?.content || undefined,
      },
    }),
    [discountLinkState]
  );

  const onSaveDiscountLink = useCallback(() => {
    if (!discountLink) {
      createDiscountLink(payloadData).then(() => {
        refetchList();
        onClose();
      });
    } else {
      updateDiscountLink(payloadData, discountLink.id as string).then(() => {
        refetchList();
        onClose();
      });
    }
  }, [payloadData, discountLink, createDiscountLink, updateDiscountLink]);

  useEffect(() => {
    if (
      !shouldNotFetchAfterSwitch &&
      discountLinkState &&
      params.promotionId &&
      typeof prevUtmParametersOptionEnabled === 'boolean' &&
      discountLinkState.utmParametersOption?.enabled !==
        prevUtmParametersOptionEnabled
    ) {
      updateDiscountLinkPreview(params.promotionId, payloadData);
    }
  }, [
    shouldNotFetchAfterSwitch,
    params.promotionId,
    payloadData.utmParametersOption?.enabled,
    prevUtmParametersOptionEnabled,
  ]);

  useEffect(() => {
    onSearchFieldValidityChange(isError);
  }, [isError]);

  useEffect(() => {
    if (
      params.promotionId &&
      isLandingPageValid &&
      isVanityLinkValid &&
      discountLinkState &&
      shouldUpdateDiscountLinkPreview
    ) {
      updateDiscountLinkPreview(params.promotionId, payloadData);
    }
  }, [
    params.promotionId,
    isLandingPageValid,
    isVanityLinkValid,
    discountLinkState?.collection,
    discountLinkState.customPage,
    discountLinkState.blogPost,
    discountLinkState?.product,
    discountLinkState.utmParametersOption?.campaign,
    discountLinkState.utmParametersOption?.content,
    discountLinkState.utmParametersOption?.medium,
    discountLinkState.utmParametersOption?.source,
    discountLinkState.utmParametersOption?.term,
    discountLinkState.landingPageType,
  ]);

  const prevVanityLinkPath = usePrevious(
    discountLinkState.vanityLinkOption?.path
  );

  useEffect(() => {
    if (
      params.promotionId &&
      ((typeof prevVanityLinkPath === 'string' &&
        discountLinkState.vanityLinkOption?.path !== prevVanityLinkPath) ||
        discountLinkState.vanityLinkOption?.enabled) &&
      discountLinkState
    ) {
      updateDiscountLinkPreview(params.promotionId, payloadData);
    }
  }, [discountLinkState.vanityLinkOption, params.promotionId]);

  useEffect(() => {
    if (discountLinkPreviewData) {
      updateCurrentDiscountLinkState('linkPreview', discountLinkPreviewData);
    }
  }, [discountLinkPreviewData]);

  useEffect(() => {
    isLandingPageValidRef.current =
      isLandingPageValid &&
      isVanityLinkValid &&
      ((discountLinkState.vanityLinkOption?.enabled &&
        !vanityLinksUsed?.includes(
          discountLinkState.vanityLinkOption?.path || ''
        )) ||
        !discountLinkState.vanityLinkOption?.enabled);
  }, [
    isLandingPageValid,
    isVanityLinkValid,
    discountLinkState.vanityLinkOption,
    vanityLinksUsed,
  ]);

  useEffect(() => {
    if (
      discountLinkState.vanityLinkOption?.enabled &&
      !discountLinkPreviewError &&
      !vanityLinksUsed?.includes(discountLinkState.vanityLinkOption?.path || '')
    ) {
      isVanityLinkValidRef.current = true;
    } else if (!discountLinkState.vanityLinkOption?.enabled) {
      isVanityLinkValidRef.current = true;
    } else if (
      discountLinkState.vanityLinkOption?.enabled &&
      (discountLinkPreviewError ||
        vanityLinksUsed?.includes(
          discountLinkState.vanityLinkOption?.path || ''
        ))
    ) {
      isVanityLinkValidRef.current = false;
    }
  }, [
    discountLinkState.vanityLinkOption,
    discountLinkPreviewError,
    vanityLinksUsed,
  ]);

  return (
    <ModalCustom
      isOpen={isModalOpen}
      onClose={onClose}
      title={i18n.translate(discountLink ? 'EditLink' : 'CreateLink')}
      buttons={[
        {
          content: i18n.translate('Discard'),
          primary: false,
          action: onClose,
        },
        {
          content: i18n.translate('Save'),
          primary: true,
          loading:
            discountLinkPreviewIsLoading ||
            createDiscountLinkIsLoading ||
            updateDiscountLinkIsLoading,
          disabled:
            !isLandingPageValid ||
            !isVanityLinkValid ||
            !isVanityLinkValidRef.current ||
            discountLinkPreviewError ||
            vanityLinksUsed?.includes(
              discountLinkState.vanityLinkOption?.path || ''
            ),
          action: onSaveDiscountLink,
        },
      ]}
      modalClass='CreateLinkModal'
      portalClass='LinkPortal'
    >
      <Scrollable
        style={{
          maxHeight: '70vh',
          padding: 'var(--p-space-400)',
        }}
      >
        <BlockStack gap='400'>
          <SelectOptions
            onOptionSelect={(value) =>
              updateCurrentDiscountLinkState('landingPageType', value)
            }
            options={options}
            selectedOption={discountLinkState.landingPageType as string}
            label={i18n.translate('LandingPageTypeLabel')}
            helpText={i18n.translate('LandingPageTypeHelpText')}
          />
          {landingPageTypeRender}
          <Box borderColor='border-brand' borderWidth='025' borderRadius='200'>
            <Box padding='400'>
              <BlockStack gap='400'>
                <Checkbox
                  label={i18n.translate('UTMParameters')}
                  helpText={i18n.translate('UTMParametersSubtitle')}
                  checked={!!discountLinkState.utmParametersOption?.enabled}
                  onChange={() => {
                    const { enabled, ...otherOptions } =
                      discountLinkState.utmParametersOption!;
                    updateCurrentDiscountLinkState('utmParametersOption', {
                      ...discountLinkState.utmParametersOption,
                      enabled: !discountLinkState.utmParametersOption?.enabled,
                    });
                    setLoadedUtmParametersOption(otherOptions);
                  }}
                />
                <Collapsible
                  id='collapsible'
                  open={!!discountLinkState.utmParametersOption?.enabled}
                >
                  <BlockStack gap='300'>
                    {utmParameters.map((parameter, idx) => (
                      <TextField
                        key={parameter.field}
                        label={parameter.label}
                        autoComplete='off'
                        placeholder={parameter.placeholder}
                        value={parameter.value || ''}
                        onChange={(value) => {
                          updateCurrentDiscountLinkState(
                            'utmParametersOption',
                            {
                              ...discountLinkState.utmParametersOption,
                              [parameter.field]: value,
                            }
                          );
                        }}
                      />
                    ))}
                  </BlockStack>
                </Collapsible>
              </BlockStack>
            </Box>
            <Divider />

            <Box padding='400'>
              <BlockStack gap='400'>
                <Checkbox
                  label={i18n.translate('VanityLink')}
                  helpText={i18n.translate('VanityLinkSubtitle')}
                  checked={!!discountLinkState.vanityLinkOption?.enabled}
                  onChange={() =>
                    updateCurrentDiscountLinkState('vanityLinkOption', {
                      ...discountLinkState.vanityLinkOption,
                      enabled: !discountLinkState.vanityLinkOption?.enabled,
                    })
                  }
                />
                <Collapsible
                  id='collapsible'
                  open={!!discountLinkState.vanityLinkOption?.enabled}
                >
                  <TextField
                    placeholder={i18n.translate('VanityLinkPlaceholder')}
                    label=''
                    labelHidden
                    value={discountLinkState?.vanityLinkOption?.path || ''}
                    onChange={(value) =>
                      updateCurrentDiscountLinkState('vanityLinkOption', {
                        ...discountLinkState?.vanityLinkOption,
                        path: value.trim(),
                      })
                    }
                    error={vanityLinkError ? vanityLinkError : false}
                    autoComplete='off'
                  />
                </Collapsible>
              </BlockStack>
            </Box>
          </Box>
          <Box
            background='bg-fill-disabled'
            borderColor='border-brand'
            borderWidth='025'
            borderRadius='200'
            padding='300'
          >
            <BlockStack gap='200'>
              {discountLinkState?.vanityLinkOption?.enabled && (
                <InlineStack align='space-between'>
                  <Box width='90%'>
                    <InlineStack align='start' wrap={false} gap='200'>
                      <Box width='72px'>
                        <Text as='p' tone='subdued'>
                          {i18n.translate('Vanity')}
                        </Text>
                      </Box>
                      <div className='LinkPreviewText'>
                        <Text as='p' tone='base'>
                          /{discountLinkState?.vanityLinkOption?.path}
                        </Text>
                      </div>
                    </InlineStack>
                  </Box>
                  <Button
                    onClick={() =>
                      onCopyLink(
                        `/${discountLinkState?.vanityLinkOption?.path}`
                      )
                    }
                    icon={ClipboardIcon}
                    variant='monochromePlain'
                  />
                </InlineStack>
              )}

              <InlineStack wrap={false} align='space-between'>
                <Box width='90%'>
                  <InlineStack align='start' wrap={false} gap='200'>
                    <Box minWidth='72px'>
                      <Text as='p' tone='subdued'>
                        {i18n.translate('Link')}
                      </Text>
                    </Box>
                    <div className='LinkPreviewText'>
                      <Text as='p' tone='base'>
                        {discountLinkPreviewData?.display}
                      </Text>
                    </div>
                  </InlineStack>
                </Box>
                <Button
                  onClick={() => onCopyLink(discountLinkPreviewData?.copy)}
                  icon={ClipboardIcon}
                  variant='monochromePlain'
                />
              </InlineStack>
            </BlockStack>
          </Box>
        </BlockStack>
      </Scrollable>
    </ModalCustom>
  );
};
