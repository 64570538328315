import React from 'react';
import { Card, BlockStack, Text, Banner, Link } from '@shopify/polaris';
import { css as CSS } from '@codemirror/lang-css';
import { useI18n } from '@shopify/react-i18n';
import CodeMirror from '@uiw/react-codemirror';
import './MandatoryCheckboxes.scss';
import { useFormatCode } from 'core/hooks';

type MandatoryCheckboxes = {
  data: string | undefined;
  section: string;
  handleCartBehaviorLocaleState: (
    section: string,
    value: string,
    field?: string
  ) => void;
};

export const MandatoryCheckboxes: React.FC<MandatoryCheckboxes> = ({
  data,
  section,
  handleCartBehaviorLocaleState,
}) => {
  const [i18n] = useI18n();

  // Andriy/Vitalii pls fix this
  const { handleFormatCode } = useFormatCode();

  return (
    <div className='MandatoryCheckboxes'>
      <Card roundedAbove='sm' padding='400'>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='h2' variant='headingSm'>
              {i18n.translate('MandatoryCheckboxesTitle')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('MandatoryCheckboxesDescription')}
            </Text>
          </BlockStack>
          <Banner tone='info'>
            {i18n.translate('Tip')}{' '}
            <Link url='http://www.google.com' target='_blank'>
              {i18n.translate('TipLink')}
            </Link>
          </Banner>
          <BlockStack>
            <Text as='p' variant='bodyMd'>
              {i18n.translate('CSSSelector')}
            </Text>
            <div className='CSSEditor'>
              <CodeMirror
                value={data || ''}
                height='120px'
                extensions={[CSS()]}
                onChange={(value) =>
                  handleCartBehaviorLocaleState(section, value)
                }
                editable={true}
              />
            </div>
          </BlockStack>
        </BlockStack>
      </Card>
    </div>
  );
};
