import { OfferTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import {
  percentageIconLookup,
  createPromotionOffersDollarIconLookup,
  createPromotionOffersLockIconLookup,
  createPromotionOffersGiftIconLookup,
  createPromotionOffersGiftsIconLookup,
  createPromotionOffersCartIconLookup,
  createPromotionOffersArrowsCrossIconLookup,
  createPromotionOffersRectangleHistoryIconLookup,
  createPromotionOffersTruckFastIconLookup,
  createPromotionOffersTruckBoltIconLookup,
  createPromotionOffersPalletBoxIconLookup,
  createPromotionOffersTagIconLookup,
  createPromotionOffersTagsIconLookup,
  createPromotionOffersLinkHorizontalIconLookup,
  createPromotionOffersBoxesStackedIconLookup,
  createPromotionOffersBadgeDollarIconLookup,
} from 'core/components/iconsDefinitions';
import { OfferTypesFromCatalogDataModel } from '../types/OfferTypesFromCatalogTypes';

export const OfferTypesFromCatalogData: OfferTypesFromCatalogDataModel = {
  Sales: [
    {
      title: 'PercentageDiscountTitle',
      subtitle: 'PercentageDiscountSubtitle',
      icon: percentageIconLookup,
      type: OfferTypeDtoEnum.SALES_PERCENTAGE,
    },
    {
      title: 'FixedAmountDiscountTitle',
      subtitle: 'FixedAmountDiscountSubtitle',
      icon: createPromotionOffersDollarIconLookup,
      type: OfferTypeDtoEnum.SALES_FIXED_AMOUNT,
    },
    {
      title: 'FixedPricePerUnitTitle',
      subtitle: 'FixedPricePerUnitSubtitle',
      icon: createPromotionOffersLockIconLookup,
      type: OfferTypeDtoEnum.SALES_FIXED_PRICE,
    },
  ],
  FreeGift: [
    {
      title: 'OfferAFreeGiftAutomaticallyTitle',
      subtitle: 'OfferAFreeGiftAutomaticallySubtitle',
      icon: createPromotionOffersGiftIconLookup,
      type: OfferTypeDtoEnum.GIFT_AUTOMATIC,
    },
    {
      title: 'PickAGiftTitle',
      subtitle: 'PickAGiftSubtitle',
      icon: createPromotionOffersGiftsIconLookup,
      type: OfferTypeDtoEnum.GIFT_MANUAL,
    },
    {
      title: 'OrderBumpTitle',
      subtitle: 'OrderBumpSubtitle',
      icon: createPromotionOffersBoxesStackedIconLookup,
      type: OfferTypeDtoEnum.BUNDLE_ORDER_DISCOUNT,
    },
  ],
  BuyOneGetOne: [
    {
      title: 'BOGOTitle',
      subtitle: 'BOGOSubtitle',
      icon: createPromotionOffersCartIconLookup,
      type: OfferTypeDtoEnum.BOGO_REGULAR,
    },
    {
      title: 'BOGOTitle',
      subtitle: 'BOGOSubtitle',
      icon: createPromotionOffersCartIconLookup,
      type: OfferTypeDtoEnum.BOGO_STRICT_MATCH,
    },
  ],
  IncreaseAverageOrderValue: [
    {
      title: 'CrossSellTitle',
      subtitle: 'CrossSellSubtitle',
      icon: createPromotionOffersArrowsCrossIconLookup,
      type: OfferTypeDtoEnum.CROSS_SELL,
    },
  ],
  TieredDiscounts: [
    {
      title: 'MultiTierBOGOTitle',
      subtitle: 'MultiTierBOGOSubtitle',
      icon: createPromotionOffersRectangleHistoryIconLookup,
      type: OfferTypeDtoEnum.TIERED_BOGO,
    },
    {
      title: 'SpendAmountGet$OffTitle',
      subtitle: 'SpendAmountGet$OffSubtitle',
      icon: createPromotionOffersBadgeDollarIconLookup,
      type: OfferTypeDtoEnum.TIERED_SPEND_X_GET_Y,
    },
  ],
  VolumeDiscount: [
    {
      title: 'VolumeTitle',
      subtitle: 'VolumeSubtitle',
      icon: createPromotionOffersBoxesStackedIconLookup,
      type: OfferTypeDtoEnum.VOLUME_DISCOUNT,
    },
  ],
  RelatedProducts: [
    {
      title: 'FrequentlyBoughtTogetherTitle',
      subtitle: 'FrequentlyBoughtTogetherSubtitle',
      icon: createPromotionOffersBoxesStackedIconLookup,
      type: OfferTypeDtoEnum.FREQUENTLY_BOUGHT_TOGETHER,
    },
    {
      title: 'OrderBumpTitle',
      subtitle: 'OrderBumpSubtitle',
      icon: createPromotionOffersBoxesStackedIconLookup,
      type: OfferTypeDtoEnum.ORDER_BUMP,
    },
  ],
  FreeShipping: [
    {
      title: 'FreeShippingToAnyCountryTitle',
      subtitle: 'FreeShippingToAnyCountrySubtitle',
      icon: createPromotionOffersTruckFastIconLookup,
      type: OfferTypeDtoEnum.FREE_SHIPPING,
    },
    {
      title: 'FreeShippingForZonesTitle',
      subtitle: 'FreeShippingForZonesSubtitle',
      icon: createPromotionOffersTruckBoltIconLookup,
      type: OfferTypeDtoEnum.SHIPPING_FIXED_AMOUNT,
    },
    {
      title: 'PerProductShippingFeeTitle',
      subtitle: 'PerProductShippingFeeSubtitle',
      icon: createPromotionOffersPalletBoxIconLookup,
      type: OfferTypeDtoEnum.SHIPPING_PERCENTAGE,
    },
  ],
  ShopifyDiscountCodeWrapper: [
    {
      title: 'ApplyAShopifyDiscountTitle',
      subtitle: 'ApplyAShopifyDiscountSubtitle',
      icon: createPromotionOffersTagIconLookup,
      type: OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_REGULAR,
    },
    {
      title: 'ApplyAUniqueShopifyDiscountTitle',
      subtitle: 'ApplyAUniqueShopifyDiscountSubtitle',
      icon: createPromotionOffersTagsIconLookup,
      type: OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_GROUP,
    },
    {
      title: 'IntegrateWithOtherAppsTitle',
      subtitle: 'IntegrateWithOtherAppsSubtitle',
      icon: createPromotionOffersLinkHorizontalIconLookup,
      type: OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_APP,
    },
  ],
};
