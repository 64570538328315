import React, { useCallback, useMemo } from 'react';
import {
  NotificationCollapsedStateLevelPresetDtoRead,
  NotificationCollapsedStatePresetDtoRead,
  NotificationPresetDto,
  NotificationPresetDtoRead,
  NotificationSystemDefaultsDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { getLastSelectedOption } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import {
  WidgetListOptionsDtoEnum,
  WidgetListTypeDtoEnum,
} from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { NotificationStatesPromotionEntryTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { SelectedOptionPath } from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightSideBarWrapper';
import NotificationGeneral from '../NotificationGeneral/NotificationGeneral';
import NotificationHeader from '../NotificationHeader/NotificationHeader';

type PresetNotificationCollapsedProps = {
  selectedOptionPath: SelectedOptionPath;
  notification: NotificationPresetDtoRead;
  defaultPresetData: NotificationPresetDtoRead;
  shopDefaultData: NotificationSystemDefaultsDto;
  defaultLanguage: string;
  updateNotification: (data: NotificationPresetDto) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const PresetNotificationCollapsed: React.FC<
  PresetNotificationCollapsedProps
> = ({
  selectedOptionPath,
  notification,
  defaultPresetData,
  shopDefaultData,
  defaultLanguage,
  updateNotification,
  handleLeftMenuSelection,
}) => {
  const updateNotificationGeneral = useCallback(
    (
      field: keyof NotificationCollapsedStatePresetDtoRead,
      data: NotificationCollapsedStatePresetDtoRead[keyof NotificationCollapsedStatePresetDtoRead]
    ) =>
      updateNotification({
        ...notification,
        options: {
          ...notification.options,
          collapsedState: {
            ...notification.options?.collapsedState,
            [field]: data,
          },
        },
      }),
    [notification]
  );

  const updateNotificationOption = useCallback(
    (
      field: keyof NotificationCollapsedStateLevelPresetDtoRead,
      data: NotificationCollapsedStateLevelPresetDtoRead[keyof NotificationCollapsedStateLevelPresetDtoRead]
    ) => {
      updateNotification({
        ...notification,
        options: {
          ...notification.options,
          collapsedState: {
            ...notification.options?.collapsedState,
            options: {
              ...notification.options?.collapsedState?.options,
              [field]: data,
            },
          },
        },
      });
    },
    [updateNotification, notification]
  );

  const handleOptionVisability = useCallback(
    (option: keyof NotificationCollapsedStateLevelPresetDtoRead) =>
      updateNotificationOption(option, {
        ...notification.options?.collapsedState?.options?.[option],
        isHidden:
          !notification.options?.collapsedState?.options?.[option]?.isHidden,
      }),
    []
  );

  const notificationOptions = useMemo(() => {
    const selectedOption = getLastSelectedOption(selectedOptionPath);
    switch (true) {
      case selectedOption?.name === WidgetListTypeDtoEnum.NOTIFICATION:
        return (
          <NotificationGeneral
            shopDefaultGeneral={shopDefaultData?.collapsedState?.general || {}}
            general={notification.options?.collapsedState?.general || {}}
            defaultGeneral={
              defaultPresetData.options?.collapsedState?.general || {}
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
            isPresetEditor
            setGeneral={(data) => updateNotificationGeneral('general', data)}
            notificationState={
              NotificationStatesPromotionEntryTypeDtoEnum.COLLAPSED_STATE
            }
            onShowHide={
              notification.options?.collapsedState?.canBeHidden
                ? () =>
                    updateNotificationGeneral(
                      'isHidden',
                      !notification.options?.collapsedState?.isHidden
                    )
                : undefined
            }
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.HEADER:
        return (
          <NotificationHeader
            header={notification.options?.collapsedState?.options?.header || {}}
            defaultHeader={
              defaultPresetData.options?.collapsedState?.options?.header || {}
            }
            shopDefaultHeader={
              shopDefaultData.collapsedState?.options?.header || {}
            }
            isPresetEditor
            notificationState={
              NotificationStatesPromotionEntryTypeDtoEnum.COLLAPSED_STATE
            }
            setHeader={(data) => updateNotificationOption('header', data)}
            onShowHide={
              notification.options?.collapsedState?.options?.header?.canBeHidden
                ? () => handleOptionVisability('header')
                : undefined
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
            defaultLanguage={defaultLanguage}
          />
        );
    }
  }, [
    selectedOptionPath,
    shopDefaultData,
    notification,
    defaultPresetData,
    updateNotification,
    handleLeftMenuSelection,
    handleOptionVisability,
  ]);
  return <div>{notificationOptions}</div>;
};

export default PresetNotificationCollapsed;
