import {
  Badge,
  Banner,
  BlockStack,
  Card,
  Checkbox,
  ChoiceList,
  Divider,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { ShopifyPromotionCodesCombinationsDto } from 'core/api/adminSettings/adminSettingsApi';
import {
  ShopifyPromotionCodesCombinationModeDtoEnum,
  ShopifyPromotionCodesStackingBehaviorDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import React, { useMemo } from 'react';
type CodesCombinationsProps = {
  combinations: ShopifyPromotionCodesCombinationsDto;
  setCombinations: (value: ShopifyPromotionCodesCombinationsDto) => void;
};
export const CodesCombinations: React.FC<CodesCombinationsProps> = ({
  combinations,
  setCombinations,
}) => {
  const [i18n] = useI18n();

  const modeOptions = useMemo(
    () =>
      Object.values(ShopifyPromotionCodesCombinationModeDtoEnum).map(
        (value) => ({
          label: (
            <InlineStack gap='200'>
              {i18n.translate(`${value}`)}
              {value ===
              ShopifyPromotionCodesCombinationModeDtoEnum.OFFER_SPECIFIC ? (
                <Badge>{i18n.translate('Recommended')}</Badge>
              ) : (
                <Badge tone='warning'>{i18n.translate('Legacy')}</Badge>
              )}
            </InlineStack>
          ),
          value: value,
          helpText: i18n.translate(`${value}_HELP`),
        })
      ),
    [i18n]
  );
  const stackingOptions = useMemo(
    () =>
      Object.values(ShopifyPromotionCodesStackingBehaviorDtoEnum).map(
        (value) => ({
          label: i18n.translate(`${value}`),
          value: value,
          helpText: i18n.translate(`${value}_HELP`),
        })
      ),
    [i18n]
  );

  return (
    <>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('CombineWithOther')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('Subtitle')}
            </Text>
          </BlockStack>
          <ChoiceList
            title=''
            choices={modeOptions}
            selected={[
              combinations.mode ||
                ShopifyPromotionCodesCombinationModeDtoEnum.OFFER_SPECIFIC,
            ]}
            onChange={(value) =>
              setCombinations({
                ...combinations,
                mode: value[0] as ShopifyPromotionCodesCombinationModeDtoEnum,
              })
            }
          />
        </BlockStack>
      </Card>
      {combinations.mode ===
        ShopifyPromotionCodesCombinationModeDtoEnum.SHOP_SETTINGS && (
        <Card>
          <BlockStack gap='400'>
            <BlockStack gap='100'>
              <Text as='p' fontWeight='bold'>
                {i18n.translate('StackingBehavior')}
              </Text>
              <Text as='p' tone='subdued'>
                {i18n.translate('StackingSubtitle')}
              </Text>
            </BlockStack>
            <Banner tone='info'>{i18n.translate('BannerText')}</Banner>
            <ChoiceList
              title=''
              choices={stackingOptions}
              selected={[
                combinations.stackingBehavior ||
                  ShopifyPromotionCodesStackingBehaviorDtoEnum.ALWAYS_STACK_PRODUCT_LEVEL_OFFERS,
              ]}
              onChange={(value) =>
                setCombinations({
                  ...combinations,
                  stackingBehavior:
                    value[0] as ShopifyPromotionCodesStackingBehaviorDtoEnum,
                })
              }
            />
            <Divider />
            <Checkbox
              label={i18n.translate('StackOrderLevel')}
              checked={!!combinations.stackOrderLevel}
              onChange={(value) =>
                setCombinations({ ...combinations, stackOrderLevel: value })
              }
            />
          </BlockStack>
        </Card>
      )}
    </>
  );
};
