import React from 'react';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import { useI18n } from '@shopify/react-i18n';
import { PositionComponentDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { PositionDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import { Banner } from '@shopify/polaris';

type PositionProps = {
  position: PositionComponentDto;
  bannerText?: string;
  disabled?: boolean;
  inherited?: boolean;
  setPosition?: (value: PositionComponentDto) => void;
  onSystemReset?: () => void;
};
export const Position: React.FC<PositionProps> = ({
  position,
  disabled,
  bannerText,
  inherited,
  setPosition,
  onSystemReset,
}) => {
  const [i18n] = useI18n();

  const positionOptions = Object.values(PositionDtoEnum).map((value) => {
    return {
      value: value,
      label: i18n.translate(`${value}`),
    };
  });
  return (
    <div className='Position'>
      <StyleComponentHeader
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            disabled: disabled,
            onAction: () => onSystemReset?.(),
          },
        ]}
        inherited={inherited}
        label={i18n.translate(`Position`)}
      >
        <SelectOptions
          options={positionOptions}
          onOptionSelect={(value) =>
            setPosition?.({ position: value as PositionDtoEnum })
          }
          selectedOption={position.position || PositionDtoEnum.TOP_RIGHT}
          label=''
          disabled={disabled}
        />
        {bannerText && <Banner>{bannerText}</Banner>}
      </StyleComponentHeader>
    </div>
  );
};
