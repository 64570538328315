import React, { useCallback } from 'react';
import { Element } from 'react-scroll';
import {
  Badge,
  BlockStack,
  Button,
  Card,
  Icon,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { ChevronRightIcon, StoreIcon } from '@shopify/polaris-icons';
import ColorBlock from '../ColorBlock/ColorBlock';
import { DateStyle, useI18n } from '@shopify/react-i18n';
import {
  GetPresetListResponseDto,
  PresetPreviewDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import './ShopLevel.scss';
import { useNavigate } from 'react-router-dom';
import { useIsDebugOrLocal } from 'core/hooks';

type ShopLevel = {
  onLevelClick: (subPath: string) => void;
  defaultShopLevelStylePreset: PresetPreviewDto | null;
  shopLevelStylePresets: GetPresetListResponseDto | null;
};

export const ShopLevel: React.FC<ShopLevel> = ({
  onLevelClick,
  defaultShopLevelStylePreset,
  shopLevelStylePresets,
}) => {
  const [i18n] = useI18n();
  const isDebugOrLocal = useIsDebugOrLocal();
  const navigate = useNavigate();

  const navigateToEditor = useCallback(async () => {
    !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
    navigate(`/configure-widgets/${defaultShopLevelStylePreset?.id}`);
  }, [navigate, isDebugOrLocal, defaultShopLevelStylePreset?.id]);

  return (
    <Element name='ShopLevel'>
      <div className='cardWrapper'>
        <Card roundedAbove='sm'>
          <div className='level'>
            <InlineStack gap={'300'} blockAlign='center'>
              <Icon tone='base' source={StoreIcon} />
              <BlockStack gap='0'>
                <Text as='p'>{i18n.translate('shopLevelTitle')}</Text>
                <Text tone='subdued' as='p'>
                  {i18n.translate('shopLevelSubtitle')}
                </Text>
              </BlockStack>
            </InlineStack>
            <div
              className='navigate'
              onClick={() => onLevelClick('shop-level_style_presets')}
            >
              <Badge>
                {`${shopLevelStylePresets?.totalItems} ${
                  shopLevelStylePresets?.totalItems === 1
                    ? i18n.translate('style')
                    : i18n.translate('styles')
                }`}
              </Badge>
              <Icon tone='base' source={ChevronRightIcon} />
            </div>
          </div>
          <div className='shopLevelDefaultStyle'>
            <Text as='p'>{i18n.translate('activeStyle')}</Text>
            <Card roundedAbove='sm'>
              <div className='level'>
                <InlineStack gap={'300'} blockAlign='center'>
                  <ColorBlock
                    colors={defaultShopLevelStylePreset?.colors || []}
                  />
                  <BlockStack gap='0'>
                    <Text as='p'>{defaultShopLevelStylePreset?.name}</Text>
                    <Text tone='subdued' as='p'>
                      {`${
                        defaultShopLevelStylePreset?.type &&
                        i18n.translate(defaultShopLevelStylePreset?.type)
                      } ${
                        defaultShopLevelStylePreset?.lastModifiedAt &&
                        `• ${i18n.translate('EditedOn')} ${i18n.formatDate(
                          new Date(defaultShopLevelStylePreset.lastModifiedAt),
                          { style: DateStyle.Short }
                        )}`
                      }`}
                    </Text>
                  </BlockStack>
                </InlineStack>
                <Button size='medium' onClick={navigateToEditor}>
                  {i18n.translate('configure')}
                </Button>
              </div>
            </Card>
          </div>
        </Card>
      </div>
    </Element>
  );
};
