import React, { useCallback } from 'react';
import { Badge, IndexTable, Text } from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';
import { PromotionCodeDto } from 'core/api/adminPromotions/adminPromotionsApi';

import { I18n } from '@shopify/react-i18n';
import { PromotionCodeStatusDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';

type PromotionCodeRowProps = {
  index: number;
  selectedResources: string[];
  codeData: PromotionCodeDto;
  i18n: I18n;
};

const PromotionCodeRow: React.FC<PromotionCodeRowProps> = ({
  index,
  selectedResources,
  codeData,
  i18n,
}) => {
  const isCodeActive = codeData.status === PromotionCodeStatusDtoEnum.ACTIVE;

  return (
    <IndexTable.Row
      id={codeData.id as string}
      selected={selectedResources.includes(codeData.id as string)}
      position={index}
    >
      <IndexTable.Cell>
        <Text as='p' fontWeight='medium'>
          {codeData.code}
        </Text>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Badge tone={isCodeActive ? 'success' : undefined}>
          {i18n.translate(isCodeActive ? 'Active' : 'Used')}
        </Badge>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Text as='p'>
          {codeData.usageLimit === 0 ? (
            i18n.translate('Unlimited')
          ) : (
            <>
              {codeData.usageCount}/{codeData.usageLimit}
            </>
          )}
        </Text>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Text as='p'>{codeData.usedInOrder ? codeData.usedInOrder : '-'}</Text>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Text as='p'>
          {codeData.usedByCustomer ? codeData.usedByCustomer : '-'}
        </Text>
      </IndexTable.Cell>
    </IndexTable.Row>
  );
};

export default PromotionCodeRow;
