import { useAppDispatch } from 'core/hooks';
import { setToastMessages } from 'core/store/offersWizardSlice';
import resolveEnvVar from 'env-var-resolver';
import { DetailedChartTypeEnum } from 'pages';
import { useState } from 'react';

const analyticsApiURL = `${resolveEnvVar(
  'REACT_APP_ANALYTICS_ADMIN_API_URL'
)}/analytics/v6/`;

const useExportAnalytics = (chartType: DetailedChartTypeEnum) => {
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';
  const dispatch = useAppDispatch();
  const [exportAnalyticsIsFetching, setExportAnalyticsIsFetching] =
    useState<boolean>(false);

  const getExportLink = () => {
    switch (chartType) {
      case DetailedChartTypeEnum.DN_CONVERSION_RATE:
        return 'conversionRate/export';
      case DetailedChartTypeEnum.DN_SESSIONS:
        return 'sessions/export';
      case DetailedChartTypeEnum.TOTAL_DN_ORDERS:
        return 'totalOrders/export';
      case DetailedChartTypeEnum.TOTAL_DN_REVENUE:
        return 'totalRevenue/export';
    }
  };

  const exportAnalytics = async () => {
    setExportAnalyticsIsFetching(true);
    await fetch(`${analyticsApiURL}/${getExportLink()}`, {
      method: 'GET',
      headers: {
        'X-LimoniApps-AppName': 'DiscountNinja',
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((data) => {
        data.blob().then((myBlob) => {
          if (myBlob.size) {
            const link = URL.createObjectURL(myBlob);
            window.open(link, '_blank');
          } else {
            dispatch(
              setToastMessages({
                error: true,
                message: 'AnalyticsExportFailed',
              })
            );
          }
        });
      })
      .catch((err) => null)
      .finally(() => setExportAnalyticsIsFetching(false));
  };
  return {
    exportAnalytics,
    exportAnalyticsIsFetching,
  };
};

export default useExportAnalytics;
