import { useI18n } from '@shopify/react-i18n';
import './UploadImageModal.scss';
import React, { useCallback, useState } from 'react';
import {
  DropZone,
  Modal,
  Thumbnail,
  Text,
  Checkbox,
  Link,
} from '@shopify/polaris';
type UploadImageModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onUploadFiles: (value: File[]) => void;
  fileFormat: 'icon' | 'image';
  uploadIsLoading: boolean;
};
const UploadImageModal: React.FC<UploadImageModalProps> = ({
  isOpen,
  onClose,
  onUploadFiles,
  fileFormat,
  uploadIsLoading,
}) => {
  const [i18n] = useI18n();
  const [files, setFiles] = useState<File[]>([]);
  const [overwriteDuplicates, setOverwriteDuplicates] =
    useState<boolean>(false);

  const isIconFormat = fileFormat === 'icon';

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      setFiles([...files, ...acceptedFiles]);
    },
    [files]
  );
  const handleUploadFiles = useCallback(() => {
    const uniqueFiles = files.filter((item, index, self) => {
      return index === self.findIndex((obj) => obj.name === item.name);
    });
    onUploadFiles(overwriteDuplicates ? uniqueFiles : files);
  }, [overwriteDuplicates, files, onUploadFiles]);

  const uploadedFiles = files.length > 0 && (
    <div className='UploadedFiles'>
      {files.map((file, index) => (
        <div className='ImageRow' key={index}>
          <Thumbnail
            size='small'
            alt={file.name}
            source={window.URL.createObjectURL(file)}
          />
          <div>{file.name}</div>
        </div>
      ))}
    </div>
  );
  const fileUpload = !files.length && (
    <DropZone.FileUpload
      actionHint={
        isIconFormat
          ? `${i18n.translate('Accepts')} .svg`
          : `${i18n.translate('Accepts')} .jpg .png`
      }
    />
  );

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        open={isOpen}
        onClose={onClose}
        title={i18n.translate(isIconFormat ? 'UploadIcon' : 'UploadImage')}
        primaryAction={{
          content: i18n.translate('Upload'),
          onAction: handleUploadFiles,
          disabled: uploadIsLoading,
        }}
        secondaryActions={[
          {
            content: i18n.translate('Cancel'),
            onAction: onClose,
          },
        ]}
        footer={<Link removeUnderline>{i18n.translate('NeedHelp')}</Link>}
      >
        <Modal.Section>
          <div className='UploadImageModalContent'>
            <Text as='p'>
              {i18n.translate(
                isIconFormat ? 'IconCustomHeader' : 'ImageCustomHeader'
              )}
            </Text>
            <div className='ImageDropZone'>
              <DropZone
                label={''}
                allowMultiple={false}
                accept={isIconFormat ? '.svg' : '.png, .jpg'}
                type='image'
                onDrop={handleDrop}
                variableHeight
              >
                {uploadedFiles}
                {fileUpload}
              </DropZone>
            </div>
            <Checkbox
              checked={overwriteDuplicates}
              onChange={setOverwriteDuplicates}
              label={i18n.translate(
                isIconFormat ? 'OverwriteIcon' : 'OverwriteImage'
              )}
            />
          </div>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default UploadImageModal;
