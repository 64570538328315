import {
  CountryLookupDto,
  useGetPromotionsV6LookupCountriesQuery,
} from 'core/api/adminPromotions/adminPromotionsApi';
import resolveEnvVar from 'env-var-resolver';
import react from 'react';
const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';
export const useCountries = (shouldSkip?: boolean) => {
  const [countryList, setCountryList] = react.useState<CountryLookupDto[]>();

  const { data: getCountryListData } = useGetPromotionsV6LookupCountriesQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
    },
    { skip: !!shouldSkip }
  );

  react.useEffect(() => {
    setCountryList((getCountryListData as CountryLookupDto[]) || []);
  }, [getCountryListData]);

  return { countryList };
};
