import React, { useState, useCallback, useMemo } from 'react';
import { Button, Popover, ActionList, IconSource } from '@shopify/polaris';
import { MenuHorizontalIcon } from '@shopify/polaris-icons';

export type ActionItem = {
  content: string;
  active?: boolean;
  icon?: IconSource;
  destructive?: boolean;
  disabled?: boolean;
  onAction: () => void;
};

type ActionListInPopoverProps = {
  btnContent: string;
  actionList: ActionItem[];
  selectType?: boolean;
  disable?: boolean;
  width?: string;
  fullWidth?: boolean;
};

export const ActionListInPopover: React.FC<ActionListInPopoverProps> = (
  props
) => {
  const {
    btnContent,
    actionList,
    selectType = false,
    disable = false,
    width,
    fullWidth = true,
  } = props;
  const [active, setActive] = useState<boolean>(false);

  const toggleActive = useCallback((e?: React.MouseEvent<HTMLDivElement>) => {
    e?.stopPropagation();
    setActive((active) => !active);
  }, []);

  const activator = useMemo(
    () => (
      <div
        style={{ width: width || 'auto', display: 'flex' }}
        onClick={toggleActive}
      >
        <Button
          disclosure={
            btnContent.length
              ? selectType
                ? 'select'
                : active
                ? 'up'
                : 'down'
              : false
          }
          variant={btnContent.length ? 'secondary' : 'tertiary'}
          icon={!btnContent.length ? MenuHorizontalIcon : undefined}
          fullWidth={fullWidth}
          textAlign='left'
          size={btnContent.length ? 'medium' : 'micro'}
          disabled={disable}
        >
          {btnContent}
        </Button>
      </div>
    ),
    [btnContent, active, selectType, disable, width]
  );

  return (
    <Popover
      active={active}
      activator={activator}
      onClose={() => toggleActive()}
      fullWidth={!!btnContent.length && fullWidth}
      captureOverscroll
    >
      <div onClick={(e) => e.stopPropagation()}>
        <ActionList
          actionRole='menuitem'
          items={actionList}
          onActionAnyItem={toggleActive}
        />
      </div>
    </Popover>
  );
};
