import React, { useCallback, useState } from 'react';
import './SaveWidgetEditorModal.scss';
import { DiscountIcon } from '@shopify/polaris-icons';

import { useI18n } from '@shopify/react-i18n';
import {
  Banner,
  BlockStack,
  Box,
  Checkbox,
  ChoiceList,
  Icon,
  InlineStack,
  Modal,
  Text,
  TextField,
} from '@shopify/polaris';
import { PaletteIcon } from 'core/components/WidgetEditor/assets/icons';
import {
  CloseEditorTransactionRequestDto,
  PromotionPresetsDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { TransactionSaveModeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import {
  PresetLevelDto,
  PromotionPresetsChangesDto,
} from '../LeftSidebarWrapper/components/PresetChangesBadge/PresetChangesBadge';

type SaveWidgetEditorModalProps = {
  isOpen: boolean;
  presets: PromotionPresetsDto;
  presetsChanges: PromotionPresetsChangesDto;
  isLoading: boolean;
  onClose: () => void;
  onSave: (data: CloseEditorTransactionRequestDto) => void;
};
const SaveWidgetEditorModal: React.FC<SaveWidgetEditorModalProps> = ({
  isOpen,
  presets,
  isLoading,
  presetsChanges,
  onClose,
  onSave,
}) => {
  const [i18n] = useI18n();
  const [configuration, setConfiguration] =
    useState<CloseEditorTransactionRequestDto>({
      promotionLevel: {
        saveMode: TransactionSaveModeDtoEnum.SAVE,
      },
      shopLevel: {
        saveMode: TransactionSaveModeDtoEnum.SAVE,
        setNewPresetAsActive: true,
      },
    });

  const modeOptions = Object.values(TransactionSaveModeDtoEnum).map((item) => ({
    label: i18n.translate(item),
    value: item,
  }));

  const getSavePresetBox = useCallback(
    (level: PresetLevelDto) => {
      const currentPreset = presets?.[level];
      return (
        <Box
          borderColor='border-brand'
          borderRadius='200'
          borderWidth='025'
          padding='300'
        >
          <InlineStack wrap={false} gap='200'>
            <div>
              <Icon source={DiscountIcon} />
            </div>
            <Box width='100%'>
              <BlockStack gap='300'>
                <InlineStack blockAlign='start' align='space-between'>
                  <BlockStack gap='100'>
                    <Text as='h2' fontWeight='medium'>
                      {currentPreset?.name}
                    </Text>
                    <Text as='p' tone='subdued'>
                      {i18n.translate(`${level}_Widgets`)}・
                      {level === 'promotionLevel' ? (
                        <>
                          {i18n.translate('UsedBy')}{' '}
                          {currentPreset?.affectedPromotionsCount}{' '}
                          {i18n.translate(
                            currentPreset?.affectedPromotionsCount === 1
                              ? 'Promotion'
                              : 'Promotions'
                          )}
                        </>
                      ) : (
                        i18n.translate('UsedByAll')
                      )}
                    </Text>
                  </BlockStack>
                  <div className='ChangesBadge'>
                    <PaletteIcon color={'#8A8A8A'} size={14} crossed />{' '}
                    <Text as='span' tone='subdued' fontWeight='medium'>
                      {i18n.translate('UnsavedChanges')}
                    </Text>
                  </div>
                </InlineStack>
                {configuration?.[level]?.saveMode ===
                  TransactionSaveModeDtoEnum.SAVE && (
                  <Banner tone='warning'>
                    {level === 'promotionLevel' ? (
                      <>
                        {i18n.translate('ThisWillImpact')}{' '}
                        {currentPreset?.affectedPromotionsCount}{' '}
                        {i18n.translate(
                          currentPreset?.affectedPromotionsCount === 1
                            ? 'OtherPromotion'
                            : 'OtherPromotions'
                        )}
                        .
                      </>
                    ) : (
                      i18n.translate('ThisImpactAll')
                    )}
                  </Banner>
                )}

                {configuration?.[level]?.saveMode ===
                  TransactionSaveModeDtoEnum.SAVE_AS_NEW_PRESET &&
                  level === 'shopLevel' && (
                    <Banner tone='warning'>
                      {i18n.translate('PresetWillBeSaved')}
                    </Banner>
                  )}

                <ChoiceList
                  title=''
                  choices={modeOptions}
                  selected={[configuration[level]?.saveMode as string]}
                  onChange={(value) =>
                    setConfiguration((prev) => ({
                      ...prev,
                      [level]: {
                        ...prev?.[level],
                        saveMode: value[0],
                      },
                    }))
                  }
                />
                {configuration?.[level]?.saveMode ===
                  TransactionSaveModeDtoEnum.SAVE_AS_NEW_PRESET && (
                  <BlockStack gap='200'>
                    <TextField
                      label={i18n.translate('PresetName')}
                      autoComplete=''
                      value={configuration?.[level]?.newPresetName || ''}
                      placeholder={i18n.translate('PresetNamePlaceholder')}
                      onChange={(value) =>
                        setConfiguration((prev) => ({
                          ...prev,
                          [level]: {
                            ...prev?.[level],
                            newPresetName: value,
                          },
                        }))
                      }
                    />
                    {level === 'shopLevel' && (
                      <Checkbox
                        checked={
                          !!configuration.shopLevel?.setNewPresetAsActive
                        }
                        label={i18n.translate('PublishNewPreset')}
                        onChange={(value) =>
                          setConfiguration((prev) => ({
                            ...prev,
                            shopLevel: {
                              ...prev.shopLevel,
                              setNewPresetAsActive: value,
                            },
                          }))
                        }
                      />
                    )}
                  </BlockStack>
                )}
              </BlockStack>
            </Box>
          </InlineStack>
        </Box>
      );
    },
    [presets, configuration]
  );

  return (
    <div className='SaveWidgetEditorModal'>
      <Modal
        open={isOpen}
        secondaryActions={[
          {
            content: i18n.translate(`Close`),
            onAction: () => onClose(),
          },
        ]}
        primaryAction={{
          content: i18n.translate(`Save`),
          onAction: () => onSave(configuration),
          loading: isLoading,
        }}
        title={i18n.translate('SaveChanges')}
        onClose={() => onClose()}
      >
        <div className='SaveWidgetEditorModalContent'>
          <Modal.Section>
            <BlockStack gap='300'>
              <Text as='p'>{i18n.translate('ThereUnsavedChanges')}</Text>
              {!presetsChanges.promotionLevel &&
                getSavePresetBox('promotionLevel')}
              {!presetsChanges.shopLevel && getSavePresetBox('shopLevel')}
            </BlockStack>
          </Modal.Section>
        </div>
      </Modal>
    </div>
  );
};

export default SaveWidgetEditorModal;
