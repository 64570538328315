import {
  BlockStack,
  Box,
  Button,
  ButtonGroup,
  Card,
  Icon,
  InlineStack,
  Text,
  Tooltip,
} from '@shopify/polaris';
import { QuestionCircleIcon } from '@shopify/polaris-icons';
import './GrayBox.scss';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useI18n } from '@shopify/react-i18n';
import { DeleteIcon } from '@shopify/polaris-icons';
import { GrayBoxExclusions } from './components/GrayBoxExclusions/GrayBoxExclusions';
import { GrayBoxBogoOptions } from './components/GrayBoxBogoOptions/GrayBoxBogoOptions';
import { OfferTypeSpecialCases } from 'core/enums/GrayBoxEnum';
import { GrayBoxFirstLineContent } from './components/GrayBoxFirstLineContent/GrayBoxFirstLineContent';
import { ResourceSelectionProps } from '../SearchFieldWithGrayBox';
import { SearchFieldWithGrayBox } from '../SearchFieldWithGrayBoxOfferWizard';
import {
  OfferCartRuleDto,
  OfferPrerequisiteEntitledAdvancedSettingsDto,
  OfferPrerequisiteEntitledNameFiltersDto,
  OfferTargetTypeDto,
  OptionDtoString,
  ShopifyObjectDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  OfferCartContentRequirementProductMatchTypeDtoEnum,
  OfferPrerequisiteEntitledCompareAtPriceBehaviorDtoEnum,
  OfferPrerequisiteEntitledPurchaseTypeDtoEnum,
  OfferRuleTypeDtoEnum,
  OfferRuleValueTypeDtoEnum,
  OfferTargetTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { TotalCartValueType } from 'features/promotions/components/SelectedOfferTypeSettings/enums/ExceptionRulesType';
import { formateList } from 'core/utils/offerTargetTypeUtils';
import { NameFilters } from './components/NameFilters/NameFilters';
import { AdvancedSettings } from './components/AdvancedSettings/AdvancedSettings';

//TODO load initial values from template
export const grayBoxInitialValues: OfferCartRuleDto = {
  type: OfferRuleTypeDtoEnum.MINIMUM_PURCHASE_AMOUNT,
  value: {
    type: OfferRuleValueTypeDtoEnum.AT_LEAST,
    from: 1,
    to: null,
    exactly: 1,
  },
  appliesTo: {
    type: OfferTargetTypeDtoEnum.ALL,
    collections: null,
    products: null,
    productMatchType: {
      enabled: false,
      value: OfferCartContentRequirementProductMatchTypeDtoEnum.SAME_PRODUCT,
    },
    variants: null,
  },
  exclusions: {
    collections: {
      enabled: false,
      value: [],
    },
  },
  nameFilters: {
    productNameFilter: {
      enabled: false,
      value: '',
    },
    productVariantNameFilter: {
      enabled: false,
      value: '',
    },
  },
  advancedSettings: {
    compareAtPrice:
      OfferPrerequisiteEntitledCompareAtPriceBehaviorDtoEnum.ALL_VARIANTS,
    purchaseType:
      OfferPrerequisiteEntitledPurchaseTypeDtoEnum.ONE_TIME_PURCHASE,
  },
  applyAfterDiscounts: true,
};

export type GrayBoxProps = {
  specialOfferType?: OfferTypeSpecialCases;
  productAmount?: number | string;
  isMoney?: boolean;
  visitorsAlsoAdd?: boolean;
  data: OfferCartRuleDto;
  onDeleteRule?: () => void;
  updateValue: (data: OfferCartRuleDto) => void;
  handleRuleValidityChange: (id: string, isValid: boolean) => void;
  title?: string;
  chidren?: React.ReactNode;
  setConfiguredCollections?: (value: string) => void;
  setExcludedCollections?: (excludedCollections: string) => void;
  hideComponent?: boolean;
};

export const GrayBox: React.FC<GrayBoxProps> = (props) => {
  const {
    isMoney = false,
    visitorsAlsoAdd,
    productAmount,
    data,
    specialOfferType,
    onDeleteRule,
    updateValue,
    handleRuleValidityChange,
    title,
    chidren,
    setConfiguredCollections,
    setExcludedCollections,
    hideComponent,
  } = props;

  const listNeeded = useMemo(
    () =>
      data.appliesTo?.type === OfferTargetTypeDtoEnum.ALL ||
      data.appliesTo?.type === OfferTargetTypeDtoEnum.COLLECTIONS
        ? 'collections'
        : data.appliesTo?.type === OfferTargetTypeDtoEnum.PRODUCTS
        ? 'products'
        : 'variants',
    [data.appliesTo?.type]
  );

  const [selectList, setSelectList] = useState<ShopifyObjectDto[]>([]);

  const [isAppliesToValid, setIsAppliesToValid] = useState<boolean>(true);
  const [isExclusionsValid, setIsExclusionsValid] = useState<boolean>(true);
  const [isFilterValid, setIsFilterValid] = useState<boolean>(true);
  const [isNameFilterValid, setIsNameFilterValid] = useState<boolean>(true);

  const bogoVariant = useMemo(
    () =>
      specialOfferType &&
      [
        OfferTypeSpecialCases.BogoStrictMatch,
        OfferTypeSpecialCases.BogoMixMatch,
      ].includes(specialOfferType)
        ? specialOfferType
        : null,
    [specialOfferType]
  );
  const isSpendXGetX = useMemo(
    () => specialOfferType === OfferTypeSpecialCases.SpendXGetX,
    [specialOfferType]
  );

  const isCrossSell = useMemo(
    () => specialOfferType === OfferTypeSpecialCases.CrossSell,
    [specialOfferType]
  );

  const [activeCollectionProductType, setActiveCollectionProductType] =
    useState<OfferTargetTypeDto>(OfferTargetTypeDtoEnum.ALL);
  const [activeTotalCartValueType, setActiveTotalCartValueType] =
    useState<boolean>(false);

  const handleUpdateNameFilters = useCallback(
    (
      filterName: keyof OfferPrerequisiteEntitledNameFiltersDto,
      field: keyof OptionDtoString,
      value: string | boolean | null
    ) => {
      updateValue({
        ...data,
        nameFilters: {
          ...data.nameFilters,
          [filterName]: {
            ...data.nameFilters?.[filterName],
            [field]: value,
          },
        },
      });
    },
    [data, updateValue]
  );

  const handleUpdateAdvancedSettings = useCallback(
    (
      field: keyof OfferPrerequisiteEntitledAdvancedSettingsDto,
      value: string
    ) => {
      updateValue({
        ...data,
        advancedSettings: {
          ...data.advancedSettings,
          [field]: value,
        },
      });
    },
    [data, updateValue]
  );

  const onCollectionProductTypeClick = useCallback(
    (ruleCollectionType: OfferTargetTypeDtoEnum) => {
      if (activeCollectionProductType !== ruleCollectionType) {
        setActiveCollectionProductType(ruleCollectionType);
        setSelectList(
          (data.appliesTo?.[
            ruleCollectionType.toLowerCase() as keyof typeof data.appliesTo
          ] || []) as ShopifyObjectDto[]
        );

        updateValue({
          ...data,
          appliesTo: {
            ...data.appliesTo,
            type: ruleCollectionType,
          },
        });
      }
    },
    [
      setActiveCollectionProductType,
      setSelectList,
      updateValue,
      data,
      activeCollectionProductType,
    ]
  );

  const onTotalCartValueTypeClick = useCallback(
    (totalCartValueType: boolean) => {
      setActiveTotalCartValueType(totalCartValueType);
      updateValue({
        ...data,
        applyAfterDiscounts: totalCartValueType,
      });
    },
    [setActiveTotalCartValueType, updateValue, data]
  );

  const [i18n] = useI18n();

  const handleSelectChange = useCallback(
    (list: ResourceSelectionProps[]) => {
      setSelectList(list);
      updateValue({
        ...data,
        appliesTo: {
          ...data.appliesTo,
          [listNeeded]: formateList(list, activeCollectionProductType),
        },
      });
    },
    [
      setSelectList,
      updateValue,
      formateList,
      activeCollectionProductType,
      data,
      listNeeded,
    ]
  );

  const getCollectionProductEnumKey = useCallback(
    (value: OfferTargetTypeDtoEnum) => {
      return Object.keys(OfferTargetTypeDtoEnum)[
        Object.values(OfferTargetTypeDtoEnum).indexOf(value)
      ];
    },
    []
  );

  const showCartValueLine = useMemo(
    () => isSpendXGetX || isMoney,
    [isSpendXGetX, isMoney]
  );

  const isAllValid: boolean = useMemo(
    () =>
      isFilterValid &&
      isAppliesToValid &&
      isExclusionsValid &&
      isNameFilterValid,
    [isFilterValid, isAppliesToValid, isExclusionsValid, isNameFilterValid]
  );

  const showGrayBoxExclusions = useMemo(
    () =>
      data.appliesTo?.type === OfferTargetTypeDtoEnum.ALL ||
      data.appliesTo?.type === OfferTargetTypeDtoEnum.COLLECTIONS,
    [data.appliesTo?.type]
  );

  const onSearchFieldValidityChange = useCallback(
    (formIsInvalid: boolean, isExclusionsField?: boolean) => {
      isExclusionsField
        ? setIsExclusionsValid(!formIsInvalid)
        : setIsAppliesToValid(!formIsInvalid);
    },
    []
  );

  const onFiltersValidityChange = useCallback((formIsValid: boolean) => {
    setIsFilterValid(formIsValid);
  }, []);

  useEffect(() => {
    setSelectList(data.appliesTo?.[listNeeded] || []);
  }, [listNeeded]);

  useEffect(() => {
    setActiveCollectionProductType(
      data.appliesTo?.type || OfferTargetTypeDtoEnum.ALL
    );
  }, [data.appliesTo?.type]);

  useEffect(() => {
    setActiveTotalCartValueType(data.applyAfterDiscounts || false);
  }, [data.applyAfterDiscounts]);

  useEffect(() => {
    handleRuleValidityChange(data.id || '', isAllValid);
  }, [isAllValid, data.id]);

  const selectedTypeOptions = useMemo(() => {
    switch (activeCollectionProductType) {
      case getCollectionProductEnumKey(OfferTargetTypeDtoEnum.ALL):
        return (
          <>
            {!isMoney && !hideComponent && (
              <GrayBoxBogoOptions
                data={data}
                bogoVariant={bogoVariant}
                isCrossSell={isCrossSell}
                updateValue={updateValue}
              />
            )}
          </>
        );
      case getCollectionProductEnumKey(OfferTargetTypeDtoEnum.COLLECTIONS):
        return (
          <>
            <div className='SearchboxContainer'>
              <SearchFieldWithGrayBox
                selectList={selectList as ResourceSelectionProps[]}
                resourceType='Collection'
                onSelectedChange={handleSelectChange}
                onFormValidityChange={onSearchFieldValidityChange}
                setConfiguredCollections={setConfiguredCollections}
                hideComponent={hideComponent}
              />
            </div>
            {!isMoney && !hideComponent && (
              <GrayBoxBogoOptions
                data={data}
                isCrossSell={isCrossSell}
                updateValue={updateValue}
                bogoVariant={bogoVariant}
              />
            )}
          </>
        );
      case getCollectionProductEnumKey(OfferTargetTypeDtoEnum.PRODUCTS):
        return (
          <>
            <div className='SearchboxContainer'>
              <SearchFieldWithGrayBox
                selectList={selectList as ResourceSelectionProps[]}
                resourceType='Product'
                onSelectedChange={handleSelectChange}
                onFormValidityChange={onSearchFieldValidityChange}
                setConfiguredCollections={setConfiguredCollections}
                hideComponent={hideComponent}
              />
            </div>
            {!isMoney && !hideComponent && (
              <GrayBoxBogoOptions
                data={data}
                isCrossSell={isCrossSell}
                updateValue={updateValue}
                bogoVariant={bogoVariant}
              />
            )}
          </>
        );
      case getCollectionProductEnumKey(OfferTargetTypeDtoEnum.VARIANTS):
        return (
          <>
            <div className='SearchboxContainer'>
              <SearchFieldWithGrayBox
                selectList={selectList as ResourceSelectionProps[]}
                resourceType='Product'
                showVariants
                onSelectedChange={handleSelectChange}
                onFormValidityChange={onSearchFieldValidityChange}
                setConfiguredCollections={setConfiguredCollections}
                hideComponent={hideComponent}
              />
            </div>
            {(bogoVariant || isCrossSell) && !hideComponent && (
              <GrayBoxBogoOptions
                data={data}
                isCrossSell={isCrossSell}
                bogoVariant={bogoVariant}
                updateValue={updateValue}
              />
            )}
          </>
        );
    }
  }, [
    bogoVariant,
    handleSelectChange,
    selectList,
    activeCollectionProductType,
    getCollectionProductEnumKey,
    updateValue,
    isCrossSell,
    data,
    isMoney,
    hideComponent,
  ]);

  return (
    <>
      {!hideComponent ? (
        <>
          <BlockStack gap='600'>
            <Card>
              <BlockStack gap='400'>
                {title && (
                  <Text as='h2' variant='headingSm'>
                    {title}
                  </Text>
                )}
                {chidren}
                <GrayBoxFirstLineContent
                  isBogo={!!bogoVariant}
                  isMoney={isMoney}
                  visitorsAlsoAdd={visitorsAlsoAdd}
                  updateValue={updateValue}
                  isSpendXGetX={isSpendXGetX}
                  productAmount={productAmount}
                  isCrossSell={isCrossSell}
                  data={data}
                  activeRuleOfferType={
                    data.value?.type || OfferRuleValueTypeDtoEnum.AT_LEAST
                  }
                  onFormValidityChange={onFiltersValidityChange}
                />

                <InlineStack gap='200' blockAlign='center'>
                  {isMoney ? (
                    <Text as='p'>{i18n.translate('GrayBox.On') || 'on'}</Text>
                  ) : (
                    <Text as='p'>
                      {i18n.translate('GrayBox.TextFrom') || 'from'}
                    </Text>
                  )}
                  <ButtonGroup variant='segmented'>
                    {Object.keys(OfferTargetTypeDtoEnum).map((key: string) => (
                      <Button
                        key={key}
                        pressed={activeCollectionProductType === key}
                        onClick={() =>
                          onCollectionProductTypeClick(
                            key as OfferTargetTypeDtoEnum
                          )
                        }
                      >
                        {i18n.translate(`GrayBox.${key}`) || key}
                      </Button>
                    ))}
                  </ButtonGroup>
                </InlineStack>
                {showCartValueLine && (
                  <InlineStack gap='100' blockAlign='center'>
                    <Text as='p'>
                      {i18n.translate('GrayBox.UsingCartValue.Text') ||
                        'using the'}
                    </Text>
                    <Text as='p'>
                      {i18n.translate('GrayBox.UsingCartValue.Hover') ||
                        'total cart value'}
                    </Text>
                    <Tooltip
                      preferredPosition='mostSpace'
                      content={
                        i18n.translate('GrayBox.UsingCartValue.HoverText') ||
                        'Note that the value of the gifts in the cart that were added by Discount Ninja are not taken into account.'
                      }
                    >
                      <span>
                        <Icon source={QuestionCircleIcon} />
                      </span>
                    </Tooltip>
                    <ButtonGroup variant='segmented'>
                      {Object.values(TotalCartValueType).map((key: string) => (
                        <Button
                          key={key}
                          pressed={
                            activeTotalCartValueType ===
                            (key === TotalCartValueType.AfterDiscounts)
                          }
                          onClick={() =>
                            onTotalCartValueTypeClick(
                              key === TotalCartValueType.AfterDiscounts
                            )
                          }
                        >
                          {i18n.translate(
                            `GrayBox.UsingCartValue.${
                              key === TotalCartValueType.AfterDiscounts
                                ? 'AfterDiscounts'
                                : 'BeforeDiscounts'
                            }`
                          ) || key}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </InlineStack>
                )}
                {selectedTypeOptions}
              </BlockStack>
            </Card>
            <NameFilters
              type={
                (data.appliesTo?.type ||
                  OfferTargetTypeDtoEnum.ALL) as OfferTargetTypeDtoEnum
              }
              data={data.nameFilters}
              handleUpdateNameFilters={handleUpdateNameFilters}
              setIsNameFilterValid={setIsNameFilterValid}
            />
            {showGrayBoxExclusions && (
              <Card>
                <BlockStack gap='400'>
                  <Text as='h2' variant='headingSm'>
                    {i18n.translate('GrayBox.Exclusions')}
                  </Text>
                  <GrayBoxExclusions
                    data={data}
                    updateValue={updateValue}
                    onFormValidityChange={onSearchFieldValidityChange}
                    setExcludedCollections={setExcludedCollections}
                    hideComponent={hideComponent}
                  />
                </BlockStack>
              </Card>
            )}
            <AdvancedSettings
              type={
                (data.appliesTo?.type ||
                  OfferTargetTypeDtoEnum.ALL) as OfferTargetTypeDtoEnum
              }
              data={data.advancedSettings}
              handleUpdateAdvancedSettings={handleUpdateAdvancedSettings}
            />
          </BlockStack>
          {onDeleteRule && (
            <Box paddingBlock='400'>
              <BlockStack inlineAlign='start'>
                <Button
                  icon={<Icon source={DeleteIcon} />}
                  onClick={onDeleteRule}
                  tone='critical'
                >
                  {i18n.translate('GrayBox.DeleteRule') || 'Delete rule'}
                </Button>
              </BlockStack>
            </Box>
          )}
        </>
      ) : (
        <>
          {selectedTypeOptions}
          <NameFilters
            type={
              (data.appliesTo?.type ||
                OfferTargetTypeDtoEnum.ALL) as OfferTargetTypeDtoEnum
            }
            data={data.nameFilters}
            handleUpdateNameFilters={handleUpdateNameFilters}
            setIsNameFilterValid={setIsNameFilterValid}
          />
          {showGrayBoxExclusions && (
            <GrayBoxExclusions
              data={data}
              updateValue={updateValue}
              onFormValidityChange={onSearchFieldValidityChange}
              setExcludedCollections={setExcludedCollections}
              hideComponent={hideComponent}
            />
          )}
          <AdvancedSettings
            type={
              (data.appliesTo?.type ||
                OfferTargetTypeDtoEnum.ALL) as OfferTargetTypeDtoEnum
            }
            data={data.advancedSettings}
            handleUpdateAdvancedSettings={handleUpdateAdvancedSettings}
          />
        </>
      )}
    </>
  );
};
