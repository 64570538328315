import React, { useCallback, useMemo } from 'react';
import {
  PromotionCodeFieldAppliedValidationStatePresetDto,
  PromotionCodeFieldCodeValidationPresetDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import {
  Icon,
  SingleInputSelection,
  TextEntries,
  TextStyle,
} from '../../../../../components';
import {
  PromotionCodeFieldCodeValidationStateDtoEnum,
  ResourceGroupKeyDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import { useI18n } from '@shopify/react-i18n';
import { isEqual } from 'lodash';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';

type PromotionCodeValidationCodeProps = {
  codeValidation: PromotionCodeFieldCodeValidationPresetDto;
  defaultCodeValidation: PromotionCodeFieldCodeValidationPresetDto;
  shopDefaultCodeValidation: PromotionCodeFieldCodeValidationPresetDto;
  defaultLanguage: string;
  isPresetEditor?: boolean;
  setCodeValidation: (data: PromotionCodeFieldCodeValidationPresetDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const PromotionCodeValidationCode: React.FC<
  PromotionCodeValidationCodeProps
> = ({
  codeValidation,
  defaultCodeValidation,
  shopDefaultCodeValidation,
  defaultLanguage,
  isPresetEditor,
  setCodeValidation,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();
  const stateOptions = Object.values(
    PromotionCodeFieldCodeValidationStateDtoEnum
  ).map((value) => {
    return {
      value: value,
      label: i18n.translate(`${value}`),
    };
  });
  const currentState: PromotionCodeFieldAppliedValidationStatePresetDto =
    useMemo(
      () =>
        codeValidation.selectedState ===
        PromotionCodeFieldCodeValidationStateDtoEnum.NOT_APPLICABLE
          ? codeValidation.notApplicableState || {}
          : codeValidation.selectedState ===
            PromotionCodeFieldCodeValidationStateDtoEnum.INVALID
          ? codeValidation.invalidState || {}
          : codeValidation.codeAppliedState || {},
      [codeValidation]
    );
  const defaultCurrentState: PromotionCodeFieldAppliedValidationStatePresetDto =
    useMemo(
      () =>
        codeValidation.selectedState ===
        PromotionCodeFieldCodeValidationStateDtoEnum.NOT_APPLICABLE
          ? defaultCodeValidation.notApplicableState || {}
          : codeValidation.selectedState ===
            PromotionCodeFieldCodeValidationStateDtoEnum.INVALID
          ? defaultCodeValidation.invalidState || {}
          : defaultCodeValidation.codeAppliedState || {},
      [defaultCodeValidation, codeValidation]
    );

  const currentShopDefaultState: PromotionCodeFieldAppliedValidationStatePresetDto =
    useMemo(
      () =>
        codeValidation.selectedState ===
        PromotionCodeFieldCodeValidationStateDtoEnum.NOT_APPLICABLE
          ? shopDefaultCodeValidation.notApplicableState || {}
          : codeValidation.selectedState ===
            PromotionCodeFieldCodeValidationStateDtoEnum.INVALID
          ? shopDefaultCodeValidation.invalidState || {}
          : shopDefaultCodeValidation.codeAppliedState || {},
      [codeValidation, shopDefaultCodeValidation]
    );

  const stateToChange = useMemo(
    () =>
      codeValidation.selectedState ===
      PromotionCodeFieldCodeValidationStateDtoEnum.NOT_APPLICABLE
        ? 'notApplicableState'
        : codeValidation.selectedState ===
          PromotionCodeFieldCodeValidationStateDtoEnum.INVALID
        ? 'invalidState'
        : 'codeAppliedState',
    [codeValidation]
  );

  const handleCurrentState = useCallback(
    (
      field: keyof PromotionCodeFieldAppliedValidationStatePresetDto,
      data: PromotionCodeFieldAppliedValidationStatePresetDto[keyof PromotionCodeFieldAppliedValidationStatePresetDto]
    ) => {
      setCodeValidation({
        ...codeValidation,
        [stateToChange]: {
          ...codeValidation?.[stateToChange],
          [field]: data,
        },
      });
    },
    [codeValidation, setCodeValidation]
  );
  const resetAllToSystemDefault = useCallback(() => {
    setCodeValidation({
      ...codeValidation,
      [stateToChange]: shopDefaultCodeValidation[stateToChange],
    });
  }, [shopDefaultCodeValidation, codeValidation, stateToChange]);

  return (
    <div className='PromotionCodeValidationCode'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={() => setCodeValidation(defaultCodeValidation)}
        onResetToSystem={resetAllToSystemDefault}
        name={WidgetListOptionsDtoEnum.CODE_VALIDATION}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <SingleInputSelection
          options={stateOptions}
          label={i18n.translate('State')}
          selectedOption={
            codeValidation.selectedState ||
            PromotionCodeFieldCodeValidationStateDtoEnum.NOT_APPLICABLE
          }
          setOption={(value) =>
            setCodeValidation({
              ...codeValidation,
              selectedState:
                value as PromotionCodeFieldCodeValidationStateDtoEnum,
            })
          }
          onSystemReset={() =>
            setCodeValidation({
              ...codeValidation,
              selectedState: shopDefaultCodeValidation.selectedState,
            })
          }
        />
      </div>
      <div className='RightSideSection'>
        <TextEntries
          key={codeValidation.selectedState}
          textMessage={currentState?.message?.message || {}}
          defaultLanguage={defaultLanguage || ''}
          inherited={
            !isPresetEditor
              ? isEqual(currentState?.message, defaultCurrentState.message)
              : undefined
          }
          disableGoal
          disableDevice
          disableColor={
            (currentState?.message?.style || {}).color !== undefined
          }
          disableCollapsed
          title={i18n.translate('Message')}
          setTextMessage={(data) => {
            handleCurrentState('message', {
              ...currentState?.message,
              message: data,
            });
          }}
          onSystemReset={() =>
            handleCurrentState('message', currentShopDefaultState?.message)
          }
        />
        <TextStyle
          textStyle={currentState?.message?.style || {}}
          hideHeader
          onSystemReset={() =>
            handleCurrentState('message', {
              ...currentState?.message,
              style: currentShopDefaultState?.message?.style,
            })
          }
          setTextStyle={(data) =>
            handleCurrentState('message', {
              ...currentState?.message,
              style: data,
            })
          }
        />
      </div>
      {codeValidation.selectedState !==
        PromotionCodeFieldCodeValidationStateDtoEnum.INVALID && (
        <div className='RightSideSection'>
          <Icon
            onSystemReset={() =>
              handleCurrentState(
                'deleteButton',
                currentShopDefaultState.deleteButton
              )
            }
            inherited={
              !isPresetEditor
                ? isEqual(
                    currentState?.deleteButton,
                    defaultCurrentState.deleteButton
                  )
                : undefined
            }
            title={i18n.translate('DeleteButton')}
            icon={currentState.deleteButton || {}}
            setIcon={(data) => handleCurrentState('deleteButton', data)}
            groupKey={ResourceGroupKeyDtoEnum.CLOSE_BUTTON}
            showIcon
          />
        </div>
      )}
      {codeValidation.selectedState ===
        PromotionCodeFieldCodeValidationStateDtoEnum.CODE_APPLIED && (
        <div className='RightSideSection'>
          <Icon
            onSystemReset={() =>
              handleCurrentState(
                'confirmationIcon',
                currentShopDefaultState.confirmationIcon
              )
            }
            inherited={
              !isPresetEditor
                ? isEqual(
                    currentState?.confirmationIcon,
                    defaultCurrentState.confirmationIcon
                  )
                : undefined
            }
            title={i18n.translate('ConfirmationIcon')}
            icon={currentState.confirmationIcon?.value || {}}
            setIcon={(data) =>
              handleCurrentState('confirmationIcon', {
                ...currentState.confirmationIcon,
                value: data,
              })
            }
            showIcon
            groupKey={ResourceGroupKeyDtoEnum.OFFER}
            enabled={currentState.confirmationIcon?.enabled || false}
            enabledText={i18n.translate('EnableIcon')}
            setEnabled={(value) =>
              handleCurrentState('confirmationIcon', {
                ...currentState.confirmationIcon,
                enabled: value,
              })
            }
          />
        </div>
      )}
    </div>
  );
};

export default PromotionCodeValidationCode;
