import React from 'react';
import './AnnouncementBarBehavior.scss';
import { useI18n } from '@shopify/react-i18n';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import { TextField } from '@shopify/polaris';
import { AnnouncementBarGeneralShopSettingsDto } from 'core/api/adminWidgets/adminWidgetsApi';
import StyleComponentHeader from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components/StyleComponentHeader/StyleComponentHeader';

type AnnouncementBarBehaviorProps = {
  behavior: AnnouncementBarGeneralShopSettingsDto;
  disabled?: boolean;
  setBehavior?: (value: AnnouncementBarGeneralShopSettingsDto) => void;
  onSystemReset?: () => void;
};
export const AnnouncementBarBehavior: React.FC<
  AnnouncementBarBehaviorProps
> = ({ behavior, setBehavior, disabled = true, onSystemReset }) => {
  const [i18n] = useI18n();

  const stickyOnScrollOptions = [
    {
      value: '1',
      label: i18n.translate(`Yes`),
    },
    {
      value: '0',
      label: i18n.translate(`No`),
    },
  ];
  const maxZIndexNumber = 2147483647;

  return (
    <div className='AnnouncementBarBehavior'>
      <StyleComponentHeader
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            disabled: disabled,
            onAction: () => onSystemReset?.(),
          },
        ]}
        label={i18n.translate(`Behavior`)}
      >
        <div className='BehaviorRow'>
          {i18n.translate(`StickyOnScroll`)}
          <div className='RightContent'>
            <SelectOptions
              options={stickyOnScrollOptions}
              onOptionSelect={(value) =>
                setBehavior?.({ ...behavior, isSticky: !!value })
              }
              selectedOption={behavior.isSticky ? '1' : '0'}
              label=''
              disabled={disabled}
            />
          </div>
        </div>
        <div className='BehaviorRow'>
          {i18n.translate(`ZIndex`)}
          <div className='RightContent'>
            <TextField
              autoComplete=''
              label=''
              type='number'
              value={behavior.zIndex?.toString()}
              onChange={(value) =>
                setBehavior?.({ ...behavior, zIndex: +value })
              }
              onBlur={() => {
                behavior.zIndex! > maxZIndexNumber
                  ? setBehavior?.({
                      ...behavior,
                      zIndex: maxZIndexNumber,
                    })
                  : behavior.zIndex! < 0
                    ? setBehavior?.({ ...behavior, zIndex: 0 })
                    : null;
              }}
              disabled={disabled}
            />
          </div>
        </div>
      </StyleComponentHeader>
    </div>
  );
};
