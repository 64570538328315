import {
  CartTextPresetDtoRead,
  CartTextLevelPresetDto,
  OfferWidgetSetupDtoCartTextOfferDtoRead,
  PromotionWidgetSetupDtoCartTextPromotionDtoRead,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { LineWeightTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';

export type PromotionWidgetSetupDtoCartTextPromotionDtoReadWithOffers =
  PromotionWidgetSetupDtoCartTextPromotionDtoRead & {
    offers?: OfferWidgetSetupDtoCartTextOfferDtoRead[] | null;
    options?: CartTextLevelPresetDto | null;
  };

export const cartTextMergeFunc = (
  widget: PromotionWidgetSetupDtoCartTextPromotionDtoReadWithOffers,
  setup?: CartTextPresetDtoRead
) => {
  if (widget) {
    const {
      isShopLevelWidget,
      canBeDragged,
      canBeRemoved,
      offers,
      options,
      ...newWidget
    } = widget;

    const { general: generalSetup } = setup?.general
      ? setup
      : { general: null };

    return {
      ...newWidget,
      availableEntries: undefined,
      ...(generalSetup
        ? {
            offers: offers?.map((offer) => {
              const {
                canBeDragged,
                canBeRemoved,
                parentKey,
                canBeHidden,
                isHidden,
                setup,
                ...newOffer
              } = offer;

              return {
                ...(canBeHidden && { isHidden }),
                ...newOffer,
                setup: {
                  ...setup,
                  availableEntries: undefined,
                  general: {
                    ...setup?.general,
                    text: {
                      ...setup?.general?.text,
                      style: {
                        ...generalSetup.textStyle,
                        style: {
                          ...generalSetup.textStyle?.style,
                          fontSize: {
                            ...generalSetup.textStyle?.style?.fontSize,
                            mobile: generalSetup.textStyle?.style
                              ?.differentOnMobile
                              ? generalSetup.textStyle?.style?.fontSize?.mobile
                              : undefined,
                          },
                          lineHeight: {
                            ...generalSetup.textStyle?.style?.lineHeight,
                            mobile: generalSetup.textStyle?.style
                              ?.differentOnMobile
                              ? generalSetup.textStyle?.style?.lineHeight
                                  ?.mobile
                              : undefined,
                          },
                          fontWeight: {
                            ...generalSetup.textStyle?.style?.fontWeight,
                            mobile: generalSetup.textStyle?.style
                              ?.differentOnMobile
                              ? generalSetup.textStyle?.style?.fontWeight
                                  ?.mobile
                              : undefined,
                          },
                        },
                      },
                    },
                    size: {
                      ...generalSetup.size,
                    },
                    cornerRadius: {
                      ...generalSetup?.cornerRadius,
                      customValue:
                        generalSetup.cornerRadius?.type ===
                        LineWeightTypeDtoEnum.CUSTOM
                          ? generalSetup.cornerRadius.customValue
                          : undefined,
                    },
                    background: {
                      ...generalSetup?.background,
                      border: {
                        ...generalSetup?.background?.border,
                        value: {
                          ...generalSetup?.background?.border?.value,
                          lineWeight: {
                            ...generalSetup?.background?.border?.value
                              ?.lineWeight,
                            customValue:
                              generalSetup?.background?.border?.value
                                ?.lineWeight?.type ===
                              LineWeightTypeDtoEnum.CUSTOM
                                ? generalSetup?.background?.border?.value
                                    ?.lineWeight?.customValue
                                : undefined,
                          },
                        },
                      },
                    },
                  },
                },
              };
            }),
          }
        : {
            general: {
              ...newWidget.general,
              background: {
                ...(newWidget.general as any)?.background,
                border: {
                  ...(newWidget.general as any)?.background?.border,
                  value: {
                    ...(newWidget.general as any)?.background?.border?.value,
                    lineWeight: {
                      ...(newWidget.general as any)?.background?.border?.value
                        ?.lineWeight,
                      customValue:
                        (newWidget.general as any)?.background?.border?.value
                          ?.lineWeight?.type === LineWeightTypeDtoEnum.CUSTOM
                          ? (newWidget.general as any)?.background?.border
                              ?.value?.lineWeight?.customValue
                          : undefined,
                    },
                  },
                },
              },
              cornerRadius: {
                ...(newWidget.general as any)?.cornerRadius,
                customValue:
                  (newWidget.general as any)?.cornerRadius?.type ===
                  LineWeightTypeDtoEnum.CUSTOM
                    ? (newWidget.general as any)?.cornerRadius?.customValue
                    : undefined,
              },
              textStyle: {
                ...(newWidget.general as any)?.textStyle,
                style: {
                  ...(newWidget.general as any)?.textStyle?.style,
                  fontSize: {
                    ...(newWidget.general as any)?.textStyle?.style?.fontSize,
                    mobile: (newWidget.general as any)?.textStyle?.style
                      ?.differentOnMobile
                      ? (newWidget.general as any)?.textStyle?.style?.fontSize
                          ?.mobile
                      : undefined,
                  },
                  lineHeight: {
                    ...(newWidget.general as any)?.textStyle?.style?.lineHeight,
                    mobile: (newWidget.general as any)?.textStyle?.style
                      ?.differentOnMobile
                      ? (newWidget.general as any)?.textStyle?.style?.lineHeight
                          ?.mobile
                      : undefined,
                  },
                  fontWeight: {
                    ...(newWidget.general as any)?.textStyle?.style?.fontWeight,
                    mobile: (newWidget.general as any)?.textStyle?.style
                      ?.differentOnMobile
                      ? (newWidget.general as any)?.textStyle?.style?.fontWeight
                          ?.mobile
                      : undefined,
                  },
                },
              },
            },
          }),
    };
  }
};
