import React, { useCallback, useMemo } from 'react';
import { SmartTagSettingsTypeDto } from '../../SmartTagSettings';
import StyleComponentHeader from '../../../StyleComponentHeader/StyleComponentHeader';
import { Divider, Text } from '@shopify/polaris';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import {
  CurrencyCodeSideDtoEnum,
  CurrencyDtoEnum,
  DecimalFormatDtoEnum,
  DecimalPrecisionDtoEnum,
  StrikethroughPriceDtoEnum,
} from '../../enums/enums';
import { useI18n } from '@shopify/react-i18n';

type SmartTagPriceSettingsProps = {
  config: SmartTagSettingsTypeDto;
  setConfig: (data: SmartTagSettingsTypeDto) => void;
};

const SmartTagPriceSettings: React.FC<SmartTagPriceSettingsProps> = ({
  config,
  setConfig,
}) => {
  const [i18n] = useI18n();
  const strikethroughOptions = useMemo(
    () =>
      Object.entries(StrikethroughPriceDtoEnum).map(([key, value]) => {
        return {
          value: value,
          label: i18n.translate(`${key}`),
        };
      }),
    [i18n]
  );

  const currencyCodeSideOptions = useMemo(
    () =>
      Object.entries(CurrencyCodeSideDtoEnum).map(([key, value]) => {
        return {
          value: value,
          label: i18n.translate(`${key}`),
        };
      }),
    [i18n]
  );

  const decimalFormatOptions = useMemo(
    () =>
      Object.entries(DecimalFormatDtoEnum).map(([key, value]) => {
        return {
          value: value,
          label: i18n.translate(`${key}`),
        };
      }),
    [i18n]
  );
  const decimalPrecisionOptions = useMemo(
    () =>
      Object.entries(DecimalPrecisionDtoEnum).map(([key, value]) => {
        return {
          value: value,
          label: i18n.translate(`${key}`),
        };
      }),
    [i18n]
  );

  const currencyCodeOptions = useMemo(
    () =>
      Object.entries(CurrencyDtoEnum).map(([key, value]) => {
        return {
          value: value,
          label: i18n.translate(`${key}`),
        };
      }),
    [i18n]
  );

  const getNumberOptions = useCallback((min: number, max: number) => {
    const numbersArray: number[] = Array.from(
      { length: max - min + 1 },
      (_, index) => index + min
    );
    return numbersArray.map((value) => ({
      value: value.toString(),
      label: value.toString(),
    }));
  }, []);

  const handleUpdateConfig = useCallback(
    (field: string, data: any) => {
      setConfig({ ...config, [field]: data });
    },
    [config]
  );
  return (
    <div className='SmartTagPriceSettings'>
      <StyleComponentHeader label={i18n.translate('Price')}>
        <div className='SettingRow'>
          <Text as='p'>{i18n.translate('Strikethrough')}</Text>
          <div className='RightContent'>
            <SelectOptions
              label=''
              onOptionSelect={(value) =>
                handleUpdateConfig('price-strikethrough', value)
              }
              options={strikethroughOptions}
              selectedOption={config['price-strikethrough']}
            />
          </div>
        </div>
        <div className='SettingRow'>
          <Text as='p'>{i18n.translate('TextSize')}</Text>
          <div className='RightContent'>
            <SelectOptions
              label=''
              onOptionSelect={(value) =>
                handleUpdateConfig('price-text-size', +value)
              }
              options={getNumberOptions(8, 32)}
              allowManual
              max={32}
              min={8}
              selectedOption={config['price-text-size']?.toString()}
            />
          </div>
        </div>
      </StyleComponentHeader>

      <Divider borderColor='border-brand' />

      <StyleComponentHeader label={i18n.translate('CurrencyCode')}>
        <div className='SettingRow'>
          <Text as='p'>{i18n.translate('Code')}</Text>
          <div className='RightContent'>
            <SelectOptions
              label=''
              onOptionSelect={(value) =>
                handleUpdateConfig('currency-code', value)
              }
              options={currencyCodeOptions}
              selectedOption={config['currency-code']}
            />
          </div>
        </div>
        <div className='SettingRow'>
          <Text as='p'>{i18n.translate('Side')}</Text>
          <div className='RightContent'>
            <SelectOptions
              label=''
              onOptionSelect={(value) =>
                handleUpdateConfig('currency-code-side', value)
              }
              options={currencyCodeSideOptions}
              selectedOption={config['currency-code-side']}
            />
          </div>
        </div>
        <div className='SettingRow'>
          <Text as='p'>{i18n.translate('Size')}</Text>
          <div className='RightContent'>
            <SelectOptions
              label=''
              onOptionSelect={(value) =>
                handleUpdateConfig('currency-code-size', +value)
              }
              options={getNumberOptions(8, 32)}
              allowManual
              max={32}
              min={8}
              selectedOption={config?.['currency-code-size']?.toString()}
            />
          </div>
        </div>
      </StyleComponentHeader>

      <Divider borderColor='border-brand' />

      <StyleComponentHeader label={i18n.translate('CurrencySymbol')}>
        <div className='SettingRow'>
          <Text as='p'>{i18n.translate('Symbol')}</Text>
          <div className='RightContent'>
            <SelectOptions
              label=''
              onOptionSelect={(value) =>
                handleUpdateConfig('currency-symbol', value)
              }
              options={currencyCodeOptions}
              selectedOption={config['currency-symbol']}
            />
          </div>
        </div>
        <div className='SettingRow'>
          <Text as='p'>{i18n.translate('Size')}</Text>
          <div className='RightContent'>
            <SelectOptions
              label=''
              onOptionSelect={(value) =>
                handleUpdateConfig('currency-symbol-size', +value)
              }
              options={getNumberOptions(8, 32)}
              allowManual
              min={8}
              max={32}
              selectedOption={config['currency-symbol-size']?.toString()}
            />
          </div>
        </div>
      </StyleComponentHeader>

      <Divider borderColor='border-brand' />

      <StyleComponentHeader label={i18n.translate('FractionalPart')}>
        <div className='SettingRow'>
          <Text as='p'>{i18n.translate('Precision')}</Text>
          <div className='RightContent'>
            <SelectOptions
              label=''
              onOptionSelect={(value) =>
                handleUpdateConfig('fractional-part-precision', value)
              }
              options={decimalPrecisionOptions}
              selectedOption={config['fractional-part-precision']}
            />
          </div>
        </div>
        <div className='SettingRow'>
          <Text as='p'>{i18n.translate('Format')}</Text>
          <div className='RightContent'>
            <SelectOptions
              label=''
              onOptionSelect={(value) =>
                handleUpdateConfig('fractional-part-format', value)
              }
              options={decimalFormatOptions}
              selectedOption={config['fractional-part-format']}
            />
          </div>
        </div>
      </StyleComponentHeader>
      <Divider borderColor='border-brand' />
    </div>
  );
};

export default SmartTagPriceSettings;
