import React, { useState, useCallback, useMemo } from 'react';
import { Editor } from '@tiptap/react';
import { Popover } from '@shopify/polaris';
import '../RichTextEditor.scss';
import ColorSelectorPopover from 'core/components/WidgetEditor/ColorSelectorPopover/ColorSelectorPopover';
import { ColorDto } from 'core/components/WidgetEditor/ColorSelectorPopover/ColorSelectorPopover';
import { ToolbarButton } from '../ToolbarButton';
import { textColorIconToolbar } from '../assets/iconsToolbar';

export const rgbStyleToHex = (color: string): string => {
  if (!color || color.indexOf('rgb') < 0) {
    return color;
  }

  if (color.charAt(0) == '#') {
    return color;
  }

  const nums = /(.*?)rgb\((\d+),\s*(\d+),\s*(\d+)\)/i.exec(color),
    r = parseInt(nums![2], 10).toString(16),
    g = parseInt(nums![3], 10).toString(16),
    b = parseInt(nums![4], 10).toString(16);

  return (
    '#' +
    ((r.length == 1 ? '0' + r : r) +
      (g.length == 1 ? '0' + g : g) +
      (b.length == 1 ? '0' + b : b))
  ).toUpperCase();
};

export const FontColorToolbarElement: React.FC<{
  editor: Editor;
  disabled: boolean;
}> = (props) => {
  const { editor, disabled } = props;

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [savedSelection, setSavedSelection] = useState<any>(null);

  const toggleDropdown = useCallback(() => {
    if (isDropdownOpen) {
      // setSavedSelection(null);
      setIsDropdownOpen(false);
    } else {
      setSavedSelection(editor.state.selection);
      setIsDropdownOpen(true);
    }
  }, [editor, isDropdownOpen]);

  const currentColor = useMemo(
    () =>
      editor.getAttributes('textStyle').color
        ? rgbStyleToHex(editor.getAttributes('textStyle').color)
        : '#000000',
    [editor.getAttributes('textStyle').color]
  );

  const activator = useMemo(
    () => (
      <ToolbarButton
        name='textColor'
        icon={textColorIconToolbar(!!currentColor, currentColor)}
        active={!!currentColor}
        onClick={toggleDropdown}
        disabled={disabled}
      />
    ),
    [toggleDropdown, disabled, currentColor]
  );

  const handleColorSelection = useCallback(
    (value: ColorDto) => {
      setTimeout(() => {
        value.hex && editor.chain().focus().setColor(value.hex).run();
        if (savedSelection) {
          editor.commands.setTextSelection(savedSelection);
        }
      }, 0);
    },
    [editor, savedSelection]
  );

  return (
    <Popover
      active={isDropdownOpen}
      activator={activator}
      onClose={toggleDropdown}
    >
      <Popover.Pane>
        <ColorSelectorPopover
          onClosePopover={toggleDropdown}
          allowAlpha={false}
          color={{ hex: currentColor, alpha: 1 }}
          onColorChange={(value) => handleColorSelection(value)}
        />
      </Popover.Pane>
    </Popover>
  );
};
