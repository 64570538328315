import React from 'react';
import { Modal, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';

interface SettingsConfirmationModalProps {
  modalActive: boolean;
  handleModalClose: () => void;
  handleLeavePage: () => void;
}

export const SettingsConfirmationModal: React.FC<
  SettingsConfirmationModalProps
> = ({ modalActive, handleModalClose, handleLeavePage }) => {
  const [i18n] = useI18n();

  return (
    <Modal
      open={modalActive}
      onClose={handleModalClose}
      title={i18n.translate('ConfirmationTitle')}
      primaryAction={{
        destructive: true,
        content: i18n.translate('LeavePage'),
        onAction: handleLeavePage,
      }}
      secondaryActions={[
        {
          content: i18n.translate('Stay'),
          onAction: handleModalClose,
        },
      ]}
    >
      <Modal.Section>
        <Text as='p'>{i18n.translate('ConfirmationText')}</Text>
      </Modal.Section>
    </Modal>
  );
};
