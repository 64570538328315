import React, { useCallback, useEffect, useState } from 'react';
import './ColorSelectorPopover.scss';
import {
  HSBAColor,
  hsbToRgb,
  rgbToHex,
  ColorPicker,
  rgbToHsb,
  Button,
  Text,
  TextField,
} from '@shopify/polaris';
import { hexToRgb } from 'core/utils/hexToRgb';
import { XIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import { hexToHsb } from 'core/utils/hexToHsb';
import { useAppSelector } from 'core/hooks';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';

export type ColorDto = {
  hex?: string | undefined;
  alpha?: number | undefined;
};
type ColorSelectorPopoverProps = {
  color?: ColorDto | null;
  onColorChange: (value: ColorDto) => void;
  onClosePopover: () => void;
  allowAlpha?: boolean;
};

const ColorSelectorPopover: React.FC<ColorSelectorPopoverProps> = ({
  onColorChange,
  color,
  onClosePopover,
  allowAlpha,
}) => {
  const [i18n] = useI18n();

  const { brandColors } = useAppSelector((state) => state.settings);

  const [shadowColor, setShadowColor] = useState({
    color: { hue: 0, saturation: 0, brightness: 0, alpha: color?.alpha || 0 },
    hex: color?.hex || '#000000',
  });

  useEffect(() => {
    onColorChange({ hex: shadowColor.hex, alpha: shadowColor.color.alpha });
  }, [shadowColor]);

  const handleColorChange = useCallback((value: HSBAColor) => {
    setShadowColor((prevState) => ({
      ...prevState,
      color: value,
      hex: rgbToHex(hsbToRgb(value)),
    }));
  }, []);

  const pickBrandColor = useCallback((hex: string) => {
    setShadowColor((prevState) => ({
      ...prevState,
      color: {
        ...rgbToHsb(hexToRgb(hex)),
        alpha: 1,
      },
      hex: hex,
    }));
  }, []);

  useEffect(() => {
    if (color?.hex) {
      setShadowColor({ ...shadowColor, color: hexToHsb(color as ColorDto) });
    }
  }, []);

  return (
    <div className='ColorPicker'>
      <div className='PickerHeader'>
        <Text as='h6' fontWeight='semibold'>
          {i18n.translate('Color')}
        </Text>
        <Button onClick={onClosePopover} icon={XIcon} variant='plain' />
      </div>
      <div className='PickerContent'>
        <ColorPicker
          onChange={(value) => handleColorChange(value)}
          color={shadowColor.color}
          allowAlpha={allowAlpha}
        />
        <TextField label='' autoComplete='' value={shadowColor.hex.slice(1)} />
        <div>
          <Text as='h6' fontWeight='semibold' variant='bodySm' tone='subdued'>
            {i18n.translate('BrandColors')}
          </Text>
          <div className='SavedColorsBoxes'>
            {brandColors.brandColors?.map((item, idx) => (
              <div
                key={idx}
                onClick={() => pickBrandColor(item)}
                style={{ background: item }}
                className='Color'
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorSelectorPopover;
