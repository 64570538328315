import React, { useCallback, useState } from 'react';
import { Link } from 'react-scroll';
import './RulesPriorities.scss';
import { useI18n } from '@shopify/react-i18n';
import {
  DragHandleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@shopify/polaris-icons';
import { Banner, LegacyCard, Icon, IconSource } from '@shopify/polaris';
import {
  OfferSuggestedRuleDto,
  OfferSuggestedRulePriorityDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  OfferSuggestedRuleTypeDtoEnum,
  OfferTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import classNames from 'classnames';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';

type RulesPrioritiesProps = {
  rules?: OfferSuggestedRuleDto[];
  priorities?: OfferSuggestedRulePriorityDto[];
  changeOrder: (startIndex: number, endIndex: number) => void;
  offerType: OfferTypeDtoEnum;
};

export const RulesPriorities: React.FC<RulesPrioritiesProps> = (props) => {
  const { priorities, changeOrder, rules, offerType } = props;

  const [i18n] = useI18n();

  const [hoveredPriority, setHoveredPriority] = useState<string | null>(null);

  const getRuleIndex = useCallback(
    (ruleId: string) => {
      const index = rules?.findIndex((rule) => rule.id === ruleId);

      const ruleNumber =
        typeof index === 'number' && index > -1
          ? `${i18n.translate('Rule')} ${index + 1}`
          : 'AI Suggestions';
      return ruleNumber;
    },
    [rules, i18n]
  );

  const getQuantityOfSuggested = useCallback(
    (ruleId: string) => {
      const value =
        offerType !== OfferTypeDtoEnum.ORDER_BUMP
          ? rules?.find((rule) => rule.id === ruleId)?.suggestedItems?.length
          : rules?.find((rule) => rule.id === ruleId)?.proposedItem?.product ||
              rules?.find((rule) => rule.id === ruleId)?.proposedItem
                ?.productVariant
            ? 1
            : 0;

      return value
        ? offerType !== OfferTypeDtoEnum.ORDER_BUMP
          ? `${value} ${i18n.translate('SuggestedItems')}`
          : `${value} ${i18n.translate('ProposedItems')}`
        : offerType !== OfferTypeDtoEnum.ORDER_BUMP
          ? i18n.translate('NoSuggestedItems')
          : i18n.translate('NoProposedItems');
    },
    [rules, offerType, i18n]
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      changeOrder(result.source.index, result.destination.index);
    },
    [changeOrder]
  );

  return (
    <LegacyCard>
      <div className='RulePriority'>
        <LegacyCard.Section>
          <h4 className='TileTitle'>{i18n.translate('Title')}</h4>
          <span className='TileDescription'>{i18n.translate('Subtitle')}</span>
        </LegacyCard.Section>
        <LegacyCard.Section>
          <h6 className='PrioritiesInfoTitle'>
            {i18n.translate('PrioritiesTitle')}
          </h6>
          <span
            className='TileDescription'
            style={{ marginBottom: '4px !important' }}
          >
            {i18n.translate('PrioritiesSubtitle')}
          </span>
          <div className='prioritiesBlock'>
            <div className='numbers'>
              {priorities?.map((priority, idx) => (
                <div key={idx} className='number'>
                  {idx + 1}
                </div>
              ))}
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='priorities'>
                {(provided) => (
                  <div
                    className='rules'
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {priorities?.map((priority, idx, array) => (
                      <Draggable
                        key={priority.ruleId}
                        draggableId={priority.ruleId as string}
                        index={idx}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={classNames({
                              rule: true,
                              disabled: priority.enabled !== true,
                            })}
                            onPointerEnter={() =>
                              setHoveredPriority(priority.ruleId as string)
                            }
                            onPointerLeave={() => setHoveredPriority(null)}
                          >
                            <Link
                              to={priority.ruleId as string}
                              smooth
                              duration={1000}
                              hashSpy={true}
                              spy={true}
                              offset={-60}
                              className='link'
                            >
                              {getRuleIndex(priority.ruleId as string)}&nbsp;
                            </Link>
                            <span className='content'>
                              {priority.ruleType !==
                                OfferSuggestedRuleTypeDtoEnum.AI_SUGGESTED &&
                                `${
                                  priority.name ? `- ${priority.name}` : ''
                                } - ${getQuantityOfSuggested(
                                  priority.ruleId as string
                                )}`}
                              {!priority.enabled &&
                                ` (${i18n.translate('Disabled')})`}
                            </span>
                            {hoveredPriority === priority.ruleId && (
                              <div className='dragHandleMinor'>
                                <Icon
                                  source={DragHandleIcon as IconSource}
                                  tone='subdued'
                                />
                              </div>
                            )}
                            {hoveredPriority === priority.ruleId && (
                              <div className='orderBtns'>
                                <div
                                  className='btn'
                                  onClick={() =>
                                    idx !== 0 && changeOrder(idx, idx - 1)
                                  }
                                >
                                  <Icon
                                    source={ChevronUpIcon as IconSource}
                                    tone={idx !== 0 ? 'base' : 'subdued'}
                                  />
                                </div>
                                <div
                                  className='btn'
                                  onClick={() =>
                                    array.length - 1 !== idx &&
                                    changeOrder(idx, idx + 1)
                                  }
                                >
                                  <Icon
                                    source={ChevronDownIcon as IconSource}
                                    tone={
                                      array.length - 1 !== idx
                                        ? 'base'
                                        : 'subdued'
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          {offerType === OfferTypeDtoEnum.ORDER_BUMP && (
            <Banner tone='info'>{i18n.translate('Info')}</Banner>
          )}
        </LegacyCard.Section>
      </div>
    </LegacyCard>
  );
};
