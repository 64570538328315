import React, { useCallback, useMemo } from 'react';
import { useI18n } from '@shopify/react-i18n';
import './SectionDivider.scss';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import {
  LineWeightDto,
  OptionDtoSectionDividerComponentDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { LineWeightTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import { Checkbox, TextField, Text } from '@shopify/polaris';
import ColorSelectorBox from 'core/components/WidgetEditor/ColorSelectorBox/ColorSelectorBox';

type SectionDividerProps = {
  sectionDivider: OptionDtoSectionDividerComponentDto;
  disabled?: boolean;
  inherited?: boolean;
  onSystemReset: () => void;
  setSectionDivider: (value: OptionDtoSectionDividerComponentDto) => void;
};

export const SectionDivider: React.FC<SectionDividerProps> = ({
  sectionDivider,
  disabled,
  inherited,
  onSystemReset,
  setSectionDivider,
}) => {
  const [i18n] = useI18n();

  const lineWeightOptions = useMemo(
    () =>
      Object.keys(LineWeightTypeDtoEnum).map((value) => ({
        value: value,
        label: i18n.translate(`${value}`),
      })),
    []
  );

  const updateLineWeight = useCallback(
    (field: keyof LineWeightDto, data: LineWeightDto[keyof LineWeightDto]) => {
      setSectionDivider({
        ...sectionDivider,
        value: {
          ...sectionDivider.value,
          lineWeight: {
            ...sectionDivider.value?.lineWeight,
            [field]: data,
          },
        },
      });
    },
    [setSectionDivider, sectionDivider]
  );

  const onLineWeightValdiate = useCallback(() => {
    const customValue = sectionDivider.value?.lineWeight?.customValue || 0;
    customValue > 6
      ? updateLineWeight('customValue', 6)
      : customValue < 1
      ? updateLineWeight('customValue', 1)
      : null;
  }, [updateLineWeight, sectionDivider]);

  return (
    <div className='SectionDivider'>
      <StyleComponentHeader
        label={i18n.translate(`SectionDivider`)}
        inherited={inherited}
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            disabled: disabled,
            onAction: () => onSystemReset(),
          },
        ]}
      >
        <Checkbox
          onChange={(value) =>
            setSectionDivider({
              ...sectionDivider,
              enabled: value,
            })
          }
          checked={sectionDivider.enabled}
          label={i18n.translate('DisplayDivider')}
          disabled={disabled}
        />

        {sectionDivider.enabled && (
          <>
            <div className='SectionDividerRow'>
              <div className='LineWeightBox'>
                <Text as='p'>{i18n.translate(`LineWeight`)}</Text>
                <div className='RightContent'>
                  <div className='LineWeightInputs'>
                    <SelectOptions
                      options={lineWeightOptions}
                      onOptionSelect={(value) =>
                        updateLineWeight('type', value as LineWeightTypeDtoEnum)
                      }
                      selectedOption={
                        sectionDivider.value?.lineWeight?.type ||
                        LineWeightTypeDtoEnum.MEDIUM
                      }
                      label=''
                      disabled={disabled}
                    />
                    {sectionDivider.value?.lineWeight?.type ===
                      LineWeightTypeDtoEnum.CUSTOM && (
                      <TextField
                        type='number'
                        value={sectionDivider.value?.lineWeight?.customValue?.toString()}
                        autoComplete=''
                        label=''
                        onChange={(value) =>
                          updateLineWeight('customValue', +value)
                        }
                        onBlur={onLineWeightValdiate}
                        suffix='px'
                        disabled={disabled}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='SectionDividerRow'>
              <Text as='p'>{i18n.translate(`Color`)}</Text>
              <div className='RightContent'>
                <ColorSelectorBox
                  color={sectionDivider.value?.color || ''}
                  disabled={disabled}
                  setColor={(value) =>
                    setSectionDivider({
                      ...sectionDivider,
                      value: {
                        ...sectionDivider.value,
                        color: value,
                      },
                    })
                  }
                />
              </div>
            </div>
          </>
        )}
      </StyleComponentHeader>
    </div>
  );
};
