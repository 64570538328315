import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  TextField,
  Icon,
  Pagination,
  Text,
  IconSource,
  InlineStack,
  Box,
} from '@shopify/polaris';
import { SearchIcon, DisabledIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import {
  OfferSelectableCombinationListDtoGuid,
  PagedResponseDtoOfferSelectableCombinationListDtoGuid,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { Loader, tagDataProps } from 'core/components';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { setLastNotCombinedWithTypeData } from 'core/store/offersWizardSlice';
import {
  recordsPerPage,
  useConfigureOffers,
} from 'features/promotions/hooks/useConfigureOffers';
import { debounce } from 'lodash';
import { CombineWithModalTile } from '../CombineWithModalTile/CombineWithModalTile';
import './SelectOffersModal.scss';

export type SelectOffersOffer = {
  title?: string;
  titleToken?: string;
  id: string;
  promotion?: string;
};

export type SelectOffersModalProps = {
  onSelectedListChange: (list: tagDataProps[]) => void;
  initialSelectedOffers: tagDataProps[];
};

export const enum TableStateEnums {
  LOADED = 'Loaded',
  LOADING = 'Loading',
  EMPTY = 'Empty',
  EMPTY_WITH_SEARCH = 'Empty with search',
}

export const SelectOffersModal: React.FC<SelectOffersModalProps> = (props) => {
  const { onSelectedListChange, initialSelectedOffers } = props;

  const { combinationsOffersIsLoading } = useConfigureOffers();
  const dispatch = useAppDispatch();
  const [i18n] = useI18n();

  const {
    notCombinedWithList,
    lastNotCombinedWithTypeData,
    combinationsOffersListIsFetching,
  } = useAppSelector((state) => state.offersWizard);

  const [searchValue, setSearchValue] = useState<string>(
    lastNotCombinedWithTypeData.offers.search || ''
  );
  const [selectedOffers, setSelectedOffers] = useState<tagDataProps[]>(
    initialSelectedOffers
  );

  const offersObj:
    | PagedResponseDtoOfferSelectableCombinationListDtoGuid
    | undefined = useMemo(
    () => notCombinedWithList.offers,
    [notCombinedWithList.offers]
  );
  const currentList: OfferSelectableCombinationListDtoGuid[] = useMemo(
    () => offersObj?.items || [],
    [offersObj?.items]
  );

  const page: number = useMemo(
    () => lastNotCombinedWithTypeData.offers.page || 1,
    [lastNotCombinedWithTypeData.offers.page]
  );
  const totalPages: number | undefined = useMemo(
    () => Math.ceil((offersObj?.totalItems || 1) / recordsPerPage),
    [offersObj?.totalItems, recordsPerPage]
  );
  const tableState: string = useMemo(
    () =>
      !combinationsOffersIsLoading &&
      !combinationsOffersListIsFetching &&
      !currentList?.length &&
      !searchValue
        ? i18n.translate('Empty')
        : !combinationsOffersIsLoading &&
          !combinationsOffersListIsFetching &&
          !currentList?.length &&
          searchValue
        ? i18n.translate('EmptyWithSearch')
        : combinationsOffersIsLoading || combinationsOffersListIsFetching
        ? i18n.translate('Loading')
        : i18n.translate('Loaded'),
    [
      currentList?.length,
      combinationsOffersIsLoading,
      searchValue,
      combinationsOffersListIsFetching,
    ]
  );

  const emptyNoResultsState = useMemo(() => {
    return (
      <div className='emptyBlock'>
        <Icon source={DisabledIcon as IconSource} tone='base' />
        <div className='emptyBlock__Title'>
          {i18n.translate('SelectOffersModal.NoResults')}
        </div>
        <Text as='span' tone='subdued'>
          {tableState === TableStateEnums.EMPTY
            ? `${i18n.translate(
                'SelectOffersModal.EmptyState.ItLooks'
              )} ${i18n.translate(
                'SelectOffersModal.EmptyState.OfferEmptyState'
              )} ${i18n.translate(
                'SelectOffersModal.EmptyState.CreateAtLeast'
              )} ${i18n.translate('SelectOffersModal.EmptyState.ToBeAble')}`
            : `${i18n.translate(
                'SelectOffersModal.EmptyState.CouldNotFindAmatch'
              )} '${searchValue}'. ${i18n.translate(
                'SelectOffersModal.EmptyState.PleaseTryAnother'
              )} `}
        </Text>
      </div>
    );
  }, [tableState, i18n, searchValue]);

  const searchUpdate = useCallback(
    debounce((newValue: string) => {
      dispatch(
        setLastNotCombinedWithTypeData({
          ...lastNotCombinedWithTypeData,
          offers: {
            ...lastNotCombinedWithTypeData.offers,
            search: newValue,
            page: 1,
          },
        })
      );
    }, 500),
    [dispatch]
  );

  const handleSearchValueChange = useCallback(
    (newValue: string) => {
      setSearchValue(newValue);
      searchUpdate(newValue);
    },
    [setSearchValue, searchUpdate]
  );

  const handleCheckboxChange = useCallback(
    (value: boolean, id: string) => {
      setSelectedOffers((prev: tagDataProps[]) => {
        if (!value) {
          return prev.filter((ele: tagDataProps) => ele.id !== id);
        }
        const item = currentList.find(
          (ele: OfferSelectableCombinationListDtoGuid) => ele.id === id
        );
        const formatedItem = item && {
          id: item?.id,
          name: item?.title,
        };
        return formatedItem ? prev.concat(formatedItem) : prev;
      });
    },
    [setSelectedOffers, currentList]
  );

  const updatePageNumber = useCallback(
    (pageNumber: number) => {
      dispatch(
        setLastNotCombinedWithTypeData({
          ...lastNotCombinedWithTypeData,
          offers: {
            ...lastNotCombinedWithTypeData.offers,
            page: pageNumber,
          },
        })
      );
    },
    [dispatch, lastNotCombinedWithTypeData]
  );

  useEffect(() => {
    onSelectedListChange(selectedOffers);
  }, [selectedOffers, onSelectedListChange]);

  return (
    <div className='SelectOffersModal'>
      {tableState !== TableStateEnums.EMPTY && (
        <div className='SearchfieldBox'>
          <TextField
            placeholder={i18n.translate('SelectOffersModal.SearchPlaceholder')}
            label=''
            autoComplete='off'
            value={searchValue}
            onChange={handleSearchValueChange}
            prefix={<Icon source={SearchIcon as IconSource} />}
          />
        </div>
      )}
      <div className='renderedTable'>
        {tableState === TableStateEnums.LOADING && (
          <div className='tableLoader'>
            <Loader size='large' fullWidth />
          </div>
        )}
        {tableState !== TableStateEnums.EMPTY &&
          tableState !== TableStateEnums.EMPTY_WITH_SEARCH &&
          currentList &&
          currentList.map((ele: OfferSelectableCombinationListDtoGuid) => (
            <Box padding='100' key={ele.id}>
              <CombineWithModalTile
                title={ele.title}
                subTitle={ele.promotionTitle || ''}
                id={ele.id}
                checked={
                  selectedOffers.findIndex(
                    (offer: tagDataProps) => ele.id === offer.id
                  ) !== -1
                }
                checkboxChanged={handleCheckboxChange}
              />
            </Box>
          ))}
        {(tableState === TableStateEnums.EMPTY ||
          tableState === TableStateEnums.EMPTY_WITH_SEARCH) &&
          emptyNoResultsState}
      </div>
      {currentList?.length && totalPages && totalPages > 1 ? (
        <div className='paginationBlock'>
          <InlineStack align='center'>
            <Pagination
              label={`Page ${page} of ${totalPages}`}
              hasPrevious={page > 1}
              onPrevious={() => updatePageNumber(page - 1)}
              hasNext={page < totalPages}
              onNext={() => updatePageNumber(page + 1)}
            />
          </InlineStack>
        </div>
      ) : null}
    </div>
  );
};
