import { CouponCheckboxItem } from './CouponCheckbox/CouponCheckbox';

export const tagMetadata = [
  {
    title: 'DiscountNinjaTags',
    section: ['discountNinjaOriginatedTag'],
  },
  {
    title: 'PromotionTags',
    section: [
      'prefixForEachAppliedPromotion',
      'prefixForEachAppliedOffer',
      'prefixForEachAppliedPromotionCode',
      'prefixAppliedDiscountCode',
    ],
  },
];

export const tagsMetafields: CouponCheckboxItem[] = [
  {
    field: 'addTagFlagCouponFraud',
    tip: true,
    bannerType: 'warning',
  },
  {
    field: 'storeDiscountAppDataInOrderMetafields',
    tip: false,
    bannerType: 'info',
  },
];
