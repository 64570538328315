import React, { useCallback, useMemo } from 'react';
import { useI18n } from '@shopify/react-i18n';
import './Stitch.scss';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import {
  OptionDtoStitchComponentDto,
  StitchComponentDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import {
  LineWeightTypeDtoEnum,
  StitchDashSizeTypeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import ColorSelectorBox from 'core/components/WidgetEditor/ColorSelectorBox/ColorSelectorBox';
import { Checkbox, TextField, Text } from '@shopify/polaris';

type StitchProps = {
  stitch: OptionDtoStitchComponentDto;
  disabled?: boolean;
  inherited?: boolean;
  onSystemReset: () => void;
  setStitch: (value: OptionDtoStitchComponentDto) => void;
};

export const Stitch: React.FC<StitchProps> = ({
  stitch,
  disabled,
  inherited,
  onSystemReset,
  setStitch,
}) => {
  const [i18n] = useI18n();

  const lineWeightOptions = useMemo(
    () =>
      Object.keys(LineWeightTypeDtoEnum).map((value) => ({
        value: value,
        label: i18n.translate(`${value}`),
      })),
    []
  );
  const dashSizeOptions = useMemo(
    () =>
      Object.keys(StitchDashSizeTypeDtoEnum).map((value) => ({
        value: value,
        label: i18n.translate(`${value}`),
      })),
    []
  );

  const handleUpdateStitch = useCallback(
    (
      field: keyof StitchComponentDto,
      data: StitchComponentDto[keyof StitchComponentDto]
    ) =>
      setStitch({
        ...stitch,
        value: {
          ...stitch.value,
          [field]: data,
        },
      }),
    [setStitch, stitch]
  );

  const onBorderLineWeightValdiate = useCallback(() => {
    const customValue = stitch.value?.lineWeight?.customValue || 0;
    customValue > 6
      ? handleUpdateStitch('lineWeight', {
          ...stitch.value?.lineWeight,
          customValue: 6,
        })
      : customValue < 1
      ? handleUpdateStitch('lineWeight', {
          ...stitch.value?.lineWeight,
          customValue: 1,
        })
      : null;
  }, [stitch, handleUpdateStitch]);

  const onDashSizeValdiate = useCallback(() => {
    const customValue = stitch.value?.customDashSize || 0;
    customValue > 20
      ? handleUpdateStitch('customDashSize', 20)
      : customValue < 1
      ? handleUpdateStitch('customDashSize', 1)
      : null;
  }, [stitch, handleUpdateStitch]);

  return (
    <div className='Stitch'>
      <StyleComponentHeader
        label={i18n.translate(`Stitch`)}
        inherited={inherited}
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            disabled: disabled,
            onAction: () => onSystemReset(),
          },
        ]}
      >
        <Checkbox
          onChange={(value) => setStitch({ ...stitch, enabled: value })}
          checked={stitch.enabled}
          label={i18n.translate('ShowStitch')}
          disabled={disabled}
        />
        {stitch.enabled && (
          <>
            <div className='StitchRow'>
              <div className='LineWeightBox'>
                <Text as='p'>{i18n.translate(`DashSize`)}</Text>
                <div className='RightContent'>
                  <div className='LineWeightInputs'>
                    <SelectOptions
                      options={dashSizeOptions}
                      onOptionSelect={(value) =>
                        handleUpdateStitch(
                          'dashSizeType',
                          value as StitchDashSizeTypeDtoEnum
                        )
                      }
                      selectedOption={
                        stitch.value?.dashSizeType as StitchDashSizeTypeDtoEnum
                      }
                      label=''
                      disabled={disabled}
                    />
                    {stitch.value?.dashSizeType ===
                      StitchDashSizeTypeDtoEnum.CUSTOM && (
                      <TextField
                        type='number'
                        value={stitch.value.customDashSize?.toString()}
                        autoComplete=''
                        label=''
                        onChange={(value) =>
                          handleUpdateStitch('customDashSize', +value)
                        }
                        onBlur={onDashSizeValdiate}
                        suffix='px'
                        disabled={disabled}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='StitchRow'>
              <div className='LineWeightBox'>
                <Text as='p'>{i18n.translate(`LineWeight`)}</Text>
                <div className='RightContent'>
                  <div className='LineWeightInputs'>
                    <SelectOptions
                      options={lineWeightOptions}
                      onOptionSelect={(value) =>
                        handleUpdateStitch('lineWeight', {
                          ...stitch.value?.lineWeight,
                          type: value as LineWeightTypeDtoEnum,
                        })
                      }
                      selectedOption={
                        stitch.value?.lineWeight?.type as LineWeightTypeDtoEnum
                      }
                      label=''
                      disabled={disabled}
                    />
                    {stitch.value?.lineWeight?.type ===
                      LineWeightTypeDtoEnum.CUSTOM && (
                      <TextField
                        type='number'
                        value={stitch.value?.lineWeight?.customValue?.toString()}
                        autoComplete=''
                        label=''
                        onChange={(value) =>
                          handleUpdateStitch('lineWeight', {
                            ...stitch.value?.lineWeight,
                            customValue: +value,
                          })
                        }
                        onBlur={onBorderLineWeightValdiate}
                        suffix='px'
                        disabled={disabled}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='StitchRow'>
              {i18n.translate(`Color`)}
              <div className='RightContent'>
                <ColorSelectorBox
                  disabled={disabled}
                  color={stitch.value?.color || ''}
                  setColor={(value) => handleUpdateStitch('color', value)}
                />
              </div>
            </div>
          </>
        )}
      </StyleComponentHeader>
    </div>
  );
};
