import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line as LineChart } from 'react-chartjs-2';
import { Loader } from 'core/components';
import { ReportChartType } from 'features/reporting/types';
import { LegacyCard } from '@shopify/polaris';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const chartOptions: any = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
    },
  },
  aspectRatio: 3,
  scales: {
    yAxis2: {
      type: 'linear',
      display: true,
      position: 'right',
      ticks: {
        callback: (label: number) => `$${label}`,
      },
      grid: {
        drawOnChartArea: false,
      },
    },
  },
};

const useChartData: any = (data?: ReportChartType) => {
  const { Views } = data || {};
  return (
    Views && {
      labels: Views.Labels,
      datasets: [
        {
          label: 'Sessions',
          data: Views.Impressions,
          fill: false,
          borderColor: 'rgb(85, 99, 192)',
          tension: 0.3,
          yAxisID: 'yAxis',
        },
        {
          label: 'Orders',
          data: Views.Claimed,
          fill: false,
          borderColor: 'rgb(156, 107, 222)',
          tension: 0.3,
          yAxisID: 'yAxis',
        },
        {
          label: 'Revenue generated',
          data: Views.Revenue,
          fill: false,
          borderColor: 'rgb(79, 184, 60)',
          tension: 0.3,
          yAxisID: 'yAxis2',
        },
      ],
    }
  );
};

type PromotionActivityCardProps = {
  data?: ReportChartType;
  isLoading?: boolean;
};

export const PromotionActivityCard: React.FC<PromotionActivityCardProps> = (
  props
) => {
  const { data, isLoading } = props;
  const chartData = useChartData(data);

  return (
    <LegacyCard title='Promotion activity in the selected date range'>
      <LegacyCard.Section>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet
          delectus quo commodi quam fugit,
        </p>
        <p>
          aliquid voluptates numquam sapiente culpa reprehenderit quaerat nobis?
          Eum ducimus unde similique fuga neque quam obcaecati.
        </p>
      </LegacyCard.Section>
      <LegacyCard.Section>
        {isLoading && <Loader fullWidth />}
        {!isLoading && data && (
          <LineChart data={chartData} options={chartOptions} />
        )}
      </LegacyCard.Section>
    </LegacyCard>
  );
};
