import { BlockStack, Card, ChoiceList, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { ShopifyPromotionCodesPatternDto } from 'core/api/adminSettings/adminSettingsApi';
import { ShopifyPromotionCodesPatternMatchModeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { InputWithTags } from 'core/components';
import React, { useMemo } from 'react';
import './CodesPattern.scss';
type CodesPatternProps = {
  pattern: ShopifyPromotionCodesPatternDto;
  setPattern: (value: ShopifyPromotionCodesPatternDto) => void;
};
export const CodesPattern: React.FC<CodesPatternProps> = ({
  pattern,
  setPattern,
}) => {
  const [i18n] = useI18n();

  const modeOptions = useMemo(
    () =>
      Object.values(ShopifyPromotionCodesPatternMatchModeDtoEnum).map(
        (value) => ({ label: i18n.translate(value), value: value })
      ),
    [i18n]
  );

  return (
    <div className='CodesPattern'>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('CodesMatchPattern')}
            </Text>
          </BlockStack>
          <ChoiceList
            title=''
            choices={modeOptions}
            selected={[
              pattern.mode ||
                ShopifyPromotionCodesPatternMatchModeDtoEnum.ALLOW_ALL,
            ]}
            onChange={(value) =>
              setPattern({
                ...pattern,
                mode: value[0] as ShopifyPromotionCodesPatternMatchModeDtoEnum,
              })
            }
          />
          {pattern.mode ===
            ShopifyPromotionCodesPatternMatchModeDtoEnum.ALLOW_MATCHING_PATTERN && (
            <>
              <div className='PatternRules'>
                {i18n.translate('PatternRules')}

                <ul>
                  <li>{i18n.translate('Example1')}</li>
                  <li>{i18n.translate('Example2')}</li>
                </ul>
              </div>
              <InputWithTags
                placeholder={i18n.translate('AddPatterns')}
                onListChange={(value) =>
                  setPattern({
                    ...pattern,
                    includePattern: value,
                  })
                }
                isNotRequired
                title={i18n.translate('IncludeDiscount')}
                tagsToPrefill={pattern.includePattern || []}
              />
              <InputWithTags
                placeholder={i18n.translate('AddPatterns')}
                onListChange={(value) =>
                  setPattern({
                    ...pattern,
                    excludePattern: value,
                  })
                }
                isNotRequired
                title={i18n.translate('ExcludeDiscount')}
                tagsToPrefill={pattern.excludePattern || []}
              />
            </>
          )}
        </BlockStack>
      </Card>
    </div>
  );
};
