import React, { useCallback, useState } from 'react';
import './GenerateCodesModal.scss';
import { BlockStack, Modal, Tabs } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  GeneratePromotionCodesAutomaticallyRequestDto,
  GeneratePromotionCodesManuallyRequestDto,
  GeneratePromotionCodesRequestDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { useConfigurePromotions } from 'features/promotions/hooks/useConfigurePromotion';
import { PromotionCodeComplexityDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import AutomaticCodesGenerate from './components/AutomaticCodesGenerate/AutomaticCodesGenerate';
import ManualCodesGenerate from './components/ManualCodesGenerate/ManualCodesGenerate';
type GenerateCodesModalProps = {
  isOpen: boolean;
  onClose: () => void;
  refetchList: () => void;
};
const GenerateCodesModal: React.FC<GenerateCodesModalProps> = ({
  isOpen,
  refetchList,
  onClose,
}) => {
  const [i18n] = useI18n();
  const { generatePromotionCodesIsLoading, generatePromotionCodes } =
    useConfigurePromotions();
  const [selectedTab, setSelectedtab] = useState(0);

  const [requestSetup, setRequestSetup] =
    useState<GeneratePromotionCodesRequestDto>({
      automatic: {
        complexity: PromotionCodeComplexityDtoEnum.MEDIUM,
        amount: 1,
        maximumUses: 0,
        prefix: '',
        suffix: '',
      },
      manual: {
        maximumUses: 0,
      },
    });
  const tabs = [
    {
      id: 'automatically',
      content: i18n.translate('Automatically'),
      panelID: 'automatically-content',
    },
    {
      id: 'manually',
      content: i18n.translate('Manually'),
      panelID: 'manually-content',
    },
  ];
  const handleTabChange = useCallback(
    (selectedTabIndex: number) => {
      setSelectedtab(selectedTabIndex);
    },
    [setRequestSetup]
  );

  const handleUpdateAutomatic = useCallback(
    (
      field: keyof GeneratePromotionCodesAutomaticallyRequestDto,
      data: GeneratePromotionCodesAutomaticallyRequestDto[keyof GeneratePromotionCodesAutomaticallyRequestDto]
    ) =>
      setRequestSetup((prev) => ({
        ...prev,
        automatic: {
          ...prev?.automatic,
          [field]: data,
        },
      })),
    [setRequestSetup]
  );

  const handleUpdateManual = useCallback(
    (
      field: keyof GeneratePromotionCodesManuallyRequestDto,
      data: GeneratePromotionCodesManuallyRequestDto[keyof GeneratePromotionCodesManuallyRequestDto]
    ) =>
      setRequestSetup((prev) => ({
        ...prev,
        manual: {
          ...prev?.manual,
          [field]: data,
        },
      })),
    [setRequestSetup]
  );

  const onGenerateCodes = useCallback(() => {
    generatePromotionCodes({
      ...(selectedTab === 0 && {
        automatic: requestSetup.automatic,
      }),
      ...(selectedTab === 1 && {
        manual: requestSetup.manual,
      }),
    }).then(() => {
      refetchList();
      onClose();
    });
  }, [requestSetup, generatePromotionCodes]);

  const manualNotValid =
    selectedTab === 1 && !requestSetup.manual?.codes?.length;

  return (
    <Modal
      open={isOpen}
      title={i18n.translate('GeneratePromotionCodes')}
      onClose={onClose}
      primaryAction={{
        content: i18n.translate('Generate'),
        disabled: manualNotValid,
        loading: generatePromotionCodesIsLoading,
        onAction: onGenerateCodes,
      }}
      secondaryActions={[
        { content: i18n.translate('Cancel'), onAction: onClose },
      ]}
    >
      <div className='GenerateCodesModal'>
        <Modal.Section>
          <BlockStack gap='400'>
            <div className='ModeTabs'>
              <Tabs
                tabs={tabs}
                selected={selectedTab}
                onSelect={handleTabChange}
                fitted
              />
            </div>
            {selectedTab === 0 ? (
              <AutomaticCodesGenerate
                automatic={requestSetup.automatic || {}}
                handleUpdateAutomatic={handleUpdateAutomatic}
              />
            ) : (
              <ManualCodesGenerate
                manaul={requestSetup.manual || {}}
                handleUpdateManual={handleUpdateManual}
              />
            )}
          </BlockStack>
        </Modal.Section>
      </div>
    </Modal>
  );
};

export default GenerateCodesModal;
