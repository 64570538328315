import { useAppDispatch, useIsDebugOrLocal } from 'core/hooks';
import { useI18n } from '@shopify/react-i18n';
import { useNavigate } from 'react-router-dom';
import {
  setCombinedWithList,
  setCurrentCombinationIsLoaded,
  setLastCombinedWithTypeFetched,
} from 'core/store/offersWizardSlice';
import { useOfferFormValidity } from 'features/promotions/hooks/useOfferFormValidity';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { DiscountOfferTabsEnum } from '../../../enums/OfferTypeTabs';
import { Combinations } from '../../Combinations/Combinations';
import { Discount } from '../../DiscountNew/Discount';
import { Products } from '../../ProductsNew/Products';
import {
  OfferSalesDto,
  OfferLevelDto,
  ShopifyObjectDto,
  OfferProductLimitDto,
  OfferCombinationsDto,
  OfferSalesDiscountSpecificationDto,
  OptionDtoOfferMinimumSpendDto,
  OptionDtoOfferDiscountLimitDto,
  OfferCartRulesDto,
  OfferPrerequisiteEntitledAppliesToDto,
  OfferPrerequisiteEntitledExclusionsDto,
  OfferPrerequisiteEntitledNameFiltersDto,
  OptionDtoString,
  OfferPrerequisiteEntitledAdvancedSettingsDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { MinimumSpend } from '../../MinimumSpend/MinimumSpend';
import { DiscountLimits } from '../../DiscountLimits/DiscountLimits';
import { BlockStack, Page } from '@shopify/polaris';
import { OfferTemplate } from '../../OfferTemplate/OfferTemplate';
import { LearnMoreModal } from '../../LearnMoreModal/LearnMoreModal';
import { getInitialSelectedDiscountType } from '../../DiscountNew/utils/utils';
import { OfferTypeSpecialCases } from 'core/enums/GrayBoxEnum';
import { OfferParentComponentProps } from '../../../types/OfferTypesFromCatalogTypes';
import { CartRules } from '../../CartRules/CartRules';

export const SalesOffer: React.FC<OfferParentComponentProps> = (props) => {
  const { offerTemplate, offerType, handleOfferDataUpdate, savedData } = props;

  const dispatch = useAppDispatch();
  const { validityChangeHandler } = useOfferFormValidity();
  const [i18n] = useI18n();

  const [offerData, setOfferData] = useState<OfferSalesDto | null>(
    offerTemplate?.template as OfferSalesDto
  );
  const [showHowModal, setShowHowModal] = useState<boolean>(false);
  const [configureCartRules, setConfigureCartRules] = useState<boolean>(false);
  const [configureProducts, setConfigureProducts] = useState<boolean>(false);
  const [ruleIndex, setRuleIndex] = useState<number>(0);
  const [groupIndex, setGroupIndex] = useState<number>(0);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const isDebugOrLocal = useIsDebugOrLocal();
  const navigate = useNavigate();

  const toggleShowHowModal = useCallback(() => {
    setShowHowModal((prevState: boolean) => !prevState);
  }, [setShowHowModal]);

  const discountSpecificationDataUpdate = useCallback(
    (specification: OfferSalesDiscountSpecificationDto) => {
      setOfferData((prevState: OfferSalesDto | null) => ({
        ...prevState,
        specification: specification,
      }));
    },
    [setOfferData]
  );

  useEffect(() => {
    if (
      offerTemplate?.template &&
      offerData &&
      !isEqual(offerData, offerTemplate?.template)
    ) {
      handleOfferDataUpdate(offerData);
    }
  }, [offerData, offerTemplate]);

  const levelDataUpdate = useCallback(
    (level: OfferLevelDto) => {
      dispatch(setCurrentCombinationIsLoaded(false));
      dispatch(setCombinedWithList({}));
      dispatch(setLastCombinedWithTypeFetched(null));
      setOfferData((prevState: OfferSalesDto | null) => ({
        ...prevState,
        level: level,
      }));
    },
    [setOfferData, dispatch]
  );

  const appliesToDataUpdate = useCallback(
    (data: OfferPrerequisiteEntitledAppliesToDto) => {
      setOfferData((prevState: OfferSalesDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          appliesTo: data,
        },
      }));
    },
    [setOfferData]
  );

  const exclusionsUpdate = useCallback(
    (data: boolean) => {
      setOfferData((prevState: OfferSalesDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          exclusions: {
            ...prevState?.products?.exclusions,
            collections: {
              ...prevState?.products?.exclusions?.collections,
              enabled: data,
            },
          },
        },
      }));
    },
    [setOfferData]
  );

  const excludedResourcesUpdate = useCallback(
    (data: ShopifyObjectDto[]) => {
      setOfferData((prevState: OfferSalesDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          exclusions: {
            ...prevState?.products?.exclusions,
            collections: {
              ...prevState?.products?.exclusions?.collections,
              value: data,
            },
          },
        },
      }));
    },
    [setOfferData]
  );

  const limitUpdate = useCallback(
    (data: OfferProductLimitDto) => {
      setOfferData((prevState: OfferSalesDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          limit: data,
        },
      }));
    },
    [setOfferData]
  );

  const cartRulesUpdate = useCallback(
    (data: OfferCartRulesDto) => {
      setOfferData((prevState: OfferSalesDto | null) => ({
        ...prevState,
        cartRules: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const combinationsUpdate = useCallback(
    (data: OfferCombinationsDto) => {
      setOfferData((prevState: OfferSalesDto | null) => ({
        ...prevState,
        combinations: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const limitUpdateData = useCallback(
    (data: OptionDtoOfferDiscountLimitDto) => {
      setOfferData((prevState: OfferSalesDto | null) => ({
        ...prevState,
        discountLimit: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const advancedSettingsUpdate = useCallback(
    (
      field: keyof OfferPrerequisiteEntitledAdvancedSettingsDto,
      value: string
    ) => {
      setOfferData((prevState: OfferSalesDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          advancedSettings: {
            ...prevState?.products?.advancedSettings,
            [field]: value,
          },
        },
      }));
    },
    [setOfferData]
  );

  const nameFiltersUpdate = useCallback(
    (
      filterName: keyof OfferPrerequisiteEntitledNameFiltersDto,
      field: keyof OptionDtoString,
      value: string | boolean | null
    ) => {
      setOfferData((prevState: OfferSalesDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          nameFilters: {
            ...prevState?.products?.nameFilters,
            [filterName]: {
              ...prevState?.products?.nameFilters?.[filterName],
              [field]: value,
            },
          },
        },
      }));
    },
    [setOfferData]
  );

  const minimumSpendUpdate = useCallback(
    (data: OptionDtoOfferMinimumSpendDto) => {
      setOfferData((prevState: OfferSalesDto | null) => ({
        ...prevState,
        minimumSpend: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  return (
    <>
      {configureCartRules && (
        <CartRules
          savedData={(savedData?.template as OfferSalesDto)?.cartRules}
          onFormValidityChange={validityChangeHandler(
            DiscountOfferTabsEnum.CartRules
          )}
          onCartRulesChange={cartRulesUpdate}
          setConfigureComponent={setConfigureCartRules}
          configureComponent={configureCartRules}
          specialOfferType={OfferTypeSpecialCases.Sales}
          groupIndex={groupIndex}
          ruleIndex={ruleIndex}
          setRuleIndex={setRuleIndex}
          rulesSaved={offerData?.cartRules || {}}
          setGroupIndex={setGroupIndex}
          setIsChanged={setIsChanged}
          isChanged={isChanged}
        />
      )}
      {configureProducts && (
        <Products
          products={(offerTemplate?.template as OfferSalesDto)?.products}
          onAppliesToUpdate={appliesToDataUpdate}
          onExclusionsUpdate={exclusionsUpdate}
          onExcludedResourcesUpdate={excludedResourcesUpdate}
          onLimitUpdate={limitUpdate}
          onFormValidityChange={validityChangeHandler(
            DiscountOfferTabsEnum.Products
          )}
          onNameFiltersUpdate={nameFiltersUpdate}
          onAdvancedSettingsUpdate={advancedSettingsUpdate}
          savedData={(savedData?.template as OfferSalesDto)?.products}
          setConfigureComponent={setConfigureProducts}
          configureComponent={configureProducts}
        />
      )}
      {!configureCartRules && !configureProducts && (
        <Page
          title={i18n.translate(`ConfigureOffer`)}
          backAction={{
            content: i18n.translate('CreateOffer'),
            onAction: async () => {
              !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
              navigate('../..', { relative: 'path' });
            },
          }}
        >
          <BlockStack gap='600'>
            <OfferTemplate
              type={offerType}
              toggleShowHowModal={toggleShowHowModal}
              discountType={getInitialSelectedDiscountType(
                (offerTemplate?.template as OfferSalesDto)?.level
              )}
            />
            <Discount
              specification={
                (offerTemplate?.template as OfferSalesDto)?.specification
              }
              level={(offerTemplate?.template as OfferSalesDto)?.level}
              onSpecificationUpdate={discountSpecificationDataUpdate}
              onLevelUpdate={levelDataUpdate}
              offerType={offerType}
              onValidityStateChange={validityChangeHandler(
                DiscountOfferTabsEnum.Discount
              )}
            />
            {(offerTemplate?.template as OfferSalesDto)?.products !==
              undefined && (
              <Products
                products={(offerTemplate?.template as OfferSalesDto)?.products}
                onAppliesToUpdate={appliesToDataUpdate}
                onExclusionsUpdate={exclusionsUpdate}
                onExcludedResourcesUpdate={excludedResourcesUpdate}
                onLimitUpdate={limitUpdate}
                onFormValidityChange={validityChangeHandler(
                  DiscountOfferTabsEnum.Products
                )}
                onNameFiltersUpdate={nameFiltersUpdate}
                onAdvancedSettingsUpdate={advancedSettingsUpdate}
                savedData={(savedData?.template as OfferSalesDto)?.products}
                setConfigureComponent={setConfigureProducts}
                configureComponent={configureProducts}
              />
            )}
            <CartRules
              savedData={(savedData?.template as OfferSalesDto)?.cartRules}
              onFormValidityChange={validityChangeHandler(
                DiscountOfferTabsEnum.CartRules
              )}
              onCartRulesChange={cartRulesUpdate}
              setConfigureComponent={setConfigureCartRules}
              configureComponent={configureCartRules}
              specialOfferType={OfferTypeSpecialCases.Sales}
              groupIndex={groupIndex}
              ruleIndex={ruleIndex}
              setRuleIndex={setRuleIndex}
              rulesSaved={offerData?.cartRules || {}}
              setGroupIndex={setGroupIndex}
              setIsChanged={setIsChanged}
              isChanged={isChanged}
            />
            <DiscountLimits
              type='Discount'
              discountLimit={
                (offerTemplate?.template as OfferSalesDto)?.discountLimit
              }
              discountLimitUpdate={limitUpdateData}
            />
            <Combinations onCombinationsChange={combinationsUpdate} />
            {offerTemplate?.minimumSpendSupported && (
              <MinimumSpend
                minimumSpend={
                  (offerTemplate?.template as OfferSalesDto)?.minimumSpend || {}
                }
                minimumSpendUpdate={minimumSpendUpdate}
              />
            )}
            {showHowModal && (
              <LearnMoreModal
                onModalClose={toggleShowHowModal}
                // url='https://youtu.be/9htdypfdlBM'
                title={i18n.translate('Tutorial')}
              />
            )}
          </BlockStack>
        </Page>
      )}
    </>
  );
};
