import { Layout, TextField } from '@shopify/polaris';
import { useAppDispatch, usePasswordDevLogin } from 'core/hooks';
import React, { useCallback, useState } from 'react';
import { ModalCustom } from '../ModalCustom/ModalCustom';
import styles from './PasswordDevLogin.module.scss';
import { usePostSettingsV6GeneralAppSecretCheckMutation } from 'core/api/adminSettings/adminSettingsApi';
import { setToastMessages } from 'core/store/offersWizardSlice';
import { useI18n } from '@shopify/react-i18n';
import resolveEnvVar from 'env-var-resolver';

const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';

export const PasswordDevLogin = () => {
  const { showLoginDevModal, toggleOpenLoginDevModal, setPasswordDevLogin } =
    usePasswordDevLogin();
  const dispatch = useAppDispatch();
  const [i18n] = useI18n();
  const [password, setPassword] = useState<string>('');

  const [getAppSecretMutation, { isLoading: getAppSecretIsLoading }] =
    usePostSettingsV6GeneralAppSecretCheckMutation();

  const getAppSecret = useCallback(async () => {
    await getAppSecretMutation({
      'X-LimoniApps-AppName': APP_NAME,
      body: {
        secret: password,
      },
    })
      .unwrap()
      .then(() => {
        setPasswordDevLogin(password);
      })
      .catch((err) => {
        dispatch(
          setToastMessages({
            error: true,
            message: err.data.errors[0],
          })
        );
      });
  }, [getAppSecretMutation, password, setPasswordDevLogin]);

  const handleSave = useCallback(() => {
    getAppSecret();
  }, [getAppSecret]);

  return (
    <ModalCustom
      isOpen={showLoginDevModal}
      showCloseButton={false}
      overlayClose={false}
      onClose={() => toggleOpenLoginDevModal()}
      title={i18n.translate('SetPassword')}
      buttons={[
        {
          content: i18n.translate('Submit'),
          primary: true,
          disabled: !password || getAppSecretIsLoading,
          action: handleSave,
        },
      ]}
    >
      <Layout>
        <Layout.Section>
          <div className={styles.EmailDevLoginContent}>
            <TextField
              value={password}
              onChange={setPassword}
              label=''
              autoComplete='off'
              placeholder={i18n.translate('EnterPassword')}
            ></TextField>
          </div>
        </Layout.Section>
      </Layout>
    </ModalCustom>
  );
};
