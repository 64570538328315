import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './Notification.scss';
import { useI18n } from '@shopify/react-i18n';
import { isEmpty, isEqual } from 'lodash';
import {
  DeviceTypeDtoEnum,
  NotificationStatesPromotionEntryTypeDtoEnum,
  PageTypeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import { useAppSelector } from 'core/hooks';
import {
  WidgetsFetchTypeEnum,
  useConfigureWidgets,
} from 'features/settings/hooks/useConfigureWidgets';
import { OfferWidgetSetupDtoNotificationOfferDto } from 'core/api/adminWidgets/adminWidgetsApi';
import {
  getCurrentMessage,
  parseSmartTagCode,
} from 'features/settings/components/EditPresetSkeleton/utils/utils';
import { PreviewEntry } from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components';
import { usePrevious } from 'core/hooks/usePrevious';

type NotificationProps = {
  data: any;
  previewType: DeviceTypeDtoEnum;
  defaultLanguage?: string;
  adminActivePath: string;
  getSelectionFromThePreview(path: string): void;
  handleSwitchWidgetState?(widgetName: string, entries: any[]): void;
};

export const Notification: React.FC<NotificationProps> = ({
  previewType,
  defaultLanguage,
  data,
  adminActivePath,
  getSelectionFromThePreview,
  handleSwitchWidgetState,
}) => {
  const [i18n] = useI18n();

  const { fontListData } = useConfigureWidgets(
    WidgetsFetchTypeEnum.PRESET_FONT_LIST
  );

  const {
    defaultIconLibary,
    defaultImageLibary,
    customIconLibary,
    customImageLibary,
    notificationOfferMessages,
    notificationHeaderMessages,
    notificationFooterMessages,
    selectedPage,
  } = useAppSelector((state) => state.widgets);

  const { smartTagsCodes } = useAppSelector((state) => state.offersWizard);

  const [currentData, setCurrentData] = useState<any>({});
  const [injectStyles, setInjectStyles] = useState<boolean>(false);

  const currentState = useMemo(
    () =>
      data?.currentEntries[0] ===
        NotificationStatesPromotionEntryTypeDtoEnum.COLLAPSED_STATE &&
      !data?.options?.collapsedState?.options?.header?.sameAsExpandedState
        ? 'collapsed'
        : 'expanded',
    [
      data?.currentEntries,
      data?.options?.collapsedState?.options?.header?.sameAsExpandedState,
    ]
  );

  const currentMainOption = useMemo(
    () =>
      data?.options?.[
        data?.currentEntries[0] ===
        NotificationStatesPromotionEntryTypeDtoEnum.COLLAPSED_STATE
          ? 'collapsedState'
          : 'expandedState'
      ]?.options,
    [data?.currentEntries, data?.options]
  );

  const imagesArray = useMemo(
    () => [...defaultImageLibary, ...customImageLibary],
    [customImageLibary, defaultImageLibary]
  );
  const iconsArray = useMemo(
    () => [...defaultIconLibary, ...customIconLibary],
    [customIconLibary, defaultIconLibary]
  );

  const assetsArray = useMemo(
    () =>
      [...iconsArray, ...imagesArray].map((item) => ({
        id: item.id,
        url: item.url,
      })),
    [iconsArray, imagesArray]
  );

  const currentMessages = useMemo(
    () =>
      data?.currentEntries[0] ===
        NotificationStatesPromotionEntryTypeDtoEnum.EXPANDED_STATE &&
      data?.options?.expandedState?.offers?.length
        ? data?.options?.expandedState?.offers?.map(
            (item: OfferWidgetSetupDtoNotificationOfferDto) => ({
              token: item.offerId,
              message:
                item.offerId &&
                notificationOfferMessages?.length &&
                notificationOfferMessages.find(
                  (message) => message.offerId === item.offerId
                )?.[previewType.toLowerCase() as keyof PreviewEntry]
                  ? parseSmartTagCode(
                      notificationOfferMessages.find(
                        (message) => message.offerId === item.offerId
                      )?.[previewType.toLowerCase() as keyof PreviewEntry] ||
                        '',
                      smartTagsCodes
                    )
                  : getCurrentMessage(
                      item.setup?.general?.text?.message?.entries,
                      defaultLanguage,
                      smartTagsCodes
                    ),
              icon: iconsArray.find(
                (icon) => icon.id === item.setup?.general?.icon?.value?.iconId
              )?.url,
            })
          )
        : [
            {
              token: 'example',
              message: i18n.translate('example'),
              icon: iconsArray[0]?.url,
            },
          ],
    [
      i18n,
      data?.currentEntries,
      data?.options?.expandedState?.offers,
      iconsArray,
      defaultLanguage,
      notificationOfferMessages,
      previewType,
      smartTagsCodes,
    ]
  );

  const currentHeadline = useMemo(() => {
    const headerMessage =
      notificationHeaderMessages?.[currentState]?.[
        previewType.toLowerCase() as keyof PreviewEntry
      ];

    return headerMessage
      ? parseSmartTagCode(headerMessage, smartTagsCodes)
      : getCurrentMessage(
          currentMainOption?.header?.headlineText?.value?.message?.entries,
          defaultLanguage,
          smartTagsCodes
        );
  }, [
    notificationHeaderMessages,
    previewType,
    currentState,
    defaultLanguage,
    currentMainOption?.header?.headlineText?.value?.message?.entries,
    smartTagsCodes,
  ]);

  const currentFooter = useMemo(
    () =>
      notificationFooterMessages?.[
        previewType.toLowerCase() as keyof PreviewEntry
      ]
        ? parseSmartTagCode(
            notificationFooterMessages?.[
              previewType.toLowerCase() as keyof PreviewEntry
            ],
            smartTagsCodes
          )
        : getCurrentMessage(
            currentMainOption?.footer?.footerText?.value?.message?.entries,
            defaultLanguage,
            smartTagsCodes
          ),
    [
      notificationFooterMessages,
      previewType,
      defaultLanguage,
      currentMainOption?.footer?.footerText?.value?.message?.entries,
      smartTagsCodes,
    ]
  );

  const currentAdminMode = useMemo(
    () => (selectedPage === PageTypeDtoEnum.DRAWER_CART ? 'false' : 'true'),
    [selectedPage]
  );

  const prevMessages = usePrevious(currentMessages);

  const addHours = useCallback((date: Date, hours: number) => {
    date.setTime(date.getTime() + hours * 60 * 60 * 1000);
    return date;
  }, []);

  const elementToReplaceWith = useMemo(
    () =>
      `
            <la-dn-notification
        admin-mode="${currentAdminMode}"
        admin-active-path="${adminActivePath}"
        admin-mode-label='[{"component":"notification","label":"${i18n.translate(
          'notification'
        )}","icon":"widget","states":[{"key":"collapsed","name":"Collapsed"},{"key":"expanded","name":"Expanded"}],"subComponents":[{"component":"image","label":"${i18n.translate(
        'image'
      )}","icon":"component"},{"component":"header","label":"${i18n.translate(
        'header'
      )}","icon":"component"},{"component":"footer","label":"${i18n.translate(
        'footer'
      )}","icon":"component"},{"component":"banner","label":"${i18n.translate(
        'offer'
      )}","icon":"offer","subComponents":[{"component":"icon","label":"${i18n.translate(
        'icon'
      )}","icon":"component"},{"component":"message","label":"${i18n.translate(
        'message'
      )}","icon":"component"}]}]}]'
        state="expanded"
        variant="minimal"
        vertical-position="bottom"
        horizontal-position="left"
      >
        <la-dn-notification-launcher message-counter="true"></la-dn-notification-launcher>

        <la-dn-notification-headline>${
          currentHeadline || ''
        }</la-dn-notification-headline>

        <la-dn-notification-header>
          ${currentHeadline || ''}
        </la-dn-notification-header>

        <la-dn-notification-body></la-dn-notification-body>

        <la-dn-notification-footer>
          ${currentFooter || ''}
        </la-dn-notification-footer>
      </la-dn-notification>
`,
    [
      adminActivePath,
      i18n,
      currentHeadline,
      currentFooter,
      currentAdminMode,
      addHours,
    ]
  );

  const showNotification = useCallback(
    (data?: any) => {
      const parser = new DOMParser();
      const container = document.querySelector('#notifications-root');

      const notification = parser
        .parseFromString(elementToReplaceWith, 'text/html')
        .querySelector('la-dn-notification');

      if (data) {
        setCurrentData({ messages: data.messages });
      }

      if (
        container &&
        notification &&
        !container.querySelector('la-dn-notification')
      ) {
        container?.appendChild(notification);
      } else if (
        container &&
        notification &&
        container.querySelector('la-dn-notification')
      ) {
        const currentNotification =
          container.querySelector('la-dn-notification');

        currentNotification?.setAttribute('admin-mode', currentAdminMode);

        if (currentNotification) {
          const headline = currentNotification.querySelector(
            'la-dn-notification-headline'
          );
          const header = currentNotification.querySelector(
            'la-dn-notification-header'
          );
          const footer = currentNotification.querySelector(
            'la-dn-notification-footer'
          );

          if (headline) {
            headline.innerHTML =
              notification.querySelector('la-dn-notification-headline')
                ?.innerHTML || '';
          }

          if (header) {
            header.innerHTML =
              notification.querySelector('la-dn-notification-header')
                ?.innerHTML || '';
          }

          if (footer) {
            footer.innerHTML =
              notification.querySelector('la-dn-notification-footer')
                ?.innerHTML || '';
          }
        }
      }
    },
    [currentAdminMode, elementToReplaceWith, setCurrentData]
  );

  useEffect(() => {
    if (!isEmpty(currentData)) {
      document.dispatchEvent(
        new CustomEvent('la:dn:notification:render', {
          detail: currentData,
        })
      );
      setInjectStyles(true);
    }
  }, [currentData]);

  useEffect(() => {
    if (injectStyles && data) {
      const notification = document.querySelector('la-dn-notification');
      notification?.setAttribute('styles', JSON.stringify(data));
    }
  }, [data, injectStyles]);

  useEffect(() => {
    if (injectStyles) {
      const notification = document.querySelector('la-dn-notification');
      notification?.setAttribute(
        'force-mobile',
        (previewType === DeviceTypeDtoEnum.MOBILE).toString()
      );
    }
  }, [injectStyles, previewType]);

  useEffect(() => {
    if (injectStyles) {
      const notification = document.querySelector('la-dn-notification');
      notification?.setAttribute('admin-active-path', adminActivePath);
    }
  }, [adminActivePath]);

  useEffect(() => {
    if (injectStyles) {
      const notification = document.querySelector('la-dn-notification');
      notification?.setAttribute('font-assets', JSON.stringify(fontListData));
    }
  }, [fontListData, injectStyles]);

  useEffect(() => {
    if (injectStyles) {
      const notification = document.querySelector('la-dn-notification');
      notification?.setAttribute('image-assets', JSON.stringify(assetsArray));
    }
  }, [assetsArray, injectStyles]);

  useEffect(() => {
    showNotification({
      messages: currentMessages,
    });
    document
      .querySelector('la-dn-notification')
      ?.addEventListener('la:dn:notification:admin-change', (e) =>
        getSelectionFromThePreview((e as CustomEvent).detail)
      );
    document
      .querySelector('la-dn-notification')
      ?.addEventListener('la:dn:notification:state-change', (e) => {
        handleSwitchWidgetState?.(
          'notification',
          (e as CustomEvent).detail === 'collapsed'
            ? [NotificationStatesPromotionEntryTypeDtoEnum.COLLAPSED_STATE]
            : [NotificationStatesPromotionEntryTypeDtoEnum.EXPANDED_STATE]
        );
      });
  }, []);

  useEffect(() => {
    showNotification();
  }, [currentHeadline, currentFooter, currentAdminMode]);

  useEffect(() => {
    if (currentMessages && !isEqual(currentMessages, prevMessages)) {
      document.dispatchEvent(
        new CustomEvent('la:dn:notification:update', {
          detail: {
            messages: currentMessages,
          },
        })
      );
    }
  }, [currentMessages]);

  return <div id='notifications-root'></div>;
};
