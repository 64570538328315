import React from 'react';

import {
  TextField as PolarisTextField,
  TextFieldProps as PolarisTextFieldProps,
  Icon,
  IconSource,
} from '@shopify/polaris';

type TextFieldProps = PolarisTextFieldProps & {
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconPosition?: 'left' | 'right';
};

export const TextField: React.FC<TextFieldProps> = (props) => {
  const { icon, iconPosition, prefix, suffix, ...otherProps } = props;

  const prefixContent =
    icon && iconPosition !== 'right' ? (
      <>
        <Icon source={icon as IconSource} /> {prefix}
      </>
    ) : (
      prefix ?? ''
    );

  const suffixContent =
    icon && iconPosition === 'right' ? (
      <>
        <Icon source={icon as IconSource} /> {suffix}
      </>
    ) : (
      suffix ?? ''
    );

  return (
    <PolarisTextField
      prefix={prefixContent}
      suffix={suffixContent}
      {...otherProps}
    />
  );
};
