import React from 'react';
type IconProps = {
  color?: string;
  size?: number;
};
export const ShopifyPlusIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width='120'
      height='25'
      viewBox='0 0 120 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M93.5203 9.45093C91.9918 9.45093 90.7376 9.99019 89.8462 11.0204L90.0188 10.1123C90.0734 9.82485 89.8801 9.58193 89.5963 9.58193C89.3126 9.58193 89.0264 9.82485 88.9724 10.1123L86.3315 24.0013C86.2769 24.2887 86.4702 24.5316 86.754 24.5316C87.0377 24.5316 87.324 24.2887 87.3786 24.0013L88.3591 18.8428C88.72 19.5022 89.5574 20.4065 91.4375 20.4065C92.9083 20.4065 94.1418 19.9486 95.1047 19.0443C96.0984 18.1114 96.7763 16.7233 97.1197 14.9173C97.4631 13.1125 97.3118 11.7275 96.6715 10.801C96.0526 9.90561 94.9917 9.45093 93.5203 9.45093ZM91.6227 19.4348C89.3861 19.4348 88.5009 17.873 89.0628 14.9185C89.6302 11.9361 91.0458 10.4239 93.2711 10.4239C95.7029 10.4239 96.605 11.8941 96.03 14.9185C95.4437 17.9995 94.0432 19.4348 91.6227 19.4348Z'
        fill='black'
      />
      <path
        d='M116.308 14.3312C114.982 13.8327 113.728 13.3621 113.966 12.1093C114.086 11.4798 114.59 10.4229 116.622 10.4229C117.764 10.4229 118.485 10.7428 119.109 11.5275C119.227 11.6737 119.36 11.6738 119.417 11.6738C119.677 11.6738 119.939 11.4512 119.99 11.1873C120.017 11.0442 119.99 10.9596 119.928 10.8471C119.926 10.8426 119.923 10.8382 119.92 10.8337C119.286 9.91544 118.239 9.44995 116.808 9.44995C114.722 9.44995 113.232 10.4687 112.919 12.1087C112.547 14.0724 114.262 14.7293 115.776 15.3093C117.084 15.8104 118.321 16.2835 118.092 17.4847C117.769 19.1807 116.014 19.4338 114.996 19.4338C113.592 19.4338 112.726 19.0472 112.098 18.1404C112.096 18.1378 112.093 18.1346 112.091 18.1321C112.014 18.0348 111.91 17.9858 111.784 17.9858C111.553 17.9858 111.267 18.1817 111.212 18.4723C111.184 18.6148 111.211 18.6987 111.274 18.8119C111.692 19.6049 112.948 20.4068 114.811 20.4068C117.174 20.4068 118.791 19.3143 119.139 17.4847C119.511 15.5338 117.809 14.8947 116.308 14.3312Z'
        fill='black'
      />
      <path
        d='M113.142 6.70803H112.037L112.25 5.58882C112.304 5.30139 112.111 5.05847 111.827 5.05847C111.544 5.05847 111.257 5.30139 111.203 5.58882L110.99 6.70803H109.885C109.601 6.70803 109.316 6.95096 109.261 7.23839C109.207 7.52582 109.4 7.76874 109.684 7.76874H110.788L110.576 8.88795C110.521 9.17538 110.714 9.4183 110.998 9.4183C111.282 9.4183 111.568 9.17538 111.622 8.88795L111.835 7.76874H112.94C113.223 7.76874 113.509 7.52582 113.564 7.23839C113.62 6.95159 113.426 6.70803 113.142 6.70803Z'
        fill='black'
      />
      <path
        d='M25.4247 17.4883C25.9614 17.7713 26.9275 18.1624 27.8509 18.1407C28.6883 18.1407 29.139 17.6835 29.139 17.1188C29.139 16.5751 28.817 16.2056 27.915 15.6836C26.7988 15.0311 25.9615 14.1173 25.9615 12.9211C25.9615 10.8112 27.7436 9.31042 30.3411 9.31042C31.4792 9.31042 32.3593 9.52791 32.8314 9.81089L32.1446 11.9425C31.7366 11.7466 31.0712 11.5291 30.2985 11.5291C29.4611 11.5291 28.9243 11.9209 28.9243 12.5294C28.9243 13.0076 29.311 13.3561 30.0624 13.7695C31.2218 14.4435 32.1879 15.3573 32.1879 16.6406C32.1879 19.0329 30.2771 20.3817 27.6149 20.3601C26.3908 20.3385 25.2527 20.0116 24.6953 19.6205L25.4247 17.4883Z'
        fill='black'
      />
      <path
        d='M32.7891 20.2998L35.666 5.07031H38.6289L37.4908 10.9741L37.5335 10.9354C38.3275 9.97831 39.3583 9.31759 40.6677 9.31759C42.2138 9.31759 43.0939 10.3249 43.0939 12.0215C43.0939 12.5436 43.0079 13.3874 42.8792 14.0405L41.7198 20.3005H38.7569L39.873 14.2281C39.9584 13.8148 40.0023 13.28 40.0023 12.8673C40.0023 12.2148 39.745 11.7411 39.0789 11.7411C38.1342 11.7411 37.1248 13.0192 36.7388 14.9988L35.7507 20.3005H32.7891V20.2998Z'
        fill='black'
      />
      <path
        d='M53.8089 13.486C53.8089 17.2055 51.4468 20.3812 47.9472 20.3812C45.285 20.3812 43.8682 18.5104 43.8682 16.1829C43.8682 12.5506 46.2303 9.28772 49.794 9.28772C52.5641 9.28836 53.8089 11.3328 53.8089 13.486ZM46.8957 16.1181C46.8957 17.2055 47.3251 18.0754 48.3345 18.0754C49.9013 18.0754 50.782 15.2475 50.782 13.3989C50.782 12.5074 50.438 11.5942 49.3866 11.5942C47.7758 11.5935 46.8957 14.3992 46.8957 16.1181Z'
        fill='black'
      />
      <path
        d='M53.1016 24.5295L55.1624 13.5536C55.3991 12.3135 55.6345 10.7098 55.7638 9.58102H58.3833L58.2113 11.2732H58.254C59.0481 10.1451 60.2081 9.3877 61.3883 9.3877C63.5564 9.3877 64.4372 11.1422 64.4372 13.165C64.4372 16.8196 62.1616 20.4189 58.6834 20.4189C57.9533 20.4189 57.2879 20.2987 56.9439 20.017H56.8793L56.0632 24.5295H53.1016ZM57.3318 17.6826C57.6539 17.9433 58.0186 18.1175 58.5559 18.1175C60.2094 18.1175 61.3475 15.3551 61.3475 13.4194C61.3475 12.615 61.0681 11.7667 60.1667 11.7667C59.136 11.7667 58.1699 13.0067 57.8265 14.8769L57.3318 17.6826Z'
        fill='black'
      />
      <path
        d='M64.5234 20.3003L66.5203 9.58329H69.5045L67.4863 20.3003H64.5234ZM68.367 8.45517C67.5296 8.45517 66.9716 7.72959 66.9716 6.83804C66.9716 5.85937 67.723 5.03967 68.6677 5.03967C69.5692 5.03967 70.1492 5.75826 70.1492 6.62819C70.1278 7.73722 69.3331 8.45581 68.4104 8.45581L68.367 8.45517Z'
        fill='black'
      />
      <path
        d='M70.0625 20.3L71.6299 11.8391H70.2558L70.6852 9.58292H72.0593L72.1453 9.04366C72.382 7.78201 72.8534 6.50064 73.8628 5.63071C74.6569 4.93502 75.709 4.62024 76.7617 4.62024C77.4917 4.62024 78.0284 4.72453 78.3718 4.87651L77.7924 7.22304C77.535 7.13656 77.2343 7.06978 76.8477 7.06978C75.8596 7.06978 75.2582 8.02174 75.0869 9.04429L74.9795 9.58292H77.0404L76.6324 11.8391H74.5928L73.0254 20.3H70.0625Z'
        fill='black'
      />
      <path
        d='M80.7129 9.5835L81.1849 14.3808C81.3142 15.4466 81.3996 16.3522 81.4423 16.9162H81.485C81.7216 16.3522 81.9363 15.5115 82.3657 14.3592L84.1692 9.58413H87.2608L83.6325 17.43C82.3444 20.127 81.0989 22.0926 79.7462 23.3758C78.6941 24.3761 77.4487 24.8696 76.8473 25L76.0312 22.4512C76.5253 22.277 77.1474 22.0144 77.706 21.6016C78.3928 21.1234 78.9728 20.4697 79.3162 19.7956C79.4016 19.6437 79.4235 19.5406 79.3808 19.3232L77.5987 9.58477L80.7129 9.5835Z'
        fill='black'
      />
      <path
        d='M110.719 10.5911C110.435 10.5911 110.149 10.834 110.094 11.1214L109.093 16.3862C108.55 19.0678 106.925 19.4341 105.401 19.4341C102.655 19.4341 102.586 17.85 102.881 16.3003L104.057 10.1129C104.112 9.82544 103.918 9.58252 103.635 9.58252C103.351 9.58252 103.065 9.82544 103.01 10.1129L101.834 16.3003C101.597 17.5473 101.681 18.4783 102.093 19.1454C102.609 19.9823 103.66 20.4064 105.215 20.4064C106.771 20.4064 107.982 19.9816 108.817 19.1454C109.483 18.4783 109.921 17.5473 110.158 16.3003L111.142 11.1214C111.196 10.834 111.002 10.5911 110.719 10.5911Z'
        fill='black'
      />
      <path
        d='M101.261 5.08813C100.977 5.08813 100.691 5.33106 100.636 5.61849L97.9506 19.7453C97.896 20.0328 98.0894 20.2757 98.3731 20.2757C98.6569 20.2757 98.9431 20.0328 98.9977 19.7453L101.683 5.61849C101.738 5.33106 101.544 5.08813 101.261 5.08813Z'
        fill='black'
      />
      <path
        d='M14.5565 2.85464C14.5546 2.85528 14.2866 2.93922 13.8333 3.08167C13.758 2.83303 13.6463 2.52715 13.4875 2.22C12.9752 1.22988 12.2245 0.705255 11.318 0.703984C11.3168 0.703984 11.3161 0.703984 11.3149 0.703984C11.2521 0.703984 11.1893 0.709702 11.1266 0.715426C11.1002 0.682994 11.0732 0.650562 11.0443 0.620039C10.6495 0.192068 10.1423 -0.0165107 9.53588 0.0012949C8.36453 0.0356343 7.19821 0.892215 6.25284 2.41396C5.58682 3.48484 5.08149 4.83043 4.93774 5.87143C3.59314 6.29304 2.65217 6.58874 2.63208 6.5951C1.9535 6.81067 1.93216 6.83229 1.84302 7.48029C1.77585 7.97058 0 21.8837 0 21.8837L14.7354 24.4656V2.82412C14.6626 2.82921 14.5973 2.84256 14.5565 2.85464ZM11.1542 3.92235C10.3745 4.16717 9.52333 4.43426 8.66898 4.70198C8.90941 3.77036 9.36451 2.84256 9.92382 2.23463C10.1316 2.00888 10.4229 1.75642 10.7675 1.61207C11.092 2.29632 11.1617 3.26672 11.1542 3.92235ZM9.55848 0.791104C9.83343 0.78538 10.0651 0.846424 10.2628 0.978059C9.94642 1.14467 9.64009 1.38377 9.35321 1.69537C8.60935 2.50362 8.03937 3.75891 7.81213 4.9697C7.10279 5.19227 6.40915 5.40975 5.77137 5.61007C6.17375 3.70423 7.75061 0.843884 9.55848 0.791104ZM7.27981 11.6487C7.35891 12.9186 10.657 13.1959 10.8422 16.1707C10.9878 18.5109 9.61686 20.1115 7.64139 20.238C5.27044 20.3894 3.96539 18.9725 3.96539 18.9725L4.46757 16.8072C4.46757 16.8072 5.78141 17.8114 6.83287 17.7439C7.51961 17.7001 7.76505 17.1341 7.74057 16.7335C7.63762 15.0769 4.95218 15.1742 4.78207 12.4525C4.63957 10.1619 6.12416 7.84085 9.40092 7.631C10.6633 7.55024 11.3099 7.87646 11.3099 7.87646L10.5603 10.7158C10.5603 10.7158 9.72483 10.3305 8.73364 10.3934C7.28044 10.4875 7.26475 11.416 7.27981 11.6487ZM11.9326 3.67752C11.9238 3.07658 11.8528 2.24099 11.5766 1.51859C12.4655 1.68901 12.903 2.70775 13.0876 3.31504C12.7455 3.42315 12.3557 3.54525 11.9326 3.67752Z'
        fill='black'
      />
      <path
        d='M15.2227 24.4061L21.3355 22.8666C21.3355 22.8666 18.7034 4.83965 18.6871 4.71564C18.6702 4.59228 18.5634 4.5236 18.4756 4.51596C18.3877 4.50833 16.6664 4.48226 16.6664 4.48226C16.6664 4.48226 15.6169 3.45017 15.2227 3.05908V24.4061Z'
        fill='black'
      />
    </svg>
  );
};
