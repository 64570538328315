import React, { useCallback, useEffect, useState } from 'react';
import { BlockStack, Page } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { MandatoryCheckboxes } from './components/MandatoryCheckboxes/MandatoryCheckboxes';
import { Notification } from './components/Notification/Notification';
import { RefreshBehavior } from './components/RefreshBehavior/RefreshBehavior';
import { CartBehaviorSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import {
  setIsUnsavedChanges,
  setOnDiscardChanges,
  setOnSaveChanges,
} from 'core/store/settingsSlice';
import { SettingsSkeleton } from '../components/SettingsSkeleton/SettingsSkeleton';

export const CartBehavior: React.FC = () => {
  const [i18n] = useI18n();

  const {
    cartBehaviorDataChange,
    cartBehaviorDataIsFetching,
    cartBehaviorData,
  } = useConfigureSettings(SettingsFetchTypeEnum.CART_BEHAVIOR);
  const [cartBehaviorState, setCartBehaviorState] =
    useState<CartBehaviorSettingsDto>({});
  const [savedData, setSavedData] = useState<CartBehaviorSettingsDto>({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(cartBehaviorState) && !isEqual(savedData, cartBehaviorState)) {
      dispatch(setIsUnsavedChanges(true));
      dispatch(
        setOnDiscardChanges(() => {
          if (!isEmpty(savedData)) {
            setCartBehaviorState(savedData);
          }
        })
      );
      dispatch(
        setOnSaveChanges(() => {
          setSavedData(cartBehaviorState);
          return cartBehaviorDataChange(cartBehaviorState || {});
        })
      );
    } else if (isEqual(savedData.toString(), cartBehaviorData?.toString())) {
      dispatch(setIsUnsavedChanges(false));
    }
  }, [cartBehaviorState, savedData, dispatch, cartBehaviorData]);

  useEffect(() => {
    if (!isEmpty(cartBehaviorData)) {
      setCartBehaviorState(cartBehaviorData);
      setSavedData({ ...cartBehaviorData });
    }
  }, [cartBehaviorData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  const handleCartBehaviorLocaleState = useCallback(
    (
      section: string,
      value: string | boolean,
      field?: string,
      subfield?: string
    ) => {
      if (field && !subfield) {
        setCartBehaviorState(
          (
            prevState: Omit<
              CartBehaviorSettingsDto,
              'requiredCheckboxesCssSelector'
            >
          ) => ({
            ...prevState,
            [section]: {
              ...prevState[section as keyof typeof prevState],
              [field]: value,
            },
          })
        );
      } else if (subfield && field) {
        setCartBehaviorState(
          (
            prevState: Omit<
              CartBehaviorSettingsDto,
              'requiredCheckboxesCssSelector'
            >
          ) => ({
            ...prevState,
            [section]: {
              ...prevState[section as keyof typeof prevState],
              [field]: {
                ...prevState.refreshBehavior?.javascriptMethod,
                [subfield]: value,
              },
            },
          })
        );
      } else {
        setCartBehaviorState((prevState) => ({
          ...prevState,
          [section]: value,
        }));
      }
    },
    [cartBehaviorState]
  );

  return (
    <div className='CartBehavior'>
      {!cartBehaviorDataIsFetching && cartBehaviorData ? (
        <Page title={i18n.translate(`CartBehavior`)}>
          <BlockStack gap='600'>
            <RefreshBehavior
              data={cartBehaviorState.refreshBehavior}
              section='refreshBehavior'
              handleCartBehaviorLocaleState={handleCartBehaviorLocaleState}
            />
            <Notification
              data={cartBehaviorState.notification}
              section='notification'
              handleCartBehaviorLocaleState={setCartBehaviorState}
            />
            <MandatoryCheckboxes
              data={cartBehaviorState.requiredCheckboxesCssSelector}
              section='requiredCheckboxesCssSelector'
              handleCartBehaviorLocaleState={handleCartBehaviorLocaleState}
            />
          </BlockStack>
        </Page>
      ) : (
        <SettingsSkeleton />
      )}
    </div>
  );
};
