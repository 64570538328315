import React, { useCallback, useMemo } from 'react';
import './Size.scss';
import { useI18n } from '@shopify/react-i18n';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import { Button, Text, TextField } from '@shopify/polaris';
import { DeleteIcon, PlusIcon } from '@shopify/polaris-icons';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import { SizeComponentDto } from 'core/api/adminWidgets/adminWidgetsApi';
import {
  SizeTypeDtoEnum,
  SizeWidthDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';

type SizeProps = {
  size: SizeComponentDto;
  disabled?: boolean;
  disableWidth?: boolean;
  inherited?: boolean;
  title?: string;
  setSize?: (data: SizeComponentDto) => void;
  onSystemReset?: () => void;
};

export const Size: React.FC<SizeProps> = ({
  size,
  disabled,
  disableWidth,
  inherited,
  title,
  setSize,
  onSystemReset,
}) => {
  const [i18n] = useI18n();

  const sizeOptions = useMemo(
    () =>
      Object.values(SizeTypeDtoEnum).map((value) => {
        return {
          value: value,
          label: i18n.translate(`${value}`),
        };
      }),
    []
  );

  const widthOptions = useMemo(
    () =>
      Object.values(SizeWidthDtoEnum).map((value) => {
        return {
          value: value,
          label: i18n.translate(`${value}`),
        };
      }),
    []
  );

  const updateBreakPoint = useCallback(
    (index: number, field: 'maxWidth' | 'contentWidth', value: number) => {
      setSize?.({
        ...size,
        breakpoints: size.breakpoints?.map((item, i) =>
          i === index ? { ...item, [field]: value } : item
        ),
      });
    },
    [size, setSize]
  );
  const deleteBreakPoint = useCallback(
    (index: number) => {
      setSize?.({
        ...size,
        breakpoints: size.breakpoints?.filter((_, i) => i !== index),
      });
    },
    [size, setSize]
  );
  const addBreakPoint = useCallback(() => {
    setSize?.({
      ...size,
      breakpoints: [
        ...(size.breakpoints || []),
        {
          maxWidth: 1280,
          contentWidth: 1200,
        },
      ],
    });
  }, [size, setSize]);

  const handleSize = useCallback(
    (value: SizeTypeDtoEnum) => {
      setSize?.({
        ...size,
        size: value,
      });
    },
    [size, setSize]
  );

  return (
    <div className='Size'>
      <StyleComponentHeader
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            disabled: disabled,
            onAction: () => onSystemReset?.(),
          },
        ]}
        inherited={inherited}
        label={title ? title : i18n.translate(`Size`)}
      >
        <div className='SizeRow'>
          {i18n.translate(`Size`)}
          <div className='RightContent'>
            <SelectOptions
              options={sizeOptions}
              onOptionSelect={(value) => handleSize(value as SizeTypeDtoEnum)}
              selectedOption={size.size || SizeTypeDtoEnum.MEDIUM}
              disabled={disabled}
              label=''
            />
          </div>
        </div>
        {!disableWidth && (
          <>
            <div className='SizeRow'>
              {i18n.translate(`Width`)}
              <div className='RightContent'>
                <SelectOptions
                  options={widthOptions}
                  onOptionSelect={(value) => {
                    setSize?.({
                      ...size,
                      width: value as SizeWidthDtoEnum,
                    });
                  }}
                  selectedOption={size.width || SizeWidthDtoEnum.AUTO}
                  label=''
                  disabled={disabled}
                />
              </div>
            </div>

            {size.width === SizeWidthDtoEnum.BREAKPOINTS && (
              <div className='SizeRow'>
                <div />
                <div className='RightContent'>
                  <div className='BreakPoints'>
                    <Text as='p'>{i18n.translate('Breakpoints')}</Text>
                    <div className='BreakPointsList'>
                      {size.breakpoints?.map((item, index) => (
                        <div key={index} className='BreakPointRow'>
                          <TextField
                            type='number'
                            label={
                              index === 0 ? i18n.translate('MaxWidth') : ''
                            }
                            value={item.maxWidth?.toString()}
                            onChange={(value) =>
                              updateBreakPoint(index, 'maxWidth', +value)
                            }
                            autoComplete=''
                            disabled={disabled}
                          />
                          <TextField
                            type='number'
                            value={item.contentWidth?.toString()}
                            label={
                              index === 0 ? i18n.translate('ContentWidth') : ''
                            }
                            onChange={(value) =>
                              updateBreakPoint(index, 'contentWidth', +value)
                            }
                            autoComplete=''
                            disabled={disabled}
                          />
                          <div className='RemoveBreakpoint'>
                            <Button
                              onClick={() => deleteBreakPoint(index)}
                              icon={DeleteIcon}
                            />
                          </div>
                        </div>
                      ))}
                      <Button
                        icon={PlusIcon}
                        onClick={addBreakPoint}
                        variant='plain'
                        disabled={disabled}
                      >
                        {i18n.translate('AddBreakpoint')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {size.width === SizeWidthDtoEnum.FIXED && (
              <div className='SizeRow'>
                <div />
                <div className='RightContent'>
                  <TextField
                    autoComplete=''
                    label=''
                    value={size.fixedWidth?.toString()}
                    onChange={(value) =>
                      setSize?.({ ...size, fixedWidth: +value })
                    }
                    suffix='px'
                    disabled={disabled}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </StyleComponentHeader>
    </div>
  );
};
