import React, { useCallback } from 'react';
import './AnnouncementBarOffer.scss';
import {
  AnnouncementBarGeneralOfferDto,
  AnnouncementBarOfferPositionDto,
  AnnouncementBarSystemDefaultsDto,
  DeviceDisplayDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import {
  DeviceDisplay,
  Link,
  OfferPosition,
  PageDisplay,
  ShopSettingsWrapper,
} from '../../../../../components';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { AnnouncementBarOfferPositionDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { useAppDispatch } from 'core/hooks';
import { setToggleSettingsConfig } from 'core/store/widgetsSlice';

type AnnouncementBarOfferProps = {
  general: AnnouncementBarGeneralOfferDto;
  defaultGeneral: AnnouncementBarGeneralOfferDto;
  offerName: string;
  shopDefaultOffer: AnnouncementBarSystemDefaultsDto;
  setGeneral: (data: AnnouncementBarGeneralOfferDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const AnnouncementBarOffer: React.FC<AnnouncementBarOfferProps> = ({
  general,
  defaultGeneral,
  offerName,
  shopDefaultOffer,
  setGeneral,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const dispatch = useAppDispatch();
  const resetAllToSystemDefault = useCallback(() => {
    const { link } = shopDefaultOffer;
    setGeneral({
      ...general,
      link,
      position: defaultGeneral.position,
    });
  }, [general, shopDefaultOffer, defaultGeneral]);

  return (
    <div className='AnnouncementBarOffer'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        name={offerName}
        handleLeftMenuSelection={handleLeftMenuSelection}
        onDiscardChanges={() => setGeneral(defaultGeneral)}
        onResetToSystem={resetAllToSystemDefault}
      />
      <div className='RightSideSection'>
        <OfferPosition
          position={general.position as AnnouncementBarOfferPositionDtoEnum}
          onSystemReset={() =>
            setGeneral({ ...general, position: defaultGeneral.position })
          }
          setPosition={(value) =>
            setGeneral({
              ...general,
              position: value as unknown as AnnouncementBarOfferPositionDto,
            })
          }
        />
      </div>
      <div className='RightSideSection'>
        <Link
          link={general.link || {}}
          setLink={(data) => setGeneral({ ...general, link: data })}
          onSystemReset={() =>
            setGeneral({ ...general, link: shopDefaultOffer.link })
          }
        />
      </div>
      <ShopSettingsWrapper
        onOpen={() =>
          dispatch(
            setToggleSettingsConfig({
              action: 'open',
              path: 'announcement-bar',
            })
          )
        }
      >
        <div className='RightSideSection'>
          <DeviceDisplay
            deviceDisplay={
              general.shopSettings?.deviceDisplay as DeviceDisplayDto
            }
            disabled
          />
        </div>
        <div className='RightSideSection'>
          <PageDisplay
            pageDisplay={general.shopSettings?.pageDisplay || {}}
            onSystemReset={() =>
              setGeneral({
                ...general,
                shopSettings: {
                  ...general.shopSettings,
                  pageDisplay: shopDefaultOffer.pageDisplay,
                },
              })
            }
            setPageDisplay={(data) =>
              setGeneral({
                ...general,
                shopSettings: {
                  ...general.shopSettings,
                  pageDisplay: data,
                },
              })
            }
          />
        </div>
      </ShopSettingsWrapper>
    </div>
  );
};

export default AnnouncementBarOffer;
