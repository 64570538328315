import React, { useMemo } from 'react';
import './CodeListSkeleton.scss';
import { IndexTable, SkeletonDisplayText } from '@shopify/polaris';
import { IndexTableHeading } from '@shopify/polaris/build/ts/src/components/IndexTable';
import { NonEmptyArray } from '@shopify/polaris/build/ts/src/types';
import { useI18n } from '@shopify/react-i18n';
const CodeListSkeleton: React.FC = () => {
  const [i18n] = useI18n();

  const headings: NonEmptyArray<IndexTableHeading> = useMemo(
    () => [
      { title: i18n.translate(`Code`) },
      { title: i18n.translate(`Status`) },
      { title: i18n.translate(`Usage`) },
      { title: i18n.translate(`Order`) },
      { title: i18n.translate(`Customer`) },
    ],
    [i18n]
  );

  const rowMarkup = useMemo(() => {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((row, index) => (
      <IndexTable.Row disabled key={index} id={`${row}`} position={row}>
        <IndexTable.Cell>
          <SkeletonDisplayText size='small' maxWidth={`${15}ch` as any} />
        </IndexTable.Cell>

        <IndexTable.Cell>
          <SkeletonDisplayText size='small' maxWidth={`${10}ch` as any} />
        </IndexTable.Cell>

        <IndexTable.Cell>
          <SkeletonDisplayText size='small' maxWidth={`${5}ch` as any} />
        </IndexTable.Cell>
        <IndexTable.Cell>
          <SkeletonDisplayText size='small' maxWidth={`${15}ch` as any} />
        </IndexTable.Cell>
        <IndexTable.Cell>
          <SkeletonDisplayText size='small' maxWidth={`${15}ch` as any} />
        </IndexTable.Cell>
      </IndexTable.Row>
    ));
  }, []);
  return (
    <div className='CodeListSkeleton'>
      <IndexTable itemCount={5} headings={headings}>
        {rowMarkup}
      </IndexTable>
    </div>
  );
};

export default CodeListSkeleton;
