import { BlockStack } from '@shopify/polaris';
import React from 'react';
import {
  StyleSetting,
  WidgetSettingsWrapper,
  WidgetTypeSettingsWrapperEnum,
} from '../components';

export const DiscountLabelSettings = () => {
  return (
    <div className='AnnouncementBarSettings'>
      <WidgetSettingsWrapper
        widgetState={{
          //Need to be reworked Andriy
          widgetType: WidgetTypeSettingsWrapperEnum.COUNTDOWN_CLOCK,
          themeName: 'default',
        }}
        isLoading={false}
      >
        <BlockStack gap='600'>
          <StyleSetting styled />
        </BlockStack>
      </WidgetSettingsWrapper>
    </div>
  );
};
