import React, { useEffect, useMemo, useState } from 'react';
import {
  ActionItem,
  ActionListInPopover,
} from 'features/promotions/components/ActionListInPopover/ActionListInPopover';
import { Icon, InlineStack, List } from '@shopify/polaris';
import { ProductCostIcon } from '@shopify/polaris-icons';
import {
  boldMessageParts,
  generateMessage,
  generateRules,
  SectionTypeEnum,
} from '../../utils/utils';
import { I18n } from '@shopify/react-i18n';
import {
  OfferCartRuleDto,
  ShopifyObjectDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  OfferTargetTypeDtoEnum,
  ShopifyObjectTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { useConfigureOffers } from 'features/promotions/hooks/useConfigureOffers';
import { headerTitle } from 'core/components/SearchFieldWithGrayBoxOfferWizard/utils/utils';
import { ResourceSelectionProps } from 'core/components/SearchFieldWithGrayBox';

type RuleInfoProps = {
  actionList: ActionItem[];
  i18n: I18n;
  rule: OfferCartRuleDto;
  ruleIndex: number;
  noValue?: boolean;
  productAmount?: number | string;
  onRuleClick: () => void;
};

export const RuleInfo: React.FC<RuleInfoProps> = ({
  actionList,
  i18n,
  rule,
  ruleIndex,
  productAmount,
  noValue,
  onRuleClick,
}) => {
  const { getLookupShopifyDetailedObjectsDetails } = useConfigureOffers();

  const [currentSelections, setCurrentSelections] = useState<string>('');
  const [collectionExclusions, setCollectionExclusions] = useState<string>('');

  const resourceType = useMemo(() => {
    switch (rule.appliesTo?.type) {
      case OfferTargetTypeDtoEnum.COLLECTIONS:
        return ShopifyObjectTypeDtoEnum.COLLECTION;
      case OfferTargetTypeDtoEnum.PRODUCTS:
        return ShopifyObjectTypeDtoEnum.PRODUCT;
      case OfferTargetTypeDtoEnum.VARIANTS:
        return ShopifyObjectTypeDtoEnum.PRODUCT_VARIANT;
      default:
        return null;
    }
  }, [rule.appliesTo?.type]);

  const ruleCurrentList = useMemo(
    () =>
      rule.appliesTo?.type !== OfferTargetTypeDtoEnum.ALL
        ? ((rule.appliesTo?.[
            rule.appliesTo?.type?.toLowerCase() as keyof typeof rule.appliesTo
          ] || []) as ShopifyObjectDto[])
        : null,
    [rule.appliesTo]
  );

  const bullets = useMemo(
    () => generateRules(rule, collectionExclusions, i18n),
    [rule, i18n, collectionExclusions, generateRules]
  );

  useEffect(() => {
    if (resourceType && ruleCurrentList?.length) {
      getLookupShopifyDetailedObjectsDetails({
        type: resourceType,
        objects: ruleCurrentList,
      }).then((res) => {
        setCurrentSelections(
          headerTitle(res as ResourceSelectionProps[], resourceType, i18n)
        );
      });
    }
  }, [resourceType, ruleCurrentList]);

  useEffect(() => {
    if (
      rule.exclusions?.collections?.enabled &&
      rule.exclusions?.collections.value?.length
    ) {
      getLookupShopifyDetailedObjectsDetails({
        type: ShopifyObjectTypeDtoEnum.COLLECTION,
        objects: rule.exclusions?.collections.value,
      }).then((res) => {
        setCollectionExclusions(
          headerTitle(
            res as ResourceSelectionProps[],
            ShopifyObjectTypeDtoEnum.COLLECTION,
            i18n
          )
        );
      });
    }
  }, [rule.exclusions?.collections]);

  return (
    <div className='specificRule' onClick={onRuleClick}>
      <InlineStack wrap={false} align='space-between' blockAlign='center'>
        <InlineStack wrap={false} gap='200' blockAlign='center'>
          <div style={{ width: 20, height: 20 }}>
            <Icon source={ProductCostIcon} tone='base' />
          </div>
          {boldMessageParts(
            generateMessage(
              SectionTypeEnum.CART_RULES,
              rule,
              ruleIndex,
              currentSelections,
              i18n,
              productAmount,
              noValue
            )
          )}
        </InlineStack>
        <ActionListInPopover btnContent='' actionList={actionList} />
      </InlineStack>
      {bullets.length ? (
        <div className='bullet'>
          <List>
            {bullets.map((bullet, index) => (
              <List.Item key={index}>{bullet}</List.Item>
            ))}
          </List>
        </div>
      ) : null}
    </div>
  );
};
