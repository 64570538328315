import React from 'react';
type IconProps = {
  color?: string;
  size?: number;
  crossed?: boolean;
};
export const PaletteIcon: React.FC<IconProps> = ({ color, size, crossed }) => {
  return !crossed ? (
    <svg
      width={size}
      height={size}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 3.5C8 4.05228 7.55228 4.5 7 4.5C6.44772 4.5 6 4.05228 6 3.5C6 2.94772 6.44772 2.5 7 2.5C7.55228 2.5 8 2.94772 8 3.5Z'
        fill={color || '#4A4A4A'}
      />
      <path
        d='M4 6C4.55228 6 5 5.55228 5 5C5 4.44772 4.55228 4 4 4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6Z'
        fill={color || '#4A4A4A'}
      />
      <path
        d='M5 8.5C5 9.05229 4.55228 9.5 4 9.5C3.44772 9.5 3 9.05229 3 8.5C3 7.94772 3.44772 7.5 4 7.5C4.55228 7.5 5 7.94772 5 8.5Z'
        fill={color || '#4A4A4A'}
      />
      <path
        d='M10 6C10.5523 6 11 5.55228 11 5C11 4.44772 10.5523 4 10 4C9.44771 4 9 4.44772 9 5C9 5.55228 9.44771 6 10 6Z'
        fill={color || '#4A4A4A'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14C7.9665 14 8.75 13.2165 8.75 12.25V10.75C8.75 9.64543 9.64543 8.75 10.75 8.75H12.25C13.2165 8.75 14 7.9665 14 7C14 3.13401 10.866 0 7 0ZM10.75 7.25H12.25C12.3881 7.25 12.5 7.13807 12.5 7C12.5 3.96243 10.0376 1.5 7 1.5C3.96243 1.5 1.5 3.96243 1.5 7C1.5 10.0376 3.96243 12.5 7 12.5C7.13807 12.5 7.25 12.3881 7.25 12.25V10.75C7.25 8.817 8.817 7.25 10.75 7.25Z'
        fill={color || '#4A4A4A'}
      />
    </svg>
  ) : (
    <svg
      width={size}
      height={size}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.6396 2.85261C13.4947 4.01339 14 5.44769 14 7C14 7.9665 13.2165 8.75 12.25 8.75H10.75C9.64543 8.75 8.75 9.64543 8.75 10.75V12.25C8.75 13.2165 7.9665 14 7 14C5.44769 14 4.01339 13.4947 2.85261 12.6396L1.8381 13.6542C1.54521 13.9471 1.07034 13.9471 0.777444 13.6542C0.486287 13.363 0.484561 12.892 0.772267 12.5987C0.773995 12.597 0.775719 12.5953 0.777435 12.5936L12.0301 1.34099C12.0318 1.33925 12.0335 1.33751 12.0352 1.33576C12.3285 1.04799 12.7995 1.0497 13.0907 1.34088C13.3836 1.63377 13.3836 2.10864 13.0907 2.40154L12.6396 2.85261ZM12.25 7.25H10.75C8.817 7.25 7.25 8.817 7.25 10.75V12.25C7.25 12.3881 7.13807 12.5 7 12.5C5.86264 12.5 4.80591 12.1548 3.92888 11.5634L11.5634 3.92888C12.1548 4.80591 12.5 5.86264 12.5 7C12.5 7.13807 12.3881 7.25 12.25 7.25Z'
        fill={color || '#4A4A4A'}
      />
      <path
        d='M10.3807 0.869019C9.3785 0.315212 8.22606 0 7 0C3.13401 0 0 3.13401 0 7C0 8.22606 0.315212 9.3785 0.869019 10.3807L1.98598 9.26374C1.67379 8.57334 1.5 7.80697 1.5 7C1.5 3.96243 3.96243 1.5 7 1.5C7.80697 1.5 8.57334 1.67379 9.26374 1.98598L10.3807 0.869019Z'
        fill={color || '#4A4A4A'}
      />
      <path
        d='M7.97461 3.27511C7.87258 2.83112 7.47496 2.5 7 2.5C6.44772 2.5 6 2.94772 6 3.5C6 3.97496 6.33112 4.37258 6.77511 4.47461L7.97461 3.27511Z'
        fill={color || '#4A4A4A'}
      />
      <path
        d='M5 5C5 5.55228 4.55228 6 4 6C3.44772 6 3 5.55228 3 5C3 4.44772 3.44772 4 4 4C4.55228 4 5 4.44772 5 5Z'
        fill={color || '#4A4A4A'}
      />
    </svg>
  );
};
