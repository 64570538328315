import React, { useCallback } from 'react';
import { PromotionCodeFieldCallToActionButtonPresetDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import {
  ButtonStyle,
  SimplifiedTextEntries,
  Size,
  TextStyle,
} from '../../../../../components';
import { useI18n } from '@shopify/react-i18n';
import { isEqual } from 'lodash';

type PromotionCodeCTAProps = {
  ctaButton: PromotionCodeFieldCallToActionButtonPresetDto;
  defaultCTAButton: PromotionCodeFieldCallToActionButtonPresetDto;
  shopDefaultCTA: PromotionCodeFieldCallToActionButtonPresetDto;
  defaultLanguage: string;
  isPresetEditor?: boolean;
  setCTAButton: (data: PromotionCodeFieldCallToActionButtonPresetDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const PromotionCodeCTA: React.FC<PromotionCodeCTAProps> = ({
  ctaButton,
  defaultCTAButton,
  shopDefaultCTA,
  defaultLanguage,
  isPresetEditor,
  setCTAButton,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();

  const resetAllToSystemDefault = useCallback(() => {
    const { buttonStyle, label, size } = shopDefaultCTA;
    setCTAButton({ ...ctaButton, buttonStyle, label, size });
  }, [shopDefaultCTA, ctaButton]);

  const handleUpdateCTA = useCallback(
    (
      field: keyof PromotionCodeFieldCallToActionButtonPresetDto,
      data: PromotionCodeFieldCallToActionButtonPresetDto[keyof PromotionCodeFieldCallToActionButtonPresetDto]
    ) => setCTAButton({ ...ctaButton, [field]: data }),
    [ctaButton, setCTAButton]
  );

  return (
    <div className='PromotionCodeCTA'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={() => setCTAButton(defaultCTAButton)}
        onResetToSystem={resetAllToSystemDefault}
        name={WidgetListOptionsDtoEnum.CTA_BUTTON}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <Size
          size={ctaButton.size || {}}
          inherited={
            !isPresetEditor
              ? isEqual(ctaButton.size, defaultCTAButton.size)
              : undefined
          }
          onSystemReset={() => handleUpdateCTA('size', shopDefaultCTA.size)}
          setSize={(data) => handleUpdateCTA('size', data)}
          disableWidth
        />
      </div>
      <div className='RightSideSection'>
        <SimplifiedTextEntries
          message={ctaButton.label?.message || {}}
          title={i18n.translate('Label')}
          defaultLanguage={defaultLanguage}
          inherited={
            !isPresetEditor
              ? isEqual(ctaButton.label, defaultCTAButton.label)
              : undefined
          }
          setMessage={(data) =>
            handleUpdateCTA('label', { ...ctaButton.label, message: data })
          }
          onSystemReset={() => handleUpdateCTA('label', shopDefaultCTA.label)}
        />
        <TextStyle
          textStyle={ctaButton.label?.style || {}}
          onSystemReset={() => null}
          setTextStyle={(data) =>
            handleUpdateCTA('label', { ...ctaButton.label, style: data })
          }
          hideHeader
        />
      </div>
      <div className='RightSideSection'>
        <ButtonStyle
          buttonStyle={ctaButton.buttonStyle || {}}
          inherited={
            !isPresetEditor
              ? isEqual(ctaButton.buttonStyle, defaultCTAButton.buttonStyle)
              : undefined
          }
          setButtonStyle={(data) => handleUpdateCTA('buttonStyle', data)}
          onSystemReset={() =>
            handleUpdateCTA('buttonStyle', shopDefaultCTA.buttonStyle)
          }
        />
      </div>
    </div>
  );
};

export default PromotionCodeCTA;
