import React from 'react';
import './BlockType.scss';
import { AnnouncementBar } from './components/Announcement/AnnouncementBar';
import { ProductBanner } from './components/ProductBanner/ProductBanner';
import { PromotionalBadge } from './components/PromotionalBadge/PromotionalBadge';
import { Notification } from './components/Notification/Notification';
import { DeviceTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { PromotionSummary } from './components/PromotionSummary/PromotionSummary';
import { PromotionCodeField } from './components/PromotionCodeField/PromotionCodeField';
import { OfferRulePopUp } from './components/OfferRulePopUp/OfferRulePopUp';
import { WidgetListTypeDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { StrikethroughPricing } from './components/StrikethroughPricing/StrikethroughPricing';
import { CartText } from './components/CartText/CartText';
import { DiscountLabel } from './components/DiscountLabel/DiscountLabel';
import { NinjaCart } from './components/NinjaCart/NinjaCart';

type BlockTypeProps = {
  blockType: WidgetListTypeDtoEnum;
  data: any;
  previewType: DeviceTypeDtoEnum;
  defaultLanguage?: string;
  adminActivePath: string;
  getSelectionFromThePreview(path: string): void;
  handleSwitchWidgetState?(widgetName: string, entries: any[]): void;
  specialRoot?: string;
  priceWidgetType?: string;
  isPreset?: boolean;
};

export const BlockType: React.FC<BlockTypeProps> = (props) => {
  const {
    data,
    blockType,
    previewType,
    defaultLanguage,
    adminActivePath,
    getSelectionFromThePreview,
    handleSwitchWidgetState,
    specialRoot = '',
    priceWidgetType = '',
    isPreset = false,
  } = props;

  return (
    <>
      {blockType === WidgetListTypeDtoEnum.ANNOUNCEMENT_BAR && (
        <AnnouncementBar
          previewType={previewType}
          data={data}
          defaultLanguage={defaultLanguage}
          adminActivePath={adminActivePath}
          getSelectionFromThePreview={getSelectionFromThePreview}
        />
      )}
      {blockType === WidgetListTypeDtoEnum.PRODUCT_BANNER && (
        <ProductBanner
          previewType={previewType}
          data={data}
          defaultLanguage={defaultLanguage}
          adminActivePath={adminActivePath}
          getSelectionFromThePreview={getSelectionFromThePreview}
        />
      )}
      {blockType === WidgetListTypeDtoEnum.PROMOTIONAL_BADGE && (
        <PromotionalBadge
          previewType={previewType}
          data={data}
          defaultLanguage={defaultLanguage}
          adminActivePath={adminActivePath}
          getSelectionFromThePreview={getSelectionFromThePreview}
        />
      )}
      {blockType === WidgetListTypeDtoEnum.NOTIFICATION && (
        <Notification
          previewType={previewType}
          data={data}
          defaultLanguage={defaultLanguage}
          adminActivePath={adminActivePath}
          getSelectionFromThePreview={getSelectionFromThePreview}
          handleSwitchWidgetState={handleSwitchWidgetState}
        />
      )}
      {(blockType === WidgetListTypeDtoEnum.CART_PROMOTION_SUMMARY ||
        blockType === WidgetListTypeDtoEnum.DRAWER_CART_PROMOTION_SUMMARY) && (
        <PromotionSummary
          previewType={previewType}
          data={data}
          defaultLanguage={defaultLanguage}
          adminActivePath={adminActivePath}
          getSelectionFromThePreview={getSelectionFromThePreview}
          isDrawerCart={
            blockType === WidgetListTypeDtoEnum.DRAWER_CART_PROMOTION_SUMMARY
          }
          root={
            blockType === WidgetListTypeDtoEnum.CART_PROMOTION_SUMMARY
              ? 'cartPromotionSummary'
              : 'drawerCartPromotionSummary'
          }
        />
      )}
      {(blockType === WidgetListTypeDtoEnum.CART_PROMOTION_CODE_FIELD ||
        blockType ===
          WidgetListTypeDtoEnum.DRAWER_CART_PROMOTION_CODE_FIELD) && (
        <PromotionCodeField
          previewType={previewType}
          data={data}
          defaultLanguage={defaultLanguage}
          adminActivePath={adminActivePath}
          getSelectionFromThePreview={getSelectionFromThePreview}
          root={
            blockType === WidgetListTypeDtoEnum.CART_PROMOTION_CODE_FIELD
              ? 'cartPromotionCodeField'
              : 'drawerCartPromotionCodeField'
          }
        />
      )}
      {blockType === WidgetListTypeDtoEnum.OFFER_RULE_POPUP && (
        <OfferRulePopUp
          previewType={previewType}
          data={data}
          defaultLanguage={defaultLanguage}
          adminActivePath={adminActivePath}
          getSelectionFromThePreview={getSelectionFromThePreview}
        />
      )}
      {blockType === WidgetListTypeDtoEnum.NINJA_CART && (
        <NinjaCart
          previewType={previewType}
          data={data}
          defaultLanguage={defaultLanguage}
          handleSwitchWidgetState={handleSwitchWidgetState}
          adminActivePath={adminActivePath}
          getSelectionFromThePreview={getSelectionFromThePreview}
        />
      )}
      {(blockType === WidgetListTypeDtoEnum.PRODUCT_PAGE_PRICE ||
        blockType === WidgetListTypeDtoEnum.COLLECTION_PAGE_PRICE) && (
        <StrikethroughPricing
          previewType={previewType}
          data={data}
          defaultLanguage={defaultLanguage}
          adminActivePath={adminActivePath}
          getSelectionFromThePreview={getSelectionFromThePreview}
          root={specialRoot}
          priceWidgetType={priceWidgetType}
          isPreset={isPreset}
        />
      )}
      {(blockType === WidgetListTypeDtoEnum.CART_PAGE_CART_TEXT ||
        blockType === WidgetListTypeDtoEnum.DRAWER_CART_PAGE_CART_TEXT) && (
        <CartText
          previewType={previewType}
          data={data}
          defaultLanguage={defaultLanguage}
          adminActivePath={adminActivePath}
          getSelectionFromThePreview={getSelectionFromThePreview}
          root={
            blockType === WidgetListTypeDtoEnum.CART_PAGE_CART_TEXT
              ? 'cartPageCartText'
              : 'drawerCartPageCartText'
          }
        />
      )}
      {blockType === WidgetListTypeDtoEnum.DISCOUNT_LABEL && (
        <DiscountLabel
          previewType={previewType}
          data={data}
          defaultLanguage={defaultLanguage}
          adminActivePath={adminActivePath}
          getSelectionFromThePreview={getSelectionFromThePreview}
        />
      )}
    </>
  );
};
