import React, { ReactElement } from 'react';
import cs from 'classnames';
import { Tooltip } from '@shopify/polaris';

type ToolbarButtonProps = {
  active: boolean;
  name: string;
  icon: ReactElement | ReactElement[];
  className?: string;
  disabled?: boolean;
  onClick: () => void;
  tooltipContent?: string;
};

export const ToolbarButton: React.FC<ToolbarButtonProps> = (props) => {
  const { className, active, onClick, icon, disabled, tooltipContent, name } =
    props;

  return name !== 'textColor' && name !== 'text-align' ? (
    <Tooltip preferredPosition='mostSpace' content={tooltipContent}>
      <button
        className={cs(className, 'rte__toolbar-button', {
          'rte__toolbar-button--active': active,
          'rte__toolbar-button--disabled': disabled,
        })}
        onClick={onClick}
        disabled={disabled}
      >
        {icon}
      </button>
    </Tooltip>
  ) : (
    <button
      className={cs(className, 'rte__toolbar-button', {
        'rte__toolbar-button--active': active,
        'rte__toolbar-button--disabled': disabled,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
    </button>
  );
};
