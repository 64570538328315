import React, { useState, useCallback, useEffect, useMemo } from 'react';
import './PromotionList.scss';
import {
  IndexTable,
  IndexFilters,
  useSetIndexFiltersMode,
  useIndexResourceState,
  EmptyState,
  Card,
  Bleed,
} from '@shopify/polaris';
import {
  GetPromotionsRequestDto,
  GetPromotionsResponseDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  PromotionOperationTypeDtoEnum,
  PromotionListSortFieldDtoEnum,
  PromotionStatusDtoEnum,
  SortDirectionDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { useConfigurePromotions } from 'features/promotions/hooks/useConfigurePromotion';
import { useI18n } from '@shopify/react-i18n';
import EmptyPromotionsIcon from '../../assets/empty-state-promotions.png';
import { debounce, isEmpty, isEqual } from 'lodash';
import PromotionListRow from './components/PromotionListRow/PromotionListRow';
import { sortColumnOptions, sortPopoverOptions } from './utils';
import { NonEmptyArray } from '@shopify/polaris/build/ts/src/types';
import {
  IndexTableHeading,
  IndexTableSortDirection,
} from '@shopify/polaris/build/ts/src/components/IndexTable';
import PromotionListSkeleton from './components/PromotionListSkeleton/PromotionListSkeleton';

type PromotionListProps = {
  toggleCreatePromotionModal: () => void;
};

export const PromotionList: React.FC<PromotionListProps> = ({
  toggleCreatePromotionModal,
}) => {
  const [i18n] = useI18n();
  const {
    getPromotionListData,
    promotionListData,
    promotionListIsLoading,
    actionOnBatchOfPromotion,
    actionOnBatchOfPromotionIsLoading,
    getPromotionOperationProgress,
    promotionOperationProgressData,
  } = useConfigurePromotions();

  const [promotionRequestSetup, setPromotionRequestSetup] =
    useState<GetPromotionsRequestDto>({
      search: '',
      page: 1,
      itemsPerPage: 10,
      sortDirection: SortDirectionDtoEnum.ASC,
      statuses: null,
      sortBy: PromotionListSortFieldDtoEnum.NAME,
    });
  const [initialRequestSetup, setInitialRequestSetup] = useState(
    promotionRequestSetup
  );
  const [columnSortDirection, setColumnSortDirection] =
    useState<IndexTableSortDirection>('descending');
  const [currentSortIndex, setCurrentSortIndex] = useState<number>();

  const [promotionListState, setPromotionListState] =
    useState<GetPromotionsResponseDto>();

  const [querySearchValue, setQuerySearchValue] = useState<string>('');

  const [sortSelected, setSortSelected] = useState([
    `${PromotionListSortFieldDtoEnum.NAME} asc`,
  ]);

  const [activePopover, setActivePopover] = useState<string>('');
  const { mode, setMode } = useSetIndexFiltersMode();
  const tabsStrings = useMemo(
    () => [
      'ALL',
      PromotionStatusDtoEnum.PUBLISHED,
      PromotionStatusDtoEnum.SCHEDULED,
      PromotionStatusDtoEnum.DRAFT,
      PromotionStatusDtoEnum.EXPIRED,
      PromotionStatusDtoEnum.ARCHIVED,
    ],
    []
  );
  const promotionsInProgress = useMemo(
    () =>
      promotionListState?.items?.filter(
        (promotion) =>
          promotion.operationProgress?.status ===
            PromotionStatusDtoEnum.IN_PROGRESS_PUBLISHING ||
          promotion.operationProgress?.status ===
            PromotionStatusDtoEnum.IN_PROGRESS_UNPUBLISHING ||
          promotion.operationProgress?.status ===
            PromotionStatusDtoEnum.IN_PROGRESS_ROLLING_BACK
      ),
    [promotionListState]
  );

  const promotionIds = useMemo(
    () =>
      promotionListState?.items?.map((item) => {
        return {
          id: item.id || '1',
          disabled:
            item.status === PromotionStatusDtoEnum.IN_PROGRESS_PUBLISHING ||
            item.status === PromotionStatusDtoEnum.IN_PROGRESS_UNPUBLISHING ||
            item.status === PromotionStatusDtoEnum.IN_PROGRESS_ROLLING_BACK,
        };
      }),
    [promotionListState, activePopover]
  );

  const selectablePromotions = useMemo(
    () => promotionIds?.filter((item) => !item.disabled),
    [promotionIds]
  );

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(selectablePromotions || []);

  const headings: NonEmptyArray<IndexTableHeading> = useMemo(
    () => [
      { title: '' },
      { title: i18n.translate('Promotion') },
      { title: i18n.translate('Token') },
      { title: i18n.translate('Status') },
      { title: i18n.translate('Schedule') },
      { title: i18n.translate('Trigger') },
      { title: i18n.translate('Type') },
      { title: i18n.translate('Modified') },
      { title: i18n.translate('Tags') },
    ],
    [selectedResources, i18n]
  );

  const onUpdatePromotionProgress = useCallback(() => {
    promotionsInProgress?.length
      ? getPromotionOperationProgress(
          promotionsInProgress.map((item) => item.id || '')
        )
      : null;
  }, [promotionsInProgress]);

  const onBatchOperation = useCallback(
    (operation: PromotionOperationTypeDtoEnum, promotionsIds: string[]) => {
      actionOnBatchOfPromotion({
        operationType: operation,
        promotionsIds: promotionsIds,
      }).then(() => {
        getPromotionListData(promotionRequestSetup).then(() =>
          clearSelection()
        );
      });
    },
    [promotionListState, promotionRequestSetup, getPromotionListData]
  );

  const rowMarkup = useMemo(() => {
    return (
      promotionIds &&
      promotionIds?.map((item, index) => {
        return (
          <PromotionListRow
            key={item.id}
            i18n={i18n}
            index={index}
            activePopover={activePopover}
            setActivePopover={setActivePopover}
            selectedResources={selectedResources}
            onUpdateProgress={() => onUpdatePromotionProgress()}
            refetchPromotionList={() =>
              getPromotionListData(promotionRequestSetup)
            }
            promotionData={
              promotionListState?.items?.find(
                (promotion) => promotion.id === item.id
              ) || {}
            }
            onBatchOperation={onBatchOperation}
          />
        );
      })
    );
  }, [
    promotionIds,
    selectedResources,
    activePopover,
    promotionRequestSetup,
    promotionListState,
    getPromotionListData,
    onBatchOperation,
  ]);

  const tabs = useMemo(() => {
    return tabsStrings.map((item, index) => ({
      content: i18n.translate(`${item}`),
      index,
      onAction: () => {
        setPromotionListState({});
        setPromotionRequestSetup((prev) => ({
          ...prev,
          statuses:
            item === 'ALL' ? null : ([item] as PromotionStatusDtoEnum[]),
        }));
      },
      id: `${item}-${index}`,
      isLocked: index === 0,
      actions: [],
    }));
  }, [tabsStrings, setPromotionRequestSetup]);

  const bulkActions = useMemo(
    () =>
      Object.values(PromotionOperationTypeDtoEnum).map((value) => ({
        id: value,
        content: i18n.translate(`${value}`),
        destructive: value === PromotionOperationTypeDtoEnum.DELETE,
        disabled: promotionListIsLoading || actionOnBatchOfPromotionIsLoading,
        onAction: () => onBatchOperation(value, selectedResources),
      })),
    [
      promotionListIsLoading,
      actionOnBatchOfPromotionIsLoading,
      onBatchOperation,
      selectedResources,
    ]
  );

  const possibleOperations = useMemo(() => {
    const selectedItems = promotionListState?.items?.filter((promotion) =>
      selectedResources.includes(promotion.id as string)
    );
    if (!selectedItems?.length) return [];
    return selectedItems?.reduce((acc, obj) => {
      return acc?.filter((operation) =>
        obj.possibleOperations?.includes(operation)
      );
    }, selectedItems[0].possibleOperations);
  }, [selectedResources, promotionListState?.items]);

  const getPossibleBulkActions = useCallback(
    (promoted?: boolean) => {
      const possibleBulkActions = bulkActions.filter((action) =>
        possibleOperations?.includes(action.id)
      );
      const secondaryActions = [
        PromotionOperationTypeDtoEnum.DUPLICATE,
        PromotionOperationTypeDtoEnum.ARCHIVE,
        PromotionOperationTypeDtoEnum.DELETE,
      ];
      const promotedBulkActions = possibleBulkActions.filter(
        (action) => !secondaryActions.includes(action.id)
      );
      const secondaryBulkActions = possibleBulkActions.filter((action) =>
        secondaryActions.includes(action.id)
      );
      if (!promotedBulkActions.length)
        return !promoted ? [] : possibleBulkActions;
      return promoted ? promotedBulkActions : secondaryBulkActions;
    },
    [bulkActions, possibleOperations]
  );

  const hasNextPage = useMemo(
    () =>
      promotionListState?.totalItems && promotionRequestSetup.page
        ? promotionListState.totalItems / promotionRequestSetup.page > 10
        : false,
    [promotionListState?.totalItems, promotionRequestSetup.page]
  );

  const handleSortPopover = useCallback(
    (value: string[]) => {
      setSortSelected(value);
      const isASC = value[0].includes('asc');
      const sortBy = isASC ? value[0].slice(0, -4) : value[0].slice(0, -5);
      setPromotionRequestSetup((prev) => ({
        ...prev,
        sortBy: sortBy as PromotionListSortFieldDtoEnum,
        sortDirection: isASC
          ? SortDirectionDtoEnum.ASC
          : SortDirectionDtoEnum.DESC,
      }));
    },
    [setPromotionRequestSetup]
  );

  const handleSortColumn = useCallback(
    (index: number) => {
      const changedDirection =
        columnSortDirection === 'ascending' ? 'descending' : 'ascending';
      let sortDirection = columnSortDirection;
      if (currentSortIndex === index) {
        setColumnSortDirection(changedDirection);
        sortDirection = changedDirection;
      }

      setCurrentSortIndex(index);
      const option = sortColumnOptions(index, sortDirection);
      setSortSelected([option as string]);

      const isASC = option?.includes('asc');
      const sortBy = isASC ? option?.slice(0, -4) : option?.slice(0, -5);
      setPromotionRequestSetup((prev) => ({
        ...prev,
        sortBy: sortBy as PromotionListSortFieldDtoEnum,
        sortDirection: isASC
          ? SortDirectionDtoEnum.ASC
          : SortDirectionDtoEnum.DESC,
      }));
    },
    [setPromotionRequestSetup, columnSortDirection, currentSortIndex]
  );

  const handleSearchQueryChange = useCallback(
    (value: string) => {
      if (value !== promotionRequestSetup.search) {
        setQuerySearchValue(value);
        debounceSearchQuery(value);
      }
    },
    [promotionRequestSetup.search]
  );

  const handleDebounceQueryChange = useCallback(
    (value: string) => {
      setPromotionRequestSetup((prev) => ({
        ...prev,
        search: value,
        page: 1,
      }));
    },
    [setPromotionRequestSetup]
  );

  const debounceSearchQuery = useCallback(
    debounce(handleDebounceQueryChange, 500),
    []
  );

  const onNextPage = useCallback(() => {
    setPromotionListState(undefined);
    setPromotionRequestSetup((prev) => ({
      ...prev,
      page: (prev.page as number) + 1,
    }));
  }, [promotionRequestSetup]);

  const onPrevPage = useCallback(() => {
    setPromotionListState(undefined);
    setPromotionRequestSetup((prev) => ({
      ...prev,
      page: prev.page === 1 ? 1 : (prev.page as number) - 1,
    }));
  }, [promotionRequestSetup]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (promotionsInProgress?.length) {
      if (
        promotionOperationProgressData &&
        promotionOperationProgressData.every((item) => item.progress === 100)
      ) {
        clearInterval(interval);
      } else {
        interval = setInterval(() => onUpdatePromotionProgress(), 60000);
      }
    }
    return () => {
      clearInterval(interval);
    };
  }, [promotionsInProgress]);

  useEffect(() => {
    getPromotionListData(promotionRequestSetup);
  }, [promotionRequestSetup]);

  useEffect(() => {
    if (promotionListData) {
      setPromotionListState(promotionListData);
    }
  }, [promotionListData]);

  useEffect(() => {
    if (promotionOperationProgressData) {
      const updatedList = promotionListState?.items?.map((promotion) => {
        const newProgressField = promotionOperationProgressData.find(
          (progress) => progress.id === promotion.id
        );
        if (newProgressField) {
          return {
            ...promotion,
            operationProgress: newProgressField,
            status: newProgressField.status,
          };
        }
        return promotion;
      });
      setPromotionListState((prev) => ({ ...prev, items: updatedList }));
    }
  }, [promotionOperationProgressData]);

  return (
    <div className='PromotionList'>
      {!isEmpty(promotionListState) &&
      !promotionListState?.totalItems &&
      isEqual(promotionRequestSetup, initialRequestSetup) ? (
        <EmptyState
          key={'promotionsList'}
          heading={i18n.translate(`NoPromotions`)}
          secondaryAction={{
            content: i18n.translate(`CreatePromotion`),
            onAction: toggleCreatePromotionModal,
          }}
          image={EmptyPromotionsIcon}
        >
          <p>{i18n.translate(`CreateAndManage`)}</p>
        </EmptyState>
      ) : (
        <Card>
          <Bleed marginBlock='400' marginInline='400'>
            <IndexFilters
              sortOptions={sortPopoverOptions(i18n)}
              sortSelected={sortSelected}
              queryValue={querySearchValue}
              queryPlaceholder={i18n.translate(`SearchingInAll`)}
              onQueryChange={handleSearchQueryChange}
              onQueryClear={() => handleSearchQueryChange('')}
              onSort={(value) => handleSortPopover(value)}
              cancelAction={{
                onAction: () => handleSearchQueryChange(''),
              }}
              loading={
                promotionListIsLoading || actionOnBatchOfPromotionIsLoading
              }
              tabs={tabs}
              selected={
                promotionRequestSetup.statuses
                  ? tabsStrings.findIndex(
                      (value) => value === promotionRequestSetup.statuses?.[0]
                    )
                  : 0
              }
              canCreateNewView={false}
              filters={[]}
              appliedFilters={[]}
              onClearAll={() => null}
              mode={mode}
              setMode={setMode}
              hideFilters
              filteringAccessibilityTooltip={i18n.translate(`SearchF`)}
            />
            {promotionIds && promotionListState ? (
              <IndexTable
                itemCount={
                  selectablePromotions?.length || promotionIds?.length || 0
                }
                selectedItemsCount={
                  allResourcesSelected ? 'All' : selectedResources.length
                }
                onSelectionChange={handleSelectionChange}
                headings={headings}
                pagination={{
                  onPrevious: onPrevPage,
                  onNext: onNextPage,
                  hasNext: hasNextPage,
                  hasPrevious: (promotionRequestSetup.page as number) > 1,
                }}
                defaultSortDirection={columnSortDirection}
                onSort={(index) => handleSortColumn(index)}
                sortable={[false, true, true, true, true, true, true, true]}
                promotedBulkActions={getPossibleBulkActions(true)}
                bulkActions={getPossibleBulkActions(false)}
              >
                {rowMarkup}
              </IndexTable>
            ) : (
              <PromotionListSkeleton />
            )}
          </Bleed>
        </Card>
      )}
    </div>
  );
};
