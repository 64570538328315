import React, { useCallback, useMemo } from 'react';
import {
  NinjaCartCartItemLevelPresetDto,
  NinjaCartCartItemPresetDto,
  NinjaCartCartItemPresetDtoRead,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useI18n } from '@shopify/react-i18n';
import { SelectedOptionPath } from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightSideBarWrapper';
import { getLastSelectedOption } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import ContentCartItemGeneral from './components/ContentCartItemGeneral/ContentCartItemGeneral';
import ContentCartItemText from './components/ContentCartItemText/ContentCartItemText';
import ContentCartItemProductBadge from './components/ContentCartItemProductBadge/ContentCartItemProductBadge';

type NinjaCartContentCartItemProps = {
  cartItem: NinjaCartCartItemPresetDtoRead;
  defaultCartItem: NinjaCartCartItemPresetDtoRead;
  shopDefaultCartItem: NinjaCartCartItemPresetDto;
  selectedOptionPath: SelectedOptionPath;
  setCartItem: (data: NinjaCartCartItemPresetDtoRead) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const NinjaCartContentCartItem: React.FC<NinjaCartContentCartItemProps> = ({
  cartItem,
  defaultCartItem,
  shopDefaultCartItem,
  selectedOptionPath,
  setCartItem,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();

  const handleUpdateOptions = useCallback(
    (
      field: keyof NinjaCartCartItemLevelPresetDto,
      data: NinjaCartCartItemLevelPresetDto[keyof NinjaCartCartItemLevelPresetDto]
    ) => {
      setCartItem({
        ...cartItem,
        options: {
          ...cartItem.options,
          [field]: data,
        },
      });
    },
    [cartItem, setCartItem]
  );

  const cartItemOptions = useMemo(() => {
    const selectedOption = getLastSelectedOption(selectedOptionPath);
    switch (true) {
      case selectedOption?.name === WidgetListOptionsDtoEnum.CART_ITEM:
        return (
          <ContentCartItemGeneral
            general={cartItem.general || {}}
            defaultGeneral={defaultCartItem.general || {}}
            shopDefaultGeneral={shopDefaultCartItem.general || {}}
            setGeneral={(data) => setCartItem({ ...cartItem, general: data })}
            handleLeftMenuSelection={handleLeftMenuSelection}
            onShowHide={
              cartItem.canBeHidden
                ? () =>
                    setCartItem({ ...cartItem, isHidden: !cartItem.isHidden })
                : undefined
            }
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.CART_TEXT:
        return (
          <ContentCartItemText
            cartText={cartItem.options?.cartText || {}}
            defaultCartText={defaultCartItem.options?.cartText || {}}
            shopDefaultCartText={shopDefaultCartItem.options?.cartText || {}}
            setCartText={(data) => handleUpdateOptions('cartText', data)}
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.PRODUCT_BADGE:
        return (
          <ContentCartItemProductBadge
            productBadge={cartItem.options?.productBadge || {}}
            defaultProductBadge={defaultCartItem.options?.productBadge || {}}
            shopDefaultProductBadge={
              shopDefaultCartItem.options?.productBadge || {}
            }
            setProductBadge={(data) =>
              handleUpdateOptions('productBadge', data)
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
    }
  }, [
    selectedOptionPath,
    defaultCartItem,
    shopDefaultCartItem,
    cartItem,
    handleLeftMenuSelection,
  ]);

  return <>{cartItemOptions}</>;
};

export default NinjaCartContentCartItem;
