import {
  NotificationExpandedStateGeneralPresetDto,
  NotificationExpandedStateImagePresetDtoRead,
  NotificationExpandedStateLevelPresetDto,
  NotificationGeneralOfferDto,
  NotificationSystemDefaultsDto,
  OfferWidgetSetupDtoNotificationOfferDtoRead,
  PresetsSetupDto,
  PromotionSetupDtoRead,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { SelectedOptionPath } from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightSideBarWrapper';
import {
  WidgetListOptionsDtoEnum,
  WidgetListTypeDtoEnum,
} from 'features/settings/components/EditPresetSkeleton/enums/enums';
import React, { useCallback, useMemo } from 'react';
import NotificationGeneral from '../../../../../EditPresetRightBar/components/PresetNotification/components/NotificationGeneral/NotificationGeneral';
import { getLastSelectedOption } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import {
  NotificationStatesPromotionEntryTypeDtoEnum,
  WidgetTypeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import NotificationOffer from '../NotificationOffer/NotificationOffer';
import NotificationHeader from '../../../../../EditPresetRightBar/components/PresetNotification/components/NotificationHeader/NotificationHeader';
import NotificationFooter from '../../../../../EditPresetRightBar/components/PresetNotification/components/NotificationFooter/NotificationFooter';
import NotificationImage from '../../../../../EditPresetRightBar/components/PresetNotification/components/NotificationImage/NotificationImage';
type PromotionNotificationExpandedProps = {
  editPromotionState: PromotionSetupDtoRead;
  defaultPromotionData: PromotionSetupDtoRead;
  shopDefaultData: NotificationSystemDefaultsDto;
  selectedOptionPath: SelectedOptionPath;
  updateEditPromotionState: (
    field: keyof PromotionSetupDtoRead,
    data: PromotionSetupDtoRead[keyof PromotionSetupDtoRead],
    presetSetup?: PresetsSetupDto
  ) => void;
  filterSupportedWidgets: (value: WidgetTypeDtoEnum) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const PromotionNotificationExpanded: React.FC<
  PromotionNotificationExpandedProps
> = ({
  editPromotionState,
  defaultPromotionData,
  shopDefaultData,
  selectedOptionPath,
  updateEditPromotionState,
  filterSupportedWidgets,
  handleLeftMenuSelection,
}) => {
  const { notification, presetSetup } = editPromotionState;
  const notificationPreset = presetSetup?.notification?.options?.expandedState;

  const offersData = editPromotionState.offers?.notification;
  const defaultOffersData = defaultPromotionData.offers?.notification;
  const defaultNotification =
    defaultPromotionData.notification?.options?.expandedState;
  const defaultPresetSetup =
    defaultPromotionData.presetSetup?.notification?.options?.expandedState;

  const updateNotificationGeneral = useCallback(
    (data: NotificationExpandedStateGeneralPresetDto) =>
      updateEditPromotionState(
        'notification',
        {
          ...notification,
          options: {
            ...notification?.options,
            expandedState: {
              ...notification?.options?.expandedState,
              general: {
                ...notification?.options?.expandedState?.general,
                stylePriority: data.stylePriority,
                customCss: data.customCss,
              },
            },
          },
        },
        {
          ...presetSetup,
          notification: {
            ...presetSetup?.notification,
            options: {
              ...presetSetup?.notification?.options,
              expandedState: {
                ...presetSetup?.notification?.options?.expandedState,
                general: {
                  ...presetSetup?.notification?.options?.expandedState?.general,
                  background: data.background,
                  cornerRadius: data.cornerRadius,
                  shadow: data.shadow,
                  size: data.size,
                },
              },
            },
          },
        }
      ),
    [notification, updateEditPromotionState, presetSetup]
  );

  const updateOffer = useCallback(
    (
      data: OfferWidgetSetupDtoNotificationOfferDtoRead,
      offerId: string,
      presetOptions?: NotificationExpandedStateLevelPresetDto
    ) =>
      updateEditPromotionState(
        'offers',
        {
          ...editPromotionState.offers,
          notification: editPromotionState.offers?.notification?.map(
            (offerData) => {
              if (offerData?.offerId === offerId) {
                return data;
              }
              return offerData;
            }
          ),
        },
        {
          ...presetSetup,
          notification: {
            ...presetSetup?.notification,
            options: {
              ...presetSetup?.notification?.options,
              expandedState: {
                ...presetSetup?.notification?.options?.expandedState,
                options: presetOptions,
              },
            },
          },
        }
      ),

    [updateEditPromotionState, editPromotionState, presetSetup]
  );

  const updateNotificationPreset = useCallback(
    (
      field: keyof NotificationExpandedStateLevelPresetDto,
      data: NotificationExpandedStateLevelPresetDto[keyof NotificationExpandedStateLevelPresetDto]
    ) => {
      updateEditPromotionState('presetSetup', {
        ...presetSetup,
        notification: {
          ...presetSetup?.notification,
          options: {
            ...presetSetup?.notification?.options,
            expandedState: {
              ...presetSetup?.notification?.options?.expandedState,
              options: {
                ...presetSetup?.notification?.options?.expandedState?.options,
                [field]: data,
              },
            },
          },
        },
      });
    },
    [presetSetup, updateEditPromotionState]
  );

  const handleShowHideOptions = useCallback(
    (option: keyof NotificationExpandedStateLevelPresetDto) =>
      updateEditPromotionState('notification', {
        ...notification,
        options: {
          ...notification?.options,
          expandedState: {
            ...notification?.options?.expandedState,
            options: {
              ...notification?.options?.expandedState?.options,
              [option]: {
                ...notification?.options?.expandedState?.options?.[option],
                isHidden: notification?.options?.expandedState?.options?.[
                  option
                ]?.canBeHidden
                  ? !notification?.options?.expandedState?.options?.[option]
                      ?.isHidden
                  : notification?.options?.expandedState?.options?.[option]
                      ?.isHidden,
              },
            },
          },
        },
      }),
    [notification]
  );

  const updateImageComponent = useCallback(
    (data: NotificationExpandedStateImagePresetDtoRead) => {
      updateEditPromotionState(
        'notification',
        {
          ...notification,
          options: {
            ...notification?.options,
            expandedState: {
              ...notification?.options?.expandedState,
              options: {
                ...notification?.options?.expandedState?.options,
                image: {
                  ...notification?.options?.expandedState?.options?.image,
                  image: { imageId: data.image?.imageId },
                },
              },
            },
          },
        },
        {
          ...presetSetup,
          notification: {
            ...presetSetup?.notification,
            options: {
              ...presetSetup?.notification?.options,
              expandedState: {
                ...presetSetup?.notification?.options?.expandedState,
                options: {
                  ...notificationPreset?.options,
                  image: {
                    ...data,
                    image: {
                      ...data.image,
                      imageId:
                        notificationPreset?.options?.image?.image?.imageId,
                    },
                  },
                },
              },
            },
          },
        }
      );
    },
    [notification, presetSetup]
  );

  const notificationOptions = useMemo(() => {
    const offerData = offersData?.find(
      (offer) =>
        offer.offerId ===
        selectedOptionPath?.selectedOption?.selectedOption?.offerId
    ) as OfferWidgetSetupDtoNotificationOfferDtoRead;

    const defaultOfferData = defaultOffersData?.find(
      (offer) =>
        offer.offerId ===
        selectedOptionPath?.selectedOption?.selectedOption?.offerId
    ) as OfferWidgetSetupDtoNotificationOfferDtoRead;

    const selectedOption = getLastSelectedOption(selectedOptionPath);

    const updateOfferGeneral = (data: NotificationGeneralOfferDto) => {
      const newOfferData: OfferWidgetSetupDtoNotificationOfferDtoRead = {
        ...offerData,
        setup: {
          ...offerData?.setup,
          general: {
            text: {
              message: data.text?.message,
            },
            icon: {
              ...data.icon,
              value: { iconId: data.icon?.value?.iconId },
            },
          },
        },
      };

      const newPresetData: NotificationExpandedStateLevelPresetDto = {
        ...notificationPreset?.options,
        offer: {
          ...notificationPreset?.options?.offer,
          icon: {
            ...notificationPreset?.options?.offer?.icon,
            color: data.icon?.value?.color,
          },
          textStyle: data.text?.style,
        },
      };
      updateOffer(newOfferData, offerData?.offerId as string, newPresetData);
    };

    switch (true) {
      case selectedOption?.name === WidgetListTypeDtoEnum.NOTIFICATION:
        return (
          <NotificationGeneral
            general={notification?.options?.expandedState?.general || {}}
            presetSetup={notificationPreset?.general || {}}
            defaultGeneral={{
              ...defaultPresetSetup?.general,
              customCss: defaultNotification?.general?.customCss,
              stylePriority: defaultNotification?.general?.stylePriority,
            }}
            notificationState={
              NotificationStatesPromotionEntryTypeDtoEnum.EXPANDED_STATE
            }
            shopDefaultGeneral={shopDefaultData.expandedState?.general || {}}
            setGeneral={updateNotificationGeneral}
            onShowHide={
              notification?.canBeHidden
                ? () =>
                    updateEditPromotionState('notification', {
                      ...notification,
                      isHidden: !notification.isHidden,
                    })
                : undefined
            }
            onRemove={() =>
              filterSupportedWidgets(WidgetTypeDtoEnum.NOTIFICATION)
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case !!selectedOption?.offerId:
        return (
          <NotificationOffer
            key={offerData?.offerId}
            offer={offerData?.setup?.general || {}}
            offerName={selectedOption?.name || ''}
            presetSetup={notificationPreset?.options?.offer || {}}
            offerId={offerData?.offerId}
            defaultOffer={defaultOfferData?.setup?.general || {}}
            defaultPresetSetup={defaultPresetSetup?.options?.offer || {}}
            shopDefaultOffer={
              shopDefaultData.expandedState?.options?.offer || {}
            }
            defaultLanguage={editPromotionState.offers?.defaultLanguage || ''}
            handleLeftMenuSelection={handleLeftMenuSelection}
            onShowHide={
              offerData?.canBeHidden
                ? () =>
                    updateOffer(
                      { ...offerData, isHidden: !offerData?.isHidden },
                      offerData?.offerId as string
                    )
                : undefined
            }
            setOffer={updateOfferGeneral}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.HEADER:
        return (
          <NotificationHeader
            header={notificationPreset?.options?.header || {}}
            defaultHeader={defaultPresetSetup?.options?.header || {}}
            defaultLanguage={editPromotionState.offers?.defaultLanguage || ''}
            shopDefaultHeader={
              shopDefaultData.expandedState?.options?.header || {}
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
            offerId={offerData?.offerId}
            notificationState={
              NotificationStatesPromotionEntryTypeDtoEnum.EXPANDED_STATE
            }
            onShowHide={() => handleShowHideOptions('header')}
            setHeader={(data) => updateNotificationPreset('header', data)}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.FOOTER:
        return (
          <NotificationFooter
            footer={notificationPreset?.options?.footer || {}}
            defaultFooter={defaultPresetSetup?.options?.footer || {}}
            defaultLanguage={editPromotionState.offers?.defaultLanguage || ''}
            shopDefaultFooter={
              shopDefaultData.expandedState?.options?.footer || {}
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
            offerId={offerData?.offerId}
            onShowHide={() => handleShowHideOptions('footer')}
            setFooter={(data) => updateNotificationPreset('footer', data)}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.IMAGE:
        return (
          <NotificationImage
            image={notification?.options?.expandedState?.options?.image || {}}
            defaultImage={{
              ...defaultNotification?.options?.image,
              border: defaultPresetSetup?.options?.image?.border,
              cornerRadius: defaultPresetSetup?.options?.image?.cornerRadius,
              image: {
                ...defaultPresetSetup?.options?.image?.image,
                imageId: defaultNotification?.options?.image?.image?.imageId,
              },
            }}
            presetSetup={notificationPreset?.options?.image || {}}
            shopDefaultImage={
              shopDefaultData.expandedState?.options?.image || {}
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
            onShowHide={() => handleShowHideOptions('image')}
            setImage={updateImageComponent}
          />
        );
    }
  }, [
    notification,
    defaultNotification,
    notificationPreset,
    offersData,
    defaultPresetSetup,
    defaultOffersData,
    editPromotionState,
    selectedOptionPath,
    updateOffer,
    handleLeftMenuSelection,
  ]);
  return <>{notificationOptions}</>;
};

export default PromotionNotificationExpanded;
