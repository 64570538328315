import React, { useMemo } from 'react';
import {
  Badge,
  Banner,
  BlockStack,
  Card,
  ChoiceList,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { DeviceDisplayDto } from 'core/api/adminSettings/adminSettingsApi';
import { DeviceDisplayDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { Element } from 'react-scroll';

type DeviceDisplaySettingProps = {
  deviceDisplay: DeviceDisplayDto;
  setDeviceDisplay: (value: DeviceDisplayDtoEnum) => void;
};
export const DeviceDisplaySetting: React.FC<DeviceDisplaySettingProps> = ({
  deviceDisplay,
  setDeviceDisplay,
}) => {
  const [i18n] = useI18n();
  const deviceDisplayOptions = useMemo(
    () =>
      Object.values(DeviceDisplayDtoEnum).map((value) => ({
        label: (
          <InlineStack gap='200'>
            {i18n.translate(value)}{' '}
            {value === DeviceDisplayDtoEnum.ALL && (
              <Badge>{i18n.translate('Recommended')}</Badge>
            )}
          </InlineStack>
        ),
        value: value,
      })),
    []
  );

  return (
    <Element className='DeviceDisplaySetting' name='DeviceDisplaySetting'>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('DeviceDisplay')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('SelectOnWhich')}
            </Text>
          </BlockStack>
          <ChoiceList
            title=''
            choices={deviceDisplayOptions}
            selected={[deviceDisplay || DeviceDisplayDtoEnum.ALL]}
            onChange={(value) =>
              setDeviceDisplay(value[0] as DeviceDisplayDtoEnum)
            }
          />
          <Banner tone='info'>{i18n.translate('BannerText')}</Banner>
        </BlockStack>
      </Card>
    </Element>
  );
};
