import {
  OfferWidgetSetupDtoPricingOfferDtoRead,
  CollectionPagePricePresetDtoRead,
  PromotionWidgetSetupMultiLevelDtoPricingPromotionDtoPricingLevelPromotionDtoPricingLevelPromotionEntryTypeDtoRead,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { LineWeightTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';

export type PromotionWidgetSetupDtoPricePromotionDtoReadWithOffers =
  PromotionWidgetSetupMultiLevelDtoPricingPromotionDtoPricingLevelPromotionDtoPricingLevelPromotionEntryTypeDtoRead & {
    offers?: OfferWidgetSetupDtoPricingOfferDtoRead[] | null;
  };

export const priceMergeFunc = (
  widget: PromotionWidgetSetupDtoPricePromotionDtoReadWithOffers,
  setup?: CollectionPagePricePresetDtoRead
) => {
  const {
    isShopLevelWidget,
    canBeDragged,
    canBeRemoved,
    offers,
    options,
    general,
    ...newWidget
  } = widget;

  const { options: optionsSetup } = setup?.options ? setup : { options: null };

  return {
    ...newWidget,
    availableEntries: undefined,
    ...(optionsSetup
      ? {
          options: {
            ...options,
            discountedPrice: {
              ...options?.discountedPrice,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              key: undefined,
              general: {
                ...options?.discountedPrice?.general,
                spacing: {
                  ...optionsSetup.discountedPrice?.general?.spacing,
                },
              },
            },
          },
          offers: offers?.map((offer) => {
            const {
              canBeDragged,
              canBeRemoved,
              canBeHidden,
              isHidden,
              setup,
              ...newOffer
            } = offer;

            return {
              ...(canBeHidden && { isHidden }),
              ...newOffer,
              setup: {
                ...setup,
                availableEntries: undefined,
                options: {
                  ...setup?.options,
                  footerText: {
                    ...optionsSetup.discountedPrice?.options?.footerText,
                    style: {
                      ...optionsSetup.discountedPrice?.options?.footerText
                        ?.general?.text?.style,
                      style: {
                        ...optionsSetup.discountedPrice?.options?.footerText
                          ?.general?.text?.style?.style,
                        fontSize: {
                          ...optionsSetup.discountedPrice?.options?.footerText
                            ?.general?.text?.style?.style?.fontSize,
                          mobile: optionsSetup.discountedPrice?.options
                            ?.footerText?.general?.text?.style?.style
                            ?.differentOnMobile
                            ? optionsSetup.discountedPrice?.options?.footerText
                                ?.general?.text?.style?.style?.fontSize?.mobile
                            : undefined,
                        },
                        lineHeight: {
                          ...optionsSetup.discountedPrice?.options?.footerText
                            ?.general?.text?.style?.style?.lineHeight,
                          mobile: optionsSetup.discountedPrice?.options
                            ?.footerText?.general?.text?.style?.style
                            ?.differentOnMobile
                            ? optionsSetup.discountedPrice?.options?.footerText
                                ?.general?.text?.style?.style?.lineHeight
                                ?.mobile
                            : undefined,
                        },
                        fontWeight: {
                          ...optionsSetup.discountedPrice?.options?.footerText
                            ?.general?.text?.style?.style?.fontWeight,
                          mobile: optionsSetup.discountedPrice?.options
                            ?.footerText?.general?.text?.style?.style
                            ?.differentOnMobile
                            ? optionsSetup.discountedPrice?.options?.footerText
                                ?.general?.text?.style?.style?.fontWeight
                                ?.mobile
                            : undefined,
                        },
                      },
                    },
                    message: {
                      ...optionsSetup.discountedPrice?.options?.footerText
                        ?.general?.text?.message,
                      settings: undefined,
                    },
                    canBeDragged: undefined,
                    canBeRemoved: undefined,
                    canBeHidden: undefined,
                  },
                  headerText: {
                    ...optionsSetup.discountedPrice?.options?.headerText,
                    style: {
                      ...optionsSetup.discountedPrice?.options?.headerText
                        ?.general?.text?.style,
                      style: {
                        ...optionsSetup.discountedPrice?.options?.headerText
                          ?.general?.text?.style?.style,
                        fontSize: {
                          ...optionsSetup.discountedPrice?.options?.headerText
                            ?.general?.text?.style?.style?.fontSize,
                          mobile: optionsSetup.discountedPrice?.options
                            ?.headerText?.general?.text?.style?.style
                            ?.differentOnMobile
                            ? optionsSetup.discountedPrice?.options?.headerText
                                ?.general?.text?.style?.style?.fontSize?.mobile
                            : undefined,
                        },
                        lineHeight: {
                          ...optionsSetup.discountedPrice?.options?.headerText
                            ?.general?.text?.style?.style?.lineHeight,
                          mobile: optionsSetup.discountedPrice?.options
                            ?.headerText?.general?.text?.style?.style
                            ?.differentOnMobile
                            ? optionsSetup.discountedPrice?.options?.headerText
                                ?.general?.text?.style?.style?.lineHeight
                                ?.mobile
                            : undefined,
                        },
                        fontWeight: {
                          ...optionsSetup.discountedPrice?.options?.headerText
                            ?.general?.text?.style?.style?.fontWeight,
                          mobile: optionsSetup.discountedPrice?.options
                            ?.headerText?.general?.text?.style?.style
                            ?.differentOnMobile
                            ? optionsSetup.discountedPrice?.options?.headerText
                                ?.general?.text?.style?.style?.fontWeight
                                ?.mobile
                            : undefined,
                        },
                      },
                    },
                    message: {
                      ...optionsSetup.discountedPrice?.options?.headerText
                        ?.general?.text?.message,
                      settings: undefined,
                    },
                    canBeDragged: undefined,
                    canBeRemoved: undefined,
                    canBeHidden: undefined,
                  },
                },
              },
            };
          }),
        }
      : {
          options: {
            ...options,
            discountedPrice: {
              ...options?.discountedPrice,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              key: undefined,
              availableEntries: undefined,
              options: {
                ...options?.discountedPrice?.options,
                footerText: {
                  ...options?.discountedPrice?.options?.footerText,
                  general: {
                    ...options?.discountedPrice?.options?.footerText?.general,
                    text: {
                      ...options?.discountedPrice?.options?.footerText?.general
                        ?.text,
                      style: {
                        ...options?.discountedPrice?.options?.footerText
                          ?.general?.text?.style,
                        style: {
                          ...options?.discountedPrice?.options?.footerText
                            ?.general?.text?.style?.style,
                          fontSize: {
                            ...options?.discountedPrice?.options?.footerText
                              ?.general?.text?.style?.style?.fontSize,
                            mobile: options?.discountedPrice?.options
                              ?.footerText?.general?.text?.style?.style
                              ?.differentOnMobile
                              ? options?.discountedPrice?.options?.footerText
                                  ?.general?.text?.style?.style?.fontSize
                                  ?.mobile
                              : undefined,
                          },
                          lineHeight: {
                            ...options?.discountedPrice?.options?.footerText
                              ?.general?.text?.style?.style?.lineHeight,
                            mobile: options?.discountedPrice?.options
                              ?.footerText?.general?.text?.style?.style
                              ?.differentOnMobile
                              ? options?.discountedPrice?.options?.footerText
                                  ?.general?.text?.style?.style?.lineHeight
                                  ?.mobile
                              : undefined,
                          },
                          fontWeight: {
                            ...options?.discountedPrice?.options?.footerText
                              ?.general?.text?.style?.style?.fontWeight,
                            mobile: options?.discountedPrice?.options
                              ?.footerText?.general?.text?.style?.style
                              ?.differentOnMobile
                              ? options?.discountedPrice?.options?.footerText
                                  ?.general?.text?.style?.style?.fontWeight
                                  ?.mobile
                              : undefined,
                          },
                        },
                      },
                      message: {
                        ...options?.discountedPrice?.options?.footerText
                          ?.general?.text?.message,
                        settings: undefined,
                      },
                    },
                  },
                  availableEntries: undefined,
                  canBeDragged: undefined,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                },
                headerText: {
                  ...options?.discountedPrice?.options?.headerText,
                  general: {
                    ...options?.discountedPrice?.options?.headerText?.general,
                    text: {
                      ...options?.discountedPrice?.options?.headerText?.general
                        ?.text,
                      style: {
                        ...options?.discountedPrice?.options?.headerText
                          ?.general?.text?.style,
                        style: {
                          ...options?.discountedPrice?.options?.headerText
                            ?.general?.text?.style?.style,
                          fontSize: {
                            ...options?.discountedPrice?.options?.headerText
                              ?.general?.text?.style?.style?.fontSize,
                            mobile: options?.discountedPrice?.options
                              ?.headerText?.general?.text?.style?.style
                              ?.differentOnMobile
                              ? options?.discountedPrice?.options?.headerText
                                  ?.general?.text?.style?.style?.fontSize
                                  ?.mobile
                              : undefined,
                          },
                          lineHeight: {
                            ...options?.discountedPrice?.options?.headerText
                              ?.general?.text?.style?.style?.lineHeight,
                            mobile: options?.discountedPrice?.options
                              ?.headerText?.general?.text?.style?.style
                              ?.differentOnMobile
                              ? options?.discountedPrice?.options?.headerText
                                  ?.general?.text?.style?.style?.lineHeight
                                  ?.mobile
                              : undefined,
                          },
                          fontWeight: {
                            ...options?.discountedPrice?.options?.headerText
                              ?.general?.text?.style?.style?.fontWeight,
                            mobile: options?.discountedPrice?.options
                              ?.headerText?.general?.text?.style?.style
                              ?.differentOnMobile
                              ? options?.discountedPrice?.options?.headerText
                                  ?.general?.text?.style?.style?.fontWeight
                                  ?.mobile
                              : undefined,
                          },
                        },
                      },
                      message: {
                        ...options?.discountedPrice?.options?.headerText
                          ?.general?.text?.message,
                        settings: undefined,
                      },
                    },
                  },
                  availableEntries: undefined,
                  canBeDragged: undefined,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                },
                bodyRow1: {
                  ...options?.discountedPrice?.options?.bodyRow1,
                  availableEntries: undefined,
                  canBeDragged: undefined,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                  options: {
                    ...options?.discountedPrice?.options?.bodyRow1?.options,
                    badge: {
                      ...options?.discountedPrice?.options?.bodyRow1?.options
                        ?.badge,
                      background: {
                        ...options?.discountedPrice?.options?.bodyRow1?.options
                          ?.badge?.background,
                        border: {
                          ...options?.discountedPrice?.options?.bodyRow1
                            ?.options?.badge?.background?.border,
                          value: {
                            ...options?.discountedPrice?.options?.bodyRow1
                              ?.options?.badge?.background?.border?.value,
                            lineWeight: {
                              ...options?.discountedPrice?.options?.bodyRow1
                                ?.options?.badge?.background?.border?.value
                                ?.lineWeight,
                              customValue:
                                options?.discountedPrice?.options?.bodyRow1
                                  ?.options?.badge?.background?.border?.value
                                  ?.lineWeight?.type ===
                                LineWeightTypeDtoEnum.CUSTOM
                                  ? options?.discountedPrice?.options?.bodyRow1
                                      ?.options?.badge?.background?.border
                                      ?.value?.lineWeight?.customValue
                                  : undefined,
                            },
                          },
                        },
                      },
                      cornerRadius: {
                        ...options?.discountedPrice?.options?.bodyRow1?.options
                          ?.badge?.cornerRadius,
                        customValue:
                          options?.discountedPrice?.options?.bodyRow1?.options
                            ?.badge?.cornerRadius?.type ===
                          LineWeightTypeDtoEnum.CUSTOM
                            ? options?.discountedPrice?.options?.bodyRow1
                                ?.options?.badge?.cornerRadius?.customValue
                            : undefined,
                      },
                      text: {
                        ...options?.discountedPrice?.options?.bodyRow1?.options
                          ?.badge?.text,
                        style: {
                          ...options?.discountedPrice?.options?.bodyRow1
                            ?.options?.badge?.text?.style,
                          style: {
                            ...options?.discountedPrice?.options?.bodyRow1
                              ?.options?.badge?.text?.style?.style,
                            fontSize: {
                              ...options?.discountedPrice?.options?.bodyRow1
                                ?.options?.badge?.text?.style?.style?.fontSize,
                              mobile: options?.discountedPrice?.options
                                ?.bodyRow1?.options?.badge?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.discountedPrice?.options?.bodyRow1
                                    ?.options?.badge?.text?.style?.style
                                    ?.fontSize?.mobile
                                : undefined,
                            },
                            lineHeight: {
                              ...options?.discountedPrice?.options?.bodyRow1
                                ?.options?.badge?.text?.style?.style
                                ?.lineHeight,
                              mobile: options?.discountedPrice?.options
                                ?.bodyRow1?.options?.badge?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.discountedPrice?.options?.bodyRow1
                                    ?.options?.badge?.text?.style?.style
                                    ?.lineHeight?.mobile
                                : undefined,
                            },
                            fontWeight: {
                              ...options?.discountedPrice?.options?.bodyRow1
                                ?.options?.badge?.text?.style?.style
                                ?.fontWeight,
                              mobile: options?.discountedPrice?.options
                                ?.bodyRow1?.options?.badge?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.discountedPrice?.options?.bodyRow1
                                    ?.options?.badge?.text?.style?.style
                                    ?.fontWeight?.mobile
                                : undefined,
                            },
                          },
                        },
                        message: {
                          ...options?.discountedPrice?.options?.bodyRow1
                            ?.options?.badge?.text?.message,
                          settings: undefined,
                        },
                      },
                      availableEntries: undefined,
                      canBeDragged: undefined,
                      canBeRemoved: undefined,
                      canBeHidden: undefined,
                    },
                    price: {
                      ...options?.discountedPrice?.options?.bodyRow1?.options
                        ?.price,
                      text: {
                        ...options?.discountedPrice?.options?.bodyRow1?.options
                          ?.price?.text,
                        style: {
                          ...options?.discountedPrice?.options?.bodyRow1
                            ?.options?.price?.text?.style,
                          style: {
                            ...options?.discountedPrice?.options?.bodyRow1
                              ?.options?.price?.text?.style?.style,
                            fontSize: {
                              ...options?.discountedPrice?.options?.bodyRow1
                                ?.options?.price?.text?.style?.style?.fontSize,
                              mobile: options?.discountedPrice?.options
                                ?.bodyRow1?.options?.price?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.discountedPrice?.options?.bodyRow1
                                    ?.options?.price?.text?.style?.style
                                    ?.fontSize?.mobile
                                : undefined,
                            },
                            lineHeight: {
                              ...options?.discountedPrice?.options?.bodyRow1
                                ?.options?.price?.text?.style?.style
                                ?.lineHeight,
                              mobile: options?.discountedPrice?.options
                                ?.bodyRow1?.options?.price?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.discountedPrice?.options?.bodyRow1
                                    ?.options?.price?.text?.style?.style
                                    ?.lineHeight?.mobile
                                : undefined,
                            },
                            fontWeight: {
                              ...options?.discountedPrice?.options?.bodyRow1
                                ?.options?.price?.text?.style?.style
                                ?.fontWeight,
                              mobile: options?.discountedPrice?.options
                                ?.bodyRow1?.options?.price?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.discountedPrice?.options?.bodyRow1
                                    ?.options?.price?.text?.style?.style
                                    ?.fontWeight?.mobile
                                : undefined,
                            },
                          },
                        },
                        message: {
                          ...options?.discountedPrice?.options?.bodyRow1
                            ?.options?.price?.text?.message,
                          settings: undefined,
                        },
                      },
                      availableEntries: undefined,
                      canBeDragged: undefined,
                      canBeRemoved: undefined,
                      canBeHidden: undefined,
                    },
                  },
                },
                bodyRow2: {
                  ...options?.discountedPrice?.options?.bodyRow2,
                  availableEntries: undefined,
                  canBeDragged: undefined,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                  options: {
                    ...options?.discountedPrice?.options?.bodyRow2?.options,
                    badge: {
                      ...options?.discountedPrice?.options?.bodyRow2?.options
                        ?.badge,
                      background: {
                        ...options?.discountedPrice?.options?.bodyRow2?.options
                          ?.badge?.background,
                        border: {
                          ...options?.discountedPrice?.options?.bodyRow2
                            ?.options?.badge?.background?.border,
                          value: {
                            ...options?.discountedPrice?.options?.bodyRow2
                              ?.options?.badge?.background?.border?.value,
                            lineWeight: {
                              ...options?.discountedPrice?.options?.bodyRow2
                                ?.options?.badge?.background?.border?.value
                                ?.lineWeight,
                              customValue:
                                options?.discountedPrice?.options?.bodyRow2
                                  ?.options?.badge?.background?.border?.value
                                  ?.lineWeight?.type ===
                                LineWeightTypeDtoEnum.CUSTOM
                                  ? options?.discountedPrice?.options?.bodyRow2
                                      ?.options?.badge?.background?.border
                                      ?.value?.lineWeight?.customValue
                                  : undefined,
                            },
                          },
                        },
                      },
                      cornerRadius: {
                        ...options?.discountedPrice?.options?.bodyRow2?.options
                          ?.badge?.cornerRadius,
                        customValue:
                          options?.discountedPrice?.options?.bodyRow2?.options
                            ?.badge?.cornerRadius?.type ===
                          LineWeightTypeDtoEnum.CUSTOM
                            ? options?.discountedPrice?.options?.bodyRow2
                                ?.options?.badge?.cornerRadius?.customValue
                            : undefined,
                      },
                      text: {
                        ...options?.discountedPrice?.options?.bodyRow2?.options
                          ?.badge?.text,
                        style: {
                          ...options?.discountedPrice?.options?.bodyRow2
                            ?.options?.badge?.text?.style,
                          style: {
                            ...options?.discountedPrice?.options?.bodyRow2
                              ?.options?.badge?.text?.style?.style,
                            fontSize: {
                              ...options?.discountedPrice?.options?.bodyRow2
                                ?.options?.badge?.text?.style?.style?.fontSize,
                              mobile: options?.discountedPrice?.options
                                ?.bodyRow2?.options?.badge?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.discountedPrice?.options?.bodyRow2
                                    ?.options?.badge?.text?.style?.style
                                    ?.fontSize?.mobile
                                : undefined,
                            },
                            lineHeight: {
                              ...options?.discountedPrice?.options?.bodyRow2
                                ?.options?.badge?.text?.style?.style
                                ?.lineHeight,
                              mobile: options?.discountedPrice?.options
                                ?.bodyRow2?.options?.badge?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.discountedPrice?.options?.bodyRow2
                                    ?.options?.badge?.text?.style?.style
                                    ?.lineHeight?.mobile
                                : undefined,
                            },
                            fontWeight: {
                              ...options?.discountedPrice?.options?.bodyRow2
                                ?.options?.badge?.text?.style?.style
                                ?.fontWeight,
                              mobile: options?.discountedPrice?.options
                                ?.bodyRow2?.options?.badge?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.discountedPrice?.options?.bodyRow2
                                    ?.options?.badge?.text?.style?.style
                                    ?.fontWeight?.mobile
                                : undefined,
                            },
                          },
                        },
                        message: {
                          ...options?.discountedPrice?.options?.bodyRow2
                            ?.options?.badge?.text?.message,
                          settings: undefined,
                        },
                      },
                      availableEntries: undefined,
                      canBeDragged: undefined,
                      canBeRemoved: undefined,
                      canBeHidden: undefined,
                    },
                    price: {
                      ...options?.discountedPrice?.options?.bodyRow2?.options
                        ?.price,
                      text: {
                        ...options?.discountedPrice?.options?.bodyRow2?.options
                          ?.price?.text,
                        style: {
                          ...options?.discountedPrice?.options?.bodyRow2
                            ?.options?.price?.text?.style,
                          style: {
                            ...options?.discountedPrice?.options?.bodyRow2
                              ?.options?.price?.text?.style?.style,
                            fontSize: {
                              ...options?.discountedPrice?.options?.bodyRow2
                                ?.options?.price?.text?.style?.style?.fontSize,
                              mobile: options?.discountedPrice?.options
                                ?.bodyRow2?.options?.price?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.discountedPrice?.options?.bodyRow2
                                    ?.options?.price?.text?.style?.style
                                    ?.fontSize?.mobile
                                : undefined,
                            },
                            lineHeight: {
                              ...options?.discountedPrice?.options?.bodyRow2
                                ?.options?.price?.text?.style?.style
                                ?.lineHeight,
                              mobile: options?.discountedPrice?.options
                                ?.bodyRow2?.options?.price?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.discountedPrice?.options?.bodyRow2
                                    ?.options?.price?.text?.style?.style
                                    ?.lineHeight?.mobile
                                : undefined,
                            },
                            fontWeight: {
                              ...options?.discountedPrice?.options?.bodyRow2
                                ?.options?.price?.text?.style?.style
                                ?.fontWeight,
                              mobile: options?.discountedPrice?.options
                                ?.bodyRow2?.options?.price?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.discountedPrice?.options?.bodyRow2
                                    ?.options?.price?.text?.style?.style
                                    ?.fontWeight?.mobile
                                : undefined,
                            },
                          },
                        },
                        message: {
                          ...options?.discountedPrice?.options?.bodyRow2
                            ?.options?.price?.text?.message,
                          settings: undefined,
                        },
                      },
                      availableEntries: undefined,
                      canBeDragged: undefined,
                      canBeRemoved: undefined,
                      canBeHidden: undefined,
                    },
                  },
                },
                bodyRow3: {
                  ...options?.discountedPrice?.options?.bodyRow3,
                  availableEntries: undefined,
                  canBeDragged: undefined,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                  options: {
                    ...options?.discountedPrice?.options?.bodyRow3?.options,
                    badge: {
                      ...options?.discountedPrice?.options?.bodyRow3?.options
                        ?.badge,
                      background: {
                        ...options?.discountedPrice?.options?.bodyRow3?.options
                          ?.badge?.background,
                        border: {
                          ...options?.discountedPrice?.options?.bodyRow3
                            ?.options?.badge?.background?.border,
                          value: {
                            ...options?.discountedPrice?.options?.bodyRow3
                              ?.options?.badge?.background?.border?.value,
                            lineWeight: {
                              ...options?.discountedPrice?.options?.bodyRow3
                                ?.options?.badge?.background?.border?.value
                                ?.lineWeight,
                              customValue:
                                options?.discountedPrice?.options?.bodyRow3
                                  ?.options?.badge?.background?.border?.value
                                  ?.lineWeight?.type ===
                                LineWeightTypeDtoEnum.CUSTOM
                                  ? options?.discountedPrice?.options?.bodyRow3
                                      ?.options?.badge?.background?.border
                                      ?.value?.lineWeight?.customValue
                                  : undefined,
                            },
                          },
                        },
                      },
                      cornerRadius: {
                        ...options?.discountedPrice?.options?.bodyRow3?.options
                          ?.badge?.cornerRadius,
                        customValue:
                          options?.discountedPrice?.options?.bodyRow3?.options
                            ?.badge?.cornerRadius?.type ===
                          LineWeightTypeDtoEnum.CUSTOM
                            ? options?.discountedPrice?.options?.bodyRow3
                                ?.options?.badge?.cornerRadius?.customValue
                            : undefined,
                      },
                      text: {
                        ...options?.discountedPrice?.options?.bodyRow3?.options
                          ?.badge?.text,
                        style: {
                          ...options?.discountedPrice?.options?.bodyRow3
                            ?.options?.badge?.text?.style,
                          style: {
                            ...options?.discountedPrice?.options?.bodyRow3
                              ?.options?.badge?.text?.style?.style,
                            fontSize: {
                              ...options?.discountedPrice?.options?.bodyRow3
                                ?.options?.badge?.text?.style?.style?.fontSize,
                              mobile: options?.discountedPrice?.options
                                ?.bodyRow3?.options?.badge?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.discountedPrice?.options?.bodyRow3
                                    ?.options?.badge?.text?.style?.style
                                    ?.fontSize?.mobile
                                : undefined,
                            },
                            lineHeight: {
                              ...options?.discountedPrice?.options?.bodyRow3
                                ?.options?.badge?.text?.style?.style
                                ?.lineHeight,
                              mobile: options?.discountedPrice?.options
                                ?.bodyRow3?.options?.badge?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.discountedPrice?.options?.bodyRow3
                                    ?.options?.badge?.text?.style?.style
                                    ?.lineHeight?.mobile
                                : undefined,
                            },
                            fontWeight: {
                              ...options?.discountedPrice?.options?.bodyRow3
                                ?.options?.badge?.text?.style?.style
                                ?.fontWeight,
                              mobile: options?.discountedPrice?.options
                                ?.bodyRow3?.options?.badge?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.discountedPrice?.options?.bodyRow3
                                    ?.options?.badge?.text?.style?.style
                                    ?.fontWeight?.mobile
                                : undefined,
                            },
                          },
                        },
                        message: {
                          ...options?.discountedPrice?.options?.bodyRow3
                            ?.options?.badge?.text?.message,
                          settings: undefined,
                        },
                      },
                      availableEntries: undefined,
                      canBeDragged: undefined,
                      canBeRemoved: undefined,
                      canBeHidden: undefined,
                    },
                    price: {
                      ...options?.discountedPrice?.options?.bodyRow3?.options
                        ?.price,
                      text: {
                        ...options?.discountedPrice?.options?.bodyRow3?.options
                          ?.price?.text,
                        style: {
                          ...options?.discountedPrice?.options?.bodyRow3
                            ?.options?.price?.text?.style,
                          style: {
                            ...options?.discountedPrice?.options?.bodyRow3
                              ?.options?.price?.text?.style?.style,
                            fontSize: {
                              ...options?.discountedPrice?.options?.bodyRow3
                                ?.options?.price?.text?.style?.style?.fontSize,
                              mobile: options?.discountedPrice?.options
                                ?.bodyRow3?.options?.price?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.discountedPrice?.options?.bodyRow3
                                    ?.options?.price?.text?.style?.style
                                    ?.fontSize?.mobile
                                : undefined,
                            },
                            lineHeight: {
                              ...options?.discountedPrice?.options?.bodyRow3
                                ?.options?.price?.text?.style?.style
                                ?.lineHeight,
                              mobile: options?.discountedPrice?.options
                                ?.bodyRow3?.options?.price?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.discountedPrice?.options?.bodyRow3
                                    ?.options?.price?.text?.style?.style
                                    ?.lineHeight?.mobile
                                : undefined,
                            },
                            fontWeight: {
                              ...options?.discountedPrice?.options?.bodyRow3
                                ?.options?.price?.text?.style?.style
                                ?.fontWeight,
                              mobile: options?.discountedPrice?.options
                                ?.bodyRow3?.options?.price?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.discountedPrice?.options?.bodyRow3
                                    ?.options?.price?.text?.style?.style
                                    ?.fontWeight?.mobile
                                : undefined,
                            },
                          },
                        },
                        message: {
                          ...options?.discountedPrice?.options?.bodyRow3
                            ?.options?.price?.text?.message,
                          settings: undefined,
                        },
                      },
                      availableEntries: undefined,
                      canBeDragged: undefined,
                      canBeRemoved: undefined,
                      canBeHidden: undefined,
                    },
                  },
                },
              },
            },
            notDiscountedPrice: {
              ...options?.notDiscountedPrice,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              key: undefined,
              availableEntries: undefined,
              options: {
                ...options?.notDiscountedPrice?.options,
                footerText: {
                  ...options?.notDiscountedPrice?.options?.footerText,
                  general: {
                    ...options?.notDiscountedPrice?.options?.footerText
                      ?.general,
                    text: {
                      ...options?.notDiscountedPrice?.options?.footerText
                        ?.general?.text,
                      style: {
                        ...options?.notDiscountedPrice?.options?.footerText
                          ?.general?.text?.style,
                        style: {
                          ...options?.notDiscountedPrice?.options?.footerText
                            ?.general?.text?.style?.style,
                          fontSize: {
                            ...options?.notDiscountedPrice?.options?.footerText
                              ?.general?.text?.style?.style?.fontSize,
                            mobile: options?.notDiscountedPrice?.options
                              ?.footerText?.general?.text?.style?.style
                              ?.differentOnMobile
                              ? options?.notDiscountedPrice?.options?.footerText
                                  ?.general?.text?.style?.style?.fontSize
                                  ?.mobile
                              : undefined,
                          },
                          lineHeight: {
                            ...options?.notDiscountedPrice?.options?.footerText
                              ?.general?.text?.style?.style?.lineHeight,
                            mobile: options?.notDiscountedPrice?.options
                              ?.footerText?.general?.text?.style?.style
                              ?.differentOnMobile
                              ? options?.notDiscountedPrice?.options?.footerText
                                  ?.general?.text?.style?.style?.lineHeight
                                  ?.mobile
                              : undefined,
                          },
                          fontWeight: {
                            ...options?.notDiscountedPrice?.options?.footerText
                              ?.general?.text?.style?.style?.fontWeight,
                            mobile: options?.notDiscountedPrice?.options
                              ?.footerText?.general?.text?.style?.style
                              ?.differentOnMobile
                              ? options?.notDiscountedPrice?.options?.footerText
                                  ?.general?.text?.style?.style?.fontWeight
                                  ?.mobile
                              : undefined,
                          },
                        },
                      },
                      message: {
                        ...options?.notDiscountedPrice?.options?.footerText
                          ?.general?.text?.message,
                        settings: undefined,
                      },
                    },
                  },
                  availableEntries: undefined,
                  canBeDragged: undefined,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                },
                headerText: {
                  ...options?.notDiscountedPrice?.options?.headerText,
                  general: {
                    ...options?.notDiscountedPrice?.options?.headerText
                      ?.general,
                    text: {
                      ...options?.notDiscountedPrice?.options?.headerText
                        ?.general?.text,
                      style: {
                        ...options?.notDiscountedPrice?.options?.headerText
                          ?.general?.text?.style,
                        style: {
                          ...options?.notDiscountedPrice?.options?.headerText
                            ?.general?.text?.style?.style,
                          fontSize: {
                            ...options?.notDiscountedPrice?.options?.headerText
                              ?.general?.text?.style?.style?.fontSize,
                            mobile: options?.notDiscountedPrice?.options
                              ?.headerText?.general?.text?.style?.style
                              ?.differentOnMobile
                              ? options?.notDiscountedPrice?.options?.headerText
                                  ?.general?.text?.style?.style?.fontSize
                                  ?.mobile
                              : undefined,
                          },
                          lineHeight: {
                            ...options?.notDiscountedPrice?.options?.headerText
                              ?.general?.text?.style?.style?.lineHeight,
                            mobile: options?.notDiscountedPrice?.options
                              ?.headerText?.general?.text?.style?.style
                              ?.differentOnMobile
                              ? options?.notDiscountedPrice?.options?.headerText
                                  ?.general?.text?.style?.style?.lineHeight
                                  ?.mobile
                              : undefined,
                          },
                          fontWeight: {
                            ...options?.notDiscountedPrice?.options?.headerText
                              ?.general?.text?.style?.style?.fontWeight,
                            mobile: options?.notDiscountedPrice?.options
                              ?.headerText?.general?.text?.style?.style
                              ?.differentOnMobile
                              ? options?.notDiscountedPrice?.options?.headerText
                                  ?.general?.text?.style?.style?.fontWeight
                                  ?.mobile
                              : undefined,
                          },
                        },
                      },
                      message: {
                        ...options?.notDiscountedPrice?.options?.headerText
                          ?.general?.text?.message,
                        settings: undefined,
                      },
                    },
                  },
                  availableEntries: undefined,
                  canBeDragged: undefined,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                },
                bodyRow1: {
                  ...options?.notDiscountedPrice?.options?.bodyRow1,
                  availableEntries: undefined,
                  canBeDragged: undefined,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                  options: {
                    ...options?.notDiscountedPrice?.options?.bodyRow1?.options,
                    badge: {
                      ...options?.notDiscountedPrice?.options?.bodyRow1?.options
                        ?.badge,
                      background: {
                        ...options?.notDiscountedPrice?.options?.bodyRow1
                          ?.options?.badge?.background,
                        border: {
                          ...options?.notDiscountedPrice?.options?.bodyRow1
                            ?.options?.badge?.background?.border,
                          value: {
                            ...options?.notDiscountedPrice?.options?.bodyRow1
                              ?.options?.badge?.background?.border?.value,
                            lineWeight: {
                              ...options?.notDiscountedPrice?.options?.bodyRow1
                                ?.options?.badge?.background?.border?.value
                                ?.lineWeight,
                              customValue:
                                options?.notDiscountedPrice?.options?.bodyRow1
                                  ?.options?.badge?.background?.border?.value
                                  ?.lineWeight?.type ===
                                LineWeightTypeDtoEnum.CUSTOM
                                  ? options?.notDiscountedPrice?.options
                                      ?.bodyRow1?.options?.badge?.background
                                      ?.border?.value?.lineWeight?.customValue
                                  : undefined,
                            },
                          },
                        },
                      },
                      cornerRadius: {
                        ...options?.notDiscountedPrice?.options?.bodyRow1
                          ?.options?.badge?.cornerRadius,
                        customValue:
                          options?.notDiscountedPrice?.options?.bodyRow1
                            ?.options?.badge?.cornerRadius?.type ===
                          LineWeightTypeDtoEnum.CUSTOM
                            ? options?.notDiscountedPrice?.options?.bodyRow1
                                ?.options?.badge?.cornerRadius?.customValue
                            : undefined,
                      },
                      text: {
                        ...options?.notDiscountedPrice?.options?.bodyRow1
                          ?.options?.badge?.text,
                        style: {
                          ...options?.notDiscountedPrice?.options?.bodyRow1
                            ?.options?.badge?.text?.style,
                          style: {
                            ...options?.notDiscountedPrice?.options?.bodyRow1
                              ?.options?.badge?.text?.style?.style,
                            fontSize: {
                              ...options?.notDiscountedPrice?.options?.bodyRow1
                                ?.options?.badge?.text?.style?.style?.fontSize,
                              mobile: options?.notDiscountedPrice?.options
                                ?.bodyRow1?.options?.badge?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.notDiscountedPrice?.options?.bodyRow1
                                    ?.options?.badge?.text?.style?.style
                                    ?.fontSize?.mobile
                                : undefined,
                            },
                            lineHeight: {
                              ...options?.notDiscountedPrice?.options?.bodyRow1
                                ?.options?.badge?.text?.style?.style
                                ?.lineHeight,
                              mobile: options?.notDiscountedPrice?.options
                                ?.bodyRow1?.options?.badge?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.notDiscountedPrice?.options?.bodyRow1
                                    ?.options?.badge?.text?.style?.style
                                    ?.lineHeight?.mobile
                                : undefined,
                            },
                            fontWeight: {
                              ...options?.notDiscountedPrice?.options?.bodyRow1
                                ?.options?.badge?.text?.style?.style
                                ?.fontWeight,
                              mobile: options?.notDiscountedPrice?.options
                                ?.bodyRow1?.options?.badge?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.notDiscountedPrice?.options?.bodyRow1
                                    ?.options?.badge?.text?.style?.style
                                    ?.fontWeight?.mobile
                                : undefined,
                            },
                          },
                        },
                        message: {
                          ...options?.notDiscountedPrice?.options?.bodyRow1
                            ?.options?.badge?.text?.message,
                          settings: undefined,
                        },
                      },
                      availableEntries: undefined,
                      canBeDragged: undefined,
                      canBeRemoved: undefined,
                      canBeHidden: undefined,
                    },
                    price: {
                      ...options?.notDiscountedPrice?.options?.bodyRow1?.options
                        ?.price,
                      text: {
                        ...options?.notDiscountedPrice?.options?.bodyRow1
                          ?.options?.price?.text,
                        style: {
                          ...options?.notDiscountedPrice?.options?.bodyRow1
                            ?.options?.price?.text?.style,
                          style: {
                            ...options?.notDiscountedPrice?.options?.bodyRow1
                              ?.options?.price?.text?.style?.style,
                            fontSize: {
                              ...options?.notDiscountedPrice?.options?.bodyRow1
                                ?.options?.price?.text?.style?.style?.fontSize,
                              mobile: options?.notDiscountedPrice?.options
                                ?.bodyRow1?.options?.price?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.notDiscountedPrice?.options?.bodyRow1
                                    ?.options?.price?.text?.style?.style
                                    ?.fontSize?.mobile
                                : undefined,
                            },
                            lineHeight: {
                              ...options?.notDiscountedPrice?.options?.bodyRow1
                                ?.options?.price?.text?.style?.style
                                ?.lineHeight,
                              mobile: options?.notDiscountedPrice?.options
                                ?.bodyRow1?.options?.price?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.notDiscountedPrice?.options?.bodyRow1
                                    ?.options?.price?.text?.style?.style
                                    ?.lineHeight?.mobile
                                : undefined,
                            },
                            fontWeight: {
                              ...options?.notDiscountedPrice?.options?.bodyRow1
                                ?.options?.price?.text?.style?.style
                                ?.fontWeight,
                              mobile: options?.notDiscountedPrice?.options
                                ?.bodyRow1?.options?.price?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.notDiscountedPrice?.options?.bodyRow1
                                    ?.options?.price?.text?.style?.style
                                    ?.fontWeight?.mobile
                                : undefined,
                            },
                          },
                        },
                        message: {
                          ...options?.notDiscountedPrice?.options?.bodyRow1
                            ?.options?.price?.text?.message,
                          settings: undefined,
                        },
                      },
                      availableEntries: undefined,
                      canBeDragged: undefined,
                      canBeRemoved: undefined,
                      canBeHidden: undefined,
                    },
                  },
                },
                bodyRow2: {
                  ...options?.notDiscountedPrice?.options?.bodyRow2,
                  availableEntries: undefined,
                  canBeDragged: undefined,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                  options: {
                    ...options?.notDiscountedPrice?.options?.bodyRow2?.options,
                    badge: {
                      ...options?.notDiscountedPrice?.options?.bodyRow2?.options
                        ?.badge,
                      background: {
                        ...options?.notDiscountedPrice?.options?.bodyRow2
                          ?.options?.badge?.background,
                        border: {
                          ...options?.notDiscountedPrice?.options?.bodyRow2
                            ?.options?.badge?.background?.border,
                          value: {
                            ...options?.notDiscountedPrice?.options?.bodyRow2
                              ?.options?.badge?.background?.border?.value,
                            lineWeight: {
                              ...options?.notDiscountedPrice?.options?.bodyRow2
                                ?.options?.badge?.background?.border?.value
                                ?.lineWeight,
                              customValue:
                                options?.notDiscountedPrice?.options?.bodyRow2
                                  ?.options?.badge?.background?.border?.value
                                  ?.lineWeight?.type ===
                                LineWeightTypeDtoEnum.CUSTOM
                                  ? options?.notDiscountedPrice?.options
                                      ?.bodyRow2?.options?.badge?.background
                                      ?.border?.value?.lineWeight?.customValue
                                  : undefined,
                            },
                          },
                        },
                      },
                      cornerRadius: {
                        ...options?.notDiscountedPrice?.options?.bodyRow2
                          ?.options?.badge?.cornerRadius,
                        customValue:
                          options?.notDiscountedPrice?.options?.bodyRow2
                            ?.options?.badge?.cornerRadius?.type ===
                          LineWeightTypeDtoEnum.CUSTOM
                            ? options?.notDiscountedPrice?.options?.bodyRow2
                                ?.options?.badge?.cornerRadius?.customValue
                            : undefined,
                      },
                      text: {
                        ...options?.notDiscountedPrice?.options?.bodyRow2
                          ?.options?.badge?.text,
                        style: {
                          ...options?.notDiscountedPrice?.options?.bodyRow2
                            ?.options?.badge?.text?.style,
                          style: {
                            ...options?.notDiscountedPrice?.options?.bodyRow2
                              ?.options?.badge?.text?.style?.style,
                            fontSize: {
                              ...options?.notDiscountedPrice?.options?.bodyRow2
                                ?.options?.badge?.text?.style?.style?.fontSize,
                              mobile: options?.notDiscountedPrice?.options
                                ?.bodyRow2?.options?.badge?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.notDiscountedPrice?.options?.bodyRow2
                                    ?.options?.badge?.text?.style?.style
                                    ?.fontSize?.mobile
                                : undefined,
                            },
                            lineHeight: {
                              ...options?.notDiscountedPrice?.options?.bodyRow2
                                ?.options?.badge?.text?.style?.style
                                ?.lineHeight,
                              mobile: options?.notDiscountedPrice?.options
                                ?.bodyRow2?.options?.badge?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.notDiscountedPrice?.options?.bodyRow2
                                    ?.options?.badge?.text?.style?.style
                                    ?.lineHeight?.mobile
                                : undefined,
                            },
                            fontWeight: {
                              ...options?.notDiscountedPrice?.options?.bodyRow2
                                ?.options?.badge?.text?.style?.style
                                ?.fontWeight,
                              mobile: options?.notDiscountedPrice?.options
                                ?.bodyRow2?.options?.badge?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.notDiscountedPrice?.options?.bodyRow2
                                    ?.options?.badge?.text?.style?.style
                                    ?.fontWeight?.mobile
                                : undefined,
                            },
                          },
                        },
                        message: {
                          ...options?.notDiscountedPrice?.options?.bodyRow2
                            ?.options?.badge?.text?.message,
                          settings: undefined,
                        },
                      },
                      availableEntries: undefined,
                      canBeDragged: undefined,
                      canBeRemoved: undefined,
                      canBeHidden: undefined,
                    },
                    price: {
                      ...options?.notDiscountedPrice?.options?.bodyRow2?.options
                        ?.price,
                      text: {
                        ...options?.notDiscountedPrice?.options?.bodyRow2
                          ?.options?.price?.text,
                        style: {
                          ...options?.notDiscountedPrice?.options?.bodyRow2
                            ?.options?.price?.text?.style,
                          style: {
                            ...options?.notDiscountedPrice?.options?.bodyRow2
                              ?.options?.price?.text?.style?.style,
                            fontSize: {
                              ...options?.notDiscountedPrice?.options?.bodyRow2
                                ?.options?.price?.text?.style?.style?.fontSize,
                              mobile: options?.notDiscountedPrice?.options
                                ?.bodyRow2?.options?.price?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.notDiscountedPrice?.options?.bodyRow2
                                    ?.options?.price?.text?.style?.style
                                    ?.fontSize?.mobile
                                : undefined,
                            },
                            lineHeight: {
                              ...options?.notDiscountedPrice?.options?.bodyRow2
                                ?.options?.price?.text?.style?.style
                                ?.lineHeight,
                              mobile: options?.notDiscountedPrice?.options
                                ?.bodyRow2?.options?.price?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.notDiscountedPrice?.options?.bodyRow2
                                    ?.options?.price?.text?.style?.style
                                    ?.lineHeight?.mobile
                                : undefined,
                            },
                            fontWeight: {
                              ...options?.notDiscountedPrice?.options?.bodyRow2
                                ?.options?.price?.text?.style?.style
                                ?.fontWeight,
                              mobile: options?.notDiscountedPrice?.options
                                ?.bodyRow2?.options?.price?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.notDiscountedPrice?.options?.bodyRow2
                                    ?.options?.price?.text?.style?.style
                                    ?.fontWeight?.mobile
                                : undefined,
                            },
                          },
                        },
                        message: {
                          ...options?.notDiscountedPrice?.options?.bodyRow2
                            ?.options?.price?.text?.message,
                          settings: undefined,
                        },
                      },
                      availableEntries: undefined,
                      canBeDragged: undefined,
                      canBeRemoved: undefined,
                      canBeHidden: undefined,
                    },
                  },
                },
                bodyRow3: {
                  ...options?.notDiscountedPrice?.options?.bodyRow3,
                  availableEntries: undefined,
                  canBeDragged: undefined,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                  options: {
                    ...options?.notDiscountedPrice?.options?.bodyRow3?.options,
                    badge: {
                      ...options?.notDiscountedPrice?.options?.bodyRow3?.options
                        ?.badge,
                      background: {
                        ...options?.notDiscountedPrice?.options?.bodyRow3
                          ?.options?.badge?.background,
                        border: {
                          ...options?.notDiscountedPrice?.options?.bodyRow3
                            ?.options?.badge?.background?.border,
                          value: {
                            ...options?.notDiscountedPrice?.options?.bodyRow3
                              ?.options?.badge?.background?.border?.value,
                            lineWeight: {
                              ...options?.notDiscountedPrice?.options?.bodyRow3
                                ?.options?.badge?.background?.border?.value
                                ?.lineWeight,
                              customValue:
                                options?.notDiscountedPrice?.options?.bodyRow3
                                  ?.options?.badge?.background?.border?.value
                                  ?.lineWeight?.type ===
                                LineWeightTypeDtoEnum.CUSTOM
                                  ? options?.notDiscountedPrice?.options
                                      ?.bodyRow3?.options?.badge?.background
                                      ?.border?.value?.lineWeight?.customValue
                                  : undefined,
                            },
                          },
                        },
                      },
                      cornerRadius: {
                        ...options?.notDiscountedPrice?.options?.bodyRow3
                          ?.options?.badge?.cornerRadius,
                        customValue:
                          options?.notDiscountedPrice?.options?.bodyRow3
                            ?.options?.badge?.cornerRadius?.type ===
                          LineWeightTypeDtoEnum.CUSTOM
                            ? options?.notDiscountedPrice?.options?.bodyRow3
                                ?.options?.badge?.cornerRadius?.customValue
                            : undefined,
                      },
                      text: {
                        ...options?.notDiscountedPrice?.options?.bodyRow3
                          ?.options?.badge?.text,
                        style: {
                          ...options?.notDiscountedPrice?.options?.bodyRow3
                            ?.options?.badge?.text?.style,
                          style: {
                            ...options?.notDiscountedPrice?.options?.bodyRow3
                              ?.options?.badge?.text?.style?.style,
                            fontSize: {
                              ...options?.notDiscountedPrice?.options?.bodyRow3
                                ?.options?.badge?.text?.style?.style?.fontSize,
                              mobile: options?.notDiscountedPrice?.options
                                ?.bodyRow3?.options?.badge?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.notDiscountedPrice?.options?.bodyRow3
                                    ?.options?.badge?.text?.style?.style
                                    ?.fontSize?.mobile
                                : undefined,
                            },
                            lineHeight: {
                              ...options?.notDiscountedPrice?.options?.bodyRow3
                                ?.options?.badge?.text?.style?.style
                                ?.lineHeight,
                              mobile: options?.notDiscountedPrice?.options
                                ?.bodyRow3?.options?.badge?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.notDiscountedPrice?.options?.bodyRow3
                                    ?.options?.badge?.text?.style?.style
                                    ?.lineHeight?.mobile
                                : undefined,
                            },
                            fontWeight: {
                              ...options?.notDiscountedPrice?.options?.bodyRow3
                                ?.options?.badge?.text?.style?.style
                                ?.fontWeight,
                              mobile: options?.notDiscountedPrice?.options
                                ?.bodyRow3?.options?.badge?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.notDiscountedPrice?.options?.bodyRow3
                                    ?.options?.badge?.text?.style?.style
                                    ?.fontWeight?.mobile
                                : undefined,
                            },
                          },
                        },
                        message: {
                          ...options?.notDiscountedPrice?.options?.bodyRow3
                            ?.options?.badge?.text?.message,
                          settings: undefined,
                        },
                      },
                      availableEntries: undefined,
                      canBeDragged: undefined,
                      canBeRemoved: undefined,
                      canBeHidden: undefined,
                    },
                    price: {
                      ...options?.notDiscountedPrice?.options?.bodyRow3?.options
                        ?.price,
                      text: {
                        ...options?.notDiscountedPrice?.options?.bodyRow3
                          ?.options?.price?.text,
                        style: {
                          ...options?.notDiscountedPrice?.options?.bodyRow3
                            ?.options?.price?.text?.style,
                          style: {
                            ...options?.notDiscountedPrice?.options?.bodyRow3
                              ?.options?.price?.text?.style?.style,
                            fontSize: {
                              ...options?.notDiscountedPrice?.options?.bodyRow3
                                ?.options?.price?.text?.style?.style?.fontSize,
                              mobile: options?.notDiscountedPrice?.options
                                ?.bodyRow3?.options?.price?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.notDiscountedPrice?.options?.bodyRow3
                                    ?.options?.price?.text?.style?.style
                                    ?.fontSize?.mobile
                                : undefined,
                            },
                            lineHeight: {
                              ...options?.notDiscountedPrice?.options?.bodyRow3
                                ?.options?.price?.text?.style?.style
                                ?.lineHeight,
                              mobile: options?.notDiscountedPrice?.options
                                ?.bodyRow3?.options?.price?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.notDiscountedPrice?.options?.bodyRow3
                                    ?.options?.price?.text?.style?.style
                                    ?.lineHeight?.mobile
                                : undefined,
                            },
                            fontWeight: {
                              ...options?.notDiscountedPrice?.options?.bodyRow3
                                ?.options?.price?.text?.style?.style
                                ?.fontWeight,
                              mobile: options?.notDiscountedPrice?.options
                                ?.bodyRow3?.options?.price?.text?.style?.style
                                ?.differentOnMobile
                                ? options?.notDiscountedPrice?.options?.bodyRow3
                                    ?.options?.price?.text?.style?.style
                                    ?.fontWeight?.mobile
                                : undefined,
                            },
                          },
                        },
                        message: {
                          ...options?.notDiscountedPrice?.options?.bodyRow3
                            ?.options?.price?.text?.message,
                          settings: undefined,
                        },
                      },
                      availableEntries: undefined,
                      canBeDragged: undefined,
                      canBeRemoved: undefined,
                      canBeHidden: undefined,
                    },
                  },
                },
              },
            },
          },
        }),
  };
};
