import React from 'react';
import './ColorBlock.scss';

interface ColorBlockProps {
  colors: string[];
}

const ColorBlock: React.FC<ColorBlockProps> = ({ colors }) => {
  const getGridTemplateRows = () => {
    switch (colors.length) {
      case 1:
        return '1fr';
      case 2:
      case 3:
      case 4:
      case 6:
        return '1fr 1fr';
      default:
        return '1fr';
    }
  };

  const getGridTemplateColumns = () => {
    switch (colors.length) {
      case 1:
      case 2:
        return '1fr';
      case 3:
      case 4:
        return '1fr 1fr';
      case 6:
        return '1fr 1fr 1fr';
      default:
        return '1fr';
    }
  };

  const getGridColumn = (index: number) => {
    if (colors.length === 3 && index === 0) {
      return '1 / span 2';
    }
    return undefined;
  };

  return colors.length !== 5 ? (
    <div
      className='grid-color-container'
      style={{
        display: 'grid',
        gridTemplateRows: getGridTemplateRows(),
        gridTemplateColumns: getGridTemplateColumns(),
        gap: 1,
      }}
    >
      {colors.map((color, index) => (
        <div
          key={color}
          className='color-block'
          style={{
            backgroundColor: color,
            gridColumn: getGridColumn(index),
          }}
        />
      ))}
    </div>
  ) : (
    <div className='flex-color-container'>
      {colors.map((color, index) => (
        <div key={index} className='block' style={{ backgroundColor: color }} />
      ))}
    </div>
  );
};

export default ColorBlock;
