import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BlockStack,
  Button,
  ButtonGroup,
  InlineStack,
  ProgressBar,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import classNames from 'classnames';
import { PromotionOperationProgressDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { PromotionStatusDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { getProgressLeftTime } from 'features/promotions/components/PromotionList/utils';
import { useConfigurePromotions } from 'features/promotions/hooks/useConfigurePromotion';

type ProgressStatusProps = {
  operationProgress: PromotionOperationProgressDto;
  promotionId: string;
};
const ProgressStatus: React.FC<ProgressStatusProps> = ({
  operationProgress,
  promotionId,
}) => {
  const {
    pausePromotionProgress,
    resumePromotionProgress,
    rollbackPromotionProgress,
    getPromotionOperationProgress,
    promotionOperationProgressData,
  } = useConfigurePromotions();
  const [i18n] = useI18n();
  const [isOperationLoading, setIsOperationLoading] = useState<boolean>(false);

  const onUpdatePromotionProgress = useCallback(() => {
    getPromotionOperationProgress([promotionId]);
  }, [promotionId]);

  const onResumePromotion = useCallback(() => {
    setIsOperationLoading(true);
    resumePromotionProgress(promotionId).then(() => {
      onUpdatePromotionProgress();
    });
  }, [promotionId]);

  const onPausePromotion = useCallback(() => {
    setIsOperationLoading(true);
    pausePromotionProgress(promotionId).then(() => {
      onUpdatePromotionProgress();
    });
  }, [promotionId]);

  const onUndoPromotion = useCallback(() => {
    setIsOperationLoading(true);
    rollbackPromotionProgress(promotionId).then(() => {
      onUpdatePromotionProgress();
    });
  }, [promotionId]);

  const progressActionButtons = useMemo(() => {
    switch (operationProgress.status) {
      case PromotionStatusDtoEnum.IN_PROGRESS_PUBLISHING:
        return (
          <ButtonGroup>
            {operationProgress?.isPaused ? (
              <Button
                disabled={isOperationLoading}
                onClick={onResumePromotion}
                variant='primary'
              >
                {i18n.translate('Resume')}
              </Button>
            ) : (
              <Button disabled={isOperationLoading} onClick={onPausePromotion}>
                {i18n.translate('Pause')}
              </Button>
            )}
            <Button
              tone='critical'
              disabled={isOperationLoading}
              onClick={onUndoPromotion}
            >
              {i18n.translate('Undo')}
            </Button>
          </ButtonGroup>
        );
      case PromotionStatusDtoEnum.IN_PROGRESS_UNPUBLISHING:
      case PromotionStatusDtoEnum.IN_PROGRESS_ROLLING_BACK:
        return (
          <ButtonGroup>
            {operationProgress?.isPaused ? (
              <Button
                disabled={isOperationLoading}
                onClick={onResumePromotion}
                variant='primary'
              >
                {i18n.translate('Resume')}
              </Button>
            ) : (
              <Button disabled={isOperationLoading} onClick={onPausePromotion}>
                {i18n.translate('Pause')}
              </Button>
            )}
          </ButtonGroup>
        );
    }
  }, [operationProgress, isOperationLoading]);

  const progressEndTime = useMemo(
    () =>
      !operationProgress.isPaused ? (
        <>
          ~
          {getProgressLeftTime(operationProgress?.estimatedTimeLeft || 0).slice(
            3
          ) + i18n.translate('Left')}
        </>
      ) : null,
    [operationProgress]
  );

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (operationProgress?.progress === 100) {
      clearInterval(interval);
    } else {
      interval = setInterval(() => onUpdatePromotionProgress(), 60000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [operationProgress]);

  return (
    <div className='Status'>
      {operationProgress.status ===
      PromotionStatusDtoEnum.IN_PROGRESS_ROLLING_BACK ? (
        <InlineStack align='space-between' blockAlign='center'>
          <Text as='p'>{i18n.translate('RollingBack')}</Text>
          <Text as='p' tone='subdued'>
            {progressEndTime}
          </Text>
        </InlineStack>
      ) : (
        <>
          <InlineStack align='space-between' blockAlign='center'>
            <Text as='p'>
              {operationProgress.status ===
              PromotionStatusDtoEnum.IN_PROGRESS_PUBLISHING
                ? operationProgress.isPaused
                  ? i18n.translate('ActivatingPaused')
                  : i18n.translate('ActivatingPromotion')
                : operationProgress.isPaused
                ? i18n.translate('DeactivatingPaused')
                : i18n.translate('DeactivatingPromotion')}
            </Text>
            <Text as='p' tone='subdued'>
              {progressEndTime}
            </Text>
          </InlineStack>
          <BlockStack gap='300'>
            <div
              className={classNames('ProgressStatusBar', {
                Disabled: operationProgress.isPaused,
              })}
            >
              <ProgressBar progress={operationProgress.progress} size='small' />
            </div>
            {progressActionButtons}
          </BlockStack>
        </>
      )}
    </div>
  );
};

export default ProgressStatus;
