import React, { useMemo } from 'react';
import {
  Bleed,
  BlockStack,
  Box,
  Card,
  Checkbox,
  ChoiceList,
  Link,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { OptionDtoCheckoutDraftOrderRemovalOptionDto } from 'core/api/adminSettings/adminSettingsApi';
import { CheckoutDraftOrderRemovalOptionDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
type RemoveDraftOrdersProps = {
  draftMode?: OptionDtoCheckoutDraftOrderRemovalOptionDto;
  setDraftMode: (value: OptionDtoCheckoutDraftOrderRemovalOptionDto) => void;
};
export const RemoveDraftOrders: React.FC<RemoveDraftOrdersProps> = ({
  draftMode,
  setDraftMode,
}) => {
  const [i18n] = useI18n();

  const removeOptions = useMemo(
    () =>
      Object.values(CheckoutDraftOrderRemovalOptionDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    [i18n]
  );

  return (
    <Card>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text fontWeight='semibold' as='h2'>
            {i18n.translate('Title')}
          </Text>

          <Text as='p' tone='subdued'>
            {i18n.translate('Subtitle', {
              readArticle: <Link>{i18n.translate('ReadThisArticle')}</Link>,
            })}
          </Text>
        </BlockStack>
        <Checkbox
          checked={draftMode?.enabled}
          label={i18n.translate('RemoveDraftOrders')}
          onChange={(value) => setDraftMode({ ...draftMode, enabled: value })}
        />
        {draftMode?.enabled && (
          <Box paddingInlineStart='600'>
            <ChoiceList
              title=''
              choices={removeOptions}
              selected={[draftMode?.value as string]}
              onChange={(value) =>
                setDraftMode({
                  ...draftMode,
                  value: value[0] as CheckoutDraftOrderRemovalOptionDtoEnum,
                })
              }
            />
          </Box>
        )}

        <Bleed marginBlockEnd='400' marginInline='400'>
          <Box background='bg-surface-secondary' padding='400'>
            <Text as='p' tone='subdued'>
              {i18n.translate('DraftOrderAreCreated')}
            </Text>
          </Box>
        </Bleed>
      </BlockStack>
    </Card>
  );
};
