import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  OfferBogoDto,
  OfferBogoSpecificationDto,
  OfferCombinationsDto,
  OfferCartRulesDto,
  OptionDtoOfferDiscountLimitDto,
  OptionDtoOfferMinimumSpendDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { useOfferFormValidity } from 'features/promotions/hooks/useOfferFormValidity';
import { RegularBogoOfferTabsEnum } from '../../../enums/OfferTypeTabs';
import { isEmpty, isEqual } from 'lodash';
import { OfferTypeSpecialCases } from 'core/enums/GrayBoxEnum';
import {
  OfferBogoTypeDtoEnum,
  OfferTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { Combinations } from '../../Combinations/Combinations';
import { MinimumSpend } from '../../MinimumSpend/MinimumSpend';
import { DiscountLimits } from '../../DiscountLimits/DiscountLimits';
import { BlockStack, Page } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { useNavigate } from 'react-router-dom';
import { LearnMoreModal } from '../../LearnMoreModal/LearnMoreModal';
import { OfferTemplate } from '../../OfferTemplate/OfferTemplate';
import { BogoOfferSection } from '../../BogoOfferSection/BogoOfferSection';
import { useIsDebugOrLocal } from 'core/hooks';
import { OfferParentComponentProps } from '../../../types/OfferTypesFromCatalogTypes';
import { CartRules } from '../../CartRules/CartRules';

export const BogoOffer: React.FC<OfferParentComponentProps> = (props) => {
  const { offerTemplate, handleOfferDataUpdate, savedData, offerType } = props;

  const { validityChangeHandler } = useOfferFormValidity();

  const [offerData, setOfferData] = useState<OfferBogoDto | null>(
    offerTemplate?.template as OfferBogoDto
  );

  const [i18n] = useI18n();
  const [configureCartRules, setConfigureCartRules] = useState<boolean>(false);
  const [configureBogo, setConfigureBogo] = useState<boolean>(false);
  const [ruleIndex, setRuleIndex] = useState<number>(0);
  const [groupIndex, setGroupIndex] = useState<number>(0);
  const [showHowModal, setShowHowModal] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const isDebugOrLocal = useIsDebugOrLocal();
  const navigate = useNavigate();

  const toggleShowHowModal = useCallback(() => {
    setShowHowModal((prevState: boolean) => !prevState);
  }, [setShowHowModal]);

  const productAmount = useMemo(() => {
    const currentSpecification = !isEmpty(offerData?.specification)
      ? offerData?.specification
      : (offerTemplate?.template as OfferBogoDto)?.specification;
    switch (currentSpecification?.type) {
      case OfferBogoTypeDtoEnum.ONE_PLUS_ONE:
        return 2;
      case OfferBogoTypeDtoEnum.ONE_PLUS_HALF:
        return 2;
      case OfferBogoTypeDtoEnum.THREE_FOR_TWO:
        return 3;
      case OfferBogoTypeDtoEnum.CUSTOM:
        return currentSpecification?.customRule?.requiredAmount;
      default:
        return 2;
    }
  }, [offerData?.specification, offerTemplate?.template]);

  const handleSpecificationDataUpdate = useCallback(
    (specification: OfferBogoSpecificationDto) => {
      setOfferData((prevState: OfferBogoDto | null) => ({
        ...prevState,
        specification: specification,
      }));
    },
    [setOfferData]
  );

  const cartRulesUpdate = useCallback(
    (data: OfferCartRulesDto) => {
      setOfferData((prevState: OfferBogoDto | null) => ({
        ...prevState,
        cartRules: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const combinationsUpdate = useCallback(
    (data: OfferCombinationsDto) => {
      setOfferData((prevState: OfferBogoDto | null) => ({
        ...prevState,
        combinations: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const minimumSpendUpdate = useCallback(
    (data: OptionDtoOfferMinimumSpendDto) => {
      setOfferData((prevState: OfferBogoDto | null) => ({
        ...prevState,
        minimumSpend: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const limitUpdateData = useCallback(
    (data: OptionDtoOfferDiscountLimitDto) => {
      setOfferData((prevState: OfferBogoDto | null) => ({
        ...prevState,
        discountLimit: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  useEffect(() => {
    if (
      offerTemplate?.template &&
      offerData &&
      !isEqual(offerData, offerTemplate?.template)
    ) {
      handleOfferDataUpdate(offerData);
    }
  }, [offerData, offerTemplate]);

  return (
    <>
      {configureCartRules && (
        <CartRules
          productAmount={productAmount as number}
          savedData={(savedData?.template as OfferBogoDto)?.cartRules}
          onFormValidityChange={validityChangeHandler(
            RegularBogoOfferTabsEnum.CartRules
          )}
          onCartRulesChange={cartRulesUpdate}
          setConfigureComponent={setConfigureCartRules}
          configureComponent={configureCartRules}
          specialOfferType={
            offerType === OfferTypeDtoEnum.BOGO_REGULAR
              ? OfferTypeSpecialCases.BogoMixMatch
              : OfferTypeSpecialCases.BogoStrictMatch
          }
          groupIndex={groupIndex}
          ruleIndex={ruleIndex}
          setRuleIndex={setRuleIndex}
          rulesSaved={offerData?.cartRules || {}}
          setGroupIndex={setGroupIndex}
          setIsChanged={setIsChanged}
          isChanged={isChanged}
        />
      )}
      {configureBogo && (
        <BogoOfferSection
          specification={
            (offerTemplate?.template as OfferBogoDto)?.specification || {}
          }
          onFormValidityChange={validityChangeHandler(
            RegularBogoOfferTabsEnum.Offer
          )}
          onSpecificationUpdate={handleSpecificationDataUpdate}
          savedData={(savedData?.template as OfferBogoDto)?.specification}
          setConfigureComponent={setConfigureBogo}
          configureComponent={configureBogo}
        />
      )}
      {!configureCartRules && !configureBogo && (
        <Page
          title={i18n.translate(`ConfigureOffer`)}
          backAction={{
            content: i18n.translate('CreateOffer'),
            onAction: async () => {
              !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
              navigate('../..', { relative: 'path' });
            },
          }}
        >
          <BlockStack gap='600'>
            <OfferTemplate
              type={offerType}
              toggleShowHowModal={toggleShowHowModal}
            />
            <BogoOfferSection
              specification={
                (offerTemplate?.template as OfferBogoDto)?.specification || {}
              }
              onFormValidityChange={validityChangeHandler(
                RegularBogoOfferTabsEnum.Offer
              )}
              onSpecificationUpdate={handleSpecificationDataUpdate}
              savedData={(savedData?.template as OfferBogoDto)?.specification}
              setConfigureComponent={setConfigureBogo}
              configureComponent={configureBogo}
            />
            <CartRules
              productAmount={productAmount as number}
              savedData={(savedData?.template as OfferBogoDto)?.cartRules}
              onFormValidityChange={validityChangeHandler(
                RegularBogoOfferTabsEnum.CartRules
              )}
              onCartRulesChange={cartRulesUpdate}
              setConfigureComponent={setConfigureCartRules}
              configureComponent={configureCartRules}
              specialOfferType={
                offerType === OfferTypeDtoEnum.BOGO_REGULAR
                  ? OfferTypeSpecialCases.BogoMixMatch
                  : OfferTypeSpecialCases.BogoStrictMatch
              }
              groupIndex={groupIndex}
              ruleIndex={ruleIndex}
              setRuleIndex={setRuleIndex}
              rulesSaved={offerData?.cartRules || {}}
              setGroupIndex={setGroupIndex}
              setIsChanged={setIsChanged}
              isChanged={isChanged}
            />
            <DiscountLimits
              type='Discount'
              discountLimit={
                (offerTemplate?.template as OfferBogoDto)?.discountLimit
              }
              discountLimitUpdate={limitUpdateData}
            />
            <Combinations onCombinationsChange={combinationsUpdate} />
            {offerTemplate?.minimumSpendSupported && (
              <MinimumSpend
                minimumSpend={
                  (offerTemplate?.template as OfferBogoDto)?.minimumSpend || {}
                }
                minimumSpendUpdate={minimumSpendUpdate}
              />
            )}
            {showHowModal && (
              <LearnMoreModal
                onModalClose={toggleShowHowModal}
                // url='https://youtu.be/9htdypfdlBM'
                title={i18n.translate('Tutorial')}
              />
            )}
          </BlockStack>
        </Page>
      )}
    </>
  );
};
