import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from 'react-zoom-pan-pinch';
import classNames from 'classnames';
import './DevicePreview.scss';
import { PREVIEW_ZOOM_MAX } from './const';
import { MockupPreviewTemplate } from './components/MockupPreviewTemplate/MockupPreviewTemplate';
import { debounce } from 'lodash';
import { usePrevious } from 'core/hooks/usePrevious';
import {
  DeviceTypeDtoEnum,
  PageTypeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import { EditPresetTypeEnum } from '../EditPresetSkeleton/enums/enums';
import { useI18n } from '@shopify/react-i18n';

type PreviewTransformProps = {
  x: number | null;
  y: number | null;
  scale: number;
};

type DevicePreviewProps = {
  page: PageTypeDtoEnum;
  previewType: DeviceTypeDtoEnum;
  handleZoomChange(value: number): void;
  handleSwitchWidgetState(widgetName: string, entries: any[]): void;
  zoomValue: number;
  data: any;
  defaultLanguage?: string;
  adminActivePath: string;
  getSelectionFromThePreview(path: string): void;
  currentHeight: number;
  currentInitialZoom: number;
  skeletonType: EditPresetTypeEnum;
  setInitialZoom: React.Dispatch<
    React.SetStateAction<{
      MOBILE_INITIAL_SCALE: number;
      DESKTOP_INITIAL_SCALE: number;
    }>
  >;
};

type DeviceTransformProps = {
  MOBILE: PreviewTransformProps;
  DESKTOP: PreviewTransformProps;
};

export const DevicePreview: React.FC<DevicePreviewProps> = (props) => {
  const {
    data,
    previewType,
    zoomValue,
    handleZoomChange,
    handleSwitchWidgetState,
    defaultLanguage,
    adminActivePath,
    getSelectionFromThePreview,
    page,
    currentHeight,
    currentInitialZoom,
    setInitialZoom,
    skeletonType,
  } = props;

  const [i18n] = useI18n();

  const transformComponentRefs = useRef<ReactZoomPanPinchRef[]>([]);
  const contentRefs = useRef<(HTMLDivElement | null)[]>([]);
  const wrapperRefs = useRef<(HTMLDivElement | null)[]>([]);

  const prevZoomValue = usePrevious(zoomValue);

  const [isPanning, setIsPanning] = useState<boolean>(false);
  const [isZooming, setIsZooming] = useState<boolean>(false);
  const [savedTransformState, setSavedTransformState] =
    useState<DeviceTransformProps>({
      MOBILE: {
        x: null,
        y: null,
        scale: 0,
      },
      DESKTOP: {
        x: null,
        y: null,
        scale: 0,
      },
    });
  const [keyInit, setKeyInit] = useState<number>(0);
  const [shouldRescale, setShouldRescale] = useState<number>(0);
  const [currentTransformComponentIndex, setCurrentTransformComponentIndex] =
    useState<number>(0);

  const transformState = useMemo(
    () =>
      transformComponentRefs.current?.[currentTransformComponentIndex]?.instance
        .transformState,
    [
      transformComponentRefs.current?.[currentTransformComponentIndex]?.instance
        .transformState,
    ]
  );

  const presetPriceWidget = useMemo(
    () =>
      !!(
        skeletonType === EditPresetTypeEnum.PRESET_EDITOR &&
        data?.currentWidgetName?.toLowerCase()?.includes('price')
      ),
    [data?.currentWidgetName, skeletonType]
  );

  const otherTransformComponentIndex = useMemo(
    () =>
      presetPriceWidget
        ? currentTransformComponentIndex === 0
          ? 1
          : 0
        : undefined,
    [currentTransformComponentIndex, presetPriceWidget]
  );

  const wrapperHeight = useMemo(
    () => (presetPriceWidget ? currentHeight / 2 - 18 : currentHeight),
    [presetPriceWidget, currentHeight]
  );

  const handleTransformChange = useCallback(
    debounce((x: number, y: number, scale: number) => {
      setSavedTransformState((prevState: DeviceTransformProps) => ({
        ...prevState,
        [previewType]: { x, y, scale },
      }));
      handleZoomChange(scale);
    }, 250),
    [previewType, handleZoomChange]
  );

  const handleManualZoomChange = useCallback(() => {
    setKeyInit((prevKey) => prevKey + 1);
  }, []);

  const fitContentToWrapper = useCallback(
    (centerView: (scale: number) => void, idx?: number) => {
      if (
        wrapperRefs.current[idx || 0] &&
        contentRefs.current[idx || 0] &&
        previewType
      ) {
        const scalePercentage = presetPriceWidget ? 1 : 0.9;

        const wrapperWidth = wrapperRefs.current[idx || 0]?.clientWidth || 0;
        const wrapperHeight = wrapperRefs.current[idx || 0]?.clientHeight || 0;

        const contentWidth = contentRefs.current[idx || 0]?.clientWidth || 0;
        const contentHeight = contentRefs.current[idx || 0]?.clientHeight || 0;

        const widthScale = (wrapperWidth / contentWidth) * scalePercentage;
        const heightScale = (wrapperHeight / contentHeight) * scalePercentage;

        const scale = widthScale < heightScale ? widthScale : heightScale;

        centerView(scale);
        setInitialZoom((prev) => ({
          ...prev,
          [previewType === DeviceTypeDtoEnum.DESKTOP
            ? 'DESKTOP_INITIAL_SCALE'
            : 'MOBILE_INITIAL_SCALE']: scale,
        }));
      }
    },
    [previewType, presetPriceWidget]
  );

  // load previously saved zoom/pan state of a device
  useEffect(() => {
    transformComponentRefs.current.forEach((ref, idx) => {
      if (ref) {
        const { setTransform, centerView } = ref;
        if (
          typeof savedTransformState[previewType].x !== 'number' ||
          typeof savedTransformState[previewType].y !== 'number'
        ) {
          fitContentToWrapper(centerView, idx);
        } else {
          setTransform(
            savedTransformState[previewType].x || 0,
            savedTransformState[previewType].y || 0,
            savedTransformState[previewType].scale
          );
          handleZoomChange(savedTransformState[previewType].scale);
        }
      }
    });
  }, [previewType, shouldRescale, handleZoomChange, fitContentToWrapper]);

  // rescale if presetPriceWidget
  useEffect(() => {
    setSavedTransformState({
      MOBILE: {
        x: null,
        y: null,
        scale: 0,
      },
      DESKTOP: {
        x: null,
        y: null,
        scale: 0,
      },
    });
    setShouldRescale((prevKey) => prevKey + 1);
  }, [presetPriceWidget]);

  // change device zoom/pan state
  useEffect(() => {
    handleTransformChange(
      transformState?.positionX || 0,
      transformState?.positionY || 0,
      transformState?.scale || 0
    );
    if (
      typeof otherTransformComponentIndex === 'number' &&
      transformComponentRefs.current[otherTransformComponentIndex]
    ) {
      const { setTransform } =
        transformComponentRefs.current[otherTransformComponentIndex];
      setTransform(
        transformState?.positionX || 0,
        transformState?.positionY || 0,
        transformState?.scale || 0
      );
    }
  }, [
    isZooming,
    isPanning,
    keyInit,
    transformState?.positionX,
    transformState?.positionY,
    transformState?.scale,
    transformState?.previousScale,
    handleTransformChange,
  ]);

  // change zoom using range slider
  useEffect(() => {
    if (
      zoomValue !== savedTransformState[previewType].scale &&
      transformComponentRefs.current[currentTransformComponentIndex || 0]
    ) {
      const factor = Math.log(zoomValue / prevZoomValue);
      const { zoomIn, zoomOut } =
        transformComponentRefs.current[currentTransformComponentIndex || 0];
      if (zoomValue > prevZoomValue) {
        zoomIn(factor, 0);
        handleManualZoomChange();
      } else {
        zoomOut(-factor, 0);
        handleManualZoomChange();
      }
    }
  }, [zoomValue]);

  const transformWrapperRender = useCallback(
    (idx?: number) => {
      const priceWidgetType =
        typeof idx === 'number'
          ? idx
            ? 'notDiscounted'
            : 'discounted'
          : undefined;

      return (
        <TransformWrapper
          ref={(ref) => {
            if (ref)
              transformComponentRefs.current[idx ?? 0] =
                ref as ReactZoomPanPinchRef;
          }}
          limitToBounds={false}
          minScale={currentInitialZoom}
          maxScale={currentInitialZoom * PREVIEW_ZOOM_MAX}
          onPanningStart={() => {
            setIsPanning(true);
            priceWidgetType &&
              typeof idx === 'number' &&
              setCurrentTransformComponentIndex(idx);
          }}
          onPanningStop={() => setIsPanning(false)}
          onZoomStart={() => {
            setIsZooming(true);
            priceWidgetType &&
              typeof idx === 'number' &&
              setCurrentTransformComponentIndex(idx);
          }}
          onZoomStop={() => setIsZooming(false)}
          doubleClick={{
            excluded: [
              'product-banner-root',
              'la-dn-product-banner',
              'la-dn-product-banner-message',
              'la-dn-product-banner-label',
              'promotional-badge-root',
              'la-dn-promotional-badge',
              'la-dn-promotional-badge-message',
              'la-dn-promotional-badge-line',
              'announcement-bar-root',
              'la-dn-announcement-bar',
              'la-dn-announcement-bar-row',
              'la-dn-announcement-bar-slot',
              'la-dn-announcement-bar-message',
              'notifications-root',
              'la-dn-notification',
              'la-dn-notification-launcher',
              'la-dn-notification-headline',
              'la-dn-notification-headline-icon',
              'la-dn-notification-headline-text',
              'la-dn-notification-header',
              'la-dn-notification-body',
              'la-dn-notification-message',
              'la-dn-notification-footer',
              'la-dn-notification-footer-text',
              'la-dn-notification-footer-clock',
              'la-dn-promotion-summary',
              'la-dn-promotion-summary-total',
              'la-dn-promotion-summary-breakdown',
              'la-dn-promotion-summary-subtotal',
              'la-dn-promotion-summary-discounts',
              'la-dn-promotion-summary-discount',
              'la-dn-promotion-summary-taxes',
              'la-dn-promotion-summary-shipping',
              'la-dn-promotion-summary-disclaimer',
              'la-dn-promotion-code-field',
              'la-dn-promotion-code-field-headline',
              'la-dn-promotion-code-field-input',
              'la-dn-promotion-code-field-codes',
              'la-dn-offer-rules-popup',
              'la-dn-offer-rules-popup-content',
              'la-dn-offer-rules-popup-close-wrapper',
              'la-dn-offer-rules-popup-title',
              'la-dn-offer-rules-popup-message',
              'la-dn-offer-rules-popup-cta-button',
              'la-dn-price',
              'la-dn-cart-item-text',
              'la-dn-discount-label',
              'la-dn-ninja-cart',
            ],
          }}
          panning={{
            excluded: [
              'product-banner-root',
              'la-dn-product-banner',
              'la-dn-product-banner-message',
              'la-dn-product-banner-label',
              'promotional-badge-root',
              'la-dn-promotional-badge',
              'la-dn-promotional-badge-message',
              'la-dn-promotional-badge-line',
              'announcement-bar-root',
              'la-dn-announcement-bar',
              'la-dn-announcement-bar-row',
              'la-dn-announcement-bar-slot',
              'la-dn-announcement-bar-message',
              'notifications-root',
              'la-dn-notification',
              'la-dn-notification-launcher',
              'la-dn-notification-headline',
              'la-dn-notification-headline-icon',
              'la-dn-notification-headline-text',
              'la-dn-notification-header',
              'la-dn-notification-body',
              'la-dn-notification-message',
              'la-dn-notification-footer',
              'la-dn-notification-footer-text',
              'la-dn-notification-footer-clock',
              'la-dn-promotion-summary',
              'la-dn-promotion-summary-total',
              'la-dn-promotion-summary-breakdown',
              'la-dn-promotion-summary-subtotal',
              'la-dn-promotion-summary-discounts',
              'la-dn-promotion-summary-discount',
              'la-dn-promotion-summary-taxes',
              'la-dn-promotion-summary-shipping',
              'la-dn-promotion-summary-disclaimer',
              'la-dn-promotion-code-field',
              'la-dn-promotion-code-field-headline',
              'la-dn-promotion-code-field-input',
              'la-dn-promotion-code-field-codes',
              'la-dn-offer-rules-popup',
              'la-dn-offer-rules-popup-content',
              'la-dn-offer-rules-popup-close-wrapper',
              'la-dn-offer-rules-popup-title',
              'la-dn-offer-rules-popup-message',
              'la-dn-offer-rules-popup-cta-button',
              'la-dn-price',
              'la-dn-cart-item-text',
              'la-dn-discount-label',
              'la-dn-ninja-cart',
            ],
          }}
        >
          <TransformComponent
            wrapperClass={classNames('DevicePreviewWrapper', {
              IsPan: isPanning,
              IsPriceWidgetType: priceWidgetType,
            })}
            contentClass={classNames('DevicePreviewContent', {
              Active: priceWidgetType,
            })}
            wrapperStyle={{ height: wrapperHeight }}
          >
            <div ref={(ref) => (contentRefs.current[idx ?? 0] = ref)}>
              <MockupPreviewTemplate
                previewType={previewType}
                data={data}
                defaultLanguage={defaultLanguage}
                adminActivePath={adminActivePath}
                getSelectionFromThePreview={getSelectionFromThePreview}
                handleSwitchWidgetState={handleSwitchWidgetState}
                page={page}
                priceWidgetType={priceWidgetType}
              />
            </div>
          </TransformComponent>
        </TransformWrapper>
      );
    },
    [
      wrapperHeight,
      currentInitialZoom,
      data,
      defaultLanguage,
      adminActivePath,
      getSelectionFromThePreview,
      handleSwitchWidgetState,
      isPanning,
      page,
      previewType,
      PREVIEW_ZOOM_MAX,
    ]
  );

  return presetPriceWidget ? (
    <div className='presetPriceWidgetWrapper'>
      {Array.from({ length: 2 }, (_, index) => index + 1).map((_, idx) => (
        <div
          key={idx}
          ref={(ref) => (wrapperRefs.current[idx] = ref)}
          style={{ position: 'relative' }}
        >
          <div className='presetPriceWidgetTitle'>
            {i18n.translate(idx ? 'notDiscountedPrice' : 'discountedPrice')}
          </div>
          {transformWrapperRender(idx)}
        </div>
      ))}
    </div>
  ) : (
    <div ref={(ref) => (wrapperRefs.current[0] = ref)}>
      {transformWrapperRender()}
    </div>
  );
};
