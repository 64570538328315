import React from 'react';
import { Button, LegacyCard, LegacyStack } from '@shopify/polaris';
import DataGroup from './DataGroup';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  billIconLookup,
  cartIconLookup,
  creditCardIconLookup,
  eyeIconLookup,
  handshakeIconLookup,
  insightsIconLookup,
} from 'core/components/iconsDefinitions';

const Insights: React.FunctionComponent = () => {
  const insightsIconDef = findIconDefinition(insightsIconLookup);
  const insightsIcon = <FontAwesomeIcon icon={insightsIconDef} />;

  return (
    <LegacyCard>
      <LegacyCard.Section>
        <LegacyStack distribution='equalSpacing' alignment='trailing'>
          <DataGroup
            iconLookup={billIconLookup}
            title='revenue generated'
            mainData={0}
          />
          <DataGroup
            iconLookup={eyeIconLookup}
            title='sessions'
            mainData={96}
            volume={-14.29}
          />
          <DataGroup
            iconLookup={cartIconLookup}
            title='carts'
            mainData={20}
            percentageOfSessions={20.83}
            volume={-37.5}
            conversion={-7.74}
          />
          <DataGroup
            iconLookup={handshakeIconLookup}
            title='reached checkout'
            mainData={6}
            percentageOfSessions={6.25}
            volume={0}
            conversion={6.25}
          />
          <DataGroup
            iconLookup={creditCardIconLookup}
            title='orders'
            mainData={0}
            percentageOfSessions={0}
          />
          <LegacyStack wrap>
            <Button icon={insightsIcon}>Discover more insights</Button>
          </LegacyStack>
        </LegacyStack>
      </LegacyCard.Section>
    </LegacyCard>
  );
};
export default Insights;
