import React from 'react';
import {
  BlockStack,
  Box,
  Button,
  Card,
  Icon,
  InlineStack,
  SkeletonBodyText,
  Text,
  Tooltip,
} from '@shopify/polaris';
import { SearchResourceIcon, InfoIcon } from '@shopify/polaris-icons';
import { ChartState, DataSeries, LineChart } from '@shopify/polaris-viz';
import '@shopify/polaris-viz/build/esm/styles.css';
import { AnalyticsDataDto } from 'core/api/adminAnalytics/adminAnalyticsApi';
import { TrendIndicator } from 'core/components';

type AnalyticsLineChartProps = {
  title: string;
  tooltip?: string;
  analyticsData: AnalyticsDataDto;
  isLoading?: boolean;
  onNavigate?: () => void;
};
export const AnalyticsLineChart: React.FC<AnalyticsLineChartProps> = ({
  title,
  tooltip,
  isLoading,
  analyticsData,
  onNavigate,
}) => {
  return (
    <Card>
      <BlockStack gap='300'>
        <InlineStack align='space-between' blockAlign='center'>
          <InlineStack gap='200'>
            <Text tone='base' as='h1' fontWeight='medium'>
              {title}
            </Text>
            {tooltip && (
              <Tooltip content={tooltip}>
                <Icon tone='base' source={InfoIcon} />
              </Tooltip>
            )}
          </InlineStack>
          {onNavigate && (
            <Button
              onClick={onNavigate}
              icon={SearchResourceIcon}
              variant='tertiary'
            />
          )}
        </InlineStack>
        {!isLoading ? (
          <InlineStack blockAlign='center' gap='200'>
            {typeof analyticsData.totalValue === 'number' && (
              <Text
                variant='headingLg'
                tone='base'
                as='h1'
                fontWeight='semibold'
              >
                ${analyticsData.totalValue}
              </Text>
            )}
            {typeof analyticsData.totalValue === 'number' && (
              <TrendIndicator value={analyticsData.trendValue as number} />
            )}
          </InlineStack>
        ) : (
          <Box width={'100px'} minHeight='22px'>
            <SkeletonBodyText lines={1} />
          </Box>
        )}

        <div style={{ height: 250 }}>
          <LineChart
            state={(isLoading ? 'Loading' : 'Success') as ChartState}
            data={(analyticsData.dataSeries as DataSeries[]) || []}
            tooltipOptions={{
              valueFormatter: (value) => `$${value}`,
            }}
            yAxisOptions={{
              labelFormatter: (value) => `$${value}`,
            }}
          />
        </div>
      </BlockStack>
    </Card>
  );
};
