import React, { useMemo } from 'react';
import { useI18n } from '@shopify/react-i18n';
import {
  OfferPrerequisiteEntitledCompareAtPriceBehaviorDtoEnum,
  OfferPrerequisiteEntitledPurchaseTypeDtoEnum,
  OfferTargetTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { OfferPrerequisiteEntitledAdvancedSettingsDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { BlockStack, Box, Card, InlineStack, Text } from '@shopify/polaris';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';

export type AdvancedSettingsProps = {
  type: OfferTargetTypeDtoEnum;
  data?: OfferPrerequisiteEntitledAdvancedSettingsDto;
  handleUpdateAdvancedSettings: (
    field: keyof OfferPrerequisiteEntitledAdvancedSettingsDto,
    value: string
  ) => void;
};

export const AdvancedSettings: React.FC<AdvancedSettingsProps> = (props) => {
  const { type, data, handleUpdateAdvancedSettings } = props;

  const [i18n] = useI18n();

  const purchaseTypeOptions = useMemo(
    () =>
      Object.values(OfferPrerequisiteEntitledPurchaseTypeDtoEnum).map(
        (value) => {
          return {
            value: value,
            label: i18n.translate(value),
          };
        }
      ),
    []
  );

  const compareAtPriceOptions = useMemo(
    () =>
      Object.values(OfferPrerequisiteEntitledCompareAtPriceBehaviorDtoEnum).map(
        (value) => {
          return {
            value: value,
            label: i18n.translate(value),
          };
        }
      ),
    []
  );

  return (
    <Card>
      <BlockStack gap='400'>
        <Text as='h2' variant='headingSm'>
          {i18n.translate('AdvancedSettings')}
        </Text>
        <InlineStack gap='600' wrap={false}>
          <Box
            width={type !== OfferTargetTypeDtoEnum.VARIANTS ? '100%' : '50%'}
          >
            <SelectOptions
              options={purchaseTypeOptions}
              onOptionSelect={(value) =>
                handleUpdateAdvancedSettings('purchaseType', value)
              }
              selectedOption={
                data?.purchaseType as OfferPrerequisiteEntitledPurchaseTypeDtoEnum
              }
              label={i18n.translate('PurchaseType')}
            />
          </Box>
          {type !== OfferTargetTypeDtoEnum.VARIANTS && (
            <Box width='100%'>
              <SelectOptions
                options={compareAtPriceOptions}
                onOptionSelect={(value) =>
                  handleUpdateAdvancedSettings('compareAtPrice', value)
                }
                selectedOption={
                  data?.compareAtPrice as OfferPrerequisiteEntitledCompareAtPriceBehaviorDtoEnum
                }
                label={i18n.translate('CompareAtPrice')}
              />
            </Box>
          )}
        </InlineStack>
      </BlockStack>
    </Card>
  );
};
