import React, { useMemo } from 'react';
import { SelectedOptionPath } from '../../../../RightSideBarWrapper';
import {
  NotificationPresetDto,
  NotificationPresetDtoRead,
  NotificationSystemDefaultsDto,
} from 'core/api/adminWidgets/adminWidgetsApi';

import { NotificationStatesPromotionEntryTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import PresetNotificationExpanded from './components/PresetNotificationExpanded/PresetNotificationExpanded';
import PresetNotificationCollapsed from './components/PresetNotificationCollapsed/PresetNotificationCollapsed';

type PresetNotificationProps = {
  selectedOptionPath: SelectedOptionPath;
  notification: NotificationPresetDtoRead;
  defaultPresetData: NotificationPresetDtoRead;
  shopDefaultData: NotificationSystemDefaultsDto;
  defaultLanguage: string;
  updateNotification: (data: NotificationPresetDto) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const PresetNotification: React.FC<PresetNotificationProps> = ({
  selectedOptionPath,
  notification,
  defaultPresetData,
  shopDefaultData,
  defaultLanguage,
  updateNotification,
  handleLeftMenuSelection,
}) => {
  const notificationOptions = useMemo(() => {
    switch (true) {
      case notification?.currentEntries?.includes(
        NotificationStatesPromotionEntryTypeDtoEnum.EXPANDED_STATE
      ):
        return (
          <PresetNotificationExpanded
            selectedOptionPath={selectedOptionPath}
            notification={notification}
            defaultPresetData={defaultPresetData}
            shopDefaultData={shopDefaultData}
            defaultLanguage={defaultLanguage}
            updateNotification={updateNotification}
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case notification?.currentEntries?.includes(
        NotificationStatesPromotionEntryTypeDtoEnum.COLLAPSED_STATE
      ):
        return (
          <PresetNotificationCollapsed
            selectedOptionPath={selectedOptionPath}
            notification={notification}
            defaultPresetData={defaultPresetData}
            shopDefaultData={shopDefaultData}
            defaultLanguage={defaultLanguage}
            updateNotification={updateNotification}
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
    }
  }, [
    selectedOptionPath,
    shopDefaultData,
    notification,
    defaultPresetData,
    defaultLanguage,
    updateNotification,
    handleLeftMenuSelection,
  ]);
  return <div>{notificationOptions}</div>;
};

export default PresetNotification;
