import { useState } from 'react';
import resolveEnvVar from 'env-var-resolver';
import { setToastMessages } from 'core/store/offersWizardSlice';
import { useAppDispatch } from 'core/hooks';

const exportSettingsURL = `${resolveEnvVar(
  'REACT_APP_SETTINGS_ADMIN_API_URL'
)}/settings/v6/export`;

const useExportSettings = () => {
  const dispatch = useAppDispatch();
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || '';
  const [exportSettingsIsFetching, setExportSettingsIsFetching] =
    useState<boolean>(false);

  const exportSettings = async () => {
    setExportSettingsIsFetching(true);
    const response = await fetch(`${exportSettingsURL}`, {
      method: 'GET',
      headers: {
        'X-LimoniApps-AppName': 'DiscountNinja',
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((data) => {
        data.blob().then((myBlob) => {
          const url = window.URL.createObjectURL(new Blob([myBlob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `discountninja-settings.dnsettings`);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode?.removeChild(link);
        });
      })
      .catch(() => {
        dispatch(
          setToastMessages({
            error: true,
            message: 'ExportSettingsFailed',
          })
        );
      })
      .finally(() => setExportSettingsIsFetching(false));
  };

  return {
    exportSettings,
    exportSettingsIsFetching,
  };
};

export default useExportSettings;
