import { useGetPromotionsV6LookupShippingCountriesQuery } from 'core/api/adminPromotions/adminPromotionsApi';
import resolveEnvVar from 'env-var-resolver';

const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';
export const useShippingCountries = () => {
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';
  const {
    data: shippingCountriesListData,
    isLoading: shippingCountriesListIsLoading,
  } = useGetPromotionsV6LookupShippingCountriesQuery({
    'X-LimoniApps-AppName': APP_NAME,
    'X-LimoniApps-AppSecret': APP_PASSWORD,
  });

  return { shippingCountriesListData, shippingCountriesListIsLoading };
};
