import { Range } from '@shopify/polaris';
import { addDays } from 'date-fns';
import { useState } from 'react';

export const useDateRange = (delay?: number) => {
  const [dateNow] = useState<Date>(new Date());
  const [dateRange, setDateRange] = useState<Range>({
    start: addDays(dateNow, delay || -7),
    end: dateNow,
  });

  return { dateRange, dateNow, setDateRange };
};
