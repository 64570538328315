import React from 'react';
import StyleComponentHeader from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components/StyleComponentHeader/StyleComponentHeader';
import { useI18n } from '@shopify/react-i18n';
import { Banner, ChoiceList } from '@shopify/polaris';
import { ComboboxOption } from 'core/components';

type SingleRadioSelectionProps = {
  options: ComboboxOption[];
  label: string;
  selectedOption: string;
  disabled?: boolean;
  bannerContent?: JSX.Element;
  noteContent?: JSX.Element;
  inherited?: boolean;
  setOption?: (value: string) => void;
  onSystemReset?: () => void;
};
export const SingleRadioSelection: React.FC<SingleRadioSelectionProps> = ({
  options,
  selectedOption,
  label,
  disabled,
  bannerContent,
  noteContent,
  inherited,
  setOption,
  onSystemReset,
}) => {
  const [i18n] = useI18n();
  return (
    <div className='SingleRadioSelection'>
      <StyleComponentHeader
        inherited={inherited}
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            disabled: disabled,
            onAction: () => onSystemReset?.(),
          },
        ]}
        label={label}
      >
        <ChoiceList
          title=''
          choices={options}
          disabled={disabled}
          selected={[selectedOption]}
          onChange={(value) => setOption?.(value[0])}
        />

        {bannerContent && <Banner>{bannerContent}</Banner>}
        {noteContent && noteContent}
      </StyleComponentHeader>
    </div>
  );
};
