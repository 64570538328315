import React, { useCallback, useMemo } from 'react';
import { useI18n } from '@shopify/react-i18n';
import {
  Text,
  Checkbox,
  Tooltip,
  Card,
  BlockStack,
  Icon,
  InlineStack,
} from '@shopify/polaris';
import { QuestionCircleIcon } from '@shopify/polaris-icons';
import { ProductsExclusionsEnum } from '../../enums/ProductsExclusionsEnum';
import {
  SearchFieldWithGrayBox,
  GrayBoxResourceTypeEnum,
  ResourceSelectionProps,
} from 'core/components/SearchFieldWithGrayBoxOfferWizard';
import {
  OfferTargetTypeDto,
  ShopifyObjectDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { OfferTargetTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { ResourcePickerOptions, ResourceSelection } from 'core/api/appBridge';

type GrayBoxCollectionsExclusionsProps = {
  selectList: ShopifyObjectDto[];
  selectedProductsApplication: OfferTargetTypeDto;
  enable: boolean;
  onCheckboxValueChange: (value: boolean) => void;
  onExcludedResourceSelection(
    data: ResourceSelection<ResourcePickerOptions['type']>[]
  ): void;
  onFormValidityChange(formIsInvalid: boolean): void;
};

export const GrayBoxCollectionsExclusions: React.FC<
  GrayBoxCollectionsExclusionsProps
> = (props) => {
  const {
    selectList,
    selectedProductsApplication,
    enable,
    onCheckboxValueChange,
    onExcludedResourceSelection,
    onFormValidityChange,
  } = props;
  const [i18n] = useI18n();

  const handleOnSelectionChange = useCallback(
    (list: ResourceSelection<ResourcePickerOptions['type']>[]) => {
      onExcludedResourceSelection(list);
    },
    [onExcludedResourceSelection]
  );

  const onSearchFieldValidityChange = useCallback(
    (formIsInvalid: boolean) => {
      onFormValidityChange(formIsInvalid);
    },
    [onFormValidityChange]
  );

  const isColectionActive = useMemo(
    () =>
      selectedProductsApplication === OfferTargetTypeDtoEnum.ALL ||
      selectedProductsApplication === OfferTargetTypeDtoEnum.COLLECTIONS,
    [selectedProductsApplication]
  );

  const grayBox = useMemo(
    () =>
      enable &&
      isColectionActive && (
        <SearchFieldWithGrayBox
          resourceType={GrayBoxResourceTypeEnum.Collection}
          selectList={selectList as unknown as ResourceSelectionProps[]}
          onSelectedChange={handleOnSelectionChange}
          onFormValidityChange={onSearchFieldValidityChange}
          searchFieldType='Exclude'
        />
      ),
    [enable, isColectionActive, handleOnSelectionChange]
  );

  const exclusions = useMemo(
    () => (
      <Card>
        <BlockStack gap='400'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate('CheckboxGroupTitle') || 'Exclusions'}
          </Text>
          <BlockStack gap='0'>
            {Object.entries(ProductsExclusionsEnum)
              .filter(([_key, value]: [string, ProductsExclusionsEnum]) =>
                selectedProductsApplication ===
                  OfferTargetTypeDtoEnum.PRODUCTS ||
                selectedProductsApplication === OfferTargetTypeDtoEnum.VARIANTS
                  ? value !== ProductsExclusionsEnum.SpecificCollections
                  : true
              )
              .map(([key, value]: [string, ProductsExclusionsEnum]) => (
                <Checkbox
                  key={key}
                  label={
                    <InlineStack gap='100'>
                      <Text as='p'>
                        {`${i18n.translate(`${key}`) || value}`}
                      </Text>
                      <Tooltip
                        preferredPosition='mostSpace'
                        content={i18n.translate(`TooltipsContent.${key}`) || ''}
                      >
                        <Icon source={QuestionCircleIcon} />
                      </Tooltip>
                    </InlineStack>
                  }
                  checked={enable}
                  onChange={(value) => {
                    onCheckboxValueChange(value);
                    onFormValidityChange(false);
                  }}
                />
              ))}
          </BlockStack>
          {grayBox}
        </BlockStack>
      </Card>
    ),
    [selectedProductsApplication, i18n, enable, grayBox]
  );

  return exclusions;
};
