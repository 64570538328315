import React from 'react';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import { useI18n } from '@shopify/react-i18n';
import {
  BadgeStyleSectionDto,
  PromotionalBadgeBadgeStyleSetKeyDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import './Style.scss';
import {
  BadgeStyleShapeDtoEnum,
  BadgeStyleSizeDtoEnum,
  PromotionalBadgeBadgeStyleSetKeyDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';

type StyleProps = {
  style: BadgeStyleSectionDto;
  styleSet: PromotionalBadgeBadgeStyleSetKeyDto;
  inherited?: boolean;
  disabled?: boolean;
  onSystemReset: () => void;
  setStyleSet: (value: PromotionalBadgeBadgeStyleSetKeyDto) => void;
  setStyle: (value: BadgeStyleSectionDto) => void;
};
export const Style: React.FC<StyleProps> = ({
  style,
  disabled,
  styleSet,
  inherited,
  onSystemReset,
  setStyleSet,
  setStyle,
}) => {
  const [i18n] = useI18n();

  const styleSetOptions = Object.values(
    PromotionalBadgeBadgeStyleSetKeyDtoEnum
  ).map((value) => {
    return {
      value: value,
      label: i18n.translate(`${value}`),
    };
  });
  const shapeOptions = Object.values(BadgeStyleShapeDtoEnum).map((value) => {
    return {
      value: value,
      label: i18n.translate(`${value}`),
    };
  });
  const sizeOptions = Object.values(BadgeStyleSizeDtoEnum).map((value) => {
    return {
      value: value,
      label: i18n.translate(`${value}`),
    };
  });

  return (
    <div className='Style'>
      <StyleComponentHeader
        label={i18n.translate(`Style`)}
        inherited={inherited}
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            disabled: disabled,
            onAction: () => onSystemReset(),
          },
        ]}
      >
        {inherited !== undefined && (
          <div className='StyleRow'>
            {i18n.translate(`Style`)}
            <div className='RightContent'>
              <SelectOptions
                options={styleSetOptions}
                onOptionSelect={(value) =>
                  setStyleSet(value as PromotionalBadgeBadgeStyleSetKeyDtoEnum)
                }
                selectedOption={
                  styleSet || PromotionalBadgeBadgeStyleSetKeyDtoEnum.PRIMARY
                }
                label=''
              />
            </div>
          </div>
        )}
        <div className='StyleRow'>
          {i18n.translate(`Shape`)}
          <div className='RightContent'>
            <SelectOptions
              options={shapeOptions}
              onOptionSelect={(value) =>
                setStyle({ ...style, shape: value as BadgeStyleShapeDtoEnum })
              }
              selectedOption={style.shape || BadgeStyleShapeDtoEnum.CIRCLE}
              label=''
              disabled={disabled}
            />
          </div>
        </div>
        <div className='StyleRow'>
          {i18n.translate(`Size`)}
          <div className='RightContent'>
            <SelectOptions
              options={sizeOptions}
              onOptionSelect={(value) =>
                setStyle({ ...style, size: value as BadgeStyleSizeDtoEnum })
              }
              selectedOption={style.size || BadgeStyleSizeDtoEnum.MEDIUM}
              label=''
              disabled={disabled}
            />
          </div>
        </div>
      </StyleComponentHeader>
    </div>
  );
};
