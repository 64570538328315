import React, { useCallback, useEffect, useState } from 'react';
import './BrandColors.scss';
import { BlockStack, Card, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { StyleSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import { isEmpty, isEqual } from 'lodash';
import {
  setIsUnsavedChanges,
  setOnDiscardChanges,
  setOnSaveChanges,
} from 'core/store/settingsSlice';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from '../../../../../hooks/useConfigureSettings';
import { useDispatch } from 'react-redux';
import { Loader } from 'core/components';
import useFetchPresetList from 'features/settings/hooks/useFetchPresetList/useFetchPresetList';
import resolveEnvVar from 'env-var-resolver';
import { useAppSelector } from 'core/hooks';
import { ConfigureBrandColors } from './components/ConfigureBrandColors/ConfigureBrandColors';

const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';

export const BrandColors: React.FC = () => {
  const [i18n] = useI18n();
  const dispatch = useDispatch();

  const { fetchBrandColors, brandColorsIsFetching } = useFetchPresetList();

  const { brandColorsDataChange, brandColorsDataIsLoading } =
    useConfigureSettings(SettingsFetchTypeEnum.BRAND_COLORS);

  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';

  const { brandColors: brandColorsData } = useAppSelector(
    (state) => state.settings
  );

  const [brandColorsState, setBrandColorsState] = useState<StyleSettingsDto>(
    {}
  );
  const [savedData, setSavedData] = useState<StyleSettingsDto>({});
  const [refetchBrandColors, setRefetchBrandColors] = useState<boolean>(false);

  const handleFetchBrandColors = useCallback(async () => {
    await fetchBrandColors({
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    });
  }, [APP_PASSWORD, APP_NAME]);

  useEffect(() => {
    if (!isEmpty(brandColorsState) && !isEqual(savedData, brandColorsState)) {
      dispatch(setIsUnsavedChanges(true));
      dispatch(
        setOnDiscardChanges(() => {
          if (!isEmpty(savedData)) {
            setBrandColorsState(savedData);
            handleFetchBrandColors();
          }
        })
      );
      dispatch(
        setOnSaveChanges(() => {
          setSavedData(brandColorsState);
          return brandColorsDataChange(
            brandColorsState || {},
            setRefetchBrandColors
          );
        })
      );
    } else if (isEqual(savedData, brandColorsState)) {
      dispatch(setIsUnsavedChanges(false));
    }
  }, [brandColorsState, savedData, dispatch, handleFetchBrandColors]);

  useEffect(() => {
    if (refetchBrandColors) {
      setRefetchBrandColors(false);
      handleFetchBrandColors();
    }
  }, [refetchBrandColors]);

  useEffect(() => {
    if (!isEmpty(brandColorsData)) {
      setBrandColorsState(brandColorsData);
      setSavedData(brandColorsData);
    }
  }, [brandColorsData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  return (
    <Card roundedAbove='sm' padding='400'>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate('BrandColors')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('BrandColorsDescription')}
          </Text>
        </BlockStack>
        {!brandColorsIsFetching && !brandColorsDataIsLoading ? (
          <ConfigureBrandColors
            brandColors={brandColorsState.brandColors || []}
            setBrandColors={(data) =>
              setBrandColorsState({ brandColors: data })
            }
            bannerText={i18n.translate('BannerInfo')}
          />
        ) : (
          <BlockStack align='center' inlineAlign='center'>
            <Loader />
          </BlockStack>
        )}
      </BlockStack>
    </Card>
  );
};
