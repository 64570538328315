import {
  OfferCartRulesDto,
  OfferCombinationsDto,
  OfferLevelDto,
  OfferPrerequisiteEntitledExclusionsDto,
  OfferProductLimitDto,
  OfferPrerequisiteEntitledAppliesToDto,
  OfferTierDto,
  OfferTieredSpendXGetYDto,
  OptionDtoOfferDiscountLimitDto,
  OptionDtoOfferMinimumSpendDto,
  ShopifyObjectDto,
  OfferPrerequisiteEntitledNameFiltersDto,
  OptionDtoString,
  OfferPrerequisiteEntitledAdvancedSettingsDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  OfferTieredDiscountTypeDtoEnum,
  OfferTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { OfferTypeSpecialCases } from 'core/enums/GrayBoxEnum';
import { useAppDispatch, useIsDebugOrLocal } from 'core/hooks';
import {
  setCombinedWithList,
  setCurrentCombinationIsLoaded,
  setLastCombinedWithTypeFetched,
  setSelectedOfferType,
} from 'core/store/offersWizardSlice';
import { OfferTypesFromCatalogData } from '../../../consts/OfferTypesFromCatalogData';
import { useOfferFormValidity } from 'features/promotions/hooks/useOfferFormValidity';
import { usePrevious } from 'core/hooks/usePrevious';
import { cloneDeep, isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SpendXGetYTieredOfferTabsEnum } from '../../../enums/OfferTypeTabs';
import { Combinations } from '../../Combinations/Combinations';
import { Discount } from '../../DiscountNew/Discount';
import { Products } from '../../ProductsNew/Products';
import { Tiers } from '../../TiersNew/Tiers';
import { DiscountLimits } from '../../DiscountLimits/DiscountLimits';
import { BlockStack, Page } from '@shopify/polaris';
import { MinimumSpend } from '../../MinimumSpend/MinimumSpend';
import { useI18n } from '@shopify/react-i18n';
import { useNavigate } from 'react-router-dom';
import { OfferTemplate } from '../../OfferTemplate/OfferTemplate';
import { LearnMoreModal } from '../../LearnMoreModal/LearnMoreModal';
import { OfferTierUpdateDto } from '../types/OfferTierUpdateDto';
import { OfferParentComponentProps } from '../../../types/OfferTypesFromCatalogTypes';
import { CartRules } from '../../CartRules/CartRules';

export const SpendAmountOffer: React.FC<OfferParentComponentProps> = (
  props
) => {
  const { offerType, offerTemplate, handleOfferDataUpdate, savedData } = props;

  const [offerData, setOfferData] = useState<OfferTieredSpendXGetYDto | null>(
    offerTemplate?.template as OfferTieredSpendXGetYDto
  );
  const [currentTiers, setCurrentTiers] = useState<OfferTierDto[]>(
    (offerTemplate?.template as OfferTieredSpendXGetYDto)?.tiers || []
  );
  const [isTiersUpdated, setIsTiersUpdated] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const isDebugOrLocal = useIsDebugOrLocal();
  const { validityChangeHandler } = useOfferFormValidity();

  const prevTiers = usePrevious(currentTiers);

  const [i18n] = useI18n();

  const [showHowModal, setShowHowModal] = useState<boolean>(false);
  const [configureTiers, setConfigureTiers] = useState<boolean>(false);
  const [configureCartRules, setConfigureCartRules] = useState<boolean>(false);
  const [ruleIndex, setRuleIndex] = useState<number>(0);
  const [groupIndex, setGroupIndex] = useState<number>(0);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const [configureProducts, setConfigureProducts] = useState<boolean>(false);
  const navigate = useNavigate();

  const productAmount = useMemo(
    () =>
      currentTiers?.[0]?.requirementWholeValue ||
      currentTiers?.[0]?.requirementPreciseValue ||
      1,
    [currentTiers[0]]
  );

  const toggleShowHowModal = useCallback(() => {
    setShowHowModal((prevState: boolean) => !prevState);
  }, [setShowHowModal]);

  const discountTypeDataUpdate = useCallback(
    (type: OfferTieredDiscountTypeDtoEnum) => {
      const offer = OfferTypesFromCatalogData['TieredDiscounts'][0];

      offerType !== OfferTypeDtoEnum.TIERED_SPEND_X_GET_Y &&
        dispatch(setSelectedOfferType(offer));
      setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
        ...prevState,
        type: type,
      }));
    },
    [setOfferData, dispatch, offerType]
  );

  useEffect(() => {
    if (
      (offerTemplate?.template &&
        offerData &&
        !isEqual(offerData, offerTemplate?.template)) ||
      isTiersUpdated
    ) {
      handleOfferDataUpdate(offerData);
      setIsTiersUpdated(false);
    }
  }, [offerData, offerTemplate, isTiersUpdated]);

  useEffect(() => {
    const tempTiers = cloneDeep(currentTiers);
    if (
      offerData?.type === OfferTieredDiscountTypeDtoEnum.FIXED_AMOUNT &&
      currentTiers[0].entitledPreciseValue === null
    ) {
      tempTiers.map((tier) => {
        tier.entitledPreciseValue = tier.entitledWholeValue;
        tier.entitledWholeValue = null;
        return tier;
      });
      setCurrentTiers(tempTiers);
    } else if (
      offerData?.type === OfferTieredDiscountTypeDtoEnum.PERCENTAGE &&
      currentTiers[0].entitledWholeValue === null
    ) {
      tempTiers.map((tier) => {
        tier.entitledWholeValue = tier.entitledPreciseValue;
        tier.entitledPreciseValue = null;
        return tier;
      });
      setCurrentTiers(tempTiers);
    }
  }, [prevTiers, currentTiers, offerData?.type]);

  useEffect(() => {
    if (prevTiers && currentTiers && !isEqual(currentTiers, prevTiers)) {
      setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
        ...prevState,
        tiers: currentTiers,
      }));
      setIsTiersUpdated(true);
    }
  }, [prevTiers, currentTiers]);

  const levelDataUpdate = useCallback(
    (level: OfferLevelDto) => {
      dispatch(setCurrentCombinationIsLoaded(false));
      dispatch(setCombinedWithList({}));
      dispatch(setLastCombinedWithTypeFetched(null));
      setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
        ...prevState,
        level: level,
      }));
    },
    [setOfferData, dispatch]
  );

  const appliesToDataUpdate = useCallback(
    (data: OfferPrerequisiteEntitledAppliesToDto) => {
      setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          appliesTo: data,
        },
      }));
    },
    [setOfferData]
  );

  const exclusionsUpdate = useCallback(
    (data: boolean) => {
      setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          exclusions: {
            ...prevState?.products?.exclusions,
            collections: {
              ...prevState?.products?.exclusions?.collections,
              enabled: data,
            },
          },
        },
      }));
    },
    [setOfferData]
  );

  const excludedResourcesUpdate = useCallback(
    (data: ShopifyObjectDto[]) => {
      setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          exclusions: {
            ...prevState?.products?.exclusions,
            collections: {
              ...prevState?.products?.exclusions?.collections,
              value: data,
            },
          },
        },
      }));
    },
    [setOfferData]
  );

  const limitUpdate = useCallback(
    (data: OfferProductLimitDto) => {
      setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          limit: data,
        },
      }));
    },
    [setOfferData]
  );

  const cartRulesUpdate = useCallback(
    (data: OfferCartRulesDto) => {
      setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
        ...prevState,
        cartRules: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const limitUpdateData = useCallback(
    (data: OptionDtoOfferDiscountLimitDto) => {
      setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
        ...prevState,
        discountLimit: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const combinationsUpdate = useCallback(
    (data: OfferCombinationsDto) => {
      setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
        ...prevState,
        combinations: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const advancedSettingsUpdate = useCallback(
    (
      field: keyof OfferPrerequisiteEntitledAdvancedSettingsDto,
      value: string
    ) => {
      setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          advancedSettings: {
            ...prevState?.products?.advancedSettings,
            [field]: value,
          },
        },
      }));
    },
    [setOfferData]
  );

  const nameFiltersUpdate = useCallback(
    (
      filterName: keyof OfferPrerequisiteEntitledNameFiltersDto,
      field: keyof OptionDtoString,
      value: string | boolean | null
    ) => {
      setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          nameFilters: {
            ...prevState?.products?.nameFilters,
            [filterName]: {
              ...prevState?.products?.nameFilters?.[filterName],
              [field]: value,
            },
          },
        },
      }));
    },
    [setOfferData]
  );

  const minimumSpendUpdate = useCallback(
    (data: OptionDtoOfferMinimumSpendDto) => {
      setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
        ...prevState,
        minimumSpend: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const tierUpdate = useCallback(
    (data: OfferTierUpdateDto) => {
      const { tierIndex, tier } = data;
      const tiersCopy = currentTiers ? cloneDeep(currentTiers) : [];
      tiersCopy[tierIndex] = {
        ...tiersCopy[tierIndex],
        ...tier,
      };
      setCurrentTiers(tiersCopy);
    },
    [currentTiers]
  );

  const tierDelete = useCallback(
    (index: number) => {
      const tiersCopy = cloneDeep(currentTiers)?.filter(
        (tier, idx) => idx !== index
      );
      tiersCopy && setCurrentTiers(tiersCopy);
    },
    [currentTiers]
  );

  return (
    <>
      {configureTiers && (
        <Tiers
          tiers={currentTiers}
          discountType={
            offerData?.type ||
            (offerTemplate?.template as OfferTieredSpendXGetYDto)?.type
          }
          offerType={offerType}
          onFormValidityChange={validityChangeHandler(
            SpendXGetYTieredOfferTabsEnum.Tiers
          )}
          setCurrentTiers={setCurrentTiers}
          setConfigureComponent={setConfigureTiers}
          configureComponent={configureTiers}
          onTierUpdate={tierUpdate}
          onTierDelete={tierDelete}
        />
      )}
      {configureCartRules && (
        <CartRules
          productAmount={productAmount}
          savedData={
            (savedData?.template as OfferTieredSpendXGetYDto)?.cartRules
          }
          onFormValidityChange={validityChangeHandler(
            SpendXGetYTieredOfferTabsEnum.CartRules
          )}
          onCartRulesChange={cartRulesUpdate}
          setConfigureComponent={setConfigureCartRules}
          configureComponent={configureCartRules}
          specialOfferType={
            offerType === OfferTypeDtoEnum.TIERED_BOGO
              ? OfferTypeSpecialCases.BogoMixMatch
              : OfferTypeSpecialCases.SpendXGetX
          }
          groupIndex={groupIndex}
          noValueRule={true}
          ruleIndex={ruleIndex}
          setRuleIndex={setRuleIndex}
          rulesSaved={offerData?.cartRules || {}}
          setGroupIndex={setGroupIndex}
          setIsChanged={setIsChanged}
          isChanged={isChanged}
        />
      )}
      {configureProducts && (
        <Products
          products={
            (offerTemplate?.template as OfferTieredSpendXGetYDto)?.products
          }
          onAppliesToUpdate={appliesToDataUpdate}
          onExclusionsUpdate={exclusionsUpdate}
          onExcludedResourcesUpdate={excludedResourcesUpdate}
          onLimitUpdate={limitUpdate}
          onFormValidityChange={validityChangeHandler(
            SpendXGetYTieredOfferTabsEnum.Products
          )}
          onNameFiltersUpdate={nameFiltersUpdate}
          onAdvancedSettingsUpdate={advancedSettingsUpdate}
          savedData={
            (savedData?.template as OfferTieredSpendXGetYDto)?.products
          }
          setConfigureComponent={setConfigureProducts}
          configureComponent={configureProducts}
        />
      )}

      {!configureTiers && !configureCartRules && !configureProducts && (
        <Page
          title={i18n.translate(`ConfigureOffer`)}
          backAction={{
            onAction: async () => {
              !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
              navigate('../..', { relative: 'path' });
            },
          }}
        >
          <BlockStack gap='600'>
            <OfferTemplate
              type={offerType}
              toggleShowHowModal={toggleShowHowModal}
            />
            <Discount
              level={
                (offerTemplate?.template as OfferTieredSpendXGetYDto)?.level
              }
              onLevelUpdate={levelDataUpdate}
              offerType={offerType}
              type={(offerTemplate?.template as OfferTieredSpendXGetYDto).type}
              onTypeUpdate={discountTypeDataUpdate}
            />

            <Tiers
              tiers={currentTiers}
              discountType={offerData?.type}
              offerType={offerType}
              onFormValidityChange={validityChangeHandler(
                SpendXGetYTieredOfferTabsEnum.Tiers
              )}
              setCurrentTiers={setCurrentTiers}
              setConfigureComponent={setConfigureTiers}
              configureComponent={configureTiers}
              onTierUpdate={tierUpdate}
              onTierDelete={tierDelete}
            />
            {(offerTemplate?.template as OfferTieredSpendXGetYDto)?.products !==
              undefined && (
              <Products
                products={
                  (offerTemplate?.template as OfferTieredSpendXGetYDto)
                    ?.products
                }
                onAppliesToUpdate={appliesToDataUpdate}
                onExclusionsUpdate={exclusionsUpdate}
                onExcludedResourcesUpdate={excludedResourcesUpdate}
                onLimitUpdate={limitUpdate}
                onNameFiltersUpdate={nameFiltersUpdate}
                onAdvancedSettingsUpdate={advancedSettingsUpdate}
                onFormValidityChange={validityChangeHandler(
                  SpendXGetYTieredOfferTabsEnum.Products
                )}
                savedData={
                  (savedData?.template as OfferTieredSpendXGetYDto)?.products
                }
                setConfigureComponent={setConfigureProducts}
                configureComponent={configureProducts}
              />
            )}
            <CartRules
              productAmount={productAmount}
              savedData={
                (savedData?.template as OfferTieredSpendXGetYDto)?.cartRules
              }
              onFormValidityChange={validityChangeHandler(
                SpendXGetYTieredOfferTabsEnum.CartRules
              )}
              onCartRulesChange={cartRulesUpdate}
              setConfigureComponent={setConfigureCartRules}
              configureComponent={configureCartRules}
              noValueRule={true}
              specialOfferType={
                offerType === OfferTypeDtoEnum.TIERED_BOGO
                  ? OfferTypeSpecialCases.BogoMixMatch
                  : OfferTypeSpecialCases.SpendXGetX
              }
              groupIndex={groupIndex}
              ruleIndex={ruleIndex}
              setRuleIndex={setRuleIndex}
              rulesSaved={offerData?.cartRules || {}}
              setGroupIndex={setGroupIndex}
              setIsChanged={setIsChanged}
              isChanged={isChanged}
            />
            <DiscountLimits
              type='Discount'
              discountLimit={
                (offerTemplate?.template as OfferTieredSpendXGetYDto)
                  ?.discountLimit
              }
              discountLimitUpdate={limitUpdateData}
            />
            <Combinations onCombinationsChange={combinationsUpdate} />
            {offerTemplate?.minimumSpendSupported && (
              <MinimumSpend
                minimumSpend={
                  (offerTemplate?.template as OfferTieredSpendXGetYDto)
                    ?.minimumSpend || {}
                }
                minimumSpendUpdate={minimumSpendUpdate}
              />
            )}
            {showHowModal && (
              <LearnMoreModal
                onModalClose={toggleShowHowModal}
                // url='https://youtu.be/9htdypfdlBM'
                title={i18n.translate('Tutorial')}
              />
            )}
          </BlockStack>
        </Page>
      )}
    </>
  );
};
