import React from 'react';
import {
  Badge,
  BlockStack,
  Card,
  Checkbox,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { Element } from 'react-scroll';
type DiscountSettingsProps = {
  showTotal: boolean;
  showDiscountBreakdown: boolean;
  setShowTotal: (value: boolean) => void;
  setShowDiscountBreakdown: (value: boolean) => void;
};
export const DiscountSettings: React.FC<DiscountSettingsProps> = ({
  showTotal,
  showDiscountBreakdown,
  setShowTotal,
  setShowDiscountBreakdown,
}) => {
  const [i18n] = useI18n();

  return (
    <Element name='DiscountSettings' className='DiscountSettings'>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('DiscountSettings')}
            </Text>
          </BlockStack>
          <Checkbox
            label={
              <InlineStack gap='200'>
                {i18n.translate('ShowTotal')}
                <Badge>{i18n.translate('Recommended')}</Badge>
              </InlineStack>
            }
            checked={showTotal}
            onChange={setShowTotal}
          />
          <Checkbox
            label={
              <InlineStack gap='200'>
                {i18n.translate('ShowDiscountBreakdown')}
                <Badge>{i18n.translate('Recommended')}</Badge>
              </InlineStack>
            }
            checked={showDiscountBreakdown}
            onChange={setShowDiscountBreakdown}
          />
        </BlockStack>
      </Card>
    </Element>
  );
};
