import React from 'react';
import './CornerRadius.scss';
import { useI18n } from '@shopify/react-i18n';
import { InlineStack, TextField } from '@shopify/polaris';
import { CornerRadiusComponentDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { CornerRadiusTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import classNames from 'classnames';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';

type CornerRadiusProps = {
  cornerRadius: CornerRadiusComponentDto;
  setCornerRadius: (value: CornerRadiusComponentDto) => void;
  onSystemReset: () => void;
  inherited?: boolean;
};
export const CornerRadius: React.FC<CornerRadiusProps> = ({
  cornerRadius,
  setCornerRadius,
  onSystemReset,
  inherited,
}) => {
  const [i18n] = useI18n();
  return (
    <div className='CornerRadius'>
      <StyleComponentHeader
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            onAction: () => onSystemReset(),
          },
        ]}
        label={i18n.translate(`CornerRadius`)}
        inherited={inherited}
      >
        <InlineStack align='space-between' blockAlign='center'>
          {i18n.translate(`CornerRadius`)}
          <div className='RightContent'>
            <div className='BordersRow'>
              {Object.entries(CornerRadiusTypeDtoEnum).map(
                ([key, value]: [string, CornerRadiusTypeDtoEnum]) => (
                  <div
                    onClick={() =>
                      setCornerRadius({ ...cornerRadius, type: value })
                    }
                    key={key}
                    className={classNames(value, {
                      isActive: cornerRadius.type === value,
                    })}
                  />
                )
              )}
            </div>
            {cornerRadius.type === CornerRadiusTypeDtoEnum.CUSTOM && (
              <TextField
                type='number'
                value={cornerRadius.customValue?.toString() || '0'}
                autoComplete=''
                label=''
                onChange={(value) =>
                  setCornerRadius({ ...cornerRadius, customValue: +value })
                }
                onBlur={() => {
                  (cornerRadius.customValue || 0) < 0
                    ? setCornerRadius({ ...cornerRadius, customValue: 0 })
                    : null;
                }}
                suffix='px'
              />
            )}
          </div>
        </InlineStack>
      </StyleComponentHeader>
    </div>
  );
};
