import React from 'react';
import {
  Banner,
  BlockStack,
  Card,
  Divider,
  Link,
  Text,
  TextField,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { StrikethroughPricingInstallmentsSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import { Element } from 'react-scroll';

type InstallmentsSettingProps = {
  installments: StrikethroughPricingInstallmentsSettingsDto;
  setInstallments: (value: StrikethroughPricingInstallmentsSettingsDto) => void;
};
export const InstallmentsSetting: React.FC<InstallmentsSettingProps> = ({
  installments,
  setInstallments,
}) => {
  const [i18n] = useI18n();

  return (
    <Element className='InstallmentsSetting' name='InstallmentsSetting'>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('Installments')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('Subtitle')}
            </Text>
          </BlockStack>
          <Banner tone='info'>
            {i18n.translate('BannerText')}
            <Link>{i18n.translate('FollowingArticle')}</Link>.
          </Banner>
          <Text as='h2' fontWeight='medium'>
            {i18n.translate('Selector4Month')}
          </Text>
          <TextField
            value={installments.fourMonthInstallmentsCssSelector}
            label={i18n.translate('CSSSelector')}
            onChange={(value) =>
              setInstallments({
                ...installments,
                fourMonthInstallmentsCssSelector: value,
              })
            }
            autoComplete=''
            labelAction={{
              content: i18n.translate('Copy'),
              onAction: () =>
                navigator.clipboard.writeText(
                  installments.fourMonthInstallmentsCssSelector || ''
                ),
            }}
          />
          <Divider />
          <Text as='h2' fontWeight='medium'>
            {i18n.translate('Selector3Month')}
          </Text>
          <TextField
            value={installments.threeMonthInstallmentsCssSelector}
            label={i18n.translate('CSSSelector')}
            onChange={(value) =>
              setInstallments({
                ...installments,
                threeMonthInstallmentsCssSelector: value,
              })
            }
            autoComplete=''
            labelAction={{
              content: i18n.translate('Copy'),
              onAction: () =>
                navigator.clipboard.writeText(
                  installments.threeMonthInstallmentsCssSelector || ''
                ),
            }}
          />
        </BlockStack>
      </Card>
    </Element>
  );
};
