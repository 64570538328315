import React, { useCallback, useMemo } from 'react';
import { SelectedOptionPath } from '../../../../RightSideBarWrapper';
import {
  BodyRowModuleLevelDto,
  PriceStateDto,
  PriceStateLevelDto,
  PricingStatesDto,
  PricingSystemDefaultsDto,
  ProductPagePricePresetDtoRead,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { getLastSelectedOption } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import { Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import PriceState from './components/PriceState/PriceState';
import PriceText from './components/PriceText/PriceText';
import BodyRowBadge from './components/BodyRowBadge/BodyRowBadge';
import { WidgetOptionHeader } from '../../../components';
import { PriceWidgetTypesDtoEnum } from '../../EditPresetRightBar';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';

type PresetPriceProps = {
  selectedOptionPath: SelectedOptionPath;
  price: ProductPagePricePresetDtoRead;
  defaultPresetData: ProductPagePricePresetDtoRead;
  shopDefaultData: PricingSystemDefaultsDto;
  defaultLanguage: string;
  priceType: PriceWidgetTypesDtoEnum;
  updatePrice: (data: ProductPagePricePresetDtoRead) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const PresetPrice: React.FC<PresetPriceProps> = ({
  selectedOptionPath,
  price,
  defaultPresetData,
  shopDefaultData,
  defaultLanguage,
  priceType,
  updatePrice,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();

  const updateDiscountedPrice = useCallback(
    (priceType: keyof PricingStatesDto, data: PriceStateDto) => {
      updatePrice({
        ...price,
        options: {
          ...price.options,
          [priceType]: data,
        },
      });
    },
    [price, updatePrice]
  );

  const priceOptions = useMemo(() => {
    const selectedOption = getLastSelectedOption(selectedOptionPath);

    const priceStateType =
      selectedOptionPath?.selectedOption?.name ===
      WidgetListOptionsDtoEnum.DISCOUNTED_PRICE
        ? WidgetListOptionsDtoEnum.DISCOUNTED_PRICE
        : WidgetListOptionsDtoEnum.NOT_DISCOUNTED_PRICE;

    const bodyRowType =
      selectedOptionPath?.selectedOption?.selectedOption?.name === 'bodyRow1'
        ? WidgetListOptionsDtoEnum.BODY_ROW_1
        : selectedOptionPath?.selectedOption?.selectedOption?.name ===
          'bodyRow2'
        ? WidgetListOptionsDtoEnum.BODY_ROW_2
        : WidgetListOptionsDtoEnum.BODY_ROW_3;

    const updatePriceComponent = (
      field: keyof PriceStateLevelDto,
      data: PriceStateLevelDto[keyof PriceStateLevelDto]
    ) => {
      updateDiscountedPrice(priceStateType, {
        ...price.options?.[priceStateType],
        options: {
          ...price.options?.[priceStateType]?.options,
          [field]: data,
        },
      });
    };

    const updateBodyRowComponent = (
      field: keyof BodyRowModuleLevelDto,
      data: BodyRowModuleLevelDto[keyof BodyRowModuleLevelDto]
    ) => {
      updatePriceComponent(bodyRowType, {
        ...price.options?.[priceStateType]?.options?.[bodyRowType],
        options: {
          ...price.options?.[priceStateType]?.options?.[bodyRowType]?.options,
          [field]: data,
        },
      });
    };

    switch (true) {
      case selectedOption?.name.slice(0, -1) === 'bodyRow':
        return (
          <div className='EmptyRightSideBanner'>
            <WidgetOptionHeader
              name={i18n.translate(selectedOption?.name || '')}
              handleLeftMenuSelection={handleLeftMenuSelection}
            />
            <div className='RightSideSection'>
              <Text as='h2' tone='subdued'>
                {i18n.translate('NoCustomizable')}
              </Text>
            </div>
          </div>
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.DISCOUNTED_PRICE:
      case selectedOption?.name ===
        WidgetListOptionsDtoEnum.NOT_DISCOUNTED_PRICE:
        return (
          <PriceState
            price={price.options?.[priceStateType]?.general || {}}
            defaultPrice={
              defaultPresetData.options?.[priceStateType]?.general || {}
            }
            priceType={priceType}
            shopDefaultPrice={shopDefaultData.discounted || {}}
            stateType={priceStateType}
            isPresetEditor
            setPrice={(data) =>
              updateDiscountedPrice(priceStateType, {
                ...price.options?.[priceStateType],
                general: data,
              })
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.HEADER_TEXT:
        return (
          <PriceText
            text={
              price.options?.[priceStateType]?.options?.headerText?.general ||
              {}
            }
            defaultText={
              defaultPresetData.options?.[priceStateType]?.options?.headerText
                ?.general || {}
            }
            textType={WidgetListOptionsDtoEnum.HEADER_TEXT}
            bodyRowType={bodyRowType}
            priceType={priceType}
            stateType={priceStateType}
            shopDefaultText={shopDefaultData.discounted?.headerText || {}}
            defaultLanguage={defaultLanguage}
            isPresetEditor
            setText={(data) =>
              updatePriceComponent('headerText', {
                ...price.options?.[priceStateType]?.options?.headerText,
                general: data,
              })
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.FOOTER_TEXT:
        return (
          <PriceText
            text={
              price.options?.[priceStateType]?.options?.footerText?.general ||
              {}
            }
            textType={WidgetListOptionsDtoEnum.FOOTER_TEXT}
            defaultText={
              defaultPresetData.options?.[priceStateType]?.options?.footerText
                ?.general || {}
            }
            bodyRowType={bodyRowType}
            priceType={priceType}
            stateType={priceStateType}
            shopDefaultText={shopDefaultData.discounted?.footerText || {}}
            defaultLanguage={defaultLanguage}
            isPresetEditor
            setText={(data) =>
              updatePriceComponent('footerText', {
                ...price.options?.[priceStateType]?.options?.footerText,
                general: data,
              })
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.PRICE:
        return (
          <PriceText
            text={
              price.options?.[priceStateType]?.options?.[bodyRowType]?.options
                ?.price || {}
            }
            bodyRowType={bodyRowType}
            textType={WidgetListOptionsDtoEnum.PRICE}
            priceType={priceType}
            stateType={priceStateType}
            defaultText={
              defaultPresetData.options?.[priceStateType]?.options?.[
                bodyRowType
              ]?.options?.price || {}
            }
            shopDefaultText={shopDefaultData.discounted?.price?.text || {}}
            defaultLanguage={defaultLanguage}
            isPresetEditor
            setText={(data) => updateBodyRowComponent('price', data)}
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.BADGE:
        return (
          <BodyRowBadge
            badge={
              price.options?.[priceStateType]?.options?.[bodyRowType]?.options
                ?.badge || {}
            }
            priceType={priceType}
            stateType={priceStateType}
            defaultBadge={
              defaultPresetData.options?.[priceStateType]?.options?.[
                bodyRowType
              ]?.options?.badge || {}
            }
            bodyRowType={bodyRowType}
            shopDefaultBadge={shopDefaultData.discounted?.badge || {}}
            defaultLanguage={defaultLanguage}
            setBadge={(data) => updateBodyRowComponent('badge', data)}
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
    }
  }, [
    selectedOptionPath,
    shopDefaultData,
    defaultLanguage,
    price,
    defaultPresetData,
    updateDiscountedPrice,
    handleLeftMenuSelection,
  ]);
  return <div className='PresetPrice'>{priceOptions}</div>;
};

export default PresetPrice;
