import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './NinjaCart.scss';
import { useI18n } from '@shopify/react-i18n';
import { NinjaCartPresetDtoRead } from 'core/api/adminWidgets/adminWidgetsApi';
import {
  DeviceTypeDtoEnum,
  NinjaCartHeaderItemCountStyleDtoEnum,
  NinjaCartStatesPresetEntryTypeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import { useAppSelector } from 'core/hooks';
import {
  useConfigureWidgets,
  WidgetsFetchTypeEnum,
} from 'features/settings/hooks/useConfigureWidgets';
import {
  getCurrentMessage,
  parseSmartTagCode,
} from 'features/settings/components/EditPresetSkeleton/utils/utils';
import { extractNumber } from 'core/utils';
import { formatStringQuotes } from '../../utils/utils';
import { PreviewEntry } from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components';

type NinjaCartProps = {
  data: NinjaCartPresetDtoRead & { isOpen?: boolean };
  previewType: DeviceTypeDtoEnum;
  defaultLanguage?: string;
  adminActivePath: string;
  getSelectionFromThePreview(path: string): void;
  handleSwitchWidgetState?(widgetName: string, entries: any[]): void;
};

export const NinjaCart: React.FC<NinjaCartProps> = ({
  previewType,
  defaultLanguage,
  data,
  adminActivePath,
  getSelectionFromThePreview,
  handleSwitchWidgetState,
}) => {
  const [i18n] = useI18n();
  const { fontListData } = useConfigureWidgets(
    WidgetsFetchTypeEnum.PRESET_FONT_LIST
  );

  const {
    defaultIconLibary,
    defaultImageLibary,
    customIconLibary,
    customImageLibary,
    promotionSummaryPageEntries,
  } = useAppSelector((state) => state.widgets);

  const { smartTagsCodes } = useAppSelector((state) => state.offersWizard);

  const [injectStyles, setInjectStyles] = useState<boolean>(false);
  const [triggerRenderCount, setTriggerRenderCount] = useState<number>(0);

  const laDnNinjaCart = document.querySelector('la-dn-ninja-cart');

  const currentIndex: number = useMemo(() => {
    const match = adminActivePath.match(/banner_(\d+)/);
    if (match) {
      return +match[1];
    } else {
      return 0;
    }
  }, [adminActivePath]);

  const isOpen = useMemo(() => data?.isOpen, [data?.isOpen]);

  const imagesArray = useMemo(
    () => [...defaultImageLibary, ...customImageLibary],
    [customImageLibary, defaultImageLibary]
  );
  const iconsArray = useMemo(
    () => [...defaultIconLibary, ...customIconLibary],
    [customIconLibary, defaultIconLibary]
  );

  const assetsArray = useMemo(
    () =>
      [...iconsArray, ...imagesArray].map((item) => ({
        id: item.id,
        url: item.url,
      })),
    [iconsArray, imagesArray]
  );

  const cartItems = useMemo(
    () => [data?.options?.defaultState?.options?.content?.options?.cartItem],
    [data?.options?.defaultState?.options?.content?.options?.cartItem]
  );

  const heading = useMemo(
    () =>
      getCurrentMessage(
        data?.options?.defaultState?.options?.header?.options?.heading?.text
          ?.message?.entries,
        defaultLanguage
      ),
    [
      data?.options?.defaultState?.options?.header?.options?.heading?.text
        ?.message?.entries,
      defaultLanguage,
    ]
  );

  const itemCountStyle = useMemo(
    () =>
      extractNumber(
        data?.options?.defaultState?.options?.header?.options?.itemCount
          ?.style || NinjaCartHeaderItemCountStyleDtoEnum.STYLE_1
      ),
    [data?.options?.defaultState?.options?.header?.options?.itemCount?.style]
  );

  const currentTotal = useMemo(
    () =>
      getCurrentMessage(
        data?.options?.defaultState?.options?.footer?.options?.promotionSummary
          ?.options?.total?.label?.message?.entries,
        defaultLanguage
      ),
    [
      data?.options?.defaultState?.options?.footer?.options?.promotionSummary
        ?.options?.total?.label?.message?.entries,
      defaultLanguage,
    ]
  );

  const currentSubTotal = useMemo(
    () =>
      getCurrentMessage(
        data?.options?.defaultState?.options?.footer?.options?.promotionSummary
          ?.options?.subtotal?.label?.entries,
        defaultLanguage
      ),
    [
      data?.options?.defaultState?.options?.footer?.options?.promotionSummary
        ?.options?.subtotal?.label?.entries,
      defaultLanguage,
    ]
  );

  const currentShipping = useMemo(
    () =>
      getCurrentMessage(
        data?.options?.defaultState?.options?.footer?.options?.promotionSummary
          ?.options?.shipping?.label?.entries,
        defaultLanguage
      ),
    [
      data?.options?.defaultState?.options?.footer?.options?.promotionSummary
        ?.options?.shipping?.label?.entries,
      defaultLanguage,
    ]
  );

  const currentTaxes = useMemo(
    () =>
      getCurrentMessage(
        data?.options?.defaultState?.options?.footer?.options?.promotionSummary
          ?.options?.taxesAndDuties?.label?.entries,
        defaultLanguage
      ),
    [
      data?.options?.defaultState?.options?.footer?.options?.promotionSummary
        ?.options?.taxesAndDuties?.label?.entries,
      defaultLanguage,
    ]
  );

  const currentDescription = useMemo(
    () =>
      getCurrentMessage(
        data?.options?.defaultState?.options?.footer?.options?.promotionSummary
          ?.options?.taxesAndDuties?.description?.message?.entries,
        defaultLanguage
      ),
    [
      data?.options?.defaultState?.options?.footer?.options?.promotionSummary
        ?.options?.taxesAndDuties?.description?.message?.entries,
      defaultLanguage,
    ]
  );

  const currentDiscounts = useMemo(
    () =>
      `<la-dn-promotion-summary-discount label="${formatStringQuotes(
        getCurrentMessage(
          data?.options?.defaultState?.options?.footer?.options
            ?.promotionSummary?.options?.discount?.label?.entries,
          defaultLanguage
        ) || i18n.translate('example')
      )}" token="example" index="0">
      -<la-dn-price
            location="drawer-cart"
            level="total"
            offer-token="A"
            available="true"
            layout="[<la-dn-money amount='5000' currency='USD' include-currency-code='true' include-currency-symbol='true' include-trailing-zeros='true' precision='2' zero-is-free='true' cents-superscript='false' hide-decimal-separator='false' use-from-label='false' use-sold-out='false' price-strikethrough='none'></la-dn-money>]"
          ></la-dn-price>
      </la-dn-promotion-summary-discount>`,
    [
      i18n,
      data?.options?.defaultState?.options?.footer?.options?.promotionSummary
        ?.options?.discount?.label?.entries,
      defaultLanguage,
    ]
  );

  const currentDisclaimer = useMemo(
    () =>
      parseSmartTagCode(
        promotionSummaryPageEntries.promotionSummary?.disclaimerText[
          previewType.toLowerCase() as keyof PreviewEntry
        ] || '',
        smartTagsCodes
      ) ||
      getCurrentMessage(
        data?.options?.defaultState?.options?.footer?.options?.promotionSummary
          ?.options?.disclaimer?.text?.value?.message?.entries,
        defaultLanguage,
        smartTagsCodes
      ),
    [
      data?.options?.defaultState?.options?.footer?.options?.promotionSummary
        ?.options?.disclaimer?.text?.value?.message?.entries,
      defaultLanguage,
      smartTagsCodes,
      previewType,
      promotionSummaryPageEntries,
    ]
  );

  const ctaButton1Label = useMemo(
    () =>
      getCurrentMessage(
        data?.options?.defaultState?.options?.footer?.options?.ctaButtons
          ?.options?.ctaButton1?.label?.entries
      ),
    [
      data?.options?.defaultState?.options?.footer?.options?.ctaButtons?.options
        ?.ctaButton1?.label?.entries,
      defaultLanguage,
    ]
  );

  const ctaButton2Label = useMemo(
    () =>
      getCurrentMessage(
        data?.options?.defaultState?.options?.footer?.options?.ctaButtons
          ?.options?.ctaButton2?.label?.entries
      ),
    [
      data?.options?.defaultState?.options?.footer?.options?.ctaButtons?.options
        ?.ctaButton2?.label?.entries,
      defaultLanguage,
    ]
  );

  const headlineEmpty = useMemo(
    () =>
      getCurrentMessage(
        data?.options?.emptyState?.options?.text?.headline?.message?.entries,
        defaultLanguage
      ),
    [
      data?.options?.emptyState?.options?.text?.headline?.message?.entries,
      defaultLanguage,
    ]
  );

  const paragraph = useMemo(
    () =>
      getCurrentMessage(
        data?.options?.emptyState?.options?.text?.paragraph?.message?.entries,
        defaultLanguage
      ),
    [
      data?.options?.emptyState?.options?.text?.paragraph?.message?.entries,
      defaultLanguage,
    ]
  );

  const ctaButtonEmpty = useMemo(
    () =>
      getCurrentMessage(
        data?.options?.emptyState?.options?.callToActionButton?.label?.entries,
        defaultLanguage
      ),
    [
      data?.options?.emptyState?.options?.callToActionButton?.label?.entries,
      defaultLanguage,
    ]
  );

  const elementToReplaceWith = useMemo(
    () =>
      `
     <la-dn-ninja-cart
  admin-mode="true"
  admin-active-path="${adminActivePath}"
  admin-mode-label='[{"component":"ninjaCart","label":"${i18n.translate(
    'NinjaCart'
  )}","icon":"widget","states":[{"key":"default","name":"Default state"},{"key":"empty","name":"Empty state"}],"subComponents":[{"component":"header","label":"${i18n.translate(
        'Header'
      )}","icon":"component","subComponents":[{"component":"heading","label":"${i18n.translate(
        'Heading'
      )}","icon":"component"},{"component":"itemCount","label":"${i18n.translate(
        'ItemCount'
      )}","icon":"component"}]},{"component":"image","label":"${i18n.translate(
        'Image'
      )}","icon":"component"},{"component":"text","label":"${i18n.translate(
        'Text'
      )}","icon":"component"},{"component":"callToActionButton","label":"${i18n.translate(
        'CTAButton'
      )}","icon":"component"},{"component":"content","label":"${i18n.translate(
        'Content'
      )}","icon":"component","subComponents":[{"component":"cartItem","label":"${i18n.translate(
        'CartItem'
      )}","icon":"component","subComponents":[{"component":"productBadge","label":"${i18n.translate(
        'ProductBadge'
      )}","icon":"component"},{"component":"productName","label":"${i18n.translate(
        'ProductName'
      )}","icon":"component"},{"component":"productDetails","label":"${i18n.translate(
        'ProductDetails'
      )}","icon":"component"},{"component":"productPrice","label":"${i18n.translate(
        'ProductPrice'
      )}","icon":"component"},{"component":"cartText","label":"${i18n.translate(
        'CartText'
      )}","icon":"component"}]}]},{"component":"footer","label":"${i18n.translate(
        'Footer'
      )}","icon":"component","subComponents":[{"component":"promotionSummary","label":"${i18n.translate(
        'PromotionSummary'
      )}","icon":"component","subComponents":[{"component":"discount","label":"${i18n.translate(
        'Discount'
      )}","icon":"component","subComponents":[{"component":"banner","label":"${i18n.translate(
        'Offer'
      )}","icon":"offer"}]},{"component":"total","label":"${i18n.translate(
        'Total'
      )}","icon":"component"},{"component":"subtotal","label":"${i18n.translate(
        'Subtotal'
      )}","icon":"component"},{"component":"shipping","label":"${i18n.translate(
        'Shipping'
      )}","icon":"component"},{"component":"taxesAndDuties","label":"${i18n.translate(
        'TaxesAndDuties'
      )}","icon":"component"},{"component":"disclaimer","label":"${i18n.translate(
        'Disclaimer'
      )}","icon":"component"}]},{"component":"termsAndConditions","label":"${i18n.translate(
        'TermsConditions'
      )}","icon":"component"},{"component":"ctaButtons","label":"${i18n.translate(
        'CTAButtons'
      )}","icon":"component","subComponents":[{"component":"ctaButton1","label":"${i18n.translate(
        'CTAButton1'
      )}","icon":"component"},{"component":"ctaButton2","label":"${i18n.translate(
        'CTAButton2'
      )}","icon":"component"}]},{"component":"paymentOptions","label":"${i18n.translate(
        'PaymentOptions'
      )}","icon":"component"},{"component":"continueShoppingButton","label":"${i18n.translate(
        'ContinueShoppingButton'
      )}","icon":"component"}]}]}]'
  mode="cart"
  attached="false"
  border="rounded"
  overlay="true"
  cart-url="/en/cart"
  cart-add-url="/en/cart/add"
  cart-change-url="/en/cart/change"
  cart-update-url="/en/cart/update"
  cart-clear-url="/en/cart/clear"
>
  <template id="la-dn-ninja-cart-item-template">
    <la-dn-ninja-cart-item line-item-key="{{ line-item-key }}">
      <la-dn-ninja-cart-item-image
        slot="image"
        product-url="{{ product-url }}"
        image-url="{{ product-image-url }}"
        image-alt="{{ product-image-alt }}"
      >
        <la-dn-promotional-badge
          offer-token="{{ offer-token }}"
          line-item-key="{{ line-item-key }}"
          discount-percentage="{{ percentage }}"
          offset="[4, 4]"
        ></la-dn-promotional-badge>
      </la-dn-ninja-cart-item-image>

      <la-dn-ninja-cart-item-header
        line-item-key="{{ line-item-key }}"
        remove-accessibility-label="{{ remove-accessibility-label }}"
        options-option="{{ product-options-option }}"
        options-separate-line="{{ product-options-separate-line }}"
        options-prefix="{{ product-options-prefix }}"
        vendor-option="{{ product-vendor-option }}"
        vendor-separate-line="{{ product-vendor-separate-line }}"
        properties-option="{{ product-properties-option }}"
        properties-separate-line="{{ product-properties-separate-line }}"
        separator="{{ product-header-separator }}"
        options='{{ product-options }}'
        vendor="{{ product-vendor }}"
        properties='{{ product-properties }}'
      >
        <la-dn-ninja-cart-item-title product-url="{{ product-url }}">{{ product-name }}</la-dn-ninja-cart-item-title>
      </la-dn-ninja-cart-item-header>

      <la-dn-ninja-cart-item-pricing>
        <la-dn-ninja-cart-item-quantity
          line-item-key="{{ line-item-key }}"
          increase-accessibility-label="{{ quantity-increase-accessibility-label }}"
          decrease-accessibility-label="{{ quantity-decrease-accessibility-label }}"
          input-accessibility-label="{{ quantity-input-accessibility-label }}"
          select-accessibility-label="{{ quantity-select-accessibility-label }}"
          disabled="false"
          variant-type="{{ quantity-variant }}"
          value="{{ quantity }}"
          min-value="1"
          max-value="null"
          step="1"
        ></la-dn-ninja-cart-item-quantity>

        <la-dn-price
          location="drawer-cart"
          level="line"
          line-item-key="{{ line-item-key }}"
          available="{{ product-available }}"
          discounted="{{ discounted }}"
          not-discounted-layout="[<div style='display: flex; align-items: flex-end; flex-direction: column;'><la-dn-money style='font-size: 13px; font-weight: 450; color: #303030; line-height: 20px;' amount='{{ original-line-price }}' currency='{{ currency }}' include-currency-code='true' include-currency-symbol='true' include-trailing-zeros='true' precision='2' zero-is-free='true' cents-superscript='false' hide-decimal-separator='false' use-from-label='false' use-sold-out='false' price-strikethrough='none'></la-dn-money></div>]"
          discounted-layout="[<div style='display: flex; align-items: flex-end; flex-direction: column;'><la-dn-money style='font-size: 13px; font-weight: 450; color: #303030; line-height: 20px;' amount='{{ original-line-price }}' currency='{{ currency }}' include-currency-code='true' include-currency-symbol='true' include-trailing-zeros='true' precision='2' zero-is-free='true' cents-superscript='false' hide-decimal-separator='false' use-from-label='false' use-sold-out='false' price-strikethrough='none'></la-dn-money> <la-dn-money style='font-size: 12px; font-weight: 450; color: #B5B5B5; line-height: 16px;' amount='{{ discounted-line-price }}' currency='{{ currency }}' include-currency-code='true' include-currency-symbol='true' include-trailing-zeros='true' precision='2' zero-is-free='true' cents-superscript='false' hide-decimal-separator='false' use-from-label='false' use-sold-out='false' price-strikethrough='horizontal'></la-dn-money></div>]"
        ></la-dn-price>
      </la-dn-ninja-cart-item-pricing>

      <la-dn-ninja-cart-item-messages line-item-key="{{ line-item-key }}">
        <la-dn-cart-item-text
          ninja-cart="true"
          size="extra-small"
          key="{{ line-item-key }}"
          button-action="{{ item-message-action }}"
          open-in-context="{{ item-message-open-in-context }}"
          product-url="{{ item-message-product-url }}"
          page-url="{{ item-message-page-url }}"
          evaluate-javascript="{{ item-message-evaluate-javascript }}"
        >
          {{ item-message }}
        </la-dn-cart-item-text>
      </la-dn-ninja-cart-item-messages>
    </la-dn-ninja-cart-item>
  </template>

  <la-dn-ninja-cart-empty>
    <la-dn-ninja-cart-empty-header>${i18n.translate(
      'YourCart'
    )}</la-dn-ninja-cart-empty-header>

    <la-dn-ninja-cart-empty-content>
      <la-dn-ninja-cart-empty-image image-url="" image-alt="Empty cart"></la-dn-ninja-cart-empty-image>

      <la-dn-ninja-cart-empty-text>
        <la-dn-ninja-cart-empty-text-headline>${headlineEmpty}</la-dn-ninja-cart-empty-text-headline>

        <la-dn-ninja-cart-empty-text-paragraph>${paragraph}</la-dn-ninja-cart-empty-text-paragraph>
      </la-dn-ninja-cart-empty-text>

      <la-dn-ninja-cart-empty-cta-button
        button-action="close"
        redirect-url="https://app.gitbook.com/o/AIERSytml2lDyLC5sT49/s/nc8R8Y1YkRBQd1GRzZwI/discount-ninja-developer-hub/storefront-api"
        open-in-context="true"
      >
      ${ctaButtonEmpty}
      </la-dn-ninja-cart-empty-cta-button>
    </la-dn-ninja-cart-empty-content>
  </la-dn-ninja-cart-empty>

  <la-dn-ninja-cart-header layout-type="1">
    <la-dn-ninja-cart-header-title>
    ${heading || ''}
    </la-dn-ninja-cart-header-title>

    <la-dn-ninja-cart-header-item-count style-type="${formatStringQuotes(
      itemCountStyle
    )}" item-count="${formatStringQuotes(
        cartItems.length.toString()
      )}"></la-dn-ninja-cart-header-item-count>
  </la-dn-ninja-cart-header>

  <la-dn-ninja-cart-content>
    <la-dn-ninja-cart-items>
        ${cartItems
          .map(
            (item: any, idx: number) =>
              `
            <la-dn-ninja-cart-item line-item-key="a:${idx}" index="${idx}">
          <la-dn-ninja-cart-item-image
            slot="image"
            product-url=""
            image-url=""
            image-alt="Product"
          >
            <la-dn-promotional-badge
              offer-token="A"
              line-item-key="a:${idx}"
              discount-percentage="${idx === 0 ? 20 : 0}"
              offset="[4, 4]"
            ></la-dn-promotional-badge>
          </la-dn-ninja-cart-item-image>

          <la-dn-ninja-cart-item-header
            line-item-key="a:${idx}"
            remove-accessibility-label="Remove product"
            options-option="line"
            options-separate-line="false"
            options-prefix="true"
            vendor-option="line"
            vendor-separate-line="false"
            properties-option="line"
            properties-separate-line="false"
            separator="comma"
            options='[]'
            vendor="Web Components Sandbox"
            properties='{}'
          >
            <la-dn-ninja-cart-item-title product-url="">Product name</la-dn-ninja-cart-item-title>
          </la-dn-ninja-cart-item-header>

          <la-dn-ninja-cart-item-pricing>
            <la-dn-ninja-cart-item-quantity
              line-item-key="a:${idx}"
              increase-accessibility-label="Increase quantity for product"
              decrease-accessibility-label="Decrease quantity for product"
              input-accessibility-label="Quantity for product"
              select-accessibility-label="Select quantity for product"
              variant-type="1"
              value="1"
              min-value="1"
              max-value="100"
              step="1"
            ></la-dn-ninja-cart-item-quantity>

            <la-dn-price
              location="drawer-cart"
              level="line"
              line-item-key="a:${idx}"
              available="true"
              discounted="${idx === 0}"
              not-discounted-layout="[<div style='display: flex; align-items: flex-end; flex-direction: column;'><la-dn-money style='font-size: 13px; font-weight: 450; color: #303030; line-height: 20px;' amount='1500' currency='USD' include-currency-code='true' include-currency-symbol='true' include-trailing-zeros='true' precision='2' zero-is-free='true' cents-superscript='false' hide-decimal-separator='false' use-from-label='false' use-sold-out='false' price-strikethrough='none'></la-dn-money></div>]"
              discounted-layout="[<div style='display: flex; align-items: flex-end; flex-direction: column;'><la-dn-money style='font-size: 13px; font-weight: 450; color: #303030; line-height: 20px;' amount='1500' currency='USD' include-currency-code='true' include-currency-symbol='true' include-trailing-zeros='true' precision='2' zero-is-free='true' cents-superscript='false' hide-decimal-separator='false' use-from-label='false' use-sold-out='false' price-strikethrough='none'></la-dn-money> <la-dn-money style='font-size: 12px; font-weight: 450; color: #B5B5B5; line-height: 16px;' amount='2000' currency='USD' include-currency-code='true' include-currency-symbol='true' include-trailing-zeros='true' precision='2' zero-is-free='true' cents-superscript='false' hide-decimal-separator='false' use-from-label='false' use-sold-out='false' price-strikethrough='horizontal'></la-dn-money></div>]"
            ></la-dn-price>
          </la-dn-ninja-cart-item-pricing>

          <la-dn-ninja-cart-item-messages line-item-key="a:${idx}">
            ${
              idx === 0
                ? `<la-dn-cart-item-text
              ninja-cart="true"
              size="extra-small"
              key="a:${idx}"
              button-action="product"
              open-in-context="true"
              product-url="product"
              page-url="cart"
              evaluate-javascript="console.log('eval')"
            >Cart text</la-dn-cart-item-text>`
                : ''
            }
          </la-dn-ninja-cart-item-messages>
        </la-dn-ninja-cart-item>
            `
          )
          .join('')}
    </la-dn-ninja-cart-items>
  </la-dn-ninja-cart-content>

  <la-dn-ninja-cart-footer sticky="true">
    <la-dn-promotion-summary
      is-hidden="true"
      collapsed="true"
      ninja-cart="true"
    >
      <la-dn-promotion-summary-total label="${formatStringQuotes(
        currentTotal
      )}">
        <la-dn-price
          location="drawer-cart"
          level="total"
          available="true"
          layout="[<la-dn-money amount='76000' currency='USD' include-currency-code='true' include-currency-symbol='true' include-trailing-zeros='true' precision='2' zero-is-free='true' cents-superscript='false' hide-decimal-separator='false' use-from-label='false' use-sold-out='false' price-strikethrough='none'></la-dn-money>]"
        ></la-dn-price>
      </la-dn-promotion-summary-total>

      <la-dn-promotion-summary-breakdown>
        <la-dn-promotion-summary-subtotal label="${formatStringQuotes(
          currentSubTotal
        )}">
          <la-dn-price
            location="drawer-cart"
            level="total"
            available="true"
            layout="[<la-dn-money amount='80000' currency='USD' include-currency-code='true' include-currency-symbol='true' include-trailing-zeros='true' precision='2' zero-is-free='true' cents-superscript='false' hide-decimal-separator='false' use-from-label='false' use-sold-out='false' price-strikethrough='none'></la-dn-money>]"
          ></la-dn-price>
        </la-dn-promotion-summary-subtotal>

        <la-dn-promotion-summary-shipping label="${formatStringQuotes(
          currentShipping
        )}">
        </la-dn-promotion-summary-shipping>

        <la-dn-promotion-summary-taxes label="${formatStringQuotes(
          currentTaxes
        )}">
          ${currentDescription}
        </la-dn-promotion-summary-taxes>
      </la-dn-promotion-summary-breakdown>

      <la-dn-promotion-summary-discounts>
      ${currentDiscounts}
      </la-dn-promotion-summary-discounts>

      <la-dn-promotion-summary-disclaimer>
      ${currentDisclaimer}
      </la-dn-promotion-summary-disclaimer>
    </la-dn-promotion-summary>

    <la-dn-ninja-cart-terms-and-conditions>${i18n.translate(
      'TermsAndConditions'
    )} <a href="https://sandbox-angry-nerds.myshopify.com/products/bow-tie" target="_blank">${i18n.translate(
        'TermsAndConditionsLink'
      )}</a></la-dn-ninja-cart-terms-and-conditions>

    <la-dn-ninja-cart-cta-buttons>
      <form action="/cart?discount=+&remove_discount_code=1" method="post">
        <button
          style="position:absolute!important;overflow:hidden;width:1px;height:1px;margin:-1px;padding:0;border:0;clip:rect(0 0 0 0);word-wrap:normal!important;"
          tabindex="-1"
          aria-hidden="true"
          type="submit"
          name="checkout"
        ></button>

        <la-dn-ninja-cart-cta-button
          cta-button="1"

          button-action="checkout"
          terms-validation="true"
          redirect-url="https://app.gitbook.com/o/AIERSytml2lDyLC5sT49/s/nc8R8Y1YkRBQd1GRzZwI/discount-ninja-developer-hub/storefront-api"
          open-in-context="true"
          evaluate-javascript="console.log('eval')"

          prefix="true"
          prefix-icon=""
          suffix="false"
          suffixAmount="1000"
        >
          ${ctaButton1Label}
        </la-dn-ninja-cart-cta-button>

        <la-dn-ninja-cart-cta-button
          cta-button="2"

          button-action="redirect"
          terms-validation="false"
          redirect-url="https://app.gitbook.com/o/AIERSytml2lDyLC5sT49/s/nc8R8Y1YkRBQd1GRzZwI/discount-ninja-developer-hub/storefront-api"
          open-in-context="true"
          evaluate-javascript="console.log('eval')"

          prefix="true"
          prefix-icon=""
          suffix="false"
          suffixAmount="1000"
        >
          ${ctaButton2Label}
        </la-dn-ninja-cart-cta-button>
      </form>
    </la-dn-ninja-cart-cta-buttons>

<!--    <la-dn-ninja-cart-payment-methods-->
<!--      method-mastercard="true"-->
<!--      method-american-express="true"-->
<!--      method-visa="true"-->
<!--      method-paypal="true"-->
<!--    ></la-dn-ninja-cart-payment-methods>-->

    <la-dn-ninja-cart-continue-shopping>${i18n.translate(
      'ContinueShopping'
    )}</la-dn-ninja-cart-continue-shopping>
  </la-dn-ninja-cart-footer>
</la-dn-ninja-cart>
    `,
    [
      adminActivePath,
      i18n,
      currentIndex,
      cartItems,
      heading,
      itemCountStyle,
      currentTotal,
      currentSubTotal,
      currentShipping,
      currentDescription,
      currentTaxes,
      currentDiscounts,
      currentDisclaimer,
      ctaButton1Label,
      ctaButton2Label,
      headlineEmpty,
      paragraph,
      ctaButtonEmpty,
    ]
  );

  const showNinjaCart = useCallback(() => {
    const parser = new DOMParser();
    const container = document.querySelector('#ninja-cart-root');

    const ninjaCart = parser
      .parseFromString(elementToReplaceWith, 'text/html')
      .querySelector('la-dn-ninja-cart');

    setTriggerRenderCount((prev) => prev + 1);

    if (
      container &&
      ninjaCart &&
      !container.querySelector('la-dn-ninja-cart')
    ) {
      container?.appendChild(ninjaCart);
    } else if (
      container &&
      ninjaCart &&
      container.querySelector('la-dn-ninja-cart')
    ) {
      const currentNinjaCart = container.querySelector('la-dn-ninja-cart');

      if (currentNinjaCart) {
        const heading = currentNinjaCart.querySelector(
          'la-dn-ninja-cart-header-title'
        );

        const headline = currentNinjaCart.querySelector(
          'la-dn-ninja-cart-empty-text-headline'
        );

        const paragraph = currentNinjaCart.querySelector(
          'la-dn-ninja-cart-empty-text-paragraph'
        );

        const ctaButtonEmpty = currentNinjaCart.querySelector(
          'la-dn-ninja-cart-empty-cta-button'
        );

        const summaryTotal = currentNinjaCart.querySelector(
          'la-dn-promotion-summary-total'
        );

        const summarySubTotal = currentNinjaCart.querySelector(
          'la-dn-promotion-summary-subtotal'
        );

        const summaryShipping = currentNinjaCart.querySelector(
          'la-dn-promotion-summary-shipping'
        );

        const summaryTaxes = currentNinjaCart.querySelector(
          'la-dn-promotion-summary-taxes'
        );

        const discount = currentNinjaCart.querySelector(
          'la-dn-promotion-summary-discount'
        );

        const disclaimer = currentNinjaCart.querySelector(
          'la-dn-promotion-summary-disclaimer'
        );

        const CTAButton1 = currentNinjaCart.querySelector(
          'la-dn-ninja-cart-cta-button[cta-button="1"]'
        );

        const CTAButton2 = currentNinjaCart.querySelector(
          'la-dn-ninja-cart-cta-button[cta-button="2"]'
        );

        if (heading) {
          heading.innerHTML =
            ninjaCart.querySelector('la-dn-ninja-cart-header-title')
              ?.innerHTML || '';
        }

        if (CTAButton1) {
          CTAButton1.innerHTML =
            ninjaCart.querySelector(
              'la-dn-ninja-cart-cta-button[cta-button="1"]'
            )?.innerHTML || '';
        }
        if (CTAButton2) {
          CTAButton2.innerHTML =
            ninjaCart.querySelector(
              'la-dn-ninja-cart-cta-button[cta-button="2"]'
            )?.innerHTML || '';
        }

        if (headline) {
          headline.innerHTML =
            ninjaCart.querySelector('la-dn-ninja-cart-empty-text-headline')
              ?.innerHTML || '';
        }

        if (paragraph) {
          paragraph.innerHTML =
            ninjaCart.querySelector('la-dn-ninja-cart-empty-text-paragraph')
              ?.innerHTML || '';
        }

        if (disclaimer) {
          disclaimer.innerHTML =
            ninjaCart.querySelector('la-dn-promotion-summary-disclaimer')
              ?.innerHTML || '';
        }

        if (ctaButtonEmpty) {
          ctaButtonEmpty.innerHTML =
            ninjaCart.querySelector('la-dn-ninja-cart-empty-cta-button')
              ?.innerHTML || '';
        }

        if (summaryTotal) {
          summaryTotal?.setAttribute(
            'label',
            ninjaCart
              .querySelector('la-dn-promotion-summary-total')
              ?.getAttribute('label') || ''
          );
        }

        if (summarySubTotal) {
          summarySubTotal?.setAttribute(
            'label',
            ninjaCart
              .querySelector('la-dn-promotion-summary-subtotal')
              ?.getAttribute('label') || ''
          );
        }

        if (summaryShipping) {
          summaryShipping?.setAttribute(
            'label',
            ninjaCart
              .querySelector('la-dn-promotion-summary-shipping')
              ?.getAttribute('label') || ''
          );
        }

        if (discount) {
          discount?.setAttribute(
            'label',
            ninjaCart
              .querySelector('la-dn-promotion-summary-discount')
              ?.getAttribute('label') || ''
          );
        }

        if (summaryTaxes) {
          summaryTaxes?.setAttribute(
            'label',
            ninjaCart
              .querySelector('la-dn-promotion-summary-taxes')
              ?.getAttribute('label') || ''
          );
          summaryTaxes.innerHTML =
            ninjaCart.querySelector('la-dn-promotion-summary-taxes')
              ?.innerHTML || '';
        }
      }
    }
  }, [elementToReplaceWith, setTriggerRenderCount]);

  useEffect(() => {
    if (triggerRenderCount) {
      document.dispatchEvent(new CustomEvent('la:dn:promotion-summary:render'));
      setInjectStyles(true);
    }
  }, [triggerRenderCount]);

  useEffect(() => {
    showNinjaCart();
  }, [
    cartItems,
    heading,
    itemCountStyle,
    currentTotal,
    currentSubTotal,
    currentShipping,
    currentDescription,
    currentTaxes,
    currentDiscounts,
    currentDisclaimer,
    ctaButton1Label,
    ctaButton2Label,
    headlineEmpty,
    paragraph,
    ctaButtonEmpty,
  ]);

  useEffect(() => {
    if (isOpen) {
      document.dispatchEvent(new CustomEvent('la:dn:ninja-cart:open'));
      document.dispatchEvent(new CustomEvent('la:dn:promotion-summary:render'));
      document
        .querySelector('la-dn-ninja-cart')
        ?.addEventListener('la:dn:ninja-cart:admin-change', (e) =>
          getSelectionFromThePreview((e as CustomEvent).detail)
        );
      document
        .querySelector('la-dn-ninja-cart')
        ?.addEventListener('la:dn:ninja-cart:state-change', (e) =>
          handleSwitchWidgetState?.(
            'ninjaCart',
            (e as CustomEvent).detail === 'empty'
              ? [NinjaCartStatesPresetEntryTypeDtoEnum.EMPTY_STATE]
              : [NinjaCartStatesPresetEntryTypeDtoEnum.DEFAULT_STATE]
          )
        );
      setInjectStyles(true);
    } else {
      document.dispatchEvent(new CustomEvent('la:dn:ninja-cart:close'));
    }

    return () => {
      document.dispatchEvent(new CustomEvent('la:dn:ninja-cart:close'));
    };
  }, [isOpen]);

  useEffect(() => {
    if (injectStyles) {
      laDnNinjaCart?.setAttribute('image-assets', JSON.stringify(assetsArray));
    }
  }, [assetsArray, injectStyles]);

  useEffect(() => {
    if (injectStyles) {
      laDnNinjaCart?.setAttribute('font-assets', JSON.stringify(fontListData));
    }
  }, [fontListData, injectStyles]);

  useEffect(() => {
    if (injectStyles) {
      laDnNinjaCart?.setAttribute('styles', JSON.stringify(data));
    }
  }, [injectStyles, data]);

  useEffect(() => {
    if (injectStyles) {
      laDnNinjaCart?.setAttribute(
        'force-mobile',
        (previewType === DeviceTypeDtoEnum.MOBILE).toString()
      );
    }
  }, [injectStyles, previewType]);

  useEffect(() => {
    if (injectStyles) {
      laDnNinjaCart?.setAttribute('admin-active-path', adminActivePath);
    }
  }, [adminActivePath]);

  return <div id='ninja-cart-root'></div>;
};
