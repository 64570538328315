import React, { useCallback, useMemo } from 'react';
import { Checkbox, Divider } from '@shopify/polaris';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import {
    LabelsStyletDtoEnum,
    SeparatorStyleDtoEnum,
    TimeFormatDtoEnum,
} from '../../enums/enums';
import { useI18n } from '@shopify/react-i18n';
import { SmartTagSettingsTypeDto } from '../../SmartTagSettings';
import StyleComponentHeader from '../../../StyleComponentHeader/StyleComponentHeader';
import { SmartTagGroupEnum } from 'core/api/adminPromotions/adminPromotionsEnums';

type SmartTagCountdownClockSettingsProps = {
    config: SmartTagSettingsTypeDto;
    setConfig: (data: SmartTagSettingsTypeDto) => void;
};

export const SmartTagCountdownClockSettings: React.FC<
    SmartTagCountdownClockSettingsProps
> = ({ config, setConfig }) => {
    const [i18n] = useI18n();

    const timeFormatOptions = useMemo(
        () =>
            Object.entries(TimeFormatDtoEnum).map(([key, value]) => {
                return {
                    value: value,
                    label: i18n.translate(`${key}_TIME`),
                };
            }),
        [i18n]
    );

    const labelsStyleOptions = useMemo(
        () =>
            Object.entries(LabelsStyletDtoEnum).map(([key, value]) => {
                return {
                    value: value,
                    label: i18n.translate(`${key}_LABEL`),
                };
            }),
        [i18n]
    );

    const separatorsStyleOptions = useMemo(
        () =>
            Object.entries(SeparatorStyleDtoEnum).map(([key, value]) => {
                return {
                    value: value,
                    label: i18n.translate(`${key}_SEPARATOR`),
                };
            }),
        [i18n]
    );

    const handleUpdateConfig = useCallback(
        (field: string, data: any) => {
            setConfig({ ...config, [field]: data });
        },
        [config]
    );
    return (
        <div className='SmartTagPriceSettings'>
            <StyleComponentHeader label={i18n.translate('Type')}>
                <SelectOptions
                    label=''
                    disabled
                    options={[
                        {
                            value: SmartTagGroupEnum.TIME,
                            label: i18n.translate(SmartTagGroupEnum.TIME),
                        },
                    ]}
                    selectedOption={SmartTagGroupEnum.TIME}
                    onOptionSelect={() => null}
                />
            </StyleComponentHeader>
            <Divider borderColor='border-brand' />
            <StyleComponentHeader label={i18n.translate('TimeFormat')}>
                <SelectOptions
                    label=''
                    options={timeFormatOptions}
                    selectedOption={config['time-format']}
                    onOptionSelect={(value) => handleUpdateConfig('time-format', value)}
                />
            </StyleComponentHeader>
            <Divider borderColor='border-brand' />
            <StyleComponentHeader label={i18n.translate('Labels')}>
                <Checkbox
                    label={i18n.translate('DisplayLabels')}
                    checked={config['display-labels']}
                    onChange={(value) => handleUpdateConfig('display-labels', value)}
                />
                {config['display-labels'] && (
                    <SelectOptions
                        label=''
                        options={labelsStyleOptions}
                        selectedOption={config['display-labels-format']}
                        onOptionSelect={(value) =>
                            handleUpdateConfig('display-labels-format', value)
                        }
                    />
                )}
            </StyleComponentHeader>

            <Divider borderColor='border-brand' />
            <StyleComponentHeader label={i18n.translate('Separators')}>
                <Checkbox
                    label={i18n.translate('EnableSeparators')}
                    checked={config['enable-separators']}
                    onChange={(value) => handleUpdateConfig('enable-separators', value)}
                />
                {config['enable-separators'] && (
                    <SelectOptions
                        label=''
                        options={separatorsStyleOptions}
                        selectedOption={config['separators-style']}
                        onOptionSelect={(value) =>
                            handleUpdateConfig('separators-style', value)
                        }
                    />
                )}
            </StyleComponentHeader>

            <Divider borderColor='border-brand' />
        </div>
    );
};
