import { useCallback, useRef } from 'react';
import { useAppSelector } from 'core/hooks';
import {
  SettingsFormValidity,
  setPromotionSettingsValidityState,
} from 'core/store/promotionsSlice';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import { PromotionSettingsDto } from 'core/api/adminPromotions/adminPromotionsApi';

export const useSettingsFormValidity = () => {
  const dispatch = useDispatch();
  const formValidity = useAppSelector(
    (stores) => stores.promotions.formValidity
  );
  const formValidityControlArray = useRef<SettingsFormValidity[]>([]);

  const validityChangeHandler = useCallback(
    (sectionName: keyof PromotionSettingsDto) => (isValid: boolean) => {
      const formValidityArr = cloneDeep(formValidity);
      const currentSectionIndex = formValidityArr.findIndex(
        (el: SettingsFormValidity) => el.sectionName === sectionName
      );
      if (currentSectionIndex > -1) {
        formValidityArr[currentSectionIndex].isValid = isValid;
      } else {
        formValidityArr.push({
          sectionName: sectionName,
          isValid: isValid,
        });
      }

      formValidityControlArray.current = [...formValidityArr];
      dispatch(setPromotionSettingsValidityState(formValidityArr));
    },
    [formValidity, dispatch]
  );

  return {
    validityChangeHandler,
    formValidityControlArray,
  };
};
