import { BlockStack, Checkbox, ChoiceList, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { PromotionSummaryDiscountShopSettingsDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { PromotionSummaryCalculationsBehaviorDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { ShopSettingsWrapper } from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components';
import StyleComponentHeader from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components/StyleComponentHeader/StyleComponentHeader';
import React from 'react';
type DiscountShopSettingsProps = {
  shopSettings: PromotionSummaryDiscountShopSettingsDto;
  disabled?: boolean;
};
const DiscountShopSettings: React.FC<DiscountShopSettingsProps> = ({
  shopSettings,
  disabled,
}) => {
  const [i18n] = useI18n();
  const discountCalculationOptions = Object.values(
    PromotionSummaryCalculationsBehaviorDtoEnum
  ).map((value) => {
    return {
      value: value,
      label: i18n.translate(`${value}`),
    };
  });
  return (
    <div className='DiscountShopSettings'>
      {' '}
      <ShopSettingsWrapper>
        <div className='RightSideSection'>
          <StyleComponentHeader
            buttons={[]}
            label={i18n.translate('TotalDiscountCalculation')}
          >
            <ChoiceList
              title=''
              choices={discountCalculationOptions}
              disabled={disabled}
              selected={[shopSettings?.calculations as string]}
            />
            <Text as='p'>{i18n.translate('DiscountSettings')}</Text>
            <BlockStack gap='100'>
              <Checkbox
                disabled={disabled}
                checked={!!shopSettings?.displaySettings?.showTotal}
                label={i18n.translate('ShowTotal')}
              />
              <Checkbox
                disabled={disabled}
                checked={!!shopSettings?.displaySettings?.showDiscountBreakdown}
                label={i18n.translate('ShowBreakdown')}
              />
            </BlockStack>
          </StyleComponentHeader>
        </div>
        <div className='RightSideSection'>
          <StyleComponentHeader
            buttons={[]}
            label={i18n.translate('FreeGiftOptions')}
          >
            <Checkbox
              disabled={disabled}
              checked={!!shopSettings?.considerFreeGiftAsDiscount}
              label={i18n.translate('ConsiderFreeGiftAsDiscount')}
            />
          </StyleComponentHeader>
        </div>
      </ShopSettingsWrapper>
    </div>
  );
};

export default DiscountShopSettings;
