import { LegacyCard, DataTable } from '@shopify/polaris';
import { EmptyDataTable, Loader } from 'core/components';
import { PromotionReportType } from 'features/reporting/types';
import React, { useMemo } from 'react';

type PromotionResultsTableProps = {
  data: PromotionReportType[];
  isLoading?: boolean;
};

export const PromotionResultsTable: React.FC<PromotionResultsTableProps> = (
  props
) => {
  const { data, isLoading } = props;

  const rows = useMemo(
    () =>
      data.map((item) => [
        item.Promotion,
        item.Impressions,
        item.AddToCarts,
        item.Checkouts,
        item.InitiatedCheckouts,
        item.OrderAmount,
      ]),
    [data]
  );

  const hasData = data.length > 0;

  return (
    <>
      {isLoading && <Loader fullWidth />}
      <LegacyCard>
        {!isLoading && data && (
          <DataTable
            columnContentTypes={[
              'text',
              'numeric',
              'numeric',
              'numeric',
              'numeric',
              'numeric',
            ]}
            headings={[
              'Promotion',
              'Sessions',
              'Carts',
              'Checkouts',
              'Orders',
              'Revenue',
            ]}
            rows={rows}
          />
        )}
        {!isLoading && !hasData && <EmptyDataTable />}
      </LegacyCard>
    </>
  );
};
