import { Icon, IconSource } from '@shopify/polaris';
import classNames from 'classnames';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import './InputWithTags.scss';
import { XSmallIcon, AlertCircleIcon } from '@shopify/polaris-icons';
import { detectDomainName } from 'core/utils';
import { useI18n } from '@shopify/react-i18n';

export type InputWithTagsProps = {
  title?: string;
  data?: string[];
  placeholder?: string;
  isNotRequired?: boolean;
  isDomain?: boolean;
  tagsToPrefill?: string[];
  onListChange: (list: string[]) => void;
  onFormValidityChange?(valid: boolean): void;
  validationText?: string;
  formatAsEmail?: boolean;
};

export const InputWithTags: React.FC<InputWithTagsProps> = (props) => {
  const {
    placeholder,
    onListChange,
    onFormValidityChange,
    title,
    isDomain = false,
    isNotRequired = false,
    tagsToPrefill,
    validationText,
    formatAsEmail,
  } = props;
  const [i18n] = useI18n();
  const [text, setText] = useState<string>('');
  const [tagList, setTagList] = useState<string[]>(
    tagsToPrefill?.length ? [...tagsToPrefill] : []
  );
  const [keyAdded, setKeyAdded] = useState<boolean>(false);
  const [isInputDirty, setIsInputDirty] = useState<boolean>(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const validateEmail = useCallback((text: string) => {
    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (text.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  }, []);

  const handleTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value.slice(-1) !== ',') {
        formatAsEmail ? setIsEmailValid(true) : null;
        setText(event.target.value);
      }
    },
    [setText, formatAsEmail]
  );

  const updateTags = useCallback(
    (onBlur?: boolean) => {
      setTagList((prev: string[]) => prev.concat(text));
      onListChange(tagList.concat(text));
      setKeyAdded(true);
      onBlur ? setText('') : null;
    },
    [setTagList, text, setKeyAdded, tagList]
  );

  const addTag = useCallback(
    (event?: React.KeyboardEvent<HTMLElement>, onBlur?: boolean) => {
      if (isDomain) {
        if (event?.key === 'Enter' && text && detectDomainName(text)) {
          updateTags();
        }
      } else if (
        (event?.code === 'Comma' || event?.code === 'Enter' || onBlur) &&
        text
      ) {
        if (formatAsEmail) {
          validateEmail(text) && tagList.length < 20
            ? updateTags(onBlur)
            : setIsEmailValid(false);
        } else {
          updateTags(onBlur);
        }
      }
    },
    [
      setTagList,
      text,
      detectDomainName,
      setKeyAdded,
      tagList,
      updateTags,
      setIsEmailValid,
    ]
  );

  const resetInput = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.code === 'Enter' || event.code === 'Comma') {
        if (keyAdded) {
          setText('');
          setKeyAdded(false);
        }
      }
    },
    [setText, keyAdded]
  );

  const removeTag = useCallback(
    (tag: string) => {
      const updatedList = tagList.filter((ele: string) => ele !== tag);
      setTagList(updatedList);
      onListChange(updatedList);
      if (!isInputDirty) {
        setIsInputDirty(true);
      }
    },
    [setTagList, isInputDirty, setIsInputDirty, tagList, onListChange]
  );

  useEffect(() => {
    setTagList(tagsToPrefill || []);
  }, [tagsToPrefill]);

  const isInvalid = useMemo(
    () => !isNotRequired && isInputDirty && !tagList.length,
    [isNotRequired, isInputDirty, tagList]
  );

  useEffect(() => {
    onFormValidityChange?.(!isInvalid);
  }, [isInvalid]);

  return (
    <div className='InputWithTagsWrapper'>
      <div className='Title'>{title}</div>
      <div
        className={classNames('InputWithTags', {
          Invalid: isInvalid || !isEmailValid,
        })}
      >
        {tagList.map((tag: string, index: number) => (
          <div
            className='ShopifyAppBridgeModalInitiatorSearchFieldTag'
            key={index}
          >
            <span>{tag}</span>
            <div onClick={() => removeTag(tag)}>
              <Icon source={XSmallIcon as IconSource} />
            </div>
          </div>
        ))}
        <input
          ref={inputRef}
          onChange={handleTextChange}
          onKeyDown={addTag}
          onKeyUp={resetInput}
          value={text}
          placeholder={tagList.length ? '' : placeholder}
          autoComplete='off'
          onBlur={() => addTag(undefined, true)}
        />
      </div>
      {isInvalid && validationText ? (
        <p className='InputWithTagsErrorBox'>
          <Icon source={AlertCircleIcon as IconSource} tone='critical' />
          {validationText}
        </p>
      ) : null}
      {!isEmailValid ? (
        <p className='InputWithTagsErrorBox'>
          <Icon source={AlertCircleIcon as IconSource} tone='critical' />
          {!isEmailValid &&
            tagList.length < 20 &&
            i18n.translate('InvalidEmail')}
          {!isEmailValid &&
            tagList.length >= 20 &&
            i18n.translate('MaximumEmails')}
        </p>
      ) : null}
    </div>
  );
};
