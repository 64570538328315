import React from 'react';
import {
  Card,
  BlockStack,
  Text,
  InlineStack,
  Link,
  Icon,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { ExternalIcon } from '@shopify/polaris-icons';

export const PrivacyPolicy = () => {
  const [i18n] = useI18n();

  return (
    <div className='PrivacyPolicyComponent'>
      <Card roundedAbove='sm' padding='400'>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='h2' variant='headingSm'>
              {i18n.translate('PrivacyPolicyTitle')}
            </Text>
            <InlineStack gap='100'>
              <Text as='p' tone='subdued'>
                {i18n.translate('PrivacyPolicySubtitle.0')}
              </Text>
              <Link
                url=' https://support.discountninja.io/discount-ninja-support-center/legal/terms-and-conditions'
                target='_blank'
              >
                {i18n.translate('PrivacyPolicySubtitle.1')}
              </Link>
              <Text as='p' tone='subdued'>
                {i18n.translate('PrivacyPolicySubtitle.2')}
              </Text>
              <Link
                url='https://support.discountninja.io/discount-ninja-support-center/legal/privacy-policy'
                target='_blank'
              >
                {i18n.translate('PrivacyPolicySubtitle.3')}
              </Link>
            </InlineStack>
          </BlockStack>
          <Card roundedAbove='sm' padding='400'>
            <BlockStack gap='100'>
              <InlineStack align='space-between'>
                <Text as='h2' variant='headingSm'>
                  {i18n.translate('GDPRTitle')}
                </Text>{' '}
                <Link
                  url='https://support.discountninja.io/discount-ninja-support-center/legal/gdpr-statement'
                  target='_blank'
                  removeUnderline
                >
                  <InlineStack gap='100'>
                    <Text as='p' variant='headingSm' fontWeight='regular'>
                      {i18n.translate('Read')}
                    </Text>{' '}
                    <Icon source={ExternalIcon} />
                  </InlineStack>
                </Link>
              </InlineStack>
              <Text as='p' tone='subdued'>
                {i18n.translate('GDPRSubtitle')}
              </Text>
            </BlockStack>
          </Card>
        </BlockStack>
      </Card>
    </div>
  );
};
