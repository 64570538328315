import React, { useCallback } from 'react';
import { PromotionCodeFieldCodeInputPresetDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { SimplifiedTextEntries, TextStyle } from '../../../../../components';
import { useI18n } from '@shopify/react-i18n';
import { Link, Text } from '@shopify/polaris';
import PromotionCodeBehavior from './components/PromotionCodeBehavior/PromotionCodeBehavior';
import { isEqual } from 'lodash';

type PromotionCodeInputCodeProps = {
  codeInput: PromotionCodeFieldCodeInputPresetDto;
  defaultCodeInput: PromotionCodeFieldCodeInputPresetDto;
  shopDefaultCodeInput: PromotionCodeFieldCodeInputPresetDto;
  defaultLanguage: string;
  isPresetEditor?: boolean;
  setCodeInput: (data: PromotionCodeFieldCodeInputPresetDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const PromotionCodeInputCode: React.FC<PromotionCodeInputCodeProps> = ({
  codeInput,
  defaultCodeInput,
  shopDefaultCodeInput,
  defaultLanguage,
  isPresetEditor,
  setCodeInput,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();

  const resetAllToSystemDefault = useCallback(() => {
    const { placeholder } = shopDefaultCodeInput;
    setCodeInput({ ...codeInput, placeholder });
  }, [shopDefaultCodeInput, codeInput]);

  return (
    <div className='PromotionCodeInputCode'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={() => setCodeInput(defaultCodeInput)}
        onResetToSystem={resetAllToSystemDefault}
        name={WidgetListOptionsDtoEnum.CODE_INPUT}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <SimplifiedTextEntries
          message={codeInput.placeholder?.message || {}}
          title={i18n.translate('FieldPlaceholder')}
          messageLabel={i18n.translate('Placeholder')}
          defaultLanguage={defaultLanguage}
          inherited={
            !isPresetEditor
              ? isEqual(codeInput.placeholder, defaultCodeInput.placeholder)
              : undefined
          }
          setMessage={(data) =>
            setCodeInput({
              ...codeInput,
              placeholder: {
                ...codeInput.placeholder,
                message: data,
              },
            })
          }
          onSystemReset={() =>
            setCodeInput({
              ...codeInput,
              placeholder: {
                ...codeInput.placeholder,
                message: shopDefaultCodeInput.placeholder?.message,
              },
            })
          }
        />
        <TextStyle
          textStyle={codeInput.placeholder?.style || {}}
          onSystemReset={() =>
            setCodeInput({
              ...codeInput,
              placeholder: {
                ...codeInput.placeholder,
                style: shopDefaultCodeInput.placeholder?.style,
              },
            })
          }
          setTextStyle={(data) =>
            setCodeInput({
              ...codeInput,
              placeholder: {
                ...codeInput.placeholder,
                style: data,
              },
            })
          }
          hideHeader
          onlyColor
        />
      </div>
      <div className='EmptyRightSideBanner'>
        <div className='RightSideSection'>
          <Text as='p' tone='subdued'>
            {i18n.translate('UIControls')}
            <Link>{i18n.translate('NinjaCart')}</Link>
            {i18n.translate('Block')}
          </Text>
        </div>
      </div>
      <div className='RightSideSection'>
        <PromotionCodeBehavior
          behavior={codeInput.shopSettings || {}}
          disabled
        />
      </div>
    </div>
  );
};

export default PromotionCodeInputCode;
