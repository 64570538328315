import React from 'react';
import './SettingsSkeleton.scss';
import {
    SkeletonPage,
    Card,
    SkeletonBodyText,
    SkeletonDisplayText,
    BlockStack,
} from '@shopify/polaris';

export const SettingsSkeleton = () => {
    return (
        <div className='SettingsSkeleton'>
            <SkeletonPage primaryAction>
                <BlockStack gap='500'>
                    <Card>
                        <BlockStack gap='400'>
                            <SkeletonDisplayText size="small" />
                            <SkeletonBodyText lines={6} />
                        </BlockStack>
                    </Card>
                    <Card >
                        <BlockStack gap='400'>
                            <SkeletonDisplayText size="small" />
                            <SkeletonBodyText lines={4} />
                        </BlockStack>
                    </Card>
                    <Card >
                        <BlockStack gap='400'>
                            <SkeletonDisplayText size="small" />
                            <SkeletonBodyText lines={2} />
                        </BlockStack>
                    </Card>
                    <Card>
                        <BlockStack gap='400'>
                            <SkeletonDisplayText size="small" />
                            <SkeletonBodyText lines={6} />
                        </BlockStack>
                    </Card>
                    <Card>
                        <BlockStack gap='400'>
                            <SkeletonDisplayText size="small" />
                            <SkeletonBodyText lines={3} />
                        </BlockStack>
                    </Card>
                </BlockStack>
            </SkeletonPage>
        </div>

    );
}