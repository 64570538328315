import React, { useCallback } from 'react';
import { useI18n } from '@shopify/react-i18n';
import { Badge, BlockStack, Card, Link, Text } from '@shopify/polaris';
import './ConfigureLanguages.scss';
import { TranslationsMainSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
type translationMainData = {
  translationMainData: TranslationsMainSettingsDto;
  isMaxModal: boolean;
};

export const ConfigureLanguages: React.FC<translationMainData> = ({
  translationMainData,
  isMaxModal,
}) => {
  const [i18n] = useI18n();

  const sendMessageToMainApp = (message: any) => {
    window.opener?.postMessage(message, location.origin);
  };

  const navigateToShopifyLanguages = useCallback(() => {
    isMaxModal
      ? sendMessageToMainApp({ type: 'NAVIGATE_TO_LANGUAGES' })
      : window.open('shopify://admin/settings/languages', '_blank');
  }, [isMaxModal]);

  return (
    <div className='ConfigureLanguages'>
      <Card roundedAbove='sm' padding='400'>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='h2' variant='headingSm'>
              {i18n.translate('ConfigureLanguages')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('ConfigureLanguagesDescription', {
                languageSettings: (
                  <Link onClick={navigateToShopifyLanguages}>
                    {i18n.translate('LanguagesSettings')}
                  </Link>
                ),
              })}
            </Text>
          </BlockStack>
          <Card roundedAbove='sm' padding='300'>
            <BlockStack gap='300'>
              {translationMainData?.locales?.map((item) => (
                <React.Fragment key={item.locale}>
                  {' '}
                  <Text as='p' variant={'headingSm'}>
                    {item?.name}
                    {item.isDefault && (
                      <span className={'Badge'}>
                        <Badge>{i18n.translate('Default')}</Badge>
                      </span>
                    )}
                  </Text>{' '}
                </React.Fragment>
              ))}
            </BlockStack>
          </Card>
        </BlockStack>
      </Card>
    </div>
  );
};
