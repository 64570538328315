import { Checkbox, Text } from '@shopify/polaris';
import React from 'react';
import './CombineWithModalTile.scss';
import { useI18n } from '@shopify/react-i18n';

export type CombineWithModalTileProps = {
  title?: string;
  titleToken?: string;
  subTitle?: string;
  checked?: boolean;
  id?: string;
  isGroup?: boolean;
  checkboxChanged: (checked: boolean, id: string) => void;
};

export const CombineWithModalTile: React.FC<CombineWithModalTileProps> = (
  props
) => {
  const {
    title,
    subTitle,
    checked,
    id,
    titleToken = '',
    checkboxChanged,
    isGroup = false,
  } = props;

  const [i18n] = useI18n();

  return (
    <div className='CombineWithModalTile'>
      <Checkbox
        label=''
        checked={checked}
        id={id}
        onChange={checkboxChanged}
      ></Checkbox>
      <div className='CombineWithModalTileText'>
        <h5>{`${title} ${titleToken}`}</h5>
        {subTitle && (
          <Text as='span' tone='subdued'>
            {subTitle}
          </Text>
        )}
      </div>
      {isGroup && <div className='hasGroup'>{i18n.translate('Group')}</div>}
    </div>
  );
};
