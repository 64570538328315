export enum AnimationDtoEnum {
    NONE = "NONE",
    SLIDE = "SLIDE",
    SLIDE_IN = "SLIDE_IN",
    SLIDE_OUT = "SLIDE_OUT",
    SLIDE_OUT_TOP = "SLIDE_OUT_TOP",
    SLIDE_OUT_RIGHT = "SLIDE_OUT_RIGHT",
    SLIDE_OUT_LEFT = "SLIDE_OUT_LEFT",
    SLIDE_OUT_BOTTOM = "SLIDE_OUT_BOTTOM",
    FADE = "FADE",
    FADE_IN = "FADE_IN",
    FADE_OUT = "FADE_OUT",
    FADE_OUT_TOP = "FADE_OUT_TOP",
    FADE_OUT_RIGHT = "FADE_OUT_RIGHT",
    FADE_OUT_LEFT = "FADE_OUT_LEFT",
    FADE_OUT_BOTTOM = "FADE_OUT_BOTTOM",
    INSTANT = "INSTANT",
    BOUNCE = "BOUNCE",
    FLASH = "FLASH",
    SHAKE = "SHAKE",
    MOVE_IN = "MOVE_IN",
    MOVE_OUT = "MOVE_OUT"
}
export enum AnnouncementBarCyclingDurationTypeDtoEnum {
    LONG = "LONG",
    NORMAL = "NORMAL",
    SHORT = "SHORT",
    CUSTOM = "CUSTOM"
}
export enum AnnouncementBarIndicatorsStyleDtoEnum {
    DOTS = "DOTS",
    BARS = "BARS"
}
export enum AnnouncementBarPagePositionDtoEnum {
    ABOVE_HEADER = "ABOVE_HEADER",
    BELOW_HEADER = "BELOW_HEADER",
    BOTTOM_OF_PAGE = "BOTTOM_OF_PAGE"
}
export enum BillingFeeTypeDtoEnum {
    SUBSCRIPTION = "SUBSCRIPTION",
    SUBSCRIPTION_OVERAGE = "SUBSCRIPTION_OVERAGE",
    FIXED = "FIXED"
}
export enum BillingsSortFieldDtoEnum {
    ISSUED_AT = "ISSUED_AT"
}
export enum CancelSubscriptionPlanReasonDtoEnum {
    CANNOT_FIND_PROPER_PLAN = "CANNOT_FIND_PROPER_PLAN",
    APP_NOT_USED_ENOUGH = "APP_NOT_USED_ENOUGH",
    BETTER_ALTERNATIVE = "BETTER_ALTERNATIVE",
    TECHNICAL_ISSUES = "TECHNICAL_ISSUES",
    OTHER = "OTHER"
}
export enum CartRefreshBehaviorJavascriptMethodModeDtoEnum {
    DRAWER_CART = "DRAWER_CART",
    CART_PAGE = "CART_PAGE",
    DRAWER_CART_AND_CART_PAGE = "DRAWER_CART_AND_CART_PAGE"
}
export enum CheckoutDraftOrderRemovalOptionDtoEnum {
    HALF_HOUR = "HALF_HOUR",
    DAY = "DAY",
    THREE_DAYS = "THREE_DAYS",
    WEEK = "WEEK"
}
export enum CheckoutModeDtoEnum {
    SHOPIFY_FUNCTIONS = "SHOPIFY_FUNCTIONS",
    DRAFT_ORDER = "DRAFT_ORDER",
    DISCOUNT_CODE = "DISCOUNT_CODE"
}
export enum CountdownClockTimerUrgencyTypeDtoEnum {
    REAL = "REAL",
    FAKE = "FAKE"
}
export enum DeviceDisplayDtoEnum {
    ALL = "ALL",
    DESKTOP = "DESKTOP",
    MOBILE = "MOBILE"
}
export enum DiscountLinksBehaviorTypeDtoEnum {
    ANY_TAB_UNTIL_LINK_EXPIRES = "ANY_TAB_UNTIL_LINK_EXPIRES",
    TAB_WHERE_LINK_OPENED = "TAB_WHERE_LINK_OPENED"
}
export enum DiscountLinksExpirationDurationDtoEnum {
    NEVER = "NEVER",
    DAY = "DAY",
    WEEK = "WEEK"
}
export enum DiscountNinjaPromotionCodesActivationModeDtoEnum {
    ANY_TAB = "ANY_TAB",
    WHERE_ENTERED_TAB = "WHERE_ENTERED_TAB"
}
export enum DiscountNinjaPromotionCodesExpirationModeDtoEnum {
    NEVER = "NEVER",
    AFTER_DAY = "AFTER_DAY",
    AFTER_WEEK = "AFTER_WEEK"
}
export enum FallbackCheckoutModeDtoEnum {
    ORDER_DISCOUNT_BY_SHOPIFY_FUNCTIONS = "ORDER_DISCOUNT_BY_SHOPIFY_FUNCTIONS",
    DRAFT_ORDER = "DRAFT_ORDER"
}
export enum FreeGiftNotificationLocationDtoEnum {
    TOP = "TOP",
    BOTTOM = "BOTTOM"
}
export enum GiftPriceHideOnPagesDtoEnum {
    CART_AND_DRAWER_CART_PAGES = "CART_AND_DRAWER_CART_PAGES",
    ALL_PAGES = "ALL_PAGES"
}
export enum InstallationSupportRequestDemandDtoEnum {
    REGULAR = "REGULAR",
    HIGH = "HIGH",
    ULTRA_HIGH = "ULTRA_HIGH"
}
export enum InstallationSupportRequestGeneralStatusDtoEnum {
    SUBMITTED = "SUBMITTED",
    NOT_SUBMITTED = "NOT_SUBMITTED"
}
export enum InstallationSupportRequestPriorityDtoEnum {
    NORMAL = "NORMAL",
    HIGH = "HIGH"
}
export enum InstallationSupportRequestStatusDtoEnum {
    PROCESSING = "PROCESSING",
    AWAITING_COLLABORATOR_ACCESS = "AWAITING_COLLABORATOR_ACCESS",
    PLANNED = "PLANNED",
    STARTED = "STARTED",
    COMPLETED = "COMPLETED"
}
export enum OffsetTypeDtoEnum {
    EXTRA_SMALL = "EXTRA_SMALL",
    SMALL = "SMALL",
    MEDIUM = "MEDIUM",
    LARGE = "LARGE",
    EXTRA_LARGE = "EXTRA_LARGE",
    CUSTOM = "CUSTOM"
}
export enum OnboardingAppGoalDtoEnum {
    GROW_AVERAGE_ORDER_VALUE = "GROW_AVERAGE_ORDER_VALUE",
    INCREASE_CONVERSION_RATE = "INCREASE_CONVERSION_RATE",
    SPECIAL_OFFERS = "SPECIAL_OFFERS",
    CROSS_SELL_UP_SELL = "CROSS_SELL_UP_SELL",
    SECRET_DEALS = "SECRET_DEALS",
    SMART_CART = "SMART_CART",
    OTHER = "OTHER"
}
export enum PageDisplayTypeDtoEnum {
    DEFAULT = "DEFAULT",
    CUSTOM = "CUSTOM"
}
export enum PageTypeDtoEnum {
    ALL = "ALL",
    HOME = "HOME",
    COLLECTION = "COLLECTION",
    PRODUCT = "PRODUCT",
    CART = "CART",
    DRAWER_CART = "DRAWER_CART",
    NINJA_CART = "NINJA_CART",
    CHECKOUT = "CHECKOUT",
    BLOG_POST = "BLOG_POST",
    SEARCH = "SEARCH",
    CUSTOM = "CUSTOM"
}
export enum PaymentStatusDtoEnum {
    PENDING = "PENDING",
    PAID = "PAID"
}
export enum PlanFeatureDtoEnum {
    MULTI_LANGUAGE = "MULTI_LANGUAGE",
    API_ACCESS = "API_ACCESS",
    ADVANCED_SCHEDULING = "ADVANCED_SCHEDULING",
    ORDER_BUMP = "ORDER_BUMP",
    CROSS_SELL = "CROSS_SELL",
    CUSTOM_JS_CSS = "CUSTOM_JS_CSS",
    NINJA_CART_PRO = "NINJA_CART_PRO"
}
export enum PlanIntervalDtoEnum {
    ANNUAL = "ANNUAL",
    MONTHLY = "MONTHLY"
}
export enum PlanLevelDtoEnum {
    DEVELOPMENT = "DEVELOPMENT",
    STARTER_1 = "STARTER_1",
    STARTER_2 = "STARTER_2",
    STARTER_3 = "STARTER_3",
    STARTER_4 = "STARTER_4",
    PRO_1 = "PRO_1",
    PRO_2 = "PRO_2",
    PRO_3 = "PRO_3",
    PRO_4 = "PRO_4",
    PLUS_1 = "PLUS_1",
    PLUS_2 = "PLUS_2",
    PLUS_3 = "PLUS_3",
    PLUS_4 = "PLUS_4",
    ENTERPRISE = "ENTERPRISE"
}
export enum PlanThresholdTypeDtoEnum {
    SESSION = "SESSION",
    ORDER = "ORDER"
}
export enum PositionDtoEnum {
    TOP = "TOP",
    TOP_LEFT = "TOP_LEFT",
    TOP_RIGHT = "TOP_RIGHT",
    BOTTOM_LEFT = "BOTTOM_LEFT",
    BOTTOM_RIGHT = "BOTTOM_RIGHT",
    BOTTOM = "BOTTOM",
    CENTER = "CENTER",
    MIDDLE = "MIDDLE",
    LEFT = "LEFT",
    RIGHT = "RIGHT"
}
export enum PromotionCodeFieldDisplayModeDtoEnum {
    COLLAPSED = "COLLAPSED",
    EXPANDED = "EXPANDED"
}
export enum PromotionCodeFieldHeaderStyleDtoEnum {
    STYLE_1 = "STYLE_1",
    STYLE_2 = "STYLE_2"
}
export enum PromotionSummaryShippingAmountModeDtoEnum {
    CALCULATED_AT_CHECKOUT = "CALCULATED_AT_CHECKOUT",
    CALCULATED_IN_CART = "CALCULATED_IN_CART"
}
export enum PromotionSummaryTotalDiscountCalculationModeDtoEnum {
    SUM_OF_ALL = "SUM_OF_ALL",
    ORDER_DISCOUNTS_ONLY = "ORDER_DISCOUNTS_ONLY"
}
export enum ReleaseVersionModeDtoEnum {
    STABLE = "STABLE",
    LATEST = "LATEST",
    REGULAR = "REGULAR"
}
export enum SettingsValidationErrorCodeEnum {
    COLOR_INVALID_HEX_FORMAT = "COLOR_INVALID_HEX_FORMAT",
    INVALID_TIMEZONE = "INVALID_TIMEZONE",
    ORDER_TAGS_INVALID_TAG = "ORDER_TAGS_INVALID_TAG",
    TAGS_METADATA_DISCOUNT_NINJA_ORIGINATED_TAG_MISSING = "TAGS_METADATA_DISCOUNT_NINJA_ORIGINATED_TAG_MISSING",
    TAGS_METADATA_PREFIX_OFFER_MISSING = "TAGS_METADATA_PREFIX_OFFER_MISSING",
    TAGS_METADATA_PREFIX_DISCOUNT_CODE_MISSING = "TAGS_METADATA_PREFIX_DISCOUNT_CODE_MISSING",
    TAGS_METADATA_PREFIX_PROMOTION_MISSING = "TAGS_METADATA_PREFIX_PROMOTION_MISSING",
    TAGS_METADATA_PREFIX_PROMOTION_CODE_MISSING = "TAGS_METADATA_PREFIX_PROMOTION_CODE_MISSING",
    SHAREABLE_LINKS_SETTINGS_INVALID_LINK_EXPIRATION_DAYS = "SHAREABLE_LINKS_SETTINGS_INVALID_LINK_EXPIRATION_DAYS",
    CUSTOM_STYLING_INVALID_FONT_WEIGHT = "CUSTOM_STYLING_INVALID_FONT_WEIGHT",
    CUSTOM_STYLING_MISSING_LINK_STYLE_VALUE = "CUSTOM_STYLING_MISSING_LINK_STYLE_VALUE",
    ACCOUNT_PRIMARY_USER_FIRST_NAME_INVALID = "ACCOUNT_PRIMARY_USER_FIRST_NAME_INVALID",
    ACCOUNT_PRIMARY_USER_LAST_NAME_INVALID = "ACCOUNT_PRIMARY_USER_LAST_NAME_INVALID",
    ACCOUNT_RECIPIENTS_INVALID_EMAIL_FORMAT = "ACCOUNT_RECIPIENTS_INVALID_EMAIL_FORMAT",
    SHOPIFY_DISCOUNT_CODES_INCLUDE_PATTERN_MISSING = "SHOPIFY_DISCOUNT_CODES_INCLUDE_PATTERN_MISSING",
    SHOPIFY_DISCOUNT_CODES_EXCLUDE_PATTERN_MISSING = "SHOPIFY_DISCOUNT_CODES_EXCLUDE_PATTERN_MISSING",
    CHECKOUT_DRAFT_ORDER_REMOVAL_OPTION_MISSING = "CHECKOUT_DRAFT_ORDER_REMOVAL_OPTION_MISSING",
    CHECKOUT_DISCOUNT_CODES_PREFIX_MISSING = "CHECKOUT_DISCOUNT_CODES_PREFIX_MISSING",
    CHECKOUT_BUTTON_LOADING_EFFECT_TIMEOUT_MISSING = "CHECKOUT_BUTTON_LOADING_EFFECT_TIMEOUT_MISSING",
    CHECKOUT_BUTTON_LOADING_EFFECT_TIMEOUT_INVALID = "CHECKOUT_BUTTON_LOADING_EFFECT_TIMEOUT_INVALID",
    CHECKOUT_PRICES_LOADING_EFFECT_TIMEOUT_INVALID = "CHECKOUT_PRICES_LOADING_EFFECT_TIMEOUT_INVALID",
    CHECKOUT_PRICES_LOADING_EFFECT_TIMEOUT_MISSING = "CHECKOUT_PRICES_LOADING_EFFECT_TIMEOUT_MISSING",
    CHECKOUT_FALLBACK_MODE_MISSING = "CHECKOUT_FALLBACK_MODE_MISSING",
    TRANSLATIONS_INVALID_LOCALE = "TRANSLATIONS_INVALID_LOCALE",
    TRANSLATIONS_INVALID_TEXT_LABELS = "TRANSLATIONS_INVALID_TEXT_LABELS",
    TRANSLATIONS_INVALID_COUNTDOWN_CLOCK_LABELS = "TRANSLATIONS_INVALID_COUNTDOWN_CLOCK_LABELS",
    TRANSLATIONS_INVALID_CHECKOUT_LOADING_MESSAGES = "TRANSLATIONS_INVALID_CHECKOUT_LOADING_MESSAGES",
    TRANSLATIONS_INVALID_PAGE_REFRESH_MESSAGES = "TRANSLATIONS_INVALID_PAGE_REFRESH_MESSAGES",
    TRANSLATIONS_INVALID_ACCESSIBILITY_LABELS = "TRANSLATIONS_INVALID_ACCESSIBILITY_LABELS",
    INSTALLATION_SUPPORT_REQUEST_NOTE_CANNOT_BE_EMPTY = "INSTALLATION_SUPPORT_REQUEST_NOTE_CANNOT_BE_EMPTY",
    INSTALLATION_SUPPORT_REQUEST_ALREADY_EXISTS = "INSTALLATION_SUPPORT_REQUEST_ALREADY_EXISTS",
    INSTALLATION_SUPPORT_REQUEST_SELECTED_WIDGETS_ARE_REQUIRED = "INSTALLATION_SUPPORT_REQUEST_SELECTED_WIDGETS_ARE_REQUIRED",
    SHAREABLE_LINKS_SETTINGS_MISSING_LINK_EXPIRATION_DAYS = "SHAREABLE_LINKS_SETTINGS_MISSING_LINK_EXPIRATION_DAYS",
    APP_SECRET_INVALID = "APP_SECRET_INVALID",
    INSTALLATION_CODE_MARKUP_NOT_FOUND_IN_ASSET = "INSTALLATION_CODE_MARKUP_NOT_FOUND_IN_ASSET",
    MISSING_CUSTOM_SPACING = "MISSING_CUSTOM_SPACING",
    INVALID_CUSTOM_SPACING = "INVALID_CUSTOM_SPACING",
    BREAKPOINT_CONTENT_WIDTH_CANNOT_BE_HIGHER_THAN_MAX_WIDTH = "BREAKPOINT_CONTENT_WIDTH_CANNOT_BE_HIGHER_THAN_MAX_WIDTH",
    BREAKPOINT_CONTENT_WIDTH_INVALID = "BREAKPOINT_CONTENT_WIDTH_INVALID",
    BREAKPOINT_MAX_WIDTH_INVALID = "BREAKPOINT_MAX_WIDTH_INVALID",
    OFFSET_INVALID_CUSTOM_VALUE = "OFFSET_INVALID_CUSTOM_VALUE",
    OFFSET_TYPE_MISSING = "OFFSET_TYPE_MISSING",
    THEME_SLOTS_MISSING_SLOT = "THEME_SLOTS_MISSING_SLOT",
    INVALID_SIZE_FIXED_WIDTH = "INVALID_SIZE_FIXED_WIDTH",
    INVALID_Z_INDEX_VALUE = "INVALID_Z_INDEX_VALUE",
    INVALID_ANIMATION = "INVALID_ANIMATION",
    INVALID_PAGE_TYPES = "INVALID_PAGE_TYPES",
    PROMOTION_SUMMARY_SHIPPING_DISPLAY_TEXT_MISSING = "PROMOTION_SUMMARY_SHIPPING_DISPLAY_TEXT_MISSING",
    INVALID_COLOR = "INVALID_COLOR",
    LOCALIZATION_TRANSLATIONS_GIFT_PURCHASE_MESSAGES_MISSING = "LOCALIZATION_TRANSLATIONS_GIFT_PURCHASE_MESSAGES_MISSING",
    LOCALIZATION_TRANSLATIONS_SUBSCRIPTION_PRODUCTS_MESSAGES_MISSING = "LOCALIZATION_TRANSLATIONS_SUBSCRIPTION_PRODUCTS_MESSAGES_MISSING",
    COLOR_INVALID_ALPHA = "COLOR_INVALID_ALPHA",
    TRANSLATIONS_INVALID_GIFT_PURCHASE_MESSAGES = "TRANSLATIONS_INVALID_GIFT_PURCHASE_MESSAGES",
    TRANSLATIONS_INVALID_SUBSCRIPTION_PRODUCTS_MESSAGES = "TRANSLATIONS_INVALID_SUBSCRIPTION_PRODUCTS_MESSAGES",
    ACCOUNT_RECIPIENTS_MISSING_ALL_NOTIFICATION_TYPES = "ACCOUNT_RECIPIENTS_MISSING_ALL_NOTIFICATION_TYPES",
    INTEGRATIONS_SETTINGS_SUBSCRIPTION_PRODUCTS_CHECKOUT_MODE_MISSING = "INTEGRATIONS_SETTINGS_SUBSCRIPTION_PRODUCTS_CHECKOUT_MODE_MISSING",
    CART_BEHAVIOR_REFRESH_BEHAVIOR_JAVASCRIPT_METHOD_MISSING = "CART_BEHAVIOR_REFRESH_BEHAVIOR_JAVASCRIPT_METHOD_MISSING",
    GIFTS_FREE_GIFT_NOTIFICATION_LOCATION_MISSING = "GIFTS_FREE_GIFT_NOTIFICATION_LOCATION_MISSING",
    GIFTS_GIFT_PRICE_HIDE_ON_PAGES_MISSING = "GIFTS_GIFT_PRICE_HIDE_ON_PAGES_MISSING",
    SETTINGS_FILE_TO_BE_IMPORTED_MISSING = "SETTINGS_FILE_TO_BE_IMPORTED_MISSING",
    SETTINGS_FILE_INVALID_TYPE = "SETTINGS_FILE_INVALID_TYPE",
    SETTINGS_FILE_BROKEN_CONTENT = "SETTINGS_FILE_BROKEN_CONTENT",
    CART_BEHAVIOR_NOTIFICATION_POSITION_INVALID = "CART_BEHAVIOR_NOTIFICATION_POSITION_INVALID",
    INVALID_PLAN_SELECTED = "INVALID_PLAN_SELECTED",
    NO_SUBSCRIPTION_TO_BE_CANCELED_OR_RESUMED = "NO_SUBSCRIPTION_TO_BE_CANCELED_OR_RESUMED",
    CHECKOUT_NON_BASE_CURRENCY_CHECKOUT_MODE_INVALID = "CHECKOUT_NON_BASE_CURRENCY_CHECKOUT_MODE_INVALID",
    CHECKOUT_INVALID_DYNAMIC_CHECKOUT_BUTTONS_OVERRIDDEN_PAGES = "CHECKOUT_INVALID_DYNAMIC_CHECKOUT_BUTTONS_OVERRIDDEN_PAGES",
    SUBSCRIPTION_REPLACEMENT_BEHAVIOR_MISSING = "SUBSCRIPTION_REPLACEMENT_BEHAVIOR_MISSING",
    ONBOARDING_INVALID_EMAIL_FORMAT = "ONBOARDING_INVALID_EMAIL_FORMAT",
    ONBOARDING_BRAND_COLORS_INVALID_AMOUNT = "ONBOARDING_BRAND_COLORS_INVALID_AMOUNT",
    ONBOARDING_INVALID_AMOUNT_OF_GOALS = "ONBOARDING_INVALID_AMOUNT_OF_GOALS",
    ONBOARDING_MISSING_OTHER_GOAL = "ONBOARDING_MISSING_OTHER_GOAL"
}
export enum ShopifyPromotionCodesCombinationModeDtoEnum {
    OFFER_SPECIFIC = "OFFER_SPECIFIC",
    SHOP_SETTINGS = "SHOP_SETTINGS"
}
export enum ShopifyPromotionCodesPatternMatchModeDtoEnum {
    ALLOW_ALL = "ALLOW_ALL",
    ALLOW_MATCHING_PATTERN = "ALLOW_MATCHING_PATTERN"
}
export enum ShopifyPromotionCodesStackingBehaviorDtoEnum {
    DEPENDING_ON_CODE = "DEPENDING_ON_CODE",
    NEVER_STACK_PRODUCT_LEVEL_OFFERS = "NEVER_STACK_PRODUCT_LEVEL_OFFERS",
    ALWAYS_STACK_PRODUCT_LEVEL_OFFERS = "ALWAYS_STACK_PRODUCT_LEVEL_OFFERS"
}
export enum SizeTypeDtoEnum {
    EXTRA_SMALL = "EXTRA_SMALL",
    SMALL = "SMALL",
    MEDIUM = "MEDIUM",
    LARGE = "LARGE",
    EXTRA_LARGE = "EXTRA_LARGE"
}
export enum SizeWidthDtoEnum {
    AUTO = "AUTO",
    FIXED = "FIXED",
    BREAKPOINTS = "BREAKPOINTS"
}
export enum SortDirectionDtoEnum {
    ASC = "ASC",
    DESC = "DESC"
}
export enum SpacingTypeDtoEnum {
    EXTRA_SMALL = "EXTRA_SMALL",
    SMALL = "SMALL",
    MEDIUM = "MEDIUM",
    LARGE = "LARGE",
    EXTRA_LARGE = "EXTRA_LARGE",
    CUSTOM = "CUSTOM"
}
export enum StrikethroughPricingFreeGiftsQuantitySpinnerModeDtoEnum {
    VISIBLE_AND_ENABLED = "VISIBLE_AND_ENABLED",
    HIDDEN = "HIDDEN",
    DISABLED = "DISABLED"
}
export enum SubscriptionProductsCheckoutModeDtoEnum {
    ALLOW = "ALLOW",
    NOT_ALLOW = "NOT_ALLOW"
}
export enum ThemeTypeDtoEnum {
    CURRENT = "CURRENT",
    TEST = "TEST",
    DEVELOPMENT = "DEVELOPMENT"
}
export enum WidgetInstallationCustomWidgetCssSelectorDtoEnum {
    BELOW_TITLE = "BELOW_TITLE",
    ABOVE_SUBTOTAL = "ABOVE_SUBTOTAL",
    AUTOMATIC = "AUTOMATIC",
    OTHER = "OTHER"
}
export enum WidgetInstallationModeDtoEnum {
    APP_BLOCKS = "APP_BLOCKS",
    APP_EMBED = "APP_EMBED",
    CUSTOM = "CUSTOM"
}
export enum WidgetStatusDtoEnum {
    NOT_READY = "NOT_READY",
    LOCKED = "LOCKED",
    ENABLED = "ENABLED",
    DISABLED = "DISABLED",
    ENABLED_VIA_CODE = "ENABLED_VIA_CODE",
    ENABLED_VIA_CSS = "ENABLED_VIA_CSS"
}
export enum WidgetTypeDtoEnum {
    ANNOUNCEMENT_BAR = "ANNOUNCEMENT_BAR",
    COUNTDOWN_CLOCK = "COUNTDOWN_CLOCK",
    NINJA_CART = "NINJA_CART",
    NOTIFICATIONS = "NOTIFICATIONS",
    POP_UP = "POP_UP",
    OFFER_RULE_POPUP = "OFFER_RULE_POPUP",
    FREQUENTLY_BOUGHT_TOGETHER = "FREQUENTLY_BOUGHT_TOGETHER",
    PRODUCT_BANNER = "PRODUCT_BANNER",
    PROMOTION_CODE_FIELD = "PROMOTION_CODE_FIELD",
    SHOPIFY_PROMOTION_CODES = "SHOPIFY_PROMOTION_CODES",
    DISCOUNT_NINJA_PROMOTION_CODES = "DISCOUNT_NINJA_PROMOTION_CODES",
    PROMOTION_SUMMARY = "PROMOTION_SUMMARY",
    VOLUME_DISCOUNT = "VOLUME_DISCOUNT",
    CART_STRIKETHROUGH_PRICING = "CART_STRIKETHROUGH_PRICING",
    DRAWER_CART_STRIKETHROUGH_PRICING = "DRAWER_CART_STRIKETHROUGH_PRICING",
    COLLECTION_STRIKETHROUGH_PRICING = "COLLECTION_STRIKETHROUGH_PRICING",
    PRODUCT_STRIKETHROUGH_PRICING = "PRODUCT_STRIKETHROUGH_PRICING",
    PROMOTIONAL_BADGE = "PROMOTIONAL_BADGE"
}
