import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Banner,
  BlockStack,
  Box,
  Checkbox,
  Icon,
  InlineStack,
  List,
  Text,
  TextField,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import { GeneratePromotionCodesManuallyRequestDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { SelectIcon } from '@shopify/polaris-icons';

type ManualCodesGenerateProps = {
  manaul: GeneratePromotionCodesManuallyRequestDto;
  handleUpdateManual: (
    field: keyof GeneratePromotionCodesManuallyRequestDto,
    data: GeneratePromotionCodesManuallyRequestDto[keyof GeneratePromotionCodesManuallyRequestDto]
  ) => void;
};
type ManualCodeDto = {
  id: string;
  value: string;
};
const ManualCodesGenerate: React.FC<ManualCodesGenerateProps> = ({
  manaul,
  handleUpdateManual,
}) => {
  const [i18n] = useI18n();
  const regex = /^[a-zA-Z0-9-]*$/;

  const [codes, setCodes] = useState<ManualCodeDto[]>([]);

  const limitOptions = useMemo(
    () =>
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => ({
        label: `${value}`,
        value: `${value}`,
      })),
    []
  );

  const updateCodes = useCallback(
    (code: ManualCodeDto) => {
      const existingCodes = codes;
      const existingIndex = codes.findIndex((item) => item.id === code.id);

      if (existingIndex !== -1) {
        return existingCodes.map((item) => (item.id === code.id ? code : item));
      } else {
        return [...existingCodes, code];
      }
    },
    [codes]
  );
  useEffect(() => {
    handleUpdateManual(
      'codes',
      codes.map((code) => code.value).filter((code) => code)
    );
  }, [codes]);

  return (
    <BlockStack gap='400'>
      <Banner tone='info' title={i18n.translate('BestPractices')}>
        <List gap='extraTight' type='bullet'>
          <List.Item>{i18n.translate('BestPractices1')}</List.Item>
          <List.Item>{i18n.translate('BestPractices2')}</List.Item>
          <List.Item>{i18n.translate('BestPractices3')}</List.Item>
        </List>
      </Banner>
      <Box
        borderRadius='200'
        padding='400'
        borderWidth='025'
        borderColor='border-brand'
      >
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='semibold'>
              {i18n.translate('CreateToTen')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('CodesSensitive')}
            </Text>
          </BlockStack>
          <List type='number'>
            <InlineStack gap='300' align='space-between'>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((id) => (
                <Box key={id} width='46%'>
                  <List.Item>
                    <TextField
                      value={
                        codes.find((item) => item.id === id.toString())?.value
                      }
                      maxLength={30}
                      autoComplete=''
                      label=''
                      onChange={(value) =>
                        regex.test(value) &&
                        setCodes(
                          updateCodes({ value: value, id: id.toString() })
                        )
                      }
                    />
                  </List.Item>
                </Box>
              ))}
            </InlineStack>
          </List>
        </BlockStack>
      </Box>

      <Box
        borderRadius='200'
        padding='400'
        borderWidth='025'
        borderColor='border-brand'
      >
        <InlineStack align='space-between' blockAlign='center'>
          <Checkbox
            checked={manaul.maximumUses !== 0}
            label={i18n.translate('MaximumUses')}
            helpText={i18n.translate('MaximumUsesSubtitle')}
            onChange={(value) =>
              handleUpdateManual('maximumUses', value ? 1 : 0)
            }
          />
          <Box width='180px'>
            {manaul.maximumUses !== 0 ? (
              <SelectOptions
                options={limitOptions}
                label=''
                selectedOption={manaul.maximumUses?.toString()}
                allowManual
                min={1}
                max={10}
                onOptionSelect={(value) =>
                  handleUpdateManual('maximumUses', +value)
                }
              />
            ) : (
              <TextField
                autoComplete=''
                value={i18n.translate('Unlimited')}
                label=''
                disabled
                suffix={<Icon source={SelectIcon} tone='base' />}
              />
            )}
          </Box>
        </InlineStack>
      </Box>
    </BlockStack>
  );
};

export default ManualCodesGenerate;
