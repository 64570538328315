import React, { ReactNode, useCallback, useMemo } from 'react';
import {
  Button,
  Layout,
  Page,
  Icon,
  IconSource,
  Text,
  ButtonGroup,
} from '@shopify/polaris';
import { SettingsIcon, AlertTriangleIcon, XIcon } from '@shopify/polaris-icons';
import './SettingsLayout.scss';
import { useAppSelector } from 'core/hooks';
import { useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { setToggleSettingsConfig } from 'core/store/widgetsSlice';
import { setIsSettingsSaving } from 'core/store/settingsSlice';
import { useI18n } from '@shopify/react-i18n';
import { SaveBar } from '@shopify/app-bridge-react';

type SettingsLayoutProps = {
  children?: ReactNode;
  isMaxModal?: boolean;
};

export const SettingsLayout: React.FC<SettingsLayoutProps> = (props) => {
  const { children, isMaxModal } = props;
  const {
    isUnsavedChanges,
    onSaveChanges,
    onDiscardChanges,
    isValidationError,
    isSettingsSaving,
  } = useAppSelector((state) => state.settings);

  const { toggleSettingsConfig } = useAppSelector((store) => store.widgets);
  const dispatch = useDispatch();
  const [i18n] = useI18n();
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();

  const widthNeeded = useMemo(
    () =>
      isMaxModal
        ? '100vw'
        : location.pathname.includes('/general-settings')
        ? '100%'
        : 'calc(100% - 20px)',
    [isMaxModal, location]
  );

  const handleSaveChanges = useCallback(() => {
    dispatch(setIsSettingsSaving(true));
    onSaveChanges?.()
      .then(() => {
        if (toggleSettingsConfig) {
          dispatch(setToggleSettingsConfig({ action: 'save' }));
          setSearchParams({});
        }
      })
      .finally(() => {
        dispatch(setIsSettingsSaving(false));
      });
  }, [onSaveChanges, toggleSettingsConfig, dispatch]);

  return (
    <div
      className='SettingsLayout'
      id='SettingsLayout'
      style={{
        width: widthNeeded,
      }}
    >
      <Page>
        <Layout>
          {!isMaxModal && !location.pathname.includes('/general-settings') && (
            <Layout.Section>
              <div className='SettingsLayoutHeader'>
                <div className='HeaderTitle'>
                  {isUnsavedChanges ? (
                    <>
                      <Icon
                        source={AlertTriangleIcon as IconSource}
                        tone='base'
                      />
                      <Text fontWeight='semibold' as='h1'>
                        {i18n.translate(`UnsavedChanges`)}
                      </Text>
                    </>
                  ) : (
                    <>
                      <Icon source={SettingsIcon as IconSource} tone='base' />
                      <Text fontWeight='semibold' as='h1'>
                        {i18n.translate(`Settings`)}
                      </Text>
                    </>
                  )}
                </div>
                <div className='HeaderActions'>
                  {isUnsavedChanges || isSettingsSaving ? (
                    <ButtonGroup>
                      <Button onClick={onDiscardChanges} variant='tertiary'>
                        {i18n.translate(`Discard`)}
                      </Button>
                      <Button
                        variant='primary'
                        disabled={isValidationError}
                        loading={isSettingsSaving}
                        onClick={handleSaveChanges}
                      >
                        {i18n.translate(`Save`)}
                      </Button>
                    </ButtonGroup>
                  ) : (
                    <>
                      <div
                        onClick={() => setSearchParams({})}
                        className='CloseSettingsIcon'
                      >
                        <Icon tone='base' source={XIcon} />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Layout.Section>
          )}
          {location.pathname.includes('/general-settings') && !isMaxModal && (
            <SaveBar
              id='general-settings-page-save-bar'
              open={isUnsavedChanges}
            >
              <button
                // eslint-disable-next-line react/no-unknown-property
                variant='primary'
                onClick={handleSaveChanges}
                loading={isSettingsSaving}
                disabled={isValidationError}
              />
              <button onClick={onDiscardChanges} />
            </SaveBar>
          )}
          <Layout.Section>{children}</Layout.Section>
        </Layout>
      </Page>
    </div>
  );
};
