import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import './CartText.scss';
import { useI18n } from '@shopify/react-i18n';
import { useAppSelector } from 'core/hooks';
import {
    getCurrentMessage,
    parseSmartTagCode,
} from 'features/settings/components/EditPresetSkeleton/utils/utils';
import { DeviceTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import {
    WidgetsFetchTypeEnum,
    useConfigureWidgets,
} from 'features/settings/hooks/useConfigureWidgets';
import { isEmpty } from 'lodash';
import { PreviewEntry } from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components';

type CartTextProps = {
    data: any;
    previewType: DeviceTypeDtoEnum;
    defaultLanguage?: string;
    adminActivePath: string;
    getSelectionFromThePreview(path: string): void;
    root: string;
};

export const CartText: React.FC<CartTextProps> = ({
    previewType,
    defaultLanguage,
    data,
    adminActivePath,
    getSelectionFromThePreview,
    root,
}) => {
    const [i18n] = useI18n();
    const { fontListData } = useConfigureWidgets(
        WidgetsFetchTypeEnum.PRESET_FONT_LIST
    );

    const { cartTextMessages, drawerCartTextMessages } = useAppSelector(
        (state) => state.widgets
    );
    const { smartTagsCodes } = useAppSelector((state) => state.offersWizard);

    const [currentData, setCurrentData] = useState<any>({});
    const [injectStyles, setInjectStyles] = useState<boolean>(false);

    const cartText = document.querySelector('la-dn-cart-item-text');

    const messagesNeeded = useMemo(
        () =>
            root === 'cartPageCartText' ? cartTextMessages : drawerCartTextMessages,
        [root, cartTextMessages, drawerCartTextMessages]
    );

    const prevMatch = useRef<number>(0);

    const currentIndex: number = useMemo(() => {
        const match = adminActivePath.match(/banner_(\d+)/);
        if (match) {
            prevMatch.current = +match[1];
            return +match[1];
        } else {
            return prevMatch.current ?? 0;
        }
    }, [adminActivePath]);

    const currentOfferId = useMemo(
        () => data?.offers?.[currentIndex]?.offerId,
        [data?.offers, currentIndex]
    );

    const currentMessages = useMemo(
        () => ({
            key: '1234:a',
            token: 'ABCD',
            message: data?.offers
                ? messagesNeeded?.length
                    ? parseSmartTagCode(
                        messagesNeeded.find(
                            (message) => message.offerId === currentOfferId
                        )?.[previewType.toLowerCase() as keyof PreviewEntry] || '',
                        smartTagsCodes
                    )
                    : getCurrentMessage(
                        data?.offers?.[currentIndex]?.setup?.general?.text?.message
                            ?.entries || '',
                        defaultLanguage,
                        smartTagsCodes
                    )
                : i18n.translate('cartTextExample'),
        }),
        [
            currentIndex,
            data?.offers,
            defaultLanguage,
            smartTagsCodes,
            i18n,
            messagesNeeded,
            currentOfferId,
        ]
    );

    const elementToReplaceWith = useMemo(
        () =>
            `
              <la-dn-cart-item-text
      admin-mode="true"
      admin-active-path="${adminActivePath}"
      admin-mode-root="${root}"
      admin-mode-label='[{"component":"${root}","label":"${i18n.translate(
                'сartText'
            )}","icon":"widget","subComponents":[{"component":"banner","label":"${i18n.translate(
                'сartText'
            )}","icon":"offer"}]}]'
      index="${currentIndex}"
   key="1234:a"
    ></la-dn-cart-item-text>
              `,
        [adminActivePath, i18n, currentIndex, root]
    );

    const showCartText = useCallback(
        (data: any) => {
            const parser = new DOMParser();
            const container = document.querySelector('#cart-text-root');

            const cartText = parser
                .parseFromString(elementToReplaceWith, 'text/html')
                .querySelector('la-dn-cart-item-text');

            setCurrentData(data);

            if (
                container &&
                cartText &&
                !container.querySelector('la-dn-cart-item-text')
            ) {
                container?.appendChild(cartText);
            } else if (
                container &&
                cartText &&
                container.querySelector('la-dn-cart-item-text')
            ) {
                const currentCartText = container.querySelector('la-dn-cart-item-text');
                if (currentCartText) {
                    currentCartText.innerHTML = cartText.innerHTML;

                    currentCartText.setAttribute(
                        'index',
                        cartText.getAttribute('index') || ''
                    );
                }
            }
        },
        [elementToReplaceWith, setCurrentData]
    );

    useEffect(() => {
        if (!isEmpty(currentData)) {
            document.dispatchEvent(
                new CustomEvent('la:dn:cart-text:render', {
                    detail: currentData,
                })
            );
            setInjectStyles(true);
        }
    }, [currentData]);

    useEffect(() => {
        if (injectStyles && data) {
            cartText?.setAttribute('styles', JSON.stringify(data));
        }
    }, [data, injectStyles]);

    useEffect(() => {
        if (injectStyles) {
            cartText?.setAttribute(
                'force-mobile',
                (previewType === DeviceTypeDtoEnum.MOBILE).toString()
            );
        }
    }, [injectStyles, previewType]);

    useEffect(() => {
        if (injectStyles) {
            cartText?.setAttribute('font-assets', JSON.stringify(fontListData));
        }
    }, [fontListData, injectStyles]);

    useEffect(() => {
        showCartText(currentMessages);
        document
            .querySelector('la-dn-cart-item-text')
            ?.addEventListener('la:dn:cart-text:admin-change', (e) =>
                getSelectionFromThePreview((e as CustomEvent).detail)
            );
    }, [currentIndex, currentMessages]);

    useEffect(() => {
        if (injectStyles) {
            cartText?.setAttribute('admin-active-path', adminActivePath);
        }
    }, [adminActivePath]);

    return <div id='cart-text-root'></div>;
};
