import React, { useCallback, useEffect, useState } from 'react';
import './Navbar.scss';
import { NavTabs } from 'core/enums/NavTabsEnum';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, IconSource, Navigation } from '@shopify/polaris';
import {
  HomeIcon,
  GiftCardIcon,
  PlusIcon,
  SearchResourceIcon,
  SettingsIcon,
  NotificationIcon,
  LightbulbIcon,
  QuestionCircleIcon,
  MenuIcon,
  XIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  ChartLineIcon,
} from '@shopify/polaris-icons';
import { NavPaths } from 'core/enums/NavPathsEnum';
import classNames from 'classnames';
import { toLowerCaseAndCapitalizeFirstLetter } from 'core/utils/formatUtils';
import { updateActivePage } from 'core/store/mainNavSlice';

export const Navbar: React.FC = () => {
  const dispatch = useAppDispatch();
  const { activePage, hideAppLeftMenu } = useAppSelector(
    (state) => state.mainNav
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isMobileNavOpened, setIsMobileNavOpened] = useState<boolean>(false);
  const [isDesktopNavExpanded, setIsDesktopNavExpanded] =
    useState<boolean>(false);
  const [currentNavTab, setCurrentNavTab] = useState<NavTabs | null>(null);

  const handleNavItemClick = useCallback(
    (path?: string, isSettingsModal?: boolean) => {
      isSettingsModal && setSearchParams({ modal: 'settings' });
      if (path && !isSettingsModal) {
        navigate(path);
      }
    },
    [navigate, setSearchParams]
  );

  const toggleMobileNavState = useCallback(() => {
    setIsMobileNavOpened((prevState) => !prevState);
  }, []);

  const toggleDesktopNavState = useCallback(() => {
    setIsDesktopNavExpanded((prevState) => !prevState);
  }, []);

  useEffect(() => {
    if (activePage && activePage !== NavTabs.Settings) {
      setCurrentNavTab(activePage);
    }
  }, [activePage]);

  useEffect(() => {
    if (!activePage && currentNavTab) {
      dispatch(updateActivePage(currentNavTab));
    }
  }, [currentNavTab, activePage, dispatch]);
  return !hideAppLeftMenu ? (
    <div
      className={classNames('Polaris-Navigation__Container', {
        'Polaris-Navigation__Container--opened': isMobileNavOpened,
        'Polaris-Navigation__Container--not-expanded': !isDesktopNavExpanded,
        'Polaris-Navigation__Container--expanded': isDesktopNavExpanded,
        'Polaris-Navigation__Container--reporting': location.pathname.includes(
          NavPaths.Reporting
        ),
      })}
    >
      <div className='overlay'></div>
      <Navigation location={location.pathname}>
        <Navigation.Section
          fill
          items={[
            {
              label: ``,
              icon: isDesktopNavExpanded ? ChevronLeftIcon : ChevronRightIcon,
              onClick: toggleDesktopNavState,
            },
            {
              label: `${toLowerCaseAndCapitalizeFirstLetter(NavTabs.Home)}`,
              icon: HomeIcon as IconSource,
              selected: activePage === NavTabs.Home,
              onClick: () => handleNavItemClick(NavPaths.Home),
            },
            {
              label: `${toLowerCaseAndCapitalizeFirstLetter(
                NavTabs.Promotions
              )}`,
              icon: GiftCardIcon as IconSource,
              selected: activePage === NavTabs.Promotions,
              onClick: () => handleNavItemClick(NavPaths.Promotions),
            },
            {
              label: `${toLowerCaseAndCapitalizeFirstLetter(
                NavTabs.Analytics
              )}`,
              icon: ChartLineIcon as IconSource,
              selected: activePage === NavTabs.Analytics,
              onClick: () => handleNavItemClick(NavPaths.Ananlytics),
            },
            {
              label: `${toLowerCaseAndCapitalizeFirstLetter(
                NavTabs.Reporting
              )}`,
              icon: SearchResourceIcon as IconSource,
              selected: activePage === NavTabs.Reporting,
              onClick: () => handleNavItemClick(NavPaths.Reporting),
            },
          ]}
        />
        <Navigation.Section
          items={[
            {
              label: `${toLowerCaseAndCapitalizeFirstLetter(NavTabs.Settings)}`,
              icon: SettingsIcon as IconSource,
              selected: activePage === NavTabs.Settings,
              onClick: () => handleNavItemClick(NavPaths.Settings, true),
            },
          ]}
        />
      </Navigation>
    </div>
  ) : null;
};
