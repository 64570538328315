import React, { useCallback } from 'react';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { Icon, WidgetOptionHeader } from '../../../../../components';
import {
  AnnouncementBarIconOfferDto,
  IconComponentDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { ResourceGroupKeyDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { isEqual } from 'lodash';

type AnnouncementBarIconProps = {
  icon: AnnouncementBarIconOfferDto;
  shopDefaultIcon: IconComponentDto;
  defaultIcon: AnnouncementBarIconOfferDto;
  isPresetEditor?: boolean;
  presetSetup?: IconComponentDto;
  defaultPresetSetup?: IconComponentDto;
  setIcon: (data: AnnouncementBarIconOfferDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const AnnouncementBarIcon: React.FC<AnnouncementBarIconProps> = ({
  icon,
  defaultIcon,
  shopDefaultIcon,
  isPresetEditor,
  presetSetup,
  defaultPresetSetup,
  setIcon,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const resetAllToSystemDefault = useCallback(() => {
    const { color, iconId } = shopDefaultIcon;
    setIcon({
      ...icon,
      icon: {
        color,
        ...(!isPresetEditor ? { iconId } : {}),
      },
    });
  }, [icon, shopDefaultIcon, isPresetEditor]);

  const onDiscardChanges = useCallback(() => {
    setIcon(
      !isPresetEditor
        ? {
            ...defaultIcon,
            icon: {
              ...defaultIcon.icon,
              color: defaultPresetSetup?.color,
            },
          }
        : defaultIcon
    );
  }, [defaultIcon, defaultPresetSetup]);

  return (
    <div className='AnnouncementBarIcon'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={onDiscardChanges}
        onResetToSystem={resetAllToSystemDefault}
        name={WidgetListOptionsDtoEnum.ICON}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <Icon
          icon={{
            ...icon.icon,
            ...(!isPresetEditor && { color: presetSetup?.color }),
          }}
          offersNote={isPresetEditor}
          showIcon={!isPresetEditor}
          inherited={
            !isPresetEditor
              ? isEqual(presetSetup?.color, defaultPresetSetup?.color)
              : undefined
          }
          groupKey={ResourceGroupKeyDtoEnum.OFFER}
          setIcon={(data) => setIcon({ ...icon, icon: data })}
          onSystemReset={() =>
            setIcon({
              ...icon,
              icon: {
                ...icon.icon,
                color: shopDefaultIcon.color,
              },
            })
          }
        />
      </div>
    </div>
  );
};

export default AnnouncementBarIcon;
