import { useI18n } from '@shopify/react-i18n';
import React from 'react';
import { Modal } from '@shopify/polaris';
type DeleteAllFilesModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onDeleteFiles: () => void;
  fileFormat: 'icon' | 'image';
  deletingIsLoading: boolean;
};
const DeleteAllFilesModal: React.FC<DeleteAllFilesModalProps> = ({
  isOpen,
  onClose,
  onDeleteFiles,
  fileFormat,
  deletingIsLoading,
}) => {
  const [i18n] = useI18n();
  const isIconFormat = fileFormat === 'icon';

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        open={isOpen}
        onClose={onClose}
        title={
          isIconFormat
            ? i18n.translate('DeleteAllIcons')
            : i18n.translate('DeleteAllImages')
        }
        primaryAction={{
          content: i18n.translate('Delete'),
          onAction: onDeleteFiles,
          disabled: deletingIsLoading,
        }}
        secondaryActions={[
          {
            content: i18n.translate('Cancel'),
            onAction: onClose,
          },
        ]}
      >
        <Modal.Section>
          <div className='DeleteAllFilesModalContent'>
            {isIconFormat
              ? i18n.translate('IconDescription')
              : i18n.translate('ImageDescription')}
          </div>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default DeleteAllFilesModal;
