import React, { useCallback, useEffect, useState } from 'react';
import {
  Badge,
  Bleed,
  BlockStack,
  Box,
  Card,
  ChoiceList,
  Divider,
  InlineStack,
  Link,
  Page,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { DiscountLinksSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import { useDispatch } from 'react-redux';
import {
  setIsUnsavedChanges,
  setOnDiscardChanges,
  setOnSaveChanges,
} from 'core/store/settingsSlice';
import {
  useConfigureSettings,
  SettingsFetchTypeEnum,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';
import { SettingsSkeleton } from '../components/SettingsSkeleton/SettingsSkeleton';
import { useSearchParams } from 'react-router-dom';

export const DiscountLinks: React.FC = () => {
  const [i18n] = useI18n();

  const [, setSearchParams] = useSearchParams();

  const behaviorOptions = [
    {
      label: (
        <InlineStack gap='100'>
          <Text as='p'>{i18n.translate('ANY_TAB_UNTIL_LINK_EXPIRES')}</Text>
          <Badge>{i18n.translate('Recommended')}</Badge>
        </InlineStack>
      ),
      value: 'ANY_TAB_UNTIL_LINK_EXPIRES',
      helpText: i18n.translate('ANY_TAB_UNTIL_LINK_EXPIRES_HELPTEXT'),
    },
    {
      label: i18n.translate('TAB_WHERE_LINK_OPENED'),
      value: 'TAB_WHERE_LINK_OPENED',
      helpText: i18n.translate('TAB_WHERE_LINK_OPENED_HELPTEXT'),
    },
  ];

  const expirationDurationOptions = [
    {
      label: i18n.translate('NEVER'),
      value: 'NEVER',
    },
    {
      label: i18n.translate('DAY'),
      value: 'DAY',
    },
    {
      label: i18n.translate('WEEK'),
      value: 'WEEK',
    },
  ];

  const dispatch = useDispatch();

  const {
    discountLinksData,
    discountLinksIsFetching,
    discountLinksDataChange,
  } = useConfigureSettings(SettingsFetchTypeEnum.DISCOUNT_LINKS);

  const [discountLinksState, setDiscountLinksState] =
    useState<DiscountLinksSettingsDto>({});
  const [savedData, setSavedData] = useState<DiscountLinksSettingsDto>({});

  useEffect(() => {
    if (
      !isEmpty(discountLinksState) &&
      !isEqual(savedData, discountLinksState)
    ) {
      dispatch(setIsUnsavedChanges(true));
      dispatch(
        setOnDiscardChanges(() => {
          if (!isEmpty(savedData)) {
            setDiscountLinksState(savedData);
          }
        })
      );
      dispatch(
        setOnSaveChanges(() => {
          setSavedData(discountLinksState);
          return discountLinksDataChange(discountLinksState);
        })
      );
    } else if (isEqual(savedData, discountLinksState)) {
      dispatch(setIsUnsavedChanges(false));
    }
  }, [discountLinksState, savedData, dispatch]);

  useEffect(() => {
    if (!isEmpty(discountLinksData)) {
      setDiscountLinksState(discountLinksData);
      setSavedData(discountLinksData);
    }
  }, [discountLinksData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  const handleChangediscountLinksLocaleState = useCallback(
    (value: string, field: string) =>
      setDiscountLinksState((prev) => ({ ...prev, [field]: value })),
    []
  );

  return (
    <div className='Style'>
      {!discountLinksIsFetching && discountLinksState ? (
        <Page title={i18n.translate(`DiscountLinks`)}>
          <Card roundedAbove='sm' padding='400'>
            <BlockStack gap='400'>
              <BlockStack gap='100'>
                <Text as='h2' variant='headingSm'>
                  {i18n.translate('DiscountLinks')}
                </Text>
                <Text as='p' tone='subdued'>
                  {i18n.translate('DiscountLinksDescription')}
                </Text>
              </BlockStack>
              <ChoiceList
                title=''
                titleHidden
                choices={behaviorOptions}
                selected={[discountLinksState.behavior || '']}
                onChange={(value) =>
                  handleChangediscountLinksLocaleState(value[0], 'behavior')
                }
              />
              {discountLinksState.behavior === 'ANY_TAB_UNTIL_LINK_EXPIRES' && (
                <>
                  <Divider />
                  <ChoiceList
                    title=''
                    titleHidden
                    choices={expirationDurationOptions}
                    selected={[discountLinksState.expirationDuration || '']}
                    onChange={(value) =>
                      handleChangediscountLinksLocaleState(
                        value[0],
                        'expirationDuration'
                      )
                    }
                  />
                </>
              )}
              <Bleed marginInline='400' marginBlockEnd='400'>
                <Box background='bg-surface-secondary' padding='400'>
                  <InlineStack gap='100'>
                    <Text as='p' tone='subdued'>
                      {i18n.translate('Footer.0', {
                        returnObjects: true,
                      })}
                    </Text>

                    <Link onClick={() => setSearchParams({
                      modal: 'settings',
                      path: 'manage-widgets',
                      subPath: 'discount-ninja-promotion-codes'
                    })}>
                      {' '}
                      {i18n.translate('Footer.1', {
                        returnObjects: true,
                      })}
                    </Link>
                    <Text as='p' tone='subdued'>
                      {i18n.translate('Footer.2', {
                        returnObjects: true,
                      })}
                    </Text>

                    <Link onClick={() => setSearchParams({
                      modal: 'settings',
                      path: 'manage-widgets',
                      subPath: 'shopify-discount-codes'
                    })}>
                      {' '}
                      {i18n.translate('Footer.3', {
                        returnObjects: true,
                      })}
                    </Link>
                  </InlineStack>
                </Box>
              </Bleed>
            </BlockStack>
          </Card>
        </Page>
      ) : (
        <SettingsSkeleton />
      )}
    </div>
  );
};
