import {
  Banner,
  BlockStack,
  Card,
  Checkbox,
  ChoiceList,
  InlineStack,
  Link,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  CartBehaviorSettingsDto,
  NotificationCartBehaviorSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { PositionDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { useIsDebugOrLocal } from 'core/hooks';
import React, { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

type NotificationProps = {
  data: NotificationCartBehaviorSettingsDto | undefined;
  section: string;
  handleCartBehaviorLocaleState: React.Dispatch<
    React.SetStateAction<CartBehaviorSettingsDto>
  >;
};

export const Notification: React.FC<NotificationProps> = ({
  data,
  section,
  handleCartBehaviorLocaleState,
}) => {
  const [i18n] = useI18n();
  const [searchParams, setSearchParams] = useSearchParams();
  const isDebugOrLocal = useIsDebugOrLocal();
  const options = [
    {
      label: i18n.translate(PositionDtoEnum.TOP),
      value: PositionDtoEnum.TOP,
    },
    {
      label: i18n.translate(PositionDtoEnum.BOTTOM),
      value: PositionDtoEnum.BOTTOM,
    },
  ];

  const navigateToTranslations = useCallback(async () => {
    !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
    searchParams.set('path', 'translations');
    setSearchParams(searchParams);
  }, [searchParams]);

  return (
    <Card roundedAbove='sm' padding='400'>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <InlineStack align='space-between'>
            <Text as='h2' variant='headingSm'>
              {i18n.translate('Notification')}
            </Text>
            <Link onClick={navigateToTranslations} removeUnderline>
              {i18n.translate('ConfigureTranslations')}
            </Link>
          </InlineStack>
        </BlockStack>
        <Checkbox
          label={i18n.translate('Show')}
          checked={data?.showNotificationWhenAppNeedsToRefreshPage?.enabled}
          onChange={(value) =>
            handleCartBehaviorLocaleState((prev) => ({
              ...prev,
              notification: {
                ...prev.notification,
                showNotificationWhenAppNeedsToRefreshPage: {
                  ...prev.notification
                    ?.showNotificationWhenAppNeedsToRefreshPage,
                  enabled: value,
                },
              },
            }))
          }
        />
        {data?.showNotificationWhenAppNeedsToRefreshPage?.enabled && (
          <>
            <ChoiceList
              title={i18n.translate('NotificationPosition')}
              choices={options}
              selected={[
                `${
                  data.showNotificationWhenAppNeedsToRefreshPage.value ||
                  PositionDtoEnum.TOP
                }`,
              ]}
              onChange={(value) =>
                handleCartBehaviorLocaleState((prev) => ({
                  ...prev,
                  notification: {
                    ...prev.notification,
                    showNotificationWhenAppNeedsToRefreshPage: {
                      ...prev.notification
                        ?.showNotificationWhenAppNeedsToRefreshPage,
                      value: value[0] as PositionDtoEnum,
                    },
                  },
                }))
              }
            />
            <Banner tone='info'>{i18n.translate('Banner')}</Banner>
          </>
        )}
      </BlockStack>
    </Card>
  );
};
