import React, { useCallback } from 'react';
import {
  Background,
  CornerRadius,
  Icon,
  Size,
  SingleRadioSelection,
} from '../../../../../components';
import { PromotionSummaryGeneralPresetDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { useI18n } from '@shopify/react-i18n';
import {
  PromotionSummaryPriceBreakdownBehaviorDtoEnum,
  ResourceGroupKeyDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import { WidgetListTypeDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { isEqual } from 'lodash';

type PromotionSummaryGeneralProps = {
  general: PromotionSummaryGeneralPresetDto;
  defaultGeneral: PromotionSummaryGeneralPresetDto;
  shopDefaultGeneral: PromotionSummaryGeneralPresetDto;
  isPresetEditor?: boolean;
  type:
    | WidgetListTypeDtoEnum.DRAWER_CART_PROMOTION_SUMMARY
    | WidgetListTypeDtoEnum.CART_PROMOTION_SUMMARY
    | WidgetListTypeDtoEnum.NINJA_CART_PROMOTION_SUMMARY;
  setGeneral: (data: PromotionSummaryGeneralPresetDto) => void;
  onShowHide?: () => void;
  onRemove?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const PromotionSummaryGeneral: React.FC<PromotionSummaryGeneralProps> = ({
  general,
  defaultGeneral,
  shopDefaultGeneral,
  isPresetEditor,
  type,
  setGeneral,
  onShowHide,
  onRemove,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();

  const resetAllToSystemDefault = useCallback(() => {
    setGeneral(shopDefaultGeneral);
  }, [shopDefaultGeneral]);

  const priceBreakdownOptions = Object.values(
    PromotionSummaryPriceBreakdownBehaviorDtoEnum
  ).map((value) => {
    return {
      value: value,
      label: i18n.translate(`${value}`),
    };
  });

  return (
    <div className='PromotionSummaryGeneral'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onRemove={onRemove}
        onDiscardChanges={() => setGeneral(defaultGeneral)}
        onResetToSystem={resetAllToSystemDefault}
        name={type}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      {type !== WidgetListTypeDtoEnum.NINJA_CART_PROMOTION_SUMMARY && (
        <div className='RightSideSection'>
          <Size
            size={general.size || {}}
            setSize={(data) => setGeneral({ ...general, size: data })}
            disableWidth
            inherited={
              !isPresetEditor
                ? isEqual(general.size, defaultGeneral.size)
                : undefined
            }
            onSystemReset={() =>
              setGeneral({ ...general, size: shopDefaultGeneral.size })
            }
          />
        </div>
      )}

      <div className='RightSideSection'>
        <SingleRadioSelection
          label={i18n.translate('PriceBreakdown')}
          options={priceBreakdownOptions}
          inherited={
            !isPresetEditor
              ? isEqual(general.priceBreakdown, defaultGeneral.priceBreakdown)
              : undefined
          }
          selectedOption={general.priceBreakdown as string}
          setOption={(value) =>
            setGeneral({
              ...general,
              priceBreakdown:
                value as PromotionSummaryPriceBreakdownBehaviorDtoEnum,
            })
          }
          onSystemReset={() =>
            setGeneral({
              ...general,
              priceBreakdown: shopDefaultGeneral.priceBreakdown,
            })
          }
        />
      </div>
      {general.priceBreakdown ===
        PromotionSummaryPriceBreakdownBehaviorDtoEnum.ALLOW_COLLAPSING && (
        <div className='RightSideSection'>
          <Icon
            icon={general.expandButton || {}}
            title={i18n.translate('ExpandButton')}
            showIcon
            inherited={
              !isPresetEditor
                ? isEqual(general.expandButton, defaultGeneral.expandButton)
                : undefined
            }
            groupKey={ResourceGroupKeyDtoEnum.CHEVRONS}
            setIcon={(data) =>
              setGeneral({
                ...general,
                expandButton: data,
              })
            }
            onSystemReset={() =>
              setGeneral({
                ...general,
                expandButton: shopDefaultGeneral.expandButton,
              })
            }
          />
        </div>
      )}

      <div className='RightSideSection'>
        <CornerRadius
          cornerRadius={general?.cornerRadius || {}}
          inherited={
            !isPresetEditor
              ? isEqual(general.cornerRadius, defaultGeneral.cornerRadius)
              : undefined
          }
          setCornerRadius={(data) =>
            setGeneral({ ...general, cornerRadius: data })
          }
          onSystemReset={() =>
            setGeneral({
              ...general,
              cornerRadius: shopDefaultGeneral.cornerRadius,
            })
          }
        />
      </div>
      <div className='RightSideSection'>
        <Background
          background={general.background || {}}
          allowIndicatorLine={
            type !== WidgetListTypeDtoEnum.NINJA_CART_PROMOTION_SUMMARY
          }
          inherited={
            !isPresetEditor
              ? isEqual(general.background, defaultGeneral.background)
              : undefined
          }
          setBackground={(data) => setGeneral({ ...general, background: data })}
          onSystemReset={() =>
            setGeneral({
              background: shopDefaultGeneral.background,
            })
          }
        />
      </div>
    </div>
  );
};

export default PromotionSummaryGeneral;
