import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './PromotionCodeField.scss';
import { useI18n } from '@shopify/react-i18n';
import { useAppSelector } from 'core/hooks';
import { getCurrentMessage } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import { DeviceTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import {
  WidgetsFetchTypeEnum,
  useConfigureWidgets,
} from 'features/settings/hooks/useConfigureWidgets';
import { formatStringQuotes } from '../../utils/utils';

type PromotionCodeFieldProps = {
  data: any;
  previewType: DeviceTypeDtoEnum;
  defaultLanguage?: string;
  adminActivePath: string;
  getSelectionFromThePreview(path: string): void;
  root: string;
};

export const PromotionCodeField: React.FC<PromotionCodeFieldProps> = ({
  previewType,
  defaultLanguage,
  data,
  adminActivePath,
  getSelectionFromThePreview,
  root,
}) => {
  const [i18n] = useI18n();
  const { fontListData } = useConfigureWidgets(
    WidgetsFetchTypeEnum.PRESET_FONT_LIST
  );

  const {
    defaultIconLibary,
    defaultImageLibary,
    customIconLibary,
    customImageLibary,
  } = useAppSelector((state) => state.widgets);
  const { smartTagsCodes } = useAppSelector((state) => state.offersWizard);

  const [triggerRenderCount, setTriggerRenderCount] = useState<number>(0);
  const [injectStyles, setInjectStyles] = useState<boolean>(false);

  const imagesArray = useMemo(
    () => [...defaultImageLibary, ...customImageLibary],
    [customImageLibary, defaultImageLibary]
  );
  const iconsArray = useMemo(
    () => [...defaultIconLibary, ...customIconLibary],
    [customIconLibary, defaultIconLibary]
  );

  const assetsArray = useMemo(
    () =>
      [...iconsArray, ...imagesArray].map((item) => ({
        id: item.id,
        url: item.url,
      })),
    [iconsArray, imagesArray]
  );

  const currentHeadline = useMemo(
    () =>
      getCurrentMessage(
        data.general.headerText.message.entries,
        defaultLanguage,
        smartTagsCodes
      ),
    [defaultLanguage, data.general.headerText.message.entries, smartTagsCodes]
  );

  const currentPlaceholder = useMemo(
    () =>
      getCurrentMessage(
        data.options.codeInput.placeholder.message.entries,
        defaultLanguage,
        smartTagsCodes
      ),
    [
      defaultLanguage,
      data.options.codeInput.placeholder.message.entries,
      smartTagsCodes,
    ]
  );

  const currentError = useMemo(
    () =>
      getCurrentMessage(
        data.options.codeValidation.invalidState.message.message.entries,
        defaultLanguage,
        smartTagsCodes
      ),
    [
      data.options.codeValidation.invalidState.message.message.entries,
      defaultLanguage,
      smartTagsCodes,
    ]
  );

  const currentCodeAppliedState = useMemo(
    () =>
      getCurrentMessage(
        data.options.codeValidation.codeAppliedState.message.message.entries,
        defaultLanguage,
        smartTagsCodes
      ),
    [
      data.options.codeValidation.codeAppliedState.message.message.entries,
      defaultLanguage,
      smartTagsCodes,
    ]
  );

  const currentNotApplicableState = useMemo(
    () =>
      getCurrentMessage(
        data.options.codeValidation.notApplicableState.message.message.entries,
        defaultLanguage,
        smartTagsCodes
      ),
    [
      data.options.codeValidation.notApplicableState.message.message.entries,
      defaultLanguage,
      smartTagsCodes,
    ]
  );

  const currentCTA = useMemo(
    () =>
      getCurrentMessage(
        data.options.callToActionButton.label.message.entries,
        defaultLanguage,
        smartTagsCodes
      ),
    [
      data.options.callToActionButton.label.message.entries,
      defaultLanguage,
      smartTagsCodes,
    ]
  );

  const elementToReplaceWith = useMemo(
    () =>
      `
            <la-dn-promotion-code-field
    admin-mode="true"
    admin-active-path="${adminActivePath}"
    admin-mode-root="${root}"
    admin-mode-label='[{"component":"${root}","label":"${i18n.translate(
        'promotionCode'
      )}","icon":"widget","subComponents":[{"component":"codeInput","label":"${i18n.translate(
        'codeField'
      )}","icon":"component"},{"component":"codeValidation","label":"${i18n.translate(
        'codeValidation'
      )}","icon":"component"},{"component":"callToActionButton","label":"${i18n.translate(
        'ctaButton'
      )}","icon":"component"}]}]'
    headline="true"
    collapsed="false"
  >
    <la-dn-promotion-code-field-headline
      label="${formatStringQuotes(currentHeadline)}"
      style-type="2"
    ></la-dn-promotion-code-field-headline>

    <la-dn-promotion-code-field-input
      placeholder="${formatStringQuotes(currentPlaceholder)}"
      apply="${formatStringQuotes(currentCTA)}"
      searching="Searching"
      error="${formatStringQuotes(currentError)}"
    ></la-dn-promotion-code-field-input>

    <la-dn-promotion-code-field-codes
      learn-more="Learn more"
      applied="${formatStringQuotes(currentCodeAppliedState)}"
      not-applied="${formatStringQuotes(currentNotApplicableState)}"
    ></la-dn-promotion-code-field-codes>
  </la-dn-promotion-code-field>
            `,
    [
      adminActivePath,
      i18n,
      currentHeadline,
      currentPlaceholder,
      currentError,
      currentNotApplicableState,
      currentCodeAppliedState,
      currentCTA,
      root,
    ]
  );

  const showPromotionCodeField = useCallback(() => {
    const parser = new DOMParser();
    const container = document.querySelector('#promotion-code-field-root');

    const promotionCodeField = parser
      .parseFromString(elementToReplaceWith, 'text/html')
      .querySelector('la-dn-promotion-code-field');

    setTriggerRenderCount((prev) => prev + 1);

    if (
      container &&
      promotionCodeField &&
      !container.querySelector('la-dn-promotion-code-field')
    ) {
      container?.appendChild(promotionCodeField);
    } else if (
      container &&
      promotionCodeField &&
      container.querySelector('la-dn-promotion-code-field')
    ) {
      const currentPromotionCodeField = container.querySelector(
        'la-dn-promotion-code-field'
      );

      if (currentPromotionCodeField) {
        const headline = currentPromotionCodeField.querySelector(
          'la-dn-promotion-code-field-headline'
        );
        const input = currentPromotionCodeField.querySelector(
          'la-dn-promotion-code-field-input'
        );
        const codes = currentPromotionCodeField.querySelector(
          'la-dn-promotion-code-field-codes'
        );

        if (headline) {
          headline?.setAttribute(
            'label',
            promotionCodeField
              .querySelector('la-dn-promotion-code-field-headline')
              ?.getAttribute('label') || ''
          );
        }
        if (codes) {
          codes?.setAttribute(
            'applied',
            promotionCodeField
              .querySelector('la-dn-promotion-code-field-codes')
              ?.getAttribute('applied') || ''
          );
          codes?.setAttribute(
            'not-applied',
            promotionCodeField
              .querySelector('la-dn-promotion-code-field-codes')
              ?.getAttribute('not-applied') || ''
          );
        }
        if (input) {
          input?.setAttribute(
            'placeholder',
            promotionCodeField
              .querySelector('la-dn-promotion-code-field-input')
              ?.getAttribute('placeholder') || ''
          );
          input?.setAttribute(
            'error',
            promotionCodeField
              .querySelector('la-dn-promotion-code-field-input')
              ?.getAttribute('error') || ''
          );
          input?.setAttribute(
            'apply',
            promotionCodeField
              .querySelector('la-dn-promotion-code-field-input')
              ?.getAttribute('apply') || ''
          );
        }
      }
    }
  }, [elementToReplaceWith, setTriggerRenderCount]);

  useEffect(() => {
    if (triggerRenderCount) {
      document.dispatchEvent(
        new CustomEvent('la:dn:promotion-code-field:render')
      );

      setInjectStyles(true);
    }
  }, [triggerRenderCount]);

  useEffect(() => {
    if (injectStyles && data) {
      const promotionCodeField = document.querySelector(
        'la-dn-promotion-code-field'
      );
      promotionCodeField?.setAttribute('styles', JSON.stringify(data));
    }
  }, [data, injectStyles]);

  useEffect(() => {
    if (injectStyles) {
      const promotionCodeField = document.querySelector(
        'la-dn-promotion-code-field'
      );
      promotionCodeField?.setAttribute(
        'force-mobile',
        (previewType === DeviceTypeDtoEnum.MOBILE).toString()
      );
    }
  }, [injectStyles, previewType]);

  useEffect(() => {
    if (injectStyles) {
      const promotionCodeField = document.querySelector(
        'la-dn-promotion-code-field'
      );
      promotionCodeField?.setAttribute('admin-active-path', adminActivePath);
    }
  }, [adminActivePath]);

  useEffect(() => {
    if (injectStyles) {
      const promotionCodeField = document.querySelector(
        'la-dn-promotion-code-field'
      );
      promotionCodeField?.setAttribute(
        'font-assets',
        JSON.stringify(fontListData)
      );
    }
  }, [fontListData, injectStyles]);

  useEffect(() => {
    if (injectStyles) {
      const promotionCodeField = document.querySelector(
        'la-dn-promotion-code-field'
      );
      promotionCodeField?.setAttribute(
        'image-assets',
        JSON.stringify(assetsArray)
      );
    }
  }, [assetsArray, injectStyles]);

  useEffect(() => {
    showPromotionCodeField();
    document
      .querySelector('la-dn-promotion-code-field')
      ?.addEventListener('la:dn:promotion-code-field:admin-change', (e) =>
        getSelectionFromThePreview((e as CustomEvent).detail)
      );
    const state = [
      { token: 'ABCD', applied: false, removeable: true, code: 'XFE6700' },
      { token: 'EFGH', applied: true, removeable: true, code: 'LOYALTY123' },
    ];

    document.addEventListener('la:dn:promotion-code-field:codes', () => {
      document.dispatchEvent(
        new CustomEvent('la:dn:promotion-code-field:codes-response', {
          detail: state,
        })
      );
    });
  }, [
    currentHeadline,
    currentPlaceholder,
    currentError,
    currentCodeAppliedState,
    currentNotApplicableState,
    currentCTA,
  ]);

  return <div id='promotion-code-field-root'></div>;
};
