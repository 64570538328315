import React, { useMemo } from 'react';
import './AnnouncementBarAnimations.scss';
import { useI18n } from '@shopify/react-i18n';
import { AnnouncementBarAnimationsShopSettingsDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { AnimationDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import StyleComponentHeader from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components/StyleComponentHeader/StyleComponentHeader';
type AnnouncementBarAnimationsProps = {
  animations: AnnouncementBarAnimationsShopSettingsDto;
  disabled?: boolean;
  setAnimations?: (data: AnnouncementBarAnimationsShopSettingsDto) => void;
  onSystemReset?: () => void;
};
export const AnnouncementBarAnimations: React.FC<
  AnnouncementBarAnimationsProps
> = ({ animations, disabled, setAnimations, onSystemReset }) => {
  const [i18n] = useI18n();

  const animationsOptions = useMemo(
    () =>
      Object.keys(AnimationDtoEnum).map((value) => ({
        value: value,
        label: i18n.translate(`${value}`),
      })),
    []
  );

  return (
    <div className='AnnouncementBarAnimations'>
      <StyleComponentHeader
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            disabled: disabled,
            onAction: () => onSystemReset?.(),
          },
        ]}
        label={i18n.translate(`Animations`)}
      >
        <div className='AnimationsRow'>
          {i18n.translate(`ClosingWidget`)}
          <div className='RightContent'>
            <SelectOptions
              options={animationsOptions}
              disabled={disabled}
              onOptionSelect={(value) =>
                setAnimations?.({
                  ...animations,
                  onClose: value as AnimationDtoEnum,
                })
              }
              selectedOption={
                animations.onClose || AnimationDtoEnum.FADE_OUT_BOTTOM
              }
              label=''
            />
          </div>
        </div>
        <div className='AnimationsRow'>
          {i18n.translate(`CyclingOffers`)}
          <div className='RightContent'>
            <SelectOptions
              options={animationsOptions}
              disabled={disabled}
              onOptionSelect={(value) =>
                setAnimations?.({
                  ...animations,
                  whenCycling: value as AnimationDtoEnum,
                })
              }
              selectedOption={
                animations.whenCycling || AnimationDtoEnum.FADE_OUT
              }
              label=''
            />
          </div>
        </div>
      </StyleComponentHeader>
    </div>
  );
};
