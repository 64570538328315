import React, { useMemo } from 'react';
import { Banner, BlockStack, Card, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { AnnouncementBarAnimationsDto } from 'core/api/adminSettings/adminSettingsApi';
import { AnimationDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import { Element } from 'react-scroll';

type AnimationsSettingProps = {
  animations: AnnouncementBarAnimationsDto;
  setAnimations: (value: AnnouncementBarAnimationsDto) => void;
};
export const AnimationsSetting: React.FC<AnimationsSettingProps> = ({
  animations,
  setAnimations,
}) => {
  const [i18n] = useI18n();
  const closingOptions = useMemo(
    () =>
      [
        AnimationDtoEnum.NONE,
        AnimationDtoEnum.FADE_OUT,
        AnimationDtoEnum.SLIDE_OUT_LEFT,
        AnimationDtoEnum.SLIDE_OUT_RIGHT,
        AnimationDtoEnum.SLIDE_OUT_TOP,
        AnimationDtoEnum.SLIDE_OUT_BOTTOM,
      ].map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    []
  );

  const cyclingOptions = useMemo(
    () =>
      [
        AnimationDtoEnum.NONE,
        AnimationDtoEnum.SLIDE,
        AnimationDtoEnum.FADE,
      ].map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    []
  );

  return (
    <Element className='AnimationsSetting' name='AnimationsSetting'>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('Animations')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('Subtitle')}
            </Text>
          </BlockStack>
          <SelectOptions
            options={closingOptions}
            onOptionSelect={(value) => {
              setAnimations({
                ...animations,
                onClose: value as AnimationDtoEnum,
              });
            }}
            selectedOption={animations.onClose || AnimationDtoEnum.NONE}
            label={i18n.translate('ClosingTheWidget')}
          />
          <SelectOptions
            options={cyclingOptions}
            onOptionSelect={(value) => {
              setAnimations({
                ...animations,
                cycling: value as AnimationDtoEnum,
              });
            }}
            selectedOption={animations.cycling || AnimationDtoEnum.NONE}
            label={i18n.translate('CyclingOffers')}
          />
          <Banner tone='info'>{i18n.translate('BannerText')}</Banner>
        </BlockStack>
      </Card>
    </Element>
  );
};
