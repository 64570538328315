import {
  PromotionSetupDtoRead,
  PresetsSetupDto,
  PricingSystemDefaultsDto,
  OfferWidgetSetupDtoPricingOfferDtoRead,
  PriceStateGeneralDto,
  PriceStateLevelDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import React, { useCallback, useMemo } from 'react';
import { SelectedOptionPath } from '../../../../RightSideBarWrapper';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { getLastSelectedOption } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import PriceState from '../../../EditPresetRightBar/components/PresetPrice/components/PriceState/PriceState';
import PriceOffer from './components/PriceOffer/PriceOffer';
import PriceText from '../../../EditPresetRightBar/components/PresetPrice/components/PriceText/PriceText';
import { PromotionPriceWidgetTypesDtoEnum } from '../../EditPromotionRightBar';

type PromotionPriceProps = {
  editPromotionState: PromotionSetupDtoRead;
  defaultPromotionData: PromotionSetupDtoRead;
  shopDefaultData: PricingSystemDefaultsDto;
  selectedOptionPath: SelectedOptionPath;
  priceType: PromotionPriceWidgetTypesDtoEnum;
  updateEditPromotionState: (
    field: keyof PromotionSetupDtoRead,
    data: PromotionSetupDtoRead[keyof PromotionSetupDtoRead],
    presetSetup?: PresetsSetupDto
  ) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const PromotionPrice: React.FC<PromotionPriceProps> = ({
  editPromotionState,
  priceType,
  defaultPromotionData,
  shopDefaultData,
  selectedOptionPath,
  updateEditPromotionState,
  handleLeftMenuSelection,
}) => {
  const price = editPromotionState?.[priceType];
  const presetSetup = editPromotionState.presetSetup;
  const pricePreset = presetSetup?.[priceType];
  const offersData = editPromotionState.offers?.[priceType];
  const defaultOffersData = defaultPromotionData.offers?.[priceType];
  const defaultPrice = defaultPromotionData?.[priceType];
  const defaultPresetSetup = defaultPromotionData.presetSetup?.[priceType];

  const updateOffer = useCallback(
    (data: OfferWidgetSetupDtoPricingOfferDtoRead, offerId: string) =>
      updateEditPromotionState('offers', {
        ...editPromotionState.offers,
        [priceType]: editPromotionState.offers?.[priceType]?.map(
          (offerData) => {
            if (offerData?.offerId === offerId) {
              return data;
            }
            return offerData;
          }
        ),
      }),

    [updateEditPromotionState, editPromotionState]
  );

  const updatePricePresetGeneral = useCallback(
    (data: PriceStateGeneralDto) => {
      updateEditPromotionState('presetSetup', presetSetup, {
        ...presetSetup,
        [priceType]: {
          ...presetSetup?.[priceType],
          options: {
            ...presetSetup?.[priceType]?.options,
            discountedPrice: {
              ...presetSetup?.[priceType]?.options?.discountedPrice,
              general: data,
            },
          },
        },
      });
    },
    [presetSetup, updateEditPromotionState]
  );

  const updatePricePresetOption = useCallback(
    (
      field: keyof PriceStateLevelDto,
      data: PriceStateLevelDto[keyof PriceStateLevelDto]
    ) => {
      updateEditPromotionState('presetSetup', presetSetup, {
        ...presetSetup,
        [priceType]: {
          ...presetSetup?.[priceType],
          options: {
            ...presetSetup?.[priceType]?.options,
            discountedPrice: {
              ...presetSetup?.[priceType]?.options?.discountedPrice,
              options: {
                ...presetSetup?.[priceType]?.options?.discountedPrice?.options,
                [field]: data,
              },
            },
          },
        },
      });
    },
    [presetSetup, updateEditPromotionState]
  );

  const priceOptions = useMemo(() => {
    const offerData = offersData?.find(
      (offer) =>
        offer.offerId ===
        selectedOptionPath?.selectedOption?.selectedOption?.offerId
    ) as OfferWidgetSetupDtoPricingOfferDtoRead;
    const defaultOfferData = defaultOffersData?.find(
      (offer) =>
        offer.offerId ===
        selectedOptionPath?.selectedOption?.selectedOption?.offerId
    ) as OfferWidgetSetupDtoPricingOfferDtoRead;

    const selectedOption = getLastSelectedOption(selectedOptionPath);

    switch (true) {
      case selectedOption?.name === WidgetListOptionsDtoEnum.DISCOUNTED_PRICE:
        return (
          <PriceState
            price={pricePreset?.options?.discountedPrice?.general || {}}
            defaultPrice={
              defaultPresetSetup?.options?.discountedPrice?.general || {}
            }
            priceType={priceType}
            shopDefaultPrice={shopDefaultData.discounted || {}}
            stateType={WidgetListOptionsDtoEnum.DISCOUNTED_PRICE}
            setPrice={updatePricePresetGeneral}
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case !!selectedOption?.offerId:
        return (
          <PriceOffer
            key={offerData?.offerId}
            offer={offerData?.setup?.general || {}}
            offerName={selectedOption?.name || ''}
            defaultOffer={defaultOfferData?.setup?.general || {}}
            shopDefaultOffer={shopDefaultData.discounted?.customCss || ''}
            presetSetup={
              pricePreset?.options?.discountedPrice?.general?.customCss || ''
            }
            onShowHide={
              offerData?.canBeHidden
                ? () =>
                    updateOffer(
                      { ...offerData, isHidden: !offerData?.isHidden },
                      offerData?.offerId as string
                    )
                : undefined
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
            setOffer={(data) =>
              updateOffer(
                { ...offerData, setup: { ...offerData.setup, general: data } },
                offerData?.offerId as string
              )
            }
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.HEADER_TEXT:
        return (
          <PriceText
            text={
              pricePreset?.options?.discountedPrice?.options?.headerText
                ?.general || {}
            }
            defaultText={
              defaultPresetSetup?.options?.discountedPrice?.options?.headerText
                ?.general || {}
            }
            priceType={priceType}
            stateType={WidgetListOptionsDtoEnum.DISCOUNTED_PRICE}
            textType={WidgetListOptionsDtoEnum.HEADER_TEXT}
            shopDefaultText={shopDefaultData.discounted?.headerText || {}}
            defaultLanguage={editPromotionState.offers?.defaultLanguage || ''}
            setText={(data) =>
              updatePricePresetOption('headerText', {
                ...pricePreset?.options?.discountedPrice?.options?.headerText,
                general: data,
              })
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.FOOTER_TEXT:
        return (
          <PriceText
            text={
              pricePreset?.options?.discountedPrice?.options?.footerText
                ?.general || {}
            }
            defaultText={
              defaultPresetSetup?.options?.discountedPrice?.options?.footerText
                ?.general || {}
            }
            priceType={priceType}
            stateType={WidgetListOptionsDtoEnum.DISCOUNTED_PRICE}
            textType={WidgetListOptionsDtoEnum.FOOTER_TEXT}
            shopDefaultText={shopDefaultData.discounted?.footerText || {}}
            defaultLanguage={editPromotionState.offers?.defaultLanguage || ''}
            setText={(data) =>
              updatePricePresetOption('footerText', {
                ...pricePreset?.options?.discountedPrice?.options?.footerText,
                general: data,
              })
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
    }
  }, [
    price,
    defaultPrice,
    pricePreset,
    offersData,
    defaultPresetSetup,
    defaultOffersData,
    editPromotionState,
    selectedOptionPath,
    handleLeftMenuSelection,
    updateOffer,
  ]);

  return <div className='PromotionPrice'>{priceOptions}</div>;
};

export default PromotionPrice;
