import React, { useCallback, useState } from 'react';
import {
  BlockStack,
  Button,
  Card,
  InlineStack,
  Page,
  Text,
} from '@shopify/polaris';
import { ImportIcon, ExportIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import useExportSettings from './utils/useExportSettings';
import { UploadFileModal } from 'core/components';

export const ImportExport: React.FC = () => {
  const [i18n] = useI18n();
  const { importSettings, importSettingsIsLoading } = useConfigureSettings(
    SettingsFetchTypeEnum.IMPORT_EXPORT
  );

  const { exportSettings, exportSettingsIsFetching } = useExportSettings();
  const [importModal, setImportModal] = useState<boolean>(false);

  const handleImportSettings = useCallback((value: File[]) => {
    const file = value[0];
    const blob = new Blob([file], { type: file.type });
    const formData = new FormData();
    formData.append('file', blob, file.name);
    importSettings(formData).then(() => {
      setImportModal(false);
    });
  }, []);
  return (
    <Page title={i18n.translate(`ImportExport`)}>
      <BlockStack gap='400'>
        <Card>
          <InlineStack align='space-between' blockAlign='center'>
            <BlockStack gap='100'>
              <Text as='h2' variant='headingSm'>
                {i18n.translate('ImportSettings')}
              </Text>
              <Text as='p' tone='subdued'>
                {i18n.translate('ImportSettingsSubtitle')}
              </Text>
            </BlockStack>
            <Button onClick={() => setImportModal(true)} icon={ImportIcon}>
              {i18n.translate('Import')}
            </Button>
          </InlineStack>
        </Card>
        <Card>
          <InlineStack align='space-between' blockAlign='center'>
            <BlockStack gap='100'>
              <Text as='h2' variant='headingSm'>
                {i18n.translate('ExportSettings')}
              </Text>
              <Text as='p' tone='subdued'>
                {i18n.translate('ExportSettingsSubtitle')}
              </Text>
            </BlockStack>
            <Button
              loading={exportSettingsIsFetching}
              onClick={exportSettings}
              icon={ExportIcon}
            >
              {i18n.translate('Export')}
            </Button>
          </InlineStack>
        </Card>
      </BlockStack>
      {importModal && (
        <UploadFileModal
          isOpen={importModal}
          fileFormat='.dnsettings'
          title={i18n.translate('ImportSettings')}
          subtitle={i18n.translate('ImportSettingsSubtitle')}
          onClose={() => setImportModal(false)}
          onUploadFiles={handleImportSettings}
          uploadIsLoading={importSettingsIsLoading}
        />
      )}
    </Page>
  );
};
