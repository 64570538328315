import React, { useCallback } from 'react';
import {
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  InlineStack,
  Link,
  RangeSlider,
  Text,
  TextField,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { OptionDtoInt32 } from 'core/api/adminSettings/adminSettingsApi';
type CheckoutLoadingEffectProps = {
  loadingEffect?: OptionDtoInt32;
  setLoadingEffect: (value: OptionDtoInt32) => void;
};
export const CheckoutLoadingEffect: React.FC<CheckoutLoadingEffectProps> = ({
  loadingEffect,
  setLoadingEffect,
}) => {
  const [i18n] = useI18n();

  const validateLoadingEffect = useCallback(() => {
    const value = loadingEffect?.value || 2;
    setLoadingEffect({
      ...loadingEffect,
      value: value > 16 ? 16 : value < 2 ? 2 : value,
    });
  }, [loadingEffect]);

  return (
    <div className='CheckoutLoadingEffect'>
      <Card>
        <BlockStack gap='400'>
          <InlineStack
            align='space-between'
            blockAlign='start'
            wrap={false}
            gap='600'
          >
            <BlockStack gap='100'>
              <InlineStack gap='200' align='start'>
                <Text fontWeight='semibold' as='h2'>
                  {i18n.translate('Title')}
                </Text>
                <Box>
                  <Badge tone={loadingEffect?.enabled ? 'success' : undefined}>
                    {i18n.translate(loadingEffect?.enabled ? 'On' : 'Off')}
                  </Badge>
                </Box>
              </InlineStack>
              <Text as='p' tone='subdued'>
                {i18n.translate('Subtitle', {
                  followingArticle: (
                    <Link>{i18n.translate('FollowingArticle')}</Link>
                  ),
                })}
              </Text>
            </BlockStack>
            <Box minWidth='max-content'>
              <Button
                onClick={() =>
                  setLoadingEffect({
                    ...loadingEffect,
                    enabled: !loadingEffect?.enabled,
                  })
                }
              >
                {i18n.translate(loadingEffect?.enabled ? 'TurnOff' : 'TurnOn')}
              </Button>
            </Box>
          </InlineStack>
          {loadingEffect?.enabled && (
            <>
              <Text as='p'>{i18n.translate('LoadingEffectDuration')}</Text>
              <InlineStack gap='600' wrap={false} align='center'>
                <Box minWidth='240px'>
                  <TextField
                    helpText={i18n.translate('DurationRange')}
                    autoComplete=''
                    label=''
                    type='number'
                    value={loadingEffect?.value?.toString()}
                    onChange={(value) =>
                      setLoadingEffect({ ...loadingEffect, value: +value })
                    }
                    onBlur={validateLoadingEffect}
                  />
                </Box>
                <Box width='100%' paddingBlockStart='200'>
                  <RangeSlider
                    output
                    label=''
                    min={2}
                    max={16}
                    value={loadingEffect?.value as number}
                    onChange={(value) =>
                      setLoadingEffect({
                        ...loadingEffect,
                        value: value as number,
                      })
                    }
                  />
                </Box>
              </InlineStack>
            </>
          )}
        </BlockStack>
      </Card>
    </div>
  );
};
