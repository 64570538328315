import React from 'react';
import {
  BlockStack,
  Box,
  InlineStack,
  MediaCard,
  Text,
  VideoThumbnail,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import StrikethroughOff from '../../assets/StrikethroughOff.svg';
import StrikethroughOn from '../../assets/StrikethroughOn.svg';
import NinjaCart from '../../assets/NinjaCart.svg';
import ProductBanners from '../../assets/ProductBanners.svg';

export const WidgetsIntroStep = () => {
  const [i18n] = useI18n();

  return (
    <Box paddingBlock='400'>
      <BlockStack gap='600'>
        <BlockStack gap='200'>
          <Text as='h1' variant='headingLg' fontWeight='semibold'>
            {i18n.translate('IntroTitle')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('IntroSubtitle')}
          </Text>
        </BlockStack>
        <MediaCard
          title={i18n.translate('WidgetsLearnMore')}
          primaryAction={{
            content: i18n.translate('LearnMore'),
          }}
          description={i18n.translate('WidgetsDescription')}
          size='small'
        >
          <VideoThumbnail
            videoLength={80}
            thumbnailUrl='https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850'
            onClick={() =>
              window.open(
                'https://www.youtube.com/watch?v=RVmG_d3HKBA',
                '_blank'
              )
            }
          />
        </MediaCard>
        <BlockStack gap='100'>
          <Text as='h1' fontWeight='semibold'>
            {i18n.translate('StrikethroughPricing')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('SPDescription')}
          </Text>
        </BlockStack>
        <InlineStack wrap={false} align='space-between' gap='400'>
          <img src={StrikethroughOff} alt='StrikethroughOff' />
          <img src={StrikethroughOn} alt='StrikethroughOn' />
        </InlineStack>
        <BlockStack gap='100'>
          <Text as='h1' fontWeight='semibold'>
            {i18n.translate('NinjaCart')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('NJDescription')}
          </Text>
        </BlockStack>
        <img src={NinjaCart} alt='NinjaCart' />
        <BlockStack gap='100'>
          <Text as='h1' fontWeight='semibold'>
            {i18n.translate('AddBlocks')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('AddBlocksDescription')}
          </Text>
        </BlockStack>
        <img src={ProductBanners} alt='ProductBanners' />
      </BlockStack>
    </Box>
  );
};
