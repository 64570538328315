import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  token: string | null | undefined;
  email: string;
  password: string;
}

const initialState: AuthState = {
  token: localStorage.getItem('token'),
  email: localStorage.getItem('emailDevLogin') || '',
  password: localStorage.getItem('passwordDevLogin') || '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<{ token: string }>) => {
      state.token = action.payload.token;
    },
    clearToken: (state) => {
      state.token = null;
    },
    setEmail: (state, action: PayloadAction<{ email: string }>) => {
      state.email = action.payload.email;
    },
    setPassword: (state, action: PayloadAction<{ password: string }>) => {
      state.password = action.payload.password;
    },
  },
});

export const { setToken, clearToken, setEmail, setPassword } =
  authSlice.actions;

export default authSlice.reducer;
