import React, { useCallback, useMemo } from 'react';
import {
  Badge,
  Banner,
  Bleed,
  BlockStack,
  Box,
  Button,
  ButtonGroup,
  Card,
  Icon,
  InlineStack,
  Link,
  ProgressBar,
  Text,
} from '@shopify/polaris';
import { InfoIcon, DiscountIcon, CheckIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import {
  GetCurrentPlanResponseDto,
  PlanLevelDto,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  PlanIntervalDtoEnum,
  PlanLevelDtoEnum,
  PlanThresholdTypeDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import { formatNumber, formatTimeDistance } from 'core/utils';
import { format } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import { useConfigureSettings } from 'features/settings/hooks/useConfigureSettings';
import { ChangePlanConfigDto } from '../../Plan';
import { ChangePlanModalTypeEnum } from '../ChangePlanModal/ChangePlanModal';

type UserSubscriptionPlanProps = {
  currentPlanData?: GetCurrentPlanResponseDto | null;
  setChangePlanConfig: (data: ChangePlanConfigDto) => void;
  refetchCurrentPlanData: () => void;
};
export const UserSubscriptionPlan: React.FC<UserSubscriptionPlanProps> = ({
  currentPlanData,
  setChangePlanConfig,
  refetchCurrentPlanData,
}) => {
  const [i18n] = useI18n();
  const {
    resumeSubscription,
    resumeSubscriptionIsLoading,
    selectSubscription,
    selectSubscriptionIsLoading,
  } = useConfigureSettings();
  const [, setSearchParams] = useSearchParams();
  const { billing, plan, usage } = currentPlanData as GetCurrentPlanResponseDto;

  const isEnterprise = useMemo(
    () => plan?.level === PlanLevelDtoEnum.ENTERPRISE,
    [plan?.level]
  );

  const getOrdinalSuffix = useCallback((day: number): string => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }, []);

  const formatedBillingDate = useMemo(() => {
    return billing?.endDate && billing?.startDate
      ? `${format(
          new Date(billing?.startDate as number),
          'MMM d, yyyy'
        )} - ${format(new Date(billing?.endDate as number), 'MMM d, yyyy')}`
      : '';
  }, [billing?.endDate, billing?.startDate]);

  const thresholdPercentage = useMemo(
    () =>
      Math.round(
        (100 * (usage?.mainUsageDetails?.usage as number)) /
          (usage?.mainUsageDetails?.threshold as number)
      ),
    [usage?.mainUsageDetails?.threshold, usage?.mainUsageDetails?.usage]
  );

  const overagePercentage = useMemo(
    () =>
      Math.round(
        (100 * (usage?.overageUsageDetails?.usage as number)) /
          (usage?.overageUsageDetails?.threshold as number)
      ),
    [usage?.overageUsageDetails?.threshold, usage?.overageUsageDetails?.usage]
  );

  const showOverageProgressBar = useMemo(
    () =>
      !!(
        thresholdPercentage === 100 &&
        usage?.overageUsageDetails?.threshold &&
        plan?.thresholdType === PlanThresholdTypeDtoEnum.ORDER &&
        !isEnterprise
      ),
    [
      thresholdPercentage,
      usage?.overageUsageDetails,
      plan?.thresholdType,
      isEnterprise,
    ]
  );

  const isDiscountApplied = useMemo(() => {
    if (!plan?.price?.discount) return null;
    return !!plan?.price?.discount.isDiscountApplied;
  }, [plan?.price?.discount]);

  const formatTrialDate = useCallback((timestamp: number): string => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const dayWithSuffix = `${day}${getOrdinalSuffix(day)}`;
    const formattedDate = format(date, `MMMM yyyy 'at' HH:mm`);

    return `${dayWithSuffix} ${formattedDate}`;
  }, []);

  const navigateThrough = useCallback(
    (subPath: string) => {
      setSearchParams((params) => {
        params.set('subPath', subPath);
        return params;
      });
    },
    [setSearchParams]
  );

  const selectCurrentSubscription = useCallback(
    () =>
      selectSubscription(
        {
          interval: plan?.interval,
          level: plan?.level,
          thresholdType: plan?.thresholdType,
        },
        true
      ),
    [plan]
  );

  const handleResumeSubscription = useCallback(
    () => resumeSubscription(refetchCurrentPlanData),
    [resumeSubscription]
  );

  const handleUpgardePlan = useCallback(
    () =>
      setChangePlanConfig({
        interval: plan?.interval as PlanIntervalDtoEnum,
        modalType: ChangePlanModalTypeEnum.CHANGE_PLAN,
        targetPlanLevel: plan?.upgradeDetails?.nextHigherTierPlan
          ?.level as PlanLevelDto,
      }),
    [plan?.upgradeDetails?.nextHigherTierPlan, plan?.interval]
  );

  const handleIncreaseThreshold = useCallback(
    () =>
      setChangePlanConfig({
        interval: plan?.interval as PlanIntervalDtoEnum,
        modalType: ChangePlanModalTypeEnum.INCREASE_THRESHOLD,
        threshold: usage?.mainUsageDetails?.usage,
        targetPlanLevel: plan?.upgradeDetails?.nextSameTierPlan
          ?.level as PlanLevelDto,
      }),
    [plan?.upgradeDetails, usage?.mainUsageDetails, plan?.interval]
  );

  return (
    <Card>
      <BlockStack gap='400'>
        <InlineStack align='space-between' blockAlign='center'>
          <BlockStack gap='100'>
            <Text as='h2' fontWeight='semibold'>
              {i18n.translate('PlanDetail')}
            </Text>
            <Text tone='subdued' as='p'>
              {i18n.translate('PlanDetailSubtitle')}
            </Text>
          </BlockStack>
          {plan && (
            <Button onClick={() => navigateThrough('pick-your-plan')}>
              {i18n.translate('ChangePlan')}
            </Button>
          )}
        </InlineStack>
        <Box
          borderColor='border'
          borderWidth='025'
          borderRadius='200'
          padding='400'
        >
          {!plan?.name ? (
            <BlockStack inlineAlign='start' gap='400'>
              <Text as='h1' variant='headingLg' fontWeight='semibold'>
                {i18n.translate('NoPlanSelected')}
              </Text>
              <Banner tone='warning'>{i18n.translate('NoPlanWarning')}</Banner>
              <ButtonGroup>
                {currentPlanData?.plan?.previousPlan && (
                  <Button
                    loading={selectSubscriptionIsLoading}
                    onClick={selectCurrentSubscription}
                    variant='primary'
                  >
                    {i18n.translate('ReactivatePrevPlan', {
                      prevPlan: currentPlanData?.plan?.previousPlan
                        ?.name as string,
                    })}
                  </Button>
                )}
                <Button
                  onClick={() => navigateThrough('pick-your-plan')}
                  variant={
                    currentPlanData?.plan?.previousPlan
                      ? 'secondary'
                      : 'primary'
                  }
                >
                  {i18n.translate('ExplorePlans')}
                </Button>
              </ButtonGroup>
            </BlockStack>
          ) : (
            <BlockStack gap='400'>
              <BlockStack inlineAlign='start' gap='200'>
                <Box width='100%'>
                  <InlineStack align='space-between' blockAlign='end'>
                    <InlineStack gap='200'>
                      <Text as='h1' variant='headingLg' fontWeight='semibold'>
                        {plan.name}
                      </Text>
                      {plan.cancellationDate && (
                        <Badge tone='warning'>
                          {i18n.translate('Cancelled')}
                        </Badge>
                      )}
                      {plan.trialEndDate && (
                        <Text as='span' tone='subdued'>
                          {i18n.translate('FreeTrial')}
                        </Text>
                      )}
                    </InlineStack>
                    <InlineStack gap='100'>
                      {isDiscountApplied && (
                        <Text
                          textDecorationLine='line-through'
                          as='span'
                          tone='subdued'
                        >
                          ${plan.price?.regularPrice}
                        </Text>
                      )}

                      <Text as='h1' variant='headingLg' fontWeight='semibold'>
                        $
                        {isDiscountApplied
                          ? plan.price?.discount?.discountedPrice
                          : plan.price?.regularPrice}
                      </Text>
                      <Text as='span' tone='subdued'>
                        {i18n.translate('USDInterval', {
                          interval: i18n.translate(
                            plan.interval === PlanIntervalDtoEnum.ANNUAL
                              ? 'Year'
                              : 'Month'
                          ),
                        })}
                      </Text>
                    </InlineStack>
                  </InlineStack>
                </Box>
                {isDiscountApplied && (
                  <InlineStack blockAlign='center'>
                    <Icon source={CheckIcon} tone='success' />
                    <Text tone='success' as='p'>
                      {i18n.translate('DiscountFor', {
                        name: (
                          <Text as='span' fontWeight='medium'>
                            {plan.price?.discount?.name}
                          </Text>
                        ),
                        percentage: plan.price?.discount?.discountPercentage,
                      })}
                    </Text>
                  </InlineStack>
                )}
              </BlockStack>

              {plan.cancellationDate && (
                <Banner tone='warning'>
                  <BlockStack gap='200' inlineAlign='start'>
                    <Text as='p'>
                      {i18n.translate('SubscriptionIsCancelled', {
                        endsIn: (
                          <Text fontWeight='semibold' as='span'>
                            {i18n.translate('DeactivatedIn', {
                              date: formatTimeDistance(plan.cancellationDate),
                            })}
                          </Text>
                        ),
                        endDate: format(
                          plan.cancellationDate,
                          "'on' MMMM d, yyyy, 'at' h:mm a"
                        ),
                      })}
                    </Text>
                    <Button
                      onClick={handleResumeSubscription}
                      loading={resumeSubscriptionIsLoading}
                      variant='primary'
                    >
                      {i18n.translate('ResumeSubscription')}
                    </Button>
                  </BlockStack>
                </Banner>
              )}
              {!plan.trialEndDate && isDiscountApplied === false && (
                <Banner
                  icon={DiscountIcon}
                  action={{
                    content: i18n.translate('ApplyDiscountNow'),
                    onAction: selectCurrentSubscription,
                    loading: selectSubscriptionIsLoading,
                  }}
                  tone='success'
                >
                  <Text as='p'>
                    {i18n.translate('DiscountEligible', {
                      name: plan.price?.discount?.name,
                      regular: plan.price?.regularPrice,
                      nowJust: (
                        <Text fontWeight='semibold' as='span'>
                          {i18n.translate('NowJust', {
                            price: plan.price?.discount?.discountedPrice,
                          })}
                        </Text>
                      ),
                    })}
                  </Text>
                  <Text as='p'>
                    {i18n.translate('ApplyYourDiscountNow', {
                      percentage: plan.price?.discount?.discountPercentage,
                    })}
                  </Text>
                </Banner>
              )}
              {plan.trialEndDate && (
                <Banner>
                  <Text as='p'>
                    {i18n.translate('FreeTrialEndIn', {
                      endsIn: (
                        <Text fontWeight='bold' as='span'>
                          {i18n.translate('EndsIn', {
                            date: formatTimeDistance(plan.trialEndDate),
                          })}
                        </Text>
                      ),
                      endDate: formatTrialDate(plan.trialEndDate as number),
                    })}
                  </Text>
                  <Text as='p'>
                    {i18n.translate('BilledInfo', {
                      interval: i18n.translate(
                        plan.interval === PlanIntervalDtoEnum.ANNUAL
                          ? 'Year'
                          : 'Month'
                      ),
                      price: plan.price?.regularPrice,
                    })}
                  </Text>
                </Banner>
              )}
            </BlockStack>
          )}
        </Box>
        {plan?.name && (
          <Box borderColor='border' borderWidth='025' borderRadius='200'>
            <Box
              borderColor='border'
              borderBlockEndWidth='025'
              padding='400'
              background='bg-surface-secondary'
            >
              <Text as='h2' fontWeight='medium'>
                {i18n.translate(`${plan?.thresholdType}_USAGE`)}
              </Text>
            </Box>
            <Box padding='400'>
              <BlockStack gap='400'>
                <Box>
                  <InlineStack gap='100'>
                    <Text as='p' fontWeight='medium'>
                      {i18n.translate(`${plan?.thresholdType}_USED`, {
                        used: usage?.mainUsageDetails?.usage,
                        threshold: usage?.mainUsageDetails?.threshold,
                        percentage: thresholdPercentage,
                      })}
                    </Text>
                    {!isEnterprise && thresholdPercentage === 100 && (
                      <Box>
                        <Icon tone='critical' source={InfoIcon} />
                      </Box>
                    )}
                  </InlineStack>
                  <Text as='p' tone='subdued'>
                    {formatedBillingDate}
                  </Text>
                </Box>
                <ProgressBar
                  tone={
                    thresholdPercentage === 100 && !isEnterprise
                      ? 'critical'
                      : undefined
                  }
                  size='small'
                  progress={thresholdPercentage}
                />
                {showOverageProgressBar && (
                  <>
                    <Box>
                      <Text as='p' fontWeight='medium'>
                        {i18n.translate(`OverageTitle`, {
                          count: usage?.overageUsageDetails?.usage,
                          percentage: overagePercentage,
                        })}
                      </Text>
                      <Text as='p' tone='subdued'>
                        {formatedBillingDate}
                      </Text>
                    </Box>
                    <ProgressBar
                      tone={overagePercentage === 100 ? 'critical' : undefined}
                      size='small'
                      progress={overagePercentage}
                    />
                  </>
                )}
                <Text as='p' tone='subdued'>
                  {i18n.translate(`${plan?.thresholdType}_RESET`, {
                    date: formatTimeDistance(usage?.counterResetDate as number),
                  })}
                </Text>
              </BlockStack>
            </Box>
            {((thresholdPercentage >= 60 && !isEnterprise) ||
              thresholdPercentage > 110) && (
              <Box
                borderColor='border'
                borderBlockStartWidth='025'
                padding='400'
              >
                <BlockStack inlineAlign='start' gap='400'>
                  {!isEnterprise && thresholdPercentage === 100 && (
                    <>
                      <Text as='p'>
                        {i18n.translate(`${plan.thresholdType}_REACHED_LIMIT`, {
                          threshold: (
                            <Text as='span' fontWeight='medium'>
                              {i18n.translate(`${plan?.thresholdType}_COUNT`, {
                                count: usage?.mainUsageDetails?.threshold,
                              })}
                            </Text>
                          ),
                          fee: (
                            <Text as='span' fontWeight='medium'>
                              {i18n.translate('FeeCount', {
                                count: usage?.overageFeeUnitPrice,
                              })}
                            </Text>
                          ),
                          limit: usage?.overageUsageDetails?.threshold,
                        })}
                      </Text>
                      {plan?.thresholdType ===
                        PlanThresholdTypeDtoEnum.SESSION && (
                        <Text as='p'>
                          {i18n.translate('WillBePaused', {
                            hours: (
                              <Text as='span' fontWeight='semibold'>
                                {i18n.translate('Hours', {
                                  count: 20,
                                })}
                              </Text>
                            ),
                          })}
                        </Text>
                      )}
                    </>
                  )}
                  {!isEnterprise && thresholdPercentage < 100 && (
                    <Text as='p'>
                      {i18n.translate(`${plan?.thresholdType}_PlanEndWarning`, {
                        from: (
                          <Text as='span' fontWeight='medium'>
                            {formatNumber(i18n, usage?.mainUsageDetails?.usage)}
                          </Text>
                        ),
                        to: (
                          <Text as='span' fontWeight='medium'>
                            {formatNumber(
                              i18n,
                              usage?.mainUsageDetails?.threshold
                            )}
                          </Text>
                        ),
                        fee: (
                          <Text as='span' fontWeight='medium'>
                            ${usage?.overageFeeUnitPrice},
                          </Text>
                        ),
                      })}
                    </Text>
                  )}
                  {!isEnterprise && (
                    <ButtonGroup>
                      {!!plan.upgradeDetails?.nextSameTierPlan && (
                        <Button
                          onClick={handleIncreaseThreshold}
                          variant='primary'
                        >
                          {i18n.translate('IncreaseThreshold')}
                        </Button>
                      )}
                      <Button
                        onClick={handleUpgardePlan}
                        variant={
                          plan.upgradeDetails?.nextSameTierPlan
                            ? 'secondary'
                            : 'primary'
                        }
                      >
                        {i18n.translate('UpgradeTo', {
                          plan:
                            plan?.upgradeDetails?.nextHigherTierPlan?.name ||
                            '',
                        }) || ''}
                      </Button>
                    </ButtonGroup>
                  )}
                  {isEnterprise && thresholdPercentage > 110 && (
                    <Text as='p'>{i18n.translate('EnterprisePeriodHigh')}</Text>
                  )}
                </BlockStack>
              </Box>
            )}
            <Box padding='400' background='bg-surface-secondary'>
              <Text as='p' tone='subdued'>
                {i18n.translate(`${plan?.thresholdType}_LEARN_MORE`, {
                  learnMore: <Link>{i18n.translate('LearnMore')}</Link>,
                })}
              </Text>
            </Box>
          </Box>
        )}

        {plan?.thresholdType === PlanThresholdTypeDtoEnum.SESSION && (
          <Banner>
            <BlockStack inlineAlign='start' gap='200'>
              <Text as='p'>
                {i18n.translate('OrderBasedAdvice', {
                  learnMore: <Link>{i18n.translate('LearnMore')}</Link>,
                })}
              </Text>
              <Button>{i18n.translate('SwitchOrderBased')}</Button>
            </BlockStack>
          </Banner>
        )}

        {!plan?.name && (
          <Bleed marginBlockEnd='400' marginInline='400'>
            <Box background='bg-surface-secondary' padding='400'>
              <Text as='p' tone='subdued'>
                {i18n.translate('NeedAssistace', {
                  requestSupport: (
                    <Link>{i18n.translate('RequestSupport')}</Link>
                  ),
                })}
              </Text>
            </Box>
          </Bleed>
        )}
      </BlockStack>
    </Card>
  );
};
