import { I18n } from '@shopify/react-i18n';
import { format, formatDistanceToNow, parse } from 'date-fns';
import { upperFirst } from 'lodash';

export const formatDate = (date: Date, i18n?: I18n) => {
  return i18n ? i18n.formatDate(date) : format(date, 'dd/MM/yyyy');
};

export const formatDateFromString = (date: string) =>
  parse(date, 'dd/MM/yyyy', new Date());

export const formatPercentage = (i18n: I18n, value?: number) =>
  i18n.formatNumber((value || 0) * 0.01, {
    precision: 2,
    as: 'percent',
  });

export const formatNumber = (i18n: I18n, value?: number) =>
  i18n.formatNumber(value || 0, {
    precision: 2,
  });

export const toLowerCaseAndCapitalizeFirstLetter = (text: string): string => {
  return upperFirst(text.toLowerCase());
};

export const formatNameEllipsis = (name: string, maxCharacteres: number) =>
  name.length <= maxCharacteres
    ? name
    : name.substring(0, maxCharacteres) + '...';

export const formatDateWithTimezone = (timestamp: number) => {
  const date = new Date(timestamp);
  return format(date, 'dd.MM.yyyy HH:mm zzz');
};
export const formatDateAndTime = (timestamp: number) => {
  const date = new Date(timestamp);
  return format(date, 'dd.MM.yyyy HH:mm');
};
export const formatTimeDistance = (timestamp: number) => {
  const date = new Date(timestamp);
  const now = new Date();
  const diffInMinutes = Math.floor(
    (now.getTime() - date.getTime()) / (1000 * 60)
  );
  if (diffInMinutes < 60) {
    return formatDistanceToNow(date, { addSuffix: true });
  } else if (diffInMinutes < 24 * 60) {
    const diffInHours = Math.floor(diffInMinutes / 60);
    return `${diffInHours} hours ago`;
  } else {
    const diffInDays = Math.floor(diffInMinutes / (24 * 60));
    return `${diffInDays} days ago`;
  }
};

export const formatDateWithOrdinalCET = (timestamp: string) => {
  const date = new Date(parseInt(timestamp));
  const formattedDate = format(date, 'MMMM do yyyy, HH:mm');
  return `${formattedDate} CET`;
};
