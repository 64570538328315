import {
  OfferWidgetSetupDtoAnnouncementBarOfferDtoRead,
  PromotionWidgetSetupMultiLevelDtoAnnouncementBarPromotionDtoAnnouncementBarFirstLevelPromotionDtoAnnouncementBarFirstLevelPromotionEntryTypeDtoRead,
  AnnouncementBarPresetDtoRead,
  AnnouncementBarFirstLevelPresetDtoRead,
} from 'core/api/adminWidgets/adminWidgetsApi';
import {
  AnnouncementBarOfferPositionDtoEnum,
  LineWeightTypeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';

export type PromotionWidgetSetupDtoAnnouncementBarPromotionDtoReadWithOffers =
  PromotionWidgetSetupMultiLevelDtoAnnouncementBarPromotionDtoAnnouncementBarFirstLevelPromotionDtoAnnouncementBarFirstLevelPromotionEntryTypeDtoRead & {
    offers?: OfferWidgetSetupDtoAnnouncementBarOfferDtoRead[] | null;
    options?: AnnouncementBarFirstLevelPresetDtoRead | null;
  };

export const announcementBarMergeFunc = (
  widget: PromotionWidgetSetupDtoAnnouncementBarPromotionDtoReadWithOffers,
  setup?: AnnouncementBarPresetDtoRead
) => {
  const {
    isShopLevelWidget,
    canBeDragged,
    canBeRemoved,
    offers,
    options,
    ...newWidget
  } = widget;

  const { options: optionsSetup } = setup?.options ? setup : { options: null };

  return {
    ...newWidget,
    availableEntries: undefined,
    ...(optionsSetup
      ? {
          options: {
            primaryBanner: {
              ...options?.primaryBanner,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              key: undefined,
              general: {
                ...options?.primaryBanner?.general,
                background: {
                  ...optionsSetup.primaryBanner?.general?.background,
                  border: {
                    ...optionsSetup.primaryBanner?.general?.background?.border,
                    value: {
                      ...optionsSetup.primaryBanner?.general?.background?.border
                        ?.value,
                      lineWeight: {
                        ...optionsSetup.primaryBanner?.general?.background
                          ?.border?.value?.lineWeight,
                        customValue:
                          optionsSetup.primaryBanner?.general?.background
                            ?.border?.value?.lineWeight?.type ===
                          LineWeightTypeDtoEnum.CUSTOM
                            ? optionsSetup.primaryBanner?.general?.background
                                ?.border?.value?.lineWeight?.customValue
                            : undefined,
                      },
                    },
                  },
                },
              },
            },
            secondaryBanner: {
              ...options?.secondaryBanner,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              availableEntries: undefined,
              general: {
                ...options?.secondaryBanner?.general,
                background: {
                  ...optionsSetup.secondaryBanner?.general?.background,
                  border: {
                    ...optionsSetup.secondaryBanner?.general?.background
                      ?.border,
                    value: {
                      ...optionsSetup.secondaryBanner?.general?.background
                        ?.border?.value,
                      lineWeight: {
                        ...optionsSetup.secondaryBanner?.general?.background
                          ?.border?.value?.lineWeight,
                        customValue:
                          optionsSetup.secondaryBanner?.general?.background
                            ?.border?.value?.lineWeight?.type ===
                          LineWeightTypeDtoEnum.CUSTOM
                            ? optionsSetup.secondaryBanner?.general?.background
                                ?.border?.value?.lineWeight?.customValue
                            : undefined,
                      },
                    },
                  },
                },
              },
            },
          },
          offers: offers?.map((offer) => {
            const {
              canBeDragged,
              canBeRemoved,
              canBeHidden,
              isHidden,
              setup,
              ...newOffer
            } = offer;

            const currentBanner =
              newOffer.parentKey ===
              AnnouncementBarOfferPositionDtoEnum.ANNOUNCEMENT_BAR_PRIMARY_BANNER
                ? 'primaryBanner'
                : 'secondaryBanner';
            return {
              ...(canBeHidden && { isHidden }),
              ...newOffer,
              setup: {
                ...setup,
                availableEntries: undefined,
                options: {
                  icon: {
                    ...setup?.options?.icon,
                    canBeDragged: undefined,
                    canBeRemoved: undefined,
                    canBeHidden: undefined,
                    icon: {
                      ...setup?.options?.icon?.icon,
                      color:
                        optionsSetup[currentBanner]?.options?.icon?.icon?.color,
                    },
                  },
                  image: {
                    ...setup?.options?.image,
                    canBeDragged: undefined,
                    canBeRemoved: undefined,
                    canBeHidden: undefined,
                    border: {
                      ...optionsSetup[currentBanner]?.options?.image?.border,
                      value: {
                        ...optionsSetup[currentBanner]?.options?.image?.border
                          ?.value,
                        lineWeight: {
                          ...optionsSetup[currentBanner]?.options?.image?.border
                            ?.value?.lineWeight,
                          customValue:
                            optionsSetup[currentBanner]?.options?.image?.border
                              ?.value?.lineWeight?.type ===
                            LineWeightTypeDtoEnum.CUSTOM
                              ? optionsSetup[currentBanner]?.options?.image
                                  ?.border?.value?.lineWeight?.customValue
                              : undefined,
                        },
                      },
                    },
                    cornerRadius: {
                      ...optionsSetup[currentBanner]?.options?.image
                        ?.cornerRadius,
                      customValue:
                        optionsSetup[currentBanner]?.options?.image
                          ?.cornerRadius?.type === LineWeightTypeDtoEnum.CUSTOM
                          ? optionsSetup[currentBanner]?.options?.image
                              ?.cornerRadius?.customValue
                          : undefined,
                    },
                    image: {
                      ...setup?.options?.image?.image,
                      fit: optionsSetup[currentBanner]?.options?.image?.image
                        ?.fit,
                      size: optionsSetup[currentBanner]?.options?.image?.image
                        ?.size,
                    },
                  },
                  message: {
                    ...setup?.options?.message,
                    canBeDragged: undefined,
                    canBeRemoved: undefined,
                    canBeHidden: undefined,
                    style: {
                      ...setup?.options?.message?.style,
                      color:
                        optionsSetup[currentBanner]?.options?.message?.style
                          ?.color,
                      style: {
                        ...setup?.options?.message?.style?.style,
                        fontSize: {
                          ...setup?.options?.message?.style?.style?.fontSize,
                          mobile: setup?.options?.message?.style?.style
                            ?.differentOnMobile
                            ? setup?.options?.message?.style?.style?.fontSize
                                ?.mobile
                            : undefined,
                        },
                        lineHeight: {
                          ...setup?.options?.message?.style?.style?.lineHeight,
                          mobile: setup?.options?.message?.style?.style
                            ?.differentOnMobile
                            ? setup?.options?.message?.style?.style.lineHeight
                                ?.mobile
                            : undefined,
                        },
                        fontWeight: {
                          ...setup?.options?.message?.style?.style?.fontWeight,
                          mobile: setup?.options?.message?.style?.style
                            ?.differentOnMobile
                            ? setup?.options?.message?.style?.style?.fontWeight
                                ?.mobile
                            : undefined,
                        },
                      },
                    },
                  },
                },
              },
            };
          }),
        }
      : {
          options: {
            primaryBanner: {
              ...options?.primaryBanner,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              key: undefined,
              general: {
                ...options?.primaryBanner?.general,
                background: {
                  ...options?.primaryBanner?.general?.background,
                  border: {
                    ...options?.primaryBanner?.general?.background?.border,
                    value: {
                      ...options?.primaryBanner?.general?.background?.border
                        ?.value,
                      lineWeight: {
                        ...options?.primaryBanner?.general?.background?.border
                          ?.value?.lineWeight,
                        customValue:
                          options?.primaryBanner?.general?.background?.border
                            ?.value?.lineWeight?.type ===
                          LineWeightTypeDtoEnum.CUSTOM
                            ? options?.primaryBanner?.general?.background
                                ?.border?.value?.lineWeight?.customValue
                            : undefined,
                      },
                    },
                  },
                },
              },
              options: {
                icon: {
                  ...options?.primaryBanner?.options?.icon,
                  canBeDragged: undefined,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                },
                image: {
                  ...options?.primaryBanner?.options?.image,
                  canBeDragged: undefined,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                  border: {
                    ...options?.primaryBanner?.options?.image?.border,
                    value: {
                      ...options?.primaryBanner?.options?.image?.border?.value,
                      lineWeight: {
                        ...options?.primaryBanner?.options?.image?.border?.value
                          ?.lineWeight,
                        customValue:
                          options?.primaryBanner?.options?.image?.border?.value
                            ?.lineWeight?.type === LineWeightTypeDtoEnum.CUSTOM
                            ? options?.primaryBanner?.options?.image?.border
                                ?.value?.lineWeight.customValue
                            : undefined,
                      },
                    },
                  },
                  cornerRadius: {
                    ...options?.primaryBanner?.options?.image?.cornerRadius,
                    customValue:
                      options?.primaryBanner?.options?.image?.cornerRadius
                        ?.type === LineWeightTypeDtoEnum.CUSTOM
                        ? options?.primaryBanner?.options?.image?.cornerRadius
                            ?.customValue
                        : undefined,
                  },
                },
                message: {
                  ...options?.primaryBanner?.options?.message,
                  canBeDragged: undefined,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                  style: {
                    ...options?.primaryBanner?.options?.message?.style,
                    style: {
                      ...options?.primaryBanner?.options?.message?.style?.style,
                      fontSize: {
                        ...options?.primaryBanner?.options?.message?.style
                          ?.style?.fontSize,
                        mobile: options?.primaryBanner?.options?.message?.style
                          ?.style?.differentOnMobile
                          ? options?.primaryBanner?.options?.message?.style
                              ?.style?.fontSize?.mobile
                          : undefined,
                      },
                      lineHeight: {
                        ...options?.primaryBanner?.options?.message?.style
                          ?.style?.lineHeight,
                        mobile: options?.primaryBanner?.options?.message?.style
                          ?.style?.differentOnMobile
                          ? options?.primaryBanner?.options?.message?.style
                              ?.style?.lineHeight?.mobile
                          : undefined,
                      },
                      fontWeight: {
                        ...options?.primaryBanner?.options?.message?.style
                          ?.style?.fontWeight,
                        mobile: options?.primaryBanner?.options?.message?.style
                          ?.style?.differentOnMobile
                          ? options?.primaryBanner?.options?.message?.style
                              ?.style?.fontWeight?.mobile
                          : undefined,
                      },
                    },
                  },
                },
              },
            },
            secondaryBanner: {
              ...options?.secondaryBanner,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              key: undefined,
              general: {
                ...options?.secondaryBanner?.general,
                background: {
                  ...options?.secondaryBanner?.general?.background,
                  border: {
                    ...options?.secondaryBanner?.general?.background?.border,
                    value: {
                      ...options?.secondaryBanner?.general?.background?.border
                        ?.value,
                      lineWeight: {
                        ...options?.secondaryBanner?.general?.background?.border
                          ?.value?.lineWeight,
                        customValue:
                          options?.secondaryBanner?.general?.background?.border
                            ?.value?.lineWeight?.type ===
                          LineWeightTypeDtoEnum.CUSTOM
                            ? options?.secondaryBanner?.general?.background
                                ?.border?.value?.lineWeight?.customValue
                            : undefined,
                      },
                    },
                  },
                },
              },
              options: {
                icon: {
                  ...options?.secondaryBanner?.options?.icon,
                  canBeDragged: undefined,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                },
                image: {
                  ...options?.secondaryBanner?.options?.image,
                  canBeDragged: undefined,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                  border: {
                    ...options?.secondaryBanner?.options?.image?.border,
                    value: {
                      ...options?.secondaryBanner?.options?.image?.border
                        ?.value,
                      lineWeight: {
                        ...options?.secondaryBanner?.options?.image?.border
                          ?.value?.lineWeight,
                        customValue:
                          options?.secondaryBanner?.options?.image?.border
                            ?.value?.lineWeight?.type ===
                          LineWeightTypeDtoEnum.CUSTOM
                            ? options?.secondaryBanner?.options?.image?.border
                                ?.value?.lineWeight.customValue
                            : undefined,
                      },
                    },
                  },
                  cornerRadius: {
                    ...options?.secondaryBanner?.options?.image?.cornerRadius,
                    customValue:
                      options?.secondaryBanner?.options?.image?.cornerRadius
                        ?.type === LineWeightTypeDtoEnum.CUSTOM
                        ? options?.secondaryBanner?.options?.image?.cornerRadius
                            ?.customValue
                        : undefined,
                  },
                },
                message: {
                  ...options?.secondaryBanner?.options?.message,
                  canBeDragged: undefined,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                  style: {
                    ...options?.secondaryBanner?.options?.message?.style,
                    style: {
                      ...options?.secondaryBanner?.options?.message?.style
                        ?.style,
                      fontSize: {
                        ...options?.secondaryBanner?.options?.message?.style
                          ?.style?.fontSize,
                        mobile: options?.secondaryBanner?.options?.message
                          ?.style?.style?.differentOnMobile
                          ? options?.secondaryBanner?.options?.message?.style
                              ?.style?.fontSize?.mobile
                          : undefined,
                      },
                      lineHeight: {
                        ...options?.secondaryBanner?.options?.message?.style
                          ?.style?.lineHeight,
                        mobile: options?.secondaryBanner?.options?.message
                          ?.style?.style?.differentOnMobile
                          ? options?.secondaryBanner?.options?.message?.style
                              ?.style?.lineHeight?.mobile
                          : undefined,
                      },
                      fontWeight: {
                        ...options?.secondaryBanner?.options?.message?.style
                          ?.style?.fontWeight,
                        mobile: options?.secondaryBanner?.options?.message
                          ?.style?.style?.differentOnMobile
                          ? options?.secondaryBanner?.options?.message?.style
                              ?.style?.fontWeight?.mobile
                          : undefined,
                      },
                    },
                  },
                },
              },
            },
          },
        }),
  };
};
