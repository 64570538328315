import React, { useMemo } from 'react';
import {
  Banner,
  BlockStack,
  Card,
  Checkbox,
  Link,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { OptionDtoNullableAnnouncementBarIndicatorsStyleDto } from 'core/api/adminSettings/adminSettingsApi';
import { AnnouncementBarIndicatorsStyleDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import { Element } from 'react-scroll';

type IndicatorsSettingProps = {
  indicators: OptionDtoNullableAnnouncementBarIndicatorsStyleDto;
  setIndicators: (
    value: OptionDtoNullableAnnouncementBarIndicatorsStyleDto
  ) => void;
};
export const IndicatorsSetting: React.FC<IndicatorsSettingProps> = ({
  indicators,
  setIndicators,
}) => {
  const [i18n] = useI18n();
  const indicatorsOptions = useMemo(
    () =>
      Object.values(AnnouncementBarIndicatorsStyleDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    []
  );

  return (
    <Element className='IndicatorsSetting' name='IndicatorsSetting'>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('Indicators')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('Subtitle')}
            </Text>
          </BlockStack>
          <Checkbox
            label={i18n.translate('EnableIndicators')}
            checked={indicators.enabled}
            onChange={(value) =>
              setIndicators({
                ...indicators,
                enabled: value,
              })
            }
          />
          <SelectOptions
            options={indicatorsOptions}
            prefix={i18n.translate('Style')}
            onOptionSelect={(value) => {
              setIndicators({
                ...indicators,
                value: value as AnnouncementBarIndicatorsStyleDtoEnum,
              });
            }}
            selectedOption={
              indicators.value || AnnouncementBarIndicatorsStyleDtoEnum.BARS
            }
            label=''
            disabled={!indicators.enabled}
          />
          <Banner tone='info'>
            {i18n.translate('BannerText')}
            <Link>{i18n.translate('PresetEditor')}</Link>.
          </Banner>
        </BlockStack>
      </Card>
    </Element>
  );
};
