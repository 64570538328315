import React, { useEffect, useMemo, useState } from 'react';
import { useI18n } from '@shopify/react-i18n';
import { useAppSelector } from 'core/hooks';
import { getCurrentMessage } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import { DeviceTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { usePrevious } from 'core/hooks/usePrevious';
import {
  WidgetsFetchTypeEnum,
  useConfigureWidgets,
} from 'features/settings/hooks/useConfigureWidgets';
import { isEqual } from 'lodash';
import { PromotionWidgetSetupDtoOfferRulePopupPromotionDtoReadWithOffers } from '../../../MockupPreviewTemplate/utils/offerRulePopUpMergeFunc';

type OfferRulePopUpProps = {
  data: PromotionWidgetSetupDtoOfferRulePopupPromotionDtoReadWithOffers;
  previewType: DeviceTypeDtoEnum;
  defaultLanguage?: string;
  adminActivePath: string;
  getSelectionFromThePreview(path: string): void;
};

export const OfferRulePopUp: React.FC<OfferRulePopUpProps> = ({
  previewType,
  defaultLanguage,
  data,
  adminActivePath,
  getSelectionFromThePreview,
}) => {
  const [i18n] = useI18n();

  const { fontListData } = useConfigureWidgets(
    WidgetsFetchTypeEnum.PRESET_FONT_LIST
  );

  const {
    defaultIconLibary,
    defaultImageLibary,
    customIconLibary,
    customImageLibary,
  } = useAppSelector((state) => state.widgets);

  const { smartTagsCodes } = useAppSelector((state) => state.offersWizard);

  const [injectStyles, setInjectStyles] = useState<boolean>(false);

  const laDnOfferRulePopup = document.querySelector('la-dn-offer-rules-popup');

  const currentIndex: number = useMemo(() => {
    const match = adminActivePath.match(/banner_(\d+)/);
    if (match) {
      return +match[1];
    } else {
      return 0;
    }
  }, [adminActivePath]);

  const isOpen = useMemo(() => data.isOpen, [data.isOpen]);

  const imagesArray = useMemo(
    () => [...defaultImageLibary, ...customImageLibary],
    [customImageLibary, defaultImageLibary]
  );
  const iconsArray = useMemo(
    () => [...defaultIconLibary, ...customIconLibary],
    [customIconLibary, defaultIconLibary]
  );

  const assetsArray = useMemo(
    () =>
      [...iconsArray, ...imagesArray].map((item) => ({
        id: item.id,
        url: item.url,
      })),
    [iconsArray, imagesArray]
  );

  const elementToReplaceWith = useMemo(
    () =>
      `
     <la-dn-offer-rules-popup
      admin-mode="true"
      admin-active-path="${adminActivePath}"
      admin-mode-label='[{"component":"offerRulePopup","label":"${i18n.translate(
        'offerRulesPopup'
      )}","icon":"widget","subComponents":[{"component":"banner","label":"${i18n.translate(
        'offerRulesPopup'
      )}","icon":"offer","subComponents":[{"component":"headlineText","label":"${i18n.translate(
        'headlineText'
      )}","icon":"component"},{"component":"ruleText","label":"${i18n.translate(
        'ruleText'
      )}","icon":"component"},{"component":"callToActionButton","label":"${i18n.translate(
        'ctaButton'
      )}","icon":"component"}]},{"component":"headlineText","label":"${i18n.translate(
        'headlineText'
      )}","icon":"component"},{"component":"ruleText","label":"${i18n.translate(
        'ruleText'
      )}","icon":"component"},{"component":"callToActionButton","label":"${i18n.translate(
        'ctaButton'
      )}","icon":"component"}]}]'
      token="ABCDE"
      index="${currentIndex}"
      size="medium"
      position="center"
      overlay="true"
      click-on-overlay-closes="false"
      animation-on-open="fade"
      animation-on-close="fade"
    ></la-dn-offer-rules-popup>
    `,
    [adminActivePath, i18n, currentIndex]
  );

  const currentMessage = useMemo(
    () =>
      data?.offers
        ? {
            token: 'ABCDE',
            index: currentIndex,
            title: getCurrentMessage(
              data?.offers[currentIndex]?.setup?.options?.headlineText
                ?.headlineText?.message?.entries,
              defaultLanguage,
              smartTagsCodes
            ),
            message: getCurrentMessage(
              data?.offers[currentIndex].setup?.options?.ruleText?.ruleText
                ?.message?.entries,
              defaultLanguage,
              smartTagsCodes
            ),
            cta: {
              label: getCurrentMessage(
                data?.offers[currentIndex].setup?.options?.callToActionButton
                  ?.label?.message?.entries,
                defaultLanguage,
                smartTagsCodes
              ),
              link: data?.offers[currentIndex].setup?.options
                ?.callToActionButton?.link?.link,
              newWindow:
                data?.offers[currentIndex].setup?.options?.callToActionButton
                  ?.link?.openInNewTab,
            },
          }
        : {
            token: 'ABCDE',
            index: currentIndex,
            title: i18n.translate('titleExample'),
            message: i18n.translate('ruleTextExample'),
            cta: {
              label: getCurrentMessage(
                data.options?.callToActionButton?.label?.message?.entries,
                defaultLanguage,
                smartTagsCodes
              ),
              link: 'https://polaris.shopify.com',
              newWindow: false,
            },
          },
    [
      currentIndex,
      smartTagsCodes,
      defaultLanguage,
      data?.offers,
      i18n,
      data.options,
    ]
  );

  const prevMessage = usePrevious(currentMessage);

  useEffect(() => {
    const offerRulePopupRoot = document.querySelector('#offer-rule-popup-root');

    if (offerRulePopupRoot) {
      offerRulePopupRoot.innerHTML = elementToReplaceWith;
    }

    return () => {
      document.dispatchEvent(new CustomEvent('la:dn:offer-rules-popup:close'));
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.dispatchEvent(
        new CustomEvent('la:dn:offer-rules-popup:open', {
          detail: currentMessage,
        })
      );
      document
        .querySelector('la-dn-offer-rules-popup')
        ?.addEventListener('la:dn:offer-rules-popup:admin-change', (e) =>
          getSelectionFromThePreview((e as CustomEvent).detail)
        );
      setInjectStyles(true);
    } else {
      document.dispatchEvent(new CustomEvent('la:dn:offer-rules-popup:close'));
    }
  }, [isOpen]);

  useEffect(() => {
    if (
      currentMessage &&
      injectStyles &&
      !isEqual(currentMessage, prevMessage)
    ) {
      document.dispatchEvent(
        new CustomEvent('la:dn:offer-rules-popup:update', {
          detail: currentMessage,
        })
      );
    }
  }, [currentMessage]);

  useEffect(() => {
    if (injectStyles) {
      laDnOfferRulePopup?.setAttribute(
        'image-assets',
        JSON.stringify(assetsArray)
      );
    }
  }, [assetsArray, injectStyles]);

  useEffect(() => {
    if (injectStyles) {
      laDnOfferRulePopup?.setAttribute(
        'font-assets',
        JSON.stringify(fontListData)
      );
    }
  }, [fontListData, injectStyles]);

  useEffect(() => {
    if (injectStyles) {
      laDnOfferRulePopup?.setAttribute('styles', JSON.stringify(data));
    }
  }, [injectStyles, data]);

  useEffect(() => {
    if (injectStyles) {
      laDnOfferRulePopup?.setAttribute(
        'force-mobile',
        (previewType === DeviceTypeDtoEnum.MOBILE).toString()
      );
    }
  }, [injectStyles, previewType]);

  useEffect(() => {
    if (injectStyles) {
      laDnOfferRulePopup?.setAttribute('admin-active-path', adminActivePath);
    }
  }, [adminActivePath]);

  return <div id='offer-rule-popup-root'></div>;
};
