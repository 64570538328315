import React, { useState } from 'react';
import {
  Banner,
  BlockStack,
  Card,
  Checkbox,
  Icon,
  Popover,
  Text,
  TextField,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { OptionDtoNullableGuid } from 'core/api/adminSettings/adminSettingsApi';
import { useAppSelector } from 'core/hooks';
import { IconResourceDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { SelectIcon } from '@shopify/polaris-icons';
import ImageIconLibrary from 'core/components/WidgetEditor/ImageIconLibrary/ImageIconLibrary';
import { useConfigureSettings } from 'features/settings/hooks/useConfigureSettings';
import './IconSetting.scss';
import { ResourceGroupKeyDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { Element } from 'react-scroll';

type IconSettingProps = {
  icon: OptionDtoNullableGuid;
  title: string;
  subtitle: string;
  enableText: string;
  bannerContent: React.ReactNode;
  groupKey: ResourceGroupKeyDtoEnum;
  setIcon: (value: OptionDtoNullableGuid) => void;
};
export const IconSetting: React.FC<IconSettingProps> = ({
  icon,
  enableText,
  title,
  subtitle,
  bannerContent,
  groupKey,
  setIcon,
}) => {
  const [i18n] = useI18n();
  const [iconLibraryOpen, setIconLibraryOpen] = useState<boolean>(false);
  const { defaultIconLibary, customIconLibary } = useAppSelector(
    (store) => store.widgets
  );
  const { refetchCustomIconLibary, isCustomIconLibaryFetching } =
    useConfigureSettings();

  const filteredDefaultIcons = defaultIconLibary.filter(
    (image) => image.groupKey === groupKey
  );

  const selectedIcon: IconResourceDto =
    defaultIconLibary
      ?.concat(customIconLibary || [])
      .find((file) => file.id === icon.value) || {};

  return (
    <Element className='IconSetting' name={title}>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {title}
            </Text>
            <Text as='p' tone='subdued'>
              {subtitle}
            </Text>
          </BlockStack>
          <Checkbox
            label={enableText}
            checked={icon.enabled}
            onChange={(value) =>
              setIcon({
                ...icon,
                enabled: value,
              })
            }
          />
          {icon.enabled && (
            <>
              <Popover
                active={iconLibraryOpen}
                fullHeight
                activator={
                  <div onClick={() => setIconLibraryOpen(true)}>
                    <TextField
                      suffix={<Icon source={SelectIcon} />}
                      prefix={
                        selectedIcon.url ? (
                          <img className='PrefixImage' src={selectedIcon.url} />
                        ) : null
                      }
                      label=''
                      autoComplete=''
                      placeholder={i18n.translate('IconPlaceholder')}
                      value={selectedIcon?.name || ''}
                    />
                  </div>
                }
                autofocusTarget='first-node'
                onClose={() => setIconLibraryOpen(false)}
              >
                <ImageIconLibrary
                  selectedFile={icon.value || ''}
                  defaultFilesLibrary={filteredDefaultIcons || []}
                  customFilesLibrary={customIconLibary || []}
                  isCustomFileLibraryFetching={isCustomIconLibaryFetching}
                  fileFormat={'icon'}
                  setSelectedFile={(value) =>
                    setIcon({ ...icon, value: value })
                  }
                  groupKey={groupKey}
                  refetchCustomLibrary={refetchCustomIconLibary}
                  onClosePopover={() => setIconLibraryOpen(false)}
                />
              </Popover>
              <Banner tone='info'>{bannerContent}</Banner>
            </>
          )}
        </BlockStack>
      </Card>
    </Element>
  );
};
