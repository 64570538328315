import { LegacyStack } from '@shopify/polaris';
import React from 'react';
import styles from './EmptyDataTable.module.scss';

type EmptyDataTableProps = {
  message?: string;
};

export const EmptyDataTable: React.FC<EmptyDataTableProps> = ({ message }) => {
  return (
    <LegacyStack distribution='center'>
      <p className={styles.EmptyDataTableText}>
        {message ?? 'No matching records found'}
      </p>
    </LegacyStack>
  );
};
