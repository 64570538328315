import { isBefore, isEqual, isValid } from 'date-fns';
import { formatDateFromString } from './formatUtils';

export const validDateString = (value: string) =>
  value.length === 10 && isValid(formatDateFromString(value));

export const isBeforeOrEqual = (date: Date, dateToCompare: Date) =>
  isEqual(date, dateToCompare) || isBefore(date, dateToCompare);

export const validMaxDate = (date: Date, maxDate?: Date) =>
  !maxDate || isBeforeOrEqual(date, maxDate);
