import React, { useMemo } from 'react';
import { SelectedOptionPath } from '../../../../RightSideBarWrapper';
import {
  CartTextPresetDtoRead,
  CartTextSystemDefaultsDto,
} from 'core/api/adminWidgets/adminWidgetsApi';

import { getLastSelectedOption } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import { WidgetListTypeDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import PresetCartTextGeneral from './components/PresetCartTextGeneral/PresetCartTextGeneral';

type PresetCartTextProps = {
  cartText: CartTextPresetDtoRead;
  selectedOptionPath: SelectedOptionPath;
  defaultPresetData: CartTextPresetDtoRead;
  shopDefaultData: CartTextSystemDefaultsDto;
  updateCartText: (data: CartTextPresetDtoRead) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const PresetCartText: React.FC<PresetCartTextProps> = ({
  cartText,
  selectedOptionPath,
  defaultPresetData,
  shopDefaultData,
  updateCartText,
  handleLeftMenuSelection,
}) => {
  const cartTextOptions = useMemo(() => {
    const selectedOption = getLastSelectedOption(selectedOptionPath);
    const pageType =
      selectedOption?.name === WidgetListTypeDtoEnum.DRAWER_CART_PAGE_CART_TEXT
        ? WidgetListTypeDtoEnum.DRAWER_CART_PAGE_CART_TEXT
        : WidgetListTypeDtoEnum.CART_PAGE_CART_TEXT;

    switch (true) {
      case selectedOption?.name === pageType:
        return (
          <PresetCartTextGeneral
            general={cartText.general || {}}
            shopDefaultGeneral={shopDefaultData || {}}
            defaultGeneral={defaultPresetData.general || {}}
            handleLeftMenuSelection={handleLeftMenuSelection}
            pageType={pageType}
            setGeneral={(data) =>
              updateCartText({
                ...cartText,
                general: data,
              })
            }
            onShowHide={
              cartText.canBeHidden
                ? () =>
                    updateCartText({
                      ...cartText,
                      isHidden: !cartText.isHidden,
                    })
                : undefined
            }
          />
        );
    }
  }, [
    selectedOptionPath,
    shopDefaultData,
    cartText,
    defaultPresetData,
    updateCartText,
    handleLeftMenuSelection,
  ]);
  return <div className='PresetCartText'>{cartTextOptions}</div>;
};

export default PresetCartText;
