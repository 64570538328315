import React, { useCallback } from 'react';
import {
  IconComponentDto,
  ProductBannerIconOfferDto,
  ProductBannerIconPresetDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { Icon } from '../../../../../components/Icon/Icon';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { ResourceGroupKeyDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { isEqual } from 'lodash';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';

type ProductBannerIconProps = {
  icon: ProductBannerIconOfferDto;
  shopDefaultIcon: IconComponentDto;
  defaultIcon: ProductBannerIconOfferDto;
  isPresetEditor?: boolean;
  presetSetup?: ProductBannerIconPresetDto;
  setIcon: (data: ProductBannerIconOfferDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const ProductBannerIcon: React.FC<ProductBannerIconProps> = ({
  icon,
  defaultIcon,
  shopDefaultIcon,
  isPresetEditor,
  presetSetup,
  setIcon,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const resetAllToSystemDefault = useCallback(() => {
    const { color, iconId } = shopDefaultIcon;
    setIcon({ ...icon, color, ...(!isPresetEditor ? { iconId } : {}) });
  }, [isPresetEditor, shopDefaultIcon, icon]);

  return (
    <div className='ProductBannerIcon'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={() => setIcon(defaultIcon)}
        onResetToSystem={resetAllToSystemDefault}
        name={WidgetListOptionsDtoEnum.ICON}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <Icon
          onSystemReset={resetAllToSystemDefault}
          icon={!isPresetEditor ? { ...icon, color: presetSetup?.color } : icon}
          setIcon={(value) => setIcon(value)}
          groupKey={ResourceGroupKeyDtoEnum.OFFER}
          offersNote={isPresetEditor}
          showIcon={!isPresetEditor}
          inherited={
            !isPresetEditor
              ? isEqual(presetSetup?.color, defaultIcon.color)
              : undefined
          }
        />
      </div>
    </div>
  );
};

export default ProductBannerIcon;
