import { ChoiceList } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  DiscountLabelGeneralOfferDto,
  DiscountLabelTypeDto,
  MessageSetupDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import StyleComponentHeader from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components/StyleComponentHeader/StyleComponentHeader';
import React, { useCallback, useEffect, useState } from 'react';

type DiscountLabelProps = {
  discountLabel: DiscountLabelGeneralOfferDto;
  setDiscountLabel: (data: DiscountLabelGeneralOfferDto) => void;
  onSystemReset: () => void;
};
const defaultEntries: MessageSetupDto = {
  settings: {
    goalStates: 'UNIFIED',
    device: 'UNIFIED',
    collapsed: null,
  },
  entries: [
    {
      goal: null,
      device: null,
      collapsed: null,
      text: [
        {
          text: 'My offer text in English language',
          language: 'en',
        },
        {
          text: 'My offer text in Polish language',
          language: 'pl',
        },
      ],
    },
  ],
};

const DiscountLabel: React.FC<DiscountLabelProps> = ({
  discountLabel,
  setDiscountLabel,
  onSystemReset,
}) => {
  const [i18n] = useI18n();
  const [defaultCustom, setDefaultCustom] =
    useState<MessageSetupDto>(defaultEntries);
  const labelOptions = [
    ...(discountLabel.values || []),
    { type: 'custom', value: i18n.translate('CustomText') },
  ]?.map((option) => ({
    label: option.value || i18n.translate('Empty'),
    value: option.type || '',
  }));

  const updateDefaultLabel = useCallback(
    (value: string) => {
      const labelName = discountLabel.values?.find(
        (label) => label.type === value
      )?.value;
      setDefaultCustom((prev) => ({
        ...prev,
        entries: prev.entries?.map((entry) => ({
          ...entry,
          text: entry.text?.map((text) => ({ ...text, text: labelName })),
        })),
      }));
    },
    [discountLabel, setDefaultCustom]
  );

  const handleChangeLabel = useCallback(
    (value: string) => {
      if (value === 'custom') {
        setDiscountLabel({
          ...discountLabel,
          customLabel: defaultCustom,
          labelType: null,
        });
      } else {
        setDiscountLabel({
          ...discountLabel,
          customLabel: null,
          labelType: value as DiscountLabelTypeDto,
        });
        updateDefaultLabel(value);
      }
    },
    [discountLabel, setDiscountLabel]
  );

  useEffect(() => {
    if (discountLabel.labelType) {
      updateDefaultLabel(discountLabel.labelType);
    }
  }, []);

  return (
    <div>
      <StyleComponentHeader
        label={i18n.translate(`Label`)}
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            onAction: () => {
              onSystemReset();
            },
          },
        ]}
      >
        <ChoiceList
          title=''
          choices={labelOptions}
          selected={[
            discountLabel.customLabel
              ? 'custom'
              : (discountLabel.labelType as string),
          ]}
          onChange={(value) => handleChangeLabel(value[0])}
        />
      </StyleComponentHeader>
    </div>
  );
};

export default DiscountLabel;
