import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './EditPresetSkeleton.scss';
import {
  MobileIcon,
  DesktopIcon,
  PlusIcon,
  MinusIcon,
} from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import { Badge, Button, ButtonGroup, Icon, Text } from '@shopify/polaris';
import { Params, useParams, useSearchParams } from 'react-router-dom';
import { LeftSidebarWrapper } from './components/LeftSidebarWrapper/LeftSidebarWrapper';
import { Preview } from './components/PreviewBlock/Preview';
import { RightSideBarWrapper } from './components/RightSideBarWrapper/RightSideBarWrapper';
import { useScreenResolution } from 'core/hooks/useScreenResolution';
import {
  WidgetsFetchTypeEnum,
  useConfigureWidgets,
} from 'features/settings/hooks/useConfigureWidgets';
import {
  CloseEditorTransactionRequestDto,
  GetSystemDefaultsResponseDto,
  OfferWidgetSetupDtoAnnouncementBarOfferDtoRead,
  PageSetupEntryDto,
  PageTypeDto,
  PresetDto,
  PresetsSetupDto,
  PromotionSetupDto,
  SavePresetRequestDto,
  WidgetTypeDto,
  useSystemDefaultsQuery,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { cloneDeep, debounce, isEmpty, isEqual, isEqualWith } from 'lodash';
import {
  DeviceTypeDtoEnum,
  PageTypeDtoEnum,
  PresetBoundariesTypeDtoEnum,
  TransactionSaveModeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import { Loader } from 'core/components';
import { PREVIEW_ZOOM_MAX, STEP } from '../DevicePreview/const';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import PresetPublishModal from './components/PresetPublishModal/PresetPublishModal';
import { v4 as uuidv4 } from 'uuid';
import {
  LeftMenuOffers,
  LeftMenuOption,
  LeftMenuWidget,
  addOptionsToInitialArray,
  formatOffers,
  generateLeftMenu,
  generateNewRightMenu,
  getAdminPath,
  getGlobalCurrentEntries,
  isLeftMenuOptionSelected,
  mergeOffers,
  reverseMergedData,
  transformWidgetsList,
  updateLeftMenu,
  updateLeftMenuSelection,
  updateSelectionByPath,
} from './utils/leftMenuGenerators';
import { useAppDispatch, useAppSelector, useIsDebugOrLocal } from 'core/hooks';
import useFetchPresetEditor from './utils/useFetchPresetEditor';
import {
  setPromotionTransactionId,
  setActiveDeviceType,
  setIsChangePresetLoading,
  setIsPromotionEditorDataLoading,
  ToggleSettingsModalConfigDto,
  setToggleSettingsConfig,
  setCurrentParams,
  setSelectedPage,
} from 'core/store/widgetsSlice';
import {
  findPresetChanges,
  findSelectedOptionPath,
  getCurrentSmartTagsList,
  getFormattedData,
  getSelectedOffer,
} from './utils/utils';
import SaveGlobalPresetModal from './components/SaveGlobalPresetModal/SaveGlobalPresetModal';
import { PromotionPresetsChangesDto } from './components/LeftSidebarWrapper/components/PresetChangesBadge/PresetChangesBadge';
import SaveWidgetEditorModal from './components/SaveWidgetEditorModal/SaveWidgetEditorModal';
import { useConfigureOffers } from 'features/promotions/hooks/useConfigureOffers';
import { EditPresetTypeEnum, WidgetListTypeDtoEnum } from './enums/enums';
import { setCurrentAnchor } from 'core/store/settingsSlice';
import { setSmartTagsCodes } from 'core/store/offersWizardSlice';
import { EditPresetSkeletonProps } from 'pages/WidgetEditor';
import useFetchPresetList from 'features/settings/hooks/useFetchPresetList/useFetchPresetList';
import resolveEnvVar from 'env-var-resolver';

const timer = 5000;

export type PresetSkeletonDto = {
  editPresetData?: PresetDto;
  promotionPresetData?: PromotionSetupDto;
  shopDefaultData?: GetSystemDefaultsResponseDto;
};

export const EditPresetSkeleton: React.FC<EditPresetSkeletonProps> = ({
  skeletonType,
}) => {
  const [i18n] = useI18n();
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || '';
  const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME');
  const params = useParams();
  const { width } = useScreenResolution();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const isDebugOrLocal = useIsDebugOrLocal();
  const { fetchBrandColors } = useFetchPresetList();
  const {
    activeDeviceType,
    toggleSettingsConfig,
    currentParams,
    isPromotionEditorDataLoading,
    selectedPage,
  } = useAppSelector((store) => store.widgets);
  const { currentAnchor } = useAppSelector((state) => state.settings);
  const { smartTagsList } = useAppSelector((state) => state.offersWizard);

  const [maxModalParams, setMaxModalParams] = useState<Params<string> | null>(
    null
  );

  const path = searchParams.get('path');
  const subPath = searchParams.get('subPath');
  const tab = searchParams.get('tab');
  const language = searchParams.get('language');

  const {
    saveEditPresetChangesIsLoading,
    saveEditPromotionChangesIsLoading,
    closeEditorTransactionIsLoading,
    affectedPromotions,
    affectedPromotionsIsFetching,
    saveEditPresetChanges,
    saveEditPromotionChanges,
    closeEditorTransaction,
  } = useConfigureWidgets(WidgetsFetchTypeEnum.EDIT_PRESET_SKELETON);

  const { fetchSmartTags } = useConfigureOffers();

  //Transaction id to close the transaction (save promotion)
  const [transactionId] = useState<string>(uuidv4());

  //Global state to manage all the actions
  const [globalPresetState, setGlobalPresetState] = useState<PresetSkeletonDto>(
    {}
  );
  //Default data for discard actions and save comprasion payload.
  const [defaultPresetState, setDefaultPresetState] =
    useState<PresetSkeletonDto>({});

  //Initial data which updates ONLY after 'save' pressed.
  const [initialPresetState, setInitialPresetState] =
    useState<PresetSkeletonDto>({});

  //Promotion changes that are relevant before 'autosave'
  const [promotionChanges, setPromotionChanges] = useState<PromotionSetupDto>(
    {}
  );

  //Promotion changes that are saved AFTER autosave
  const [savedPromotionChanges, setSavedPromotionChanges] =
    useState<PromotionSetupDto>({});

  const [isPercentageLoading, setIsPercentageLoading] =
    useState<boolean>(false);

  //Flag to stop infinitive data-transferring between left and right panels
  const [flag, setFlag] = useState<boolean | null>(true);

  //Auto save trigger (Only for promotion editor)
  const [isTriggerSaving, setIsTriggerSaving] = useState<boolean>(false);

  //Only for promotion editor
  const [supportedWidgets, setSupportedWidgets] = useState<WidgetTypeDto[]>([]);

  const [leftWidgetsMenu, setLeftWidgetsMenu] = useState<LeftMenuWidget[]>([]);

  const [initialZoom, setInitialZoom] = useState({
    MOBILE_INITIAL_SCALE: 0,
    DESKTOP_INITIAL_SCALE: 0,
  });

  const [zoomLevel, setZoomLevel] = useState<number>(0);

  const [publishModalOpen, setPublishModalOpen] = useState<boolean>(false);

  const [saveGlobalPresetModal, setSaveGlobalPresetModal] =
    useState<boolean>(false);

  const [saveWidgetEditorModalOpen, setSaveWidgetEditorModalOpen] =
    useState<boolean>(false);

  const {
    fetchPromotionPreset,
    fetchEditPreset,
    promotionPresetData,
    editPresetData,
    isPresetEditorDataLoading,
  } = useFetchPresetEditor();

  //Data for "Reset to system default" action
  const { data: shopDefaultData } = useSystemDefaultsQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      widgets: supportedWidgets.length ? supportedWidgets : undefined,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !supportedWidgets.length,
    }
  );

  //Independent value to identicate if there were any changes in editor
  const anyChangesInEditor = useMemo(
    () =>
      skeletonType === EditPresetTypeEnum.PRESET_EDITOR
        ? !!findPresetChanges(
            globalPresetState.editPresetData,
            initialPresetState.editPresetData,
            false,
            true
          )
        : !!findPresetChanges(
            globalPresetState.promotionPresetData,
            initialPresetState.promotionPresetData,
            false
          ),
    [
      globalPresetState.editPresetData,
      globalPresetState.promotionPresetData,
      initialPresetState.editPresetData,
      initialPresetState.promotionPresetData,
      skeletonType,
    ]
  );

  const pageOptions = useMemo(
    () => [
      {
        value: PageTypeDtoEnum.HOME,
        label: i18n.translate(PageTypeDtoEnum.HOME),
      },
      {
        value: PageTypeDtoEnum.COLLECTION,
        label: i18n.translate(PageTypeDtoEnum.COLLECTION),
      },
      {
        value: PageTypeDtoEnum.PRODUCT,
        label: i18n.translate(PageTypeDtoEnum.PRODUCT),
      },
      {
        value: PageTypeDtoEnum.DRAWER_CART,
        label: i18n.translate(PageTypeDtoEnum.DRAWER_CART),
      },
      {
        value: PageTypeDtoEnum.CART,
        label: i18n.translate(PageTypeDtoEnum.CART),
      },
      {
        value: PageTypeDtoEnum.CHECKOUT,
        label: i18n.translate(PageTypeDtoEnum.CHECKOUT),
      },
    ],
    [i18n]
  );

  const allPagesWidgetsList = [
    WidgetListTypeDtoEnum.ANNOUNCEMENT_BAR,
    WidgetListTypeDtoEnum.NOTIFICATION,
    WidgetListTypeDtoEnum.OFFER_RULE_POPUP,
    WidgetListTypeDtoEnum.COUNTDOWN_CLOCK,
  ];

  const pagesSupportLists = [
    {
      name: PageTypeDtoEnum.HOME,
      supports: [...allPagesWidgetsList],
    },
    {
      name: PageTypeDtoEnum.COLLECTION,
      supports: [
        ...allPagesWidgetsList,
        WidgetListTypeDtoEnum.PROMOTIONAL_BADGE,
        WidgetListTypeDtoEnum.COLLECTION_PAGE_PRICE,
      ],
    },
    {
      name: PageTypeDtoEnum.PRODUCT,
      supports: [
        ...allPagesWidgetsList,
        WidgetListTypeDtoEnum.PRODUCT_BANNER,
        WidgetListTypeDtoEnum.PRODUCT_PAGE_PRICE,
      ],
    },
    {
      name: PageTypeDtoEnum.CART,
      supports: [
        ...allPagesWidgetsList,
        WidgetListTypeDtoEnum.CART_PROMOTION_SUMMARY,
        WidgetListTypeDtoEnum.CART_PROMOTION_CODE_FIELD,
        WidgetListTypeDtoEnum.CART_PAGE_CART_TEXT,
      ],
    },
    {
      name: PageTypeDtoEnum.DRAWER_CART,
      supports: [
        ...allPagesWidgetsList,
        WidgetListTypeDtoEnum.DRAWER_CART_PROMOTION_SUMMARY,
        WidgetListTypeDtoEnum.DRAWER_CART_PROMOTION_CODE_FIELD,
        WidgetListTypeDtoEnum.DRAWER_CART_PAGE_CART_TEXT,
        WidgetListTypeDtoEnum.NINJA_CART,
      ],
    },
    {
      name: PageTypeDtoEnum.CHECKOUT,
      supports: [WidgetListTypeDtoEnum.DISCOUNT_LABEL],
    },
  ];

  const currentInitialZoom = useMemo(() => {
    switch (activeDeviceType) {
      case DeviceTypeDtoEnum.DESKTOP:
        return initialZoom.DESKTOP_INITIAL_SCALE;
      default:
        return initialZoom.MOBILE_INITIAL_SCALE;
    }
  }, [activeDeviceType, initialZoom]);

  const currentPercentage = useMemo(
    () =>
      !zoomLevel || !currentInitialZoom || zoomLevel <= currentInitialZoom
        ? 100
        : Math.min(
            300,
            Math.max(100, Math.round((zoomLevel / currentInitialZoom) * 100))
          ),
    [zoomLevel, currentInitialZoom]
  );

  const dataToShowOnPreview = useMemo(
    () =>
      skeletonType === EditPresetTypeEnum.PRESET_EDITOR
        ? 'editPresetData'
        : 'promotionPresetData',
    [skeletonType]
  );

  const deviceBtns = useMemo(
    () => [
      {
        id: DeviceTypeDtoEnum.MOBILE,
        value: DeviceTypeDtoEnum.MOBILE,
        icon: <Icon source={MobileIcon} tone='base' />,
      },
      {
        id: DeviceTypeDtoEnum.DESKTOP,
        value: DeviceTypeDtoEnum.DESKTOP,
        icon: <Icon source={DesktopIcon} tone='base' />,
      },
    ],
    []
  );

  const saveDisabled = useMemo(
    () =>
      !!(
        saveEditPresetChangesIsLoading ||
        closeEditorTransactionIsLoading ||
        saveEditPromotionChangesIsLoading ||
        isPresetEditorDataLoading ||
        isPromotionEditorDataLoading
      ),
    [
      saveEditPresetChangesIsLoading,
      saveEditPromotionChangesIsLoading,
      closeEditorTransactionIsLoading,
      isPresetEditorDataLoading,
      isPromotionEditorDataLoading,
    ]
  );

  const transformLeftbar = useMemo(() => width < 1440, [width]);

  const currentWidget = useMemo(() => {
    return leftWidgetsMenu?.find((widget) => !!widget.isOpen);
  }, [leftWidgetsMenu]);

  const isRightSidebar = useMemo(() => !transformLeftbar, [transformLeftbar]);

  const currentOfferId = useMemo(
    () => getSelectedOffer(findSelectedOptionPath([currentWidget || {}])),
    [currentWidget]
  );

  const presetTitle = useMemo(
    () => (
      <div className='topBarTitle'>
        <Text variant='headingMd' as='h5'>
          {skeletonType === EditPresetTypeEnum.PRESET_EDITOR ? (
            <div className='PresetEditorTitle'>
              {globalPresetState.editPresetData?.name}{' '}
              <Badge>{i18n.translate('PresetEditor')}</Badge>
            </div>
          ) : (
            globalPresetState.promotionPresetData?.title
          )}
        </Text>
      </div>
    ),
    [globalPresetState, skeletonType]
  );

  const currentWidgetName = useMemo(
    () => currentWidget?.name as WidgetListTypeDtoEnum,
    [currentWidget?.name]
  );

  //Const to see if promotion has some preset changes (Only for promotion editor)
  const presetsChanges: PromotionPresetsChangesDto = useMemo(() => {
    const getPresetsGroup = (
      shopLevel: boolean,
      presetSetup: PresetsSetupDto | null | undefined
    ) => {
      const filtered: any = {};
      for (const [key, value] of Object.entries(presetSetup || {})) {
        if (
          presetSetup?.[key as keyof PresetsSetupDto]?.isShopLevelWidget ===
          shopLevel
        ) {
          filtered[key] = value;
        }
      }
      return filtered;
    };
    const currentPresetSetup =
      globalPresetState.promotionPresetData?.presetSetup;
    const initialPresetSetup =
      defaultPresetState.promotionPresetData?.presetSetup;
    const customizer = (objValue: any, othValue: any, key: any) => {
      if (
        key === 'canBeHidden' ||
        key === 'canBeDragged' ||
        key === 'canBeRemoved'
      ) {
        return true;
      }
    };
    const isShopEqual = isEqualWith(
      getPresetsGroup(true, currentPresetSetup),
      getPresetsGroup(true, initialPresetSetup),
      customizer
    );
    const isWidgetEqual = isEqualWith(
      getPresetsGroup(false, currentPresetSetup),
      getPresetsGroup(false, initialPresetSetup),
      customizer
    );
    return {
      promotionLevel: isWidgetEqual,
      shopLevel: isShopEqual,
    };
  }, [
    globalPresetState.promotionPresetData?.presetSetup,
    defaultPresetState.promotionPresetData?.presetSetup,
  ]);

  const currentPageSupportList = useMemo(
    () =>
      pagesSupportLists.find((page) => page.name === selectedPage)?.supports,
    [pagesSupportLists, selectedPage]
  );

  const dataForPreview = useMemo(() => {
    const supportedWidgets = getGlobalCurrentEntries(
      globalPresetState[dataToShowOnPreview]?.pages
    );
    const widgetsList =
      Array.isArray(supportedWidgets) &&
      supportedWidgets.every((item) => typeof item === 'string')
        ? transformWidgetsList(supportedWidgets)
        : [];
    const data = cloneDeep(globalPresetState[dataToShowOnPreview]);

    widgetsList.forEach((fieldName) => {
      // Check if the fieldName exists in the object
      if (data && fieldName in data) {
        // Replace the value with the new one (you can customize this part)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data[fieldName as keyof typeof data] =
          dataToShowOnPreview && globalPresetState
            ? {
                ...(globalPresetState[dataToShowOnPreview] as any)?.[fieldName],
                // Update this to suit new backend structure
                ...(skeletonType === EditPresetTypeEnum.PROMOTION_EDITOR && {
                  offers: (
                    (
                      globalPresetState[
                        dataToShowOnPreview
                      ] as PromotionSetupDto
                    ).offers as any
                  )?.[fieldName],
                }),
              }
            : {};
      }
    });
    if (data) {
      const {
        title,
        offers,
        id,
        name,
        defaultLanguage,
        presets,
        supportedWidgets,
        ...newObject
      } = data as any;
      return { ...newObject, currentWidgetName };
    } else {
      return {};
    }
  }, [dataToShowOnPreview, globalPresetState, currentWidgetName]);

  const adminActivePath = useMemo(
    () =>
      getAdminPath(
        leftWidgetsMenu,
        skeletonType === EditPresetTypeEnum.PRESET_EDITOR
      ),
    [leftWidgetsMenu, skeletonType]
  );

  //Function that is called after "Save" button click (Only for promotion editor)
  const closePromotionTransaction = useCallback(
    (closeTransactionSetup: CloseEditorTransactionRequestDto) => {
      const closeAndNavigate = () => {
        closeEditorTransaction(transactionId, closeTransactionSetup).then(
          () => {
            initializePromotionData();
            setSavedPromotionChanges(promotionChanges);
          }
        );
      };

      if (!isEqual(promotionChanges, savedPromotionChanges)) {
        saveEditPromotionChanges(promotionChanges).then(closeAndNavigate);
      } else {
        closeAndNavigate();
      }
    },
    [
      isTriggerSaving,
      promotionChanges,
      transactionId,
      globalPresetState.promotionPresetData,
      saveEditPromotionChanges,
    ]
  );

  const handleDiscardAllChanges = useCallback(() => {
    if (skeletonType === EditPresetTypeEnum.PRESET_EDITOR) {
      handleUpdateDefaultState(
        'editPresetData',
        initialPresetState.editPresetData
      );
      handleUpdateGlobalState(
        'editPresetData',
        initialPresetState.editPresetData
      );
      setPublishModalOpen(false);
    } else {
      closeEditorTransaction(transactionId, { isDiscarding: true }).then(() => {
        handleUpdateDefaultState(
          'promotionPresetData',
          initialPresetState.promotionPresetData
        );
        handleUpdateGlobalState(
          'promotionPresetData',
          initialPresetState.promotionPresetData
        );
        setSaveWidgetEditorModalOpen(false);
      });
    }
  }, [initialPresetState, skeletonType, transactionId]);

  //Function to save preset editor (Only for preset editor)
  const saveEditPresetData = useCallback(() => {
    const changes = findPresetChanges(
      globalPresetState.editPresetData,
      editPresetData
    );
    if (changes !== undefined) {
      saveEditPresetChanges({
        ...(changes as SavePresetRequestDto),
      }).then(() => initializePresetData());
    }
  }, [saveEditPresetChanges, skeletonType, globalPresetState]);

  //Function that is called after "Save" button click (Only for preset editor)
  const checkOnAffectedPromotions = useCallback(() => {
    if (
      !affectedPromotions?.active?.totalCount &&
      !affectedPromotions?.inactive?.totalCount
    ) {
      saveEditPresetData();
    } else {
      setPublishModalOpen(true);
    }
  }, [affectedPromotions, saveEditPresetData]);

  const handleMessageFromMainApp = (ev: MessageEvent) => {
    const { type } = ev.data;

    if (type === 'PARAMS_UPDATE') {
      setMaxModalParams(ev.data.params);
    }

    if (type === 'CLEAR_SEARCH_PARAMS') {
      setSearchParams({});
    }

    if (type === 'DISCARD_ACTION') {
      handleDiscardAllChanges();
    }

    if (type === 'SAVE_ACTION') {
      if (skeletonType === EditPresetTypeEnum.PROMOTION_EDITOR) {
        if (presetsChanges.promotionLevel && presetsChanges.shopLevel) {
          closePromotionTransaction({
            promotionLevel: {
              saveMode: TransactionSaveModeDtoEnum.SAVE,
            },
            shopLevel: {
              saveMode: TransactionSaveModeDtoEnum.SAVE,
            },
          });
        } else {
          setSaveWidgetEditorModalOpen(true);
        }
      } else {
        checkOnAffectedPromotions();
      }
    }
  };

  const handleUpdateGlobalState = useCallback(
    (
      field: keyof PresetSkeletonDto,
      data: PresetSkeletonDto[keyof PresetSkeletonDto],
      flag = true
    ) => {
      setFlag(flag);
      setGlobalPresetState({
        ...globalPresetState,
        [field]: data,
      });
    },
    [setGlobalPresetState, globalPresetState, setFlag]
  );

  const handleUpdateDefaultState = useCallback(
    (
      field: keyof PresetSkeletonDto,
      data: PresetSkeletonDto[keyof PresetSkeletonDto]
    ) => {
      setDefaultPresetState((prev) => ({
        ...prev,
        [field]: data,
      }));
    },
    [setDefaultPresetState]
  );

  const handleUpdateInitialState = useCallback(
    (
      field: keyof PresetSkeletonDto,
      data: PresetSkeletonDto[keyof PresetSkeletonDto]
    ) => {
      setInitialPresetState((prev) => ({
        ...prev,
        [field]: data,
      }));
    },
    [setInitialPresetState]
  );

  const handleGetSupportedWidgets = useCallback(
    (pages: PageSetupEntryDto[]) => {
      const supportedWidgets = pages.flatMap((page) => page.currentEntries);
      setSupportedWidgets(supportedWidgets as WidgetTypeDto[]);
    },
    [setSupportedWidgets]
  );

  const initializePromotionData = useCallback(() => {
    dispatch(setPromotionTransactionId(transactionId));
    fetchPromotionPreset(
      {
        id: currentParams?.promotionId || '',
        transactionId: transactionId,
      },
      (data) => {
        handleGetSupportedWidgets(data.pages);
        handleUpdateGlobalState('promotionPresetData', data);
        handleUpdateDefaultState('promotionPresetData', data);
        handleUpdateInitialState('promotionPresetData', data);
        setSaveWidgetEditorModalOpen(false);
        handlePointerEnter();
      }
    );
  }, [transactionId, currentParams]);

  const initializePresetData = useCallback(() => {
    fetchEditPreset(
      {
        id: currentParams?.presetId || '',
      },
      (data) => {
        handleGetSupportedWidgets(data.pages);
        handleUpdateGlobalState('editPresetData', data);
        handleUpdateDefaultState('editPresetData', data);
        handleUpdateInitialState('editPresetData', data);
      }
    );
  }, [currentParams, handleUpdateGlobalState]);

  //Function that is called when you are changing the preset (Only for promotion editor)
  const handlePresetChangeData = useCallback(
    (presetLevel?: PresetBoundariesTypeDtoEnum) => {
      const updatePromotionState = (data: any) => {
        const getUpdatedPreset = (defaultState?: boolean) => {
          const isShopLevel =
            presetLevel === PresetBoundariesTypeDtoEnum.SHOP_LEVEL;
          const currentState = defaultState
            ? defaultPresetState.promotionPresetData
            : globalPresetState.promotionPresetData;
          const updatedWidgetPreset = {
            ...currentState,
            presetSetup: currentState?.presetSetup,
            presets: data.presets,
          };
          for (const key in updatedWidgetPreset.presetSetup) {
            if (
              updatedWidgetPreset.presetSetup[key as keyof PresetsSetupDto]
                ?.isShopLevelWidget === isShopLevel
            ) {
              updatedWidgetPreset.presetSetup[key as keyof PresetsSetupDto] =
                data.presetSetup[key];
            }
          }
          return updatedWidgetPreset;
        };

        handleUpdateGlobalState('promotionPresetData', getUpdatedPreset(false));
        handleUpdateDefaultState('promotionPresetData', getUpdatedPreset(true));
        dispatch(setIsChangePresetLoading(false));
      };

      fetchPromotionPreset(
        {
          id: currentParams?.promotionId || '',
          transactionId: transactionId,
        },
        updatePromotionState
      );
    },
    [
      currentParams,
      defaultPresetState.promotionPresetData,
      globalPresetState.promotionPresetData,
      transactionId,
      fetchPromotionPreset,
      handleUpdateGlobalState,
    ]
  );

  //Function that is called when you are adding/removing widget (Only for promotion editor)
  const handleWidgetChangeData = useCallback(() => {
    const updatePromotionState = (data: any) => {
      const getUpdatedPreset = (defaultState?: boolean) => {
        const currentState = defaultState
          ? defaultPresetState.promotionPresetData
          : globalPresetState.promotionPresetData;

        const updatedWidgetPreset = {
          ...data,
          presetSetup: currentState?.presetSetup,
        };

        for (const key in updatedWidgetPreset.presetSetup) {
          if (
            updatedWidgetPreset.presetSetup[key as keyof PresetsSetupDto] ===
            null
          ) {
            updatedWidgetPreset.presetSetup[key as keyof PresetsSetupDto] =
              data.presetSetup[key];
          }
        }
        return updatedWidgetPreset;
      };
      handleGetSupportedWidgets(data.pages);
      handleUpdateGlobalState('promotionPresetData', getUpdatedPreset(false));
      handleUpdateDefaultState('promotionPresetData', getUpdatedPreset(true));
    };

    fetchPromotionPreset(
      {
        id: currentParams?.promotionId || '',
        transactionId: transactionId,
      },
      updatePromotionState
    );
  }, [
    currentParams,
    transactionId,
    defaultPresetState.promotionPresetData,
    globalPresetState.promotionPresetData,
    handleUpdateDefaultState,
    fetchPromotionPreset,
  ]);

  //Function to update left menu state
  const handleWidgetsMenuState = useCallback(
    (widgetType: string, field: string, value: any) => {
      setLeftWidgetsMenu((prevWidget) => {
        const updatedWidgetsState = prevWidget.map((widget) => {
          if (widget.name === widgetType) {
            return {
              ...widget,
              [field]: value,
            };
          }
          return widget;
        });
        return updatedWidgetsState;
      });
    },
    [setLeftWidgetsMenu, leftWidgetsMenu]
  );

  const handleRemoveWidget = useCallback(
    (widgetName: WidgetTypeDto) => {
      const pages = globalPresetState.promotionPresetData?.pages;
      const updatedPages = pages?.map((page) => {
        const index = page.currentEntries?.indexOf(widgetName) as number;
        if (index !== -1) {
          page.currentEntries?.splice(index, 1);
        }
        return page;
      });
      handleUpdateGlobalState('promotionPresetData', {
        ...globalPresetState?.promotionPresetData,
        pages: updatedPages,
      });
    },
    [handleUpdateGlobalState, globalPresetState.promotionPresetData]
  );

  const handleAddWidget = useCallback(
    (page: PageTypeDto, widgetName: WidgetTypeDto) => {
      const pages = globalPresetState.promotionPresetData?.pages;
      const updatedPages = pages?.map((currentPage) => {
        if (currentPage.page === page) {
          return {
            ...currentPage,
            currentEntries: [
              ...(currentPage?.currentEntries || []),
              widgetName,
            ],
          };
        }
        return currentPage;
      });
      dispatch(setIsPromotionEditorDataLoading(true));
      saveEditPromotionChanges({
        ...promotionChanges,
        pages: updatedPages,
      }).then(() => handleWidgetChangeData());
    },
    [
      promotionChanges,
      transactionId,
      globalPresetState.promotionPresetData,
      saveEditPromotionChanges,
    ]
  );

  //Function to auto-save (Only for promotion editor)
  const handleAutoSavePromotionChanges = useCallback(() => {
    if (
      !isEqual(promotionChanges, savedPromotionChanges) &&
      anyChangesInEditor
    ) {
      setIsTriggerSaving(true);
      setSavedPromotionChanges(promotionChanges);
      saveEditPromotionChanges(promotionChanges).then(() => {
        handlePointerEnter();
      });
    }
  }, [
    promotionChanges,
    savedPromotionChanges,
    anyChangesInEditor,
    setSavedPromotionChanges,
    saveEditPromotionChanges,
  ]);

  const debouncedSaveTrigger = useCallback(
    debounce(() => {
      if (
        anyChangesInEditor &&
        !isEqual(promotionChanges, savedPromotionChanges)
      ) {
        handleAutoSavePromotionChanges();
      }
    }, timer),
    [
      anyChangesInEditor,
      promotionChanges,
      savedPromotionChanges,
      handleAutoSavePromotionChanges,
    ]
  );

  const handlePointerEnter = useCallback(() => {
    debouncedSaveTrigger.cancel();
    setIsTriggerSaving(false);
  }, [setIsTriggerSaving, debouncedSaveTrigger]);

  const handlePointerLeave = useCallback(() => {
    debouncedSaveTrigger();
  }, [debouncedSaveTrigger]);

  const handleZoomIn = useCallback(() => {
    const newZoomLevel = Math.min(zoomLevel + STEP, PREVIEW_ZOOM_MAX);
    setZoomLevel(newZoomLevel);
  }, [zoomLevel, setZoomLevel]);

  const handleZoomOut = useCallback(() => {
    const newZoomLevel = Math.max(zoomLevel - STEP, currentInitialZoom);
    setZoomLevel(newZoomLevel);
  }, [zoomLevel, setZoomLevel, currentInitialZoom]);

  const handleZoomChange = useCallback(
    (value: number) => setZoomLevel(value),
    []
  );

  const handleLeftMenuSelection = useCallback(
    (openWidget?: boolean) => {
      if (openWidget === undefined) {
        setLeftWidgetsMenu(updateLeftMenuSelection(leftWidgetsMenu, null));
      } else if (openWidget && currentWidget?.id) {
        setLeftWidgetsMenu(
          updateLeftMenuSelection(leftWidgetsMenu, currentWidget.id)
        );
      } else {
        setLeftWidgetsMenu(
          updateLeftMenuSelection(leftWidgetsMenu, null, true)
        );
      }
    },
    [
      leftWidgetsMenu,
      updateLeftMenuSelection,
      setLeftWidgetsMenu,
      currentWidget,
    ]
  );

  const generatePromotionLeftMenu = useCallback(
    (data: PromotionSetupDto) => {
      const output = generateLeftMenu(data as LeftMenuOption);
      const formattedOffers = formatOffers(data?.offers as LeftMenuOffers);
      const mergedData = addOptionsToInitialArray(output, formattedOffers);
      const newLeftWidgetsMenu =
        mergedData.length < leftWidgetsMenu.length
          ? leftWidgetsMenu.filter((obj1) =>
              mergedData.some((obj2) => obj1.name === obj2.name)
            )
          : leftWidgetsMenu;
      setLeftWidgetsMenu(updateLeftMenu(newLeftWidgetsMenu, mergedData));
    },
    [setLeftWidgetsMenu, leftWidgetsMenu]
  );

  const generatePresetLeftMenu = useCallback(
    (data: PresetDto) => {
      const generatedLeftMenu = generateLeftMenu(data as LeftMenuOption);
      const newLeftWidgetsMenu =
        generatedLeftMenu.length < leftWidgetsMenu.length
          ? leftWidgetsMenu.filter((obj1) =>
              generatedLeftMenu.some((obj2) => obj1.name === obj2.name)
            )
          : leftWidgetsMenu;
      setLeftWidgetsMenu(updateLeftMenu(newLeftWidgetsMenu, generatedLeftMenu));
    },
    [generateLeftMenu, leftWidgetsMenu]
  );

  //Function to navigate to settings modal
  const handleSettingsModalActions = useCallback(
    (config: ToggleSettingsModalConfigDto) => {
      if (config.action === 'open' && config.path) {
        setSearchParams({
          modal: 'settings',
          path: 'manage-widgets',
          subPath: `${config.path}`,
        });
        config.element && dispatch(setCurrentAnchor(config.element));
      } else if (config.action === 'save') {
        saveEditPromotionChanges(promotionChanges).then(() =>
          handleWidgetChangeData()
        );
      }
    },
    [setSearchParams, handleWidgetChangeData, promotionChanges]
  );

  const sendMessageToMainApp = useCallback((message: any) => {
    window.opener?.postMessage(message, location.origin);
  }, []);

  const getSelectionFromThePreview = useCallback((path: string) => {
    const isNotification = !!path?.includes('notification');
    setLeftWidgetsMenu((prevLeftMenu) => {
      const newLeftMenu = updateSelectionByPath(
        prevLeftMenu,
        path,
        isNotification
      );
      return newLeftMenu;
    });
  }, []);

  const handleFetchBrandColors = useCallback(async () => {
    await fetchBrandColors({
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    });
  }, [APP_PASSWORD, APP_NAME]);

  // Need fix for that
  const handleSwitchWidgetState = useCallback(
    (widgetName: string, entries: any[]) => {
      setLeftWidgetsMenu((prev) =>
        updateLeftMenuSelection(prev, null).map((widget) => {
          if (widget.name === widgetName) {
            return {
              ...widget,
              currentEntries: entries,
              // Probably need to be fixed in the future
              // isSelected: transformLeftbar ? widget.isSelected : true,
            };
          } else {
            return widget;
          }
        })
      );
      setFlag(null);
    },
    []
  );

  useEffect(() => {
    if (saveWidgetEditorModalOpen) {
      handlePointerEnter();
    } else {
      handlePointerLeave();
    }
  }, [saveWidgetEditorModalOpen]);

  useEffect(() => {
    if (toggleSettingsConfig) {
      handleSettingsModalActions(toggleSettingsConfig);
    }
  }, [toggleSettingsConfig]);

  // Clean up the debounced function when the component unmounts. Andriy should addjust this in future.
  useEffect(() => {
    return () => {
      handlePointerEnter();
    };
  }, [handlePointerEnter]);

  useEffect(() => {
    if (
      skeletonType === EditPresetTypeEnum.PRESET_EDITOR &&
      currentParams?.presetId
    ) {
      initializePresetData();
      fetchSmartTags();
    } else if (currentParams?.promotionId) {
      initializePromotionData();
      fetchSmartTags();
    }
    return () => {
      dispatch(setPromotionTransactionId(''));
      dispatch(setToggleSettingsConfig(null));
    };
  }, [currentParams]);

  useEffect(
    () => handleUpdateDefaultState('shopDefaultData', shopDefaultData),
    [shopDefaultData]
  );

  //Useeffect for updating RIGHT menu.
  useEffect(() => {
    const { formattedOffers, output } = reverseMergedData(leftWidgetsMenu);
    if (globalPresetState[dataToShowOnPreview]) {
      const input = globalPresetState[dataToShowOnPreview];
      if (input) {
        const mergedOffers = mergeOffers(
          formattedOffers,
          (input as any)?.offers ? (input as any).offers : {}
        );

        // add into generator in future
        const newMergeOffers = {
          ...mergedOffers,
          ...(!!mergedOffers.announcementBar?.length && {
            announcementBar: mergedOffers.announcementBar.map(
              (offer: OfferWidgetSetupDtoAnnouncementBarOfferDtoRead) => ({
                ...offer,
                setup: {
                  ...offer.setup,
                  general: {
                    ...offer.setup?.general,
                    position: offer.parentKey,
                  },
                },
              })
            ),
          }),
        };

        const newRightMenu = generateNewRightMenu(
          output,
          input,
          newMergeOffers
        );
        handleUpdateGlobalState(dataToShowOnPreview, newRightMenu);
        flag !== null && setFlag(false);
      }
    }
  }, [leftWidgetsMenu]);

  //useEffect for updating LEFT menu.
  useEffect(() => {
    if (
      promotionPresetData &&
      globalPresetState.promotionPresetData &&
      skeletonType === EditPresetTypeEnum.PROMOTION_EDITOR &&
      flag
    ) {
      const changes = findPresetChanges(
        globalPresetState.promotionPresetData,
        promotionPresetData
      );

      changes !== undefined
        ? setPromotionChanges(changes)
        : setPromotionChanges({});
      generatePromotionLeftMenu(globalPresetState.promotionPresetData);
    }
    if (
      editPresetData &&
      skeletonType === EditPresetTypeEnum.PRESET_EDITOR &&
      globalPresetState.editPresetData &&
      flag
    ) {
      generatePresetLeftMenu(globalPresetState.editPresetData);
    }
  }, [globalPresetState]);

  useEffect(() => {
    if (!currentParams) {
      isDebugOrLocal
        ? dispatch(setCurrentParams(params))
        : dispatch(setCurrentParams(maxModalParams));
    }
  }, [params, maxModalParams, isDebugOrLocal]);

  useEffect(() => {
    if (
      currentWidgetName &&
      !currentPageSupportList?.includes(currentWidgetName)
    ) {
      setLeftWidgetsMenu(updateLeftMenuSelection(leftWidgetsMenu, null, true));
    }
  }, [selectedPage]);

  useEffect(() => {
    smartTagsList &&
      dispatch(
        setSmartTagsCodes(
          getFormattedData(
            getCurrentSmartTagsList(smartTagsList, currentOfferId) || []
          )
        )
      );
  }, [currentOfferId, smartTagsList]);

  useEffect(() => {
    // Set isLoading to true when activeDeviceType changes
    setIsPercentageLoading(true);

    // Use setTimeout to hide the loader after 0.8 second
    const timer = setTimeout(() => {
      setIsPercentageLoading(false);
    }, 800);

    // Clear the timer when the component unmounts or activeDeviceType changes
    return () => clearTimeout(timer);
  }, [activeDeviceType]);

  useEffect(() => {
    !isDebugOrLocal &&
      sendMessageToMainApp({
        type: 'UPDATE_SEARCH_PARAMS',
        searchParams: { path, subPath, tab, language },
      });
  }, [path, subPath, tab, language]);

  useEffect(() => {
    currentAnchor &&
      !isDebugOrLocal &&
      sendMessageToMainApp({
        type: 'UPDATE_ANCHOR',
        currentAnchor,
      });
  }, [currentAnchor]);

  useEffect(() => {
    !isDebugOrLocal && sendMessageToMainApp({ type: 'MAX_MODAL_RENDERED' });
  }, [isDebugOrLocal]);

  useEffect(() => {
    !isDebugOrLocal &&
      sendMessageToMainApp({ type: 'DISABLE_SAVE', saveDisabled });
  }, [saveDisabled]);

  useEffect(() => {
    !isDebugOrLocal &&
      sendMessageToMainApp({ type: 'EDITOR_CHANGES', anyChangesInEditor });
  }, [anyChangesInEditor]);

  useEffect(() => {
    !isDebugOrLocal &&
      window.addEventListener('message', handleMessageFromMainApp);
    return () => {
      window.removeEventListener('message', handleMessageFromMainApp);
    };
  }, [handleMessageFromMainApp]);

  useEffect(() => {
    APP_PASSWORD && APP_NAME && handleFetchBrandColors();
  }, [APP_PASSWORD, APP_NAME]);

  console.log(promotionChanges);

  return (
    <div className='EditPresetSkeletonWrapper'>
      {!isEmpty(globalPresetState) ? (
        <>
          <div className='SkeletonTopBar'>
            <div className='exitBlock'>{presetTitle}</div>
            <div>
              <div className='previewControls'>
                <ButtonGroup variant='segmented'>
                  {deviceBtns.map((btn) => (
                    <Button
                      key={btn.id}
                      pressed={activeDeviceType === btn.value}
                      onClick={() => dispatch(setActiveDeviceType(btn.value))}
                      icon={btn.icon}
                    />
                  ))}
                </ButtonGroup>
                <ButtonGroup variant='segmented'>
                  <Button
                    onClick={handleZoomOut}
                    disabled={
                      zoomLevel <= currentInitialZoom ||
                      currentPercentage <= 100 ||
                      isPercentageLoading
                    }
                    icon={<Icon source={MinusIcon} tone='base' />}
                  />
                  <Button id='zoom-value' loading={isPercentageLoading}>
                    {`${currentPercentage}`}%
                  </Button>
                  <Button
                    onClick={handleZoomIn}
                    disabled={currentPercentage >= 300 || isPercentageLoading}
                    icon={<Icon source={PlusIcon} tone='base' />}
                  />
                </ButtonGroup>
                {skeletonType === EditPresetTypeEnum.PROMOTION_EDITOR && (
                  <SelectOptions
                    options={pageOptions}
                    onOptionSelect={(value) => {
                      dispatch(setSelectedPage(value as PageTypeDtoEnum));
                    }}
                    selectedOption={selectedPage}
                    label=''
                  />
                )}
              </div>
            </div>
          </div>
          <div className='PresetEditor'>
            {transformLeftbar &&
            isLeftMenuOptionSelected([currentWidget as LeftMenuWidget]) &&
            !isEmpty(defaultPresetState) ? (
              <RightSideBarWrapper
                skeletonType={skeletonType}
                globalPresetState={globalPresetState}
                currentWidget={currentWidget}
                defaultPresetData={defaultPresetState}
                smallScreen
                handleRemoveWidget={handleRemoveWidget}
                handleUpdateGlobalState={handleUpdateGlobalState}
                handlePointerLeave={handlePointerLeave}
                handlePointerEnter={handlePointerEnter}
                handleLeftMenuSelection={handleLeftMenuSelection}
              />
            ) : (
              <LeftSidebarWrapper
                widgetsState={leftWidgetsMenu}
                smallScreen={transformLeftbar}
                presets={globalPresetState.promotionPresetData?.presets || {}}
                presetsChanges={presetsChanges}
                pages={globalPresetState[dataToShowOnPreview]?.pages || []}
                handleWidgetsMenuState={handleWidgetsMenuState}
                handleAddWidget={handleAddWidget}
                handleRemoveWidget={handleRemoveWidget}
                setLeftWidgetsMenu={setLeftWidgetsMenu}
                setFlag={setFlag}
                handleSwitchWidgetState={handleSwitchWidgetState}
                handlePresetChangeData={handlePresetChangeData}
              />
            )}
            <Preview
              isRightSidebar={isRightSidebar ? 720 : 360}
              activeDeviceType={activeDeviceType}
              handleSwitchWidgetState={handleSwitchWidgetState}
              data={dataForPreview}
              page={selectedPage}
              handleZoomChange={handleZoomChange}
              zoomValue={zoomLevel}
              defaultLanguage={
                (
                  (globalPresetState[dataToShowOnPreview] as PromotionSetupDto)
                    ?.offers as any
                )?.defaultLanguage || 'en'
              }
              skeletonType={skeletonType}
              adminActivePath={adminActivePath}
              getSelectionFromThePreview={getSelectionFromThePreview}
              currentInitialZoom={currentInitialZoom}
              setInitialZoom={setInitialZoom}
            />
            {isRightSidebar && !isEmpty(defaultPresetState) && (
              <RightSideBarWrapper
                skeletonType={skeletonType}
                globalPresetState={globalPresetState}
                currentWidget={currentWidget}
                defaultPresetData={defaultPresetState}
                handleUpdateGlobalState={handleUpdateGlobalState}
                handlePointerLeave={handlePointerLeave}
                handlePointerEnter={handlePointerEnter}
                handleLeftMenuSelection={handleLeftMenuSelection}
                handleRemoveWidget={handleRemoveWidget}
              />
            )}
          </div>
          {publishModalOpen && (
            <PresetPublishModal
              affectedPromotions={affectedPromotions || {}}
              onPublishIsLoading={saveEditPresetChangesIsLoading}
              affectedPromotionsIsFetching={affectedPromotionsIsFetching}
              isOpen={publishModalOpen}
              onClose={() => setPublishModalOpen(false)}
              onPublish={saveEditPresetData}
            />
          )}
          {saveGlobalPresetModal && (
            <SaveGlobalPresetModal
              isOpen={saveGlobalPresetModal}
              onClose={() => setSaveGlobalPresetModal(false)}
              onPublish={saveEditPresetData}
            />
          )}
          {saveWidgetEditorModalOpen && (
            <SaveWidgetEditorModal
              presets={globalPresetState.promotionPresetData?.presets || {}}
              presetsChanges={presetsChanges}
              isOpen={saveWidgetEditorModalOpen}
              isLoading={
                closeEditorTransactionIsLoading ||
                saveEditPromotionChangesIsLoading
              }
              onClose={() => setSaveWidgetEditorModalOpen(false)}
              onSave={(data) => closePromotionTransaction(data)}
            />
          )}
        </>
      ) : (
        <Loader fullWidth />
      )}
    </div>
  );
};
