import React, { useCallback, useEffect, useState } from 'react';
import { useI18n } from '@shopify/react-i18n';
import { OfferSuggestedRuleConditionDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { ModalCustom } from 'core/components/ModalCustom/ModalCustom';
import { initialCondition } from '../../../Offers/FrequentlyBoughtTogether/FrequentlyBoughtTogether';
import './ConditionalModal.scss';
import { GrayBox } from 'core/components';
import { CartRulesRadios } from '../../../CartRules/components/CartRulesRadios/CartRulesRadios';
import {
  OfferRuleTypeDtoEnum,
  OfferTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';

type ConditionModalProps = {
  open: boolean;
  condition: OfferSuggestedRuleConditionDto;
  setCondition: (condition: OfferSuggestedRuleConditionDto) => void;
  handleClose: () => void;
  offerType?: OfferTypeDtoEnum;
};

export const ConditionModal: React.FC<ConditionModalProps> = (props) => {
  const { condition, open, setCondition, handleClose, offerType } = props;

  const [preSavedCondition, setPreSavedCondition] =
    useState<OfferSuggestedRuleConditionDto>(condition);
  const [isValid, setIsValid] = useState<boolean>(true);

  const [i18n] = useI18n();

  const handleSave = useCallback(() => {
    setCondition(preSavedCondition);
    handleClose();
  }, [setCondition, handleClose, preSavedCondition]);

  const mergeObjects = useCallback(
    (
      existing: OfferSuggestedRuleConditionDto,
      newObj: Partial<OfferSuggestedRuleConditionDto>
    ): OfferSuggestedRuleConditionDto => {
      const mergedObject: any = { ...existing };

      Object.keys(newObj).forEach((key) => {
        if (
          typeof newObj[key as keyof typeof newObj] === 'object' &&
          newObj[key as keyof typeof newObj] !== null &&
          !Array.isArray(newObj[key as keyof typeof newObj])
        ) {
          // If the property is an object, recursively merge it
          mergedObject[key] = mergeObjects(
            existing[
              key as keyof typeof existing
            ] as OfferSuggestedRuleConditionDto,
            newObj[
              key as keyof typeof newObj
            ] as Partial<OfferSuggestedRuleConditionDto>
          );
        } else {
          // Otherwise, replace the property value
          mergedObject[key] = newObj[key as keyof typeof newObj];
        }
      });

      return mergedObject;
    },
    []
  );

  const handlePreSavedConditionUpdate = useCallback(
    (data: Partial<OfferSuggestedRuleConditionDto>) => {
      setPreSavedCondition((prevState: OfferSuggestedRuleConditionDto) =>
        mergeObjects(prevState, data)
      );
    },
    [setPreSavedCondition, mergeObjects]
  );

  const handleRuleValidityChange = useCallback(
    (id: string, isValid: boolean) => {
      setIsValid(isValid);
    },
    [setIsValid]
  );

  useEffect(() => {
    open &&
      Object.keys(preSavedCondition).length === 0 &&
      setPreSavedCondition(initialCondition);
  }, [preSavedCondition, open]);

  return (
    <ModalCustom
      isOpen={open}
      title={i18n.translate('Title')}
      onClose={handleClose}
      buttons={[
        {
          content: i18n.translate('Cancel'),
          primary: false,
          action: handleClose,
        },
        {
          content: i18n.translate('Save'),
          primary: true,
          disabled: !isValid,
          action: handleSave,
        },
      ]}
      modalClass='ConditionModal'
      portalClass='ConditionPortal'
    >
      {offerType === OfferTypeDtoEnum.ORDER_BUMP && (
        <CartRulesRadios
          handleSetSelectedOption={(
            checked: boolean,
            value: OfferRuleTypeDtoEnum
          ) =>
            setPreSavedCondition(
              (prevState: OfferSuggestedRuleConditionDto) => ({
                ...prevState,
                type: value,
              })
            )
          }
          isOrderBump
          selectedOption={preSavedCondition.type}
        />
      )}
      <div style={{ padding: '20px' }}>
        <GrayBox
          data={preSavedCondition}
          updateValue={(data) => handlePreSavedConditionUpdate(data)}
          handleRuleValidityChange={handleRuleValidityChange}
          isMoney={
            preSavedCondition.type ===
            OfferRuleTypeDtoEnum.MINIMUM_PURCHASE_AMOUNT
          }
        />
      </div>
    </ModalCustom>
  );
};
