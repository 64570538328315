import React from 'react';
import { Banner, BlockStack, Card, ChoiceList, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';

type RemovingFreeGiftsProps = {
  data: boolean | undefined;
  section: string;
  handleGiftsLocaleState: (section: string, value: boolean) => void;
};

export const RemovingFreeGifts: React.FC<RemovingFreeGiftsProps> = ({
  data,
  section,
  handleGiftsLocaleState,
}) => {
  const [i18n] = useI18n();

  const options = [
    {
      label: i18n.translate('allow'),
      value: 'true',
    },
    {
      label: i18n.translate('dontAllow'),
      value: 'false',
    },
  ];

  return (
    <Card roundedAbove='sm' padding='400'>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate('RemovingFreeGiftsTitle')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('RemovingFreeGiftsSubtitle')}
          </Text>
        </BlockStack>
        <ChoiceList
          title={''}
          titleHidden
          choices={options}
          selected={[`${data}`]}
          onChange={(value) =>
            handleGiftsLocaleState(section, JSON.parse(value[0]))
          }
        />
        <Banner>{i18n.translate(data ? 'allowTip' : 'dontAllowTip')}</Banner>
      </BlockStack>
    </Card>
  );
};
