import React, { useCallback } from 'react';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import { useI18n } from '@shopify/react-i18n';
import { OptionDtoOffsetComponentDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { OffsetTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import './Offset.scss';
import { Checkbox, TextField, Text } from '@shopify/polaris';

type OffsetProps = {
  offset: OptionDtoOffsetComponentDto;
  supportCustom?: boolean;
  disabled?: boolean;
  note?: string;
  setOffset?: (value: OptionDtoOffsetComponentDto) => void;
  onSystemReset?: () => void;
};
export const Offset: React.FC<OffsetProps> = ({
  offset,
  supportCustom,
  disabled,
  note,
  setOffset,
  onSystemReset,
}) => {
  const [i18n] = useI18n();

  const offsetTypeOptions = Object.values(OffsetTypeDtoEnum)
    .filter((value) =>
      !supportCustom ? value !== OffsetTypeDtoEnum.CUSTOM : true
    )
    .map((value) => {
      return {
        value: value,
        label: i18n.translate(`${value}`),
      };
    });

  const onCustomOffsetValdiate = useCallback(
    (field: 'horizontal' | 'vertical') => {
      const customValue = offset.value?.[field] || 0;
      setOffset?.({
        ...offset,
        value: {
          ...offset.value,
          [field]: customValue > 24 ? 24 : customValue < 0 ? 0 : customValue,
        },
      });
    },
    [setOffset, offset]
  );

  return (
    <div className='Offset'>
      <StyleComponentHeader
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            disabled: disabled,
            onAction: () => onSystemReset?.(),
          },
        ]}
        label={i18n.translate(`Offset`)}
      >
        {offset.enabled !== undefined && (
          <Checkbox
            label={i18n.translate('EnableOffset')}
            checked={offset.enabled}
            onChange={(value) => setOffset?.({ ...offset, enabled: value })}
            disabled={disabled}
          />
        )}

        {offset.enabled ||
          (offset.enabled === undefined && (
            <>
              <div className='OffsetRow'>
                {i18n.translate(`Offset`)}
                <div className='RightContent'>
                  <SelectOptions
                    options={offsetTypeOptions}
                    onOptionSelect={(value) =>
                      setOffset?.({
                        ...offset,
                        value: {
                          ...offset.value,
                          type: value as OffsetTypeDtoEnum,
                        },
                      })
                    }
                    selectedOption={
                      offset.value?.type || OffsetTypeDtoEnum.MEDIUM
                    }
                    label=''
                    disabled={disabled}
                  />
                </div>
              </div>
              {offset.value?.type === OffsetTypeDtoEnum.CUSTOM && (
                <>
                  <div className='OffsetRow'>
                    {i18n.translate(`Horizontal`)}
                    <div className='RightContent'>
                      <TextField
                        type='number'
                        value={offset.value.horizontal?.toString()}
                        autoComplete=''
                        label=''
                        suffix='px'
                        disabled={disabled}
                        onChange={(value) =>
                          setOffset?.({
                            ...offset,
                            value: {
                              ...offset.value,
                              horizontal: +value,
                            },
                          })
                        }
                        onBlur={() => onCustomOffsetValdiate('horizontal')}
                      />
                    </div>
                  </div>
                  <div className='OffsetRow'>
                    {i18n.translate(`Vertical`)}
                    <div className='RightContent'>
                      <TextField
                        type='number'
                        value={offset.value.vertical?.toString()}
                        autoComplete=''
                        label=''
                        suffix='px'
                        disabled={disabled}
                        onChange={(value) =>
                          setOffset?.({
                            ...offset,
                            value: {
                              ...offset.value,
                              vertical: +value,
                            },
                          })
                        }
                        onBlur={() => onCustomOffsetValdiate('vertical')}
                      />
                    </div>
                  </div>
                </>
              )}
              {note && (
                <Text as='p' tone='subdued'>
                  {note}
                </Text>
              )}
            </>
          ))}
      </StyleComponentHeader>
    </div>
  );
};
