import {
  ReferrerLookupDto,
  useGetPromotionsV6LookupReferrersQuery,
} from 'core/api/adminPromotions/adminPromotionsApi';
import resolveEnvVar from 'env-var-resolver';
import { useEffect, useState } from 'react';
const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';
export const useReferrers = (shouldSkip?: boolean) => {
  const [referrerList, setReferrerList] = useState<ReferrerLookupDto[]>();

  const { data: getReferrerListData } = useGetPromotionsV6LookupReferrersQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
    },
    { skip: !!shouldSkip }
  );

  useEffect(() => {
    setReferrerList((getReferrerListData as ReferrerLookupDto[]) || []);
  }, [getReferrerListData, setReferrerList]);

  return { referrerList };
};
