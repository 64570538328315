import React, { useMemo } from 'react';
import { useI18n } from '@shopify/react-i18n';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import { Checkbox, TextField } from '@shopify/polaris';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import { NinjaCartCallToActionButtonActionDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { NinjaCartCallToActionButtonActionTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';

type ActionProps = {
  action: NinjaCartCallToActionButtonActionDto;
  simplified?: boolean;
  setAction: (data: NinjaCartCallToActionButtonActionDto) => void;
  onSystemReset?: () => void;
};

export const Action: React.FC<ActionProps> = ({
  action,
  simplified,
  setAction,
  onSystemReset,
}) => {
  const [i18n] = useI18n();

  const typeOptions = useMemo(
    () =>
      Object.values(NinjaCartCallToActionButtonActionTypeDtoEnum)
        .filter((value) =>
          simplified
            ? value === NinjaCartCallToActionButtonActionTypeDtoEnum.CLOSE ||
              value === NinjaCartCallToActionButtonActionTypeDtoEnum.OPEN_PAGE
            : value !== NinjaCartCallToActionButtonActionTypeDtoEnum.CLOSE
        )
        .map((value) => {
          return {
            value: value,
            label: i18n.translate(`${value}`),
          };
        }),
    [simplified]
  );

  return (
    <div className='Action'>
      <StyleComponentHeader
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            onAction: () => onSystemReset?.(),
          },
        ]}
        label={i18n.translate(`Action`)}
      >
        <SelectOptions
          options={typeOptions}
          onOptionSelect={(value) =>
            setAction({
              ...action,
              type: value as NinjaCartCallToActionButtonActionTypeDtoEnum,
            })
          }
          selectedOption={
            action.type as NinjaCartCallToActionButtonActionTypeDtoEnum
          }
          label=''
        />
        {action.type ===
          NinjaCartCallToActionButtonActionTypeDtoEnum.OPEN_PAGE && (
          <>
            <TextField
              autoComplete=''
              label={i18n.translate('Page')}
              helpText={i18n.translate('LinkExample')}
              value={action.selectedPage?.link || ''}
              onChange={(value) =>
                setAction({
                  ...action,
                  selectedPage: {
                    ...action.selectedPage,
                    link: value,
                  },
                })
              }
            />
            <Checkbox
              checked={!!action.selectedPage?.openInNewTab}
              label={i18n.translate('OpenNewtab')}
              onChange={(value) =>
                setAction({
                  ...action,
                  selectedPage: {
                    ...action.selectedPage,
                    openInNewTab: value,
                  },
                })
              }
            />
          </>
        )}
        {action.type ===
          NinjaCartCallToActionButtonActionTypeDtoEnum.GO_TO_CHECKOUT && (
          <Checkbox
            checked={!!action.disableBeforeTermsAndConditionsChecked}
            label={i18n.translate('DisableTermsConditions')}
            onChange={(value) =>
              setAction({
                ...action,
                disableBeforeTermsAndConditionsChecked: value,
              })
            }
          />
        )}
      </StyleComponentHeader>
    </div>
  );
};
