import { OfferTargetTypeDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { ResourceSelectionProps } from 'core/components/SearchFieldWithGrayBox';
import { flatten } from 'lodash';
import { OfferTargetTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { Product } from 'core/api/appBridge';

export const parseLastPartToNum = (value?: string | number) => {
  if (value) {
    if (typeof value === 'number') {
      return value;
    } else {
      return Number(/[^/]*$/.exec(value)?.[0]);
    }
  }
};

export const formateList = (
  list: ResourceSelectionProps[],
  type?: OfferTargetTypeDto,
  isFreeGift = false
) => {
  if (type === OfferTargetTypeDtoEnum.VARIANTS) {
    const parsedList = isFreeGift
      ? list.map((item) => ({
          id: item.id,
          productId: null,
        }))
      : flatten(
          list.map((item) =>
            (item as Product).variants.map((el) => ({
              id: el.id,
              productId: el.product?.id || null,
            }))
          )
        );
    return parsedList;
  } else {
    return list.map((item) => ({
      id: item.id,
      productId: null,
    }));
  }
};
