import React, { useCallback } from 'react';
import {
  MessageStyleComponentDto,
  PromotionalBadgeMessageOfferDto,
  PromotionalBadgeMessagePresetDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { TextEntries, TextStyle } from '../../../../../components';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useAppDispatch } from 'core/hooks';
import { setPromotionalBadgeMessages } from 'core/store/widgetsSlice';
import { isEqual } from 'lodash';

type PromotionalBadgeMessageProps = {
  message: PromotionalBadgeMessageOfferDto;
  defaultMessage: PromotionalBadgeMessageOfferDto;
  shopDefaultMessage: MessageStyleComponentDto;
  defaultLanguage?: string;
  offerId?: string;
  presetSetup?: PromotionalBadgeMessagePresetDto;
  isPresetEditor?: boolean;
  setMessage: (data: PromotionalBadgeMessageOfferDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const PromotionalBadgeMessage: React.FC<PromotionalBadgeMessageProps> = ({
  message,
  defaultMessage,
  shopDefaultMessage,
  defaultLanguage,
  offerId,
  isPresetEditor,
  presetSetup,
  setMessage,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const dispatch = useAppDispatch();

  const resetAllToSystemDefault = useCallback(() => {
    setMessage({
      ...message,
      style: shopDefaultMessage,
      message: defaultMessage?.message,
    });
  }, [shopDefaultMessage, defaultMessage, message]);

  const updateMessageState = useCallback(
    (
      field: keyof PromotionalBadgeMessageOfferDto,
      data: PromotionalBadgeMessageOfferDto[keyof PromotionalBadgeMessageOfferDto]
    ) => {
      setMessage({
        ...message,
        ...(!isPresetEditor && { ...message, style: presetSetup?.style }),
        [field]: data,
      });
    },
    [message, setMessage, presetSetup]
  );

  return (
    <div className='PromotionalBadgeMessage'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={() => setMessage(defaultMessage)}
        onResetToSystem={resetAllToSystemDefault}
        name={WidgetListOptionsDtoEnum.MESSAGE}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      {!isPresetEditor && (
        <div className='RightSideSection'>
          <TextEntries
            textMessage={message.message || {}}
            onSystemReset={() =>
              updateMessageState('message', defaultMessage.message)
            }
            defaultLanguage={defaultLanguage || ''}
            disableCollapsed={true}
            disableColor={
              ((!isPresetEditor ? presetSetup?.style : message.style) || {})
                .color !== undefined
            }
            offerId={offerId}
            setTextMessage={(data) => updateMessageState('message', data)}
            setPreviewEntry={(entry) =>
              dispatch(
                setPromotionalBadgeMessages({
                  ...entry,
                  offerId: offerId as string,
                })
              )
            }
          />
        </div>
      )}

      <div className='RightSideSection'>
        <TextStyle
          setTextStyle={(data) => updateMessageState('style', data)}
          textStyle={
            (!isPresetEditor ? presetSetup?.style : message.style) || {}
          }
          inherited={
            !isPresetEditor
              ? isEqual(presetSetup?.style, defaultMessage.style)
              : undefined
          }
          onSystemReset={() => updateMessageState('style', shopDefaultMessage)}
          offersNote={isPresetEditor}
        />
      </div>
    </div>
  );
};

export default PromotionalBadgeMessage;
