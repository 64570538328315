import React, { useCallback } from 'react';
import './CycleOffers.scss';
import { useI18n } from '@shopify/react-i18n';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import { Text, TextField } from '@shopify/polaris';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import { AnnouncementBarShopSettingsCyclingDto } from 'core/api/adminWidgets/adminWidgetsApi';
import {
  AnimationDtoEnum,
  AnnouncementBarCyclingDurationDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';

type CycleOffersProps = {
  cycling: AnnouncementBarShopSettingsCyclingDto;
  setCycling?: (data: AnnouncementBarShopSettingsCyclingDto) => void;
  onSystemReset?: () => void;
  disabled?: boolean;
};

export const CycleOffers: React.FC<CycleOffersProps> = ({
  cycling,
  setCycling,
  disabled,
  onSystemReset,
}) => {
  const [i18n] = useI18n();

  const getSelectorOptions = useCallback(
    (options: AnnouncementBarCyclingDurationDtoEnum[] | AnimationDtoEnum[]) =>
      options.map(
        (value: AnnouncementBarCyclingDurationDtoEnum | AnimationDtoEnum) => {
          return {
            value: value,
            label: i18n.translate(`${value}`),
          };
        }
      ),
    []
  );
  const onDurationTimeChange = useCallback(
    (value: string) => {
      setCycling?.({ ...cycling, customDuration: +value });
    },
    [cycling]
  );

  return (
    <div className='CycleOffers'>
      <StyleComponentHeader
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            onAction: () => onSystemReset?.(),
            disabled: disabled,
          },
        ]}
        label={i18n.translate(`CycleOffers`)}
      >
        <div className='CycleSelectorRow'>
          <Text as='p'>{i18n.translate(`OfferDuration`)}</Text>
          <SelectOptions
            options={getSelectorOptions(
              Object.values(AnnouncementBarCyclingDurationDtoEnum)
            )}
            onOptionSelect={(value) => {
              setCycling?.({
                ...cycling,
                duration: value as AnnouncementBarCyclingDurationDtoEnum,
              });
            }}
            selectedOption={
              cycling.duration || AnnouncementBarCyclingDurationDtoEnum.LONG
            }
            label=''
            disabled={disabled}
          />
        </div>

        {cycling.duration === AnnouncementBarCyclingDurationDtoEnum.CUSTOM && (
          <div className='CycleSelectorRow'>
            <Text as='p'>{i18n.translate(`DurationTime`)}</Text>
            <TextField
              autoComplete=''
              label=''
              suffix='ms'
              type='number'
              value={cycling.customDuration?.toString() || '3000'}
              onChange={onDurationTimeChange}
              onBlur={() => {
                (cycling.customDuration as number) > 30000
                  ? onDurationTimeChange('30000')
                  : (cycling.customDuration as number) < 3000
                    ? onDurationTimeChange('3000')
                    : null;
              }}
              disabled={disabled}
            />
          </div>
        )}
      </StyleComponentHeader>
    </div>
  );
};
