import React, { useCallback, useMemo } from 'react';
import { SelectedOptionPath } from '../../../../RightSideBarWrapper';
import {
  ProductBannerPresetDto,
  ProductBannerPresetDtoRead,
  ProductBannerPresetFirstLevelDto,
  ProductBannerSystemDefaultsDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import ProductBannerIcon from './components/ProductBannerIcon/ProductBannerIcon';
import ProductBannerImage from './components/ProductBannerImage/ProductBannerImage';
import ProductBannerGeneral from './components/ProductBannerGeneral/ProductBannerGeneral';
import ProductBannerMessage from '../../../EditPromotionRightBar/components/PromotionProductBanner/components/ProductBannerMessage/ProductBannerMessage';
import {
  WidgetListOptionsDtoEnum,
  WidgetListTypeDtoEnum,
} from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { getLastSelectedOption } from 'features/settings/components/EditPresetSkeleton/utils/utils';
type PresetProductBannerProps = {
  selectedOptionPath: SelectedOptionPath;
  productBanner: ProductBannerPresetDtoRead;
  defaultPresetData: ProductBannerPresetDtoRead;
  shopDefaultData: ProductBannerSystemDefaultsDto;
  updateProductBanner: (data: ProductBannerPresetDto) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const PresetProductBanner: React.FC<PresetProductBannerProps> = ({
  selectedOptionPath,
  productBanner,
  defaultPresetData,
  shopDefaultData,
  updateProductBanner,
  handleLeftMenuSelection,
}) => {
  const handleComponentVisability = useCallback(
    (option: keyof ProductBannerPresetFirstLevelDto) => {
      updateProductBanner({
        ...productBanner,
        options: {
          ...productBanner.options,
          [option]: {
            ...productBanner.options?.[option],
            isHidden: !productBanner.options?.[option]?.isHidden,
          },
        },
      });
    },
    [updateProductBanner, productBanner]
  );

  const productBannerOptions = useMemo(() => {
    const selectedOption = getLastSelectedOption(selectedOptionPath);
    switch (selectedOption?.name) {
      case WidgetListTypeDtoEnum.PRODUCT_BANNER:
        return (
          <ProductBannerGeneral
            general={productBanner.general || {}}
            shopDefaultGeneral={shopDefaultData || {}}
            defaultGeneral={defaultPresetData.general || {}}
            isPresetEditor
            handleLeftMenuSelection={handleLeftMenuSelection}
            setGeneral={(data) =>
              updateProductBanner({
                ...productBanner,
                general: data,
              })
            }
            onShowHide={
              productBanner.canBeHidden
                ? () =>
                    updateProductBanner({
                      ...productBanner,
                      isHidden: !productBanner.isHidden,
                    })
                : undefined
            }
          />
        );
      case WidgetListOptionsDtoEnum.ICON:
        return (
          <ProductBannerIcon
            icon={productBanner.options?.icon || {}}
            defaultIcon={defaultPresetData.options?.icon || {}}
            shopDefaultIcon={shopDefaultData.icon || {}}
            isPresetEditor
            setIcon={(data) =>
              updateProductBanner({
                ...productBanner,
                options: {
                  ...productBanner.options,
                  icon: data,
                },
              })
            }
            onShowHide={
              productBanner.options?.icon?.canBeHidden
                ? () => handleComponentVisability('icon')
                : undefined
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case WidgetListOptionsDtoEnum.IMAGE:
        return (
          <ProductBannerImage
            handleLeftMenuSelection={handleLeftMenuSelection}
            image={productBanner.options?.image || {}}
            defaultImage={defaultPresetData.options?.image || {}}
            shopDefaultImage={shopDefaultData.image || {}}
            isPresetEditor
            setImage={(data) =>
              updateProductBanner({
                ...productBanner,
                options: {
                  ...productBanner.options,
                  image: data,
                },
              })
            }
            onShowHide={
              productBanner.options?.image?.canBeHidden
                ? () => handleComponentVisability('image')
                : undefined
            }
          />
        );
      case WidgetListOptionsDtoEnum.MESSAGE:
        return (
          <ProductBannerMessage
            handleLeftMenuSelection={handleLeftMenuSelection}
            message={productBanner.options?.message || {}}
            defaultMessage={defaultPresetData.options?.message || {}}
            shopDefaultMessage={shopDefaultData.textStyle || {}}
            isPresetEditor
            setMessage={(data) =>
              updateProductBanner({
                ...productBanner,
                options: {
                  ...productBanner.options,
                  message: data,
                },
              })
            }
            onShowHide={
              productBanner.options?.message?.canBeHidden
                ? () => handleComponentVisability('message')
                : undefined
            }
          />
        );
    }
  }, [
    selectedOptionPath,
    shopDefaultData,
    productBanner,
    defaultPresetData,
    updateProductBanner,
    handleLeftMenuSelection,
    handleComponentVisability,
  ]);
  return <div>{productBannerOptions}</div>;
};

export default PresetProductBanner;
