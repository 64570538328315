import React, { useMemo } from 'react';
import { BlockStack, Card, ChoiceList, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { PromotionCodeFieldHeaderStyleDto } from 'core/api/adminSettings/adminSettingsApi';
import { PromotionCodeFieldHeaderStyleDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { Element } from 'react-scroll';
type HeaderStyleSettingProps = {
  headerStyle: PromotionCodeFieldHeaderStyleDto;
  setHeaderStyle: (value: PromotionCodeFieldHeaderStyleDto) => void;
};
export const HeaderStyleSetting: React.FC<HeaderStyleSettingProps> = ({
  headerStyle,
  setHeaderStyle,
}) => {
  const [i18n] = useI18n();
  const headerStyleOptions = useMemo(
    () =>
      Object.values(PromotionCodeFieldHeaderStyleDtoEnum).map((value) => ({
        label: i18n.translate(`${value}`),
        value: value,
      })),
    []
  );
  return (
    <Element name='HeaderStyleSetting' className='HeaderStyleSetting'>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('HeaderStyle')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('Subtitle')}
            </Text>
          </BlockStack>
          <ChoiceList
            title=''
            choices={headerStyleOptions}
            selected={[
              headerStyle || PromotionCodeFieldHeaderStyleDtoEnum.STYLE_1,
            ]}
            onChange={(value) =>
              setHeaderStyle(value[0] as PromotionCodeFieldHeaderStyleDtoEnum)
            }
          />
        </BlockStack>
      </Card>
    </Element>
  );
};
