import {
  OfferWidgetSetupDtoProductBannerOfferDtoRead,
  PresetsSetupDto,
  ProductBannerGeneralOfferDto,
  ProductBannerIconPresetDto,
  ProductBannerImagePresetDto,
  ProductBannerMessageOfferDto,
  ProductBannerOfferFirstLevelDtoRead,
  ProductBannerPresetDto,
  ProductBannerSystemDefaultsDto,
  PromotionSetupDtoRead,
  PromotionWidgetSetupDtoProductBannerPromotionDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import React, { useCallback, useMemo } from 'react';
import ProductBannerGeneral from '../../../EditPresetRightBar/components/PresetProductBanner/components/ProductBannerGeneral/ProductBannerGeneral';
import ProductBannerOffer from './components/ProductBannerOffer/ProductBannerOffer';
import ProductBannerIcon from '../../../EditPresetRightBar/components/PresetProductBanner/components/ProductBannerIcon/ProductBannerIcon';
import ProductBannerImage from '../../../EditPresetRightBar/components/PresetProductBanner/components/ProductBannerImage/ProductBannerImage';
import { SelectedOptionPath } from '../../../../RightSideBarWrapper';
import { WidgetTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import ProductBannerEVSP from './components/ProductBannerEVSP/ProductBannerEVSP';
import { useI18n } from '@shopify/react-i18n';
import ProductBannerMessage from './components/ProductBannerMessage/ProductBannerMessage';
import {
  WidgetListOptionsDtoEnum,
  WidgetListTypeDtoEnum,
} from 'features/settings/components/EditPresetSkeleton/enums/enums';

type PromotionProductBannerProps = {
  editPromotionState: PromotionSetupDtoRead;
  defaultPromotionData: PromotionSetupDtoRead;
  shopDefaultData: ProductBannerSystemDefaultsDto;
  selectedOptionPath: SelectedOptionPath;
  updateEditPromotionState: (
    field: keyof PromotionSetupDtoRead,
    data: PromotionSetupDtoRead[keyof PromotionSetupDtoRead],
    presetSetup?: PresetsSetupDto
  ) => void;
  filterSupportedWidgets: (value: WidgetTypeDtoEnum) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const PromotionProductBanner: React.FC<PromotionProductBannerProps> = ({
  editPromotionState,
  defaultPromotionData,
  selectedOptionPath,
  shopDefaultData,
  updateEditPromotionState,
  filterSupportedWidgets,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();
  const { productBanner, presetSetup } = editPromotionState;
  const offersData = editPromotionState.offers?.productBanner;
  const defaultOffersData = defaultPromotionData.offers?.productBanner;
  const defaultProductBanner = defaultPromotionData.productBanner;
  const defaultPresetSetup = defaultPromotionData.presetSetup?.productBanner;
  const widgetPreset = presetSetup?.productBanner;

  const updateProductBanner = useCallback(
    (
      field: keyof PromotionWidgetSetupDtoProductBannerPromotionDto,
      data: PromotionWidgetSetupDtoProductBannerPromotionDto[keyof PromotionWidgetSetupDtoProductBannerPromotionDto]
    ) =>
      updateEditPromotionState('productBanner', {
        ...productBanner,
        [field]: data,
      }),
    [productBanner, updateEditPromotionState]
  );

  const updateOffer = useCallback(
    (data: OfferWidgetSetupDtoProductBannerOfferDtoRead, offerId: string) => {
      updateEditPromotionState(
        'offers',
        {
          ...editPromotionState.offers,
          productBanner: editPromotionState.offers?.productBanner?.map(
            (offerData) => {
              if (offerData?.offerId === offerId) {
                return {
                  ...data,
                  setup: {
                    ...data.setup,
                    general: {
                      ...data.setup?.general,
                      background: undefined,
                      cornerRadius: undefined,
                    },
                  },
                };
              }
              return offerData;
            }
          ),
        },
        {
          ...presetSetup,
          productBanner: {
            ...presetSetup?.productBanner,
            general: {
              ...presetSetup?.productBanner?.general,
              background: data.setup?.general?.background,
              cornerRadius: data.setup?.general?.cornerRadius,
            },
          },
        }
      );
    },
    [updateEditPromotionState, editPromotionState, presetSetup]
  );

  const updateOfferComponent = useCallback(
    (
      field: keyof ProductBannerOfferFirstLevelDtoRead,
      data: ProductBannerOfferFirstLevelDtoRead[keyof ProductBannerOfferFirstLevelDtoRead],
      offerId: string,
      preset?: ProductBannerPresetDto
    ) =>
      updateEditPromotionState(
        'offers',
        {
          ...editPromotionState.offers,
          productBanner: editPromotionState.offers?.productBanner?.map(
            (offer) => {
              if (offer.offerId === offerId) {
                return {
                  ...offer,
                  setup: {
                    ...offer.setup,
                    options: {
                      ...offer.setup?.options,
                      [field]: data,
                    },
                  },
                };
              }
              return offer;
            }
          ),
        },
        { ...presetSetup, productBanner: preset }
      ),

    [updateEditPromotionState, editPromotionState, presetSetup]
  );

  const handleComponentVisability = useCallback(
    (
      offerData: OfferWidgetSetupDtoProductBannerOfferDtoRead,
      option: 'image' | 'message' | 'icon',
      type: 'default' | 'entitled' | 'prerequisite'
    ) => {
      if (type === 'default')
        updateOfferComponent(
          option,
          {
            ...offerData?.setup?.options?.[option],
            isHidden: !offerData?.setup?.options?.[option]?.isHidden,
          },
          offerData?.offerId as string
        );
      if (type === 'entitled' || type === 'prerequisite') {
        updateOfferComponent(
          option,
          {
            ...offerData?.setup?.options?.[type],
            options: {
              ...offerData?.setup?.options?.[type]?.options,
              [option]: {
                ...offerData?.setup?.options?.[type]?.options?.[option],
                isHidden:
                  !offerData?.setup?.options?.[type]?.options?.[option]
                    ?.isHidden,
              },
            },
          },
          offerData?.offerId as string
        );
      }
    },
    [updateOfferComponent]
  );

  const getLastSelectedOption = useCallback(
    (path: SelectedOptionPath): SelectedOptionPath => {
      if (!path || !path.selectedOption) {
        return path;
      }
      return getLastSelectedOption(path.selectedOption);
    },
    []
  );

  const getImageComponent = useCallback(
    (
      parentName: string,
      offerData: OfferWidgetSetupDtoProductBannerOfferDtoRead,
      defaultOfferData: OfferWidgetSetupDtoProductBannerOfferDtoRead
    ) => {
      const type =
        parentName === 'entitled'
          ? 'entitled'
          : parentName === 'prerequisite'
          ? 'prerequisite'
          : 'image';

      const imageOfferData =
        type === 'image'
          ? offerData?.setup?.options?.image
          : offerData?.setup?.options?.[type]?.options?.image;

      const defaultImageOfferData =
        type === 'image'
          ? defaultOfferData?.setup?.options?.image
          : defaultOfferData?.setup?.options?.[type]?.options?.image;

      const defaultImageData = {
        ...defaultImageOfferData,
        border: defaultPresetSetup?.options?.image?.border,
        cornerRadius: defaultPresetSetup?.options?.image?.cornerRadius,
        image: {
          ...defaultPresetSetup?.options?.image?.image,
          imageId: defaultImageOfferData?.image?.imageId,
        },
      };

      const updateImageComponent = (data: ProductBannerImagePresetDto) => {
        const newOfferData =
          type === 'image'
            ? { ...imageOfferData, image: { imageId: data.image?.imageId } }
            : {
                ...offerData.setup?.options?.[type],
                options: {
                  ...offerData.setup?.options?.[type]?.options,
                  image: {
                    ...imageOfferData,
                    image: { imageId: data.image?.imageId },
                  },
                },
              };
        const newPresetData = {
          ...widgetPreset,
          options: {
            ...widgetPreset?.options,
            image: {
              ...data,
              image: {
                ...data.image,
                imageId: widgetPreset?.options?.image?.image?.imageId,
              },
            },
          },
        };
        updateOfferComponent(
          type,
          newOfferData,
          offerData?.offerId as string,
          newPresetData
        );
      };
      return (
        <ProductBannerImage
          image={imageOfferData || {}}
          defaultImage={defaultImageData}
          presetSetup={widgetPreset?.options?.image || {}}
          shopDefaultImage={shopDefaultData.image || {}}
          handleLeftMenuSelection={handleLeftMenuSelection}
          setImage={updateImageComponent}
          onShowHide={
            imageOfferData?.canBeHidden
              ? () =>
                  handleComponentVisability(
                    offerData,
                    'image',
                    type === 'image' ? 'default' : type
                  )
              : undefined
          }
        />
      );
    },
    [handleLeftMenuSelection, updateOfferComponent, widgetPreset]
  );

  const getIconComponent = useCallback(
    (
      parentName: string,
      offerData: OfferWidgetSetupDtoProductBannerOfferDtoRead,
      defaultOfferData: OfferWidgetSetupDtoProductBannerOfferDtoRead
    ) => {
      const type =
        parentName === 'entitled'
          ? 'entitled'
          : parentName === 'prerequisite'
          ? 'prerequisite'
          : 'icon';

      const iconOfferData =
        type === 'icon'
          ? offerData?.setup?.options?.icon
          : offerData?.setup?.options?.[type]?.options?.icon;

      const defaultIconOfferData =
        type === 'icon'
          ? defaultOfferData?.setup?.options?.icon
          : defaultOfferData?.setup?.options?.[type]?.options?.icon;

      const defaultIconData = {
        ...defaultIconOfferData,
        color: defaultPresetSetup?.options?.icon?.color,
      };

      const updateIconComponent = (data: ProductBannerIconPresetDto) => {
        const newOfferData =
          type === 'icon'
            ? { ...iconOfferData, iconId: data.iconId }
            : {
                ...offerData.setup?.options?.[type],
                options: {
                  ...offerData.setup?.options?.[type]?.options,
                  icon: { ...iconOfferData, iconId: data.iconId },
                },
              };

        const newPresetData = {
          ...widgetPreset,
          options: {
            ...widgetPreset?.options,
            icon: { ...widgetPreset?.options?.icon, color: data.color },
          },
        };

        updateOfferComponent(
          type,
          newOfferData,
          offerData?.offerId as string,
          newPresetData
        );
      };
      return (
        <ProductBannerIcon
          icon={iconOfferData || {}}
          defaultIcon={defaultIconData || {}}
          shopDefaultIcon={shopDefaultData.icon || {}}
          presetSetup={widgetPreset?.options?.icon || {}}
          handleLeftMenuSelection={handleLeftMenuSelection}
          onShowHide={
            iconOfferData?.canBeHidden
              ? () =>
                  handleComponentVisability(
                    offerData,
                    'icon',
                    type === 'icon' ? 'default' : type
                  )
              : undefined
          }
          setIcon={updateIconComponent}
        />
      );
    },
    [handleLeftMenuSelection, updateOfferComponent, widgetPreset]
  );

  const getMessageComponent = useCallback(
    (
      parentName: string,
      offerData: OfferWidgetSetupDtoProductBannerOfferDtoRead,
      defaultOfferData: OfferWidgetSetupDtoProductBannerOfferDtoRead
    ) => {
      const type =
        parentName === 'entitled'
          ? 'entitled'
          : parentName === 'prerequisite'
          ? 'prerequisite'
          : 'message';

      const messageOfferData =
        type === 'message'
          ? offerData?.setup?.options?.message
          : offerData?.setup?.options?.[type]?.options?.message;

      const defaultMessageOfferData =
        type === 'message'
          ? defaultOfferData?.setup?.options?.message
          : defaultOfferData?.setup?.options?.[type]?.options?.message;

      const defaultMessageData = {
        ...defaultMessageOfferData,
        style: defaultPresetSetup?.options?.message?.style,
      };

      const updateMessageComponent = (data: ProductBannerMessageOfferDto) => {
        const newOfferData =
          type === 'message'
            ? { ...messageOfferData, message: data.message }
            : {
                ...offerData.setup?.options?.[type],
                options: {
                  ...offerData.setup?.options?.[type]?.options,
                  message: { ...messageOfferData, message: data.message },
                },
              };

        const newPresetData = {
          ...widgetPreset,
          options: {
            ...widgetPreset?.options,
            message: { ...widgetPreset?.options?.message, style: data.style },
          },
        };

        updateOfferComponent(
          type,
          newOfferData,
          offerData?.offerId as string,
          newPresetData
        );
      };

      return (
        <ProductBannerMessage
          key={offerData?.offerId + type}
          offerId={offerData?.offerId || ''}
          offerType={type}
          presetSetup={widgetPreset?.options?.message || {}}
          message={messageOfferData || {}}
          defaultMessage={defaultMessageData}
          shopDefaultMessage={shopDefaultData.textStyle || {}}
          defaultLanguage={editPromotionState.offers?.defaultLanguage || ''}
          handleLeftMenuSelection={handleLeftMenuSelection}
          setMessage={updateMessageComponent}
          onShowHide={
            messageOfferData?.canBeHidden
              ? () =>
                  handleComponentVisability(
                    offerData,
                    'message',
                    type === 'message' ? 'default' : type
                  )
              : undefined
          }
        />
      );
    },
    [handleLeftMenuSelection, updateOfferComponent, widgetPreset]
  );

  const productBannerOptions = useMemo(() => {
    const offerData = offersData?.find(
      (offer) => offer?.offerId === selectedOptionPath?.selectedOption?.offerId
    ) as OfferWidgetSetupDtoProductBannerOfferDtoRead;

    const defaultOfferData = defaultOffersData?.find(
      (offer) => offer?.offerId === selectedOptionPath?.selectedOption?.offerId
    ) as OfferWidgetSetupDtoProductBannerOfferDtoRead;

    const selectedOption = getLastSelectedOption(selectedOptionPath);
    const evspType =
      selectedOption?.name === WidgetListOptionsDtoEnum.ENTITLED
        ? 'entitled'
        : 'prerequisite';
    const updateEVSP = (
      type: 'prerequisite' | 'entitled',
      data: ProductBannerGeneralOfferDto
    ) => {
      updateOfferComponent(
        type,
        {
          ...offerData.setup?.options?.[type],
          general: {
            ...data,
            background: undefined,
            cornerRadius: undefined,
          },
        },
        offerData?.offerId as string,
        {
          ...widgetPreset,
          general: {
            ...widgetPreset?.general,
            background: data.background,
            cornerRadius: data.cornerRadius,
          },
        }
      );
    };

    switch (true) {
      case selectedOption?.name === WidgetListTypeDtoEnum.PRODUCT_BANNER:
        return (
          <ProductBannerGeneral
            general={productBanner?.general || {}}
            defaultGeneral={defaultProductBanner?.general || {}}
            shopDefaultGeneral={shopDefaultData}
            setGeneral={(data) => updateProductBanner('general', data)}
            onShowHide={
              productBanner?.canBeHidden
                ? () =>
                    updateProductBanner('isHidden', !productBanner?.isHidden)
                : undefined
            }
            onRemove={() =>
              filterSupportedWidgets(WidgetTypeDtoEnum.PRODUCT_BANNER)
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case !!selectedOption?.offerId:
        return (
          <ProductBannerOffer
            offer={offerData.setup || {}}
            presetSetup={widgetPreset?.general || {}}
            defaultOffer={defaultOfferData?.setup || {}}
            shopDefaultOffer={shopDefaultData}
            defaultPresetSetup={defaultPresetSetup?.general || {}}
            offerName={offerData.title || ''}
            handleLeftMenuSelection={handleLeftMenuSelection}
            onShowHide={
              offerData.canBeHidden
                ? () =>
                    updateOffer(
                      { ...offerData, isHidden: !offerData?.isHidden },
                      offerData?.offerId as string
                    )
                : undefined
            }
            setOffer={(data) =>
              updateOffer(
                { ...offerData, setup: data },
                offerData?.offerId as string
              )
            }
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.ENTITLED ||
        selectedOption?.name === WidgetListOptionsDtoEnum.PREREQUISITE:
        return (
          <ProductBannerEVSP
            general={offerData.setup?.options?.[evspType]?.general || {}}
            presetSetup={widgetPreset?.general || {}}
            defaultGeneral={
              defaultOfferData.setup?.options?.[evspType]?.general || {}
            }
            shopDefaultGeneral={shopDefaultData}
            title={i18n.translate(evspType) + offerData.title}
            setGeneral={(data) => updateEVSP(evspType, data)}
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.ICON:
        return getIconComponent(
          selectedOption?.parentName || '',
          offerData,
          defaultOfferData
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.IMAGE:
        return getImageComponent(
          selectedOption?.parentName || '',
          offerData,
          defaultOfferData
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.MESSAGE:
        return getMessageComponent(
          selectedOption?.parentName || '',
          offerData,
          defaultOfferData
        );
    }
  }, [
    productBanner,
    defaultProductBanner,
    offersData,
    defaultOffersData,
    selectedOptionPath,
    widgetPreset,
    updateProductBanner,
    updateOfferComponent,
    handleLeftMenuSelection,
    updateOffer,
    handleComponentVisability,
    getIconComponent,
    getImageComponent,
    getMessageComponent,
  ]);

  return <div className='PromotionProductBanner'>{productBannerOptions}</div>;
};

export default PromotionProductBanner;
