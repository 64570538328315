import React, { useCallback, useState } from 'react';
import {
  Badge,
  BlockStack,
  Box,
  Card,
  Collapsible,
  Icon,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { ChevronDownIcon, ChevronUpIcon } from '@shopify/polaris-icons';
import './FeatureListDescription.scss';
import { PlanFeatureDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';

type FeatureListDescriptionProps = {
  features: PlanFeatureDtoEnum[];
};
export const FeatureListDescription: React.FC<FeatureListDescriptionProps> = ({
  features,
}) => {
  const [i18n] = useI18n();
  const [featureOpened, setFeatureOpened] = useState<string[]>([]);

  const handleFeatureOpened = useCallback(
    (feature: string) =>
      setFeatureOpened((prev) =>
        prev.includes(feature)
          ? prev.filter((item) => item !== feature)
          : [...prev, feature]
      ),
    []
  );

  return (
    <div className='FeatureListDescription'>
      <Card padding='0'>
        {features.map((feature, index) => {
          const isOpen = featureOpened.includes(feature);
          return (
            <div
              key={feature}
              className='FeatureRow'
              onClick={() => handleFeatureOpened(feature)}
            >
              <Box
                borderBlockEndWidth={
                  index + 1 === features.length ? '0' : '025'
                }
                borderColor='border'
                paddingBlock='300'
                paddingInline='400'
              >
                <BlockStack gap='400'>
                  <InlineStack align='space-between' blockAlign='center'>
                    <InlineStack gap='100'>
                      <Text as='p' fontWeight='medium'>
                        {i18n.translate(`${feature}_Title`)}
                      </Text>
                      <Badge tone='attention'>
                        {feature === PlanFeatureDtoEnum.MULTI_LANGUAGE ||
                        feature === PlanFeatureDtoEnum.API_ACCESS
                          ? 'Plus'
                          : 'Pro'}
                      </Badge>
                    </InlineStack>
                    <Box>
                      <Icon source={isOpen ? ChevronUpIcon : ChevronDownIcon} />
                    </Box>
                  </InlineStack>
                  <Collapsible open={isOpen} id={feature}>
                    <Text as='p' tone='subdued'>
                      {i18n.translate(`${feature}_Subtitle`)}
                    </Text>
                  </Collapsible>
                </BlockStack>
              </Box>
            </div>
          );
        })}
      </Card>
    </div>
  );
};
