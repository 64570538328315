import React from 'react';
import { Button, LegacyCard, Link } from '@shopify/polaris';
import styles from './Update.module.scss';
import {
  checkIconLookup,
  linkIconLookup,
} from 'core/components/iconsDefinitions';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useI18n } from '@shopify/react-i18n';

const Update: React.FC = () => {
  const checkIconDef = findIconDefinition(checkIconLookup);
  const linkIconDef = findIconDefinition(linkIconLookup);
  const [i18n] = useI18n();

  return (
    <LegacyCard sectioned>
      <section className={styles.updateSection}>
        <div>
          <p className={styles.status}>
            <FontAwesomeIcon icon={checkIconDef} />{' '}
            {i18n.translate('Update.statusTitle')}
          </p>
          <p>
            {i18n.translate('Update.statusDescription')}{' '}
            <Link>
              <FontAwesomeIcon icon={linkIconDef} />{' '}
              {i18n.translate('Update.statusDescriptionLink')}
            </Link>
          </p>
        </div>
        <Button>{i18n.translate('Update.button')}</Button>
      </section>
    </LegacyCard>
  );
};
export default Update;
