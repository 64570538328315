import React, { useCallback } from 'react';
import { BorderStyle, Image, CornerRadius } from '../../../../../components';
import {
  AnnouncementBarImageOfferDto,
  AnnouncementBarImagePresetDto,
  AnnouncementBarImageSystemDefaultsDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { isEqual } from 'lodash';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';

type AnnouncementBarImageProps = {
  image: AnnouncementBarImageOfferDto;
  defaultImage: AnnouncementBarImageOfferDto;
  shopDefaultImage: AnnouncementBarImageSystemDefaultsDto;
  isPresetEditor?: boolean;
  presetSetup?: AnnouncementBarImagePresetDto;
  defaultPresetSetup?: AnnouncementBarImagePresetDto;
  setImage: (data: AnnouncementBarImageOfferDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const AnnouncementBarImage: React.FC<AnnouncementBarImageProps> = ({
  image,
  defaultImage,
  shopDefaultImage,
  isPresetEditor,
  presetSetup,
  defaultPresetSetup,
  setImage,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const updateImageState = useCallback(
    (
      field: keyof AnnouncementBarImageOfferDto,
      data: AnnouncementBarImageOfferDto[keyof AnnouncementBarImageOfferDto]
    ) => {
      setImage({
        ...image,
        ...(!isPresetEditor && {
          ...presetSetup,
          image: { ...presetSetup?.image, imageId: image.image?.imageId },
        }),
        [field]: data,
      });
    },
    [setImage, image]
  );

  const resetAllToSystemDefault = useCallback(() => {
    setImage({
      ...image,
      border: shopDefaultImage.border,
      cornerRadius: shopDefaultImage.cornerRadius,
      image: {
        fit: shopDefaultImage.image?.fit,
        size: shopDefaultImage.image?.size,
        imageId: !isPresetEditor ? shopDefaultImage.image?.imageId : undefined,
      },
    });
  }, [isPresetEditor, shopDefaultImage, image]);

  const onDiscardChanges = useCallback(() => {
    setImage(
      !isPresetEditor
        ? {
            ...defaultImage,
            border: defaultPresetSetup?.border,
            cornerRadius: defaultPresetSetup?.cornerRadius,
            image: {
              ...defaultPresetSetup?.image,
              imageId: defaultImage.image?.imageId,
            },
          }
        : defaultImage
    );
  }, [isPresetEditor, defaultPresetSetup, defaultImage]);

  return (
    <div className='AnnouncementBarImage'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={onDiscardChanges}
        onResetToSystem={resetAllToSystemDefault}
        name={WidgetListOptionsDtoEnum.IMAGE}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <Image
          groupKey='OFFER'
          offersNote={isPresetEditor}
          onSystemReset={() =>
            updateImageState('image', {
              fit: shopDefaultImage.image?.fit,
              size: shopDefaultImage.image?.size,
              imageId: !isPresetEditor
                ? shopDefaultImage.image?.imageId
                : undefined,
            })
          }
          showImage={!isPresetEditor}
          image={
            !isPresetEditor
              ? { ...presetSetup?.image, imageId: image.image?.imageId }
              : image.image || {}
          }
          setImage={(value) => updateImageState('image', value)}
          inherited={
            !isPresetEditor
              ? isEqual(presetSetup?.image, defaultPresetSetup?.image)
              : undefined
          }
        />
      </div>
      <div className='RightSideSection'>
        <CornerRadius
          onSystemReset={() =>
            updateImageState('cornerRadius', shopDefaultImage.cornerRadius)
          }
          cornerRadius={
            (!isPresetEditor
              ? presetSetup?.cornerRadius
              : image.cornerRadius) || {}
          }
          setCornerRadius={(value) => updateImageState('cornerRadius', value)}
          inherited={
            !isPresetEditor
              ? isEqual(
                  presetSetup?.cornerRadius,
                  defaultPresetSetup?.cornerRadius
                )
              : undefined
          }
        />
      </div>
      <div className='RightSideSection'>
        <BorderStyle
          border={(!isPresetEditor ? presetSetup?.border : image.border) || {}}
          disableStyle
          disableSides
          inherited={
            !isPresetEditor
              ? isEqual(presetSetup?.border, defaultPresetSetup?.border)
              : undefined
          }
          setBorder={(value) => updateImageState('border', value)}
          onSystemReset={() =>
            updateImageState('border', shopDefaultImage.border)
          }
        />
      </div>
    </div>
  );
};

export default AnnouncementBarImage;
