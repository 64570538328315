import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './PromotionSummary.scss';
import { useI18n } from '@shopify/react-i18n';
import { DeviceTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import {
  WidgetsFetchTypeEnum,
  useConfigureWidgets,
} from 'features/settings/hooks/useConfigureWidgets';
import { useAppSelector } from 'core/hooks';
import {
  getCurrentMessage,
  parseSmartTagCode,
} from 'features/settings/components/EditPresetSkeleton/utils/utils';
import { PreviewEntry } from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components';
import { formatStringQuotes } from '../../utils/utils';

type PromotionSummaryProps = {
  data: any;
  previewType: DeviceTypeDtoEnum;
  defaultLanguage?: string;
  adminActivePath: string;
  getSelectionFromThePreview(path: string): void;
  isDrawerCart: boolean;
  root: string;
};

export const PromotionSummary: React.FC<PromotionSummaryProps> = ({
  previewType,
  defaultLanguage,
  data,
  adminActivePath,
  getSelectionFromThePreview,
  isDrawerCart,
  root,
}) => {
  const [i18n] = useI18n();
  const { fontListData } = useConfigureWidgets(
    WidgetsFetchTypeEnum.PRESET_FONT_LIST
  );

  const {
    defaultIconLibary,
    defaultImageLibary,
    customIconLibary,
    customImageLibary,
    promotionSummaryPageEntries,
  } = useAppSelector((state) => state.widgets);
  const { smartTagsCodes } = useAppSelector((state) => state.offersWizard);

  const [triggerRenderCount, setTriggerRenderCount] = useState<number>(0);
  const [injectStyles, setInjectStyles] = useState<boolean>(false);

  const imagesArray = useMemo(
    () => [...defaultImageLibary, ...customImageLibary],
    [customImageLibary, defaultImageLibary]
  );
  const iconsArray = useMemo(
    () => [...defaultIconLibary, ...customIconLibary],
    [customIconLibary, defaultIconLibary]
  );

  const assetsArray = useMemo(
    () =>
      [...iconsArray, ...imagesArray].map((item) => ({
        id: item.id,
        url: item.url,
      })),
    [iconsArray, imagesArray]
  );

  const currentDisclaimer = useMemo(
    () =>
      isDrawerCart
        ? parseSmartTagCode(
            promotionSummaryPageEntries.drawerCartPromotionSummary
              ?.disclaimerText[
              previewType.toLowerCase() as keyof PreviewEntry
            ] || '',
            smartTagsCodes
          ) ||
          getCurrentMessage(
            data.options.disclaimer.text.value.message.entries,
            defaultLanguage,
            smartTagsCodes
          )
        : parseSmartTagCode(
            promotionSummaryPageEntries.cartPromotionSummary?.disclaimerText[
              previewType.toLowerCase() as keyof PreviewEntry
            ] || '',
            smartTagsCodes
          ) ||
          getCurrentMessage(
            data.options.disclaimer.text.value.message.entries,
            defaultLanguage,
            smartTagsCodes
          ),
    [
      promotionSummaryPageEntries,
      previewType,
      isDrawerCart,
      smartTagsCodes,
      defaultLanguage,
      data.options.disclaimer.text.value.message.entries,
    ]
  );

  const currentDiscounts = useMemo(
    () =>
      data?.offers
        ? data.offers
            .map(
              (item: any, idx: number) =>
                `<la-dn-promotion-summary-discount key="${
                  item.offerId
                }" label="${formatStringQuotes(
                  getCurrentMessage(
                    item.setup.general.label.message.entries,
                    defaultLanguage,
                    smartTagsCodes
                  )
                )}" token="${item.offerId}" index="${idx}">
              
          </la-dn-promotion-summary-discount>`
            )
            .join('')
        : `<la-dn-promotion-summary-discount label="${formatStringQuotes(
            getCurrentMessage(
              data.options.discount.label.entries,
              defaultLanguage,
              smartTagsCodes
            ) || i18n.translate('example')
          )}" token="example" index="0"></la-dn-promotion-summary-discount>`,
    [
      i18n,
      data.offers,
      defaultLanguage,
      data.options.discount.label.entries,
      smartTagsCodes,
    ]
  );

  const currentTotal = useMemo(
    () =>
      getCurrentMessage(
        data.options.total.label.message.entries,
        defaultLanguage,
        smartTagsCodes
      ),
    [data.options.total.label.message.entries, defaultLanguage, smartTagsCodes]
  );
  const currentSubTotal = useMemo(
    () =>
      getCurrentMessage(
        data.options.subtotal.label.entries,
        defaultLanguage,
        smartTagsCodes
      ),
    [data.options.subtotal.label.entries, defaultLanguage, smartTagsCodes]
  );
  const currentTaxes = useMemo(
    () =>
      getCurrentMessage(
        data.options.taxesAndDuties.label.entries,
        defaultLanguage,
        smartTagsCodes
      ),
    [data.options.taxesAndDuties.label.entries, defaultLanguage, smartTagsCodes]
  );
  const currentDescription = useMemo(
    () =>
      getCurrentMessage(
        data.options.taxesAndDuties.description.message.entries,
        defaultLanguage,
        smartTagsCodes
      ),
    [smartTagsCodes, data.options.taxesAndDuties.description.message.entries]
  );

  const currentShipping = useMemo(
    () =>
      getCurrentMessage(
        data.options.shipping.label.entries,
        defaultLanguage,
        smartTagsCodes
      ),
    [data.options.shipping.label.entries, defaultLanguage, smartTagsCodes]
  );

  const elementToReplaceWith = useMemo(
    () =>
      `
            <la-dn-promotion-summary
    admin-mode="true"
    admin-active-path="${adminActivePath}"
    admin-mode-root="${root}"
    admin-mode-label='[{"component":"${root}","label":"${i18n.translate(
        'promotionSummary'
      )}","icon":"widget","subComponents":[{"component":"discount","label":"${i18n.translate(
        'discount'
      )}","icon":"component","subComponents":[{"component":"banner","label":"${i18n.translate(
        'offer'
      )}","icon":"offer"}]},{"component":"total","label":"${i18n.translate(
        'total'
      )}","icon":"component"},{"component":"subtotal","label":"${i18n.translate(
        'subtotal'
      )}","icon":"component"},{"component":"shipping","label":"${i18n.translate(
        'shipping'
      )}","icon":"component"},{"component":"taxesAndDuties","label":"${i18n.translate(
        'taxes'
      )}","icon":"component"},{"component":"disclaimer","label":"${i18n.translate(
        'disclaimer'
      )}","icon":"component"}]}]'
    collapsed="false"
  >
    <la-dn-promotion-summary-total label="${formatStringQuotes(currentTotal)}">
      <la-dn-price
        location="product-page"
        product-handle="product-1"
        offer-token="ABCD"
        variant-id="123456"
        available="true"
        currency="USD"
        layout="[{{price}}]"
        price="76000"
      ></la-dn-price>
    </la-dn-promotion-summary-total>
    
    <la-dn-promotion-summary-breakdown>
      <la-dn-promotion-summary-subtotal label="${formatStringQuotes(
        currentSubTotal
      )}">
        <la-dn-price
          location="product-page"
          product-handle="product-1"
          offer-token="ABCD"
          variant-id="123456"
          available="true"
          currency="USD"
          layout="[{{price}}]"
          price="81000"
        ></la-dn-price>
      </la-dn-promotion-summary-subtotal>

      <la-dn-promotion-summary-shipping label="${formatStringQuotes(
        currentShipping
      )}"></la-dn-promotion-summary-shipping>

      <la-dn-promotion-summary-taxes label="${formatStringQuotes(
        currentTaxes
      )}">
      ${currentDescription}
      </la-dn-promotion-summary-taxes>
    </la-dn-promotion-summary-breakdown>

    <la-dn-promotion-summary-discounts>
      ${currentDiscounts}
    </la-dn-promotion-summary-discounts>

    <la-dn-promotion-summary-disclaimer>
      ${currentDisclaimer}
    </la-dn-promotion-summary-disclaimer>
  </la-dn-promotion-summary>
            `,
    [
      adminActivePath,
      i18n,
      currentDisclaimer,
      currentDiscounts,
      currentTotal,
      currentSubTotal,
      currentTaxes,
      currentShipping,
      currentDescription,
      root,
    ]
  );

  const showPromotionSummary = useCallback(() => {
    const parser = new DOMParser();
    const container = document.querySelector('#promotion-summary-root');

    const promotionSummary = parser
      .parseFromString(elementToReplaceWith, 'text/html')
      .querySelector('la-dn-promotion-summary');

    setTriggerRenderCount((prev) => prev + 1);

    if (
      container &&
      promotionSummary &&
      !container.querySelector('la-dn-promotion-summary')
    ) {
      container?.appendChild(promotionSummary);
    } else if (
      container &&
      promotionSummary &&
      container.querySelector('la-dn-promotion-summary')
    ) {
      const currentPromotionSummary = container.querySelector(
        'la-dn-promotion-summary'
      );

      if (currentPromotionSummary) {
        const disclaimer = currentPromotionSummary.querySelector(
          'la-dn-promotion-summary-disclaimer'
        );
        const subtotal = currentPromotionSummary.querySelector(
          'la-dn-promotion-summary-subtotal'
        );
        const total = currentPromotionSummary.querySelector(
          'la-dn-promotion-summary-total'
        );
        const taxes = currentPromotionSummary.querySelector(
          'la-dn-promotion-summary-taxes'
        );
        const shipping = currentPromotionSummary.querySelector(
          'la-dn-promotion-summary-shipping'
        );
        const discounts = currentPromotionSummary.querySelector(
          'la-dn-promotion-summary-discounts'
        );
        const discountArr = currentPromotionSummary.querySelectorAll(
          'la-dn-promotion-summary-discount'
        );
        if (discountArr.length > 0) {
          const newDiscounts = promotionSummary.querySelectorAll(
            'la-dn-promotion-summary-discount'
          );
          discountArr.forEach((discount, index) => {
            if (newDiscounts[index]) {
              discount.setAttribute(
                'label',
                newDiscounts[index].getAttribute('label') || ''
              );
            }
          });
        }
        if (disclaimer) {
          disclaimer.innerHTML =
            promotionSummary.querySelector('la-dn-promotion-summary-disclaimer')
              ?.innerHTML || '';
        }
        if (subtotal) {
          subtotal?.setAttribute(
            'label',
            promotionSummary
              .querySelector('la-dn-promotion-summary-subtotal')
              ?.getAttribute('label') || ''
          );
        }
        if (total) {
          total?.setAttribute(
            'label',
            promotionSummary
              .querySelector('la-dn-promotion-summary-total')
              ?.getAttribute('label') || ''
          );
        }
        if (taxes) {
          taxes.innerHTML =
            promotionSummary.querySelector('la-dn-promotion-summary-taxes')
              ?.innerHTML || '';
          taxes?.setAttribute(
            'label',
            promotionSummary
              .querySelector('la-dn-promotion-summary-taxes')
              ?.getAttribute('label') || ''
          );
        }
        if (shipping) {
          shipping?.setAttribute(
            'label',
            promotionSummary
              .querySelector('la-dn-promotion-summary-shipping')
              ?.getAttribute('label') || ''
          );
        }
        if (discounts) {
          discounts?.setAttribute(
            'label',
            promotionSummary
              .querySelector('la-dn-promotion-summary-discounts')
              ?.getAttribute('label') || ''
          );
        }
      }
    }
  }, [elementToReplaceWith, setTriggerRenderCount]);

  useEffect(() => {
    if (triggerRenderCount) {
      document.dispatchEvent(new CustomEvent('la:dn:promotion-summary:render'));
      setInjectStyles(true);
    }
  }, [triggerRenderCount]);

  useEffect(() => {
    if (injectStyles && data) {
      const promotionSummary = document.querySelector(
        'la-dn-promotion-summary'
      );
      promotionSummary?.setAttribute('styles', JSON.stringify(data));
    }
  }, [data, injectStyles]);

  useEffect(() => {
    if (injectStyles) {
      const promotionSummary = document.querySelector(
        'la-dn-promotion-summary'
      );
      promotionSummary?.setAttribute(
        'force-mobile',
        (previewType === DeviceTypeDtoEnum.MOBILE).toString()
      );
    }
  }, [injectStyles, previewType]);

  useEffect(() => {
    if (injectStyles) {
      const promotionSummary = document.querySelector(
        'la-dn-promotion-summary'
      );
      promotionSummary?.setAttribute('admin-active-path', adminActivePath);
    }
  }, [adminActivePath]);

  useEffect(() => {
    if (injectStyles) {
      const promotionSummary = document.querySelector(
        'la-dn-promotion-summary'
      );
      promotionSummary?.setAttribute(
        'font-assets',
        JSON.stringify(fontListData)
      );
    }
  }, [fontListData, injectStyles]);

  useEffect(() => {
    if (injectStyles) {
      const promotionSummary = document.querySelector(
        'la-dn-promotion-summary'
      );
      promotionSummary?.setAttribute(
        'image-assets',
        JSON.stringify(assetsArray)
      );
    }
  }, [assetsArray, injectStyles]);

  useEffect(() => {
    showPromotionSummary();
    document
      .querySelector('la-dn-promotion-summary')
      ?.addEventListener('la:dn:promotion-summary:admin-change', (e) =>
        getSelectionFromThePreview((e as CustomEvent).detail)
      );
  }, [
    currentDisclaimer,
    currentDiscounts,
    currentTotal,
    currentSubTotal,
    currentTaxes,
    currentShipping,
    currentDescription,
  ]);

  return <div id='promotion-summary-root'></div>;
};
