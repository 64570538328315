import React, { useMemo } from 'react';
import './OverlaySettings.scss';
import { useI18n } from '@shopify/react-i18n';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import { Checkbox } from '@shopify/polaris';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import { OverlayComponentDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { AnimationDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';

type OverlaySettingsProps = {
  overlay: OverlayComponentDto;
  disabled?: boolean;
  openOptions?: AnimationDtoEnum[];
  closeOptions?: AnimationDtoEnum[];
  setOverlay?: (data: OverlayComponentDto) => void;
  onSystemReset?: () => void;
};

export const OverlaySettings: React.FC<OverlaySettingsProps> = ({
  overlay,
  disabled,
  openOptions,
  closeOptions,
  setOverlay,
  onSystemReset,
}) => {
  const [i18n] = useI18n();

  const openAnimationOptions = useMemo(
    () =>
      (openOptions ? openOptions : Object.values(AnimationDtoEnum)).map(
        (value) => {
          return {
            value: value,
            label: i18n.translate(`${value}`),
          };
        }
      ),
    [openOptions]
  );
  const closeAnimationOptions = useMemo(
    () =>
      (closeOptions ? closeOptions : Object.values(AnimationDtoEnum)).map(
        (value) => {
          return {
            value: value,
            label: i18n.translate(`${value}`),
          };
        }
      ),
    [closeOptions]
  );

  return (
    <div className='OverlaySettings'>
      <StyleComponentHeader
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            disabled: disabled,
            onAction: () => onSystemReset?.(),
          },
        ]}
        label={i18n.translate(`OverlaySettings`)}
      >
        <div className='OverlayRow'>
          {i18n.translate(`Opening`)}
          <div className='RightContent'>
            <SelectOptions
              options={openAnimationOptions}
              onOptionSelect={(value) =>
                setOverlay?.({ ...overlay, opening: value as AnimationDtoEnum })
              }
              selectedOption={overlay.opening || AnimationDtoEnum.NONE}
              disabled={disabled}
              label=''
            />
          </div>
        </div>
        <div className='OverlayRow'>
          {i18n.translate(`Closing`)}
          <div className='RightContent'>
            <SelectOptions
              options={closeAnimationOptions}
              onOptionSelect={(value) =>
                setOverlay?.({ ...overlay, closing: value as AnimationDtoEnum })
              }
              selectedOption={overlay.closing || AnimationDtoEnum.NONE}
              disabled={disabled}
              label=''
            />
          </div>
        </div>
        <Checkbox
          checked={!!overlay.background?.enabled}
          label={i18n.translate('EnableBackground')}
          onChange={(value) =>
            setOverlay?.({
              ...overlay,
              background: {
                ...overlay.background,
                enabled: value,
              },
            })
          }
          disabled={disabled}
        />
        <Checkbox
          checked={!!overlay.closeOnOutsideClick}
          label={i18n.translate('CloseOnOutsideClick')}
          onChange={(value) =>
            setOverlay?.({
              ...overlay,
              closeOnOutsideClick: value,
            })
          }
          disabled={disabled}
        />
      </StyleComponentHeader>
    </div>
  );
};
