import React, { useCallback, useEffect, useState } from 'react';
import {
  Text,
  BlockStack,
  Card,
  Badge,
  Icon,
  InlineStack,
  Page,
  Button,
  Link,
  Banner,
} from '@shopify/polaris';
import { ThemeIcon, ChevronRightIcon } from '@shopify/polaris-icons';
import { Element } from 'react-scroll';
import './StrikethroughPricing.scss';
import { useI18n } from '@shopify/react-i18n';
import { useDispatch } from 'react-redux';
import { StrikethroughPricingGeneralWidgetShopSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';
import {
  setIsUnsavedChanges,
  setOnDiscardChanges,
  setOnSaveChanges,
} from 'core/store/settingsSlice';
import {
  PageTypeDtoEnum,
  WidgetStatusDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import {
  InstallmentsSetting,
  PageDisplaySetting,
  StyleSetting,
} from '../ManageWidgets/components/WidgetSettings/components';
import { SettingsSkeleton } from '../components/SettingsSkeleton/SettingsSkeleton';

export const StrikethroughPricing: React.FC = () => {
  const [i18n] = useI18n();
  const dispatch = useDispatch();

  const {
    strikethroughPricingData,
    strikethroughPricingIsFetching,
    saveStrikethroughPricing,
  } = useConfigureSettings(SettingsFetchTypeEnum.STRIKE_THROUGH_PRICING);

  const [searchParams, setSearchParams] = useSearchParams();
  const [savedData, setSavedData] =
    useState<StrikethroughPricingGeneralWidgetShopSettingsDto>({});
  const [strikethroughState, setStrikethroughState] =
    useState<StrikethroughPricingGeneralWidgetShopSettingsDto>({});
  const onWidgetClick = useCallback(
    (widgetPath: string) => {
      searchParams.set('subPath', widgetPath);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const isDataLoaded =
    !isEmpty(strikethroughState) &&
    strikethroughPricingData &&
    !strikethroughPricingIsFetching;

  useEffect(() => {
    if (
      !isEmpty(strikethroughState) &&
      !isEqual(savedData, strikethroughState)
    ) {
      dispatch(setIsUnsavedChanges(true));
      dispatch(
        setOnDiscardChanges(
          () => !isEmpty(savedData) && setStrikethroughState(savedData)
        )
      );
      dispatch(
        setOnSaveChanges(() => {
          setSavedData(strikethroughState);
          return saveStrikethroughPricing(strikethroughState || {});
        })
      );
    } else if (isEqual(savedData, strikethroughState)) {
      dispatch(setIsUnsavedChanges(false));
    }
  }, [strikethroughState, savedData, dispatch]);

  useEffect(() => {
    if (!isEmpty(strikethroughPricingData?.widgetSettings)) {
      setStrikethroughState(strikethroughPricingData?.widgetSettings || {});
      setSavedData(strikethroughPricingData?.widgetSettings || {});
    }
  }, [strikethroughPricingData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  return (
    <div className='StrikethroughPricing'>
      {isDataLoaded ? (
        <Page title={i18n.translate(`StrikethroughPricing`)}>
          <BlockStack gap='600'>
            <Element name='StyleSetting'>
              <StyleSetting styled />
            </Element>
            <Element name='Widgets'>
              <Card roundedAbove='sm'>
                <BlockStack gap='400'>
                  <BlockStack gap='100'>
                    <Text as='h2' variant='headingSm'>
                      {i18n.translate(`Widgets`)}
                    </Text>
                    <Text as='p' tone='subdued'>
                      {i18n.translate(`BoostPromotion`)}
                    </Text>
                  </BlockStack>
                  <div className='WidgetListBox'>
                    {strikethroughPricingData?.widgets?.map(
                      (widget, index) => {
                        const isEnabled =
                          widget.status === WidgetStatusDtoEnum.ENABLED ||
                          widget.status ===
                          WidgetStatusDtoEnum.ENABLED_VIA_CODE ||
                          widget.status ===
                          WidgetStatusDtoEnum.ENABLED_VIA_CSS;
                        const convertWidgetPages = (
                          arr: PageTypeDtoEnum[]
                        ) => {
                          const formattedArray = arr.map((item) => {
                            return i18n.translate(`${item}_PAGE`);
                          });
                          return formattedArray.join(' & ');
                        };
                        return (
                          <div
                            key={widget.type}
                            className={classNames('WidgetListItem', {
                              IsLastItem:
                                index + 1 ===
                                strikethroughPricingData?.widgets?.length,
                            })}
                            onClick={() =>
                              onWidgetClick(
                                i18n.translate(`${widget.type}_LINK`)
                              )
                            }
                          >
                            <InlineStack gap={'300'} blockAlign='center'>
                              <Icon tone='base' source={ThemeIcon} />
                              <BlockStack gap='100'>
                                <Text as='p'>
                                  {i18n.translate(`${widget.type}`)}{' '}
                                  <Badge
                                    tone={isEnabled ? 'success' : undefined}
                                  >
                                    {i18n.translate(isEnabled ? 'On' : 'Off')}
                                  </Badge>
                                  {widget.isPremium && (
                                    <Badge tone='attention'>
                                      {i18n.translate('Premium')}
                                    </Badge>
                                  )}
                                </Text>
                                <Text tone='subdued' as='p'>
                                  {i18n.translate(
                                    `${widget.installationMode}`
                                  )}
                                  •{' '}
                                  {convertWidgetPages(
                                    widget.pages as PageTypeDtoEnum[]
                                  )}
                                </Text>
                              </BlockStack>
                            </InlineStack>
                            <InlineStack blockAlign='center' gap='300'>
                              {widget.status ===
                                WidgetStatusDtoEnum.LOCKED && (
                                  <Button variant='primary'>
                                    {i18n.translate('GetTheFeature')}
                                  </Button>
                                )}
                              <div className='NavigateArrow'>
                                <Icon tone='base' source={ChevronRightIcon} />
                              </div>
                            </InlineStack>
                          </div>
                        );
                      }
                    )}
                  </div>
                </BlockStack>
              </Card>
            </Element>
            <Element name='StyleSetting'>
              <Card>
                <BlockStack gap='500'>
                  <InlineStack
                    align='space-between'
                    wrap={false}
                    blockAlign='center'
                  >
                    <BlockStack gap='100'>
                      <Text as='h2' fontWeight='bold'>
                        {i18n.translate('TestStrikethroughPricing')}
                      </Text>
                      <Text as='p' tone='subdued'>
                        {i18n.translate('DoNotShare')}
                      </Text>
                    </BlockStack>
                    <Button
                      onClick={() =>
                        window.open(
                          strikethroughPricingData?.testUrl,
                          '_blank'
                        )
                      }
                    >
                      {i18n.translate('Test')}
                    </Button>
                  </InlineStack>
                  <Banner tone='info'>
                    {i18n.translate('MoreInfoAboutTesting')}
                    <Link> {i18n.translate('FollowingArticle')}</Link>.
                  </Banner>
                </BlockStack>
              </Card>
            </Element>
            <PageDisplaySetting
              pageDisplay={strikethroughState.pageDisplay || {}}
              setPageDisplay={(data) =>
                setStrikethroughState((prev) => ({
                  ...prev,
                  pageDisplay: data,
                }))
              }
            />
            <InstallmentsSetting
              installments={strikethroughState?.installments || {}}
              setInstallments={(data) =>
                setStrikethroughState((prev) => ({
                  ...prev,
                  installments: data,
                }))
              }
            />
          </BlockStack>
        </Page>
      ) : (
        <SettingsSkeleton />
      )}
    </div>
  );
};
