import React from 'react';
import StyleComponentHeader from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components/StyleComponentHeader/StyleComponentHeader';
import { useI18n } from '@shopify/react-i18n';
import { PromotionCodeFieldCodeInputShopSettingsDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { BlockStack, Checkbox, Link, Text } from '@shopify/polaris';
import { setToggleSettingsConfig } from 'core/store/widgetsSlice';
import { useAppDispatch } from 'core/hooks';
type PromotionCodeBehaviorProps = {
  behavior: PromotionCodeFieldCodeInputShopSettingsDto;
  disabled?: boolean;
  setBehavior?: (data: PromotionCodeFieldCodeInputShopSettingsDto) => void;
  onSystemReset?: () => void;
};
const PromotionCodeBehavior: React.FC<PromotionCodeBehaviorProps> = ({
  behavior,
  disabled,
  setBehavior,
  onSystemReset,
}) => {
  const dispatch = useAppDispatch();
  const [i18n] = useI18n();
  return (
    <div className='PromotionCodeBehavior'>
      <StyleComponentHeader
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            disabled: disabled,
            onAction: () => onSystemReset?.(),
          },
        ]}
        label={i18n.translate('PromotionCodeBehavior')}
      >
        <BlockStack gap={'100'}>
          <Checkbox
            checked={!!behavior.shopifyDiscountCodesEnabled}
            label={i18n.translate('ShopifyDiscountCodes')}
            disabled={disabled}
            onChange={(value) =>
              setBehavior?.({ ...behavior, shopifyDiscountCodesEnabled: value })
            }
          />
          <Checkbox
            checked={!!behavior.discountNinjaPromotionCodesEnabled}
            label={i18n.translate('NinjaPromotionCodes')}
            disabled={disabled}
            onChange={(value) =>
              setBehavior?.({
                ...behavior,
                discountNinjaPromotionCodesEnabled: value,
              })
            }
          />
          <Text tone='subdued' as='p'>
            {i18n.translate('HelpText')}{' '}
            <Link
              onClick={() =>
                //IMPROVE PATH IN FUTURE (for Andriy)
                dispatch(
                  setToggleSettingsConfig({
                    action: 'open',
                    path: 'promotion-code-field',
                  })
                )
              }
            >
              {i18n.translate('OpenShopSettings')}
            </Link>
            .
          </Text>
        </BlockStack>
      </StyleComponentHeader>
    </div>
  );
};

export default PromotionCodeBehavior;
