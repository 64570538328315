import {
  NinjaCartEmptyStateLevelPresetDto,
  NinjaCartEmptyStatePresetDto,
  NinjaCartEmptyStatePresetDtoRead,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { SelectedOptionPath } from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightSideBarWrapper';
import {
  WidgetListOptionsDtoEnum,
  WidgetListTypeDtoEnum,
} from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { getLastSelectedOption } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import React, { useCallback, useMemo } from 'react';
import NinjaCartEmptyGeneral from './components/NinjaCartEmptyGeneral/NinjaCartEmptyGeneral';
import { Box, Link, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { WidgetOptionHeader } from '../../../../../components';
import NinjaCartEmptyImage from './components/NinjaCartEmptyImage/NinjaCartEmptyImage';
import NinjaCartEmptyText from './components/NinjaCartEmptyText/NinjaCartEmptyText';
import NinjaCartEmptyCTA from './components/NinjaCartEmptyCTA/NinjaCartEmptyCTA';
type NinjaCartEmptyProps = {
  emptyState: NinjaCartEmptyStatePresetDtoRead;
  defaultEmptyState: NinjaCartEmptyStatePresetDtoRead;
  selectedOptionPath: SelectedOptionPath;
  defaultLanguage: string;
  shopDefaultData: NinjaCartEmptyStatePresetDto;
  setEmptyState: (data: NinjaCartEmptyStatePresetDtoRead) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

export const NinjaCartEmpty: React.FC<NinjaCartEmptyProps> = ({
  emptyState,
  defaultEmptyState,
  selectedOptionPath,
  shopDefaultData,
  defaultLanguage,
  setEmptyState,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();
  const handleUpdateOptions = useCallback(
    (
      field: keyof NinjaCartEmptyStateLevelPresetDto,
      data: NinjaCartEmptyStateLevelPresetDto[keyof NinjaCartEmptyStateLevelPresetDto]
    ) => {
      setEmptyState({
        ...emptyState,
        options: {
          ...emptyState.options,
          [field]: data,
        },
      });
    },
    [emptyState, setEmptyState]
  );
  const emptyStateOptions = useMemo(() => {
    const selectedOption = getLastSelectedOption(selectedOptionPath);
    switch (true) {
      case selectedOption?.name === WidgetListTypeDtoEnum.NINJA_CART:
        return (
          <NinjaCartEmptyGeneral
            general={emptyState.general || {}}
            defaultGeneral={defaultEmptyState.general || {}}
            shopDefaultGeneral={shopDefaultData.general || {}}
            setGeneral={(data) =>
              setEmptyState({ ...emptyState, general: data })
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.IMAGE:
        return (
          <NinjaCartEmptyImage
            image={emptyState.options?.image || {}}
            defaultImage={defaultEmptyState.options?.image || {}}
            shopDefaultImage={shopDefaultData.options?.image || {}}
            setImage={(data) => handleUpdateOptions('image', data)}
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.TEXT:
        return (
          <NinjaCartEmptyText
            text={emptyState.options?.text || {}}
            defaultText={defaultEmptyState.options?.text || {}}
            shopDefaultText={shopDefaultData.options?.text || {}}
            defaultLanguage={defaultLanguage}
            setText={(data) => handleUpdateOptions('text', data)}
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.CTA_BUTTON:
        return (
          <NinjaCartEmptyCTA
            cta={emptyState.options?.callToActionButton || {}}
            defaultCTA={defaultEmptyState.options?.callToActionButton || {}}
            shopDefaultCTA={shopDefaultData.options?.callToActionButton || {}}
            defaultLanguage={defaultLanguage}
            setCTA={(data) => handleUpdateOptions('callToActionButton', data)}
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.HEADER:
        return (
          <>
            <WidgetOptionHeader
              name={WidgetListOptionsDtoEnum.HEADER}
              handleLeftMenuSelection={handleLeftMenuSelection}
            />
            <div className='RightSideSection'>
              <Box padding='500'>
                <Text as='p' tone='subdued'>
                  {i18n.translate('NoCustomizable', {
                    defaultState: <Link>{i18n.translate('defaultState')}</Link>,
                  })}
                </Text>
              </Box>
            </div>
          </>
        );
    }
  }, [
    selectedOptionPath,
    emptyState,
    defaultEmptyState,
    handleUpdateOptions,
    handleLeftMenuSelection,
  ]);
  return <>{emptyStateOptions}</>;
};
