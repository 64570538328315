import React, { useEffect, useMemo, useRef, useState } from 'react';
import YouTube, { YouTubeProps } from 'react-youtube';
import { linkReplace, validate } from '@loomhq/loom-embed';
import './LearnMoreModal.scss';
import { Loader } from 'core/components';
import { ModalCustom } from 'core/components/ModalCustom/ModalCustom';

type LearnMoreModalProps = {
  onModalClose: () => void;
  title?: string;
  url?: string;
};

export const LearnMoreModal: React.FC<LearnMoreModalProps> = (props) => {
  const { onModalClose, title, url } = props;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [linkRef, setLinkRef] = useState<HTMLAnchorElement | null>(null);

  const youtubePattern = /youtu.be\/([^?]+)/;
  const currentUrl = useMemo(
    () => url || 'https://www.loom.com/share/cd98f3811dcf418aa0d2104b3c567421',
    [url]
  );
  const isLoom = useMemo(() => validate.isLoomUrl(currentUrl), [currentUrl]);
  const loomIframe = useMemo(
    () => (isLoom ? document.getElementsByTagName('iframe')[0] : null),
    [isLoom]
  );

  const opts: YouTubeProps['opts'] = {
    width: '100%',
    height: '100%',
    playerVars: {
      controls: 1,
      autoplay: 0,
    },
  };

  useEffect(() => {
    linkRef && linkReplace('a');
  }, [linkRef]);

  useEffect(
    () =>
      loomIframe?.addEventListener('load', () => {
        setIsLoading(false);
      }),
    [loomIframe]
  );

  return (
    <>
      <ModalCustom
        isOpen={true}
        onClose={onModalClose}
        title={title || 'Learn more about the percentage discount offer'}
        modalClass='IntroductionModal'
      >
        {isLoading && (
          <div className='loaderWrapper'>
            <Loader />
          </div>
        )}
        {isLoom ? (
          <div style={{ padding: '20px' }}>
            <a href={currentUrl} ref={(ref) => setLinkRef(ref)} />
          </div>
        ) : (
          <YouTube
            style={{ position: 'relative', height: '63.5vh', padding: '20px' }}
            videoId={currentUrl.match(youtubePattern)?.[1]}
            opts={opts}
            onReady={() => setIsLoading(false)}
          />
        )}
      </ModalCustom>
    </>
  );
};
