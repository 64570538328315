import React from 'react';
import { Tooltip } from '@shopify/polaris';
import classNames from 'classnames';
import './Switch.scss';

type SwitchProps = {
  id: string;
  label?: string;
  checked: boolean;
  labels?: {
    on: string;
    off: string;
  };
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  tooltip?: string;
};

export const Switch: React.FC<SwitchProps> = (props) => {
  const { id, checked, labels, onChange, disabled = false, tooltip } = props;

  return (
    <div
      className={classNames({
        switch: true,
        'switch--on': checked,
        disabled: disabled,
      })}
    >
      <input
        className='switch__input'
        type='checkbox'
        id={id}
        checked={checked}
        onChange={() => onChange(checked)}
        disabled={disabled}
      />
      {tooltip ? (
        <Tooltip preferredPosition='mostSpace' content={tooltip}>
          <label className='switch__label' htmlFor={id}>
            {labels?.on ? (
              <span className='switch__on'>{labels.on}</span>
            ) : null}
            {labels?.off ? (
              <span className='switch__off'>{labels.off}</span>
            ) : null}
          </label>
        </Tooltip>
      ) : (
        <label className='switch__label' htmlFor={id}>
          {labels?.on ? <span className='switch__on'>{labels.on}</span> : null}
          {labels?.off ? (
            <span className='switch__off'>{labels.off}</span>
          ) : null}
        </label>
      )}
    </div>
  );
};
