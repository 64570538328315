import { ShopifyObjectTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { ResourceSelectionProps } from '../SearchFieldWithGrayBoxOfferWizard';
import { I18n } from '@shopify/react-i18n';

export const headerTitle = (
  selectedGrayBoxElements: ResourceSelectionProps[],
  currentResourceType: string,
  i18n: I18n
): string => {
  let result = '';
  let counter = 0;

  selectedGrayBoxElements.map((item: any) => {
    if (currentResourceType === ShopifyObjectTypeDtoEnum.PRODUCT_VARIANT) {
      item?.variants?.map((variant: any) => {
        counter++;
        if (counter <= 3) {
          result += `${item.title}/${variant.title}, `;
        }
      });
    } else {
      counter++;
      if (counter <= 3) {
        result += `${item.title}, `;
      }
    }
  });

  return counter <= 3
    ? `<b>${result.slice(0, -2)}</b>`
    : i18n.translate('ConfiguredData', {
        data: result.slice(0, -2),
        amount: counter - 3,
      });
};
