import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  OfferFreeGiftRuleDto,
  OfferGiftSpecificationDto,
  OfferGiftTypeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  Button,
  ButtonGroup,
  Icon,
  IconSource,
  InlineStack,
  Text,
} from '@shopify/polaris';
import classNames from 'classnames';
import {
  OfferGiftTypeDtoEnum,
  OfferTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { useI18n } from '@shopify/react-i18n';
import {
  Controller,
  ControllerRenderProps,
  useController,
  useForm,
} from 'react-hook-form';
import * as yup from 'yup';
import NumberFormat from 'react-number-format';
import { yupResolver } from '@hookform/resolvers/yup';
import './FreeGiftFirstLineContent.scss';
import { OfferTypesFromCatalogData } from 'features/promotions/components/SelectedOfferTypeSettings/consts/OfferTypesFromCatalogData';
import { OfferTypesFromCatalogDataType } from 'features/promotions/components/SelectedOfferTypeSettings/types/OfferTypesFromCatalogTypes';
import { useAppDispatch } from 'core/hooks';
import { AlertCircleIcon } from '@shopify/polaris-icons';
import { FreeGiftSectionValidityBlocksProps } from '../../FreeGiftSection';
import { CaretDownIcon, CaretUpIcon } from '@shopify/polaris-icons';
export type FreeGiftFirstLineContentProps = {
  updateValidityBlock(
    field: keyof FreeGiftSectionValidityBlocksProps,
    value: boolean
  ): void;
  updateCurrentSpecification(
    field: keyof OfferGiftSpecificationDto | keyof OfferFreeGiftRuleDto,
    data:
      | OfferGiftSpecificationDto[keyof OfferGiftSpecificationDto]
      | OfferFreeGiftRuleDto[keyof OfferFreeGiftRuleDto]
  ): void;
  type?: OfferGiftTypeDto;
  amount?: number | null;
  offerType: OfferTypeDtoEnum;
  limit: number;
};

type FormFields = {
  numberOfItems: number;
};

const form = yup.object({
  numberOfItems: yup.number().min(1).integer().required(),
});

export const FreeGiftFirstLineContent: React.FC<
  FreeGiftFirstLineContentProps
> = (props) => {
  const {
    updateCurrentSpecification,
    updateValidityBlock,
    type,
    amount,
    offerType,
    limit
  } = props;

  const [i18n] = useI18n();
  const dispatch = useAppDispatch();

  const [currentValue, setCurrentValue] = useState<number>(amount || 1);

  const { control, formState, watch, getValues, setValue } =
    useForm<FormFields>({
      defaultValues: {
        numberOfItems: currentValue,
      },
      mode: 'onChange',
      resolver: yupResolver(form),
    });

  const validationMessage = useMemo(() => {
    const { numberOfItems: numberOfItemsErrors } = formState.errors;
    let label = { translationKey: '', textContent: '' };

    if (numberOfItemsErrors) {
      switch (numberOfItemsErrors.type) {
        case 'min':
          label = {
            translationKey: 'FiltersWarning.WarningAmount1',
            textContent: 'Value must be equal or larger than 1',
          };
          break;
        case 'typeError':
          label = {
            translationKey: 'FiltersWarning.WarningAmount3',
            textContent: 'Value is required',
          };
          break;
      }
    }

    if( getValues("numberOfItems") > limit){
      label = {
        translationKey: 'FiltersWarning.WarningAmount4',
        textContent: 'Value is greater than limit',
      };
    }

    if (label.textContent && label.translationKey) {
      return (
        <span style={{ paddingLeft: '35px' }}>
          <Text as='p' tone='critical'>
            <InlineStack gap='100'>
              <span>
                {' '}
                <Icon source={AlertCircleIcon as IconSource} />
              </span>
              {i18n.translate(
                `FreeGiftFirstLineContent.${label.translationKey}`
              ) || label.textContent}
            </InlineStack>
          </Text>
        </span>
      );
    } else {
      return null;
    }
  }, [formState, i18n]);

  const findFreeGiftOfferTypeNeeded = useCallback(
    (offerType: OfferTypeDtoEnum) => {
      return (
        OfferTypesFromCatalogData.FreeGift.find(
          (offerTypeFromCatalog: OfferTypesFromCatalogDataType) =>
            offerTypeFromCatalog.type === offerType
        ) || null
      );
    },
    []
  );

  const handleFreeGiftTypeClick = useCallback(
    (key: OfferGiftTypeDto) => {
      updateCurrentSpecification('type', key);
    },
    [updateCurrentSpecification, findFreeGiftOfferTypeNeeded, dispatch]
  );

  const changeValue = useCallback(
    (field: ControllerRenderProps<FormFields>, value: string) => {
      if (field.value !== parseFloat(value)) {
        setCurrentValue(parseFloat(value));
        field.onChange(parseFloat(value));
      }
    },
    [setCurrentValue]
  );

  useEffect(() => {
    const subscription = watch((value, { type }) => {
      if (type === 'change') {
        updateCurrentSpecification('amount', value?.numberOfItems);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, updateCurrentSpecification]);

  useEffect(() => {
    updateValidityBlock('isTypeSwitcherBlockValid', formState.isValid);
  }, [formState.isValid]);

  const incrementDecrementValue = useCallback(
    (
      field: ControllerRenderProps<FormFields>,
      type: 'increment' | 'decrement'
    ) => {
      const currentValue =
        typeof getValues(field.name) === 'number' ? getValues(field.name) : 1;
      if (type === 'increment' && typeof currentValue === 'number') {
        changeValue(field, `${currentValue + 1}`);
      } else if (type === 'decrement' && typeof currentValue === 'number') {
        changeValue(field, `${currentValue - 1}`);
      }
    },
    [setValue, getValues]
  );

  const incrementDecrementButtons = useCallback(
    (field: ControllerRenderProps<FormFields>) => {
      return (
        <div className='Polaris-TextField__Spinner' aria-hidden='true'>
          <div
            role='button'
            className='Polaris-TextField__Segment'
            onClick={() => {
              incrementDecrementValue(field, 'increment');
            }}
          >
            <div className='Polaris-TextField__SpinnerIcon'>
              <span className='Polaris-Icon'>
                <Icon source={CaretUpIcon} />
              </span>
            </div>
          </div>
          <div
            role='button'
            className='Polaris-TextField__Segment'
            onClick={() => {
              incrementDecrementValue(field, 'decrement');
            }}
          >
            <div className='Polaris-TextField__SpinnerIcon'>
              <span className='Polaris-Icon'>
                <Icon source={CaretDownIcon} />
              </span>
            </div>
          </div>
        </div>
      );
    },
    [incrementDecrementValue]
  );

  const { field: numberOfItemsField } = useController({
    name: 'numberOfItems',
    control,
  });

  return (
    <div className='FreeGiftFirstLineContainer'>
      <InlineStack gap='200' blockAlign='center'>
        <Text as='p'> {i18n.translate('FreeGiftFirstLineContent.Offer')}</Text>
        <div
          className={classNames(
            'Polaris-TextField Polaris-TextField--hasValue',
            {
              'Polaris-TextField--error': getValues("numberOfItems") > limit,
            }
          )}
          style={{ maxWidth: '64px' }}
        >
          <Controller<FormFields>
            name='numberOfItems'
            control={control}
            render={({ field }) => (
              <NumberFormat
                {...field}
                inputMode='numeric'
                value={field.value as number}
                className='Polaris-TextField__Input'
                decimalScale={0}
                disabled={offerType === OfferTypeDtoEnum.GIFT_MANUAL}
                allowNegative={false}
                autoComplete='off'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  changeValue(field, e.target.value)
                }
              />
            )}
          />
          {offerType !== OfferTypeDtoEnum.GIFT_MANUAL &&
            incrementDecrementButtons(numberOfItemsField)}
          <div className='Polaris-TextField__Backdrop'></div>
        </div>
        <Text as='p'>
          {' '}
          {i18n.translate('FreeGiftFirstLineContent.Free', {
            config:
              offerType !== OfferTypeDtoEnum.GIFT_MANUAL
                ? i18n.translate(`FreeGiftFirstLineContent.PRODUCT_VARIANT`)
                : '',
          })}
        </Text>
        <ButtonGroup variant='segmented'>
          {offerType === OfferTypeDtoEnum.GIFT_MANUAL &&
            Object.keys(OfferGiftTypeDtoEnum).map((key: string) => (
              <Button
                key={key}
                pressed={type === key}
                onClick={() =>
                  handleFreeGiftTypeClick(key as OfferGiftTypeDto)
                }
              >
                {i18n.translate(`FreeGiftFirstLineContent.${key}`)}
              </Button>
            ))}
        </ButtonGroup>
      </InlineStack>
      {validationMessage}
    </div>
  );
};
