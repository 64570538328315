import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SettingsLayout } from './components/SettingsLayout/SettingsLayout';
import { useLocation, useSearchParams } from 'react-router-dom';
import './Settings.scss';
import { SettingsNavbar } from './components/SettingsNavbar/SettingsNavbar';
import {
  CheckoutOptions,
  ManageWidgets,
  StrikethroughPricing,
} from './components';
import { AnnouncementBarSettings } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/AnnouncementBarSettings/AnnouncementBarSettings';
import { ProductBannerSettings } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/ProductBannerSettings/ProductBannerSettings';
import { NotificationSettings } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/NotificationSettings/NotificationSettings';
import { PromotionalBadgeSettings } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/PromotionalBadgeSettings/PromotionalBadgeSettings';
import { PromotionCodeSettings } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/PromotionCodeSettings/PromotionCodeSettings';
import { DiscountNinjaCodesSetting } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/DiscountNinjaCodesSetting/DiscountNinjaCodesSetting';
import { ShopifyDiscountCodesSettings } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/ShopifyDiscountCodesSettings/ShopifyDiscountCodesSettings';
import { PromotionSummarySetting } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/PromotionSummarySetting/PromotionSummarySetting';
import { OfferRulePopupSettings } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/OfferRulePopupSettings/OfferRulePopupSettings';
import { CountdownClockSettings } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/CountdownClockSettings/CountdownClockSettings';
import { scroller } from 'react-scroll';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import {
  setCurrentAnchor,
  setIsSettingsSaving,
  setIsUnsavedChanges,
  setIsValidationError,
} from 'core/store/settingsSlice';
import {
  setDefaultShopLevelStylePreset,
  setPromotionLevelStylePresets,
  setShopLevelStylePresets,
  setToggleSettingsConfig,
} from 'core/store/widgetsSlice';
import { ShopLevelStylePresetsPage } from './components/GeneralSettings/Style/components/ShopLevelStylePresetsPage/ShopLevelStylePresetsPage';
import { PromotionLevelStylePresetsPage } from './components/GeneralSettings/Style/components/PromotionLevelStylePresetsPage/PromotionLevelStylePresetsPage';
import { StrikethroughDrawerCartSettings } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/StrikethroughDrawerCartSettings/StrikethroughDrawerCartSettings';
import { StrikethroughCartSettings } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/StrikethroughCartSettings/StrikethroughCartSettings';
import { StrikethroughCollectionSettings } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/StrikethroughCollectionSettings/StrikethroughCollectionSettings';
import { StrikethroughProductSettings } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/StrikethroughProductSettings/StrikethroughProductSettings';
import { Translations } from './components/GeneralSettings/Translations/Translations';
import { Plan } from './components/GeneralSettings/Plan/Plan';
import { CartBehavior } from './components/GeneralSettings/CartBehavior/CartBehavior';
import { TagsMetadata } from './components/GeneralSettings/TagsMetadata/TagsMetadata';
import { Testing } from './components/GeneralSettings/Testing/Testing';
import { DiscountLinks } from './components/GeneralSettings/DiscountLinks/DiscountLinks';
import { Gifts } from './components/GeneralSettings/Gifts/Gifts';
import { ImportExport } from './components/GeneralSettings/ImportExport/ImportExport';
import { Style } from './components/GeneralSettings/Style/Style';
import { TranslationSettingPage } from './components/GeneralSettings/Translations/components/TranslationSettingPage/TranslationSettingPage';
import { useI18n } from '@shopify/react-i18n';
import { Button } from '@shopify/polaris';
import { Integrations } from './components/GeneralSettings/Integrations/Integrations';
import { Account } from './components/GeneralSettings/Account/Account';
import { ActivityLogPage } from './components/GeneralSettings/Account/components/ActivityLogPage/ActivityLogPage';
import { CartTextSettings } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/CartTextSettings/CartTextSettings';
import { DiscountLabelSettings } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/DiscountLabelSettings/DiscountLabelSettings';
import { MessageEventData } from 'pages/SettingsPage';
import resolveEnvVar from 'env-var-resolver';
import useFetchPresetList from './hooks/useFetchPresetList/useFetchPresetList';
import { PastBills } from './components/GeneralSettings/Plan/pages/PastBills/PastBills';
import { PlanDetails } from './components/GeneralSettings/Plan/pages/PlanDetails/PlanDetails';
import classNames from 'classnames';

type SettingsProps = {
  isMaxModal?: boolean;
};

const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';

export const Settings: React.FC<SettingsProps> = ({ isMaxModal }) => {
  const dispatch = useAppDispatch();
  const [i18n] = useI18n();

  const { fetchBrandColors } = useFetchPresetList();

  const {
    currentAnchor,
    isUnsavedChanges,
    onSaveChanges,
    isSettingsSaving,
    isValidationError,
    onDiscardChanges,
  } = useAppSelector((state) => state.settings);
  const { toggleSettingsConfig } = useAppSelector((store) => store.widgets);

  const currentLocation = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';

  const [targetElementRendered, setTargetElementRendered] =
    useState<boolean>(false);

  const hasPath = useMemo(
    () => searchParams.has('path'),
    [currentLocation.search]
  );

  const path = searchParams.get('path');
  const subPath = searchParams.get('subPath');
  const tab = searchParams.get('tab');
  const language = searchParams.get('language');

  const hideFooterSaveButton = useMemo(
    () => (path === 'account' && subPath === 'activity-log') || path === 'plan',
    [path, subPath]
  );

  const hideNavBar = useMemo(
    () => path === 'plan' && subPath === 'pick-your-plan',
    [path, subPath]
  );

  const renderSettingComponent = useMemo(() => {
    switch (true) {
      case path === 'style' && !subPath:
        return <Style />;
      case path === 'style' && subPath === 'shop-level_style_presets':
        return <ShopLevelStylePresetsPage />;
      case path === 'style' && subPath === 'promotion-level_style_presets':
        return <PromotionLevelStylePresetsPage />;
      case path === 'translations' && !subPath:
        return <Translations isMaxModal={!!isMaxModal} />;
      case path === 'translations' && subPath === 'translations-settings':
        return <TranslationSettingPage />;
      case path === 'plan' && !subPath:
        return <Plan />;
      case path === 'integrations':
        return <Integrations />;
      case path === 'cart-behavior' && !subPath:
        return <CartBehavior />;
      case path === 'tags-metadata' && !subPath:
        return <TagsMetadata />;
      case path === 'testing' && !subPath:
        return <Testing />;
      case path === 'discount-links' && !subPath:
        return <DiscountLinks />;
      case path === 'import-export' && !subPath:
        return <ImportExport />;
      case path === 'gifts' && !subPath:
        return <Gifts />;
      case path === 'account' && !subPath:
        return <Account />;
      case path === 'account' && subPath === 'activity-log':
        return <ActivityLogPage />;
      case path === 'plan' && subPath === 'past-bills':
        return <PastBills />;
      case path === 'plan' && subPath === 'pick-your-plan':
        return <PlanDetails />;

      // old links

      case path === 'checkout-options':
        return <CheckoutOptions />;
      case path === 'manage-widgets' && !subPath:
        return <ManageWidgets />;

      case path === 'manage-widgets' && subPath === 'announcement-bar':
        return <AnnouncementBarSettings />;
      case path === 'manage-widgets' && subPath === 'product-banner':
        return <ProductBannerSettings />;
      case path === 'manage-widgets' && subPath === 'notification':
        return <NotificationSettings />;
      case path === 'manage-widgets' && subPath === 'promotional-badge':
        return <PromotionalBadgeSettings />;
      case path === 'manage-widgets' && subPath === 'promotion-code-field':
        return <PromotionCodeSettings />;
      case path === 'manage-widgets' &&
        subPath === 'discount-ninja-promotion-codes':
        return <DiscountNinjaCodesSetting />;
      case path === 'manage-widgets' && subPath === 'shopify-discount-codes':
        return <ShopifyDiscountCodesSettings />;
      case path === 'manage-widgets' && subPath === 'promotion-summary':
        return <PromotionSummarySetting />;
      case path === 'manage-widgets' && subPath === 'offer-rules-popup':
        return <OfferRulePopupSettings />;
      case path === 'manage-widgets' && subPath === 'countdown-clock':
        return <CountdownClockSettings />;
      case path === 'manage-widgets' && subPath === 'cart-text':
        return <CartTextSettings />;
      case path === 'manage-widgets' && subPath === 'discount-label':
        return <DiscountLabelSettings />;
      case path === 'strikethrough-pricing' && !subPath:
        return <StrikethroughPricing />;
      case (path === 'strikethrough-pricing' || path === 'manage-widgets') &&
        subPath === 'drawer-cart-strikethrough-pricing':
        return <StrikethroughDrawerCartSettings />;
      case (path === 'strikethrough-pricing' || path === 'manage-widgets') &&
        subPath === 'cart-strikethrough-pricing':
        return <StrikethroughCartSettings />;
      case (path === 'strikethrough-pricing' || path === 'manage-widgets') &&
        subPath === 'collection-strikethrough-pricing':
        return <StrikethroughCollectionSettings />;
      case (path === 'strikethrough-pricing' || path === 'manage-widgets') &&
        subPath === 'product-strikethrough-pricing':
        return <StrikethroughProductSettings />;

      default:
        return null;
    }
  }, [path, subPath, isMaxModal]);

  const sendMessageToMainApp = (message: any) => {
    window.opener?.postMessage(message, location.origin);
  };

  const handleSaveChanges = useCallback(() => {
    dispatch(setIsSettingsSaving(true));
    onSaveChanges?.()
      .then(() => {
        isMaxModal &&
          sendMessageToMainApp({
            type: 'IS_UNSAVED_CHANGES',
            isUnsavedChanges: false,
          });
        if (toggleSettingsConfig) {
          dispatch(setToggleSettingsConfig({ action: 'save' }));
          setSearchParams({});
        }
      })
      .finally(() => {
        dispatch(setIsSettingsSaving(false));
      });
  }, [onSaveChanges, toggleSettingsConfig, isMaxModal, dispatch]);

  const handleMessageFromMainApp = (ev: MessageEvent) => {
    const { type } = ev.data as MessageEventData;
    if (type === 'TOGGLE_SETTINGS_CONFIG') {
      dispatch(setToggleSettingsConfig(ev.data.toggleSettingsConfig));
    }
    if (type === 'IS_UNSAVED_CHANGES') {
      dispatch(setIsUnsavedChanges(ev.data.isUnsavedChanges));
    }
    if (type === 'IS_VALIDATION_ERROR') {
      dispatch(setIsValidationError(ev.data.isValidationError));
    }
    if (type === 'IS_SETTINGS_SAVING') {
      dispatch(setIsSettingsSaving(ev.data.isSettingsSaving));
    }
    if (type === 'SAVING_FUNC') {
      handleSaveChanges();
    }
    if (type === 'DISCARD_FUNC') {
      onDiscardChanges?.();
    }
    if (type === 'UPDATE_ANCHOR') {
      dispatch(setCurrentAnchor(ev.data.currentAnchor));
    }
    if (type === 'UPDATE_SEARCH_PARAMS') {
      if (ev.data.searchParams?.path) {
        setSearchParams((params) => {
          params.set('path', ev.data.searchParams.path);
          ev.data.searchParams?.subPath
            ? params.set('subPath', ev.data.searchParams.subPath)
            : params.delete('subPath');
          ev.data.searchParams?.tab
            ? params.set('tab', ev.data.searchParams.tab)
            : params.delete('tab');
          ev.data.searchParams?.language
            ? params.set('language', ev.data.searchParams.language)
            : params.delete('language');
          return params;
        });
        ev.data.searchParams?.currentAnchor &&
          dispatch(setCurrentAnchor(ev.data.searchParams?.currentAnchor));
      } else {
        searchParams.set('path', 'account');
        setSearchParams(searchParams);
        ev.data.searchParams?.currentAnchor &&
          dispatch(setCurrentAnchor(ev.data.searchParams?.currentAnchor));
      }
    }
  };

  const handleFetchBrandColors = useCallback(async () => {
    await fetchBrandColors({
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    });
  }, [APP_NAME, APP_PASSWORD]);

  if (isMaxModal) {
    sendMessageToMainApp({
      type: 'IS_UNSAVED_CHANGES',
      isUnsavedChanges,
    });
  }

  // redirect to 'account' by default if there is no 'path' and not maxModal
  useEffect(() => {
    if (!hasPath && !isMaxModal) {
      searchParams.set('path', 'account');
      setSearchParams(searchParams);
    }
  }, [hasPath]);

  useEffect(() => {
    isMaxModal &&
      path &&
      sendMessageToMainApp({
        type: 'UPDATE_SEARCH_PARAMS',
        searchParams: { path, subPath, tab, language },
      });
    if (subPath !== 'promotion-code-field' && path !== 'manage-widgets') {
      searchParams.delete('tab');
      setSearchParams(searchParams);
    }
    if (path !== 'translations') {
      searchParams.delete('language');
      setSearchParams(searchParams);
    }
    if (path !== 'style') {
      dispatch(setShopLevelStylePresets(null));
      dispatch(setPromotionLevelStylePresets(null));
      dispatch(setDefaultShopLevelStylePreset(null));
    }
  }, [path, subPath, tab, language]);

  useEffect(() => {
    isMaxModal && window.addEventListener('message', handleMessageFromMainApp);
    return () => {
      window.removeEventListener('message', handleMessageFromMainApp);
    };
  }, [handleMessageFromMainApp]);

  useEffect(() => {
    const presetId = currentLocation.pathname.includes('configure-widgets')
      ? currentLocation.pathname.slice(19)
      : '';

    if (isMaxModal && presetId) {
      sendMessageToMainApp({
        type: 'NAVIGATE_TO_EDITOR',
        presetId,
      });
    }
  }, [currentLocation.pathname]);

  useEffect(() => {
    !isMaxModal &&
      !currentLocation.pathname.includes('configure-widgets') &&
      localStorage.setItem(
        'settingsLocation',
        `${currentLocation.pathname}${path ? `?path=${path}` : ''}${
          subPath ? `&subPath=${subPath}` : ''
        }${tab ? `&tab=${tab}` : ''}${language ? `&language=${language}` : ''}`
      );
  }, [currentLocation, path, subPath, language, tab]);

  useEffect(() => {
    isMaxModal &&
      sendMessageToMainApp({
        type: 'TOGGLE_SETTINGS_CONFIG',
        toggleSettingsConfig,
      });
  }, [toggleSettingsConfig]);

  useEffect(() => {
    isMaxModal &&
      sendMessageToMainApp({ type: 'IS_VALIDATION_ERROR', isValidationError });
  }, [isValidationError]);

  useEffect(() => {
    isMaxModal &&
      sendMessageToMainApp({ type: 'IS_SETTINGS_SAVING', isSettingsSaving });
  }, [isSettingsSaving]);

  useEffect(() => {
    isMaxModal && sendMessageToMainApp({ type: 'MAX_MODAL_RENDERED' });
  }, [isMaxModal]);

  // workaround for now ...react-scroll package update needed
  useEffect(() => {
    const checkIfRendered = () => {
      const element = document.querySelector(`.${currentAnchor}`);
      if (element) {
        setTargetElementRendered(true);
      } else {
        setTargetElementRendered(false);
      }
    };

    // Initial check
    checkIfRendered();

    // Optional: Add a mutation observer to detect changes in the DOM
    const observer = new MutationObserver(checkIfRendered);
    observer.observe(document.body, { childList: true, subtree: true });

    // Clean up the observer on component unmount
    return () => observer.disconnect();
  }, [currentAnchor]);

  // scroll to element needed (addjust/change props if needed)
  useEffect(() => {
    if (targetElementRendered && currentAnchor) {
      scroller.scrollTo(currentAnchor, {
        containerId: 'SettingsLayout',
        offset: -20,
        duration: 1000,
        delay: 100,
        smooth: true,
      });
      dispatch(setCurrentAnchor(null));
    }
  }, [targetElementRendered]);

  useEffect(() => {
    APP_NAME && APP_PASSWORD && handleFetchBrandColors();
  }, [APP_NAME, APP_PASSWORD]);

  return (
    <SettingsLayout isMaxModal={isMaxModal}>
      <div className='Settings'>
        {!hideNavBar && <SettingsNavbar />}
        <div
          className={classNames('SettingsPages', {
            HideNavBar: hideNavBar,
          })}
        >
          {renderSettingComponent}

          {!hideFooterSaveButton && (
            <div className='SettingFooterSaveButton'>
              <Button
                variant='primary'
                disabled={!isUnsavedChanges}
                loading={isSettingsSaving}
                onClick={() =>
                  !isMaxModal
                    ? handleSaveChanges()
                    : sendMessageToMainApp({
                        type: 'PAGE_SAVE',
                      })
                }
              >
                {i18n.translate('Save')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </SettingsLayout>
  );
};
