import { IconLookup, library } from '@fortawesome/fontawesome-svg-core';
import {
  faHouseChimney as falHouseChimney,
  faGift as falGift,
  faPlusCircle as falPlusCircle,
  faChartLine as falChartLine,
  faShapes as falShapes,
  faPalette as falPalette,
  faBell as falBell,
  faLightbulb as falLightbulb,
  faQuestionCircle as falQuestionCircle,
  faCircleQuestion as falCircleQuestion,
  faCog as falCog,
  faMoneyBill as falMoneyBill,
  faArrowDownRight as falArrowDownRight,
  faArrowUpRight as falArrowUpRight,
  faArrowRightLong as falArrowRightLong,
  faCommentDots as falCommentDots,
  faEnvelope as falEnvelope,
  faRotate as falRotate,
  faCalendar as falCalendar,
} from '@fortawesome/pro-light-svg-icons';
import {
  faHouseChimney as farHouseChimney,
  faGift as farGift,
  faPlusCircle as farPlusCircle,
  faChartLine as farChartLine,
  faShapes as farShapes,
  faPalette as farPalette,
  faBell as farBell,
  faLightbulb as farLightbulb,
  faQuestionCircle as farQuestionCircle,
  faCircleQuestion as farCircleQuestion,
  faCog as farCog,
  faMoneyBill as farMoneyBill,
  faCartShopping as farCartShopping,
  faHandshake as farHandshake,
  faCreditCard as farCreditCard,
  faChartColumn as farChartColumn,
  faArrowsRotate as farArrowsRotate,
  faArrowUpRightFromSquare as farArrowUpRightFromSquare,
  faUnderline as farUnderline,
  faItalic as farItalic,
  faBold as farBold,
  faCode as farCode,
  faFont as farFont,
  faRotate as farRotate,
  faCalendar as farCalendar,
  faAngleDown as farAngleDown,
  faAngleUp as farAngleUp,
  faMessageSmile as farMessageSmile,
  faTrashAlt as farTrashAlt,
  faText as farText,
  faPercent as farPercent,
  faCommentAltSmile as farCommentAltSmile,
  faDesktop as farDesktop,
  faMobileScreenButton as farMobileScreenButton,
  faShopLock as farShopLock,
  faSliders as farSliders,
  faBrush as farBrush,
  faImage as farImage,
  faRectangle as farRectangle,
  faArrowUp as farArrowUp,
  faArrowRight as farArrowRight,
  faArrowDown as farArrowDown,
  faArrowLeft as farArrowLeft,
  faArrowUpRight as farArrowUpRight,
  faArrowUpLeft as farArrowUpLeft,
  faArrowDownLeft as farArrowDownLeft,
  faArrowDownRight as farArrowDownRight,
  faGem as farGem,
  faVectorSquare as faVectorSquare,
  faLinkSimpleSlash as farLinkSimpleSlash,
  faBadgePercent as farBadgePercent,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCircleInfo as fasCircleInfo,
  faGift as fasGift,
  faVideo as fasVideo,
  faDollarSign as fasDollarSign,
  faLock as fasLock,
  faGifts as fasGifts,
  faCartCirclePlus as fasCartCirclePlus,
  faArrowsCross as fasArrowsCross,
  faArrowTurnDownRight as fasArrowTurnDownRight,
  faBadgePercent as fasBadgePercent,
  faBadgeDollar as fasBadgeDollar,
  faBoxesStacked as fasBoxesStacked,
  faRectangleHistoryCirclePlus as fasRectangleHistoryCirclePlus,
  faTruckFast as fasTruckFast,
  faTruckBolt as fasTruckBolt,
  faPalletBox as fasPalletBox,
  faTag as fasTag,
  faTags as fasTags,
  faLinkHorizontal as fasLinkHorizontal,
  faEye as fasEye,
  faCircleQuestion as fasCircleQuestion,
  faAngleLeft as fasAngleLeft,
  faCircleCheck as fasCircleChek,
  faUnderline as fasUnderline,
  faItalic as fasItalic,
  faBold as fasBold,
  faCode as fasCode,
  faFont as fasFont,
  faBolt as fasBolt,
  faPlusSquare as fasPlusSquare,
  faLink as fasLink,
  faCheck as fasCheck,
  faPlus as fasPlus,
  faMinus as fasMinus,
  faDisplay as fasDisplay,
  faMobileScreenButton as fasMobileScreenButton,
  faAngleRight as fasAngleRight,
  faInfoCircle as fasInfoCircle,
  faTriangleExclamation as fasTriangleExclamation,
  faMagnifyingGlassPlus as fasMagnifyingGlassPlus,
  faHand as fasHand,
  faStar as fasStar,
  faCartCircleArrowUp as fasCartCircleArrowUp,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  falHouseChimney,
  farHouseChimney,
  falGift,
  farGift,
  falPlusCircle,
  farPlusCircle,
  fasCircleInfo,
  fasGift,
  fasVideo,
  fasDollarSign,
  fasLock,
  fasGifts,
  fasCartCirclePlus,
  fasArrowsCross,
  fasArrowTurnDownRight,
  fasBadgePercent,
  fasBadgeDollar,
  fasBoxesStacked,
  fasRectangleHistoryCirclePlus,
  fasTruckFast,
  fasTruckBolt,
  fasPalletBox,
  fasTag,
  fasTags,
  fasLinkHorizontal,
  fasHand,
  fasStar,
  falChartLine,
  farChartLine,
  falShapes,
  farShapes,
  falPalette,
  farPalette,
  falBell,
  farBell,
  falLightbulb,
  farLightbulb,
  falQuestionCircle,
  farQuestionCircle,
  falCircleQuestion,
  farCircleQuestion,
  falCog,
  farCog,
  falMoneyBill,
  farMoneyBill,
  fasEye,
  farCartShopping,
  farHandshake,
  farCreditCard,
  farChartColumn,
  farArrowsRotate,
  fasCircleQuestion,
  falArrowDownRight,
  falArrowUpRight,
  falArrowRightLong,
  fasAngleLeft,
  fasCircleChek,
  fasCartCircleArrowUp,
  farArrowUpRightFromSquare,
  falCommentDots,
  falEnvelope,
  fasUnderline,
  fasItalic,
  fasBold,
  fasCode,
  fasFont,
  farUnderline,
  farItalic,
  farBold,
  farCode,
  farFont,
  falRotate,
  farRotate,
  falCalendar,
  farCalendar,
  farAngleDown,
  farAngleUp,
  fasBolt,
  fasPlusSquare,
  fasLink,
  fasCheck,
  farMessageSmile,
  fasPlus,
  fasMinus,
  fasDisplay,
  fasMobileScreenButton,
  fasAngleRight,
  farTrashAlt,
  farText,
  farPercent,
  fasInfoCircle,
  farCommentAltSmile,
  fasTriangleExclamation,
  fasMagnifyingGlassPlus,
  farDesktop,
  farMobileScreenButton,
  farShopLock,
  farSliders,
  farBrush,
  farImage,
  farRectangle,
  farArrowUp,
  farArrowRight,
  farArrowDown,
  farArrowLeft,
  farArrowUpRight,
  farArrowUpLeft,
  farArrowDownLeft,
  farArrowDownRight,
  farGem,
  faVectorSquare,
  farLinkSimpleSlash,
  farBadgePercent
);

// NAVIGATION

// home

export const homeIconLookup: IconLookup = {
  prefix: 'fal',
  iconName: 'house-chimney',
};
export const homeThickIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'house-chimney',
};

// promotions

export const promotionsIconLookup: IconLookup = {
  prefix: 'fal',
  iconName: 'gift',
};
export const promotionsThickIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'gift',
};

// create promotions

export const createPromotionIconLookup: IconLookup = {
  prefix: 'fal',
  iconName: 'plus-circle',
};
export const createPromotionThickIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'plus-circle',
};

export const createPromotionInfoIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'circle-info',
};

export const createPromotionOffersIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'gift',
};

export const createPromotionOffersMovieIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'video',
};

export const createPromotionOffersDollarIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'dollar-sign',
};

export const createPromotionOffersLockIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'lock',
};

export const createPromotionOffersGiftIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'gift',
};

export const createPromotionOffersGiftsIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'gifts',
};

export const createPromotionOffersCartIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'cart-circle-plus',
};
export const createPromotionOffersCartArrowUpIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'cart-circle-arrow-up',
};

export const createPromotionOffersArrowsCrossIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'arrows-cross',
};

export const createPromotionOffersBadgePercentIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'badge-percent',
};

export const createPromotionOffersBoxesStackedIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'boxes-stacked',
};

export const createPromotionOffersBadgeDollarIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'badge-dollar',
};

export const createPromotionOffersRectangleHistoryIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'rectangle-history-circle-plus',
};

export const createPromotionOffersTruckFastIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'truck-fast',
};

export const createPromotionOffersTruckBoltIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'truck-bolt',
};

export const createPromotionOffersPalletBoxIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'pallet-box',
};

export const createPromotionOffersTagIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'tag',
};

export const createPromotionOffersTagsIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'tags',
};

export const createPromotionOffersLinkHorizontalIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'link-horizontal',
};

export const createPromotionTriggerOpenImageIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'magnifying-glass-plus',
};
export const starIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'star',
};
// reporting

export const reportingIconLookup: IconLookup = {
  prefix: 'fal',
  iconName: 'chart-line',
};
export const reportingThickIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'chart-line',
};

// block settings

export const blockSettingsIconLookup: IconLookup = {
  prefix: 'fal',
  iconName: 'shapes',
};
export const blockSettingsThickIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'shapes',
};

// themes

export const themesIconLookup: IconLookup = {
  prefix: 'fal',
  iconName: 'palette',
};
export const themesThickIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'palette',
};

// news

export const newsIconLookup: IconLookup = {
  prefix: 'fal',
  iconName: 'bell',
};
export const newsThickIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'bell',
};

// feature request

export const featureRequestIconLookup: IconLookup = {
  prefix: 'fal',
  iconName: 'lightbulb',
};
export const featureRequestThickIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'lightbulb',
};

// help

export const helpIconLookup: IconLookup = {
  prefix: 'fal',
  iconName: 'question-circle',
};
export const helpThickIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'question-circle',
};

// settings

export const settingsIconLookup: IconLookup = {
  prefix: 'fal',
  iconName: 'cog',
};
export const settingsThickIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'cog',
};

export const desktopScreenIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'desktop',
};
export const mobileScreenIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'mobile-screen-button',
};
export const shopLockIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'shop-lock',
};
export const slidersIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'sliders',
};
export const brushIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'brush',
};
export const imageIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'image',
};
export const rectangleIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'rectangle',
};
export const arrowUpIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'arrow-up',
};
export const arrowRightIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'arrow-right',
};
export const arrowDownIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'arrow-down',
};
export const arrowLeftIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'arrow-left',
};
export const arrowUpRightIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'arrow-up-right',
};
export const arrowUpLeftIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'arrow-up-left',
};
export const arrowDownLeftIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'arrow-down-left',
};
export const arrowDownRightIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'arrow-down-right',
};
export const gemIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'gem',
};
// INSIGHTS

//revenue

export const billIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'money-bill',
};

//sessions

export const eyeIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'eye',
};

//carts

export const cartIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'cart-shopping',
};

//checkout

export const handshakeIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'handshake',
};
export const handIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'hand',
};
//orders

export const creditCardIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'credit-card',
};

//discover more insights

export const insightsIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'chart-column',
};

//  OTHERS

// refresh

export const refreshIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'arrows-rotate',
};

// question mark

export const questionMarkIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'circle-question',
};

// arrows

export const descendingArrowIconLookup: IconLookup = {
  prefix: 'fal',
  iconName: 'arrow-down-right',
};
export const risingArrowIconLookup: IconLookup = {
  prefix: 'fal',
  iconName: 'arrow-up-right',
};
export const constantArrowIconLookup: IconLookup = {
  prefix: 'fal',
  iconName: 'arrow-right-long',
};
export const turnDownRightIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'arrow-turn-down-right',
};
export const angleLeftIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'angle-left',
};

// UPDATE SECTION

export const checkIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'circle-check',
};

export const linkIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'arrow-up-right-from-square',
};

// SUPPORT SECTION

export const chatIconLookup: IconLookup = {
  prefix: 'fal',
  iconName: 'comment-dots',
};

export const emailIconLookup: IconLookup = {
  prefix: 'fal',
  iconName: 'envelope',
};

// RICH TEXT EDITOR

export const underlineIconLookupThick: IconLookup = {
  prefix: 'fas',
  iconName: 'underline',
};

export const italicIconLookupThick: IconLookup = {
  prefix: 'fas',
  iconName: 'italic',
};

export const boldIconLookupThick: IconLookup = {
  prefix: 'fas',
  iconName: 'bold',
};

export const codeIconLookupThick: IconLookup = {
  prefix: 'fas',
  iconName: 'code',
};

export const fontCaseIconLookupThick: IconLookup = {
  prefix: 'fas',
  iconName: 'font',
};

export const fontCaseIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'font',
};

export const underlineIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'underline',
};

export const italicIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'italic',
};

export const boldIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'bold',
};

export const codeIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'code',
};
// rotate

export const rotateIconLookup: IconLookup = {
  prefix: 'fal',
  iconName: 'rotate',
};
export const rotateThickIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'rotate',
};

// calendar

export const calendarIconLookup: IconLookup = {
  prefix: 'fal',
  iconName: 'calendar',
};
export const calendarThickIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'calendar',
};

// angle down arrow
export const arrowAngleDownLookup: IconLookup = {
  prefix: 'far',
  iconName: 'angle-down',
};

// angle up arrow
export const arrowAngleUpLookup: IconLookup = {
  prefix: 'far',
  iconName: 'angle-up',
};

// bolt (thunder)
export const boltIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'bolt',
};

// gift
export const giftIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'gift',
};

// gift
export const plusSquareIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'plus-square',
};

// tag
export const tagIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'tag',
};

// url link
export const urlLinkIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'link',
};

//WIZARD

export const checkedIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'check',
};

// BLOCK EDITOR
export const messageSmileIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'message-smile',
};

export const plusIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'plus',
};

export const minusIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'minus',
};

export const desktopIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'display',
};

export const mobileIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'mobile-screen-button',
};

export const angleRightIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'angle-right',
};

export const trashAltIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'trash-alt',
};

export const textIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'text',
};
export const percentageIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'percent',
};
export const moreInfoIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'info-circle',
};

export const commentSmileIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'comment-alt-smile',
};

export const errorIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'triangle-exclamation',
};

export const warningIconLookup: IconLookup = {
  prefix: 'fas',
  iconName: 'circle-exclamation',
};
// PRESET EDITOR
export const slashLinkIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'link-simple-slash',
};
export const badgePercentIconLookup: IconLookup = {
  prefix: 'far',
  iconName: 'badge-percent',
};

export { faVectorSquare };
