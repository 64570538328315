import { BlockStack, Box, Checkbox, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  OfferCombinationDetailsDto,
  OfferCombinationsDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CanCombineWithModal } from '../CanCombineWithModal/CanCombineWithModal';

export type CombinationsProductDiscountProps = {
  setIsChanged: (val: boolean) => void;
  onCombinationsDraftStateUpdate: (data: OfferCombinationsDto) => void;
  setShowBanner: (val: boolean) => void;
  shippingDiscounts?: OfferCombinationDetailsDto;
  productDiscounts?: OfferCombinationDetailsDto;
  orderDiscounts?: OfferCombinationDetailsDto;
};

export const CombinationsProductDiscount: React.FC<
  CombinationsProductDiscountProps
> = (props) => {
  const {
    productDiscounts,
    shippingDiscounts,
    orderDiscounts,
    onCombinationsDraftStateUpdate,
    setIsChanged,
    setShowBanner,
  } = props;
  const [i18n] = useI18n();

  const productDiscountsCheckedDefault = useMemo(
    () => !!(productDiscounts?.isEnforced || productDiscounts?.enabled),
    [productDiscounts?.isEnforced, productDiscounts?.enabled]
  );
  const shippingDiscountsCheckedDefault = useMemo(
    () => !!(shippingDiscounts?.isEnforced || shippingDiscounts?.enabled),
    [shippingDiscounts?.isEnforced, shippingDiscounts?.enabled]
  );
  const orderDiscountsCheckedDefault = useMemo(
    () => !!(orderDiscounts?.isEnforced || orderDiscounts?.enabled),
    [orderDiscounts?.isEnforced, orderDiscounts?.enabled]
  );

  const [productDiscountsChecked, setProductDiscountsChecked] =
    useState<boolean>(productDiscountsCheckedDefault);
  const [shippingDiscountsChecked, setShippingDiscountsChecked] =
    useState<boolean>(shippingDiscountsCheckedDefault);
  const [orderDiscountsChecked, setOrderDiscountsChecked] = useState<boolean>(
    orderDiscountsCheckedDefault
  );

  const handleShippingDiscountsChange = useCallback(
    (newChecked: boolean) => {
      setShippingDiscountsChecked(newChecked);
      setIsChanged(true);
    },
    [setShippingDiscountsChecked, setIsChanged]
  );
  const handleProductDiscountsChange = useCallback(
    (newChecked: boolean) => {
      setProductDiscountsChecked(newChecked);
      setIsChanged(true);
    },
    [setProductDiscountsChecked, setIsChanged]
  );
  const handleOrderDiscountsChange = useCallback(
    (newChecked: boolean) => {
      setOrderDiscountsChecked(newChecked);
      setIsChanged(true);
    },
    [setProductDiscountsChecked, setIsChanged]
  );

  useEffect(() => {
    onCombinationsDraftStateUpdate({
      shipping: {
        enabled: shippingDiscountsChecked,
        stackingBehavior: shippingDiscounts?.stackingBehavior,
      },
      product: {
        enabled: productDiscountsChecked,
        stackingBehavior: productDiscounts?.stackingBehavior,
      },
      order: {
        enabled: orderDiscountsChecked,
        stackingBehavior: orderDiscounts?.stackingBehavior,
      },
    });
  }, [
    productDiscountsChecked,
    shippingDiscountsChecked,
    orderDiscountsChecked,
    productDiscounts,
    shippingDiscounts,
    orderDiscounts,
    onCombinationsDraftStateUpdate,
  ]);

  useEffect(() => {
    setShowBanner(
      productDiscountsChecked &&
        [orderDiscountsChecked, shippingDiscountsChecked].includes(true)
    );
  }, [
    productDiscountsChecked,
    orderDiscountsChecked,
    shippingDiscountsChecked,
  ]);

  return (
    <>
      <Box paddingBlockEnd='300'>
        <Text as='p'>
          {i18n.translate('CombinationsProductDiscount.Title')}
        </Text>
      </Box>
      <BlockStack>
        <div>
          <Checkbox
            label={
              i18n.translate('CombinationsProductDiscount.ProductDiscounts') ||
              'Other product discounts'
            }
            checked={productDiscountsChecked}
            disabled={productDiscounts?.isEnforced}
            onChange={handleProductDiscountsChange}
          />
          {productDiscountsChecked && (
            <Box paddingInlineStart='600'>
              <Text as='span' tone='subdued'>
                <CanCombineWithModal
                  type={productDiscounts?.type}
                  count={productDiscounts?.combinedCount || 0}
                  modalTitle={i18n.translate(
                    'CombinationsProductDiscount.ProductDiscountModalTitle'
                  )}
                  text={i18n.translate(
                    'CombinationsProductDiscount.CheckboxInfo.ProductDiscounts'
                  )}
                />
                <Text as='span' tone='subdued'>
                  {i18n.translate(
                    'CombinationsProductDiscount.CheckboxInfo.SetToCombine',
                    {
                      irregular:
                        (productDiscounts?.combinedCount || 0) > 1
                          ? i18n.translate(
                              'CombinationsProductDiscount.CheckboxInfo.are'
                            )
                          : i18n.translate(
                              'CombinationsProductDiscount.CheckboxInfo.is'
                            ),
                    }
                  )}
                </Text>
              </Text>
            </Box>
          )}
        </div>
        <div>
          <Checkbox
            label={i18n.translate('CombinationsProductDiscount.OrderDiscounts')}
            checked={orderDiscountsChecked}
            disabled={orderDiscounts?.isEnforced}
            onChange={handleOrderDiscountsChange}
          />
          {orderDiscountsChecked && (
            <Box paddingInlineStart='600'>
              <Text as='span' tone='subdued'>
                <CanCombineWithModal
                  type={orderDiscounts?.type}
                  count={orderDiscounts?.combinedCount || 0}
                  modalTitle={i18n.translate(
                    'CombinationsProductDiscount.ProductDiscountModalTitle'
                  )}
                  text={i18n.translate(
                    'CombinationsProductDiscount.CheckboxInfo.OrderDiscount'
                  )}
                />
                <Text as='span' tone='subdued'>
                  {i18n.translate(
                    'CombinationsProductDiscount.CheckboxInfo.SetToCombine',
                    {
                      irregular:
                        (orderDiscounts?.combinedCount || 0) > 1
                          ? i18n.translate(
                              'CombinationsProductDiscount.CheckboxInfo.are'
                            )
                          : i18n.translate(
                              'CombinationsProductDiscount.CheckboxInfo.is'
                            ),
                    }
                  )}
                </Text>
              </Text>
            </Box>
          )}
        </div>
        <div>
          <Checkbox
            label={
              i18n.translate('CombinationsProductDiscount.ShippingDiscounts') ||
              'Shipping discounts'
            }
            disabled={shippingDiscounts?.isEnforced}
            checked={shippingDiscountsChecked}
            onChange={handleShippingDiscountsChange}
          />
          {shippingDiscountsChecked && (
            <Box paddingInlineStart='600'>
              <Text as='span' tone='subdued'>
                <CanCombineWithModal
                  type={shippingDiscounts?.type}
                  count={shippingDiscounts?.combinedCount || 0}
                  modalTitle={i18n.translate(
                    'CombinationsProductDiscount.ShippingDiscountModalTitle'
                  )}
                  text={i18n.translate(
                    'CombinationsProductDiscount.CheckboxInfo.ShippingDiscounts'
                  )}
                />
                <Text as='span' tone='subdued'>
                  {i18n.translate(
                    'CombinationsProductDiscount.CheckboxInfo.SetToCombine',
                    {
                      irregular:
                        (shippingDiscounts?.combinedCount || 0) > 1
                          ? i18n.translate(
                              'CombinationsProductDiscount.CheckboxInfo.are'
                            )
                          : i18n.translate(
                              'CombinationsProductDiscount.CheckboxInfo.is'
                            ),
                    }
                  )}
                </Text>
              </Text>
            </Box>
          )}
        </div>
      </BlockStack>
    </>
  );
};
