import { cloneDeep } from 'lodash';
import { useState, useCallback } from 'react';

export type ObjValidity = {
  id: number;
  isValid: boolean;
};

type UpdateValidityFn = (index: number, isValid: boolean) => void;

const useValidityUpdater = (
  validationAmount: number,
  initialValidities?: ObjValidity[]
): [ObjValidity[], UpdateValidityFn] => {
  const [currentObjectsValidities, setCurrentObjectsValidities] = useState<
    ObjValidity[]
  >(initialValidities || []);

  const updateValidity = useCallback<UpdateValidityFn>(
    (index: number, isValid: boolean) => {
      const objectsValiditiesCopy = currentObjectsValidities?.length
        ? cloneDeep(currentObjectsValidities)
        : cloneDeep(
            [...Array(validationAmount)].map((e, idx) => {
              return {
                id: idx,
                isValid: true,
              };
            })
          );

      const currentSectionIndex = objectsValiditiesCopy?.findIndex(
        (el: ObjValidity) => el.id === index
      );
      if (currentSectionIndex > -1) {
        objectsValiditiesCopy[currentSectionIndex].isValid = isValid;
      } else {
        objectsValiditiesCopy.push({
          id: index,
          isValid,
        });
      }
      setCurrentObjectsValidities(objectsValiditiesCopy);
    },
    [currentObjectsValidities, validationAmount]
  );

  return [currentObjectsValidities, updateValidity];
};

export default useValidityUpdater;
