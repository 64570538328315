import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import resolveEnvVar from 'env-var-resolver';

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitAuthApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: resolveEnvVar('REACT_APP_AUTH_API_URL'),
  }),
  reducerPath: 'authApi',
  endpoints: () => ({}),
});
