import { Layout, Page, Range } from '@shopify/polaris';
import { DateRangeBar } from 'core/components';
import { addDays } from 'date-fns/esm';
import { usePromotionActivityData } from 'mocks';
import React, { useCallback, useState } from 'react';
import { PromotionActivityCard } from './components/PromotionActivityCard/PromotionActivityCard';

export const PromotionActivity = () => {
  const { data, isLoading, fetchData } = usePromotionActivityData();
  const [dateNow] = useState<Date>(new Date());
  const [dateRange, setDateRange] = useState<Range>({
    start: addDays(dateNow, -30),
    end: dateNow,
  });

  const handleChangeDateRange = useCallback(
    (dateRange?: Range) => {
      fetchData(dateRange);
      setDateRange(dateRange!);
    },
    [fetchData]
  );

  return (
    <Page fullWidth>
      <Layout>
        <Layout.Section>
          <DateRangeBar
            onChangeDateRange={handleChangeDateRange}
            onRefresh={handleChangeDateRange}
            startDate={dateRange.start}
            endDate={dateRange.end}
            disableDatesAfter={dateNow}
          />
        </Layout.Section>
        <Layout.Section>
          <PromotionActivityCard data={data} isLoading={isLoading} />
        </Layout.Section>
      </Layout>
    </Page>
  );
};
