import React, { useCallback, useMemo, useState } from 'react';
import './SettingsDiscountLinks.scss';
import {
  ActionList,
  Bleed,
  BlockStack,
  Box,
  Button,
  Card,
  Icon,
  IconSource,
  InlineStack,
  Link,
  Page,
  Popover,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  LinkIcon,
  ClipboardIcon,
  HomeIcon,
  MenuHorizontalIcon,
  PlusIcon,
  CollectionIcon,
  ProductIcon,
  BlogIcon,
  PageIcon,
  EditIcon,
  DeleteIcon,
} from '@shopify/polaris-icons';
import {
  PromotionFetchTypeEnum,
  useConfigurePromotions,
} from 'features/promotions/hooks/useConfigurePromotion';
import {
  TriggerDiscountLinkDto,
  TriggerDiscountLinkLandingPageTypeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { TriggerDiscountLinkLandingPageTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { CreateLinkModal } from './components/CreateLinkModal/CreateLinkModal';
import { Loader } from 'core/components';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'core/hooks';
import { setToastMessages } from 'core/store/offersWizardSlice';

export const SettingsDiscountLinks: React.FC = () => {
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    discountLinkListData,
    discountLinkListIsFetching,
    deleteDiscountLinkIsLoading,
    deleteDiscountLink,
    refetchDiscountLinkList,
  } = useConfigurePromotions(PromotionFetchTypeEnum.DISCOUNT_LINKS);
  const [openLinkModal, setOpenLinkModal] = useState<boolean>(false);
  const [currentDiscountLink, setCurrentDiscountLink] =
    useState<TriggerDiscountLinkDto | null>(null);
  const [activePopover, setActivePopover] = useState<string | undefined>('');

  const getLandingPageIcon = useCallback(
    (link?: TriggerDiscountLinkLandingPageTypeDto): IconSource => {
      switch (link) {
        case TriggerDiscountLinkLandingPageTypeDtoEnum.HOME_PAGE:
          return HomeIcon;
        case TriggerDiscountLinkLandingPageTypeDtoEnum.COLLECTION:
          return CollectionIcon;
        case TriggerDiscountLinkLandingPageTypeDtoEnum.PRODUCT:
          return ProductIcon;
        case TriggerDiscountLinkLandingPageTypeDtoEnum.BLOG_POST:
          return BlogIcon;
        case TriggerDiscountLinkLandingPageTypeDtoEnum.CUSTOM_PAGE:
          return PageIcon;
        default:
          return HomeIcon;
      }
    },
    []
  );

  const onCopyLink = useCallback((value?: string) => {
    dispatch(
      setToastMessages({
        error: false,
        message: 'CopiedToClipboard',
      })
    );
    navigator.clipboard.writeText(`${value}`);
  }, []);

  const onDeleteDiscountLink = useCallback(
    (id: string) => {
      setActivePopover('');
      deleteDiscountLink(id).then(() => refetchDiscountLinkList());
    },
    [deleteDiscountLink]
  );

  const renderDiscountLink = useCallback(
    (link: TriggerDiscountLinkDto) => {
      return (
        <Box
          key={link.id}
          padding='300'
          borderBlockStartWidth='025'
          borderColor='border-brand'
        >
          <InlineStack wrap={false} gap='300'>
            <Box>
              <Icon
                tone='base'
                source={getLandingPageIcon(link?.landingPageType)}
              />
            </Box>
            <Box width='100%'>
              <BlockStack gap='200'>
                <InlineStack align='space-between'>
                  <Text as='p' tone='subdued'>
                    {i18n.translate(`${link.landingPageType}`)}
                  </Text>
                  <Popover
                    active={activePopover === link.id}
                    activator={
                      <Button
                        variant='monochromePlain'
                        icon={MenuHorizontalIcon}
                        onClick={() => setActivePopover(link.id as string)}
                      />
                    }
                    autofocusTarget='first-node'
                    onClose={() => setActivePopover('')}
                  >
                    <ActionList
                      actionRole='menuitem'
                      items={[
                        {
                          content: i18n.translate('Edit'),
                          onAction: () => {
                            setCurrentDiscountLink(link);
                            setActivePopover('');
                            toggleOpenLinkModal();
                          },
                          prefix: <Icon source={EditIcon} />,
                        },
                        {
                          content: i18n.translate('Remove'),
                          destructive: true,
                          onAction: () =>
                            onDeleteDiscountLink(link.id as string),
                          prefix: <Icon tone='critical' source={DeleteIcon} />,
                        },
                      ]}
                    />
                  </Popover>
                </InlineStack>
                {link.vanityLinkPreview && (
                  <InlineStack align='space-between'>
                    <InlineStack gap='200'>
                      <Box width='72px'>
                        <Text as='p' tone='subdued'>
                          {i18n.translate('VanityLink')}
                        </Text>
                      </Box>

                      <Text as='p'>
                        {link.vanityLinkPreview?.display || ''}
                      </Text>
                    </InlineStack>

                    <Button
                      onClick={() => onCopyLink(link.vanityLinkPreview?.copy)}
                      icon={ClipboardIcon}
                      variant='monochromePlain'
                    />
                  </InlineStack>
                )}

                <InlineStack align='space-between'>
                  <InlineStack gap='200'>
                    <Box width='72px'>
                      <Text as='p' tone='subdued'>
                        {i18n.translate('Link')}
                      </Text>
                    </Box>
                    <Text as='p'>{link.linkPreview?.display || ''}</Text>
                  </InlineStack>

                  <Button
                    onClick={() => onCopyLink(link.linkPreview?.copy)}
                    icon={ClipboardIcon}
                    variant='monochromePlain'
                  />
                </InlineStack>
              </BlockStack>
            </Box>
          </InlineStack>
        </Box>
      );
    },
    [activePopover]
  );

  const toggleOpenLinkModal = useCallback(() => {
    if (openLinkModal) {
      setCurrentDiscountLink(null);
    }
    setOpenLinkModal((prevState: boolean) => !prevState);
  }, [setOpenLinkModal, openLinkModal]);

  const vanityLinksUsed = useMemo(
    () =>
      discountLinkListData?.links
        ?.filter(
          (link) =>
            link.vanityLinkOption?.path?.trim() !== '' &&
            link.vanityLinkOption?.enabled &&
            currentDiscountLink?.id !== link.id
        )
        .map((link) => link.vanityLinkOption!.path!),
    [discountLinkListData?.links, currentDiscountLink?.id]
  );
  return (
    <>
      <div className='SettingsDiscountLinks'>
        <Page
          backAction={{ onAction: () => navigate('..', { relative: 'path' }) }}
          primaryAction={{
            content: i18n.translate('CreateDiscount'),
            onAction: toggleOpenLinkModal,
          }}
          title={i18n.translate('DiscountLinks')}
        >
          <Card>
            <BlockStack gap='400'>
              <BlockStack gap='100'>
                <Text as='h2' fontWeight='semibold'>
                  {i18n.translate('DiscountLinks')}
                </Text>
                <Text as='p' tone='subdued'>
                  {i18n.translate('DiscountLinksSubtitle')}
                </Text>
              </BlockStack>
              <Box
                borderRadius='200'
                borderWidth='025'
                borderColor='border-brand'
              >
                <Box padding='300' background='bg-fill-active'>
                  <InlineStack align='space-between'>
                    <InlineStack gap='300'>
                      <Box>
                        <Icon tone='base' source={LinkIcon} />
                      </Box>
                      <InlineStack gap='200'>
                        <Text as='p' tone='subdued'>
                          {i18n.translate('DefaultLink')}
                        </Text>
                        <Text as='p' tone='subdued'>
                          {discountLinkListData?.default?.display}
                        </Text>
                      </InlineStack>
                    </InlineStack>
                    <InlineStack blockAlign='center' gap='300'>
                      {(discountLinkListIsFetching ||
                        deleteDiscountLinkIsLoading) && (
                        <div className='SmallLoader'>
                          <Loader size='small' />
                        </div>
                      )}
                      <Button
                        onClick={() =>
                          onCopyLink(discountLinkListData?.default?.copy)
                        }
                        icon={ClipboardIcon}
                        variant='monochromePlain'
                      />
                    </InlineStack>
                  </InlineStack>
                </Box>
                {!!discountLinkListData?.links?.length &&
                  discountLinkListData.links.map((link) =>
                    renderDiscountLink(link)
                  )}
                <Box
                  padding='300'
                  borderBlockStartWidth='025'
                  borderColor='border-brand'
                >
                  <BlockStack align='center' inlineAlign='start'>
                    <Button
                      onClick={toggleOpenLinkModal}
                      variant='monochromePlain'
                      icon={PlusIcon}
                    >
                      {i18n.translate('CreateDiscount')}
                    </Button>
                  </BlockStack>
                </Box>
              </Box>
              <Bleed marginBlockEnd='400' marginInline='400'>
                <Box background='bg-surface-secondary' padding='400'>
                  {i18n.translate('ToLearnMore', {
                    followingArticle: (
                      <Link>{i18n.translate('followingArticle')}</Link>
                    ),
                  })}
                </Box>
              </Bleed>
            </BlockStack>
          </Card>
        </Page>
      </div>
      {openLinkModal && (
        <CreateLinkModal
          isModalOpen={openLinkModal}
          refetchList={refetchDiscountLinkList}
          onClose={toggleOpenLinkModal}
          onCopyLink={onCopyLink}
          discountLink={currentDiscountLink}
          vanityLinksUsed={vanityLinksUsed}
        />
      )}
    </>
  );
};
