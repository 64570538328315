import React from 'react';
import { Card, BlockStack, Text, TextField, Grid } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { AccountSettingsPrimaryUserDto } from 'core/api/adminSettings/adminSettingsApi';

type ProfileProps = {
  data: AccountSettingsPrimaryUserDto;
  handleAccountLocaleState: (data: AccountSettingsPrimaryUserDto) => void;
};

export const Profile: React.FC<ProfileProps> = ({
  data,
  handleAccountLocaleState,
}) => {
  const [i18n] = useI18n();

  return (
    <div className='ProfileComponent'>
      <Card roundedAbove='sm' padding='400'>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='h2' variant='headingSm'>
              {i18n.translate('ProfileTitle')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('ProfileSubtitle')}
            </Text>
          </BlockStack>
          <Grid>
            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
              <TextField
                autoComplete='on'
                name='firstName'
                label={i18n.translate('ProfileFirstNameLabel')}
                placeholder={i18n.translate('ProfileFirstNamePlaceholder')}
                value={data?.firstName}
                onChange={(value) =>
                  handleAccountLocaleState({ ...data, firstName: value })
                }
              />
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
              <TextField
                autoComplete='on'
                name='lastName'
                label={i18n.translate('ProfileLastNameLabel')}
                placeholder={i18n.translate('ProfileLastNamePlaceholder')}
                value={data?.lastName}
                onChange={(value) =>
                  handleAccountLocaleState({ ...data, lastName: value })
                }
              />
            </Grid.Cell>
          </Grid>
        </BlockStack>
      </Card>
    </div>
  );
};
