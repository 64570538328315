import React from 'react';
import './AnnouncementBarPosition.scss';
import { useI18n } from '@shopify/react-i18n';
import { AnnouncementBarPagePositionDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { AnnouncementBarPagePositionDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import StyleComponentHeader from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components/StyleComponentHeader/StyleComponentHeader';

type PositionProps = {
  position: AnnouncementBarPagePositionDto;
  disabled?: boolean;
  setPosition?: (value: AnnouncementBarPagePositionDto) => void;
  onSystemReset?: () => void;
};
export const AnnouncementBarPosition: React.FC<PositionProps> = ({
  position,
  disabled,
  setPosition,
  onSystemReset,
}) => {
  const [i18n] = useI18n();

  const positionOptions = Object.values(AnnouncementBarPagePositionDtoEnum).map(
    (value) => {
      return {
        value: value,
        label: i18n.translate(`${value}`),
      };
    }
  );
  return (
    <div className='AnnouncementBarPosition'>
      <StyleComponentHeader
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            disabled: disabled,
            onAction: () => onSystemReset?.(),
          },
        ]}
        label={i18n.translate(`Position`)}
      >
        <div className='PositionRow'>
          {i18n.translate(`Position`)}
          <div className='RightContent'>
            <SelectOptions
              options={positionOptions}
              onOptionSelect={(value) =>
                setPosition?.(value as AnnouncementBarPagePositionDtoEnum)
              }
              selectedOption={
                position || AnnouncementBarPagePositionDtoEnum.ABOVE_HEADER
              }
              label=''
              disabled={disabled}
            />
          </div>
        </div>
      </StyleComponentHeader>
    </div>
  );
};
