import { Checkbox } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { NotificationMinimizingBehaviorShopSettingsDto } from 'core/api/adminWidgets/adminWidgetsApi';
import StyleComponentHeader from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components/StyleComponentHeader/StyleComponentHeader';
import React from 'react';
type MinimizingNotificationProps = {
  minimizing: NotificationMinimizingBehaviorShopSettingsDto;
  disabled?: boolean;
  setMinimizing?: (data: NotificationMinimizingBehaviorShopSettingsDto) => void;
  onSystemReset?: () => void;
};
const MinimizingNotification: React.FC<MinimizingNotificationProps> = ({
  minimizing,
  disabled,
  setMinimizing,
  onSystemReset,
}) => {
  const [i18n] = useI18n();
  return (
    <div className='MinimizingNotification'>
      <StyleComponentHeader
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            disabled: disabled,
            onAction: () => onSystemReset?.(),
          },
        ]}
        label={i18n.translate(`MinimizingNotification`)}
      >
        <Checkbox
          label={i18n.translate('MinimizeAfterFirstView')}
          disabled={disabled}
          checked={!!minimizing.minimizeAfterFirstView}
          onChange={(value) =>
            setMinimizing?.({ ...minimizing, minimizeAfterFirstView: value })
          }
        />
        <Checkbox
          label={i18n.translate('MinimizeAutomatically')}
          disabled={disabled}
          checked={!!minimizing.minimizeAutomatically}
          onChange={(value) =>
            setMinimizing?.({ ...minimizing, minimizeAutomatically: value })
          }
        />
      </StyleComponentHeader>
    </div>
  );
};

export default MinimizingNotification;
