import React from 'react';
import { Banner, BlockStack, Card, Link, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';

export const MoreIntegrations = () => {
  const [i18n] = useI18n();

  return (
    <Card roundedAbove='sm' padding='400'>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate('MoreIntegrationsTitle')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('MoreIntegrationsDescription')}
          </Text>
        </BlockStack>
        <Banner tone='info'>
          {i18n.translate('Banner', {
            DiscountNinjaDeveloperHub: (
              <Link url='http://www.google.com' target='_blank'>
                {i18n.translate('DiscountNinjaDeveloperHub')}
              </Link>
            ),
            CheckOutOurAPI: (
              <Link url='http://www.google.com' target='_blank'>
                {i18n.translate('CheckOutOurAPI')}
              </Link>
            ),
            Documentation: (
              <Link url='http://www.google.com' target='_blank'>
                {i18n.translate('Documentation')}
              </Link>
            ),
          })}
        </Banner>
      </BlockStack>
    </Card>
  );
};
