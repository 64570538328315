import React from 'react';
import './PresetPublishModal.scss';
import { ModalCustom } from 'core/components/ModalCustom/ModalCustom';
import { useI18n } from '@shopify/react-i18n';
import { Badge, Divider, Text } from '@shopify/polaris';
import { getPromotionStatusColor } from 'features/promotions/components/PromotionList/utils';
import { PromotionStatusDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { Loader } from 'core/components';
import {
  GetAffectedPromotionsByPresetResponseDto,
  WidgetTypeDto,
  // useGetWidgetsV6PresetsAffectedPromotionsGlobalQuery,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { getKeyByValue } from '../../utils/utils';
import { WidgetListTypeDtoEnum } from '../../enums/enums';
import resolveEnvVar from 'env-var-resolver';

const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';

type PresetPublishModalProps = {
  isOpen: boolean;
  onPublish: () => void;
  onClose: () => void;
  onPublishIsLoading?: boolean;
  affectedPromotions: GetAffectedPromotionsByPresetResponseDto;
  affectedPromotionsIsFetching: boolean;
  globalPresetWidget?: string;
  presetName?: string;
};
const PresetPublishModal: React.FC<PresetPublishModalProps> = ({
  isOpen,
  affectedPromotions,
  affectedPromotionsIsFetching,
  globalPresetWidget,
  onPublishIsLoading,
  presetName,
  onPublish,
  onClose,
}) => {
  const [i18n] = useI18n();
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';

  // const {
  //   data: affectedGlobalPromotions,
  //   isFetching: affectedGlobalPromotionsIsFetching,
  // } = useGetWidgetsV6PresetsAffectedPromotionsGlobalQuery(
  //   {
  //     'X-LimoniApps-AppName': APP_NAME,
  //     'X-LimoniApps-AppSecret': APP_PASSWORD,
  //     type: getKeyByValue(
  //       WidgetListTypeDtoEnum,
  //       globalPresetWidget as string
  //     ) as WidgetTypeDto,
  //   },
  //   {
  //     refetchOnMountOrArgChange: true,
  //     skip: !globalPresetWidget,
  //   }
  // );

  const isGlobal = !!globalPresetWidget;

  // const currentAffected = globalPresetWidget
  //   ? affectedGlobalPromotions
  //   : affectedPromotions;
  // const currentAffectedIsFetching = globalPresetWidget
  //   ? affectedGlobalPromotionsIsFetching
  //   : affectedPromotionsIsFetching;

  // const activeTotal = currentAffected?.active?.totalCount || 0;
  // const inactiveTotal = currentAffected?.inactive?.totalCount || 0;

  return (
    <ModalCustom
      isOpen={isOpen}
      buttons={[
        {
          content: i18n.translate(`Cancel`),
          primary: false,
          action: () => onClose(),
        },
        {
          content: i18n.translate(isGlobal ? 'Confirm' : 'Publish'),
          primary: true,
          // disabled: currentAffectedIsFetching || onPublishIsLoading,
          action: () => onPublish(),
        },
      ]}
      title={i18n.translate(isGlobal ? 'SelectGlobalPreset' : 'SaveAndPublish')}
      onClose={() => onClose()}
    >
      {/* <div className='PresetPublishModalContent'>
        {!currentAffectedIsFetching ? (
          <>
            <div className='PublishInfoTitle'>
              {i18n.translate(isGlobal ? 'GlobalWillImpact' : 'ThisWillImpact')}
              <span>
                {activeTotal} {i18n.translate(`Active`)}
              </span>
              {i18n.translate(`And`)}
              <span>
                {inactiveTotal} {i18n.translate(`inactive`)}
              </span>
              {i18n.translate(isGlobal ? 'PromotionsAreUsing' : 'promotions')}
            </div>
            <div className='ListsBox'>
              {!!activeTotal && (
                <ul className='AffectedPromotionList'>
                  {currentAffected?.active?.topItems
                    ?.slice(0, 3)
                    ?.map((item) => (
                      <li key={item.name}>
                        <div className='ListItem'>
                          <Text as='p' fontWeight='medium'>
                            {item.name}
                          </Text>
                          <Badge
                            tone={getPromotionStatusColor(
                              item.status as PromotionStatusDtoEnum
                            )}
                          >
                            {i18n.translate(`${item.status}`)}
                          </Badge>
                        </div>
                      </li>
                    ))}
                  {activeTotal > 3 && (
                    <li>
                      +{activeTotal - 3} {i18n.translate(`More`)}
                    </li>
                  )}
                </ul>
              )}

              {!!activeTotal && !!inactiveTotal && <Divider />}
              {!!inactiveTotal && (
                <ul className='AffectedPromotionList'>
                  {currentAffected?.inactive?.topItems
                    ?.slice(0, 3)
                    ?.map((item) => (
                      <li key={item.name}>
                        <div className='ListItem'>
                          <Text as='p' fontWeight='medium'>
                            {item.name}
                          </Text>
                          <Badge
                            tone={getPromotionStatusColor(
                              item.status as PromotionStatusDtoEnum
                            )}
                          >
                            {i18n.translate(`${item.status}`)}
                          </Badge>
                        </div>
                      </li>
                    ))}
                  {inactiveTotal > 3 && (
                    <li>
                      +{inactiveTotal - 3} {i18n.translate(`More`)}
                    </li>
                  )}
                </ul>
              )}
              <Text as='p'>
                {isGlobal ? (
                  <>
                    {i18n.translate('WantToSelect')}
                    <b>{presetName}</b>
                    {i18n.translate('AsGlobalPreset')}
                  </>
                ) : (
                  i18n.translate(`WantPublishChanges`)
                )}
              </Text>
            </div>
          </>
        ) : (
          <Loader fullWidth />
        )}
      </div> */}
    </ModalCustom>
  );
};

export default PresetPublishModal;
