import {
  NotificationCollapsedStateGeneralPresetDto,
  NotificationCollapsedStateLevelPresetDto,
  NotificationSystemDefaultsDto,
  PresetsSetupDto,
  PromotionSetupDtoRead,
} from 'core/api/adminWidgets/adminWidgetsApi';
import {
  NotificationStatesPromotionEntryTypeDtoEnum,
  WidgetTypeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import { SelectedOptionPath } from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightSideBarWrapper';
import { getLastSelectedOption } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import React, { useCallback, useMemo } from 'react';
import NotificationGeneral from '../../../../../EditPresetRightBar/components/PresetNotification/components/NotificationGeneral/NotificationGeneral';
import {
  WidgetListOptionsDtoEnum,
  WidgetListTypeDtoEnum,
} from 'features/settings/components/EditPresetSkeleton/enums/enums';
import NotificationHeader from '../../../../../EditPresetRightBar/components/PresetNotification/components/NotificationHeader/NotificationHeader';
type PromotionNotificationCollapsedProps = {
  editPromotionState: PromotionSetupDtoRead;
  defaultPromotionData: PromotionSetupDtoRead;
  shopDefaultData: NotificationSystemDefaultsDto;
  selectedOptionPath: SelectedOptionPath;
  updateEditPromotionState: (
    field: keyof PromotionSetupDtoRead,
    data: PromotionSetupDtoRead[keyof PromotionSetupDtoRead],
    presetSetup?: PresetsSetupDto
  ) => void;
  filterSupportedWidgets: (value: WidgetTypeDtoEnum) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const PromotionNotificationCollapsed: React.FC<
  PromotionNotificationCollapsedProps
> = ({
  editPromotionState,
  defaultPromotionData,
  shopDefaultData,
  selectedOptionPath,
  updateEditPromotionState,
  filterSupportedWidgets,
  handleLeftMenuSelection,
}) => {
  const { notification, presetSetup } = editPromotionState;
  const notificationPreset = presetSetup?.notification?.options?.collapsedState;

  const defaultNotification =
    defaultPromotionData.notification?.options?.collapsedState;
  const defaultPresetSetup =
    defaultPromotionData.presetSetup?.notification?.options?.collapsedState;

  const updateNotificationGeneral = useCallback(
    (data: NotificationCollapsedStateGeneralPresetDto) =>
      updateEditPromotionState('notification', {
        ...notification,
        options: {
          ...notification?.options,
          collapsedState: {
            ...notification?.options?.collapsedState,
            general: data,
          },
        },
      }),
    [notification, updateEditPromotionState]
  );

  const updateCollapsedOptions = useCallback(
    (
      field: keyof NotificationCollapsedStateLevelPresetDto,
      data: NotificationCollapsedStateLevelPresetDto[keyof NotificationCollapsedStateLevelPresetDto]
    ) =>
      updateEditPromotionState('presetSetup', {
        ...presetSetup,
        notification: {
          ...presetSetup?.notification,
          options: {
            ...presetSetup?.notification?.options,
            collapsedState: {
              ...presetSetup?.notification?.options?.collapsedState,
              options: {
                ...presetSetup?.notification?.options?.collapsedState?.options,
                [field]: data,
              },
            },
          },
        },
      }),
    [presetSetup, updateEditPromotionState]
  );

  const notificationOptions = useMemo(() => {
    const selectedOption = getLastSelectedOption(selectedOptionPath);

    switch (true) {
      case selectedOption?.name === WidgetListTypeDtoEnum.NOTIFICATION:
        return (
          <NotificationGeneral
            general={notification?.options?.collapsedState?.general || {}}
            defaultGeneral={defaultNotification?.general || {}}
            shopDefaultGeneral={shopDefaultData.collapsedState?.general || {}}
            notificationState={
              NotificationStatesPromotionEntryTypeDtoEnum.COLLAPSED_STATE
            }
            presetSetup={notificationPreset?.general || {}}
            setGeneral={updateNotificationGeneral}
            onShowHide={
              notification?.canBeHidden
                ? () =>
                    updateEditPromotionState('notification', {
                      ...notification,
                      isHidden: !notification.isHidden,
                    })
                : undefined
            }
            onRemove={() =>
              filterSupportedWidgets(WidgetTypeDtoEnum.NOTIFICATION)
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.HEADER:
        return (
          <NotificationHeader
            header={notificationPreset?.options?.header || {}}
            defaultHeader={defaultPresetSetup?.options?.header || {}}
            defaultLanguage={editPromotionState.offers?.defaultLanguage || ''}
            shopDefaultHeader={
              shopDefaultData.collapsedState?.options?.header || {}
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
            notificationState={
              NotificationStatesPromotionEntryTypeDtoEnum.COLLAPSED_STATE
            }
            onShowHide={() =>
              notificationPreset?.options?.header?.canBeHidden
                ? updateCollapsedOptions('header', {
                    ...notificationPreset?.options?.header,
                    isHidden: !notificationPreset?.options?.header?.isHidden,
                  })
                : undefined
            }
            setHeader={(data) => updateCollapsedOptions('header', data)}
          />
        );
    }
  }, [
    notification,
    defaultNotification,
    notificationPreset,
    defaultPresetSetup,
    editPromotionState,
    selectedOptionPath,
    updateCollapsedOptions,
    handleLeftMenuSelection,
  ]);
  return <>{notificationOptions}</>;
};

export default PromotionNotificationCollapsed;
