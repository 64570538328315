import { IndexTable, SkeletonDisplayText } from '@shopify/polaris';
import { IndexTableHeading } from '@shopify/polaris/build/ts/src/components/IndexTable';
import { NonEmptyArray } from '@shopify/polaris/build/ts/src/types';
import { useI18n } from '@shopify/react-i18n';
import React, { useMemo } from 'react';
const PromotionListSkeleton: React.FC = () => {
  const [i18n] = useI18n();

  const headings: NonEmptyArray<IndexTableHeading> = useMemo(
    () => [
      { title: i18n.translate('PromotionName') },
      { title: i18n.translate('Token') },
      { title: i18n.translate('Status') },
      { title: i18n.translate('Schedule') },
      { title: i18n.translate('Trigger') },
      { title: i18n.translate('Type') },
      { title: i18n.translate('Modified') },
      { title: i18n.translate('Tags') },
    ],
    [i18n]
  );
  const rowMarkup = useMemo(() => {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((row, index) => (
      <IndexTable.Row disabled key={index} id={`${row}`} position={row}>
        <IndexTable.Cell>
          <SkeletonDisplayText size='small' maxWidth={`380px` as any} />
        </IndexTable.Cell>

        <IndexTable.Cell>
          <SkeletonDisplayText size='small' maxWidth={`80px` as any} />
        </IndexTable.Cell>

        <IndexTable.Cell>
          <SkeletonDisplayText size='small' maxWidth={`112px` as any} />
        </IndexTable.Cell>
        <IndexTable.Cell>
          <SkeletonDisplayText size='small' maxWidth={`136px` as any} />
        </IndexTable.Cell>

        <IndexTable.Cell>
          <SkeletonDisplayText size='small' maxWidth={`136px` as any} />
        </IndexTable.Cell>

        <IndexTable.Cell>
          <SkeletonDisplayText size='small' maxWidth={`136px` as any} />
        </IndexTable.Cell>
        <IndexTable.Cell>
          <SkeletonDisplayText size='small' maxWidth={`120px` as any} />
        </IndexTable.Cell>
        <IndexTable.Cell>
          <SkeletonDisplayText size='small' />
        </IndexTable.Cell>
      </IndexTable.Row>
    ));
  }, []);
  return (
    <IndexTable itemCount={5} headings={headings}>
      {rowMarkup}
    </IndexTable>
  );
};

export default PromotionListSkeleton;
