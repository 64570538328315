import React from 'react';
type IconProps = {
  color?: string;
  direction: 'up' | 'down';
};
export const DiagonalArrowIcon: React.FC<IconProps> = ({
  color,
  direction,
}) => {
  return direction === 'up' ? (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        d='M8.75 7C8.33579 7 8 7.33579 8 7.75C8 8.16421 8.33579 8.5 8.75 8.5H10.4393L7.21967 11.7197C6.92678 12.0126 6.92678 12.4874 7.21967 12.7803C7.51256 13.0732 7.98744 13.0732 8.28033 12.7803L11.5 9.56066V11.25C11.5 11.6642 11.8358 12 12.25 12C12.6642 12 13 11.6642 13 11.25V7.75C13 7.33579 12.6642 7 12.25 7H8.75Z'
        fill={color}
      />
    </svg>
  ) : (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        d='M13 8.75C13 8.33579 12.6642 8 12.25 8C11.8358 8 11.5 8.33579 11.5 8.75L11.5 10.4393L8.28033 7.21967C7.98744 6.92678 7.51256 6.92678 7.21967 7.21967C6.92678 7.51256 6.92678 7.98744 7.21967 8.28033L10.4393 11.5L8.75 11.5C8.33579 11.5 8 11.8358 8 12.25C8 12.6642 8.33579 13 8.75 13L12.25 13C12.6642 13 13 12.6642 13 12.25L13 8.75Z'
        fill={color}
      />
    </svg>
  );
};
