import React from 'react';
import { useI18n } from '@shopify/react-i18n';
import { LinkComponentDto } from 'core/api/adminWidgets/adminWidgetsApi';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import { Checkbox, TextField } from '@shopify/polaris';

type LinkProps = {
  link?: LinkComponentDto;
  setLink: (value: LinkComponentDto) => void;
  onSystemReset: () => void;
};
export const Link: React.FC<LinkProps> = ({ link, setLink, onSystemReset }) => {
  const [i18n] = useI18n();

  return (
    <div className='Link'>
      <StyleComponentHeader
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            onAction: () => onSystemReset(),
          },
        ]}
        tip='ccdc'
        label={i18n.translate(`Link`)}
      >
        <TextField
          value={link?.link || ''}
          onChange={(value) => setLink({ ...link, link: value })}
          autoComplete=''
          label=''
        />
        <Checkbox
          label={i18n.translate(`OpenLink`)}
          checked={link?.openInNewTab || false}
          onChange={(value) => setLink({ ...link, openInNewTab: value })}
        />
      </StyleComponentHeader>
    </div>
  );
};
