import React, { useCallback, useRef } from 'react';
import { Box, Page } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { useNavigate } from 'react-router-dom';
import { ReportListTypeEnum } from 'pages';
import {
  AnalyticsReportList,
  AnalyticsReportListRef,
} from '../Charts/AnalyticsReportList/AnalyticsReportList';
import useExportListReports from 'features/analytics/utils/useExportListReports';
type DetailedListPageProps = {
  listType: ReportListTypeEnum;
};
export const DetailedListPage: React.FC<DetailedListPageProps> = ({
  listType,
}) => {
  const [i18n] = useI18n();
  const listRef = useRef<AnalyticsReportListRef>(null);
  const { exportReportIsFetching, exportReportList } =
    useExportListReports(listType);

  const handleRefreshList = useCallback(
    () => listRef.current?.refresh(),
    [listRef.current]
  );
  const navigate = useNavigate();
  return (
    <Page
      backAction={{ onAction: () => navigate(-1) }}
      fullWidth
      title={i18n.translate(`${listType}_TITLE`)}
      secondaryActions={[
        {
          content: i18n.translate('Refresh'),
          onAction: handleRefreshList,
        },
        {
          content: i18n.translate('Export'),
          loading: exportReportIsFetching,
          onAction: exportReportList,
        },
      ]}
    >
      <Box paddingBlockEnd='500'>
        <AnalyticsReportList ref={listRef} listType={listType} />
      </Box>
    </Page>
  );
};
