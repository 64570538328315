import {
  OfferWidgetSetupDtoPromotionSummaryOfferDtoRead,
  PromotionSummaryPresetDtoRead,
  PromotionWidgetSetupMultiLevelDtoPromotionSummaryPromotionDtoPromotionSummaryLevelPromotionDtoPromotionSummaryLevelPromotionEntryTypeDtoRead,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { LineWeightTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';

export type PromotionWidgetSetupDtoPromotionSummaryPromotionDtoReadWithOffers =
  PromotionWidgetSetupMultiLevelDtoPromotionSummaryPromotionDtoPromotionSummaryLevelPromotionDtoPromotionSummaryLevelPromotionEntryTypeDtoRead & {
    offers?: OfferWidgetSetupDtoPromotionSummaryOfferDtoRead[] | null;
  };

export const promotionSummaryMergeFunc = (
  widget: PromotionWidgetSetupDtoPromotionSummaryPromotionDtoReadWithOffers,
  setup?: PromotionSummaryPresetDtoRead
) => {
  const {
    isShopLevelWidget,
    canBeDragged,
    canBeRemoved,
    offers,
    options,
    general,
    ...newWidget
  } = widget;

  const { general: generalSetup, options: optionsSetup } =
    setup?.general && setup?.options ? setup : { general: null, options: null };

  return {
    ...newWidget,
    availableEntries: undefined,
    ...(generalSetup
      ? {
          general: {
            ...generalSetup,
            background: {
              ...generalSetup.background,
              border: {
                ...generalSetup.background?.border,
                value: {
                  ...generalSetup.background?.border?.value,
                  lineWeight: {
                    ...generalSetup.background?.border?.value?.lineWeight,
                    customValue:
                      generalSetup.background?.border?.value?.lineWeight
                        ?.type === LineWeightTypeDtoEnum.CUSTOM
                        ? generalSetup.background?.border?.value?.lineWeight
                            ?.customValue
                        : undefined,
                  },
                },
              },
            },
            cornerRadius: {
              ...generalSetup.cornerRadius,
              customValue:
                generalSetup.cornerRadius?.type === LineWeightTypeDtoEnum.CUSTOM
                  ? generalSetup.cornerRadius?.customValue
                  : undefined,
            },
          },
        }
      : {
          general: {
            ...general,
            background: {
              ...general?.background,
              border: {
                ...general?.background?.border,
                value: {
                  ...general?.background?.border?.value,
                  lineWeight: {
                    ...general?.background?.border?.value?.lineWeight,
                    customValue:
                      general?.background?.border?.value?.lineWeight?.type ===
                      LineWeightTypeDtoEnum.CUSTOM
                        ? general?.background?.border?.value?.lineWeight
                            ?.customValue
                        : undefined,
                  },
                },
              },
            },
            cornerRadius: {
              ...general?.cornerRadius,
              customValue:
                general?.cornerRadius?.type === LineWeightTypeDtoEnum.CUSTOM
                  ? general?.cornerRadius?.customValue
                  : undefined,
            },
          },
        }),
    ...(optionsSetup
      ? {
          options: {
            total: {
              ...optionsSetup.total,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              sectionDivider: {
                ...optionsSetup.total?.sectionDivider,
                value: {
                  ...optionsSetup.total?.sectionDivider?.value,
                  lineWeight: {
                    ...optionsSetup.total?.sectionDivider?.value?.lineWeight,
                    customValue:
                      optionsSetup.total?.sectionDivider?.value?.lineWeight
                        ?.type === LineWeightTypeDtoEnum.CUSTOM
                        ? optionsSetup.total?.sectionDivider?.value?.lineWeight
                            ?.customValue
                        : undefined,
                  },
                },
              },
              label: {
                ...optionsSetup.total?.label,
                message: {
                  ...optionsSetup.total?.label?.message,
                  settings: undefined,
                },
                style: {
                  ...optionsSetup.total?.label?.style,
                  style: {
                    ...optionsSetup.total?.label?.style?.style,
                    fontSize: {
                      ...optionsSetup.total?.label?.style?.style?.fontSize,
                      mobile: optionsSetup.total?.label?.style?.style
                        ?.differentOnMobile
                        ? optionsSetup.total?.label?.style?.style?.fontSize
                            ?.mobile
                        : undefined,
                    },
                    lineHeight: {
                      ...optionsSetup.total?.label?.style?.style?.lineHeight,
                      mobile: optionsSetup.total?.label?.style?.style
                        ?.differentOnMobile
                        ? optionsSetup.total?.label?.style?.style?.lineHeight
                            ?.mobile
                        : undefined,
                    },
                    fontWeight: {
                      ...optionsSetup.total?.label?.style?.style?.fontWeight,
                      mobile: optionsSetup.total?.label?.style?.style
                        ?.differentOnMobile
                        ? optionsSetup.total?.label?.style?.style?.fontWeight
                            ?.mobile
                        : undefined,
                    },
                  },
                },
              },
            },
            taxesAndDuties: {
              ...optionsSetup.taxesAndDuties,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              description: {
                ...optionsSetup.taxesAndDuties?.description,
                message: {
                  ...optionsSetup.taxesAndDuties?.description?.message,
                  settings: undefined,
                },
                style: {
                  ...optionsSetup.taxesAndDuties?.description?.style,
                  style: {
                    ...optionsSetup.taxesAndDuties?.description?.style?.style,
                    fontSize: {
                      ...optionsSetup.taxesAndDuties?.description?.style?.style
                        ?.fontSize,
                      mobile: optionsSetup.taxesAndDuties?.description?.style
                        ?.style?.differentOnMobile
                        ? optionsSetup.taxesAndDuties?.description?.style?.style
                            ?.fontSize?.mobile
                        : undefined,
                    },
                    lineHeight: {
                      ...optionsSetup.taxesAndDuties?.description?.style?.style
                        ?.lineHeight,
                      mobile: optionsSetup.taxesAndDuties?.description?.style
                        ?.style?.differentOnMobile
                        ? optionsSetup.taxesAndDuties?.description?.style?.style
                            ?.lineHeight?.mobile
                        : undefined,
                    },
                    fontWeight: {
                      ...optionsSetup.taxesAndDuties?.description?.style?.style
                        ?.fontWeight,
                      mobile: optionsSetup.taxesAndDuties?.description?.style
                        ?.style?.differentOnMobile
                        ? optionsSetup.taxesAndDuties?.description?.style?.style
                            ?.fontWeight?.mobile
                        : undefined,
                    },
                  },
                },
              },
              label: {
                ...optionsSetup.taxesAndDuties?.label,
                settings: undefined,
              },
            },
            subtotal: {
              ...optionsSetup.subtotal,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              label: {
                ...optionsSetup.subtotal?.label,
                settings: undefined,
              },
            },
            shipping: {
              ...optionsSetup.shipping,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              label: {
                ...optionsSetup.shipping?.label,
                settings: undefined,
              },
            },
            disclaimer: {
              ...optionsSetup.disclaimer,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              text: {
                ...optionsSetup.disclaimer?.text,
                value: {
                  ...optionsSetup.disclaimer?.text?.value,
                  message: {
                    ...optionsSetup.disclaimer?.text?.value?.message,
                    settings: undefined,
                  },
                  style: {
                    ...optionsSetup.disclaimer?.text?.value?.style,
                    style: {
                      ...optionsSetup.disclaimer?.text?.value?.style?.style,
                      fontSize: {
                        ...optionsSetup.disclaimer?.text?.value?.style?.style
                          ?.fontSize,
                        mobile: optionsSetup.disclaimer?.text?.value?.style
                          ?.style?.differentOnMobile
                          ? optionsSetup.disclaimer?.text?.value?.style?.style
                              ?.fontSize?.mobile
                          : undefined,
                      },
                      lineHeight: {
                        ...optionsSetup.disclaimer?.text?.value?.style?.style
                          ?.lineHeight,
                        mobile: optionsSetup.disclaimer?.text?.value?.style
                          ?.style?.differentOnMobile
                          ? optionsSetup.disclaimer?.text?.value?.style?.style
                              ?.lineHeight?.mobile
                          : undefined,
                      },
                      fontWeight: {
                        ...optionsSetup.disclaimer?.text?.value?.style?.style
                          ?.fontWeight,
                        mobile: optionsSetup.disclaimer?.text?.value?.style
                          ?.style?.differentOnMobile
                          ? optionsSetup.disclaimer?.text?.value?.style?.style
                              ?.fontWeight?.mobile
                          : undefined,
                      },
                    },
                  },
                },
              },
            },
            discount: {
              ...optionsSetup.discount,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              key: undefined,
              label: {
                ...optionsSetup.discount?.label,
                settings: undefined,
              },
            },
          },
          offers: offers?.map((offer) => {
            const {
              canBeDragged,
              canBeRemoved,
              parentKey,
              canBeHidden,
              isHidden,
              setup,
              ...newOffer
            } = offer;

            return {
              ...(canBeHidden && { isHidden }),
              ...newOffer,
              setup: {
                ...setup,
                availableEntries: undefined,
                general: {
                  ...setup?.general,
                  label: {
                    ...setup?.general?.label,
                    message: {
                      ...setup?.general?.label?.message,
                      settings: undefined,
                    },
                    style: {
                      ...setup?.general?.label?.style,
                      style: {
                        ...setup?.general?.label?.style?.style,
                        fontSize: {
                          ...setup?.general?.label?.style?.style?.fontSize,
                          mobile: setup?.general?.label?.style?.style
                            ?.differentOnMobile
                            ? setup?.general?.label?.style?.style?.fontSize
                                ?.mobile
                            : undefined,
                        },
                        lineHeight: {
                          ...setup?.general?.label?.style?.style?.lineHeight,
                          mobile: setup?.general?.label?.style?.style
                            ?.differentOnMobile
                            ? setup?.general?.label?.style?.style?.lineHeight
                                ?.mobile
                            : undefined,
                        },
                        fontWeight: {
                          ...setup?.general?.label?.style?.style?.fontWeight,
                          mobile: setup?.general?.label?.style?.style
                            ?.differentOnMobile
                            ? setup?.general?.label?.style?.style?.fontWeight
                                ?.mobile
                            : undefined,
                        },
                      },
                    },
                  },
                },
              },
            };
          }),
        }
      : {
          options: {
            total: {
              ...options?.total,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              sectionDivider: {
                ...options?.total?.sectionDivider,
                value: {
                  ...options?.total?.sectionDivider?.value,
                  lineWeight: {
                    ...options?.total?.sectionDivider?.value?.lineWeight,
                    customValue:
                      options?.total?.sectionDivider?.value?.lineWeight
                        ?.type === LineWeightTypeDtoEnum.CUSTOM
                        ? options?.total?.sectionDivider?.value?.lineWeight
                            ?.customValue
                        : undefined,
                  },
                },
              },
              label: {
                ...options?.total?.label,
                message: {
                  ...options?.total?.label?.message,
                  settings: undefined,
                },
                style: {
                  ...options?.total?.label?.style,
                  style: {
                    ...options?.total?.label?.style?.style,
                    fontSize: {
                      ...options?.total?.label?.style?.style?.fontSize,
                      mobile: options?.total?.label?.style?.style
                        ?.differentOnMobile
                        ? options?.total?.label?.style?.style?.fontSize?.mobile
                        : undefined,
                    },
                    lineHeight: {
                      ...options?.total?.label?.style?.style?.lineHeight,
                      mobile: options?.total?.label?.style?.style
                        ?.differentOnMobile
                        ? options?.total?.label?.style?.style?.lineHeight
                            ?.mobile
                        : undefined,
                    },
                    fontWeight: {
                      ...options?.total?.label?.style?.style?.fontWeight,
                      mobile: options?.total?.label?.style?.style
                        ?.differentOnMobile
                        ? options?.total?.label?.style?.style?.fontWeight
                            ?.mobile
                        : undefined,
                    },
                  },
                },
              },
            },
            taxesAndDuties: {
              ...options?.taxesAndDuties,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              description: {
                ...options?.taxesAndDuties?.description,
                message: {
                  ...options?.taxesAndDuties?.description?.message,
                  settings: undefined,
                },
                style: {
                  ...options?.taxesAndDuties?.description?.style,
                  style: {
                    ...options?.taxesAndDuties?.description?.style?.style,
                    fontSize: {
                      ...options?.taxesAndDuties?.description?.style?.style
                        ?.fontSize,
                      mobile: options?.taxesAndDuties?.description?.style?.style
                        ?.differentOnMobile
                        ? options?.taxesAndDuties?.description?.style?.style
                            ?.fontSize?.mobile
                        : undefined,
                    },
                    lineHeight: {
                      ...options?.taxesAndDuties?.description?.style?.style
                        ?.lineHeight,
                      mobile: options?.taxesAndDuties?.description?.style?.style
                        ?.differentOnMobile
                        ? options?.taxesAndDuties?.description?.style?.style
                            ?.lineHeight?.mobile
                        : undefined,
                    },
                    fontWeight: {
                      ...options?.taxesAndDuties?.description?.style?.style
                        ?.fontWeight,
                      mobile: options?.taxesAndDuties?.description?.style?.style
                        ?.differentOnMobile
                        ? options?.taxesAndDuties?.description?.style?.style
                            ?.fontWeight?.mobile
                        : undefined,
                    },
                  },
                },
              },
              label: {
                ...options?.taxesAndDuties?.label,
                settings: undefined,
              },
            },
            subtotal: {
              ...options?.subtotal,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              label: {
                ...options?.subtotal?.label,
                settings: undefined,
              },
            },
            shipping: {
              ...options?.shipping,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              label: {
                ...options?.shipping?.label,
                settings: undefined,
              },
            },
            disclaimer: {
              ...options?.disclaimer,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              text: {
                ...options?.disclaimer?.text,
                value: {
                  ...options?.disclaimer?.text?.value,
                  message: {
                    ...options?.disclaimer?.text?.value?.message,
                    settings: undefined,
                  },
                  style: {
                    ...options?.disclaimer?.text?.value?.style,
                    style: {
                      ...options?.disclaimer?.text?.value?.style?.style,
                      fontSize: {
                        ...options?.disclaimer?.text?.value?.style?.style
                          ?.fontSize,
                        mobile: options?.disclaimer?.text?.value?.style?.style
                          ?.differentOnMobile
                          ? options?.disclaimer?.text?.value?.style?.style
                              ?.fontSize?.mobile
                          : undefined,
                      },
                      lineHeight: {
                        ...options?.disclaimer?.text?.value?.style?.style
                          ?.lineHeight,
                        mobile: options?.disclaimer?.text?.value?.style?.style
                          ?.differentOnMobile
                          ? options?.disclaimer?.text?.value?.style?.style
                              ?.lineHeight?.mobile
                          : undefined,
                      },
                      fontWeight: {
                        ...options?.disclaimer?.text?.value?.style?.style
                          ?.fontWeight,
                        mobile: options?.disclaimer?.text?.value?.style?.style
                          ?.differentOnMobile
                          ? options?.disclaimer?.text?.value?.style?.style
                              ?.fontWeight?.mobile
                          : undefined,
                      },
                    },
                  },
                },
              },
            },
            discount: {
              ...options?.discount,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              key: undefined,
              label: {
                ...options?.discount?.label,
                settings: undefined,
              },
            },
          },
        }),
  };
};
