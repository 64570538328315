import { DatePicker } from 'core/components/ControlLibrary';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CalendarIcon } from '@shopify/polaris-icons';
import { Button, Popover, Range, Select, LegacyStack } from '@shopify/polaris';
import { getMonth, getYear, addMonths, isEqual } from 'date-fns';
import {
  formatDate,
  formatDateFromString,
  isBeforeOrEqual,
  validMaxDate,
} from 'core/utils';
import { addDays } from 'date-fns/esm';
import { DateField } from '../DateField/DateField';
import { useI18n } from '@shopify/react-i18n';

export type DateMonthYear = {
  month: number;
  year: number;
};

type DateRangeProps = {
  startDate?: Date;
  endDate?: Date;
  disableDatesAfter?: Date;

  onChangeDateRange?: (dateRange: Range) => void;
};

type DateRangePreset = 'Last 7 days' | 'Last 30 days' | 'Last 90 days';

export const DateRange: React.FC<DateRangeProps> = (props) => {
  const { startDate, endDate, disableDatesAfter, onChangeDateRange } = props;
  const [i18n] = useI18n();
  const [now] = useState<Date>(new Date());
  const [initialDateRange, setInitialDateRange] = useState<Range>({
    start: startDate || addMonths(now, -1),
    end: endDate || now,
  });
  const [dateRange, setDateRange] = useState<Range>({
    start: initialDateRange.start,
    end: initialDateRange.end,
  });
  const [{ month, year }, setDate] = useState<DateMonthYear>({
    month: getMonth(dateRange.start),
    year: getYear(dateRange.end),
  });
  const [fieldValue, setFieldValue] = useState<string>('');
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);
  const [dateRangePreset, setDateRangePreset] = useState<DateRangePreset>();
  const [startDateInputValue, setStartDateInputValue] = useState<string>(
    formatDate(dateRange.start)
  );
  const [endDateInputValue, setEndDateInputValue] = useState<string>(
    formatDate(dateRange.end)
  );

  useEffect(() => {
    if (startDate && endDate) {
      setInitialDateRange({ start: startDate, end: endDate });
      setDate({
        month: getMonth(addMonths(endDate, -1)),
        year: getYear(endDate),
      });
    }
  }, [startDate, endDate, setInitialDateRange]);

  const handleDateChange = useCallback(
    (date: Range) => {
      setDateRange(date);
      setStartDateInputValue(formatDate(date.start));
      setEndDateInputValue(formatDate(date.end));
      setDateRangePreset(undefined);
    },
    [setDateRange]
  );

  const handleMonthChange = useCallback(
    (month: number, year: number) => {
      setDate({ month, year });
    },
    [setDate]
  );

  const formatedInputDate = useMemo(() => {
    const { start, end } = dateRange;
    const startDate = formatDate(start, i18n);
    const endDate = formatDate(end, i18n);
    return isEqual(start, end) ? startDate : `${startDate} - ${endDate}`;
  }, [dateRange, i18n]);

  useEffect(() => {
    setFieldValue(formatedInputDate);
  }, [formatedInputDate, setFieldValue]);

  const setDateRangeByPreset = useCallback(
    (dateRangePreset: DateRangePreset) => {
      let startDate = now;
      if (dateRangePreset === 'Last 7 days') {
        startDate = addDays(now, -7);
      }
      if (dateRangePreset === 'Last 30 days') {
        startDate = addDays(now, -30);
      }
      if (dateRangePreset === 'Last 90 days') {
        startDate = addDays(now, -90);
      }
      setDateRange({
        start: startDate,
        end: now,
      });
      setDate({
        month: getMonth(startDate),
        year: getYear(now),
      });
      setStartDateInputValue(formatDate(startDate));
      setEndDateInputValue(formatDate(now));
    },
    [now]
  );

  const handleDateRangePresetChange = useCallback(
    (dateRangePreset: DateRangePreset) => {
      setDateRangePreset(dateRangePreset);
      setDateRangeByPreset(dateRangePreset);
    },
    [setDateRangePreset, setDateRangeByPreset]
  );

  const dateRangePresets: DateRangePreset[] = useMemo(
    () => ['Last 7 days', 'Last 30 days', 'Last 90 days'],
    []
  );

  const handleApply = useCallback(() => {
    setOpenDatePicker(false);
    onChangeDateRange?.(dateRange);
    setDateRangePreset(undefined);
    setStartDateInputValue(formatDate(dateRange.start));
    setEndDateInputValue(formatDate(dateRange.end));
  }, [dateRange, onChangeDateRange, setOpenDatePicker]);

  const handleCancel = useCallback(() => {
    setOpenDatePicker(false);
    setDateRange(initialDateRange);
    setDate({
      month: getMonth(addMonths(initialDateRange.end, -1)),
      year: getYear(initialDateRange.end),
    });
    setDateRangePreset(undefined);
  }, [initialDateRange, setDateRange, setOpenDatePicker, setDateRangePreset]);

  const handleChangeStartDateInputValue = useCallback(
    (value: string) => {
      const startDate = formatDateFromString(value);
      const endDate = formatDateFromString(endDateInputValue);
      setStartDateInputValue(value);

      if (
        validMaxDate(startDate, disableDatesAfter) &&
        isBeforeOrEqual(startDate, endDate)
      ) {
        setDateRange({
          start: startDate,
          end: endDate,
        });
        setDate({
          month: getMonth(startDate),
          year: getYear(startDate),
        });
        setDateRangePreset(undefined);
      }
    },
    [endDateInputValue, disableDatesAfter, setDateRange]
  );

  const handleChangeEndDateInputValue = useCallback(
    (value: string) => {
      setEndDateInputValue(value);
      const startDate = formatDateFromString(startDateInputValue);
      const endDate = formatDateFromString(value);
      if (
        validMaxDate(endDate, disableDatesAfter) &&
        isBeforeOrEqual(startDate, endDate)
      ) {
        setDateRange({
          start: startDate,
          end: endDate,
        });
        setDate({
          month: getMonth(addMonths(endDate, -1)),
          year: getYear(endDate),
        });
        setDateRangePreset(undefined);
      }
    },
    [startDateInputValue, disableDatesAfter, setDateRange]
  );

  const startDateMax = useMemo(() => {
    return disableDatesAfter
      ? isBeforeOrEqual(dateRange.end, disableDatesAfter)
        ? dateRange.end
        : disableDatesAfter
      : dateRange.end;
  }, [disableDatesAfter, dateRange]);

  return (
    <Popover
      active={openDatePicker}
      activator={
        <Button icon={<CalendarIcon />} onClick={() => setOpenDatePicker(true)}>
          {fieldValue}
        </Button>
      }
      onClose={handleCancel}
      preferredAlignment='left'
    >
      <Popover.Section>
        <LegacyStack vertical>
          <Select
            label='Date range'
            placeholder='Custom date range'
            options={dateRangePresets}
            onChange={handleDateRangePresetChange}
            value={dateRangePreset}
          />
          <LegacyStack vertical={false} distribution='fill'>
            <DateField
              value={startDateInputValue}
              label='Starting'
              max={startDateMax}
              onChange={handleChangeStartDateInputValue}
            />
            <DateField
              value={endDateInputValue}
              label='Ending'
              max={disableDatesAfter}
              min={dateRange.start}
              onChange={handleChangeEndDateInputValue}
            />
          </LegacyStack>
          <DatePicker
            month={month}
            year={year}
            selected={dateRange}
            disableDatesAfter={disableDatesAfter}
            onChange={handleDateChange}
            onMonthChange={handleMonthChange}
            multiMonth
            allowRange
          />
        </LegacyStack>
      </Popover.Section>
      <Popover.Section>
        <LegacyStack distribution='equalSpacing'>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleApply} variant='primary'>
            Apply
          </Button>
        </LegacyStack>
      </Popover.Section>
    </Popover>
  );
};
