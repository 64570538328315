import React, { useMemo } from 'react';
import { BlockStack, Card, ChoiceList, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { Element } from 'react-scroll';

type CodesSupportSettingProps = {
  allow: boolean;
  setAllow: (value: boolean) => void;
};
export const CodesSupportSetting: React.FC<CodesSupportSettingProps> = ({
  allow,
  setAllow,
}) => {
  const [i18n] = useI18n();

  const codesSupportOptions = useMemo(
    () => [
      { label: i18n.translate('AllowMultiple'), value: '1' },
      { label: i18n.translate('DontAllowMultiple'), value: '' },
    ],
    [i18n]
  );

  return (
    <Element name='CodesSupportSetting' className='CodesSupportSetting'>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('MultipleCodesSupport')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('AllowVisitors')}
            </Text>
          </BlockStack>
          <ChoiceList
            title=''
            choices={codesSupportOptions}
            selected={[allow ? '1' : '']}
            onChange={(value) => setAllow(!!value[0])}
          />
        </BlockStack>
      </Card>
    </Element>
  );
};
