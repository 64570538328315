import { Button, LegacyStack } from '@shopify/polaris';
import { PromotionReportType } from 'features/reporting/types';
import { startCase } from 'lodash';
import React, { useCallback, useState } from 'react';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { sleep } from 'core/utils';

type PromotionsResultExportBarProps = {
  data: PromotionReportType[];
};

export const PromotionsResultExportBar: React.FC<
  PromotionsResultExportBarProps
> = (props) => {
  const { data } = props;
  const [isExportingToCSV, setIsExportingToCSV] = useState<boolean>(false);
  const [isExportingToExcel, setIsExportingToExcel] = useState<boolean>(false);

  const createWorkbook = useCallback(() => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Promotion Report', {
      views: [{ state: 'frozen', ySplit: 1 }],
    });
    const headers = Object.keys(data[0]);
    worksheet.columns = headers.map((key: string, idx: number) => ({
      key,
      header: startCase(key),
      width:
        idx === 0
          ? data.reduce((w, r) => Math.max(w, r.Promotion.length + 6), 10)
          : key.length + 6,
      style: {
        alignment: idx === 0 ? { horizontal: 'left' } : { horizontal: 'right' },
      },
    }));
    worksheet.addRows(data);
    return workbook;
  }, [data]);

  const downloadCsvFile = useCallback(async () => {
    setIsExportingToCSV(true);
    await sleep();
    const workbook = createWorkbook();
    workbook.csv.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `Promotion_Report_${new Date().toISOString()}.csv`
      );

      setIsExportingToCSV(false);
    });
  }, [createWorkbook]);

  const downloadExcelFile = useCallback(async () => {
    setIsExportingToExcel(true);
    await sleep();
    const workbook = createWorkbook();
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `Promotion_Report_${new Date().toISOString()}.xlsx`
      );
      setIsExportingToExcel(false);
    });
  }, [createWorkbook]);

  return (
    <LegacyStack spacing='baseTight'>
      <Button onClick={downloadCsvFile} loading={isExportingToCSV}>
        Export to CSV
      </Button>
      <Button onClick={downloadExcelFile} loading={isExportingToExcel}>
        Export to Excel
      </Button>
    </LegacyStack>
  );
};
