import {
  configureStore,
  ThunkAction,
  Action,
  isRejectedWithValue,
  Dispatch,
  AnyAction,
  MiddlewareAPI,
  Middleware,
} from '@reduxjs/toolkit';
import { emptySplitAuthApi } from 'core/api/auth';
import { emptySplitAdminSettingsApi } from 'core/api/adminSettings';
import { emptySplitAdminPromotionsApi } from 'core/api/adminPromotions/emptyAdminPromotionsApi';
import { emptySplitAdminWidgetsApi } from 'core/api/adminWidgets';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import mainNavReducer from './mainNavSlice';
import authReducer from './authSlice';
import promotionsReducer from './promotionsSlice';
import offersWizardReducer, { setToastMessages } from './offersWizardSlice';
import settingsReducer from './settingsSlice';
import widgetsReducer from './widgetsSlice';
import { emptySplitAdminAnalyticsApi } from 'core/api/adminAnalytics';

// eslint-disable-next-line
const handleRejectedAction = (dispatch: Dispatch<AnyAction>, action: any) => {
  if (isRejectedWithValue(action)) {
    dispatch(
      setToastMessages({
        error: true,
        message: action?.payload?.data?.errors?.[0] || '',
      })
    );
  }
};

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    const dispatch = api.dispatch;
    handleRejectedAction(dispatch, action);
    return next(action);
  };
// adminPromotionsApi: emptySplitAdminPromotionsApi.reducer,
export const store = configureStore({
  reducer: {
    mainNav: mainNavReducer,
    auth: authReducer,
    [emptySplitAuthApi.reducerPath]: emptySplitAuthApi.reducer,
    [emptySplitAdminSettingsApi.reducerPath]:
      emptySplitAdminSettingsApi.reducer,
    [emptySplitAdminPromotionsApi.reducerPath]:
      emptySplitAdminPromotionsApi.reducer,
    [emptySplitAdminWidgetsApi.reducerPath]: emptySplitAdminWidgetsApi.reducer,
    [emptySplitAdminAnalyticsApi.reducerPath]:
      emptySplitAdminAnalyticsApi.reducer,
    promotions: promotionsReducer,
    offersWizard: offersWizardReducer,
    settings: settingsReducer,
    widgets: widgetsReducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(emptySplitAuthApi.middleware)
      .concat(emptySplitAdminSettingsApi.middleware)
      .concat(emptySplitAdminPromotionsApi.middleware)
      .concat(emptySplitAdminWidgetsApi.middleware)
      .concat(emptySplitAdminAnalyticsApi.middleware),
    rtkQueryErrorLogger,
  ],
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
