import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './PresetChangesBadge.scss';
import {
  BlockStack,
  Box,
  Collapsible,
  InlineStack,
  Text,
} from '@shopify/polaris';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  EditIcon,
} from '@shopify/polaris-icons';
import classNames from 'classnames';
import { PromotionPresetsDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { useI18n } from '@shopify/react-i18n';
import { IconButton } from 'core/components';
import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { useConfigureWidgets } from 'features/settings/hooks/useConfigureWidgets';
import { setIsChangePresetLoading } from 'core/store/widgetsSlice';
import { PresetsListModal } from 'features/settings/components/GeneralSettings/Style/components/PresetsListModal/PresetsListModal';
import { PresetBoundariesTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { PaletteIcon } from 'core/components/WidgetEditor/assets/icons';

export type PromotionPresetsChangesDto = {
  promotionLevel: boolean;
  shopLevel: boolean;
};

type PromotionPresetsDtoProps = {
  presets: PromotionPresetsDto;
  presetsChanges: PromotionPresetsChangesDto;
  handlePresetChangeData: (presetLevel: PresetBoundariesTypeDtoEnum) => void;
};
export type PresetLevelDto = 'shopLevel' | 'promotionLevel';
const PresetChangesBadge: React.FC<PromotionPresetsDtoProps> = ({
  presets,
  presetsChanges,
  handlePresetChangeData,
}) => {
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();
  const { saveEditPromotionChanges } = useConfigureWidgets();
  const { isChangePresetLoading } = useAppSelector((store) => store.widgets);
  const [presetModalOpen, setPresetModalOpen] =
    useState<PresetBoundariesTypeDtoEnum | null>(null);
  const [isPresetsCollapsed, setIsPresetsCollapsed] = useState<boolean>(false);
  const presetHaveChanges =
    !presetsChanges.promotionLevel || !presetsChanges.shopLevel;
  const iconTone = presetHaveChanges ? 'inherit' : 'base';

  const saveSelectedPreset = useCallback(
    (level: PresetLevelDto, presetId: string) => {
      dispatch(setIsChangePresetLoading(true));
      saveEditPromotionChanges({
        presets: {
          [level]: {
            id: presetId,
          },
        },
      }).then(async () => {
        handlePresetChangeData(
          level === 'shopLevel'
            ? PresetBoundariesTypeDtoEnum.SHOP_LEVEL
            : PresetBoundariesTypeDtoEnum.PROMOTION_LEVEL
        );
      });
    },
    [
      setIsChangePresetLoading,
      handlePresetChangeData,
      dispatch,
      saveEditPromotionChanges,
    ]
  );

  const presetLevels = useMemo(() => {
    return Object.keys(presetsChanges).map((level) => {
      const preset = presets[level as keyof PromotionPresetsDto] || {};
      const isShopLevel = level === 'shopLevel';
      const levelEnum = isShopLevel
        ? PresetBoundariesTypeDtoEnum.SHOP_LEVEL
        : PresetBoundariesTypeDtoEnum.PROMOTION_LEVEL;
      const hasChanges = !presetsChanges[level as keyof PromotionPresetsDto];
      return !isEmpty(preset) ? (
        <BlockStack gap='200' key={level}>
          <Text as='p' tone='subdued'>
            {i18n.translate(
              isShopLevel ? 'StyleShopLevel' : 'StylePromotionLevel'
            )}
          </Text>
          <div
            className={classNames('PresetBox', {
              HasChanges: hasChanges,
            })}
          >
            <InlineStack align='space-between' blockAlign='center' wrap={false}>
              <InlineStack wrap={false} gap='200' blockAlign='start'>
                <Box paddingBlock='100'>
                  <PaletteIcon
                    color={hasChanges ? 'var(--p-color-text-info)' : ''}
                    size={16}
                    crossed={hasChanges}
                  />
                </Box>
                <BlockStack align='start'>
                  <Text as='p' fontWeight='medium'>
                    {preset.name}
                  </Text>
                  <Text as='span'>
                    {hasChanges && (
                      <Text as='span'>
                        {i18n.translate('UnsavedChangesDot')}
                      </Text>
                    )}
                    {isShopLevel
                      ? i18n.translate('UsedAllPromotions')
                      : i18n.translate('UsedBy') +
                        preset.affectedPromotionsCount +
                        i18n.translate(
                          preset.affectedPromotionsCount === 1
                            ? 'Promotion'
                            : 'Promotions'
                        )}
                  </Text>
                </BlockStack>
              </InlineStack>
              <IconButton
                source={EditIcon}
                tone={iconTone}
                onClick={() => setPresetModalOpen(levelEnum)}
              />
            </InlineStack>
          </div>
          {presetModalOpen === levelEnum && (
            <PresetsListModal
              presetsListModalOpen={presetModalOpen === levelEnum}
              selectedPresetId={
                presets[level as keyof PromotionPresetsDto]?.id as string
              }
              boundaries={
                level === 'shopLevel'
                  ? PresetBoundariesTypeDtoEnum.SHOP_LEVEL
                  : PresetBoundariesTypeDtoEnum.PROMOTION_LEVEL
              }
              isLoading={isChangePresetLoading}
              onSave={(id) => saveSelectedPreset(level as PresetLevelDto, id)}
              handleClosePresetsListModal={() => setPresetModalOpen(null)}
            />
          )}
        </BlockStack>
      ) : null;
    });
  }, [presets, presetsChanges, presetModalOpen, isChangePresetLoading]);

  useEffect(() => {
    if (!isChangePresetLoading) {
      setPresetModalOpen(null);
    }
  }, [isChangePresetLoading]);

  return (
    <div className='PresetChangesBadge'>
      <BlockStack gap='400'>
        <div
          className={classNames('PresetBox', {
            HasChanges: presetHaveChanges,
          })}
        >
          <InlineStack align='space-between' blockAlign='center'>
            <InlineStack gap='200' blockAlign='center'>
              <PaletteIcon
                color={presetHaveChanges ? 'var(--p-color-text-info)' : ''}
                size={16}
                crossed={presetHaveChanges}
              />
              <Text as='p'>
                {presetHaveChanges ? (
                  <>
                    {i18n.translate('StylePresetHas')}
                    <Text as='span' fontWeight='medium'>
                      {i18n.translate('UnsavedChanges')}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text as='span' fontWeight='medium'>
                      {i18n.translate('TwoPresetsUsed')}
                    </Text>
                    {i18n.translate('UsedInPromotion')}
                  </>
                )}
              </Text>
            </InlineStack>
            <IconButton
              onClick={() => setIsPresetsCollapsed(!isPresetsCollapsed)}
              source={isPresetsCollapsed ? ChevronDownIcon : ChevronUpIcon}
              tone={iconTone}
            />
          </InlineStack>
        </div>
        <Collapsible id='PresetsCollapsedInfo' open={isPresetsCollapsed}>
          <BlockStack gap='400'>{presetLevels}</BlockStack>
        </Collapsible>
      </BlockStack>
    </div>
  );
};

export default PresetChangesBadge;
