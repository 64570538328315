import React, { useCallback, useMemo } from 'react';
import { BlockStack, Card, Text, TextField } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { AnnouncementBarCyclingDto } from 'core/api/adminSettings/adminSettingsApi';
import { AnnouncementBarCyclingDurationTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import { Element } from 'react-scroll';

type CycleOffersSettingProps = {
  cycling: AnnouncementBarCyclingDto;
  setCycling: (value: AnnouncementBarCyclingDto) => void;
};
export const CycleOffersSetting: React.FC<CycleOffersSettingProps> = ({
  cycling,
  setCycling,
}) => {
  const [i18n] = useI18n();
  const closingOptions = useMemo(
    () =>
      Object.values(AnnouncementBarCyclingDurationTypeDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    []
  );

  const onDurationTimeChange = useCallback(
    (value: string) => {
      setCycling({ ...cycling, customDuration: +value });
    },
    [cycling]
  );

  return (
    <Element className='CycleOffersSetting' name='CycleOffersSetting'>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('CycleOffers')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('Subtitle')}
            </Text>
          </BlockStack>
          <SelectOptions
            options={closingOptions}
            onOptionSelect={(value) => {
              setCycling({
                ...cycling,
                duration: value as AnnouncementBarCyclingDurationTypeDtoEnum,
              });
            }}
            selectedOption={
              cycling.duration ||
              AnnouncementBarCyclingDurationTypeDtoEnum.NORMAL
            }
            label={i18n.translate('OfferDuration')}
          />
          <TextField
            autoComplete=''
            label={i18n.translate('DurationTime')}
            suffix='ms'
            type='number'
            value={cycling.customDuration?.toString() || '3000'}
            onChange={onDurationTimeChange}
            onBlur={() => {
              (cycling.customDuration as number) > 30000
                ? onDurationTimeChange('30000')
                : (cycling.customDuration as number) < 3000
                  ? onDurationTimeChange('3000')
                  : null;
            }}
            disabled={
              cycling.duration !==
              AnnouncementBarCyclingDurationTypeDtoEnum.CUSTOM
            }
          />
        </BlockStack>
      </Card>
    </Element>
  );
};
