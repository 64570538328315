import React from 'react';
import { NavTabs } from 'core/enums/NavTabsEnum';
import { usePageName } from 'core/hooks';
import { Route, Routes } from 'react-router-dom';
import { Analytics } from 'features/analytics/Analytics';
import { DetailedChartPage } from 'features/analytics/components/DetailedChartPage/DetailedChartPage';
import { PolarisVizProvider } from '@shopify/polaris-viz';
import { DetailedListPage } from 'features/analytics/components/DetailedListPage/DetailedListPage';

export enum ReportListTypeEnum {
  PROMOTION_ACTIVITY = 'PROMOTION_ACTIVITY',
  DETAILED_ORDER = 'DETAILED_ORDER',
  DETAILED_CODE = 'DETAILED_CODE',
}

export enum DetailedChartTypeEnum {
  TOTAL_DN_REVENUE = 'TOTAL_DN_REVENUE',
  DN_CONVERSION_RATE = 'DN_CONVERSION_RATE',
  TOTAL_DN_ORDERS = 'TOTAL_DN_ORDERS',
  DN_SESSIONS = 'DN_SESSIONS',
}

export const AnalyticsPage = () => {
  usePageName(NavTabs.Analytics);

  return (
    <PolarisVizProvider>
      <div
        style={{
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <Routes>
          <Route index element={<Analytics />} />
          <Route
            path='total-dn-revenue'
            element={
              <DetailedChartPage
                chartType={DetailedChartTypeEnum.TOTAL_DN_REVENUE}
              />
            }
          />
          <Route
            path='dn-conversion-rate'
            element={
              <DetailedChartPage
                chartType={DetailedChartTypeEnum.DN_CONVERSION_RATE}
              />
            }
          />
          <Route
            path='total-dn-orders'
            element={
              <DetailedChartPage
                chartType={DetailedChartTypeEnum.TOTAL_DN_ORDERS}
              />
            }
          />
          <Route
            path='dn-sesstions'
            element={
              <DetailedChartPage
                chartType={DetailedChartTypeEnum.DN_SESSIONS}
              />
            }
          />
          <Route
            path='promotion-activity-report'
            element={
              <DetailedListPage
                listType={ReportListTypeEnum.PROMOTION_ACTIVITY}
              />
            }
          />
          <Route
            path='order-report'
            element={
              <DetailedListPage listType={ReportListTypeEnum.DETAILED_ORDER} />
            }
          />
          <Route
            path='promotion-code-report'
            element={
              <DetailedListPage listType={ReportListTypeEnum.DETAILED_CODE} />
            }
          />
        </Routes>
      </div>
    </PolarisVizProvider>
  );
};
