import { useAppDispatch, useIsDebugOrLocal } from 'core/hooks';
import { setPassword } from 'core/store/authSlice';
import { useCallback, useMemo, useState } from 'react';

export const PASSWORD_DEV_LOGIN_LOCAL_STORAGE = 'passwordDevLogin';

export const usePasswordDevLogin = () => {
  const dispatch = useAppDispatch();

  const [openPasswordDevLogin, setOpenPasswordDevLogin] = useState<boolean>(
    !localStorage.getItem(PASSWORD_DEV_LOGIN_LOCAL_STORAGE)
  );
  const isDebugOrLocal = useIsDebugOrLocal();

  const showLoginDevModal = useMemo(
    () => openPasswordDevLogin && isDebugOrLocal,
    [openPasswordDevLogin, isDebugOrLocal]
  );

  const toggleOpenLoginDevModal = useCallback(() => {
    setOpenPasswordDevLogin((curr) => !curr);
  }, [setOpenPasswordDevLogin]);

  const setPasswordDevLogin = useCallback(
    (value: string) => {
      dispatch(setPassword({ password: value }));
      localStorage.setItem(PASSWORD_DEV_LOGIN_LOCAL_STORAGE, value);
      toggleOpenLoginDevModal();
    },
    [toggleOpenLoginDevModal, setPassword, dispatch]
  );

  return {
    showLoginDevModal,
    toggleOpenLoginDevModal,
    setPasswordDevLogin,
  };
};
