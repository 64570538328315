import React, { useCallback } from 'react';
import {
  MessageDto,
  NotificationExpandedStateOfferPresetDto,
  NotificationGeneralOfferDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { TextEntries, TextStyle } from '../../../../../components';
import { useI18n } from '@shopify/react-i18n';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { Icon } from '../../../../../components/Icon/Icon';
import { useAppDispatch } from 'core/hooks';
import { setNotificationOfferMessages } from 'core/store/widgetsSlice';
import { ResourceGroupKeyDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { isEqual } from 'lodash';

type NotificationOfferProps = {
  offer: NotificationGeneralOfferDto;
  defaultOffer: NotificationGeneralOfferDto;
  shopDefaultOffer: NotificationExpandedStateOfferPresetDto;
  offerName: string;
  offerId?: string;
  defaultLanguage: string;
  presetSetup?: NotificationExpandedStateOfferPresetDto;
  defaultPresetSetup?: NotificationExpandedStateOfferPresetDto;
  setOffer: (data: NotificationGeneralOfferDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const NotificationOffer: React.FC<NotificationOfferProps> = ({
  offer,
  defaultOffer,
  shopDefaultOffer,
  offerName,
  offerId,
  defaultLanguage,
  presetSetup,
  defaultPresetSetup,
  setOffer,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();
  const handleUpdateOffer = useCallback(
    (
      field: keyof NotificationGeneralOfferDto,
      data: NotificationGeneralOfferDto[keyof NotificationGeneralOfferDto]
    ) => {
      setOffer({
        ...offer,
        icon: {
          ...offer.icon,
          value: {
            ...offer.icon?.value,
            color: presetSetup?.icon?.color,
          },
        },
        text: {
          ...offer.text,
          style: presetSetup?.textStyle,
        },
        [field]: data,
      });
    },
    [offer, setOffer, presetSetup]
  );

  const handleMessageText = useCallback(
    (field: keyof MessageDto, data: MessageDto[keyof MessageDto]) => {
      handleUpdateOffer('text', {
        ...offer.text,
        style: presetSetup?.textStyle,
        [field]: data,
      });
    },
    [offer, handleUpdateOffer, presetSetup]
  );

  const resetAllToSystemDefault = useCallback(() => {
    const { icon, textStyle } = shopDefaultOffer;
    setOffer({
      text: {
        style: textStyle,
        message: defaultOffer.text?.message,
      },
      icon: {
        enabled: defaultOffer.icon?.enabled,
        value: icon,
      },
    });
  }, [shopDefaultOffer, defaultOffer]);

  const onDiscardChanges = useCallback(() => {
    setOffer({
      ...defaultOffer,
      icon: {
        ...defaultOffer.icon,
        value: {
          ...defaultOffer.icon?.value,
          color: defaultPresetSetup?.icon?.color,
        },
      },
      text: {
        ...defaultOffer.text,
        style: defaultPresetSetup?.textStyle,
      },
    });
  }, [defaultOffer, defaultPresetSetup]);

  return (
    <div className='NotificationOffer'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={onDiscardChanges}
        onResetToSystem={resetAllToSystemDefault}
        name={offerName}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <TextEntries
          textMessage={offer.text?.message || {}}
          defaultLanguage={defaultLanguage || ''}
          disableCollapsed
          offerId={offerId}
          disableColor={(presetSetup?.textStyle || {}).color !== undefined}
          setTextMessage={(data) => handleMessageText('message', data)}
          onSystemReset={() =>
            handleMessageText('message', defaultOffer.text?.message)
          }
          setPreviewEntry={(entry) =>
            dispatch(
              setNotificationOfferMessages({
                ...entry,
                offerId: offerId as string,
              })
            )
          }
        />
      </div>
      <div className='RightSideSection'>
        <TextStyle
          textStyle={presetSetup?.textStyle || {}}
          inherited={isEqual(presetSetup?.textStyle, defaultOffer.text?.style)}
          offersNote
          onSystemReset={() =>
            handleMessageText('style', shopDefaultOffer.textStyle)
          }
          setTextStyle={(data) => handleMessageText('style', data)}
        />
      </div>
      <div className='RightSideSection'>
        <Icon
          icon={{ ...offer.icon?.value, color: presetSetup?.icon?.color }}
          groupKey={ResourceGroupKeyDtoEnum.OFFER}
          inherited={isEqual(
            presetSetup?.icon?.color,
            defaultOffer.icon?.value?.color
          )}
          enabled={offer.icon?.enabled}
          enabledText={i18n.translate('EnableIcon')}
          showIcon
          onSystemReset={() =>
            handleUpdateOffer('icon', {
              enabled: defaultOffer.icon?.enabled,
              value: shopDefaultOffer.icon,
            })
          }
          setIcon={(data) =>
            handleUpdateOffer('icon', { ...offer.icon, value: data })
          }
          setEnabled={(value) =>
            handleUpdateOffer('icon', {
              enabled: value,
              value: {
                ...offer.icon?.value,
                color: presetSetup?.icon?.color,
              },
            })
          }
        />
      </div>
    </div>
  );
};

export default NotificationOffer;
