import React, { useCallback } from 'react';
import {
  OfferRulePopupGeneralOfferDto,
  OfferRulePopupGeneralPresetDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { useI18n } from '@shopify/react-i18n';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import {
  Background,
  CornerRadius,
  Icon,
  Shadow,
  ShopSettingsWrapper,
  Size,
} from '../../../../../components';
import { useAppDispatch } from 'core/hooks';
import { ResourceGroupKeyDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { isEqual } from 'lodash';
import { setToggleSettingsConfig } from 'core/store/widgetsSlice';

type OfferRulePopupOfferProps = {
  offer: OfferRulePopupGeneralOfferDto;
  defaultOffer: OfferRulePopupGeneralOfferDto;
  defaultPresetSetup: OfferRulePopupGeneralPresetDto;
  shopDefaultOffer: OfferRulePopupGeneralPresetDto;
  offerName: string;
  setOffer: (data: OfferRulePopupGeneralOfferDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const OfferRulePopupOffer: React.FC<OfferRulePopupOfferProps> = ({
  offer,
  defaultOffer,
  offerName,
  shopDefaultOffer,
  defaultPresetSetup,
  setOffer,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();
  const resetAllToSystemDefault = useCallback(() => {
    const { background, cornerRadius, shadow, shopSettings } = shopDefaultOffer;
    setOffer({
      background,
      cornerRadius,
      shadow,
      shopSettings: {
        ...offer.shopSettings,
        closeButton: {
          ...offer.shopSettings?.closeButton,
          color: shopSettings?.closeButton?.color,
        },
      },
      size: defaultOffer.size,
    });
  }, [shopDefaultOffer, defaultOffer, setOffer]);

  const handleUpdateComponent = useCallback(
    (
      field: keyof OfferRulePopupGeneralOfferDto,
      data: OfferRulePopupGeneralOfferDto[keyof OfferRulePopupGeneralOfferDto]
    ) => setOffer({ ...offer, [field]: data }),
    [offer, setOffer]
  );
  const onDiscardChanges = useCallback(() => {
    const { background, cornerRadius, shadow, shopSettings } =
      defaultPresetSetup;
    setOffer({
      ...defaultOffer,
      background,
      cornerRadius,
      shadow,
      shopSettings: {
        closeButton: {
          ...defaultOffer.shopSettings?.closeButton,
          color: shopSettings?.closeButton?.color,
        },
      },
    });
  }, [defaultPresetSetup, defaultOffer]);

  return (
    <div className='OfferRulePopupOffer'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={onDiscardChanges}
        onResetToSystem={resetAllToSystemDefault}
        name={offerName}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <Size
          size={offer.size || {}}
          disableWidth
          setSize={(data) => handleUpdateComponent('size', data)}
          onSystemReset={() => handleUpdateComponent('size', defaultOffer.size)}
        />
      </div>
      <div className='RightSideSection'>
        <Background
          background={offer.background || {}}
          inherited={isEqual(offer.background, defaultPresetSetup.background)}
          setBackground={(data) => handleUpdateComponent('background', data)}
          onSystemReset={() =>
            handleUpdateComponent('background', shopDefaultOffer.background)
          }
        />
      </div>
      <div className='RightSideSection'>
        <CornerRadius
          cornerRadius={offer.cornerRadius || {}}
          inherited={isEqual(
            offer.cornerRadius,
            defaultPresetSetup.cornerRadius
          )}
          onSystemReset={() =>
            handleUpdateComponent('cornerRadius', shopDefaultOffer.cornerRadius)
          }
          setCornerRadius={(data) =>
            handleUpdateComponent('cornerRadius', data)
          }
        />
      </div>
      <div className='RightSideSection'>
        <Shadow
          shadow={offer.shadow || {}}
          inherited={isEqual(offer.shadow, defaultPresetSetup.shadow)}
          setShadow={(data) => handleUpdateComponent('shadow', data)}
          onSystemReset={() =>
            handleUpdateComponent('shadow', shopDefaultOffer.shadow)
          }
        />
      </div>
      <ShopSettingsWrapper
        onOpen={() =>
          dispatch(
            setToggleSettingsConfig({
              action: 'open',
              path: 'offer-rules-popup',
            })
          )
        }
      >
        <div className='RightSideSection'>
          <Icon
            icon={offer.shopSettings?.closeButton || {}}
            title={i18n.translate('CloseButton')}
            showIcon
            inherited={isEqual(
              offer.shopSettings?.closeButton?.color,
              defaultPresetSetup.shopSettings?.closeButton?.color
            )}
            groupKey={ResourceGroupKeyDtoEnum.CLOSE_BUTTON}
            onlyColor
            setIcon={(data) =>
              handleUpdateComponent('shopSettings', {
                ...offer.shopSettings,
                closeButton: data,
              })
            }
            onSystemReset={() =>
              handleUpdateComponent('shopSettings', {
                ...offer.shopSettings,
                closeButton: {
                  ...offer.shopSettings?.closeButton,
                  color: shopDefaultOffer.shopSettings?.closeButton?.color,
                },
              })
            }
          />
        </div>
      </ShopSettingsWrapper>
    </div>
  );
};

export default OfferRulePopupOffer;
