import React from 'react';
import {
  Card,
  BlockStack,
  Text,
  Banner,
  Link,
  Checkbox,
  Divider,
  Bleed,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { PixelEventsIntegrationsSettingsDto } from 'core/api/adminSettings/adminSettingsApi';

type PixelEventIntegrations = {
  data: PixelEventsIntegrationsSettingsDto | undefined;
  section: string;
  handleIntegrationsLocaleState: (
    section: string,
    value: string | boolean,
    field?: string
  ) => void;
};

export const PixelEventIntegrations: React.FC<PixelEventIntegrations> = ({
  data,
  section,
  handleIntegrationsLocaleState,
}) => {
  const [i18n] = useI18n();

  return (
    <Card roundedAbove='sm' padding='400'>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate('PixelEventIntegrationsTitle')}
          </Text>
        </BlockStack>

        <Banner tone='info'>
          {i18n.translate('Tip')}{' '}
          <Link url='http://www.google.com' target='_blank'>
            {i18n.translate('TipLink')}
          </Link>
        </Banner>
        <Checkbox
          label={i18n.translate('Facebook')}
          helpText={i18n.translate('FacebookHelpText')}
          checked={data?.trackCustomEventFacebookPixel}
          onChange={(value) =>
            handleIntegrationsLocaleState(
              section,
              value,
              'trackCustomEventFacebookPixel'
            )
          }
        />
        <Bleed marginInline='400'>
          <Divider />
        </Bleed>
        <Checkbox
          label={i18n.translate('GoogleAnalytics')}
          helpText={i18n.translate('GoogleAnalyticsHelpText')}
          checked={data?.trackCustomEventGoogleAnalyticsPixel}
          onChange={(value) =>
            handleIntegrationsLocaleState(
              section,
              value,
              'trackCustomEventGoogleAnalyticsPixel'
            )
          }
        />
        <Bleed marginInline='400'>
          <Divider />
        </Bleed>
        <Checkbox
          label={i18n.translate('GoogleTagManager')}
          helpText={i18n.translate('GoogleTagManagerHelpText')}
          checked={data?.trackPromoViewEventInGoogleAnalytics}
          onChange={(value) =>
            handleIntegrationsLocaleState(
              section,
              value,
              'trackPromoViewEventInGoogleAnalytics'
            )
          }
        />
      </BlockStack>
    </Card>
  );
};
