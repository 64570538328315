import React, { useCallback } from 'react';
import {
  MessageDto,
  PromotionSummaryDisclaimerPresetDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { TextEntries, TextStyle } from '../../../../../components';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import {
  WidgetListOptionsDtoEnum,
  WidgetListTypeDtoEnum,
} from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useI18n } from '@shopify/react-i18n';
import { useAppDispatch } from 'core/hooks';
import { setPromotionSummaryPageEntries } from 'core/store/widgetsSlice';
import { isEqual } from 'lodash';

type PromotionSummaryDisclaimerProps = {
  disclaimer: PromotionSummaryDisclaimerPresetDto;
  defaultDisclaimer: PromotionSummaryDisclaimerPresetDto;
  shopDefaultDisclaimer: PromotionSummaryDisclaimerPresetDto;
  defaultLanguage?: string;
  isPresetEditor?: boolean;
  pageType:
    | WidgetListTypeDtoEnum.DRAWER_CART_PROMOTION_SUMMARY
    | WidgetListTypeDtoEnum.NINJA_CART_PROMOTION_SUMMARY
    | WidgetListTypeDtoEnum.CART_PROMOTION_SUMMARY;

  setDisclaimer: (data: PromotionSummaryDisclaimerPresetDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
  offerId?: string;
};

const PromotionSummaryDisclaimer: React.FC<PromotionSummaryDisclaimerProps> = ({
  disclaimer,
  defaultDisclaimer,
  shopDefaultDisclaimer,
  defaultLanguage,
  isPresetEditor,
  pageType,
  setDisclaimer,
  onShowHide,
  handleLeftMenuSelection,
  offerId,
}) => {
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();

  const handleDisclaimerText = useCallback(
    (field: keyof MessageDto, data: MessageDto[keyof MessageDto]) =>
      setDisclaimer({
        ...disclaimer,
        text: {
          ...disclaimer.text,
          value: {
            ...disclaimer.text?.value,
            [field]: data,
          },
        },
      }),
    [disclaimer, setDisclaimer]
  );

  const resetAllToSystemDefault = useCallback(() => {
    setDisclaimer({ ...disclaimer, text: shopDefaultDisclaimer.text });
  }, [shopDefaultDisclaimer, disclaimer]);

  return (
    <div className='PromotionSummaryDisclaimer'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={() => setDisclaimer(defaultDisclaimer)}
        onResetToSystem={resetAllToSystemDefault}
        name={WidgetListOptionsDtoEnum.DISCLAIMER}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <TextEntries
          textMessage={disclaimer.text?.value?.message || {}}
          inherited={
            !isPresetEditor
              ? isEqual(disclaimer.text, defaultDisclaimer.text)
              : undefined
          }
          defaultLanguage={defaultLanguage || ''}
          disableGoal
          disableCollapsed
          disableColor={
            (disclaimer.text?.value?.style || {}).color !== undefined
          }
          offerId={offerId}
          enableText={i18n.translate('EnableDisclaimer')}
          enabled={disclaimer.text?.enabled}
          setEnabled={(value) =>
            setDisclaimer({
              ...disclaimer,
              text: {
                ...disclaimer.text,
                enabled: value,
              },
            })
          }
          setTextMessage={(data) => handleDisclaimerText('message', data)}
          onSystemReset={() =>
            handleDisclaimerText(
              'message',
              shopDefaultDisclaimer.text?.value?.message
            )
          }
          setPreviewEntry={(value) =>
            dispatch(setPromotionSummaryPageEntries({ pageType, data: value }))
          }
        />

        <TextStyle
          textStyle={disclaimer.text?.value?.style || {}}
          onSystemReset={() =>
            handleDisclaimerText(
              'style',
              shopDefaultDisclaimer.text?.value?.style
            )
          }
          hideHeader
          setTextStyle={(data) => handleDisclaimerText('style', data)}
        />
      </div>
    </div>
  );
};

export default PromotionSummaryDisclaimer;
