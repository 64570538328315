import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './PromotionalBadge.scss';
import { OfferWidgetSetupDtoPromotionalBadgeOfferDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { useI18n } from '@shopify/react-i18n';
import { useAppSelector } from 'core/hooks';
import {
  getCurrentMessage,
  parseSmartTagCode,
} from 'features/settings/components/EditPresetSkeleton/utils/utils';
import { PreviewEntry } from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components';
import { isEmpty, isEqual } from 'lodash';
import {
  BadgeStyleShapeDtoEnum,
  DeviceTypeDtoEnum,
  PromotionalBadgeBadgeStyleSetKeyDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import {
  WidgetsFetchTypeEnum,
  useConfigureWidgets,
} from 'features/settings/hooks/useConfigureWidgets';
import { usePrevious } from 'core/hooks/usePrevious';

type PromotionalBadgeProps = {
  data: any;
  previewType: DeviceTypeDtoEnum;
  defaultLanguage?: string;
  adminActivePath: string;
  getSelectionFromThePreview(path: string): void;
};

export const PromotionalBadge: React.FC<PromotionalBadgeProps> = ({
  previewType,
  defaultLanguage,
  data,
  adminActivePath,
  getSelectionFromThePreview,
}) => {
  const [i18n] = useI18n();
  const { fontListData } = useConfigureWidgets(
    WidgetsFetchTypeEnum.PRESET_FONT_LIST
  );
  const { promotionalBadgeMessages } = useAppSelector((state) => state.widgets);
  const { smartTagsCodes } = useAppSelector((state) => state.offersWizard);

  const [injectStyles, setInjectStyles] = useState<boolean>(false);
  const [currentData, setCurrentData] = useState<any>({});

  const currentIndex: number = useMemo(() => {
    const match = adminActivePath.match(/banner_(\d+)/);
    if (match) {
      return +match[1];
    } else {
      return 0;
    }
  }, [adminActivePath]);

  const currentOffer = useMemo(
    () => data?.offers?.[currentIndex],
    [data?.offers, currentIndex]
  );

  const offersList = useMemo(() => {
    const isRectangleTypeBadge =
      currentOffer?.setup.options.badge[
        currentOffer?.setup.options.badge.selectedStyleSet ===
          PromotionalBadgeBadgeStyleSetKeyDtoEnum.PRIMARY
          ? 'primaryStyle'
          : 'secondaryStyle'
      ].style.shape === BadgeStyleShapeDtoEnum.RECTANGLE;
    if (!currentOffer || isRectangleTypeBadge) return data.offers;
    return [currentOffer];
  }, [currentOffer, data.offers]);

  const currentMessages = useMemo(
    () =>
      offersList?.length
        ? offersList?.map(
          (item: OfferWidgetSetupDtoPromotionalBadgeOfferDto) => ({
            token: item.offerId,
            lines:
              item.offerId &&
                promotionalBadgeMessages?.length &&
                promotionalBadgeMessages.find(
                  (message) => message.offerId === item.offerId
                )?.[previewType.toLowerCase() as keyof PreviewEntry]
                ? [
                  {
                    label: parseSmartTagCode(
                      promotionalBadgeMessages.find(
                        (message) => message.offerId === item.offerId
                      )?.[
                      previewType.toLowerCase() as keyof PreviewEntry
                      ] || '',
                      smartTagsCodes
                    ),
                  },
                ]
                : [
                  {
                    label: getCurrentMessage(
                      item.setup?.options?.message?.message?.entries,
                      defaultLanguage,
                      smartTagsCodes
                    ),
                  },
                ],
            shape:
              item?.setup?.options?.badge?.[
                item?.setup?.options?.badge?.selectedStyleSet ===
                  PromotionalBadgeBadgeStyleSetKeyDtoEnum.PRIMARY
                  ? 'primaryStyle'
                  : 'secondaryStyle'
              ]?.style?.shape?.toLowerCase() || undefined,
            size:
              item?.setup?.options?.badge?.[
                item?.setup?.options?.badge?.selectedStyleSet ===
                  PromotionalBadgeBadgeStyleSetKeyDtoEnum.PRIMARY
                  ? 'primaryStyle'
                  : 'secondaryStyle'
              ]?.style?.size?.toLowerCase() || undefined,
            position:
              data?.general?.shopSettings?.position?.position || undefined,
          })
        )
        : [
          {
            token: 'example',
            lines: [{ label: i18n.translate('example') }],
            size:
              data?.options?.badge?.[
                data?.options?.badge?.selectedStyleSet ===
                  PromotionalBadgeBadgeStyleSetKeyDtoEnum.PRIMARY
                  ? 'primaryStyle'
                  : 'secondaryStyle'
              ]?.style?.size?.toLowerCase() || undefined,
            shape:
              data?.options?.badge?.[
                data?.options?.badge?.selectedStyleSet ===
                  PromotionalBadgeBadgeStyleSetKeyDtoEnum.PRIMARY
                  ? 'primaryStyle'
                  : 'secondaryStyle'
              ]?.style?.shape?.toLowerCase() || undefined,
            position:
              data?.general?.shopSettings?.position?.position || undefined,
          },
        ],
    [
      i18n,
      data.options,
      offersList,
      data.general,
      defaultLanguage,
      promotionalBadgeMessages,
      previewType,
      smartTagsCodes,
    ]
  );

  const prevMessages = usePrevious(currentMessages);

  const shouldReload = useMemo(
    () => prevMessages?.length !== currentMessages?.length,
    [prevMessages?.length, currentMessages?.length]
  );

  const elementToReplaceWith = useMemo(
    () =>
      `
  <la-dn-promotional-badge
      product-handle="1"
      admin-mode="true"
      admin-active-path="${adminActivePath}"
      admin-mode-label='[{"component":"promotionalBadge","label":"${i18n.translate(
        'promotionalBadgeLabel'
      )}","icon":"widget","subComponents":[{"component":"banner","label":"${i18n.translate(
        'offer'
      )}","icon":"offer","subComponents":[{"component":"message","label":"${i18n.translate(
        'message'
      )}","icon":"component"},{"component":"badge","label":"${i18n.translate(
        'badge'
      )}","icon":"component"}]}]}]'
      replace-element="#replacePromotionalBadge"
    ></la-dn-promotional-badge>
`,
    [adminActivePath, i18n]
  );

  const showPromotionalBadge = useCallback(
    (data: any) => {
      const container = document.querySelector('#promotional-badge-root');
      const badge = document.querySelector('la-dn-promotional-badge');
      if (container && !badge) {
        container.innerHTML = elementToReplaceWith;
      }
      setCurrentData(data);
    },
    [elementToReplaceWith]
  );

  useEffect(() => {
    if (!isEmpty(currentData)) {
      document.dispatchEvent(
        new CustomEvent('la:dn:promotional-badge:admin-render', {
          detail: currentData,
        })
      );
      setInjectStyles(true);
    }
  }, [currentData]);

  useEffect(() => {
    if (injectStyles) {
      const badge = document.querySelector('la-dn-promotional-badge');
      badge?.setAttribute('styles', JSON.stringify(data));
    }
  }, [injectStyles, data]);

  useEffect(() => {
    if (injectStyles) {
      const badge = document.querySelector('la-dn-promotional-badge');
      badge?.setAttribute(
        'force-mobile',
        (previewType === DeviceTypeDtoEnum.MOBILE).toString()
      );
    }
  }, [injectStyles, previewType]);

  useEffect(() => {
    if (injectStyles) {
      const badge = document.querySelector('la-dn-promotional-badge');
      badge?.setAttribute('admin-active-path', adminActivePath);
    }
  }, [adminActivePath]);

  useEffect(() => {
    if (injectStyles) {
      const badge = document.querySelector('la-dn-promotional-badge');
      badge?.setAttribute('font-assets', JSON.stringify(fontListData));
    }
  }, [fontListData, injectStyles]);

  useEffect(() => {
    shouldReload &&
      showPromotionalBadge({
        'product-handle': '1',
        messages: currentMessages,
      });
    document
      .querySelector('la-dn-promotional-badge')!
      .addEventListener('la:dn:promotional-badge:admin-change', (e) =>
        getSelectionFromThePreview((e as CustomEvent).detail)
      );
  }, [shouldReload]);

  useEffect(() => {
    if (
      currentMessages &&
      injectStyles &&
      !isEqual(currentMessages, prevMessages)
    ) {
      document.dispatchEvent(
        new CustomEvent('la:dn:promotional-badge:update', {
          detail: {
            'product-handle': '1',
            messages: currentMessages,
          },
        })
      );
    }
  }, [currentMessages]);

  return (
    <div id='promotional-badge-root' className='promotional-badge-root'>
      <div
        id='replacePromotionalBadge'
        style={{ backgroundColor: 'transparent' }}
      />
    </div>
  );
};
