import React, { useEffect, useMemo, useState } from 'react';
import { BlockStack } from '@shopify/polaris';
import './ShippingDeliveryMethods.scss';
import { NumberFormatValues } from 'react-number-format';
import {
  OfferAppliedCollectionTypeDtoEnum,
  OfferShippingRateTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import {
  OfferAppliedCollectionTypeDto,
  OfferShippingRateTypeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { getInitialPriceTextfieldValue } from '../ShippingRate/utils/utils';
import { ShippingAppliesTo } from './components/ShippingAppliesTo/ShippingAppliesTo';
import { ShippingCountries } from './components/ShippingCountries/ShippingCountries';
import useValidityUpdater, {
  ObjValidity,
} from 'features/promotions/hooks/useValidityUpdater';
type ShippingDeliveryMethodsProps = {
  specification?: any;
  // OfferFreeShippingDeliveryMethodsSpecificationDto;
  onValidityStateChange(invalid: boolean): void;
  onShippingCountriesDataUpdate?: (
    data: any
    // OfferFreeShippingDeliveryMethodsSpecificationDto
  ) => void;
  onApplyToDataUpdate?: (data: any) => void;
  // OfferFreeShippingDeliveryMethodsSpecificationDto
};
export const ShippingDeliveryMethods: React.FC<ShippingDeliveryMethodsProps> = (
  props
) => {
  const {
    onValidityStateChange,
    specification,
    onApplyToDataUpdate,
    onShippingCountriesDataUpdate,
  } = props;
  const [deliveryMethodsOption, setDeliveryMethodsOption] =
    useState<OfferShippingRateTypeDto>(
      specification?.shippingRateType || OfferShippingRateTypeDtoEnum.ALL
    );
  const [shippingCountriesOption, setShippingCountriesOption] =
    useState<OfferAppliedCollectionTypeDto>(
      specification?.shippingCountriesOption ||
        OfferAppliedCollectionTypeDtoEnum.ALL
    );

  const [thresholdTextFieldValue, setThresholdTextFieldValue] =
    useState<NumberFormatValues>(
      getInitialPriceTextfieldValue(
        specification?.shippingRateThreshold as number
      )
    );

  const [selectedCountries, setSelectedCountries] = useState<string[]>(
    specification?.selectedShippingCountries?.countriesIds || []
  );

  const [currentObjectsValidities, updateValidity] = useValidityUpdater(1);
  const isAllInputsValid = useMemo(
    () =>
      currentObjectsValidities?.every(
        (inputValidity: ObjValidity) => inputValidity.isValid
      ),
    [currentObjectsValidities]
  );
  useEffect(() => {
    if (!onShippingCountriesDataUpdate || !shippingCountriesOption) {
      return;
    }
    const getShippingValueRequestObject = (): any => {
      switch (shippingCountriesOption) {
        // OfferFreeShippingDeliveryMethodsSpecificationDto
        case OfferAppliedCollectionTypeDtoEnum.ALL:
          return {
            ...specification,
            shippingCountriesOption: shippingCountriesOption,
          };
        case OfferAppliedCollectionTypeDtoEnum.SELECTED:
          return {
            ...specification,
            shippingCountriesOption: shippingCountriesOption,
            selectedShippingCountries: {
              ...specification?.selectedShippingCountries,
              countriesIds: selectedCountries,
            },
          };
        default:
          return {
            ...specification,
            shippingCountriesOption: shippingCountriesOption,
          };
      }
    };
    onShippingCountriesDataUpdate(getShippingValueRequestObject());
  }, [
    shippingCountriesOption,
    setShippingCountriesOption,
    onShippingCountriesDataUpdate,
    selectedCountries,
  ]);

  useEffect(() => {
    if (!onApplyToDataUpdate || !deliveryMethodsOption) {
      return;
    }
    const getShippingValueRequestObject = (): any => {
      // OfferFreeShippingDeliveryMethodsSpecificationDto
      switch (deliveryMethodsOption) {
        case OfferAppliedCollectionTypeDtoEnum.ALL:
          return {
            ...specification,
            deliveryMethodsOption: deliveryMethodsOption,
          };
        case OfferAppliedCollectionTypeDtoEnum.SELECTED:
          return {
            ...specification,
            deliveryMethodsOption: deliveryMethodsOption,
            threshold: thresholdTextFieldValue.floatValue,
          };
        default:
          return {
            ...specification,
            deliveryMethodsOption: deliveryMethodsOption,
          };
      }
    };
    onApplyToDataUpdate(getShippingValueRequestObject());
  }, [
    deliveryMethodsOption,
    setDeliveryMethodsOption,
    onApplyToDataUpdate,
    thresholdTextFieldValue,
  ]);

  useEffect(() => {
    if (onValidityStateChange) {
      onValidityStateChange(isAllInputsValid);
    }
  }, [isAllInputsValid]);

  return (
    <BlockStack gap='600'>
      <ShippingAppliesTo
        updateValidity={updateValidity}
        thresholdTextFieldValue={thresholdTextFieldValue}
        setThresholdTextFieldValue={setThresholdTextFieldValue}
        deliveryMethodsOption={deliveryMethodsOption}
        setDeliveryMethodsOption={setDeliveryMethodsOption}
      />
      <ShippingCountries
        updateValidity={updateValidity}
        selectedCountries={selectedCountries}
        setSelectedCountries={setSelectedCountries}
        shippingCountriesOption={shippingCountriesOption}
        setShippingCountriesOption={setShippingCountriesOption}
      />
    </BlockStack>
  );
};
