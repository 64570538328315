import React, { useEffect, useState } from 'react';
import {
  Badge,
  BlockStack,
  Button,
  Card,
  InlineStack,
  MediaCard,
  Page,
  Text,
  VideoThumbnail,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { TestingSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import {
  setIsUnsavedChanges,
  setOnDiscardChanges,
  setOnSaveChanges,
} from 'core/store/settingsSlice';
import { isEmpty, isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { SettingsSkeleton } from '../components/SettingsSkeleton/SettingsSkeleton';

export const Testing: React.FC = () => {
  const [i18n] = useI18n();
  const dispatch = useDispatch();

  const { testingData, testingIsFetching, testingDataChange } =
    useConfigureSettings(SettingsFetchTypeEnum.TESTING);

  const [testingState, setTestingState] = useState<TestingSettingsDto>({});
  const [savedData, setSavedData] = useState<TestingSettingsDto>({});

  useEffect(() => {
    if (!isEmpty(testingState) && !isEqual(savedData, testingState)) {
      dispatch(setIsUnsavedChanges(true));
      dispatch(
        setOnDiscardChanges(() => {
          if (!isEmpty(savedData)) {
            setTestingState(savedData);
          }
        })
      );
      dispatch(
        setOnSaveChanges(() => {
          setSavedData(testingState);
          return testingDataChange(testingState || {});
        })
      );
    } else if (isEqual(savedData, testingState)) {
      dispatch(setIsUnsavedChanges(false));
    }
  }, [testingState, savedData, dispatch]);

  useEffect(() => {
    if (!isEmpty(testingData)) {
      setTestingState(testingData);
      setSavedData(testingData);
    }
  }, [testingData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  return (
    <div className='Style'>
      {!testingIsFetching && testingState ? (
        <Page title={i18n.translate(`Testing`)}>
          <BlockStack gap='400'>
            <Card roundedAbove='sm' padding='400'>
              <BlockStack gap='100'>
                <InlineStack align='space-between' blockAlign='center'>
                  <InlineStack gap='200' blockAlign='center'>
                    <Text as='h2' variant='headingSm'>
                      {i18n.translate('TestingSwitcherTitle')}
                    </Text>
                    <Badge
                      tone={testingState.isEnabled ? 'success' : 'enabled'}
                    >
                      {testingState.isEnabled
                        ? i18n.translate('On')
                        : i18n.translate('Off')}
                    </Badge>
                  </InlineStack>
                  <Button
                    onClick={() =>
                      setTestingState({
                        ...testingState,
                        isEnabled: !testingState.isEnabled,
                      })
                    }
                  >
                    {testingState.isEnabled
                      ? i18n.translate('TurnOff')
                      : i18n.translate('TurnOn')}
                  </Button>
                </InlineStack>
                <Text as='p' tone='subdued'>
                  {i18n.translate('TestingSwitcherSubtitle')}
                </Text>
              </BlockStack>
            </Card>
            <MediaCard
              title={i18n.translate('MediaTestingTitle')}
              primaryAction={{
                content: i18n.translate('LearnMore'),
                onAction: () => null,
              }}
              description={i18n.translate(`MediaTestingSubtitle`)}
              size='small'
            >
              <VideoThumbnail
                videoLength={80}
                thumbnailUrl='https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850'
                onClick={() =>
                  window.open(
                    'https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley',
                    '_blank'
                  )
                }
              />
            </MediaCard>
          </BlockStack>
        </Page>
      ) : (
        <SettingsSkeleton />
      )}
    </div>
  );
};
