import React, { useMemo } from 'react';
import {
  Badge,
  BlockStack,
  Card,
  ChoiceList,
  Icon,
  InlineStack,
  Link,
  Text,
  Tooltip,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { InfoIcon } from '@shopify/polaris-icons';
import { FallbackCheckoutModeDto } from 'core/api/adminSettings/adminSettingsApi';
import { FallbackCheckoutModeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
type FallbackModeProps = {
  fallbackMode?: FallbackCheckoutModeDto;
  setFallbackMode: (value: FallbackCheckoutModeDto) => void;
};
export const FallbackMode: React.FC<FallbackModeProps> = ({
  fallbackMode,
  setFallbackMode,
}) => {
  const [i18n] = useI18n();

  const modeOptions = useMemo(
    () =>
      Object.values(FallbackCheckoutModeDtoEnum).map((value) => ({
        label: (
          <InlineStack gap='200'>
            <Text as='p'>{i18n.translate(`${value}_TITLE`)}</Text>
            {value ===
              FallbackCheckoutModeDtoEnum.ORDER_DISCOUNT_BY_SHOPIFY_FUNCTIONS && (
              <Badge>{i18n.translate('Recommended')}</Badge>
            )}
          </InlineStack>
        ),
        helpText: i18n.translate(`${value}_SUBTITLE`),
        value: value,
      })),
    [i18n]
  );

  return (
    <Card>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <InlineStack gap='200'>
            <Text fontWeight='semibold' as='h2'>
              {i18n.translate('Title')}
            </Text>
            <Tooltip content={i18n.translate('FallbackTooltip')}>
              <Icon source={InfoIcon} />
            </Tooltip>
          </InlineStack>
          <Text as='p' tone='subdued'>
            {i18n.translate('Subtitle', {
              readArticle: <Link>{i18n.translate('ReadThisArticle')}</Link>,
            })}
          </Text>
        </BlockStack>
        <ChoiceList
          title=''
          choices={modeOptions}
          selected={[fallbackMode as string]}
          onChange={(value) =>
            setFallbackMode(value[0] as FallbackCheckoutModeDtoEnum)
          }
        />
      </BlockStack>
    </Card>
  );
};
