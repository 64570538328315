import React, { useCallback, useMemo } from 'react';
import { SelectedOptionPath } from '../../../../RightSideBarWrapper';
import {
  AnnouncementBarFirstLevelPresetDtoRead,
  AnnouncementBarPresetDtoRead,
  AnnouncementBarPrimaryBannerLevelPresetDtoRead,
  AnnouncementBarSystemDefaultsDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { getLastSelectedOption } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import AnnouncementBarImage from './components/AnnouncementBarImage/AnnouncementBarImage';
import AnnouncementBarIcon from './components/AnnouncementBarIcon/AnnouncementBarIcon';
import AnnouncementBarMessage from './components/AnnouncementBarMessage/AnnouncementBarMessage';
import AnnouncementBarGeneral from '../../../EditPromotionRightBar/components/PromotionAnnouncementBar/components/AnnouncementBarGeneral/AnnouncementBarGeneral';
import AnnoucementBarBanner from './components/AnnoucementBarBanner/AnnoucementBarBanner';
import {
  WidgetListOptionsDtoEnum,
  WidgetListTypeDtoEnum,
} from 'features/settings/components/EditPresetSkeleton/enums/enums';

type PresetAnnouncementBarProps = {
  selectedOptionPath: SelectedOptionPath;
  announcementBar: AnnouncementBarPresetDtoRead;
  defaultPresetData: AnnouncementBarPresetDtoRead;
  shopDefaultData: AnnouncementBarSystemDefaultsDto;
  updateAnnouncementBar: (data: AnnouncementBarPresetDtoRead) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const PresetAnnouncementBar: React.FC<PresetAnnouncementBarProps> = ({
  selectedOptionPath,
  announcementBar,
  defaultPresetData,
  shopDefaultData,
  updateAnnouncementBar,
  handleLeftMenuSelection,
}) => {
  const updateBannerType = useCallback(
    (
      option: keyof AnnouncementBarFirstLevelPresetDtoRead,
      data: AnnouncementBarFirstLevelPresetDtoRead[keyof AnnouncementBarFirstLevelPresetDtoRead]
    ) => {
      updateAnnouncementBar({
        ...announcementBar,
        options: {
          ...announcementBar.options,
          [option]: data,
        },
      });
    },
    [updateAnnouncementBar, announcementBar]
  );
  const updateBannerComponent = useCallback(
    (
      option: keyof AnnouncementBarPrimaryBannerLevelPresetDtoRead,
      data: AnnouncementBarPrimaryBannerLevelPresetDtoRead[keyof AnnouncementBarPrimaryBannerLevelPresetDtoRead],
      bannerType: keyof AnnouncementBarFirstLevelPresetDtoRead
    ) => {
      updateAnnouncementBar({
        ...announcementBar,
        options: {
          ...announcementBar.options,
          [bannerType]: {
            ...announcementBar.options?.[bannerType],
            options: {
              ...announcementBar.options?.[bannerType]?.options,
              [option]: data,
            },
          },
        },
      });
    },
    [updateAnnouncementBar, announcementBar]
  );

  const getBannerComponent = useCallback(
    (bannerType: 'secondaryBanner' | 'primaryBanner', optionName: string) => {
      const bannerData = announcementBar.options?.[bannerType]?.options;
      const defaultBannerData =
        defaultPresetData.options?.[bannerType]?.options;
      const systemDefaultData = shopDefaultData?.[bannerType];
      switch (optionName) {
        case WidgetListOptionsDtoEnum.IMAGE:
          return (
            <AnnouncementBarImage
              handleLeftMenuSelection={handleLeftMenuSelection}
              image={bannerData?.image || {}}
              defaultImage={defaultBannerData?.image || {}}
              shopDefaultImage={systemDefaultData?.image || {}}
              isPresetEditor
              setImage={(data) =>
                updateBannerComponent('image', data, bannerType)
              }
              onShowHide={
                bannerData?.image?.canBeHidden
                  ? () =>
                      updateBannerComponent(
                        'image',
                        {
                          ...bannerData?.image,
                          isHidden: !bannerData?.image?.isHidden,
                        },
                        bannerType
                      )
                  : undefined
              }
            />
          );
        case WidgetListOptionsDtoEnum.ICON:
          return (
            <AnnouncementBarIcon
              handleLeftMenuSelection={handleLeftMenuSelection}
              icon={bannerData?.icon || {}}
              defaultIcon={defaultBannerData?.icon || {}}
              shopDefaultIcon={systemDefaultData?.icon || {}}
              setIcon={(data) =>
                updateBannerComponent('icon', data, bannerType)
              }
              isPresetEditor
              onShowHide={
                bannerData?.icon?.canBeHidden
                  ? () =>
                      updateBannerComponent(
                        'icon',
                        {
                          ...bannerData?.icon,
                          isHidden: !bannerData?.icon?.isHidden,
                        },
                        bannerType
                      )
                  : undefined
              }
            />
          );
        case WidgetListOptionsDtoEnum.MESSAGE:
          return (
            <AnnouncementBarMessage
              handleLeftMenuSelection={handleLeftMenuSelection}
              message={bannerData?.message || {}}
              defaultMessage={defaultBannerData?.message || {}}
              shopDefaultMessage={systemDefaultData?.textStyle || {}}
              setMessage={(data) =>
                updateBannerComponent('message', data, bannerType)
              }
              isPresetEditor
              onShowHide={
                bannerData?.message?.canBeHidden
                  ? () =>
                      updateBannerComponent(
                        'message',
                        {
                          ...bannerData?.message,
                          isHidden: !bannerData?.message?.isHidden,
                        },
                        bannerType
                      )
                  : undefined
              }
            />
          );
      }
    },
    [announcementBar, defaultPresetData, shopDefaultData, updateBannerComponent]
  );

  const announcementBarOptions = useMemo(() => {
    const selectedOption = getLastSelectedOption(selectedOptionPath);
    switch (true) {
      case selectedOption?.name === WidgetListTypeDtoEnum.ANNOUNCEMENT_BAR:
        return (
          <AnnouncementBarGeneral
            general={announcementBar.general || {}}
            defaultGeneral={{}}
            shopDefaultGeneral={{}}
            setGeneral={() => null}
            handleLeftMenuSelection={handleLeftMenuSelection}
            isPresetEditor
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.PRIMARY_MULTI_SLOT:
        return (
          <AnnoucementBarBanner
            banner={announcementBar.options?.primaryBanner?.general || {}}
            defaultBanner={
              defaultPresetData.options?.primaryBanner?.general || {}
            }
            shopDefaultBanner={shopDefaultData.primaryBanner || {}}
            isPresetEditor
            isPrimary
            setBanner={(data) =>
              updateBannerType('primaryBanner', {
                ...announcementBar.options?.primaryBanner,
                general: data,
              })
            }
            onShowHide={
              announcementBar?.options?.primaryBanner?.canBeHidden
                ? () =>
                    updateBannerType('primaryBanner', {
                      ...announcementBar?.options?.primaryBanner,
                      isHidden:
                        !announcementBar?.options?.primaryBanner?.isHidden,
                    })
                : undefined
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedOption?.name ===
        WidgetListOptionsDtoEnum.SECONDARY_THREE_SLOT:
        return (
          <AnnoucementBarBanner
            banner={announcementBar.options?.secondaryBanner?.general || {}}
            defaultBanner={
              defaultPresetData.options?.secondaryBanner?.general || {}
            }
            isPresetEditor
            shopDefaultBanner={shopDefaultData.secondaryBanner || {}}
            setBanner={(data) =>
              updateBannerType('secondaryBanner', {
                ...announcementBar.options?.secondaryBanner,
                general: data,
              })
            }
            onShowHide={
              announcementBar?.options?.secondaryBanner?.canBeHidden
                ? () =>
                    updateBannerType('secondaryBanner', {
                      ...announcementBar?.options?.secondaryBanner,
                      isHidden:
                        !announcementBar?.options?.secondaryBanner?.isHidden,
                    })
                : undefined
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      default:
        return getBannerComponent(
          selectedOption?.parentName as keyof AnnouncementBarFirstLevelPresetDtoRead,
          selectedOption?.name as string
        );
    }
  }, [
    selectedOptionPath,
    shopDefaultData,
    announcementBar,
    defaultPresetData,
    getBannerComponent,
    updateAnnouncementBar,
    handleLeftMenuSelection,
  ]);
  return <div className='PresetAnnouncementBar'>{announcementBarOptions}</div>;
};

export default PresetAnnouncementBar;
