import React, { useCallback, useMemo } from 'react';
import {
  Banner,
  BlockStack,
  Card,
  Checkbox,
  ChoiceList,
  Text,
  TextField,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { OptionDtoSpacingDto } from 'core/api/adminSettings/adminSettingsApi';
import { SpacingTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { Element } from 'react-scroll';

type StackingSettingProps = {
  stacking: OptionDtoSpacingDto;
  maxCustomSpacing: number;
  subtitle: string;
  checkboxSubtitle?: string;
  setStacking: (value: OptionDtoSpacingDto) => void;
};
export const StackingSetting: React.FC<StackingSettingProps> = ({
  stacking,
  maxCustomSpacing,
  subtitle,
  checkboxSubtitle,
  setStacking,
}) => {
  const [i18n] = useI18n();

  const spacingOptions = useMemo(
    () =>
      Object.values(SpacingTypeDtoEnum)
        .filter(
          (item) =>
            !(
              item === SpacingTypeDtoEnum.EXTRA_LARGE ||
              item === SpacingTypeDtoEnum.EXTRA_SMALL
            )
        )
        .map((value) => ({
          label: i18n.translate(value),
          value: value,
        })),
    []
  );

  const onSpacingValdiate = useCallback(() => {
    const customValue = stacking.value?.customValue || 0;
    setStacking({
      ...stacking,
      value: {
        ...stacking.value,
        customValue:
          customValue > maxCustomSpacing
            ? maxCustomSpacing
            : customValue < 0
            ? 0
            : customValue,
      },
    });
  }, [stacking, setStacking, maxCustomSpacing]);

  return (
    <Element name='StackingSetting' className='StackingSetting'>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('Stacking')}
            </Text>
            <Text as='p' tone='subdued'>
              {subtitle}
            </Text>
          </BlockStack>
          <Checkbox
            label={i18n.translate('EnableStacking')}
            checked={stacking.enabled}
            helpText={stacking.enabled ? checkboxSubtitle : ''}
            onChange={(value) => setStacking({ ...stacking, enabled: value })}
          />
          {stacking.enabled && (
            <>
              <ChoiceList
                title={i18n.translate('Spacing')}
                choices={spacingOptions}
                selected={[stacking.value?.type || SpacingTypeDtoEnum.MEDIUM]}
                onChange={(value) =>
                  setStacking({
                    ...stacking,
                    value: {
                      ...stacking.value,
                      type: value[0] as SpacingTypeDtoEnum,
                    },
                  })
                }
              />
              {stacking.value?.type === SpacingTypeDtoEnum.CUSTOM && (
                <TextField
                  autoComplete=''
                  label={i18n.translate('CustomSpacing')}
                  type='number'
                  value={stacking.value?.customValue?.toString() || '4'}
                  suffix='px'
                  onChange={(value) =>
                    setStacking({
                      ...stacking,
                      value: {
                        ...stacking.value,
                        customValue: +value,
                      },
                    })
                  }
                  onBlur={onSpacingValdiate}
                />
              )}
            </>
          )}

          {!stacking.enabled && (
            <Banner tone='info'>{i18n.translate('BannerText')}</Banner>
          )}
        </BlockStack>
      </Card>
    </Element>
  );
};
