import { BlockStack, Box, Text, ChoiceList } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  OfferCombinationStackingBehaviorDto,
  OfferRelationalCombinationTypeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  OfferCombinationStackingBehaviorDtoEnum,
  OfferRelationalCombinationTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { MultipleOrderDiscountRule } from 'features/promotions/components/SelectedOfferTypeSettings/enums/DiscountType';
import React, { useCallback, useMemo } from 'react';
import './MultipleDiscountsHelp.scss';

export type MultipleDiscountsHelpProps = {
  isOtherOrderDiscount?: boolean;
  defaultValue?: OfferCombinationStackingBehaviorDto;
  handleTypeStackingChange?: (
    type: OfferRelationalCombinationTypeDto,
    value: OfferCombinationStackingBehaviorDto
  ) => void;
};

export const MultipleDiscountsHelp: React.FC<MultipleDiscountsHelpProps> = (
  props
) => {
  const {
    isOtherOrderDiscount = false,
    defaultValue,
    handleTypeStackingChange,
  } = props;
  const [i18n] = useI18n();

  const cartRule: OfferCombinationStackingBehaviorDto = useMemo(
    () => defaultValue || OfferCombinationStackingBehaviorDtoEnum.LARGEST_ONLY,
    [defaultValue]
  );

  const handleCartRuleChange = useCallback(
    (newValue: MultipleOrderDiscountRule[]) => {
      const type = isOtherOrderDiscount
        ? OfferRelationalCombinationTypeDtoEnum.OTHER_ORDER
        : OfferRelationalCombinationTypeDtoEnum.PRODUCT;
      const formatValue =
        newValue[0] === MultipleOrderDiscountRule.Largest
          ? OfferCombinationStackingBehaviorDtoEnum.LARGEST_ONLY
          : OfferCombinationStackingBehaviorDtoEnum.STACK;
      handleTypeStackingChange && handleTypeStackingChange(type, formatValue);
    },
    [isOtherOrderDiscount, handleTypeStackingChange]
  );

  const formatedCartRule = useMemo(
    () =>
      cartRule === OfferCombinationStackingBehaviorDtoEnum.STACK
        ? [MultipleOrderDiscountRule.Stack]
        : [MultipleOrderDiscountRule.Largest],
    [cartRule]
  );

  return (
    <Box paddingInlineStart='600'>
      <BlockStack>
        <ChoiceList
          title={
            isOtherOrderDiscount
              ? i18n.translate('MultipleDiscountsHelp.TitleOther')
              : i18n.translate('MultipleDiscountsHelp.Title')
          }
          choices={[
            {
              label: i18n.translate(
                'MultipleDiscountsHelp.ApplyOnlyTheHighestDiscount'
              ),
              value: MultipleOrderDiscountRule.Largest,
            },
            {
              label: i18n.translate('MultipleDiscountsHelp.ApplyAllDiscounts'),
              value: MultipleOrderDiscountRule.Stack,
            },
          ]}
          selected={formatedCartRule}
          onChange={handleCartRuleChange}
        />
      </BlockStack>
    </Box>
  );
};
