import React, { useCallback } from 'react';
import { useI18n } from '@shopify/react-i18n';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import {
  OptionDtoStackingComponentDto,
  StackingComponentDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import {
  StackingDirectionDtoEnum,
  SpacingTypeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import { TextField, Tooltip, Text } from '@shopify/polaris';
import './Stacking.scss';
type StackingProps = {
  stacking: OptionDtoStackingComponentDto;
  allowDirection?: boolean;
  disabled?: boolean;
  maxCustomSpacing?: number;
  note?: string;
  setStacking?: (value: OptionDtoStackingComponentDto) => void;
  onSystemReset?: () => void;
};

export const Stacking: React.FC<StackingProps> = ({
  stacking,
  allowDirection,
  disabled,
  maxCustomSpacing = 12,
  note,
  setStacking,
  onSystemReset,
}) => {
  const [i18n] = useI18n();

  const stackingDirectionOptions = Object.values(StackingDirectionDtoEnum).map(
    (value) => {
      return {
        value: value,
        label: i18n.translate(`${value}`),
      };
    }
  );
  const stackingSpacingOptions = Object.values(SpacingTypeDtoEnum).map(
    (value) => {
      return {
        value: value,
        label: i18n.translate(`${value}`),
      };
    }
  );

  const onSpacingValdiate = useCallback(() => {
    const customValue = stacking.value?.spacing?.customValue || 0;
    setStacking?.({
      ...stacking,
      value: {
        ...stacking.value,
        spacing: {
          ...stacking.value?.spacing,
          customValue:
            customValue > maxCustomSpacing
              ? maxCustomSpacing
              : customValue < 0
                ? 0
                : customValue,
        },
      },
    });
  }, [stacking, setStacking, maxCustomSpacing]);

  const updateStackingValue = useCallback(
    (
      field: keyof StackingComponentDto,
      data: StackingComponentDto[keyof StackingComponentDto]
    ) => {
      setStacking?.({
        ...stacking,
        value: {
          ...stacking.value,
          [field]: data,
        },
      });
    },
    [stacking]
  );

  return (
    <div className='Stacking'>
      <StyleComponentHeader
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            disabled: disabled,
            onAction: () => onSystemReset?.(),
          },
        ]}
        label={i18n.translate(`Stacking`)}
      >
        {allowDirection && (
          <div className='StackingRow'>
            {i18n.translate(`Direction`)}
            <div className='RightContent'>
              <Tooltip
                active={!allowDirection}
                content={i18n.translate('OnlyVerticalTip')}
              >
                <SelectOptions
                  disabled={disabled}
                  options={stackingDirectionOptions}
                  onOptionSelect={(value) =>
                    updateStackingValue(
                      'direction',
                      value as StackingDirectionDtoEnum
                    )
                  }
                  selectedOption={
                    stacking.value?.direction ||
                    StackingDirectionDtoEnum.HORIZONTAL
                  }
                  label=''
                />
              </Tooltip>
            </div>
          </div>
        )}

        <div className='StackingRow'>
          {i18n.translate(`Spacing`)}
          <div className='RightContent'>
            <SelectOptions
              options={stackingSpacingOptions}
              onOptionSelect={(value) =>
                updateStackingValue('spacing', {
                  ...stacking.value?.spacing,
                  type: value as SpacingTypeDtoEnum,
                })
              }
              selectedOption={
                stacking.value?.spacing?.type || SpacingTypeDtoEnum.MEDIUM
              }
              label=''
              disabled={disabled}
            />
          </div>
        </div>
        {stacking.value?.spacing?.type === SpacingTypeDtoEnum.CUSTOM && (
          <div className='StackingRow'>
            <div />
            <div className='RightContent'>
              <TextField
                autoComplete=''
                label=''
                type='number'
                value={stacking.value?.spacing?.customValue?.toString() || '4'}
                suffix='px'
                onChange={(value) =>
                  updateStackingValue('spacing', {
                    ...stacking.value?.spacing,
                    customValue: +value,
                  })
                }
                disabled={disabled}
                onBlur={onSpacingValdiate}
              />
            </div>
          </div>
        )}
        {!!note && (
          <Text tone='subdued' as='p'>
            {note}
          </Text>
        )}
      </StyleComponentHeader>
    </div>
  );
};
