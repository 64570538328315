import React from 'react';
import {
  Badge,
  Box,
  IndexTable,
  SkeletonDisplayText,
  Text,
} from '@shopify/polaris';
import { PromotionCodeAnalyticsEntryDto } from 'core/api/adminAnalytics/adminAnalyticsApi';
import { useI18n } from '@shopify/react-i18n';
import { PromotionCodeStatusDtoEnum } from 'core/api/adminAnalytics/adminAnalyticsEnums';

type DetailedCodeRowProps = {
  index: number;
  data: PromotionCodeAnalyticsEntryDto;
  listWidth: number;
  isLoading: boolean;
};

export const DetailedCodeRow: React.FC<DetailedCodeRowProps> = ({
  index,
  data,
  listWidth,
  isLoading,
}) => {
  const [i18n] = useI18n();
  return (
    <IndexTable.Row id={data.id as string} position={index}>
      <IndexTable.Cell className='PromotionCodeColumn'>
        {!isLoading ? (
          <Text as='p' fontWeight='medium'>
            {data.promotionCode}
          </Text>
        ) : (
          <SkeletonDisplayText />
        )}
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Box width={`${(listWidth / 100) * 40}px`}>
          {!isLoading ? (
            <Text truncate as='p'>
              {data.promotionsAppliedTo}
            </Text>
          ) : (
            <SkeletonDisplayText maxWidth={'100%' as any} />
          )}
        </Box>
      </IndexTable.Cell>
      <IndexTable.Cell className='StatusColumn'>
        {!isLoading ? (
          <Badge
            tone={
              data.status === PromotionCodeStatusDtoEnum.ACTIVE
                ? 'success'
                : undefined
            }
          >
            {i18n.translate(`${data.status}`)}
          </Badge>
        ) : (
          <SkeletonDisplayText />
        )}
      </IndexTable.Cell>
      <IndexTable.Cell className='UsageColumn'>
        {!isLoading ? (
          <Text alignment='end' as='p'>
            ${data.usageCount}
          </Text>
        ) : (
          <SkeletonDisplayText />
        )}
      </IndexTable.Cell>
      <IndexTable.Cell className='LimitColumn'>
        {!isLoading ? (
          <Text alignment='end' as='p'>
            {data.limit}
          </Text>
        ) : (
          <SkeletonDisplayText />
        )}
      </IndexTable.Cell>
    </IndexTable.Row>
  );
};
