import React, { useEffect } from 'react';
import { ResourcePickerOptions } from 'core/api/appBridge';
import { ShopifyGlobal, useAppBridge } from '@shopify/app-bridge-react';

export type ResourcePickerAdapterProps = ResourcePickerOptions & {
  isTestMode?: boolean; // For storybook test purpose
  onCancel?: () => any;
  onSelection?: (data: any) => void;
  showVariants?: boolean;
};

export const ResourcePickerAdapter: React.FC<ResourcePickerAdapterProps> = (
  props
) => {
  const {
    type,
    showVariants,
    multiple,
    selectionIds,
    onCancel,
    onSelection,
  } = props;

  const appBridge: ShopifyGlobal = useAppBridge();

  useEffect(() => {
    const handleSelection = async () => {
      const selected = await appBridge.resourcePicker({
        type: type,
        selectionIds: selectionIds,
        multiple: multiple,
        filter: {
          variants: showVariants,
        },
      });

      if (selected === undefined) {
        onCancel?.();
      } else {
        onSelection?.(selected);
      }
    };

    handleSelection();
  }, [type, selectionIds, multiple, showVariants]);

  return null;
};
