import React, { useMemo } from 'react';
import {
  BlockStack,
  Button,
  ButtonGroup,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  PromotionOverviewScheduleDto,
  PromotionStatusDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { PromotionStatusDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { formatDateWithOrdinalCET, formatTimeDistance } from 'core/utils';
import { EditIcon, CalendarTimeIcon, DeleteIcon } from '@shopify/polaris-icons';
import { ScheduleModalEnum } from '../../../SchedulePromotionModal/SchedulePromotionModal';

type DefaultStatusProps = {
  promotionStatus: PromotionStatusDto;
  schedule: PromotionOverviewScheduleDto;
  scheduleIsLoading: boolean;
  isPromotionEditable: boolean;
  setScheduleModalType: (value: ScheduleModalEnum) => void;
  onDeleteEndDate: () => void;
  onDeleteStartDate: () => void;
};
const DefaultStatus: React.FC<DefaultStatusProps> = ({
  promotionStatus,
  schedule,
  scheduleIsLoading,
  isPromotionEditable,
  setScheduleModalType,
  onDeleteEndDate,
  onDeleteStartDate,
}) => {
  const [i18n] = useI18n();

  const startDateVariant = useMemo(() => {
    if (!schedule.startsAt) return i18n.translate('NotConfigured');
    switch (promotionStatus) {
      case PromotionStatusDtoEnum.DRAFT:
        return formatDateWithOrdinalCET(schedule.startsAt.toString());
      case PromotionStatusDtoEnum.SCHEDULED:
        return (
          i18n.translate('Starts') + formatTimeDistance(schedule.startsAt || 0)
        );
      default:
        return (
          i18n.translate('Started') + formatTimeDistance(schedule.startsAt || 0)
        );
    }
  }, [promotionStatus, schedule.startsAt, i18n]);

  const endDateVariant = useMemo(() => {
    switch (promotionStatus) {
      case PromotionStatusDtoEnum.EXPIRED:
        return (
          i18n.translate(`Expired`) + formatTimeDistance(schedule.endsAt || 0)
        );
      default:
        return schedule.endsAt
          ? i18n.translate('Ends') + formatTimeDistance(schedule.endsAt || 0)
          : i18n.translate('NotConfigured');
    }
  }, [promotionStatus, schedule.endsAt, i18n]);

  return (
    <div className='Status'>
      <BlockStack gap='300'>
        {promotionStatus !== PromotionStatusDtoEnum.EXPIRED && (
          <InlineStack align='space-between' blockAlign='center'>
            <BlockStack gap='100'>
              <Text variant='bodySm' as='p' fontWeight='medium'>
                {i18n.translate('StartDate')}
              </Text>
              <Text variant='bodySm' as='p' tone='subdued'>
                {startDateVariant}
              </Text>
            </BlockStack>
            {isPromotionEditable &&
              promotionStatus !== PromotionStatusDtoEnum.PUBLISHED && (
                <ButtonGroup>
                  <Button
                    onClick={() =>
                      setScheduleModalType(ScheduleModalEnum.START_DATE)
                    }
                    icon={schedule.startsAt ? EditIcon : CalendarTimeIcon}
                    variant='tertiary'
                  />
                  {!!schedule.startsAt && (
                    <Button
                      onClick={onDeleteStartDate}
                      disabled={scheduleIsLoading}
                      icon={DeleteIcon}
                      variant='tertiary'
                    />
                  )}
                </ButtonGroup>
              )}
          </InlineStack>
        )}

        <InlineStack align='space-between' blockAlign='center'>
          <BlockStack gap='100'>
            <Text variant='bodySm' as='p' fontWeight='medium'>
              {i18n.translate('EndDate')}
            </Text>
            <Text variant='bodySm' as='p' tone='subdued'>
              {endDateVariant}
            </Text>
          </BlockStack>
          <InlineStack>
            {promotionStatus !== PromotionStatusDtoEnum.EXPIRED &&
              isPromotionEditable && (
                <ButtonGroup>
                  <Button
                    onClick={() =>
                      setScheduleModalType(ScheduleModalEnum.END_DATE)
                    }
                    icon={schedule.endsAt ? EditIcon : CalendarTimeIcon}
                    variant='tertiary'
                  />
                  {!!schedule.endsAt && (
                    <Button
                      onClick={onDeleteEndDate}
                      icon={DeleteIcon}
                      disabled={scheduleIsLoading}
                      variant='tertiary'
                    />
                  )}
                </ButtonGroup>
              )}
          </InlineStack>
        </InlineStack>
        {promotionStatus === PromotionStatusDtoEnum.EXPIRED && (
          <Text tone='subdued' as='p' variant='bodySm'>
            {i18n.translate('DuplicateToRepublish')}
          </Text>
        )}
      </BlockStack>
    </div>
  );
};

export default DefaultStatus;
