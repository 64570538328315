import React, { useCallback, useEffect, useMemo } from 'react';
import './Tier.scss';
import { useI18n } from '@shopify/react-i18n';
import {
  FieldsProps,
  OfferTierProps,
  OfferTierUpdateDto,
} from '../../../Offers/types/OfferTierUpdateDto';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { cloneDeep } from 'lodash';
import {
  Card,
  Icon,
  TextField,
  Text,
  InlineStack,
  Button,
  BlockStack,
  Box,
  Banner,
} from '@shopify/polaris';
import { OfferTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { QuestionCircleIcon } from '@shopify/polaris-icons';
export type TierProps = {
  tier?: OfferTierProps;
  tierIndex: number;
  onTierUpdate(data: OfferTierUpdateDto): void;
  onTierDelete(index: number): void;
  fields: FieldsProps;
  prevTierValue: number;
  nextTierValue: number;
  setTierAction(action: string): void;
  tierAction: string;
  updateValidity(index: number, isValid: boolean): void;
  removeValidity(index: number): void;
  isLastTier: boolean;
  offerType: OfferTypeDtoEnum;
};

type FormFields = {
  spendBoughtFieldValue?: number | null | string;
  offerDiscountFieldValue?: number | null | string;
  leastExpensiveFieldValue?: number | null | string;
};

export const Tier: React.FC<TierProps> = (props) => {
  const {
    tier,
    tierIndex,
    onTierUpdate,
    onTierDelete,
    fields,
    prevTierValue,
    nextTierValue,
    setTierAction,
    tierAction,
    updateValidity,
    removeValidity,
    isLastTier,
    offerType,
  } = props;
  const {
    spendBoughtFieldName,
    offerDiscountFieldName,
    leastExpensiveFieldName,
  } = fields;

  const [i18n] = useI18n();

  const spendBoughtFieldValue = useMemo(
    () => tier?.[spendBoughtFieldName as keyof typeof tier] || 0,
    [spendBoughtFieldName, tier]
  );
  const offerDiscountFieldValue = useMemo(
    () => tier?.[offerDiscountFieldName as keyof typeof tier] || 0,
    [offerDiscountFieldName, tier]
  );
  const leastExpensiveFieldValue = useMemo(
    () => tier?.[leastExpensiveFieldName as keyof typeof tier] || 0,
    [leastExpensiveFieldName, tier]
  );

  const isBogoType = useMemo(
    () => spendBoughtFieldName === 'requirementWholeValue',
    [spendBoughtFieldName]
  );
  const isGetAmountOffType = useMemo(
    () => offerDiscountFieldName === 'entitledPreciseValue',
    [offerDiscountFieldName]
  );

  const formForGetPercentage = yup.object({
    spendBoughtFieldValue: yup
      .number()
      .min(0)
      .moreThan(prevTierValue)
      .required(),
    offerDiscountFieldValue: yup.number().min(1).max(100).required(),
  });

  const formForGetAmount = yup.object({
    spendBoughtFieldValue: yup
      .number()
      .min(0)
      .moreThan(prevTierValue)
      .required(),
    offerDiscountFieldValue: yup.number().min(0.01).required(),
  });

  const formForBogo = yup.object({
    spendBoughtFieldValue: yup
      .number()
      .min(1)
      .integer()
      .moreThan(prevTierValue)
      .required(),
    offerDiscountFieldValue: yup.number().when([], {
      is: () => offerDiscountFieldName === 'entitledWholeValue',
      then: yup.number().min(1).max(100).required(),
      otherwise: yup.number().min(0.01).required(),
    }),
    leastExpensiveFieldValue: yup.number().min(1).integer().required(),
  });

  const formForVolume = yup.object({
    spendBoughtFieldValue: yup
      .number()
      .min(1)
      .integer()
      .moreThan(prevTierValue)
      .required(),
    offerDiscountFieldValue: yup.number().when([], {
      is: () => offerDiscountFieldName === 'entitledWholeValue',
      then: yup.number().min(1).max(100).required(),
      otherwise: yup.number().min(0.01).required(),
    }),
  });

  const formNeeded = useMemo(
    () =>
      offerType === OfferTypeDtoEnum.TIERED_BOGO
        ? formForBogo
        : offerType === OfferTypeDtoEnum.TIERED_SPEND_X_GET_Y
        ? formForGetAmount
        : offerType === OfferTypeDtoEnum.VOLUME_DISCOUNT
        ? formForVolume
        : formForGetPercentage,
    [
      formForBogo,
      formForGetAmount,
      formForGetPercentage,
      formForVolume,
      offerType,
    ]
  );

  const defaultFieldsValues: FormFields = useMemo(() => {
    if (leastExpensiveFieldName) {
      return {
        spendBoughtFieldValue,
        offerDiscountFieldValue,
        leastExpensiveFieldValue,
      };
    } else {
      return {
        spendBoughtFieldValue,
        offerDiscountFieldValue,
      };
    }
  }, [
    spendBoughtFieldValue,
    offerDiscountFieldValue,
    leastExpensiveFieldValue,
    leastExpensiveFieldName,
    offerType,
  ]);

  const { control, formState, watch, trigger, reset } = useForm<FormFields>({
    defaultValues: defaultFieldsValues,
    mode: 'onChange',
    resolver: yupResolver(formNeeded),
  });

  const getTierGreyBoxContent = useMemo(() => {
    const isError = formState.errors.spendBoughtFieldValue;
    const roundSpendBoughtFieldValue = spendBoughtFieldValue
      ? Math.round(spendBoughtFieldValue * 100) / 100
      : '?';
    const numberType = isBogoType
      ? roundSpendBoughtFieldValue
      : `$${roundSpendBoughtFieldValue}`;
    const value = isError ? (
      <span style={{ color: '#D72C0D' }}>
        &nbsp;{numberType}
        &nbsp;
      </span>
    ) : (
      numberType
    );

    if (isBogoType) {
      if (isLastTier) {
        return (
          <>
            {i18n.translate('Tier.LastTierInfoBogo.BuysOver')} {value}{' '}
            {offerType !== OfferTypeDtoEnum.VOLUME_DISCOUNT
              ? i18n.translate('Tier.LastTierInfoBogo.FromPurchase')
              : i18n.translate('Tier.LastTierInfoBogo.Items')}
          </>
        );
      } else {
        return (
          <>
            {nextTierValue - 1 !== value
              ? i18n.translate('Tier.TierInfoBogo.BuysBetween')
              : i18n.translate('Tier.TierInfoBogo.Buys')}{' '}
            {value}{' '}
            {nextTierValue - 1 !== value &&
              `${i18n.translate('Tier.TierInfoBogo.And')} ${
                nextTierValue - 1 > 0 ? nextTierValue - 1 : '?'
              }`}{' '}
            {offerType !== OfferTypeDtoEnum.VOLUME_DISCOUNT
              ? i18n.translate('Tier.TierInfoBogo.FromPurchase')
              : i18n.translate('Tier.TierInfoBogo.Items')}
          </>
        );
      }
    } else {
      if (isLastTier) {
        return (
          <>
            {i18n.translate('Tier.LastTierInfoNotBogo.SpendsOver')} {value}{' '}
            {i18n.translate('Tier.LastTierInfoNotBogo.OnPurchase')}
          </>
        );
      } else {
        return (
          <>
            {i18n.translate('Tier.TierInfoNotBogo.SpendsBetween')} {value}{' '}
            {i18n.translate('Tier.TierInfoNotBogo.And')} $
            {nextTierValue - 0.01 > 0
              ? Math.round((nextTierValue - 0.01) * 100) / 100
              : '?'}{' '}
            {i18n.translate('Tier.TierInfoNotBogo.OnPurchase')}
          </>
        );
      }
    }
  }, [
    formState.errors.spendBoughtFieldValue,
    i18n,
    isBogoType,
    isLastTier,
    spendBoughtFieldValue,
    nextTierValue,
    offerType,
  ]);

  const validationMessages = useMemo(() => {
    const currentErrors = cloneDeep(formState.errors);
    const {
      spendBoughtFieldValue,
      offerDiscountFieldValue,
      leastExpensiveFieldValue,
    } = currentErrors;
    if (spendBoughtFieldValue) {
      if (tierIndex > 0) {
        if (isBogoType) {
          spendBoughtFieldValue.message = i18n.translate(
            'Tier.ValidityErrors.BogoType.TierIndexHigherThanZero'
          );
        } else {
          spendBoughtFieldValue.message = i18n.translate(
            'Tier.ValidityErrors.NotBogoType.TierIndexHigherThanZero'
          );
        }
      } else {
        if (isBogoType) {
          spendBoughtFieldValue.message = i18n.translate(
            'Tier.ValidityErrors.BogoType.TierIndexIsZero'
          );
        } else {
          spendBoughtFieldValue.message = i18n.translate(
            'Tier.ValidityErrors.NotBogoType.TierIndexIsZero'
          );
        }
      }
    }
    if (offerDiscountFieldValue) {
      if (isGetAmountOffType) {
        offerDiscountFieldValue.message = i18n.translate(
          'Tier.ValidityErrors.AmountOffType'
        );
      } else {
        if (offerDiscountFieldValue.type === 'max') {
          offerDiscountFieldValue.message = i18n.translate(
            'Tier.ValidityErrors.PercentageOffTypeMax'
          );
        } else {
          offerDiscountFieldValue.message = i18n.translate(
            'Tier.ValidityErrors.PercentageOffTypeMin'
          );
        }
      }
    }
    if (leastExpensiveFieldValue) {
      leastExpensiveFieldValue.message = i18n.translate(
        'Tier.ValidityErrors.LeastExpensiveNumber'
      );
    }

    const arrOfTierError = Object.values(currentErrors).map(
      (error) => error?.message
    );

    return arrOfTierError?.length ? (
      <div className='TierErrorBoxWrapper'>
        {arrOfTierError.map((errorMessage, idx) => (
          <Banner tone='critical' key={idx}>
            {errorMessage}
          </Banner>
        ))}
      </div>
    ) : null;
  }, [formState, i18n, isBogoType, isGetAmountOffType, tierIndex, offerType]);

  const shouldTriggerOnFormValidityChange = useMemo(
    () => formState.isDirty,
    [formState.isDirty]
  );

  const changeValue = useCallback(
    (field: ControllerRenderProps<FormFields>, value: string) => {
      field.onChange(value);
    },
    []
  );

  const deleteTierAction = useCallback(() => {
    removeValidity(tierIndex);
    onTierDelete(tierIndex);
  }, [onTierDelete, removeValidity, tierIndex]);

  const triggerFields = useCallback(() => {
    trigger('spendBoughtFieldValue');
    trigger('offerDiscountFieldValue');
    leastExpensiveFieldName && trigger('leastExpensiveFieldValue');
  }, [trigger, leastExpensiveFieldName]);

  const resetFields = useCallback(() => {
    tierAction && setTierAction('');
    (tierAction === 'Delete' || tierAction === 'Rebuild') &&
      reset(defaultFieldsValues);
    shouldTriggerOnFormValidityChange &&
      reset(defaultFieldsValues, { keepErrors: true });
  }, [
    tierAction,
    defaultFieldsValues,
    shouldTriggerOnFormValidityChange,
    reset,
    setTierAction,
  ]);

  useEffect(() => {
    updateValidity(tierIndex, formState.isValid);
  }, [formState.isValid, tierIndex]);

  useEffect(() => {
    if (tierAction.length) {
      resetFields();
      triggerFields();
    }
  }, [tierAction, resetFields, triggerFields]);

  // catch prev tier value error
  useEffect(() => {
    if (
      prevTierValue &&
      spendBoughtFieldValue &&
      (prevTierValue >= spendBoughtFieldValue ||
        prevTierValue < spendBoughtFieldValue)
    ) {
      triggerFields();
      updateValidity(tierIndex, prevTierValue < spendBoughtFieldValue);
    }
  }, [prevTierValue, spendBoughtFieldValue, tierIndex]);

  // catch prev tier value error
  useEffect(() => {
    triggerFields();
  }, [prevTierValue, spendBoughtFieldValue]);

  // checks validity if value has been updated (compares with the previous one) or tier added/deleted
  useEffect(() => {
    if (shouldTriggerOnFormValidityChange) {
      resetFields();
      triggerFields();
    }
  }, [shouldTriggerOnFormValidityChange, triggerFields, resetFields]);

  const fractionDigitsFormator = useCallback(
    (name: string, value: number | null) => {
      if (typeof value !== 'number' && !value) return null;
      const maxFracDigits =
        name === 'spendBoughtFieldValue' && !isBogoType
          ? 2
          : name === 'offerDiscountFieldValue' && isGetAmountOffType
          ? 2
          : 0;
      return Number(value.toFixed(maxFracDigits));
    },
    [isBogoType, isGetAmountOffType]
  );

  // runs tier update func whenever a new value has been entered
  useEffect(() => {
    const subscription = watch((value, { type, name }) => {
      if (type === 'change') {
        const fieldName =
          name === 'spendBoughtFieldValue'
            ? spendBoughtFieldName
            : name === 'offerDiscountFieldValue'
            ? offerDiscountFieldName
            : leastExpensiveFieldName;

        const currentValue = value[name as keyof typeof value] || null;

        onTierUpdate({
          tierIndex,
          tier: {
            ...tier,
            [fieldName]: fractionDigitsFormator(
              name!,
              typeof currentValue === 'string'
                ? parseFloat(currentValue as string)
                : currentValue
            ),
          },
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [
    watch,
    tier,
    onTierUpdate,
    tierIndex,
    leastExpensiveFieldName,
    offerDiscountFieldName,
    spendBoughtFieldName,
  ]);

  return (
    <Card>
      <InlineStack align='space-between'>
        <BlockStack gap='100'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate('Tier.Tier')} {tierIndex + 1}
          </Text>
          <Text as='p' tone='subdued'>
            {getTierGreyBoxContent}
          </Text>
        </BlockStack>
        {tierIndex !== 0 && tierIndex !== 1 && (
          <Button variant='plain' tone='critical' onClick={deleteTierAction}>
            {i18n.translate('Tier.TrashIconText')}
          </Button>
        )}
      </InlineStack>
      <Box paddingBlockStart='400'>
        <BlockStack gap='300'>
          <InlineStack gap='200' blockAlign='center'>
            <Text as='p'>
              {isBogoType
                ? i18n.translate('Tier.LabelVisitorBuysAtLeast')
                : i18n.translate('Tier.LabelVisitorSpendAmount')}
            </Text>
            <Box maxWidth='15%'>
              <Controller<FormFields>
                name='spendBoughtFieldValue'
                control={control}
                render={({ field }) => (
                  <TextField
                    type='number'
                    inputMode={isBogoType ? 'numeric' : 'decimal'}
                    prefix={isBogoType ? null : '$'}
                    suffix={isBogoType ? 'item(s)' : null}
                    value={`${field.value}`}
                    label={
                      isBogoType
                        ? i18n.translate('Tier.LabelVisitorBuysAtLeast')
                        : i18n.translate('Tier.LabelVisitorSpendAmount')
                    }
                    labelHidden
                    onChange={(value) => changeValue(field, value)}
                    autoComplete='off'
                  />
                )}
              />
            </Box>
            <InlineStack gap='100'>
              <Text as='p'>
                {isBogoType
                  ? i18n.translate('Tier.ProductsBuysAtLeast.From')
                  : i18n.translate('Tier.PurchaseRequirementsSpendAmount.From')}
                {isBogoType && offerType !== OfferTypeDtoEnum.VOLUME_DISCOUNT
                  ? i18n.translate(
                      'Tier.ProductsBuysAtLeast.PurchaseRequirementsLink'
                    )
                  : isBogoType && offerType === OfferTypeDtoEnum.VOLUME_DISCOUNT
                  ? i18n.translate(
                      'Tier.PurchaseRequirementsSpendAmount.ProductsLink'
                    )
                  : i18n.translate(
                      'Tier.PurchaseRequirementsSpendAmount.PurchaseRequirementsLink'
                    )}
              </Text>
              <span>
                <Icon source={QuestionCircleIcon} />
              </span>
            </InlineStack>
          </InlineStack>
          <InlineStack gap='200'>
            <InlineStack gap='200' blockAlign='center'>
              <Text as='p'>{i18n.translate('Tier.LabelOfferDiscount')}</Text>

              <Box width='17%'>
                <Controller<FormFields>
                  name='offerDiscountFieldValue'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      inputMode={isGetAmountOffType ? 'decimal' : 'numeric'}
                      prefix={isGetAmountOffType ? '$' : null}
                      suffix={isGetAmountOffType ? null : '%'}
                      value={`${field?.value || 0}`}
                      label={i18n.translate('Tier.LabelOfferDiscount')}
                      labelHidden
                      onChange={(value) => changeValue(field, value)}
                      autoComplete='off'
                    />
                  )}
                />
              </Box>

              <Text as='p'>
                {isBogoType && offerType !== OfferTypeDtoEnum.VOLUME_DISCOUNT
                  ? null
                  : i18n.translate('Tier.ProductsOfferDiscount.On')}

                {isBogoType && offerType !== OfferTypeDtoEnum.VOLUME_DISCOUNT
                  ? null
                  : i18n.translate('Tier.ProductsOfferDiscount.ProductsLink')}

              </Text>

              {leastExpensiveFieldName && (
                <>
                  <Text as='p'>
                    {i18n.translate('Tier.LabelXLeastExpensive')}
                  </Text>
                  <Box maxWidth='15%'>
                    <Controller<FormFields>
                      name='leastExpensiveFieldValue'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          type='number'
                          inputMode='numeric'
                          suffix='item(s)'
                          value={`${field.value}`}
                          label={i18n.translate('Tier.LabelXLeastExpensive')}
                          labelHidden
                          onChange={(value) => changeValue(field, value)}
                          autoComplete='off'
                        />
                      )}
                    />
                  </Box>

                  <Text as='p'>
                    {i18n.translate('Tier.ProductsBuysAtLeast.From')}

                    {i18n.translate(
                      'Tier.ProductsBuysAtLeast.PurchaseRequirementsLink'
                    )}

                  </Text>
                </>
              )}
            </InlineStack>
          </InlineStack>
        </BlockStack>
      </Box>
      {validationMessages}
    </Card>
  );
};
