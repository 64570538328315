import React from 'react';

export const boldIconToolbar = (active?: boolean) => {
  const color = active ? '#202223' : '#8A8A8A';
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        d='M11.3921 16H5V4H10.8738C11.5663 4.00004 12.2444 4.1913 12.8279 4.55119C13.4113 4.91108 13.8758 5.42456 14.1665 6.03097C14.4572 6.63738 14.5619 7.31138 14.4682 7.97335C14.3746 8.63531 14.0865 9.25759 13.638 9.76667C14.2238 10.2186 14.65 10.8347 14.8579 11.53C15.0658 12.2253 15.0452 12.9657 14.7989 13.6492C14.5526 14.3327 14.0928 14.9258 13.4827 15.3468C12.8726 15.7678 12.1422 15.996 11.3921 16ZM7.07311 14H11.3783C11.5825 14 11.7846 13.9612 11.9733 13.8858C12.1619 13.8104 12.3333 13.6999 12.4777 13.5607C12.6221 13.4214 12.7366 13.256 12.8148 13.074C12.8929 12.892 12.9331 12.697 12.9331 12.5C12.9331 12.303 12.8929 12.108 12.8148 11.926C12.7366 11.744 12.6221 11.5786 12.4777 11.4393C12.3333 11.3001 12.1619 11.1896 11.9733 11.1142C11.7846 11.0388 11.5825 11 11.3783 11H7.07311V14ZM7.07311 9H10.8738C11.078 9 11.2802 8.9612 11.4688 8.88582C11.6575 8.81044 11.8289 8.69995 11.9732 8.56066C12.1176 8.42137 12.2322 8.25601 12.3103 8.07403C12.3884 7.89204 12.4287 7.69698 12.4287 7.5C12.4287 7.30302 12.3884 7.10796 12.3103 6.92597C12.2322 6.74399 12.1176 6.57863 11.9732 6.43934C11.8289 6.30005 11.6575 6.18956 11.4688 6.11418C11.2802 6.0388 11.078 6 10.8738 6H7.07311V9Z'
        fill={color}
      />
    </svg>
  );
};

export const smartTagIconToolbar = (
  active?: boolean,
  specialColor?: string
) => {
  const defaultColor = specialColor ?? '#8A8A8A';
  const color = active ? '#202223' : defaultColor;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        d='M8.82843 7.5C8.27614 7.5 7.82843 7.05228 7.82843 6.5C7.82843 5.94772 8.27614 5.5 8.82843 5.5C9.38072 5.5 9.82843 5.94772 9.82843 6.5C9.82843 7.05228 9.38072 7.5 8.82843 7.5Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.8115 4.09835C12.1083 3.39509 11.1544 3 10.1599 3H8.07843C6.55965 3 5.32843 4.23122 5.32843 5.75V8.03854C5.32843 8.9005 5.67084 9.72714 6.28033 10.3366L10.4866 14.5429C11.4629 15.5192 13.0458 15.5192 14.0221 14.5429L16.6945 11.8705C17.7685 10.7965 17.7685 9.05532 16.6945 7.98138L12.8115 4.09835ZM10.1599 4.5C10.7566 4.5 11.3289 4.73705 11.7509 5.15901L15.6339 9.04204C16.122 9.5302 16.122 10.3217 15.6339 10.8098L12.9615 13.4822C12.5709 13.8728 11.9378 13.8728 11.5472 13.4822L7.34099 9.27598C7.0128 8.94779 6.82843 8.50267 6.82843 8.03854V5.75C6.82843 5.05965 7.38807 4.5 8.07843 4.5H10.1599Z'
        fill={color}
      />
      <path
        d='M3.57844 5.5C3.16422 5.5 2.82844 5.83579 2.82844 6.25V9.2786C2.82844 10.1482 3.17692 10.9815 3.79597 11.5922L8.55171 16.2839C8.84658 16.5748 9.32144 16.5716 9.61234 16.2767C9.90324 15.9819 9.90002 15.507 9.60515 15.2161L4.84942 10.5244C4.51608 10.1956 4.32844 9.74684 4.32844 9.2786V6.25C4.32844 5.83579 3.99265 5.5 3.57844 5.5Z'
        fill={color}
      />
    </svg>
  );
};

export const cursiveIconToolbar = (active?: boolean) => {
  const color = active ? '#202223' : '#8A8A8A';
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15 5.1C15 5.37614 14.7761 5.6 14.5 5.6L7.5 5.6C7.22386 5.6 7 5.37614 7 5.1V4.5C7 4.22386 7.22386 4 7.5 4L14.5 4C14.7761 4 15 4.22386 15 4.5V5.1Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.21533 14.8428L10.2153 4.84277L11.7843 5.15656L9.78426 15.1566L8.21533 14.8428Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13 15.5004C13 15.7765 12.7761 16.0004 12.5 16.0004H5.5C5.22386 16.0004 5 15.7765 5 15.5004V14.9004C5 14.6242 5.22386 14.4004 5.5 14.4004H12.5C12.7761 14.4004 13 14.6242 13 14.9004V15.5004Z'
        fill={color}
      />
    </svg>
  );
};

export const underlineIconToolbar = (active?: boolean) => {
  const color = active ? '#202223' : '#8A8A8A';
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14 15.5004C14 15.7765 13.7761 16.0004 13.5 16.0004H6.5C6.22386 16.0004 6 15.7765 6 15.5004V14.9004C6 14.6242 6.22386 14.4004 6.5 14.4004H13.5C13.7761 14.4004 14 14.6242 14 14.9004V15.5004Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.2002 9.5V4.5C6.2002 4.22386 6.42405 4 6.7002 4H7.3002C7.57634 4 7.8002 4.22386 7.8002 4.5V9.5C7.8002 10.715 8.78517 11.7 10.0002 11.7C11.2152 11.7 12.2002 10.715 12.2002 9.5V4.5C12.2002 4.22386 12.4241 4 12.7002 4H13.3002C13.5763 4 13.8002 4.22386 13.8002 4.5V9.5C13.8002 11.5987 12.0989 13.3 10.0002 13.3C7.90151 13.3 6.2002 11.5987 6.2002 9.5Z'
        fill={color}
      />
    </svg>
  );
};

export const strikeIconToolbar = (active?: boolean) => {
  const color = active ? '#202223' : '#8A8A8A';
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.22724 9.92842C7.97794 9.86506 7.74638 9.8062 7.52503 9.69989C7.22503 9.55046 6.95718 9.36633 6.72146 9.14752C6.48575 8.92338 6.29825 8.65654 6.15896 8.347C6.01968 8.03213 5.95003 7.66122 5.95003 7.23428C5.95003 6.55116 6.12682 5.96945 6.48039 5.48914C6.83932 5.00349 7.32414 4.63258 7.93486 4.37641C8.54557 4.12024 9.23396 3.99483 10 4.00016C10.7768 4.0055 11.4706 4.14426 12.0813 4.41644C12.6974 4.68328 13.2063 5.06753 13.6081 5.56919C13.9154 5.94789 14.1465 6.38287 14.3012 6.8741C14.3855 7.14205 14.1997 7.41003 13.9229 7.45817L13.4029 7.54862C13.1303 7.59603 12.8765 7.41024 12.7825 7.15003C12.6854 6.88149 12.5481 6.64007 12.3706 6.42575C12.0974 6.08419 11.7545 5.82269 11.342 5.64124C10.9295 5.45445 10.4768 5.35838 9.98396 5.35305C9.51253 5.34771 9.08664 5.42243 8.70628 5.57719C8.32593 5.73196 8.02325 5.9481 7.79825 6.22562C7.57325 6.49779 7.46075 6.81267 7.46075 7.17023C7.46075 7.52246 7.56253 7.80798 7.76611 8.02679C7.96968 8.24027 8.21878 8.41104 8.51343 8.53913C8.91674 8.72753 9.37523 8.91665 9.85721 9.11095C10.3565 9.31221 10.2182 10 9.67994 10H8.56829C8.523 10 8.47793 9.99376 8.43419 9.98202C8.3638 9.96313 8.29486 9.94561 8.22724 9.92842ZM10.1688 16C9.34914 16 8.60986 15.8612 7.95093 15.5837C7.29736 15.3062 6.75628 14.9113 6.32771 14.399C6.00755 14.0073 5.77171 13.5623 5.62017 13.0638C5.53844 12.7949 5.72879 12.5298 6.00638 12.4863L6.53042 12.404C6.78468 12.3641 7.02329 12.5259 7.12087 12.7641C7.33551 13.288 7.67648 13.7128 8.14378 14.0387C8.73843 14.4443 9.43753 14.6471 10.2411 14.6471C10.7607 14.6471 11.2295 14.5671 11.6474 14.407C12.0706 14.2415 12.4054 14.0067 12.6518 13.7025C12.8982 13.3983 13.0215 13.0381 13.0215 12.6218C13.0215 12.3817 12.9815 12.1745 12.9015 12H14.4635C14.4878 12.1691 14.5 12.3497 14.5 12.5417C14.5 13.0968 14.3875 13.5904 14.1625 14.0227C13.9429 14.455 13.6349 14.8179 13.2384 15.1114C12.842 15.3996 12.3813 15.6184 11.8563 15.7678C11.3313 15.9226 10.7688 16 10.1688 16Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 10.3002C16 10.5763 15.7761 10.8002 15.5 10.8002H4.5C4.22386 10.8002 4 10.5763 4 10.3002V9.7002C4 9.42405 4.22386 9.2002 4.5 9.2002H15.5C15.7761 9.2002 16 9.42405 16 9.7002V10.3002Z'
        fill={color}
      />
    </svg>
  );
};

export const textColorIconToolbar = (
  active?: boolean,
  currentColor?: string
) => {
  const color = active ? '#202223' : '#8A8A8A';
  const lineColor = active ? currentColor : '#8A8A8A';
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.99992 3C10.2968 2.99999 10.5658 3.17515 10.6859 3.44672L14.8862 12.9467C15.0537 13.3256 14.8824 13.7684 14.5035 13.9359C14.1247 14.1034 13.6818 13.9321 13.5143 13.5533L12.1643 10.5H7.83596L6.4862 13.5532C6.31872 13.9321 5.87584 14.1034 5.49699 13.936C5.11815 13.7685 4.9468 13.3256 5.11428 12.9468L9.31398 3.44675C9.43404 3.17518 9.703 3.00001 9.99992 3ZM9.99999 5.60482L11.5011 9H8.49907L9.99999 5.60482Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17 17C17 17.2761 16.7761 17.5 16.5 17.5H3.5C3.22386 17.5 3 17.2761 3 17V16.5C3 16.2239 3.22386 16 3.5 16H16.5C16.7761 16 17 16.2239 17 16.5V17Z'
        fill={lineColor}
      />
    </svg>
  );
};

export const codeIconToolbar = (active?: boolean) => {
  const color = active ? '#2C6ECB' : 'black';
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='12'
      viewBox='0 0 18 12'
      fill='none'
    >
      <path
        d='M10.9806 1.19631C11.0889 0.654749 10.7377 0.127924 10.1961 0.0196121C9.65457 -0.0886997 9.12774 0.262517 9.01943 0.804077L7.01943 10.8041C6.91112 11.3456 7.26233 11.8725 7.80389 11.9808C8.34545 12.0891 8.87228 11.7379 8.98059 11.1963L10.9806 1.19631Z'
        fill={color}
      />
      <path
        d='M5.8 2.40018C6.13137 2.84201 6.04183 3.46881 5.6 3.80018L2.66667 6.00018L5.6 8.20018C6.04183 8.53155 6.13137 9.15835 5.8 9.60018C5.46863 10.042 4.84183 10.1316 4.4 9.80018L0.4 6.80018C0.148194 6.61133 0 6.31494 0 6.00018C0 5.68542 0.148194 5.38904 0.4 5.20018L4.4 2.20018C4.84183 1.86881 5.46863 1.95835 5.8 2.40018Z'
        fill={color}
      />
      <path
        d='M12.2 9.60018C11.8686 9.15836 11.9582 8.53156 12.4 8.20018L15.3333 6.00019L12.4 3.80019C11.9582 3.46881 11.8686 2.84201 12.2 2.40019C12.5314 1.95836 13.1582 1.86881 13.6 2.20018L17.6 5.20019C17.8518 5.38904 18 5.68543 18 6.00019C18 6.31494 17.8518 6.61133 17.6 6.80019L13.6 9.80019C13.1582 10.1316 12.5314 10.042 12.2 9.60018Z'
        fill={color}
      />
    </svg>
  );
};

export const textAlignLeftToolbar = (active?: boolean) => {
  const color = active ? '#2C6ECB' : 'black';
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='14'
      viewBox='0 0 16 14'
      fill='none'
    >
      <path
        d='M1 0H15C15.2652 0 15.5196 0.105357 15.7071 0.292893C15.8946 0.48043 16 0.734784 16 1C16 1.26522 15.8946 1.51957 15.7071 1.70711C15.5196 1.89464 15.2652 2 15 2H1C0.734784 2 0.48043 1.89464 0.292893 1.70711C0.105357 1.51957 0 1.26522 0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM1 4H11C11.2652 4 11.5196 4.10536 11.7071 4.29289C11.8946 4.48043 12 4.73478 12 5C12 5.26522 11.8946 5.51957 11.7071 5.70711C11.5196 5.89464 11.2652 6 11 6H1C0.734784 6 0.48043 5.89464 0.292893 5.70711C0.105357 5.51957 0 5.26522 0 5C0 4.73478 0.105357 4.48043 0.292893 4.29289C0.48043 4.10536 0.734784 4 1 4ZM1 8H15C15.2652 8 15.5196 8.10536 15.7071 8.29289C15.8946 8.48043 16 8.73478 16 9C16 9.26522 15.8946 9.51957 15.7071 9.70711C15.5196 9.89464 15.2652 10 15 10H1C0.734784 10 0.48043 9.89464 0.292893 9.70711C0.105357 9.51957 0 9.26522 0 9C0 8.73478 0.105357 8.48043 0.292893 8.29289C0.48043 8.10536 0.734784 8 1 8ZM1 12H11C11.2652 12 11.5196 12.1054 11.7071 12.2929C11.8946 12.4804 12 12.7348 12 13C12 13.2652 11.8946 13.5196 11.7071 13.7071C11.5196 13.8946 11.2652 14 11 14H1C0.734784 14 0.48043 13.8946 0.292893 13.7071C0.105357 13.5196 0 13.2652 0 13C0 12.7348 0.105357 12.4804 0.292893 12.2929C0.48043 12.1054 0.734784 12 1 12Z'
        fill={color}
      />
    </svg>
  );
};

export const textAlignCenterToolbar = (active?: boolean) => {
  const color = active ? '#2C6ECB' : 'black';
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='14'
      viewBox='0 0 16 14'
      fill='none'
    >
      <path
        d='M1 0H15C15.2652 0 15.5196 0.105357 15.7071 0.292893C15.8946 0.48043 16 0.734784 16 1C16 1.26522 15.8946 1.51957 15.7071 1.70711C15.5196 1.89464 15.2652 2 15 2H1C0.734784 2 0.48043 1.89464 0.292893 1.70711C0.105357 1.51957 0 1.26522 0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM3 4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5C14 5.26522 13.8946 5.51957 13.7071 5.70711C13.5196 5.89464 13.2652 6 13 6H3C2.73478 6 2.48043 5.89464 2.29289 5.70711C2.10536 5.51957 2 5.26522 2 5C2 4.73478 2.10536 4.48043 2.29289 4.29289C2.48043 4.10536 2.73478 4 3 4ZM1 8H15C15.2652 8 15.5196 8.10536 15.7071 8.29289C15.8946 8.48043 16 8.73478 16 9C16 9.26522 15.8946 9.51957 15.7071 9.70711C15.5196 9.89464 15.2652 10 15 10H1C0.734784 10 0.48043 9.89464 0.292893 9.70711C0.105357 9.51957 0 9.26522 0 9C0 8.73478 0.105357 8.48043 0.292893 8.29289C0.48043 8.10536 0.734784 8 1 8ZM3 12H13C13.2652 12 13.5196 12.1054 13.7071 12.2929C13.8946 12.4804 14 12.7348 14 13C14 13.2652 13.8946 13.5196 13.7071 13.7071C13.5196 13.8946 13.2652 14 13 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13C2 12.7348 2.10536 12.4804 2.29289 12.2929C2.48043 12.1054 2.73478 12 3 12Z'
        fill={color}
      />
    </svg>
  );
};

export const textAlignRightToolbar = (active?: boolean) => {
  const color = active ? '#2C6ECB' : 'black';
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='14'
      viewBox='0 0 16 14'
      fill='none'
    >
      <path
        d='M1 0H15C15.2652 0 15.5196 0.105357 15.7071 0.292893C15.8946 0.48043 16 0.734784 16 1C16 1.26522 15.8946 1.51957 15.7071 1.70711C15.5196 1.89464 15.2652 2 15 2H1C0.734784 2 0.48043 1.89464 0.292893 1.70711C0.105357 1.51957 0 1.26522 0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM5 4H15C15.2652 4 15.5196 4.10536 15.7071 4.29289C15.8946 4.48043 16 4.73478 16 5C16 5.26522 15.8946 5.51957 15.7071 5.70711C15.5196 5.89464 15.2652 6 15 6H5C4.73478 6 4.48043 5.89464 4.29289 5.70711C4.10536 5.51957 4 5.26522 4 5C4 4.73478 4.10536 4.48043 4.29289 4.29289C4.48043 4.10536 4.73478 4 5 4ZM1 8H15C15.2652 8 15.5196 8.10536 15.7071 8.29289C15.8946 8.48043 16 8.73478 16 9C16 9.26522 15.8946 9.51957 15.7071 9.70711C15.5196 9.89464 15.2652 10 15 10H1C0.734784 10 0.48043 9.89464 0.292893 9.70711C0.105357 9.51957 0 9.26522 0 9C0 8.73478 0.105357 8.48043 0.292893 8.29289C0.48043 8.10536 0.734784 8 1 8ZM5 12H15C15.2652 12 15.5196 12.1054 15.7071 12.2929C15.8946 12.4804 16 12.7348 16 13C16 13.2652 15.8946 13.5196 15.7071 13.7071C15.5196 13.8946 15.2652 14 15 14H5C4.73478 14 4.48043 13.8946 4.29289 13.7071C4.10536 13.5196 4 13.2652 4 13C4 12.7348 4.10536 12.4804 4.29289 12.2929C4.48043 12.1054 4.73478 12 5 12Z'
        fill={color}
      />
    </svg>
  );
};

export const linkToolbar = (active?: boolean) => {
  const color = active ? '#2C6ECB' : 'black';
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 24 24'
      fill='none'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71'></path>
      <path d='M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71'></path>
    </svg>
  );
};
