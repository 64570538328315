import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  findIconDefinition,
  IconLookup,
} from '@fortawesome/fontawesome-svg-core';

export const useIcon = (iconLookup: IconLookup) => {
  const iconDefinition = findIconDefinition(iconLookup);
  return <FontAwesomeIcon icon={iconDefinition} />;
};
