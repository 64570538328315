import { Bleed, BlockStack, Box, Card, Checkbox, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { OfferGiftSpecificationAdvancedDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { OfferTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import React, { FC, useMemo } from 'react';

type AdvancedProps = {
  offerType: OfferTypeDtoEnum;
  advanced: OfferGiftSpecificationAdvancedDto | undefined;
  advancedUpdate: (advanced: OfferGiftSpecificationAdvancedDto) => void;
};

export const Advanced: FC<AdvancedProps> = ({
  offerType,
  advanced,
  advancedUpdate,
}) => {
  const [i18n] = useI18n();

  const options = useMemo(() => {
    if (!advanced) return null;

    return Object.keys(advanced).map((key, index) => {
      if (
        offerType === OfferTypeDtoEnum.GIFT_AUTOMATIC &&
        key === 'removeExcessGiftsAutomatically'
      ) {
        return null;
      }

      const label =
        offerType === OfferTypeDtoEnum.ORDER_BUMP
          ? i18n.translate(`Option${index}`)
          : i18n.translate(key);

      const helpText =
        offerType === OfferTypeDtoEnum.ORDER_BUMP && index === 1
          ? i18n.translate(`Option${key}HelpText`)
          : undefined;

      return (
        <Checkbox
          key={key}
          label={label}
          helpText={helpText}
          checked={!!advanced[key as keyof OfferGiftSpecificationAdvancedDto]}
          onChange={() =>
            advancedUpdate({
              ...advanced,
              [key as keyof OfferGiftSpecificationAdvancedDto]:
                !advanced[key as keyof OfferGiftSpecificationAdvancedDto],
            })
          }
        />
      );
    });
  }, [offerType, advanced, i18n, advancedUpdate]);

  return (
    <Card>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate('Title')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('Subtitle')}
          </Text>
        </BlockStack>
        <BlockStack gap='200'>{options}</BlockStack>
        {offerType === OfferTypeDtoEnum.ORDER_BUMP && (
          <Bleed marginBlockEnd='400' marginInline='400'>
            <Box background='bg-surface-secondary' padding='400'>
              <Text as='p' tone='subdued'>
                {i18n.translate('Footer')}
              </Text>
            </Box>
          </Bleed>
        )}
      </BlockStack>
    </Card>
  );
};
