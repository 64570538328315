import React, { useCallback, useEffect, useState } from 'react';
import { BlockStack, Page } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { SubscriptionProductsCheckout } from './components/SubscriptionProductsCheckout/SubscriptionProductsCheckout';
import { PixelEventIntegrations } from './components/PixelEventIntegrations/PixelEventIntegrations';
import { MicroData } from './components/MicroData/MicroData';

import {
  IntegrationsSettingsDto,
  IntegrationsSettingsDtoRead,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import {
  setIsUnsavedChanges,
  setOnDiscardChanges,
  setOnSaveChanges,
} from 'core/store/settingsSlice';
import { SettingsSkeleton } from '../components/SettingsSkeleton/SettingsSkeleton';
import { MoreIntegrations } from './components/MoreIntegrations/MoreIntegrations';

export const Integrations: React.FC = () => {
  const [i18n] = useI18n();
  const {
    saveIntegrationsSettings,
    integrationsDataIsFetching,
    integrationsData,
  } = useConfigureSettings(SettingsFetchTypeEnum.INTEGRATIONS);
  const [integrationState, setIntegrationsState] =
    useState<IntegrationsSettingsDtoRead>({});
  const [savedData, setSavedData] = useState<IntegrationsSettingsDtoRead>({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(integrationState) && !isEqual(savedData, integrationState)) {
      dispatch(setIsUnsavedChanges(true));
      dispatch(
        setOnDiscardChanges(() => {
          if (!isEmpty(savedData)) {
            setIntegrationsState(savedData);
          }
        })
      );
      dispatch(
        setOnSaveChanges(() => {
          setSavedData(integrationState);
          return saveIntegrationsSettings(integrationState || {});
        })
      );
    } else if (isEqual(savedData, integrationState)) {
      dispatch(setIsUnsavedChanges(false));
    }
  }, [integrationState, savedData, dispatch]);

  useEffect(() => {
    if (!isEmpty(integrationsData)) {
      setIntegrationsState(integrationsData);
      setSavedData(integrationsData);
    }
    !isEmpty(integrationsData) && setIntegrationsState(integrationsData);
  }, [integrationsData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  const handleIntegrationsLocaleState = useCallback(
    (section: string, value: string | boolean, field?: string) => {
      if (field) {
        setIntegrationsState((prevState: IntegrationsSettingsDto) => ({
          ...prevState,
          [section]: {
            ...(prevState[section as keyof typeof prevState] || {}),
            [field]: value,
          },
        }));
      } else {
        setIntegrationsState((prevState) => ({
          ...prevState,
          [section]: value,
        }));
      }
    },
    [integrationState]
  );

  return (
    <>
      {!integrationsDataIsFetching && integrationState ? (
        <Page title={i18n.translate('Integrations')}>
          <BlockStack gap='600'>
            <SubscriptionProductsCheckout
              data={integrationState.subscriptionProductsCheckoutSettings}
              section={'subscriptionProductsCheckoutSettings'}
              handleIntegrationsLocaleState={handleIntegrationsLocaleState}
            />
            <PixelEventIntegrations
              data={integrationState.pixelEventsSettings}
              section='pixelEventsSettings'
              handleIntegrationsLocaleState={handleIntegrationsLocaleState}
            />
            <MicroData
              data={integrationState.microdataSettings}
              section='microdataSettings'
              handleIntegrationsLocaleState={handleIntegrationsLocaleState}
            />
            <MoreIntegrations />
          </BlockStack>
        </Page>
      ) : (
        <SettingsSkeleton />
      )}
    </>
  );
};
