import React, { useCallback, useEffect, useState } from 'react';
import { BlockStack, Page } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { Profile } from './components/Profile/Profile';
import { Notifications } from './components/Notifications/Notifications';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { Timezone } from './components/Timezone/Timezone';
import { AccountSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import { PrivacyPolicy } from './components/PrivacyPolicy/PrivacyPolicy';
import {
  setIsUnsavedChanges,
  setOnDiscardChanges,
  setOnSaveChanges,
} from 'core/store/settingsSlice';
import { isEmpty, isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { SettingsSkeleton } from '../components/SettingsSkeleton/SettingsSkeleton';
import { useIsDebugOrLocal } from 'core/hooks';

export const Account: React.FC = () => {
  const [i18n] = useI18n();
  const [_, setSearchParams] = useSearchParams();
  const isDebugOrLocal = useIsDebugOrLocal();

  const { timezoneData, timezoneIsFetching } = useConfigureSettings(
    SettingsFetchTypeEnum.TIMEZONE
  );
  const { accountData, accountDataIsFetching, accountDataChange } =
    useConfigureSettings(SettingsFetchTypeEnum.ACCOUNT);

  const dispatch = useDispatch();
  const [savedData, setSavedData] = useState<AccountSettingsDto>({});
  const [accountDataState, setAccountDataState] = useState<AccountSettingsDto>(
    {}
  );

  useEffect(() => {
    if (!isEmpty(accountDataState) && !isEqual(savedData, accountDataState)) {
      dispatch(setIsUnsavedChanges(true));
      dispatch(
        setOnDiscardChanges(() => {
          if (!isEmpty(savedData)) {
            setAccountDataState(savedData);
          }
        })
      );
      dispatch(
        setOnSaveChanges(() => {
          setSavedData(accountDataState);
          return accountDataChange(accountDataState);
        })
      );
    } else if (isEqual(savedData, accountDataState)) {
      dispatch(setIsUnsavedChanges(false));
    }
  }, [accountDataState, savedData, dispatch]);

  useEffect(() => {
    if (!isEmpty(accountData)) {
      setAccountDataState(accountData);
      setSavedData(accountData);
    }
  }, [accountData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  const handleAccountLocaleState = useCallback(
    (
      field: keyof AccountSettingsDto,
      data: AccountSettingsDto[keyof AccountSettingsDto]
    ) => {
      setAccountDataState((prevState) => ({
        ...prevState,
        [field]: data,
      }));
    },
    [accountDataState]
  );

  const onLevelClick = useCallback(async () => {
    !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
    setSearchParams((params) => {
      params.set('subPath', 'activity-log');
      return params;
    });
  }, [setSearchParams]);

  return (
    <div className='AccountTab'>
      {!timezoneIsFetching && !accountDataIsFetching && accountDataState ? (
        <Page
          title={i18n.translate(`Account`)}
          secondaryActions={[
            { content: i18n.translate('ActivityLog'), onAction: onLevelClick },
          ]}
        >
          <BlockStack gap='400'>
            <Profile
              data={accountDataState.primaryUser || {}}
              handleAccountLocaleState={(data) =>
                handleAccountLocaleState('primaryUser', data)
              }
            />
            <Notifications
              data={accountDataState.notifications || {}}
              section='notifications'
              handleAccountLocaleState={(data) =>
                handleAccountLocaleState('notifications', data)
              }
            />
            <Timezone
              timezoneData={timezoneData || []}
              handleAccountLocaleState={(data) =>
                handleAccountLocaleState('timezone', data)
              }
              data={accountDataState.timezone || ''}
            />
            <PrivacyPolicy />
          </BlockStack>
        </Page>
      ) : (
        <SettingsSkeleton />
      )}
    </div>
  );
};
