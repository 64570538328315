export enum DiscountType {
  ProductDiscount = 'Product discount',
  OrderDiscount = 'Order discount',
  ShippingDiscount = 'Shipping discount',
}

export enum DiscountTypeDTOEnum {
  LINE = 'Product discount',
  ORDER = 'Order discount',
}

export enum MultipleOrderDiscountRule {
  Largest = 'Largest',
  Stack = 'Stack',
}
