import React, { useCallback, useMemo } from 'react';
import {
  Background,
  Icon,
  SingleInputSelection,
  WidgetOptionHeader,
} from '../../../../../../../../../components';
import { NinjaCartDefaultStateHeaderGeneralPresetDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useI18n } from '@shopify/react-i18n';
import {
  NinjaCartHeaderLayoutDtoEnum,
  ResourceGroupKeyDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';

type NinjaCartHeaderGeneralProps = {
  general: NinjaCartDefaultStateHeaderGeneralPresetDto;
  defaultGeneral: NinjaCartDefaultStateHeaderGeneralPresetDto;
  shopDefaultGeneral: NinjaCartDefaultStateHeaderGeneralPresetDto;
  setGeneral: (data: NinjaCartDefaultStateHeaderGeneralPresetDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const NinjaCartHeaderGeneral: React.FC<NinjaCartHeaderGeneralProps> = ({
  general,
  defaultGeneral,
  shopDefaultGeneral,
  setGeneral,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();

  const handleUpdateGeneral = useCallback(
    (
      field: keyof NinjaCartDefaultStateHeaderGeneralPresetDto,
      data: NinjaCartDefaultStateHeaderGeneralPresetDto[keyof NinjaCartDefaultStateHeaderGeneralPresetDto]
    ) => setGeneral({ ...general, [field]: data }),
    [general, setGeneral]
  );
  const layoutOptions = useMemo(
    () =>
      Object.values(NinjaCartHeaderLayoutDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    []
  );

  return (
    <>
      <WidgetOptionHeader
        onDiscardChanges={() => setGeneral(defaultGeneral)}
        onResetToSystem={() => setGeneral(shopDefaultGeneral)}
        name={WidgetListOptionsDtoEnum.HEADER}
        handleLeftMenuSelection={handleLeftMenuSelection}
        onShowHide={onShowHide}
      />
      <div className='RightSideSection'>
        <SingleInputSelection
          options={layoutOptions}
          selectedOption={general.layout as string}
          setOption={(value) =>
            handleUpdateGeneral('layout', value as NinjaCartHeaderLayoutDtoEnum)
          }
          label={i18n.translate('Layout')}
          onSystemReset={() =>
            handleUpdateGeneral('layout', shopDefaultGeneral.layout)
          }
        />
      </div>
      <div className='RightSideSection'>
        <Icon
          icon={general.cartIcon?.value || {}}
          groupKey={ResourceGroupKeyDtoEnum.OFFER}
          enabled={general.cartIcon?.enabled}
          enabledText={i18n.translate('EnableCartIcon')}
          showIcon
          onSystemReset={() =>
            handleUpdateGeneral('cartIcon', shopDefaultGeneral.cartIcon)
          }
          setEnabled={(value) =>
            handleUpdateGeneral('cartIcon', {
              ...general.cartIcon,
              enabled: value,
            })
          }
          setIcon={(data) =>
            handleUpdateGeneral('cartIcon', {
              ...general.cartIcon,
              value: data,
            })
          }
        />
      </div>
      <div className='RightSideSection'>
        <Background
          background={general.background || {}}
          setBackground={(data) => handleUpdateGeneral('background', data)}
          onSystemReset={() =>
            handleUpdateGeneral('background', shopDefaultGeneral.background)
          }
        />
      </div>
    </>
  );
};

export default NinjaCartHeaderGeneral;
