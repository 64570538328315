export const enum BogoOptionsCartRules {
  Product = 'Product',
  Variant = 'Variation',
}

export const enum OfferTypeSpecialCases {
  BogoMixMatch = 'BogoMixMatch',
  BogoStrictMatch = 'BogoStrictMatch',
  CrossSell = 'CrossSell',
  SpendXGetX = 'SpendXGetX',
  BundleOrderDiscount = 'BundleOrderDiscount',
  Sales = 'Sales',
}
