import { sleep } from 'core/utils';
import {
  PromotionReportDTOType,
  PromotionReportQueryType,
} from 'features/reporting';
import { useCallback, useEffect, useMemo, useState } from 'react';

const mockedData: PromotionReportDTOType = {
  draw: 2,
  recordsTotal: 13,
  recordsFiltered: 13,
  data: [
    {
      Promotion: 'MAYOREO_1 (INMTQ)',
      Impressions: 0,
      AddToCarts: 0,
      InitiatedCheckouts: 0,
      Checkouts: 0,
      OrderAmount: '$0',
    },
    {
      Promotion: 'MAYOREO_30 (US9TE)',
      Impressions: 0,
      AddToCarts: 0,
      InitiatedCheckouts: 0,
      Checkouts: 0,
      OrderAmount: '$0',
    },
    {
      Promotion: 'INV2022 (MWIF)',
      Impressions: 0,
      AddToCarts: 0,
      InitiatedCheckouts: 0,
      Checkouts: 0,
      OrderAmount: '$0',
    },
    {
      Promotion: 'INV2022 (P6K6Y)',
      Impressions: 0,
      AddToCarts: 0,
      InitiatedCheckouts: 0,
      Checkouts: 0,
      OrderAmount: '$0',
    },
    {
      Promotion: 'Prod mayoreo (MWQ07)',
      Impressions: 34,
      AddToCarts: 0,
      InitiatedCheckouts: 0,
      Checkouts: 0,
      OrderAmount: '$0',
    },
    {
      Promotion: '40PAUYARI (AE3L6)',
      Impressions: 0,
      AddToCarts: 0,
      InitiatedCheckouts: 0,
      Checkouts: 0,
      OrderAmount: '$0',
    },
    {
      Promotion: 'FELIZ2022 (197KQ)',
      Impressions: 0,
      AddToCarts: 0,
      InitiatedCheckouts: 0,
      Checkouts: 0,
      OrderAmount: '$0',
    },
    {
      Promotion: '504GROUP45 (AZ7PZ)',
      Impressions: 0,
      AddToCarts: 0,
      InitiatedCheckouts: 0,
      Checkouts: 0,
      OrderAmount: '$0',
    },
    {
      Promotion: 'BAZAR LEADERS INV 2021 (NHNOL)',
      Impressions: 0,
      AddToCarts: 0,
      InitiatedCheckouts: 0,
      Checkouts: 0,
      OrderAmount: '$0',
    },
    {
      Promotion: 'INV2022 (DH0NN)',
      Impressions: 0,
      AddToCarts: 0,
      InitiatedCheckouts: 0,
      Checkouts: 0,
      OrderAmount: '$0',
    },
    {
      Promotion: 'Prod mayoreo (MWQ07)',
      Impressions: 34,
      AddToCarts: 0,
      InitiatedCheckouts: 0,
      Checkouts: 0,
      OrderAmount: '$0',
    },
    {
      Promotion: '40PAUYARI (AE3L6)',
      Impressions: 0,
      AddToCarts: 0,
      InitiatedCheckouts: 0,
      Checkouts: 0,
      OrderAmount: '$0',
    },
    {
      Promotion: 'FELIZ2022 (197KQ)',
      Impressions: 0,
      AddToCarts: 0,
      InitiatedCheckouts: 0,
      Checkouts: 0,
      OrderAmount: '$0',
    },
  ],
};

export const usePromotionReportData = (
  initialQueryData?: PromotionReportQueryType
) => {
  const [data, setData] = useState<PromotionReportDTOType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [queryData, setQueryData] = useState<
    PromotionReportQueryType | undefined
  >(initialQueryData);

  const recordsPerPage = useMemo(() => {
    if (queryData?.entriesToShow === 'all') {
      return 99999;
    }
    return +(queryData?.entriesToShow || '10');
  }, [queryData]);

  const itemsStart = useMemo(() => {
    return (recordsPerPage || 10) * ((queryData?.page || 1) - 1);
  }, [queryData, recordsPerPage]);

  const itemsEnd = useMemo(() => {
    return itemsStart + (recordsPerPage || 10);
  }, [itemsStart, recordsPerPage]);

  const dataBySearchFilter = useMemo(() => {
    return mockedData.data.filter(
      (item) =>
        item.Promotion.toLowerCase().indexOf(
          (queryData?.searchTerm || '').toLowerCase()
        ) > -1
    );
  }, [queryData?.searchTerm]);

  const dataByFilters = useMemo(() => {
    return {
      ...mockedData,
      data: dataBySearchFilter.slice(itemsStart, itemsEnd),
    };
  }, [dataBySearchFilter, itemsStart, itemsEnd]);

  const recordsTotal = useMemo(() => {
    return dataBySearchFilter.length;
  }, [dataBySearchFilter]);

  const recordsFiltered = useMemo(() => {
    return dataByFilters.data.length;
  }, [dataByFilters]);

  const fetchData = useCallback(
    (queryData?: PromotionReportQueryType) => {
      setQueryData(queryData);
    },
    [setQueryData]
  );

  useEffect(() => {
    (async () => {
      try {
        setData(undefined);
        setIsLoading(true);
        await sleep(500);
        setData(dataByFilters);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [dataByFilters, queryData?.date]);

  return {
    data,
    isLoading,
    recordsPerPage,
    recordsFiltered,
    recordsTotal,
    fetchData,
  };
};
