import React, { useCallback } from 'react';
import './WidgetRefreshModal.scss';
import { ModalCustom } from 'core/components/ModalCustom/ModalCustom';
import { useI18n } from '@shopify/react-i18n';
import { BlockStack, Icon, Text } from '@shopify/polaris';
import { useAppDispatch } from 'core/hooks';
import { setIsRefreshModalOpen } from 'core/store/settingsSlice';
import { CheckIcon } from '@shopify/polaris-icons';
type WidgetRefreshModalProps = {
  isOpen: boolean;
  onRefresh: () => void;
};
const WidgetRefreshModal: React.FC<WidgetRefreshModalProps> = ({
  isOpen,
  onRefresh,
}) => {
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();
  const onClose = useCallback(
    () => dispatch(setIsRefreshModalOpen(false)),
    [dispatch]
  );
  return (
    <ModalCustom
      title={i18n.translate('RefreshTitle')}
      isOpen={isOpen}
      centerButtons
      buttons={[
        {
          content: i18n.translate('Refresh'),
          primary: true,
          action: () => {
            onRefresh();
            onClose();
          },
        },
      ]}
      onClose={onClose}
    >
      <div className='WidgetRefreshModalContent'>
        <BlockStack gap='300'>
          {/* <Icon source={CheckIcon} tone='success' /> */}
          <Text as='h2'>{i18n.translate('NeedToRefresh')}</Text>
        </BlockStack>
      </div>
    </ModalCustom>
  );
};

export default WidgetRefreshModal;
