import React, { useCallback, useEffect, useMemo } from 'react';
import { I18n } from '@shopify/react-i18n';
import { Banner, BlockStack } from '@shopify/polaris';
import { useShippingCountries } from 'core/hooks/useShippingCountries';
import { MultiselectTagCombobox } from 'core/components/MultiSelectComboBox/MultiSelectComboBox';

type SelectCountriesProps = {
  selectedCountries: string[];
  setSelectedCountries: (data: string[]) => void;
  i18n: I18n;
  formIsInvalid: boolean;
};
export const SelectCountries: React.FC<SelectCountriesProps> = (props) => {
  const { selectedCountries, setSelectedCountries, i18n, formIsInvalid } =
    props;

  const { shippingCountriesListData } = useShippingCountries();

  const countriesList = useMemo(
    () => shippingCountriesListData?.map((country) => country.name),
    [shippingCountriesListData]
  );

  const geolocationFilter = useMemo(
    () =>
      selectedCountries
        .map((id) => {
          const option = shippingCountriesListData?.find(
            (opt) => opt.id === id
          );
          return option ? option.name : null;
        })
        .filter((name): name is string => name !== null && name !== undefined),
    [selectedCountries, shippingCountriesListData]
  );

  const handleCountriesSelection = useCallback(
    (tags: string[]) => {
      setSelectedCountries(
        tags
          .map((name) => {
            const option = shippingCountriesListData?.find(
              (opt) => opt.name === name
            );
            return option ? option.id : null;
          })
          .filter((id): id is string => id !== null && id !== undefined)
      );
    },
    [shippingCountriesListData, setSelectedCountries]
  );

  useEffect(() => {
    return () => {
      setSelectedCountries([]);
    };
  }, []);

  return (
    <div className='SelectCountriesInputContainer'>
      <BlockStack gap='400'>
        <MultiselectTagCombobox
          id='SelectCountries'
          tagsInside
          label={i18n.translate('SelectCountries')}
          suggestions={countriesList as string[]}
          placeholder={i18n.translate('SelectCountriesPlaceholder')}
          requiredIndicator
          labelHidden={false}
          selectedTags={geolocationFilter || []}
          withAddFunc={false}
          setSelectedTags={handleCountriesSelection}
        />
        <Banner tone='info'>{i18n.translate('InfoBanner')}</Banner>
      </BlockStack>
    </div>
  );
};
