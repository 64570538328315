import React from 'react';
import './IconButton.scss';
import { Icon, IconSource } from '@shopify/polaris';
import classNames from 'classnames';

type Tone =
  | 'base'
  | 'inherit'
  | 'subdued'
  | 'caution'
  | 'warning'
  | 'critical'
  | 'interactive'
  | 'info'
  | 'success'
  | 'primary'
  | 'emphasis'
  | 'magic'
  | 'textCaution'
  | 'textWarning'
  | 'textCritical'
  | 'textInfo'
  | 'textSuccess'
  | 'textPrimary'
  | 'textMagic';

type IconButtonProps = {
  source: IconSource;
  tone?: Tone;
  onClick?: () => void;
};

export const IconButton: React.FC<IconButtonProps> = ({
  source,
  tone = 'base',
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={classNames('IconButton', {
        DefaultTone: tone === 'base',
      })}
    >
      <Icon tone={tone} source={source} />
    </div>
  );
};
