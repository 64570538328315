import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useI18n } from '@shopify/react-i18n';
import {
  SchedulePromotionRequestDto,
  PromotionOverviewScheduleDto,
  PromotionTypeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { DateTimePicker } from 'core/components';
import { Banner, BlockStack, Modal, Text } from '@shopify/polaris';
import { PromotionTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { formatTimeDistance } from 'core/utils';

export enum ScheduleModalEnum {
  END_DATE = 'END_DATE',
  START_DATE = 'START_DATE',
}

type SchedulePromotionModalProps = {
  type: ScheduleModalEnum | null;
  promotionType: PromotionTypeDto;
  schedule: PromotionOverviewScheduleDto;
  timezone: string;
  onClose: () => void;
  onSchedulePromotion: (data: SchedulePromotionRequestDto) => void;
};
export const SchedulePromotionModal: React.FC<SchedulePromotionModalProps> = ({
  type,
  promotionType,
  schedule,
  timezone,
  onClose,
  onSchedulePromotion,
}) => {
  const [i18n] = useI18n();
  const [schedulePromotionSetup, setSchedulePromotionSetup] =
    useState<SchedulePromotionRequestDto>({});

  const handleSaveScheduling = useCallback(() => {
    onSchedulePromotion(schedulePromotionSetup);
    onClose();
  }, [schedulePromotionSetup]);

  const isStartDateInPast = useMemo(
    () =>
      new Date(schedulePromotionSetup.startDate?.value as number) < new Date(),
    [schedulePromotionSetup.startDate?.value]
  );

  const isStartDateValid = useMemo(
    () =>
      !!(schedule.endsAt
        ? new Date(schedulePromotionSetup.startDate?.value as number) <
          new Date(schedule.endsAt as number)
        : true),
    [schedulePromotionSetup.startDate, schedule.endsAt]
  );

  const isEndDateValid = useMemo(
    () =>
      new Date(schedulePromotionSetup.endsDate?.value as number) > new Date() &&
      !!(schedule.startsAt
        ? new Date(schedule.startsAt as number) <
          new Date(schedulePromotionSetup.endsDate?.value as number)
        : true),
    [schedule.startsAt, schedulePromotionSetup.endsDate]
  );

  const isDateValid =
    type === ScheduleModalEnum.END_DATE ? isEndDateValid : isStartDateValid;

  const conclusionText = useMemo(() => {
    if (isDateValid) {
      if (type === ScheduleModalEnum.START_DATE) {
        return (
          <>
            {i18n.translate(`PromotionWillStart`)}
            {isStartDateInPast ? (
              <Text fontWeight='semibold' as='span'>
                {i18n.translate(`Immediately`)}
              </Text>
            ) : (
              <Text fontWeight='semibold' as='span'>
                {formatTimeDistance(
                  schedulePromotionSetup.startDate?.value ||
                    schedule.startsAt ||
                    0
                )}
              </Text>
            )}
          </>
        );
      } else {
        return (
          <>
            {i18n.translate(`PromotionWillEnd`)}
            <Text fontWeight='semibold' as='span'>
              {formatTimeDistance(
                schedulePromotionSetup.endsDate?.value as number
              )}
            </Text>
          </>
        );
      }
    } else {
      return (
        <Text fontWeight='semibold' as='span' tone='critical'>
          {i18n.translate(`InvalidEndDate`)}
        </Text>
      );
    }
  }, [
    schedulePromotionSetup,
    isStartDateInPast,
    isDateValid,
    schedule.startsAt,
  ]);

  const bottomSectionText = useMemo(
    () =>
      promotionType === PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE &&
      !schedulePromotionSetup.startDate
        ? null
        : conclusionText,
    [schedulePromotionSetup, promotionType, isStartDateInPast]
  );

  const getInitialDate = (end?: boolean) => {
    const currentDate = new Date();
    if (end) {
      return currentDate.setMinutes(currentDate.getMinutes() + 60);
    } else {
      return currentDate.getTime();
    }
  };

  useEffect(() => {
    setSchedulePromotionSetup({
      ...(type === ScheduleModalEnum.START_DATE && {
        startDate: {
          enabled: true,
          value: schedule.startsAt,
        },
      }),
      ...(type === ScheduleModalEnum.END_DATE && {
        endsDate: {
          enabled: true,
          value: schedule.endsAt || schedulePromotionSetup.endsDate?.value,
        },
      }),
    });
  }, [schedule.endsAt, schedule.startsAt]);

  return (
    <Modal
      title={i18n.translate(`${type}_TITLE`)}
      open={!!type}
      footer={bottomSectionText}
      primaryAction={{
        content: i18n.translate(`Save`),
        disabled: !isDateValid,
        onAction: handleSaveScheduling,
      }}
      secondaryActions={[
        {
          content: i18n.translate(`Cancel`),
          onAction: onClose,
        },
      ]}
      onClose={onClose}
    >
      <div className='SchedulePromotionModalContent'>
        {promotionType === PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE &&
        !schedulePromotionSetup.startDate ? null : (
          <Modal.Section>
            <BlockStack gap='400'>
              <BlockStack gap='100'>
                {promotionType !==
                  PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE && (
                  <Text tone='subdued' as='p'>
                    {i18n.translate(`${type}_SUBTITLE`)}
                  </Text>
                )}
                {promotionType === PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE &&
                  !schedulePromotionSetup.endsDate?.value && (
                    <Text tone='subdued' as='p'>
                      {i18n.translate(`NoEndDateConfigured`)}
                    </Text>
                  )}
              </BlockStack>
              {type === ScheduleModalEnum.START_DATE ? (
                <DateTimePicker
                  dateLabel={i18n.translate(`Date`)}
                  timeLabel={i18n.translate(`Time`)}
                  disabled={
                    promotionType === PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE
                  }
                  setTimestampValue={(date) => {
                    date
                      ? setSchedulePromotionSetup((prev) => ({
                          ...prev,
                          startDate: {
                            ...prev.startDate,
                            value: date,
                          },
                        }))
                      : null;
                  }}
                  timestampValue={
                    schedulePromotionSetup.startDate?.value ||
                    getInitialDate(false)
                  }
                />
              ) : (
                <DateTimePicker
                  dateLabel={i18n.translate(`Date`)}
                  timeLabel={i18n.translate(`Time`)}
                  setTimestampValue={(date) => {
                    date
                      ? setSchedulePromotionSetup((prev) => ({
                          ...prev,
                          endsDate: { ...prev.endsDate, value: date },
                        }))
                      : null;
                  }}
                  minDate={schedulePromotionSetup.startDate?.value || undefined}
                  timestampValue={getInitialDate(true)}
                  disabled={
                    promotionType === PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE
                  }
                />
              )}
              <Text as='p' tone='subdued'>
                {i18n.translate('Timezone', {
                  timezone: timezone,
                })}
              </Text>
            </BlockStack>
          </Modal.Section>
        )}

        {promotionType === PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE && (
          <Modal.Section>
            <Banner tone='info'>{i18n.translate(`EditDiscountCode`)}</Banner>
          </Modal.Section>
        )}
      </div>
    </Modal>
  );
};
