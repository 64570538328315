import React, { useMemo } from 'react';
import { useI18n } from '@shopify/react-i18n';
import './Shadow.scss';
import { OptionDtoShadowComponentDto } from 'core/api/adminWidgets/adminWidgetsApi';

import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import ColorSelectorBox from 'core/components/WidgetEditor/ColorSelectorBox/ColorSelectorBox';
import { Checkbox } from '@shopify/polaris';
import { ShadowSizeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';

type ShadowProps = {
  shadow: OptionDtoShadowComponentDto;
  disabled?: boolean;
  inherited?: boolean;
  onSystemReset: () => void;
  setShadow: (value: OptionDtoShadowComponentDto) => void;
};

export const Shadow: React.FC<ShadowProps> = ({
  shadow,
  disabled,
  inherited,
  onSystemReset,
  setShadow,
}) => {
  const [i18n] = useI18n();

  const shadowOptions = useMemo(
    () =>
      Object.keys(ShadowSizeDtoEnum).map((value) => ({
        value: value,
        label: i18n.translate(`${value}`),
      })),
    []
  );

  return (
    <div className='Shadow'>
      <StyleComponentHeader
        label={i18n.translate(`Shadow`)}
        inherited={inherited}
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            disabled: disabled,
            onAction: () => onSystemReset(),
          },
        ]}
      >
        <Checkbox
          onChange={(value) => setShadow({ ...shadow, enabled: value })}
          checked={shadow.enabled}
          label={i18n.translate('DropShadow')}
          disabled={disabled}
        />
        {shadow.enabled && (
          <>
            <div className='ShadowRow'>
              {i18n.translate(`Size`)}
              <div className='RightContent'>
                <SelectOptions
                  options={shadowOptions}
                  selectedOption={
                    shadow.value?.size || ShadowSizeDtoEnum.MEDIUM
                  }
                  label=''
                  disabled={disabled}
                  onOptionSelect={(value) =>
                    setShadow({
                      ...shadow,
                      value: {
                        ...shadow.value,
                        size: value as ShadowSizeDtoEnum,
                      },
                    })
                  }
                />
              </div>
            </div>
            <div className='ShadowRow'>
              {i18n.translate(`Color`)}
              <div className='RightContent'>
                <ColorSelectorBox
                  disabled={disabled}
                  color={shadow.value?.color || ''}
                  setColor={(value) =>
                    setShadow({
                      ...shadow,
                      value: {
                        ...shadow.value,
                        color: value,
                      },
                    })
                  }
                />
              </div>
            </div>
          </>
        )}
      </StyleComponentHeader>
    </div>
  );
};
