
import {
  Banner,
  Bleed,
  BlockStack,
  Box,
  Card,
  Combobox,
  Icon,
  Link,
  Listbox,
  Text,
} from '@shopify/polaris';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useI18n } from '@shopify/react-i18n';
import { OfferTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import './ShopifyDiscountCode.scss';
import { SelectIcon } from '@shopify/polaris-icons';
import { Loader } from 'core/components';
import { useShopifyDiscountCodes } from './hooks/useShopifyDiscountCodes';
import {
  OfferShopifyDiscountCodeDto,
  ShopifyDiscountCodeAppLookupDto,
  ShopifyDiscountCodeLookupDto,
} from 'core/api/adminPromotions/adminPromotionsApi';

type ShopifyDiscountCodeOfferType =
  | 'DiscountCode'
  | 'DiscountApp'
  | 'DiscountCodeGroup';

type ShopifyDiscountCodeProps = {
  offerType: OfferTypeDtoEnum;
  draftData?: OfferShopifyDiscountCodeDto;
  onSelectInputValueChange: (data: string | null) => void;
  onValidityStateChange(isValid: boolean): void;
};

export const ShopifyDiscountCode: React.FC<ShopifyDiscountCodeProps> = (
  props
) => {
  const {
    offerType,
    onSelectInputValueChange,
    onValidityStateChange,
    draftData,
  } = props;
  const [i18n] = useI18n();
  const {
    fetchDiscountCodesData,
    fetchDiscountCodesIsFetching,
    fetchDiscountAppIsFetching,
    fetchDiscountAppData,
    fetchDiscountCodesGroupIsFetching,
    fetchDiscountCodesGroupData,
  } = useShopifyDiscountCodes();

  const isLoading = useMemo(
    () =>
      fetchDiscountAppIsFetching ||
      fetchDiscountCodesIsFetching ||
      fetchDiscountCodesGroupIsFetching,
    [
      fetchDiscountCodesIsFetching,
      fetchDiscountAppIsFetching,
      fetchDiscountCodesGroupIsFetching,
    ]
  );

  const discountCodeOfferType: ShopifyDiscountCodeOfferType = useMemo(() => {
    if (offerType === OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_REGULAR) {
      return 'DiscountCode';
    } else if (offerType === OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_GROUP) {
      return 'DiscountCodeGroup';
    } else {
      return 'DiscountApp';
    }
  }, [offerType]);

  const comboBoxOptions = useMemo(() => {
    if (offerType === OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_REGULAR) {
      return fetchDiscountCodesData?.length
        ? fetchDiscountCodesData.map((el: ShopifyDiscountCodeLookupDto) => ({
            value: el.id?.toString() as string,
            label: el.title as string,
          }))
        : [];
    } else if (offerType === OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_APP) {
      return fetchDiscountAppData?.length
        ? fetchDiscountAppData.map((el: ShopifyDiscountCodeAppLookupDto) => ({
            value: el.id?.toString() as string,
            label: el.title as string,
          }))
        : [];
    } else {
      return fetchDiscountCodesGroupData?.length
        ? fetchDiscountCodesGroupData.map(
            (el: ShopifyDiscountCodeLookupDto) => ({
              value: el.id?.toString() as string,
              label: el.title as string,
            })
          )
        : [];
    }
  }, [
    fetchDiscountCodesData,
    offerType,
    fetchDiscountAppData,
    fetchDiscountCodesGroupData,
  ]);

  const preSelectedOptionId: string = useMemo(() => {
    if (draftData?.app) {
      return draftData.app.toString();
    } else if (draftData?.code) {
      return draftData.code.toString();
    } else if (draftData?.group) {
      return draftData.group.toString();
    } else {
      return '';
    }
  }, [draftData?.app, draftData?.code, draftData?.group]);

  const [options, setOptions] = useState(comboBoxOptions);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setOptions(comboBoxOptions);
  }, [comboBoxOptions]);

  const getInputValueNeeded = useCallback(() => {
    if (preSelectedOptionId) {
      const initialInputValue = comboBoxOptions?.find((option) =>
        option?.value?.match(preSelectedOptionId)
      )?.label;
      if (initialInputValue) {
        setInputValue(initialInputValue);
        setOptions(comboBoxOptions);
      }
    }
  }, [comboBoxOptions, preSelectedOptionId]);

  useEffect(() => {
    getInputValueNeeded();
  }, [preSelectedOptionId, comboBoxOptions]);

  const escapeRegExp = (input: string) => {
    return input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const updateText = useCallback(
    (value: string) => {
      setInputValue(value);

      if (value === '') {
        setOptions(comboBoxOptions);
        return;
      }

      const escapedValue = escapeRegExp(value);

      const filterRegex = new RegExp(escapedValue, 'i');
      const resultOptions = comboBoxOptions.filter((option) =>
        option.label.match(filterRegex)
      );
      setOptions(resultOptions);
    },
    [comboBoxOptions, escapeRegExp]
  );

  const updateSelection = useCallback(
    (selected: string) => {
      const matchedOption = options.find((option) => {
        return option.value.match(selected);
      });

      onSelectInputValueChange(selected);
      setInputValue((matchedOption && matchedOption.label) || '');
    },
    [options]
  );

  const optionsMarkup =
    options.length > 0
      ? options.map((option) => {
          const { label, value } = option;

          return (
            <Listbox.Option
              key={`${value}`}
              value={value}
              selected={preSelectedOptionId === value}
              accessibilityLabel={label}
            >
              {label}
            </Listbox.Option>
          );
        })
      : null;

  useEffect(() => {
    onValidityStateChange(!!preSelectedOptionId);
  }, [preSelectedOptionId, inputValue]);

  const selectInput = useMemo(
    () =>
      isLoading ? (
        <Loader size='large' fullWidth />
      ) : (
        <div style={{ width: '50%' }}>
          <Combobox
            activator={
              <Combobox.TextField
                onBlur={getInputValueNeeded}
                onChange={updateText}
                label={i18n.translate(`${discountCodeOfferType}.SelectLabel`)}
                value={inputValue}
                placeholder={i18n.translate(
                  `${discountCodeOfferType}.SelectPlaceholder`
                )}
                suffix={<Icon source={SelectIcon} />}
                autoComplete='off'
                error={!preSelectedOptionId}
              />
            }
          >
            {options.length > 0 ? (
              <Listbox onSelect={updateSelection}>{optionsMarkup}</Listbox>
            ) : null}
          </Combobox>
        </div>
      ),
    [
      options,
      discountCodeOfferType,
      i18n,
      isLoading,
      updateSelection,
      updateText,
      preSelectedOptionId,
      inputValue,
    ]
  );

  return (
    <Card>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as={'h2'} variant='headingSm'>
            {i18n.translate(`${discountCodeOfferType}.Title`)}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate(`${discountCodeOfferType}.Subtitle`)}
          </Text>
        </BlockStack>
        <Banner tone='info'>
          <p>{i18n.translate(`${discountCodeOfferType}.BannerText`)}</p>
        </Banner>
        <BlockStack>
          <div className='DiscountCodeSectionContainer'>{selectInput}</div>
        </BlockStack>
        <Bleed marginInline='400' marginBlockEnd='400'>
          <Box background='bg-surface-secondary' padding='400'>
            <Text as='p' tone='subdued'>
              {i18n.translate('Footer', {
                link: (
                  <Link url='/' target='_blank'>
                    {i18n.translate('FollowingArticle')}
                  </Link>
                ),
              })}
            </Text>
          </Box>
        </Bleed>
      </BlockStack>
    </Card>
  );
};