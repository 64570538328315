import React from 'react';
import StyleComponentHeader from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components/StyleComponentHeader/StyleComponentHeader';
import { useI18n } from '@shopify/react-i18n';
import { Banner, Checkbox } from '@shopify/polaris';

type SingleCheckboxProps = {
  title: string;
  label: string;
  checked: boolean;
  inherited?: boolean;
  disabled?: boolean;
  bannerContent?: JSX.Element;
  noteContent?: JSX.Element;
  onChange?: (value: boolean) => void;
  onSystemReset?: () => void;
};
export const SingleCheckbox: React.FC<SingleCheckboxProps> = ({
  title,
  label,
  checked,
  inherited,
  bannerContent,
  disabled,
  noteContent,
  onChange,
  onSystemReset,
}) => {
  const [i18n] = useI18n();
  return (
    <div className='SingleCheckbox'>
      <StyleComponentHeader
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            disabled: disabled,
            onAction: () => onSystemReset?.(),
          },
        ]}
        inherited={inherited}
        label={title}
      >
        <Checkbox
          label={label}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        {bannerContent && <Banner>{bannerContent}</Banner>}
        {noteContent && noteContent}
      </StyleComponentHeader>
    </div>
  );
};
