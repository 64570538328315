import React from 'react';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import { useI18n } from '@shopify/react-i18n';
import { DeviceDisplayDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { DeviceDisplayDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import './DeviceDisplay.scss';
type DeviceDisplayProps = {
  deviceDisplay: DeviceDisplayDto;
  disabled?: boolean;
  setDeviceDisplay?: (value: DeviceDisplayDto) => void;
  onSystemReset?: () => void;
};
export const DeviceDisplay: React.FC<DeviceDisplayProps> = ({
  deviceDisplay,
  disabled,
  setDeviceDisplay,
  onSystemReset,
}) => {
  const [i18n] = useI18n();

  const deviceDisplayOptions = Object.values(DeviceDisplayDtoEnum).map(
    (value) => {
      return {
        value: value,
        label: i18n.translate(`${value}`),
      };
    }
  );
  return (
    <div className='DeviceDisplay'>
      <StyleComponentHeader
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            disabled: disabled,
            onAction: () => onSystemReset?.(),
          },
        ]}
        label={i18n.translate(`DeviceDisplay`)}
      >
        <div className='DeviceRow'>
          {i18n.translate(`Device`)}
          <div className='RightContent'>
            <SelectOptions
              options={deviceDisplayOptions}
              onOptionSelect={(value) =>
                setDeviceDisplay?.(value as DeviceDisplayDtoEnum)
              }
              selectedOption={deviceDisplay || DeviceDisplayDtoEnum.ALL}
              label=''
              disabled={disabled}
            />
          </div>
        </div>
      </StyleComponentHeader>
    </div>
  );
};
