import React, { useCallback } from 'react';
import {
  Spacing,
  CustomCSS,
  ShopSettingsWrapper,
  SingleCheckbox,
} from '../../../../../components';
import {
  PriceStateGeneralDto,
  PricingStateSystemDefaultsDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { isEqual } from 'lodash';
import { useI18n } from '@shopify/react-i18n';
import { Box, Link, Text } from '@shopify/polaris';
import { PriceWidgetTypesDtoEnum } from '../../../../EditPresetRightBar';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { setToggleSettingsConfig } from 'core/store/widgetsSlice';
import { useAppDispatch } from 'core/hooks';
import { PromotionPriceWidgetTypesDtoEnum } from '../../../../../EditPromotionRightBar/EditPromotionRightBar';

type PriceStateProps = {
  price: PriceStateGeneralDto;
  defaultPrice: PriceStateGeneralDto;
  shopDefaultPrice: PricingStateSystemDefaultsDto;
  isPresetEditor?: boolean;
  stateType:
    | WidgetListOptionsDtoEnum.DISCOUNTED_PRICE
    | WidgetListOptionsDtoEnum.NOT_DISCOUNTED_PRICE;
  priceType: PriceWidgetTypesDtoEnum | PromotionPriceWidgetTypesDtoEnum;
  setPrice: (data: PriceStateGeneralDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const PriceState: React.FC<PriceStateProps> = ({
  price,
  defaultPrice,
  shopDefaultPrice,
  isPresetEditor,
  stateType,
  priceType,
  setPrice,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();
  const updatePriceState = useCallback(
    (
      field: keyof PriceStateGeneralDto,
      data: PriceStateGeneralDto[keyof PriceStateGeneralDto]
    ) => {
      setPrice({
        ...price,
        [field]: data,
      });
    },
    [price, setPrice]
  );

  const resetAllToSystemDefault = useCallback(() => {
    const { customCss, spacing } = shopDefaultPrice;
    setPrice({
      ...price,
      ...(isPresetEditor && { customCss }),
      spacing,
    });
  }, [isPresetEditor, shopDefaultPrice, price]);

  return (
    <div className='PriceState'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={() => setPrice(defaultPrice)}
        onResetToSystem={resetAllToSystemDefault}
        name={stateType}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      {!isPresetEditor && (
        <div className='RightSideSection'>
          <Box padding='500'>
            <Text as='p' tone='subdued'>
              {i18n.translate('WidgetEditorNote1')}
              <Link>{i18n.translate('PresetEditor')}</Link>
              {i18n.translate('WidgetEditorNote2')}
            </Text>
          </Box>
        </div>
      )}
      <div className='RightSideSection'>
        <Spacing
          spacing={price.spacing || {}}
          setSpacing={(data) => updatePriceState('spacing', data)}
          inherited={
            !isPresetEditor
              ? isEqual(price?.spacing, defaultPrice.spacing)
              : undefined
          }
          onSystemReset={() =>
            updatePriceState('spacing', shopDefaultPrice.spacing)
          }
        />
      </div>
      {isPresetEditor && (
        <div className='RightSideSection'>
          <CustomCSS
            css={price.customCss || ''}
            setCSS={(value) => updatePriceState('customCss', value)}
            note={i18n.translate('CSSNote')}
          />
        </div>
      )}
      {priceType === PriceWidgetTypesDtoEnum.COLLECTION_PAGE_PRICE ||
      priceType === PriceWidgetTypesDtoEnum.PRODUCT_PAGE_PRICE ? (
        <ShopSettingsWrapper
          onOpen={() =>
            dispatch(
              setToggleSettingsConfig({
                action: 'open',
                path:
                  priceType === PriceWidgetTypesDtoEnum.COLLECTION_PAGE_PRICE
                    ? 'collection-strikethrough-pricing'
                    : 'product-strikethrough-pricing',
              })
            )
          }
        >
          <div className='RightSideSection'>
            <SingleCheckbox
              checked={!!price.shopSettings?.useSoldOutLabel}
              disabled
              title={i18n.translate('SoldOutLabel')}
              label={i18n.translate('UseSoldOut')}
            />
          </div>
          {priceType !== PriceWidgetTypesDtoEnum.PRODUCT_PAGE_PRICE && (
            <div className='RightSideSection'>
              <SingleCheckbox
                checked={!!price.shopSettings?.useFromLabel}
                disabled
                title={i18n.translate('FromLabel')}
                label={i18n.translate('UseFrom')}
              />
            </div>
          )}
        </ShopSettingsWrapper>
      ) : null}
    </div>
  );
};

export default PriceState;
