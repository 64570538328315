import {
  OfferWidgetSetupDtoDiscountLabelOfferDtoRead,
  PromotionWidgetSetupDtoDiscountLabelPromotionDtoRead,
} from 'core/api/adminWidgets/adminWidgetsApi';

export type PromotionWidgetSetupDtoDiscountLabelPromotionDtoReadWithOffers =
  PromotionWidgetSetupDtoDiscountLabelPromotionDtoRead & {
    offers?: OfferWidgetSetupDtoDiscountLabelOfferDtoRead[] | null;
  };

export const discountLabelMergeFunc = (
  widget: PromotionWidgetSetupDtoDiscountLabelPromotionDtoReadWithOffers
) => {
  if (widget) {
    const {
      isShopLevelWidget,
      canBeDragged,
      canBeRemoved,
      offers,
      ...newWidget
    } = widget;

    return {
      ...newWidget,
      availableEntries: undefined,
      offers: offers?.map((offer) => {
        const {
          canBeDragged,
          canBeRemoved,
          parentKey,
          canBeHidden,
          isHidden,
          setup,
          ...newOffer
        } = offer;

        return {
          ...(canBeHidden && { isHidden }),
          ...newOffer,
          setup: {
            ...setup,
            availableEntries: undefined,
          },
        };
      }),
    };
  }
};
