import React, { useCallback, useMemo } from 'react';
import { Editor } from '@tiptap/react';
import '../RichTextEditor.scss';
import { defaultFontSize } from '../RichTextEditor';
import { Select } from '@shopify/polaris';

export const FontSizeToolbarElement: React.FC<{
  editor: Editor;
  disabled: boolean;
}> = (props) => {
  const { editor, disabled } = props;

  const fontSizeOptions = useMemo(
    () =>
      [10, 11, 12, 13, 14, 15, 16, 20, 24, 32, 36, 40, 48, 64, 96, 128].map(
        (value) => ({
          value: value.toString(),
          label: value.toString(),
        })
      ),
    []
  );

  const currentFontSize = useMemo(
    () =>
      disabled
        ? '-'
        : editor.getAttributes('textStyle').fontSize
        ? editor.getAttributes('textStyle').fontSize
        : defaultFontSize,
    [editor.getAttributes('textStyle').fontSize, disabled, defaultFontSize]
  );

  const handleOnChange = useCallback((value: string) => {
    editor.chain().focus().setFontSize(parseInt(value)).run();
  }, []);

  return (
    <div className='fontSizeBlock'>
      <Select
        options={fontSizeOptions}
        onChange={handleOnChange}
        value={String(currentFontSize) || '13'}
        label=''
        labelHidden
        disabled={disabled}
      />
    </div>
  );
};
