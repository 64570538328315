import React, { useCallback, useState } from 'react';
import { Page } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { PromotionList } from './components/PromotionList/PromotionList';
import { CreatePromotionModal } from './components/CreatePromotionModal/CreatePromotionModal';
export const Promotions = () => {
  const [i18n] = useI18n();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);

  const toggleCreatePromotionModal = useCallback(
    () => setIsCreateModalOpen(!isCreateModalOpen),
    [isCreateModalOpen]
  );

  return (
    <Page
      fullWidth
      title={i18n.translate(`Promotions`)}
      primaryAction={{
        onAction: toggleCreatePromotionModal,
        content: i18n.translate(`CreatePromotion`),
      }}
      secondaryActions={[
        {
          disabled: true,
          content: i18n.translate(`Export`),
        },
        {
          content: i18n.translate(`Import`),
        },
      ]}
    >
      <PromotionList toggleCreatePromotionModal={toggleCreatePromotionModal} />
      {isCreateModalOpen && (
        <CreatePromotionModal
          isOpen={isCreateModalOpen}
          onClose={toggleCreatePromotionModal}
        />
      )}
    </Page>
  );
};
