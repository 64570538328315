import React, { useCallback, useMemo, useState } from 'react';
import {
  ActionList,
  Box,
  Button,
  Card,
  Icon,
  InlineStack,
  Popover,
  Text,
  Bleed,
  Pagination,
  BlockStack,
  Link,
} from '@shopify/polaris';
import {
  MenuHorizontalIcon,
  DuplicateIcon,
  EditIcon,
  DeleteIcon,
  PlusIcon,
} from '@shopify/polaris-icons';
import {
  GetPromotionOverviewOffersResponseDto,
  OfferTypeDto,
  PromotionTypeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { OfferTemplatesList } from 'features/promotions/components/CreatePromotionModal/consts/OfferTemplatesList';
import { useI18n } from '@shopify/react-i18n';
import { PromotionTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { useNavigate, useParams } from 'react-router-dom';
import './OfferList.scss';
import { useConfigureOffers } from 'features/promotions/hooks/useConfigureOffers';
import { Loader } from 'core/components';
import {
  SelectOfferModalTypeEnum,
  SelectOfferPage,
} from 'pages/SelectOfferPage';

type OfferListProps = {
  offerList: GetPromotionOverviewOffersResponseDto;
  promotionType: PromotionTypeDto;
  offerPage: number;
  offerListIsFetching: boolean;
  isPromotionEditable: boolean;
  setOfferPage: (value: number) => void;
  refetchOfferList: () => void;
  refetchPromotionOverview: () => void;
};

export const OfferList: React.FC<OfferListProps> = ({
  offerList,
  promotionType,
  offerPage,
  offerListIsFetching,
  isPromotionEditable,
  setOfferPage,
  refetchOfferList,
  refetchPromotionOverview,
}) => {
  const [i18n] = useI18n();
  const params = useParams();
  const navigate = useNavigate();

  const {
    deleteOfferIsLoading,
    duplicateOfferIsLoading,
    deleteOffer,
    duplicateOffer,
  } = useConfigureOffers();

  const [activePopover, setActivePopover] = useState<string>('');
  const [addOfferModal, setAddOfferModal] = useState<boolean>(false);

  const isShopifyCodeExist = useMemo(
    () =>
      promotionType === PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE &&
      !!offerList.items?.length,
    [promotionType, offerList.items]
  );

  const isDataLoading = useMemo(
    () =>
      deleteOfferIsLoading || duplicateOfferIsLoading || offerListIsFetching,
    [deleteOfferIsLoading, duplicateOfferIsLoading, offerListIsFetching]
  );

  const hasNextPage = useMemo(
    () =>
      offerList?.totalItems && offerPage
        ? offerList.totalItems / offerPage > 5
        : false,
    [offerList?.totalItems, offerPage]
  );

  const getOfferIcon = useCallback((offerType: OfferTypeDto) => {
    return OfferTemplatesList.find((offer) => offer.type === offerType)?.icon;
  }, []);

  const toggleSelectOfferModal = useCallback(() => {
    setAddOfferModal(!addOfferModal);
  }, [addOfferModal]);

  const onEditOffer = useCallback((offerId: string) => {
    navigate(`create-offer-template/${offerId}`);
  }, []);

  const onDuplicateOffer = useCallback(
    (offerId: string) => {
      if (params.promotionId) {
        setActivePopover('');
        duplicateOffer(params.promotionId, offerId, () => {
          refetchOfferList();
          refetchPromotionOverview();
        });
      }
    },
    [params]
  );
  const onDeleteOffer = useCallback(
    (offerId: string) => {
      if (params.promotionId) {
        setActivePopover('');
        deleteOffer(params.promotionId, offerId, () => {
          if (offerList.items?.length === 1 && offerPage !== 1) {
            setOfferPage(offerPage - 1);
          }
          refetchOfferList();
          refetchPromotionOverview();
        });
      }
    },
    [params, offerList.items?.length, offerPage]
  );
  const onOpenPopover = useCallback((e: any, offerId: string) => {
    e.stopPropagation();
    setActivePopover(offerId);
  }, []);

  const onNextPage = useCallback(() => {
    setOfferPage(offerPage + 1);
  }, [offerPage]);

  const onPrevPage = useCallback(() => {
    setOfferPage(offerPage === 1 ? 1 : offerPage - 1);
  }, [offerPage]);

  return (
    <div className='OfferList'>
      <Card>
        <Bleed marginBlockEnd='400' marginInline='400'>
          <Box
            paddingBlockEnd='400'
            paddingInline='400'
            borderBlockEndWidth='025'
            borderColor='border-brand'
          >
            <InlineStack wrap={false} align='space-between' blockAlign='center'>
              <Text as='h2' fontWeight='semibold'>
                {i18n.translate(`Offers`)}
              </Text>
              <InlineStack gap='200' blockAlign='center'>
                {(deleteOfferIsLoading || duplicateOfferIsLoading) && (
                  <div style={{ maxHeight: 20, maxWidth: 20 }}>
                    <Loader size='small' />
                  </div>
                )}
                <Button
                  onClick={toggleSelectOfferModal}
                  disabled={isShopifyCodeExist || !isPromotionEditable}
                  variant='plain'
                >
                  {i18n.translate(`AddOffer`)}
                </Button>
              </InlineStack>
            </InlineStack>
          </Box>
          {offerList.totalItems ? (
            offerList?.items?.map((offer) => (
              <div
                key={offer.id}
                onClick={() =>
                  isDataLoading ? null : onEditOffer(offer.id as string)
                }
                className='OfferListItem'
              >
                <Box
                  padding='400'
                  borderBlockEndWidth='025'
                  borderColor='border-brand'
                >
                  <InlineStack
                    wrap={false}
                    align='space-between'
                    blockAlign='center'
                  >
                    <Box width='75%'>
                      <InlineStack align='start' wrap={false} gap='300'>
                        <Box>
                          <Icon
                            tone='base'
                            source={getOfferIcon(offer.type as OfferTypeDto)}
                          />
                        </Box>
                        <InlineStack align='start' wrap={false} gap='100'>
                          <Box minWidth='56px'>
                            <Text as='p' tone='subdued'>
                              {offer.token}
                            </Text>
                          </Box>
                          <Text as='p'>{offer.title}</Text>
                        </InlineStack>
                      </InlineStack>
                    </Box>
                    <InlineStack wrap={false} gap='300' blockAlign='center'>
                      <Text as='p' tone='subdued'>
                        {i18n.translate(`${offer.combinationType}`)}
                      </Text>
                      <Popover
                        active={offer.id === activePopover}
                        activator={
                          <div
                            onClick={(e) => onOpenPopover(e, offer.id || '')}
                            style={{ height: 20 }}
                          >
                            <Button
                              icon={MenuHorizontalIcon}
                              disabled={!isPromotionEditable}
                              variant='tertiary'
                            />
                          </div>
                        }
                        autofocusTarget='first-node'
                        onClose={() => setActivePopover('')}
                      >
                        <div onClick={(e) => e.stopPropagation()}>
                          <ActionList
                            actionRole='menuitem'
                            items={
                              promotionType ===
                              PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE
                                ? [
                                    {
                                      external: true,
                                      content: i18n.translate(`Edit`),
                                      icon: EditIcon,
                                      disabled:
                                        isDataLoading || !isPromotionEditable,
                                      onAction: () =>
                                        onEditOffer(offer.id as string),
                                    },
                                  ]
                                : [
                                    {
                                      external: true,
                                      content: i18n.translate(`Edit`),
                                      icon: EditIcon,
                                      disabled:
                                        isDataLoading || !isPromotionEditable,
                                      onAction: () =>
                                        onEditOffer(offer.id as string),
                                    },
                                    {
                                      content: i18n.translate(`Duplicate`),
                                      icon: DuplicateIcon,
                                      disabled:
                                        isDataLoading || !isPromotionEditable,
                                      onAction: () =>
                                        onDuplicateOffer(offer.id as string),
                                    },
                                    {
                                      content: i18n.translate(`Delete`),
                                      icon: DeleteIcon,
                                      disabled:
                                        isDataLoading || !isPromotionEditable,
                                      destructive: true,
                                      onAction: () =>
                                        onDeleteOffer(offer.id as string),
                                    },
                                  ]
                            }
                          />
                        </div>
                      </Popover>
                    </InlineStack>
                  </InlineStack>
                </Box>
              </div>
            ))
          ) : (
            <Box paddingInline='300' paddingBlock='800'>
              <BlockStack inlineAlign='center'>
                <Box width='50%'>
                  <BlockStack gap='400' inlineAlign='center'>
                    <BlockStack gap='200'>
                      <Text
                        alignment='center'
                        fontWeight='semibold'
                        variant='bodyLg'
                        as='p'
                      >
                        {i18n.translate('EmptyStateTitle')}
                      </Text>
                      <Text alignment='center' tone='subdued' as='p'>
                        {i18n.translate('EmptyStateSubtitle', {
                          learnMore: (
                            <Link monochrome>
                              {i18n.translate('LearnMore')}
                            </Link>
                          ),
                        })}
                      </Text>
                    </BlockStack>
                    <Button
                      variant='primary'
                      onClick={toggleSelectOfferModal}
                      icon={PlusIcon}
                      disabled={isShopifyCodeExist || !isPromotionEditable}
                    >
                      {i18n.translate('AddFirstOffer')}
                    </Button>
                  </BlockStack>
                </Box>
              </BlockStack>
            </Box>
          )}
          {!!offerList.totalItems && (
            <Box
              background='bg-surface-secondary'
              paddingInline='300'
              paddingBlock='150'
            >
              <InlineStack align='space-between' blockAlign='center'>
                <Text as='p' tone='subdued'>
                  {i18n.translate('ShowXOfY', {
                    from: (offerPage - 1) * 5 + 1,
                    to: Math.min(offerPage * 5, offerList.totalItems as number),
                    total: offerList.totalItems,
                  })}
                </Text>
                {(offerList.totalItems as number) > 5 && (
                  <Pagination
                    onPrevious={onPrevPage}
                    onNext={onNextPage}
                    hasNext={hasNextPage}
                    hasPrevious={(offerPage as number) > 1}
                  />
                )}
              </InlineStack>
            </Box>
          )}
        </Bleed>
      </Card>
      {addOfferModal && (
        <SelectOfferPage
          isOpen={addOfferModal}
          onClose={toggleSelectOfferModal}
          modalType={SelectOfferModalTypeEnum.CREATE_NEW}
        />
      )}
    </div>
  );
};
