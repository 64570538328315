import {
  Banner,
  Bleed,
  BlockStack,
  Card,
  Grid,
  Icon,
  Text,
  TextField,
  InlineStack,
} from '@shopify/polaris';
import { InfoIcon } from '@shopify/polaris-icons';
import React from 'react';
import { useI18n } from '@shopify/react-i18n';
import './TranslateSection.scss';
import { useSearchParams } from 'react-router-dom';
import {
  AccessibilityLabelsTranslationsDto,
  CheckoutLoadingMessagesTranslationsDto,
  CountdownClockLabelsTranslationsDto,
  GiftPurchaseMessagesTranslationsDto,
  PageRefreshMessagesTranslationsDto,
  SubscriptionProductsMessagesTranslationsDto,
  TextLabelsTranslationsDto,
} from 'core/api/adminSettings/adminSettingsApi';

export type Item =
  | TextLabelsTranslationsDto
  | CountdownClockLabelsTranslationsDto
  | CheckoutLoadingMessagesTranslationsDto
  | PageRefreshMessagesTranslationsDto
  | AccessibilityLabelsTranslationsDto
  | GiftPurchaseMessagesTranslationsDto
  | SubscriptionProductsMessagesTranslationsDto
  | undefined;

type TranslateSection = {
  item: Item;
  section: string;
  handleTranslationLocaleState: (
    section: string,
    field: string,
    value: string
  ) => void;
};

export const TranslateSection: React.FC<TranslateSection> = ({
  item,
  section,
  handleTranslationLocaleState,
}) => {
  const [i18n] = useI18n();
  const [searchParams] = useSearchParams();

  return (
    <div className='TranslateSection'>
      <Card roundedAbove='sm' padding='400'>
        <BlockStack gap='100'>
          <InlineStack gap='100'>
            <Text as='h2' variant='headingSm'>
              {' '}
              {i18n.translate(`${section}Title`)}
            </Text>
            {section === 'checkoutLoadingMessages' && (
              <span>
                <Icon source={InfoIcon} tone='base' />
              </span>
            )}{' '}
          </InlineStack>
          <Text as='p' tone='subdued'>
            {i18n.translate(`${section}Description`)}
          </Text>
        </BlockStack>
        {section === 'countdownClockLabels' && (
          <div className='PaddingTop'>
            <Banner tone='info'>{i18n.translate(`Tip`)}</Banner>
          </div>
        )}
        <Bleed marginInline='400'>
          <div className='Background'>
            <Grid>
              <Grid.Cell columnSpan={{ xs: 3, sm: 3, md: 3, lg: 5, xl: 5 }}>
                <Text as='p' tone='subdued'>
                  {i18n.translate('Label')}
                </Text>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ xs: 3, sm: 3, md: 3, lg: 7, xl: 7 }}>
                <Text as='p' tone='subdued'>
                  {i18n.translate(`${searchParams.get('language')}`)}
                </Text>
              </Grid.Cell>
            </Grid>
          </div>
        </Bleed>
        <Bleed marginInline='400'>
          {item &&
            Object.keys(item).map((field: string, index: number) => (
              <div
                className={`InputSection ${
                  index + 1 === Object.keys(item).length &&
                  'PaddingBottomDelete'
                }`}
                key={index}
              >
                <Grid>
                  <Grid.Cell columnSpan={{ xs: 3, sm: 3, md: 3, lg: 5, xl: 5 }}>
                    <div className='Label'>
                      <Text as='p' tone='subdued'>
                        {i18n.translate(`${section}.${index}`, {
                          returnObjects: true,
                        })}
                      </Text>
                    </div>
                  </Grid.Cell>
                  <Grid.Cell columnSpan={{ xs: 3, sm: 3, md: 3, lg: 7, xl: 7 }}>
                    <TextField
                      label=''
                      value={item[field as keyof Item]}
                      onChange={(value: string) => {
                        handleTranslationLocaleState(section, field, value);
                      }}
                      autoComplete='off'
                    />
                  </Grid.Cell>
                </Grid>
              </div>
            ))}
        </Bleed>
      </Card>
    </div>
  );
};
