import { TextField } from '@shopify/polaris';
import { useDebounce } from 'core/hooks';
import React, { useCallback, useState } from 'react';

type SearchFieldProps = {
  initialSearchTerm?: string;
  delay?: number;
  prefix?: React.ReactNode;
  placeholder?: string;
  onChangeSearchTerm?: (searchTerm: string) => void;
};

export const SearchField: React.FC<SearchFieldProps> = (props) => {
  const { initialSearchTerm, delay, prefix, placeholder, onChangeSearchTerm } =
    props;
  const [searchTerm, setSearchTerm] = useState<string>(initialSearchTerm || '');
  const { debounce } = useDebounce(delay);

  const handleSearchTermChange = useCallback(
    (searchTerm: string) => {
      setSearchTerm(searchTerm);
      debounce(() => {
        onChangeSearchTerm?.(searchTerm);
      });
    },
    [setSearchTerm, debounce, onChangeSearchTerm]
  );

  return (
    <TextField
      label=''
      autoComplete='off'
      prefix={prefix}
      placeholder={placeholder}
      value={searchTerm}
      onChange={handleSearchTermChange}
    />
  );
};
