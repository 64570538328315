import React from 'react';
import Insights from './components/Insights/Insights';
import { Layout, Page } from '@shopify/polaris';
import Update from './components/Update/Update';
import Support from './components/Support/Support';
import SessionUsage from './components/SessionUsage/SessionUsage';
import { DateRangeBar } from 'core/components';

export const Dashboard = () => {
  return (
    <Page fullWidth>
      <Layout>
        <Layout.Section variant='fullWidth'>
          <DateRangeBar />
        </Layout.Section>
        <Layout.Section variant='fullWidth'>
          <Insights />
        </Layout.Section>
        <Layout.Section variant='oneHalf'>
          <Update />
          <Support />
        </Layout.Section>
        <Layout.Section variant='oneHalf'>
          <SessionUsage />
        </Layout.Section>
      </Layout>
    </Page>
  );
};
