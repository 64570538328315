import { InlineStack, Icon, Text } from '@shopify/polaris';
import { OfferTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import React, { useCallback, useEffect, useState } from 'react';
import { useI18n } from '@shopify/react-i18n';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { DiscountValueTypeEnum } from 'features/promotions/components/SelectedOfferTypeSettings/enums/DiscountValueType';
import classNames from 'classnames';
import { AlertCircleIcon } from '@shopify/polaris-icons';
type ApplyInputNumberType = {
  suffix: string;
  offerType: OfferTypeDtoEnum;
  textFieldValue: NumberFormatValues;
  setTextFieldValue: (data: NumberFormatValues) => void;
  activeDiscountValueType: DiscountValueTypeEnum;
};

type LocaleNumberFormattingSettingsModel = {
  thousandSymbol: string;
  decimalSymbol: string;
};

export const ApplyInputNumber: React.FC<ApplyInputNumberType> = ({
  suffix,
  offerType,
  textFieldValue,
  setTextFieldValue,
  activeDiscountValueType,
}) => {
  const [i18n] = useI18n();

  const [isNumberInputFocused, setIsNumberInputFocused] =
    useState<boolean>(false);

  const [validationError, setValidationError] = useState<string>('');

  const localeNumberFormattingSettings: LocaleNumberFormattingSettingsModel =
    i18n.numberSymbols();

  const onNumberFormatInputMouseAction = useCallback(
    () => setIsNumberInputFocused((prevState) => !prevState),
    []
  );

  const maxAllowedValueRule = useCallback(
    (data: NumberFormatValues) => {
      if (!data.floatValue) {
        return true;
      }

      if (activeDiscountValueType === DiscountValueTypeEnum.Percentage) {
        return data.floatValue <= 100;
      } else {
        return data.floatValue <= 2147483647;
      }
    },
    [activeDiscountValueType]
  );

  useEffect(() => {
    switch (textFieldValue.value) {
      case '':
        setValidationError(i18n.translate(`required`));
        break;
      case '0':
        setValidationError(i18n.translate(`min`));
        break;
      default:
        setValidationError('');
        break;
    }
  }, [textFieldValue.value]);

  return (
    <InlineStack gap='100' blockAlign='center'>
      <Text as='p'>{i18n.translate(`${offerType}.start`)}</Text>
      <span className='ApplyInputNumberTextFieldWrap'>
        <div
          className={classNames(
            'Polaris-TextField Polaris-TextField--hasValue',
            {
              'Polaris-TextField--error': validationError,
            }
          )}
        >
          {suffix !== '%' && (
            <div className='Polaris-TextField__Prefix'>
              <span className='Polaris-Text--root Polaris-Text--bodyMd'>$</span>
            </div>
          )}
          <NumberFormat
            className='Polaris-TextField__Input'
            value={textFieldValue.value}
            decimalSeparator={localeNumberFormattingSettings.decimalSymbol}
            thousandSeparator={localeNumberFormattingSettings.thousandSymbol}
            allowNegative={false}
            isNumericString
            decimalScale={2}
            onValueChange={setTextFieldValue}
            isAllowed={maxAllowedValueRule}
            onFocus={onNumberFormatInputMouseAction}
            onBlur={onNumberFormatInputMouseAction}
          />
          {suffix === '%' && (
            <div className='Polaris-TextField__Suffix'>
              <span className='Polaris-Text--root Polaris-Text--bodyMd'>%</span>
            </div>
          )}
          <div className='Polaris-TextField__Backdrop'></div>
        </div>
        {validationError && (
          <span style={{ position: 'absolute' }}>
            <Text as='p' tone='critical'>
              <span style={{ display: 'flex', gap: '4px', paddingTop: '2px' }}>
                <Icon source={AlertCircleIcon} />
                {validationError}
              </span>
            </Text>
          </span>
        )}
      </span>
      <Text as='p'>{i18n.translate(`${offerType}.end`)}</Text>{' '}
    </InlineStack>
  );
};
