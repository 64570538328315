import React, { useCallback } from 'react';
import { PromotionSummaryGeneralOfferDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { TextEntries, TextStyle } from '../../../../../components';
import { useI18n } from '@shopify/react-i18n';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import StyleComponentHeader from '../../../../../components/StyleComponentHeader/StyleComponentHeader';
import { Link, Text } from '@shopify/polaris';

type PromotionSummaryOfferProps = {
  offer: PromotionSummaryGeneralOfferDto;
  offerId: string;
  defaultOffer: PromotionSummaryGeneralOfferDto;
  shopDefaultOffer: PromotionSummaryGeneralOfferDto;
  offerName: string;
  defaultLanguage: string;
  setOffer: (data: PromotionSummaryGeneralOfferDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const PromotionSummaryOffer: React.FC<PromotionSummaryOfferProps> = ({
  offer,
  offerId,
  defaultOffer,
  shopDefaultOffer,
  offerName,
  defaultLanguage,
  setOffer,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();
  const resetAllToSystemDefault = useCallback(() => {
    setOffer({ ...offer, label: shopDefaultOffer.label });
  }, [shopDefaultOffer, defaultOffer]);

  return (
    <div className='PromotionSummaryOffer'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={() => setOffer(defaultOffer)}
        onResetToSystem={resetAllToSystemDefault}
        name={offerName}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <TextEntries
          textMessage={offer.label?.message || {}}
          title={i18n.translate('Label')}
          defaultLanguage={defaultLanguage || ''}
          disableCollapsed
          disableDevice
          disableGoal
          disableColor={(offer.label?.style || {}).color !== undefined}
          offerId={offerId}
          setTextMessage={(data) =>
            setOffer({
              ...offer,
              label: {
                ...offer.label,
                message: data,
              },
            })
          }
          onSystemReset={() =>
            setOffer({ ...offer, label: shopDefaultOffer.label })
          }
        />
        <TextStyle
          textStyle={offer.label?.style || {}}
          hideHeader
          onlyFont
          setTextStyle={(data) =>
            setOffer({
              ...offer,
              label: {
                ...offer.label,
                style: data,
              },
            })
          }
        />
      </div>
      <div className='RightSideSection'>
        <StyleComponentHeader buttons={[]} label={i18n.translate('PriceStyle')}>
          <Text tone='subdued' as='p'>
            {i18n.translate('ToCustomizeGo')}
            <Link>{i18n.translate('BreakdownPrice')}</Link>
            {i18n.translate('Widget')}
          </Text>
        </StyleComponentHeader>
      </div>
    </div>
  );
};

export default PromotionSummaryOffer;
