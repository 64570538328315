import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import {
  Combobox as PolarisCombobox,
  Listbox as PolarisListbox,
  Tag as PolarisTag,
  LegacyStack as PolarisLegacyStack,
} from '@shopify/polaris';
import './Select.scss';

export type ComboboxOption = {
  value: string;
  label: string;
  helpText?: string;
};

interface ComboboxProps {
  options: ComboboxOption[];
  selectedOptions?: string[];
  label: string;
  hideLabel?: boolean;
  hidePlaceholder?: boolean;
  placeholder?: string;
  allowMultiple?: boolean;
  prefix?: ReactNode;
  suffix?: ReactNode;
  fieldIsRequired?: boolean;
  onChange?(selectedOptions: string[]): void;
  onValidityStateChange?(isValid: boolean): void;
}

export const Select: React.FC<ComboboxProps> = (props) => {
  const {
    options: deselectedOptions,
    selectedOptions: preSelectedOptions,
    allowMultiple,
    label,
    hideLabel,
    placeholder,
    hidePlaceholder,
    suffix,
    prefix,
    fieldIsRequired,
    onChange,
    onValidityStateChange,
  } = props;

  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    preSelectedOptions || []
  );
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState(deselectedOptions);
  const [fieldIsDirty, setFieldIsDirty] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  useEffect(() => {
    setSelectedOptions(preSelectedOptions || []);
  }, [preSelectedOptions, setSelectedOptions]);

  useEffect(() => {
    setOptions(deselectedOptions);
  }, [deselectedOptions, setOptions]);

  useEffect(() => {
    if (selectedOptions && !allowMultiple && !inputValue) {
      const el = options.find(
        (el: ComboboxOption) => el.value === selectedOptions[0]
      );
      if (el?.label) {
        setInputValue(el.label);
      }
    }
  }, [selectedOptions, allowMultiple]);

  useEffect(() => {
    // we dispatch a callback to parent component,
    // providing selected items, whenever `selectedOptions` change.
    isChanged && onChange?.(selectedOptions);
    if (!fieldIsDirty && selectedOptions?.length) {
      setFieldIsDirty(true);
    }
  }, [onChange, selectedOptions, isChanged]);

  useEffect(() => {
    if (onValidityStateChange && fieldIsRequired) {
      onValidityStateChange(fieldIsDirty && !selectedOptions?.length);
    }
  }, [
    fieldIsDirty,
    selectedOptions?.length,
    onValidityStateChange,
    fieldIsRequired,
  ]);

  const updateText = useCallback(
    (value: string) => {
      setInputValue(value);
      setIsChanged(true);
      if (!allowMultiple && selectedOptions.length) {
        setSelectedOptions([]);
      }

      if (value === '') {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, 'i');
      const resultOptions = deselectedOptions.filter(
        (option) =>
          option.value.match(filterRegex) || option.label.match(filterRegex)
      );
      setOptions(resultOptions);
    },
    [deselectedOptions, selectedOptions]
  );

  const updateSelection = useCallback(
    (selected: string) => {
      if (allowMultiple) {
        const alreadySelectedOption = selectedOptions.find(
          (o) => o === selected
        );
        if (alreadySelectedOption) {
          setSelectedOptions(
            selectedOptions.filter((o) => o !== alreadySelectedOption)
          );
        } else {
          const selectedOption = options.find((o) =>
            o.value.match(selected)
          )?.value;
          if (selectedOption)
            setSelectedOptions([...selectedOptions, selectedOption]);
        }

        updateText('');
        setIsChanged(true);
      } else {
        const selectedOption = options.find((o) => o.value.match(selected));
        if (selectedOption) setSelectedOptions([selectedOption.value]);
        setInputValue(selectedOption?.label || '');
        setIsChanged(true);
      }
      document.getElementById('textField')?.blur();
    },
    [allowMultiple, options, selectedOptions, updateText]
  );

  const removeTag = useCallback(
    (tag: string) => () => {
      const options = [...selectedOptions];
      options.splice(options.indexOf(tag), 1);
      setSelectedOptions(options);
    },
    [selectedOptions]
  );

  const verticalContentMarkup =
    selectedOptions.length > 0 ? (
      <PolarisLegacyStack spacing='extraTight' alignment='center'>
        {options
          .filter((option) => selectedOptions.includes(option.value))
          .map((option) => (
            <PolarisTag
              key={`option-${option.value}`}
              onRemove={removeTag(option.value)}
            >
              {option.label}
            </PolarisTag>
          ))}
      </PolarisLegacyStack>
    ) : null;

  const optionsMarkup =
    options.length > 0
      ? options.map((option) => {
          return (
            <PolarisListbox.Option
              key={option.value}
              value={option.value}
              selected={selectedOptions.includes(option.value)}
              accessibilityLabel={option.label}
            >
              {option.label}
            </PolarisListbox.Option>
          );
        })
      : null;

  return (
    <div className='customSelector'>
      <PolarisCombobox
        allowMultiple={allowMultiple}
        activator={
          <PolarisCombobox.TextField
            onChange={updateText}
            label={label}
            labelHidden={hideLabel ?? true}
            value={inputValue}
            placeholder={hidePlaceholder ? '' : placeholder || label}
            verticalContent={allowMultiple && verticalContentMarkup}
            autoComplete='off'
            prefix={prefix}
            suffix={suffix}
            id='textField'
          />
        }
      >
        {optionsMarkup ? (
          <PolarisListbox onSelect={updateSelection}>
            {optionsMarkup}
          </PolarisListbox>
        ) : null}
      </PolarisCombobox>
    </div>
  );
};
