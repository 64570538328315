import React, { useEffect, useState } from 'react';
import { BlockStack, Text } from '@shopify/polaris';
import ColorSelectorBox from 'core/components/WidgetEditor/ColorSelectorBox/ColorSelectorBox';

type ColorSelector = {
  color?: string | null;
  onColorChange: (value: string) => void;
  label?: string;
};

export const ColorSelector: React.FC<ColorSelector> = ({
  onColorChange,
  color,
  label,
}) => {
  const [shadowColor, setShadowColor] = useState(color || '#00000');

  useEffect(() => {
    onColorChange(shadowColor);
  }, [shadowColor]);

  return (
    <BlockStack>
      <Text as='p'>{label || 'Color'}</Text>
      <ColorSelectorBox
        color={`${shadowColor}`}
        setColor={(value) => setShadowColor(value || '#00000')}
      />
    </BlockStack>
  );
};
