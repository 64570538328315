import React, { useCallback, useMemo } from 'react';
import { Checkbox, TextField, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import classNames from 'classnames';
import './BorderStyle.scss';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import {
  BorderComponentDto,
  OptionDtoBorderComponentDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import {
  BorderSideDtoEnum,
  BorderStyleTypeDtoEnum,
  LineWeightTypeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import ColorSelectorBox from 'core/components/WidgetEditor/ColorSelectorBox/ColorSelectorBox';

type BorderStyleProps = {
  border: OptionDtoBorderComponentDto;
  isInherited?: boolean;
  customSides?: BorderSideDtoEnum[];
  disableStyle?: boolean;
  disableSides?: boolean;
  allowMultipleSides?: boolean;
  inherited?: boolean;
  setBorder: (value: OptionDtoBorderComponentDto) => void;
  onSystemReset: () => void;
};

export const BorderStyle: React.FC<BorderStyleProps> = ({
  customSides,
  disableStyle,
  border,
  disableSides,
  allowMultipleSides,
  inherited,
  onSystemReset,
  setBorder,
}) => {
  const [i18n] = useI18n();

  const styleBorderOptions = useMemo(
    () => [
      {
        value: BorderStyleTypeDtoEnum.DASHED,
        label: i18n.translate(`Dashed`),
      },
      {
        value: BorderStyleTypeDtoEnum.SOLID,
        label: i18n.translate(`Solid`),
      },
    ],
    []
  );

  const lineWeightOptions = useMemo(
    () =>
      Object.keys(LineWeightTypeDtoEnum).map((value) => ({
        value: value,
        label: i18n.translate(`${value}`),
      })),
    []
  );

  const updateBorderValue = useCallback(
    (
      field: keyof BorderComponentDto,
      data: BorderComponentDto[keyof BorderComponentDto]
    ) => {
      const sides = border.value?.sides;
      setBorder({
        ...border,
        value: {
          ...border.value,
          [field]:
            field === 'sides'
              ? allowMultipleSides
                ? sides?.includes(data as BorderSideDtoEnum)
                  ? sides.filter((item) => item !== data)
                  : [...(sides || []), data]
                : [data]
              : data,
        },
      });
    },
    [setBorder, border, allowMultipleSides]
  );

  const onBorderLineWeightValdiate = useCallback(() => {
    const customValue = border.value?.lineWeight?.customValue || 0;
    customValue > 6
      ? updateBorderValue('lineWeight', {
          ...border.value?.lineWeight,
          customValue: 6,
        })
      : customValue < 1
      ? updateBorderValue('lineWeight', {
          ...border.value?.lineWeight,
          customValue: 1,
        })
      : null;
  }, [border, updateBorderValue]);

  const onToggleBorder = useCallback(
    (value: boolean) => {
      setBorder({
        enabled: value,
        value:
          border.value === null
            ? {
                color: '#000000',
                sides: [BorderSideDtoEnum.ALL],
                type: BorderStyleTypeDtoEnum.DASHED,
                lineWeight: {
                  customValue: 1,
                  type: LineWeightTypeDtoEnum.MEDIUM,
                },
              }
            : border.value,
      });
    },
    [border, setBorder]
  );

  const borderSides = useMemo(
    () =>
      !customSides?.length
        ? Object.values(BorderSideDtoEnum)?.filter(
            (border) => border !== BorderSideDtoEnum.MIDDLE
          )
        : customSides,
    [customSides]
  );

  return (
    <div className='BorderStyle'>
      <StyleComponentHeader
        label={i18n.translate(`Border`)}
        inherited={inherited}
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            onAction: () => {
              onSystemReset();
            },
          },
        ]}
      >
        <Checkbox
          label={i18n.translate(`EnableBorder`)}
          checked={border.enabled}
          onChange={(value) => onToggleBorder(value)}
        />
        {border.enabled && (
          <>
            {!disableSides && (
              <div className='BorderRow'>
                {i18n.translate(`Side`)}
                <div className='RightContent'>
                  <div className='BorderSides'>
                    {borderSides.map((value) => (
                      <div
                        onClick={() => {
                          updateBorderValue('sides', value);
                        }}
                        key={value}
                        className={`${classNames('BackgroundSide', {
                          isActive: border.value?.sides?.includes(value),
                        })}`}
                      >
                        <div
                          className={`${classNames(value, {
                            isActive: border.value?.sides?.includes(value),
                          })} BorderSide`}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {!disableStyle && (
              <div className='BorderRow'>
                {i18n.translate(`Style`)}
                <div className='RightContent'>
                  <SelectOptions
                    options={styleBorderOptions}
                    onOptionSelect={(value) => {
                      updateBorderValue('type', value);
                    }}
                    selectedOption={border.value?.type || ''}
                    label=''
                  />
                </div>
              </div>
            )}
            <div className='BorderRow'>
              <div className='LineWeightBox'>
                <Text as='p'>{i18n.translate(`LineWeight`)}</Text>
                <div className='RightContent'>
                  <div className='LineWeightInputs'>
                    <SelectOptions
                      options={lineWeightOptions}
                      onOptionSelect={(value) =>
                        updateBorderValue('lineWeight', {
                          ...border.value?.lineWeight,
                          type: value as LineWeightTypeDtoEnum,
                        })
                      }
                      selectedOption={
                        border.value?.lineWeight?.type as LineWeightTypeDtoEnum
                      }
                      label=''
                    />
                    {border.value?.lineWeight?.type ===
                      LineWeightTypeDtoEnum.CUSTOM && (
                      <TextField
                        type='number'
                        value={border.value?.lineWeight?.customValue?.toString()}
                        autoComplete=''
                        label=''
                        onChange={(value) =>
                          updateBorderValue('lineWeight', {
                            ...border.value?.lineWeight,
                            customValue: +value,
                          })
                        }
                        onBlur={onBorderLineWeightValdiate}
                        suffix='px'
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='BorderRow'>
              {i18n.translate(`Color`)}
              <div className='RightContent'>
                <ColorSelectorBox
                  color={border.value?.color || ''}
                  setColor={(value) => updateBorderValue('color', value)}
                />
              </div>
            </div>
          </>
        )}
      </StyleComponentHeader>
    </div>
  );
};
