import React from 'react';
import { Button, LegacyCard } from '@shopify/polaris';
import styles from './Support.module.scss';
import {
  chatIconLookup,
  emailIconLookup,
} from '../../../../core/components/iconsDefinitions';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Update: React.FC = () => {
  const chatIconDef = findIconDefinition(chatIconLookup);
  const emailIconDef = findIconDefinition(emailIconLookup);

  const chatIcon = <FontAwesomeIcon icon={chatIconDef} />;
  const emailIcon = <FontAwesomeIcon icon={emailIconDef} />;

  return (
    <LegacyCard sectioned>
      <section className={styles.supportSection}>
        <div>
          <p>Our support team is here to help.</p>
          <p>We are available on weekdays from 9am EST until 9pm EST.</p>
        </div>
        <div className={styles.buttons}>
          <Button icon={chatIcon}>Start chat</Button>
          <Button icon={emailIcon}>Send email</Button>
        </div>
      </section>
    </LegacyCard>
  );
};
export default Update;
