import React, { useMemo } from 'react';
import { Banner, BlockStack, Card, Checkbox, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { NotificationAnimationsDto } from 'core/api/adminSettings/adminSettingsApi';
import { AnimationDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import { Element } from 'react-scroll';

type NotificationAnimationsSettingProps = {
  animations: NotificationAnimationsDto;
  setAnimations: (value: NotificationAnimationsDto) => void;
};
export const NotificationAnimationsSetting: React.FC<
  NotificationAnimationsSettingProps
> = ({ animations, setAnimations }) => {
  const [i18n] = useI18n();

  const aninmationOptions = useMemo(
    () =>
      Object.values(AnimationDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    []
  );

  return (
    <Element
      name='NotificationAnimationsSetting'
      className='NotificationAnimationsSetting'
    >
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('Animations')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('Subtitle')}
            </Text>
          </BlockStack>
          <Checkbox
            label={i18n.translate('FirstDisplay')}
            helpText={
              <BlockStack gap='300'>
                <Text as='p' tone='subdued'>
                  {i18n.translate('FirstDisplayDescription')}
                </Text>
                {animations.firstDisplay?.enabled && (
                  <SelectOptions
                    options={aninmationOptions}
                    onOptionSelect={(value) => {
                      setAnimations({
                        ...animations,
                        firstDisplay: {
                          ...animations.firstDisplay,
                          value: value as AnimationDtoEnum,
                        },
                      });
                    }}
                    selectedOption={
                      animations.firstDisplay?.value || AnimationDtoEnum.NONE
                    }
                    label={i18n.translate('Animation')}
                  />
                )}
              </BlockStack>
            }
            checked={animations.firstDisplay?.enabled}
            onChange={(value) => {
              setAnimations({
                ...animations,
                firstDisplay: {
                  ...animations.firstDisplay,
                  enabled: value,
                },
              });
            }}
          />

          <Checkbox
            label={i18n.translate('AnimationWhenMinimizing')}
            helpText={
              <BlockStack gap='300'>
                <Text as='p' tone='subdued'>
                  {i18n.translate('MinimizingDescription')}
                </Text>
                {animations.minimizing?.enabled && (
                  <SelectOptions
                    options={aninmationOptions}
                    onOptionSelect={(value) => {
                      setAnimations({
                        ...animations,
                        minimizing: {
                          ...animations.minimizing,
                          value: value as AnimationDtoEnum,
                        },
                      });
                    }}
                    selectedOption={
                      animations.minimizing?.value || AnimationDtoEnum.NONE
                    }
                    label={i18n.translate('Animation')}
                  />
                )}
              </BlockStack>
            }
            checked={animations.minimizing?.enabled}
            onChange={(value) => {
              setAnimations({
                ...animations,
                minimizing: {
                  ...animations.minimizing,
                  enabled: value,
                },
              });
            }}
          />

          <Banner tone='info'>{i18n.translate('BannerText')}</Banner>
        </BlockStack>
      </Card>
    </Element>
  );
};
