import React, { useCallback, useMemo } from 'react';
import {
  Badge,
  BlockStack,
  Button,
  Card,
  ChoiceList,
  InlineStack,
  Text,
  TextField,
} from '@shopify/polaris';
import './SizeWidthSetting.scss';
import { useI18n } from '@shopify/react-i18n';
import { SizeComponentDto } from 'core/api/adminSettings/adminSettingsApi';
import { SizeWidthDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { DeleteIcon, PlusIcon } from '@shopify/polaris-icons';
import { Element } from 'react-scroll';

type SizeWidthSettingProps = {
  size: SizeComponentDto;
  setSize: (value: SizeComponentDto) => void;
};
export const SizeWidthSetting: React.FC<SizeWidthSettingProps> = ({
  size,
  setSize,
}) => {
  const [i18n] = useI18n();
  const sizeWidthOptions = useMemo(
    () =>
      [SizeWidthDtoEnum.AUTO, SizeWidthDtoEnum.BREAKPOINTS].map((value) => ({
        label: (
          <InlineStack gap='200'>
            {i18n.translate(value)}
            {value === SizeWidthDtoEnum.AUTO && (
              <Badge>{i18n.translate('Recommended')}</Badge>
            )}
          </InlineStack>
        ),
        value: value,
      })),
    []
  );

  const updateBreakPoint = useCallback(
    (index: number, field: 'maxWidth' | 'contentWidth', value: number) => {
      setSize({
        ...size,
        breakpoints: size.breakpoints?.map((item, i) =>
          i === index ? { ...item, [field]: value } : item
        ),
      });
    },
    [size, setSize]
  );
  const deleteBreakPoint = useCallback(
    (index: number) => {
      setSize({
        ...size,
        breakpoints: size.breakpoints?.filter((_, i) => i !== index),
      });
    },
    [size, setSize]
  );
  const addBreakPoint = useCallback(() => {
    setSize({
      ...size,
      breakpoints: [
        ...(size.breakpoints || []),
        {
          maxWidth: 1280,
          contentWidth: 1200,
        },
      ],
    });
  }, [size, setSize]);

  return (
    <Element className='SizeWidthSetting' name='SizeWidthSetting'>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('Width')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('Subtitle')}
            </Text>
          </BlockStack>
          <ChoiceList
            title=''
            choices={sizeWidthOptions}
            selected={[size.width || SizeWidthDtoEnum.AUTO]}
            onChange={(value) =>
              setSize({ ...size, width: value[0] as SizeWidthDtoEnum })
            }
          />
          {size.width === SizeWidthDtoEnum.BREAKPOINTS && (
            <div className='BreakPointsList'>
              {size.breakpoints?.map((item, index) => (
                <div key={index} className='BreakPointRow'>
                  <div className='BreakpointsInput'>
                    <TextField
                      type='number'
                      label={index === 0 ? i18n.translate('MaxWidth') : ''}
                      value={item.maxWidth?.toString()}
                      onChange={(value) =>
                        updateBreakPoint(index, 'maxWidth', +value)
                      }
                      autoComplete=''
                    />
                  </div>
                  <div className='BreakpointsInput'>
                    <TextField
                      type='number'
                      value={item.contentWidth?.toString()}
                      label={index === 0 ? i18n.translate('ContentWidth') : ''}
                      onChange={(value) =>
                        updateBreakPoint(index, 'contentWidth', +value)
                      }
                      autoComplete=''
                    />
                  </div>
                  <div className='RemoveBreakpoint'>
                    <Button
                      onClick={() => deleteBreakPoint(index)}
                      icon={DeleteIcon}
                    />
                  </div>
                </div>
              ))}
              <Button icon={PlusIcon} onClick={addBreakPoint} variant='plain'>
                {i18n.translate('AddBreakpoint')}
              </Button>
            </div>
          )}
        </BlockStack>
      </Card>
    </Element>
  );
};
