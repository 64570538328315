import React, { useMemo } from 'react';
import { SelectedOptionPath } from '../../../../RightSideBarWrapper';
import {
  OfferRulePopupPresetDtoRead,
  OfferRulePopupSystemDefaultsDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { getLastSelectedOption } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import OfferRulePopupGeneral from './components/OfferRulePopupGeneral/OfferRulePopupGeneral';
import OfferRulePopupRule from './components/OfferRulePopupRule/OfferRulePopupRule';
import OfferRulePopupCTA from './components/OfferRulePopupCTA/OfferRulePopupCTA';
import {
  WidgetListOptionsDtoEnum,
  WidgetListTypeDtoEnum,
} from 'features/settings/components/EditPresetSkeleton/enums/enums';

type PresetOfferRulePopupProps = {
  selectedOptionPath: SelectedOptionPath;
  offerRulePopup: OfferRulePopupPresetDtoRead;
  defaultPresetData: OfferRulePopupPresetDtoRead;
  shopDefaultData: OfferRulePopupSystemDefaultsDto;
  defaultLanguage: string;
  updateOfferRulePopup: (data: OfferRulePopupPresetDtoRead) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const PresetOfferRulePopup: React.FC<PresetOfferRulePopupProps> = ({
  selectedOptionPath,
  offerRulePopup,
  defaultPresetData,
  shopDefaultData,
  defaultLanguage,
  updateOfferRulePopup,
  handleLeftMenuSelection,
}) => {
  const offerRulePopupOptions = useMemo(() => {
    const selectedOption = getLastSelectedOption(selectedOptionPath);
    switch (selectedOption?.name) {
      case WidgetListTypeDtoEnum.OFFER_RULE_POPUP:
        return (
          <OfferRulePopupGeneral
            general={offerRulePopup?.general || {}}
            defaultGeneral={defaultPresetData?.general || {}}
            shopDefaultGeneral={shopDefaultData.general || {}}
            isPresetEditor
            setGeneral={(data) =>
              updateOfferRulePopup({
                ...offerRulePopup,
                general: data,
              })
            }
            onShowHide={
              offerRulePopup.canBeHidden
                ? () =>
                    updateOfferRulePopup({
                      ...offerRulePopup,
                      isHidden: !offerRulePopup.isHidden,
                    })
                : undefined
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case WidgetListOptionsDtoEnum.OFFER_RULE:
        return (
          <OfferRulePopupRule
            offerRule={offerRulePopup.options?.offerRule || {}}
            defaultOfferRule={defaultPresetData?.options?.offerRule || {}}
            shopDefaultData={shopDefaultData.offerRule || {}}
            handleLeftMenuSelection={handleLeftMenuSelection}
            setOfferRule={(data) =>
              updateOfferRulePopup({
                ...offerRulePopup,
                options: {
                  ...offerRulePopup.options,
                  offerRule: data,
                },
              })
            }
          />
        );
      case WidgetListOptionsDtoEnum.CTA_BUTTON:
        return (
          <OfferRulePopupCTA
            ctaButton={offerRulePopup.options?.callToActionButton || {}}
            defaultCTAButton={
              defaultPresetData?.options?.callToActionButton || {}
            }
            shopDefaultCTA={shopDefaultData.callToActionButton || {}}
            defaultLanguage={defaultLanguage}
            isPresetEditor
            handleLeftMenuSelection={handleLeftMenuSelection}
            setCTAButton={(data) =>
              updateOfferRulePopup({
                ...offerRulePopup,
                options: {
                  ...offerRulePopup.options,
                  callToActionButton: data,
                },
              })
            }
          />
        );
    }
  }, [
    selectedOptionPath,
    shopDefaultData,
    offerRulePopup,
    defaultPresetData,
    updateOfferRulePopup,
    handleLeftMenuSelection,
  ]);
  return <div>{offerRulePopupOptions}</div>;
};

export default PresetOfferRulePopup;
