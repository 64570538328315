import React, { useMemo } from 'react';
import './RichTextEditor.scss';
import { EditorContentProps } from '@tiptap/react';
import { ToolbarButton } from './ToolbarButton';
import { SmartTagToolbarElement } from './extensions/SmartTag';
import { PlatformEnum } from './RichTextEditor';
import { FontFamilyToolbarElement } from './extensions/FontFamily';
import { FontSizeToolbarElement } from './extensions/FontSize';
import { FontColorToolbarElement } from './extensions/FontColor';
import {
  boldIconToolbar,
  codeIconToolbar,
  cursiveIconToolbar,
  strikeIconToolbar,
  underlineIconToolbar,
} from './assets/iconsToolbar';
import { LinkToolbarElement } from './extensions/LinkComponent';
import { useI18n } from '@shopify/react-i18n';
import { SmartTag } from 'core/api/adminPromotions/adminPromotionsApi';
import classNames from 'classnames';
import { TextAlignement } from './extensions/TextAlignement';
import { Feature } from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components';

type ToolbarProps = EditorContentProps & {
  features: Feature[];
  codeView: boolean;
  currentList?: SmartTag[];
  onEditorViewChange?: (isCodeVew: boolean) => void;
  platform: PlatformEnum;
  disableToolbar: boolean;
  priceOnly?: boolean;
};

export const Toolbar: React.FC<ToolbarProps> = (props) => {
  const {
    editor,
    features,
    codeView,
    platform,
    onEditorViewChange,
    disableToolbar,
    currentList,
    priceOnly,
  } = props;

  const [i18n] = useI18n();

  const disabled = useMemo(
    () => !!(codeView || disableToolbar),
    [codeView, disableToolbar]
  );

  if (!editor) return null;

  return (
    <div
      className={classNames('rte__toolbar', {
        HideToolbar: priceOnly,
      })}
    >
      <div className='rte__toolbar-format'>
        {features.includes('font-family') && (
          <div className='rte__toolbar-format__line'>
            {features.includes('font-family') && (
              <FontFamilyToolbarElement editor={editor} disabled={disabled} />
            )}
          </div>
        )}
        <div className='rte__toolbar-format__line'>
          {features.includes('bold') && (
            <ToolbarButton
              name='bold'
              icon={boldIconToolbar(editor.isActive('bold'))}
              active={editor.isActive('bold')}
              onClick={() =>
                !codeView && editor.chain().focus().toggleBold().run()
              }
              disabled={disabled}
              tooltipContent={`${i18n?.translate('Bold')} ${platform === PlatformEnum.IOS ? '(⌘+B)' : '(Ctrl+B)'
                }`}
            />
          )}
          {features.includes('italic') && (
            <ToolbarButton
              name='italic'
              icon={cursiveIconToolbar(editor.isActive('italic'))}
              active={editor.isActive('italic')}
              onClick={() =>
                !codeView && editor.chain().focus().toggleItalic().run()
              }
              disabled={disabled}
              tooltipContent={`${i18n?.translate('Italic')} ${platform === PlatformEnum.IOS ? '(⌘+I)' : '(Ctrl+I)'
                }`}
            />
          )}
          {features.includes('underline') && (
            <ToolbarButton
              name='underline'
              icon={underlineIconToolbar(editor.isActive('underline'))}
              active={editor.isActive('underline')}
              onClick={() =>
                !codeView && editor.chain().focus().toggleUnderline().run()
              }
              disabled={disabled}
              tooltipContent={`${i18n?.translate('Underline')} ${platform === PlatformEnum.IOS ? '(⌘+U)' : '(Ctrl+U)'
                }`}
            />
          )}
          {features.includes('color') && (
            <FontColorToolbarElement editor={editor} disabled={disabled} />
          )}
          {features.includes('strike') && (
            <ToolbarButton
              name='strike'
              icon={strikeIconToolbar(editor.isActive('strike'))}
              active={editor.isActive('strike')}
              onClick={() =>
                !codeView && editor.chain().focus().toggleStrike().run()
              }
              disabled={disabled}
              tooltipContent={`${i18n?.translate('Strike')} ${platform === PlatformEnum.IOS ? '(⌘+Shift+X)' : '(Ctrl+Shift+X)'
                }`}
            />
          )}
          {features.includes('font-size') && (
            <FontSizeToolbarElement editor={editor} disabled={disabled} />
          )}
          {features.includes('link') && (
            <LinkToolbarElement editor={editor} disabled={disabled} />
          )}
          {features.includes('code-editor') && (
            <ToolbarButton
              name='code-editor'
              icon={codeIconToolbar(codeView)}
              active={codeView}
              onClick={() =>
                onEditorViewChange && onEditorViewChange(!codeView)
              }
            />
          )}
          {features.includes('text-align') && (
            <TextAlignement editor={editor} disabled={disabled} platform={platform} />
          )}
          {features.includes('smartTag') && currentList?.length && (
            <div className='rte__toolbar-smart-tags'>
              <SmartTagToolbarElement
                editor={editor}
                disabled={disabled}
                currentList={currentList}
                priceOnly={priceOnly}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
