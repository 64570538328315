import React, { useCallback, useMemo } from 'react';
import { useI18n } from '@shopify/react-i18n';
import './Background.scss';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import {
  BackgroundComponentDto,
  BorderComponentDto,
  LineWeightDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import {
  BackgroundColorFillTypeDtoEnum,
  BorderSideDtoEnum,
  LineWeightTypeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import ColorSelectorBox from 'core/components/WidgetEditor/ColorSelectorBox/ColorSelectorBox';
import { Checkbox, TextField, Text } from '@shopify/polaris';
import classNames from 'classnames';

type BackgroundProps = {
  background: BackgroundComponentDto;
  allowFill?: boolean;
  allowIndicatorLine?: boolean;
  borderSides?: BorderSideDtoEnum[];
  allowMultipleSides?: boolean;
  inherited?: boolean;
  hideBorder?: boolean;
  onSystemReset: () => void;
  setBackground: (value: BackgroundComponentDto) => void;
};

export const Background: React.FC<BackgroundProps> = ({
  background,
  allowFill,
  allowIndicatorLine,
  borderSides,
  allowMultipleSides,
  inherited,
  hideBorder,
  onSystemReset,
  setBackground,
}) => {
  const [i18n] = useI18n();

  const backgroundColorFillOptions = useMemo(
    () =>
      Object.keys(BackgroundColorFillTypeDtoEnum).map((value) => ({
        value: value,
        label: i18n.translate(`${value}`),
      })),
    []
  );
  const lineWeightOptions = useMemo(
    () =>
      Object.keys(LineWeightTypeDtoEnum).map((value) => ({
        value: value,
        label: i18n.translate(`${value}`),
      })),
    []
  );

  const updateBorder = useCallback(
    (
      field: keyof BorderComponentDto,
      data: BorderComponentDto[keyof BorderComponentDto]
    ) => {
      const sides = background.border?.value?.sides;

      setBackground({
        ...background,
        border: {
          ...background.border,
          value: {
            ...background.border?.value,
            [field]:
              field === 'sides'
                ? allowMultipleSides
                  ? sides?.includes(data as BorderSideDtoEnum)
                    ? sides.filter((item) => item !== data)
                    : [...(sides || []), data]
                  : [data]
                : data,
          },
        },
      });
    },
    [setBackground, background, allowMultipleSides]
  );
  const updateIndicatorLineWeight = useCallback(
    (field: keyof LineWeightDto, data: LineWeightDto[keyof LineWeightDto]) => {
      setBackground({
        ...background,
        indicatorLine: {
          ...background.indicatorLine,
          value: {
            ...background.indicatorLine?.value,
            lineWeight: {
              ...background.indicatorLine?.value?.lineWeight,
              [field]: data,
            },
          },
        },
      });
    },
    [setBackground, background]
  );

  const onBorderLineWeightValdiate = useCallback(() => {
    const customValue = background.border?.value?.lineWeight?.customValue || 0;
    customValue > 6
      ? updateBorder('lineWeight', {
          ...background.border?.value?.lineWeight,
          customValue: 6,
        })
      : customValue < 1
      ? updateBorder('lineWeight', {
          ...background.border?.value?.lineWeight,
          customValue: 1,
        })
      : null;
  }, [background, updateBorder]);

  const onIndicatorLineWeightValdiate = useCallback(() => {
    const customValue =
      background.indicatorLine?.value?.lineWeight?.customValue || 0;
    customValue > 6
      ? updateIndicatorLineWeight('customValue', 6)
      : customValue < 1
      ? updateIndicatorLineWeight('customValue', 1)
      : null;
  }, [background, updateIndicatorLineWeight]);

  return (
    <div className='Background'>
      <StyleComponentHeader
        label={i18n.translate(`Background`)}
        inherited={inherited}
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            onAction: () => onSystemReset(),
          },
        ]}
      >
        {allowFill && (
          <div className='BackgroundRow'>
            {i18n.translate(`Fill`)}
            <div className='RightContent'>
              <SelectOptions
                options={backgroundColorFillOptions}
                onOptionSelect={(value) => {
                  setBackground({
                    ...background,
                    fill: value as BackgroundColorFillTypeDtoEnum,
                  });
                }}
                selectedOption={
                  background.fill || BackgroundColorFillTypeDtoEnum.IMAGE
                }
                label=''
              />
            </div>
          </div>
        )}

        <div className='BackgroundRow'>
          {i18n.translate(`Color`)}
          <div className='RightContent'>
            <ColorSelectorBox
              color={background.color || ''}
              setColor={(value) =>
                setBackground({ ...background, color: value })
              }
            />
          </div>
        </div>
        {!hideBorder && (
          <>
            {' '}
            <Checkbox
              onChange={(value) =>
                setBackground({
                  ...background,
                  border: {
                    ...background.border,
                    enabled: value,
                  },
                })
              }
              checked={background.border?.enabled}
              label={i18n.translate('EnableBorder')}
            />
            {background.border?.enabled && (
              <>
                {!!borderSides?.length && (
                  <div className='BackgroundRow'>
                    {i18n.translate(`Side`)}
                    <div className='RightContent'>
                      <div className='BorderSides'>
                        {borderSides.map((value) => (
                          <div
                            onClick={() => {
                              updateBorder('sides', value);
                            }}
                            key={value}
                            className={`${classNames('BackgroundSide', {
                              isActive:
                                background.border?.value?.sides?.includes(
                                  value
                                ),
                            })}`}
                          >
                            <div
                              className={`${classNames(value, {
                                isActive:
                                  background.border?.value?.sides?.includes(
                                    value
                                  ),
                              })} BorderSide`}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                <div className='BackgroundRow'>
                  <div className='LineWeightBox'>
                    <Text as='p'>{i18n.translate(`LineWeight`)}</Text>
                    <div className='RightContent'>
                      <div className='LineWeightInputs'>
                        <SelectOptions
                          options={lineWeightOptions}
                          onOptionSelect={(value) =>
                            updateBorder('lineWeight', {
                              ...background.border?.value?.lineWeight,
                              type: value as LineWeightTypeDtoEnum,
                            })
                          }
                          selectedOption={
                            background.border.value?.lineWeight?.type ||
                            LineWeightTypeDtoEnum.MEDIUM
                          }
                          label=''
                        />
                        {background.border.value?.lineWeight?.type ===
                          LineWeightTypeDtoEnum.CUSTOM && (
                          <TextField
                            type='number'
                            value={background.border.value?.lineWeight?.customValue?.toString()}
                            autoComplete=''
                            label=''
                            onChange={(value) =>
                              updateBorder('lineWeight', {
                                ...background.border?.value?.lineWeight,
                                customValue: +value,
                              })
                            }
                            onBlur={onBorderLineWeightValdiate}
                            suffix='px'
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='BackgroundRow'>
                  {i18n.translate(`BorderColor`)}
                  <div className='RightContent'>
                    <ColorSelectorBox
                      color={background.border?.value?.color || ''}
                      setColor={(value) =>
                        setBackground({
                          ...background,
                          border: {
                            ...background.border,
                            value: {
                              ...background.border?.value,
                              color: value,
                            },
                          },
                        })
                      }
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}

        {allowIndicatorLine && (
          <>
            {' '}
            <Checkbox
              onChange={(value) =>
                setBackground({
                  ...background,
                  indicatorLine: {
                    ...background.indicatorLine,
                    enabled: value,
                  },
                })
              }
              checked={background.indicatorLine?.enabled}
              label={i18n.translate('EnableIndicatorLine')}
            />
            {background.indicatorLine?.enabled && (
              <>
                <div className='BackgroundRow'>
                  <div className='LineWeightBox'>
                    <Text as='p'>{i18n.translate(`LineWeight`)}</Text>
                    <div className='RightContent'>
                      <div className='LineWeightInputs'>
                        <SelectOptions
                          options={lineWeightOptions}
                          onOptionSelect={(value) =>
                            updateIndicatorLineWeight(
                              'type',
                              value as LineWeightTypeDtoEnum
                            )
                          }
                          selectedOption={
                            background.indicatorLine.value?.lineWeight?.type ||
                            LineWeightTypeDtoEnum.MEDIUM
                          }
                          label=''
                        />
                        {background.indicatorLine.value?.lineWeight?.type ===
                          LineWeightTypeDtoEnum.CUSTOM && (
                          <TextField
                            type='number'
                            value={background.indicatorLine.value?.lineWeight?.customValue?.toString()}
                            autoComplete=''
                            label=''
                            onChange={(value) =>
                              updateIndicatorLineWeight('customValue', +value)
                            }
                            onBlur={onIndicatorLineWeightValdiate}
                            suffix='px'
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='BackgroundRow'>
                  {i18n.translate(`BorderColor`)}
                  <div className='RightContent'>
                    <ColorSelectorBox
                      color={background.indicatorLine.value?.color || ''}
                      setColor={(value) =>
                        setBackground({
                          ...background,
                          indicatorLine: {
                            ...background.indicatorLine,
                            value: {
                              ...background.indicatorLine?.value,
                              color: value,
                            },
                          },
                        })
                      }
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </StyleComponentHeader>
    </div>
  );
};
