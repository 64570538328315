import React from 'react';
import './Spacing.scss';
import { useI18n } from '@shopify/react-i18n';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import { DirectionalSpacingComponentDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { SpacingTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';

type SpacingProps = {
  spacing: DirectionalSpacingComponentDto;
  disabled?: boolean;
  inherited?: boolean;
  setSpacing: (value: DirectionalSpacingComponentDto) => void;
  onSystemReset?: () => void;
};

export const Spacing: React.FC<SpacingProps> = ({
  spacing,
  disabled,
  inherited,
  setSpacing,
  onSystemReset,
}) => {
  const [i18n] = useI18n();

  const spacingOptions = Object.values(SpacingTypeDtoEnum)
    .filter((value) => value !== SpacingTypeDtoEnum.CUSTOM)
    .map((value) => {
      return {
        value: value,
        label: i18n.translate(`${value}`),
      };
    });

  return (
    <div className='Spacing'>
      <StyleComponentHeader
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            disabled: disabled,
            onAction: () => onSystemReset?.(),
          },
        ]}
        inherited={inherited}
        label={i18n.translate(`Spacing`)}
      >
        <div className='SpacingRow'>
          {i18n.translate(`Horizontal`)}
          <div className='RightContent'>
            <SelectOptions
              disabled={disabled}
              options={spacingOptions}
              onOptionSelect={(value) =>
                setSpacing({
                  ...spacing,
                  horizontal: value as SpacingTypeDtoEnum,
                })
              }
              selectedOption={spacing.horizontal as SpacingTypeDtoEnum}
              label=''
            />
          </div>
        </div>
        <div className='SpacingRow'>
          {i18n.translate(`Vertical`)}
          <div className='RightContent'>
            <SelectOptions
              disabled={disabled}
              options={spacingOptions}
              onOptionSelect={(value) =>
                setSpacing({
                  ...spacing,
                  vertical: value as SpacingTypeDtoEnum,
                })
              }
              selectedOption={spacing.vertical as SpacingTypeDtoEnum}
              label=''
            />
          </div>
        </div>
      </StyleComponentHeader>
    </div>
  );
};
