import React from 'react';
import './StyleSetting.scss';
import {
  Bleed,
  BlockStack,
  Box,
  Button,
  CalloutCard,
  Card,
  Icon,
  InlineStack,
  Link,
  Text,
  Tooltip,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { InfoIcon } from '@shopify/polaris-icons';
import { Element } from 'react-scroll';
import ColorBlock from 'features/settings/components/GeneralSettings/Style/components/ColorBlock/ColorBlock';
import MomentAgo from 'core/components/MomentAgo/MomentAgo';

type StyleSettingProps = {
  styled?: boolean;
};
export const StyleSetting: React.FC<StyleSettingProps> = ({ styled }) => {
  const [i18n] = useI18n();

  return (
    <Element name='StyleSetting' className='StyleSetting'>
      {styled ? (
        <Card>
          {/* MOCK MOCK MOCK MOCK */}
          <BlockStack gap='400'>
            <InlineStack align='space-between'>
              <InlineStack gap='200'>
                <Text as='p' fontWeight='bold'>
                  Style
                </Text>
                <Tooltip content={i18n.translate('TooltipContent')}>
                  <Icon tone='base' source={InfoIcon} />
                </Tooltip>
              </InlineStack>
              <Button variant='plain'>Change style</Button>
            </InlineStack>
            <div className='StyleCardBox'>
              <InlineStack align='space-between' blockAlign='center'>
                <InlineStack gap='300'>
                  <ColorBlock
                    colors={['#061a3f', '#167d24', '#604141', '#1c6b2d']}
                  />
                  <BlockStack>
                    <Text fontWeight='regular' as='p'>
                      Red apple
                    </Text>
                    <Text as='p' tone='subdued'>
                      <div style={{ display: 'flex', whiteSpace: 'pre' }}>
                        {'System preset '}{' '}
                        <MomentAgo timestamp={1715248728177} />
                      </div>
                    </Text>
                  </BlockStack>
                </InlineStack>
                <Button>{i18n.translate('Configure')}</Button>
              </InlineStack>
            </div>
            <Bleed marginBlockEnd='400' marginInline='400'>
              <Box background='bg-surface-secondary' padding='400'>
                <BlockStack gap='200'>
                  <Text as='h3' tone='subdued'>
                    {i18n.translate(`ToConfigureVisual`)}
                    <Link>{i18n.translate(`StylePresets`)}</Link>
                  </Text>
                </BlockStack>
              </Box>
            </Bleed>
          </BlockStack>
          {/* MOCK MOCK MOCK MOCK */}
        </Card>
      ) : (
        <CalloutCard
          title={
            <InlineStack gap='200'>
              <Text fontWeight='bold' as='p'>
                {i18n.translate('Style')}
              </Text>
              <Tooltip content={i18n.translate('TooltipContent')}>
                <Icon tone='base' source={InfoIcon} />
              </Tooltip>
            </InlineStack>
          }
          illustration='https://cdn.shopify.com/s/assets/admin/checkout/settings-customizecart-705f57c725ac05be5a34ec20c05b94298cb8afd10aac7bd9c7ad02030f48cfa0.svg'
          primaryAction={{
            content: i18n.translate('ManageStyles'),
          }}
        >
          {i18n.translate('VisualStyleFor')}
        </CalloutCard>
      )}
    </Element>
  );
};
