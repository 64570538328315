import React, { useCallback } from 'react';
import {
  DiscountLabelGeneralOfferDto,
  DiscountLabelSystemDefaultsDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { TextEntries } from '../../../../../components';
import { useI18n } from '@shopify/react-i18n';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import DiscountLabel from './components/DiscountLabel/DiscountLabel';
import { Box, Link, Text } from '@shopify/polaris';

type DiscountLabelOfferProps = {
  offer: DiscountLabelGeneralOfferDto;
  defaultOffer: DiscountLabelGeneralOfferDto;
  shopDefaultOffer: DiscountLabelSystemDefaultsDto;
  offerName: string;
  offerId?: string;
  defaultLanguage: string;
  setOffer: (data: DiscountLabelGeneralOfferDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const DiscountLabelOffer: React.FC<DiscountLabelOfferProps> = ({
  offer,
  defaultOffer,
  shopDefaultOffer,
  offerName,
  defaultLanguage,
  offerId,
  setOffer,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();
  const handleUpdateOffer = useCallback(
    (
      field: keyof DiscountLabelGeneralOfferDto,
      data: DiscountLabelGeneralOfferDto[keyof DiscountLabelGeneralOfferDto]
    ) => {
      setOffer({
        ...offer,
        [field]: data,
      });
    },
    [offer, setOffer]
  );

  const resetAllToSystemDefault = useCallback(() => {
    const { labelType } = shopDefaultOffer;
    setOffer({
      ...defaultOffer,
      labelType,
    });
  }, [shopDefaultOffer, defaultOffer]);

  return (
    <div className='DiscountLabelOffer'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={() => setOffer(defaultOffer)}
        onResetToSystem={resetAllToSystemDefault}
        name={offerName}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />

      <div className='RightSideSection'>
        <DiscountLabel
          discountLabel={offer || {}}
          setDiscountLabel={setOffer}
          onSystemReset={resetAllToSystemDefault}
        />
        {offer.customLabel !== null && (
          <TextEntries
            textMessage={offer.customLabel || {}}
            defaultLanguage={defaultLanguage || ''}
            disableCollapsed
            disableDevice
            disableGoal
            priceOnly
            offerId={offerId}
            setTextMessage={(data) => handleUpdateOffer('customLabel', data)}
            onSystemReset={() =>
              handleUpdateOffer('customLabel', defaultOffer.customLabel)
            }
          />
        )}
      </div>
      <div className='RightSideSection'>
        <Box padding='500'>
          <Text tone='subdued' as='p'>
            {i18n.translate('Note')} <Link>{i18n.translate('LearnMore')} </Link>
          </Text>
        </Box>
      </div>
    </div>
  );
};

export default DiscountLabelOffer;
