import React from 'react';
import { BlockStack, Card, Link, Text, TextField } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { OptionDtoString } from 'core/api/adminSettings/adminSettingsApi';

type DiscountCodePrefixProps = {
  prefix?: OptionDtoString;
  setPrefix: (value: OptionDtoString) => void;
};
export const DiscountCodePrefix: React.FC<DiscountCodePrefixProps> = ({
  prefix,
  setPrefix,
}) => {
  const [i18n] = useI18n();

  return (
    <Card>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text fontWeight='semibold' as='h2'>
            {i18n.translate('Title')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('Subtitle', {
              readArticle: <Link>{i18n.translate('ReadThisArticle')}</Link>,
            })}
          </Text>
        </BlockStack>
        <TextField
          autoComplete=''
          label={i18n.translate('Prefix')}
          value={prefix?.value as string}
          onChange={(value) => setPrefix({ ...prefix, value: value })}
          maxLength={10}
          placeholder={i18n.translate('Placeholder')}
          helpText={i18n.translate('CodeExample', {
            dn: (
              <Text as='span' fontWeight='semibold'>
                DN
              </Text>
            ),
          })}
        />
      </BlockStack>
    </Card>
  );
};
