import React, { useCallback } from 'react';
import {
  MessageStyleComponentDto,
  OfferRulePopupRuleTextOfferDto,
  ProductBannerMessageOfferDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { TextStyle, TextEntries } from '../../../../../components';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useI18n } from '@shopify/react-i18n';
import { isEqual } from 'lodash';

type OfferRulePopupTextProps = {
  message: OfferRulePopupRuleTextOfferDto;
  defaultMessage: OfferRulePopupRuleTextOfferDto;
  defaultLanguage: string;
  shopDefaultMessage: MessageStyleComponentDto;
  presetSetup?: MessageStyleComponentDto;
  defaultPresetSetup?: MessageStyleComponentDto;
  setMessage: (data: OfferRulePopupRuleTextOfferDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
  offerId?: string;
};

const OfferRulePopupText: React.FC<OfferRulePopupTextProps> = ({
  message,
  defaultMessage,
  defaultLanguage,
  shopDefaultMessage,
  presetSetup,
  defaultPresetSetup,
  setMessage,
  onShowHide,
  handleLeftMenuSelection,
  offerId,
}) => {
  const [i18n] = useI18n();
  const resetAllToSystemDefault = useCallback(() => {
    setMessage({
      ruleText: {
        ...message.ruleText,
        message: defaultMessage?.ruleText?.message,
        style: shopDefaultMessage,
      },
    });
  }, [shopDefaultMessage, defaultMessage, message]);

  const updateMessageState = useCallback(
    (
      field: keyof ProductBannerMessageOfferDto,
      data: ProductBannerMessageOfferDto[keyof ProductBannerMessageOfferDto]
    ) => {
      setMessage({
        ruleText: {
          ...message.ruleText,
          style: presetSetup,
          [field]: data,
        },
      });
    },
    [message, setMessage, presetSetup]
  );

  const onDiscardChanges = useCallback(() => {
    setMessage({
      ...defaultMessage,
      ruleText: {
        ...defaultMessage.ruleText,
        style: defaultPresetSetup,
      },
    });
  }, [defaultPresetSetup, defaultMessage]);

  return (
    <div className='OfferRulePopupText'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={onDiscardChanges}
        onResetToSystem={resetAllToSystemDefault}
        name={WidgetListOptionsDtoEnum.RULE_TEXT}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <TextEntries
          textMessage={message.ruleText?.message || {}}
          title={i18n.translate('RuleText')}
          disableColor={(presetSetup || {}).color !== undefined}
          offerId={offerId}
          defaultLanguage={defaultLanguage}
          inherited={isEqual(presetSetup, defaultPresetSetup)}
          disableCollapsed
          disableDevice
          disableGoal
          setTextMessage={(data) => updateMessageState('message', data)}
          onSystemReset={resetAllToSystemDefault}
        />
        <TextStyle
          hideHeader
          textStyle={presetSetup || {}}
          setTextStyle={(data) => updateMessageState('style', data)}
          onSystemReset={() => null}
        />
      </div>
    </div>
  );
};

export default OfferRulePopupText;
