import React, { useCallback, useEffect, useState } from 'react';
import { BlockStack, Card, Checkbox, Text } from '@shopify/polaris';
import { useDispatch } from 'react-redux';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';
import {
  setIsUnsavedChanges,
  setOnDiscardChanges,
  setOnSaveChanges,
} from 'core/store/settingsSlice';
import {
  PromotionSummaryShopSettingsDto,
  PromotionSummaryTotalDiscountCalculationModeDto,
  WidgetSettingsDtoPromotionSummaryShopSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  StyleSetting,
  WidgetSettingsWrapper,
  WidgetTypeSettingsWrapperEnum,
} from '../components';
import { useI18n } from '@shopify/react-i18n';
import { TotalDiscount } from './components/TotalDiscount/TotalDiscount';
import { DiscountSettings } from './components/DiscountSettings/DiscountSettings';
import { ShippingAmount } from './components/ShippingAmount/ShippingAmount';

export const PromotionSummarySetting: React.FC = () => {
  const dispatch = useDispatch();
  const {
    promotionSummaryData,
    savePromotionSummary,
    promotionSummaryDataIsFetching,
    savePromotionSummaryIsLoading,
    refetchPromotionSummaryData,
  } = useConfigureSettings(SettingsFetchTypeEnum.PROMOTION_SUMMARY);
  const [i18n] = useI18n();
  const [savedData, setSavedData] =
    useState<WidgetSettingsDtoPromotionSummaryShopSettingsDto>({});
  const [promotionSummaryState, setPromotionSummaryState] =
    useState<WidgetSettingsDtoPromotionSummaryShopSettingsDto>({});

  const updatePromotionSummaryState = useCallback(
    (
      field: keyof PromotionSummaryShopSettingsDto,
      data: PromotionSummaryShopSettingsDto[keyof PromotionSummaryShopSettingsDto]
    ) =>
      setPromotionSummaryState((prev) => ({
        ...prev,
        settings: {
          ...prev.settings,
          [field]: data,
        },
      })),
    [setPromotionSummaryState]
  );

  useEffect(() => {
    if (
      !isEmpty(promotionSummaryState) &&
      !isEqual(savedData, promotionSummaryState)
    ) {
      dispatch(setIsUnsavedChanges(true));
      dispatch(
        setOnDiscardChanges(
          () => !isEmpty(savedData) && setPromotionSummaryState(savedData)
        )
      );
      dispatch(
        setOnSaveChanges(() => {
          setSavedData(promotionSummaryState);
          return savePromotionSummary(promotionSummaryState.settings || {});
        })
      );
    } else if (isEqual(savedData, promotionSummaryState)) {
      dispatch(setIsUnsavedChanges(false));
    }
  }, [promotionSummaryState, savedData, dispatch]);

  useEffect(() => {
    if (!isEmpty(promotionSummaryData)) {
      setPromotionSummaryState(promotionSummaryData);
      setSavedData(promotionSummaryData);
    }
  }, [promotionSummaryData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  return (
    <div className='PromotionSummarySetting'>
      <WidgetSettingsWrapper
        isLoading={promotionSummaryDataIsFetching || !promotionSummaryData}
        widgetState={{
          ...promotionSummaryState,
          widgetType: WidgetTypeSettingsWrapperEnum.PROMOTION_SUMMARY,
        }}
        refetchWidget={refetchPromotionSummaryData}
        cartDrawer={true}
        cartDrawerData={{
          title: i18n.translate('PromotionSummary'),
          subtitle: i18n.translate('PromotionSummarySubtitle'),
          newLinks: false,
          items: [
            {
              name: i18n.translate('CartPage'),
              enabled: true,
              tab: 'cart',
              helpText: i18n.translate('CartPageHelpText'),
            },
            {
              name: i18n.translate('DrawerCart'),
              enabled: false,
              tab: 'drawer',
              helpText: i18n.translate('DrawerCartHelpText'),
            },
          ],
        }}
      >
        <BlockStack gap='600'>
          <StyleSetting styled />
          <TotalDiscount
            total={
              promotionSummaryState.settings
                ?.totalDiscountCalculationMode as PromotionSummaryTotalDiscountCalculationModeDto
            }
            setTotal={(value) =>
              updatePromotionSummaryState('totalDiscountCalculationMode', value)
            }
          />
          <DiscountSettings
            showTotal={!!promotionSummaryState?.settings?.showTotal}
            setShowTotal={(value) =>
              updatePromotionSummaryState('showTotal', value)
            }
            showDiscountBreakdown={
              !!promotionSummaryState?.settings?.showDiscountBreakdown
            }
            setShowDiscountBreakdown={(value) =>
              updatePromotionSummaryState('showDiscountBreakdown', value)
            }
          />
          <ShippingAmount
            shippingAmount={
              promotionSummaryState.settings?.shippingAmount || {}
            }
            setShippingAmount={(data) =>
              updatePromotionSummaryState('shippingAmount', data)
            }
          />
          <Card>
            <BlockStack gap='400'>
              <Text as='p' fontWeight='bold'>
                {i18n.translate('FreeGift')}
              </Text>
              <Checkbox
                label={i18n.translate('ConsiderFreeGift')}
                checked={
                  !!promotionSummaryState.settings?.considerFreeGiftADiscount
                }
                onChange={(value) =>
                  updatePromotionSummaryState(
                    'considerFreeGiftADiscount',
                    value
                  )
                }
              />
            </BlockStack>
          </Card>
        </BlockStack>
      </WidgetSettingsWrapper>
    </div>
  );
};
