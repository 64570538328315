import React, { useCallback } from 'react';
import './Order.scss';
import { useI18n } from '@shopify/react-i18n';
import { OrderComponentDto } from 'core/api/adminWidgets/adminWidgetsApi';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import { TextField } from '@shopify/polaris';

type OrderProps = {
  order?: OrderComponentDto;
  title?: string;
  setOrder: (value: OrderComponentDto) => void;
  onSystemReset: () => void;
};
export const Order: React.FC<OrderProps> = ({
  order,
  title,
  setOrder,
  onSystemReset,
}) => {
  const [i18n] = useI18n();

  const handleBlurPriority = useCallback(() => {
    const newPriority = Math.min(20, Math.max(1, order?.priority || 1));
    setOrder({ priority: newPriority });
  }, [order?.priority, setOrder]);

  return (
    <div className='Order'>
      <StyleComponentHeader
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            onAction: () => onSystemReset(),
          },
        ]}
        tip='ccdc'
        label={title ? title : i18n.translate(`Order`)}
      >
        <div className='OrderRow'>
          {i18n.translate(`Priority`)}
          <div className='RightContent'>
            <TextField
              type='number'
              value={order?.priority?.toString()}
              onBlur={handleBlurPriority}
              onChange={(value) => setOrder({ priority: +value })}
              autoComplete=''
              label=''
            />
          </div>
        </div>
      </StyleComponentHeader>
    </div>
  );
};
