import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  InlineStack,
  Modal,
  Page,
} from '@shopify/polaris';
import {
  BrandColorsStep,
  EmailStep,
  FinalStep,
  GoalStep,
  WelcomeStep,
  WidgetInstallationStep,
  WidgetsIntroStep,
} from './components';
import { useI18n } from '@shopify/react-i18n';
import { CompleteOnboardingRequestDto } from 'core/api/adminSettings/adminSettingsApi';
import {
  OnboardingFetchTypeEnum,
  useConfigureOnboarding,
} from './hooks/useConfigureOnboarding';
import { isEmpty } from 'lodash';
import { CardsSkeleton } from 'core/components';
import RequestWidgetSupport from 'features/settings/components/GeneralSettings/ManageWidgets/components/WidgetSettings/components/WidgetSettingsWrapper/components/ToggleWidgetModal/components/RequestWidgetSupport/RequestWidgetSupport';
import './Onboarding.scss';
type OnboardingProps = {
  finishOnboarding: () => void;
};
export const Onboarding: React.FC<OnboardingProps> = ({ finishOnboarding }) => {
  const [i18n] = useI18n();
  const { onboardingInitialData } = useConfigureOnboarding(
    OnboardingFetchTypeEnum.ONBOARDING_PAGE
  );

  const [onboardingSetup, setOnboardingSetup] =
    useState<CompleteOnboardingRequestDto>({});

  const [step, setStep] = useState<number>(1);
  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [installationModalOpen, setInstallationModalOpen] =
    useState<boolean>(false);
  const [isWidgetRequestSent, setIsWidgetRequestSent] =
    useState<boolean>(false);
  const showStepButtons = useMemo(() => step !== 1 && step !== 7, [step]);

  const isEmailValid = useMemo(() => {
    const regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');
    return step === 3 ? regex.test(onboardingSetup.email as string) : true;
  }, [onboardingSetup.email, step]);

  const isGoalValid = useMemo(
    () => (step === 2 ? !!onboardingSetup.goal?.goals?.length : true),
    [onboardingSetup.goal?.goals, step]
  );

  const onNextStep = useCallback(() => {
    if (isEmailValid) {
      setStep(step + 1);
    } else {
      setDisableNext(true);
    }
  }, [step, isEmailValid]);

  const onPrevStep = useCallback(() => {
    setDisableNext(false);
    setStep(step - 1);
  }, [step]);

  const hadnleUpdateSetup = useCallback(
    (
      field: keyof CompleteOnboardingRequestDto,
      data: CompleteOnboardingRequestDto[keyof CompleteOnboardingRequestDto]
    ) => {
      setDisableNext(false);
      setOnboardingSetup({ ...onboardingSetup, [field]: data });
    },
    [onboardingSetup]
  );

  const toggleWidgetInstallation = useCallback(
    () => setInstallationModalOpen(!installationModalOpen),
    [installationModalOpen]
  );

  useEffect(() => {
    if (onboardingInitialData) {
      setOnboardingSetup(onboardingInitialData);
    }
  }, [onboardingInitialData]);

  return (
    <div className='Onboarding'>
      <Page>
        {!isEmpty(onboardingSetup) ? (
          <Card padding='600'>
            <InlineStack align='center'>
              <Box width='70%'>
                {step === 1 && <WelcomeStep onNextStep={onNextStep} />}
                {step === 2 && (
                  <GoalStep
                    goal={onboardingSetup.goal}
                    setGoal={(data) => hadnleUpdateSetup('goal', data)}
                  />
                )}
                {step === 3 && (
                  <EmailStep
                    email={onboardingSetup.email}
                    emailValid={!disableNext}
                    subscribe={onboardingSetup.subscribeToNewsletter}
                    setSubscribe={(value) =>
                      hadnleUpdateSetup('subscribeToNewsletter', value)
                    }
                    setEmail={(value) => hadnleUpdateSetup('email', value)}
                  />
                )}
                {step === 4 && (
                  <BrandColorsStep
                    brandColors={onboardingSetup.brandColors}
                    setBrandColors={(value) =>
                      hadnleUpdateSetup('brandColors', value)
                    }
                  />
                )}
                {step === 5 && <WidgetsIntroStep />}
                {step === 6 && (
                  <WidgetInstallationStep
                    onNextStep={onNextStep}
                    isWidgetRequestSent={isWidgetRequestSent}
                  />
                )}
                {step === 7 && (
                  <FinalStep
                    onboardingSetup={onboardingSetup}
                    finishOnboarding={finishOnboarding}
                  />
                )}

                {showStepButtons && (
                  <Box paddingBlock='400'>
                    <InlineStack align='end'>
                      <ButtonGroup>
                        <Button onClick={onPrevStep}>
                          {i18n.translate('Back')}
                        </Button>
                        <Button
                          disabled={disableNext || !isGoalValid}
                          onClick={
                            step === 6 && !isWidgetRequestSent
                              ? toggleWidgetInstallation
                              : onNextStep
                          }
                          variant='primary'
                        >
                          {i18n.translate(
                            step === 6 && !isWidgetRequestSent
                              ? 'InstallWidgets'
                              : step === 6 && isWidgetRequestSent
                              ? 'FinishConfiguration'
                              : 'Next'
                          )}
                        </Button>
                      </ButtonGroup>
                    </InlineStack>
                  </Box>
                )}
              </Box>
            </InlineStack>
            <Modal
              title={i18n.translate('RequestWidgetInstallationSupport')}
              open={installationModalOpen}
              onClose={toggleWidgetInstallation}
            >
              <div className='WidgetInstallationModalContent'>
                <RequestWidgetSupport
                  onClose={toggleWidgetInstallation}
                  setIsWidgetRequestSent={setIsWidgetRequestSent}
                />
              </div>
            </Modal>
          </Card>
        ) : (
          <CardsSkeleton cardsCount={1} size='large' />
        )}
      </Page>
    </div>
  );
};
