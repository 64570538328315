import React, { useState, useCallback, useMemo } from 'react';
import { Editor } from '@tiptap/react';
import '../RichTextEditor.scss';
import { ToolbarButton } from '../ToolbarButton';
import { linkToolbar } from '../assets/iconsToolbar';
import { Button, ButtonGroup, Popover, TextField } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';

export const LinkToolbarElement: React.FC<{
  editor: Editor;
  disabled: boolean;
}> = (props) => {
  const { editor, disabled } = props;

  const [i18n] = useI18n();

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');

  const toggleDropdown = useCallback(
    () => setIsDropdownOpen((active) => !active),
    []
  );

  const openBtnPopover = useCallback(() => {
    editor.chain().focus();
    setUrl(editor.getAttributes('link').href);
    toggleDropdown();
  }, [editor, setUrl, toggleDropdown]);

  const closeBtnPopover = useCallback(() => {
    toggleDropdown();
    setUrl('');
  }, [toggleDropdown, setUrl]);

  const saveLink = useCallback(() => {
    if (url) {
      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url, target: '_blank' })
        .run();
    } else {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
    }
    closeBtnPopover();
  }, [url, editor, closeBtnPopover]);

  const removeLink = useCallback(() => {
    editor.chain().focus().extendMarkRange('link').unsetLink().run();
    closeBtnPopover();
  }, [editor, closeBtnPopover]);

  const activator = useMemo(
    () => (
      <ToolbarButton
        name='strike'
        icon={linkToolbar(editor.isActive('link'))}
        active={editor.isActive('link')}
        onClick={openBtnPopover}
        disabled={disabled}
      />
    ),
    [editor.isActive('link'), openBtnPopover, linkToolbar, disabled]
  );

  return (
    <Popover
      active={isDropdownOpen}
      activator={activator}
      onClose={closeBtnPopover}
    >
      <Popover.Section>
        <div className='linkPopover'>
          <TextField
            type='url'
            label={i18n.translate('Label')}
            value={url}
            onChange={(value) => setUrl(value)}
            autoComplete='off'
            autoFocus
          />
          <ButtonGroup>
            <Button size='slim' onClick={removeLink}>
              {i18n.translate('Discard')}
            </Button>
            <Button size='slim' onClick={saveLink} variant='primary'>
              {i18n.translate('Save')}
            </Button>
          </ButtonGroup>
        </div>
      </Popover.Section>
    </Popover>
  );
};
