import React, { useCallback, useMemo } from 'react';
import {
  Badge,
  Bleed,
  BlockStack,
  Box,
  Divider,
  Icon,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  PlanDiscountDto,
  PlanDtoRead,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  PlanFeatureDtoEnum,
  PlanIntervalDtoEnum,
  PlanThresholdTypeDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import { CheckSmallIcon, XSmallIcon } from '@shopify/polaris-icons';
import './PlanComparisonCard.scss';
import { DiagonalArrowIcon } from '../../assets/icons';
import { formatNumber } from 'core/utils';
import classNames from 'classnames';
import { ChangePlanModalTypeEnum } from '../../ChangePlanModal';
type PlanComparisonCardProps = {
  planData: PlanDtoRead | null;
  comparedPlan: PlanDtoRead;
  interval: PlanIntervalDtoEnum;
  discount?: PlanDiscountDto | null;
  isSelected?: boolean;
  isFreeTrial: boolean;
  isDifferentThreshold: boolean;
  modalType: ChangePlanModalTypeEnum;
  highlightedFeatures?: PlanFeatureDtoEnum[];
};
export const PlanComparisonCard: React.FC<PlanComparisonCardProps> = ({
  planData,
  comparedPlan,
  interval,
  discount,
  isSelected,
  isFreeTrial,
  isDifferentThreshold,
  modalType,
  highlightedFeatures,
}) => {
  const [i18n] = useI18n();
  const { pricing, threshold } = planData || {};

  const isAnnual = useMemo(
    () =>
      isSelected
        ? planData?.isCurrentAnnually
        : interval === PlanIntervalDtoEnum.ANNUAL,
    [interval, isSelected, planData?.isCurrentAnnually]
  );

  const isDiscounted = useMemo(
    () => !!(pricing?.intervalPrices?.discounted && discount),
    [pricing?.intervalPrices?.discounted, discount]
  );

  const isSessionThreshold = useMemo(
    () => planData?.thresholdType === PlanThresholdTypeDtoEnum.SESSION,
    [planData?.thresholdType]
  );

  const comparedFeatures = useMemo(
    () =>
      comparedPlan?.features?.filter(
        (item) => !planData?.features?.includes(item)
      ),
    [comparedPlan?.features, planData?.features]
  );
  const currentFeatures = useMemo(
    () =>
      planData?.features?.filter(
        (item) => !comparedPlan?.features?.includes(item)
      ),
    [comparedPlan?.features, planData?.features]
  );

  const showFeatureList = useMemo(
    () =>
      !!(comparedFeatures?.length || currentFeatures?.length) &&
      !isFreeTrial &&
      !isDifferentThreshold,
    [comparedFeatures, currentFeatures, isFreeTrial, isDifferentThreshold]
  );

  const getAdvantageRow = useCallback(
    (content: React.ReactNode, currentValue: number, comparedValue: number) => {
      const direction =
        currentValue === comparedValue
          ? 'base'
          : currentValue > comparedValue || !currentValue
          ? 'up'
          : 'down';
      return (
        <InlineStack wrap={false} align='start' blockAlign='start'>
          {isSelected || isDifferentThreshold || direction === 'base' ? (
            <Box>
              <Icon tone={'base'} source={CheckSmallIcon} />
            </Box>
          ) : (
            <DiagonalArrowIcon
              color={
                direction === 'up'
                  ? 'var(--p-color-text-success)'
                  : 'var(--p-color-text-critical)'
              }
              direction={direction}
            />
          )}
          <Text
            as='p'
            tone={
              isSelected || isDifferentThreshold || direction === 'base'
                ? 'subdued'
                : direction === 'up'
                ? 'success'
                : 'critical'
            }
          >
            {content}
          </Text>
        </InlineStack>
      );
    },
    [isSelected, isDifferentThreshold]
  );

  const featureList = useMemo(() => {
    const isUpgrading = !!currentFeatures?.length;
    const list = isUpgrading ? currentFeatures : comparedFeatures;
    return list?.map((value) => {
      const isNinjaPro = value === PlanFeatureDtoEnum.NINJA_CART_PRO;
      return (
        <Box
          padding='050'
          key={value}
          borderRadius='100'
          background={
            highlightedFeatures?.includes(value as PlanFeatureDtoEnum)
              ? 'bg-surface-success'
              : 'bg-surface'
          }
        >
          <InlineStack align='start'>
            {isNinjaPro && !isSelected ? (
              <DiagonalArrowIcon
                color={
                  isUpgrading
                    ? 'var(--p-color-text-success)'
                    : 'var(--p-color-text-critical)'
                }
                direction={isUpgrading ? 'up' : 'down'}
              />
            ) : (
              <Box>
                <Icon
                  tone={
                    (isNinjaPro && isSelected) || (isSelected && isUpgrading)
                      ? 'subdued'
                      : isUpgrading
                      ? 'textSuccess'
                      : 'textCritical'
                  }
                  source={
                    isUpgrading || isNinjaPro ? CheckSmallIcon : XSmallIcon
                  }
                />
              </Box>
            )}
            <Text
              as='p'
              tone={
                (isNinjaPro && isSelected) || (isSelected && isUpgrading)
                  ? 'subdued'
                  : isUpgrading
                  ? 'success'
                  : 'critical'
              }
            >
              {i18n.translate(
                `${value}_${isUpgrading ? 'AVAILABLE' : 'UNAVAILABLE'}`
              )}
            </Text>
          </InlineStack>
        </Box>
      );
    });
  }, [comparedFeatures, currentFeatures, highlightedFeatures]);

  const freeFeatures = useMemo(() => {
    const features = Array.from({ length: 4 }, (_, i) => `FreeFeature${i + 1}`);
    return features.map((value, index) => {
      const unavailable = index === 2 || index === 3;
      return (
        <InlineStack key={value} align='start'>
          <Box>
            <Icon
              tone={unavailable ? 'textCritical' : 'base'}
              source={unavailable ? XSmallIcon : CheckSmallIcon}
            />
          </Box>
          <Text as='p' tone={unavailable ? 'critical' : 'subdued'}>
            {i18n.translate(value)}
          </Text>
        </InlineStack>
      );
    });
  }, []);

  return (
    <div
      className={classNames('PlanComparisonCard', {
        isSelected: isSelected,
      })}
    >
      <Box paddingInline='400' paddingBlock='400'>
        <BlockStack gap='400'>
          {isDifferentThreshold && (
            <Bleed marginBlockStart='400' marginInline='400'>
              <Box
                borderStartEndRadius='300'
                borderStartStartRadius='300'
                paddingInline='400'
                paddingBlock='200'
                background={
                  isSessionThreshold
                    ? 'bg-surface-secondary'
                    : 'bg-surface-info'
                }
              >
                <Text
                  tone={isSessionThreshold ? 'subdued' : 'inherit'}
                  as='p'
                  fontWeight='medium'
                  alignment='center'
                >
                  {i18n.translate(`${planData?.thresholdType || 'ORDER'}`)}
                </Text>
              </Box>
            </Bleed>
          )}
          <InlineStack gap='200' align='start'>
            <Text as='h2' fontWeight='semibold' variant='headingLg'>
              {planData?.name ? planData.name : i18n.translate('NoPlan')}
            </Text>
            {isSelected && <Badge>{i18n.translate('Current')}</Badge>}
            {!isSelected &&
              modalType === ChangePlanModalTypeEnum.CHANGE_BILLING_CYCLE && (
                <Badge tone='success'>
                  {i18n.translate('SaveYearAmount', {
                    amount: planData?.pricing?.intervalPrices?.saving || '',
                  })}
                </Badge>
              )}
          </InlineStack>
          {planData && (
            <BlockStack>
              <Text as='h1' id='PlanMonthPrice' variant='heading2xl'>
                <Text
                  as='span'
                  tone={isDiscounted ? 'subdued' : 'base'}
                  fontWeight={isDiscounted ? 'regular' : 'bold'}
                  textDecorationLine={isDiscounted ? 'line-through' : undefined}
                >
                  $
                  {isAnnual
                    ? pricing?.intervalPrices?.regular?.annualPlanPerYearPrice
                    : pricing?.intervalPrices?.regular
                        ?.monthlyPlanPerMonthPrice}
                </Text>
                {isDiscounted && (
                  <Text as='span' tone='critical'>
                    $
                    {isAnnual
                      ? pricing?.intervalPrices?.discounted
                          ?.annualPlanPerYearPrice
                      : pricing?.intervalPrices?.discounted
                          ?.monthlyPlanPerMonthPrice}
                  </Text>
                )}

                <Text as='span' tone='subdued' variant='bodySm'>
                  {i18n.translate(isAnnual ? 'USDYear' : 'USDMonth')}
                </Text>
              </Text>
              <Text as='p' tone='subdued'>
                {i18n.translate(isAnnual ? 'BilledAnnually' : 'BilledMonthly')}
              </Text>
            </BlockStack>
          )}

          {!planData && (
            <Box paddingBlockEnd='500'>
              <Text as='h1' id='PlanMonthPrice' variant='heading2xl'>
                {i18n.translate('Free')}
              </Text>
            </Box>
          )}

          {showFeatureList && (
            <>
              <Divider />
              <BlockStack gap='100'>{featureList}</BlockStack>
            </>
          )}

          <Divider />
          <BlockStack gap='100'>
            {planData && (
              <>
                {getAdvantageRow(
                  i18n.translate('MaxPublishedPromotions', {
                    amount: planData?.publishedPromotionsLimit,
                    publishedPromotions: (
                      <u>{i18n.translate('PublishedPromotions')}</u>
                    ),
                  }),
                  planData.publishedPromotionsLimit || 0,
                  comparedPlan.publishedPromotionsLimit || 0
                )}
                {getAdvantageRow(
                  i18n.translate('OrdersPerPeriod', {
                    amount: formatNumber(
                      i18n,
                      isAnnual ? threshold?.perYear : threshold?.perMonth
                    ),
                    period: i18n.translate(isAnnual ? 'Year' : 'Month'),
                    thresholdType: (
                      <u>
                        {i18n.translate(
                          isSessionThreshold ? 'Sessions' : 'DiscountedOrders'
                        )}
                      </u>
                    ),
                  }),
                  planData?.threshold?.perMonth || 0,
                  comparedPlan.threshold?.perMonth || 0
                )}
                {!isSessionThreshold &&
                  getAdvantageRow(
                    i18n.translate('PerAdditionalOrder', {
                      amount: isAnnual
                        ? pricing?.intervalPrices?.regular
                            ?.annualOverageUnitPrice
                        : pricing?.intervalPrices?.regular
                            ?.monthlyOverageUnitPrice,
                    }),
                    comparedPlan.pricing?.intervalPrices?.regular
                      ?.monthlyOverageUnitPrice || 0,
                    planData?.pricing?.intervalPrices?.regular
                      ?.monthlyOverageUnitPrice || 0
                  )}
              </>
            )}
            {!planData && freeFeatures}
          </BlockStack>
        </BlockStack>
      </Box>
      {isFreeTrial && !isSelected && (
        <Box
          paddingInline='400'
          paddingBlock='300'
          background='bg-fill-secondary'
          borderEndEndRadius='300'
          borderEndStartRadius='300'
        >
          <Text as='p' tone='subdued' fontWeight='medium'>
            {i18n.translate('RiskFree')}
          </Text>
        </Box>
      )}
    </div>
  );
};
