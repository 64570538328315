import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import './SmartTagSettings.scss';
import { Box, Button, ButtonGroup, InlineStack, Text } from '@shopify/polaris';
import { XIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import { SmartTagGroupEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import SmartTagPriceSettings from './components/SmartTagPriceSettings/SmartTagPriceSettings';
import { useAppDispatch } from 'core/hooks';
import { setIsSmartTagConfigOpen } from 'core/store/widgetsSlice';
import { debounce, isEqual } from 'lodash';
import { SmartTagCountdownClockSettings } from './components/SmartTagCountdownClockSettings/SmartTagCountdownClockSettings';

export type SmartTagSettingsTypeDto = {
  category: SmartTagGroupEnum;
  [key: string]: any;
};

type SmartTagSettingsProps = {
  attrs: SmartTagSettingsTypeDto;
  onClose: () => void;
  onUpdate: (data: SmartTagSettingsTypeDto) => void;
  onDelete: () => void;
};

const SmartTagSettings: React.FC<SmartTagSettingsProps> = ({
  attrs,
  onClose,
  onUpdate,
  onDelete,
}) => {
  const dispatch = useAppDispatch();
  const [i18n] = useI18n();

  const isSaved = useRef<boolean>(false);
  const [config, setConfig] = useState<SmartTagSettingsTypeDto>(attrs);

  const handleSave = useCallback(
    debounce(() => {
      if (!isSaved.current) {
        onUpdate(attrs);
      }
    }, 300),
    [isSaved.current]
  );

  const handleSaveAndClose = useCallback(() => {
    handleSave();
    onClose();
  }, [handleSave]);

  const handleDeleteSmartTag = useCallback(() => {
    isSaved.current = true;
    onDelete();
    handleSaveAndClose();
  }, [onDelete]);

  const handleSaveConfig = useCallback(() => {
    isSaved.current = true;
    handleSaveAndClose();
  }, []);

  useEffect(() => {
    dispatch(setIsSmartTagConfigOpen(true));
    return () => {
      dispatch(setIsSmartTagConfigOpen(false));
      handleSaveAndClose();
    };
  }, []);

  useEffect(() => {
    if (attrs && config && !isEqual(config, attrs)) {
      onUpdate(config);
    }
  }, [config]);

  const settingsVariant = useMemo(() => {
    switch (attrs.category) {
      case SmartTagGroupEnum.PRICE:
        return <SmartTagPriceSettings config={config} setConfig={setConfig} />;
      case SmartTagGroupEnum.TIME:
        return (
          <SmartTagCountdownClockSettings
            config={config}
            setConfig={setConfig}
          />
        );
    }
  }, [attrs]);

  return (
    <div className='SmartTagSettings'>
      <Box
        borderBlockEndWidth='025'
        borderColor='border-brand'
        paddingBlock='400'
        paddingInline='500'
      >
        <InlineStack align='space-between' blockAlign='center'>
          <Text as='h2' fontWeight='bold'>
            {i18n.translate('EditSmartTag')}
          </Text>
          <Button
            icon={XIcon}
            variant='tertiary'
            onClick={handleSaveAndClose}
          />
        </InlineStack>
      </Box>
      <div className='SmartTagSettingsContent'>{settingsVariant}</div>
      <div className='SmartTagSettingsFooter'>
        <InlineStack align='space-between' blockAlign='center'>
          <Button
            onClick={handleDeleteSmartTag}
            tone='critical'
            variant='plain'
          >
            {i18n.translate('Delete')}
          </Button>
          <ButtonGroup>
            <Button onClick={handleSaveAndClose} variant='secondary'>
              {i18n.translate('Cancel')}
            </Button>
            <Button onClick={handleSaveConfig} variant='primary'>
              {i18n.translate('Save')}
            </Button>
          </ButtonGroup>
        </InlineStack>
      </div>
    </div>
  );
};

export default SmartTagSettings;
