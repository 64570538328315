import React, { useMemo } from 'react';
import {
  Badge,
  BlockStack,
  Card,
  ChoiceList,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { StrikethroughPricingFreeGiftsQuantitySpinnerModeDto } from 'core/api/adminSettings/adminSettingsApi';
import { StrikethroughPricingFreeGiftsQuantitySpinnerModeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { Element } from 'react-scroll';

type SpinnerModeSettingProps = {
  spinnerMode: StrikethroughPricingFreeGiftsQuantitySpinnerModeDto;
  setSpinnerMode: (
    value: StrikethroughPricingFreeGiftsQuantitySpinnerModeDto
  ) => void;
};
export const SpinnerModeSetting: React.FC<SpinnerModeSettingProps> = ({
  spinnerMode,
  setSpinnerMode,
}) => {
  const [i18n] = useI18n();

  const spinnerModeOptions = useMemo(
    () =>
      Object.values(
        StrikethroughPricingFreeGiftsQuantitySpinnerModeDtoEnum
      ).map((value) => ({
        label: (
          <InlineStack gap='200'>
            {i18n.translate(`${value}`)}
            {value ===
              StrikethroughPricingFreeGiftsQuantitySpinnerModeDtoEnum.VISIBLE_AND_ENABLED && (
              <Badge>{i18n.translate('ThemeDefault')}</Badge>
            )}
          </InlineStack>
        ),
        value: value,
      })),
    [i18n]
  );

  return (
    <Element name='SpinnerModeSetting' className='SpinnerModeSetting'>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('QuantitySpinner')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('Subtitle')}
            </Text>
          </BlockStack>
          <ChoiceList
            title=''
            choices={spinnerModeOptions}
            selected={[
              spinnerMode as StrikethroughPricingFreeGiftsQuantitySpinnerModeDtoEnum,
            ]}
            onChange={(value) =>
              setSpinnerMode(
                value[0] as StrikethroughPricingFreeGiftsQuantitySpinnerModeDto
              )
            }
          />
        </BlockStack>
      </Card>
    </Element>
  );
};
