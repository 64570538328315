import React, { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector, useIsDebugOrLocal } from 'core/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, TitleBar } from '@shopify/app-bridge-react';
import { useI18n } from '@shopify/react-i18n';
import { Modal as PolarisModal } from '@shopify/polaris';
import { SelectNewOffer } from 'features/promotions/components';
import { setResetTemplate } from 'core/store/offersWizardSlice';

export enum SelectOfferModalTypeEnum {
  CREATE_NEW = 'create-new',
  CHANGE_TEMPLATE = 'change-template',
}

export interface MessageEventData {
  type: string;
  offerId: string;
}
type SelectOfferPageProps = {
  modalType: SelectOfferModalTypeEnum;
  isOpen: boolean;
  onClose: () => void;
};
export const SelectOfferPage: React.FC<SelectOfferPageProps> = ({
  modalType,
  isOpen,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [i18n] = useI18n();
  const isDebugOrLocal = useIsDebugOrLocal();

  const {
    lastSelectedOffer,
    selectedOfferType: globalSelectedOfferType,
    resetTemplate,
  } = useAppSelector((stores) => stores.offersWizard);

  const showMaxModal = useMemo(() => {
    return !isDebugOrLocal;
  }, [isDebugOrLocal]);

  const sendMessageToModal = (message: any) => {
    const iframe = document.getElementById(
      'selectOfferModal'
    ) as HTMLIFrameElement | null;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(message, window.location.origin);
    }
  };

  useEffect(() => {
    function handleMessageFromModal(ev: MessageEvent) {
      const { type } = ev.data as MessageEventData;
      if (type === 'MAX_MODAL_RENDERED') {
        sendMessageToModal({
          type: 'STATES_UPDATE',
          params,
          lastSelectedOffer,
          globalSelectedOfferType,
        });
      }
      if (type === 'NAVIGATE_TO_OFFER') {
        onClose();
        navigate(`create-offer-template/${ev.data.offerId}`);
      }
      if (type === 'NAVIGATE_TO_LAST_SELECTED_OFFER') {
        onClose();
      }
      if (type === 'CHANGE_OFFER_TEMPLATE') {
        dispatch(setResetTemplate(resetTemplate + 1));
        onClose();
      }
    }

    showMaxModal && window.addEventListener('message', handleMessageFromModal);

    return () => {
      showMaxModal &&
        window.removeEventListener('message', handleMessageFromModal);
    };
  }, [params]);

  useEffect(() => {
    !isDebugOrLocal && localStorage.setItem('selectOfferModalType', modalType);
  }, [isDebugOrLocal, modalType]);

  return (
    <>
      {!isDebugOrLocal ? (
        <Modal
          id='selectOfferModal'
          open={isOpen}
          onHide={onClose}
          variant='max'
          src='/modals/selectOffer.html'
        >
          <TitleBar title={i18n.translate('DiscountNinja')} />
        </Modal>
      ) : (
        <PolarisModal
          size='fullScreen'
          open={isOpen}
          title=''
          onClose={onClose}
        >
          <SelectNewOffer
            toChangeTemplate={
              modalType === SelectOfferModalTypeEnum.CHANGE_TEMPLATE
            }
          />
        </PolarisModal>
      )}
    </>
  );
};
