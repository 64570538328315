import React, { useCallback, useMemo } from 'react';
import {
  BlockStack,
  Box,
  Button,
  ButtonGroup,
  InlineStack,
  Modal,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { CheckFilledIcon, EmptyCheckIcon } from './assets/icons';
import { RefreshIcon } from '@shopify/polaris-icons';
import { useSearchParams } from 'react-router-dom';
import { PromotionEngineAndPlanCheckResponseDto } from 'core/api/adminSettings/adminSettingsApi';

type FinishConfigurationModalProps = {
  isOpen: boolean;
  engineAndPlanCheckData?: PromotionEngineAndPlanCheckResponseDto;
  engineAndPlanCheckIsFetching: boolean;
  refetchEngineAndPlanCheck: () => void;
  setCheckConfiguration: (value: boolean) => void;
  onClose: () => void;
  onPublishPromiton: () => Promise<void>;
};
export const FinishConfigurationModal: React.FC<
  FinishConfigurationModalProps
> = ({
  isOpen,
  engineAndPlanCheckData,
  engineAndPlanCheckIsFetching,
  refetchEngineAndPlanCheck,
  onPublishPromiton,
  onClose,
  setCheckConfiguration,
}) => {
  const [i18n] = useI18n();
  const [, setSearchParams] = useSearchParams();
  const isPromotionEngineEnabled =
    engineAndPlanCheckData?.promotionEngine?.enabled;
  const isActiveSubscriptionInPlace =
    engineAndPlanCheckData?.isActiveSubscriptionInPlace;

  const completedSteps = useMemo(() => {
    return (
      Number(isPromotionEngineEnabled) + Number(isActiveSubscriptionInPlace)
    );
  }, [isPromotionEngineEnabled, isActiveSubscriptionInPlace]);

  const handleOpenThemeEditor = useCallback(() => {
    setCheckConfiguration(true);
    window.open(
      engineAndPlanCheckData?.promotionEngine?.installationDeepLink || '',
      '_blank'
    );
  }, [engineAndPlanCheckData?.promotionEngine?.installationDeepLink]);

  const handleOpenPlans = useCallback(() => {
    setSearchParams({
      modal: 'settings',
      path: 'plan',
      subPath: 'pick-your-plan',
    });
  }, []);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={i18n.translate('FinishConfiguration')}
      primaryAction={{
        content: i18n.translate('PublishPromotion'),
        onAction: () => {
          onPublishPromiton();
          onClose();
        },
        loading: engineAndPlanCheckIsFetching,
        disabled: !isActiveSubscriptionInPlace || !isPromotionEngineEnabled,
      }}
      secondaryActions={[
        { content: i18n.translate('Cancel'), onAction: onClose },
      ]}
    >
      <Modal.Section>
        <BlockStack gap='400'>
          <BlockStack inlineAlign='start' gap='300'>
            <Text as='p'>{i18n.translate('ToPublishYouNeed')}</Text>
            <Box
              borderColor='border'
              borderWidth='025'
              borderRadius='200'
              paddingInline='200'
            >
              <Text as='p'>
                {i18n.translate('CompletedCount', { count: completedSteps })}
              </Text>
            </Box>
          </BlockStack>
          <Box
            borderColor='border'
            borderWidth='025'
            borderRadius='200'
            paddingInline='200'
            paddingBlock='300'
            background={
              !isPromotionEngineEnabled ? 'bg-surface-active' : 'bg-surface'
            }
          >
            <InlineStack align='start' gap='200' wrap={false}>
              <div style={{ maxHeight: 20 }}>
                {isPromotionEngineEnabled ? (
                  <CheckFilledIcon />
                ) : (
                  <EmptyCheckIcon />
                )}
              </div>

              <BlockStack gap='400' inlineAlign='start'>
                <BlockStack gap='100'>
                  <Text as='p' fontWeight='medium'>
                    {i18n.translate('ToggleEngine')}
                  </Text>
                  {!isPromotionEngineEnabled && (
                    <Text tone='subdued' as='p'>
                      {i18n.translate('ToggleEngineHelp')}
                    </Text>
                  )}
                </BlockStack>
                {!isPromotionEngineEnabled && (
                  <ButtonGroup>
                    <Button onClick={handleOpenThemeEditor} variant='primary'>
                      {i18n.translate('OpenThemeEditor')}
                    </Button>
                    <Button
                      icon={RefreshIcon}
                      onClick={refetchEngineAndPlanCheck}
                      disabled={engineAndPlanCheckIsFetching}
                    >
                      {i18n.translate('Reload')}
                    </Button>
                  </ButtonGroup>
                )}
              </BlockStack>
            </InlineStack>
          </Box>
          <Box
            borderColor='border'
            borderWidth='025'
            borderRadius='200'
            paddingInline='200'
            paddingBlock='300'
            background={
              !isActiveSubscriptionInPlace && isPromotionEngineEnabled
                ? 'bg-surface-active'
                : 'bg-surface'
            }
          >
            <InlineStack align='start' gap='200' wrap={false}>
              <div style={{ maxHeight: 20 }}>
                {isActiveSubscriptionInPlace ? (
                  <CheckFilledIcon />
                ) : (
                  <EmptyCheckIcon />
                )}
              </div>

              <BlockStack gap='400' inlineAlign='start'>
                <BlockStack gap='100'>
                  <Text as='p' fontWeight='medium'>
                    {i18n.translate('SelectPlan')}
                  </Text>
                  {!isActiveSubscriptionInPlace && isPromotionEngineEnabled && (
                    <Text tone='subdued' as='p'>
                      {i18n.translate('SelectPlanHelp')}
                    </Text>
                  )}
                </BlockStack>
                {!isActiveSubscriptionInPlace && isPromotionEngineEnabled && (
                  <Button onBlur={handleOpenPlans} variant='primary'>
                    {i18n.translate('ExplorePlans')}
                  </Button>
                )}
              </BlockStack>
            </InlineStack>
          </Box>
        </BlockStack>
      </Modal.Section>
    </Modal>
  );
};
