import { v4 as uuidv4 } from 'uuid';
import {
  mergeWith,
  isUndefined,
  cloneDeep,
  isEmpty,
  merge,
  isArray,
  every,
  isString,
  isPlainObject,
} from 'lodash';
import {
  PageTypeDtoEnum,
  WidgetTypeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import { PageSetupEntryDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { PriceWidgetTypesDtoEnum } from '../components/RightSideBarWrapper/RightBarTypes/EditPresetRightBar/EditPresetRightBar';

export interface LeftMenuOption {
  [key: string]: {
    id: string;
    isSelected?: boolean;
    isHidden?: boolean;
    canBeDragged?: boolean;
    canBeHidden?: boolean;
    canBeRemoved?: boolean;
    isOpen?: boolean;
    options?: LeftMenuOption;
    currentEntries?: string[];
    availableEntries?: string[];
    key?: string;
    supportsModules?: boolean | null;
    page?: PageTypeDtoEnum;
  };
}

export interface LeftMenuWidget {
  name: string;
  offerId?: string;
  id?: string;
  isHidden: boolean;
  isDraggable: boolean;
  canBeHidden: boolean;
  canBeRemoved: boolean;
  isSelected: boolean;
  isOpen?: boolean;
  parentKey?: string | null;
  key?: string;
  currentEntries?: string[];
  availableEntries?: string[];
  options?: LeftMenuWidget[];
  supportsModules?: boolean | null;
  page?: PageTypeDtoEnum;
}

export interface LeftMenuOffers {
  [field: string]: LeftMenuWidget[];
}

const fullWidgetsList = Object.values(WidgetTypeDtoEnum);

export const transformEnum = (str: string, joinSymb: string): string => {
  return str
    .split('_')
    .map((part, index) =>
      index === 0
        ? part.toLowerCase()
        : part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
    )
    .join(joinSymb);
};

export const transformWidgetsList = (
  inputArray: (string | null)[]
): string[] => {
  return inputArray
    .filter((item): item is string => item !== null)
    .map((str) => transformEnum(str, ''));
};

const processOptions = (
  options: LeftMenuOption | undefined,
  currentEntries: string[] | undefined,
  parentIsHidden: boolean
): LeftMenuWidget[] => {
  const processedOptions = options
    ? Object.keys(options).map((subKey) => {
        if (
          subKey !== 'key' &&
          currentEntries &&
          transformWidgetsList(currentEntries)?.includes(subKey)
        ) {
          const option = options[subKey];
          const nestedOptions = option?.options
            ? processOptions(
                option.options,
                option.currentEntries,
                option.isHidden === true || parentIsHidden
              )
            : [];
          return {
            name: subKey,
            id: option?.id || uuidv4(),
            isSelected:
              option?.isHidden === true || parentIsHidden
                ? false
                : option?.isSelected
                ? option?.isSelected
                : false,
            isHidden:
              parentIsHidden || option?.isHidden === true ? true : false,
            isDraggable: option?.canBeDragged || false,
            canBeHidden: option?.canBeHidden || false,
            canBeRemoved: option?.canBeRemoved || false,
            ...(option?.supportsModules && {
              supportsModules: option?.supportsModules,
            }),
            ...(option?.page && { page: option?.page }),
            ...(Object.prototype.hasOwnProperty.call(
              option,
              'currentEntries'
            ) && { currentEntries: option.currentEntries }),
            ...(Object.prototype.hasOwnProperty.call(
              option,
              'availableEntries'
            ) && { availableEntries: option.availableEntries }),
            ...(nestedOptions.length && { isOpen: option.isOpen || false }),
            ...(nestedOptions.length && { options: nestedOptions }),
            ...(option?.key && { key: option?.key }),
          };
        }
      })
    : [];
  return processedOptions.length > 0
    ? (processedOptions.filter(Boolean) as LeftMenuWidget[])
    : [];
};

export const getGlobalCurrentEntries = (
  data?: PageSetupEntryDto[] | null
): string[] => {
  const globalCurrentEntries: string[] = [];

  if (!data || !data.length) return globalCurrentEntries;

  data.forEach((item) => {
    if (item.currentEntries) {
      const nonNullEntries = item.currentEntries.filter(
        (entry) => typeof entry === 'string'
      );
      globalCurrentEntries.push(...(nonNullEntries as string[]));
    }
  });

  return globalCurrentEntries;
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const generateLeftMenu = (input: any): LeftMenuWidget[] => {
  const notSupportedWidgets: string[] = transformWidgetsList(
    fullWidgetsList
  ).filter(
    (element) =>
      !transformWidgetsList(getGlobalCurrentEntries(input.pages)).includes(
        element
      )
  );
  return Object.entries(input)
    .map(([key, value]) => {
      if (
        key === 'id' ||
        key === 'name' ||
        key === 'supportedWidgets' ||
        key === 'title' ||
        key === 'offers' ||
        key === 'defaultLanguage' ||
        key === 'pages' ||
        key === 'isGlobal' ||
        key === 'presetSetup' ||
        key === 'presets' ||
        key === 'isShopLevel' ||
        value === null ||
        notSupportedWidgets.includes(key)
      ) {
        return null;
      }

      const section = input[key];
      const options = processOptions(
        section.options,
        section.currentEntries,
        !!section.isHidden
      );

      const sectionOutput: LeftMenuWidget = {
        name: key,
        id: section.id || uuidv4(),
        isHidden: section.isHidden || false,
        isDraggable: section.canBeDragged || false,
        canBeHidden: section.canBeHidden || false,
        canBeRemoved: section.canBeRemoved || false,
        ...(section.supportsModules && {
          supportsModules: section.supportsModules,
        }),
        ...(section.page && { page: section.page }),
        isSelected: section.isHidden
          ? false
          : section.isSelected
          ? section.isSelected
          : false,
        ...(Object.prototype.hasOwnProperty.call(section, 'currentEntries') && {
          currentEntries: section.currentEntries,
        }),
        ...(Object.prototype.hasOwnProperty.call(
          section,
          'availableEntries'
        ) && { availableEntries: section.availableEntries }),
        ...(options.length && { isOpen: section.isOpen || false }),
        ...(section?.key && { key: section?.key }),
      };

      if (options.length > 0) {
        sectionOutput.options = options;
      }

      return sectionOutput;
    })
    .filter((section) => section !== null) as LeftMenuWidget[];
};
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const formatOffers = (offers: any) => {
  const formatOptions = (
    options: any,
    currentEntries: string[] | undefined,
    parentIsHidden: boolean
  ): LeftMenuWidget[] => {
    return Object.keys(options)
      .map((key) => {
        if (
          key !== 'key' &&
          currentEntries &&
          transformWidgetsList(currentEntries)?.includes(key)
        ) {
          const option = options[key as keyof typeof options];
          if (option && typeof option !== 'string') {
            const formattedOption: LeftMenuWidget = {
              name: key,
              id: option.id || uuidv4(),
              isSelected:
                option.isHidden === true || parentIsHidden
                  ? false
                  : option?.isSelected
                  ? option?.isSelected
                  : false,
              isHidden:
                option.isHidden === true || parentIsHidden ? true : false,
              isDraggable: option.canBeDragged || false,
              canBeHidden: option.canBeHidden || false,
              canBeRemoved: option.canBeRemoved || false,
              ...(option.supportsModules && {
                supportsModules: option.supportsModules,
              }),
              ...(option.page && { page: option.page }),
              ...(Object.prototype.hasOwnProperty.call(
                option,
                'currentEntries'
              ) && { currentEntries: option.currentEntries }),
              ...(Object.prototype.hasOwnProperty.call(
                option,
                'availableEntries'
              ) && { availableEntries: option.availableEntries }),
            };

            if (option.options && Object.keys(option.options).length > 0) {
              const nestedOptions = formatOptions(
                option.options,
                option.currentEntries,
                !!(option.isHidden === true || parentIsHidden)
              );
              formattedOption.options = nestedOptions;
              formattedOption.isOpen =
                nestedOptions.length > 0 ? option.isOpen || false : false;
            }
            return formattedOption;
          }
        }
        return undefined;
      })
      .filter(
        (formattedOption): formattedOption is LeftMenuWidget =>
          formattedOption !== undefined
      );
  };

  const formatProperty = (property: any): LeftMenuWidget[] => {
    if (!Array.isArray(property)) {
      return [];
    }
    return property
      ?.map((item: any) => {
        const formattedItem: LeftMenuWidget = {
          id: item.id || uuidv4(),
          name: item.title,
          offerId: item.offerId,
          isHidden: item.isHidden || false,
          isDraggable: item.canBeDragged || false,
          canBeHidden: item.canBeHidden || false,
          canBeRemoved: item.canBeRemoved || false,
          isSelected: item.isSelected || false,
          parentKey: item.parentKey || null,
          ...(item.supportsModules && {
            supportsModules: item.supportsModules,
          }),
          ...(item.page && { page: item.page }),
          ...(Object.prototype.hasOwnProperty.call(
            item.setup,
            'currentEntries'
          ) && {
            currentEntries: item.setup.currentEntries,
          }),
          ...(Object.prototype.hasOwnProperty.call(
            item.setup,
            'availableEntries'
          ) && { availableEntries: item.setup.availableEntries }),
        };

        if (
          item?.setup?.options &&
          item?.setup?.options !== null &&
          Object.keys(item?.setup?.options)?.length > 0
        ) {
          const nestedOptions = formatOptions(
            item.setup.options,
            item.setup.currentEntries,
            !!item.isHidden
          );
          formattedItem.options = nestedOptions;
          formattedItem.isOpen =
            nestedOptions.length > 0 ? formattedItem.isOpen || false : false;
        }
        return formattedItem;
      })
      .filter(Boolean);
  };

  const formattedObj: LeftMenuOffers = {};

  Object.keys(offers).forEach((field) => {
    if (field !== 'defaultLanguage') {
      formattedObj[field] = formatProperty(offers[field]);
    }
  });

  return formattedObj;
};

const findElementByName = (
  array: LeftMenuWidget[],
  name: string
): LeftMenuWidget | null => {
  for (const element of array) {
    if (element.name === name) {
      return element;
    }
    if (element.options) {
      const nestedElement = findElementByName(element.options, name);
      if (nestedElement) {
        return nestedElement;
      }
    }
  }
  return null;
};

const findParentOption = (
  options: LeftMenuWidget[],
  parentKey: string
): LeftMenuWidget | undefined => {
  for (const opt of options) {
    if (opt.key === parentKey) {
      return opt;
    }
    if (opt.options) {
      const parentOption = findParentOption(opt.options, parentKey);
      if (parentOption) {
        return parentOption;
      }
    }
  }
};

const addOptionsRecursive = (
  targetArray: LeftMenuWidget[],
  optionsArray: LeftMenuWidget[],
  propertyName: string
) => {
  optionsArray?.forEach((option) => {
    const targetElement = findElementByName(targetArray, propertyName);
    if (targetElement) {
      targetElement.options = targetElement.options || [];

      const existingOption = targetElement.options.find(
        (opt) => opt.name === option.name && opt.id === option.id
      );

      if (existingOption) {
        Object.assign(existingOption, {
          ...option,
          ...(option.options?.length && { options: option.options }),
          ...(option.options?.length && { isOpen: option.isOpen }),
        });
      } else {
        if (option.parentKey === null) {
          targetElement.options.push({
            ...option,
            ...(option.options?.length && { options: option.options }),
            ...(option.options?.length && { isOpen: option.isOpen }),
          });
        } else if (option?.parentKey?.length) {
          const parentOption = findParentOption(
            targetElement.options,
            option.parentKey
          );
          if (parentOption) {
            if (parentOption?.key?.includes('SECONDARY_BANNER')) {
              Object.assign(parentOption, merge({}, parentOption, option));
            } else {
              parentOption.options = parentOption.options || [];
              parentOption.options.push(option);
              parentOption.isOpen = parentOption.isOpen || false;
            }
          }
        }
      }

      if (option.options) {
        addOptionsRecursive(targetElement.options, option.options, option.name);
      }
    }
  });
};

export const addOptionsToInitialArray = (
  array: LeftMenuWidget[],
  optionsObject: LeftMenuOffers | null
) => {
  // Check if optionsObject is null, return the array directly
  if (optionsObject === null) {
    return array;
  }

  const currentOptionsObject = cloneDeep(optionsObject);
  const currentArray = cloneDeep(array);
  Object.keys(currentOptionsObject)?.forEach((property) => {
    addOptionsRecursive(currentArray, currentOptionsObject[property], property);
  });
  return currentArray;
};

export const updateLeftMenu = (
  source: LeftMenuWidget[],
  target: LeftMenuWidget[]
): LeftMenuWidget[] => {
  return target.map((targetItem) => {
    const sourceItem = source.find(
      (item) =>
        item.name === targetItem.name && item.offerId === targetItem.offerId
    );

    if (sourceItem) {
      const mergedOptions = sourceItem.options
        ? updateLeftMenu(sourceItem.options, targetItem.options || [])
        : targetItem.options;

      return {
        ...targetItem,
        isSelected: sourceItem.isSelected,
        isOpen: sourceItem.isOpen,
        options: mergedOptions,
      };
    }

    return targetItem;
  });
};

export const updateLeftMenuSelection = (
  widgets: LeftMenuWidget[],
  selectedId: string | null,
  closeWidget?: boolean
) => {
  // When selectedId === null, all selected elements become false
  return widgets.map((widget: LeftMenuWidget) => {
    const isPriceWidget =
      Object.values(PriceWidgetTypesDtoEnum).includes(
        widget.name as PriceWidgetTypesDtoEnum
      ) && widget.options?.[0].id === selectedId;
    const updatedWidget = {
      ...widget,
      isSelected: selectedId ? widget.id === selectedId : false,
      isOpen: !closeWidget
        ? widget.id === selectedId || isPriceWidget
          ? true
          : widget.isOpen
        : false,
    };
    if (widget.options) {
      updatedWidget.options = updateLeftMenuSelection(
        widget.options,
        selectedId
      );
    }
    return updatedWidget;
  });
};

export const isLeftMenuOptionSelected = (
  widgets: LeftMenuWidget[]
): boolean => {
  for (const widget of widgets) {
    if (widget?.isSelected) {
      return true;
    }
    if (widget?.options && widget?.options?.length > 0) {
      const isAnyOptionSelected = isLeftMenuOptionSelected(widget?.options);
      if (isAnyOptionSelected) {
        return true;
      }
    }
  }
  return false;
};
export const unselectAll = (data: LeftMenuWidget[]): LeftMenuWidget[] => {
  return data.map((item) => {
    const newItem = { ...item, isSelected: false };
    if (item.options) {
      newItem.options = unselectAll(item.options);
    }
    return newItem;
  });
};

const reverseFormatOffers = (formattedOffers: LeftMenuOffers) => {
  const reverseFormatOptions = (
    formattedOptions: LeftMenuWidget[] | undefined
  ) => {
    if (!formattedOptions) {
      return {};
    }

    const options = {};
    formattedOptions.forEach((formattedOption) => {
      const {
        name,
        isHidden,
        isDraggable,
        canBeHidden,
        canBeRemoved,
        currentEntries,
        availableEntries,
        supportsModules,
        page,
        options: nestedOptions,
        isOpen,
      } = formattedOption;

      (options as any)[name] = {
        isHidden,
        canBeDragged: isDraggable,
        ...(supportsModules && { supportsModules }),
        ...(page && { page }),
        canBeHidden,
        canBeRemoved,
        ...(currentEntries && { currentEntries }),
        ...(availableEntries && { availableEntries }),
        ...(nestedOptions?.length && {
          options: reverseFormatOptions(nestedOptions),
        }),
        ...(nestedOptions?.length && {
          isOpen,
        }),
      };
    });

    return options;
  };

  const reverseFormatProperty = (formattedProperty: LeftMenuWidget[]) => {
    return formattedProperty?.map((formattedItem) => {
      const {
        name,
        offerId,
        isHidden,
        isDraggable,
        canBeHidden,
        canBeRemoved,
        parentKey,
        currentEntries,
        availableEntries,
        supportsModules,
        page,
        options,
      } = formattedItem;

      return {
        title: name,
        offerId,
        isHidden,
        canBeDragged: isDraggable,
        canBeHidden,
        canBeRemoved,
        parentKey,
        setup: {
          currentEntries,
          availableEntries,
          supportsModules,
          page,
          options: reverseFormatOptions(options),
        },
      };
    });
  };

  const reverseFormattedObj = {};
  Object.keys(formattedOffers).forEach((field) => {
    (reverseFormattedObj as any)[field] = reverseFormatProperty(
      formattedOffers[field]
    );
  });

  return reverseFormattedObj;
};

// customizer might need refactor in future
const customizer = (objValue: any, srcValue: any): any => {
  if (
    isArray(objValue) &&
    every(objValue, (element: any) => isString(element)) &&
    isArray(srcValue) && // Check if srcValue is also an array
    every(srcValue, (element: any) => isString(element))
  ) {
    return objValue;
  } else if (
    isArray(objValue) &&
    every(objValue, (element: any) => isPlainObject(element))
  ) {
    return objValue.map((obj) => {
      const matchingSrcObject = srcValue.find(
        (srcObj: any) => srcObj.offerId === obj.offerId
      );
      return matchingSrcObject
        ? mergeWith(obj, matchingSrcObject, customizer)
        : obj;
    });
  } else if (isPlainObject(objValue)) {
    // If the property is an object, let lodash merge it (recursively)
    return mergeWith(objValue, srcValue, customizer);
  } else if (isUndefined(objValue)) {
    return srcValue;
  } else {
    return objValue;
  }
};

export const mergeOffers = (
  formattedOffers: Record<string, LeftMenuWidget[]>,
  offers: any
) => {
  const clonedFormattedOffers = cloneDeep(formattedOffers);
  const clonedOffers = cloneDeep(offers);
  const reversedFormatOffers: any = cloneDeep(
    reverseFormatOffers(clonedFormattedOffers)
  );
  return mergeWith(reversedFormatOffers, clonedOffers, customizer);
};

const formatToRightMenu = (output: LeftMenuWidget[]): any => {
  const reversedSections: { [key: string]: any } = {};

  const processOptions = (options: LeftMenuWidget[]) => {
    const reversedOptions: { [key: string]: any } = {};

    options.forEach((option) => {
      const {
        name: optionName,
        isHidden: optionIsHidden,
        isDraggable: optionIsDraggable,
        canBeHidden: optionCanBeHidden,
        canBeRemoved: optionCanBeRemoved,
        currentEntries: optionCurrentEntries,
        availableEntries: optionAvailableEntries,
        supportsModules: optionSupportsModules,
        page: optionPage,
        options: nestedOptions,
      } = option;

      reversedOptions[optionName] = {
        isHidden: optionIsHidden || false,
        canBeDragged: optionIsDraggable || false,
        canBeHidden: optionCanBeHidden || false,
        canBeRemoved: optionCanBeRemoved || false,
        ...(optionSupportsModules && {
          supportsModules: optionSupportsModules,
        }),
        ...(optionPage && { page: optionPage }),
        ...(Array.isArray(optionCurrentEntries) && {
          currentEntries: optionCurrentEntries,
        }),
        ...(Array.isArray(optionAvailableEntries) && {
          availableEntries: optionAvailableEntries,
        }),
      };

      if (nestedOptions && nestedOptions.length > 0) {
        reversedOptions[optionName].options = processOptions(nestedOptions);
      }
    });

    return reversedOptions;
  };

  output.forEach((section) => {
    const {
      name,
      isHidden,
      isDraggable,
      canBeHidden,
      canBeRemoved,
      currentEntries,
      availableEntries,
      supportsModules,
      page,
      options,
      isOpen,
    } = section;

    reversedSections[name] = {
      ...(options?.length && {
        options: processOptions(options),
      }),
      ...(options?.length && {
        isOpen,
      }),
      isHidden: isHidden || false,
      canBeDragged: isDraggable || false,
      canBeHidden: canBeHidden || false,
      canBeRemoved: canBeRemoved || false,
      ...(supportsModules && { supportsModules }),
      ...(page && { page }),
      ...(Array.isArray(currentEntries) && { currentEntries }),
      ...(Array.isArray(availableEntries) && { availableEntries }),
    };
  });

  return reversedSections;
};

export const generateNewRightMenu = (
  newMenu: any,
  oldMenu: any,
  offers: any
) => {
  const clonedNewMenu = cloneDeep(newMenu);
  const clonedOldMenu = cloneDeep(oldMenu);
  const clonedOffers = cloneDeep(offers);
  return mergeWith(
    {
      ...formatToRightMenu(clonedNewMenu),
      ...(!isEmpty(clonedOffers) && { offers }),
    },
    clonedOldMenu,
    customizer
  );
};

const initialSecondaryBannerSlots: LeftMenuWidget[] = [
  {
    name: 'slot1',
    isSelected: false,
    isHidden: false,
    isDraggable: true,
    canBeHidden: true,
    canBeRemoved: false,
    key: 'ANNOUNCEMENT_BAR_SECONDARY_BANNER_SLOT_1',
  },
  {
    name: 'slot2',
    isSelected: false,
    isHidden: false,
    isDraggable: true,
    canBeHidden: true,
    canBeRemoved: false,
    key: 'ANNOUNCEMENT_BAR_SECONDARY_BANNER_SLOT_2',
  },
  {
    name: 'slot3',
    isSelected: false,
    isHidden: false,
    isDraggable: true,
    canBeHidden: true,
    canBeRemoved: false,
    key: 'ANNOUNCEMENT_BAR_SECONDARY_BANNER_SLOT_3',
  },
];

const formateNewSecondaryBannerSlots = (
  modifiedArray: LeftMenuWidget[],
  order: string[]
) => {
  const initialKeys = initialSecondaryBannerSlots.map((item) => item.key);
  const modifiedKeys = modifiedArray.map((item) => item.key);

  const missingKeys = initialKeys.filter((key) => !modifiedKeys.includes(key));

  const missingItems = missingKeys.map((key) => {
    const missingIndex = key?.match(/\d+/)?.[0]; // Extract the numeric index from the key
    return {
      name: `slot${missingIndex}`,
      id: uuidv4(), // Assuming you have a function like uuidv4() to generate a UUID
      isSelected: false,
      isHidden: false,
      isDraggable: true,
      canBeHidden: true,
      canBeRemoved: false,
      key: `ANNOUNCEMENT_BAR_SECONDARY_BANNER_SLOT_${missingIndex}`,
    };
  });

  // Combine modifiedArray and missingItems, then sort based on the specified order
  const sortedResult = [...modifiedArray, ...missingItems].sort((a, b) => {
    const indexA = order.indexOf(a.name);
    const indexB = order.indexOf(b.name);
    return indexA - indexB;
  });

  return sortedResult;
};

export const reverseMergedData = (
  mergedData: LeftMenuWidget[]
): {
  output: LeftMenuWidget[];
  formattedOffers: Record<string, LeftMenuWidget[]>;
} => {
  const output: LeftMenuWidget[] = [];
  const formattedOffers: Record<string, LeftMenuWidget[]> = {};

  const processOptions = (
    options: LeftMenuWidget[] | undefined,
    parentKey?: string
  ): {
    result: LeftMenuWidget[];
    nestedFormattedOffers: Record<string, LeftMenuWidget[]>;
  } => {
    const result: LeftMenuWidget[] = [];
    const nestedFormattedOffers: Record<string, LeftMenuWidget[]> = {};
    const groupName = parentKey || '';

    if (options) {
      options.forEach((option) => {
        if (option.offerId) {
          const { key, ...other } = option;
          nestedFormattedOffers[groupName] =
            nestedFormattedOffers[groupName] || [];
          nestedFormattedOffers[groupName].push(other);
        } else {
          const { isOpen, options, ...other } = option;
          const { result: nestedResult, nestedFormattedOffers: nestedOffers } =
            processOptions(option.options, groupName);

          const nestedResultCopy = nestedResult?.length
            ? [{ ...option, options: nestedResult }]
            : [other];

          result.push(...nestedResultCopy);
          Object.keys(nestedOffers).forEach((key) => {
            nestedFormattedOffers[key] = (
              nestedFormattedOffers[key] || []
            ).concat(nestedOffers[key]);
          });
        }
      });
    }

    return { result, nestedFormattedOffers };
  };

  const currentMergedData = cloneDeep(mergedData);

  currentMergedData.forEach((element) => {
    const { isOpen, options, ...other } = element;
    if (!element.options) {
      // This is an element in 'output'
      output.push(other);
    } else {
      // This is an element in 'formattedOffers'
      const { result, nestedFormattedOffers } = processOptions(
        element.options,
        element.name
      );

      const resultNeeded = result.map((item) => {
        if (item.name === 'secondaryBanner') {
          return {
            ...item,
            ...(item?.options?.length && {
              options: formateNewSecondaryBannerSlots(
                item.options as LeftMenuWidget[],
                transformWidgetsList(item.currentEntries || [])
              ),
            }),
          };
        }
        return item;
      });
      Object.assign(formattedOffers, nestedFormattedOffers);
      resultNeeded?.length
        ? output.push({
            ...element,
            options: resultNeeded as LeftMenuWidget[],
          })
        : output.push(other);
    }
  });

  return { output, formattedOffers };
};

const findSelectedElementPath = (
  widgets: LeftMenuWidget[],
  path = ''
): string | null => {
  for (let i = 0; i < widgets.length; i++) {
    const widget = widgets[i];
    const newPath = path === '' ? widget.name : `${path}.${widget.name}`;

    // Log the index if the widget is selected
    if (widget.isSelected === true) {
      return newPath;
    }

    if (widget.options && widget.options.length > 0) {
      const selectedPath = findSelectedElementPath(widget.options, newPath);
      if (selectedPath !== null) {
        return selectedPath;
      }
    }
  }

  return null;
};

const hasSelectedOption = (option: LeftMenuWidget): boolean => {
  if (option.isSelected) {
    return true;
  }

  if (option.options) {
    for (const nestedOption of option.options) {
      if (hasSelectedOption(nestedOption)) {
        return true;
      }
    }
  }

  return false;
};

const findOptionIndexById = (
  menu: LeftMenuWidget[],
  option: LeftMenuWidget,
  condition1 = false,
  condition2 = false
): { index: number; name: string } | undefined => {
  for (const currentMenu of menu) {
    if (currentMenu && currentMenu.options) {
      for (let index = 0; index < currentMenu.options.length; index++) {
        const currentOption = currentMenu.options[index];
        if (currentOption.id === option.id) {
          const nameNeeded =
            condition1 && option.options?.length
              ? option.options.find(hasSelectedOption)?.name
              : option.name;
          const indexNeeded =
            condition1 && option.options?.length
              ? option.options.findIndex(hasSelectedOption)
              : index;
          if (condition2) {
            return nameNeeded &&
              [
                'header',
                'footer',
                'image',
                'disclaimer',
                'discount',
                'shipping',
                'subtotal',
                'taxesAndDuties',
                'total',
                'codeValidation',
                'codeInput',
                'callToActionButton',
                'discountedPrice',
              ].includes(nameNeeded)
              ? undefined
              : nameNeeded === 'offer'
              ? { index: 0, name: nameNeeded }
              : nameNeeded
              ? { index: indexNeeded - 3, name: nameNeeded }
              : undefined;
          } else {
            return nameNeeded
              ? { index: indexNeeded, name: nameNeeded }
              : undefined;
          }
        }
      }
    }
  }
  return undefined;
};

const findOptionBySelectedOption = (
  data: LeftMenuWidget[],
  condition1 = false,
  condition2 = false
): { index: number; name: string } | undefined => {
  const findMainOption = (
    options: LeftMenuWidget[] | undefined
  ): LeftMenuWidget | undefined => {
    if (!options) {
      return undefined;
    }

    for (const option of options) {
      if (
        option &&
        (option.isSelected ||
          (option.options && findMainOption(option.options)))
      ) {
        return option;
      }
    }

    return undefined;
  };

  for (const mainOption of data
    .map((item) => findMainOption(item.options))
    .filter((mainOption): mainOption is LeftMenuWidget => !!mainOption)) {
    if (mainOption) {
      return findOptionIndexById(data, mainOption, condition1, condition2);
    }
  }

  return undefined;
};

const createAdminPath = (
  inputString: string,
  replacementObject: { name: string; index: number },
  isPresetEditor: boolean
): string => {
  if (isPresetEditor) {
    return inputString;
  }

  // Replace the specified substring
  return inputString.replace(
    replacementObject.name,
    `banner_${replacementObject.index}`
  );
};

const countDots = (str: string | null): number => {
  if (!str) return 0;
  let count = 0;
  for (let i = 0; i < str.length; i++) {
    if (str[i] === '.') {
      count++;
    }
  }
  return count;
};

export const getAdminPath = (
  leftMenu: LeftMenuWidget[],
  isPresetEditor = false
) => {
  const path = findSelectedElementPath(leftMenu);
  const isAnnouncementBar = !!path?.includes('announcementBar');
  const isNotification = !!path?.includes('notification');
  const insideDiscountInPromotionSummary =
    (!!path?.includes('cartPromotionSummary.discount') &&
      path !== 'cartPromotionSummary.discount') ||
    (!!path?.includes('drawerCartPromotionSummary.discount') &&
      path !== 'drawerCartPromotionSummary.discount') ||
    (!!path?.includes('productPagePrice.discountedPrice') &&
      path !== 'productPagePrice.discountedPrice' &&
      !isPresetEditor) ||
    (!!path?.includes('collectionPagePrice.discountedPrice') &&
      path !== 'collectionPagePrice.discountedPrice' &&
      !isPresetEditor) ||
    (!!path?.includes('notification.expandedState') &&
      path !== 'notification.expandedState' &&
      !isPresetEditor) ||
    (!!path?.includes('notification.collapsedState') &&
      path !== 'notification.collapsedState' &&
      !isPresetEditor);
  const simplePromotionComponent =
    countDots(path) === 1 &&
    (!!path?.includes('cartPromotionSummary') ||
      !!path?.includes('drawerCartPromotionSummary') ||
      !!path?.includes('drawerCartPromotionCodeField') ||
      !!path?.includes('cartPromotionCodeField') ||
      (!!path?.includes('productPagePrice') && !isPresetEditor) ||
      (!!path?.includes('collectionPagePrice') && !isPresetEditor));
  const replacementObject = findOptionBySelectedOption(
    leftMenu,
    isAnnouncementBar || insideDiscountInPromotionSummary,
    isNotification || simplePromotionComponent
  );

  if (path && replacementObject) {
    return createAdminPath(path, replacementObject, isPresetEditor);
  } else if (path && !replacementObject) {
    return path;
  } else {
    // Handle the case where either path or replacementObject is undefined
    return '';
  }
};

export const updateSelectionByPath = (
  widgets: LeftMenuWidget[],
  path: string,
  isNotification = false
): LeftMenuWidget[] => {
  const resetSelection = (
    widget: LeftMenuWidget,
    isClosed?: boolean
  ): LeftMenuWidget => {
    return {
      ...widget,
      isSelected: false,
      ...(isClosed && { isOpen: !isClosed }),
      ...(widget?.options?.length && {
        options: widget?.options?.map((option) => resetSelection(option)),
      }),
    };
  };

  const updateWidgetSelection = (
    widget: LeftMenuWidget,
    parts: string[],
    currentIndex: number,
    parentIsSelected = false
  ): LeftMenuWidget => {
    if (currentIndex === parts.length - 1) {
      // If this is the last part of the path, update the isSelected property
      return {
        ...widget,
        isSelected: true,
        ...(currentIndex === 0 && { isOpen: true }),
      };
    }

    // If the current part is in the format "banner_${index}",
    // treat it as an index and go to the corresponding option
    if (parts[currentIndex + 1].startsWith('banner_')) {
      const index = parseInt(parts[currentIndex + 1].split('_')[1], 10);
      const offerOptions =
        widget.options?.filter((option) => option.offerId) || [];
      const componentOptions =
        widget.options?.filter((option) => !option.offerId) || [];
      const optionsNeeded = isNotification ? offerOptions : widget.options;
      if (optionsNeeded && optionsNeeded[index]) {
        const updatedOptions = optionsNeeded.map((option, i) => {
          return i === index
            ? updateWidgetSelection(option, parts, currentIndex + 1, true) // Set parentIsSelected to true
            : option;
        });

        const mergedUpdatedOptions = isNotification
          ? [...componentOptions, ...updatedOptions]
          : updatedOptions;

        return {
          ...widget,
          isSelected: false, // Reset isSelected for the entire tree
          isOpen: parentIsSelected || false, // Set isOpen based on parentIsSelected
          ...(updatedOptions?.length && { options: mergedUpdatedOptions }),
        };
      }
    }

    // If there are more parts in the path and the current part is not "banner_${index}",
    // continue traversing
    if (widget.options && widget.options.length > 0) {
      const updatedOptions = widget.options.map((option) => {
        if (option.name === parts[currentIndex + 1]) {
          return updateWidgetSelection(option, parts, currentIndex + 1, true); // Set parentIsSelected to true
        }
        return option;
      });

      return {
        ...widget,
        isSelected: false, // Reset isSelected for the entire tree
        isOpen: parentIsSelected || false, // Set isOpen based on parentIsSelected
        ...(updatedOptions?.length && { options: updatedOptions }),
      };
    }

    return widget;
  };

  const pathParts = path.split('.');
  const widgetsWithResetSelection = widgets.map((widget) =>
    resetSelection(widget, true)
  );

  return widgetsWithResetSelection.map((widget) => {
    if (widget.name === pathParts[0]) {
      return updateWidgetSelection(widget, pathParts, 0, true);
    }
    return widget;
  });
};
