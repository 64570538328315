import React, { useCallback } from 'react';
import { BlockStack, Box, Checkbox, Text, TextField } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { OnboardingAppGoalDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { OnboardingAppGoalSetupDto } from 'core/api/adminSettings/adminSettingsApi';
type GoalStepProps = {
  goal?: OnboardingAppGoalSetupDto;
  setGoal: (data: OnboardingAppGoalSetupDto) => void;
};
export const GoalStep: React.FC<GoalStepProps> = ({ goal, setGoal }) => {
  const [i18n] = useI18n();

  const handleGoals = useCallback(
    (value: OnboardingAppGoalDtoEnum) => {
      const exist = goal?.goals?.includes(value);
      setGoal({
        ...goal,
        goals: exist
          ? goal?.goals?.filter((goal) => goal !== value)
          : [...(goal?.goals || []), value],
      });
    },
    [goal?.goals]
  );

  return (
    <Box paddingBlock='400'>
      <BlockStack gap='400'>
        <BlockStack gap='200'>
          <Text as='h1' variant='headingLg' fontWeight='semibold'>
            {i18n.translate('GoalTitle')}
          </Text>
          <Text as='p' fontWeight={'regular'} tone={'subdued'}>
            {i18n.translate('GoalSubtitle')}
          </Text>
        </BlockStack>
        {Object.values(OnboardingAppGoalDtoEnum).map((value) => {
          const isSelected = goal?.goals?.includes(value);
          return (
            <Box
              borderRadius='200'
              key={value}
              padding='300'
              borderColor={isSelected ? 'border-tertiary' : 'border'}
              background={isSelected ? 'bg-surface-selected' : 'bg-surface'}
              borderWidth='025'
            >
              <BlockStack gap='400'>
                <Checkbox
                  checked={isSelected}
                  onChange={() => handleGoals(value)}
                  label={i18n.translate(`${value}`)}
                />
                {goal?.goals?.includes(OnboardingAppGoalDtoEnum.OTHER) &&
                  value === OnboardingAppGoalDtoEnum.OTHER && (
                    <TextField
                      autoComplete=''
                      label=''
                      placeholder={i18n.translate('OtherPlaceholder')}
                      value={goal?.otherGoal || ''}
                      onChange={(value) =>
                        setGoal({ ...goal, otherGoal: value })
                      }
                    />
                  )}
              </BlockStack>
            </Box>
          );
        })}
      </BlockStack>
    </Box>
  );
};
