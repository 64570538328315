import { useCallback } from 'react';
import prettier from 'prettier/standalone';
import parserPostCSS from 'prettier/parser-postcss';
import parserBabel from 'prettier/parser-babel';

export const useFormatCode = (
  initialCode = '',
  initialLanguage = 'javascript'
) => {
  const handleFormatCode = useCallback(
    (code = initialCode, language = initialLanguage): string => {
      if (language === 'css') {
        return prettier.format(code, {
          parser: 'css',
          plugins: [parserPostCSS],
          tabWidth: 2,
          singleQuote: true,
        });
      } else if (language === 'javascript') {
        return prettier.format(code, {
          parser: 'babel',
          plugins: [parserBabel],
          semi: false,
          singleQuote: true,
        });
      } else {
        return code;
      }
    },
    [initialCode, initialLanguage]
  );

  return { handleFormatCode };
};
