import {
  BlockStack,
  Box,
  Button,
  Collapsible,
  InlineStack,
  Link,
  Text,
} from '@shopify/polaris';
import { I18n, useI18n } from '@shopify/react-i18n';
import { ChevronUpIcon, ChevronDownIcon } from '@shopify/polaris-icons';
import { IconButton } from 'core/components';
import React, { useState } from 'react';
type ShopSettingsWrapperProps = {
  children: React.ReactNode;
  onOpen?: () => void;
};
export const ShopSettingsWrapper: React.FC<ShopSettingsWrapperProps> = ({
  children,
  onOpen,
}) => {
  const [i18n] = useI18n();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  return (
    <div className='ShopSettingsWrapper'>
      <Box
        borderBlockEndWidth={'025'}
        borderColor='border-disabled'
        padding='500'
      >
        <BlockStack gap='200'>
          <InlineStack align='space-between' blockAlign='center'>
            <Text fontWeight='medium' as='h2'>
              {i18n.translate('ShopSettings')}
            </Text>
            <Button
              onClick={() => setIsOpen(!isOpen)}
              variant='tertiary'
              icon={isOpen ? ChevronUpIcon : ChevronDownIcon}
            />
          </InlineStack>
          <Text as='p' tone='subdued'>
            {i18n.translate('ImpactAllPromotions')}
            <Link onClick={onOpen}>{i18n.translate('OpenShopSettings')}</Link>
          </Text>
        </BlockStack>
      </Box>
      <Collapsible
        open={isOpen}
        id='basic-collapsible'
        transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
        expandOnPrint
      >
        {children}
      </Collapsible>
    </div>
  );
};
