import React, { useCallback, useEffect, useState } from 'react';
import { BlockStack, Page } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { TagComponent } from './components/TagComponent/TagComponent';
import { tagMetadata, tagsMetafields } from './components/TagMetadata.data';
import { CouponCheckbox } from './components/CouponCheckbox/CouponCheckbox';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { TagsMetadataSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import { useDispatch } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import {
  setIsUnsavedChanges,
  setOnDiscardChanges,
  setOnSaveChanges,
} from 'core/store/settingsSlice';
import { SettingsSkeleton } from '../components/SettingsSkeleton/SettingsSkeleton';

export const TagsMetadata: React.FC = () => {
  const [i18n] = useI18n();

  const {
    tagsMetadataDataChange,
    tagsMetadataDataIsFetching,
    tagsMetadataData,
  } = useConfigureSettings(SettingsFetchTypeEnum.TAGS_METADATA);
  const [tagsMetadataState, setTagsMetadataState] =
    useState<TagsMetadataSettingsDto>({});
  const [savedData, setSavedData] = useState<TagsMetadataSettingsDto>({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(tagsMetadataState) && !isEqual(savedData, tagsMetadataState)) {
      dispatch(setIsUnsavedChanges(true));
      dispatch(
        setOnDiscardChanges(() => {
          if (!isEmpty(savedData)) {
            setTagsMetadataState(savedData);
          }
        })
      );
      dispatch(
        setOnSaveChanges(() => {
          setSavedData(tagsMetadataState);
          return tagsMetadataDataChange(tagsMetadataState || {});
        })
      );
    } else if (isEqual(savedData, tagsMetadataData)) {
      dispatch(setIsUnsavedChanges(false));
    }
  }, [tagsMetadataState, savedData, dispatch]);

  useEffect(() => {
    if (!isEmpty(tagsMetadataData)) {
      setTagsMetadataState(tagsMetadataData);
      setSavedData(tagsMetadataData);
    }
    !isEmpty(tagsMetadataData) && setTagsMetadataState(tagsMetadataData);
  }, [tagsMetadataData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  const handleTagsMetadataLocaleState = useCallback(
    (section: string, value: string | boolean, field?: string) => {
      if (field) {
        setTagsMetadataState(
          (
            prevState: Omit<
              TagsMetadataSettingsDto,
              'addTagFlagCouponFraud' | 'storeDiscountAppDataInOrderMetafields'
            >
          ) => ({
            ...prevState,
            [section]: {
              ...(prevState[section as keyof typeof prevState] || {}),
              [field]: value,
            },
          })
        );
      } else {
        setTagsMetadataState((prevState) => ({
          ...prevState,
          [section]: value,
        }));
      }
    },
    [tagsMetadataState]
  );

  return (
    <div className='Style'>
      {!tagsMetadataDataIsFetching && tagsMetadataState ? (
        <Page
          title={i18n.translate(`TagsMetadata`)}
          subtitle={i18n.translate(`TagsMetadataSubtitle`)}
        >
          <BlockStack gap='800'>
            {tagMetadata.map((item, index) => (
              <TagComponent
                mapData={item}
                key={index}
                data={tagsMetadataState}
                handleTagsMetadataLocaleState={handleTagsMetadataLocaleState}
              />
            ))}
            {tagsMetafields.map((item, index) => (
              <CouponCheckbox
                mapData={item}
                key={index}
                data={tagsMetadataState}
                handleTagsMetadataLocaleState={handleTagsMetadataLocaleState}
              />
            ))}
          </BlockStack>
        </Page>
      ) : (
        <SettingsSkeleton />
      )}
    </div>
  );
};
