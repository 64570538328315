import React, { useMemo } from 'react';
import {
  Badge,
  BlockStack,
  Card,
  ChoiceList,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { PromotionCodeFieldDisplayModeDto } from 'core/api/adminSettings/adminSettingsApi';
import { PromotionCodeFieldDisplayModeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { Element } from 'react-scroll';

type DisplayModeSettingProps = {
  displayMode: PromotionCodeFieldDisplayModeDto;
  setDisplayMode: (value: PromotionCodeFieldDisplayModeDto) => void;
};
export const DisplayModeSetting: React.FC<DisplayModeSettingProps> = ({
  displayMode,
  setDisplayMode,
}) => {
  const [i18n] = useI18n();
  const modeOptions = useMemo(
    () =>
      Object.values(PromotionCodeFieldDisplayModeDtoEnum).map((value) => ({
        label: (
          <InlineStack gap='200'>
            {i18n.translate(`${value}`)}
            {value === PromotionCodeFieldDisplayModeDtoEnum.COLLAPSED && (
              <Badge>{i18n.translate('Recommended')}</Badge>
            )}
          </InlineStack>
        ),
        helpText: i18n.translate(`${value}_DESCRIPTION`),
        value: value,
      })),
    []
  );
  return (
    <Element name='DisplayModeSetting' className='DisplayModeSetting'>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('DisplayMode')}
            </Text>
          </BlockStack>
          <ChoiceList
            title=''
            choices={modeOptions}
            selected={[
              displayMode || PromotionCodeFieldDisplayModeDtoEnum.COLLAPSED,
            ]}
            onChange={(value) =>
              setDisplayMode(value[0] as PromotionCodeFieldDisplayModeDtoEnum)
            }
          />
        </BlockStack>
      </Card>
    </Element>
  );
};
