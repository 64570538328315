import { ColorDto } from 'core/components/WidgetEditor/ColorSelectorPopover/ColorSelectorPopover';

interface HSBColor {
  hue: number;
  saturation: number;
  brightness: number;
  alpha: number;
}

export const hexToHsb = (hexColor: ColorDto): HSBColor => {
  // Remove the '#' from the hex code
  const hex = hexColor.hex!.replace(/^#/, '');

  // Parse the hex code into RGB values
  const r = parseInt(hex.substring(0, 2), 16) / 255;
  const g = parseInt(hex.substring(2, 4), 16) / 255;
  const b = parseInt(hex.substring(4, 6), 16) / 255;

  // Find the maximum and minimum values among R, G, and B
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  // Calculate the brightness
  const brightness = max;

  // Calculate the saturation
  const delta = max - min;
  const saturation = max === 0 ? 0 : delta / max;

  // Calculate the hue
  let hue = 0;

  if (delta !== 0) {
    if (max === r) {
      hue = ((g - b) / delta + (g < b ? 6 : 0)) % 6;
    } else if (max === g) {
      hue = ((b - r) / delta + 2) % 6;
    } else {
      hue = ((r - g) / delta + 4) % 6;
    }

    hue *= 60;
  }

  // Return the HSB color
  return {
    hue,
    saturation,
    brightness,
    alpha: hexColor.alpha as number,
  };
};
