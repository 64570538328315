import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './SupportDetailsModal.scss';
import { ModalCustom } from 'core/components/ModalCustom/ModalCustom';
import { useI18n } from '@shopify/react-i18n';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import {
  Banner,
  BlockStack,
  Button,
  ButtonGroup,
  InlineStack,
  List,
  Text,
  TextField,
} from '@shopify/polaris';
import { Loader } from 'core/components';
import { format } from 'date-fns';
import {
  WidgetInstallationModeDtoEnum,
  WidgetTypeDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import {
  InstallationSupportRequestNoteDto,
  WidgetInstallationModeDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { formatTimeDistance } from 'core/utils';
type SupportDetailsModalProps = {
  isOpen: boolean;
  onRefetch: () => void;
  onClose: () => void;
};
const SupportDetailsModal: React.FC<SupportDetailsModalProps> = ({
  isOpen,
  onClose,
  onRefetch,
}) => {
  const [i18n] = useI18n();
  const {
    requestSupportData,
    requestSupportIsFetching,
    updateSupportIsLoading,
    updateSupportRequest,
    deleteSupportIsLoading,
    deleteSupportRequest,
  } = useConfigureSettings(SettingsFetchTypeEnum.WIDGET_SUPPORT_REQUEST);

  const [notes, setNotes] = useState<InstallationSupportRequestNoteDto[]>();
  const [note, setNote] = useState<string>('');
  const [addNoteActive, setAddNoteActive] = useState<boolean>(false);
  const [deleteActive, setDeleteActive] = useState<boolean>(false);

  const convertDateCode = useCallback((code: number) => {
    const formattedDate = format(new Date(code), 'dd.MM.yyyy HH:mm');
    return formattedDate;
  }, []);

  const getWidgetList = useCallback(
    (mode: WidgetInstallationModeDto, widgets: WidgetTypeDtoEnum[]) => {
      return widgets?.length ? (
        <div className='WidgetModeListBox'>
          <BlockStack gap='300'>
            <InlineStack align='space-between' blockAlign='center'>
              <Text as='p' fontWeight='bold'>
                {i18n.translate(`${mode}_TITLE`)}
              </Text>
              <Text as='p' tone='subdued'>
                {widgets.length}{' '}
                {i18n.translate(widgets.length === 1 ? 'Widget' : 'Widgets')}
              </Text>
            </InlineStack>
            <List type='bullet'>
              {widgets?.map((widget) => (
                <List.Item key={widget}>{i18n.translate(widget)}</List.Item>
              ))}
            </List>
          </BlockStack>
        </div>
      ) : null;
    },
    []
  );

  const widgetModeLists = useMemo(() => {
    const selectedWidgets = requestSupportData?.widgets?.filter((widget) =>
      requestSupportData.supportRequest?.selectedWidgets?.includes(
        widget.type as WidgetTypeDtoEnum
      )
    );
    const getModeTypes = (mode: WidgetInstallationModeDto) => {
      return selectedWidgets
        ?.filter((widget) => widget.installationMode === mode)
        .map((widget) => widget.type);
    };
    const appBlocks = getModeTypes(WidgetInstallationModeDtoEnum.APP_BLOCKS);
    const appEmbed = getModeTypes(WidgetInstallationModeDtoEnum.APP_EMBED);
    const custom = getModeTypes(WidgetInstallationModeDtoEnum.CUSTOM);

    return (
      <>
        {getWidgetList(
          WidgetInstallationModeDtoEnum.APP_BLOCKS,
          appBlocks as WidgetTypeDtoEnum[]
        )}
        {getWidgetList(
          WidgetInstallationModeDtoEnum.APP_EMBED,
          appEmbed as WidgetTypeDtoEnum[]
        )}
        {getWidgetList(
          WidgetInstallationModeDtoEnum.CUSTOM,
          custom as WidgetTypeDtoEnum[]
        )}
      </>
    );
  }, [requestSupportData]);

  const onAddNote = useCallback(async () => {
    const newNotes = [
      ...(notes as InstallationSupportRequestNoteDto[]),
      { message: note, date: Date.now() },
    ];
    updateSupportRequest({
      ...requestSupportData?.supportRequest,
      note: note,
    })
      .then(() => {
        setNotes(newNotes);
        setNote('');
        setAddNoteActive(false);
      })
      .catch(() => null);
  }, [setNotes, note, requestSupportData, notes]);

  const cancelRequest = useCallback(() => {
    deleteSupportRequest()
      .then(() => {
        onClose();
        onRefetch();
      })
      .catch((err) => null);
  }, []);

  useEffect(() => {
    if (requestSupportData) {
      setNotes(requestSupportData.supportRequest?.notes || []);
    }
  }, [requestSupportData]);

  return (
    <ModalCustom
      title={i18n.translate(deleteActive ? 'CancelSupport' : 'RequestDetails')}
      isOpen={isOpen}
      onClose={onClose}
      showCloseButton
    >
      <div className='SupportDetailsModal'>
        {requestSupportData && !requestSupportIsFetching ? (
          <div className='SupportDetailsModalContent'>
            {!deleteActive ? (
              <BlockStack gap='400'>
                <BlockStack gap='200'>
                  <Text as='p'>
                    {i18n.translate(`YouSubmitted`)}
                    <Text fontWeight='medium' as='span'>
                      {convertDateCode(
                        requestSupportData.supportRequest?.submissionDate || 0
                      )}
                    </Text>

                    {i18n.translate(`ForThe`)}
                    <Text fontWeight='medium' as='span'>
                      {i18n.translate(`CurrentTheme`)}
                      {requestSupportData.supportRequest?.themeName}
                    </Text>
                    {i18n.translate(`ForSelectedWidgets`)}
                  </Text>
                  {widgetModeLists}
                </BlockStack>
                <BlockStack gap='200'>
                  <Text as='h2' fontWeight='medium'>
                    {i18n.translate(`Timeline`)}
                  </Text>
                  <Text as='p'>
                    {i18n.translate(`ExpectedBy`)}
                    <Text fontWeight='medium' as='span'>
                      {convertDateCode(
                        requestSupportData.supportRequest
                          ?.expectedSupportDate || 0
                      )}
                    </Text>
                  </Text>
                </BlockStack>
                <BlockStack gap='200'>
                  <Text as='h2' fontWeight='medium'>
                    {i18n.translate(`Status`)}
                  </Text>
                  <Text as='p'>
                    {i18n.translate(
                      `${requestSupportData.supportRequest?.status}`
                    )}
                  </Text>
                </BlockStack>
                <BlockStack gap='200'>
                  <Text as='h2' fontWeight='medium'>
                    {i18n.translate(`Note`)}
                  </Text>
                  {notes?.map((note) => (
                    <div key={note.date} className='NoteBox'>
                      <Text as='p'>{note.message}</Text>
                      <Text as='p' tone='subdued'>
                        {formatTimeDistance(note.date || 0)}
                      </Text>
                    </div>
                  ))}
                  {addNoteActive ? (
                    <TextField
                      autoComplete=''
                      placeholder={i18n.translate(`TypeHere`)}
                      label={i18n.translate(`OptionalNote`)}
                      value={note}
                      onChange={setNote}
                      connectedRight={
                        <Button
                          loading={updateSupportIsLoading}
                          onClick={onAddNote}
                          variant='primary'
                        >
                          {i18n.translate(`AddNote`)}
                        </Button>
                      }
                    />
                  ) : (
                    <div>
                      <Button onClick={() => setAddNoteActive(true)}>
                        {i18n.translate(`AddNote`)}
                      </Button>
                    </div>
                  )}
                </BlockStack>
                <Banner tone='info'>{i18n.translate(`BannerText`)}</Banner>
              </BlockStack>
            ) : (
              <Text as='p'>{i18n.translate(`DeleteText`)}</Text>
            )}
          </div>
        ) : (
          <Loader fullWidth />
        )}

        <div className='ModalFooterActions'>
          <ButtonGroup>
            <Button
              onClick={
                deleteActive ? cancelRequest : () => setDeleteActive(true)
              }
              variant={deleteActive ? 'primary' : undefined}
              tone='critical'
              loading={deleteSupportIsLoading}
            >
              {i18n.translate('CancelRequest')}
            </Button>
            {deleteActive && (
              <Button onClick={onClose}>{i18n.translate('Close')}</Button>
            )}
          </ButtonGroup>
        </div>
      </div>
    </ModalCustom>
  );
};

export default SupportDetailsModal;
