import React, { useEffect, useMemo } from 'react';
import { I18n } from '@shopify/react-i18n';
import {
  TextField,
  BlockStack,
} from '@shopify/polaris';
import './ShippingNameMethod.scss';
type ShippingNameMethodProps = {
  i18n: I18n;
  setMethodName: (value: string) => void;
  methodName: string;
  updateValidity?(index: number, isValid: boolean): void;
};
export const ShippingNameMethod: React.FC<ShippingNameMethodProps> = (
  props
) => {
  const { i18n, setMethodName, methodName, updateValidity } = props;
  const methodNameValueNotValid: boolean = useMemo(
    () => methodName === '',
    [methodName]
  );
  useEffect(() => {
    updateValidity ? updateValidity(1, !methodNameValueNotValid) : null;
  }, [methodNameValueNotValid]);
  return (
    <>
      <BlockStack>
        <div style={{ width: '50%' }}>
          <TextField
            maxLength={50}
            label='Enter the name of the custom delivery method'
            value={methodName}
            onChange={setMethodName}
            autoComplete='off'
            placeholder={i18n.translate('Shipping.NamePlaceholder')}
            error={
              methodNameValueNotValid &&
              i18n.translate('Shipping.validationMsg')
            }
          />
        </div>
      </BlockStack>
    </>
  );
};
