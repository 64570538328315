import React from 'react';
import { LegacyCard } from '@shopify/polaris';
import { Chart, ArcElement, Tooltip } from 'chart.js';
import styles from './sessionUsage.module.scss';
import { Doughnut } from 'react-chartjs-2';
import { useI18n } from '@shopify/react-i18n';
import { formatNumber } from 'core/utils';

Chart.register(ArcElement, Tooltip);

export const data = {
  datasets: [
    {
      data: [76, 24],
      backgroundColor: ['#5563c1', '#f4f6f8'],
      borderWidth: 1,
      borderColor: ['#f4f6f8', '#f4f6f8'],
      hoverOffset: 0,
      hoverBackgroundColor: ['#262d5e', '#b6b6b6'],
      hoverBorderColor: ['#f4f6f8', '#f4f6f8'],
      label: 'Available quota',
    },
  ],
};

const options = {
  cutout: '86%',
  responsive: true,
  plugins: {
    tooltip: {
      bodyFont: {
        size: 12,
      },
      padding: 10,
      caretSize: 8,
      caretPadding: 5,
      boxWidth: 10,
      boxHeight: 10,
      boxPadding: 15,
      callbacks: {
        label: (context: any) => {
          let label = context.dataset.label || '';

          if (label) {
            label += `: ${context.raw} `;
          }
          return label;
        },
      },
    },
  },
};

const SessionUsage: React.FC = () => {
  const [i18n] = useI18n();

  return (
    <LegacyCard title='Sessions usage'>
      <LegacyCard.Section>
        <p>
          Usage on development stores is limited to {formatNumber(i18n, 1000)}{' '}
          per month.
        </p>
      </LegacyCard.Section>
      <LegacyCard.Section>
        <div className={styles.chartBox}>
          <Doughnut data={data} options={options} />
          <p className={styles.sessionsLeft}>
            {data.datasets[0].data[0]}% left
          </p>
        </div>
      </LegacyCard.Section>
    </LegacyCard>
  );
};
export default SessionUsage;
