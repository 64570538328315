import React, { useCallback, useState } from 'react';
import './ImageIconLibrary.scss';
import {
  ActionList,
  Button,
  Icon,
  Popover,
  Tabs,
  Text,
  Tooltip,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  XIcon,
  DeleteIcon,
  PlusIcon,
  MenuHorizontalIcon,
} from '@shopify/polaris-icons';
import { IconResourceDto } from 'core/api/adminWidgets/adminWidgetsApi';
import classNames from 'classnames';
import UploadImageModal from 'core/components/WidgetEditor/UploadImageModal/UploadImageModal';
import { Loader } from 'core/components';
import { useConfigureWidgets } from 'features/settings/hooks/useConfigureWidgets';
import {
  IconStyleGroupDtoEnum,
  ResourceGroupKeyDtoEnum,
  ResourceLibraryTypeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import DeleteAllFilesModal from 'core/components/WidgetEditor/DeleteAllFilesModal/DeleteAllFilesModal';

type ImageIconLibraryProps = {
  selectedFile: string;
  defaultFilesLibrary: IconResourceDto[];
  customFilesLibrary: IconResourceDto[];
  isCustomFileLibraryFetching: boolean;
  fileFormat: 'icon' | 'image';
  groupKey?: ResourceGroupKeyDtoEnum;
  setSelectedFile: (value: string) => void;
  refetchCustomLibrary: () => void;
  onClosePopover: () => void;
};

const ImageIconLibrary: React.FC<ImageIconLibraryProps> = ({
  selectedFile,
  defaultFilesLibrary,
  customFilesLibrary,
  isCustomFileLibraryFetching,
  fileFormat,
  groupKey,
  setSelectedFile,
  refetchCustomLibrary,
  onClosePopover,
}) => {
  const [i18n] = useI18n();
  const [styleGroup, setStyleGroup] = useState<IconStyleGroupDtoEnum>(
    IconStyleGroupDtoEnum.THIN
  );
  const {
    uploadIcon,
    uploadImage,
    uploadIconIsLoading,
    uploadImageIsLoading,
    deleteAllIcons,
    deleteAllImages,
    deleteIcon,
    deleteImage,
    deleteAllIconsIsLoading,
    deleteAllImagesIsLoading,
    deleteImageIsLoading,
    deleteIconIsLoading,
  } = useConfigureWidgets();

  const [removeAllPopover, setRemoveAllPopover] = useState<boolean>(false);
  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const isIconFormat = fileFormat === 'icon';

  const uploadFileIsLoading = uploadIconIsLoading || uploadImageIsLoading;
  const deleteAllFilesIsLoading =
    deleteAllIconsIsLoading || deleteAllImagesIsLoading;
  const deleteFileIsLoading = deleteImageIsLoading || deleteIconIsLoading;

  const tabs = Object.keys(IconStyleGroupDtoEnum).map((value) => ({
    id: value,
    content: i18n.translate(`${value}`),
  }));

  const handleUploadFiles = useCallback(
    (value: File[]) => {
      const formData = new FormData();
      value.forEach((blob) => {
        formData.append('files', blob);
      });
      (isIconFormat ? uploadIcon(formData) : uploadImage(formData)).then(() => {
        setUploadModalOpen(false);
        refetchCustomLibrary();
      });
    },
    [
      uploadIcon,
      setUploadModalOpen,
      uploadImage,
      refetchCustomLibrary,
      isIconFormat,
    ]
  );

  const handleDeleteIcons = useCallback(
    (id: string | null) => {
      if (id === null) {
        deleteAllIcons().then(() => {
          setDeleteModalOpen(false);
          refetchCustomLibrary();
        });
      } else {
        deleteIcon(id).then(() => refetchCustomLibrary());
      }
    },
    [deleteAllIcons, setDeleteModalOpen, refetchCustomLibrary, deleteIcon]
  );
  const handleDeleteImages = useCallback(
    (id: string | null) => {
      if (id === null) {
        deleteAllImages().then(() => {
          setDeleteModalOpen(false);
          refetchCustomLibrary();
        });
      } else {
        deleteImage(id).then(() => refetchCustomLibrary());
      }
    },
    [deleteAllImages, setDeleteModalOpen, refetchCustomLibrary, deleteImage]
  );

  const getFileViewBox = useCallback(
    (file: IconResourceDto) => {
      return (
        <Tooltip key={file.id} content={file.name}>
          <div
            className={classNames('LibraryListItem', {
              IsSelected: selectedFile === file.id,
            })}
            onClick={() => setSelectedFile(file.id || '')}
          >
            <img src={file.url} alt='' />
            {file.type === ResourceLibraryTypeDtoEnum.CUSTOM &&
              file.canBeDeleted && (
                <div
                  onClick={() => handleDeleteIcons(file.id || '')}
                  className='DeleteIcon'
                >
                  <Icon tone='base' source={DeleteIcon} />
                </div>
              )}
          </div>
        </Tooltip>
      );
    },
    [setSelectedFile, selectedFile]
  );

  return (
    <div className='ImageIconLibrary'>
      <div className='IconLibraryHeader'>
        <Text as='h6' fontWeight='semibold'>
          {i18n.translate(isIconFormat ? 'Icon' : 'Image')}
        </Text>
        <Button onClick={onClosePopover} icon={XIcon} />
      </div>
      <div className='IconLibraryContent'>
        <Text as='p'>{i18n.translate('DefaultLibrary')}</Text>
        {isIconFormat && (
          <div className='StyleGroup'>
            <Tabs
              tabs={tabs}
              selected={
                styleGroup === IconStyleGroupDtoEnum.THIN
                  ? 0
                  : styleGroup === IconStyleGroupDtoEnum.THICK
                  ? 1
                  : 2
              }
              onSelect={(value) => {
                setStyleGroup(
                  value === 0
                    ? IconStyleGroupDtoEnum.THIN
                    : value === 1
                    ? IconStyleGroupDtoEnum.THICK
                    : IconStyleGroupDtoEnum.SOLID
                );
              }}
              fitted
            />
          </div>
        )}

        <div className='LibraryList'>
          {defaultFilesLibrary.length ? (
            defaultFilesLibrary
              ?.filter(
                (file) =>
                  file.styleGroup === (isIconFormat ? styleGroup : undefined)
              )
              ?.map((file) => getFileViewBox(file))
          ) : (
            <Loader size='small' fullWidth />
          )}
        </div>
        {groupKey !== ResourceGroupKeyDtoEnum.CHEVRONS && (
          <>
            <div className='CustomLibraryHeader'>
              <Text as='p'>{i18n.translate('CustomLibrary')}</Text>
              <div className='CustomActions'>
                <div onClick={() => setUploadModalOpen(true)}>
                  <Icon tone='base' source={PlusIcon} />
                </div>
                <Popover
                  active={removeAllPopover}
                  activator={
                    <div onClick={() => setRemoveAllPopover(true)}>
                      <Icon tone='base' source={MenuHorizontalIcon} />
                    </div>
                  }
                  autofocusTarget='first-node'
                  onClose={() => setRemoveAllPopover(false)}
                >
                  <div onClick={(e) => e.stopPropagation()}>
                    <ActionList
                      actionRole='menuitem'
                      items={[
                        {
                          external: true,
                          destructive: true,
                          content: i18n.translate(`RemoveAll`),
                          onAction: () => setDeleteModalOpen(true),
                          disabled: !customFilesLibrary?.length,
                        },
                      ]}
                    />
                  </div>
                </Popover>
              </div>
            </div>
            <div className='LibraryList'>
              {!isCustomFileLibraryFetching && !deleteFileIsLoading ? (
                customFilesLibrary?.length ? (
                  customFilesLibrary?.map((file) => getFileViewBox(file))
                ) : (
                  <p className='EmptyCustomLibrary'>
                    {i18n.translate('LibraryIsEmpty')}{' '}
                    <span onClick={() => setUploadModalOpen(true)}>
                      {i18n.translate(
                        isIconFormat ? 'UploadIcons' : 'UploadImages'
                      )}
                    </span>
                  </p>
                )
              ) : (
                <Loader fullWidth size='small' />
              )}
            </div>
          </>
        )}
      </div>
      {uploadModalOpen && (
        <UploadImageModal
          isOpen={uploadModalOpen}
          onClose={() => setUploadModalOpen(false)}
          onUploadFiles={(value) => handleUploadFiles(value)}
          uploadIsLoading={uploadFileIsLoading}
          fileFormat={fileFormat}
        />
      )}
      {deleteModalOpen && (
        <DeleteAllFilesModal
          isOpen={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onDeleteFiles={() =>
            isIconFormat ? handleDeleteIcons(null) : handleDeleteImages(null)
          }
          fileFormat={fileFormat}
          deletingIsLoading={deleteAllFilesIsLoading}
        />
      )}
    </div>
  );
};

export default ImageIconLibrary;
