import React, { useCallback, useState } from 'react';
import './ButtonStyle.scss';
import { useI18n } from '@shopify/react-i18n';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import {
  BlockStack,
  Button,
  Checkbox,
  Collapsible,
  Icon,
  InlineStack,
  Tabs,
  Tooltip,
} from '@shopify/polaris';
import { CallToActionButtonStyleDto } from 'core/api/adminWidgets/adminWidgetsApi';
import {
  ButtonCornersStyleDtoEnum,
  FontStyleTypeDtoEnum,
  HoveringStyleDtoEnum,
  NinjaCartButtonStyleSetKeyDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import {
  ChevronUpIcon,
  ChevronDownIcon,
  InfoIcon,
} from '@shopify/polaris-icons';
import ColorSelectorBox from 'core/components/WidgetEditor/ColorSelectorBox/ColorSelectorBox';
import TextRow from '../TextStyle/components/TextRow/TextRow';
import FontSelector from 'core/components/WidgetEditor/FontSelector/FontSelector';

type ButtonStyleProps = {
  buttonStyle: CallToActionButtonStyleDto;
  selectedStyleSet?: NinjaCartButtonStyleSetKeyDtoEnum;
  setSelectedStyleSet?: (value: NinjaCartButtonStyleSetKeyDtoEnum) => void;
  disabled?: boolean;
  inherited?: boolean;
  setButtonStyle?: (data: CallToActionButtonStyleDto) => void;
  onSystemReset?: () => void;
};

export const ButtonStyle: React.FC<ButtonStyleProps> = ({
  buttonStyle,
  disabled,
  inherited,
  selectedStyleSet,
  setSelectedStyleSet,
  setButtonStyle,
  onSystemReset,
}) => {
  const [i18n] = useI18n();
  const [isAdvancedOpen, setIsAdvancedOpen] = useState<boolean>(false);

  const cornersOptions = Object.values(ButtonCornersStyleDtoEnum).map(
    (value) => {
      return {
        value: value,
        label: i18n.translate(value),
      };
    }
  );
  const hoveringStyleOptions = Object.values(HoveringStyleDtoEnum).map(
    (value) => {
      return {
        value: value,
        label: i18n.translate(value),
      };
    }
  );

  const styleSetOptions = Object.keys(NinjaCartButtonStyleSetKeyDtoEnum).map(
    (value) => ({
      id: value,
      content: i18n.translate(`${value}`),
    })
  );

  const handleUpdateButton = useCallback(
    (
      field: keyof CallToActionButtonStyleDto,
      data: CallToActionButtonStyleDto[keyof CallToActionButtonStyleDto]
    ) => setButtonStyle?.({ ...buttonStyle, [field]: data }),
    [buttonStyle, setButtonStyle]
  );

  return (
    <div className='ButtonStyle'>
      <StyleComponentHeader
        label={i18n.translate(`ButtonStyle`)}
        inherited={inherited}
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            disabled: disabled,
            onAction: () => onSystemReset?.(),
          },
        ]}
      >
        {selectedStyleSet && (
          <div className='StyleSet'>
            <Tabs
              tabs={styleSetOptions}
              selected={
                selectedStyleSet === NinjaCartButtonStyleSetKeyDtoEnum.PRIMARY
                  ? 0
                  : 1
              }
              onSelect={(value) => {
                setSelectedStyleSet?.(
                  value === 0
                    ? NinjaCartButtonStyleSetKeyDtoEnum.PRIMARY
                    : NinjaCartButtonStyleSetKeyDtoEnum.SECONDARY
                );
              }}
              fitted
            />
          </div>
        )}

        <div className='ButtonStyleRow'>
          {i18n.translate(`Corners`)}
          <div className='RightContent'>
            <SelectOptions
              options={cornersOptions}
              onOptionSelect={(value) =>
                handleUpdateButton('cornersStyle', value)
              }
              selectedOption={
                buttonStyle.cornersStyle as ButtonCornersStyleDtoEnum
              }
              disabled={disabled}
              label=''
            />
          </div>
        </div>
        <div className='ButtonStyleRow'>
          {i18n.translate(`Background`)}
          <div className='RightContent'>
            <ColorSelectorBox
              disabled={disabled}
              color={buttonStyle.backgroundColor || ''}
              setColor={(value) => handleUpdateButton('backgroundColor', value)}
            />
          </div>
        </div>
        <div className='ButtonStyleRow'>
          {i18n.translate(`Border`)}
          <div className='RightContent'>
            <ColorSelectorBox
              disabled={disabled}
              color={buttonStyle.borderColor || ''}
              setColor={(value) => handleUpdateButton('borderColor', value)}
            />
          </div>
        </div>
        <div className='ButtonStyleRow'>
          {i18n.translate(`TextColor`)}
          <div className='RightContent'>
            <ColorSelectorBox
              disabled={disabled}
              color={buttonStyle.textStyle?.color || ''}
              setColor={(value) =>
                handleUpdateButton('textStyle', {
                  ...buttonStyle.textStyle,
                  color: value,
                })
              }
            />
          </div>
        </div>
        <div>
          <Button
            variant='plain'
            onClick={() => setIsAdvancedOpen(!isAdvancedOpen)}
            icon={isAdvancedOpen ? ChevronUpIcon : ChevronDownIcon}
          >
            {i18n.translate(`Advanced`)}
          </Button>
          <Collapsible
            open={isAdvancedOpen}
            id='basic-collapsible'
            transition={{
              duration: '500ms',
              timingFunction: 'ease-in-out',
            }}
            expandOnPrint
          >
            <BlockStack gap='300'>
              <Checkbox
                label={i18n.translate('EnableHovering')}
                checked={buttonStyle.hoveringEffect?.enabled}
                onChange={(value) =>
                  handleUpdateButton('hoveringEffect', {
                    ...buttonStyle.hoveringEffect,
                    enabled: value,
                  })
                }
              />
              <div className='ButtonStyleRow'>
                {i18n.translate(`HoveringStyle`)}
                <div className='RightContent'>
                  <SelectOptions
                    options={hoveringStyleOptions}
                    onOptionSelect={(value) =>
                      handleUpdateButton('hoveringEffect', {
                        ...buttonStyle.hoveringEffect,
                        value: value as HoveringStyleDtoEnum,
                      })
                    }
                    selectedOption={
                      buttonStyle.hoveringEffect?.value as HoveringStyleDtoEnum
                    }
                    disabled={disabled}
                    label=''
                  />
                </div>
              </div>
              <div className='ButtonStyleRow'>
                {i18n.translate(`TextStyle`)}
                <div className='RightContent'>
                  <TextRow
                    disabled={disabled}
                    text={buttonStyle.textStyle?.style || {}}
                    setText={(data) =>
                      handleUpdateButton('textStyle', {
                        ...buttonStyle.textStyle,
                        style: data,
                      })
                    }
                  />
                </div>
              </div>
              <InlineStack align='space-between' blockAlign='center'>
                <Checkbox
                  disabled={disabled}
                  label={i18n.translate(`InheritFromParent`)}
                  checked={
                    buttonStyle.textStyle?.font?.type ===
                    FontStyleTypeDtoEnum.INHERITED
                  }
                  onChange={() =>
                    handleUpdateButton('textStyle', {
                      ...buttonStyle.textStyle,
                      font: {
                        ...buttonStyle.textStyle?.font,
                        type:
                          buttonStyle.textStyle?.font?.type ===
                          FontStyleTypeDtoEnum.INHERITED
                            ? FontStyleTypeDtoEnum.CUSTOM
                            : FontStyleTypeDtoEnum.INHERITED,
                      },
                    })
                  }
                />
                <Tooltip
                  width='wide'
                  content={i18n.translate(`InheritTooltip`)}
                >
                  <Icon
                    tone={disabled ? 'subdued' : 'base'}
                    source={InfoIcon}
                  />
                </Tooltip>
              </InlineStack>
              <div className='ButtonStyleRow'>
                {i18n.translate(`Font`)}
                <div className='RightContent'>
                  <FontSelector
                    disabled={
                      buttonStyle.textStyle?.font?.type ===
                        FontStyleTypeDtoEnum.INHERITED || disabled
                    }
                    fontId={buttonStyle.textStyle?.font?.fontId || ''}
                    setFontId={(value) =>
                      handleUpdateButton('textStyle', {
                        ...buttonStyle.textStyle,
                        font: {
                          ...buttonStyle.textStyle?.font,
                          fontId: value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            </BlockStack>
          </Collapsible>
        </div>
      </StyleComponentHeader>
    </div>
  );
};
