import React, { useMemo } from 'react';
import {
  Badge,
  Bleed,
  BlockStack,
  Box,
  Card,
  ChoiceList,
  InlineStack,
  Link,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { DynamicCheckoutButtonsCheckoutSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import { PageTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
type DynamicCheckoutButtonsProps = {
  dynamicCheckout?: DynamicCheckoutButtonsCheckoutSettingsDto;
  setDynamicCheckout: (
    value: DynamicCheckoutButtonsCheckoutSettingsDto
  ) => void;
};
export const DynamicCheckoutButtons: React.FC<DynamicCheckoutButtonsProps> = ({
  dynamicCheckout,
  setDynamicCheckout,
}) => {
  const [i18n] = useI18n();
  const availablePages = useMemo(
    () => [PageTypeDtoEnum.PRODUCT, PageTypeDtoEnum.CART],
    []
  );

  const pageOptions = useMemo(
    () =>
      availablePages.map((value) => ({
        label: (
          <InlineStack gap='200'>
            <Text as='p'>{i18n.translate(value)}</Text>
            <Badge>{i18n.translate('Recommended')}</Badge>
          </InlineStack>
        ),
        value: value,
      })),
    [availablePages, i18n]
  );

  return (
    <Card>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text fontWeight='semibold' as='h2'>
            {i18n.translate('Title')}
          </Text>

          <Text as='p' tone='subdued'>
            {i18n.translate('Subtitle', {
              readArticle: <Link>{i18n.translate('ReadThisArticle')}</Link>,
            })}
          </Text>
        </BlockStack>

        <ChoiceList
          allowMultiple
          title={i18n.translate('OverrideDynamicCheckout')}
          choices={pageOptions}
          selected={(dynamicCheckout?.overrideOnPages as string[]) || []}
          onChange={(data) =>
            setDynamicCheckout({ overrideOnPages: data as PageTypeDtoEnum[] })
          }
        />
        <Bleed marginBlockEnd='400' marginInline='400'>
          <Box background='bg-surface-secondary' padding='400'>
            <Text as='p' tone='subdued'>
              {i18n.translate('VisitorsCanStill')}
            </Text>
          </Box>
        </Bleed>
      </BlockStack>
    </Card>
  );
};
