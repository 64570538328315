import React, { useMemo } from 'react';
import {
  Banner,
  BlockStack,
  Card,
  ChoiceList,
  Link,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { InternationalPricingCheckoutSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import { CheckoutModeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
type InternationalPricingProps = {
  internationalPricing?: InternationalPricingCheckoutSettingsDto;
  setInternationalPricing: (
    data: InternationalPricingCheckoutSettingsDto
  ) => void;
};
export const InternationalPricing: React.FC<InternationalPricingProps> = ({
  internationalPricing,
  setInternationalPricing,
}) => {
  const [i18n] = useI18n();

  const modeOptions = useMemo(
    () =>
      Object.values(CheckoutModeDtoEnum)
        .filter((value) => value !== CheckoutModeDtoEnum.SHOPIFY_FUNCTIONS)
        .map((value) => ({
          label: i18n.translate(`${value}_TITLE`),
          helpText: i18n.translate(`${value}_SUBTITLE`),
          value: value,
        })),
    [i18n]
  );

  return (
    <Card>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text fontWeight='semibold' as='h2'>
            {i18n.translate('Title')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('Subtitle', {
              readArticle: <Link>{i18n.translate('ReadThisArticle')}</Link>,
            })}
          </Text>
        </BlockStack>
        <Banner title={i18n.translate('SupportedCurrencies')}>
          <Text as='p'>
            {i18n.translate('WeDetectedYourShopUses', {
              current: internationalPricing?.shopCurrency,
              supported: (
                <Text fontWeight='medium' as='span'>
                  {internationalPricing?.supportedCurrenciesAtCheckout?.join(
                    ', '
                  )}
                </Text>
              ),
            })}
          </Text>
        </Banner>
        <ChoiceList
          title={i18n.translate('SelectCheckoutMode')}
          choices={modeOptions}
          selected={[
            internationalPricing?.nonBaseCurrencyDiscountsCheckoutMode as string,
          ]}
          onChange={(value) =>
            setInternationalPricing({
              ...internationalPricing,
              nonBaseCurrencyDiscountsCheckoutMode:
                value[0] as CheckoutModeDtoEnum,
            })
          }
        />
      </BlockStack>
    </Card>
  );
};
