import {
  OfferRulePopupOfferRulePresetDto,
  OfferRulePopupOfferRulePresetDtoRead,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import React, { useCallback } from 'react';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { useI18n } from '@shopify/react-i18n';
import { TextStyle } from '../../../../../components';
import { Text } from '@shopify/polaris';

type OfferRulePopupRuleProps = {
  offerRule: OfferRulePopupOfferRulePresetDtoRead;
  defaultOfferRule: OfferRulePopupOfferRulePresetDtoRead;
  shopDefaultData: OfferRulePopupOfferRulePresetDto;
  setOfferRule: (data: OfferRulePopupOfferRulePresetDtoRead) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const OfferRulePopupRule: React.FC<OfferRulePopupRuleProps> = ({
  offerRule,
  defaultOfferRule,
  shopDefaultData,
  setOfferRule,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();

  const resetAllToSystemDefault = useCallback(() => {
    setOfferRule(shopDefaultData);
  }, [shopDefaultData, setOfferRule]);

  return (
    <div className='PromotionalBadgeBadge'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={() => setOfferRule(defaultOfferRule)}
        onResetToSystem={resetAllToSystemDefault}
        name={WidgetListOptionsDtoEnum.OFFER_RULE}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='EmptyRightSideBanner'>
        <div className='RightSideSection'>
          <Text as='p' tone='subdued'>
            {i18n.translate('ActualTextNote')}
          </Text>
        </div>
      </div>
      <div className='RightSideSection'>
        <TextStyle
          title={i18n.translate('HeadlineText')}
          textStyle={offerRule.headlineText || {}}
          offersNote
          setTextStyle={(data) =>
            setOfferRule({
              ...offerRule,
              headlineText: data,
            })
          }
          onSystemReset={() =>
            setOfferRule({
              ...offerRule,
              headlineText: shopDefaultData.headlineText,
            })
          }
        />
      </div>
      <div className='RightSideSection'>
        <TextStyle
          title={i18n.translate('RuleText')}
          textStyle={offerRule.ruleText || {}}
          offersNote
          setTextStyle={(data) =>
            setOfferRule({
              ...offerRule,
              ruleText: data,
            })
          }
          onSystemReset={() =>
            setOfferRule({
              ...offerRule,
              ruleText: shopDefaultData.ruleText,
            })
          }
        />
      </div>
    </div>
  );
};

export default OfferRulePopupRule;
