import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RulesSection } from '../../RulesSection/RulesSection';
import {
  OfferFrequentlyBoughtTogetherDto,
  OfferSuggestedAdvancedSetupDto,
  OfferSuggestedRuleConditionDto,
  OfferSuggestedRuleDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { useOfferFormValidity } from 'features/promotions/hooks/useOfferFormValidity';
import { isEqual } from 'lodash';
import { FrequentlyBoughtTogetherTabsEnum } from '../../../enums/OfferTypeTabs';
import {
  OfferCartContentRequirementProductMatchTypeDtoEnum,
  OfferRuleTypeDtoEnum,
  OfferRuleValueTypeDtoEnum,
  OfferSuggestedRuleTypeDtoEnum,
  OfferTargetTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { RulesPriorities } from '../../RulesPriorities/RulesPriorities';
import { RuleAdvancedSection } from '../../RuleAdvancedSection/RuleAdvancedSection';
import { v4 as uuidv4 } from 'uuid';
import { OfferParentComponentProps } from '../../../types/OfferTypesFromCatalogTypes';

export const initialCondition: OfferSuggestedRuleConditionDto = {
  value: {
    type: OfferRuleValueTypeDtoEnum.AT_LEAST,
    from: 1,
    to: null,
  },
  appliesTo: {
    type: OfferTargetTypeDtoEnum.ALL,
    collections: null,
    products: null,
    productMatchType: {
      enabled: false,
      value: OfferCartContentRequirementProductMatchTypeDtoEnum.SAME_PRODUCT,
    },
    variants: null,
  },
  exclusions: {
    collections: {
      enabled: false,
      value: [],
    },
  },
  applyAfterDiscounts: true,
  type: OfferRuleTypeDtoEnum.MINIMUM_ITEMS_QUANTITY,
};

export const FrequentlyBoughtTogetherOffer: React.FC<
  OfferParentComponentProps
> = (props) => {
  const { offerTemplate, offerType, handleOfferDataUpdate } = props;

  const [offerData, setOfferData] =
    useState<OfferFrequentlyBoughtTogetherDto | null>(
      offerTemplate?.template as OfferFrequentlyBoughtTogetherDto
    );

  const { validityChangeHandler } = useOfferFormValidity();

  const aiSuggestionsId = useMemo(
    () =>
      offerData?.priorities?.find(
        (priority) =>
          priority.ruleType === OfferSuggestedRuleTypeDtoEnum.AI_SUGGESTED
      )?.ruleId,
    [offerData?.priorities]
  );

  const aiSuggestionsRuleUpdate = useCallback(
    (aiSuggestionsEnabled?: boolean) => {
      setOfferData((prevState: OfferFrequentlyBoughtTogetherDto | null) => ({
        ...prevState,
        aiSuggestionsEnabled,
        priorities: (prevState?.priorities || []).map((priority) => {
          if (
            priority.ruleType === OfferSuggestedRuleTypeDtoEnum.AI_SUGGESTED
          ) {
            return { ...priority, enabled: aiSuggestionsEnabled };
          }
          return priority;
        }),
      }));
    },
    [setOfferData]
  );

  const duplicateRuleAction = useCallback(
    (ruleId: string) => {
      const clickedIndex = offerData?.rules?.findIndex(
        (rule) => rule.id === ruleId
      );
      const newId = uuidv4();

      if (
        typeof clickedIndex === 'number' &&
        clickedIndex !== -1 &&
        offerData?.rules?.[clickedIndex]
      ) {
        const duplicatedRule: OfferSuggestedRuleDto = {
          ...offerData?.rules?.[clickedIndex],
          id: newId,
          name: `${offerData?.rules?.[clickedIndex].name} (copy)`,
        };

        const newArray = [...(offerData?.rules || [])];
        newArray.splice(clickedIndex! + 1, 0, duplicatedRule);

        setOfferData((prevState: OfferFrequentlyBoughtTogetherDto | null) => ({
          ...prevState,
          rules: newArray,
          priorities: [
            ...(prevState?.priorities || []),
            {
              ruleId: newId,
              enabled: offerData?.rules?.[clickedIndex].enabled,
              ruleType: OfferSuggestedRuleTypeDtoEnum.USER_DEFINED,
              name: `${offerData?.rules?.[clickedIndex].name} (copy)`,
              priority: (prevState?.priorities?.length || 0) + 1,
            },
          ],
        }));
      }
    },
    [setOfferData, offerData]
  );

  const addRuleAction = useCallback(() => {
    const newId = uuidv4();
    setOfferData((prevState: OfferFrequentlyBoughtTogetherDto | null) => ({
      ...prevState,
      priorities: [
        ...(prevState?.priorities || []),
        {
          ruleId: newId,
          enabled: true,
          ruleType: OfferSuggestedRuleTypeDtoEnum.USER_DEFINED,
          name: '',
          priority: (prevState?.priorities?.length || 0) + 1,
        },
      ],
      rules: [
        ...(prevState?.rules || []),
        {
          id: newId,
          enabled: true,
          name: '',
          groups: [
            {
              connector: null,
              conditions: [],
            },
          ],
          suggestedItems: [],
          actionType: null,
        },
      ],
    }));
  }, [setOfferData]);

  const deleteRuleAction = useCallback(
    (ruleId: string) => {
      setOfferData((prevState: OfferFrequentlyBoughtTogetherDto | null) => ({
        ...prevState,
        priorities: (prevState?.priorities || [])
          .filter((rule) => rule.ruleId !== ruleId)
          .map((rule, index) => ({
            ...rule,
            priority: index + 1,
          })),
        rules: (prevState?.rules || []).filter((item) => item.id !== ruleId),
      }));
    },
    [setOfferData]
  );

  const updateRuleAction = useCallback(
    (ruleId: string, body: OfferSuggestedRuleDto) => {
      if (body) {
        setOfferData((prevState: OfferFrequentlyBoughtTogetherDto | null) => ({
          ...prevState,
          priorities: (prevState?.priorities || []).map((priority) => {
            if (priority.ruleId === ruleId) {
              return {
                ...priority,
                name: body.name || '',
                enabled: body.enabled,
              };
            }
            return priority;
          }),
          rules: (prevState?.rules || []).map((rule) => {
            if (rule.id === ruleId) {
              return body;
            }
            return rule;
          }),
        }));
      }
    },
    [setOfferData]
  );

  const changeOrder = useCallback(
    (startIndex: number, endIndex: number) => {
      const result = offerData?.priorities ? [...offerData.priorities] : [];
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      setOfferData((prevState: OfferFrequentlyBoughtTogetherDto | null) => ({
        ...prevState,
        priorities: result.map((item, idx) => ({
          ...item,
          priority: idx + 1,
        })),
      }));
    },
    [setOfferData, offerData?.priorities]
  );

  const changeAdvancedSettings = useCallback(
    (newAdvancedSettings: OfferSuggestedAdvancedSetupDto) => {
      setOfferData((prevState: OfferFrequentlyBoughtTogetherDto | null) => ({
        ...prevState,
        advancedSetup: newAdvancedSettings,
      }));
    },
    [setOfferData]
  );

  useEffect(() => {
    if (
      offerTemplate?.template &&
      offerData &&
      !isEqual(offerData, offerTemplate?.template)
    ) {
      handleOfferDataUpdate(offerData);
    }
  }, [offerData, offerTemplate]);

  // const minimumSpendUpdate = useCallback(
  //   debounce((data: OptionDtoOfferMinimumSpendDto) => {
  //     setOfferData((prevState: OfferFrequentlyBoughtTogetherDto | null) => ({
  //       ...prevState,
  //       minimumSpend: {
  //         ...data,
  //       },
  //     }));
  //   }, 300),
  //   [setOfferData]
  // );

  return (
    <>
      <RulesSection
        offerType={offerType}
        rules={offerData?.rules}
        aiSuggestionsEnabled={offerData?.aiSuggestionsEnabled}
        aiSuggestionsId={aiSuggestionsId}
        aiSuggestionsRuleUpdate={aiSuggestionsRuleUpdate}
        addRuleAction={addRuleAction}
        deleteRuleAction={deleteRuleAction}
        duplicateRuleAction={duplicateRuleAction}
        updateRuleAction={updateRuleAction}
        onFormValidityChange={validityChangeHandler(
          FrequentlyBoughtTogetherTabsEnum.Rules
        )}
      />
      <RulesPriorities
        offerType={offerType}
        rules={offerData?.rules}
        priorities={offerData?.priorities}
        changeOrder={changeOrder}
      />
      <RuleAdvancedSection
        offerType={offerType}
        advancedSetup={offerData?.advancedSetup}
        updateAdvancedSetup={changeAdvancedSettings}
      />
    </>
  );
};
