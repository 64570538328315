import React, { useCallback, useMemo } from 'react';
import {
  Badge,
  Banner,
  Bleed,
  BlockStack,
  Box,
  Button,
  Card,
  Grid,
  Icon,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { CheckSmallIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import {
  DevelopmentPlanDto,
  PlanDiscountDto,
  PlanDtoRead,
  PlanThresholdDetailsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { PlanIntervalDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { formatNumber } from 'core/utils';
import { useConfigureSettings } from 'features/settings/hooks/useConfigureSettings';

type EnterpriseCardProps = {
  planData: PlanDtoRead;
  discount?: PlanDiscountDto | null;
  interval: PlanIntervalDtoEnum;
  highestPlanThreshold?: PlanThresholdDetailsDto;
  developmentPlan?: DevelopmentPlanDto;
};
export const EnterpriseCard: React.FC<EnterpriseCardProps> = ({
  planData,
  discount,
  interval,
  highestPlanThreshold,
  developmentPlan,
}) => {
  const [i18n] = useI18n();
  const {
    requestDevelopmentPlan,
    requestDevelopmentPlanIsLoading,
    requestDevelopmentPlanSuccess,
  } = useConfigureSettings();
  const { pricing, threshold, comparisonDetails, quote } = planData;
  const isAnnual = interval === PlanIntervalDtoEnum.ANNUAL;
  const isDiscounted = useMemo(
    () => !!(pricing?.intervalPrices?.discounted && discount),
    [pricing?.intervalPrices?.discounted, discount]
  );
  const isSelected = useMemo(
    () => planData.isCurrentAnnually || planData.isCurrentMonthly,
    [planData.isCurrentAnnually, planData.isCurrentMonthly]
  );

  const advantages = useMemo(
    () => [
      i18n.translate('AllFeaturesFromPlus'),
      i18n.translate('MaxPublishedPromotions', {
        amount: planData.publishedPromotionsLimit,
        publishedPromotions: <u>{i18n.translate('PublishedPromotions')}</u>,
      }),
      i18n.translate('OrdersPerPeriod', {
        amount: `${formatNumber(
          i18n,
          threshold?.perMonth === -1
            ? isAnnual
              ? highestPlanThreshold?.perYear
              : highestPlanThreshold?.perMonth
            : isAnnual
            ? threshold?.perYear
            : threshold?.perMonth
        )}${threshold?.perMonth === -1 ? '+' : ''}`,
        period: i18n.translate(isAnnual ? 'Year' : 'Month'),
        discountedOrders: <u>{i18n.translate('DiscountedOrders')}</u>,
      }),
      i18n.translate('NoAdditionalCost', {
        fixedPrice: (
          <Text fontWeight='medium' as='span'>
            {i18n.translate('FixedPrice')}
          </Text>
        ),
      }),
      i18n.translate('Priority24'),
      i18n.translate('MultipleStores'),
    ],
    [
      isAnnual,
      threshold,
      planData.publishedPromotionsLimit,
      i18n,
      highestPlanThreshold,
    ]
  );

  const advantagesList = useMemo(
    () =>
      advantages.map((content, i) => (
        <InlineStack key={i} align='start'>
          <Box>
            <Icon source={CheckSmallIcon} />
          </Box>
          <Text as='p' tone='subdued'>
            {content}
          </Text>
        </InlineStack>
      )),
    [advantages]
  );

  const handleContactClick = useCallback(() => {
    window.location.href = `mailto:${quote?.contactEmail}?subject=Request for information regarding Discount Ninja Enterprise plan`;
  }, [quote?.contactEmail]);

  return (
    <BlockStack gap='400'>
      <Text variant='headingLg' as='h1'>
        {i18n.translate('MoreOptions')}
      </Text>
      <Card>
        <BlockStack gap='400'>
          {isDiscounted && (
            <Bleed marginBlockStart='400' marginInline='400'>
              <Box
                paddingInline='400'
                paddingBlock='200'
                background='bg-surface-success'
              >
                <Text
                  as='p'
                  fontWeight='medium'
                  alignment='center'
                  tone='success'
                >
                  {i18n.translate('DiscountEnabled', {
                    percentage: discount?.discountPercentage,
                  })}
                </Text>
              </Box>
            </Bleed>
          )}
          <Grid>
            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
              <Box paddingInlineEnd='400'>
                <BlockStack gap='400'>
                  <InlineStack gap='200' align='start'>
                    <Text as='h2' fontWeight='semibold' variant='headingLg'>
                      {planData.name}
                    </Text>
                    {isSelected && <Badge>{i18n.translate('Current')}</Badge>}
                  </InlineStack>
                  <BlockStack gap='100'>
                    <Text variant='bodySm' as='p' tone='subdued'>
                      {i18n.translate('StartingAt')}
                    </Text>
                    <Text as='h1' id='PlanMonthPrice' variant='heading2xl'>
                      <Text
                        as='span'
                        tone={isDiscounted ? 'subdued' : 'base'}
                        fontWeight={isDiscounted ? 'regular' : 'bold'}
                        textDecorationLine={
                          isDiscounted ? 'line-through' : undefined
                        }
                      >
                        $
                        {isAnnual
                          ? pricing?.intervalPrices?.regular
                              ?.annualPlanPerMonthPrice
                          : pricing?.intervalPrices?.regular
                              ?.monthlyPlanPerMonthPrice}
                      </Text>
                      {isDiscounted && (
                        <Text as='span' tone='critical'>
                          $
                          {isAnnual
                            ? pricing?.intervalPrices?.discounted
                                ?.annualPlanPerMonthPrice
                            : pricing?.intervalPrices?.discounted
                                ?.monthlyPlanPerMonthPrice}
                        </Text>
                      )}

                      <Text as='span' tone='subdued' variant='bodySm'>
                        {i18n.translate('USDMonth')}
                      </Text>
                    </Text>
                    <InlineStack
                      gap='100'
                      align='start'
                      blockAlign='center'
                      wrap={false}
                    >
                      <Text
                        variant='bodySm'
                        tone='base'
                        as='p'
                        textDecorationLine={
                          isDiscounted ? 'line-through' : undefined
                        }
                      >
                        $
                        {formatNumber(
                          i18n,
                          isAnnual
                            ? pricing?.intervalPrices?.regular
                                ?.annualPlanPerYearPrice
                            : pricing?.intervalPrices?.regular
                                ?.monthlyPlanPerYearPrice
                        )}
                      </Text>
                      {isDiscounted && (
                        <Text tone='critical' as='p' variant='bodySm'>
                          $
                          {formatNumber(
                            i18n,
                            isAnnual
                              ? pricing?.intervalPrices?.discounted
                                  ?.annualPlanPerYearPrice
                              : pricing?.intervalPrices?.discounted
                                  ?.monthlyPlanPerYearPrice
                          )}
                        </Text>
                      )}
                      <Text as='p' tone='subdued' variant='bodySm'>
                        {i18n.translate('USDYear')}
                      </Text>
                      {isAnnual && (
                        <Text
                          variant='bodySm'
                          as='p'
                          tone='success'
                          fontWeight='medium'
                          truncate
                        >
                          {i18n.translate('SavePercentage', {
                            amount: pricing?.intervalPrices?.savingPercentage,
                          })}
                        </Text>
                      )}
                    </InlineStack>
                  </BlockStack>
                  {quote?.isAvailable ? (
                    <Button disabled={isSelected} variant='primary'>
                      {i18n.translate('SelectPlan', {
                        plan: planData.name as string,
                      })}
                    </Button>
                  ) : (
                    <Button onClick={handleContactClick} variant='primary'>
                      {i18n.translate('ContactUs')}
                    </Button>
                  )}

                  <Text alignment='center' tone='subdued' as='p'>
                    {i18n.translate('ContactEnterprise', {
                      email: (
                        <Text tone='base' as='span' fontWeight='medium'>
                          {quote?.contactEmail}
                        </Text>
                      ),
                    })}
                  </Text>
                </BlockStack>
              </Box>
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
              <Box paddingInlineStart='400'>
                <Box
                  borderRadius='300'
                  padding='400'
                  background='bg-fill-secondary'
                >
                  <BlockStack gap='100'>
                    <Text as='p' tone='subdued' fontWeight='semibold'>
                      {i18n.translate('YouGet')}
                    </Text>
                    {advantagesList}
                  </BlockStack>
                </Box>
              </Box>
            </Grid.Cell>
          </Grid>
        </BlockStack>
      </Card>
      <Card>
        <BlockStack gap='300'>
          <InlineStack
            gap='500'
            align='space-between'
            blockAlign='center'
            wrap={false}
          >
            <Text as='p' tone='subdued'>
              {i18n.translate('DevelopmentAccess', {
                freeOfCharge: (
                  <Text as='span' fontWeight='medium'>
                    {i18n.translate('FreeOfCharge')}
                  </Text>
                ),
                nonProduction: (
                  <Text as='span' fontWeight='medium'>
                    {i18n.translate('NonProduction')}
                  </Text>
                ),
              })}
            </Text>
            <Box minWidth='fit-content'>
              <Button
                loading={requestDevelopmentPlanIsLoading}
                onClick={requestDevelopmentPlan}
                variant='plain'
                disabled={
                  requestDevelopmentPlanSuccess ||
                  developmentPlan?.hasBeenRequested
                }
              >
                {i18n.translate('RequestFreeAccess')}
              </Button>
            </Box>
          </InlineStack>
          {(requestDevelopmentPlanSuccess ||
            developmentPlan?.hasBeenRequested) && (
            <Banner tone='success'>
              {i18n.translate('RequestSuccessfully')}
            </Banner>
          )}
        </BlockStack>
      </Card>
    </BlockStack>
  );
};
