import { BlockStack, Card, ChoiceList, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { PromotionSummaryTotalDiscountCalculationModeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import React, { useMemo } from 'react';
import { PromotionSummaryTotalDiscountCalculationModeDto } from 'core/api/adminSettings/adminSettingsApi';
import { Element } from 'react-scroll';
type TotalDiscountProps = {
  total: PromotionSummaryTotalDiscountCalculationModeDto;
  setTotal: (
    value: PromotionSummaryTotalDiscountCalculationModeDtoEnum
  ) => void;
};
export const TotalDiscount: React.FC<TotalDiscountProps> = ({
  total,
  setTotal,
}) => {
  const [i18n] = useI18n();

  const totalDiscountOptions = useMemo(
    () =>
      Object.values(PromotionSummaryTotalDiscountCalculationModeDtoEnum).map(
        (value) => ({
          label: i18n.translate(value),
          value: value,
          helpText: i18n.translate(`${value}_HELP`),
        })
      ),
    [i18n]
  );

  return (
    <Element name='TotalDiscount' className='TotalDiscount'>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('TotalDiscountCalculation')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('Subtitle')}
            </Text>
          </BlockStack>
          <ChoiceList
            title=''
            choices={totalDiscountOptions}
            selected={[
              total ||
                PromotionSummaryTotalDiscountCalculationModeDtoEnum.ORDER_DISCOUNTS_ONLY,
            ]}
            onChange={(value) =>
              setTotal(
                value[0] as PromotionSummaryTotalDiscountCalculationModeDtoEnum
              )
            }
          />
        </BlockStack>
      </Card>
    </Element>
  );
};
