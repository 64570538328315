import {
  OrderAnalyticsQueryDto,
  PromotionActivityAnalyticsQueryDto,
  PromotionCodeAnalyticsQueryDto,
  useGetAnalyticsV6ConversionRateQuery,
  useGetAnalyticsV6SessionsQuery,
  useGetAnalyticsV6TotalOrdersQuery,
  useGetAnalyticsV6TotalRevenueQuery,
  usePostAnalyticsV6OrderMutation,
  usePostAnalyticsV6PromotionActivityMutation,
  usePostAnalyticsV6PromotionCodeMutation,
} from 'core/api/adminAnalytics/adminAnalyticsApi';
import { useCallback, useMemo } from 'react';

const APP_NAME = process.env.REACT_APP_APP_NAME || '';

export enum AnalyticsFetchTypeEnum {
  TOTAL_DN_REVENUE = 'TOTAL_DN_REVENUE',
  DN_CONVERSION_RATE = 'DN_CONVERSION_RATE',
  TOTAL_DN_ORDERS = 'TOTAL_DN_ORDERS',
  DN_SESSIONS = 'DN_SESSIONS',
  PROMOTION_ACTIVITY = 'PROMOTION_ACTIVITY',
  DETAILED_ORDER = 'DETAILED_ORDER',
  DETAILED_CODE = 'DETAILED_CODE',
}

export const useConfigureAnalytics = (fetchType?: AnalyticsFetchTypeEnum) => {
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || '';

  const chartDetailsQuery: any = useMemo(() => {
    switch (fetchType) {
      case AnalyticsFetchTypeEnum.DN_CONVERSION_RATE:
        return useGetAnalyticsV6ConversionRateQuery;
      case AnalyticsFetchTypeEnum.DN_SESSIONS:
        return useGetAnalyticsV6SessionsQuery;
      case AnalyticsFetchTypeEnum.TOTAL_DN_ORDERS:
        return useGetAnalyticsV6TotalOrdersQuery;
      case AnalyticsFetchTypeEnum.TOTAL_DN_REVENUE:
        return useGetAnalyticsV6TotalRevenueQuery;
      default:
        return useGetAnalyticsV6TotalOrdersQuery;
    }
  }, [fetchType]);

  const [
    getPromotionActivityListMutation,
    {
      isLoading: promotionActivityListIsLoading,
      data: promotionActivityListData,
    },
  ] = usePostAnalyticsV6PromotionActivityMutation();

  const getPromotionActivityList = useCallback(
    async (setup: PromotionActivityAnalyticsQueryDto) => {
      await getPromotionActivityListMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: setup,
      })
        .unwrap()
        .catch((err) => null);
    },
    [getPromotionActivityListMutation]
  );

  const [
    getPromotionCodesListMutation,
    { isLoading: promotionCodesListIsLoading, data: promotionCodesListData },
  ] = usePostAnalyticsV6PromotionCodeMutation();

  const getPromotionCodesList = useCallback(
    async (setup: PromotionCodeAnalyticsQueryDto) => {
      await getPromotionCodesListMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: setup,
      })
        .unwrap()
        .catch((err) => null);
    },
    [getPromotionCodesListMutation]
  );

  const [
    getOrderListMutation,
    { isLoading: orderListIsLoading, data: orderListData },
  ] = usePostAnalyticsV6OrderMutation();

  const getOrderList = useCallback(
    async (setup: OrderAnalyticsQueryDto) => {
      await getOrderListMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: setup,
      })
        .unwrap()
        .catch((err) => null);
    },
    [getOrderListMutation]
  );

  const reportListFetchType = useMemo(() => {
    switch (fetchType) {
      case AnalyticsFetchTypeEnum.DETAILED_CODE:
        return AnalyticsFetchTypeEnum.DETAILED_CODE;
      case AnalyticsFetchTypeEnum.PROMOTION_ACTIVITY:
        return AnalyticsFetchTypeEnum.PROMOTION_ACTIVITY;
      case AnalyticsFetchTypeEnum.DETAILED_ORDER:
        return AnalyticsFetchTypeEnum.DETAILED_ORDER;
      default:
        return AnalyticsFetchTypeEnum.DETAILED_ORDER;
    }
  }, [fetchType]);

  const reportListQuery = useMemo(
    () => ({
      [AnalyticsFetchTypeEnum.PROMOTION_ACTIVITY]: {
        getReportList: getPromotionActivityList,
        reportListIsLoading: promotionActivityListIsLoading,
        reportListData: promotionActivityListData,
      },
      [AnalyticsFetchTypeEnum.DETAILED_CODE]: {
        getReportList: getPromotionCodesList,
        reportListIsLoading: promotionCodesListIsLoading,
        reportListData: promotionCodesListData,
      },
      [AnalyticsFetchTypeEnum.DETAILED_ORDER]: {
        getReportList: getOrderList,
        reportListIsLoading: orderListIsLoading,
        reportListData: orderListData,
      },
    }),
    [
      promotionActivityListIsLoading,
      promotionActivityListData,
      promotionCodesListIsLoading,
      promotionCodesListData,
      orderListIsLoading,
      orderListData,
    ]
  );

  const { getReportList, reportListData, reportListIsLoading } =
    reportListQuery[reportListFetchType];

  return {
    chartDetailsQuery,
    getOrderList,
    getReportList,
    reportListData,
    reportListIsLoading,
  };
};
