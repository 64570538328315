import { BlockStack, Box, Checkbox, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  OfferCombinationDetailsDto,
  OfferCombinationsDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CanCombineWithModal } from '../CanCombineWithModal/CanCombineWithModal';

export type CombinationsShippingDiscountProps = {
  setIsChanged: (val: boolean) => void;
  onCombinationsDraftStateUpdate: (data: OfferCombinationsDto) => void;
  orderDiscounts?: OfferCombinationDetailsDto;
  productDiscounts?: OfferCombinationDetailsDto;
};

export const CombinationsShippingDiscount: React.FC<
  CombinationsShippingDiscountProps
> = (props) => {
  const {
    productDiscounts,
    orderDiscounts,
    onCombinationsDraftStateUpdate,
    setIsChanged,
  } = props;
  const [i18n] = useI18n();

  const productDiscountsCheckedDefault = useMemo(
    () => !!(productDiscounts?.isEnforced || productDiscounts?.enabled),
    [productDiscounts?.isEnforced, productDiscounts?.enabled]
  );
  const orderDiscountsDiscountsCheckedDefault = useMemo(
    () => !!(orderDiscounts?.isEnforced || orderDiscounts?.enabled),
    [orderDiscounts?.isEnforced, orderDiscounts?.enabled]
  );

  const [productDiscountsChecked, setProductDiscountsChecked] =
    useState<boolean>(productDiscountsCheckedDefault);
  const [orderDiscountsChecked, setOrderDiscountsChecked] = useState<boolean>(
    orderDiscountsDiscountsCheckedDefault
  );

  const handleOrderDiscountsChange = useCallback(
    (newChecked: boolean) => {
      setOrderDiscountsChecked(newChecked);
      setIsChanged(true);
    },
    [setOrderDiscountsChecked, setIsChanged]
  );
  const handleProductDiscountsChange = useCallback(
    (newChecked: boolean) => {
      setProductDiscountsChecked(newChecked);
      setIsChanged(true);
    },
    [setProductDiscountsChecked, setIsChanged]
  );

  useEffect(() => {
    onCombinationsDraftStateUpdate({
      order: {
        enabled: orderDiscountsChecked,
        stackingBehavior: orderDiscounts?.stackingBehavior,
      },
      product: {
        enabled: productDiscountsChecked,
        stackingBehavior: productDiscounts?.stackingBehavior,
      },
    });
  }, [
    productDiscountsChecked,
    orderDiscountsChecked,
    productDiscounts,
    orderDiscounts,
    onCombinationsDraftStateUpdate,
  ]);

  return (
    <>
      <Box paddingBlockEnd='300'>
        <Text as='h2'>
          {i18n.translate('CombinationsShippingDiscount.Title')}
        </Text>
      </Box>
      <BlockStack>
        <div>
          <Checkbox
            label={i18n.translate(
              'CombinationsShippingDiscount.ProductDiscounts'
            )}
            checked={productDiscountsChecked}
            disabled={productDiscounts?.isEnforced}
            onChange={handleProductDiscountsChange}
          />
          {productDiscountsChecked && (
            <div className='CombinationsCheckboxInfo'>
              <Box paddingInlineStart='600'>
                <Text as='span' tone='subdued'>
                  <CanCombineWithModal
                    type={productDiscounts?.type}
                    count={productDiscounts?.combinedCount || 0}
                    text={i18n.translate(
                      'CombinationsShippingDiscount.CheckboxInfo.ProductDiscounts'
                    )}
                    modalTitle={i18n.translate(
                      'CombinationsShippingDiscount.ProductDiscountModalTitle'
                    )}
                  />
                  {i18n.translate(
                    'CombinationsShippingDiscount.CheckboxInfo.SetToCombine',
                    {
                      irregular:
                        (productDiscounts?.combinedCount || 0) > 1
                          ? i18n.translate(
                              'CombinationsShippingDiscount.CheckboxInfo.are'
                            )
                          : i18n.translate(
                              'CombinationsShippingDiscount.CheckboxInfo.is'
                            ),
                    }
                  )}
                </Text>
              </Box>
            </div>
          )}
        </div>
        <div>
          <Checkbox
            label={i18n.translate(
              'CombinationsShippingDiscount.OrderDiscounts'
            )}
            checked={orderDiscountsChecked}
            disabled={orderDiscounts?.isEnforced}
            onChange={handleOrderDiscountsChange}
          />
          {orderDiscountsChecked && (
            <div className='CombinationsCheckboxInfo'>
              <Box paddingInlineStart='600'>
                <Text as='span' tone='subdued'>
                  <CanCombineWithModal
                    type={orderDiscounts?.type}
                    count={orderDiscounts?.combinedCount || 0}
                    text={i18n.translate(
                      'CombinationsShippingDiscount.CheckboxInfo.OrderDiscounts'
                    )}
                    modalTitle={i18n.translate(
                      'CombinationsShippingDiscount.OrderDiscountModalTitle'
                    )}
                  />
                  {i18n.translate(
                    'CombinationsShippingDiscount.CheckboxInfo.SetToCombine',
                    {
                      irregular:
                        (orderDiscounts?.combinedCount || 0) > 1
                          ? i18n.translate(
                              'CombinationsShippingDiscount.CheckboxInfo.are'
                            )
                          : i18n.translate(
                              'CombinationsShippingDiscount.CheckboxInfo.is'
                            ),
                    }
                  )}
                </Text>
              </Box>
            </div>
          )}
        </div>
      </BlockStack>
    </>
  );
};
