import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  Button,
  ButtonGroup,
  Text,
  Checkbox,
  Modal,
  Card,
  BlockStack,
  Box,
} from '@shopify/polaris';
import { DiscountType } from '../../enums/DiscountType';
import { DiscountValueTypeEnum } from '../../enums/DiscountValueType';
import { useI18n } from '@shopify/react-i18n';
import { NumberFormatValues } from 'react-number-format';
import {
  getInitialActiveDiscountValueType,
  getInitialSelectedDiscountType,
  getInitialTextfieldValue,
} from './utils/utils';
import { useAppDispatch } from 'core/hooks';
import { setSelectedOfferType } from 'core/store/offersWizardSlice';
import { OfferTypesFromCatalogData } from '../../consts/OfferTypesFromCatalogData';
import { OfferTypesFromCatalogDataType } from '../../types/OfferTypesFromCatalogTypes';
import { usePrevious } from 'core/hooks/usePrevious';
import {
  OfferLevelDto,
  OfferSalesDiscountSpecificationDto,
  OfferTieredDiscountTypeDto,
  OfferSalesDiscountTypeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  OfferLevelDtoEnum,
  OfferSalesDiscountTypeDtoEnum,
  OfferTieredDiscountTypeDtoEnum,
  OfferTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { DiscountApplySection } from './components/DiscountApplySection/DiscountApplySection';
import { DiscountSelectSection } from './components/DiscountSelectSection/DiscountSelectSection';

type DiscountProps = {
  specification?: OfferSalesDiscountSpecificationDto;
  level?: OfferLevelDto;
  onLevelUpdate?: (level: OfferLevelDto) => void;
  onSpecificationUpdate?: (
    specification: OfferSalesDiscountSpecificationDto
  ) => void;
  offerType: OfferTypeDtoEnum;
  type?: OfferTieredDiscountTypeDto;
  isLoading?: boolean;
  onTypeUpdate?: (type: OfferTieredDiscountTypeDtoEnum) => void;
  onValidityStateChange?(valid: boolean): void;
};

export const Discount: React.FC<DiscountProps> = (props) => {
  const {
    specification,
    level,
    onLevelUpdate,
    onSpecificationUpdate,
    offerType,
    type,
    isLoading,
    onTypeUpdate,
    onValidityStateChange,
  } = props;
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();
  const [activeDiscountValueType, setActiveDiscountValueType] =
    useState<DiscountValueTypeEnum>(
      getInitialActiveDiscountValueType(specification?.type, type)
    );
  const [percentageTextFieldValue, setPercentageTextFieldValue] =
    useState<NumberFormatValues>(
      getInitialTextfieldValue(
        i18n,
        specification,
        type,
        specification?.percentage as number
      )
    );
  const [fixedAmountTextFieldValue, setFixedAmountTextFieldValue] =
    useState<NumberFormatValues>(
      getInitialTextfieldValue(
        i18n,
        specification,
        type,
        specification?.fixedAmount as number
      )
    );
  const [fixedPriceTextFieldValue, setFixedPriceTextFieldValue] =
    useState<NumberFormatValues>(
      getInitialTextfieldValue(
        i18n,
        specification,
        type,
        specification?.fixedPricePerUnit as number
      )
    );
  const [selectedDiscountType, setSelectedDiscountType] =
    useState<DiscountType>(getInitialSelectedDiscountType(level));
  const [showModal, setShowModal] = useState<boolean>(false);
  const [doNotShowModalAgain, setDoNotShowModalAgain] =
    useState<boolean>(false);
  const [discountTypeInQueue, setDiscountTypeInQueue] =
    useState<DiscountType | null>(null);
  const spendAmountOffer = useMemo(
    () =>
      [
        OfferTypeDtoEnum.TIERED_SPEND_X_GET_Y,
        OfferTypeDtoEnum.TIERED_BOGO,
        OfferTypeDtoEnum.VOLUME_DISCOUNT,
      ].includes(offerType),
    [offerType]
  );

  const prevSelectedDiscountType = usePrevious(selectedDiscountType);

  // discount code offer types data handlers

  useEffect(() => {
    if (
      [
        OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_GROUP,
        OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_REGULAR,
        OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_APP,
      ].includes(offerType) &&
      specification
    ) {
      setActiveDiscountValueType(
        getInitialActiveDiscountValueType(specification?.type, type)
      );
      setPercentageTextFieldValue(
        getInitialTextfieldValue(
          i18n,
          specification,
          type,
          specification?.percentage as number
        )
      );
      setFixedAmountTextFieldValue(
        getInitialTextfieldValue(
          i18n,
          specification,
          type,
          specification?.fixedAmount as number
        )
      );
    }
  }, [specification]);

  // request data update handlers
  useEffect(() => {
    if (!selectedDiscountType || !onLevelUpdate) {
      return;
    } else if (selectedDiscountType === DiscountType.OrderDiscount) {
      prevSelectedDiscountType &&
        selectedDiscountType !== prevSelectedDiscountType &&
        onLevelUpdate(OfferLevelDtoEnum.ORDER);
    } else {
      prevSelectedDiscountType &&
        selectedDiscountType !== prevSelectedDiscountType &&
        onLevelUpdate(OfferLevelDtoEnum.LINE);
    }
  }, [selectedDiscountType, prevSelectedDiscountType, onLevelUpdate]);

  useEffect(() => {
    if (!onSpecificationUpdate || !activeDiscountValueType || !specification) {
      return;
    }

    let type: OfferSalesDiscountTypeDto;
    switch (activeDiscountValueType) {
      case DiscountValueTypeEnum.FixedAmount:
        type = OfferSalesDiscountTypeDtoEnum.FIXED_AMOUNT;
        break;
      case DiscountValueTypeEnum.FixedPricePerUnit:
        type = OfferSalesDiscountTypeDtoEnum.FIXED_PRICE_PER_UNIT;
        break;
      default:
        type = OfferSalesDiscountTypeDtoEnum.PERCENTAGE;
        break;
    }

    const getDiscountValueRequestObject =
      (): OfferSalesDiscountSpecificationDto => {
        switch (activeDiscountValueType) {
          case DiscountValueTypeEnum.FixedAmount:
            return {
              type: type,
              fixedAmount: fixedAmountTextFieldValue.floatValue,
              percentage: null,
              fixedPricePerUnit: null,
            };
          case DiscountValueTypeEnum.FixedPricePerUnit:
            return {
              type: type,
              fixedPricePerUnit: fixedPriceTextFieldValue.floatValue,
              percentage: null,
              fixedAmount: null,
            };
          default:
            return {
              type: type,
              percentage: percentageTextFieldValue.floatValue,
              fixedAmount: null,
              fixedPricePerUnit: null,
            };
        }
      };

    onSpecificationUpdate(getDiscountValueRequestObject());
  }, [
    activeDiscountValueType,
    onSpecificationUpdate,
    fixedAmountTextFieldValue,
    fixedPriceTextFieldValue,
    percentageTextFieldValue,
  ]);

  useEffect(() => {
    if (!onTypeUpdate || !activeDiscountValueType) {
      return;
    }

    if (activeDiscountValueType === DiscountValueTypeEnum.FixedAmount) {
      onTypeUpdate(OfferTieredDiscountTypeDtoEnum.FIXED_AMOUNT);
    } else {
      onTypeUpdate(OfferTieredDiscountTypeDtoEnum.PERCENTAGE);
    }
  }, [activeDiscountValueType, onTypeUpdate]);

  // discount type handlers
  const getNewOfferType = useCallback(
    (discountType: DiscountValueTypeEnum): OfferTypesFromCatalogDataType => {
      switch (discountType) {
        case DiscountValueTypeEnum.FixedAmount:
          return OfferTypesFromCatalogData['Sales'][1];
        case DiscountValueTypeEnum.FixedPricePerUnit:
          return OfferTypesFromCatalogData['Sales'][2];
        case DiscountValueTypeEnum.Percentage:
          return OfferTypesFromCatalogData['Sales'][0];
      }
    },
    []
  );

  const onDiscountTypeClick = useCallback(
    (discountType: string) => {
      setActiveDiscountValueType(discountType as DiscountValueTypeEnum);
      if (!specification) {
        return;
      }
      dispatch(
        setSelectedOfferType(
          getNewOfferType(discountType as DiscountValueTypeEnum)
        )
      );
    },
    [setActiveDiscountValueType, dispatch, getNewOfferType]
  );

  const onActiveDiscountTypeSelect = useCallback(
    (discount: string) => {
      if (discount === selectedDiscountType) {
        return;
      }

      if (!doNotShowModalAgain) {
        setShowModal(true);
        setDiscountTypeInQueue(discount as DiscountType);
      } else {
        setSelectedDiscountType(discount as DiscountType);
      }
    },
    [setSelectedDiscountType, doNotShowModalAgain, selectedDiscountType]
  );

  // awareness modal action handlers
  const onModalCancelClick = useCallback(() => {
    setDiscountTypeInQueue(null);
    setShowModal(false);
    setDoNotShowModalAgain(false);
  }, [setDiscountTypeInQueue]);

  const onModalContinueClick = useCallback(() => {
    if (discountTypeInQueue) {
      setSelectedDiscountType(discountTypeInQueue);
      setDiscountTypeInQueue(null);
      setShowModal(false);
      if (doNotShowModalAgain) {
        localStorage.setItem('doNotShowAwarenessDiscountTypeModalAgain', '1');
      }
    }
  }, [discountTypeInQueue, setSelectedDiscountType, doNotShowModalAgain]);

  useEffect(() => {
    const doNotShowAwarenessDiscountTypeModalAgain = localStorage.getItem(
      'doNotShowAwarenessDiscountTypeModalAgain'
    );
    if (doNotShowAwarenessDiscountTypeModalAgain === '1') {
      setDoNotShowModalAgain(true);
    }
  }, []);

  // input field data handlers
  const currentTextFieldObject: NumberFormatValues = useMemo(() => {
    switch (activeDiscountValueType) {
      case DiscountValueTypeEnum.FixedAmount:
        return fixedAmountTextFieldValue;
      case DiscountValueTypeEnum.FixedPricePerUnit:
        return fixedPriceTextFieldValue;
      default:
        return percentageTextFieldValue;
    }
  }, [
    activeDiscountValueType,
    fixedAmountTextFieldValue,
    fixedPriceTextFieldValue,
    percentageTextFieldValue,
  ]);

  const onTextFieldValueUpdate = useCallback(
    (value: NumberFormatValues) => {
      switch (activeDiscountValueType) {
        case DiscountValueTypeEnum.FixedAmount:
          return setFixedAmountTextFieldValue(value);
        case DiscountValueTypeEnum.FixedPricePerUnit:
          return setFixedPriceTextFieldValue(value);
        default:
          return setPercentageTextFieldValue(value);
      }
    },
    [activeDiscountValueType]
  );

  const validityChangeHandler = useCallback(
    (invalid: boolean) => {
      if (onValidityStateChange) {
        onValidityStateChange(!invalid);
      }
    },
    [onValidityStateChange]
  );

  return (
    <>
      <Card roundedAbove='sm' padding='400'>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='h2' variant='headingSm'>
              {i18n.translate('Discount.discount') || 'Discount'}
            </Text>
            <Text as='span' tone={'subdued'}>
              {i18n.translate(
                'Discount.discountSubtitle.' +
                  offerType +
                  (offerType === OfferTypeDtoEnum.SALES_FIXED_AMOUNT
                    ? selectedDiscountType.replaceAll(' ', '')
                    : '')
              )}
            </Text>
          </BlockStack>
          <BlockStack gap='100'>
            <DiscountApplySection
              i18n={i18n}
              offerType={offerType}
              activeDiscountValueType={activeDiscountValueType}
              spendAmountOffer={spendAmountOffer}
              textFieldValue={currentTextFieldObject}
              onDiscountTypeClick={onDiscountTypeClick}
              setTextFieldValue={onTextFieldValueUpdate}
              onValidityStateChange={validityChangeHandler}
            />
            {((offerType === OfferTypeDtoEnum.SALES_PERCENTAGE &&
              !specification?.percentage) ||
              (offerType === OfferTypeDtoEnum.SALES_FIXED_AMOUNT &&
                !specification?.fixedAmount) ||
              (offerType === OfferTypeDtoEnum.SALES_FIXED_PRICE &&
                !specification?.fixedPricePerUnit)) && (
              <Box paddingBlockEnd='300'></Box>
            )}

            {offerType !== OfferTypeDtoEnum.TIERED_BOGO &&
              offerType !== OfferTypeDtoEnum.VOLUME_DISCOUNT && (
                <>
                  <DiscountSelectSection
                    i18n={i18n}
                    activeDiscountValueType={activeDiscountValueType}
                    selectedDiscountType={selectedDiscountType}
                    onActiveDiscountTypeSelect={onActiveDiscountTypeSelect}
                    textFieldValue={currentTextFieldObject}
                    offerType={offerType}
                    isLoading={isLoading}
                  />
                </>
              )}
          </BlockStack>
        </BlockStack>
      </Card>
      <Modal open={showModal} onClose={onModalCancelClick} title=''>
        <Card>
          <BlockStack gap='200'>
            <Text as='h2' variant='headingSm'>
              {i18n.translate('Discount.awarenessInfoText') ||
                'Product and order discounts'}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('Discount.awarenessContent') ||
                'Product and Order discounts can behave differently when combined with other offers. Please review the settings in the "Combinations" section below.'}
            </Text>
            <Checkbox
              label={
                i18n.translate('Discount.dontShowAgain') ||
                'Don"t show this message again'
              }
              checked={doNotShowModalAgain}
              onChange={(value: boolean) => setDoNotShowModalAgain(value)}
            />
          </BlockStack>
          <BlockStack inlineAlign='end'>
            <ButtonGroup>
              <Button onClick={onModalCancelClick}>
                {i18n.translate('Discount.discard') || 'Discard'}
              </Button>
              <Button onClick={onModalContinueClick} variant='primary'>
                {i18n.translate('Discount.continue') || 'Continue'}
              </Button>
            </ButtonGroup>
          </BlockStack>
        </Card>
      </Modal>
    </>
  );
};
