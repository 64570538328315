import React from 'react';
import StyleComponentHeader from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components/StyleComponentHeader/StyleComponentHeader';
import { useI18n } from '@shopify/react-i18n';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import { Link, Text } from '@shopify/polaris';
import { AnnouncementBarOfferPositionDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';

type OfferPositionProps = {
  position: AnnouncementBarOfferPositionDtoEnum;
  setPosition: (value: AnnouncementBarOfferPositionDtoEnum) => void;
  onSystemReset: () => void;
};
export const OfferPosition: React.FC<OfferPositionProps> = ({
  position,
  setPosition,
  onSystemReset,
}) => {
  const [i18n] = useI18n();

  const positionOptions = Object.values(
    AnnouncementBarOfferPositionDtoEnum
  ).map((value) => {
    return {
      value: value,
      label: i18n.translate(`${value}`),
    };
  });
  return (
    <div className='OfferPosition'>
      <StyleComponentHeader
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            onAction: () => onSystemReset(),
          },
        ]}
        label={i18n.translate(`Position`)}
      >
        <SelectOptions
          options={positionOptions}
          onOptionSelect={(value) =>
            setPosition(value as AnnouncementBarOfferPositionDtoEnum)
          }
          selectedOption={
            position ||
            AnnouncementBarOfferPositionDtoEnum.ANNOUNCEMENT_BAR_PRIMARY_BANNER
          }
          label=''
        />
        <Text as='p'>
          {i18n.translate('HelpText')}
          <Link>{i18n.translate('LearnMore')}</Link>
        </Text>
      </StyleComponentHeader>
    </div>
  );
};
