import React, { useMemo, useState } from 'react';
import { BlockStack, ChoiceList, Modal } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { TriggerTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
type ChangeTriggerModalProps = {
  isOpen: boolean;
  selectedTrigger: TriggerTypeDtoEnum;
  onSave: (value: TriggerTypeDtoEnum) => void;
  onClose: () => void;
};
const ChangeTriggerModal: React.FC<ChangeTriggerModalProps> = ({
  isOpen,
  selectedTrigger,
  onClose,
  onSave,
}) => {
  const [i18n] = useI18n();
  const [trigger, setTrigger] = useState<TriggerTypeDtoEnum>(selectedTrigger);

  const triggerTypeOptions = useMemo(
    () =>
      Object.values(TriggerTypeDtoEnum).map((value) => ({
        label: i18n.translate(`${value}_TRIGGER`),
        helpText: i18n.translate(`${value}_TIP`),
        value: value,
      })),
    [i18n]
  );
  return (
    <Modal
      open={isOpen}
      title={i18n.translate('ChangeTrigger')}
      onClose={onClose}
      primaryAction={{
        content: i18n.translate('Change'),
        disabled: selectedTrigger === trigger,
        onAction: () => {
          onSave(trigger);
          onClose();
        },
      }}
      secondaryActions={[
        { content: i18n.translate('Cancel'), onAction: onClose },
      ]}
    >
      <Modal.Section>
        <BlockStack gap='400'>
          <ChoiceList
            title=''
            choices={triggerTypeOptions}
            selected={[trigger]}
            onChange={(value) => setTrigger(value[0] as TriggerTypeDtoEnum)}
          />
        </BlockStack>
      </Modal.Section>
    </Modal>
  );
};

export default ChangeTriggerModal;
