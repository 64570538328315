import { GetSettingsV6StyleApiArg } from 'core/api/adminSettings/adminSettingsApi';
import {
  GetPresetListResponseDto,
  GetWidgetsV6PresetsByIdAffectedPromotionsOthersApiArg,
  GetWidgetsV6PresetsShopDefaultShopLevelApiArg,
  ListApiArg,
  PresetsApiArg,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { PresetBoundariesTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { setToastMessages } from 'core/store/offersWizardSlice';
import { setBrandColors } from 'core/store/settingsSlice';
import {
  setDefaultPromotionLevelStylePreset,
  setDefaultShopLevelStylePreset,
  setPromotionLevelStylePresets,
  setShopLevelStylePresets,
} from 'core/store/widgetsSlice';
import resolveEnvVar from 'env-var-resolver';
import { useState, useCallback, Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';

const stylePresetsApiURL = `${resolveEnvVar(
  'REACT_APP_WIDGETS_ADMIN_API_URL'
)}/widgets/v6`;

const settingsApiURL = `${resolveEnvVar(
  'REACT_APP_SETTINGS_ADMIN_API_URL'
)}/settings/v6`;

const useFetchPresetList = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');

  const [stylePresetsLibraryIsFetching, setStylePresetsLibraryIsFetching] =
    useState(false);

  const [applyToAllPromotionsIsFetching, setApplyToAllPromotionsIsFetching] =
    useState(false);

  const [brandColorsIsFetching, setBrandColorsIsFetching] = useState(false);

  const getCurrentShopLevelDefaultStylePreview = useCallback(
    async (args: GetWidgetsV6PresetsShopDefaultShopLevelApiArg) => {
      setStylePresetsLibraryIsFetching(true);
      try {
        const response = await fetch(
          `${stylePresetsApiURL}/presets/shopDefault/shopLevel`,
          {
            method: 'GET',
            headers: {
              'X-LimoniApps-AppName': args['X-LimoniApps-AppName'],
              'X-LimoniApps-AppSecret': args['X-LimoniApps-AppSecret'],
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setStylePresetsLibraryIsFetching(false);
        dispatch(setDefaultShopLevelStylePreset(data));
      } catch (error) {
        dispatch(
          setToastMessages({
            error: true,
            message: 'ShopLevelDefaultStylePreviewFailed',
          })
        );
        setStylePresetsLibraryIsFetching(false);
        dispatch(setDefaultShopLevelStylePreset(null));
      }
    },
    [stylePresetsApiURL, token, dispatch]
  );

  const getCurrentPromotionLevelDefaultStylePreview = useCallback(
    async (args: GetWidgetsV6PresetsShopDefaultShopLevelApiArg) => {
      setStylePresetsLibraryIsFetching(true);
      try {
        const response = await fetch(
          `${stylePresetsApiURL}/presets/shopDefault/promotionLevel`,
          {
            method: 'GET',
            headers: {
              'X-LimoniApps-AppName': args['X-LimoniApps-AppName'],
              'X-LimoniApps-AppSecret': args['X-LimoniApps-AppSecret'],
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setStylePresetsLibraryIsFetching(false);
        dispatch(setDefaultPromotionLevelStylePreset(data));
      } catch (error) {
        dispatch(
          setToastMessages({
            error: true,
            message: 'PromotionLevelDefaultStylePreviewFailed',
          })
        );
        setStylePresetsLibraryIsFetching(false);
        dispatch(setDefaultPromotionLevelStylePreset(null));
      }
    },
    [stylePresetsApiURL, token, dispatch]
  );

  const fetchApplyToAllPromotionsData = useCallback(
    async (args: GetWidgetsV6PresetsByIdAffectedPromotionsOthersApiArg) => {
      setApplyToAllPromotionsIsFetching(true);
      try {
        const response = await fetch(
          `${stylePresetsApiURL}/presets/${args.id}/affectedPromotions/others`,
          {
            method: 'GET',
            headers: {
              'X-LimoniApps-AppName': args['X-LimoniApps-AppName'],
              'X-LimoniApps-AppSecret': args['X-LimoniApps-AppSecret'],
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setApplyToAllPromotionsIsFetching(false);
        return data;
      } catch (error) {
        dispatch(
          setToastMessages({
            error: true,
            message: 'FetchingDataFailed',
          })
        );
        setApplyToAllPromotionsIsFetching(false);
        return null;
      }
    },
    [stylePresetsApiURL, token]
  );

  const fetchList = useCallback(
    async (
      args: ListApiArg,
      setState?: Dispatch<SetStateAction<GetPresetListResponseDto | null>>
    ) => {
      setStylePresetsLibraryIsFetching(true);
      try {
        const response = await fetch(
          `${stylePresetsApiURL}/presets/list?${
            'boundaries=' + args.boundaries
          }${args.type ? '&type=' + args.type : ''}&page=${args.page}&search=${
            args.search
          }&itemsPerPage=${args.itemsPerPage || 8}`,
          {
            method: 'GET',
            headers: {
              'X-LimoniApps-AppName': args['X-LimoniApps-AppName'],
              'X-LimoniApps-AppSecret': args['X-LimoniApps-AppSecret'],
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setStylePresetsLibraryIsFetching(false);
        if (setState) {
          setState(data);
        } else {
          dispatch(
            args.boundaries === PresetBoundariesTypeDtoEnum.SHOP_LEVEL
              ? setShopLevelStylePresets(data)
              : setPromotionLevelStylePresets(data)
          );
        }
      } catch (error) {
        dispatch(
          setToastMessages({
            error: true,
            message: 'FetchingDataFailed',
          })
        );
        setStylePresetsLibraryIsFetching(false);
        if (setState) {
          setState(null);
        } else {
          dispatch(
            args.boundaries === PresetBoundariesTypeDtoEnum.SHOP_LEVEL
              ? setShopLevelStylePresets(null)
              : setPromotionLevelStylePresets(null)
          );
        }
      }
    },
    [stylePresetsApiURL, token, dispatch]
  );

  const fetchAllListForSelector = useCallback(async (args: PresetsApiArg) => {
    setStylePresetsLibraryIsFetching(true);
    try {
      const response = await fetch(
        `${stylePresetsApiURL}/lookup/presets?${
          'boundaries=' + args.boundaries
        }&itemsPerPage=0&page=1`,
        {
          method: 'GET',
          headers: {
            'X-LimoniApps-AppName': args['X-LimoniApps-AppName'],
            'X-LimoniApps-AppSecret': args['X-LimoniApps-AppSecret'],
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setStylePresetsLibraryIsFetching(false);
      const data = await response.json();
      return data;
    } catch (error) {
      dispatch(
        setToastMessages({
          error: true,
          message: 'FetchingDataFailed',
        })
      );
      setStylePresetsLibraryIsFetching(false);
      return null;
    }
  }, []);

  const fetchBrandColors = useCallback(
    async (args: GetSettingsV6StyleApiArg) => {
      setBrandColorsIsFetching(true);
      try {
        const response = await fetch(`${settingsApiURL}/style`, {
          method: 'GET',
          headers: {
            'X-LimoniApps-AppName': args['X-LimoniApps-AppName'],
            'X-LimoniApps-AppSecret': args['X-LimoniApps-AppSecret'],
            Authorization: `Bearer ${token}`,
          },
        });
        setBrandColorsIsFetching(false);
        const data = await response.json();
        dispatch(setBrandColors(data));
      } catch (error) {
        dispatch(
          setToastMessages({
            error: true,
            message: 'FetchingBrandColorsFailed',
          })
        );
        setBrandColorsIsFetching(false);
      }
    },
    []
  );

  return {
    fetchList,
    getCurrentShopLevelDefaultStylePreview,
    getCurrentPromotionLevelDefaultStylePreview,
    stylePresetsLibraryIsFetching,
    fetchAllListForSelector,
    fetchApplyToAllPromotionsData,
    applyToAllPromotionsIsFetching,
    fetchBrandColors,
    brandColorsIsFetching,
  };
};

export default useFetchPresetList;
