import {
  LegacyCard,
  DataTable,
  Pagination,
  Link,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { ModalCustom } from 'core/components/ModalCustom/ModalCustom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './CanCombineWithModal.scss';
import {
  OfferCombinationListDto,
  OfferCombinationTypeDto,
  OfferRelationalCombinationTypeDto,
  PagedResponseDtoOfferCombinationListDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  setLastCombinedWithTypeFetched,
  setLastCombinedWithTypePage,
} from 'core/store/offersWizardSlice';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { Loader } from 'core/components';
import {
  recordsPerPage,
  useConfigureOffers,
} from 'features/promotions/hooks/useConfigureOffers';
import { usePrevious } from 'core/hooks/usePrevious';
import {
  OfferCombinationTypeDtoEnum,
  OfferRelationalCombinationTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';

export type CanCombineWithModalProps = {
  type?: OfferRelationalCombinationTypeDto;
  count: number;
  text: string;
  modalTitle: string;
};

export type CanCombineWithTableData = {
  title: { name: string; promotion: string };
  type: { name: string; promotion: string };
  combinesWith: string[];
  url: string;
};

export const CanCombineWithModal: React.FC<CanCombineWithModalProps> = (
  props
) => {
  const { count, text, modalTitle, type } = props;
  const [i18n] = useI18n();

  const dispatch = useAppDispatch();
  const {
    combinedWithList,
    lastCombinedWithTypeFetched,
    currentCombinationIsFetching,
    currentOfferData,
    typeListIsFetching,
  } = useAppSelector((state) => state.offersWizard);

  const { getCurrentTypeOfList, getOfTypeListIsLoading } = useConfigureOffers();

  const combinedWithTypeNeeded:
    | PagedResponseDtoOfferCombinationListDto
    | undefined = useMemo(
    () => combinedWithList?.[type as keyof typeof combinedWithList],
    [combinedWithList, type]
  );
  const totalPages: number | undefined = useMemo(
    () => Math.ceil((combinedWithTypeNeeded?.totalItems || 1) / recordsPerPage),
    [combinedWithTypeNeeded?.totalItems, recordsPerPage]
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const prevPage = usePrevious(page);

  const data: OfferCombinationListDto[] | undefined = useMemo(
    () => combinedWithTypeNeeded?.items,
    [combinedWithTypeNeeded?.items]
  );

  const toggleIsOpen = useCallback(() => {
    count > 0 && setIsOpen((isOpen: boolean) => !isOpen);
    !count && setIsOpen(false);
    type !== lastCombinedWithTypeFetched &&
      dispatch(setLastCombinedWithTypePage(1));
    type && dispatch(setLastCombinedWithTypeFetched(type));
  }, [count, type, lastCombinedWithTypeFetched, setIsOpen, dispatch]);

  const combinationType = useMemo(() => {
    switch (type) {
      case OfferRelationalCombinationTypeDtoEnum.ORDER:
      case OfferRelationalCombinationTypeDtoEnum.OTHER_ORDER:
        return i18n.translate('CanCombineWithModal.Order');
      case OfferRelationalCombinationTypeDtoEnum.PRODUCT:
      case OfferRelationalCombinationTypeDtoEnum.OTHER_PRODUCT:
        return i18n.translate('CanCombineWithModal.Product');
      case OfferRelationalCombinationTypeDtoEnum.SHIPPING:
        return i18n.translate('CanCombineWithModal.Shipping');
    }
  }, [type, i18n]);

  const rows = useMemo(
    () =>
      data?.map((ele: OfferCombinationListDto) => [
        <>
          <div className='CombineWithModalTileText'>
            <h5 className='BoldTitle'>{ele.title}</h5>
            {ele.promotionTitle && (
              <Text as='span' tone='subdued'>
                {i18n.translate('CanCombineWithModal.Promotion')}:{' '}
                {ele.promotionTitle}
              </Text>
            )}
            {ele.isGroup && (
              <div className='hasGroup'>
                {i18n.translate('CanCombineWithModal.Group')}
              </div>
            )}
          </div>
        </>,
        <>
          <div className='CombineWithModalTileText'>
            <h5>{combinationType}</h5>
          </div>
        </>,
        <>
          {ele?.combinesWith?.map(
            (value: OfferCombinationTypeDto, index: number) => (
              <p key={index}>
                {value === OfferCombinationTypeDtoEnum.ORDER
                  ? i18n.translate('CanCombineWithModal.OrderDiscounts')
                  : value === OfferCombinationTypeDtoEnum.PRODUCT
                  ? i18n.translate('CanCombineWithModal.ProductDiscounts')
                  : i18n.translate('CanCombineWithModal.ShippingDiscounts')}
              </p>
            )
          )}
        </>,
      ]),
    [data, combinationType]
  );

  const checkAtLeastOneSameField = useCallback(
    (arr1: string[], arr2: string[], arr3: string[]) => {
      const firstСomparison = arr1.some((str) => arr2.includes(str));
      const secondComparison = arr1.some((str) => arr3.includes(str));
      if (firstСomparison || secondComparison) {
        setShouldRefetch(true);
      }
    },
    [setShouldRefetch]
  );

  useEffect(() => {
    if (isOpen) {
      checkAtLeastOneSameField(
        data?.map((obj) => obj.id!) || [],
        currentOfferData?.template?.combinations?.excludedOffersIds || [],
        currentOfferData?.template?.combinations
          ?.excludedShopifyDiscountCodesIds || []
      );
    }
  }, [isOpen, checkAtLeastOneSameField]);

  useEffect(() => {
    if (page && prevPage && prevPage !== page) {
      dispatch(setLastCombinedWithTypePage(page));
    }
  }, [page, prevPage, dispatch]);

  useEffect(() => {
    if (shouldRefetch) {
      setShouldRefetch(false);
      getCurrentTypeOfList();
    }
  }, [shouldRefetch, getCurrentTypeOfList]);

  return (
    <>
      {count > 0 ? (
        <Link onClick={toggleIsOpen} removeUnderline>
          {count + text}
        </Link>
      ) : (
        <Text as='span'>{count + text}</Text>
      )}

      <ModalCustom
        isOpen={isOpen}
        buttons={[
          {
            content: i18n.translate('CanCombineWithModal.Done'),
            primary: false,
            action: toggleIsOpen,
          },
        ]}
        modalClass='SelectOfferModal TableModal'
        title={modalTitle}
        onClose={toggleIsOpen}
      >
        <div className='renderedTable'>
          {rows && count && (
            <DataTable
              columnContentTypes={['text', 'text', 'text']}
              headings={[
                i18n.translate('CanCombineWithModal.Title'),
                i18n.translate('CanCombineWithModal.Type'),
                i18n.translate('CanCombineWithModal.CombinesWith'),
              ]}
              verticalAlign='middle'
              rows={rows || []}
            />
          )}
          {(getOfTypeListIsLoading ||
            currentCombinationIsFetching ||
            typeListIsFetching) && (
            <div className='tableLoader'>
              <Loader size='large' fullWidth />
            </div>
          )}
        </div>
        {rows?.length && totalPages && totalPages > 1 ? (
          <LegacyCard.Section>
            <Pagination
              label={`Page ${page} of ${totalPages}`}
              hasPrevious={page > 1}
              onPrevious={() => {
                setPage((prev) => prev - 1);
              }}
              hasNext={page < totalPages}
              onNext={() => {
                setPage((prev) => prev + 1);
              }}
            />
          </LegacyCard.Section>
        ) : null}
      </ModalCustom>
    </>
  );
};
