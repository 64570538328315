import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { PromotionActivity, PromotionResults } from 'features/reporting';
import { usePageName } from 'core/hooks';
import { NavTabs } from 'core/enums/NavTabsEnum';
import { NavPaths } from 'core/enums/NavPathsEnum';

export const ReportingPage = () => {
  const location = useLocation();
  const [pageName, setPageName] = useState<NavTabs>(NavTabs.Reporting);
  useEffect(() => {
    const pathNameIndex = location?.pathname?.lastIndexOf('/');
    let pathName = '';
    if (pathNameIndex > -1) {
      pathName = location.pathname.substring(pathNameIndex);
      switch (pathName) {
        case NavPaths.PromotionActivity:
          return setPageName(NavTabs.ReportingPromotionActivity);
        case NavPaths.ResultsByPromotion:
          return setPageName(NavTabs.ReportingResultsByPromotion);
        case NavPaths.ResultsByOrder:
          return setPageName(NavTabs.ReportingResultsByOrder);
        case NavPaths.ResultsByPromotionCode:
          return setPageName(NavTabs.ReportingResultsByPromotionCode);
        default:
          return setPageName(NavTabs.Reporting);
      }
    }

    return setPageName(NavTabs.Reporting);
  }, [location]);

  usePageName(pageName);

  return (
    <Routes>
      <Route index element={<PromotionActivity />} />
      <Route path='promotion-results' element={<PromotionResults />} />
      <Route path={NavPaths.PromotionActivity} />
      <Route path={NavPaths.ResultsByPromotion} />
      <Route path={NavPaths.ResultsByOrder} />
      <Route path={NavPaths.ResultsByPromotionCode} />
    </Routes>
  );
};
