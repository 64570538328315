import React, { useState } from 'react';
import './Image.scss';
import CoverIcon from './assets/cover.svg';
import ContainIcon from './assets/contain.svg';
import { useI18n } from '@shopify/react-i18n';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import {
  Icon,
  IconSource,
  Popover,
  Text,
  TextField,
  Tooltip,
} from '@shopify/polaris';
import {
  ImageFitDtoEnum,
  ImageSizeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import {
  IconResourceDto,
  ImageComponentDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { InfoIcon, SelectIcon } from '@shopify/polaris-icons';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import ImageIconLibrary from 'core/components/WidgetEditor/ImageIconLibrary/ImageIconLibrary';
import { useAppSelector } from 'core/hooks';
import { useConfigureWidgets } from 'features/settings/hooks/useConfigureWidgets';

type ImageProps = {
  image?: ImageComponentDto;
  disableSize?: boolean;
  showImage?: boolean;
  groupKey: string;
  offersNote?: boolean;
  promotionsNote?: boolean;
  inherited?: boolean;
  setImage: (value: ImageComponentDto) => void;
  onSystemReset: () => void;
};
export const Image: React.FC<ImageProps> = ({
  image,
  disableSize,
  showImage,
  groupKey,
  offersNote,
  promotionsNote,
  inherited,
  setImage,
  onSystemReset,
}) => {
  const [i18n] = useI18n();
  const { defaultImageLibary, customImageLibary } = useAppSelector(
    (store) => store.widgets
  );
  const { refetchCustomImageLibary, isCustomImageLibaryFetching } =
    useConfigureWidgets();
  const [imageLibraryOpen, setImageLibraryOpen] = useState<boolean>(false);
  const imageFitOptions = Object.values(ImageFitDtoEnum).map((value) => {
    return {
      value: value,
      label: i18n.translate(`${value}`),
    };
  });
  const imageSizeOptions = Object.values(ImageSizeDtoEnum).map((value) => {
    return {
      value: value,
      label: i18n.translate(`${value}`),
    };
  });

  const filteredDefaultImages = defaultImageLibary.filter(
    (image) => image.groupKey === groupKey
  );

  const selectedImage: IconResourceDto =
    defaultImageLibary
      ?.concat(customImageLibary || [])
      .find((file) => file.id === image?.imageId) || {};

  return (
    <div className='Image'>
      <StyleComponentHeader
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            onAction: () => onSystemReset(),
          },
        ]}
        inherited={inherited}
        label={i18n.translate(`Image`)}
      >
        {promotionsNote && (
          <Text tone='subdued' as='p'>
            {i18n.translate('PromotionsNote')}
          </Text>
        )}
        {offersNote && (
          <Text tone='subdued' as='p'>
            {i18n.translate('OffersNote')}
          </Text>
        )}
        {showImage && (
          <div className='ImageRow'>
            {i18n.translate(`Image`)}
            <div className='RightContent'>
              <Popover
                active={imageLibraryOpen}
                fullHeight
                activator={
                  <div onClick={() => setImageLibraryOpen(true)}>
                    <TextField
                      suffix={<Icon source={SelectIcon as IconSource} />}
                      label=''
                      autoComplete=''
                      prefix={
                        selectedImage.url ? (
                          <img
                            className='PrefixImage'
                            src={selectedImage.url}
                          />
                        ) : null
                      }
                      placeholder={i18n.translate('ImagePlaceholder')}
                      value={selectedImage?.name || ''}
                    />
                  </div>
                }
                autofocusTarget='first-node'
                onClose={() => setImageLibraryOpen(false)}
              >
                <ImageIconLibrary
                  selectedFile={image?.imageId || ''}
                  defaultFilesLibrary={filteredDefaultImages || []}
                  customFilesLibrary={customImageLibary || []}
                  isCustomFileLibraryFetching={isCustomImageLibaryFetching}
                  fileFormat={'image'}
                  setSelectedFile={(value) =>
                    setImage({ ...image, imageId: value })
                  }
                  refetchCustomLibrary={refetchCustomImageLibary}
                  onClosePopover={() => setImageLibraryOpen(false)}
                />
              </Popover>
            </div>
          </div>
        )}
        {!disableSize && (
          <div className='ImageRow'>
            {i18n.translate(`Size`)}
            <div className='RightContent'>
              <SelectOptions
                options={imageSizeOptions}
                onOptionSelect={(value) => {
                  setImage({ ...image, size: value as ImageSizeDtoEnum });
                }}
                selectedOption={image?.size || ImageSizeDtoEnum.MEDIUM}
                label=''
              />
            </div>
          </div>
        )}
        <div className='ImageRow'>
          {i18n.translate(`ImageFit`)}
          <div className='RightContent'>
            <SelectOptions
              options={imageFitOptions}
              onOptionSelect={(value) => {
                setImage({ ...image, fit: value as ImageFitDtoEnum });
              }}
              selectedOption={image?.fit || ImageFitDtoEnum.CONTAIN}
              label=''
            />
            <Tooltip
              width='wide'
              content={
                <div className='EditPresetImagePreview'>
                  <img
                    src={
                      image?.fit === ImageFitDtoEnum.COVER
                        ? CoverIcon
                        : ContainIcon
                    }
                  />
                  <Text as='p' tone='subdued'>
                    {image?.fit === ImageFitDtoEnum.COVER
                      ? i18n.translate(`CoverText`)
                      : i18n.translate(`ContainText`)}
                  </Text>
                </div>
              }
            >
              <Icon tone='base' source={InfoIcon} />
            </Tooltip>
          </div>
        </div>
      </StyleComponentHeader>
    </div>
  );
};
