import React, { useEffect, useMemo, useState } from 'react';
import { OfferSuggestedRuleConditionDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { useI18n } from '@shopify/react-i18n';
import { EditIcon, XSmallIcon } from '@shopify/polaris-icons';
import { TextField } from 'core/components/ControlLibrary/TextField/TextField';
import './ConditionComponent.scss';
import classNames from 'classnames';
import { Icon, IconSource } from '@shopify/polaris';
import { usePrevious } from 'core/hooks/usePrevious';
import { isEqual } from 'lodash';
import { ConditionModal } from '../ConditionModal/ConditionModal';
import {
  OfferRuleTypeDtoEnum,
  OfferRuleValueTypeDtoEnum,
  OfferTargetTypeDtoEnum,
  OfferTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';

type ConditionalComponentProps = {
  condition: OfferSuggestedRuleConditionDto;
  idx: number;
  lastIdx: number;
  firstGroup: boolean;
  handleDeleteCondition: (index: number) => void;
  handleAddCondition: () => void;
  handleUpdateCondition: (
    obj: OfferSuggestedRuleConditionDto,
    index: number
  ) => void;
  offerType?: OfferTypeDtoEnum;
};

export const ConditionComponent: React.FC<ConditionalComponentProps> = (
  props
) => {
  const {
    lastIdx,
    idx,
    condition,
    firstGroup,
    handleDeleteCondition,
    handleAddCondition,
    handleUpdateCondition,
    offerType,
  } = props;

  const [i18n] = useI18n();

  const [currentCondition, setCurrentCondition] =
    useState<OfferSuggestedRuleConditionDto>(condition);
  const [open, setOpen] = useState<boolean>(false);

  const prevCondition = usePrevious(currentCondition);

  const conditionName = useMemo(() => {
    const valueType = currentCondition.value?.type;
    const valueFrom = currentCondition.value?.from;
    const valueTo = currentCondition.value?.to;
    const appliesToType = currentCondition.appliesTo?.type;
    const appliesToCollections = currentCondition.appliesTo?.collections;
    const appliesToProducts = currentCondition.appliesTo?.products;
    const appliesToVariants = currentCondition.appliesTo?.variants;

    let result = '';

    if (valueType === OfferRuleValueTypeDtoEnum.AT_LEAST) {
      result += `${
        currentCondition.type === OfferRuleTypeDtoEnum.MINIMUM_PURCHASE_AMOUNT
          ? i18n.translate('Spends')
          : i18n.translate('Adds')
      } ${i18n.translate('AtLeast')} ${
        currentCondition.type === OfferRuleTypeDtoEnum.MINIMUM_PURCHASE_AMOUNT
          ? '$'
          : ''
      }${valueFrom} ${
        currentCondition.type === OfferRuleTypeDtoEnum.MINIMUM_PURCHASE_AMOUNT
          ? `${i18n.translate('on')} `
          : ''
      }${
        valueFrom !== 1 ? i18n.translate('products') : i18n.translate('product')
      }`;
    } else if (valueType === OfferRuleValueTypeDtoEnum.AT_MOST) {
      result += `${
        currentCondition.type === OfferRuleTypeDtoEnum.MINIMUM_PURCHASE_AMOUNT
          ? i18n.translate('Spends')
          : i18n.translate('Adds')
      } ${i18n.translate('AtMost')} ${
        currentCondition.type === OfferRuleTypeDtoEnum.MINIMUM_PURCHASE_AMOUNT
          ? '$'
          : ''
      }${valueTo} ${
        currentCondition.type === OfferRuleTypeDtoEnum.MINIMUM_PURCHASE_AMOUNT
          ? `${i18n.translate('on')} `
          : ''
      }${
        valueTo !== 1 ? i18n.translate('products') : i18n.translate('product')
      }`;
    } else if (valueType === OfferRuleValueTypeDtoEnum.BETWEEN) {
      result += `${
        currentCondition.type === OfferRuleTypeDtoEnum.MINIMUM_PURCHASE_AMOUNT
          ? i18n.translate('Spends')
          : i18n.translate('Adds')
      } ${i18n.translate('Between')} ${
        currentCondition.type === OfferRuleTypeDtoEnum.MINIMUM_PURCHASE_AMOUNT
          ? '$'
          : ''
      }${valueFrom} ${i18n.translate('and')} ${
        currentCondition.type === OfferRuleTypeDtoEnum.MINIMUM_PURCHASE_AMOUNT
          ? '$'
          : ''
      }${valueTo} ${
        currentCondition.type === OfferRuleTypeDtoEnum.MINIMUM_PURCHASE_AMOUNT
          ? `${i18n.translate('on')} `
          : ''
      }${
        valueFrom !== 1 ? i18n.translate('products') : i18n.translate('product')
      }`;
    }

    if (appliesToType === OfferTargetTypeDtoEnum.COLLECTIONS) {
      const selectedCollections = appliesToCollections?.length || 0;
      result += ` ${i18n.translate(
        'from'
      )} ${selectedCollections} ${i18n.translate('selected')} ${
        selectedCollections !== 1
          ? i18n.translate('collections')
          : i18n.translate('collection')
      }`;
    } else if (appliesToType === OfferTargetTypeDtoEnum.PRODUCTS) {
      const selectedProducts = appliesToProducts?.length || 0;
      result += ` ${i18n.translate(
        'from'
      )} ${selectedProducts} ${i18n.translate('selected')} ${
        selectedProducts !== 1
          ? i18n.translate('products')
          : i18n.translate('product')
      }`;
    } else if (appliesToType === OfferTargetTypeDtoEnum.VARIANTS) {
      const selectedVariants = appliesToVariants?.length || 0;
      result += ` ${i18n.translate(
        'from'
      )} ${selectedVariants} ${i18n.translate('selected')} ${
        selectedVariants !== 1
          ? i18n.translate('productVariants')
          : i18n.translate('productVariant')
      }`;
    } else if (appliesToType === OfferTargetTypeDtoEnum.ALL) {
      result += ` ${i18n.translate('from')} ${i18n.translate('anyCollection')}`;
    }

    return result;
  }, [currentCondition, offerType, i18n]);

  useEffect(() => {
    if (
      prevCondition &&
      currentCondition &&
      !isEqual(prevCondition, currentCondition) &&
      Object.keys(currentCondition).length !== 0
    ) {
      handleUpdateCondition(currentCondition, idx);
    }
  }, [prevCondition, currentCondition]);

  return (
    <>
      <div className='Condition'>
        <div
          className={classNames({
            inputLine: true,
            firstGroupAndFirstCondition: idx === 0 && firstGroup,
          })}
        >
          <div onClick={() => setOpen(true)} style={{ width: '100%' }}>
            <TextField
              label=''
              labelHidden
              value={conditionName}
              placeholder={i18n.translate('ConditionPlaceholder')}
              icon={EditIcon}
              iconPosition='right'
              readOnly
              autoComplete='off'
            />
          </div>
          {idx === 0 && firstGroup ? null : (
            <div
              className='deleteIcon'
              onClick={() => handleDeleteCondition(idx)}
            >
              <Icon source={XSmallIcon as IconSource} tone='base' />
            </div>
          )}
        </div>
        {lastIdx === idx &&
        lastIdx < 4 &&
        Object.keys(currentCondition).length !== 0 ? (
          <div className='addOrConditionBtn' onClick={handleAddCondition}>
            {i18n.translate('AddOrConditionBtn')}
          </div>
        ) : lastIdx === idx && lastIdx >= 4 ? (
          <div className='maxOrConditionsWarning'>
            {i18n.translate('MaxOrConditions')}
          </div>
        ) : null}
        {lastIdx !== idx && (
          <div
            className={classNames({
              orConnection: true,
              firstConnection: idx === 0,
              lastConnection: lastIdx - 1 === idx,
            })}
          >
            <div className='connector'>{i18n.translate('orConnector')}</div>
            <div className='whiteSpace' />
          </div>
        )}
      </div>
      {open && (
        <ConditionModal
          offerType={offerType}
          open={open}
          condition={currentCondition}
          setCondition={setCurrentCondition}
          handleClose={() => setOpen(false)}
        />
      )}
    </>
  );
};
