import React, { useMemo, useState } from 'react';
import './SettingTrigger.scss';
import {
  Icon,
  Link,
  Text,
  Button,
  Card,
  BlockStack,
  InlineStack,
  Box,
  Badge,
  List,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { PromotionSettingsDto } from 'core/api/adminPromotions/adminPromotionsApi';
import {
  LinkIcon,
  DiscountCodeIcon,
  ChevronRightIcon,
} from '@shopify/polaris-icons';
import { TriggerTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import VisitorBehaviorModal from './components/VisitorBehaviorModal/VisitorBehaviorModal';
import ChangeTriggerModal from './components/ChangeTriggerModal/ChangeTriggerModal';

type SettingTriggerProps = {
  settingsState: PromotionSettingsDto;
  navigateThrough: (path: string) => void;
  updateSettingsState: (
    field: keyof PromotionSettingsDto,
    data: PromotionSettingsDto[keyof PromotionSettingsDto]
  ) => void;
  onFormValidityChange(formIsValid: boolean): void;
};

export const SettingTrigger: React.FC<SettingTriggerProps> = ({
  settingsState,
  navigateThrough,
  updateSettingsState,
}) => {
  const [i18n] = useI18n();
  const [visitorModalOpen, setVisitorModalOpen] = useState<boolean>(false);
  const [triggerModalOpen, setTriggerModalOpen] = useState<boolean>(false);

  const {
    exitIntentEnabled,
    numberOfPagesVisited,
    percentageOfPageViewed,
    timeSpentOnPage,
  } = settingsState.visitorBehavior || {};

  const isNotConfigured = useMemo(
    () =>
      !timeSpentOnPage?.enabled &&
      !numberOfPagesVisited?.enabled &&
      !percentageOfPageViewed?.enabled &&
      !exitIntentEnabled,
    [
      timeSpentOnPage,
      numberOfPagesVisited,
      percentageOfPageViewed,
      exitIntentEnabled,
    ]
  );

  return (
    <div className='SettingTrigger'>
      <Card>
        <BlockStack gap='500'>
          <BlockStack gap='400'>
            <InlineStack align='space-between' blockAlign='center'>
              <BlockStack gap='100'>
                <Text as='h2' fontWeight='semibold'>
                  {i18n.translate('Trigger', {
                    selected: i18n.translate(
                      `${settingsState.triggerType}_TRIGGER`
                    ),
                  })}
                </Text>
                <Text as='p' tone='subdued'>
                  {i18n.translate('TriggerSubtitle', {
                    followingArticle: (
                      <Link>{i18n.translate('followingArticle')}</Link>
                    ),
                  })}
                </Text>
              </BlockStack>
              <Button onClick={() => setTriggerModalOpen(true)}>
                {i18n.translate('Change')}
              </Button>
            </InlineStack>
          </BlockStack>
          {settingsState.triggerType === TriggerTypeDtoEnum.PUBLIC && (
            <BlockStack gap='300'>
              <Text as='p' fontWeight='medium'>
                {i18n.translate('VisitorFilters')}
              </Text>
              <Box
                borderRadius='200'
                padding='400'
                borderWidth='025'
                borderColor='border-brand'
              >
                <InlineStack align='space-between'>
                  {isNotConfigured ? (
                    <Text as='p' tone='subdued'>
                      {i18n.translate('NoVisitorBehavior')}
                    </Text>
                  ) : (
                    <List type='bullet'>
                      {timeSpentOnPage?.enabled && (
                        <List.Item>
                          {i18n.translate('TimeSpent', {
                            seconds: timeSpentOnPage.value,
                          })}
                        </List.Item>
                      )}
                      {numberOfPagesVisited?.enabled && (
                        <List.Item>
                          {i18n.translate('NumberOfPages', {
                            pages: numberOfPagesVisited?.value,
                          })}
                        </List.Item>
                      )}
                      {percentageOfPageViewed?.enabled && (
                        <List.Item>
                          {i18n.translate('PercentageOfPage', {
                            percentage: percentageOfPageViewed.value,
                          })}
                        </List.Item>
                      )}
                      {exitIntentEnabled && (
                        <List.Item>{i18n.translate('ExitIntent')}</List.Item>
                      )}
                    </List>
                  )}
                  <Button
                    onClick={() => setVisitorModalOpen(true)}
                    variant='plain'
                  >
                    {i18n.translate('Manage')}
                  </Button>
                </InlineStack>
              </Box>
            </BlockStack>
          )}
          {settingsState.triggerType === TriggerTypeDtoEnum.PRIVATE && (
            <BlockStack gap='300'>
              <div
                onClick={() => navigateThrough('discount-links')}
                className='SettingNavigationBox'
              >
                <InlineStack align='space-between'>
                  <InlineStack gap='300'>
                    <Box>
                      <Icon source={LinkIcon} />
                    </Box>
                    <Text as='p' tone='subdued'>
                      {i18n.translate('DiscountLinks')}
                    </Text>
                  </InlineStack>
                  <InlineStack gap='300'>
                    <Badge>
                      {settingsState.discountLinksCount +
                        i18n.translate(
                          settingsState.discountLinksCount === 1
                            ? 'Link'
                            : 'Links'
                        )}
                    </Badge>
                    <Box>
                      <Icon source={ChevronRightIcon} />
                    </Box>
                  </InlineStack>
                </InlineStack>
              </div>
              <div
                onClick={() => navigateThrough('promotion-codes')}
                className='SettingNavigationBox'
              >
                <InlineStack align='space-between'>
                  <InlineStack gap='300'>
                    <Box>
                      <Icon source={DiscountCodeIcon} />
                    </Box>
                    <Text as='p' tone='subdued'>
                      {i18n.translate('PromotionCodes')}
                    </Text>
                  </InlineStack>
                  <InlineStack gap='300'>
                    <Badge>
                      {settingsState.promotionCodesCount +
                        i18n.translate(
                          settingsState.promotionCodesCount === 1
                            ? 'Code'
                            : 'Codes'
                        )}
                    </Badge>
                    <Box>
                      <Icon source={ChevronRightIcon} />
                    </Box>
                  </InlineStack>
                </InlineStack>
              </div>
            </BlockStack>
          )}
        </BlockStack>
      </Card>
      {visitorModalOpen && (
        <VisitorBehaviorModal
          isOpen={visitorModalOpen}
          visitorBehavior={settingsState.visitorBehavior}
          onClose={() => setVisitorModalOpen(false)}
          onSave={(data) => updateSettingsState('visitorBehavior', data)}
        />
      )}
      {triggerModalOpen && (
        <ChangeTriggerModal
          selectedTrigger={settingsState.triggerType as TriggerTypeDtoEnum}
          isOpen={triggerModalOpen}
          onClose={() => setTriggerModalOpen(false)}
          onSave={(value) => updateSettingsState('triggerType', value)}
        />
      )}
    </div>
  );
};
