import React from 'react';
import {
  Background,
  WidgetOptionHeader,
} from '../../../../../../../../../components';
import { NinjaCartDefaultStateContentGeneralPresetDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useI18n } from '@shopify/react-i18n';
import { Box, Link, Text } from '@shopify/polaris';

type NinjaCartContentGeneralProps = {
  general: NinjaCartDefaultStateContentGeneralPresetDto;
  defaultGeneral: NinjaCartDefaultStateContentGeneralPresetDto;
  shopDefaultGeneral: NinjaCartDefaultStateContentGeneralPresetDto;
  setGeneral: (data: NinjaCartDefaultStateContentGeneralPresetDto) => void;
  onShowHide?: () => void;
  onRemove?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const NinjaCartContentGeneral: React.FC<NinjaCartContentGeneralProps> = ({
  general,
  defaultGeneral,
  shopDefaultGeneral,
  setGeneral,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();

  return (
    <>
      <WidgetOptionHeader
        onDiscardChanges={() => setGeneral(defaultGeneral)}
        onResetToSystem={() => setGeneral(shopDefaultGeneral)}
        name={WidgetListOptionsDtoEnum.CONTENT}
        onShowHide={onShowHide}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <Background
          background={general.background || {}}
          setBackground={(data) => setGeneral({ ...general, background: data })}
          onSystemReset={() =>
            setGeneral({
              ...general,
              background: shopDefaultGeneral.background,
            })
          }
        />
      </div>
      <div className='RightSideSection'>
        <Box padding='500'>
          <Text tone='subdued' as='p'>
            {i18n.translate('SettingsInherited', {
              defaultState: <Link>{i18n.translate('defaultState')}</Link>,
            })}
          </Text>
        </Box>
      </div>
    </>
  );
};

export default NinjaCartContentGeneral;
