import React, { useCallback, useMemo, useState } from 'react';
import {
  BlockStack,
  Button,
  ButtonGroup,
  Text,
  InlineStack,
  Page,
  Box,
  Banner,
  Icon,
  Card,
} from '@shopify/polaris';
import {
  PlusIcon,
  MinusIcon,
  DiscountIcon,
  GamesIcon,
  ChartVerticalIcon,
} from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import {
  PlanIntervalDtoEnum,
  PlanLevelDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { useSearchParams } from 'react-router-dom';
import GreenArrow from './assets/GreenArrow.svg';
import './PlanDetails.scss';
import { PlanCard } from './components/PlanCard/PlanCard';
import { SettingsSkeleton } from '../../../components/SettingsSkeleton/SettingsSkeleton';
import { PlanFeaturesList } from './components/PlanFeaturesList/PlanFeaturesList';
import { EnterpriseCard } from './components/EnterpriseCard/EnterpriseCard';
import { FrequentlyQuestions } from './components/FrequentlyQuestions/FrequentlyQuestions';
import {
  ChangePlanModal,
  ChangePlanModalTypeEnum,
} from '../../components/ChangePlanModal/ChangePlanModal';

export const PlanDetails = () => {
  const [i18n] = useI18n();
  const [, setSearchParams] = useSearchParams();
  const { planDetailsData } = useConfigureSettings(
    SettingsFetchTypeEnum.PLAN_DETAILS
  );

  const [selectedInterval, setSelectedInterval] = useState<PlanIntervalDtoEnum>(
    PlanIntervalDtoEnum.MONTHLY
  );
  const [isFeaturesOpen, setIsFeaturesOpen] = useState<boolean>(false);
  const [planToChangeModal, setPlanToChangeModal] =
    useState<PlanLevelDtoEnum | null>(null);

  const selectedIntervalPlan = useMemo(
    () =>
      planDetailsData?.plans?.find((item) =>
        selectedInterval === PlanIntervalDtoEnum.ANNUAL
          ? item.isCurrentAnnually
          : item.isCurrentMonthly
      ),
    [planDetailsData?.plans, selectedInterval]
  );

  const isFreeTrialAvailable = useMemo(
    () =>
      !!(
        planDetailsData?.plans?.some(
          (plan) => !plan.isCurrentAnnually && !plan.isCurrentMonthly
        ) && !!planDetailsData.trialDays
      ),
    [planDetailsData?.plans, planDetailsData?.trialDays]
  );

  const highestPlanThreshold = useMemo(() => {
    const highestPlan = planDetailsData?.plans?.reduce((prev, curr) => {
      return (curr.threshold?.perMonth || 0) > (prev.threshold?.perMonth || 0)
        ? curr
        : prev;
    }, planDetailsData?.plans?.[0]);
    return highestPlan?.threshold;
  }, [planDetailsData?.plans]);

  const comparisonPlans = useMemo(() => {
    const mainPlans = [
      PlanLevelDtoEnum.STARTER_1,
      PlanLevelDtoEnum.PRO_1,
      PlanLevelDtoEnum.PLUS_1,
    ];
    return planDetailsData?.plans?.filter((plan) =>
      mainPlans.includes(plan.level as PlanLevelDtoEnum)
    );
  }, [planDetailsData?.plans]);

  const handleRedirectBack = useCallback(() => {
    setSearchParams((params) => {
      params.delete('subPath');
      return params;
    });
  }, [setSearchParams]);

  const togglePlanChangeModal = useCallback((level?: PlanLevelDtoEnum) => {
    setPlanToChangeModal(level ? level : null);
  }, []);

  return (
    <div className='PlanDetails'>
      <Page
        backAction={{ onAction: handleRedirectBack }}
        title={i18n.translate('PickYourPlan')}
      >
        {planDetailsData && comparisonPlans ? (
          <BlockStack gap='600' inlineAlign='center'>
            <InlineStack align='center'>
              <BlockStack gap='200' inlineAlign='center'>
                <div className='IntervalButtons'>
                  <ButtonGroup variant='segmented'>
                    <Button
                      pressed={selectedInterval === PlanIntervalDtoEnum.MONTHLY}
                      onClick={() =>
                        setSelectedInterval(PlanIntervalDtoEnum.MONTHLY)
                      }
                    >
                      {i18n.translate('BilledMonthly')}
                    </Button>
                    <Button
                      pressed={selectedInterval === PlanIntervalDtoEnum.ANNUAL}
                      onClick={() =>
                        setSelectedInterval(PlanIntervalDtoEnum.ANNUAL)
                      }
                    >
                      {i18n.translate('BilledAnnually')}
                    </Button>
                  </ButtonGroup>
                  <div className='GreenArrowSave'>
                    <img src={GreenArrow} alt='Arrow left' />
                    <Text
                      variant='bodySm'
                      as='p'
                      tone='success'
                      fontWeight='semibold'
                    >
                      {i18n.translate('Save25')}
                    </Text>
                  </div>
                </div>
                <Text variant='bodySm' as='p' tone='subdued'>
                  {i18n.translate('SaveAnnually', {
                    amount: (
                      <Text as='span' fontWeight='semibold'>
                        {i18n.translate('Save25%')}
                      </Text>
                    ),
                  })}
                </Text>
              </BlockStack>
            </InlineStack>

            {planDetailsData.discount && (
              <Box width='100%'>
                <Banner tone='success' icon={DiscountIcon}>
                  {i18n.translate('EligibleForSale', {
                    discount: (
                      <Text as='span' fontWeight='semibold'>
                        {planDetailsData.discount?.discountPercentage}%{' '}
                        {planDetailsData.discount?.name}
                      </Text>
                    ),
                  })}
                </Banner>
              </Box>
            )}

            <Box width='100%'>
              <InlineStack gap='400' wrap={false} align='space-between'>
                {comparisonPlans?.map((plan) => {
                  const isSelected =
                    selectedIntervalPlan?.level?.slice(0, -2) ===
                    plan.level?.slice(0, -2);
                  return (
                    <PlanCard
                      planData={plan}
                      discount={planDetailsData.discount}
                      isSelected={isSelected}
                      key={plan.level}
                      interval={selectedInterval}
                      isFreeTrialAvailable={isFreeTrialAvailable}
                      onSelectPlan={(value) => togglePlanChangeModal(value)}
                    />
                  );
                })}
              </InlineStack>
            </Box>

            <Button
              onClick={() => setIsFeaturesOpen(!isFeaturesOpen)}
              icon={isFeaturesOpen ? MinusIcon : PlusIcon}
            >
              {i18n.translate(
                isFeaturesOpen ? 'CloseFeatures' : 'FullListFeatures'
              )}
            </Button>
            <Box width='100%' borderRadius='400'>
              {isFeaturesOpen && (
                <PlanFeaturesList comparisonPlans={comparisonPlans || []} />
              )}
            </Box>
            <Box width='100%'>
              <EnterpriseCard
                planData={
                  planDetailsData.plans?.find(
                    (plan) => plan.level === PlanLevelDtoEnum.ENTERPRISE
                  ) || {}
                }
                highestPlanThreshold={highestPlanThreshold}
                discount={planDetailsData.discount}
                interval={selectedInterval}
                developmentPlan={planDetailsData.developmentPlan}
              />
            </Box>
            <Box width='100%'>
              <BlockStack gap='400'>
                <Text as='h1' variant='headingLg'>
                  {i18n.translate('WhatEveryPlanGets')}
                </Text>
                <Card>
                  <InlineStack align='space-around' blockAlign='center'>
                    <BlockStack gap='300' inlineAlign='center'>
                      <Icon tone='subdued' source={DiscountIcon} />
                      <Text as='p'>{i18n.translate('15PromotionTypes')}</Text>
                    </BlockStack>
                    <BlockStack gap='300' inlineAlign='center'>
                      <Icon tone='subdued' source={GamesIcon} />
                      <Text as='p'>{i18n.translate('14DaysFreeTrial')}</Text>
                    </BlockStack>
                    <BlockStack gap='300' inlineAlign='center'>
                      <Icon tone='subdued' source={ChartVerticalIcon} />
                      <Text as='p'>
                        {i18n.translate('ReportingConversion')}
                      </Text>
                    </BlockStack>
                  </InlineStack>
                </Card>
              </BlockStack>
            </Box>
            <Box width='100%'>
              <FrequentlyQuestions />
            </Box>
          </BlockStack>
        ) : (
          <SettingsSkeleton />
        )}
      </Page>
      {!!planToChangeModal && (
        <ChangePlanModal
          isOpen={!!planToChangeModal}
          targetPlanLevel={planToChangeModal}
          interval={selectedInterval}
          modalType={ChangePlanModalTypeEnum.CHANGE_PLAN}
          onClose={() => togglePlanChangeModal()}
        />
      )}
    </div>
  );
};
