import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavTabs } from 'core/enums/NavTabsEnum';

interface MainNavType {
  activePage: NavTabs | null;
  hideAppLeftMenu: boolean;
}

const initialState: MainNavType = {
  activePage: NavTabs.Home,
  hideAppLeftMenu: true,
};

export const mainNavSlice = createSlice({
  name: 'mainNav',
  initialState,
  reducers: {
    updateActivePage: (
      state,
      action: PayloadAction<MainNavType['activePage']>
    ) => {
      state.activePage = action.payload;
    },
    setHideAppLeftMenu: (
      state,
      action: PayloadAction<MainNavType['hideAppLeftMenu']>
    ) => {
      state.hideAppLeftMenu = action.payload;
    },
    clearActivePage: (state) => {
      state.activePage = null;
    },
  },
});

export const { updateActivePage, clearActivePage, setHideAppLeftMenu } =
  mainNavSlice.actions;

export default mainNavSlice.reducer;
