import {
  OfferWidgetSetupDtoOfferRulePopupOfferDtoRead,
  OfferRulePopupPresetDtoRead,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { LineWeightTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';

export type PromotionWidgetSetupDtoOfferRulePopupPromotionDtoReadWithOffers =
  OfferRulePopupPresetDtoRead & {
    offers?: OfferWidgetSetupDtoOfferRulePopupOfferDtoRead[] | null;
    isOpen?: boolean;
  };

export const offerRulePopUpMergeFunc = (
  widget: PromotionWidgetSetupDtoOfferRulePopupPromotionDtoReadWithOffers,
  setup?: OfferRulePopupPresetDtoRead,
  currentWidgetName?: string
) => {
  const {
    isShopLevelWidget,
    canBeDragged,
    canBeRemoved,
    offers,
    options,
    ...newWidget
  } = widget;

  const isOpen = currentWidgetName === 'offerRulePopup';

  const { general: generalSetup, options: optionsSetup } =
    setup?.general && setup?.options ? setup : { general: null, options: null };

  return {
    ...newWidget,
    availableEntries: undefined,
    isOpen: isOpen,
    ...(optionsSetup && generalSetup
      ? {
          offers: offers?.map((offer) => {
            const {
              canBeDragged,
              canBeRemoved,
              parentKey,
              canBeHidden,
              isHidden,
              setup,
              ...newOffer
            } = offer;
            return {
              ...(canBeHidden && { isHidden }),
              ...newOffer,
              setup: {
                ...setup,
                availableEntries: undefined,
                general: {
                  ...generalSetup,
                  size: setup?.general?.size,
                  background: {
                    ...generalSetup?.background,
                    border: {
                      ...generalSetup?.background?.border,
                      value: {
                        ...generalSetup?.background?.border?.value,
                        lineWeight: {
                          ...generalSetup?.background?.border?.value
                            ?.lineWeight,
                          customValue:
                            generalSetup?.background?.border?.value?.lineWeight
                              ?.type === LineWeightTypeDtoEnum.CUSTOM
                              ? generalSetup?.background?.border?.value
                                  ?.lineWeight?.customValue
                              : undefined,
                        },
                      },
                    },
                  },
                  cornerRadius: {
                    ...generalSetup?.cornerRadius,
                    customValue:
                      generalSetup.cornerRadius?.type ===
                      LineWeightTypeDtoEnum.CUSTOM
                        ? generalSetup.cornerRadius.customValue
                        : undefined,
                  },
                },
                options: {
                  headlineText: {
                    ...setup?.options?.headlineText,
                    canBeDragged: undefined,
                    canBeRemoved: undefined,
                    canBeHidden: undefined,
                    headlineText: {
                      ...optionsSetup.offerRule?.headlineText,
                      message: {
                        ...setup?.options?.headlineText?.headlineText?.message,
                        settings: undefined,
                      },
                    },
                  },
                  ruleText: {
                    ...setup?.options?.ruleText,
                    canBeDragged: undefined,
                    canBeRemoved: undefined,
                    canBeHidden: undefined,
                    ruleText: {
                      ...optionsSetup.offerRule?.ruleText,
                      message: {
                        ...setup?.options?.ruleText?.ruleText?.message,
                        settings: undefined,
                      },
                    },
                  },
                  callToActionButton: {
                    ...setup?.options?.callToActionButton,
                    canBeDragged: undefined,
                    canBeRemoved: undefined,
                    canBeHidden: undefined,
                    ...optionsSetup.callToActionButton,
                    label: {
                      ...optionsSetup.callToActionButton?.label,
                      message: {
                        ...optionsSetup.callToActionButton?.label?.message,
                        settings: undefined,
                      },
                      style: {
                        ...optionsSetup.callToActionButton?.label?.style,
                        style: {
                          ...optionsSetup.callToActionButton?.label?.style
                            ?.style,
                          fontSize: {
                            ...optionsSetup.callToActionButton?.label?.style
                              ?.style?.fontSize,
                            mobile: optionsSetup.callToActionButton?.label
                              ?.style?.style?.differentOnMobile
                              ? optionsSetup.callToActionButton?.label?.style
                                  ?.style?.fontSize?.mobile
                              : undefined,
                          },
                          lineHeight: {
                            ...optionsSetup.callToActionButton?.label?.style
                              ?.style?.lineHeight,
                            mobile: optionsSetup.callToActionButton?.label
                              ?.style?.style?.differentOnMobile
                              ? optionsSetup.callToActionButton?.label?.style
                                  ?.style?.lineHeight?.mobile
                              : undefined,
                          },
                          fontWeight: {
                            ...optionsSetup.callToActionButton?.label?.style
                              ?.style?.fontWeight,
                            mobile: optionsSetup.callToActionButton?.label
                              ?.style?.style?.differentOnMobile
                              ? optionsSetup.callToActionButton?.label?.style
                                  ?.style?.fontWeight?.mobile
                              : undefined,
                          },
                        },
                      },
                    },
                    buttonStyle: {
                      ...optionsSetup.callToActionButton?.buttonStyle,
                      textStyle: {
                        ...optionsSetup?.callToActionButton?.buttonStyle
                          ?.textStyle,
                        style: {
                          ...optionsSetup?.callToActionButton?.buttonStyle
                            ?.textStyle?.style,
                          fontSize: {
                            ...optionsSetup?.callToActionButton?.buttonStyle
                              ?.textStyle?.style?.fontSize,
                            mobile: optionsSetup?.callToActionButton
                              ?.buttonStyle?.textStyle?.style?.differentOnMobile
                              ? optionsSetup?.callToActionButton?.buttonStyle
                                  ?.textStyle?.style?.fontSize?.mobile
                              : undefined,
                          },
                          lineHeight: {
                            ...optionsSetup?.callToActionButton?.buttonStyle
                              ?.textStyle?.style?.lineHeight,
                            mobile: optionsSetup?.callToActionButton
                              ?.buttonStyle?.textStyle?.style?.differentOnMobile
                              ? optionsSetup?.callToActionButton?.buttonStyle
                                  ?.textStyle?.style?.lineHeight?.mobile
                              : undefined,
                          },
                          fontWeight: {
                            ...optionsSetup?.callToActionButton?.buttonStyle
                              ?.textStyle?.style?.fontWeight,
                            mobile: optionsSetup?.callToActionButton
                              ?.buttonStyle?.textStyle?.style?.differentOnMobile
                              ? optionsSetup?.callToActionButton?.buttonStyle
                                  ?.textStyle?.style?.fontWeight?.mobile
                              : undefined,
                          },
                        },
                      },
                    },
                  },
                },
              },
            };
          }),
        }
      : {
          general: {
            ...newWidget.general,
            background: {
              ...newWidget.general?.background,
              border: {
                ...newWidget.general?.background?.border,
                value: {
                  ...newWidget.general?.background?.border?.value,
                  lineWeight: {
                    ...newWidget.general?.background?.border?.value?.lineWeight,
                    customValue:
                      newWidget.general?.background?.border?.value?.lineWeight
                        ?.type === LineWeightTypeDtoEnum.CUSTOM
                        ? newWidget.general?.background?.border?.value
                            ?.lineWeight?.customValue
                        : undefined,
                  },
                },
              },
            },
            cornerRadius: {
              ...newWidget.general?.cornerRadius,
              customValue:
                newWidget.general?.cornerRadius?.type ===
                LineWeightTypeDtoEnum.CUSTOM
                  ? newWidget.general?.cornerRadius.customValue
                  : undefined,
            },
          },
          options: {
            ...options,
            callToActionButton: {
              ...options?.callToActionButton,
              label: {
                ...options?.callToActionButton?.label,
                message: {
                  ...options?.callToActionButton?.label?.message,
                  settings: undefined,
                },
                style: {
                  ...options?.callToActionButton?.label?.style,
                  style: {
                    ...options?.callToActionButton?.label?.style?.style,
                    fontSize: {
                      ...options?.callToActionButton?.label?.style?.style
                        ?.fontSize,
                      mobile: options?.callToActionButton?.label?.style?.style
                        ?.differentOnMobile
                        ? options?.callToActionButton?.label?.style?.style
                            ?.fontSize?.mobile
                        : undefined,
                    },
                    lineHeight: {
                      ...options?.callToActionButton?.label?.style?.style
                        ?.lineHeight,
                      mobile: options?.callToActionButton?.label?.style?.style
                        ?.differentOnMobile
                        ? options?.callToActionButton?.label?.style?.style
                            ?.lineHeight?.mobile
                        : undefined,
                    },
                    fontWeight: {
                      ...options?.callToActionButton?.label?.style?.style
                        ?.fontWeight,
                      mobile: options?.callToActionButton?.label?.style?.style
                        ?.differentOnMobile
                        ? options?.callToActionButton?.label?.style?.style
                            ?.fontWeight?.mobile
                        : undefined,
                    },
                  },
                },
              },
              buttonStyle: {
                ...options?.callToActionButton?.buttonStyle,
                textStyle: {
                  ...options?.callToActionButton?.buttonStyle?.textStyle,
                  style: {
                    ...options?.callToActionButton?.buttonStyle?.textStyle
                      ?.style,
                    fontSize: {
                      ...options?.callToActionButton?.buttonStyle?.textStyle
                        ?.style?.fontSize,
                      mobile: options?.callToActionButton?.buttonStyle
                        ?.textStyle?.style?.differentOnMobile
                        ? options?.callToActionButton?.buttonStyle?.textStyle
                            ?.style?.fontSize?.mobile
                        : undefined,
                    },
                    lineHeight: {
                      ...options?.callToActionButton?.buttonStyle?.textStyle
                        ?.style?.lineHeight,
                      mobile: options?.callToActionButton?.buttonStyle
                        ?.textStyle?.style?.differentOnMobile
                        ? options?.callToActionButton?.buttonStyle?.textStyle
                            ?.style?.lineHeight?.mobile
                        : undefined,
                    },
                    fontWeight: {
                      ...options?.callToActionButton?.buttonStyle?.textStyle
                        ?.style?.fontWeight,
                      mobile: options?.callToActionButton?.buttonStyle
                        ?.textStyle?.style?.differentOnMobile
                        ? options?.callToActionButton?.buttonStyle?.textStyle
                            ?.style?.fontWeight?.mobile
                        : undefined,
                    },
                  },
                },
              },
            },
            offerRule: {
              ...options?.offerRule,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              headlineText: {
                ...options?.offerRule?.headlineText,
                style: {
                  ...options?.offerRule?.headlineText?.style,
                  fontSize: {
                    ...options?.offerRule?.headlineText?.style?.fontSize,
                    mobile: options?.offerRule?.headlineText?.style
                      ?.differentOnMobile
                      ? options?.offerRule?.headlineText?.style?.fontSize
                          ?.mobile
                      : undefined,
                  },
                  lineHeight: {
                    ...options?.offerRule?.headlineText?.style?.lineHeight,
                    mobile: options?.offerRule?.headlineText?.style
                      ?.differentOnMobile
                      ? options?.offerRule?.headlineText?.style?.lineHeight
                          ?.mobile
                      : undefined,
                  },
                  fontWeight: {
                    ...options?.offerRule?.headlineText?.style?.fontWeight,
                    mobile: options?.offerRule?.headlineText?.style
                      ?.differentOnMobile
                      ? options?.offerRule?.headlineText?.style?.fontWeight
                          ?.mobile
                      : undefined,
                  },
                },
              },
              ruleText: {
                ...options?.offerRule?.ruleText,
                style: {
                  ...options?.offerRule?.ruleText?.style,
                  fontSize: {
                    ...options?.offerRule?.ruleText?.style?.fontSize,
                    mobile: options?.offerRule?.ruleText?.style
                      ?.differentOnMobile
                      ? options?.offerRule?.ruleText?.style?.fontSize?.mobile
                      : undefined,
                  },
                  lineHeight: {
                    ...options?.offerRule?.ruleText?.style?.lineHeight,
                    mobile: options?.offerRule?.ruleText?.style
                      ?.differentOnMobile
                      ? options?.offerRule?.ruleText?.style?.lineHeight?.mobile
                      : undefined,
                  },
                  fontWeight: {
                    ...options?.offerRule?.ruleText?.style?.fontWeight,
                    mobile: options?.offerRule?.ruleText?.style
                      ?.differentOnMobile
                      ? options?.offerRule?.ruleText?.style?.fontWeight?.mobile
                      : undefined,
                  },
                },
              },
            },
          },
        }),
  };
};
