export enum DiscountValueTypeEnum {
  Percentage = 'Percentage',
  FixedAmount = 'Fixed amount',
  FixedPricePerUnit = 'Fixed price per unit',
}

export enum DiscountValueTypeDTOEnumUX {
  PERCENTAGE = 'Percentage',
  FIXED_AMOUNT = 'Fixed amount',
  FIXED_PRICE_PER_UNIT = 'Fixed price per unit',
}

export enum OfferTieredDiscountTypeDtoEnumUX {
  PERCENTAGE = 'Percentage',
  FIXED_AMOUNT = 'Fixed amount',
}
