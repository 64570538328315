import React, { useEffect, useMemo } from 'react';
import { useI18n } from '@shopify/react-i18n';
import { OptionDtoTriggerGeolocationFilterDto } from 'core/api/adminPromotions/adminPromotionsApi';
import {
  Badge,
  Banner,
  Bleed,
  BlockStack,
  Box,
  Button,
  Card,
  ChoiceList,
  Collapsible,
  InlineStack,
  Link,
  Text,
} from '@shopify/polaris';
import { FilterTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { MultiselectTagCombobox } from 'core/components/MultiSelectComboBox/MultiSelectComboBox';
import { useCountries } from 'core/hooks/useCountries';

type SettingGeolocationFilterProps = {
  updateSettingsState: (data: OptionDtoTriggerGeolocationFilterDto) => void;
  onFormValidityChange(formIsValid: boolean): void;
  geolocationFilter?: OptionDtoTriggerGeolocationFilterDto;
};

export const SettingGeolocationFilter: React.FC<
  SettingGeolocationFilterProps
> = ({ updateSettingsState, onFormValidityChange, geolocationFilter }) => {
  const [i18n] = useI18n();

  const shouldSkip = useMemo(
    () => !geolocationFilter?.enabled,
    [geolocationFilter?.enabled]
  );

  const { countryList: availableCountries } = useCountries(shouldSkip);

  const countriesList = useMemo(
    () => availableCountries?.map((country) => country.name),
    [availableCountries]
  );

  const filterOptions = useMemo(
    () =>
      Object.values(FilterTypeDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    [i18n]
  );

  const isError = useMemo(
    () =>
      geolocationFilter?.enabled &&
      (!geolocationFilter.value ||
        !geolocationFilter.value.countries?.length ||
        !geolocationFilter.value.countries),
    [geolocationFilter]
  );

  useEffect(() => {
    onFormValidityChange(!isError);
  }, [isError]);

  return (
    <div className='GeolocationFilter'>
      <Card>
        <BlockStack gap='400'>
          <InlineStack blockAlign='start' wrap={false} align='space-between'>
            <Box width='65%'>
              <BlockStack gap='100'>
                <InlineStack align='start' gap='200'>
                  <Text fontWeight='semibold' as='h2'>
                    {i18n.translate('Geolocation')}
                  </Text>
                  <Badge
                    tone={geolocationFilter?.enabled ? 'success' : 'enabled'}
                  >
                    {i18n.translate(geolocationFilter?.enabled ? 'On' : 'Off')}
                  </Badge>
                </InlineStack>
                <Text as='p' tone='subdued'>
                  {i18n.translate('GeolocationSubtitle', {
                    followingArticle: (
                      <Link>{i18n.translate('followingArticle')}</Link>
                    ),
                  })}
                </Text>
              </BlockStack>
            </Box>
            <Button
              onClick={() =>
                updateSettingsState({
                  ...geolocationFilter,
                  enabled: !geolocationFilter?.enabled,
                })
              }
            >
              {i18n.translate(
                geolocationFilter?.enabled ? 'TurnOff' : 'TurnOn'
              )}
            </Button>
          </InlineStack>
          <Collapsible id='collapsible' open={!!geolocationFilter?.enabled}>
            <BlockStack gap='400'>
              <Banner tone='info'>
                {i18n.translate('DontRelyInfo', {
                  followingArticle: (
                    <Link>{i18n.translate('followingArticle')}</Link>
                  ),
                })}
              </Banner>
              <ChoiceList
                title=''
                selected={[geolocationFilter?.value?.type as string]}
                choices={filterOptions}
                onChange={(value: FilterTypeDtoEnum[]) => {
                  updateSettingsState({
                    ...geolocationFilter,
                    value: {
                      ...geolocationFilter?.value,
                      type: value[0],
                    },
                  });
                }}
              />
              <MultiselectTagCombobox
                id='SelectCountries'
                tagsInside
                label={i18n.translate('SelectCountries')}
                suggestions={countriesList as string[]}
                placeholder={i18n.translate('SelectCountriesPlaceHolder')}
                requiredIndicator
                labelHidden={false}
                selectedTags={geolocationFilter?.value?.countries || []}
                withAddFunc={false}
                setSelectedTags={(tags: string[]) => {
                  updateSettingsState({
                    ...geolocationFilter,
                    value: {
                      ...geolocationFilter?.value,
                      countries: tags,
                    },
                  });
                }}
              />
              <Bleed marginBlockEnd='400' marginInline='400'>
                <Box background='bg-surface-secondary' padding='400'>
                  <Text as='p' tone='subdued'>
                    {i18n.translate('FilterIsNotApplied', {
                      readArticle: <Link>{i18n.translate('readArticle')}</Link>,
                    })}
                  </Text>
                </Box>
              </Bleed>
            </BlockStack>
          </Collapsible>
        </BlockStack>
      </Card>
    </div>
  );
};
