import React, { useCallback, useMemo } from 'react';
import {
  CartTextGeneralOfferDto,
  CartTextGeneralPresetDto,
  CartTextSystemDefaultsDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { WidgetListTypeDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useAppDispatch } from 'core/hooks';
import {
  Background,
  CornerRadius,
  Size,
  TextEntries,
  TextStyle,
} from '../../../../../components';
import { isEqual } from 'lodash';
import { setCartTextMessages } from 'core/store/widgetsSlice';
type CartTextGeneralDto = CartTextGeneralOfferDto & CartTextGeneralPresetDto;
type PresetCartTextGeneralProps = {
  general: CartTextGeneralDto;
  defaultGeneral: CartTextGeneralDto;
  shopDefaultGeneral: CartTextSystemDefaultsDto;
  defaultLanguage?: string;
  presetSetup?: CartTextGeneralPresetDto;
  offerName?: string;
  offerId?: string;
  pageType?:
    | WidgetListTypeDtoEnum.CART_PAGE_CART_TEXT
    | WidgetListTypeDtoEnum.DRAWER_CART_PAGE_CART_TEXT;
  setGeneral: (data: CartTextGeneralOfferDto) => void;
  onShowHide?: () => void;
  onRemove?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const PresetCartTextGeneral: React.FC<PresetCartTextGeneralProps> = ({
  general,
  defaultGeneral,
  shopDefaultGeneral,
  presetSetup,
  defaultLanguage,
  offerName,
  offerId,
  pageType,
  setGeneral,
  onShowHide,
  onRemove,
  handleLeftMenuSelection,
}) => {
  const dispatch = useAppDispatch();
  const isPresetEditor = !offerName;

  const textStyle = useMemo(
    () => (!isPresetEditor ? presetSetup?.textStyle : general.textStyle) || {},
    [presetSetup, general, isPresetEditor]
  );
  const resetAllToSystemDefault = useCallback(() => {
    const { size, background, cornerRadius, textStyle } = shopDefaultGeneral;
    setGeneral({
      ...general,
      ...(!isPresetEditor
        ? {
            size,
            text: {
              message: defaultGeneral.text?.message,
              style: shopDefaultGeneral.textStyle,
            },
          }
        : { textStyle }),
      cornerRadius,
      background,
    });
  }, [isPresetEditor, shopDefaultGeneral]);

  const updateCartText = useCallback(
    (
      field: keyof CartTextGeneralDto,
      data: CartTextGeneralDto[keyof CartTextGeneralDto]
    ) => {
      const { background, cornerRadius, size, textStyle } = presetSetup || {};
      setGeneral({
        ...general,
        ...(!isPresetEditor && {
          background,
          cornerRadius,
          size,
          text: {
            ...general.text,
            style: textStyle,
          },
        }),
        [field]: data,
      });
    },
    [general, presetSetup, isPresetEditor]
  );

  return (
    <div className='PresetCartTextGeneral'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onRemove={onRemove}
        onDiscardChanges={() => setGeneral(defaultGeneral)}
        onResetToSystem={resetAllToSystemDefault}
        name={(isPresetEditor ? pageType : offerName) as string}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      {!isPresetEditor && (
        <div className='RightSideSection'>
          <TextEntries
            textMessage={general.text?.message || {}}
            onSystemReset={() =>
              updateCartText('text', {
                ...general.text,
                message: defaultGeneral.text?.message,
                style: shopDefaultGeneral.textStyle,
              })
            }
            inherited={isEqual(
              presetSetup?.textStyle,
              defaultGeneral.text?.style
            )}
            defaultLanguage={defaultLanguage || ''}
            disableCollapsed={true}
            disableColor={textStyle.color !== undefined}
            offerId={offerId}
            setTextMessage={(data) =>
              updateCartText('text', {
                ...general.text,
                style: presetSetup?.textStyle,
                message: data,
              })
            }
            setPreviewEntry={(entry) =>
              dispatch(
                setCartTextMessages({
                  data: {
                    ...entry,
                    offerId: offerId as string,
                  },
                  pageType:
                    pageType || WidgetListTypeDtoEnum.CART_PAGE_CART_TEXT,
                })
              )
            }
          />
          <TextStyle
            textStyle={presetSetup?.textStyle || {}}
            hideHeader
            onlyFont
            setTextStyle={(data) =>
              updateCartText('text', {
                ...general.text,
                style: data,
              })
            }
          />
        </div>
      )}
      {isPresetEditor && (
        <div className='RightSideSection'>
          <TextStyle
            textStyle={general.textStyle || {}}
            setTextStyle={(data) => updateCartText('textStyle', data)}
            onSystemReset={() =>
              updateCartText('textStyle', shopDefaultGeneral.textStyle)
            }
          />
        </div>
      )}
      <div className='RightSideSection'>
        <Size
          size={(isPresetEditor ? general.size : presetSetup?.size) || {}}
          setSize={(data) => updateCartText('size', data)}
          disableWidth
          inherited={
            !isPresetEditor
              ? isEqual(presetSetup?.size, defaultGeneral.size)
              : undefined
          }
          onSystemReset={() => updateCartText('size', shopDefaultGeneral.size)}
        />
      </div>
      <div className='RightSideSection'>
        <Background
          background={
            (isPresetEditor ? general.background : presetSetup?.background) ||
            {}
          }
          setBackground={(data) => updateCartText('background', data)}
          onSystemReset={() =>
            updateCartText('background', shopDefaultGeneral.background)
          }
          inherited={
            !isPresetEditor
              ? isEqual(presetSetup?.background, defaultGeneral.background)
              : undefined
          }
        />
      </div>
      <div className='RightSideSection'>
        <CornerRadius
          cornerRadius={
            (isPresetEditor
              ? general.cornerRadius
              : presetSetup?.cornerRadius) || {}
          }
          setCornerRadius={(data) => updateCartText('cornerRadius', data)}
          inherited={
            !isPresetEditor
              ? isEqual(presetSetup?.cornerRadius, defaultGeneral.cornerRadius)
              : undefined
          }
          onSystemReset={() =>
            updateCartText('cornerRadius', shopDefaultGeneral.cornerRadius)
          }
        />
      </div>
    </div>
  );
};

export default PresetCartTextGeneral;
