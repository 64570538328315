import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Editor } from '@tiptap/react';
import { Popover, ActionList } from '@shopify/polaris';
import DownUpArrows from '../assets/DownUpArrows.svg';
import '../RichTextEditor.scss';
import { setToastMessages } from 'core/store/offersWizardSlice';
import { useAppDispatch } from 'core/hooks';

export const FontFamilyToolbarElement: React.FC<{
  disabled: boolean;
  editor: Editor;
}> = (props) => {
  const { editor, disabled } = props;

  const dispatch = useAppDispatch();

  // const { fontsList } = useAppSelector((state) => state.buildingBlocks);

  //Mocked for now. Need to be fixed in future
  let fontsList: any;

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const toggleDropdown = useCallback(
    () => setIsDropdownOpen((active) => !active),
    []
  );

  const currentFont = useMemo(
    () => editor.getAttributes('textStyle').fontFamily,
    [editor.getAttributes('textStyle').fontFamily]
  );

  const activator = useMemo(
    () => (
      <button
        onClick={toggleDropdown}
        className='fontSelector'
        disabled={disabled}
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      >
        <span className='fontName'>
          {fontsList.value.find(
            (font: any) => font.cssReference === currentFont
          )?.name || fontsList?.value[0]?.name}
        </span>
        <img src={DownUpArrows} alt='DownUpArrows' />
      </button>
    ),
    [currentFont, fontsList.value, toggleDropdown, disabled]
  );

  const handleFontSelection = useCallback(
    (fontName: string, didMount?: boolean) => {
      editor.commands.setFontFamily(fontName || '');
      !didMount && toggleDropdown();
    },
    [toggleDropdown, editor]
  );

  const loadFont = useCallback(
    async (id: string, didMount?: boolean) => {
      const currentFont = fontsList.value.find((item: any) => item.id === id);
      if ((document as any).fonts.check(`1em ${currentFont?.name}`)) {
        currentFont?.cssReference &&
          handleFontSelection(currentFont?.cssReference);
      } else {
        await fetch(currentFont?.url as string)
          .then((response) => response.text())
          .then((css) => {
            const style = document.createElement('style');
            style.innerHTML = css;
            document.head.appendChild(style);
          })
          .then(() => {
            currentFont?.cssReference &&
              handleFontSelection(currentFont?.cssReference, didMount);
          })
          .catch(() => {
            dispatch(
              setToastMessages({
                error: true,
                message: 'FontsLoadFailed',
              })
            );
          });
      }
    },
    [fontsList.value, handleFontSelection, dispatch]
  );

  useEffect(() => {
    fontsList?.value[0]?.id && loadFont(fontsList.value[0].id as string, true);
  }, [fontsList?.value[0]?.id]);

  useEffect(() => {
    !currentFont?.length &&
      handleFontSelection(fontsList?.value[0]?.cssReference as string, true);
  }, [currentFont]);

  return (
    <Popover
      active={isDropdownOpen}
      activator={activator}
      onClose={toggleDropdown}
    >
      <ActionList
        actionRole='menuitem'
        items={fontsList.value.map((font: any) => ({
          content: `${font.name}`,
          active: editor.isActive('textStyle', {
            fontFamily: font.cssReference,
          }),
          onAction: () => loadFont(font.id as string),
        }))}
      />
    </Popover>
  );
};
