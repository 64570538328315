import React, { useCallback, useState } from 'react';
import {
  OfferTemplateListDto,
  OfferTypeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { OfferTemplateDto } from 'features/promotions/components/CreatePromotionModal/consts/OfferTemplatesFromTypeData';
import './OfferTemplate.scss';
import {
  Badge,
  Button,
  Link,
  BlockStack,
  InlineStack,
  Icon,
  Text,
  Box,
} from '@shopify/polaris';
import { I18n } from '@shopify/react-i18n';
import { LearnMoreModal } from 'features/promotions/components/SelectedOfferTypeSettings/components/LearnMoreModal/LearnMoreModal';
import { AlertCircleIcon, LockIcon } from '@shopify/polaris-icons';
import { ShopifyPlusIcon } from 'features/promotions/assets/icons';

type OfferTemplateProps = {
  offerTemplate: OfferTemplateDto;
  i18n: I18n;
  fullTemplateListData: OfferTemplateListDto[];
  disabled?: boolean;
  loading?: boolean;
  selected?: boolean;
  onCreate?: (value: OfferTypeDto) => void;
};

const OfferTemplate: React.FC<OfferTemplateProps> = ({
  offerTemplate,
  i18n,
  fullTemplateListData,
  disabled,
  loading,
  selected,
  onCreate,
}) => {
  const [showHowModal, setShowHowModal] = useState<boolean>(false);
  const [activeButton, setActiveButton] = useState<boolean>(false);

  const templateDataInfo = fullTemplateListData.find(
    (item) => item.type === offerTemplate.types[0]
  );

  const toggleShowHowModal = useCallback(
    (event?: any) => {
      if (event) {
        event.stopPropagation();
      }
      setShowHowModal((prevState: boolean) => !prevState);
    },
    [setShowHowModal]
  );

  return (
    <>
      <div
        className='OfferTemplate'
        onMouseEnter={() => setActiveButton(true)}
        onMouseLeave={() => setActiveButton(false)}
      >
        <InlineStack gap='500' align='space-between' blockAlign='start'>
          <Box width='75%'>
            <InlineStack wrap={false} align='start' gap='200'>
              <div>
                {/* for Andriy */}
                {/* <Icon
                  source={
                    !templateDataInfo?.isPremium ? offerTemplate.icon : LockIcon
                  }
                /> */}
              </div>
              <BlockStack gap='400'>
                <BlockStack gap='100'>
                  <InlineStack gap='100'>
                    <Text fontWeight='semibold' as='h2'>
                      {i18n.translate(offerTemplate.title)}
                    </Text>
                    {templateDataInfo?.isNew && (
                      <Badge tone='success'>{i18n.translate(`New`)}</Badge>
                    )}
                    {/* for Andriy */}
                    {/* {templateDataInfo?.isPremium && (
                      <Badge tone='warning'>{i18n.translate(`Premium`)}</Badge>
                    )} */}
                    {selected && (
                      <Badge>{i18n.translate(`CurrentlySelected`)}</Badge>
                    )}
                  </InlineStack>
                  <Text as='p' tone='subdued'>
                    {i18n.translate(offerTemplate.subtitle)}{' '}
                    <span onClick={(e) => toggleShowHowModal(e)}>
                      <Link removeUnderline url=''>
                        {i18n.translate(`WatchTutorial`)}
                      </Link>
                    </span>
                  </Text>
                </BlockStack>
                {/* for Andriy */}
                {/* {templateDataInfo?.isPremium && (
                  <InlineStack gap='100'>
                    <div>
                      <Icon tone='caution' source={AlertCircleIcon} />
                    </div>
                    <Text tone='caution' as='p'>
                      {i18n.translate('FeatureUnavailable')}
                    </Text>
                  </InlineStack>
                )} */}
              </BlockStack>
            </InlineStack>
          </Box>

          <BlockStack gap='300' align='space-between' inlineAlign='end'>
            {/* for Andriy */}
            {/* {!templateDataInfo?.isPremium ? (
              <Button
                disabled={disabled}
                loading={loading}
                variant={activeButton ? 'primary' : 'secondary'}
                onClick={() =>
                  onCreate?.(templateDataInfo?.type as OfferTypeDto)
                }
              >
                {i18n.translate(`Select`)}
              </Button>
            ) : (
              <Button variant='primary'>
                {i18n.translate(`GetTheFeature`)}
              </Button>
            )} */}
            {templateDataInfo?.isShopifyPlus && <ShopifyPlusIcon />}
          </BlockStack>
        </InlineStack>
      </div>
      {showHowModal && (
        <LearnMoreModal
          onModalClose={() => setShowHowModal(false)}
          url={templateDataInfo?.videoUrl}
          title={i18n.translate('OfferExplanation')}
        />
      )}
    </>
  );
};

export default OfferTemplate;
