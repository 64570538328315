import { PromotionCodesBatchOperationTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { useAppDispatch } from 'core/hooks';
import { setToastMessages } from 'core/store/offersWizardSlice';
import resolveEnvVar from 'env-var-resolver';
import { useState } from 'react';

const presetApiURL = `${resolveEnvVar(
  'REACT_APP_PROMOTIONS_ADMIN_API_URL'
)}/promotions/v6/promotion`;

const useExportPromotionCodes = () => {
  const dispatch = useAppDispatch();
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';
  const [exportCodesIsFetching, setExportCodesIsFetching] =
    useState<boolean>(false);
  const [batchExportCodesIsFetching, setBatchExportCodesIsFetching] =
    useState<boolean>(false);
  const exportPromotionCodes = async (promotionId: string) => {
    setExportCodesIsFetching(true);
    const response = await fetch(
      `${presetApiURL}/${promotionId}/settings/promotionCode/export`,
      {
        method: 'GET',
        headers: {
          'X-LimoniApps-AppName': 'DiscountNinja',
          'X-LimoniApps-AppSecret': APP_PASSWORD,
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )
      .then((data) => {
        data.blob().then((myBlob) => {
          const link = URL.createObjectURL(myBlob);
          window.open(link, '_blank');
        });
      })
      .catch(() => {
        dispatch(
          setToastMessages({
            error: true,
            message: 'ExportFailed',
          })
        );
      })
      .finally(() => setExportCodesIsFetching(false));
  };

  const batchExportPromotionCodes = async (
    promotionId: string,
    ids: string[]
  ) => {
    setBatchExportCodesIsFetching(true);
    const response = await fetch(
      `${presetApiURL}/${promotionId}/settings/promotionCode/batch`,
      {
        method: 'PUT',
        body: JSON.stringify({
          operationType: PromotionCodesBatchOperationTypeDtoEnum.EXPORT,
          ids: ids,
        }),
        headers: {
          'Content-Type': 'application/json',
          'X-LimoniApps-AppName': 'DiscountNinja',
          'X-LimoniApps-AppSecret': APP_PASSWORD,
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )
      .then((data) => {
        data.blob().then((myBlob) => {
          const link = URL.createObjectURL(myBlob);
          window.open(link, '_blank');
        });
      })
      .catch(() => {
        dispatch(
          setToastMessages({
            error: true,
            message: 'BatchFailed',
          })
        );
      })
      .finally(() => setBatchExportCodesIsFetching(false));
  };

  return {
    exportPromotionCodes,
    batchExportPromotionCodes,
    batchExportCodesIsFetching,
    exportCodesIsFetching,
  };
};

export default useExportPromotionCodes;
