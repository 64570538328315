import { useGetAuthenticateQuery } from 'core/api/auth';
import { setToken } from 'core/store/authSlice';
import { useEffect } from 'react';
import { useAppDispatch, useIsDebugOrLocal } from '.';
import resolveEnvVar from 'env-var-resolver';
import { useLocation } from 'react-router-dom';

export const useAuthentication = () => {
  const dispatch = useAppDispatch();
  const isLocal = useIsDebugOrLocal();
  const { search } = useLocation();

  const { data, isSuccess, isLoading, isError } = useGetAuthenticateQuery({
    shopifyAccessToken: resolveEnvVar('REACT_APP_SHOPIFY_ACCESS_TOKEN'),
    appName: resolveEnvVar('REACT_APP_APP_NAME'),
    uri: resolveEnvVar('REACT_APP_HOST_URI'),
    debugShop: resolveEnvVar('REACT_APP_DEBUG_SHOP'),
  });

  useEffect(() => {
    let token = null;
    if (isLocal) {
      token = data?.Token;
    } else {
      token = data?.discountNinjaSessionToken;
      localStorage.setItem(
        'passwordDevLogin',
        resolveEnvVar('REACT_APP_API_SECRET')
      );

      const params = new URLSearchParams(search);
      const host = params.get('host');
      if (host) {
        localStorage.setItem('encodedHost', host);
      }
    }

    if (token) {
      dispatch(
        setToken({
          token: token,
        })
      );
      localStorage.setItem('token', token);
    }
  }, [dispatch, data, isLocal, search]);

  return { isAuthenticated: isSuccess, isError, isLoading };
};
