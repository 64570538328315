import React, { useMemo } from 'react';
import './NotificationAnimations.scss';
import { useI18n } from '@shopify/react-i18n';
import { NotificationAnimationsShopSettingsDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { AnimationDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import StyleComponentHeader from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components/StyleComponentHeader/StyleComponentHeader';
type NotificationAnimationsProps = {
  animations: NotificationAnimationsShopSettingsDto;
  disabled?: boolean;
  setAnimations?: (data: NotificationAnimationsShopSettingsDto) => void;
  onSystemReset?: () => void;
};
const NotificationAnimations: React.FC<NotificationAnimationsProps> = ({
  animations,
  disabled,
  setAnimations,
  onSystemReset,
}) => {
  const [i18n] = useI18n();

  const animationsOptions = useMemo(
    () =>
      Object.keys(AnimationDtoEnum).map((value) => ({
        value: value,
        label: i18n.translate(`${value}`),
      })),
    []
  );

  return (
    <div className='NotificationAnimations'>
      <StyleComponentHeader
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            disabled: disabled,
            onAction: () => onSystemReset?.(),
          },
        ]}
        label={i18n.translate(`Animations`)}
      >
        <div className='AnimationsRow'>
          {i18n.translate(`FirstDisplay`)}
          <div className='RightContent'>
            <SelectOptions
              options={animationsOptions}
              disabled={disabled}
              onOptionSelect={(value) =>
                setAnimations?.({
                  ...animations,
                  onFirstDisplay: {
                    ...animations.onFirstDisplay,
                    value: value as AnimationDtoEnum,
                  },
                })
              }
              selectedOption={
                animations.onFirstDisplay?.value ||
                AnimationDtoEnum.FADE_OUT_BOTTOM
              }
              label=''
            />
          </div>
        </div>
        <div className='AnimationsRow'>
          {i18n.translate(`Minimizing`)}
          <div className='RightContent'>
            <SelectOptions
              options={animationsOptions}
              disabled={disabled}
              onOptionSelect={(value) =>
                setAnimations?.({
                  ...animations,
                  whenMinimizing: {
                    ...animations.whenMinimizing,
                    value: value as AnimationDtoEnum,
                  },
                })
              }
              selectedOption={
                animations.whenMinimizing?.value || AnimationDtoEnum.FADE_OUT
              }
              label=''
            />
          </div>
        </div>
      </StyleComponentHeader>
    </div>
  );
};

export default NotificationAnimations;
