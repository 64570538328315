import React, { FC, useCallback } from 'react';
import {
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  Collapsible,
  InlineStack,
  Link,
  Text,
  TextField,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  OptionDtoInt32,
  OptionDtoOfferDiscountLimitDto,
} from 'core/api/adminPromotions/adminPromotionsApi';

type DiscountLimits = {
  type: 'Discount' | 'Gifts';
  limits?: OptionDtoInt32 | undefined;
  discountLimit?: OptionDtoOfferDiscountLimitDto | undefined;
  limitUpdate?: (limit: OptionDtoInt32) => void;
  discountLimitUpdate?: (limit: OptionDtoOfferDiscountLimitDto) => void;
  maxAmount?: number;
};

export const DiscountLimits: FC<DiscountLimits> = ({
  type,
  limits,
  discountLimit,
  limitUpdate,
  discountLimitUpdate,
  maxAmount,
}) => {
  const [i18n] = useI18n();

  const handleSaveChange = useCallback(
    (value: number) => {
      if (type === 'Gifts' && limitUpdate) {
        limitUpdate({ ...limits, value: value } as OptionDtoInt32);
      } else if (discountLimitUpdate) {
        discountLimitUpdate({
          ...discountLimit,
          value: { limit: value },
        } as OptionDtoOfferDiscountLimitDto);
      }
    },
    [type, limitUpdate, discountLimitUpdate, limits, discountLimit]
  );

  const handleToggleChange = useCallback(() => {
    if (type === 'Gifts' && limitUpdate) {
      limitUpdate({ ...limits, enabled: !limits?.enabled });
    } else if (discountLimitUpdate) {
      discountLimitUpdate({
        ...discountLimit,
        enabled: !discountLimit?.enabled,
      });
    }
  }, [type, limitUpdate, discountLimitUpdate, limits, discountLimit]);

  const handleValidateValue = useCallback((): string | boolean => {
    if (
      (limits?.value || 0) < (maxAmount || 0) ||
      limits?.value === 0 ||
      discountLimit?.value?.limit === 0
    ) {
      return i18n.translate(`${type}.Error`);
    } else {
      return false;
    }
  }, [discountLimit, limits, limits?.value, handleSaveChange]);

  return (
    <Card>
      <BlockStack gap='400'>
        <InlineStack blockAlign='start' wrap={false} align='space-between'>
          <Box width='80%'>
            <BlockStack gap='100'>
              <InlineStack align='start' gap='200'>
                <Text as='h2' variant='headingSm'>
                  {i18n.translate(`${type}.Title`)}
                </Text>
                <Badge
                  tone={
                    limits?.enabled || discountLimit?.enabled
                      ? 'success'
                      : 'enabled'
                  }
                >
                  {i18n.translate(
                    limits?.enabled || discountLimit?.enabled ? 'On' : 'Off'
                  )}
                </Badge>
              </InlineStack>
              <Text as='span' tone='subdued'>
                {i18n.translate(`${type}.Subtitle`, {
                  link: (
                    <Link url='/' target='_blank'>
                      {i18n.translate('FollowingArticle')}
                    </Link>
                  ),
                })}
              </Text>
            </BlockStack>
          </Box>
          <Button onClick={() => handleToggleChange()}>
            {i18n.translate(
              limits?.enabled || discountLimit?.enabled ? 'TurnOff' : 'TurnOn'
            )}
          </Button>
        </InlineStack>
        <Collapsible
          id='collapsible'
          open={!!(limits?.enabled || discountLimit?.enabled)}
        >
          <Box width='35%'>
            <TextField
              label={i18n.translate(`${type}.Label`)}
              type='number'
              value={
                limits?.value?.toString() ||
                discountLimit?.value?.limit?.toString()
              }
              onChange={(value) => handleSaveChange(Number(value))}
              autoComplete='off'
              error={handleValidateValue()}
            />
          </Box>
        </Collapsible>
      </BlockStack>
    </Card>
  );
};
