import React, { useCallback, useMemo } from 'react';
import {
  PromotionSetupDtoRead,
  DiscountLabelSystemDefaultsDto,
  OfferWidgetSetupDtoDiscountLabelOfferDtoRead,
  DiscountLabelGeneralOfferDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { SelectedOptionPath } from '../../../../RightSideBarWrapper';
import { WidgetTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { getLastSelectedOption } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import { WidgetListTypeDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import DiscountLabelOffer from './components/DiscountLabelOffer/DiscountLabelOffer';
import { WidgetOptionHeader } from '../../../components';
import { Link, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';

type PromotionDiscountLabelProps = {
  editPromotionState: PromotionSetupDtoRead;
  defaultPromotionData: PromotionSetupDtoRead;
  shopDefaultData: DiscountLabelSystemDefaultsDto;
  selectedOptionPath: SelectedOptionPath;
  updateEditPromotionState: (
    field: keyof PromotionSetupDtoRead,
    data: PromotionSetupDtoRead[keyof PromotionSetupDtoRead]
  ) => void;
  filterSupportedWidgets: (value: WidgetTypeDtoEnum) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const PromotionDiscountLabel: React.FC<PromotionDiscountLabelProps> = ({
  editPromotionState,
  defaultPromotionData,
  shopDefaultData,
  selectedOptionPath,
  updateEditPromotionState,
  filterSupportedWidgets,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();
  const { discountLabel } = editPromotionState;
  const offersData = editPromotionState.offers?.discountLabel;
  const defaultOffersData = defaultPromotionData.offers?.discountLabel;

  const updateOffer = useCallback(
    (data: OfferWidgetSetupDtoDiscountLabelOfferDtoRead, offerId: string) =>
      updateEditPromotionState('offers', {
        ...editPromotionState.offers,
        discountLabel: editPromotionState.offers?.discountLabel?.map(
          (offerData) => {
            if (offerData?.offerId === offerId) {
              return data;
            }
            return offerData;
          }
        ),
      }),

    [updateEditPromotionState, editPromotionState]
  );

  const discountLabelOptions = useMemo(() => {
    const offerData = offersData?.find(
      (offer) => offer.offerId === selectedOptionPath?.selectedOption?.offerId
    ) as OfferWidgetSetupDtoDiscountLabelOfferDtoRead;

    const defaultOfferData = defaultOffersData?.find(
      (offer) => offer.offerId === selectedOptionPath?.selectedOption?.offerId
    ) as OfferWidgetSetupDtoDiscountLabelOfferDtoRead;

    const selectedOption = getLastSelectedOption(selectedOptionPath);

    const updateOfferGeneral = (data: DiscountLabelGeneralOfferDto) =>
      updateOffer(
        {
          ...offerData,
          setup: {
            ...offerData.setup,
            general: data,
          },
        },
        offerData?.offerId as string
      );

    switch (true) {
      case selectedOption?.name === WidgetListTypeDtoEnum.DISCOUNT_LABEL:
        return (
          <div className='EmptyRightSideBanner'>
            <WidgetOptionHeader
              name={i18n.translate(selectedOption?.name || '')}
              handleLeftMenuSelection={handleLeftMenuSelection}
              onRemove={
                discountLabel?.canBeRemoved
                  ? () =>
                      filterSupportedWidgets(WidgetTypeDtoEnum.DISCOUNT_LABEL)
                  : undefined
              }
            />
            <div className='RightSideSection'>
              <Text as='p' tone='subdued'>
                {i18n.translate('AddCustomMessage')}
                <Link>{i18n.translate('LearnMore')}</Link>
              </Text>
            </div>
            <div className='RightSideSection'>
              <Text as='p' tone='subdued'>
                {i18n.translate('NoCustomizable')}
              </Text>
            </div>
          </div>
        );
      case !!selectedOption?.offerId:
        return (
          <DiscountLabelOffer
            key={offerData?.offerId}
            offer={offerData.setup?.general || {}}
            offerName={selectedOption?.name || ''}
            offerId={offerData?.offerId}
            defaultOffer={defaultOfferData.setup?.general || {}}
            shopDefaultOffer={shopDefaultData || {}}
            defaultLanguage={editPromotionState.offers?.defaultLanguage || ''}
            handleLeftMenuSelection={handleLeftMenuSelection}
            onShowHide={
              offerData.canBeHidden
                ? () =>
                    updateOffer(
                      { ...offerData, isHidden: !offerData?.isHidden },
                      offerData?.offerId as string
                    )
                : undefined
            }
            setOffer={updateOfferGeneral}
          />
        );
    }
  }, [
    discountLabel,
    offersData,
    defaultOffersData,
    editPromotionState,
    selectedOptionPath,
    handleLeftMenuSelection,
    updateOffer,
  ]);

  return <div className='PromotionDiscountLabel'>{discountLabelOptions}</div>;
};

export default PromotionDiscountLabel;
