import React, { useCallback } from 'react';
import { PricingBaseGeneralOfferDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { CustomCSS } from '../../../../../components';

type PriceOfferProps = {
  offer: PricingBaseGeneralOfferDto;
  defaultOffer: PricingBaseGeneralOfferDto;
  shopDefaultOffer: string;
  offerName: string;
  presetSetup: string;
  setOffer: (data: PricingBaseGeneralOfferDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const PriceOffer: React.FC<PriceOfferProps> = ({
  offer,
  defaultOffer,
  shopDefaultOffer,
  offerName,
  presetSetup,
  setOffer,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const resetAllToSystemDefault = useCallback(() => {
    setOffer({
      ...offer,
      customCss: shopDefaultOffer,
    });
  }, [shopDefaultOffer, defaultOffer]);

  return (
    <div className='PriceOffer'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={() => setOffer(defaultOffer)}
        onResetToSystem={resetAllToSystemDefault}
        name={offerName}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />

      <div className='RightSideSection'>
        <CustomCSS
          css={offer.customCss || ''}
          preview={presetSetup}
          setCSS={(value) => setOffer({ ...offer, customCss: value })}
        />
      </div>
    </div>
  );
};

export default PriceOffer;
