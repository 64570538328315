import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import {
//   GetPromotionDraftStatusResponseDto,
//   OfferCombinationsDetailsDto,
//   OfferTypeDto,
//   PagedResponseDtoOfferCombinationListDto,
//   PagedResponseDtoOfferSelectableCombinationListDtoShopifyId,
//   PostPromotionsV6PromotionDraftsByIdApiResponse,
//   PromotionDraftOverviewHeaderDto,
//   PromotionDraftOverviewOfferDto,
//   PromotionDraftTriggerDiscountLinkPreviewDto,
// } from 'core/api/adminNew';
import {
  OfferRelationalCombinationTypeDto,
  OfferCombinationsDetailsDto,
  OfferTypeDto,
  // PagedResponseDtoOfferSelectableCombinationListDtoShopifyId,
  PagedResponseDtoOfferCombinationListDto,
  OfferSmartTags,
  SmartTag,
  PagedResponseDtoOfferSelectableCombinationListDtoGuid,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { OfferTypesFromCatalogDataType } from 'features/promotions/components/SelectedOfferTypeSettings/types/OfferTypesFromCatalogTypes';
import { SectionTypeEnum } from 'features/promotions/components/SelectedOfferTypeSettings/enums/SectionType';

export interface OfferFormValidity {
  sectionName: SectionTypeEnum;
  isValid: boolean;
}

export type CombinedWithListProps = {
  PRODUCT?: PagedResponseDtoOfferCombinationListDto;
  OTHER_PRODUCT?: PagedResponseDtoOfferCombinationListDto;
  ORDER?: PagedResponseDtoOfferCombinationListDto;
  OTHER_ORDER?: PagedResponseDtoOfferCombinationListDto;
  SHIPPING?: PagedResponseDtoOfferCombinationListDto;
};

export type NotCombinedWithListProps = {
  offers?: PagedResponseDtoOfferSelectableCombinationListDtoGuid;
  shopifyDiscountCodes?: PagedResponseDtoOfferCombinationListDto;
};

export type NotCombinedWithListPageProps = {
  page: number;
  search: string;
  shouldFetch: boolean;
};

export type NotCombinedWithListPagesProps = {
  offers: NotCombinedWithListPageProps;
  shopifyDiscountCodes: NotCombinedWithListPageProps;
};

export type lastSelectedOfferProps = {
  id?: string;
  type?: OfferTypeDto;
};

export type ToastProps = {
  error: boolean;
  message: string;
};

export type SmartTagValue = {
  keyValue: string;
  currencyCode?: string;
};

interface OffersWizardSliceState {
  tagsList: string[];
  selectedOfferType: OfferTypesFromCatalogDataType | null;
  // promotionDraftStatus: GetPromotionDraftStatusResponseDto;
  unfetchedPromotionData: boolean;
  formValidity: OfferFormValidity[];
  isEditOfferDraftClicked: boolean;
  isPromotionCodeUnique: boolean;
  lastSelectedOffer: lastSelectedOfferProps;
  currentCombination: OfferCombinationsDetailsDto;
  combinedWithList: CombinedWithListProps;
  notCombinedWithList: NotCombinedWithListProps;
  lastCombinedWithTypeFetched: OfferRelationalCombinationTypeDto;
  currentCombinationIsLoaded: boolean;
  lastCombinedWithTypePage: number;
  lastNotCombinedWithTypeData: NotCombinedWithListPagesProps;
  isUpdatePromotionOfferDraftIsFetching: boolean;
  isUpdateOfferDraftError: boolean;
  currentCombinationIsFetching: boolean;
  toastMessages: ToastProps[];
  offerHasChanges: boolean;
  currentOfferData: any;
  typeListIsFetching: boolean;
  combinationsOffersListIsFetching: boolean;
  shopifyDiscountCodesIsFetching: boolean;
  smartTagsList: OfferSmartTags[] | SmartTag[];
  smartTagsCodes: Record<string, SmartTagValue>;
  currentConfigPage: string | null;
  resetTemplate: number;
}

const initialState: OffersWizardSliceState = {
  tagsList: [],
  selectedOfferType: null,
  // promotionDraftStatus: { id: undefined, status: undefined },
  // promotionDraftData: {},
  unfetchedPromotionData: false,
  formValidity: [],
  isEditOfferDraftClicked: false,
  isPromotionCodeUnique: true,
  lastSelectedOffer: {},
  // defaultDiscountLink: {},
  currentCombination: {},
  combinedWithList: {},
  notCombinedWithList: {},
  lastCombinedWithTypeFetched: null,
  currentCombinationIsLoaded: false,
  isUpdatePromotionOfferDraftIsFetching: false,
  currentCombinationIsFetching: false,
  isUpdateOfferDraftError: false,
  lastCombinedWithTypePage: 1,
  lastNotCombinedWithTypeData: {
    offers: {
      page: 1,
      search: '',
      shouldFetch: false,
    },
    shopifyDiscountCodes: {
      page: 1,
      search: '',
      shouldFetch: false,
    },
  },
  toastMessages: [],
  offerHasChanges: false,
  currentOfferData: null,
  typeListIsFetching: false,
  combinationsOffersListIsFetching: false,
  shopifyDiscountCodesIsFetching: false,
  smartTagsList: [],
  smartTagsCodes: {},
  currentConfigPage: null,
  resetTemplate: 0,
};

export const OffersWizardSlice = createSlice({
  name: 'createPromotionWizard-1',
  initialState,
  reducers: {
    setTagsList: (
      state,
      action: PayloadAction<OffersWizardSliceState['tagsList']>
    ) => {
      state.tagsList = action.payload;
    },
    setResetTemplate: (
      state,
      action: PayloadAction<OffersWizardSliceState['resetTemplate']>
    ) => {
      state.resetTemplate = action.payload;
    },
    setCurrentConfigPage: (
      state,
      action: PayloadAction<OffersWizardSliceState['currentConfigPage']>
    ) => {
      state.currentConfigPage = action.payload;
    },
    setSmartTagsCodes: (
      state,
      action: PayloadAction<OffersWizardSliceState['smartTagsCodes']>
    ) => {
      state.smartTagsCodes = action.payload;
    },
    setShopifyDiscountCodesIsFetching: (
      state,
      action: PayloadAction<
        OffersWizardSliceState['shopifyDiscountCodesIsFetching']
      >
    ) => {
      state.shopifyDiscountCodesIsFetching = action.payload;
    },
    setTypeListIsFetching: (
      state,
      action: PayloadAction<OffersWizardSliceState['typeListIsFetching']>
    ) => {
      state.typeListIsFetching = action.payload;
    },
    setCombinationsOffersListIsFetching: (
      state,
      action: PayloadAction<
        OffersWizardSliceState['combinationsOffersListIsFetching']
      >
    ) => {
      state.combinationsOffersListIsFetching = action.payload;
    },
    setCurrentOfferData: (state, action) => {
      state.currentOfferData = action.payload;
    },
    setOfferHasChanges: (
      state,
      action: PayloadAction<OffersWizardSliceState['offerHasChanges']>
    ) => {
      state.offerHasChanges = action.payload;
    },
    setToastMessages: (state, action: PayloadAction<ToastProps>) => {
      const existingIndex = state.toastMessages.findIndex(
        (toast) => toast.message === action.payload.message
      );
      if (existingIndex === -1) {
        state.toastMessages = [action.payload, ...state.toastMessages];
      }
    },
    deleteToastMessage: (
      state,
      action: PayloadAction<ToastProps['message']>
    ) => {
      state.toastMessages = state.toastMessages.filter(
        (toast) => toast.message !== action.payload
      );
    },
    setSelectedOfferType: (
      state,
      action: PayloadAction<OffersWizardSliceState['selectedOfferType']>
    ) => {
      state.selectedOfferType = action.payload;
    },
    setPromotionDraftDataFetchingIndicator: (
      state,
      action: PayloadAction<OffersWizardSliceState['unfetchedPromotionData']>
    ) => {
      state.unfetchedPromotionData = action.payload;
    },
    setPromotionOfferValidityState: (
      state,
      action: PayloadAction<OffersWizardSliceState['formValidity']>
    ) => {
      state.formValidity = action.payload;
    },
    setIsEditOfferDraftClicked: (
      state,
      action: PayloadAction<OffersWizardSliceState['isEditOfferDraftClicked']>
    ) => {
      state.isEditOfferDraftClicked = action.payload;
    },
    setIsPromotionCodeUnique: (
      state,
      action: PayloadAction<OffersWizardSliceState['isPromotionCodeUnique']>
    ) => {
      state.isPromotionCodeUnique = action.payload;
    },
    setLastSelectedOffer: (
      state,
      action: PayloadAction<OffersWizardSliceState['lastSelectedOffer']>
    ) => {
      state.lastSelectedOffer = action.payload;
    },
    setCurrentCombination: (
      state,
      action: PayloadAction<OffersWizardSliceState['currentCombination']>
    ) => {
      state.currentCombination = action.payload;
      state.currentCombinationIsLoaded = true;
    },
    setCurrentCombinationIsLoaded: (
      state,
      action: PayloadAction<
        OffersWizardSliceState['currentCombinationIsLoaded']
      >
    ) => {
      state.currentCombinationIsLoaded = action.payload;
    },
    setCurrentCombinationIsFetching: (
      state,
      action: PayloadAction<
        OffersWizardSliceState['currentCombinationIsFetching']
      >
    ) => {
      state.currentCombinationIsFetching = action.payload;
    },
    setLastCombinedWithTypeFetched: (
      state,
      action: PayloadAction<
        OffersWizardSliceState['lastCombinedWithTypeFetched']
      >
    ) => {
      state.lastCombinedWithTypeFetched = action.payload;
    },
    setCombinedWithList: (
      state,
      action: PayloadAction<OffersWizardSliceState['combinedWithList']>
    ) => {
      state.combinedWithList = action.payload;
    },
    setNotCombinedWithList: (
      state,
      action: PayloadAction<OffersWizardSliceState['notCombinedWithList']>
    ) => {
      state.notCombinedWithList = action.payload;
    },
    setLastCombinedWithTypePage: (
      state,
      action: PayloadAction<OffersWizardSliceState['lastCombinedWithTypePage']>
    ) => {
      state.lastCombinedWithTypePage = action.payload;
    },
    setLastNotCombinedWithTypeData: (
      state,
      action: PayloadAction<
        OffersWizardSliceState['lastNotCombinedWithTypeData']
      >
    ) => {
      state.lastNotCombinedWithTypeData = action.payload;
    },
    setIsUpdatePromotionOfferDraftIsFetching: (
      state,
      action: PayloadAction<
        OffersWizardSliceState['isUpdatePromotionOfferDraftIsFetching']
      >
    ) => {
      state.isUpdatePromotionOfferDraftIsFetching = action.payload;
    },
    setIsUpdateOfferDraftError: (
      state,
      action: PayloadAction<OffersWizardSliceState['isUpdateOfferDraftError']>
    ) => {
      state.isUpdateOfferDraftError = action.payload;
    },
    setSmartTagsList: (
      state,
      action: PayloadAction<OffersWizardSliceState['smartTagsList']>
    ) => {
      state.smartTagsList = action.payload;
    },
  },
});

export const {
  setTagsList,
  setSelectedOfferType,
  setPromotionDraftDataFetchingIndicator,
  setPromotionOfferValidityState,
  setIsEditOfferDraftClicked,
  setIsPromotionCodeUnique,
  setLastSelectedOffer,
  setCurrentCombination,
  setLastCombinedWithTypeFetched,
  setCombinedWithList,
  setCurrentCombinationIsLoaded,
  setCurrentCombinationIsFetching,
  setLastCombinedWithTypePage,
  setLastNotCombinedWithTypeData,
  setNotCombinedWithList,
  setIsUpdatePromotionOfferDraftIsFetching,
  setIsUpdateOfferDraftError,
  setToastMessages,
  deleteToastMessage,
  setOfferHasChanges,
  setCurrentOfferData,
  setTypeListIsFetching,
  setCombinationsOffersListIsFetching,
  setShopifyDiscountCodesIsFetching,
  setSmartTagsList,
  setSmartTagsCodes,
  setCurrentConfigPage,
  setResetTemplate,
} = OffersWizardSlice.actions;

export default OffersWizardSlice.reducer;
