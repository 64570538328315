import React from 'react';
import { Banner, BlockStack, Card, Checkbox, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { Element } from 'react-scroll';
type MessageCountSettingProps = {
  messageCount: boolean;
  setMessageCount: (value: boolean) => void;
};
export const MessageCountSetting: React.FC<MessageCountSettingProps> = ({
  messageCount,
  setMessageCount,
}) => {
  const [i18n] = useI18n();

  return (
    <Element name='MessageCountSetting' className='MessageCountSetting'>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('MessageCount')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('Subtitle')}
            </Text>
          </BlockStack>
          <Checkbox
            checked={messageCount}
            onChange={(value) => setMessageCount(value)}
            label={i18n.translate('EnableMessageCount')}
          />
          {messageCount && (
            <Banner tone='info'>{i18n.translate('BannerText')}</Banner>
          )}
        </BlockStack>
      </Card>
    </Element>
  );
};
