import React, { useMemo } from 'react';
import { BlockStack, Card, ChoiceList, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { SizeTypeDto } from 'core/api/adminSettings/adminSettingsApi';
import { SizeTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { Element } from 'react-scroll';

type SizeTypeSettingProps = {
  sizeType: SizeTypeDto;
  setSizeType: (value: SizeTypeDtoEnum) => void;
  simplified?: boolean;
  subtitle: string;
};
export const SizeTypeSetting: React.FC<SizeTypeSettingProps> = ({
  sizeType,
  subtitle,
  simplified,
  setSizeType,
}) => {
  const [i18n] = useI18n();

  const sizeTypeOptions = useMemo(() => {
    const options = simplified
      ? [SizeTypeDtoEnum.SMALL, SizeTypeDtoEnum.MEDIUM, SizeTypeDtoEnum.LARGE]
      : Object.values(SizeTypeDtoEnum);
    return options.map((value) => ({
      label: i18n.translate(value),
      value: value,
    }));
  }, [simplified]);

  return (
    <Element className='SizeTypeSetting' name='SizeTypeSetting'>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('Size')}
            </Text>
            <Text as='p' tone='subdued'>
              {subtitle}
            </Text>
          </BlockStack>
          <ChoiceList
            title=''
            choices={sizeTypeOptions}
            selected={[sizeType || SizeTypeDtoEnum.MEDIUM]}
            onChange={(value) => setSizeType(value[0] as SizeTypeDtoEnum)}
          />
        </BlockStack>
      </Card>
    </Element>
  );
};
