import { Layout, Page, Range, LegacyStack } from '@shopify/polaris';
import { DateRangeBar, PagerOptionsType, Pagination } from 'core/components';
import { useDateRange } from 'core/hooks';
import { usePromotionReportData } from 'mocks';
import React, { useCallback, useEffect, useState } from 'react';
import { PromotionReportQueryType } from '../types';
import {
  PromotionResultsFilersBar,
  PromotionsResultExportBar,
  PromotionResultsTable,
} from './components';

export const PromotionResults: React.FC = () => {
  const { dateRange, dateNow } = useDateRange();
  const [queryData, setQueryData] = useState<PromotionReportQueryType>({
    date: dateRange,
    page: 1,
    entriesToShow: '10',
  });
  const { data, isLoading, recordsPerPage, recordsTotal, fetchData } =
    usePromotionReportData(queryData);

  const handleChangeDateRange = useCallback(
    (dateRange?: Range) => {
      setQueryData({ ...queryData, date: dateRange, page: 1 });
    },
    [queryData]
  );

  const handleChangeEntriesToShow = useCallback(
    (entriesToShow: PagerOptionsType) => {
      setQueryData({ ...queryData, entriesToShow, page: 1 }); // TODO TBC when when full API integration
    },
    [queryData]
  );

  const handleChangeSearchTerm = useCallback(
    (searchTerm: string) => {
      setQueryData({ ...queryData, searchTerm, page: 1 }); // TODO TBC when when full API integration
    },
    [queryData]
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setQueryData({ ...queryData, page }); // TODO TBC when when full API integration
    },
    [queryData]
  );

  useEffect(() => {
    fetchData(queryData);
  }, [queryData, fetchData]);

  return (
    <Page fullWidth>
      <Layout>
        <Layout.Section>
          <DateRangeBar
            onChangeDateRange={handleChangeDateRange}
            onRefresh={handleChangeDateRange}
            startDate={queryData?.date?.start}
            endDate={queryData?.date?.end}
            disableDatesAfter={dateNow}
          />
        </Layout.Section>
        <Layout.Section>
          <LegacyStack vertical>
            <PromotionsResultExportBar data={data?.data || []} />
            <PromotionResultsFilersBar
              entriesToShow={queryData?.entriesToShow || '10'}
              onChangeEntriesToShow={handleChangeEntriesToShow}
              onChangeSearchTerm={handleChangeSearchTerm}
            />
            <PromotionResultsTable
              data={data?.data || []}
              isLoading={isLoading}
            />
            {data && (
              <Pagination
                page={queryData?.page || 1}
                recordsTotal={recordsTotal}
                recordsPerPage={recordsPerPage}
                onChangePage={handlePageChange}
                showDescription
              />
            )}
          </LegacyStack>
        </Layout.Section>
      </Layout>
    </Page>
  );
};
