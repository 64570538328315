import { DevicePreview } from 'features/settings/components/DevicePreview/DevicePreview';
import React, { useMemo } from 'react';
import { useScreenResolution } from 'core/hooks/useScreenResolution';
import {
  DeviceTypeDtoEnum,
  PageTypeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import { EditPresetTypeEnum } from '../../enums/enums';

type PreviewProps = {
  isRightSidebar: number;
  data: any; // for now any
  page: PageTypeDtoEnum;
  activeDeviceType: DeviceTypeDtoEnum;
  handleZoomChange(value: number): void;
  getSelectionFromThePreview(path: string): void;
  handleSwitchWidgetState(widgetName: string, entries: any[]): void;
  zoomValue: number;
  defaultLanguage?: string;
  adminActivePath: string;
  currentInitialZoom: number;
  skeletonType: EditPresetTypeEnum;
  setInitialZoom: React.Dispatch<
    React.SetStateAction<{
      MOBILE_INITIAL_SCALE: number;
      DESKTOP_INITIAL_SCALE: number;
    }>
  >;
};

export const Preview: React.FC<PreviewProps> = ({
  isRightSidebar,
  data,
  activeDeviceType,
  handleZoomChange,
  getSelectionFromThePreview,
  handleSwitchWidgetState,
  zoomValue,
  defaultLanguage,
  adminActivePath,
  page,
  currentInitialZoom,
  setInitialZoom,
  skeletonType,
}) => {
  const { height } = useScreenResolution();

  // `calc(100vh - ${(5 / 100) * (height || 1) + 64}px)` // for not page

  const currentHeight = useMemo(() => height - 64, [height]);

  return (
    <div
      style={{
        flex: `0 0 calc(100% - ${isRightSidebar}px)`,
        position: 'relative',
        overflow: 'hidden',
        height: currentHeight,
      }}
    >
      <DevicePreview
        page={page}
        data={data}
        previewType={activeDeviceType}
        handleZoomChange={handleZoomChange}
        zoomValue={zoomValue}
        defaultLanguage={defaultLanguage}
        adminActivePath={adminActivePath}
        getSelectionFromThePreview={getSelectionFromThePreview}
        handleSwitchWidgetState={handleSwitchWidgetState}
        currentHeight={currentHeight}
        currentInitialZoom={currentInitialZoom}
        setInitialZoom={setInitialZoom}
        skeletonType={skeletonType}
      />
    </div>
  );
};
