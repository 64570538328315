export enum AnimationDtoEnum {
    NONE = "NONE",
    SLIDE = "SLIDE",
    SLIDE_IN = "SLIDE_IN",
    SLIDE_OUT = "SLIDE_OUT",
    SLIDE_OUT_TOP = "SLIDE_OUT_TOP",
    SLIDE_OUT_RIGHT = "SLIDE_OUT_RIGHT",
    SLIDE_OUT_LEFT = "SLIDE_OUT_LEFT",
    SLIDE_OUT_BOTTOM = "SLIDE_OUT_BOTTOM",
    FADE = "FADE",
    FADE_IN = "FADE_IN",
    FADE_OUT = "FADE_OUT",
    FADE_OUT_TOP = "FADE_OUT_TOP",
    FADE_OUT_RIGHT = "FADE_OUT_RIGHT",
    FADE_OUT_LEFT = "FADE_OUT_LEFT",
    FADE_OUT_BOTTOM = "FADE_OUT_BOTTOM",
    INSTANT = "INSTANT",
    BOUNCE = "BOUNCE",
    FLASH = "FLASH",
    SHAKE = "SHAKE",
    MOVE_IN = "MOVE_IN",
    MOVE_OUT = "MOVE_OUT"
}
export enum AnnouncementBarCyclingDurationDtoEnum {
    LONG = "LONG",
    NORMAL = "NORMAL",
    SHORT = "SHORT",
    CUSTOM = "CUSTOM"
}
export enum AnnouncementBarFirstLevelPresetEntryTypeDtoEnum {
    PRIMARY_BANNER = "PRIMARY_BANNER",
    SECONDARY_BANNER = "SECONDARY_BANNER"
}
export enum AnnouncementBarFirstLevelPromotionEntryTypeDtoEnum {
    PRIMARY_BANNER = "PRIMARY_BANNER",
    SECONDARY_BANNER = "SECONDARY_BANNER"
}
export enum AnnouncementBarIndicatorsColorTypeDtoEnum {
    DARK = "DARK",
    LIGHT = "LIGHT"
}
export enum AnnouncementBarIndicatorsStyleDtoEnum {
    DOTS = "DOTS",
    BARS = "BARS"
}
export enum AnnouncementBarOfferLevelEntryTypeDtoEnum {
    ICON = "ICON",
    IMAGE = "IMAGE",
    MESSAGE = "MESSAGE"
}
export enum AnnouncementBarOfferPositionDtoEnum {
    ANNOUNCEMENT_BAR_PRIMARY_BANNER = "ANNOUNCEMENT_BAR_PRIMARY_BANNER",
    ANNOUNCEMENT_BAR_SECONDARY_BANNER_SLOT_1 = "ANNOUNCEMENT_BAR_SECONDARY_BANNER_SLOT_1",
    ANNOUNCEMENT_BAR_SECONDARY_BANNER_SLOT_2 = "ANNOUNCEMENT_BAR_SECONDARY_BANNER_SLOT_2",
    ANNOUNCEMENT_BAR_SECONDARY_BANNER_SLOT_3 = "ANNOUNCEMENT_BAR_SECONDARY_BANNER_SLOT_3"
}
export enum AnnouncementBarPagePositionDtoEnum {
    ABOVE_HEADER = "ABOVE_HEADER",
    BELOW_HEADER = "BELOW_HEADER",
    BOTTOM_OF_PAGE = "BOTTOM_OF_PAGE"
}
export enum AnnouncementBarPrimaryBannerLevelPresetEntryTypeDtoEnum {
    ICON = "ICON",
    IMAGE = "IMAGE",
    MESSAGE = "MESSAGE"
}
export enum AnnouncementBarSecondaryBannerLevelEntryTypePromotionDtoEnum {
    SLOT_1 = "SLOT_1",
    SLOT_2 = "SLOT_2",
    SLOT_3 = "SLOT_3"
}
export enum AnnouncementBarSecondaryBannerLevelPresetEntryTypeDtoEnum {
    ICON = "ICON",
    IMAGE = "IMAGE",
    MESSAGE = "MESSAGE"
}
export enum BackgroundColorFillTypeDtoEnum {
    SOLID = "SOLID",
    IMAGE = "IMAGE"
}
export enum BadgeStyleShapeDtoEnum {
    RECTANGLE = "RECTANGLE",
    CIRCLE = "CIRCLE",
    STAR = "STAR",
    RIBBON = "RIBBON"
}
export enum BadgeStyleSizeDtoEnum {
    SMALL = "SMALL",
    MEDIUM = "MEDIUM",
    LARGE = "LARGE"
}
export enum BodyRowPricingModuleEntryTypeDtoEnum {
    PRICE = "PRICE",
    BADGE = "BADGE"
}
export enum BorderSideDtoEnum {
    ALL = "ALL",
    TOP = "TOP",
    BOTTOM = "BOTTOM",
    LEFT = "LEFT",
    RIGHT = "RIGHT",
    MIDDLE = "MIDDLE"
}
export enum BorderStyleTypeDtoEnum {
    SOLID = "SOLID",
    DASHED = "DASHED"
}
export enum ButtonCornersStyleDtoEnum {
    SQUARE = "SQUARE",
    ROUND_EXTRA_SMALL = "ROUND_EXTRA_SMALL",
    ROUND_SMALL = "ROUND_SMALL",
    ROUND_MEDIUM = "ROUND_MEDIUM",
    ROUND_LARGE = "ROUND_LARGE",
    ROUND_EXTRA_LARGE = "ROUND_EXTRA_LARGE",
    CIRCLE = "CIRCLE"
}
export enum CollapsedStateDtoEnum {
    COLLAPSED = "COLLAPSED",
    NOT_COLLAPSED = "NOT_COLLAPSED"
}
export enum CornerRadiusSimpleTypeDtoEnum {
    NONE = "NONE",
    SMALL = "SMALL",
    MEDIUM = "MEDIUM",
    LARGE = "LARGE"
}
export enum CornerRadiusTypeDtoEnum {
    NONE = "NONE",
    CIRCLE = "CIRCLE",
    CUSTOM = "CUSTOM"
}
export enum DeviceDisplayDtoEnum {
    ALL = "ALL",
    DESKTOP = "DESKTOP",
    MOBILE = "MOBILE"
}
export enum DeviceTypeDtoEnum {
    DESKTOP = "DESKTOP",
    MOBILE = "MOBILE"
}
export enum DiscountLabelTypeDtoEnum {
    EMPTY = "EMPTY",
    DISCOUNT_CODE = "DISCOUNT_CODE",
    PERCENTAGE = "PERCENTAGE",
    YOU_SAVE_PERCENTAGE = "YOU_SAVE_PERCENTAGE",
    YOU_SAVE_AMOUNT_ON_EACH = "YOU_SAVE_AMOUNT_ON_EACH",
    FREE_GIFT = "FREE_GIFT",
    FREE_GIFT_ENHANCED = "FREE_GIFT_ENHANCED",
    BLACK_FRIDAY = "BLACK_FRIDAY",
    CYBER_MONDAY_SALE = "CYBER_MONDAY_SALE",
    CHRISTMAS = "CHRISTMAS",
    CUSTOM = "CUSTOM"
}
export enum FontStyleTypeDtoEnum {
    INHERITED = "INHERITED",
    CUSTOM = "CUSTOM"
}
export enum GoalStateDtoEnum {
    BEFORE = "BEFORE",
    DURING = "DURING",
    AFTER = "AFTER"
}
export enum HoveringStyleDtoEnum {
    DARKER = "DARKER"
}
export enum IconStyleGroupDtoEnum {
    THIN = "THIN",
    THICK = "THICK",
    SOLID = "SOLID"
}
export enum ImageFitDtoEnum {
    COVER = "COVER",
    CONTAIN = "CONTAIN"
}
export enum ImageSizeDtoEnum {
    SMALL = "SMALL",
    MEDIUM = "MEDIUM",
    LARGE = "LARGE"
}
export enum InputCornersStyleDtoEnum {
    SQUARE = "SQUARE",
    ROUND_EXTRA_SMALL = "ROUND_EXTRA_SMALL",
    ROUND_SMALL = "ROUND_SMALL",
    ROUND_MEDIUM = "ROUND_MEDIUM",
    ROUND_LARGE = "ROUND_LARGE",
    ROUND_EXTRA_LARGE = "ROUND_EXTRA_LARGE",
    CIRCLE = "CIRCLE"
}
export enum LineWeightTypeDtoEnum {
    EXTRA_SMALL = "EXTRA_SMALL",
    SMALL = "SMALL",
    MEDIUM = "MEDIUM",
    LARGE = "LARGE",
    EXTRA_LARGE = "EXTRA_LARGE",
    CUSTOM = "CUSTOM"
}
export enum NinjaCartButtonStyleSetKeyDtoEnum {
    PRIMARY = "PRIMARY",
    SECONDARY = "SECONDARY"
}
export enum NinjaCartCallToActionButtonActionTypeDtoEnum {
    CLOSE = "CLOSE",
    OPEN_PAGE = "OPEN_PAGE",
    GO_TO_CHECKOUT = "GO_TO_CHECKOUT",
    GO_TO_CART = "GO_TO_CART",
    EVALUATE_JS = "EVALUATE_JS"
}
export enum NinjaCartCartItemPresetEntryTypeDtoEnum {
    PRODUCT_BADGE = "PRODUCT_BADGE",
    CART_TEXT = "CART_TEXT"
}
export enum NinjaCartCartItemQuantitySelectorTypeDtoEnum {
    TYPE_1 = "TYPE_1",
    TYPE_2 = "TYPE_2",
    TYPE_3 = "TYPE_3"
}
export enum NinjaCartDefaultStateContentPresetEntryTypeDtoEnum {
    CART_ITEM = "CART_ITEM"
}
export enum NinjaCartDefaultStateFooterCtaButtonsPresetEntryTypeDtoEnum {
    CTA_BUTTON_1 = "CTA_BUTTON_1",
    CTA_BUTTON_2 = "CTA_BUTTON_2"
}
export enum NinjaCartDefaultStateFooterPresetEntryTypeDtoEnum {
    PROMOTION_SUMMARY = "PROMOTION_SUMMARY",
    CTA_BUTTONS = "CTA_BUTTONS"
}
export enum NinjaCartDefaultStateHeaderPresetEntryTypeDtoEnum {
    HEADING = "HEADING",
    ITEM_COUNT = "ITEM_COUNT"
}
export enum NinjaCartDefaultStatePresetEntryTypeDtoEnum {
    HEADER = "HEADER",
    CONTENT = "CONTENT",
    FOOTER = "FOOTER"
}
export enum NinjaCartEmptyStatePresetEntryTypeDtoEnum {
    HEADER = "HEADER",
    IMAGE = "IMAGE",
    TEXT = "TEXT",
    CALL_TO_ACTION_BUTTON = "CALL_TO_ACTION_BUTTON"
}
export enum NinjaCartHeaderItemCountStyleDtoEnum {
    STYLE_1 = "STYLE_1",
    STYLE_2 = "STYLE_2"
}
export enum NinjaCartHeaderLayoutDtoEnum {
    LAYOUT_1 = "LAYOUT_1",
    LAYOUT_2 = "LAYOUT_2"
}
export enum NinjaCartStatesPresetEntryTypeDtoEnum {
    EMPTY_STATE = "EMPTY_STATE",
    DEFAULT_STATE = "DEFAULT_STATE"
}
export enum NotificationCollapsedStatePresetEntryTypeDtoEnum {
    HEADER = "HEADER"
}
export enum NotificationExpandedStatePresetEntryTypeDtoEnum {
    HEADER = "HEADER",
    OFFER = "OFFER",
    FOOTER = "FOOTER",
    IMAGE = "IMAGE"
}
export enum NotificationStatesPresetEntryTypeDtoEnum {
    COLLAPSED_STATE = "COLLAPSED_STATE",
    EXPANDED_STATE = "EXPANDED_STATE"
}
export enum NotificationStatesPromotionEntryTypeDtoEnum {
    COLLAPSED_STATE = "COLLAPSED_STATE",
    EXPANDED_STATE = "EXPANDED_STATE"
}
export enum OfferRulePopupLevelPresetEntryTypeDtoEnum {
    OFFER_RULE = "OFFER_RULE",
    CALL_TO_ACTION_BUTTON = "CALL_TO_ACTION_BUTTON"
}
export enum OfferRulePopupOfferLevelEntryTypeDtoEnum {
    HEADLINE_TEXT = "HEADLINE_TEXT",
    RULE_TEXT = "RULE_TEXT",
    CALL_TO_ACTION_BUTTON = "CALL_TO_ACTION_BUTTON"
}
export enum OffsetTypeDtoEnum {
    EXTRA_SMALL = "EXTRA_SMALL",
    SMALL = "SMALL",
    MEDIUM = "MEDIUM",
    LARGE = "LARGE",
    EXTRA_LARGE = "EXTRA_LARGE",
    CUSTOM = "CUSTOM"
}
export enum PaddingsDtoEnum {
    EXTRA_SMALL = "EXTRA_SMALL",
    SMALL = "SMALL",
    MEDIUM = "MEDIUM",
    LARGE = "LARGE",
    EXTRA_LARGE = "EXTRA_LARGE"
}
export enum PageDisplayTypeDtoEnum {
    DEFAULT = "DEFAULT",
    CUSTOM = "CUSTOM"
}
export enum PageTypeDtoEnum {
    ALL = "ALL",
    HOME = "HOME",
    COLLECTION = "COLLECTION",
    PRODUCT = "PRODUCT",
    CART = "CART",
    DRAWER_CART = "DRAWER_CART",
    NINJA_CART = "NINJA_CART",
    CHECKOUT = "CHECKOUT",
    BLOG_POST = "BLOG_POST",
    SEARCH = "SEARCH",
    CUSTOM = "CUSTOM"
}
export enum PositionDtoEnum {
    TOP = "TOP",
    TOP_LEFT = "TOP_LEFT",
    TOP_RIGHT = "TOP_RIGHT",
    BOTTOM_LEFT = "BOTTOM_LEFT",
    BOTTOM_RIGHT = "BOTTOM_RIGHT",
    BOTTOM = "BOTTOM",
    CENTER = "CENTER",
    MIDDLE = "MIDDLE",
    LEFT = "LEFT",
    RIGHT = "RIGHT"
}
export enum PresetBoundariesTypeDtoEnum {
    PROMOTION_LEVEL = "PROMOTION_LEVEL",
    SHOP_LEVEL = "SHOP_LEVEL"
}
export enum PresetListSortFieldDtoEnum {
    NAME = "NAME"
}
export enum PresetTypeDtoEnum {
    SYSTEM = "SYSTEM",
    CUSTOM = "CUSTOM"
}
export enum PriceStateLevelEntryTypeDtoEnum {
    HEADER_TEXT = "HEADER_TEXT",
    BODY_ROW_1 = "BODY_ROW_1",
    BODY_ROW_2 = "BODY_ROW_2",
    BODY_ROW_3 = "BODY_ROW_3",
    FOOTER_TEXT = "FOOTER_TEXT"
}
export enum PriceStatesEntryTypeDtoEnum {
    DISCOUNTED_PRICE = "DISCOUNTED_PRICE",
    NOT_DISCOUNTED_PRICE = "NOT_DISCOUNTED_PRICE"
}
export enum PricingLevelPromotionEntryTypeDtoEnum {
    DISCOUNTED_PRICE = "DISCOUNTED_PRICE",
    NOT_DISCOUNTED_PRICE = "NOT_DISCOUNTED_PRICE"
}
export enum PricingOfferEntryTypeDtoEnum {
    HEADER_TEXT = "HEADER_TEXT",
    FOOTER_TEXT = "FOOTER_TEXT"
}
export enum ProductBannerFirstLevelEntryTypeDtoEnum {
    ICON = "ICON",
    IMAGE = "IMAGE",
    MESSAGE = "MESSAGE"
}
export enum ProductBannerOfferLevelEntryTypeDtoEnum {
    ICON = "ICON",
    IMAGE = "IMAGE",
    MESSAGE = "MESSAGE",
    ENTITLED = "ENTITLED",
    PREREQUISITE = "PREREQUISITE"
}
export enum PromotionCodeFieldCodeValidationStateDtoEnum {
    INVALID = "INVALID",
    NOT_APPLICABLE = "NOT_APPLICABLE",
    CODE_APPLIED = "CODE_APPLIED"
}
export enum PromotionCodeFieldDisplayModeDtoEnum {
    COLLAPSED = "COLLAPSED",
    EXPANDED = "EXPANDED"
}
export enum PromotionCodeFieldHeaderStyleDtoEnum {
    STYLE_1 = "STYLE_1",
    STYLE_2 = "STYLE_2"
}
export enum PromotionCodeFieldPresetEntryTypeDtoEnum {
    CODE_INPUT = "CODE_INPUT",
    CODE_VALIDATION = "CODE_VALIDATION",
    CALL_TO_ACTION_BUTTON = "CALL_TO_ACTION_BUTTON"
}
export enum PromotionCodeFieldPromotionEntryTypeDtoEnum {
    CODE_INPUT = "CODE_INPUT",
    CODE_VALIDATION = "CODE_VALIDATION",
    CALL_TO_ACTION_BUTTON = "CALL_TO_ACTION_BUTTON"
}
export enum PromotionStatusDtoEnum {
    PUBLISHED = "PUBLISHED",
    SCHEDULED = "SCHEDULED",
    EXPIRED = "EXPIRED",
    DRAFT = "DRAFT",
    IN_PROGRESS_ACTIVATING = "IN_PROGRESS_ACTIVATING",
    IN_PROGRESS_DEACTIVATING = "IN_PROGRESS_DEACTIVATING",
    IN_PROGRESS_ROLLING_BACK = "IN_PROGRESS_ROLLING_BACK",
    USED = "USED",
    ARCHIVED = "ARCHIVED",
    PUBLISHED_EDITED = "PUBLISHED_EDITED"
}
export enum PromotionSummaryCalculationsBehaviorDtoEnum {
    SUM_OF_ALL = "SUM_OF_ALL",
    ORDER_DISCOUNTS_ONLY = "ORDER_DISCOUNTS_ONLY"
}
export enum PromotionSummaryLevelPresetEntryTypeDtoEnum {
    TOTAL = "TOTAL",
    SUBTOTAL = "SUBTOTAL",
    SHIPPING = "SHIPPING",
    TAXES_AND_DUTIES = "TAXES_AND_DUTIES",
    DISCOUNT = "DISCOUNT",
    DISCLAIMER = "DISCLAIMER"
}
export enum PromotionSummaryLevelPromotionEntryTypeDtoEnum {
    TOTAL = "TOTAL",
    SUBTOTAL = "SUBTOTAL",
    SHIPPING = "SHIPPING",
    TAXES_AND_DUTIES = "TAXES_AND_DUTIES",
    DISCOUNT = "DISCOUNT",
    DISCLAIMER = "DISCLAIMER"
}
export enum PromotionSummaryPriceBreakdownBehaviorDtoEnum {
    ALLOW_COLLAPSING = "ALLOW_COLLAPSING",
    ALWAYS_OPEN = "ALWAYS_OPEN"
}
export enum PromotionSummaryShippingAmountCalculationBehaviorDtoEnum {
    CALCULATED_AT_CHECKOUT = "CALCULATED_AT_CHECKOUT",
    CALCULATED_IN_CART = "CALCULATED_IN_CART"
}
export enum PromotionalBadgeBadgeStyleSetKeyDtoEnum {
    PRIMARY = "PRIMARY",
    SECONDARY = "SECONDARY"
}
export enum PromotionalBadgeFirstLevelEntryTypeDtoEnum {
    MESSAGE = "MESSAGE",
    BADGE = "BADGE"
}
export enum ResourceGroupKeyDtoEnum {
    OFFER = "OFFER",
    CHEVRONS = "CHEVRONS",
    CLOSE_BUTTON = "CLOSE_BUTTON"
}
export enum ResourceLibraryTypeDtoEnum {
    DEFAULT = "DEFAULT",
    CUSTOM = "CUSTOM"
}
export enum ShadowSizeDtoEnum {
    EXTRA_SMALL = "EXTRA_SMALL",
    SMALL = "SMALL",
    MEDIUM = "MEDIUM",
    LARGE = "LARGE",
    EXTRA_LARGE = "EXTRA_LARGE"
}
export enum SizeTypeDtoEnum {
    EXTRA_SMALL = "EXTRA_SMALL",
    SMALL = "SMALL",
    MEDIUM = "MEDIUM",
    LARGE = "LARGE",
    EXTRA_LARGE = "EXTRA_LARGE"
}
export enum SizeWidthDtoEnum {
    AUTO = "AUTO",
    FIXED = "FIXED",
    BREAKPOINTS = "BREAKPOINTS"
}
export enum SortDirectionDtoEnum {
    ASC = "ASC",
    DESC = "DESC"
}
export enum SpacingTypeDtoEnum {
    EXTRA_SMALL = "EXTRA_SMALL",
    SMALL = "SMALL",
    MEDIUM = "MEDIUM",
    LARGE = "LARGE",
    EXTRA_LARGE = "EXTRA_LARGE",
    CUSTOM = "CUSTOM"
}
export enum StackingDirectionDtoEnum {
    VERTICAL = "VERTICAL",
    HORIZONTAL = "HORIZONTAL"
}
export enum StitchDashSizeTypeDtoEnum {
    SMALL = "SMALL",
    MEDIUM = "MEDIUM",
    LARGE = "LARGE",
    CUSTOM = "CUSTOM"
}
export enum TextTypeDtoEnum {
    UNIFIED = "UNIFIED",
    DIFFERENT = "DIFFERENT"
}
export enum TransactionSaveModeDtoEnum {
    SAVE = "SAVE",
    SAVE_AS_NEW_PRESET = "SAVE_AS_NEW_PRESET",
    DISCARD = "DISCARD"
}
export enum WidgetTypeDtoEnum {
    ANNOUNCEMENT_BAR = "ANNOUNCEMENT_BAR",
    NOTIFICATION = "NOTIFICATION",
    PROMOTIONAL_BADGE = "PROMOTIONAL_BADGE",
    POP_UP = "POP_UP",
    PRODUCT_BANNER = "PRODUCT_BANNER",
    DRAWER_CART_PROMOTION_SUMMARY = "DRAWER_CART_PROMOTION_SUMMARY",
    CART_PROMOTION_SUMMARY = "CART_PROMOTION_SUMMARY",
    DRAWER_CART_PROMOTION_CODE_FIELD = "DRAWER_CART_PROMOTION_CODE_FIELD",
    CART_PROMOTION_CODE_FIELD = "CART_PROMOTION_CODE_FIELD",
    CHECKOUT_MESSAGE = "CHECKOUT_MESSAGE",
    VOLUME_DISCOUNT = "VOLUME_DISCOUNT",
    OFFER_RULE_POPUP = "OFFER_RULE_POPUP",
    PRODUCT_PAGE_PRICE = "PRODUCT_PAGE_PRICE",
    COLLECTION_PAGE_PRICE = "COLLECTION_PAGE_PRICE",
    DRAWER_CART_PRODUCT_PRICE = "DRAWER_CART_PRODUCT_PRICE",
    DRAWER_CART_LINE_PRICE = "DRAWER_CART_LINE_PRICE",
    DRAWER_CART_TOTAL_PRICE = "DRAWER_CART_TOTAL_PRICE",
    DRAWER_CART_BREAKDOWN_PRICE = "DRAWER_CART_BREAKDOWN_PRICE",
    CART_PRODUCT_PRICE = "CART_PRODUCT_PRICE",
    CART_LINE_PRICE = "CART_LINE_PRICE",
    CART_TOTAL_PRICE = "CART_TOTAL_PRICE",
    CART_BREAKDOWN_PRICE = "CART_BREAKDOWN_PRICE",
    CART_PAGE_CART_TEXT = "CART_PAGE_CART_TEXT",
    DRAWER_CART_PAGE_CART_TEXT = "DRAWER_CART_PAGE_CART_TEXT",
    DISCOUNT_LABEL = "DISCOUNT_LABEL",
    NINJA_CART = "NINJA_CART"
}
export enum WidgetsValidationErrorCodeEnum {
    RESOURCE_CONTENT_IS_NULL_OR_EMPTY = "RESOURCE_CONTENT_IS_NULL_OR_EMPTY",
    RESOURCE_IMAGE_EXCEEDS_MAX_FILE_SIZE = "RESOURCE_IMAGE_EXCEEDS_MAX_FILE_SIZE",
    RESOURCE_IMAGE_IS_NOT_AN_IMAGE_TYPE = "RESOURCE_IMAGE_IS_NOT_AN_IMAGE_TYPE",
    PRESET_RESTRICTED_TO_BE_DELETED = "PRESET_RESTRICTED_TO_BE_DELETED",
    PRESET_RESTRICTED_TO_BE_UPDATED = "PRESET_RESTRICTED_TO_BE_UPDATED",
    PRESET_NAME_INVALID = "PRESET_NAME_INVALID",
    PRESET_WIDGET_TYPES_MISSING = "PRESET_WIDGET_TYPES_MISSING",
    COLOR_INVALID_FORMAT = "COLOR_INVALID_FORMAT",
    TEXT_MESSAGE_FONT_SIZE_INVALID_VALUE = "TEXT_MESSAGE_FONT_SIZE_INVALID_VALUE",
    TEXT_MESSAGE_FONT_WEIGHT_INVALID_VALUE = "TEXT_MESSAGE_FONT_WEIGHT_INVALID_VALUE",
    TEXT_MESSAGE_LINE_HEIGHT_INVALID_VALUE = "TEXT_MESSAGE_LINE_HEIGHT_INVALID_VALUE",
    GIVEN_FONT_MISSING = "GIVEN_FONT_MISSING",
    TRANSACTION_IDENTIFIER_MISSING = "TRANSACTION_IDENTIFIER_MISSING",
    PRESET_CUSTOM_LIMIT_REACHED = "PRESET_CUSTOM_LIMIT_REACHED",
    BREAKPOINT_CONTENT_WIDTH_INVALID = "BREAKPOINT_CONTENT_WIDTH_INVALID",
    BREAKPOINT_MAX_WIDTH_INVALID = "BREAKPOINT_MAX_WIDTH_INVALID",
    BREAKPOINT_CONTENT_WIDTH_CANNOT_BE_HIGHER_THAN_MAX_WIDTH = "BREAKPOINT_CONTENT_WIDTH_CANNOT_BE_HIGHER_THAN_MAX_WIDTH",
    PRESET_DOES_NOT_EXIST = "PRESET_DOES_NOT_EXIST",
    PROMOTION_SETUP_INVALID_STYLE_PRIORITY = "PROMOTION_SETUP_INVALID_STYLE_PRIORITY",
    BEHAVIOR_INVALID_PAGE_TYPES = "BEHAVIOR_INVALID_PAGE_TYPES",
    RESOURCE_ICON_EXCEEDS_MAX_FILE_SIZE = "RESOURCE_ICON_EXCEEDS_MAX_FILE_SIZE",
    RESOURCE_ICON_IS_NOT_AN_ICON_TYPE = "RESOURCE_ICON_IS_NOT_AN_ICON_TYPE",
    STACKING_MISSING_CUSTOM_SPACING = "STACKING_MISSING_CUSTOM_SPACING",
    FONT_MISSING = "FONT_MISSING",
    BACKGROUND_IMAGE_MISSING = "BACKGROUND_IMAGE_MISSING",
    BACKGROUND_COLOR_MISSING = "BACKGROUND_COLOR_MISSING",
    IMAGE_NOT_FOUND = "IMAGE_NOT_FOUND",
    ICON_NOT_FOUND = "ICON_NOT_FOUND",
    PROMOTION_SETUP_AT_LEAST_ONE_SUPPORTED_WIDGET_REQUIRED = "PROMOTION_SETUP_AT_LEAST_ONE_SUPPORTED_WIDGET_REQUIRED",
    OFFSET_TYPE_MISSING = "OFFSET_TYPE_MISSING",
    LINE_WEIGHT_INVALID_CUSTOM_VALUE = "LINE_WEIGHT_INVALID_CUSTOM_VALUE",
    LINE_WEIGHT_MISSING_CUSTOM_VALUE = "LINE_WEIGHT_MISSING_CUSTOM_VALUE",
    STITCH_MISSING_CUSTOM_DASH_SIZE = "STITCH_MISSING_CUSTOM_DASH_SIZE",
    STITCH_INVALID_CUSTOM_DASH_SIZE = "STITCH_INVALID_CUSTOM_DASH_SIZE",
    RESOURCE_CANNOT_BE_DELETED_ALREADY_IN_USE = "RESOURCE_CANNOT_BE_DELETED_ALREADY_IN_USE",
    OFFSET_INVALID_CUSTOM_VALUE = "OFFSET_INVALID_CUSTOM_VALUE",
    ANNOUNCEMENT_BAR_POSITION_ALREADY_TAKEN = "ANNOUNCEMENT_BAR_POSITION_ALREADY_TAKEN",
    PRESET_WIDGET_TYPES_OUTSIDE_BOUNDARIES = "PRESET_WIDGET_TYPES_OUTSIDE_BOUNDARIES",
    CORNER_RADIUS_INVALID_CUSTOM_VALUE = "CORNER_RADIUS_INVALID_CUSTOM_VALUE",
    PRESET_CANNOT_BE_SET_AS_PROMOTION_LEVEL = "PRESET_CANNOT_BE_SET_AS_PROMOTION_LEVEL",
    PRESET_CANNOT_BE_SET_AS_SHOP_LEVEL = "PRESET_CANNOT_BE_SET_AS_SHOP_LEVEL",
    PRESET_BOUNDARIES_MISSING = "PRESET_BOUNDARIES_MISSING",
    PRESET_SET_AS_ACTIVE_FLAG_MISSING = "PRESET_SET_AS_ACTIVE_FLAG_MISSING",
    PRESET_CREATING_PRESET_ON_INVALID_BASE_PRESET_BOUNDARIES = "PRESET_CREATING_PRESET_ON_INVALID_BASE_PRESET_BOUNDARIES"
}
