import React from 'react';
import { BlockStack, Box, Button, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import DiscountNinja from '../../assets/DiscountNinja.svg';
type WelcomeStepProps = {
  onNextStep: () => void;
};
export const WelcomeStep: React.FC<WelcomeStepProps> = ({ onNextStep }) => {
  const [i18n] = useI18n();
  return (
    <Box paddingBlock='400'>
      <BlockStack gap='800' inlineAlign='center'>
        <BlockStack gap='600' inlineAlign='center'>
          <img src={DiscountNinja} alt='DiscountNinja' />
          <BlockStack inlineAlign='center' gap='200'>
            <Text
              alignment='center'
              as='h1'
              variant='headingLg'
              fontWeight='semibold'
            >
              {i18n.translate('WelcometoDN')}
            </Text>
            <Box width='75%'>
              <Text alignment='center' as='p' tone='subdued'>
                {i18n.translate('Description')}
              </Text>
            </Box>
          </BlockStack>
        </BlockStack>
        <Box paddingBlock='400'>
          <Button onClick={onNextStep} variant='primary'>
            {i18n.translate('StartConfiguration')}
          </Button>
        </Box>
      </BlockStack>
    </Box>
  );
};
