import React from 'react';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import { useI18n } from '@shopify/react-i18n';
import { OptionDtoAnnouncementBarIndicatorsDto } from 'core/api/adminWidgets/adminWidgetsApi';
import {
  AnnouncementBarIndicatorsColorTypeDtoEnum,
  AnnouncementBarIndicatorsStyleDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import './Indicators.scss';
import { Checkbox } from '@shopify/polaris';

type IndicatorsProps = {
  indicators: OptionDtoAnnouncementBarIndicatorsDto;
  disabled?: boolean;
  onlyColor?: boolean;
  inherited?: boolean;
  setIndicators: (value: OptionDtoAnnouncementBarIndicatorsDto) => void;
  onSystemReset: () => void;
};
export const Indicators: React.FC<IndicatorsProps> = ({
  indicators,
  disabled,
  onlyColor,
  inherited,
  setIndicators,
  onSystemReset,
}) => {
  const [i18n] = useI18n();

  const styleOptions = Object.values(AnnouncementBarIndicatorsStyleDtoEnum).map(
    (value) => {
      return {
        value: value,
        label: i18n.translate(`${value}`),
      };
    }
  );
  const colorTypeOptions = Object.values(
    AnnouncementBarIndicatorsColorTypeDtoEnum
  ).map((value) => {
    return {
      value: value,
      label: i18n.translate(`${value}`),
    };
  });

  return (
    <div className='Indicators'>
      <StyleComponentHeader
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            disabled: disabled,
            onAction: () => onSystemReset(),
          },
        ]}
        inherited={inherited}
        label={i18n.translate(`Indicators`)}
      >
        {indicators.enabled && (
          <>
            <div className='IndicatorsRow'>
              {i18n.translate(`Style`)}
              <div className='RightContent'>
                <SelectOptions
                  options={styleOptions}
                  onOptionSelect={(value) =>
                    setIndicators({
                      ...indicators,
                      value: {
                        ...indicators.value,
                        style: value as AnnouncementBarIndicatorsStyleDtoEnum,
                      },
                    })
                  }
                  selectedOption={
                    indicators.value?.style ||
                    AnnouncementBarIndicatorsStyleDtoEnum.BARS
                  }
                  label=''
                  disabled={disabled || onlyColor}
                />
              </div>
            </div>
            <div className='IndicatorsRow'>
              {i18n.translate(`Color`)}
              <div className='RightContent'>
                <SelectOptions
                  options={colorTypeOptions}
                  onOptionSelect={(value) =>
                    setIndicators({
                      ...indicators,
                      value: {
                        ...indicators.value,
                        color:
                          value as AnnouncementBarIndicatorsColorTypeDtoEnum,
                      },
                    })
                  }
                  selectedOption={
                    indicators.value?.color ||
                    AnnouncementBarIndicatorsColorTypeDtoEnum.LIGHT
                  }
                  label=''
                  disabled={disabled}
                />
              </div>
            </div>
          </>
        )}
      </StyleComponentHeader>
    </div>
  );
};
