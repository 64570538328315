import React from 'react';
import StyleComponentHeader from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components/StyleComponentHeader/StyleComponentHeader';
import { useI18n } from '@shopify/react-i18n';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import { Banner } from '@shopify/polaris';
import { ComboboxOption } from 'core/components';

type SingleInputSelectionProps = {
  options: ComboboxOption[];
  label: string;
  selectedOption: string;
  disabled?: boolean;
  inherited?: boolean;
  bannerContent?: JSX.Element;
  noteContent?: JSX.Element;
  setOption?: (value: string) => void;
  onSystemReset?: () => void;
};
export const SingleInputSelection: React.FC<SingleInputSelectionProps> = ({
  options,
  selectedOption,
  label,
  disabled,
  bannerContent,
  inherited,
  noteContent,
  setOption,
  onSystemReset,
}) => {
  const [i18n] = useI18n();
  return (
    <div className='SingleInputSelection'>
      <StyleComponentHeader
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            disabled: disabled,
            onAction: () => onSystemReset?.(),
          },
        ]}
        inherited={inherited}
        label={label}
      >
        <SelectOptions
          options={options}
          onOptionSelect={(value) => setOption?.(value)}
          selectedOption={selectedOption}
          disabled={disabled}
          label=''
        />
        {bannerContent && <Banner>{bannerContent}</Banner>}
        {noteContent && noteContent}
      </StyleComponentHeader>
    </div>
  );
};
