import {
  NotificationPresetDtoRead,
  OfferWidgetSetupDtoNotificationOfferDtoRead,
  PromotionWidgetSetupMultiLevelDtoNotificationPromotionDtoNotificationStatesPromotionDtoNotificationStatesPromotionEntryTypeDtoRead,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { LineWeightTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';

export type PromotionWidgetSetupDtoNotificationPromotionDtoReadWithOffers =
  PromotionWidgetSetupMultiLevelDtoNotificationPromotionDtoNotificationStatesPromotionDtoNotificationStatesPromotionEntryTypeDtoRead & {
    offers?: OfferWidgetSetupDtoNotificationOfferDtoRead[] | null;
  };

export const notificationMergeFunc = (
  widget: PromotionWidgetSetupDtoNotificationPromotionDtoReadWithOffers,
  setup?: NotificationPresetDtoRead
) => {
  const {
    isShopLevelWidget,
    canBeDragged,
    canBeRemoved,
    offers,
    options,
    ...newWidget
  } = widget;
  const { options: optionsSetup } = setup?.options ? setup : { options: null };

  return {
    ...newWidget,
    availableEntries: undefined,
    ...(optionsSetup
      ? {
          options: {
            collapsedState: {
              ...options?.collapsedState,
              availableEntries: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              canBeDragged: undefined,
              options: {
                header: {
                  ...options?.collapsedState?.options?.header,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                  canBeDragged: undefined,
                  sameAsExpandedState:
                    optionsSetup.collapsedState?.options?.header
                      ?.sameAsExpandedState,
                  icon: optionsSetup.collapsedState?.options?.header?.icon,
                  headlineText: {
                    ...optionsSetup.collapsedState?.options?.header
                      ?.headlineText,
                    value: {
                      ...optionsSetup.collapsedState?.options?.header
                        ?.headlineText?.value,
                      style: {
                        ...optionsSetup.collapsedState?.options?.header
                          ?.headlineText?.value?.style,
                        style: {
                          ...optionsSetup.collapsedState?.options?.header
                            ?.headlineText?.value?.style?.style,
                          fontSize: {
                            ...optionsSetup.collapsedState?.options?.header
                              ?.headlineText?.value?.style?.style?.fontSize,
                            mobile: optionsSetup.collapsedState?.options?.header
                              ?.headlineText?.value?.style?.style
                              ?.differentOnMobile
                              ? optionsSetup.collapsedState?.options?.header
                                  ?.headlineText?.value?.style?.style?.fontSize
                                  ?.mobile
                              : undefined,
                          },
                          lineHeight: {
                            ...optionsSetup.collapsedState?.options?.header
                              ?.headlineText?.value?.style?.style?.lineHeight,
                            mobile: optionsSetup.collapsedState?.options?.header
                              ?.headlineText?.value?.style?.style
                              ?.differentOnMobile
                              ? optionsSetup.collapsedState?.options?.header
                                  ?.headlineText?.value?.style?.style
                                  ?.lineHeight?.mobile
                              : undefined,
                          },
                          fontWeight: {
                            ...optionsSetup.collapsedState?.options?.header
                              ?.headlineText?.value?.style?.style?.fontWeight,
                            mobile: optionsSetup.collapsedState?.options?.header
                              ?.headlineText?.value?.style?.style
                              ?.differentOnMobile
                              ? optionsSetup.collapsedState?.options?.header
                                  ?.headlineText?.value?.style?.style
                                  ?.fontWeight?.mobile
                              : undefined,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            expandedState: {
              ...options?.expandedState,
              availableEntries: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              canBeDragged: undefined,
              general: {
                ...options?.expandedState?.general,
                size: optionsSetup.expandedState?.general?.size,
                background: {
                  ...optionsSetup.expandedState?.general?.background,
                  border: {
                    ...optionsSetup.expandedState?.general?.background?.border,
                    value: {
                      ...optionsSetup.expandedState?.general?.background?.border
                        ?.value,
                      lineWeight: {
                        ...optionsSetup.expandedState?.general?.background
                          ?.border?.value?.lineWeight,
                        customValue:
                          optionsSetup.expandedState?.general?.background
                            ?.border?.value?.lineWeight?.type ===
                          LineWeightTypeDtoEnum.CUSTOM
                            ? optionsSetup.expandedState?.general?.background
                                ?.border?.value?.lineWeight?.customValue
                            : undefined,
                      },
                    },
                  },
                },
                cornerRadius: {
                  ...optionsSetup.expandedState?.general?.cornerRadius,
                  customValue:
                    optionsSetup.expandedState?.general?.cornerRadius?.type ===
                    LineWeightTypeDtoEnum.CUSTOM
                      ? optionsSetup.expandedState?.general.cornerRadius
                          .customValue
                      : undefined,
                },
                shadow: optionsSetup.expandedState?.general?.shadow,
              },
              options: {
                offer: undefined,
                footer: {
                  ...options?.expandedState?.options?.footer,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                  canBeDragged: undefined,
                  countdownClock: {
                    ...optionsSetup.expandedState?.options?.footer
                      ?.countdownClock,
                    textStyle: {
                      ...optionsSetup.expandedState?.options?.footer
                        ?.countdownClock?.textStyle,
                      style: {
                        ...optionsSetup.expandedState?.options?.footer
                          ?.countdownClock?.textStyle?.style,
                        fontSize: {
                          ...optionsSetup.expandedState?.options?.footer
                            ?.countdownClock?.textStyle?.style?.fontSize,
                          mobile: optionsSetup.expandedState?.options?.footer
                            ?.countdownClock?.textStyle?.style
                            ?.differentOnMobile
                            ? optionsSetup.expandedState?.options?.footer
                                ?.countdownClock?.textStyle?.style?.fontSize
                                ?.mobile
                            : undefined,
                        },
                        lineHeight: {
                          ...optionsSetup.expandedState?.options?.footer
                            ?.countdownClock?.textStyle?.style?.lineHeight,
                          mobile: optionsSetup.expandedState?.options?.footer
                            ?.countdownClock?.textStyle?.style
                            ?.differentOnMobile
                            ? optionsSetup.expandedState?.options?.footer
                                ?.countdownClock?.textStyle?.style?.lineHeight
                                ?.mobile
                            : undefined,
                        },
                        fontWeight: {
                          ...optionsSetup.expandedState?.options?.footer
                            ?.countdownClock?.textStyle?.style?.fontWeight,
                          mobile: optionsSetup.expandedState?.options?.footer
                            ?.countdownClock?.textStyle?.style
                            ?.differentOnMobile
                            ? optionsSetup.expandedState?.options?.footer
                                ?.countdownClock?.textStyle?.style?.fontWeight
                                ?.mobile
                            : undefined,
                        },
                      },
                    },
                  },
                  footerText: {
                    ...optionsSetup.expandedState?.options?.footer?.footerText,
                    value: {
                      ...optionsSetup.expandedState?.options?.footer?.footerText
                        ?.value,
                      style: {
                        ...optionsSetup.expandedState?.options?.footer
                          ?.footerText?.value?.style,
                        style: {
                          ...optionsSetup.expandedState?.options?.footer
                            ?.footerText?.value?.style?.style,
                          fontSize: {
                            ...optionsSetup.expandedState?.options?.footer
                              ?.footerText?.value?.style?.style?.fontSize,
                            mobile: optionsSetup.expandedState?.options?.footer
                              ?.footerText?.value?.style?.style
                              ?.differentOnMobile
                              ? optionsSetup.expandedState?.options?.footer
                                  ?.footerText?.value?.style?.style?.fontSize
                                  ?.mobile
                              : undefined,
                          },
                          lineHeight: {
                            ...optionsSetup.expandedState?.options?.footer
                              ?.footerText?.value?.style?.style?.lineHeight,
                            mobile: optionsSetup.expandedState?.options?.footer
                              ?.footerText?.value?.style?.style
                              ?.differentOnMobile
                              ? optionsSetup.expandedState?.options?.footer
                                  ?.footerText.value?.style?.style?.lineHeight
                                  ?.mobile
                              : undefined,
                          },
                          fontWeight: {
                            ...optionsSetup.expandedState?.options?.footer
                              ?.footerText?.value?.style?.style?.fontWeight,
                            mobile: optionsSetup.expandedState?.options?.footer
                              ?.footerText?.value?.style?.style
                              ?.differentOnMobile
                              ? optionsSetup.expandedState?.options?.footer
                                  ?.footerText?.value?.style?.style?.fontWeight
                                  ?.mobile
                              : undefined,
                          },
                        },
                      },
                    },
                  },
                },
                header: {
                  ...options?.expandedState?.options?.header,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                  canBeDragged: undefined,
                  headlineText: {
                    ...optionsSetup.expandedState?.options?.header
                      ?.headlineText,
                    value: {
                      ...optionsSetup.expandedState?.options?.header
                        ?.headlineText?.value,
                      style: {
                        ...optionsSetup.expandedState?.options?.header
                          ?.headlineText?.value?.style,
                        style: {
                          ...optionsSetup.expandedState?.options?.header
                            ?.headlineText?.value?.style?.style,
                          fontSize: {
                            ...optionsSetup.expandedState?.options?.header
                              ?.headlineText?.value?.style?.style?.fontSize,
                            mobile: optionsSetup.expandedState?.options?.header
                              ?.headlineText?.value?.style?.style
                              ?.differentOnMobile
                              ? optionsSetup.expandedState?.options?.header
                                  ?.headlineText?.value?.style?.style?.fontSize
                                  ?.mobile
                              : undefined,
                          },
                          lineHeight: {
                            ...optionsSetup.expandedState?.options?.header
                              ?.headlineText?.value?.style?.style?.lineHeight,
                            mobile: optionsSetup.expandedState?.options?.header
                              ?.headlineText?.value?.style?.style
                              ?.differentOnMobile
                              ? optionsSetup.expandedState?.options?.header
                                  ?.headlineText?.value?.style?.style
                                  ?.lineHeight?.mobile
                              : undefined,
                          },
                          fontWeight: {
                            ...optionsSetup.expandedState?.options?.header
                              ?.headlineText?.value?.style?.style?.fontWeight,
                            mobile: optionsSetup.expandedState?.options?.header
                              ?.headlineText?.value?.style?.style
                              ?.differentOnMobile
                              ? optionsSetup.expandedState?.options?.header
                                  ?.headlineText?.value?.style?.style
                                  ?.fontWeight?.mobile
                              : undefined,
                          },
                        },
                      },
                    },
                  },
                  shopSettings:
                    optionsSetup.expandedState?.options?.header?.shopSettings,
                },
                image: {
                  ...options?.expandedState?.options?.image,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                  canBeDragged: undefined,
                  border: {
                    ...optionsSetup.expandedState?.options?.image?.border,
                    value: {
                      ...optionsSetup.expandedState?.options?.image?.border
                        ?.value,
                      lineWeight: {
                        ...optionsSetup.expandedState?.options?.image?.border
                          ?.value?.lineWeight,
                        customValue:
                          optionsSetup.expandedState?.options?.image?.border
                            ?.value?.lineWeight?.type ===
                          LineWeightTypeDtoEnum.CUSTOM
                            ? optionsSetup.expandedState?.options?.image?.border
                                ?.value?.lineWeight?.customValue
                            : undefined,
                      },
                    },
                  },
                  cornerRadius: {
                    ...optionsSetup.expandedState?.options?.image?.cornerRadius,
                    customValue:
                      optionsSetup.expandedState?.options?.image?.cornerRadius
                        ?.type === LineWeightTypeDtoEnum.CUSTOM
                        ? optionsSetup.expandedState?.options?.image
                            ?.cornerRadius.customValue
                        : undefined,
                  },
                  image: {
                    ...optionsSetup.expandedState?.options?.image?.image,
                    imageId:
                      options?.expandedState?.options?.image?.image?.imageId,
                  },
                },
              },
              offers: offers?.map((offer) => {
                const {
                  canBeDragged,
                  canBeRemoved,
                  parentKey,
                  canBeHidden,
                  isHidden,
                  setup,
                  ...newOffer
                } = offer;

                return {
                  ...(canBeHidden && { isHidden }),
                  ...newOffer,
                  setup: {
                    ...setup,
                    availableEntries: undefined,
                    general: {
                      ...setup?.general,
                      icon: {
                        ...setup?.general?.icon,
                        value: {
                          ...setup?.general?.icon?.value,
                          color:
                            optionsSetup.expandedState?.options?.offer?.icon
                              ?.color,
                        },
                      },
                      text: {
                        ...setup?.general?.text,
                        style: {
                          ...optionsSetup.expandedState?.options?.offer
                            ?.textStyle,
                          style: {
                            ...optionsSetup.expandedState?.options?.offer
                              ?.textStyle?.style,
                            fontSize: {
                              ...optionsSetup.expandedState?.options?.offer
                                ?.textStyle?.style?.fontSize,
                              mobile: optionsSetup.expandedState?.options?.offer
                                ?.textStyle?.style?.differentOnMobile
                                ? optionsSetup.expandedState?.options?.offer
                                    ?.textStyle?.style?.fontSize?.mobile
                                : undefined,
                            },
                            lineHeight: {
                              ...optionsSetup.expandedState?.options?.offer
                                ?.textStyle?.style?.lineHeight,
                              mobile: optionsSetup.expandedState?.options?.offer
                                ?.textStyle?.style?.differentOnMobile
                                ? optionsSetup.expandedState?.options?.offer
                                    ?.textStyle?.style?.lineHeight?.mobile
                                : undefined,
                            },
                            fontWeight: {
                              ...optionsSetup.expandedState?.options?.offer
                                ?.textStyle?.style?.fontWeight,
                              mobile: optionsSetup.expandedState?.options?.offer
                                ?.textStyle?.style?.differentOnMobile
                                ? optionsSetup.expandedState?.options?.offer
                                    ?.textStyle?.style?.fontWeight?.mobile
                                : undefined,
                            },
                          },
                        },
                      },
                    },
                  },
                };
              }),
            },
          },
        }
      : {
          options: {
            collapsedState: {
              ...options?.collapsedState,
              availableEntries: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              canBeDragged: undefined,
              options: {
                header: {
                  ...options?.collapsedState?.options?.header,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                  canBeDragged: undefined,
                  sameAsExpandedState:
                    options?.collapsedState?.options?.header
                      ?.sameAsExpandedState,
                  icon: options?.collapsedState?.options?.header?.icon,
                  headlineText: {
                    ...options?.collapsedState?.options?.header?.headlineText,
                    value: {
                      ...options?.collapsedState?.options?.header?.headlineText
                        ?.value,
                      style: {
                        ...options?.collapsedState?.options?.header
                          ?.headlineText?.value?.style,
                        style: {
                          ...options?.collapsedState?.options?.header
                            ?.headlineText?.value?.style?.style,
                          fontSize: {
                            ...options?.collapsedState?.options?.header
                              ?.headlineText?.value?.style?.style?.fontSize,
                            mobile: options?.collapsedState?.options?.header
                              ?.headlineText?.value?.style?.style
                              ?.differentOnMobile
                              ? options?.collapsedState?.options?.header
                                  ?.headlineText?.value?.style?.style?.fontSize
                                  ?.mobile
                              : undefined,
                          },
                          lineHeight: {
                            ...options?.collapsedState?.options?.header
                              ?.headlineText?.value?.style?.style?.lineHeight,
                            mobile: options?.collapsedState?.options?.header
                              ?.headlineText?.value?.style?.style
                              ?.differentOnMobile
                              ? options?.collapsedState?.options?.header
                                  ?.headlineText?.value?.style?.style
                                  ?.lineHeight?.mobile
                              : undefined,
                          },
                          fontWeight: {
                            ...options?.collapsedState?.options?.header
                              ?.headlineText?.value?.style?.style?.fontWeight,
                            mobile: options?.collapsedState?.options?.header
                              ?.headlineText?.value?.style?.style
                              ?.differentOnMobile
                              ? options?.collapsedState?.options?.header
                                  ?.headlineText?.value?.style?.style
                                  ?.fontWeight?.mobile
                              : undefined,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            expandedState: {
              ...options?.expandedState,
              availableEntries: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              canBeDragged: undefined,
              general: {
                ...options?.expandedState?.general,
                background: {
                  ...options?.expandedState?.general?.background,
                  border: {
                    ...options?.expandedState?.general?.background?.border,
                    value: {
                      ...options?.expandedState?.general?.background?.border
                        ?.value,
                      lineWeight: {
                        ...options?.expandedState?.general?.background?.border
                          ?.value?.lineWeight,
                        customValue:
                          options?.expandedState?.general?.background?.border
                            ?.value?.lineWeight?.type ===
                          LineWeightTypeDtoEnum.CUSTOM
                            ? options?.expandedState?.general?.background
                                ?.border?.value?.lineWeight?.customValue
                            : undefined,
                      },
                    },
                  },
                },
                cornerRadius: {
                  ...options?.expandedState?.general?.cornerRadius,
                  customValue:
                    options?.expandedState?.general?.cornerRadius?.type ===
                    LineWeightTypeDtoEnum.CUSTOM
                      ? options?.expandedState?.general?.cornerRadius
                          .customValue
                      : undefined,
                },
              },
              options: {
                footer: {
                  ...options?.expandedState?.options?.footer,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                  canBeDragged: undefined,
                  countdownClock: {
                    ...options?.expandedState?.options?.footer?.countdownClock,
                    textStyle: {
                      ...options?.expandedState?.options?.footer?.countdownClock
                        ?.textStyle,
                      style: {
                        ...options?.expandedState?.options?.footer
                          ?.countdownClock?.textStyle?.style,
                        fontSize: {
                          ...options?.expandedState?.options?.footer
                            ?.countdownClock?.textStyle?.style?.fontSize,
                          mobile: options?.expandedState?.options?.footer
                            ?.countdownClock?.textStyle?.style
                            ?.differentOnMobile
                            ? options?.expandedState?.options?.footer
                                ?.countdownClock?.textStyle?.style?.fontSize
                                ?.mobile
                            : undefined,
                        },
                        lineHeight: {
                          ...options?.expandedState?.options?.footer
                            ?.countdownClock?.textStyle?.style?.lineHeight,
                          mobile: options?.expandedState?.options?.footer
                            ?.countdownClock?.textStyle?.style
                            ?.differentOnMobile
                            ? options?.expandedState?.options?.footer
                                ?.countdownClock?.textStyle?.style?.lineHeight
                                ?.mobile
                            : undefined,
                        },
                        fontWeight: {
                          ...options?.expandedState?.options?.footer
                            ?.countdownClock?.textStyle?.style?.fontWeight,
                          mobile: options?.expandedState?.options?.footer
                            ?.countdownClock?.textStyle?.style
                            ?.differentOnMobile
                            ? options?.expandedState?.options?.footer
                                ?.countdownClock?.textStyle?.style?.fontWeight
                                ?.mobile
                            : undefined,
                        },
                      },
                    },
                  },
                  footerText: {
                    ...options?.expandedState?.options?.footer?.footerText,
                    value: {
                      ...options?.expandedState?.options?.footer?.footerText
                        ?.value,
                      style: {
                        ...options?.expandedState?.options?.footer?.footerText
                          ?.value?.style,
                        style: {
                          ...options?.expandedState?.options?.footer?.footerText
                            ?.value?.style?.style,
                          fontSize: {
                            ...options?.expandedState?.options?.footer
                              ?.footerText?.value?.style?.style?.fontSize,
                            mobile: options?.expandedState?.options?.footer
                              ?.footerText?.value?.style?.style
                              ?.differentOnMobile
                              ? options?.expandedState?.options?.footer
                                  ?.footerText?.value?.style?.style?.fontSize
                                  ?.mobile
                              : undefined,
                          },
                          lineHeight: {
                            ...options?.expandedState?.options?.footer
                              ?.footerText?.value?.style?.style?.lineHeight,
                            mobile: options?.expandedState?.options?.footer
                              ?.footerText?.value?.style?.style
                              ?.differentOnMobile
                              ? options?.expandedState?.options?.footer
                                  ?.footerText.value?.style?.style?.lineHeight
                                  ?.mobile
                              : undefined,
                          },
                          fontWeight: {
                            ...options?.expandedState?.options?.footer
                              ?.footerText?.value?.style?.style?.fontWeight,
                            mobile: options?.expandedState?.options?.footer
                              ?.footerText?.value?.style?.style
                              ?.differentOnMobile
                              ? options?.expandedState?.options?.footer
                                  ?.footerText?.value?.style?.style?.fontWeight
                                  ?.mobile
                              : undefined,
                          },
                        },
                      },
                    },
                  },
                },
                header: {
                  ...options?.expandedState?.options?.header,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                  canBeDragged: undefined,
                  headlineText: {
                    ...options?.expandedState?.options?.header?.headlineText,
                    value: {
                      ...options?.expandedState?.options?.header?.headlineText
                        ?.value,
                      style: {
                        ...options?.expandedState?.options?.header?.headlineText
                          ?.value?.style,
                        style: {
                          ...options?.expandedState?.options?.header
                            ?.headlineText?.value?.style?.style,
                          fontSize: {
                            ...options?.expandedState?.options?.header
                              ?.headlineText?.value?.style?.style?.fontSize,
                            mobile: options?.expandedState?.options?.header
                              ?.headlineText?.value?.style?.style
                              ?.differentOnMobile
                              ? options?.expandedState?.options?.header
                                  ?.headlineText?.value?.style?.style?.fontSize
                                  ?.mobile
                              : undefined,
                          },
                          lineHeight: {
                            ...options?.expandedState?.options?.header
                              ?.headlineText?.value?.style?.style?.lineHeight,
                            mobile: options?.expandedState?.options?.header
                              ?.headlineText?.value?.style?.style
                              ?.differentOnMobile
                              ? options?.expandedState?.options?.header
                                  ?.headlineText?.value?.style?.style
                                  ?.lineHeight?.mobile
                              : undefined,
                          },
                          fontWeight: {
                            ...options?.expandedState?.options?.header
                              ?.headlineText?.value?.style?.style?.fontWeight,
                            mobile: options?.expandedState?.options?.header
                              ?.headlineText?.value?.style?.style
                              ?.differentOnMobile
                              ? options?.expandedState?.options?.header
                                  ?.headlineText?.value?.style?.style
                                  ?.fontWeight?.mobile
                              : undefined,
                          },
                        },
                      },
                    },
                  },
                },
                image: {
                  ...options?.expandedState?.options?.image,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                  canBeDragged: undefined,
                  border: {
                    ...options?.expandedState?.options?.image?.border,
                    value: {
                      ...options?.expandedState?.options?.image?.border?.value,
                      lineWeight: {
                        ...options?.expandedState?.options?.image?.border?.value
                          ?.lineWeight,
                        customValue:
                          options?.expandedState?.options?.image?.border?.value
                            ?.lineWeight?.type === LineWeightTypeDtoEnum.CUSTOM
                            ? options?.expandedState?.options?.image?.border
                                ?.value?.lineWeight?.customValue
                            : undefined,
                      },
                    },
                  },
                  cornerRadius: {
                    ...options?.expandedState?.options?.image?.cornerRadius,
                    customValue:
                      options?.expandedState?.options?.image?.cornerRadius
                        ?.type === LineWeightTypeDtoEnum.CUSTOM
                        ? options?.expandedState?.options?.image?.cornerRadius
                            .customValue
                        : undefined,
                  },
                },
                offer: {
                  ...options?.expandedState?.options?.offer,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                  canBeDragged: undefined,
                  textStyle: {
                    ...options?.expandedState?.options?.offer?.textStyle,
                    style: {
                      ...options?.expandedState?.options?.offer?.textStyle
                        ?.style,
                      fontSize: {
                        ...options?.expandedState?.options?.offer?.textStyle
                          ?.style?.fontSize,
                        mobile: options?.expandedState?.options?.offer
                          ?.textStyle?.style?.differentOnMobile
                          ? options?.expandedState?.options?.offer?.textStyle
                              ?.style?.fontSize?.mobile
                          : undefined,
                      },
                      lineHeight: {
                        ...options?.expandedState?.options?.offer?.textStyle
                          ?.style?.lineHeight,
                        mobile: options?.expandedState?.options?.offer
                          ?.textStyle?.style?.differentOnMobile
                          ? options?.expandedState?.options?.offer?.textStyle
                              ?.style?.lineHeight?.mobile
                          : undefined,
                      },
                      fontWeight: {
                        ...options?.expandedState?.options?.offer?.textStyle
                          ?.style?.fontWeight,
                        mobile: options?.expandedState?.options?.offer
                          ?.textStyle?.style?.differentOnMobile
                          ? options?.expandedState?.options?.offer?.textStyle
                              ?.style?.fontWeight?.mobile
                          : undefined,
                      },
                    },
                  },
                },
              },
            },
          },
        }),
  };
};
