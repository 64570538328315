import {
  CompleteOnboardingRequestDto,
  useGetSettingsV6OnboardingInitialDataQuery,
  useGetSettingsV6SystemCheckQuery,
  usePostSettingsV6OnboardingCompleteMutation,
} from 'core/api/adminSettings/adminSettingsApi';
import { useCallback } from 'react';
import resolveEnvVar from 'env-var-resolver';

export enum OnboardingFetchTypeEnum {
  SYSTEM_CHECK = 'SYSTEM_CHECK',
  ONBOARDING_PAGE = 'ONBOARDING_PAGE',
}

export const useConfigureOnboarding = (fetchType?: OnboardingFetchTypeEnum) => {
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';
  const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME');

  const { data: systemCheckData, isLoading: systemCheckIsLoading } =
    useGetSettingsV6SystemCheckQuery(
      {
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
      },
      { skip: fetchType !== OnboardingFetchTypeEnum.SYSTEM_CHECK }
    );

  const { data: onboardingInitialData, isLoading: onboardingInitialIsLoading } =
    useGetSettingsV6OnboardingInitialDataQuery(
      {
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
      },
      { skip: fetchType !== OnboardingFetchTypeEnum.ONBOARDING_PAGE }
    );

  const [
    completeOnboardingMutation,
    {
      isLoading: completeOnboardingIsLoading,
      error: completeOnboardingError,
      isSuccess: completeOnboardingSuccess,
    },
  ] = usePostSettingsV6OnboardingCompleteMutation();

  const completeOnboarding = useCallback(
    async (setup: CompleteOnboardingRequestDto) => {
      await completeOnboardingMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: setup,
      })
        .unwrap()
        .catch((err) => null);
    },
    [completeOnboardingMutation]
  );

  return {
    systemCheckData,
    systemCheckIsLoading,
    completeOnboarding,
    completeOnboardingSuccess,
    completeOnboardingIsLoading,
    completeOnboardingError,
    onboardingInitialData,
    onboardingInitialIsLoading,
  };
};
