import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useI18n } from '@shopify/react-i18n';
import {
  Banner,
  Button,
  Card,
  Icon,
  IconSource,
  InlineStack,
  Text,
  Bleed,
  Box,
  Badge,
  BlockStack,
  Pagination,
} from '@shopify/polaris';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CollectionIcon,
} from '@shopify/polaris-icons';
import { ResourcePickerAdapter } from 'core/components/ResourcePickerAdapter';

import './SearchFieldWithGrayBoxOfferWizard.scss';
import { useConfigureOffers } from 'features/promotions/hooks/useConfigureOffers';
import {
  ShopifyObjectDto,
  ShopifyObjectTypeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  OfferTargetTypeDtoEnum,
  ShopifyObjectTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { MergedSelectedGrayBoxElementsProps } from 'features/promotions/components/SelectedOfferTypeSettings/components/RulesSection/components/RuleComponent/RuleComponent';
import {
  Product,
  ProductVariant,
  Resource,
  ResourcePickerOptions,
  ResourcePickerResourceType,
  ResourceSelection,
} from 'core/api/appBridge';
import { useIsDebugOrLocal } from 'core/hooks';
import { ResourcePickerCustom } from '../ResourcePickerAdapter/components/ResourcePickerCustom';
import { headerTitle } from './utils/utils';
import { formateList } from 'core/utils';

export type ResourceSelectionProps = ResourceSelection<
  ResourcePickerOptions['type']
> & {
  featuredImageUrl: string;
  subtitle?: string;
};

export enum GrayBoxResourceTypeEnum {
  Product = 'Product',
  Collection = 'Collection',
}

export type GrayBoxResourceType = 'Product' | 'Collection';

type SelectSectionProps = {
  selection: ResourceSelectionProps[];
};

type SearchFieldWithGrayBoxProps = {
  selectList: ResourceSelectionProps[];
  mergedSelectedGrayBoxElements?: MergedSelectedGrayBoxElementsProps[];
  resourceType: GrayBoxResourceType;
  showVariants?: boolean;
  isFreeGiftSearchField?: boolean;
  isDiscountLinkSearchField?: boolean;
  onSelectedChange: (list: ResourceSelectionProps[]) => void;
  onFormValidityChange?(isFormInvalid: boolean | null): void;
  setShowAwarenessModal?: () => void;
  getOnMount?: (list: ResourceSelectionProps[]) => void;
  changeSuggestedItemOrder?: (startIndex: number, endIndex: number) => void;
  searchFieldType?: string;
  preventFromOpening?: boolean;
  isCrossSell?: boolean;
  productVariantId?: string;
  showLabel?: boolean;
  isLimitReached?: boolean;
  isFBTtype?: boolean;
  isOrderBumpType?: boolean;
  ignoreValidation?: boolean;
  uniqueLabel?: string;
  setConfiguredCollections?: (value: string) => void;
  hideComponent?: boolean;
};

export const SearchFieldWithGrayBox: React.FC<SearchFieldWithGrayBoxProps> = (
  props
) => {
  const {
    resourceType,
    showVariants,
    isFreeGiftSearchField,
    isDiscountLinkSearchField,
    selectList,
    onSelectedChange,
    onFormValidityChange,
    searchFieldType,
    setShowAwarenessModal,
    preventFromOpening,
    isCrossSell,
    productVariantId,
    isLimitReached,
    isFBTtype,
    getOnMount,
    mergedSelectedGrayBoxElements,
    isOrderBumpType,
    ignoreValidation,
    setConfiguredCollections,
    hideComponent = false,
  } = props;
  const { getLookupShopifyDetailedObjectsDetails } = useConfigureOffers();
  const isDebugOrLocal = useIsDebugOrLocal();
  const [i18n] = useI18n();
  const [showGrayBox, setShowGrayBox] = useState<boolean>(false);
  const [selectedGrayBoxElements, setSelectedGrayBoxElements] =
    useState<ResourceSelectionProps[]>(selectList);
  const [showMoreThanFiveTags, setShowMoreThanFiveTags] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const extraValidityCondition = useMemo(
    () => (isFBTtype ? isLimitReached : !selectedGrayBoxElements?.length),
    [isFBTtype, selectedGrayBoxElements, isLimitReached]
  );

  const formIsInvalid = useMemo(
    () => !!extraValidityCondition && !isLoading && !isOrderBumpType,
    [extraValidityCondition, isLoading, isOrderBumpType]
  );

  // const listForPreview = useMemo(
  //   () =>
  //     isFBTtype
  //       ? (mergedSelectedGrayBoxElements as ResourceSelectionProps[])
  //       : selectedGrayBoxElements,
  //   [isFBTtype, mergedSelectedGrayBoxElements, selectedGrayBoxElements]
  // );

  useEffect(() => {
    onFormValidityChange?.(formIsInvalid);
    return () => {
      // change in future
      onFormValidityChange?.(null);
    };
  }, [formIsInvalid, onFormValidityChange]);

  const toggleShowGrayBoxState = useCallback(
    (breakCondition?: boolean) => {
      if (!selectedGrayBoxElements?.length || breakCondition) {
        setShowAwarenessModal && setShowAwarenessModal();
        setShowGrayBox((prevState: boolean) => !prevState);
      }
    },
    [selectedGrayBoxElements, setShowAwarenessModal, setShowGrayBox]
  );

  const onSelection = useCallback(
    (data: SelectSectionProps) => {
      setShowGrayBox(false);
      onSelectedChange(data.selection);
      getLookupShopifyDetailedObjectsDetails(
        {
          type: currentResourceType as ShopifyObjectTypeDto,
          objects: formateList(
            data.selection,
            currentResourceType === ShopifyObjectTypeDtoEnum.PRODUCT_VARIANT
              ? OfferTargetTypeDtoEnum.VARIANTS
              : undefined
          ) as unknown as ShopifyObjectDto[],
        },
        setIsLoading
      ).then((res) => {
        setSelectedGrayBoxElements(res as ResourceSelectionProps[]);
      });
    },
    [onSelectedChange]
  );

  const removeSelectedTag = useCallback(
    (el: ResourceSelectionProps) => {
      const list = selectedGrayBoxElements?.filter(
        (element: ResourceSelectionProps) => element.id !== el.id
      );
      setSelectedGrayBoxElements(list);
      onSelectedChange(list);
    },
    [setSelectedGrayBoxElements, selectedGrayBoxElements, onSelectedChange]
  );

  const toggleTagsVisibilityState = useCallback(() => {
    setShowMoreThanFiveTags((prevState: boolean) => !prevState);
  }, [setShowMoreThanFiveTags]);

  const currentResourceType = useMemo(
    () =>
      isOrderBumpType
        ? resourceType === GrayBoxResourceTypeEnum.Product && !!productVariantId
          ? ShopifyObjectTypeDtoEnum.PRODUCT_VARIANT
          : resourceType.toUpperCase()
        : resourceType === GrayBoxResourceTypeEnum.Product && !!showVariants
        ? ShopifyObjectTypeDtoEnum.PRODUCT_VARIANT
        : resourceType.toUpperCase(),
    [
      resourceType,
      showVariants,
      productVariantId,
      isFreeGiftSearchField,
      isOrderBumpType,
    ]
  );

  useEffect(() => {
    if (selectList?.length) {
      setIsLoading(true);
      setShowGrayBox(false);
      getLookupShopifyDetailedObjectsDetails(
        {
          type: currentResourceType as ShopifyObjectTypeDto,
          objects: selectList as unknown as ShopifyObjectDto[],
        },
        setIsLoading
      ).then((res) => {
        getOnMount?.(res as ResourceSelectionProps[]);
        setSelectedGrayBoxElements(res as ResourceSelectionProps[]);
      });
    } else {
      setShowGrayBox(false);
    }

    return () => {
      setShowGrayBox(false);
      setSelectedGrayBoxElements([]);
      setShowMoreThanFiveTags(false);
      setIsLoading(false);
    };
  }, [
    currentResourceType,
    productVariantId,
    getLookupShopifyDetailedObjectsDetails,
    getOnMount,
  ]);

  const helpTextLabel: string = useMemo(() => {
    switch (resourceType) {
      case GrayBoxResourceTypeEnum.Collection:
        return isFreeGiftSearchField
          ? 'FreeGiftSearchFieldHelpText'
          : isDiscountLinkSearchField
          ? 'DiscountLinkSearchFieldHelpText'
          : 'SearchFieldHelpText';
      case GrayBoxResourceTypeEnum.Product:
        return isFreeGiftSearchField
          ? 'FreeGiftSearchFieldHelpText2'
          : isDiscountLinkSearchField
          ? 'DiscountLinkSearchFieldHelpText'
          : 'SearchFieldHelpText2';
      default:
        return 'SearchFieldHelpText';
    }
  }, [resourceType, isFreeGiftSearchField]);

  const warningTextLabel: string = useMemo(() => {
    switch (resourceType) {
      case GrayBoxResourceTypeEnum.Collection:
        return 'SearchFieldWarningText';
      case GrayBoxResourceTypeEnum.Product:
        if (showVariants && !isFreeGiftSearchField) {
          return 'SearchFieldWarningText3';
        } else {
          return 'SearchFieldWarningText2';
        }
      default:
        return 'SearchFieldWarningText';
    }
  }, [resourceType, isFreeGiftSearchField, showVariants]);

  const placeholderTextLabel: string = useMemo(
    () =>
      resourceType === GrayBoxResourceTypeEnum.Product &&
      showVariants &&
      !isOrderBumpType
        ? `SearchFieldPlaceholderProductVariants${searchFieldType || ''}`
        : resourceType === GrayBoxResourceTypeEnum.Product && !showVariants
        ? `SearchFieldPlaceholderProducts${searchFieldType || ''}`
        : resourceType === GrayBoxResourceTypeEnum.Product &&
          showVariants &&
          isOrderBumpType
        ? `SearchFieldPlaceholderProduct${searchFieldType || ''}`
        : `SearchFieldPlaceholder${searchFieldType || ''}`,
    [resourceType, showVariants, isOrderBumpType]
  );

  const selectedVariants: Resource[] = useMemo(
    () =>
      selectedGrayBoxElements?.map((el: any) => ({
        id: el.id,
        variants:
          (el as Product).variants?.map((variant: Partial<ProductVariant>) => ({
            id: variant.id || '',
          })) || [],
      })),
    [selectedGrayBoxElements]
  );

  const SearchFieldPlaceholder = useMemo(
    () =>
      !selectedGrayBoxElements?.length ? (
        <InlineStack gap='200' blockAlign='center' wrap={false}>
          <span>
            <Icon tone='base' source={CollectionIcon as IconSource} />
          </span>
          <Text as='p' tone='subdued'>
            {i18n.translate(placeholderTextLabel) || 'No collection selected'}
          </Text>
        </InlineStack>
      ) : (
        <InlineStack gap='200' blockAlign='center' wrap={false}>
          <span>
            <Icon source={CollectionIcon as IconSource} />
          </span>
          <Text as='p' fontWeight='regular'>
            {resourceType === 'Collection'
              ? i18n.translate('Collections')
              : i18n.translate('Products')}
            {`: `}
            <span
              dangerouslySetInnerHTML={{
                __html: headerTitle(
                  selectedGrayBoxElements,
                  currentResourceType,
                  i18n
                ),
              }}
            ></span>
          </Text>
        </InlineStack>
      ),
    [selectedGrayBoxElements, i18n, placeholderTextLabel, currentResourceType]
  );

  const [pageNumber, setPageNumber] = useState(1);

  const getResourceType = useCallback(
    (grayBoxType: GrayBoxResourceType): ResourcePickerResourceType => {
      switch (grayBoxType) {
        case GrayBoxResourceTypeEnum.Product:
          return 'product';
        case GrayBoxResourceTypeEnum.Collection:
          return 'collection';
        default:
          return 'variant';
      }
    },
    []
  );

  const reformatProducts = useCallback(
    (products: any) => {
      const reformattedArray: any = [];

      products?.map((product: any) => {
        product?.variants?.map((variant: any) => {
          reformattedArray.push({
            id: variant.id,
            title: product.title,
            imageUrl: product.imageUrl,
            featuredImageUrl: product.featuredImageUrl,
            variant: variant.title,
            salesChannels: product.salesChannels,
          });
        });
      });

      return reformattedArray;
    },

    []
  );

  const [reformatedProducts, setRefformatedProducts] = useState([]);

  useEffect(() => {
    setRefformatedProducts(reformatProducts(selectedGrayBoxElements));
  }, [selectedGrayBoxElements]);

  const searchFieldFilledWithTags = useMemo(
    () =>
      selectedGrayBoxElements?.length ? (
        <>
          <Box paddingBlockStart='400'>
            <Bleed marginInline='400' marginBlockEnd='400'>
              <Box
                paddingInline='400'
                background='bg-surface-secondary'
                paddingBlock='200'
                borderColor='border'
                borderBlockStartWidth='025'
              >
                {currentResourceType ===
                ShopifyObjectTypeDtoEnum.PRODUCT_VARIANT ? (
                  <InlineStack>
                    <Box width='40%'>
                      <Text as='p' tone='subdued'>
                        {i18n.translate(`${resourceType}`)}
                      </Text>
                    </Box>

                    <Box width='30%'>
                      <BlockStack inlineAlign='center'>
                        <Text as='p' tone='subdued'>
                          {i18n.translate(`Variant`)}
                        </Text>
                      </BlockStack>
                    </Box>

                    <Box width='15%'>
                      <BlockStack inlineAlign='center'>
                        <Text as='p' tone='subdued'>
                          {i18n.translate(`Status`)}
                        </Text>
                      </BlockStack>
                    </Box>
                    <Box width='15%'>
                      <BlockStack inlineAlign='center'>
                        <Text as='p' tone='subdued'>
                          {i18n.translate(`SalesChannel`)}
                        </Text>
                      </BlockStack>
                    </Box>
                  </InlineStack>
                ) : (
                  <InlineStack>
                    <Box width='50%'>
                      <Text as='p' tone='subdued'>
                        {i18n.translate(`${resourceType}`)}
                      </Text>
                    </Box>

                    <Box width='25%'>
                      <BlockStack inlineAlign='center'>
                        <Text as='p' tone='subdued'>
                          {i18n.translate(`Status`)}
                        </Text>
                      </BlockStack>
                    </Box>
                    <Box width='25%'>
                      <BlockStack inlineAlign='center'>
                        <Text as='p' tone='subdued'>
                          {i18n.translate(`SalesChannel`)}
                        </Text>
                      </BlockStack>
                    </Box>
                  </InlineStack>
                )}
              </Box>
              {currentResourceType ===
              ShopifyObjectTypeDtoEnum.PRODUCT_VARIANT ? (
                <>
                  {reformatedProducts?.map((el: any, index: number) => {
                    if (index >= pageNumber * 5 - 5 && index < pageNumber * 5) {
                      return (
                        <Box
                          key={el.id}
                          paddingInline='400'
                          paddingBlock='200'
                          borderColor='border'
                          borderBlockStartWidth='025'
                        >
                          <InlineStack blockAlign='center'>
                            <Box width='40%'>
                              <InlineStack gap='200' blockAlign='center'>
                                {el.imageUrl || el.featuredImageUrl ? (
                                  <img
                                    src={el.imageUrl || el.featuredImageUrl}
                                    alt={el.title}
                                    width={'40px'}
                                    height={'40px'}
                                    style={{ borderRadius: '8px' }}
                                  />
                                ) : (
                                  <Box
                                    background='bg-surface-secondary'
                                    padding='500'
                                    borderRadius='100'
                                  ></Box>
                                )}
                                <BlockStack>
                                  <Text as='p' fontWeight='semibold'>
                                    {el.title}
                                  </Text>
                                </BlockStack>
                              </InlineStack>
                            </Box>

                            <Box width='30%'>
                              <BlockStack inlineAlign='center'>
                                <Text as='p' tone='subdued'>
                                  {el.variant}
                                </Text>
                              </BlockStack>
                            </Box>
                            <Box width='15%'>
                              <BlockStack inlineAlign='center'>
                                <Badge tone='success'>
                                  {i18n.translate(`Active`)}
                                </Badge>
                              </BlockStack>
                            </Box>

                            <Box width='15%'>
                              <BlockStack inlineAlign='center'>
                                {el?.salesChannels?.map((channel: string) => (
                                  <Text key={channel} as='p' tone='subdued'>
                                    {channel}
                                  </Text>
                                ))}
                              </BlockStack>
                            </Box>
                          </InlineStack>
                        </Box>
                      );
                    } else {
                      return null;
                    }
                  })}

                  {reformatedProducts?.length > 5 && (
                    <Box
                      paddingInline='400'
                      background='bg-surface-secondary'
                      paddingBlock='200'
                      borderColor='border'
                      borderBlockStartWidth='025'
                    >
                      <InlineStack align='center'>
                        <Pagination
                          hasPrevious={pageNumber >= 2}
                          onPrevious={() => {
                            setPageNumber((prev) => prev - 1);
                          }}
                          hasNext={reformatedProducts.length / 5 > pageNumber}
                          onNext={() => {
                            setPageNumber((prev) => prev + 1);
                          }}
                        />
                      </InlineStack>
                    </Box>
                  )}
                </>
              ) : (
                <>
                  {selectedGrayBoxElements.map((el: any, index: number) => {
                    if (index >= pageNumber * 5 - 5 && index < pageNumber * 5) {
                      return (
                        <Box
                          key={el.id}
                          paddingInline='400'
                          paddingBlock='200'
                          borderColor='border'
                          borderBlockStartWidth='025'
                        >
                          <InlineStack blockAlign='center'>
                            <Box width='50%'>
                              <InlineStack gap='200' blockAlign='center'>
                                {el.imageUrl || el.featuredImageUrl ? (
                                  <img
                                    src={el.imageUrl || el.featuredImageUrl}
                                    alt={el.title}
                                    width={'40px'}
                                    height={'40px'}
                                    style={{ borderRadius: '8px' }}
                                  />
                                ) : (
                                  <Box
                                    background='bg-surface-secondary'
                                    padding='500'
                                    borderRadius='100'
                                  ></Box>
                                )}
                                <BlockStack>
                                  <Text as='p' fontWeight='semibold'>
                                    {el.title}
                                  </Text>
                                </BlockStack>
                              </InlineStack>
                            </Box>

                            <Box width='25%'>
                              <BlockStack inlineAlign='center'>
                                <Badge tone='success'>
                                  {i18n.translate(`Active`)}
                                </Badge>
                              </BlockStack>
                            </Box>

                            <Box width='25%'>
                              <BlockStack inlineAlign='center'>
                                {el?.salesChannels?.map((channel: string) => (
                                  <Text key={channel} as='p' tone='subdued'>
                                    {channel}
                                  </Text>
                                ))}
                              </BlockStack>
                            </Box>
                          </InlineStack>
                        </Box>
                      );
                    } else {
                      return null;
                    }
                  })}

                  {selectedGrayBoxElements?.length > 5 && (
                    <Box
                      paddingInline='400'
                      background='bg-surface-secondary'
                      paddingBlock='200'
                      borderColor='border'
                      borderBlockStartWidth='025'
                    >
                      <InlineStack align='center'>
                        <Pagination
                          hasPrevious={pageNumber >= 2}
                          onPrevious={() => {
                            setPageNumber((prev) => prev - 1);
                          }}
                          hasNext={
                            selectedGrayBoxElements.length / 5 > pageNumber
                          }
                          onNext={() => {
                            setPageNumber((prev) => prev + 1);
                          }}
                        />
                      </InlineStack>
                    </Box>
                  )}
                </>
              )}
            </Bleed>
          </Box>
        </>
      ) : null,
    [
      selectedGrayBoxElements,
      showMoreThanFiveTags,
      removeSelectedTag,
      toggleShowGrayBoxState,
      toggleTagsVisibilityState,
      i18n,
      pageNumber,
      selectedGrayBoxElements.length,
      reformatedProducts,
    ]
  );

  useEffect(() => {
    if (setConfiguredCollections) {
      setConfiguredCollections(
        headerTitle(selectedGrayBoxElements, currentResourceType, i18n)
      );
    }
  }, [
    selectedGrayBoxElements,
    selectedGrayBoxElements.length,
    showGrayBox,
    i18n,
  ]);

  const [showList, setShowList] = useState(false);
  const toggleShowList = useCallback(() => setShowList(!showList), [showList]);

  useEffect(() => {
    if (hideComponent && setConfiguredCollections) {
      setConfiguredCollections(
        headerTitle(selectedGrayBoxElements, currentResourceType, i18n)
      );
    }
  }, [hideComponent, selectedGrayBoxElements, currentResourceType]);

  return (
    <>
      {!hideComponent && (
        <>
          <div>
            <Card>
              <InlineStack align='space-between' wrap={false} gap={'200'}>
                <InlineStack gap='200'>{SearchFieldPlaceholder}</InlineStack>
                <InlineStack gap='200' wrap={false}>
                  <Button
                    variant='plain'
                    onClick={
                      selectedGrayBoxElements.length === 0
                        ? () => toggleShowGrayBoxState()
                        : () => toggleShowGrayBoxState(true)
                    }
                  >
                    {selectedGrayBoxElements.length === 0 ? 'Select' : 'Edit'}
                  </Button>
                  {selectedGrayBoxElements.length > 0 && (
                    <Button
                      icon={!showList ? ChevronDownIcon : ChevronUpIcon}
                      onClick={() => toggleShowList()}
                      variant='tertiary'
                    />
                  )}
                </InlineStack>
              </InlineStack>
              {showList && searchFieldFilledWithTags}
            </Card>

            {!selectedGrayBoxElements.length &&
              warningTextLabel &&
              !ignoreValidation && (
                <Box paddingBlockStart='400'>
                  <Banner tone='critical'>
                    {i18n.translate(warningTextLabel)}
                  </Banner>{' '}
                </Box>
              )}
            {isCrossSell && !formIsInvalid && (
              <Box paddingBlockStart='400'>
                <Banner>{i18n.translate(helpTextLabel)}</Banner>{' '}
              </Box>
            )}
          </div>
          {showGrayBox && !preventFromOpening && !isDebugOrLocal && (
            <ResourcePickerAdapter
              type={getResourceType(resourceType)}
              onCancel={() => setShowGrayBox(false)}
              onSelection={onSelection}
              selectionIds={selectedVariants}
              showVariants={!!(showVariants && !isOrderBumpType)}
              multiple={
                (resourceType === GrayBoxResourceTypeEnum.Product &&
                  (isFreeGiftSearchField || isOrderBumpType)) ||
                ((resourceType === GrayBoxResourceTypeEnum.Collection ||
                  resourceType === GrayBoxResourceTypeEnum.Product) &&
                  isDiscountLinkSearchField)
                  ? false
                  : true
              }
            />
          )}
          {showGrayBox && !preventFromOpening && isDebugOrLocal && (
            <ResourcePickerCustom
              open={showGrayBox && !preventFromOpening}
              type={getResourceType(resourceType)}
              onCancel={() => setShowGrayBox(false)}
              onSelection={onSelection}
              selectionIds={selectedVariants}
              showVariants={!!(showVariants && !isOrderBumpType)}
              multiple={
                (resourceType === GrayBoxResourceTypeEnum.Product &&
                  (isFreeGiftSearchField || isOrderBumpType)) ||
                ((resourceType === GrayBoxResourceTypeEnum.Collection ||
                  resourceType === GrayBoxResourceTypeEnum.Product) &&
                  isDiscountLinkSearchField)
                  ? false
                  : true
              }
            />
          )}
        </>
      )}
    </>
  );
};
