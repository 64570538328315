import React, { useCallback, useMemo } from 'react';
import {
  NinjaCartDefaultStateFooterLevelPresetDtoRead,
  NinjaCartDefaultStateFooterPresetDto,
  NinjaCartDefaultStateFooterPresetDtoRead,
  PromotionSummarySystemDefaultsDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import {
  WidgetListOptionsDtoEnum,
  WidgetListTypeDtoEnum,
} from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { SelectedOptionPath } from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightSideBarWrapper';
import { getLastSelectedOption } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import NinjaCartFooterGeneral from './components/NinjaCartFooterGeneral/NinjaCartFooterGeneral';
import PresetPromotionSummary from '../../../../../PresetPromotionSummary/PresetPromotionSummary';
import NinjaCartFooterCTA from './components/NinjaCartFooterCTA/NinjaCartFooterCTA';

type NinjaCartDefaultFooterProps = {
  footer: NinjaCartDefaultStateFooterPresetDtoRead;
  defaultFooter: NinjaCartDefaultStateFooterPresetDtoRead;
  shopDefaultFooter: NinjaCartDefaultStateFooterPresetDto;
  selectedOptionPath: SelectedOptionPath;
  defaultLanguage: string;
  setFooter: (data: NinjaCartDefaultStateFooterPresetDtoRead) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const NinjaCartDefaultFooter: React.FC<NinjaCartDefaultFooterProps> = ({
  footer,
  defaultFooter,
  shopDefaultFooter,
  selectedOptionPath,
  defaultLanguage,
  setFooter,
  handleLeftMenuSelection,
}) => {
  const handleUpdateOptions = useCallback(
    (
      field: keyof NinjaCartDefaultStateFooterLevelPresetDtoRead,
      data: NinjaCartDefaultStateFooterLevelPresetDtoRead[keyof NinjaCartDefaultStateFooterLevelPresetDtoRead]
    ) => {
      setFooter({
        ...footer,
        options: {
          ...footer.options,
          [field]: data,
        },
      });
    },
    [footer, setFooter]
  );

  const promotionSummaryShopDefault: PromotionSummarySystemDefaultsDto =
    useMemo(
      () =>
        ({
          ...shopDefaultFooter.options?.promotionSummary?.options,
          general: shopDefaultFooter.options?.promotionSummary?.general,
        } as PromotionSummarySystemDefaultsDto),
      [shopDefaultFooter.options?.promotionSummary]
    );

  const footerOptions = useMemo(() => {
    const selectedOption = getLastSelectedOption(selectedOptionPath);
    const selectedDefaultOption =
      selectedOptionPath?.selectedOption?.selectedOption?.selectedOption;
    switch (true) {
      case selectedOption?.name === WidgetListOptionsDtoEnum.FOOTER:
        return (
          <NinjaCartFooterGeneral
            general={footer.general || {}}
            defaultGeneral={defaultFooter.general || {}}
            shopDefaultGeneral={shopDefaultFooter.general || {}}
            setGeneral={(data) => setFooter({ ...footer, general: data })}
            handleLeftMenuSelection={handleLeftMenuSelection}
            onShowHide={
              footer.canBeHidden
                ? () => setFooter({ ...footer, isHidden: !footer.isHidden })
                : undefined
            }
          />
        );
      case selectedDefaultOption?.name ===
        WidgetListTypeDtoEnum.NINJA_CART_PROMOTION_SUMMARY:
        return (
          <PresetPromotionSummary
            promotionSummary={footer.options?.promotionSummary || {}}
            selectedOptionPath={selectedOptionPath}
            defaultPresetData={defaultFooter.options?.promotionSummary || {}}
            shopDefaultData={promotionSummaryShopDefault}
            defaultLanguage={defaultLanguage}
            pageType={WidgetListTypeDtoEnum.NINJA_CART_PROMOTION_SUMMARY}
            updatePromotionSummary={(data) =>
              handleUpdateOptions('promotionSummary', data)
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedDefaultOption?.name === WidgetListOptionsDtoEnum.CTA_BUTTONS:
        return (
          <NinjaCartFooterCTA
            ctaButtons={footer.options?.ctaButtons || {}}
            selectedOptionPath={selectedOptionPath}
            defaultLanguage={defaultLanguage}
            defaultCTAButtons={defaultFooter.options?.ctaButtons || {}}
            shopDefaultCTAButtons={shopDefaultFooter.options?.ctaButtons || {}}
            setCTAButtons={(data) => handleUpdateOptions('ctaButtons', data)}
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
    }
  }, [
    selectedOptionPath,
    defaultFooter,
    defaultLanguage,
    shopDefaultFooter,
    footer,
    handleLeftMenuSelection,
  ]);

  return <>{footerOptions}</>;
};

export default NinjaCartDefaultFooter;
