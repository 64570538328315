import React from 'react';
import {
  Card,
  BlockStack,
  Text,
  Banner,
  Link,
  Checkbox,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { MicrodataIntegrationsSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
type MicroData = {
  data: MicrodataIntegrationsSettingsDto | undefined;
  section: string;
  handleIntegrationsLocaleState: (
    section: string,
    value: string | boolean,
    field?: string
  ) => void;
};
export const MicroData: React.FC<MicroData> = ({
  data,
  section,
  handleIntegrationsLocaleState,
}) => {
  const [i18n] = useI18n();

  return (
    <Card roundedAbove='sm' padding='400'>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate('MicroDataTitle')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('MicroDataDescription')}
          </Text>
        </BlockStack>
        <Banner tone='info'>
          {i18n.translate('Tip')}{' '}
          <Link url='http://www.google.com' target='_blank'>
            {i18n.translate('TipLink')}
          </Link>
        </Banner>
        <Checkbox
          label={i18n.translate('Allow')}
          helpText={i18n.translate('AllowHelpText')}
          checked={data?.allowMicrodataAttributesUpdate}
          onChange={(value) =>
            handleIntegrationsLocaleState(
              section,
              value,
              'allowMicrodataAttributesUpdate'
            )
          }
        />
      </BlockStack>
    </Card>
  );
};
