import { NumberFormatValues } from 'react-number-format';
import {
    OfferLevelDto,
    OfferSalesDiscountSpecificationDto,
    OfferSalesDiscountTypeDto,
    OfferTieredDiscountTypeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
    DiscountValueTypeEnum,
    DiscountValueTypeDTOEnumUX,
    OfferTieredDiscountTypeDtoEnumUX,
} from '../../../enums/DiscountValueType';
import { I18n } from '@shopify/react-i18n';
import { DiscountType, DiscountTypeDTOEnum } from '../../../enums/DiscountType';

export function getInitialActiveDiscountValueType(
    specificationType: OfferSalesDiscountTypeDto | undefined,
    type: OfferTieredDiscountTypeDto | undefined
): DiscountValueTypeEnum {
    if (specificationType) {
        return DiscountValueTypeDTOEnumUX[
            specificationType
        ] as unknown as DiscountValueTypeEnum;
    } else if (type) {
        return OfferTieredDiscountTypeDtoEnumUX[
            type
        ] as unknown as DiscountValueTypeEnum;
    } else {
        return DiscountValueTypeEnum.Percentage;
    }
}

export function getInitialTextfieldValue(
    i18n: I18n,
    specification: OfferSalesDiscountSpecificationDto | undefined,
    type: OfferTieredDiscountTypeDto | undefined,
    value: number
): NumberFormatValues {
    return {
        floatValue: value || 10,
        formattedValue: `${i18n.translate('Discount.currencySign')}${value || 10}`,
        value: value?.toString() || '10',
    };
}

export function getInitialSelectedDiscountType(
    level: OfferLevelDto | undefined
): DiscountType {
    if (level) {
        return DiscountTypeDTOEnum[level] as unknown as DiscountType;
    } else {
        return DiscountType.ProductDiscount;
    }
}
