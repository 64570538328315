import React, { useCallback, useMemo, useState } from 'react';
import {
  ActionList,
  ActionListItemDescriptor,
  BlockStack,
  Button,
  Icon,
  Popover,
  Text,
  Tooltip,
} from '@shopify/polaris';
import './StyleComponentHeader.scss';
import { MenuHorizontalIcon, InfoIcon } from '@shopify/polaris-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useI18n } from '@shopify/react-i18n';
import { PaletteIcon } from 'core/components/WidgetEditor/assets/icons';
import classNames from 'classnames';

type StyleComponentHeaderProps = {
  label: string;
  buttons?: ActionListItemDescriptor[];
  tip?: string;
  presetId?: string;
  isShopSetting?: boolean;
  inherited?: boolean;
  children?: React.ReactNode;
  removeTopPadding?: boolean;
};
const StyleComponentHeader: React.FC<StyleComponentHeaderProps> = ({
  label,
  buttons,
  tip,
  presetId,
  isShopSetting,
  inherited,
  children,
  removeTopPadding,
}) => {
  const [mainPopover, setMainPopover] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [i18n] = useI18n();

  const navigateToPreset = useCallback(() => {
    navigate(`${location.pathname}/${presetId}`);
  }, [navigate, location, presetId]);

  const acitonsVariant = useMemo(() => {
    if (presetId) {
      return [
        {
          external: true,
          content: i18n.translate(`GoToPreset`),
          onAction: () => navigateToPreset(),
        },
      ];
    } else if (isShopSetting) {
      return [
        {
          external: true,
          content: i18n.translate(`GoToShop`),
          onAction: () => null,
        },
      ];
    } else {
      return buttons?.map((button) => ({
        ...button,
        onAction: () => {
          button?.onAction?.();
          setMainPopover(false);
        },
      }));
    }
  }, [i18n, navigateToPreset, buttons, setMainPopover]);

  return (
    <div
      className={classNames('StyleComponentWrapper', {
        RemoveTopPadding: removeTopPadding,
      })}
    >
      <div className='StyleComponentHeader'>
        <Text as='p' fontWeight='medium'>
          {label}
        </Text>
        <div className='TipAndMoreActions'>
          {tip && (
            <Tooltip preferredPosition='mostSpace' content={tip}>
              <Icon tone='base' source={InfoIcon} />
            </Tooltip>
          )}
          {inherited !== undefined && (
            <Tooltip
              preferredPosition='mostSpace'
              content={i18n.translate('PartOfPreset')}
            >
              <div style={{ height: 16 }}>
                <PaletteIcon size={16} crossed={!inherited} />
              </div>
            </Tooltip>
          )}
          {buttons && (
            <div className='MenuButton'>
              <Popover
                active={mainPopover}
                activator={
                  <Button
                    variant='tertiary'
                    icon={MenuHorizontalIcon}
                    onClick={() => setMainPopover(true)}
                  />
                }
                autofocusTarget='first-node'
                onClose={() => setMainPopover(false)}
              >
                <ActionList actionRole='menuitem' items={acitonsVariant} />
              </Popover>
            </div>
          )}
        </div>
      </div>
      {children && <BlockStack gap='300'>{children}</BlockStack>}
    </div>
  );
};

export default StyleComponentHeader;
