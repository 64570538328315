import React from 'react';
import {
  BlockStack,
  Card,
  Layout,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
} from '@shopify/polaris';

export const PromotionOverviewSkeleton = () => {
  return (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <BlockStack gap='400'>
            <Card>
              <BlockStack gap='400'>
                <SkeletonDisplayText size='small' />
                <SkeletonBodyText lines={6} />
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap='400'>
                <SkeletonDisplayText size='small' />
                <SkeletonBodyText lines={4} />
                <SkeletonBodyText lines={4} />
              </BlockStack>
            </Card>
            <SkeletonBodyText lines={1} />
          </BlockStack>
        </Layout.Section>
        <Layout.Section variant='oneThird'>
          <BlockStack gap='400'>
            <Card>
              <BlockStack gap='400'>
                <SkeletonDisplayText size='small' />
                <SkeletonBodyText lines={4} />
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap='400'>
                <SkeletonDisplayText size='small' />
                <SkeletonBodyText lines={3} />
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap='400'>
                <SkeletonDisplayText size='small' />
                <SkeletonBodyText lines={2} />
                <SkeletonBodyText lines={2} />
                <SkeletonBodyText lines={2} />
                <SkeletonBodyText lines={2} />
                <SkeletonBodyText lines={2} />
              </BlockStack>
            </Card>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
};
