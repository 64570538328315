import React, { useCallback, useMemo, useRef } from 'react';
import './MockupPreviewTemplate.scss';
import Book from '../../assets/Book.svg';
import ArrowRight from '../../assets/ArrowRight.svg';
import ArrowLeft from '../../assets/ArrowLeft.svg';
import Share from '../../assets/Share.svg';
import Tabs from '../../assets/Tabs.svg';
import Plus from '../../assets/Plus.svg';
import Wifi from '../../assets/Wifi.svg';
import Battery from '../../assets/Battery.svg';
import Cellular from '../../assets/Cellular.svg';
import Time from '../../assets/Time.svg';
import Bars from '../../assets/Hamburger.svg';
import ShoppingCart from '../../assets/Cart.svg';
import Search from '../../assets/Search.svg';
import EmptyImage from '../../assets/EmptyImage.svg';
import classNames from 'classnames';
import { BlockType } from '../BlockType/BlockType';
import { Icon, Text, SkeletonBodyText } from '@shopify/polaris';
import { LockFilledIcon } from '@shopify/polaris-icons';
import {
  DeviceTypeDtoEnum,
  PageTypeDtoEnum,
  WidgetTypeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import { productBannerMergeFunc } from './utils/productBannerMergeFunc';
import { promotionalBadgeMergeFunc } from './utils/promotionalBadgeMergeFunc';
import { notificationMergeFunc } from './utils/notificationMergeFunc';
import { announcementBarMergeFunc } from './utils/announcementBarMergeFunc';
import { promotionCodeFieldMergeFunc } from './utils/promotionCodeFieldMergeFunc';
import { promotionSummaryMergeFunc } from './utils/promotionSummaryMergeFunc';
import { offerRulePopUpMergeFunc } from './utils/offerRulePopUpMergeFunc';
import { getGlobalCurrentEntries } from 'features/settings/components/EditPresetSkeleton/utils/leftMenuGenerators';
import { useI18n } from '@shopify/react-i18n';
import { WidgetListTypeDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { priceMergeFunc } from './utils/priceMergeFunc';
import { cartTextMergeFunc } from './utils/cartTextMergeFunc';
import { discountLabelMergeFunc } from './utils/discountLabelMergeFunc';
import { ninjaCartMergeFunc } from './utils/ninjaCartMergeFunc';

type MockupPreviewTemplateProps = {
  page: PageTypeDtoEnum;
  data: any;
  previewType: DeviceTypeDtoEnum;
  defaultLanguage?: string;
  adminActivePath: string;
  getSelectionFromThePreview(path: string): void;
  handleSwitchWidgetState(widgetName: string, entries: any[]): void;
  priceWidgetType?: string;
};

export const MockupPreviewTemplate: React.FC<MockupPreviewTemplateProps> = (
  props
) => {
  const {
    previewType,
    data,
    defaultLanguage,
    adminActivePath,
    getSelectionFromThePreview,
    handleSwitchWidgetState,
    page,
    priceWidgetType,
  } = props;

  const headerRef = useRef<HTMLDivElement | null>(null);

  const itemsArray = useCallback(
    (number: number) => Array.from({ length: number }, (_, index) => index + 1),
    []
  );

  const supportedWidgets = useMemo(
    () => getGlobalCurrentEntries(data?.pages),
    [data?.pages]
  );

  const [i18n] = useI18n();

  return priceWidgetType ? (
    <div className='mockupPreviewPriceWidgetTemplate'>
      <BlockType
        previewType={previewType}
        blockType={WidgetListTypeDtoEnum.PRODUCT_PAGE_PRICE}
        data={priceMergeFunc(
          data[data.currentWidgetName],
          data.presetSetup?.[data.currentWidgetName]
        )}
        defaultLanguage={defaultLanguage}
        adminActivePath={adminActivePath}
        getSelectionFromThePreview={getSelectionFromThePreview}
        specialRoot={data.currentWidgetName}
        priceWidgetType={priceWidgetType}
        isPreset
      />
    </div>
  ) : previewType === DeviceTypeDtoEnum.MOBILE ? (
    <div className='mockupPreviewTemplateMobile' id='previewAssetMobile'>
      <div className='browserMenu'>
        <div className='deviceMenu'>
          <img
            style={{ marginRight: 'auto', height: 10.5 }}
            src={Time}
            alt='Time'
          />
          <img style={{ marginRight: 5 }} src={Cellular} alt='Cellular' />
          <img style={{ marginRight: 5 }} src={Wifi} alt='Wifi' />
          <img src={Battery} alt='Battery' />
        </div>
        <div className='urlBlock'>
          <Icon source={LockFilledIcon} tone='subdued' />
          yourshop.com
        </div>
      </div>
      <div
        className='previewBody'
        style={{
          height: `calc(100% - ${
            (headerRef.current?.offsetHeight || 0) + 14
          }px)`,
        }}
      >
        {[PageTypeDtoEnum.CHECKOUT].includes(page) && (
          <div className='mainContent' style={{ height: '100%' }}>
            <div className='columns'>
              <div className='column'>
                <div className='spaceBetweenLineSecondColumn checkout'>
                  <SkeletonBodyText lines={1} />
                  <SkeletonBodyText lines={1} />
                </div>
                <div
                  style={{ display: 'flex', gap: 12, paddingBottom: 24 }}
                  className='section'
                >
                  <div
                    style={{
                      width: 84,
                      height: 65,
                    }}
                    className='imageWrapper'
                  >
                    <img src={EmptyImage} alt='EmptyImage' />
                  </div>
                  <div style={{ width: '100%' }}>
                    <div className='spaceBetweenLine'>
                      <SkeletonBodyText lines={1} />
                      <SkeletonBodyText lines={1} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ width: '20%' }}>
                        <SkeletonBodyText lines={1} />
                      </div>
                      {supportedWidgets?.includes(
                        WidgetTypeDtoEnum.DISCOUNT_LABEL
                      ) && (
                        <BlockType
                          previewType={DeviceTypeDtoEnum.MOBILE}
                          blockType={WidgetListTypeDtoEnum.DISCOUNT_LABEL}
                          data={discountLabelMergeFunc(data.discountLabel)}
                          defaultLanguage={defaultLanguage}
                          adminActivePath={adminActivePath}
                          getSelectionFromThePreview={
                            getSelectionFromThePreview
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='column checkout'>
                <div className='block'>
                  <Text variant='headingSm' as='h6'>
                    {i18n.translate('Contact')}
                  </Text>
                  <div
                    style={{
                      width: '100%',
                      height: 40,
                      borderRadius: 'var(--p-border-radius-300)',
                      background: 'var(--p-color-bg-fill-tertiary)',
                    }}
                  />
                  <div style={{ display: 'flex', gap: 20 }}>
                    {itemsArray(2).map((item, idx) => (
                      <div
                        key={idx}
                        style={{
                          width: '50%',
                          height: 40,
                          borderRadius: 'var(--p-border-radius-300)',
                          background: 'var(--p-color-bg-fill-tertiary)',
                        }}
                      />
                    ))}
                  </div>
                </div>
                <div className='block'>
                  <Text variant='headingSm' as='h6'>
                    {i18n.translate('Delivery')}
                  </Text>
                  <div style={{ display: 'flex', gap: 20 }}>
                    {itemsArray(2).map((item, idx) => (
                      <div
                        key={idx}
                        style={{
                          width: '50%',
                          height: 40,
                          borderRadius: 'var(--p-border-radius-300)',
                          background: 'var(--p-color-bg-fill-tertiary)',
                        }}
                      />
                    ))}
                  </div>
                  <div
                    style={{
                      width: '100%',
                      height: 40,
                      borderRadius: 'var(--p-border-radius-300)',
                      background: 'var(--p-color-bg-fill-tertiary)',
                    }}
                  />
                  <div className='blockLine'>
                    <SkeletonBodyText lines={1} />
                    <SkeletonBodyText lines={1} />
                  </div>
                </div>
                <div className='block'>
                  <Text variant='headingSm' as='h6'>
                    {i18n.translate('Payment')}
                  </Text>
                  <div style={{ display: 'flex', gap: 20 }}>
                    {itemsArray(2).map((item, idx) => (
                      <div
                        key={idx}
                        style={{
                          width: '50%',
                          height: 40,
                          borderRadius: 'var(--p-border-radius-300)',
                          background: 'var(--p-color-bg-fill-tertiary)',
                        }}
                      />
                    ))}
                  </div>
                  <div className='blockLine'>
                    <SkeletonBodyText lines={1} />
                    <SkeletonBodyText lines={1} />
                  </div>
                  <div className='blockLine short'>
                    <SkeletonBodyText lines={1} />
                    <SkeletonBodyText lines={1} />
                  </div>
                </div>
                <div
                  style={{
                    width: '100%',
                    height: 40,
                    borderRadius: 'var(--p-border-radius-300)',
                    background: 'var(--p-color-bg-fill-tertiary)',
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {[PageTypeDtoEnum.DRAWER_CART].includes(page) &&
          data.currentWidgetName === 'ninjaCart' && (
            <BlockType
              previewType={DeviceTypeDtoEnum.MOBILE}
              blockType={WidgetListTypeDtoEnum.NINJA_CART}
              data={ninjaCartMergeFunc(data.ninjaCart)}
              handleSwitchWidgetState={handleSwitchWidgetState}
              defaultLanguage={defaultLanguage}
              adminActivePath={adminActivePath}
              getSelectionFromThePreview={getSelectionFromThePreview}
            />
          )}
        {[PageTypeDtoEnum.DRAWER_CART].includes(page) &&
          data.currentWidgetName !== 'ninjaCart' && (
            <div className='drawerCartModal'>
              <div className='modalWindow'>
                <div className='firstColumnLine'>
                  <SkeletonBodyText lines={1} />
                  <SkeletonBodyText lines={1} />
                </div>
                {itemsArray(2).map((item) => (
                  <div
                    key={item}
                    style={{ display: 'flex', gap: 8, paddingBottom: 12 }}
                    className='section'
                  >
                    <div
                      style={{
                        width: 84,
                        height: 65,
                      }}
                      className='imageWrapper'
                    >
                      <img src={EmptyImage} alt='EmptyImage' />
                    </div>
                    <div style={{ width: '100%' }}>
                      <div className='spaceBetweenLine'>
                        <SkeletonBodyText lines={1} />
                        <SkeletonBodyText lines={1} />
                      </div>
                      <div style={{ width: '20%' }}>
                        <SkeletonBodyText lines={1} />
                      </div>
                      {item === 1 &&
                        supportedWidgets?.includes(
                          WidgetTypeDtoEnum.DRAWER_CART_PAGE_CART_TEXT
                        ) && (
                          <BlockType
                            previewType={DeviceTypeDtoEnum.MOBILE}
                            blockType={
                              WidgetListTypeDtoEnum.DRAWER_CART_PAGE_CART_TEXT
                            }
                            data={cartTextMergeFunc(
                              data.drawerCartPageCartText,
                              data.presetSetup?.drawerCartPageCartText
                            )}
                            defaultLanguage={defaultLanguage}
                            adminActivePath={adminActivePath}
                            getSelectionFromThePreview={
                              getSelectionFromThePreview
                            }
                          />
                        )}
                    </div>
                  </div>
                ))}
                {supportedWidgets?.includes(
                  WidgetTypeDtoEnum.DRAWER_CART_PROMOTION_CODE_FIELD
                ) && (
                  <BlockType
                    previewType={DeviceTypeDtoEnum.MOBILE}
                    blockType={
                      WidgetListTypeDtoEnum.DRAWER_CART_PROMOTION_CODE_FIELD
                    }
                    data={promotionCodeFieldMergeFunc(
                      data.drawerCartPromotionCodeField,
                      data.presetSetup?.drawerCartPromotionCodeField
                    )}
                    defaultLanguage={defaultLanguage}
                    adminActivePath={adminActivePath}
                    getSelectionFromThePreview={getSelectionFromThePreview}
                  />
                )}
                {supportedWidgets?.includes(
                  WidgetTypeDtoEnum.DRAWER_CART_PROMOTION_SUMMARY
                ) && (
                  <BlockType
                    previewType={DeviceTypeDtoEnum.MOBILE}
                    blockType={
                      WidgetListTypeDtoEnum.DRAWER_CART_PROMOTION_SUMMARY
                    }
                    data={promotionSummaryMergeFunc(
                      data.drawerCartPromotionSummary,
                      data.presetSetup?.drawerCartPromotionSummary
                    )}
                    defaultLanguage={defaultLanguage}
                    adminActivePath={adminActivePath}
                    getSelectionFromThePreview={getSelectionFromThePreview}
                  />
                )}
                <div
                  style={{
                    width: '100%',
                    height: 40,
                    borderRadius: 'var(--p-border-radius-300)',
                    background: 'var(--p-color-bg-fill-tertiary)',
                    marginBottom: 12,
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 8,
                    marginBottom: 12,
                  }}
                >
                  {itemsArray(3).map((item) => (
                    <div key={item} className='seed'>
                      <SkeletonBodyText lines={1} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        {supportedWidgets?.includes(WidgetTypeDtoEnum.ANNOUNCEMENT_BAR) &&
          ![PageTypeDtoEnum.CHECKOUT, PageTypeDtoEnum.NINJA_CART].includes(
            page
          ) && (
            <BlockType
              previewType={DeviceTypeDtoEnum.MOBILE}
              blockType={WidgetListTypeDtoEnum.ANNOUNCEMENT_BAR}
              data={announcementBarMergeFunc(
                data.announcementBar,
                data.presetSetup?.announcementBar
              )}
              defaultLanguage={defaultLanguage}
              adminActivePath={adminActivePath}
              getSelectionFromThePreview={getSelectionFromThePreview}
            />
          )}
        {supportedWidgets?.includes(WidgetTypeDtoEnum.OFFER_RULE_POPUP) &&
          ![PageTypeDtoEnum.CHECKOUT, PageTypeDtoEnum.NINJA_CART].includes(
            page
          ) && (
            <BlockType
              previewType={DeviceTypeDtoEnum.MOBILE}
              blockType={WidgetListTypeDtoEnum.OFFER_RULE_POPUP}
              data={offerRulePopUpMergeFunc(
                data.offerRulePopup,
                data.presetSetup?.offerRulePopup,
                data.currentWidgetName
              )}
              defaultLanguage={defaultLanguage}
              adminActivePath={adminActivePath}
              getSelectionFromThePreview={getSelectionFromThePreview}
            />
          )}
        {supportedWidgets?.includes(WidgetTypeDtoEnum.NOTIFICATION) &&
          ![PageTypeDtoEnum.CHECKOUT, PageTypeDtoEnum.NINJA_CART].includes(
            page
          ) && (
            <BlockType
              previewType={DeviceTypeDtoEnum.MOBILE}
              blockType={WidgetListTypeDtoEnum.NOTIFICATION}
              data={notificationMergeFunc(
                data.notification,
                data.presetSetup?.notification
              )}
              handleSwitchWidgetState={handleSwitchWidgetState}
              defaultLanguage={defaultLanguage}
              adminActivePath={adminActivePath}
              getSelectionFromThePreview={getSelectionFromThePreview}
            />
          )}
        <div className='limoniapps-discountninja-headersection' ref={headerRef}>
          <div className='actionButtons'>
            <img src={Bars} alt='Bars' />
            <Text variant='headingMd' as='h6'>
              Your Shop
            </Text>
            <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
              <img src={Search} alt='Search' />
              <img src={ShoppingCart} alt='ShoppingCart' />
            </div>
          </div>
        </div>
        {page === PageTypeDtoEnum.HOME && (
          <div className='mainItem'>
            <div className='imageWrapper'>
              <img src={EmptyImage} alt='EmptyImage' />
            </div>
            <SkeletonBodyText lines={1} />
          </div>
        )}
        {[PageTypeDtoEnum.PRODUCT, PageTypeDtoEnum.DRAWER_CART].includes(
          page
        ) && (
          <div className='mainContent'>
            <div
              className='imageWrapper'
              style={{ height: 200, marginBottom: 0 }}
            >
              <img src={EmptyImage} alt='EmptyImage' />
            </div>
            <div className='raw'>
              <div style={{ paddingTop: 12 }}>
                <SkeletonBodyText lines={2} />
              </div>
            </div>
            {supportedWidgets?.includes(WidgetTypeDtoEnum.PRODUCT_PAGE_PRICE) &&
              [PageTypeDtoEnum.PRODUCT].includes(page) && (
                <div style={{ padding: '12px 0' }}>
                  <BlockType
                    previewType={previewType}
                    blockType={WidgetListTypeDtoEnum.PRODUCT_PAGE_PRICE}
                    data={priceMergeFunc(
                      data.productPagePrice,
                      data.presetSetup?.productPagePrice
                    )}
                    defaultLanguage={defaultLanguage}
                    adminActivePath={adminActivePath}
                    getSelectionFromThePreview={getSelectionFromThePreview}
                    specialRoot='productPagePrice'
                    priceWidgetType='discounted'
                  />
                </div>
              )}
            {supportedWidgets?.includes(WidgetTypeDtoEnum.PRODUCT_BANNER) &&
              [PageTypeDtoEnum.PRODUCT].includes(page) && (
                <div style={{ padding: '12px 0' }}>
                  <BlockType
                    previewType={DeviceTypeDtoEnum.MOBILE}
                    blockType={WidgetListTypeDtoEnum.PRODUCT_BANNER}
                    data={productBannerMergeFunc(
                      data.productBanner,
                      data.presetSetup?.productBanner
                    )}
                    defaultLanguage={defaultLanguage}
                    adminActivePath={adminActivePath}
                    getSelectionFromThePreview={getSelectionFromThePreview}
                  />
                </div>
              )}
            <div className='multiLines'>
              <SkeletonBodyText lines={7} />
            </div>
            <div style={{ display: 'flex', gap: 8, paddingBottom: 24 }}>
              <div
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: 'var(--p-border-radius-300)',
                  background: 'var(--p-color-bg-fill-tertiary)',
                }}
              />
              <div
                style={{
                  width: 'calc(100% - 48px)',
                  height: 40,
                  borderRadius: 'var(--p-border-radius-300)',
                  background: 'var(--p-color-bg-fill-tertiary)',
                }}
              />
            </div>
            <div className='singleLineAfterColumns'>
              <SkeletonBodyText lines={1} />
            </div>
          </div>
        )}
        {[PageTypeDtoEnum.CART].includes(page) && (
          <div className='mainContent' style={{ height: '100%' }}>
            <div className='columns mobile'>
              <div className='column'>
                <div className='singleCartLine'>
                  <SkeletonBodyText lines={1} />
                </div>
                {itemsArray(2).map((item) => (
                  <div
                    key={item}
                    style={{ display: 'flex', gap: 8, paddingBottom: 12 }}
                    className='section'
                  >
                    <div
                      style={{
                        width: 84,
                        height: 65,
                      }}
                      className='imageWrapper'
                    >
                      <img src={EmptyImage} alt='EmptyImage' />
                    </div>
                    <div style={{ width: '100%' }}>
                      <div className='spaceBetweenLine'>
                        <SkeletonBodyText lines={1} />
                        <SkeletonBodyText lines={1} />
                      </div>
                      <div style={{ width: '20%' }}>
                        <SkeletonBodyText lines={1} />
                      </div>
                      {item === 1 &&
                        supportedWidgets?.includes(
                          WidgetTypeDtoEnum.CART_PAGE_CART_TEXT
                        ) && (
                          <BlockType
                            previewType={DeviceTypeDtoEnum.MOBILE}
                            blockType={
                              WidgetListTypeDtoEnum.CART_PAGE_CART_TEXT
                            }
                            data={cartTextMergeFunc(
                              data.cartPageCartText,
                              data.presetSetup?.cartPageCartText
                            )}
                            defaultLanguage={defaultLanguage}
                            adminActivePath={adminActivePath}
                            getSelectionFromThePreview={
                              getSelectionFromThePreview
                            }
                          />
                        )}
                    </div>
                  </div>
                ))}
              </div>
              <div className='column'>
                {supportedWidgets?.includes(
                  WidgetTypeDtoEnum.CART_PROMOTION_CODE_FIELD
                ) && (
                  <BlockType
                    previewType={DeviceTypeDtoEnum.MOBILE}
                    blockType={WidgetListTypeDtoEnum.CART_PROMOTION_CODE_FIELD}
                    data={promotionCodeFieldMergeFunc(
                      data.cartPromotionCodeField,
                      data.presetSetup?.cartPromotionCodeField
                    )}
                    defaultLanguage={defaultLanguage}
                    adminActivePath={adminActivePath}
                    getSelectionFromThePreview={getSelectionFromThePreview}
                  />
                )}
                {supportedWidgets?.includes(
                  WidgetTypeDtoEnum.CART_PROMOTION_SUMMARY
                ) && (
                  <BlockType
                    previewType={DeviceTypeDtoEnum.MOBILE}
                    blockType={WidgetListTypeDtoEnum.CART_PROMOTION_SUMMARY}
                    data={promotionSummaryMergeFunc(
                      data.cartPromotionSummary,
                      data.presetSetup?.cartPromotionSummary
                    )}
                    defaultLanguage={defaultLanguage}
                    adminActivePath={adminActivePath}
                    getSelectionFromThePreview={getSelectionFromThePreview}
                  />
                )}
                <div
                  style={{
                    width: '100%',
                    height: 40,
                    borderRadius: 'var(--p-border-radius-300)',
                    background: 'var(--p-color-bg-fill-tertiary)',
                    marginBottom: 12,
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 8,
                    marginBottom: 12,
                  }}
                >
                  {itemsArray(4).map((item) => (
                    <div key={item} className='seed'>
                      <SkeletonBodyText lines={1} />
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 8,
                    marginBottom: 12,
                  }}
                >
                  <div className='seed extra'>
                    <SkeletonBodyText lines={1} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {[
          PageTypeDtoEnum.COLLECTION,
          PageTypeDtoEnum.HOME,
          PageTypeDtoEnum.PRODUCT,
          PageTypeDtoEnum.DRAWER_CART,
        ].includes(page) && (
          <div className='gridBox'>
            {itemsArray(12).map((item, idx) => (
              <div key={item} className='item'>
                <div className='imageWrapper'>
                  {supportedWidgets?.includes(
                    WidgetTypeDtoEnum.PROMOTIONAL_BADGE
                  ) &&
                    [PageTypeDtoEnum.COLLECTION].includes(page) &&
                    idx === 0 && (
                      <BlockType
                        previewType={DeviceTypeDtoEnum.MOBILE}
                        blockType={WidgetListTypeDtoEnum.PROMOTIONAL_BADGE}
                        data={promotionalBadgeMergeFunc(
                          data.promotionalBadge,
                          data.presetSetup?.promotionalBadge
                        )}
                        defaultLanguage={defaultLanguage}
                        adminActivePath={adminActivePath}
                        getSelectionFromThePreview={getSelectionFromThePreview}
                      />
                    )}
                  <img src={EmptyImage} alt='EmptyImage' />
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                  <div style={{ width: '100%' }}>
                    <SkeletonBodyText lines={2} />
                  </div>
                  {supportedWidgets?.includes(
                    WidgetTypeDtoEnum.COLLECTION_PAGE_PRICE
                  ) &&
                  [PageTypeDtoEnum.COLLECTION].includes(page) &&
                  idx === 0 ? (
                    <BlockType
                      previewType={previewType}
                      blockType={WidgetListTypeDtoEnum.COLLECTION_PAGE_PRICE}
                      data={priceMergeFunc(
                        data.collectionPagePrice,
                        data.presetSetup?.collectionPagePrice
                      )}
                      defaultLanguage={defaultLanguage}
                      adminActivePath={adminActivePath}
                      getSelectionFromThePreview={getSelectionFromThePreview}
                      specialRoot='collectionPagePrice'
                      priceWidgetType='discounted'
                    />
                  ) : (
                    <div className='priceWidgetPlaceHolder'>
                      <SkeletonBodyText lines={1} />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className='mockupPreviewTemplateDesktop' id='previewAssetDesktop'>
      <div className='browserMenu'>
        <div
          className={classNames('windowButton', {
            isRed: true,
          })}
        />
        <div
          className={classNames('windowButton', {
            isYellow: true,
          })}
        />
        <div
          className={classNames('windowButton', {
            isGreen: true,
          })}
        />
        <img style={{ marginLeft: 20 }} src={Book} alt='Book' />
        <img
          style={{ margin: '0 20px 0 26px' }}
          src={ArrowLeft}
          alt='Arrow left'
        />
        <img src={ArrowRight} alt='Arrow right' />
        <div className='urlBlock'>
          <Icon source={LockFilledIcon} tone='subdued' />
          yourshop.com
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: 'auto',
            gap: 20,
          }}
        >
          <img src={Share} alt='Share' />
          <img src={Plus} alt='Plus' />
          <img src={Tabs} alt='Tabs' />
        </div>
      </div>
      <div
        className='previewBody'
        style={{
          height: `calc(100% - ${headerRef.current?.offsetHeight || 0}px)`,
        }}
      >
        {[PageTypeDtoEnum.CHECKOUT].includes(page) && (
          <div className='mainContent' style={{ height: '100%' }}>
            <div className='columns'>
              <div className='column checkout'>
                <div className='block'>
                  <Text variant='headingSm' as='h6'>
                    {i18n.translate('Contact')}
                  </Text>
                  <div
                    style={{
                      width: '100%',
                      height: 40,
                      borderRadius: 'var(--p-border-radius-300)',
                      background: 'var(--p-color-bg-fill-tertiary)',
                    }}
                  />
                  <div style={{ display: 'flex', gap: 20 }}>
                    {itemsArray(2).map((item, idx) => (
                      <div
                        key={idx}
                        style={{
                          width: '50%',
                          height: 40,
                          borderRadius: 'var(--p-border-radius-300)',
                          background: 'var(--p-color-bg-fill-tertiary)',
                        }}
                      />
                    ))}
                  </div>
                </div>
                <div className='block'>
                  <Text variant='headingSm' as='h6'>
                    {i18n.translate('Delivery')}
                  </Text>
                  <div style={{ display: 'flex', gap: 20 }}>
                    {itemsArray(2).map((item, idx) => (
                      <div
                        key={idx}
                        style={{
                          width: '50%',
                          height: 40,
                          borderRadius: 'var(--p-border-radius-300)',
                          background: 'var(--p-color-bg-fill-tertiary)',
                        }}
                      />
                    ))}
                  </div>
                  <div
                    style={{
                      width: '100%',
                      height: 40,
                      borderRadius: 'var(--p-border-radius-300)',
                      background: 'var(--p-color-bg-fill-tertiary)',
                    }}
                  />
                  <div className='blockLine'>
                    <SkeletonBodyText lines={1} />
                    <SkeletonBodyText lines={1} />
                  </div>
                </div>
                <div className='block'>
                  <Text variant='headingSm' as='h6'>
                    {i18n.translate('Payment')}
                  </Text>
                  <div style={{ display: 'flex', gap: 20 }}>
                    {itemsArray(2).map((item, idx) => (
                      <div
                        key={idx}
                        style={{
                          width: '50%',
                          height: 40,
                          borderRadius: 'var(--p-border-radius-300)',
                          background: 'var(--p-color-bg-fill-tertiary)',
                        }}
                      />
                    ))}
                  </div>
                  <div className='blockLine'>
                    <SkeletonBodyText lines={1} />
                    <SkeletonBodyText lines={1} />
                  </div>
                  <div className='blockLine short'>
                    <SkeletonBodyText lines={1} />
                    <SkeletonBodyText lines={1} />
                  </div>
                </div>
                <div
                  style={{
                    width: '100%',
                    height: 40,
                    borderRadius: 'var(--p-border-radius-300)',
                    background: 'var(--p-color-bg-fill-tertiary)',
                  }}
                />
              </div>
              <div className='column'>
                <div className='spaceBetweenLineSecondColumn checkout'>
                  <SkeletonBodyText lines={1} />
                  <SkeletonBodyText lines={1} />
                </div>
                <div
                  style={{ display: 'flex', gap: 12, paddingBottom: 24 }}
                  className='section'
                >
                  <div
                    style={{
                      width: 84,
                      height: 65,
                    }}
                    className='imageWrapper'
                  >
                    <img src={EmptyImage} alt='EmptyImage' />
                  </div>
                  <div style={{ width: '100%' }}>
                    <div className='spaceBetweenLine'>
                      <SkeletonBodyText lines={1} />
                      <SkeletonBodyText lines={1} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ width: '20%' }}>
                        <SkeletonBodyText lines={1} />
                      </div>
                      {supportedWidgets?.includes(
                        WidgetTypeDtoEnum.DISCOUNT_LABEL
                      ) && (
                        <BlockType
                          previewType={DeviceTypeDtoEnum.DESKTOP}
                          blockType={WidgetListTypeDtoEnum.DISCOUNT_LABEL}
                          data={discountLabelMergeFunc(data.discountLabel)}
                          defaultLanguage={defaultLanguage}
                          adminActivePath={adminActivePath}
                          getSelectionFromThePreview={
                            getSelectionFromThePreview
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {[PageTypeDtoEnum.DRAWER_CART].includes(page) &&
          data.currentWidgetName === 'ninjaCart' && (
            <BlockType
              previewType={DeviceTypeDtoEnum.DESKTOP}
              blockType={WidgetListTypeDtoEnum.NINJA_CART}
              data={ninjaCartMergeFunc(data.ninjaCart)}
              handleSwitchWidgetState={handleSwitchWidgetState}
              defaultLanguage={defaultLanguage}
              adminActivePath={adminActivePath}
              getSelectionFromThePreview={getSelectionFromThePreview}
            />
          )}
        {[PageTypeDtoEnum.DRAWER_CART].includes(page) &&
          data.currentWidgetName !== 'ninjaCart' && (
            <div className='drawerCartModal'>
              <div className='modalWindow'>
                <div className='firstColumnLine'>
                  <SkeletonBodyText lines={1} />
                  <SkeletonBodyText lines={1} />
                </div>
                {itemsArray(2).map((item) => (
                  <div
                    key={item}
                    style={{ display: 'flex', gap: 8, paddingBottom: 12 }}
                    className='section'
                  >
                    <div
                      style={{
                        width: 84,
                        height: 65,
                      }}
                      className='imageWrapper'
                    >
                      <img src={EmptyImage} alt='EmptyImage' />
                    </div>
                    <div style={{ width: '100%' }}>
                      <div className='spaceBetweenLine'>
                        <SkeletonBodyText lines={1} />
                        <SkeletonBodyText lines={1} />
                      </div>
                      <div style={{ width: '20%' }}>
                        <SkeletonBodyText lines={1} />
                      </div>
                      {item === 1 &&
                        supportedWidgets?.includes(
                          WidgetTypeDtoEnum.DRAWER_CART_PAGE_CART_TEXT
                        ) && (
                          <BlockType
                            previewType={DeviceTypeDtoEnum.DESKTOP}
                            blockType={
                              WidgetListTypeDtoEnum.DRAWER_CART_PAGE_CART_TEXT
                            }
                            data={cartTextMergeFunc(
                              data.drawerCartPageCartText,
                              data.presetSetup?.drawerCartPageCartText
                            )}
                            defaultLanguage={defaultLanguage}
                            adminActivePath={adminActivePath}
                            getSelectionFromThePreview={
                              getSelectionFromThePreview
                            }
                          />
                        )}
                    </div>
                  </div>
                ))}
                {supportedWidgets?.includes(
                  WidgetTypeDtoEnum.DRAWER_CART_PROMOTION_CODE_FIELD
                ) && (
                  <BlockType
                    previewType={DeviceTypeDtoEnum.DESKTOP}
                    blockType={
                      WidgetListTypeDtoEnum.DRAWER_CART_PROMOTION_CODE_FIELD
                    }
                    data={promotionCodeFieldMergeFunc(
                      data.drawerCartPromotionCodeField,
                      data.presetSetup?.drawerCartPromotionCodeField
                    )}
                    defaultLanguage={defaultLanguage}
                    adminActivePath={adminActivePath}
                    getSelectionFromThePreview={getSelectionFromThePreview}
                  />
                )}
                {supportedWidgets?.includes(
                  WidgetTypeDtoEnum.DRAWER_CART_PROMOTION_SUMMARY
                ) && (
                  <BlockType
                    previewType={DeviceTypeDtoEnum.DESKTOP}
                    blockType={
                      WidgetListTypeDtoEnum.DRAWER_CART_PROMOTION_SUMMARY
                    }
                    data={promotionSummaryMergeFunc(
                      data.drawerCartPromotionSummary,
                      data.presetSetup?.drawerCartPromotionSummary
                    )}
                    defaultLanguage={defaultLanguage}
                    adminActivePath={adminActivePath}
                    getSelectionFromThePreview={getSelectionFromThePreview}
                  />
                )}
                <div
                  style={{
                    width: '100%',
                    height: 40,
                    borderRadius: 'var(--p-border-radius-300)',
                    background: 'var(--p-color-bg-fill-tertiary)',
                    marginBottom: 12,
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 8,
                    marginBottom: 12,
                  }}
                >
                  {itemsArray(3).map((item) => (
                    <div key={item} className='seed'>
                      <SkeletonBodyText lines={1} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        {supportedWidgets?.includes(WidgetTypeDtoEnum.ANNOUNCEMENT_BAR) &&
          ![PageTypeDtoEnum.CHECKOUT, PageTypeDtoEnum.NINJA_CART].includes(
            page
          ) && (
            <BlockType
              previewType={DeviceTypeDtoEnum.DESKTOP}
              blockType={WidgetListTypeDtoEnum.ANNOUNCEMENT_BAR}
              data={announcementBarMergeFunc(
                data.announcementBar,
                data.presetSetup?.announcementBar
              )}
              defaultLanguage={defaultLanguage}
              adminActivePath={adminActivePath}
              getSelectionFromThePreview={getSelectionFromThePreview}
            />
          )}
        {supportedWidgets?.includes(WidgetTypeDtoEnum.OFFER_RULE_POPUP) &&
          ![PageTypeDtoEnum.CHECKOUT, PageTypeDtoEnum.NINJA_CART].includes(
            page
          ) && (
            <BlockType
              previewType={DeviceTypeDtoEnum.DESKTOP}
              blockType={WidgetListTypeDtoEnum.OFFER_RULE_POPUP}
              data={offerRulePopUpMergeFunc(
                data.offerRulePopup,
                data.presetSetup?.offerRulePopup,
                data.currentWidgetName
              )}
              defaultLanguage={defaultLanguage}
              adminActivePath={adminActivePath}
              getSelectionFromThePreview={getSelectionFromThePreview}
            />
          )}
        {supportedWidgets?.includes(WidgetTypeDtoEnum.NOTIFICATION) &&
          ![PageTypeDtoEnum.CHECKOUT, PageTypeDtoEnum.NINJA_CART].includes(
            page
          ) && (
            <BlockType
              previewType={DeviceTypeDtoEnum.DESKTOP}
              blockType={WidgetListTypeDtoEnum.NOTIFICATION}
              data={notificationMergeFunc(
                data.notification,
                data.presetSetup?.notification
              )}
              handleSwitchWidgetState={handleSwitchWidgetState}
              defaultLanguage={defaultLanguage}
              adminActivePath={adminActivePath}
              getSelectionFromThePreview={getSelectionFromThePreview}
            />
          )}
        <div className='limoniapps-discountninja-headersection' ref={headerRef}>
          <div className='actionButtons'>
            <img src={Bars} alt='Bars' />
            <Text variant='headingMd' as='h6'>
              Your Shop
            </Text>
            <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
              <img src={Search} alt='Search' />
              <img src={ShoppingCart} alt='ShoppingCart' />
            </div>
          </div>
        </div>
        {page === PageTypeDtoEnum.HOME && (
          <div className='mainItem'>
            <div className='imageWrapper'>
              <img src={EmptyImage} alt='EmptyImage' />
            </div>
            <SkeletonBodyText lines={1} />
          </div>
        )}
        {[PageTypeDtoEnum.PRODUCT, PageTypeDtoEnum.DRAWER_CART].includes(
          page
        ) && (
          <div className='mainContent'>
            <div className='columns'>
              <div className='column'>
                <div className='imageWrapper' style={{ height: 240 }}>
                  <img src={EmptyImage} alt='EmptyImage' />
                </div>
                <div style={{ display: 'flex', gap: 8, marginTop: 12 }}>
                  {itemsArray(3).map((item) => (
                    <div
                      key={item}
                      className='imageWrapper'
                      style={{
                        height: 40,
                        width: 40,
                        borderRadius: 'var(--p-border-radius-300)',
                      }}
                    >
                      <img src={EmptyImage} alt='EmptyImage' />
                    </div>
                  ))}
                </div>
              </div>
              <div className='column'>
                <div style={{ paddingTop: 12 }}>
                  <SkeletonBodyText lines={2} />
                </div>
                <div className='singleLine'>
                  {supportedWidgets?.includes(
                    WidgetTypeDtoEnum.PRODUCT_PAGE_PRICE
                  ) && [PageTypeDtoEnum.PRODUCT].includes(page) ? (
                    <BlockType
                      previewType={previewType}
                      blockType={WidgetListTypeDtoEnum.PRODUCT_PAGE_PRICE}
                      data={priceMergeFunc(
                        data.productPagePrice,
                        data.presetSetup?.productPagePrice
                      )}
                      defaultLanguage={defaultLanguage}
                      adminActivePath={adminActivePath}
                      getSelectionFromThePreview={getSelectionFromThePreview}
                      specialRoot='productPagePrice'
                      priceWidgetType='discounted'
                    />
                  ) : (
                    <SkeletonBodyText lines={1} />
                  )}
                </div>
                {supportedWidgets?.includes(WidgetTypeDtoEnum.PRODUCT_BANNER) &&
                  [PageTypeDtoEnum.PRODUCT].includes(page) && (
                    <div style={{ paddingBottom: 12 }}>
                      <BlockType
                        previewType={DeviceTypeDtoEnum.DESKTOP}
                        blockType={WidgetListTypeDtoEnum.PRODUCT_BANNER}
                        data={productBannerMergeFunc(
                          data.productBanner,
                          data.presetSetup?.productBanner
                        )}
                        defaultLanguage={defaultLanguage}
                        adminActivePath={adminActivePath}
                        getSelectionFromThePreview={getSelectionFromThePreview}
                      />
                    </div>
                  )}
                <div className='multiLines'>
                  <SkeletonBodyText lines={10} />
                </div>
                <div style={{ display: 'flex', gap: 8, paddingBottom: 12 }}>
                  <div
                    style={{
                      width: 40,
                      height: 40,
                      borderRadius: 'var(--p-border-radius-300)',
                      background: 'var(--p-color-bg-fill-tertiary)',
                    }}
                  />
                  <div
                    style={{
                      width: 'calc(100% - 48px)',
                      height: 40,
                      borderRadius: 'var(--p-border-radius-300)',
                      background: 'var(--p-color-bg-fill-tertiary)',
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='singleLineAfterColumns'>
              <SkeletonBodyText lines={1} />
            </div>
          </div>
        )}
        {[PageTypeDtoEnum.CART].includes(page) && (
          <div className='mainContent' style={{ height: '100%' }}>
            <div className='columns' style={{ gap: 32 }}>
              <div className='column' style={{ flex: 'initial', width: '55%' }}>
                <div className='singleCartLine'>
                  <SkeletonBodyText lines={1} />
                </div>
                <div className='spaceBetweenLine'>
                  <SkeletonBodyText lines={1} />
                  <SkeletonBodyText lines={1} />
                </div>
                {itemsArray(3).map((item) => (
                  <div
                    key={item}
                    style={{ display: 'flex', gap: 8, paddingBottom: 12 }}
                    className='section'
                  >
                    <div
                      style={{
                        width: 97,
                        height: 80,
                      }}
                      className='imageWrapper'
                    >
                      <img src={EmptyImage} alt='EmptyImage' />
                    </div>
                    <div style={{ width: '100%' }}>
                      <div className='spaceBetweenLine'>
                        <SkeletonBodyText lines={1} />
                        <SkeletonBodyText lines={1} />
                      </div>
                      <div style={{ width: '20%' }}>
                        <SkeletonBodyText lines={1} />
                      </div>
                      {item === 1 &&
                        supportedWidgets?.includes(
                          WidgetTypeDtoEnum.CART_PAGE_CART_TEXT
                        ) && (
                          <BlockType
                            previewType={DeviceTypeDtoEnum.DESKTOP}
                            blockType={
                              WidgetListTypeDtoEnum.CART_PAGE_CART_TEXT
                            }
                            data={cartTextMergeFunc(
                              data.cartPageCartText,
                              data.presetSetup?.cartPageCartText
                            )}
                            defaultLanguage={defaultLanguage}
                            adminActivePath={adminActivePath}
                            getSelectionFromThePreview={
                              getSelectionFromThePreview
                            }
                          />
                        )}
                    </div>
                  </div>
                ))}
                <div className='singleCartLine'>
                  <SkeletonBodyText lines={1} />
                </div>
                {itemsArray(2).map((item) => (
                  <div
                    key={item}
                    style={{ display: 'flex', gap: 8, paddingBottom: 12 }}
                    className='section'
                  >
                    <div
                      style={{
                        width: 97,
                        height: 80,
                      }}
                      className='imageWrapper'
                    >
                      <img src={EmptyImage} alt='EmptyImage' />
                    </div>
                    <div style={{ width: '100%' }}>
                      <div className='spaceBetweenLine'>
                        <SkeletonBodyText lines={1} />
                        <SkeletonBodyText lines={1} />
                      </div>
                      <div style={{ width: '20%' }}>
                        <SkeletonBodyText lines={1} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className='column' style={{ flex: 'initial', width: '45%' }}>
                <div className='spaceBetweenLineSecondColumn'>
                  <SkeletonBodyText lines={1} />
                  <SkeletonBodyText lines={1} />
                </div>
                <div
                  style={{
                    width: '100%',
                    height: 80,
                    borderRadius: 'var(--p-border-radius-300)',
                    background: 'var(--p-color-bg-fill-tertiary)',
                    marginBottom: 12,
                  }}
                />
                {supportedWidgets?.includes(
                  WidgetTypeDtoEnum.CART_PROMOTION_CODE_FIELD
                ) && (
                  <BlockType
                    previewType={DeviceTypeDtoEnum.DESKTOP}
                    blockType={WidgetListTypeDtoEnum.CART_PROMOTION_CODE_FIELD}
                    data={promotionCodeFieldMergeFunc(
                      data.cartPromotionCodeField,
                      data.presetSetup?.cartPromotionCodeField
                    )}
                    defaultLanguage={defaultLanguage}
                    adminActivePath={adminActivePath}
                    getSelectionFromThePreview={getSelectionFromThePreview}
                  />
                )}
                {supportedWidgets?.includes(
                  WidgetTypeDtoEnum.CART_PROMOTION_SUMMARY
                ) && (
                  <BlockType
                    previewType={DeviceTypeDtoEnum.DESKTOP}
                    blockType={WidgetListTypeDtoEnum.CART_PROMOTION_SUMMARY}
                    data={promotionSummaryMergeFunc(
                      data.cartPromotionSummary,
                      data.presetSetup?.cartPromotionSummary
                    )}
                    defaultLanguage={defaultLanguage}
                    adminActivePath={adminActivePath}
                    getSelectionFromThePreview={getSelectionFromThePreview}
                  />
                )}
                <div
                  style={{
                    width: '100%',
                    height: 48,
                    borderRadius: 'var(--p-border-radius-300)',
                    background: 'var(--p-color-bg-fill-tertiary)',
                    marginBottom: 12,
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 8,
                    marginBottom: 12,
                  }}
                >
                  {itemsArray(4).map((item) => (
                    <div key={item} className='seed'>
                      <SkeletonBodyText lines={1} />
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 8,
                    marginBottom: 12,
                  }}
                >
                  <div className='seed extra'>
                    <SkeletonBodyText lines={1} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {[
          PageTypeDtoEnum.COLLECTION,
          PageTypeDtoEnum.HOME,
          PageTypeDtoEnum.PRODUCT,
          PageTypeDtoEnum.DRAWER_CART,
        ].includes(page) && (
          <div className='gridBox'>
            {itemsArray(12).map((item, idx) => (
              <div key={item} className='item'>
                <div className='imageWrapper'>
                  {supportedWidgets?.includes(
                    WidgetTypeDtoEnum.PROMOTIONAL_BADGE
                  ) &&
                    [PageTypeDtoEnum.COLLECTION].includes(page) &&
                    idx === 0 && (
                      <BlockType
                        previewType={DeviceTypeDtoEnum.DESKTOP}
                        blockType={WidgetListTypeDtoEnum.PROMOTIONAL_BADGE}
                        data={promotionalBadgeMergeFunc(
                          data.promotionalBadge,
                          data.presetSetup?.promotionalBadge
                        )}
                        defaultLanguage={defaultLanguage}
                        adminActivePath={adminActivePath}
                        getSelectionFromThePreview={getSelectionFromThePreview}
                      />
                    )}
                  <img src={EmptyImage} alt='EmptyImage' />
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                  <div style={{ width: '100%' }}>
                    <SkeletonBodyText lines={2} />
                  </div>
                  {supportedWidgets?.includes(
                    WidgetTypeDtoEnum.COLLECTION_PAGE_PRICE
                  ) &&
                  [PageTypeDtoEnum.COLLECTION].includes(page) &&
                  idx === 0 ? (
                    <BlockType
                      previewType={previewType}
                      blockType={WidgetListTypeDtoEnum.COLLECTION_PAGE_PRICE}
                      data={priceMergeFunc(
                        data.collectionPagePrice,
                        data.presetSetup?.collectionPagePrice
                      )}
                      defaultLanguage={defaultLanguage}
                      adminActivePath={adminActivePath}
                      getSelectionFromThePreview={getSelectionFromThePreview}
                      specialRoot='collectionPagePrice'
                      priceWidgetType='discounted'
                    />
                  ) : (
                    <div className='priceWidgetPlaceHolder'>
                      <SkeletonBodyText lines={1} />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
