import {
  NotificationSystemDefaultsDto,
  PresetsSetupDto,
  PromotionSetupDtoRead,
} from 'core/api/adminWidgets/adminWidgetsApi';
import React, { useMemo } from 'react';
import { SelectedOptionPath } from '../../../../RightSideBarWrapper';
import { WidgetTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { NotificationStatesPromotionEntryTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import PromotionNotificationExpanded from './components/PromotionNotificationExpanded/PromotionNotificationExpanded';
import PromotionNotificationCollapsed from './components/PromotionNotificationCollapsed/PromotionNotificationCollapsed';

type PromotionNotificationProps = {
  editPromotionState: PromotionSetupDtoRead;
  defaultPromotionData: PromotionSetupDtoRead;
  shopDefaultData: NotificationSystemDefaultsDto;
  selectedOptionPath: SelectedOptionPath;
  updateEditPromotionState: (
    field: keyof PromotionSetupDtoRead,
    data: PromotionSetupDtoRead[keyof PromotionSetupDtoRead],
    presetSetup?: PresetsSetupDto
  ) => void;
  filterSupportedWidgets: (value: WidgetTypeDtoEnum) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const PromotionNotification: React.FC<PromotionNotificationProps> = ({
  editPromotionState,
  defaultPromotionData,
  shopDefaultData,
  selectedOptionPath,
  updateEditPromotionState,
  filterSupportedWidgets,
  handleLeftMenuSelection,
}) => {
  const { notification } = editPromotionState;

  const notificationOptions = useMemo(() => {
    switch (true) {
      case notification?.currentEntries?.includes(
        NotificationStatesPromotionEntryTypeDtoEnum.COLLAPSED_STATE
      ):
        return (
          <PromotionNotificationCollapsed
            editPromotionState={editPromotionState}
            defaultPromotionData={defaultPromotionData}
            shopDefaultData={shopDefaultData}
            selectedOptionPath={selectedOptionPath}
            updateEditPromotionState={updateEditPromotionState}
            filterSupportedWidgets={filterSupportedWidgets}
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case notification?.currentEntries?.includes(
        NotificationStatesPromotionEntryTypeDtoEnum.EXPANDED_STATE
      ):
        return (
          <PromotionNotificationExpanded
            editPromotionState={editPromotionState}
            defaultPromotionData={defaultPromotionData}
            shopDefaultData={shopDefaultData}
            selectedOptionPath={selectedOptionPath}
            updateEditPromotionState={updateEditPromotionState}
            filterSupportedWidgets={filterSupportedWidgets}
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
    }
  }, [
    editPromotionState,
    defaultPromotionData,
    shopDefaultData,
    selectedOptionPath,
    updateEditPromotionState,
    filterSupportedWidgets,
    handleLeftMenuSelection,
  ]);
  return <>{notificationOptions}</>;
};

export default PromotionNotification;
