import React, { useCallback, useEffect, useState } from 'react';
import {
  Badge,
  BlockStack,
  Card,
  Icon,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { ChevronRightIcon } from '@shopify/polaris-icons';
import { useDispatch } from 'react-redux';
import { SizeTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';
import {
  setIsUnsavedChanges,
  setOnDiscardChanges,
  setOnSaveChanges,
} from 'core/store/settingsSlice';
import {
  PromotionCodeFieldDisplayModeDto,
  PromotionCodeFieldHeaderStyleDto,
  PromotionCodeFieldShopSettingsDto,
  WidgetSettingsDtoPromotionCodeFieldShopSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  DisplayModeSetting,
  SizeTypeSetting,
  StyleSetting,
  WidgetSettingsWrapper,
  WidgetTypeSettingsWrapperEnum,
} from '../components';
import { useI18n } from '@shopify/react-i18n';
import './PromotionCodeSettings.scss';
import { HeaderStyleSetting } from '../components/HeaderStyleSetting/HeaderStyleSetting';
import { useSearchParams } from 'react-router-dom';
export const PromotionCodeSettings: React.FC = () => {
  const dispatch = useDispatch();
  const {
    promotionCodeData,
    savePromotionCode,
    savePromotionCodeIsLoading,
    promotionCodeDataIsFetching,
    refetchPromotionCodeData,
  } = useConfigureSettings(SettingsFetchTypeEnum.PROMOTION_CODE_FIELD);
  const [searchParams, setSearchParams] = useSearchParams();
  const [i18n] = useI18n();
  const [savedData, setSavedData] =
    useState<WidgetSettingsDtoPromotionCodeFieldShopSettingsDto>({});
  const [promotionCodeState, setPromotionCodeState] =
    useState<WidgetSettingsDtoPromotionCodeFieldShopSettingsDto>({});
  const updatePromotionCodeState = useCallback(
    (
      field: keyof PromotionCodeFieldShopSettingsDto,
      data: PromotionCodeFieldShopSettingsDto[keyof PromotionCodeFieldShopSettingsDto]
    ) =>
      setPromotionCodeState((prev) => ({
        ...prev,
        settings: {
          ...prev.settings,
          [field]: data,
        },
      })),
    [setPromotionCodeState]
  );

  useEffect(() => {
    if (
      !isEmpty(promotionCodeState) &&
      !isEqual(savedData, promotionCodeState)
    ) {
      dispatch(setIsUnsavedChanges(true));
      dispatch(
        setOnDiscardChanges(
          () => !isEmpty(savedData) && setPromotionCodeState(savedData)
        )
      );
      dispatch(
        setOnSaveChanges(() => {
          setSavedData(promotionCodeState);
          return savePromotionCode(promotionCodeState.settings || {});
        })
      );
    } else if (isEqual(savedData, promotionCodeState)) {
      dispatch(setIsUnsavedChanges(false));
    }
  }, [promotionCodeState, savedData, dispatch]);

  useEffect(() => {
    if (!isEmpty(promotionCodeData)) {
      setPromotionCodeState(promotionCodeData);
      setSavedData(promotionCodeData);
    }
  }, [promotionCodeData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  const tab = searchParams.get('tab');

  const onWidgetClick = useCallback(
    (widgetPath: string) => {
      searchParams.set('subPath', widgetPath);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const getPromotionCodeNavigateCard = useCallback(
    (enabled: boolean, name: string) => {
      const widgetPath =
        name === 'DiscountNinjaPromotion'
          ? 'discount-ninja-promotion-codes'
          : 'shopify-discount-codes';
      return (
        <div
          onClick={() => onWidgetClick(widgetPath)}
          className='PromotionCodeNavigateCard'
        >
          <InlineStack align='space-between'>
            <Text as='p'>{i18n.translate(name)}</Text>
            <InlineStack gap='200'>
              <Badge tone={enabled ? 'success' : undefined}>
                {i18n.translate(!enabled ? 'Off' : 'On')}
              </Badge>
              <Icon source={ChevronRightIcon} />
            </InlineStack>
          </InlineStack>
        </div>
      );
    },
    [i18n, onWidgetClick]
  );

  return (
    <div className='PromotionCodeSettings'>
      <WidgetSettingsWrapper
        widgetState={{
          ...promotionCodeState,
          widgetType: WidgetTypeSettingsWrapperEnum.PROMOTION_CODE_FIELD,
        }}
        isLoading={promotionCodeDataIsFetching || !promotionCodeData}
        refetchWidget={refetchPromotionCodeData}
        cartDrawer={true}
        cartDrawerData={{
          title: i18n.translate('PromotionCodeField'),
          subtitle: i18n.translate('PromotionCodeFieldSubtitle'),
          newLinks: true,
          items: [
            {
              name: i18n.translate('CartPage'),
              enabled: true,
              tab: 'cart',
            },
            {
              name: i18n.translate('DrawerCart'),
              enabled: false,
              tab: 'drawer',
            },
          ],
        }}
      >
        <BlockStack gap='600'>
          {!tab ? (
            <>
              {' '}
              <StyleSetting styled />
              <Card>
                <BlockStack gap='400'>
                  <BlockStack gap='100'>
                    <Text as='p' fontWeight='bold'>
                      {i18n.translate('PromotionCodeBehavior')}
                    </Text>
                    <Text as='p' tone='subdued'>
                      {i18n.translate('ConfigureHowCodes')}
                    </Text>
                  </BlockStack>
                  <BlockStack gap='200'>
                    {getPromotionCodeNavigateCard(
                      !!promotionCodeState.settings
                        ?.areDiscountNinjaPromotionCodesEnabled,
                      'DiscountNinjaPromotion'
                    )}
                    {getPromotionCodeNavigateCard(
                      !!promotionCodeState.settings
                        ?.areShopifyPromotionCodesEnabled,
                      'ShopifyDiscountCodes'
                    )}
                  </BlockStack>
                </BlockStack>
              </Card>
            </>
          ) : (
            <>
              <DisplayModeSetting
                displayMode={
                  promotionCodeState.settings
                    ?.displayMode as PromotionCodeFieldDisplayModeDto
                }
                setDisplayMode={(value) =>
                  updatePromotionCodeState('displayMode', value)
                }
              />
              <SizeTypeSetting
                sizeType={
                  promotionCodeState.settings?.size?.size as SizeTypeDtoEnum
                }
                setSizeType={(value) =>
                  updatePromotionCodeState('size', {
                    ...promotionCodeState.settings?.size,
                    size: value,
                  })
                }
                subtitle={i18n.translate('AdjustSize')}
              />
              <HeaderStyleSetting
                headerStyle={
                  promotionCodeState.settings
                    ?.headerStyle as PromotionCodeFieldHeaderStyleDto
                }
                setHeaderStyle={(value) =>
                  updatePromotionCodeState('headerStyle', value)
                }
              />
            </>
          )}
        </BlockStack>
      </WidgetSettingsWrapper>
    </div>
  );
};
