import React, { useCallback, useState } from 'react';
import './UploadFileModal.scss';
import { useI18n } from '@shopify/react-i18n';
import {
  DropZone,
  Modal,
  Text,
  Checkbox,
  Link,
  BlockStack,
  Box,
} from '@shopify/polaris';

type UploadFileModalProps = {
  title?: string;
  subtitle?: React.ReactNode;
  fileFormat: string;
  isOpen: boolean;
  uploadIsLoading?: boolean;
  checkboxLabel?: string;
  allowMultiple?: boolean;
  onClose: () => void;
  onUploadFiles: (value: File[], overwrite?: boolean) => void;
};
export const UploadFileModal: React.FC<UploadFileModalProps> = ({
  title,
  subtitle,
  isOpen,
  fileFormat,
  uploadIsLoading,
  checkboxLabel,
  allowMultiple,
  onClose,
  onUploadFiles,
}) => {
  const [i18n] = useI18n();
  const [files, setFiles] = useState<File[]>([]);
  const [overwrite, setOverwrite] = useState<boolean>(false);

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      setFiles([...(allowMultiple ? files : []), ...acceptedFiles]);
    },
    [files]
  );
  const handleUploadFiles = useCallback(() => {
    onUploadFiles(files, overwrite);
  }, [files, onUploadFiles, overwrite]);

  const uploadedFiles = files.length > 0 && (
    <Box padding='400'>
      <BlockStack>
        {files.map((file, index) => (
          <Text key={index} as='p'>
            {file.name}
          </Text>
        ))}
      </BlockStack>
    </Box>
  );
  const fileUpload = !files.length && (
    <DropZone.FileUpload
      actionHint={`${i18n.translate('Accepts')} ${fileFormat}`}
    />
  );

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        open={isOpen}
        onClose={onClose}
        title={title}
        primaryAction={{
          content: i18n.translate('Import'),
          onAction: handleUploadFiles,
          disabled: !files.length,
          loading: uploadIsLoading,
        }}
        secondaryActions={[
          {
            content: i18n.translate('Cancel'),
            onAction: onClose,
          },
        ]}
      >
        <div className='UploadFileModal'>
          <Modal.Section>
            <BlockStack gap='400'>
              <Text as='p'>{subtitle}</Text>
              <DropZone
                label={''}
                allowMultiple={false}
                accept={fileFormat}
                type='file'
                onDrop={handleDrop}
                variableHeight
              >
                {uploadedFiles}
                {fileUpload}
              </DropZone>
              {checkboxLabel && (
                <Checkbox
                  checked={overwrite}
                  onChange={setOverwrite}
                  label={checkboxLabel}
                />
              )}
            </BlockStack>
          </Modal.Section>
        </div>
      </Modal>
    </div>
  );
};
