import React from 'react';
import { useI18n } from '@shopify/react-i18n';
import './MessageCount.scss';
import { OptionDtoMessageCountComponentDto } from 'core/api/adminWidgets/adminWidgetsApi';

import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import ColorSelectorBox from 'core/components/WidgetEditor/ColorSelectorBox/ColorSelectorBox';

type MessageCountProps = {
  messageCount: OptionDtoMessageCountComponentDto;
  disabled?: boolean;
  onSystemReset?: () => void;
  setMessageCount: (value: OptionDtoMessageCountComponentDto) => void;
};

export const MessageCount: React.FC<MessageCountProps> = ({
  messageCount,
  disabled,
  onSystemReset,
  setMessageCount,
}) => {
  const [i18n] = useI18n();

  return (
    <div className='MessageCount'>
      <StyleComponentHeader
        label={i18n.translate(`MessageCount`)}
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            disabled: disabled,
            onAction: () => onSystemReset?.(),
          },
        ]}
      >
        <div className='MessageCountRow'>
          {i18n.translate(`Color`)}
          <div className='RightContent'>
            <ColorSelectorBox
              disabled={disabled}
              color={messageCount.value?.color || ''}
              setColor={(value) =>
                setMessageCount({
                  ...messageCount,
                  value: {
                    color: value,
                  },
                })
              }
            />
          </div>
        </div>
      </StyleComponentHeader>
    </div>
  );
};
