import React, { useCallback, useEffect, useState } from 'react';
import { BlockStack } from '@shopify/polaris';
import { useDispatch } from 'react-redux';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';
import {
  setIsUnsavedChanges,
  setOnDiscardChanges,
  setOnSaveChanges,
} from 'core/store/settingsSlice';
import {
  DrawerCartStrikethroughPricingShopSettingsDto,
  StrikethroughPricingFreeGiftsQuantitySpinnerModeDto,
  WidgetSettingsDtoDrawerCartStrikethroughPricingShopSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  SpinnerModeSetting,
  StyleSetting,
  WidgetSettingsWrapper,
  WidgetTypeSettingsWrapperEnum,
} from '../components';

export const StrikethroughDrawerCartSettings: React.FC = () => {
  const dispatch = useDispatch();
  const {
    strikethroughDrawerCartData,
    saveStrikethroughDrawerCart,
    strikethroughDrawerCartIsFetching,
    refetchStrikethroughDrawerCartData,
  } = useConfigureSettings(SettingsFetchTypeEnum.STRIKE_THROUGH_DRAWER_CART);
  const [savedData, setSavedData] =
    useState<WidgetSettingsDtoDrawerCartStrikethroughPricingShopSettingsDto>(
      {}
    );
  const [strikethroughDrawerCartState, setStrikethroughDrawerCartState] =
    useState<WidgetSettingsDtoDrawerCartStrikethroughPricingShopSettingsDto>(
      {}
    );

  const updateDrawerCartState = useCallback(
    (
      field: keyof DrawerCartStrikethroughPricingShopSettingsDto,
      data: DrawerCartStrikethroughPricingShopSettingsDto[keyof DrawerCartStrikethroughPricingShopSettingsDto]
    ) =>
      setStrikethroughDrawerCartState((prev) => ({
        ...prev,
        settings: {
          ...prev.settings,
          [field]: data,
        },
      })),
    [setStrikethroughDrawerCartState]
  );

  useEffect(() => {
    if (
      !isEmpty(strikethroughDrawerCartState) &&
      !isEqual(savedData, strikethroughDrawerCartState)
    ) {
      dispatch(setIsUnsavedChanges(true));
      dispatch(
        setOnDiscardChanges(
          () =>
            !isEmpty(savedData) && setStrikethroughDrawerCartState(savedData)
        )
      );
      dispatch(
        setOnSaveChanges(() => {
          setSavedData(strikethroughDrawerCartState);
          return saveStrikethroughDrawerCart(
            strikethroughDrawerCartState.settings || {}
          );
        })
      );
    } else if (isEqual(savedData, strikethroughDrawerCartState)) {
      dispatch(setIsUnsavedChanges(false));
    }
  }, [strikethroughDrawerCartState, savedData, dispatch]);

  useEffect(() => {
    if (!isEmpty(strikethroughDrawerCartData)) {
      setStrikethroughDrawerCartState(strikethroughDrawerCartData);
      setSavedData(strikethroughDrawerCartData);
    }
  }, [strikethroughDrawerCartData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  return (
    <div className='StrikethroughDrawerCartSettings'>
      <WidgetSettingsWrapper
        widgetState={{
          ...strikethroughDrawerCartState,
          widgetType:
            WidgetTypeSettingsWrapperEnum.DRAWER_CART_STRIKETHROUGH_PRICING,
        }}
        isLoading={
          strikethroughDrawerCartIsFetching || !strikethroughDrawerCartData
        }
        refetchWidget={refetchStrikethroughDrawerCartData}
      >
        <BlockStack gap='600'>
          <StyleSetting styled />
          <SpinnerModeSetting
            spinnerMode={
              strikethroughDrawerCartState.settings
                ?.spinnerMode as StrikethroughPricingFreeGiftsQuantitySpinnerModeDto
            }
            setSpinnerMode={(value) =>
              updateDrawerCartState('spinnerMode', value)
            }
          />
        </BlockStack>
      </WidgetSettingsWrapper>
    </div>
  );
};
