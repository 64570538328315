import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import './DiscountLabel.scss';
import { useI18n } from '@shopify/react-i18n';
import { useAppSelector } from 'core/hooks';
import {
    getCurrentMessage,
    parseSmartTagCode,
} from 'features/settings/components/EditPresetSkeleton/utils/utils';
import { DeviceTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import {
    WidgetsFetchTypeEnum,
    useConfigureWidgets,
} from 'features/settings/hooks/useConfigureWidgets';
import { DiscountLabelOfferValueDto } from 'core/api/adminWidgets/adminWidgetsApi';

type DiscountLabelProps = {
    data: any;
    previewType: DeviceTypeDtoEnum;
    defaultLanguage?: string;
    adminActivePath: string;
    getSelectionFromThePreview(path: string): void;
};

export const DiscountLabel: React.FC<DiscountLabelProps> = ({
    previewType,
    defaultLanguage,
    data,
    adminActivePath,
    getSelectionFromThePreview,
}) => {
    const [i18n] = useI18n();
    const { fontListData } = useConfigureWidgets(
        WidgetsFetchTypeEnum.PRESET_FONT_LIST
    );

    const { smartTagsCodes } = useAppSelector((state) => state.offersWizard);

    const [rerender, setRerender] = useState<number>(0);
    const [injectStyles, setInjectStyles] = useState<boolean>(false);

    const discountLabel = document.querySelector('la-dn-discount-label');

    const prevMatch = useRef<number>(0);

    const currentIndex: number = useMemo(() => {
        const match = adminActivePath.match(/banner_(\d+)/);
        if (match) {
            prevMatch.current = +match[1];
            return +match[1];
        } else {
            return prevMatch.current ?? 0;
        }
    }, [adminActivePath]);

    const currentMessage = useMemo(
        () =>
            data?.offers?.[currentIndex]?.setup?.general?.customLabel
                ? getCurrentMessage(
                    data?.offers?.[currentIndex]?.setup?.general?.customLabel
                        ?.entries || '',
                    defaultLanguage,
                    smartTagsCodes
                )
                : parseSmartTagCode(
                    data?.offers?.[currentIndex]?.setup?.general?.values?.find(
                        (value: DiscountLabelOfferValueDto) =>
                            value?.type ===
                            data?.offers?.[currentIndex]?.setup?.general?.labelType
                    )?.value || '',
                    smartTagsCodes
                ),
        [currentIndex, data?.offers, defaultLanguage, smartTagsCodes]
    );

    const elementToReplaceWith = useMemo(
        () =>
            `
              <la-dn-discount-label
              admin-mode="true"
              admin-active-path="${adminActivePath}"
              admin-mode-label='[{"component":"discountLabel","label":"${i18n.translate(
                'discountLabel'
            )}","icon":"widget","subComponents":[{"component":"banner","label":"${i18n.translate(
                'discountLabel'
            )}","icon":"offer"}]}]'
        index="${currentIndex}"
              >
                  <div class="discount-label">
                      <div discount-label="true">${currentMessage}</div>
                  </div>
              </la-dn-discount-label>
              `,
        [adminActivePath, i18n, currentMessage, currentIndex]
    );

    const showDiscountLabel = useCallback(() => {
        const parser = new DOMParser();
        const container = document.querySelector('#discount-label-root');

        const discountLabel = parser
            .parseFromString(elementToReplaceWith, 'text/html')
            .querySelector('la-dn-discount-label');

        setRerender((prev) => prev + 1);

        if (
            container &&
            discountLabel &&
            !container.querySelector('la-dn-discount-label')
        ) {
            container?.appendChild(discountLabel);
        } else if (
            container &&
            discountLabel &&
            container.querySelector('la-dn-discount-label')
        ) {
            const currentDiscountLabel = container.querySelector(
                'la-dn-discount-label'
            );
            if (currentDiscountLabel) {
                currentDiscountLabel.innerHTML = discountLabel.innerHTML;

                currentDiscountLabel.setAttribute(
                    'index',
                    discountLabel.getAttribute('index') || ''
                );
            }
        }
    }, [elementToReplaceWith, setRerender]);

    useEffect(() => {
        if (rerender) {
            document.dispatchEvent(new CustomEvent('la:dn:discount-label:render'));
            setInjectStyles(true);
        }
    }, [rerender]);

    useEffect(() => {
        if (injectStyles && data) {
            discountLabel?.setAttribute('styles', JSON.stringify(data));
        }
    }, [data, injectStyles]);

    useEffect(() => {
        if (injectStyles) {
            discountLabel?.setAttribute(
                'force-mobile',
                (previewType === DeviceTypeDtoEnum.MOBILE).toString()
            );
        }
    }, [injectStyles, previewType]);

    useEffect(() => {
        if (injectStyles) {
            discountLabel?.setAttribute('font-assets', JSON.stringify(fontListData));
        }
    }, [fontListData, injectStyles]);

    useEffect(() => {
        showDiscountLabel();
        document
            .querySelector('la-dn-discount-label')
            ?.addEventListener('la:dn:discount-label:admin-change', (e) =>
                getSelectionFromThePreview((e as CustomEvent).detail)
            );
    }, [currentIndex, currentMessage]);

    useEffect(() => {
        if (injectStyles) {
            discountLabel?.setAttribute('admin-active-path', adminActivePath);
        }
    }, [adminActivePath]);

    return <div id='discount-label-root'></div>;
};
