import React from 'react';
import { BlockStack, Checkbox, Modal, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { setMarkdownActionModalShow } from 'core/store/promotionsSlice';

type ActivatePromotionModalProps = {
  onClose: () => void;
  onActivatePromotion: () => void;
  isOpen: boolean;
};
export const ActivatePromotionModal: React.FC<ActivatePromotionModalProps> = ({
  onClose,
  onActivatePromotion,
  isOpen,
}) => {
  const [i18n] = useI18n();
  const dispath = useAppDispatch();
  const { markdownActionModalShow } = useAppSelector(
    (store) => store.promotions
  );
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={i18n.translate(`ActivatePromotion`)}
      primaryAction={{
        content: i18n.translate(`Activate`),
        onAction: () => {
          onActivatePromotion();
          onClose();
        },
      }}
      secondaryActions={[
        {
          content: i18n.translate('Cancel'),
          onAction: onClose,
        },
      ]}
    >
      <div className='ActivatePromotionModal'>
        <Modal.Section>
          <BlockStack gap='300'>
            <Text as='p'>{i18n.translate(`MarkdownActivation`)}</Text>
            <Checkbox
              label={i18n.translate(`RememberDontShow`)}
              checked={!markdownActionModalShow}
              onChange={() =>
                dispath(setMarkdownActionModalShow(!markdownActionModalShow))
              }
            />
          </BlockStack>
        </Modal.Section>
      </div>
    </Modal>
  );
};
