import React, { useCallback, useMemo } from 'react';
import { SelectedOptionPath } from '../../../../RightSideBarWrapper';
import {
  PromotionalBadgePresetDtoRead,
  PromotionalBadgePresetFirstLevelDto,
  PromotionalBadgeSystemDefaultsDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import PromotionalBadgeGeneral from './components/PromotionalBadgeGeneral/PromotionalBadgeGeneral';
import PromotionalBadgeMessage from './components/PromotionalBadgeMessage/PromotionalBadgeMessage';
import PromotionalBadgeBadge from './components/PromotionalBadgeBadge/PromotionalBadgeBadge';
import {
  WidgetListOptionsDtoEnum,
  WidgetListTypeDtoEnum,
} from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { getLastSelectedOption } from 'features/settings/components/EditPresetSkeleton/utils/utils';

type PresetPromotionalBadgeProps = {
  selectedOptionPath: SelectedOptionPath;
  promotionalBadge: PromotionalBadgePresetDtoRead;
  defaultPresetData: PromotionalBadgePresetDtoRead;
  shopDefaultData: PromotionalBadgeSystemDefaultsDto;
  updatePromotionalBadge: (data: PromotionalBadgePresetDtoRead) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const PresetPromotionalBadge: React.FC<PresetPromotionalBadgeProps> = ({
  selectedOptionPath,
  promotionalBadge,
  defaultPresetData,
  shopDefaultData,
  updatePromotionalBadge,
  handleLeftMenuSelection,
}) => {
  const handleComponentVisability = useCallback(
    (option: keyof PromotionalBadgePresetFirstLevelDto) => {
      updatePromotionalBadge({
        ...promotionalBadge,
        options: {
          ...promotionalBadge.options,
          [option]: {
            ...promotionalBadge.options?.[option],
            isHidden: !promotionalBadge.options?.[option]?.isHidden,
          },
        },
      });
    },
    [updatePromotionalBadge, promotionalBadge]
  );

  const promotionalBadgeOptions = useMemo(() => {
    const selectedOption = getLastSelectedOption(selectedOptionPath);
    switch (selectedOption?.name) {
      case WidgetListTypeDtoEnum.PROMOTIONAL_BADGE:
        return (
          <PromotionalBadgeGeneral
            general={promotionalBadge?.general || {}}
            isPresetEditor
            onShowHide={
              promotionalBadge.canBeHidden
                ? () =>
                    updatePromotionalBadge({
                      ...promotionalBadge,
                      isHidden: !promotionalBadge.isHidden,
                    })
                : undefined
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case WidgetListOptionsDtoEnum.MESSAGE:
        return (
          <PromotionalBadgeMessage
            message={promotionalBadge.options?.message || {}}
            defaultMessage={defaultPresetData.options?.message || {}}
            shopDefaultMessage={shopDefaultData.textStyle || {}}
            handleLeftMenuSelection={handleLeftMenuSelection}
            isPresetEditor
            onShowHide={
              promotionalBadge.options?.message?.canBeHidden
                ? () => handleComponentVisability('message')
                : undefined
            }
            setMessage={(data) =>
              updatePromotionalBadge({
                ...promotionalBadge,
                options: {
                  ...promotionalBadge.options,
                  message: data,
                },
              })
            }
          />
        );
      case WidgetListOptionsDtoEnum.BADGE:
        return (
          <PromotionalBadgeBadge
            badge={promotionalBadge.options?.badge || {}}
            defaultBadge={defaultPresetData?.options?.badge || {}}
            shopDefaultData={shopDefaultData}
            handleLeftMenuSelection={handleLeftMenuSelection}
            isPresetEditor
            setBadge={(data) =>
              updatePromotionalBadge({
                ...promotionalBadge,
                options: {
                  ...promotionalBadge.options,
                  badge: data,
                },
              })
            }
            onShowHide={
              promotionalBadge.options?.badge?.canBeHidden
                ? () => handleComponentVisability('badge')
                : undefined
            }
          />
        );
    }
  }, [
    selectedOptionPath,
    shopDefaultData,
    promotionalBadge,
    defaultPresetData,
    updatePromotionalBadge,
    handleLeftMenuSelection,
    handleComponentVisability,
  ]);
  return <div>{promotionalBadgeOptions}</div>;
};

export default PresetPromotionalBadge;
