import {
  PromotionSetupDtoRead,
  PromotionWidgetSetupMultiLevelDtoPromotionCodeFieldPromotionDtoPromotionCodeFieldFirstLevelPromotionDtoPromotionCodeFieldPromotionEntryTypeDtoRead as PromotionWidgetSetupDtoPromotionCodeFieldDtoRead,
  PresetsSetupDto,
  CartTextSystemDefaultsDto,
  OfferWidgetSetupDtoCartTextOfferDtoRead,
  CartTextGeneralOfferDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import React, { useCallback, useMemo } from 'react';
import { SelectedOptionPath } from '../../../../RightSideBarWrapper';
import { WidgetTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { getLastSelectedOption } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import { WidgetListTypeDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import PresetCartTextGeneral from '../../../EditPresetRightBar/components/PresetCartText/components/PresetCartTextGeneral/PresetCartTextGeneral';
import { WidgetOptionHeader } from '../../../components';
import { useI18n } from '@shopify/react-i18n';
import { Link, Text } from '@shopify/polaris';

type PromotionCartTextProps = {
  editPromotionState: PromotionSetupDtoRead;
  defaultPromotionData: PromotionSetupDtoRead;
  shopDefaultData: CartTextSystemDefaultsDto;
  selectedOptionPath: SelectedOptionPath;
  type:
  | WidgetListTypeDtoEnum.CART_PAGE_CART_TEXT
  | WidgetListTypeDtoEnum.DRAWER_CART_PAGE_CART_TEXT;
  updateEditPromotionState: (
    field: keyof PromotionSetupDtoRead,
    data: PromotionSetupDtoRead[keyof PromotionSetupDtoRead],
    presetSetup?: PresetsSetupDto
  ) => void;
  filterSupportedWidgets: (value: WidgetTypeDtoEnum) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const PromotionCartText: React.FC<PromotionCartTextProps> = ({
  type,
  editPromotionState,
  defaultPromotionData,
  shopDefaultData,
  selectedOptionPath,
  updateEditPromotionState,
  filterSupportedWidgets,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();
  const cartText = editPromotionState?.[type];
  const presetSetup = editPromotionState.presetSetup;
  const widgetPreset = presetSetup?.[type];
  const defaultPresetSetup = defaultPromotionData?.presetSetup?.[type];
  const offersData = editPromotionState.offers?.[type];
  const defaultOffersData = defaultPromotionData.offers?.[type];

  const updateOfferGeneral = useCallback(
    (data: CartTextGeneralOfferDto, offerId: string) =>
      updateEditPromotionState(
        'offers',
        {
          ...editPromotionState.offers,
          [type]: editPromotionState.offers?.[type]?.map((offerData) => {
            if (offerData?.offerId === offerId) {
              return {
                ...offerData,
                setup: {
                  ...offerData.setup,
                  general: {
                    ...offerData.setup?.general,
                    text: {
                      ...offerData.setup?.general?.text,
                      message: data.text?.message,
                    },
                  },
                },
              };
            }
            return offerData;
          }),
        },
        {
          ...presetSetup,
          [type]: {
            ...presetSetup?.[type],
            general: {
              ...presetSetup?.[type]?.general,
              background: data.background,
              cornerRadius: data.cornerRadius,
              textStyle: data.text?.style,
              size: data.size,
            },
          },
        }
      ),

    [updateEditPromotionState, editPromotionState]
  );

  const updateOfferVisability = useCallback(
    (offerId: string) =>
      updateEditPromotionState('offers', {
        ...editPromotionState.offers,
        [type]: editPromotionState.offers?.[type]?.map((offerData) => {
          if (offerData?.offerId === offerId) {
            return { ...offerData, isHidden: !offerData.isHidden };
          }
          return offerData;
        }),
      }),

    [updateEditPromotionState, editPromotionState]
  );

  const promotionCodeOptions = useMemo(() => {
    const selectedOption = getLastSelectedOption(selectedOptionPath);
    const defaultLanguage = editPromotionState.offers?.defaultLanguage || '';
    const offerData = offersData?.find(
      (offer) => offer.offerId === selectedOption?.offerId
    ) as OfferWidgetSetupDtoCartTextOfferDtoRead;
    const defaultOfferData = defaultOffersData?.find(
      (offer) => offer.offerId === selectedOption?.offerId
    ) as OfferWidgetSetupDtoCartTextOfferDtoRead;
    switch (true) {
      case selectedOption?.name === type:
        return (
          <div className='EmptyRightSideBanner'>
            <WidgetOptionHeader
              name={i18n.translate(selectedOption?.name || '')}
              handleLeftMenuSelection={handleLeftMenuSelection}
              onRemove={
                cartText?.canBeRemoved
                  ? () =>
                    filterSupportedWidgets(
                      type === WidgetListTypeDtoEnum.CART_PAGE_CART_TEXT
                        ? WidgetTypeDtoEnum.CART_PAGE_CART_TEXT
                        : WidgetTypeDtoEnum.DRAWER_CART_PAGE_CART_TEXT
                    )
                  : undefined
              }
            />
            <div className='RightSideSection'>
              <Text as='p' tone='subdued'>
                {i18n.translate('AddMessageBelow')}
                <Link>{i18n.translate('LearnMore')}</Link>
              </Text>
            </div>
            <div className='RightSideSection'>
              <Text as='p' tone='subdued'>
                {i18n.translate('NoCustomizable')}
              </Text>
            </div>
          </div>
        );
      case !!selectedOption?.offerId:
        return (
          <PresetCartTextGeneral
            key={offerData.offerId}
            general={offerData.setup?.general || {}}
            presetSetup={widgetPreset?.general || {}}
            defaultGeneral={{
              ...defaultPresetSetup?.general,
              text: {
                ...defaultOfferData.setup?.general?.text,
                style: defaultPresetSetup?.general?.textStyle,
              },
            }}
            pageType={type}
            defaultLanguage={defaultLanguage}
            shopDefaultGeneral={shopDefaultData || {}}
            offerId={offerData?.offerId}
            offerName={offerData.title || ''}
            setGeneral={(data) =>
              updateOfferGeneral(data, offerData?.offerId as string)
            }
            onShowHide={
              offerData?.canBeHidden
                ? () => updateOfferVisability(offerData?.offerId as string)
                : undefined
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
    }
  }, [
    selectedOptionPath,
    widgetPreset,
    offersData,
    defaultOffersData,
    updateOfferVisability,
    handleLeftMenuSelection,
  ]);

  return <div className='PromotionCartText'>{promotionCodeOptions}</div>;
};

export default PromotionCartText;
