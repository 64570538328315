import { useCallback } from 'react';
import { NumberFormatValues } from 'react-number-format';

const useMaxAllowedValueRule = (percentage?: boolean) => {
  const maxAllowedValueRule = useCallback((data: NumberFormatValues) => {
    if (!data.floatValue) {
      return true;
    }
    if (percentage) {
      return data.floatValue <= 100;
    } else {
      return data.floatValue <= 2147483647;
    }
  }, []);
  return maxAllowedValueRule;
};
export default useMaxAllowedValueRule;
