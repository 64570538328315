import {
  OfferWidgetSetupDtoPromotionalBadgeOfferDtoRead,
  PromotionWidgetSetupDtoPromotionalBadgePromotionDtoRead,
  PromotionalBadgePresetDtoRead,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { LineWeightTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';

export type PromotionWidgetSetupDtoPromotionalBadgePromotionDtoReadWithOffers =
  PromotionWidgetSetupDtoPromotionalBadgePromotionDtoRead & {
    offers?: OfferWidgetSetupDtoPromotionalBadgeOfferDtoRead[] | null;
  };

export const promotionalBadgeMergeFunc = (
  widget: PromotionWidgetSetupDtoPromotionalBadgePromotionDtoReadWithOffers,
  setup?: PromotionalBadgePresetDtoRead
) => {
  const {
    isShopLevelWidget,
    canBeDragged,
    canBeRemoved,
    offers,
    ...newWidget
  } = widget;
  const { options: optionsSetup } = setup?.options ? setup : { options: null };

  return {
    ...newWidget,
    availableEntries: undefined,
    ...(optionsSetup
      ? {
          offers: offers?.map((offer) => {
            const {
              canBeDragged,
              canBeRemoved,
              parentKey,
              canBeHidden,
              isHidden,
              setup,
              ...newOffer
            } = offer;

            return {
              ...(canBeHidden && { isHidden }),
              ...newOffer,
              setup: {
                ...setup,
                availableEntries: undefined,
                options: {
                  message: {
                    ...setup?.options?.message,
                    style: {
                      ...optionsSetup?.message?.style,
                      style: {
                        ...optionsSetup?.message?.style?.style,
                        fontSize: {
                          ...optionsSetup?.message?.style?.style?.fontSize,
                          mobile: optionsSetup?.message?.style?.style
                            ?.differentOnMobile
                            ? optionsSetup?.message?.style?.style?.fontSize
                                ?.mobile
                            : undefined,
                        },
                        lineHeight: {
                          ...optionsSetup?.message?.style?.style?.lineHeight,
                          mobile: optionsSetup?.message?.style?.style
                            ?.differentOnMobile
                            ? optionsSetup?.message?.style?.style?.lineHeight
                                ?.mobile
                            : undefined,
                        },
                        fontWeight: {
                          ...optionsSetup?.message?.style?.style?.fontWeight,
                          mobile: optionsSetup?.message?.style?.style
                            ?.differentOnMobile
                            ? optionsSetup?.message?.style?.style?.fontWeight
                                ?.mobile
                            : undefined,
                        },
                      },
                    },
                    message: {
                      ...setup?.options?.message?.message,
                      settings: undefined,
                    },
                    isHidden: setup?.options?.message?.canBeHidden
                      ? setup?.options?.message.isHidden
                      : undefined,
                    canBeDragged: undefined,
                    canBeRemoved: undefined,
                    canBeHidden: undefined,
                  },
                  badge: {
                    ...setup?.options?.badge,
                    isHidden: setup?.options?.badge?.canBeHidden
                      ? setup?.options?.badge.isHidden
                      : undefined,
                    canBeDragged: undefined,
                    canBeRemoved: undefined,
                    canBeHidden: undefined,
                    primaryStyle: {
                      ...optionsSetup.badge?.primaryStyle,
                      background: {
                        ...optionsSetup.badge?.primaryStyle?.background,
                        border: {
                          ...optionsSetup.badge?.primaryStyle?.background
                            ?.border,
                          value: {
                            ...optionsSetup.badge?.primaryStyle?.background
                              ?.border?.value,
                            lineWeight: {
                              ...optionsSetup.badge?.primaryStyle?.background
                                ?.border?.value?.lineWeight,
                              customValue:
                                optionsSetup.badge?.primaryStyle?.background
                                  ?.border?.value?.lineWeight?.type ===
                                LineWeightTypeDtoEnum.CUSTOM
                                  ? optionsSetup.badge?.primaryStyle?.background
                                      ?.border?.value?.lineWeight?.customValue
                                  : undefined,
                            },
                          },
                        },
                      },
                      cornerRadius: {
                        ...optionsSetup.badge?.primaryStyle?.cornerRadius,
                        customValue:
                          optionsSetup.badge?.primaryStyle?.cornerRadius
                            ?.type === LineWeightTypeDtoEnum.CUSTOM
                            ? optionsSetup.badge?.primaryStyle.cornerRadius
                                .customValue
                            : undefined,
                      },
                      stitch: {
                        ...optionsSetup.badge?.primaryStyle?.stitch,
                        value: {
                          ...optionsSetup.badge?.primaryStyle?.stitch?.value,
                          lineWeight: {
                            ...optionsSetup.badge?.primaryStyle?.stitch?.value
                              ?.lineWeight,
                            customValue:
                              optionsSetup.badge?.primaryStyle?.stitch?.value
                                ?.lineWeight?.type ===
                              LineWeightTypeDtoEnum.CUSTOM
                                ? optionsSetup.badge?.primaryStyle?.stitch
                                    ?.value?.lineWeight?.customValue
                                : undefined,
                          },
                        },
                      },
                    },
                    secondaryStyle: {
                      ...optionsSetup.badge?.secondaryStyle,
                      background: {
                        ...optionsSetup.badge?.secondaryStyle?.background,
                        border: {
                          ...optionsSetup.badge?.secondaryStyle?.background
                            ?.border,
                          value: {
                            ...optionsSetup.badge?.secondaryStyle?.background
                              ?.border?.value,
                            lineWeight: {
                              ...optionsSetup.badge?.secondaryStyle?.background
                                ?.border?.value?.lineWeight,
                              customValue:
                                optionsSetup.badge?.secondaryStyle?.background
                                  ?.border?.value?.lineWeight?.type ===
                                LineWeightTypeDtoEnum.CUSTOM
                                  ? optionsSetup.badge?.secondaryStyle
                                      ?.background?.border?.value?.lineWeight
                                      ?.customValue
                                  : undefined,
                            },
                          },
                        },
                      },
                      cornerRadius: {
                        ...optionsSetup.badge?.secondaryStyle?.cornerRadius,
                        customValue:
                          optionsSetup.badge?.secondaryStyle?.cornerRadius
                            ?.type === LineWeightTypeDtoEnum.CUSTOM
                            ? optionsSetup.badge?.secondaryStyle.cornerRadius
                                .customValue
                            : undefined,
                      },
                      stitch: {
                        ...optionsSetup.badge?.secondaryStyle?.stitch,
                        value: {
                          ...optionsSetup.badge?.secondaryStyle?.stitch?.value,
                          lineWeight: {
                            ...optionsSetup.badge?.secondaryStyle?.stitch?.value
                              ?.lineWeight,
                            customValue:
                              optionsSetup.badge?.secondaryStyle?.stitch?.value
                                ?.lineWeight?.type ===
                              LineWeightTypeDtoEnum.CUSTOM
                                ? optionsSetup.badge?.secondaryStyle?.stitch
                                    ?.value?.lineWeight?.customValue
                                : undefined,
                          },
                        },
                      },
                    },
                    selectedStyleSet: optionsSetup.badge?.selectedStyleSet,
                  },
                },
              },
            };
          }),
        }
      : {
          options: {
            message: {
              ...(newWidget as PromotionalBadgePresetDtoRead).options?.message,
              style: {
                ...(newWidget as PromotionalBadgePresetDtoRead).options?.message
                  ?.style,
                style: {
                  ...(newWidget as PromotionalBadgePresetDtoRead).options
                    ?.message?.style?.style,
                  fontSize: {
                    ...(newWidget as PromotionalBadgePresetDtoRead).options
                      ?.message?.style?.style?.fontSize,
                    mobile: (newWidget as PromotionalBadgePresetDtoRead).options
                      ?.message?.style?.style?.differentOnMobile
                      ? (newWidget as PromotionalBadgePresetDtoRead).options
                          ?.message?.style?.style?.fontSize?.mobile
                      : undefined,
                  },
                  lineHeight: {
                    ...(newWidget as PromotionalBadgePresetDtoRead).options
                      ?.message?.style?.style?.lineHeight,
                    mobile: (newWidget as PromotionalBadgePresetDtoRead).options
                      ?.message?.style?.style?.differentOnMobile
                      ? (newWidget as PromotionalBadgePresetDtoRead).options
                          ?.message?.style?.style?.lineHeight?.mobile
                      : undefined,
                  },
                  fontWeight: {
                    ...(newWidget as PromotionalBadgePresetDtoRead).options
                      ?.message?.style?.style?.fontWeight,
                    mobile: (newWidget as PromotionalBadgePresetDtoRead).options
                      ?.message?.style?.style?.differentOnMobile
                      ? (newWidget as PromotionalBadgePresetDtoRead).options
                          ?.message?.style?.style?.fontWeight?.mobile
                      : undefined,
                  },
                },
              },
              isHidden: setup?.options?.message?.canBeHidden
                ? setup?.options?.message.isHidden
                : undefined,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
            },
            badge: {
              ...(newWidget as PromotionalBadgePresetDtoRead).options?.badge,
              isHidden: setup?.options?.badge?.canBeHidden
                ? setup?.options?.badge.isHidden
                : undefined,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              primaryStyle: {
                ...(newWidget as PromotionalBadgePresetDtoRead).options?.badge
                  ?.primaryStyle,
                background: {
                  ...(newWidget as PromotionalBadgePresetDtoRead).options?.badge
                    ?.primaryStyle?.background,
                  border: {
                    ...(newWidget as PromotionalBadgePresetDtoRead).options
                      ?.badge?.primaryStyle?.background?.border,
                    value: {
                      ...(newWidget as PromotionalBadgePresetDtoRead).options
                        ?.badge?.primaryStyle?.background?.border?.value,
                      lineWeight: {
                        ...(newWidget as PromotionalBadgePresetDtoRead).options
                          ?.badge?.primaryStyle?.background?.border?.value
                          ?.lineWeight,
                        customValue:
                          (newWidget as PromotionalBadgePresetDtoRead).options
                            ?.badge?.primaryStyle?.background?.border?.value
                            ?.lineWeight?.type === LineWeightTypeDtoEnum.CUSTOM
                            ? (newWidget as PromotionalBadgePresetDtoRead)
                                .options?.badge?.primaryStyle?.background
                                ?.border?.value?.lineWeight?.customValue
                            : undefined,
                      },
                    },
                  },
                },
                cornerRadius: {
                  ...(newWidget as PromotionalBadgePresetDtoRead).options?.badge
                    ?.primaryStyle?.cornerRadius,
                  customValue:
                    (newWidget as PromotionalBadgePresetDtoRead).options?.badge
                      ?.primaryStyle?.cornerRadius?.type ===
                    LineWeightTypeDtoEnum.CUSTOM
                      ? (newWidget as PromotionalBadgePresetDtoRead).options
                          ?.badge?.primaryStyle?.cornerRadius?.customValue
                      : undefined,
                },
                stitch: {
                  ...(newWidget as PromotionalBadgePresetDtoRead).options?.badge
                    ?.primaryStyle?.stitch,
                  value: {
                    ...(newWidget as PromotionalBadgePresetDtoRead).options
                      ?.badge?.primaryStyle?.stitch?.value,
                    lineWeight: {
                      ...(newWidget as PromotionalBadgePresetDtoRead).options
                        ?.badge?.primaryStyle?.stitch?.value?.lineWeight,
                      customValue:
                        (newWidget as PromotionalBadgePresetDtoRead).options
                          ?.badge?.primaryStyle?.stitch?.value?.lineWeight
                          ?.type === LineWeightTypeDtoEnum.CUSTOM
                          ? (newWidget as PromotionalBadgePresetDtoRead).options
                              ?.badge?.primaryStyle?.stitch?.value?.lineWeight
                              ?.customValue
                          : undefined,
                    },
                  },
                },
              },
              secondaryStyle: {
                ...(newWidget as PromotionalBadgePresetDtoRead).options?.badge
                  ?.secondaryStyle,
                background: {
                  ...(newWidget as PromotionalBadgePresetDtoRead).options?.badge
                    ?.secondaryStyle?.background,
                  border: {
                    ...(newWidget as PromotionalBadgePresetDtoRead).options
                      ?.badge?.secondaryStyle?.background?.border,
                    value: {
                      ...(newWidget as PromotionalBadgePresetDtoRead).options
                        ?.badge?.secondaryStyle?.background?.border?.value,
                      lineWeight: {
                        ...(newWidget as PromotionalBadgePresetDtoRead).options
                          ?.badge?.secondaryStyle?.background?.border?.value
                          ?.lineWeight,
                        customValue:
                          (newWidget as PromotionalBadgePresetDtoRead).options
                            ?.badge?.secondaryStyle?.background?.border?.value
                            ?.lineWeight?.type === LineWeightTypeDtoEnum.CUSTOM
                            ? (newWidget as PromotionalBadgePresetDtoRead)
                                .options?.badge?.secondaryStyle?.background
                                ?.border?.value?.lineWeight?.customValue
                            : undefined,
                      },
                    },
                  },
                },
                cornerRadius: {
                  ...(newWidget as PromotionalBadgePresetDtoRead).options?.badge
                    ?.secondaryStyle?.cornerRadius,
                  customValue:
                    (newWidget as PromotionalBadgePresetDtoRead).options?.badge
                      ?.secondaryStyle?.cornerRadius?.type ===
                    LineWeightTypeDtoEnum.CUSTOM
                      ? (newWidget as PromotionalBadgePresetDtoRead).options
                          ?.badge?.secondaryStyle?.cornerRadius?.customValue
                      : undefined,
                },
                stitch: {
                  ...(newWidget as PromotionalBadgePresetDtoRead).options?.badge
                    ?.secondaryStyle?.stitch,
                  value: {
                    ...(newWidget as PromotionalBadgePresetDtoRead).options
                      ?.badge?.secondaryStyle?.stitch?.value,
                    lineWeight: {
                      ...(newWidget as PromotionalBadgePresetDtoRead).options
                        ?.badge?.secondaryStyle?.stitch?.value?.lineWeight,
                      customValue:
                        (newWidget as PromotionalBadgePresetDtoRead).options
                          ?.badge?.secondaryStyle?.stitch?.value?.lineWeight
                          ?.type === LineWeightTypeDtoEnum.CUSTOM
                          ? (newWidget as PromotionalBadgePresetDtoRead).options
                              ?.badge?.secondaryStyle?.stitch?.value?.lineWeight
                              ?.customValue
                          : undefined,
                    },
                  },
                },
              },
            },
          },
        }),
  };
};
