import { Range } from '@shopify/polaris';
import { sleep } from 'core/utils';
import { ReportChartType } from 'features/reporting';
import { useCallback, useEffect, useState } from 'react';

const mockedData: ReportChartType = {
  Views: {
    Labels: [
      'May 02',
      'May 03',
      'May 04',
      'May 05',
      'May 06',
      'May 07',
      'May 08',
      'May 09',
    ],
    Impressions: [572, 598, 317, 172, 409, 340, 279, 173],
    Activations: [572, 598, 316, 170, 406, 340, 279, 172],
    Claimed: [2, 4, 2, 3, 2, 2, 1, 1],
    AddToCarts: [12, 14, 9, 6, 8, 9, 6, 3],
    EmailCaptures: [null, null, null, null, null, null, null, null],
    Revenue: [175.47, 568.13, 216.11, 255.66, 261.26, 167.41, 95.28, 85.86],
    MaxLeftAxis: 662,
    MaxRightAxis: 634,
  },
  Conversions: {
    Labels: [
      'FREE CASTOR OIL - MA... [959.71 USD]',
      'Mother-s Day - Free ... [865.47 USD]',
      'Free gift [0 USD]',
      'Giveaway [0 USD]',
      'Giveaway - ES - Apri... [0 USD]',
    ],
    ClaimedPercentages: [0.52, 0.67, 0, 0, 0],
    ActivatedPercentages: null,
    AddToCartPercentages: [2.43, 2.32, 0, 0, 0],
  },
  ActivePromotions: {
    ManualActivePromotions: 0,
    ManualInactivePromotions: 0,
    ScheduledLivePromotions: 0,
    ScheduledPendingPromotions: 0,
  },
  KPIs: {
    Impressions: 2860,
    Activated: 2853,
    AddToCarts: 67,
    Checkouts: 17,
    InitiatedCheckouts: 37,
    EmailsCaptured: 0,
    ImpressionsLastPeriod: 1942,
    ActivatedLastPeriod: 1942,
    CheckoutsLastPeriod: 15,
    InitiatedCheckoutsLastPeriod: 15,
    AddToCartsLastPeriod: 59,
    EmailsCapturedLastPeriod: 0,
    ImpressionsTrend: 47.27,
    ActivatedTrend: 46.91,
    CheckoutsTrend: 13.33,
    InitiatedCheckoutsTrend: 146.67,
    AddToCartsTrend: 13.56,
    EmailsCapturedTrend: 0,
    PeriodStart: '2022-05-02T00:00:00+00:00',
    PeriodEnd: '2022-05-09T23:59:59+00:00',
    LastPeriodStart: '2022-04-24T00:00:01+00:00',
    LastPeriodEnd: '2022-05-02T00:00:00+00:00',
    ActivatedConversionRate: 99.76,
    CheckoutsConversionRate: 0.59,
    InitiatedCheckoutsConversionRate: 1.29,
    AddToCartsConversionRate: 2.34,
    ActivatedConversionRateLastPeriod: 100,
    CheckoutsConversionRateLastPeriod: 0.77,
    InitiatedCheckoutsConversionRateLastPeriod: 0.77,
    AddToCartsConversionRateLastPeriod: 3.04,
    ActivatedConversionRateTrend: -0.24,
    CheckoutsConversionRateTrend: -0.18,
    InitiatedCheckoutsConversionRateTrend: 0.52,
    AddToCartsConversionRateTrend: -0.7,
    OrderAmountTrend: 21.5,
    OrderAmount: 1825,
    OrderAmountLastPeriod: 1502,
    CurrencySymbol: '$',
  },
  Usage: {
    QuotaNotUsedColor: 'rgba(85, 99, 193, 1)',
    QuotaNotUsedHoverColor: '#3f4eae',
    QuotaUsedPercentage: 2,
    QuotaNotUsedPercentage: 98,
    ImpressionsThisMonth: 3328,
    UsageChargeDue: 0,
    WarnQuotaExhausted: false,
    WarnQuotaAlmostExhausted: false,
  },
  Trial: {
    DaysLeft: 0,
    UsedPercentage: 100,
    NotUsedPercentage: 0,
    NotUsedColor: '#ff4039',
    NotUsedHoverColor: '#ff3838',
    ImpressionsThisMonth: 3328,
    OverImpressionsQuota: false,
  },
  TopOrders: {
    Labels: [
      'Mother-s Day - Free ...',
      'FREE CASTOR OIL - MA...',
      'Free gift',
      'Giveaway',
      'Giveaway - ES - Apri...',
    ],
    Orders: [9, 8, 0, 0, 0],
    MaxOrders: 9,
  },
  TopRevenue: {
    Labels: [
      'FREE CASTOR OIL - MA...',
      'Mother-s Day - Free ...',
      'Free gift',
      'Giveaway',
      'Giveaway - ES - Apri...',
    ],
    Revenue: [959.71, 865.47, 0, 0, 0],
    MaxRevenue: 959.71,
    CurrencySymbol: '$',
  },
};

export const usePromotionActivityData = (dateRangeInitial?: Range) => {
  const [data, setData] = useState<ReportChartType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<Range | undefined>(
    dateRangeInitial
  );

  const fetchData = useCallback(
    (dateRange?: Range) => {
      setDateRange(dateRange);
    },
    [setDateRange]
  );

  useEffect(() => {
    (async () => {
      try {
        setData(undefined);
        setIsLoading(true);
        await sleep(500);
        setData(mockedData);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    })();
  }, [dateRange]);

  return { data, isLoading, fetchData };
};
