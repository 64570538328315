import React, { useCallback, useMemo } from 'react';
import {
  AnnouncementBarFirstLevelPresetDto,
  AnnouncementBarFirstLevelPromotionDtoRead,
  AnnouncementBarGeneralOfferDto,
  AnnouncementBarIconOfferDto,
  AnnouncementBarImageOfferDto,
  AnnouncementBarMessageOfferDto,
  AnnouncementBarOfferFirstLevelDto,
  AnnouncementBarPrimaryBannerGeneralPromotionDto,
  AnnouncementBarSecondaryBannerGeneralPromotionDto,
  AnnouncementBarSystemDefaultsDto,
  OfferWidgetSetupDtoAnnouncementBarOfferDtoRead,
  PresetsSetupDto,
  PromotionSetupDtoRead,
} from 'core/api/adminWidgets/adminWidgetsApi';
import AnnouncementBarOffer from './components/AnnouncementBarOffer/AnnouncementBarOffer';
import AnnouncementBarGeneral from './components/AnnouncementBarGeneral/AnnouncementBarGeneral';
import AnnouncementBarIcon from '../../../EditPresetRightBar/components/PresetAnnouncementBar/components/AnnouncementBarIcon/AnnouncementBarIcon';
import AnnouncementBarImage from '../../../EditPresetRightBar/components/PresetAnnouncementBar/components/AnnouncementBarImage/AnnouncementBarImage';
import AnnouncementBarMessage from '../../../EditPresetRightBar/components/PresetAnnouncementBar/components/AnnouncementBarMessage/AnnouncementBarMessage';
import AnnoucementBarBanner from '../../../EditPresetRightBar/components/PresetAnnouncementBar/components/AnnoucementBarBanner/AnnoucementBarBanner';
import { SelectedOptionPath } from '../../../../RightSideBarWrapper';
import {
  AnnouncementBarOfferPositionDtoEnum,
  WidgetTypeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import { useI18n } from '@shopify/react-i18n';
import { getLastSelectedOption } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import { Text } from '@shopify/polaris';
import { WidgetOptionHeader } from '../../../components';
import {
  WidgetListOptionsDtoEnum,
  WidgetListTypeDtoEnum,
} from 'features/settings/components/EditPresetSkeleton/enums/enums';

type PromotionAnnouncementBarProps = {
  editPromotionState: PromotionSetupDtoRead;
  defaultPromotionData: PromotionSetupDtoRead;
  shopDefaultData: AnnouncementBarSystemDefaultsDto;
  selectedOptionPath: SelectedOptionPath;
  updateEditPromotionState: (
    field: keyof PromotionSetupDtoRead,
    data: PromotionSetupDtoRead[keyof PromotionSetupDtoRead],
    presetSetup?: PresetsSetupDto
  ) => void;
  filterSupportedWidgets: (value: WidgetTypeDtoEnum) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const PromotionAnnouncementBar: React.FC<PromotionAnnouncementBarProps> = ({
  editPromotionState,
  defaultPromotionData,
  selectedOptionPath,
  shopDefaultData,
  updateEditPromotionState,
  filterSupportedWidgets,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();
  const { announcementBar, presetSetup } = editPromotionState;
  const offersData = editPromotionState.offers?.announcementBar;
  const defaultOffersData = defaultPromotionData.offers?.announcementBar;
  const defaultAnnouncementBar = defaultPromotionData.announcementBar;
  const defaultPresetSetup = defaultPromotionData.presetSetup?.announcementBar;
  const widgetPreset = presetSetup?.announcementBar;

  const updateOffer = useCallback(
    (data: OfferWidgetSetupDtoAnnouncementBarOfferDtoRead, offerId: string) => {
      updateEditPromotionState('offers', {
        ...editPromotionState.offers,
        announcementBar: editPromotionState.offers?.announcementBar?.map(
          (offerData) => {
            if (offerData?.offerId === offerId) {
              return {
                ...data,
                canBeDragged:
                  data.parentKey !==
                  AnnouncementBarOfferPositionDtoEnum.ANNOUNCEMENT_BAR_PRIMARY_BANNER
                    ? true
                    : false,
              };
            }
            if (
              offerData.parentKey === data.parentKey &&
              offerData.parentKey !==
                AnnouncementBarOfferPositionDtoEnum.ANNOUNCEMENT_BAR_PRIMARY_BANNER
            ) {
              const prevOfferPosition =
                editPromotionState.offers?.announcementBar?.find(
                  (offer) => offer.offerId === offerId
                )?.setup?.general?.position;
              return {
                ...offerData,
                parentKey: prevOfferPosition,
                canBeDragged:
                  prevOfferPosition ===
                  AnnouncementBarOfferPositionDtoEnum.ANNOUNCEMENT_BAR_PRIMARY_BANNER
                    ? false
                    : true,
                setup: {
                  ...offerData.setup,
                  general: {
                    ...offerData.setup?.general,
                    position: prevOfferPosition,
                  },
                },
              };
            }
            return offerData;
          }
        ),
      });
    },
    [updateEditPromotionState, editPromotionState]
  );

  const updateOfferComponent = useCallback(
    (
      field: keyof AnnouncementBarOfferFirstLevelDto,
      data: AnnouncementBarOfferFirstLevelDto[keyof AnnouncementBarOfferFirstLevelDto],
      offerId: string,
      preset?: AnnouncementBarFirstLevelPresetDto
    ) =>
      updateEditPromotionState(
        'offers',
        {
          ...editPromotionState.offers,
          announcementBar: editPromotionState.offers?.announcementBar?.map(
            (offerData) => {
              if (offerData?.offerId === offerId) {
                return {
                  ...offerData,
                  setup: {
                    ...offerData.setup,
                    options: {
                      ...offerData.setup?.options,
                      [field]: data,
                    },
                  },
                };
              }
              return offerData;
            }
          ),
        },
        {
          ...presetSetup,
          announcementBar: {
            ...presetSetup?.announcementBar,
            options: preset,
          },
        }
      ),
    [updateEditPromotionState, editPromotionState]
  );

  const updateBannerType = useCallback(
    (
      option: keyof AnnouncementBarFirstLevelPromotionDtoRead,
      data: AnnouncementBarFirstLevelPromotionDtoRead[keyof AnnouncementBarFirstLevelPromotionDtoRead],
      preset?: AnnouncementBarFirstLevelPresetDto
    ) => {
      updateEditPromotionState(
        'announcementBar',
        {
          ...announcementBar,
          options: {
            ...announcementBar?.options,
            [option]: data,
          },
        },
        {
          ...presetSetup,
          announcementBar: {
            ...presetSetup?.announcementBar,
            options: preset,
          },
        }
      );
    },
    [announcementBar, updateEditPromotionState, presetSetup]
  );

  const updateBannerTypeVisability = useCallback(
    (type: 'primaryBanner' | 'secondaryBanner') =>
      announcementBar?.options?.[type]?.canBeHidden
        ? () =>
            updateBannerType(type, {
              ...announcementBar?.options?.[type],
              isHidden: !announcementBar?.options?.[type]?.isHidden,
            })
        : undefined,
    [announcementBar, updateBannerType]
  );
  const updateOfferComponentVisability = useCallback(
    (
      option: keyof AnnouncementBarOfferFirstLevelDto,
      offerData: OfferWidgetSetupDtoAnnouncementBarOfferDtoRead
    ) =>
      offerData?.setup?.options?.[option]?.canBeHidden
        ? () =>
            updateOfferComponent(
              option,
              {
                ...offerData.setup?.options?.[option],
                isHidden: !offerData.setup?.options?.[option]?.isHidden,
              },
              offerData?.offerId as string
            )
        : undefined,
    [updateOfferComponent]
  );

  const announcementBarOptions = useMemo(() => {
    const selectedOption = getLastSelectedOption(selectedOptionPath);
    const selectedOffer = selectedOptionPath?.selectedOption?.selectedOption;
    const offerData = offersData?.find(
      (offer) => offer.offerId === selectedOffer?.offerId
    ) as OfferWidgetSetupDtoAnnouncementBarOfferDtoRead;

    const defaultOfferData = defaultOffersData?.find(
      (offer) => offer.offerId === selectedOffer?.offerId
    ) as OfferWidgetSetupDtoAnnouncementBarOfferDtoRead;

    const currentBanner =
      offerData?.parentKey ===
      AnnouncementBarOfferPositionDtoEnum.ANNOUNCEMENT_BAR_PRIMARY_BANNER
        ? 'primaryBanner'
        : 'secondaryBanner';

    const updateOfferGeneral = (data: AnnouncementBarGeneralOfferDto) => {
      updateOffer(
        {
          ...offerData,
          parentKey: data.position,
          setup: {
            ...offerData?.setup,
            general: data,
          },
        },
        offerData?.offerId as string
      );
    };
    const updatePrimaryBanner = (
      data: AnnouncementBarPrimaryBannerGeneralPromotionDto
    ) => {
      updateBannerType(
        'primaryBanner',
        announcementBar?.options?.primaryBanner,
        {
          ...widgetPreset?.options,
          primaryBanner: {
            ...widgetPreset?.options?.primaryBanner,
            general: data,
          },
        }
      );
    };
    const updateSecondaryBanner = (
      data: AnnouncementBarSecondaryBannerGeneralPromotionDto
    ) => {
      updateBannerType(
        'secondaryBanner',
        announcementBar?.options?.secondaryBanner,
        {
          ...widgetPreset?.options,
          secondaryBanner: {
            ...widgetPreset?.options?.secondaryBanner,
            general: {
              ...widgetPreset?.options?.secondaryBanner?.general,
              background: data.background,
            },
          },
        }
      );
    };

    const updateIconComponent = (data: AnnouncementBarIconOfferDto) => {
      updateOfferComponent(
        'icon',
        { ...data, icon: { iconId: data.icon?.iconId } },
        offerData?.offerId as string,
        {
          ...widgetPreset?.options,
          [currentBanner]: {
            ...widgetPreset?.options?.[currentBanner],
            options: {
              ...widgetPreset?.options?.[currentBanner]?.options,
              icon: {
                ...widgetPreset?.options?.[currentBanner]?.options?.icon,
                icon: {
                  ...widgetPreset?.options?.[currentBanner]?.options?.icon
                    ?.icon,
                  color: data.icon?.color,
                },
              },
            },
          },
        }
      );
    };

    const updateImageComponent = (data: AnnouncementBarImageOfferDto) => {
      updateOfferComponent(
        'image',
        {
          ...offerData.setup?.options?.image,
          image: { imageId: data.image?.imageId },
        },
        offerData?.offerId as string,
        {
          ...widgetPreset?.options,
          [currentBanner]: {
            ...widgetPreset?.options?.[currentBanner],
            options: {
              ...widgetPreset?.options?.[currentBanner]?.options,
              image: {
                ...data,
                image: {
                  ...data.image,
                  imageId:
                    widgetPreset?.options?.[currentBanner]?.options?.image
                      ?.image?.imageId,
                },
              },
            },
          },
        }
      );
    };
    const updateMessageComponent = (data: AnnouncementBarMessageOfferDto) => {
      updateOfferComponent(
        'message',
        {
          ...offerData.setup?.options?.message,
          text: data.text,
          preLaunchText: data.preLaunchText,
        },
        offerData?.offerId as string,
        {
          ...widgetPreset?.options,
          [currentBanner]: {
            ...widgetPreset?.options?.[currentBanner],
            options: {
              ...widgetPreset?.options?.[currentBanner]?.options,
              message: {
                ...widgetPreset?.options?.[currentBanner]?.options?.message,
                style: data.style,
              },
            },
          },
        }
      );
    };

    switch (true) {
      case selectedOption?.name === WidgetListTypeDtoEnum.ANNOUNCEMENT_BAR:
        return (
          <AnnouncementBarGeneral
            general={announcementBar?.general || {}}
            defaultGeneral={defaultAnnouncementBar?.general || {}}
            shopDefaultGeneral={shopDefaultData}
            setGeneral={(data) =>
              updateEditPromotionState('announcementBar', {
                ...announcementBar,
                general: data,
              })
            }
            onShowHide={
              announcementBar?.canBeHidden
                ? () =>
                    updateEditPromotionState('announcementBar', {
                      ...announcementBar,
                      isHidden: !announcementBar.isHidden,
                    })
                : undefined
            }
            onRemove={() =>
              filterSupportedWidgets(WidgetTypeDtoEnum.ANNOUNCEMENT_BAR)
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.PRIMARY_MULTI_SLOT:
        return (
          <AnnoucementBarBanner
            banner={widgetPreset?.options?.primaryBanner?.general || {}}
            defaultBanner={
              defaultPresetSetup?.options?.primaryBanner?.general || {}
            }
            shopDefaultBanner={shopDefaultData.primaryBanner || {}}
            isPrimary
            handleLeftMenuSelection={handleLeftMenuSelection}
            setBanner={updatePrimaryBanner}
            onShowHide={() => updateBannerTypeVisability('primaryBanner')}
          />
        );

      case selectedOption?.name ===
        WidgetListOptionsDtoEnum.SECONDARY_THREE_SLOT:
        return (
          <AnnoucementBarBanner
            banner={widgetPreset?.options?.secondaryBanner?.general || {}}
            defaultBanner={
              defaultPresetSetup?.options?.secondaryBanner?.general || {}
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
            shopDefaultBanner={shopDefaultData.secondaryBanner || {}}
            setBanner={updateSecondaryBanner}
            onShowHide={() => updateBannerTypeVisability('secondaryBanner')}
          />
        );
      case !!selectedOption?.offerId:
        return (
          <AnnouncementBarOffer
            general={offerData?.setup?.general || {}}
            offerName={
              (i18n.translate(
                offerData?.parentKey ||
                  AnnouncementBarOfferPositionDtoEnum.ANNOUNCEMENT_BAR_PRIMARY_BANNER
              ) + selectedOption?.name) as string
            }
            shopDefaultOffer={shopDefaultData}
            defaultGeneral={defaultOfferData?.setup?.general || {}}
            handleLeftMenuSelection={handleLeftMenuSelection}
            onShowHide={
              offerData?.canBeHidden
                ? () =>
                    updateOffer(
                      { ...offerData, isHidden: !offerData?.isHidden },
                      offerData?.offerId as string
                    )
                : undefined
            }
            setGeneral={updateOfferGeneral}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.ICON:
        return (
          <AnnouncementBarIcon
            icon={offerData?.setup?.options?.icon || {}}
            defaultIcon={defaultOfferData?.setup?.options?.icon || {}}
            presetSetup={
              widgetPreset?.options?.[currentBanner]?.options?.icon?.icon || {}
            }
            defaultPresetSetup={
              defaultPresetSetup?.options?.[currentBanner]?.options?.icon
                ?.icon || {}
            }
            shopDefaultIcon={shopDefaultData?.[currentBanner]?.icon || {}}
            handleLeftMenuSelection={handleLeftMenuSelection}
            onShowHide={() => updateOfferComponentVisability('icon', offerData)}
            setIcon={updateIconComponent}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.IMAGE:
        return (
          <AnnouncementBarImage
            image={offerData.setup?.options?.image || {}}
            presetSetup={
              widgetPreset?.options?.[currentBanner]?.options?.image || {}
            }
            defaultPresetSetup={
              defaultPresetSetup?.options?.[currentBanner]?.options?.image || {}
            }
            defaultImage={defaultOfferData.setup?.options?.image || {}}
            shopDefaultImage={shopDefaultData?.[currentBanner]?.image || {}}
            handleLeftMenuSelection={handleLeftMenuSelection}
            setImage={updateImageComponent}
            onShowHide={() =>
              updateOfferComponentVisability('image', offerData)
            }
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.MESSAGE:
        return (
          <AnnouncementBarMessage
            key={offerData?.offerId}
            message={offerData.setup?.options?.message || {}}
            defaultMessage={defaultOfferData.setup?.options?.message || {}}
            presetSetup={
              widgetPreset?.options?.[currentBanner]?.options?.message || {}
            }
            defaultPresetSetup={
              defaultPresetSetup?.options?.[currentBanner]?.options?.message ||
              {}
            }
            shopDefaultMessage={
              shopDefaultData?.[currentBanner]?.textStyle || {}
            }
            defaultLanguage={
              editPromotionState.offers?.defaultLanguage as string
            }
            offerId={offerData?.offerId}
            handleLeftMenuSelection={handleLeftMenuSelection}
            setMessage={updateMessageComponent}
            onShowHide={() =>
              updateOfferComponentVisability('message', offerData)
            }
          />
        );
      case selectedOption?.name.slice(0, -1) === 'slot':
        return (
          <div className='EmptyRightSideBanner'>
            <WidgetOptionHeader
              name={i18n.translate(selectedOption?.name || '')}
              handleLeftMenuSelection={handleLeftMenuSelection}
            />
            <div className='RightSideSection'>
              <Text as='h2' tone='subdued'>
                {i18n.translate('EmptySlotInfoBanner')}
              </Text>
            </div>
          </div>
        );
    }
  }, [
    announcementBar,
    defaultAnnouncementBar,
    offersData,
    defaultOffersData,
    editPromotionState,
    selectedOptionPath,
    widgetPreset,
    updateOffer,
    updateBannerType,
    handleLeftMenuSelection,
    updateOfferComponent,
  ]);

  return (
    <div className='PromotionAnnouncementBar'>{announcementBarOptions}</div>
  );
};

export default PromotionAnnouncementBar;
