import React from 'react';
import { Box, IndexTable, SkeletonDisplayText, Text } from '@shopify/polaris';
import { OrderAnalyticsEntryDtoRead } from 'core/api/adminAnalytics/adminAnalyticsApi';
import MomentAgo from 'core/components/MomentAgo/MomentAgo';

type DetailedOrderRowProps = {
  index: number;
  data: OrderAnalyticsEntryDtoRead;
  listWidth: number;
  isLoading: boolean;
};

export const DetailedOrderRow: React.FC<DetailedOrderRowProps> = ({
  index,
  data,
  listWidth,
  isLoading,
}) => {
  return (
    <IndexTable.Row id={data.id as string} position={index}>
      <IndexTable.Cell className='OrderColumn'>
        {!isLoading ? (
          <Text as='p' fontWeight='medium'>
            {data.order}
          </Text>
        ) : (
          <SkeletonDisplayText />
        )}
      </IndexTable.Cell>
      <IndexTable.Cell className='DateColumn'>
        {!isLoading ? (
          <MomentAgo prefix='' timestamp={data.date as number} />
        ) : (
          <SkeletonDisplayText />
        )}
      </IndexTable.Cell>
      <IndexTable.Cell className='CustomerColumn'>
        {!isLoading ? (
          <Text as='p'>{data.customer}</Text>
        ) : (
          <SkeletonDisplayText />
        )}
      </IndexTable.Cell>
      <IndexTable.Cell className='OrderValueColumn'>
        {!isLoading ? (
          <Text as='p'>${data.orderValue}</Text>
        ) : (
          <SkeletonDisplayText />
        )}
      </IndexTable.Cell>
      <IndexTable.Cell className='PromotionCodeColumn'>
        {!isLoading ? (
          <Text as='p'>{data.promotionCodes?.[0]}</Text>
        ) : (
          <SkeletonDisplayText />
        )}
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Box width={`${(listWidth / 100) * 40}px`}>
          {!isLoading ? (
            <Text as='p'>{data.promotionsApplied?.[0]}</Text>
          ) : (
            <SkeletonDisplayText maxWidth={'100%' as any} />
          )}
        </Box>
      </IndexTable.Cell>
    </IndexTable.Row>
  );
};
