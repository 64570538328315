import React from 'react';
import {
  Card,
  BlockStack,
  Text,
  Checkbox,
  ChoiceList,
  InlineStack,
  Button,
} from '@shopify/polaris';

import { useI18n } from '@shopify/react-i18n';
import {
  GiftPriceGiftsSettingsDto,
  FreeGiftNotificationGiftsSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';

type CheckboxComponentProps = {
  data:
    | GiftPriceGiftsSettingsDto
    | FreeGiftNotificationGiftsSettingsDto
    | undefined;
  section: string;
  fieldCheckbox: string;
  fieldOptions: string;
  options: Option[];
  handleGiftsLocaleState: (
    section: string,
    value: string | boolean,
    field: string
  ) => void;
  navigateToTranslations: () => void;
};

type Option = {
  label: string;
  value: string;
};

type BooleanKeys =
  | Pick<GiftPriceGiftsSettingsDto, 'hideGiftOriginalPrice'>
  | Pick<FreeGiftNotificationGiftsSettingsDto, 'showFreeGiftNotification'>;
type StringKeys =
  | Omit<GiftPriceGiftsSettingsDto, 'hideGiftOriginalPrice'>
  | Omit<FreeGiftNotificationGiftsSettingsDto, 'showFreeGiftNotification'>;

export const CheckboxComponent: React.FC<CheckboxComponentProps> = ({
  data,
  section,
  fieldCheckbox,
  fieldOptions,
  options,
  handleGiftsLocaleState,
  navigateToTranslations,
}) => {
  const [i18n] = useI18n();

  return (
    <Card roundedAbove='sm' padding='400'>
      <BlockStack gap='400'>
        <InlineStack align='space-between'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate(`${section}Title`)}
          </Text>
          <Button onClick={navigateToTranslations} variant='plain'>
            {i18n.translate('configureTranslations')}
          </Button>
        </InlineStack>

        <Checkbox
          label={i18n.translate(`${section}Checkbox`)}
          checked={
            (
              data as
                | GiftPriceGiftsSettingsDto
                | FreeGiftNotificationGiftsSettingsDto
            )?.[fieldCheckbox as keyof BooleanKeys] || false
          }
          helpText={i18n.translate(`${section}HelpText`)}
          onChange={(value) =>
            handleGiftsLocaleState(section, value, fieldCheckbox)
          }
        />
        {data && data[fieldCheckbox as keyof typeof data] && (
          <ChoiceList
            title={i18n.translate(`${section}OptionsTitle`)}
            choices={options}
            selected={[data[fieldOptions as keyof StringKeys] || '']}
            onChange={(value) =>
              handleGiftsLocaleState(section, value[0], fieldOptions)
            }
          />
        )}
      </BlockStack>
    </Card>
  );
};
