import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  OfferSuggestedRuleConditionDto,
  OfferSuggestedRuleConditionGroupDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import DownUpArrows from '../../../../../../../../core/components/ControlLibrary/RichTextEditor/assets/DownUpArrows.svg';
import { useI18n } from '@shopify/react-i18n';
import {
  OfferCartRuleGroupConnectorDtoEnum,
  OfferTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { ActionList, Popover } from '@shopify/polaris';
import { ConditionComponent } from '../ConditionComponent/ConditionComponent';
import './GroupComponent.scss';
import { usePrevious } from 'core/hooks/usePrevious';
import { isEqual } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

type GroupComponentProps = {
  group: OfferSuggestedRuleConditionGroupDto;
  idx: number;
  updateGroup(group: OfferSuggestedRuleConditionGroupDto, idx: number): void;
  deleteGroup: (index: number) => void;
  offerType?: OfferTypeDtoEnum;
};

export const GroupComponent: React.FC<GroupComponentProps> = (props) => {
  const { group, idx, updateGroup, deleteGroup, offerType } = props;

  const [i18n] = useI18n();

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [currentGroup, setCurrentGroup] =
    useState<OfferSuggestedRuleConditionGroupDto>(group);

  const prevGroup = usePrevious(currentGroup);

  const toggleDropdown = useCallback(
    () => setIsDropdownOpen((active) => !active),
    []
  );

  const currentConnector = useMemo(
    () =>
      currentGroup.connector === OfferCartRuleGroupConnectorDtoEnum.AND
        ? i18n.translate('And')
        : i18n.translate('ButNot'),
    [i18n, currentGroup.connector]
  );

  const hasEmptyCondition = useMemo(
    () => currentGroup.conditions?.some((obj) => Object.keys(obj).length === 0),
    [currentGroup.conditions]
  );

  const activator = useMemo(
    () => (
      <button onClick={toggleDropdown} className='connectorSelector'>
        <span className='connector'>{currentConnector}</span>
        <img src={DownUpArrows} alt='DownUpArrows' />
      </button>
    ),
    [currentConnector, toggleDropdown, idx]
  );

  const updateCurrentGroup = useCallback(
    (
      field: keyof OfferSuggestedRuleConditionGroupDto,
      data: OfferSuggestedRuleConditionGroupDto[keyof OfferSuggestedRuleConditionGroupDto]
    ) => {
      setCurrentGroup((prevState: OfferSuggestedRuleConditionGroupDto) => ({
        ...prevState,
        [field]: data,
      }));
    },
    [setCurrentGroup]
  );

  const handleConnectorChanger = useCallback(
    (value: OfferCartRuleGroupConnectorDtoEnum) => {
      updateCurrentGroup('connector', value);
      toggleDropdown();
    },
    [toggleDropdown, updateCurrentGroup]
  );

  const handleDeleteCondition = useCallback(
    (index: number) => {
      const newConditions = currentGroup.conditions
        ? [...currentGroup.conditions]
        : [];
      if (newConditions.length === 1 || newConditions.length === 0) {
        deleteGroup(idx);
      } else {
        newConditions.splice(index, 1);
        updateCurrentGroup('conditions', newConditions);
      }
    },
    [deleteGroup, currentGroup.conditions, idx, updateCurrentGroup]
  );

  const handleUpdateCondition = useCallback(
    (updatedObject: OfferSuggestedRuleConditionDto, index: number) => {
      const newConditions = currentGroup.conditions
        ? [...currentGroup.conditions]
        : [];
      newConditions[index] = updatedObject;
      updateCurrentGroup('conditions', newConditions);
    },
    [updateCurrentGroup, currentGroup.conditions]
  );

  const handleAddCondition = useCallback(() => {
    const currentConditions: OfferSuggestedRuleConditionDto[] =
      currentGroup.conditions ? [...currentGroup.conditions] : [];
    const newConditions = [...currentConditions, {}];
    updateCurrentGroup('conditions', newConditions);
  }, [currentGroup.conditions, updateCurrentGroup]);

  useEffect(() => {
    if (
      prevGroup &&
      currentGroup &&
      !isEqual(prevGroup, currentGroup) &&
      !hasEmptyCondition
    ) {
      updateGroup?.(currentGroup, idx);
    }
  }, [prevGroup, currentGroup, hasEmptyCondition]);

  return (
    <div className='GroupComponent'>
      {currentGroup.connector ? (
        <>
          <div className='connectorBlock'>
            <Popover
              active={isDropdownOpen}
              activator={activator}
              onClose={toggleDropdown}
            >
              <ActionList
                actionRole='menuitem'
                items={[i18n.translate('And'), i18n.translate('ButNot')].map(
                  (connector) => ({
                    content: connector,
                    active: currentConnector === connector,
                    onAction: () =>
                      handleConnectorChanger(
                        connector === i18n.translate('And')
                          ? OfferCartRuleGroupConnectorDtoEnum.AND
                          : OfferCartRuleGroupConnectorDtoEnum.BUT_NOT
                      ),
                  })
                )}
              />
            </Popover>
          </div>
          <div className='conditionsBlock'>
            {currentGroup?.conditions && currentGroup.conditions?.length ? (
              currentGroup.conditions.map((condition, index, arr) => (
                <ConditionComponent
                  offerType={offerType}
                  key={uuidv4()}
                  condition={condition}
                  idx={index}
                  lastIdx={arr.length - 1}
                  firstGroup={idx === 0}
                  handleDeleteCondition={handleDeleteCondition}
                  handleAddCondition={handleAddCondition}
                  handleUpdateCondition={handleUpdateCondition}
                />
              ))
            ) : (
              <ConditionComponent
                offerType={offerType}
                condition={{}}
                idx={0}
                lastIdx={0}
                firstGroup={idx === 0}
                handleDeleteCondition={handleDeleteCondition}
                handleAddCondition={handleAddCondition}
                handleUpdateCondition={handleUpdateCondition}
              />
            )}
          </div>
        </>
      ) : (
        <>
          <div className='defaultConnectorBlock'>
            {i18n.translate('ApplyIfTheVisitor')}
          </div>
          <div className='conditionsBlock'>
            {currentGroup?.conditions && currentGroup.conditions?.length ? (
              currentGroup.conditions.map((condition, index, arr) => (
                <ConditionComponent
                  offerType={offerType}
                  key={uuidv4()}
                  condition={condition}
                  idx={index}
                  lastIdx={arr.length - 1}
                  firstGroup={idx === 0}
                  handleDeleteCondition={handleDeleteCondition}
                  handleAddCondition={handleAddCondition}
                  handleUpdateCondition={handleUpdateCondition}
                />
              ))
            ) : (
              <ConditionComponent
                offerType={offerType}
                condition={{}}
                idx={0}
                lastIdx={0}
                firstGroup={idx === 0}
                handleDeleteCondition={handleDeleteCondition}
                handleAddCondition={handleAddCondition}
                handleUpdateCondition={handleUpdateCondition}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
