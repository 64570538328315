import React, { useCallback } from 'react';
import { OfferRulePopupGeneralPresetDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { useI18n } from '@shopify/react-i18n';
import {
  Background,
  CornerRadius,
  CustomCSS,
  Icon as IconComponent,
  Offset,
  OverlaySettings,
  Position,
  Shadow,
  ShopSettingsWrapper,
} from '../../../../../components';
import { ResourceGroupKeyDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { WidgetListTypeDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { setToggleSettingsConfig } from 'core/store/widgetsSlice';
import { useAppDispatch } from 'core/hooks';

type OfferRulePopupGeneralProps = {
  general: OfferRulePopupGeneralPresetDto;
  defaultGeneral: OfferRulePopupGeneralPresetDto;
  shopDefaultGeneral: OfferRulePopupGeneralPresetDto;
  isPresetEditor?: boolean;
  setGeneral: (data: OfferRulePopupGeneralPresetDto) => void;
  onShowHide?: () => void;
  onRemove?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const OfferRulePopupGeneral: React.FC<OfferRulePopupGeneralProps> = ({
  general,
  defaultGeneral,
  shopDefaultGeneral,
  isPresetEditor,
  setGeneral,
  onShowHide,
  onRemove,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();
  const resetAllToSystemDefault = useCallback(() => {
    setGeneral(shopDefaultGeneral);
  }, [shopDefaultGeneral]);

  const handleUpdateComponent = useCallback(
    (
      field: keyof OfferRulePopupGeneralPresetDto,
      data: OfferRulePopupGeneralPresetDto[keyof OfferRulePopupGeneralPresetDto]
    ) => setGeneral({ ...general, [field]: data }),
    [general, setGeneral]
  );

  return (
    <div className='PromotionalBadgeGeneral'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onRemove={onRemove}
        onDiscardChanges={() => setGeneral(defaultGeneral)}
        onResetToSystem={resetAllToSystemDefault}
        name={WidgetListTypeDtoEnum.OFFER_RULE_POPUP}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      {/* <div className='RightSideSection'>
        <Size
          size={general.size || {}}
          disableWidth
          setSize={(data) => handleUpdateComponent('size', data)}
          onSystemReset={() =>
            handleUpdateComponent('size', shopDefaultData.size)
          }
        />
      </div> */}
      {isPresetEditor && (
        <>
          {' '}
          <div className='RightSideSection'>
            <Background
              background={general.background || {}}
              setBackground={(data) =>
                handleUpdateComponent('background', data)
              }
              onSystemReset={() =>
                handleUpdateComponent(
                  'background',
                  shopDefaultGeneral.background
                )
              }
            />
          </div>
          <div className='RightSideSection'>
            <CornerRadius
              onSystemReset={() =>
                handleUpdateComponent(
                  'cornerRadius',
                  shopDefaultGeneral.cornerRadius
                )
              }
              cornerRadius={general.cornerRadius || {}}
              setCornerRadius={(data) =>
                handleUpdateComponent('cornerRadius', data)
              }
            />
          </div>
          <div className='RightSideSection'>
            <Shadow
              shadow={general.shadow || {}}
              setShadow={(data) => handleUpdateComponent('shadow', data)}
              onSystemReset={() =>
                handleUpdateComponent('shadow', shopDefaultGeneral.shadow)
              }
            />
          </div>
        </>
      )}

      <div className='RightSideSection'>
        <CustomCSS
          css={general.customCss || ''}
          setCSS={(value) => handleUpdateComponent('customCss', value)}
          note={i18n.translate('CSSNote')}
        />
      </div>
      <ShopSettingsWrapper
        onOpen={() =>
          dispatch(
            setToggleSettingsConfig({
              action: 'open',
              path: 'offer-rules-popup',
            })
          )
        }
      >
        <div className='RightSideSection'>
          <Position position={general.shopSettings?.position || {}} disabled />
        </div>
        <div className='RightSideSection'>
          <Offset
            offset={general.shopSettings?.offset || {}}
            note={i18n.translate('OffsetNote')}
            disabled
          />
        </div>
        {isPresetEditor && (
          <div className='RightSideSection'>
            <IconComponent
              icon={general.shopSettings?.closeButton || {}}
              title={i18n.translate('CloseButton')}
              showIcon
              groupKey={ResourceGroupKeyDtoEnum.CLOSE_BUTTON}
              onlyColor
              setIcon={(data) =>
                handleUpdateComponent('shopSettings', {
                  ...general.shopSettings,
                  closeButton: data,
                })
              }
              onSystemReset={() =>
                handleUpdateComponent('shopSettings', {
                  ...general.shopSettings,
                  closeButton: {
                    ...general.shopSettings?.closeButton,
                    color: shopDefaultGeneral.shopSettings?.closeButton?.color,
                  },
                })
              }
            />
          </div>
        )}

        <div className='RightSideSection'>
          <OverlaySettings
            overlay={general.shopSettings?.overlay || {}}
            disabled
          />
        </div>
      </ShopSettingsWrapper>
    </div>
  );
};

export default OfferRulePopupGeneral;
