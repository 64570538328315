import React, { useMemo } from 'react';
import { BlockStack, Card, ChoiceList, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { PositionDto } from 'core/api/adminSettings/adminSettingsApi';
import { PositionDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { Element } from 'react-scroll';

type PositionSettingProps = {
  position: PositionDto;
  title: string;
  subtitle?: string;
  positionSides: PositionDtoEnum[];
  setPosition: (value: PositionDto) => void;
};
export const PositionSetting: React.FC<PositionSettingProps> = ({
  position,
  positionSides,
  title,
  subtitle,
  setPosition,
}) => {
  const [i18n] = useI18n();

  const positionOptions = useMemo(
    () =>
      positionSides.map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    []
  );

  return (
    <Element name='PositionSetting' className='PositionSetting'>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {title}
            </Text>
            {subtitle && (
              <Text as='p' tone='subdued'>
                {subtitle}
              </Text>
            )}
          </BlockStack>
          <ChoiceList
            title=''
            choices={positionOptions}
            selected={[position || PositionDtoEnum.BOTTOM_LEFT]}
            onChange={(value) => setPosition(value[0] as PositionDtoEnum)}
          />
        </BlockStack>
      </Card>
    </Element>
  );
};
