import React, { useCallback, useEffect, useState } from 'react';
import './LeftSideBarWidgetList.scss';
import { ActionList, Button, Icon, Popover, Text } from '@shopify/polaris';
import { LeftMenuWidget } from 'features/settings/components/EditPresetSkeleton/utils/leftMenuGenerators';
import {
  ThemeIcon,
  DeleteIcon,
  ViewIcon,
  HideIcon,
  PlusCircleIcon,
} from '@shopify/polaris-icons';
import classNames from 'classnames';
import { I18n } from '@shopify/react-i18n';
import {
  PageSetupEntryDto,
  PageTypeDto,
  WidgetTypeDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { useAppSelector } from 'core/hooks';
import { Loader } from 'core/components';
import {
  PageTypeDtoEnum,
  WidgetTypeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import { WidgetListTypeDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { getWidgetPageGroupIcon } from 'features/settings/components/EditPresetSkeleton/utils/utils';

type LeftSideBarWidgetListProps = {
  widgetsState: LeftMenuWidget[];
  i18n: I18n;
  pages: PageSetupEntryDto[];
  handleSelectWidget: (widget: LeftMenuWidget, page: PageTypeDtoEnum) => void;
  handleRemoveWidget: (widgetName: WidgetTypeDto) => void;
  handleAddWidget: (page: PageTypeDto, widgetName: WidgetTypeDto) => void;
  handleWidgetsMenuState: (
    widgetType: string,
    field: string,
    value: any
  ) => void;
};
const LeftSideBarWidgetList: React.FC<LeftSideBarWidgetListProps> = ({
  widgetsState,
  i18n,
  pages,
  handleSelectWidget,
  handleRemoveWidget,
  handleWidgetsMenuState,
  handleAddWidget,
}) => {
  const [addWidgetPopover, setAddWidgetPopover] = useState<string>('');
  const { isPromotionEditorDataLoading } = useAppSelector(
    (store) => store.widgets
  );
  const convertIntoEnum = useCallback((widgetName: string) => {
    return widgetName.replace(/([a-z])([A-Z])/g, '$1_$2').toUpperCase();
  }, []);

  const renderAddWidgetButton = useCallback(
    (page: PageSetupEntryDto) => {
      const widgetsToAdd =
        page.availableEntries?.filter(
          (entry) => !page.currentEntries?.includes(entry)
        ) || [];
      return (
        <div className='AddWidgetButton'>
          <Popover
            active={addWidgetPopover === page.page}
            activator={
              <Button
                onClick={() => setAddWidgetPopover(page.page as string)}
                icon={PlusCircleIcon}
                variant='plain'
                disabled={isPromotionEditorDataLoading}
              >
                {i18n.translate('AddWidget')}
              </Button>
            }
            onClose={() => setAddWidgetPopover('')}
          >
            {isPromotionEditorDataLoading ? (
              <div className='AddWidgetListLoaderBox'>
                <Loader fullWidth />
              </div>
            ) : (
              <ActionList
                actionRole='menuitem'
                items={widgetsToAdd?.map((widget) => ({
                  content: i18n.translate(
                    `${
                      WidgetListTypeDtoEnum[
                        widget || WidgetTypeDtoEnum.ANNOUNCEMENT_BAR
                      ]
                    }`
                  ),
                  icon: ThemeIcon,
                  onAction: () => {
                    handleAddWidget(page.page!, widget);
                  },
                }))}
              />
            )}
          </Popover>
        </div>
      );
    },
    [addWidgetPopover, handleAddWidget, isPromotionEditorDataLoading]
  );

  useEffect(() => {
    if (!isPromotionEditorDataLoading) {
      setAddWidgetPopover('');
    }
  }, [isPromotionEditorDataLoading]);

  return (
    <div className='LeftSideBarWidgetList'>
      {pages
        .filter((page) => page.availableEntries?.length)
        .map((page) => (
          <div className='PageGroupList' key={page.page}>
            <div className='PageGroupNameBox'>
              <div>
                <Icon
                  tone='base'
                  source={getWidgetPageGroupIcon(page.page as PageTypeDto)}
                />
              </div>
              <Text tone='subdued' as='p'>
                {i18n.translate(`${page.page}_PAGE`)}
              </Text>
            </div>
            {widgetsState
              .filter((widget) => widget.page === page.page)
              .map((widget) => (
                <div
                  className={classNames('WidgetOption', {
                    SecondLvlNesting: true,
                  })}
                  key={widget.id}
                  onClick={() =>
                    handleSelectWidget(
                      widget,
                      page.page === PageTypeDtoEnum.ALL
                        ? PageTypeDtoEnum.HOME
                        : (page.page as PageTypeDtoEnum)
                    )
                  }
                >
                  <div className='WidgetOptionSide'>
                    <div>
                      <Icon
                        tone={widget.isHidden ? 'subdued' : undefined}
                        source={ThemeIcon}
                      />
                    </div>
                    <div
                      className={classNames('WidgetName', {
                        Disabled: widget.isHidden,
                      })}
                    >
                      {i18n.translate(`${widget.name}`)}
                    </div>
                    <div className='HideDeleteActions'>
                      {widget.canBeRemoved && (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveWidget(
                              convertIntoEnum(widget.name) as WidgetTypeDto
                            );
                            setAddWidgetPopover('');
                          }}
                          className='DeleteOptionIcon'
                        >
                          <Icon tone='base' source={DeleteIcon} />
                        </div>
                      )}
                      {widget.canBeHidden && (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleWidgetsMenuState(
                              widget.name,
                              'isHidden',
                              !widget.isHidden
                            );
                          }}
                          className='ViewOptionIcon'
                        >
                          <Icon
                            tone={widget.isHidden ? 'subdued' : 'base'}
                            source={!widget.isHidden ? ViewIcon : HideIcon}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}

            {page.currentEntries?.length !== page.availableEntries?.length &&
              renderAddWidgetButton(page)}
          </div>
        ))}
    </div>
  );
};

export default LeftSideBarWidgetList;
