import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import './PromotionListRow.scss';
import {
  Badge,
  BlockStack,
  Box,
  Button,
  ButtonGroup,
  Icon,
  IndexTable,
  InlineStack,
  Link,
  Popover,
  Tag,
  Text,
} from '@shopify/polaris';
import { CaretDownIcon, InfoIcon } from '@shopify/polaris-icons';
import { useNavigate } from 'react-router-dom';
import { PromotionListItemDto } from 'core/api/adminPromotions/adminPromotionsApi';
import {
  PromotionOperationTypeDtoEnum,
  PromotionStatusDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { I18n } from '@shopify/react-i18n';
import { getProgressLeftTime, getPromotionStatusColor } from '../../utils';
import { formatTimeDistance } from 'core/utils';
import { useConfigurePromotions } from 'features/promotions/hooks/useConfigurePromotion';
import { ActivatePromotionModal } from 'features/promotions/components/PromotionOverview/components/ActivatePromotionModal/ActivatePromotionModal';
import {
  DeactivatePromotionModal,
  DeletePromotionModal,
} from 'features/promotions/components/PromotionOverview/components';
import { Tooltip } from 'core/components';

type PromotionListRowProps = {
  index: number;
  selectedResources: string[];
  promotionData: PromotionListItemDto;
  i18n: I18n;
  activePopover: string;
  setActivePopover: (id: string) => void;
  refetchPromotionList: () => void;
  onUpdateProgress: () => void;
  onBatchOperation: (
    operation: PromotionOperationTypeDtoEnum,
    promotionsIds: string[]
  ) => void;
};

const PromotionListRow: React.FC<PromotionListRowProps> = ({
  index,
  selectedResources,
  promotionData,
  i18n,
  activePopover,
  setActivePopover,
  refetchPromotionList,
  onUpdateProgress,
  onBatchOperation,
}) => {
  const navigate = useNavigate();
  const {
    pausePromotionProgress,
    resumePromotionProgress,
    rollbackPromotionProgress,
  } = useConfigurePromotions();
  const rowRef = useRef<any | null>(null);
  const [rowWidth, setRowWidth] = useState(0);
  const [isOperationLoading, setIsOperationLoading] = useState<boolean>(false);
  const [isActivateModal, setIsActivateModal] = useState<boolean>(false);
  const [isDeactivateModal, setIsDeactivateModal] = useState<boolean>(false);
  const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false);

  const isProgressStatus = useMemo(
    () =>
      promotionData.status === PromotionStatusDtoEnum.IN_PROGRESS_PUBLISHING ||
      promotionData.status ===
        PromotionStatusDtoEnum.IN_PROGRESS_UNPUBLISHING ||
      promotionData.status === PromotionStatusDtoEnum.IN_PROGRESS_ROLLING_BACK,
    [promotionData.status]
  );
  const isProgressFinished = useMemo(
    () =>
      promotionData.operationProgress?.progress === 100 &&
      (promotionData.status === PromotionStatusDtoEnum.IN_PROGRESS_PUBLISHING ||
        promotionData.status ===
          PromotionStatusDtoEnum.IN_PROGRESS_UNPUBLISHING ||
        promotionData.status ===
          PromotionStatusDtoEnum.IN_PROGRESS_ROLLING_BACK),
    [promotionData]
  );

  const showSchedule = useMemo(
    () =>
      (promotionData.status === PromotionStatusDtoEnum.PUBLISHED &&
        promotionData.endsAt) ||
      isProgressStatus ||
      promotionData.status === PromotionStatusDtoEnum.SCHEDULED,
    [promotionData.status, promotionData.endsAt, isProgressStatus]
  );

  const isPublishedEdited = useMemo(
    () => promotionData.status === PromotionStatusDtoEnum.PUBLISHED_EDITED,
    [promotionData.status]
  );

  const tagsList = useMemo(() => {
    const tagsLength = promotionData.tags?.length || 0;
    const tags = promotionData.tags?.slice(0, tagsLength > 3 ? 2 : 3);
    return tagsLength ? (
      <div className='TagsPopoverActivator' onClick={(e) => onPopoverOpen(e)}>
        <Box width='100%'>
          <Popover
            active={promotionData.id === activePopover}
            activator={
              <InlineStack
                gap='300'
                blockAlign='center'
                wrap={false}
                align='space-between'
              >
                <InlineStack wrap={false} gap='100'>
                  {tags?.map((tag, index) => {
                    const tagName =
                      tag.length > 12 ? tag.slice(0, 12) + '...' : tag;
                    return <Tag key={index}>{tagName}</Tag>;
                  })}
                  {tagsLength - 3 > 0 && (
                    <Text variant='bodySm' as='span' tone='subdued'>{`+ ${
                      tagsLength - 2
                    }`}</Text>
                  )}
                </InlineStack>
                {tagsLength > 3 && (
                  <div className='IconActivator'>
                    <Icon tone='base' source={CaretDownIcon} />
                  </div>
                )}
              </InlineStack>
            }
            autofocusTarget='first-node'
            onClose={() => {
              setActivePopover('');
            }}
          >
            <Box maxWidth='300px' paddingBlock='300' paddingInline='200'>
              <InlineStack gap='100'>
                {promotionData.tags?.map((tag) => (
                  <Badge key={tag}>{tag}</Badge>
                ))}
              </InlineStack>
            </Box>
          </Popover>
        </Box>
      </div>
    ) : (
      <Text as='p'>-</Text>
    );
  }, [promotionData.tags, activePopover]);

  const onResumePromotion = useCallback(
    (e?: any) => {
      e.stopPropagation();
      if (promotionData.id) {
        setIsOperationLoading(true);
        resumePromotionProgress(promotionData.id).then(() =>
          onUpdateProgress()
        );
      }
    },
    [promotionData]
  );
  const onPausePromotion = useCallback(
    (e?: any) => {
      e.stopPropagation();
      if (promotionData.id) {
        setIsOperationLoading(true);
        pausePromotionProgress(promotionData.id).then(() => onUpdateProgress());
      }
    },
    [promotionData]
  );
  const onUndoPromotion = useCallback(
    (e?: any) => {
      e.stopPropagation();
      if (promotionData.id) {
        setIsOperationLoading(true);
        rollbackPromotionProgress(promotionData.id).then(() =>
          onUpdateProgress()
        );
      }
    },
    [promotionData]
  );
  const onPopoverOpen = useCallback(
    (e: any) => {
      if ((promotionData.tags?.length as number) > 3) {
        e.stopPropagation();
        setActivePopover(promotionData.id as string);
      }
    },
    [promotionData]
  );

  const onReloadList = useCallback((e?: any) => {
    e.stopPropagation();
    refetchPromotionList();
  }, []);

  const onPromotionAction = useCallback(
    (operation: PromotionOperationTypeDtoEnum) => {
      onBatchOperation(operation, [promotionData.id as string]);
      setActivePopover('');
    },
    [promotionData, onBatchOperation]
  );

  const getPromotionScheduleText = (promotion: PromotionListItemDto) => {
    switch (promotion.status) {
      case PromotionStatusDtoEnum.IN_PROGRESS_PUBLISHING:
      case PromotionStatusDtoEnum.IN_PROGRESS_UNPUBLISHING:
      case PromotionStatusDtoEnum.IN_PROGRESS_ROLLING_BACK:
        return !promotion.operationProgress?.isPaused
          ? '~' +
              getProgressLeftTime(
                promotion.operationProgress?.estimatedTimeLeft || 0
              ).slice(3) +
              i18n.translate('Left')
          : i18n.translate('Paused');
      case PromotionStatusDtoEnum.SCHEDULED:
        return (
          <BlockStack>
            <Text tone='subdued' variant='bodyXs' as='p'>
              {i18n.translate('Starts')}
            </Text>
            <Text as='p'>{formatTimeDistance(promotion.startsAt || 0)}</Text>{' '}
          </BlockStack>
        );
      case PromotionStatusDtoEnum.PUBLISHED:
        return promotion.endsAt ? (
          <BlockStack>
            <Text tone='subdued' variant='bodyXs' as='p'>
              {i18n.translate('Expires')}
            </Text>
            <Text as='p'>{formatTimeDistance(promotion.endsAt || 0)}</Text>{' '}
          </BlockStack>
        ) : null;
      default:
        return '';
    }
  };

  const progressActionButtons = useMemo(() => {
    if (!isProgressFinished) {
      switch (promotionData.status) {
        case PromotionStatusDtoEnum.IN_PROGRESS_PUBLISHING:
          return (
            <ButtonGroup>
              {promotionData.operationProgress?.isPaused ? (
                <Button
                  disabled={isOperationLoading}
                  onClick={onResumePromotion}
                  variant='primary'
                >
                  {i18n.translate('Resume')}
                </Button>
              ) : (
                <Button
                  disabled={isOperationLoading}
                  onClick={onPausePromotion}
                >
                  {i18n.translate('Pause')}
                </Button>
              )}
              <Button
                tone='critical'
                disabled={isOperationLoading}
                onClick={onUndoPromotion}
              >
                {i18n.translate('Undo')}
              </Button>
            </ButtonGroup>
          );
        case PromotionStatusDtoEnum.IN_PROGRESS_UNPUBLISHING:
        case PromotionStatusDtoEnum.IN_PROGRESS_ROLLING_BACK:
          return (
            <ButtonGroup>
              {promotionData.operationProgress?.isPaused ? (
                <Button
                  disabled={isOperationLoading}
                  onClick={onResumePromotion}
                  variant='primary'
                >
                  {i18n.translate('Resume')}
                </Button>
              ) : (
                <Button
                  disabled={isOperationLoading}
                  onClick={onPausePromotion}
                >
                  {i18n.translate('Pause')}
                </Button>
              )}
            </ButtonGroup>
          );
      }
    } else {
      return (
        <ButtonGroup>
          <Button onClick={onReloadList}>
            {i18n.translate('ReloadTheList')}
          </Button>
        </ButtonGroup>
      );
    }
  }, [
    promotionData.status,
    promotionData,
    isProgressFinished,
    isOperationLoading,
  ]);

  useEffect(() => {
    const updateWidth = () => {
      setRowWidth(rowRef.current?.clientWidth || 0);
    };
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  useEffect(() => {
    if (promotionData) {
      isOperationLoading ? setIsOperationLoading(false) : null;
    }
  }, [promotionData]);

  const redirectToPromotionOverview = useCallback(() => {
    navigate(`${promotionData.id}`);
  }, [navigate, promotionData.id]);

  return (
    <>
      <IndexTable.Row
        disabled={isProgressStatus}
        id={promotionData.id as string}
        selected={selectedResources.includes(promotionData.id as string)}
        position={index}
      >
        <Link
          onClick={() => redirectToPromotionOverview()}
          dataPrimaryLink
          monochrome
        />
        <td
          ref={rowRef}
          className='ProgressBar'
          style={{
            width: `${promotionData.operationProgress?.progress}%`,
            background: isProgressStatus ? '#F1F8F5' : 'transparent',
          }}
        />
        <IndexTable.Cell>
          <Box maxWidth={`${(rowWidth / 100) * 35}px`} minWidth='200px'>
            <Text
              truncate={true}
              as='span'
              variant='bodyMd'
              fontWeight='semibold'
            >
              {promotionData.name}
            </Text>
          </Box>
        </IndexTable.Cell>

        <IndexTable.Cell className='TokenColumn'>
          <Text as='p' variant='bodyMd'>
            {promotionData.token}
          </Text>
        </IndexTable.Cell>

        <IndexTable.Cell className='StatusColumn'>
          <Tooltip
            content={
              isPublishedEdited ? i18n.translate('UnpublishedChanges') : null
            }
          >
            <Badge
              icon={isPublishedEdited ? InfoIcon : undefined}
              tone={getPromotionStatusColor(
                promotionData.status as PromotionStatusDtoEnum
              )}
            >
              {i18n.translate(`${promotionData.status}`)}
            </Badge>
          </Tooltip>
        </IndexTable.Cell>

        <IndexTable.Cell className='ScheduleColumn'>
          {showSchedule ? (
            !isProgressFinished ? (
              getPromotionScheduleText(
                promotionData.operationProgress?.id
                  ? {
                      ...promotionData,
                      operationProgress: {
                        estimatedTimeLeft:
                          promotionData.operationProgress?.estimatedTimeLeft,
                        isPaused: promotionData.operationProgress?.isPaused,
                      },
                    }
                  : promotionData
              )
            ) : (
              i18n.translate('Completed')
            )
          ) : (
            <Text as='p'>-</Text>
          )}
        </IndexTable.Cell>

        <IndexTable.Cell className='TriggerColumn'>
          {i18n.translate(`${promotionData.triggerType}`)}
        </IndexTable.Cell>
        <IndexTable.Cell className='PromotionTypeColumn'>
          {i18n.translate(`${promotionData.type}_TYPE`)}
        </IndexTable.Cell>

        <IndexTable.Cell className='ModifiedColumn'>
          <Box minWidth='120px'>
            {formatTimeDistance(promotionData.lastModifiedAt || 0)}
          </Box>
        </IndexTable.Cell>
        <IndexTable.Cell className='PromotionTagsColumn'>
          {isProgressStatus ? progressActionButtons : tagsList}
        </IndexTable.Cell>
      </IndexTable.Row>

      <ActivatePromotionModal
        onClose={() => setIsActivateModal(false)}
        onActivatePromotion={() =>
          onPromotionAction(PromotionOperationTypeDtoEnum.PUBLISH)
        }
        isOpen={isActivateModal}
      />
      <DeactivatePromotionModal
        onClose={() => setIsDeactivateModal(false)}
        onDeactivatePromotion={() =>
          onPromotionAction(PromotionOperationTypeDtoEnum.UNPUBLISH)
        }
        isOpen={isDeactivateModal}
      />
      <DeletePromotionModal
        onClose={() => setIsDeleteModal(false)}
        onDeletePromotion={() =>
          onPromotionAction(PromotionOperationTypeDtoEnum.DELETE)
        }
        isOpen={isDeleteModal}
      />
    </>
  );
};

export default PromotionListRow;
