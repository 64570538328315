import React, { useCallback } from 'react';
import { Badge, IndexTable, SkeletonDisplayText, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { BillingEntryDto } from 'core/api/adminSettings/adminSettingsApi';
import { format } from 'date-fns';
import { PaymentStatusDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';

type PastBillsRowProps = {
  index: number;
  data: BillingEntryDto;
  isLoading: boolean;
};

export const PastBillsRow: React.FC<PastBillsRowProps> = ({
  index,
  data,
  isLoading,
}) => {
  const [i18n] = useI18n();
  const formatDate = useCallback(
    (timestamp: number) => format(new Date(timestamp), 'MMM d, yyyy'),
    []
  );

  return (
    <IndexTable.Row id={data.number as string} position={index}>
      <IndexTable.Cell className='DateIssuedColumn'>
        {!isLoading ? (
          <Text as='p'>{formatDate(data.issuedAt as number)}</Text>
        ) : (
          <SkeletonDisplayText />
        )}
      </IndexTable.Cell>
      <IndexTable.Cell className='NumberColumn'>
        {!isLoading ? (
          <Text as='p'>{data.number}</Text>
        ) : (
          <SkeletonDisplayText />
        )}
      </IndexTable.Cell>
      <IndexTable.Cell className='BillTypeColumn'>
        {!isLoading ? (
          <Text as='p'>{i18n.translate(`${data.type}`)}</Text>
        ) : (
          <SkeletonDisplayText />
        )}
      </IndexTable.Cell>
      <IndexTable.Cell className='StatusColumn'>
        {!isLoading ? (
          <Badge
            tone={
              data.status === PaymentStatusDtoEnum.PAID ? 'success' : undefined
            }
          >
            {i18n.translate(`${data.status}`)}
          </Badge>
        ) : (
          <SkeletonDisplayText />
        )}
      </IndexTable.Cell>
      <IndexTable.Cell className='AmountColumn'>
        {!isLoading ? (
          <Text alignment='end' as='p'>{`$${data.amount}`}</Text>
        ) : (
          <SkeletonDisplayText />
        )}
      </IndexTable.Cell>
    </IndexTable.Row>
  );
};
