import React, { useCallback } from 'react';
import { TextEntries, TextStyle } from '../../../../../components';
import {
  HeaderTextPricingModuleGeneralDto,
  MessageDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { isEqual } from 'lodash';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import {
  PricingEntriesStateDto,
  setPricePageEntries,
} from 'core/store/widgetsSlice';
import { PriceWidgetTypesDtoEnum } from '../../../../EditPresetRightBar';
import { PromotionPriceWidgetTypesDtoEnum } from '../../../../../EditPromotionRightBar/EditPromotionRightBar';

type PriceTextProps = {
  text: HeaderTextPricingModuleGeneralDto;
  defaultText: HeaderTextPricingModuleGeneralDto;
  shopDefaultText: MessageDto;
  isPresetEditor?: boolean;
  defaultLanguage?: string;
  priceType: PriceWidgetTypesDtoEnum | PromotionPriceWidgetTypesDtoEnum;
  stateType:
    | WidgetListOptionsDtoEnum.DISCOUNTED_PRICE
    | WidgetListOptionsDtoEnum.NOT_DISCOUNTED_PRICE;
  textType:
    | WidgetListOptionsDtoEnum.FOOTER_TEXT
    | WidgetListOptionsDtoEnum.HEADER_TEXT
    | WidgetListOptionsDtoEnum.PRICE;
  bodyRowType?:
    | WidgetListOptionsDtoEnum.BODY_ROW_1
    | WidgetListOptionsDtoEnum.BODY_ROW_2
    | WidgetListOptionsDtoEnum.BODY_ROW_3;
  setText: (data: HeaderTextPricingModuleGeneralDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const PriceText: React.FC<PriceTextProps> = ({
  text,
  defaultText,
  shopDefaultText,
  isPresetEditor,
  defaultLanguage,
  textType,
  priceType,
  stateType,
  bodyRowType,
  setText,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const dispatch = useAppDispatch();
  const { pricePageEntries } = useAppSelector((store) => store.widgets);
  const updatePriceText = useCallback(
    (field: keyof MessageDto, data: MessageDto[keyof MessageDto]) => {
      setText({
        ...text,
        text: {
          ...text.text,
          [field]: data,
        },
      });
    },
    [text, setText]
  );
  const resetAllToSystemDefault = useCallback(() => {
    setText({ text: shopDefaultText });
  }, [shopDefaultText]);
  return (
    <div className='PriceText'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={() => setText(defaultText)}
        onResetToSystem={resetAllToSystemDefault}
        name={textType}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <TextEntries
          textMessage={text.text?.message || {}}
          inherited={
            !isPresetEditor ? isEqual(text.text, defaultText.text) : undefined
          }
          alignmentFeature
          defaultLanguage={defaultLanguage || ''}
          disableGoal
          disableCollapsed
          disableColor={(text.text?.style || {}).color !== undefined}
          setTextMessage={(data) => updatePriceText('message', data)}
          onSystemReset={resetAllToSystemDefault}
          setPreviewEntry={(value) =>
            dispatch(
              setPricePageEntries({
                priceType,
                stateType,
                field: (textType === WidgetListOptionsDtoEnum.PRICE
                  ? bodyRowType
                  : textType) as keyof PricingEntriesStateDto,
                data:
                  textType === WidgetListOptionsDtoEnum.PRICE
                    ? {
                        ...pricePageEntries?.[priceType]?.[stateType]?.[
                          bodyRowType as keyof PricingEntriesStateDto
                        ],
                        price: value,
                      }
                    : value,
              })
            )
          }
        />
        <TextStyle
          textStyle={text.text?.style || {}}
          hideHeader
          onlyFont
          setTextStyle={(data) => updatePriceText('style', data)}
        />
      </div>
    </div>
  );
};

export default PriceText;
