import { useAppDispatch } from 'core/hooks';
import { setToastMessages } from 'core/store/offersWizardSlice';
import resolveEnvVar from 'env-var-resolver';
import { ReportListTypeEnum } from 'pages';
import { useState } from 'react';

const analyticsApiURL = `${resolveEnvVar(
  'REACT_APP_ANALYTICS_ADMIN_API_URL'
)}/analytics/v6/`;

const useExportListReports = (listType: ReportListTypeEnum) => {
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';
  const dispatch = useAppDispatch();
  const [exportReportIsFetching, setExportReportIsFetching] =
    useState<boolean>(false);
  const exportLink =
    listType === ReportListTypeEnum.DETAILED_CODE
      ? 'promotionCode/export'
      : listType === ReportListTypeEnum.DETAILED_ORDER
      ? 'order/export'
      : 'promotionActivity/export';

  const exportReportList = async () => {
    setExportReportIsFetching(true);
    await fetch(`${analyticsApiURL}/${exportLink}`, {
      method: 'GET',
      headers: {
        'X-LimoniApps-AppName': 'DiscountNinja',
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((data) => {
        data.blob().then((myBlob) => {
          if (myBlob.size) {
            const link = URL.createObjectURL(myBlob);
            window.open(link, '_blank');
          } else {
            dispatch(
              setToastMessages({
                error: true,
                message: 'ReportExportFailed',
              })
            );
          }
        });
      })
      .catch((err) => null)
      .finally(() => setExportReportIsFetching(false));
  };
  return {
    exportReportList,
    exportReportIsFetching,
  };
};

export default useExportListReports;
