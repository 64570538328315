import React from 'react';
import {
  Banner,
  BlockStack,
  Card,
  Checkbox,
  Icon,
  InlineStack,
  Link,
  Text,
  Tooltip,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { InfoIcon } from '@shopify/polaris-icons';
import { TagsMetadataSettingsDto } from 'core/api/adminSettings/adminSettingsApi';

type CouponCheckbox = {
  mapData: CouponCheckboxItem;
  data: TagsMetadataSettingsDto | undefined;
  handleTagsMetadataLocaleState: (
    section: string,
    value: string | boolean,
    field?: string
  ) => void;
};

export type CouponCheckboxItem = {
  field: string;
  tip: boolean;
  bannerType: 'success' | 'info' | 'warning' | 'critical';
};

export const CouponCheckbox: React.FC<CouponCheckbox> = ({
  mapData,
  data,
  handleTagsMetadataLocaleState,
}) => {
  const [i18n] = useI18n();

  return (
    <Card roundedAbove='sm' padding='400'>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <InlineStack gap='100'>
            <Text as='h2' variant='headingSm'>
              {' '}
              {i18n.translate(`${mapData.field}Title`)}
            </Text>
            {mapData.tip && (
              <Tooltip content={i18n.translate('tooltip')}>
                <span>
                  <Icon source={InfoIcon} tone='base' />
                </span>
              </Tooltip>
            )}{' '}
          </InlineStack>
          <Text as='p' tone='subdued'>
            {i18n.translate(`${mapData.field}Subtitle`)}
          </Text>
        </BlockStack>
        <Banner tone={mapData.bannerType}>
          <span style={{ whiteSpace: 'pre-line' }}>
            {' '}
            {i18n.translate(`${mapData.field}Tip`)}
          </span>
          <Link url='http://www.google.com' target='_blank'>
            {' '}
            {i18n.translate(`${mapData.field}TipLink`)}
          </Link>
        </Banner>
        {data && (
          <Checkbox
            label={i18n.translate(mapData.field)}
            checked={
              data?.[
                mapData.field as keyof Pick<
                  TagsMetadataSettingsDto,
                  | 'addTagFlagCouponFraud'
                  | 'storeDiscountAppDataInOrderMetafields'
                >
              ]
            }
            onChange={(value) =>
              handleTagsMetadataLocaleState(mapData.field, value)
            }
          />
        )}
      </BlockStack>
    </Card>
  );
};
