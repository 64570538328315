import { Select } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';

export type PagerOptionsType = '10' | '25' | '50' | '100' | 'all';
type PagerOption = {
  label: string;
  value: PagerOptionsType;
};

type PagerProps = {
  entriesToShow?: PagerOptionsType;
  label?: React.ReactNode;

  onChange?: (entriesToShow: PagerOptionsType) => void;
};

const options: PagerOption[] = [
  { label: '10', value: '10' },
  { label: '25', value: '25' },
  { label: '50', value: '50' },
  { label: '100', value: '100' },
  { label: 'All', value: 'all' },
];

export const Pager: React.FC<PagerProps> = (props) => {
  const { label, entriesToShow, onChange } = props;
  const [selected, setSelected] = useState<PagerOptionsType>(
    entriesToShow ?? '10'
  );

  const handleSelectChange = useCallback(
    (value: PagerOptionsType) => {
      setSelected(value);
      onChange?.(value);
    },
    [setSelected, onChange]
  );

  return (
    <Select
      label={label ?? 'Items per page: '}
      labelInline
      options={options}
      onChange={handleSelectChange}
      value={selected}
    />
  );
};
