import React from 'react';
import './SaveGlobalPresetModal.scss';
import { ModalCustom } from 'core/components/ModalCustom/ModalCustom';
import { useI18n } from '@shopify/react-i18n';
import { Text } from '@shopify/polaris';

type SaveGlobalPresetModalProps = {
  isOpen: boolean;
  onPublish: () => void;
  onClose: () => void;
};
const SaveGlobalPresetModal: React.FC<SaveGlobalPresetModalProps> = ({
  isOpen,
  onPublish,
  onClose,
}) => {
  const [i18n] = useI18n();

  return (
    <ModalCustom
      isOpen={isOpen}
      buttons={[
        {
          content: i18n.translate(`Cancel`),
          primary: false,
          action: () => onClose(),
        },
        {
          content: i18n.translate('Publish'),
          primary: true,
          action: () => onPublish(),
        },
      ]}
      title={i18n.translate('PublishChanges')}
      onClose={() => onClose()}
    >
      <div className='PresetPublishModalContent'>
        <Text as='p'>{i18n.translate('ChangingGlobalPreset')}</Text>
      </div>
    </ModalCustom>
  );
};

export default SaveGlobalPresetModal;
