import { Button, ButtonGroup } from '@shopify/polaris';
import React, { useCallback, useMemo } from 'react';

type PaginationProps = {
  page: number;
  recordsTotal: number;
  recordsPerPage: number;
  showDescription?: boolean;

  onChangePage?: (page: number) => void;
};

export const Pagination: React.FC<PaginationProps> = (props) => {
  const { page, recordsTotal, recordsPerPage, showDescription, onChangePage } =
    props;

  const handleClickPreviousButton = useCallback(() => {
    onChangePage?.(page - 1);
  }, [page, onChangePage]);

  const handleClickNextButton = useCallback(() => {
    onChangePage?.(page + 1);
  }, [page, onChangePage]);

  const disabledPreviousButton = useMemo(() => page < 2, [page]);
  const disabledNextButton = useMemo(
    () => page >= Math.ceil(recordsTotal / recordsPerPage),
    [page, recordsTotal, recordsPerPage]
  );

  const entriesShowing = useMemo(() => {
    return page * recordsPerPage;
  }, [page, recordsPerPage]);

  const entriesShowingFrom = useMemo(() => {
    return entriesShowing - recordsPerPage + 1;
  }, [entriesShowing, recordsPerPage]);

  const entriesShowingTo = useMemo(() => {
    return entriesShowing < recordsTotal ? entriesShowing : recordsTotal;
  }, [entriesShowing, recordsTotal]);

  return (
    <>
      {showDescription && (
        <p>
          Showing {entriesShowingFrom} to {entriesShowingTo} of {recordsTotal}{' '}
          entries
        </p>
      )}
      <ButtonGroup variant='segmented'>
        <Button
          onClick={handleClickPreviousButton}
          disabled={disabledPreviousButton}
        >
          Previous
        </Button>
        <Button onClick={handleClickNextButton} disabled={disabledNextButton}>
          Next
        </Button>
      </ButtonGroup>
    </>
  );
};
