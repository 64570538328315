export enum AnalyticsQueryComparisonPeriodDtoEnum {
    PREVIOUS_PERIOD = "PREVIOUS_PERIOD",
    PREVIOUS_YEAR = "PREVIOUS_YEAR"
}
export enum AnalyticsQueryPeriodDtoEnum {
    TODAY = "TODAY",
    YESTERDAY = "YESTERDAY",
    LAST_7_DAYS = "LAST_7_DAYS",
    LAST_30_DAYS = "LAST_30_DAYS",
    LAST_90_DAYS = "LAST_90_DAYS",
    LAST_365_DAYS = "LAST_365_DAYS",
    LAST_MONTH = "LAST_MONTH",
    LAST_12_MONTHS = "LAST_12_MONTHS",
    LAST_YEAR = "LAST_YEAR"
}
export enum OrderAnalyticsSortFieldDtoEnum {
    ORDER = "ORDER",
    DATE = "DATE",
    CUSTOMER = "CUSTOMER",
    ORDER_VALUE = "ORDER_VALUE",
    PROMOTION_CODES = "PROMOTION_CODES",
    PROMOTIONS_APPLIED = "PROMOTIONS_APPLIED"
}
export enum PromotionActivityAnalyticsSortFieldDtoEnum {
    PROMOTION_NAME = "PROMOTION_NAME",
    SESSIONS = "SESSIONS",
    ADD_TO_CART = "ADD_TO_CART",
    CHECKOUT_INITIATED = "CHECKOUT_INITIATED",
    ORDERS = "ORDERS",
    REVENUE = "REVENUE"
}
export enum PromotionCodeAnalyticsSortFieldDtoEnum {
    PROMOTION_CODE = "PROMOTION_CODE",
    PROMOTIONS_APPLIED = "PROMOTIONS_APPLIED",
    STATUS = "STATUS",
    USAGE_COUNT = "USAGE_COUNT",
    LIMIT = "LIMIT"
}
export enum PromotionCodeStatusDtoEnum {
    ACTIVE = "ACTIVE",
    USED = "USED"
}
export enum SortDirectionDtoEnum {
    ASC = "ASC",
    DESC = "DESC"
}
