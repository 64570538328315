import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './ProductBanner.scss';
import { OfferWidgetSetupDtoProductBannerOfferDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { useI18n } from '@shopify/react-i18n';
import { useAppSelector } from 'core/hooks';
import {
  getCurrentMessage,
  parseSmartTagCode,
} from 'features/settings/components/EditPresetSkeleton/utils/utils';
import { PreviewEntry } from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components';
import { isEmpty, isEqual } from 'lodash';
import { DeviceTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import {
  WidgetsFetchTypeEnum,
  useConfigureWidgets,
} from 'features/settings/hooks/useConfigureWidgets';
import { usePrevious } from 'core/hooks/usePrevious';

type ProductBannerProps = {
  data: any;
  previewType: DeviceTypeDtoEnum;
  defaultLanguage?: string;
  adminActivePath: string;
  getSelectionFromThePreview(path: string): void;
};

export const ProductBanner: React.FC<ProductBannerProps> = ({
  previewType,
  defaultLanguage,
  data,
  adminActivePath,
  getSelectionFromThePreview,
}) => {
  const [i18n] = useI18n();
  const { fontListData } = useConfigureWidgets(
    WidgetsFetchTypeEnum.PRESET_FONT_LIST
  );
  const {
    defaultIconLibary,
    defaultImageLibary,
    customIconLibary,
    customImageLibary,
    productBannerMessages,
  } = useAppSelector((state) => state.widgets);
  const { smartTagsCodes } = useAppSelector((state) => state.offersWizard);

  const [injectStyles, setInjectStyles] = useState<boolean>(false);
  const [currentData, setCurrentData] = useState<any>({});

  const imagesArray = useMemo(
    () => [...defaultImageLibary, ...customImageLibary],
    [customImageLibary, defaultImageLibary]
  );
  const iconsArray = useMemo(
    () => [...defaultIconLibary, ...customIconLibary],
    [customIconLibary, defaultIconLibary]
  );

  const currentMessages = useMemo(
    () =>
      data?.offers
        ? data.offers.map((item: OfferWidgetSetupDtoProductBannerOfferDto) => {
            const type = item.setup?.general
              ?.supportEntitledAndPrerequisiteProducts
              ? adminActivePath.includes('prerequisite')
                ? 'prerequisite'
                : 'entitled'
              : null;
            const currentPBMessages =
              productBannerMessages[type ? type : 'message'];
            return type
              ? {
                  token: item.offerId,
                  message: currentPBMessages.some(
                    (message) =>
                      item.offerId && message.offerId === item.offerId
                  )
                    ? parseSmartTagCode(
                        currentPBMessages.find(
                          (message) => message.offerId === item.offerId
                        )?.[previewType.toLowerCase() as keyof PreviewEntry] ||
                          '',
                        smartTagsCodes
                      )
                    : getCurrentMessage(
                        item.setup?.options?.[type]?.options?.message?.message
                          ?.entries,
                        defaultLanguage,
                        smartTagsCodes
                      ),
                  icon: item.setup?.options?.[type]?.currentEntries?.includes(
                    'ICON'
                  )
                    ? iconsArray.find(
                        (icon) =>
                          icon.id ===
                          item.setup?.options?.[type]?.options?.icon?.iconId
                      )?.url
                    : undefined,
                  image: item.setup?.options?.[type]?.currentEntries?.includes(
                    'IMAGE'
                  )
                    ? imagesArray.find(
                        (image) =>
                          image.id ===
                          item.setup?.options?.[type]?.options?.image?.image
                            ?.imageId
                      )?.url
                    : undefined,
                }
              : {
                  token: item.offerId,
                  message: currentPBMessages.some(
                    (message) =>
                      item.offerId && message.offerId === item.offerId
                  )
                    ? parseSmartTagCode(
                        currentPBMessages.find(
                          (message) => message.offerId === item.offerId
                        )?.[previewType.toLowerCase() as keyof PreviewEntry] ||
                          '',
                        smartTagsCodes
                      )
                    : getCurrentMessage(
                        item.setup?.options?.message?.message?.entries,
                        defaultLanguage,
                        smartTagsCodes
                      ),
                  icon: item.setup?.currentEntries?.includes('ICON')
                    ? iconsArray.find(
                        (icon) => icon.id === item.setup?.options?.icon?.iconId
                      )?.url
                    : undefined,
                  image: item.setup?.currentEntries?.includes('IMAGE')
                    ? imagesArray.find(
                        (image) =>
                          image.id ===
                          item.setup?.options?.image?.image?.imageId
                      )?.url
                    : undefined,
                };
          })
        : [
            {
              token: 'example1',
              message: i18n.translate('example'),
              icon: iconsArray[0].url,
              image: null,
            },
            {
              token: 'example2',
              message: i18n.translate('example'),
              icon: null,
              image: imagesArray[0].url,
            },
          ],
    [
      i18n,
      data.options,
      data?.offers,
      iconsArray,
      imagesArray,
      defaultLanguage,
      productBannerMessages,
      previewType,
      adminActivePath,
      smartTagsCodes,
    ]
  );

  const prevMessages = usePrevious(currentMessages);

  const elementToReplaceWith = useMemo(
    () =>
      `
  <la-dn-product-banner
    admin-mode="true"
    admin-active-path="${adminActivePath}"
    admin-mode-label='[{"component":"productBanner","label":"${i18n.translate(
      'productBannerLabel'
    )}","icon":"widget","subComponents":[{"component":"banner","label":"${i18n.translate(
        'offerLabel'
      )}","icon":"offer","subComponents":[{"component":"entitled","label":"${i18n.translate(
        'entitled'
      )}","icon":"component"},{"component":"prerequisite","label":"${i18n.translate(
        'prerequisite'
      )}","icon":"component"},{"component":"image","label":"${i18n.translate(
        'imageLabel'
      )}","icon":"component"},{"component":"icon","label":"${i18n.translate(
        'iconLabel'
      )}","icon":"component"},{"component":"message","label":"${i18n.translate(
        'messageLabel'
      )}","icon":"component"},{"component":"link","label":"${i18n.translate(
        'linkLabel'
      )}","icon":"component"}]}]}]'
  product-handle="1"
  replace-element="#replace-product-banner"
  ></la-dn-product-banner>
`,
    [adminActivePath, i18n]
  );

  const showProductBanner = useCallback(
    (data: any) => {
      const container = document.querySelector('#product-banner-root');
      container && (container.innerHTML = elementToReplaceWith);
      setCurrentData(data);
    },
    [elementToReplaceWith]
  );

  useEffect(() => {
    if (!isEmpty(currentData)) {
      document.dispatchEvent(
        new CustomEvent('la:dn:product-banner:render', { detail: currentData })
      );
      setInjectStyles(true);
    }
  }, [currentData]);

  useEffect(() => {
    if (injectStyles) {
      const banner = document.querySelector('la-dn-product-banner');
      banner?.setAttribute(
        'force-mobile',
        (previewType === DeviceTypeDtoEnum.MOBILE).toString()
      );
    }
  }, [injectStyles, previewType]);

  useEffect(() => {
    if (injectStyles) {
      const banner = document.querySelector('la-dn-product-banner');
      banner?.setAttribute('styles', JSON.stringify(data));
    }
  }, [injectStyles, data]);

  useEffect(() => {
    if (injectStyles) {
      const banner = document.querySelector('la-dn-product-banner');
      banner?.setAttribute('font-assets', JSON.stringify(fontListData));
    }
  }, [fontListData, injectStyles]);

  useEffect(() => {
    if (injectStyles) {
      const banner = document.querySelector('la-dn-product-banner');
      banner?.setAttribute('admin-active-path', adminActivePath);
    }
  }, [adminActivePath]);

  useEffect(() => {
    showProductBanner({
      'product-handle': '1',
      messages: currentMessages,
    });
    document
      .querySelector('la-dn-product-banner')!
      .addEventListener('la:dn:product-banner:admin-change', (e) =>
        getSelectionFromThePreview((e as CustomEvent).detail)
      );
  }, []);

  useEffect(() => {
    if (
      currentMessages &&
      injectStyles &&
      !isEqual(currentMessages, prevMessages)
    ) {
      document.dispatchEvent(
        new CustomEvent('la:dn:product-banner:update', {
          detail: {
            'product-handle': '1',
            messages: currentMessages,
          },
        })
      );
    }
  }, [currentMessages]);

  return (
    <div id='product-banner-root' className='product-banner-root'>
      <div
        id='replace-product-banner'
        style={{ backgroundColor: 'transparent' }}
      />
    </div>
  );
};
