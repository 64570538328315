import React, { useCallback, useEffect, useState } from 'react';
import { BlockStack, Page } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';

import {
  CheckoutModeCheckoutSettingsDto,
  CheckoutSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import {
  setIsUnsavedChanges,
  setOnDiscardChanges,
  setOnSaveChanges,
} from 'core/store/settingsSlice';
import { SettingsSkeleton } from '../components/SettingsSkeleton/SettingsSkeleton';
import { PreferredCheckoutMode } from './components/PreferredCheckoutMode/PreferredCheckoutMode';
import { FallbackMode } from './components/FallbackMode/FallbackMode';
import { InternationalPricing } from './components/InternationalPricing/InternationalPricing';
import { DynamicCheckoutButtons } from './components/DynamicCheckoutButtons/DynamicCheckoutButtons';
import { DiscountCodePrefix } from './components/DiscountCodePrefix/DiscountCodePrefix';
import { RemoveDraftOrders } from './components/RemoveDraftOrders/RemoveDraftOrders';
import { CheckoutLoadingEffect } from './components/CheckoutLoadingEffect/CheckoutLoadingEffect';
import { CheckoutModeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';

export const CheckoutOptions: React.FC = () => {
  const [i18n] = useI18n();
  const {
    checkoutOptionsData,
    checkoutOptionsIsFetching,
    saveCheckoutOptions,
  } = useConfigureSettings(SettingsFetchTypeEnum.CHECKOUT_OPTIONS);
  const [checkoutState, setCheckoutState] = useState<CheckoutSettingsDto>({});
  const [savedData, setSavedData] = useState<CheckoutSettingsDto>({});
  const dispatch = useDispatch();

  const mode = checkoutState.checkoutModeSettings?.mode;
  const handleCheckoutModeSettings = useCallback(
    (
      field: keyof CheckoutModeCheckoutSettingsDto,
      data: CheckoutModeCheckoutSettingsDto[keyof CheckoutModeCheckoutSettingsDto]
    ) =>
      setCheckoutState({
        ...checkoutState,
        checkoutModeSettings: {
          ...checkoutState.checkoutModeSettings,
          [field]: data,
        },
      }),
    [checkoutState]
  );

  useEffect(() => {
    if (!isEmpty(checkoutState) && !isEqual(savedData, checkoutState)) {
      dispatch(setIsUnsavedChanges(true));
      dispatch(
        setOnDiscardChanges(() => {
          if (!isEmpty(savedData)) {
            setCheckoutState(savedData);
          }
        })
      );
      dispatch(
        setOnSaveChanges(() => {
          setSavedData(checkoutState);
          return saveCheckoutOptions(checkoutState || {});
        })
      );
    } else if (isEqual(savedData, checkoutState)) {
      dispatch(setIsUnsavedChanges(false));
    }
  }, [checkoutState, savedData, dispatch]);

  useEffect(() => {
    if (!isEmpty(checkoutOptionsData)) {
      setCheckoutState(checkoutOptionsData);
      setSavedData(checkoutOptionsData);
    }
    !isEmpty(checkoutOptionsData) && setCheckoutState(checkoutOptionsData);
  }, [checkoutOptionsData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  return (
    <>
      {!checkoutOptionsIsFetching && checkoutState ? (
        <Page title={i18n.translate('CheckoutOptions')}>
          <BlockStack gap='600'>
            <PreferredCheckoutMode
              checkoutMode={checkoutState.checkoutModeSettings || {}}
              isLegacyFlow={!!checkoutState.isLegacyFlow}
              setCheckoutMode={(value) =>
                handleCheckoutModeSettings('mode', value)
              }
            />
            {checkoutState.isLegacyFlow && (
              <FallbackMode
                fallbackMode={checkoutState.checkoutModeSettings?.fallbackMode}
                setFallbackMode={(value) =>
                  handleCheckoutModeSettings('fallbackMode', value)
                }
              />
            )}

            {checkoutState.isLegacyFlow &&
              (checkoutState.checkoutModeSettings?.internationalPricing
                ?.supportedCurrenciesAtCheckout?.length as number) > 1 && (
                <InternationalPricing
                  internationalPricing={
                    checkoutState.checkoutModeSettings?.internationalPricing
                  }
                  setInternationalPricing={(data) =>
                    handleCheckoutModeSettings('internationalPricing', data)
                  }
                />
              )}

            {mode !== CheckoutModeDtoEnum.SHOPIFY_FUNCTIONS &&
              mode !== CheckoutModeDtoEnum.DRAFT_ORDER && (
                <DiscountCodePrefix
                  prefix={
                    checkoutState.checkoutModeSettings?.discountCodesPrefix ||
                    {}
                  }
                  setPrefix={(data) =>
                    handleCheckoutModeSettings('discountCodesPrefix', data)
                  }
                />
              )}
            {mode === CheckoutModeDtoEnum.DRAFT_ORDER && (
              <RemoveDraftOrders
                draftMode={
                  checkoutState.checkoutModeSettings?.draftOrderRemovalOption ||
                  {}
                }
                setDraftMode={(data) =>
                  handleCheckoutModeSettings('draftOrderRemovalOption', data)
                }
              />
            )}
            <DynamicCheckoutButtons
              dynamicCheckout={checkoutState.dynamicCheckoutButtons}
              setDynamicCheckout={(data) =>
                setCheckoutState({
                  ...checkoutState,
                  dynamicCheckoutButtons: data,
                })
              }
            />

            <CheckoutLoadingEffect
              loadingEffect={checkoutState.checkoutButtonLoadingEffectDuration}
              setLoadingEffect={(data) =>
                setCheckoutState({
                  ...checkoutState,
                  checkoutButtonLoadingEffectDuration: data,
                })
              }
            />
          </BlockStack>
        </Page>
      ) : (
        <SettingsSkeleton />
      )}
    </>
  );
};
