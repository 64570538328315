import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Banner,
  Bleed,
  BlockStack,
  Box,
  Card,
  Checkbox,
  Divider,
  Link,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  OfferCombinationsDto,
  OfferCombinationsExcludedOfferDto,
  OfferRelationalCombinationTypeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { OfferCombinationTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { useAppSelector } from 'core/hooks';
import './Combinations.scss';
import { Loader } from 'core/components';
import { DoNotCombineWith } from './components/DoNotCombineWith/DoNotCombineWith';
import { CombinationsOrderDiscount } from './components/CombinationsOrderDiscount/CombinationsOrderDiscount';
import { CombinationsProductDiscount } from './components/CombinationsProductDiscount.tsx/CombinationsProductDiscount';
import { CombinationsShippingDiscount } from './components/CombinationsShippingDiscount/CombinationsShippingDiscount';

export type CombinationsProps = {
  onCombinationsChange(data: OfferCombinationsDto): void;
};

export const Combinations: React.FC<CombinationsProps> = (props) => {
  const { onCombinationsChange } = props;

  const { currentCombination, currentCombinationIsLoaded } = useAppSelector(
    (state) => state.offersWizard
  );

  const [showDoNotCombine, setShowDoNotCombine] = useState<boolean>(false);
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const [combinationsDraftState, setCombinationsDraftState] =
    useState<OfferCombinationsDto>({});
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [tempExcludedOffers, setTempExcludedOffers] = useState<
    OfferCombinationsExcludedOfferDto[]
  >([]);

  const [i18n] = useI18n();

  const onCombinationsDraftStateUpdate = useCallback(
    (props: OfferCombinationsDto) => {
      const newCombinationsDraftState =
        combinationsDraftState && Object.assign(combinationsDraftState, props);
      setCombinationsDraftState(newCombinationsDraftState);
    },
    [setCombinationsDraftState, combinationsDraftState]
  );

  const handleChange = useCallback((value: boolean) => {
    setShowDoNotCombine(value);
    setIsChanged(true);
    !value &&
      onCombinationsDraftStateUpdate({
        excludedOffersIds: [],
      });
  }, []);

  const getCombinationType = useCallback(
    (type: OfferRelationalCombinationTypeDto) => {
      return currentCombination?.availableCombinations?.find(
        (combination) => combination.type === type
      );
    },
    [currentCombination?.availableCombinations]
  );

  const checkboxes = useMemo(() => {
    switch (currentCombination.currentCombinationType) {
      case OfferCombinationTypeDtoEnum.PRODUCT:
        return (
          <CombinationsProductDiscount
            shippingDiscounts={getCombinationType('SHIPPING')}
            productDiscounts={getCombinationType('OTHER_PRODUCT')}
            orderDiscounts={getCombinationType('ORDER')}
            onCombinationsDraftStateUpdate={onCombinationsDraftStateUpdate}
            setIsChanged={setIsChanged}
            setShowBanner={setShowBanner}
          />
        );
      case OfferCombinationTypeDtoEnum.ORDER:
        return (
          <CombinationsOrderDiscount
            shippingDiscounts={getCombinationType('SHIPPING')}
            productDiscounts={getCombinationType('PRODUCT')}
            orderDiscounts={getCombinationType('OTHER_ORDER')}
            onCombinationsDraftStateUpdate={onCombinationsDraftStateUpdate}
            setIsChanged={setIsChanged}
            setShowBanner={setShowBanner}
          />
        );
      case OfferCombinationTypeDtoEnum.SHIPPING:
        return (
          <CombinationsShippingDiscount
            productDiscounts={getCombinationType('PRODUCT')}
            orderDiscounts={getCombinationType('ORDER')}
            onCombinationsDraftStateUpdate={onCombinationsDraftStateUpdate}
            setIsChanged={setIsChanged}
          />
        );
      default:
        return <></>;
    }
  }, [
    currentCombination.currentCombinationType,
    getCombinationType,
    onCombinationsDraftStateUpdate,
  ]);

  useEffect(() => {
    if (Object.keys(combinationsDraftState || {}).length && isChanged) {
      onCombinationsChange(combinationsDraftState);
      setIsChanged(false);
    }
  }, [combinationsDraftState, isChanged, onCombinationsChange]);

  useEffect(() => {
    currentCombination.excludedOffers?.length &&
      setTempExcludedOffers(currentCombination.excludedOffers);
    if (currentCombination.excludedOffers?.length && !showDoNotCombine) {
      setShowDoNotCombine(true);
    }
  }, [currentCombination.excludedOffers?.length]);

  return (
    <Card roundedAbove='sm' padding='400'>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate('Combinations.Title')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('Combinations.Subtitle')}
          </Text>
        </BlockStack>
        {showBanner && (
          <Banner
            tone='warning'
            title={i18n.translate('Combinations.BannerTitle')}
          >
            <Text as='p'>
              {i18n.translate('Combinations.BannerContent', {
                link: (
                  <Link url='/'>
                    {i18n.translate('Combinations.LearnMore')}
                  </Link>
                ),
              })}
            </Text>
          </Banner>
        )}
        {currentCombinationIsLoaded ? (
          <>
            <BlockStack>{checkboxes}</BlockStack>
            <Divider />
            <Checkbox
              label={i18n.translate('Combinations.CheckboxInfo')}
              checked={showDoNotCombine}
              onChange={handleChange}
            />
            {showDoNotCombine && (
              <>
                <DoNotCombineWith
                  tempExcludedOffers={tempExcludedOffers}
                  setIsChanged={setIsChanged}
                  onCombinationsDraftStateUpdate={
                    onCombinationsDraftStateUpdate
                  }
                />
                <Banner>
                  <Text as='p'>
                    {i18n.translate('Combinations.BannerInfo', {
                      link: (
                        <Link url='/'>
                          {i18n.translate('Combinations.LearnMore')}
                        </Link>
                      ),
                    })}
                  </Text>
                </Banner>
              </>
            )}
          </>
        ) : (
          <Loader size='large' fullWidth />
        )}
        <Bleed marginInline='400' marginBlockEnd='400'>
          <Box padding='400' background='bg-surface-secondary'>
            <Text as='span' tone='subdued'>
              {i18n.translate('Combinations.Footer', {
                link: (
                  <Link url='/'>
                    {i18n.translate('Combinations.FollowingArticle')}
                  </Link>
                ),
              })}
            </Text>
          </Box>
        </Bleed>
      </BlockStack>
    </Card>
  );
};
