import { formatDate, formatDateFromString, validDateString } from 'core/utils';
import { isAfter, isBefore } from 'date-fns';
import React, { useState, useCallback, ChangeEvent, useEffect } from 'react';
import ReactInputMask from 'react-input-mask';

type DateFieldProps = {
  value?: string | number | readonly string[];
  label?: string | number;
  max?: Date;
  min?: Date;
  datePattern?: string;
  onChange?: (value: string) => void;
};

export const DateField: React.FC<DateFieldProps> = (props) => {
  const { value, label, max, min, datePattern, onChange } = props;
  const [localValue, setLocalValue] = useState<
    string | number | readonly string[] | undefined
  >(value);
  const [minDate] = useState<Date>(min || new Date('2010-01-01'));

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleChangeValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let { value } = e.target;

      if (minDate && isBefore(formatDateFromString(value), minDate)) {
        value = formatDate(minDate);
      }

      if (max && isAfter(formatDateFromString(value), max)) {
        value = formatDate(max);
      }

      setLocalValue(value);
      if (validDateString(value)) {
        onChange?.(value);
      }
    },
    [minDate, max, onChange]
  );

  return (
    <div>
      {label && (
        <div className='Polaris-Labelled__LabelWrapper'>
          <div className='Polaris-Label'>
            <label className='Polaris-Label__Text'>{label}</label>
          </div>
        </div>
      )}
      <div className='Polaris-Connected'>
        <div className='Polaris-Connected__Item Polaris-Connected__Item--primary'>
          <div className='Polaris-TextField Polaris-TextField--hasValue'>
            <ReactInputMask
              mask={datePattern || '99/99/9999'}
              value={localValue}
              onChange={handleChangeValue}
              className='Polaris-TextField__Input'
            />
            <div className='Polaris-TextField__Backdrop'></div>
          </div>
        </div>
      </div>
    </div>
  );
};
