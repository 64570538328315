import React, { useCallback } from 'react';
import { Button, IconSource, Tooltip } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { DuplicateIcon } from '@shopify/polaris-icons';
import { useAppDispatch } from 'core/hooks';
import { setToastMessages } from 'core/store/offersWizardSlice';
type CopyIconProps = {
  copyText?: string | null;
  tooltip?: string;
  icon?: IconSource;
  buttonText?: string;
};
export const CopyIcon: React.FC<CopyIconProps> = ({
  tooltip,
  copyText,
  icon,
  buttonText,
}) => {
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();

  const onCopyText = useCallback(() => {
    navigator.clipboard.writeText(copyText || '');
    dispatch(
      setToastMessages({
        error: false,
        message: 'CopiedToClipboard',
      })
    );
  }, [dispatch, copyText]);

  return (
    <div style={{ maxHeight: 20 }} className='CopyIcon'>
      <Tooltip content={tooltip ? tooltip : i18n.translate('CopyLink')}>
        <Button
          icon={buttonText ? undefined : icon ? icon : DuplicateIcon}
          variant='monochromePlain'
          onClick={onCopyText}
        >
          {buttonText}
        </Button>
      </Tooltip>
    </div>
  );
};
