import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './StrikethroughPricing.scss';
import { useI18n } from '@shopify/react-i18n';
import { useAppSelector } from 'core/hooks';
import {
  getCurrentMessage,
  parseSmartTagCode,
} from 'features/settings/components/EditPresetSkeleton/utils/utils';
import { BodyRowPricingModuleEntryTypeDtoEnum, DeviceTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import {
  WidgetsFetchTypeEnum,
  useConfigureWidgets,
} from 'features/settings/hooks/useConfigureWidgets';
import { PreviewEntry } from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components';
import { isEmpty } from 'lodash';
import { formatStringQuotes } from '../../utils/utils';
import { transformWidgetsList } from 'features/settings/components/EditPresetSkeleton/utils/leftMenuGenerators';
import classNames from 'classnames';

type StrikethroughPricingProps = {
  data: any;
  previewType: DeviceTypeDtoEnum;
  defaultLanguage?: string;
  adminActivePath: string;
  getSelectionFromThePreview(path: string): void;
  root: string;
  priceWidgetType: string;
  isPreset: boolean;
};

export const StrikethroughPricing: React.FC<StrikethroughPricingProps> = ({
  previewType,
  defaultLanguage,
  data,
  adminActivePath,
  getSelectionFromThePreview,
  root,
  priceWidgetType,
  isPreset,
}) => {
  const [i18n] = useI18n();
  const { fontListData } = useConfigureWidgets(
    WidgetsFetchTypeEnum.PRESET_FONT_LIST
  );

  const { pricePageEntries } = useAppSelector((state) => state.widgets);
  const { smartTagsCodes } = useAppSelector((state) => state.offersWizard);

  const [currentData, setCurrentData] = useState<any>({});
  const [injectStyles, setInjectStyles] = useState<boolean>(false);

  const currentIndex: number = useMemo(() => {
    const match = adminActivePath.match(/banner_(\d+)/);
    if (match) {
      return +match[1];
    } else {
      return 0;
    }
  }, [adminActivePath]);

  const currentMessages = useMemo(
    () => ({
      token: 'ABCD',
      location: 'collection',
      productHandle: root,
    }),
    [root]
  );

  const currentAdminActivePath = useMemo(
    () =>
      isPreset ? adminActivePath.replace(`${root}.`, '') : adminActivePath,
    [adminActivePath, isPreset]
  );

  const currentRoot = useMemo(
    () => (isPreset ? priceWidgetType + 'Price' : root),
    [priceWidgetType, root]
  );

  const laDnPrice = document.querySelectorAll(
    `la-dn-price[admin-mode-root="${currentRoot}"]`
  );

  const currentEntries = useMemo(
    () =>
      transformWidgetsList(
        data.options?.[`${priceWidgetType}Price`]?.currentEntries
      ),
    [data.options, priceWidgetType]
  );

  const header = useMemo(
    () =>
      formatStringQuotes(
        (pricePageEntries as any)[root]?.[`${priceWidgetType}Price`]
          ?.headerText?.[previewType.toLowerCase() as keyof PreviewEntry]
          ? parseSmartTagCode(
            (pricePageEntries as any)[root]?.[`${priceWidgetType}Price`]
              ?.headerText?.[
            previewType.toLowerCase() as keyof PreviewEntry
            ] || '',
            smartTagsCodes
          )
          : data?.offers
            ? getCurrentMessage(
              data?.offers?.[currentIndex]?.setup?.options?.headerText?.message
                ?.entries || '',
              defaultLanguage,
              smartTagsCodes
            )
            : getCurrentMessage(
              data.options?.[`${priceWidgetType}Price`]?.options?.headerText
                ?.general?.text?.message?.entries || '',
              defaultLanguage,
              smartTagsCodes
            )
      ),
    [
      data?.offers,
      currentIndex,
      data.options,
      priceWidgetType,
      defaultLanguage,
      smartTagsCodes,
      root,
      pricePageEntries,
      previewType,
    ]
  );

  const footer = useMemo(
    () =>
      formatStringQuotes(
        (pricePageEntries as any)[root]?.[`${priceWidgetType}Price`]
          ?.footerText?.[previewType.toLowerCase() as keyof PreviewEntry]
          ? parseSmartTagCode(
            (pricePageEntries as any)[root]?.[`${priceWidgetType}Price`]
              ?.footerText?.[
            previewType.toLowerCase() as keyof PreviewEntry
            ] || '',
            smartTagsCodes
          )
          : data?.offers
            ? getCurrentMessage(
              data?.offers?.[currentIndex]?.setup?.options?.footerText?.message
                ?.entries || '',
              defaultLanguage,
              smartTagsCodes
            )
            : getCurrentMessage(
              data.options?.[`${priceWidgetType}Price`]?.options?.footerText
                ?.general?.text?.message?.entries || '',
              defaultLanguage,
              smartTagsCodes
            )
      ),
    [
      data?.offers,
      currentIndex,
      data.options,
      priceWidgetType,
      defaultLanguage,
      smartTagsCodes,
      root,
      pricePageEntries,
      previewType,
    ]
  );

  const row1 = useMemo(() => {
    const price = (pricePageEntries as any)[root]?.[`${priceWidgetType}Price`]
      ?.bodyRow1?.price?.[previewType.toLowerCase() as keyof PreviewEntry]
      ? parseSmartTagCode(
        (pricePageEntries as any)[root]?.[`${priceWidgetType}Price`]?.bodyRow1
          ?.price?.[previewType.toLowerCase() as keyof PreviewEntry],
        smartTagsCodes
      )
      : getCurrentMessage(
        data.options?.[`${priceWidgetType}Price`]?.options?.bodyRow1?.options
          ?.price?.text?.message?.entries,
        defaultLanguage,
        smartTagsCodes
      );

    const defaultBadgeText = getCurrentMessage(
      data.options?.[`${priceWidgetType}Price`]?.options?.bodyRow1?.options
        ?.badge?.text?.message?.entries,
      defaultLanguage,
      smartTagsCodes
    )
      ? `|##${getCurrentMessage(
        data.options?.[`${priceWidgetType}Price`]?.options?.bodyRow1?.options
          ?.badge?.text?.message?.entries,
        defaultLanguage,
        smartTagsCodes
      )}##`
      : '';

    const showBadge = data.options?.[`${priceWidgetType}Price`]?.options?.bodyRow1?.currentEntries?.includes(BodyRowPricingModuleEntryTypeDtoEnum.BADGE)

    const badge =
      showBadge
        ? (pricePageEntries as any)[root]?.[`${priceWidgetType}Price`]
          ?.bodyRow1?.badge?.[previewType.toLowerCase() as keyof PreviewEntry]
          ? parseSmartTagCode(
            `|##${(pricePageEntries as any)[root]?.[`${priceWidgetType}Price`]
              ?.bodyRow1?.badge?.[
            previewType.toLowerCase() as keyof PreviewEntry
            ]
            }##`,
            smartTagsCodes
          )
          : defaultBadgeText
        : ''

    if (currentEntries?.includes('bodyRow1')) {
      return formatStringQuotes(price + badge);
    } else {
      return null;
    }
  }, [
    data.options,
    priceWidgetType,
    defaultLanguage,
    smartTagsCodes,
    root,
    pricePageEntries,
    previewType,
    currentEntries,
  ]);

  const row2 = useMemo(() => {
    const price = (pricePageEntries as any)[root]?.[`${priceWidgetType}Price`]
      ?.bodyRow2?.price?.[previewType.toLowerCase() as keyof PreviewEntry]
      ? parseSmartTagCode(
        (pricePageEntries as any)[root]?.[`${priceWidgetType}Price`]?.bodyRow2
          ?.price?.[previewType.toLowerCase() as keyof PreviewEntry],
        smartTagsCodes
      )
      : getCurrentMessage(
        data.options?.[`${priceWidgetType}Price`]?.options?.bodyRow2?.options
          ?.price?.text?.message?.entries,
        defaultLanguage,
        smartTagsCodes
      );

    const defaultBadgeText = getCurrentMessage(
      data.options?.[`${priceWidgetType}Price`]?.options?.bodyRow2?.options
        ?.badge?.text?.message?.entries,
      defaultLanguage,
      smartTagsCodes
    )
      ? `|##${getCurrentMessage(
        data.options?.[`${priceWidgetType}Price`]?.options?.bodyRow2?.options
          ?.badge?.text?.message?.entries,
        defaultLanguage,
        smartTagsCodes
      )}##`
      : '';

    const showBadge = data.options?.[`${priceWidgetType}Price`]?.options?.bodyRow2?.currentEntries?.includes(BodyRowPricingModuleEntryTypeDtoEnum.BADGE)

    const badge =
      showBadge
        ? (pricePageEntries as any)[root]?.[`${priceWidgetType}Price`]
          ?.bodyRow2?.badge?.[previewType.toLowerCase() as keyof PreviewEntry]
          ? parseSmartTagCode(
            `|##${(pricePageEntries as any)[root]?.[`${priceWidgetType}Price`]
              ?.bodyRow2?.badge?.[
            previewType.toLowerCase() as keyof PreviewEntry
            ]
            }##`,
            smartTagsCodes
          )
          : defaultBadgeText
        : ''

    if (currentEntries?.includes('bodyRow2')) {
      return formatStringQuotes(price + badge);
    } else {
      return null;
    }
  }, [
    data.options,
    priceWidgetType,
    defaultLanguage,
    smartTagsCodes,
    root,
    pricePageEntries,
    previewType,
    currentEntries,
  ]);

  const row3 = useMemo(() => {
    const price = (pricePageEntries as any)[root]?.[`${priceWidgetType}Price`]
      ?.bodyRow3?.price?.[previewType.toLowerCase() as keyof PreviewEntry]
      ? parseSmartTagCode(
        (pricePageEntries as any)[root]?.[`${priceWidgetType}Price`]?.bodyRow3
          ?.price?.[previewType.toLowerCase() as keyof PreviewEntry],
        smartTagsCodes
      )
      : getCurrentMessage(
        data.options?.[`${priceWidgetType}Price`]?.options?.bodyRow3?.options
          ?.price?.text?.message?.entries,
        defaultLanguage,
        smartTagsCodes
      );

    const defaultBadgeText = getCurrentMessage(
      data.options?.[`${priceWidgetType}Price`]?.options?.bodyRow3?.options
        ?.badge?.text?.message?.entries,
      defaultLanguage,
      smartTagsCodes
    )
      ? `|##${getCurrentMessage(
        data.options?.[`${priceWidgetType}Price`]?.options?.bodyRow3?.options
          ?.badge?.text?.message?.entries,
        defaultLanguage,
        smartTagsCodes
      )}##`
      : '';

    const showBadge = data.options?.[`${priceWidgetType}Price`]?.options?.bodyRow3?.currentEntries?.includes(BodyRowPricingModuleEntryTypeDtoEnum.BADGE)

    const badge =
      showBadge
        ? (pricePageEntries as any)[root]?.[`${priceWidgetType}Price`]
          ?.bodyRow3?.badge?.[previewType.toLowerCase() as keyof PreviewEntry]
          ? parseSmartTagCode(
            `|##${(pricePageEntries as any)[root]?.[`${priceWidgetType}Price`]
              ?.bodyRow3?.badge?.[
            previewType.toLowerCase() as keyof PreviewEntry
            ]
            }##`,
            smartTagsCodes
          )
          : defaultBadgeText
        : ''

    if (currentEntries?.includes('bodyRow3')) {
      return formatStringQuotes(price + badge);
    } else {
      return null;
    }
  }, [
    data.options,
    priceWidgetType,
    defaultLanguage,
    smartTagsCodes,
    root,
    pricePageEntries,
    previewType,
    currentEntries,
  ]);


  const isDiscounted = useMemo(
    () => priceWidgetType === 'discounted',
    [priceWidgetType]
  );

  const currentLayout = useMemo(() => {
    let layout = '';

    if (isDiscounted) {
      layout += '[header]';

      if (row1) {
        layout += `[${row1}]`;
      }

      if (row2) {
        layout += `[${row2}]`;
      }

      if (row3) {
        layout += `[${row3}]`;
      }

      layout += '[footer]';
    } else {
      if (row1) {
        layout += `[${row1}]`;
      }

      if (row2) {
        layout += `[${row2}]`;
      }

      if (row3) {
        layout += `[${row3}]`;
      }
    }

    return layout;
  }, [row1, row2, row3, isDiscounted]);

  const elementToReplaceWith = useMemo(
    () =>
      `
            <la-dn-price
    admin-mode="true"
    admin-active-path="${currentAdminActivePath}"
    admin-mode-root="${currentRoot}"
    admin-mode-label='[{"component":"${currentRoot}","label":"${i18n.translate(
        'price'
      )}","icon":"widget","subComponents":[{"component":"banner","label":"${i18n.translate(
        'price'
      )}","icon":"offer"},{"component":"discountedPrice","label":"${i18n.translate(
        'discountedPrice'
      )}","icon":"component"},{"component":"notDiscountedPrice","label":"${i18n.translate(
        'notDiscountedPrice'
      )}","icon":"component"},{"component":"headerText","label":"${i18n.translate(
        'headerText'
      )}","icon":"component"},{"component":"footerText","label":"${i18n.translate(
        'footerText'
      )}","icon":"component"},{"component":"bodyRow1","label":"${i18n.translate(
        'bodyRow1'
      )}","icon":"component","subComponents":[{"component":"price","label":"${i18n.translate(
        'price'
      )}","icon":"component"},{"component":"badge","label":"${i18n.translate(
        'badge'
      )}","icon":"component"}]},{"component":"bodyRow2","label":"${i18n.translate(
        'bodyRow2'
      )}","icon":"component","subComponents":[{"component":"price","label":"${i18n.translate(
        'price'
      )}","icon":"component"},{"component":"badge","label":"${i18n.translate(
        'badge'
      )}","icon":"component"}]},{"component":"bodyRow3","label":"${i18n.translate(
        'bodyRow3'
      )}","icon":"component","subComponents":[{"component":"price","label":"${i18n.translate(
        'price'
      )}","icon":"component"},{"component":"badge","label":"${i18n.translate(
        'badge'
      )}","icon":"component"}]}]}]'
    index="${currentIndex}"

    location="collection"
    product-handle="${root}"
    offer-token="ABCD"
    variant-id="123456"

    available="true"
    discounted="${isDiscounted}"

    layout="${currentLayout}"
    header="${header}"
    footer="${footer}"
  ></la-dn-price>
            `,
    [
      currentAdminActivePath,
      i18n,
      currentIndex,
      header,
      footer,
      isDiscounted,
      currentRoot,
      currentLayout,
    ]
  );

  const showStrikethroughPricing = useCallback(
    (data: any) => {
      const parser = new DOMParser();
      const container = document.querySelector(
        `#strike-through-pricing-root-${priceWidgetType}`
      );

      const barComponent = parser
        .parseFromString(elementToReplaceWith, 'text/html')
        .querySelector('la-dn-price');

      setCurrentData({ messages: data.messages });

      if (
        container &&
        barComponent &&
        !container.querySelector('la-dn-price')
      ) {
        container?.appendChild(barComponent);
      } else if (
        container &&
        barComponent &&
        container.querySelector('la-dn-price')
      ) {
        const currentPrice = container.querySelector('la-dn-price');

        if (currentPrice) {
          currentPrice.innerHTML = barComponent.innerHTML;

          currentPrice.setAttribute(
            'header',
            barComponent.getAttribute('header') || ''
          );
          currentPrice.setAttribute(
            'footer',
            barComponent.getAttribute('footer') || ''
          );
          currentPrice.setAttribute(
            'layout',
            barComponent.getAttribute('layout') || ''
          );
          currentPrice.setAttribute(
            'index',
            barComponent.getAttribute('index') || ''
          );
        }
      }
    },
    [setCurrentData, elementToReplaceWith]
  );

  useEffect(() => {
    if (!isEmpty(currentData)) {
      document.dispatchEvent(
        new CustomEvent('la:dn:price:render', {
          detail: {
            message: currentData.messages,
          },
        })
      );
      setInjectStyles(true);
    }
  }, [currentData]);

  useEffect(() => {
    if (injectStyles) {
      laDnPrice.forEach((price) => {
        price.setAttribute('admin-active-path', currentAdminActivePath);
      });
    }
  }, [currentAdminActivePath]);

  useEffect(() => {
    if (injectStyles) {
      laDnPrice.forEach((price) => {
        price.setAttribute(
          'force-mobile',
          (previewType === DeviceTypeDtoEnum.MOBILE).toString()
        );
      });
    }
  }, [injectStyles, previewType]);

  useEffect(() => {
    if (injectStyles) {
      laDnPrice.forEach((price) => {
        price.setAttribute('styles', JSON.stringify(data));
      });
    }
  }, [injectStyles, data]);

  useEffect(() => {
    if (injectStyles) {
      laDnPrice.forEach((price) => {
        price.setAttribute('font-assets', JSON.stringify(fontListData));
      });
    }
  }, [fontListData, injectStyles]);

  useEffect(() => {
    showStrikethroughPricing({
      messages: currentMessages,
    });

    document
      .querySelectorAll(`la-dn-price[admin-mode-root="${currentRoot}"]`)
      .forEach((price) => {
        price.addEventListener('la:dn:price:admin-change', (e) => {
          getSelectionFromThePreview(
            isPreset
              ? `${root}.${(e as CustomEvent).detail}`
              : (e as CustomEvent).detail
          );
        });
      });
  }, [header, footer, currentLayout, currentIndex]);

  return (
    <div
      className={classNames('strike-through-pricing-root', {
        isNotPreset: !isPreset,
        isCollectionPagePrice: root === 'collectionPagePrice',
      })}
      id={`strike-through-pricing-root-${priceWidgetType}`}
    ></div>
  );
};
