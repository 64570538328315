import {
  useGetPromotionsV6LookupAppBridgeResourcesCollectionsQuery,
  useGetPromotionsV6LookupAppBridgeResourcesProductVariantsQuery,
  useGetPromotionsV6LookupAppBridgeResourcesProductsQuery,
} from 'core/api/adminPromotions/adminPromotionsApi';
import resolveEnvVar from 'env-var-resolver';

const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';

export const useResourcePickerResources = () => {
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';
  const {
    isLoading: collectionListListIsLoading,
    isError: collectionListIsError,
    isSuccess: collectionListIsSuccess,
    data: collectionListData,
    error: collectionListError,
    refetch: fetchCollectionList,
  } = useGetPromotionsV6LookupAppBridgeResourcesCollectionsQuery({
    'X-LimoniApps-AppName': APP_NAME,
    'X-LimoniApps-AppSecret': APP_PASSWORD,
  });

  const {
    isLoading: productsListListIsLoading,
    isError: productsListIsError,
    isSuccess: productsListIsSuccess,
    data: productsListData,
    error: productsListError,
    refetch: fetchProductsList,
  } = useGetPromotionsV6LookupAppBridgeResourcesProductsQuery({
    'X-LimoniApps-AppName': APP_NAME,
    'X-LimoniApps-AppSecret': APP_PASSWORD,
  });

  const {
    isLoading: productVariantsListListIsLoading,
    isError: productVariantsListIsError,
    isSuccess: productVariantsListIsSuccess,
    data: productVariantsListData,
    error: productVariantsListError,
    refetch: fetchProductVariantsList,
  } = useGetPromotionsV6LookupAppBridgeResourcesProductVariantsQuery({
    'X-LimoniApps-AppName': APP_NAME,
    'X-LimoniApps-AppSecret': APP_PASSWORD,
  });

  return {
    collectionListListIsLoading,
    collectionListIsError,
    collectionListIsSuccess,
    collectionListData,
    collectionListError,
    fetchCollectionList,
    productsListListIsLoading,
    productsListIsError,
    productsListIsSuccess,
    productsListData,
    productsListError,
    fetchProductsList,
    productVariantsListListIsLoading,
    productVariantsListIsError,
    productVariantsListIsSuccess,
    productVariantsListData,
    productVariantsListError,
    fetchProductVariantsList,
  };
};
