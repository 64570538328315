import React, { useEffect, useMemo } from 'react';
import { useI18n } from '@shopify/react-i18n';
import { OptionDtoTriggerExclusivityDto } from 'core/api/adminPromotions/adminPromotionsApi';
import {
  Badge,
  Banner,
  BlockStack,
  Box,
  Button,
  Card,
  Collapsible,
  InlineStack,
  Link,
  Text,
  TextField,
} from '@shopify/polaris';

type SettingExclusivityProps = {
  exclusivity?: OptionDtoTriggerExclusivityDto;
  updateSettingsState: (data: OptionDtoTriggerExclusivityDto) => void;
  onFormValidityChange(formIsValid: boolean): void;
};

export const SettingExclusivity: React.FC<SettingExclusivityProps> = ({
  exclusivity,
  updateSettingsState,
  onFormValidityChange,
}) => {
  const [i18n] = useI18n();

  const isPriorityError = useMemo(
    () => exclusivity?.enabled && (exclusivity?.value?.priority as number) < 0,
    [exclusivity?.value?.priority, exclusivity?.enabled]
  );

  useEffect(() => {
    onFormValidityChange(!isPriorityError);
  }, [isPriorityError]);

  return (
    <div className='SettingExclusivity'>
      <Card>
        <BlockStack gap='400'>
          <InlineStack blockAlign='start' wrap={false} align='space-between'>
            <Box width='80%'>
              <BlockStack gap='100'>
                <InlineStack align='start' gap='200'>
                  <Text fontWeight='semibold' as='h2'>
                    {i18n.translate('Exclusivity')}
                  </Text>
                  <Badge tone={exclusivity?.enabled ? 'success' : 'enabled'}>
                    {i18n.translate(exclusivity?.enabled ? 'On' : 'Off')}
                  </Badge>
                </InlineStack>
                <Text as='p' tone='subdued'>
                  {i18n.translate('ExclusivitySubtitle', {
                    followingArticle: (
                      <Link>{i18n.translate('followingArticle')}</Link>
                    ),
                  })}
                </Text>
              </BlockStack>
            </Box>
            <Button
              onClick={() =>
                updateSettingsState({
                  ...exclusivity,
                  enabled: !exclusivity?.enabled,
                })
              }
            >
              {i18n.translate(exclusivity?.enabled ? 'TurnOff' : 'TurnOn')}
            </Button>
          </InlineStack>
          <Collapsible id='collapsible' open={!!exclusivity?.enabled}>
            <BlockStack gap='400'>
              <Banner tone='warning'>
                {i18n.translate('BannerWarning', {
                  here: <Link>{i18n.translate('here')}</Link>,
                })}
              </Banner>
              <Box width='280px'>
                <TextField
                  type='integer'
                  inputMode='numeric'
                  value={exclusivity?.value?.priority?.toString() || ''}
                  onChange={(value) =>
                    updateSettingsState({
                      ...exclusivity,
                      value: {
                        ...exclusivity?.value,
                        priority: value ? parseInt(value, 10) : 0,
                      },
                    })
                  }
                  label={i18n.translate('PriorityLabel')}
                  helpText={i18n.translate('PriorityHelperText')}
                  requiredIndicator
                  error={isPriorityError}
                  autoComplete='off'
                />
              </Box>
            </BlockStack>
          </Collapsible>
        </BlockStack>
      </Card>
    </div>
  );
};
