import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BlockStack,
  IndexFilters,
  InlineStack,
  Modal,
  ResourceList,
  useSetIndexFiltersMode,
  Text,
  Badge,
  Pagination,
  Checkbox,
} from '@shopify/polaris';
import { DateStyle, useI18n } from '@shopify/react-i18n';
import { useConfigureWidgets } from 'features/settings/hooks/useConfigureWidgets';
import useFetchPresetList from 'features/settings/hooks/useFetchPresetList/useFetchPresetList';
import {
  GetPresetListResponseDto,
  ListApiArg,
  PresetBoundariesTypeDto,
  PresetPreviewDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { debounce } from 'lodash';
import {
  PresetListSortFieldDtoEnum,
  PresetTypeDtoEnum,
  SortDirectionDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import './PresetsListModal.scss';
import ColorBlock from '../ColorBlock/ColorBlock';
import { Loader } from 'core/components';
import resolveEnvVar from 'env-var-resolver';

interface PresetsListModalProps {
  presetsListModalOpen: boolean;
  title?: string;
  isLoading?: boolean;
  boundaries: PresetBoundariesTypeDto;
  selectedPresetId?: string;
  handleClosePresetsListModal: () => void;
  handleSetDefault?: () => void;
  onSave?: (id: string) => void;
}

const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';

export const PresetsListModal: React.FC<PresetsListModalProps> = (props) => {
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';
  const {
    presetsListModalOpen,
    boundaries,
    title,
    isLoading,
    selectedPresetId,
    handleClosePresetsListModal,
    handleSetDefault,
    onSave,
  } = props;

  const [i18n] = useI18n();

  const { fetchList, stylePresetsLibraryIsFetching } = useFetchPresetList();

  const { setAsDefaultPreset } = useConfigureWidgets();

  const { mode, setMode } = useSetIndexFiltersMode();

  const [stylePresets, setStylePresets] =
    useState<GetPresetListResponseDto | null>(null);
  const [presetRequestSetup, setPresetRequestSetup] = useState<ListApiArg>({
    search: '',
    page: 1,
    itemsPerPage: 5,
    boundaries: boundaries,
    sortDirection: SortDirectionDtoEnum.ASC,
    type: undefined,
    sortBy: PresetListSortFieldDtoEnum.NAME,
    'X-LimoniApps-AppName': APP_NAME,
    'X-LimoniApps-AppSecret': APP_PASSWORD,
  });
  const [currentPreset, setCurrentPreset] = useState<PresetPreviewDto | null>(
    null
  );
  const [sortSelected, setSortSelected] = useState([`NAME asc`]);
  const [querySearchValue, setQuerySearchValue] = useState<string>('');

  const currentTabSelected = useMemo(() => {
    switch (presetRequestSetup.type) {
      case PresetTypeDtoEnum.SYSTEM:
        return 1;
      case PresetTypeDtoEnum.CUSTOM:
        return 2;
      default:
        return 0;
    }
  }, [presetRequestSetup.type]);

  const totalPages: number | undefined = useMemo(
    () =>
      Math.ceil(
        (stylePresets?.totalItems || 1) /
          (presetRequestSetup?.itemsPerPage as number)
      ),
    [stylePresets?.totalItems, presetRequestSetup?.itemsPerPage]
  );

  const sortOptions = useMemo(
    () => [
      {
        label: i18n.translate('PresetName'),
        value: `NAME asc` as const,
        directionLabel: i18n.translate('Ascending'),
      },
      {
        label: i18n.translate('PresetName'),
        value: `NAME desc` as const,
        directionLabel: i18n.translate('Descending'),
      },
    ],
    [i18n]
  );

  const tabsStrings = useMemo(
    () => ['ALL', 'systemPresets', 'myPresets'],
    [i18n]
  );

  const tabs = useMemo(() => {
    return tabsStrings.map((item, index) => ({
      content: i18n.translate(`${item}`),
      index,
      onAction: () => {
        setPresetRequestSetup((prev) => ({
          ...prev,
          type:
            item === 'ALL'
              ? undefined
              : item === 'systemPresets'
              ? PresetTypeDtoEnum.SYSTEM
              : PresetTypeDtoEnum.CUSTOM,
        }));
      },
      id: `${item}-${index}`,
      isLocked: index === 0,
      actions: [],
    }));
  }, [tabsStrings, setPresetRequestSetup]);

  const debounceSearchQuery = useCallback(
    debounce((newValue: string) => {
      setPresetRequestSetup((prev) => ({
        ...prev,
        search: newValue,
        page: 1,
      }));
    }, 500),
    []
  );

  const handleSavePreset = useCallback(() => {
    if (onSave) {
      onSave(currentPreset?.id as string);
    } else if (handleSetDefault) {
      setAsDefaultPreset(currentPreset?.id as string, handleSetDefault);
      handleClosePresetsListModal();
    }
  }, [onSave, handleSetDefault, currentPreset]);

  // might need a fix
  const handleSearchQueryChange = useCallback(
    (value: string) => {
      setQuerySearchValue(value);
      debounceSearchQuery(value);
    },
    [setQuerySearchValue, debounceSearchQuery]
  );

  const onSortSelect = useCallback((value: string[]) => {
    const [firstPart, secondPart] = value[0].split(' ');
    setSortSelected(value);
    setPresetRequestSetup((prev) => ({
      ...prev,
      sortBy: firstPart as PresetListSortFieldDtoEnum,
      sortDirection: secondPart.toUpperCase() as SortDirectionDtoEnum,
    }));
  }, []);

  useEffect(() => {
    fetchList(presetRequestSetup, setStylePresets);
  }, [presetRequestSetup]);

  useEffect(() => {
    !currentPreset &&
      stylePresets &&
      setCurrentPreset(
        stylePresets.items?.find((item) =>
          selectedPresetId ? item.id === selectedPresetId : item.isDefault
        ) || null
      );
  }, [currentPreset, stylePresets]);

  return (
    <Modal
      open={presetsListModalOpen}
      onClose={handleClosePresetsListModal}
      title={title ? title : i18n.translate('title')}
      primaryAction={{
        content: i18n.translate('Select'),
        onAction: () => {
          handleSavePreset();
        },
        loading: isLoading,
        disabled: !!(
          !currentPreset?.id ||
          (currentPreset?.id &&
            (selectedPresetId
              ? currentPreset?.id === selectedPresetId
              : currentPreset?.isDefault)) ||
          isLoading
        ),
      }}
      secondaryActions={[
        {
          content: i18n.translate('cancel'),
          onAction: handleClosePresetsListModal,
        },
      ]}
    >
      <div className='modalSectionWrapper'>
        <Modal.Section>
          <IndexFilters
            sortOptions={sortOptions}
            sortSelected={sortSelected}
            queryValue={querySearchValue}
            queryPlaceholder={i18n.translate(`SearchingInAll`)}
            onQueryChange={handleSearchQueryChange}
            onQueryClear={() => handleSearchQueryChange('')}
            onSort={(value) => onSortSelect(value)}
            cancelAction={{
              onAction: () => handleSearchQueryChange(''),
            }}
            tabs={tabs}
            selected={currentTabSelected}
            canCreateNewView={false}
            filters={[]}
            appliedFilters={[]}
            onClearAll={() => null}
            mode={mode}
            setMode={setMode}
            hideFilters
            filteringAccessibilityTooltip={i18n.translate(`SearchF`)}
          />
          {stylePresets?.items && (
            <ResourceList
              items={stylePresets.items}
              loading={stylePresetsLibraryIsFetching}
              renderItem={(item: PresetPreviewDto) => {
                const { colors, name, type, lastModifiedAt, isDefault, id } =
                  item;
                return (
                  <div
                    className='listItem'
                    onClick={() => setCurrentPreset(item)}
                  >
                    <InlineStack gap={'300'} blockAlign='center'>
                      <Checkbox
                        label=''
                        labelHidden
                        checked={currentPreset?.id === id}
                      />
                      <ColorBlock colors={colors || []} />
                      <BlockStack gap='0'>
                        <Text as='p'>{name}</Text>
                        <Text tone='subdued' as='p'>
                          {`${type && i18n.translate(type)} ${
                            lastModifiedAt &&
                            `• ${i18n.translate('EditedOn')} ${i18n.formatDate(
                              new Date(lastModifiedAt),
                              { style: DateStyle.Short }
                            )}`
                          }`}
                        </Text>
                      </BlockStack>
                    </InlineStack>
                    <InlineStack gap={'400'} blockAlign='center'>
                      {isDefault && <Badge>{i18n.translate('default')}</Badge>}
                    </InlineStack>
                  </div>
                );
              }}
            />
          )}
          {!stylePresets?.totalItems && (
            <div className='emptyState'>
              {!stylePresetsLibraryIsFetching ? (
                <Text as='p' variant='bodyMd' tone='subdued'>
                  {i18n.translate('emptyState', {
                    type: `“${tabs[currentTabSelected].content}”`,
                  })}
                </Text>
              ) : (
                <Loader fullWidth />
              )}
            </div>
          )}
          <div className='presetsListPagination'>
            <Pagination
              hasPrevious={(presetRequestSetup.page as number) > 1}
              onPrevious={() => {
                setPresetRequestSetup((prev) => ({
                  ...prev,
                  page: (prev.page as number) - 1,
                }));
              }}
              hasNext={(presetRequestSetup.page as number) < totalPages}
              onNext={() => {
                setPresetRequestSetup((prev) => ({
                  ...prev,
                  page: (prev.page as number) + 1,
                }));
              }}
            />
          </div>
        </Modal.Section>
      </div>
    </Modal>
  );
};
