import React, { useCallback, useMemo } from 'react';
import {
  Checkbox,
  RadioButton,
  Text,
  Tooltip,
  BlockStack,
  Icon,
  InlineStack,
  Box,
  Banner,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { OfferCartRuleDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { OfferCartContentRequirementProductMatchTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { QuestionCircleIcon } from '@shopify/polaris-icons';
import './GrayBoxBogoOptions.scss';
import { OfferTypeSpecialCases } from 'core/enums/GrayBoxEnum';

export type GrayBoxBogoOptionsProps = {
  data: OfferCartRuleDto;
  isCrossSell?: boolean;
  updateValue: (data: OfferCartRuleDto) => void;
  bogoVariant: OfferTypeSpecialCases | null;
};

export const GrayBoxBogoOptions: React.FC<GrayBoxBogoOptionsProps> = (
  props
) => {
  const { data, updateValue, isCrossSell = false, bogoVariant } = props;

  const [i18n] = useI18n();

  const cartRule = useMemo(
    () =>
      data.appliesTo?.productMatchType?.value ||
      OfferCartContentRequirementProductMatchTypeDtoEnum.SAME_PRODUCT,
    [data.appliesTo?.productMatchType?.value]
  );

  const checkBoxLabel = useMemo(
    () => (
      <InlineStack gap='100' blockAlign='center'>
        <Text as='p'>
          {i18n.translate('GrayBoxBogoOptions.RequireProducts')}
        </Text>
        <Tooltip
          preferredPosition='mostSpace'
          content={i18n.translate('GrayBoxBogoOptions.MatchHover')}
        >
          <span>
            <Icon source={QuestionCircleIcon} />
          </span>
        </Tooltip>
      </InlineStack>
    ),
    [i18n]
  );

  const handleCartRuleChange = useCallback(
    (
      _checked: boolean,
      newValue: OfferCartContentRequirementProductMatchTypeDtoEnum
    ) => {
      updateValue({
        ...data,
        appliesTo: {
          ...data.appliesTo,
          productMatchType: {
            ...data.appliesTo?.productMatchType,
            value: newValue,
          },
        },
      });
    },
    [data, updateValue]
  );

  const handleRequireProductsMatchChange = useCallback(
    (val: boolean) => {
      updateValue({
        ...data,
        appliesTo: {
          ...data.appliesTo,
          productMatchType: {
            ...data.appliesTo?.productMatchType,
            enabled: val,
          },
        },
      });
    },
    [data, updateValue]
  );

  return (
    <BlockStack>
      {bogoVariant === OfferTypeSpecialCases.BogoMixMatch ? (
        <Banner>{i18n.translate('GrayBoxBogoOptions.Banner')}</Banner>
      ) : bogoVariant === OfferTypeSpecialCases.BogoStrictMatch ? (
        checkBoxLabel
      ) : (
        <Checkbox
          label={checkBoxLabel}
          checked={data.appliesTo?.productMatchType?.enabled}
          onChange={handleRequireProductsMatchChange}
        />
      )}
      {bogoVariant !== OfferTypeSpecialCases.BogoMixMatch &&
        !isCrossSell &&
        data.appliesTo?.productMatchType?.enabled && (
          <Box
            paddingInlineStart={
              bogoVariant !== OfferTypeSpecialCases.BogoStrictMatch
                ? '600'
                : '0'
            }
            paddingBlockStart={
              bogoVariant === OfferTypeSpecialCases.BogoStrictMatch
                ? '100'
                : '0'
            }
          >
            <BlockStack gap='025'>
              <RadioButton
                label={i18n.translate('GrayBoxBogoOptions.SameProduct')}
                checked={
                  cartRule ===
                  OfferCartContentRequirementProductMatchTypeDtoEnum.SAME_PRODUCT
                }
                id={
                  OfferCartContentRequirementProductMatchTypeDtoEnum.SAME_PRODUCT
                }
                onChange={handleCartRuleChange}
              />
              <RadioButton
                label={i18n.translate('GrayBoxBogoOptions.SameProductVariant')}
                id={
                  OfferCartContentRequirementProductMatchTypeDtoEnum.SAME_VARIANT
                }
                checked={
                  cartRule ===
                  OfferCartContentRequirementProductMatchTypeDtoEnum.SAME_VARIANT
                }
                onChange={handleCartRuleChange}
              />
            </BlockStack>
          </Box>
        )}
    </BlockStack>
  );
};
