import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  ActionList,
  BlockStack,
  Box,
  Button,
  ButtonGroup,
  Icon,
  Layout,
  Page,
  Popover,
} from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';
import { useI18n } from '@shopify/react-i18n';
import { CalendarIcon, CheckSmallIcon } from '@shopify/polaris-icons';
import '@shopify/polaris-viz/build/esm/styles.css';
import {
  AnalyticsBarChart,
  AnalyticsConversionChart,
  AnalyticsLineChart,
} from './components/Charts';
import {
  AnalyticsQueryComparisonPeriodDto,
  AnalyticsQueryPeriodDto,
  useGetAnalyticsV6OverviewQuery,
} from 'core/api/adminAnalytics/adminAnalyticsApi';
import { AnalyticsQueryPeriodDtoEnum } from 'core/api/adminAnalytics/adminAnalyticsEnums';
import {
  AnalyticsReportList,
  AnalyticsReportListRef,
} from './components/Charts/AnalyticsReportList/AnalyticsReportList';
import { ReportListTypeEnum } from 'pages';
const APP_NAME = process.env.REACT_APP_APP_NAME || '';

export const Analytics = () => {
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || '';
  const [i18n] = useI18n();
  const navigate = useNavigate();
  const orderListRef = useRef<AnalyticsReportListRef>(null);
  const codesListRef = useRef<AnalyticsReportListRef>(null);
  const activityListRef = useRef<AnalyticsReportListRef>(null);
  const [comparisonPeriod, setComparisonPeriod] = useState<
    AnalyticsQueryComparisonPeriodDto | undefined
  >(undefined);
  const [period, setPeriod] = useState<AnalyticsQueryPeriodDto>(
    AnalyticsQueryPeriodDtoEnum.LAST_12_MONTHS
  );
  const [periodActive, setPeriodActive] = useState<boolean>(false);

  const {
    data: analyticsOverviewData,
    isFetching: analyticsOverviewIsFetching,
    refetch: refetchAnalyticsOverview,
  } = useGetAnalyticsV6OverviewQuery(
    {
      comparisonPeriod: comparisonPeriod,
      period: period,
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    { refetchOnMountOrArgChange: true }
  );

  const handleRefreshAnalytics = useCallback(() => {
    orderListRef.current?.refresh();
    codesListRef.current?.refresh();
    activityListRef.current?.refresh();
    refetchAnalyticsOverview();
  }, [orderListRef.current, codesListRef.current, activityListRef.current]);

  const periodOptions = useMemo(
    () =>
      Object.values(AnalyticsQueryPeriodDtoEnum).map((value) => ({
        content: i18n.translate(value),
        active: value === period,
        onAction: () => setPeriod(value),
        suffix: value === period ? <Icon source={CheckSmallIcon} /> : null,
      })),
    [period, setPeriod, i18n]
  );

  const togglePeriod = useCallback(
    () => setPeriodActive(!periodActive),
    [periodActive]
  );

  return (
    <Page
      fullWidth
      title={i18n.translate(`Analytics`)}
      secondaryActions={[
        {
          content: i18n.translate(`Refresh`),
          onAction: handleRefreshAnalytics,
        },
      ]}
    >
      <Box paddingBlockEnd='500'>
        <BlockStack gap='400'>
          <ButtonGroup>
            <Popover
              active={periodActive}
              activator={
                <Button onClick={togglePeriod} icon={CalendarIcon}>
                  {i18n.translate(`${period}`)}
                </Button>
              }
              autofocusTarget='first-node'
              onClose={togglePeriod}
            >
              <ActionList actionRole='menuitem' items={periodOptions} />
            </Popover>
            {/*Develop in next iteration */}
            {/* <Button>Compare to: Jun1, 2022-May 31,2023</Button> */}
          </ButtonGroup>
          <Layout>
            <Layout.Section variant='oneThird'>
              <BlockStack gap='400'>
                <AnalyticsLineChart
                  analyticsData={analyticsOverviewData?.totalRevenue || {}}
                  title={i18n.translate('TotalDNRevenue')}
                  tooltip={i18n.translate('TotalRevenueTooltip')}
                  onNavigate={() => navigate('total-dn-revenue')}
                  isLoading={analyticsOverviewIsFetching}
                />
                <AnalyticsBarChart
                  title={i18n.translate('RevenueByPromotion')}
                  analyticsData={
                    analyticsOverviewData?.revenueByPromotion || {}
                  }
                  tooltip={i18n.translate('RevenueByPromotionTooltip')}
                  onNavigate={() => navigate('promotion-activity-report')}
                  isLoading={analyticsOverviewIsFetching}
                />
                <AnalyticsLineChart
                  analyticsData={analyticsOverviewData?.totalOrders || {}}
                  title={i18n.translate('TotalDNOrders')}
                  tooltip={i18n.translate('TotalDNOrdersTooltip')}
                  onNavigate={() => navigate('total-dn-orders')}
                  isLoading={analyticsOverviewIsFetching}
                />
              </BlockStack>
            </Layout.Section>
            <Layout.Section variant='oneThird'>
              <BlockStack gap='400'>
                <AnalyticsConversionChart
                  title={i18n.translate('DNConversionRate')}
                  isLoading={analyticsOverviewIsFetching}
                  analyticsData={analyticsOverviewData?.conversionRate || {}}
                  tooltip={i18n.translate('ConversionRateTooltip')}
                  onNavigate={() => navigate('dn-conversion-rate')}
                />
                <AnalyticsBarChart
                  analyticsData={analyticsOverviewData?.ordersByPromotion || {}}
                  title={i18n.translate('OrdersByPromotion')}
                  tooltip={i18n.translate('OrdersByPromotionTooltip')}
                  onNavigate={() => navigate('promotion-activity-report')}
                  isLoading={analyticsOverviewIsFetching}
                />
                <AnalyticsLineChart
                  analyticsData={analyticsOverviewData?.sessions || {}}
                  title={i18n.translate('DNSessions')}
                  tooltip={i18n.translate('DNSessionsTooltip')}
                  onNavigate={() => navigate('dn-sesstions')}
                  isLoading={analyticsOverviewIsFetching}
                />
              </BlockStack>
            </Layout.Section>
          </Layout>

          <AnalyticsReportList
            listType={ReportListTypeEnum.PROMOTION_ACTIVITY}
            ref={activityListRef}
            simplified
          />
          <AnalyticsReportList
            listType={ReportListTypeEnum.DETAILED_ORDER}
            simplified
            ref={orderListRef}
            tooltip={i18n.translate('DetailedOrderTooltip')}
          />
          <AnalyticsReportList
            listType={ReportListTypeEnum.DETAILED_CODE}
            simplified
            ref={codesListRef}
          />
        </BlockStack>
      </Box>
    </Page>
  );
};
