import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BlockStack, Card, Link, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import './ShippingRate.scss';
import { ShippingTypeSection } from './components/ShippingTypeSection/ShippingTypeSection';
import { ShippingNameMethod } from './components/ShippingNameMethod/ShippingNameMethod';
import { NumberFormatValues } from 'react-number-format';
import {
  OfferFreeShippingRateTypeDtoEnum,
  OfferTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import {
  OfferFreeShippingRateTypeDto,
  OfferFreeShippingSpecificationDto,
  OfferShippingRateTypeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { ShippingValueTypeEnum } from '../../enums/ShippingValueType';
import {
  getInitialActiveShippingValueType,
  getInitialPriceTextfieldValue,
} from './utils/utils';
import { setSelectedOfferType } from 'core/store/offersWizardSlice';
import { OfferTypesFromCatalogDataType } from '../../types/OfferTypesFromCatalogTypes';
import { OfferTypesFromCatalogData } from '../../consts/OfferTypesFromCatalogData';
import { useAppDispatch } from 'core/hooks';
import useValidityUpdater, {
  ObjValidity,
} from 'features/promotions/hooks/useValidityUpdater';
import { usePrevious } from 'core/hooks/usePrevious';

type ShippingRateProps = {
  onValidityStateChange?(valid: boolean): void;
  specification?: OfferFreeShippingSpecificationDto;
  onSpecificationUpdate?: (
    specification: OfferFreeShippingSpecificationDto
  ) => void;
  type?: OfferShippingRateTypeDto;
  offerType: OfferTypeDtoEnum;
};

export const ShippingRate: React.FC<ShippingRateProps> = (props) => {
  const { specification, onSpecificationUpdate, onValidityStateChange } = props;
  const [i18n] = useI18n();
  const [fixedPriceTextFieldValue, setFixedPriceTextFieldValue] =
    useState<NumberFormatValues>(
      getInitialPriceTextfieldValue(
        specification?.flatFeeShippingRate as number
      )
    );
  const [methodName, setMethodName] = useState<string>(
    specification?.shippingRateName || 'Shipping'
  );
  const [activeShippingValueType, setActiveShippingValueType] =
    useState<ShippingValueTypeEnum>(
      getInitialActiveShippingValueType(specification?.shippingRateType)
    );

  const prevMethodName = usePrevious(methodName);
  const prevFixedPrice = usePrevious(fixedPriceTextFieldValue);
  const prevShippingValueType = usePrevious(activeShippingValueType);

  const [currentObjectsValidities, updateValidity] = useValidityUpdater(1);

  const dispatch = useAppDispatch();

  const isAllInputsValid = useMemo(
    () =>
      currentObjectsValidities?.every(
        (inputValidity: ObjValidity) => inputValidity.isValid
      ),
    [currentObjectsValidities]
  );

  useEffect(() => {
    if (onValidityStateChange) {
      onValidityStateChange(isAllInputsValid);
    }
  }, [isAllInputsValid]);

  useEffect(() => {
    if (
      (prevShippingValueType &&
        activeShippingValueType !== prevShippingValueType) ||
      (prevFixedPrice?.floatValue &&
        fixedPriceTextFieldValue.floatValue !== prevFixedPrice?.floatValue) ||
      (prevMethodName && methodName !== prevMethodName)
    ) {
      if (!onSpecificationUpdate || !activeShippingValueType) {
        return;
      }
      let shippingRateType: OfferFreeShippingRateTypeDto;
      switch (activeShippingValueType) {
        case ShippingValueTypeEnum.FreeShipping:
          shippingRateType = OfferFreeShippingRateTypeDtoEnum.FREE_SHIPPING;
          break;
        case ShippingValueTypeEnum.FlatFeeShippingRate:
          shippingRateType =
            OfferFreeShippingRateTypeDtoEnum.FLAT_FEE_SHIPPING_RATE;
          break;
        default:
          shippingRateType = OfferFreeShippingRateTypeDtoEnum.FREE_SHIPPING;
          break;
      }

      const getShippingValueRequestObject =
        (): OfferFreeShippingSpecificationDto => {
          switch (activeShippingValueType) {
            case ShippingValueTypeEnum.FreeShipping:
              return {
                ...specification,
                shippingRateType,
                shippingRateName: methodName,
              };
            case ShippingValueTypeEnum.FlatFeeShippingRate:
              return {
                shippingRateType,
                flatFeeShippingRate: fixedPriceTextFieldValue.floatValue || 0,
                shippingRateName: methodName,
              };
            default:
              return {
                ...specification,
                shippingRateType,
                shippingRateName: methodName,
              };
          }
        };

      onSpecificationUpdate(getShippingValueRequestObject());
    }
  }, [
    activeShippingValueType,
    onSpecificationUpdate,
    fixedPriceTextFieldValue,
    methodName,
  ]);

  const onShippingTypeClick = useCallback(
    (shippingType: string) => {
      setActiveShippingValueType(shippingType as ShippingValueTypeEnum);
      if (!specification) {
        return;
      }
      dispatch(
        setSelectedOfferType(
          OfferTypesFromCatalogData[
            'FreeShipping'
          ][0] as OfferTypesFromCatalogDataType
        )
      );
    },
    [setActiveShippingValueType, dispatch]
  );
  return (
    <>
      <BlockStack gap='600'>
        <Card roundedAbove='sm' padding='400'>
          <BlockStack gap='400'>
            <BlockStack gap='100'>
              <Text as='h2' variant='headingSm'>
                {i18n.translate('Shipping.Title') || 'Shipping'}
              </Text>
              <Text as='p'>
                {i18n.translate('Shipping.Subtitle', {
                  link: (
                    <Link url='/'>
                      {i18n.translate('Shipping.FollowingArticle')}
                    </Link>
                  ),
                })}
              </Text>
            </BlockStack>
            <ShippingTypeSection
              updateValidity={updateValidity}
              activeShippingValueType={activeShippingValueType}
              onShippingTypeClick={onShippingTypeClick}
              i18n={i18n}
              textFieldValue={fixedPriceTextFieldValue}
              setTextFieldValue={setFixedPriceTextFieldValue}
            />
          </BlockStack>
        </Card>
        <Card>
          <BlockStack gap='400'>
            <BlockStack gap='100'>
              <Text as='h2' variant='headingSm'>
                {i18n.translate('Shipping.ShippingName')}
              </Text>
              <Text as='p'>
                {i18n.translate('Shipping.ShippingNameSubtitle', {
                  link: (
                    <Link url='/'>
                      {i18n.translate('Shipping.FollowingArticle')}
                    </Link>
                  ),
                })}
              </Text>
            </BlockStack>
            <ShippingNameMethod
              updateValidity={updateValidity}
              methodName={methodName}
              setMethodName={setMethodName}
              i18n={i18n}
            />
          </BlockStack>
        </Card>
      </BlockStack>
    </>
  );
};
