import React from 'react';
import {
  Bleed,
  BlockStack,
  Card,
  Checkbox,
  Divider,
  InlineStack,
  Text,
  TextField,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { TagsMetadataSettingsDto } from 'core/api/adminSettings/adminSettingsApi';

type TagComponent = {
  mapData: TagComponentItem;
  data: TagsMetadataSettingsDto | undefined;
  handleTagsMetadataLocaleState: (
    section: string,
    value: string | boolean,
    field?: string
  ) => void;
};

type TagComponentItem = {
  title: string;
  section: string[];
};

export const TagComponent: React.FC<TagComponent> = ({
  mapData,
  data,
  handleTagsMetadataLocaleState,
}) => {
  const [i18n] = useI18n();

  return (
    <Card roundedAbove='sm' padding='400'>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate(mapData.title)}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate(`${mapData.title}Subtitle`)}
          </Text>
        </BlockStack>
        {data &&
          mapData.section.map((item: string) => (
            <BlockStack key={item} gap='400'>
              <Bleed marginInline='400'>
                <Divider />
              </Bleed>
              <Checkbox
                label={i18n.translate(item)}
                checked={
                  data?.[
                    item as keyof Omit<
                      TagsMetadataSettingsDto,
                      | 'addTagFlagCouponFraud'
                      | 'storeDiscountAppDataInOrderMetafields'
                    >
                  ]?.enabled
                }
                onChange={(value) =>
                  handleTagsMetadataLocaleState(item, value, 'enabled')
                }
              />
              {data?.[
                item as keyof Omit<
                  TagsMetadataSettingsDto,
                  | 'addTagFlagCouponFraud'
                  | 'storeDiscountAppDataInOrderMetafields'
                >
              ]?.enabled && (
                <TextField
                  placeholder={i18n.translate(`${item}Placeholder`)}
                  autoComplete='off'
                  label={
                    <InlineStack gap='100'>
                      <Text as='h2'>{i18n.translate('prefix')}</Text>
                      <Text as='p' tone='subdued'>
                        {i18n.translate('optional')}
                      </Text>
                    </InlineStack>
                  }
                  value={
                    data?.[
                      item as keyof Omit<
                        TagsMetadataSettingsDto,
                        | 'addTagFlagCouponFraud'
                        | 'storeDiscountAppDataInOrderMetafields'
                      >
                    ]?.value || ''
                  }
                  onChange={(value) =>
                    handleTagsMetadataLocaleState(item, value, 'value')
                  }
                />
              )}
            </BlockStack>
          ))}
      </BlockStack>
    </Card>
  );
};
