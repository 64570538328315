import React, { useCallback, useState } from 'react';
import './PageDisplay.scss';
import { useI18n } from '@shopify/react-i18n';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import { Button, Checkbox, Collapsible, Divider } from '@shopify/polaris';
import { PageDisplayComponentDto } from 'core/api/adminWidgets/adminWidgetsApi';
import {
  PageDisplayTypeDtoEnum,
  PageTypeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import {
  GrayBoxResourceTypeEnum,
  ResourceSelectionProps,
  SearchFieldWithGrayBox,
} from 'core/components/SearchFieldWithGrayBox';
import { formateList } from 'core/utils/offerTargetTypeUtils';
import { ChevronUpIcon, ChevronDownIcon } from '@shopify/polaris-icons';

type PageDisplayProps = {
  pageDisplay: PageDisplayComponentDto;
  disabled?: boolean;
  setPageDisplay?: (data: PageDisplayComponentDto) => void;
  onSystemReset?: () => void;
};

export const PageDisplay: React.FC<PageDisplayProps> = ({
  pageDisplay,
  disabled,
  setPageDisplay,
  onSystemReset,
}) => {
  const [i18n] = useI18n();
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);

  const pageDisplayOptions = Object.values(PageDisplayTypeDtoEnum).map(
    (value) => {
      return {
        value: value,
        label: i18n.translate(`${value}_TYPE`),
      };
    }
  );
  const isTypeDefault = pageDisplay.type === PageDisplayTypeDtoEnum.DEFAULT;
  const handleChangeCheckbox = useCallback(
    (value: boolean, page: PageTypeDtoEnum) => {
      if (value) {
        setPageDisplay?.({
          ...pageDisplay,
          pages: [...(pageDisplay.pages || []), page],
        });
      } else {
        setPageDisplay?.({
          ...pageDisplay,
          pages: pageDisplay.pages?.filter((item) => item !== page),
        });
      }
    },
    [pageDisplay, setPageDisplay]
  );

  return (
    <div className='PageDisplay'>
      <StyleComponentHeader
        label={i18n.translate(`PageDisplay`)}
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            disabled: disabled,
            onAction: () => onSystemReset?.(),
          },
        ]}
      >
        <div className='PageDisplayRow'>
          {i18n.translate(`DisplaySettings`)}
          <div className='RightContent'>
            <SelectOptions
              options={pageDisplayOptions}
              onOptionSelect={(value) =>
                setPageDisplay?.({
                  ...pageDisplay,
                  type: value as PageDisplayTypeDtoEnum,
                })
              }
              selectedOption={
                pageDisplay.type || PageDisplayTypeDtoEnum.DEFAULT
              }
              disabled={disabled}
              label=''
            />
          </div>
        </div>

        <div>
          <Button
            variant='plain'
            onClick={() => setIsDetailsOpen(!isDetailsOpen)}
            icon={isDetailsOpen ? ChevronUpIcon : ChevronDownIcon}
          >
            {i18n.translate(`Details`)}
          </Button>
          <Collapsible
            open={isDetailsOpen}
            id='basic-collapsible'
            transition={{
              duration: '500ms',
              timingFunction: 'ease-in-out',
            }}
            expandOnPrint
          >
            <div className='DetailsSection'>
              <div className='PageList'>
                {pageDisplay.availablePages?.map((item) => (
                  <Checkbox
                    key={item}
                    label={i18n.translate(`${item}`)}
                    checked={pageDisplay.pages?.includes(item)}
                    onChange={(value) =>
                      handleChangeCheckbox(value, item as PageTypeDtoEnum)
                    }
                    disabled={disabled || isTypeDefault}
                  />
                ))}
              </div>
              {(pageDisplay.pages?.includes(PageTypeDtoEnum.PRODUCT) ||
                pageDisplay.pages?.includes(PageTypeDtoEnum.COLLECTION)) && (
                <Divider />
              )}
              {pageDisplay.pages?.includes(PageTypeDtoEnum.PRODUCT) && (
                <div className='SelectProducts'>
                  <Checkbox
                    label={'All products'}
                    checked={pageDisplay.products?.allItems || false}
                    disabled={disabled || isTypeDefault}
                    onChange={(value) =>
                      setPageDisplay?.({
                        ...pageDisplay,
                        products: {
                          selectedItems: value
                            ? null
                            : pageDisplay.products?.selectedItems,
                          allItems: value,
                        },
                      })
                    }
                  />
                  {!pageDisplay.products?.allItems && (
                    <SearchFieldWithGrayBox
                      resourceType={GrayBoxResourceTypeEnum.Product}
                      showLabel={false}
                      selectList={
                        pageDisplay.products?.selectedItems?.map((item) => ({
                          id: item,
                        })) as ResourceSelectionProps[]
                      }
                      onSelectedChange={(list: ResourceSelectionProps[]) => {
                        const data = formateList(list);
                        setPageDisplay?.({
                          ...pageDisplay,
                          products: {
                            ...pageDisplay.products,
                            selectedItems: data.map(
                              (item: any) => item.id || ''
                            ),
                          },
                        });
                      }}
                      ignoreValidation
                    />
                  )}
                </div>
              )}
              {pageDisplay.pages?.includes(PageTypeDtoEnum.COLLECTION) && (
                <div className='SelectProducts'>
                  <Checkbox
                    label={'All collections'}
                    checked={pageDisplay.collections?.allItems || false}
                    disabled={disabled || isTypeDefault}
                    onChange={(value) =>
                      setPageDisplay?.({
                        ...pageDisplay,
                        collections: {
                          selectedItems: value
                            ? null
                            : pageDisplay.collections?.selectedItems,
                          allItems: value,
                        },
                      })
                    }
                  />
                  {!pageDisplay.collections?.allItems && (
                    <SearchFieldWithGrayBox
                      resourceType={GrayBoxResourceTypeEnum.Collection}
                      showLabel={false}
                      selectList={
                        pageDisplay.collections?.selectedItems?.map((item) => ({
                          id: item,
                        })) as ResourceSelectionProps[]
                      }
                      onSelectedChange={(list: ResourceSelectionProps[]) => {
                        const data = formateList(list);
                        setPageDisplay?.({
                          ...pageDisplay,
                          collections: {
                            ...pageDisplay.collections,
                            selectedItems: data.map(
                              (item: any) => item.id || ''
                            ),
                          },
                        });
                      }}
                      ignoreValidation
                    />
                  )}
                </div>
              )}
            </div>
          </Collapsible>
        </div>
      </StyleComponentHeader>
    </div>
  );
};
