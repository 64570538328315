import React, { useState } from 'react';
import { BlockStack, Button, Card, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { CancelSubscriptionModal } from './components/CancelSubscriptionModal/CancelSubscriptionModal';
type CancelSubscriptionProps = {
  refetchCurrentPlanData: () => void;
};
export const CancelSubscription: React.FC<CancelSubscriptionProps> = ({
  refetchCurrentPlanData,
}) => {
  const [i18n] = useI18n();
  const [cancelModal, setCancelModal] = useState<boolean>(false);

  return (
    <Card>
      <BlockStack gap='400' inlineAlign='start'>
        <BlockStack gap='100'>
          <Text as='h2' fontWeight='semibold'>
            {i18n.translate('CancelSubscription')}
          </Text>
          <Text tone='subdued' as='p'>
            {i18n.translate('StopFutureBilling')}
          </Text>
        </BlockStack>
        <Button onClick={() => setCancelModal(true)} tone='critical'>
          {i18n.translate('CancelSubscription')}
        </Button>
      </BlockStack>
      {cancelModal && (
        <CancelSubscriptionModal
          isOpen={cancelModal}
          refetchCurrentPlanData={refetchCurrentPlanData}
          onClose={() => setCancelModal(false)}
        />
      )}
    </Card>
  );
};
