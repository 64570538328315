import React, { useCallback, useMemo } from 'react';
import { SelectedOptionPath } from '../../../../RightSideBarWrapper';
import {
  PromotionSummaryLevelPresetDto,
  PromotionSummaryPresetDtoRead,
  PromotionSummarySystemDefaultsDto,
} from 'core/api/adminWidgets/adminWidgetsApi';

import { getLastSelectedOption } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import PromotionSummaryGeneral from './components/PromotionSummaryGeneral/PromotionSummaryGeneral';
import PromotionSummaryCombinedComponent, {
  PromotionSummaryCombinedComponentTypes,
} from './components/PromotionSummaryCombinedComponent/PromotionSummaryCombinedComponent';
import PromotionSummaryDisclaimer from './components/PromotionSummaryDisclaimer/PromotionSummaryDisclaimer';
import {
  WidgetListOptionsDtoEnum,
  WidgetListTypeDtoEnum,
} from 'features/settings/components/EditPresetSkeleton/enums/enums';

type PresetPromotionSummaryProps = {
  promotionSummary: PromotionSummaryPresetDtoRead;
  selectedOptionPath: SelectedOptionPath;
  defaultPresetData: PromotionSummaryPresetDtoRead;
  shopDefaultData: PromotionSummarySystemDefaultsDto;
  defaultLanguage: string;
  pageType:
    | WidgetListTypeDtoEnum.DRAWER_CART_PROMOTION_SUMMARY
    | WidgetListTypeDtoEnum.CART_PROMOTION_SUMMARY
    | WidgetListTypeDtoEnum.NINJA_CART_PROMOTION_SUMMARY;
  updatePromotionSummary: (data: PromotionSummaryPresetDtoRead) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const PresetPromotionSummary: React.FC<PresetPromotionSummaryProps> = ({
  promotionSummary,
  selectedOptionPath,
  defaultPresetData,
  shopDefaultData,
  defaultLanguage,
  pageType,
  updatePromotionSummary,
  handleLeftMenuSelection,
}) => {
  const handleComponentVisability = useCallback(
    (option: keyof PromotionSummaryLevelPresetDto) => {
      updatePromotionSummary({
        ...promotionSummary,
        options: {
          ...promotionSummary.options,
          [option]: {
            ...promotionSummary.options?.[option],
            isHidden: !promotionSummary.options?.[option]?.isHidden,
          },
        },
      });
    },
    [updatePromotionSummary, promotionSummary]
  );
  const getPromotionSummaryCombinedComponent = useCallback(
    (sectionType: PromotionSummaryCombinedComponentTypes) => {
      return (
        <PromotionSummaryCombinedComponent
          key={sectionType}
          sectionType={sectionType}
          general={promotionSummary.options?.[sectionType] || {}}
          defaultGeneral={defaultPresetData.options?.[sectionType] || {}}
          shopDefaultGeneral={shopDefaultData?.[sectionType] || {}}
          textStyle={promotionSummary.general?.textStyle || {}}
          defaultTextStyle={defaultPresetData.general?.textStyle || {}}
          shopDefaultTextStyle={shopDefaultData.general?.textStyle || {}}
          isPresetEditor
          setGeneral={(data, textStyleData) =>
            updatePromotionSummary({
              ...promotionSummary,
              general: {
                ...promotionSummary.general,
                ...(textStyleData && { textStyle: textStyleData }),
              },
              options: {
                ...promotionSummary.options,
                [sectionType]: data,
              },
            })
          }
          onShowHide={
            promotionSummary.options?.[sectionType]?.canBeHidden
              ? () => handleComponentVisability(sectionType)
              : undefined
          }
          handleLeftMenuSelection={handleLeftMenuSelection}
          defaultLanguage={defaultLanguage}
        />
      );
    },
    [
      promotionSummary,
      defaultPresetData,
      shopDefaultData,
      defaultLanguage,
      updatePromotionSummary,
      handleLeftMenuSelection,
    ]
  );

  const promotionSummaryOptions = useMemo(() => {
    const selectedOption = getLastSelectedOption(selectedOptionPath);
    switch (true) {
      case selectedOption?.name === pageType:
        return (
          <PromotionSummaryGeneral
            general={promotionSummary.general || {}}
            shopDefaultGeneral={shopDefaultData.general || {}}
            type={pageType}
            isPresetEditor
            defaultGeneral={defaultPresetData.general || {}}
            handleLeftMenuSelection={handleLeftMenuSelection}
            setGeneral={(data) =>
              updatePromotionSummary({
                ...promotionSummary,
                general: data,
              })
            }
            onShowHide={
              promotionSummary.canBeHidden
                ? () =>
                    updatePromotionSummary({
                      ...promotionSummary,
                      isHidden: !promotionSummary.isHidden,
                    })
                : undefined
            }
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.TOTAL:
        return getPromotionSummaryCombinedComponent(
          WidgetListOptionsDtoEnum.TOTAL
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.SUBTOTAL:
        return getPromotionSummaryCombinedComponent(
          WidgetListOptionsDtoEnum.SUBTOTAL
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.SHIPPING:
        return getPromotionSummaryCombinedComponent(
          WidgetListOptionsDtoEnum.SHIPPING
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.TAXES_AND_DUTIES:
        return getPromotionSummaryCombinedComponent(
          WidgetListOptionsDtoEnum.TAXES_AND_DUTIES
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.DISCOUNT:
        return getPromotionSummaryCombinedComponent(
          WidgetListOptionsDtoEnum.DISCOUNT
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.DISCLAIMER:
        return (
          <PromotionSummaryDisclaimer
            pageType={pageType}
            disclaimer={promotionSummary.options?.disclaimer || {}}
            defaultDisclaimer={defaultPresetData.options?.disclaimer || {}}
            shopDefaultDisclaimer={shopDefaultData?.disclaimer || {}}
            isPresetEditor
            setDisclaimer={(data) =>
              updatePromotionSummary({
                ...promotionSummary,
                options: {
                  ...promotionSummary.options,
                  disclaimer: data,
                },
              })
            }
            onShowHide={
              promotionSummary.options?.disclaimer?.canBeHidden
                ? () => handleComponentVisability('disclaimer')
                : undefined
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
            defaultLanguage={defaultLanguage}
          />
        );
    }
  }, [
    selectedOptionPath,
    shopDefaultData,
    promotionSummary,
    defaultPresetData,
    pageType,
    updatePromotionSummary,
    handleLeftMenuSelection,
  ]);
  return (
    <div className='PresetPromotionSummary'>{promotionSummaryOptions}</div>
  );
};

export default PresetPromotionSummary;
