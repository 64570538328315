import resolveEnvVar from 'env-var-resolver';
import { emptySplitAuthApi as api } from './emptyAuthApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAuthenticate: build.query<
      GetAuthenticateApiResponse,
      GetAuthenticateApiArg
    >({
      query: (queryArg) => ({
        url: resolveEnvVar('REACT_APP_LOCAL') === 'true' ? `/authenticate` : `/v6/api/session`,
        params: {
          shopifyAccessToken: queryArg.shopifyAccessToken,
          appName: queryArg.appName,
          uri: queryArg.uri,
          debugShop: queryArg.debugShop,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as authApi };
export type GetAuthenticateApiResponse =
  /** status 200 Returns a JSON object with a Token property which contains the JWT Token, valid for 24 hours */ SessionToken;
export type GetAuthenticateApiArg = {
  /** Token received from the Shopify AppBridge. In development use 'local-debug-sessiontoken'. */
  shopifyAccessToken?: string;
  /** The name of the app to authenticate with (Example: DiscountNinja). */
  appName?: string;
  /** The full uri of the client requesting the token. */
  uri?: string;
  /** Only used in development. Overrides the shop to request access to. */
  debugShop?: string;
};
export type SessionToken = {
  discountNinjaSessionToken?: string | null;
  Token?: string | null;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export const { useGetAuthenticateQuery } = injectedRtkApi;
