import React, { useCallback } from 'react';

interface WrapperProps {
  children: React.ReactNode;
  onCursorEnter?: () => void;
  onCursorLeave?: () => void;
}

export const ActivityDetector: React.FC<WrapperProps> = ({
  children,
  onCursorEnter,
  onCursorLeave,
}) => {
  const handlePointerEnter = useCallback(() => {
    onCursorEnter?.();
  }, [onCursorEnter]);

  const handlePointerLeave = useCallback(() => {
    onCursorLeave?.();
  }, [onCursorLeave]);

  return (
    <div
      onPointerEnter={handlePointerEnter}
      onPointerLeave={handlePointerLeave}
    >
      {children}
    </div>
  );
};
