import React, { useMemo, useState } from 'react';
import './TextStyle.scss';
import { useI18n } from '@shopify/react-i18n';
import TextRow from './components/TextRow/TextRow';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import { MessageStyleComponentDto } from 'core/api/adminWidgets/adminWidgetsApi';
import {
  Banner,
  Button,
  Checkbox,
  Collapsible,
  Icon,
  InlineStack,
  Text,
  Tooltip,
} from '@shopify/polaris';
import ColorSelectorBox from 'core/components/WidgetEditor/ColorSelectorBox/ColorSelectorBox';
import {
  ChevronUpIcon,
  ChevronDownIcon,
  InfoIcon,
} from '@shopify/polaris-icons';
import FontSelector from 'core/components/WidgetEditor/FontSelector/FontSelector';
import { FontStyleTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import classNames from 'classnames';

type TextStyleProps = {
  textStyle: MessageStyleComponentDto;
  disabled?: boolean;
  hideHeader?: boolean;
  bannerText?: string;
  note?: string;
  title?: string;
  inherited?: boolean;
  offersNote?: boolean;
  onlyColor?: boolean;
  onlyFont?: boolean;
  setTextStyle: (data: MessageStyleComponentDto) => void;
  onSystemReset?: () => void;
};
export const TextStyle: React.FC<TextStyleProps> = ({
  textStyle,
  disabled,
  hideHeader,
  bannerText,
  note,
  title,
  inherited,
  offersNote,
  onlyColor,
  onlyFont,
  setTextStyle,
  onSystemReset,
}) => {
  const [i18n] = useI18n();
  const [isAdvancedOpen, setIsAdvancedOpen] = useState<boolean>(false);

  const fontInheritance = useMemo(
    () => (
      <>
        <InlineStack align='space-between' blockAlign='center'>
          <Checkbox
            disabled={disabled}
            label={i18n.translate(`InheritFromParent`)}
            checked={textStyle.font?.type === FontStyleTypeDtoEnum.INHERITED}
            onChange={() =>
              setTextStyle({
                ...textStyle,
                font: {
                  ...textStyle.font,
                  type:
                    textStyle.font?.type === FontStyleTypeDtoEnum.INHERITED
                      ? FontStyleTypeDtoEnum.CUSTOM
                      : FontStyleTypeDtoEnum.INHERITED,
                },
              })
            }
          />
          <Tooltip width='wide' content={i18n.translate(`InheritTooltip`)}>
            <Icon tone={disabled ? 'subdued' : 'base'} source={InfoIcon} />
          </Tooltip>
        </InlineStack>
        <div className='TextStyleRow'>
          {i18n.translate(`Font`)}
          <div className='RightContent'>
            <FontSelector
              disabled={
                textStyle.font?.type === FontStyleTypeDtoEnum.INHERITED ||
                disabled
              }
              fontId={textStyle.font?.fontId || ''}
              setFontId={(value) =>
                setTextStyle({
                  ...textStyle,
                  font: { ...textStyle.font, fontId: value },
                })
              }
            />
          </div>
        </div>
      </>
    ),
    [textStyle, i18n, disabled]
  );

  return (
    <div
      className={classNames('TextStyle', {
        HideHeader: hideHeader,
      })}
    >
      {!hideHeader && (
        <StyleComponentHeader
          label={title ? title : i18n.translate(`TextStyle`)}
          inherited={inherited}
          buttons={[
            {
              external: true,
              content: i18n.translate(`ResetToSystemDefault`),
              disabled: disabled,
              onAction: () => {
                onSystemReset?.();
              },
            },
          ]}
        />
      )}
      <div className='TextStyleContent'>
        {bannerText && <Banner>{bannerText}</Banner>}
        {offersNote && (
          <Text as='p' tone='subdued'>
            {i18n.translate('OffersNote')}
          </Text>
        )}
        {!onlyFont && (
          <div className='TextStyleRow'>
            {i18n.translate(`Color`)}
            <div className='RightContent'>
              <ColorSelectorBox
                disabled={disabled}
                color={textStyle.color || ''}
                setColor={(value) =>
                  setTextStyle({ ...textStyle, color: value })
                }
              />
            </div>
          </div>
        )}

        {!onlyColor && !onlyFont && (
          <div className='TextStyleRow'>
            <div className='AdancedSection'>
              <Button
                variant='plain'
                onClick={() => setIsAdvancedOpen(!isAdvancedOpen)}
                icon={isAdvancedOpen ? ChevronUpIcon : ChevronDownIcon}
              >
                {i18n.translate(`Advanced`)}
              </Button>
              <Collapsible
                open={isAdvancedOpen}
                id='basic-collapsible'
                transition={{
                  duration: '500ms',
                  timingFunction: 'ease-in-out',
                }}
                expandOnPrint
              >
                <div className='TextStyleStyleRow'>
                  <div className='LeftContent'>
                    {i18n.translate(`TextStyle`)}
                  </div>
                  <div className='RightContent'>
                    <TextRow
                      disabled={disabled}
                      text={textStyle.style || {}}
                      setText={(data) =>
                        setTextStyle({ ...textStyle, style: data })
                      }
                    />
                  </div>
                </div>
                {fontInheritance}
              </Collapsible>
            </div>
          </div>
        )}
        {onlyFont && fontInheritance}

        {note && (
          <div className='TextStyleRow'>
            <Text as='p' tone='subdued'>
              {note}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};
