import { CoreExtensionTextStyle } from '.';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    fontSize: {
      /**
       * Set the font size
       */
      setFontSize: (size: number) => ReturnType;
      setFontSizeWithOutFocus: (size: number) => ReturnType;
      /**
       * Unset the font size
       */
      unsetFontSize: () => ReturnType;
    };
  }
}

const ExtensionFontSize = CoreExtensionTextStyle.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      fontSize: {
        default: null,
        parseHTML: (element) => element.style.fontSize.replace('px', ''),
        renderHTML: (attributes) => {
          if (!attributes['fontSize'] && attributes['fontSize'] !== 0) {
            return {};
          }
          return {
            style: `font-size: ${attributes['fontSize']}px`,
          };
        },
      },
    };
  },

  addCommands() {
    return {
      ...this.parent?.(),
      setFontSize:
        (fontSize) =>
        ({ chain }) => {
          return chain().setMark(this.name, { fontSize: fontSize }).run();
        },
      setFontSizeWithOutFocus:
        (fontSize) =>
        ({ chain }) => {
          return chain().setMark(this.name, { fontSize: fontSize }).run();
        },
      unsetFontSize:
        () =>
        ({ chain }) => {
          return chain()
            .focus()
            .setMark(this.name, { fontSize: null })
            .removeEmptyTextStyle()
            .run();
        },
    };
  },
});

export default ExtensionFontSize;
