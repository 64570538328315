import { Button, Range, LegacyStack } from '@shopify/polaris';
import React from 'react';
import { DateRange } from 'core/components/ControlLibrary';
import { rotateThickIconLookup } from 'core/components/iconsDefinitions';
import { useIcon } from 'core/hooks';

type DateRangeBarProps = {
  startDate?: Date;
  endDate?: Date;
  disableDatesAfter?: Date;

  onChangeDateRange?: (dateRange: Range) => void;
  onRefresh?: () => void;
};

export const DateRangeBar: React.FC<DateRangeBarProps> = (props) => {
  const {
    startDate,
    endDate,
    disableDatesAfter,
    onChangeDateRange,
    onRefresh,
  } = props;
  const rotateIcon = useIcon(rotateThickIconLookup);

  return (
    <LegacyStack spacing='baseTight'>
      <DateRange
        startDate={startDate}
        endDate={endDate}
        disableDatesAfter={disableDatesAfter}
        onChangeDateRange={onChangeDateRange}
      />
      <Button icon={rotateIcon} onClick={onRefresh}>
        Refresh
      </Button>
    </LegacyStack>
  );
};
