import React, { useCallback, useMemo } from 'react';
import { SelectedOptionPath } from '../../../../RightSideBarWrapper';
import {
  PromotionCodeFieldFirstLevelPresetDto,
  PromotionCodeFieldPresetDtoRead,
  PromotionCodeFieldSystemDefaultsDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import PromotionCodeGeneral from './components/PromotionCodeGeneral/PromotionCodeGeneral';
import PromotionCodeInputCode from './components/PromotionCodeInputCode/PromotionCodeInputCode';
import PromotionCodeValidationCode from './components/PromotionCodeValidationCode/PromotionCodeValidationCode';
import PromotionCodeCTA from './components/PromotionCodeCTA/PromotionCodeCTA';
import { getLastSelectedOption } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import {
  WidgetListOptionsDtoEnum,
  WidgetListTypeDtoEnum,
} from 'features/settings/components/EditPresetSkeleton/enums/enums';

type PresetPromotionCodeProps = {
  selectedOptionPath: SelectedOptionPath;
  promotionCodeField: PromotionCodeFieldPresetDtoRead;
  defaultPresetData: PromotionCodeFieldPresetDtoRead;
  defaultLanguage: string;
  shopDefaultData: PromotionCodeFieldSystemDefaultsDto;
  updatePromotionCodeField: (data: PromotionCodeFieldPresetDtoRead) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const PresetPromotionCode: React.FC<PresetPromotionCodeProps> = ({
  selectedOptionPath,
  promotionCodeField,
  defaultPresetData,
  defaultLanguage,
  shopDefaultData,
  updatePromotionCodeField,
  handleLeftMenuSelection,
}) => {
  const updateComponent = useCallback(
    (
      field: keyof PromotionCodeFieldFirstLevelPresetDto,
      data: PromotionCodeFieldFirstLevelPresetDto[keyof PromotionCodeFieldFirstLevelPresetDto]
    ) =>
      updatePromotionCodeField({
        ...promotionCodeField,
        options: {
          ...promotionCodeField?.options,
          [field]: data,
        },
      }),

    [promotionCodeField]
  );

  const promotionCodeOptions = useMemo(() => {
    const selectedOption = getLastSelectedOption(selectedOptionPath);
    const type =
      selectedOption?.name ===
      WidgetListTypeDtoEnum.DRAWER_CART_PROMOTION_CODE_FIELD
        ? WidgetListTypeDtoEnum.DRAWER_CART_PROMOTION_CODE_FIELD
        : WidgetListTypeDtoEnum.CART_PROMOTION_CODE_FIELD;

    switch (true) {
      case selectedOption?.name === type:
        return (
          <PromotionCodeGeneral
            general={promotionCodeField?.general || {}}
            defaultLanguage={defaultLanguage}
            defaultGeneral={defaultPresetData?.general || {}}
            shopDefaultGeneral={shopDefaultData.general || {}}
            isPresetEditor
            type={type}
            setGeneral={(data) =>
              updatePromotionCodeField({
                ...promotionCodeField,
                general: data,
              })
            }
            onShowHide={
              promotionCodeField.canBeHidden
                ? () =>
                    updatePromotionCodeField({
                      ...promotionCodeField,
                      isHidden: !promotionCodeField.isHidden,
                    })
                : undefined
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.CODE_INPUT:
        return (
          <PromotionCodeInputCode
            codeInput={promotionCodeField?.options?.codeInput || {}}
            defaultCodeInput={defaultPresetData?.options?.codeInput || {}}
            isPresetEditor
            shopDefaultCodeInput={shopDefaultData.codeInput || {}}
            defaultLanguage={defaultLanguage}
            setCodeInput={(data) => updateComponent('codeInput', data)}
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.CODE_VALIDATION:
        return (
          <PromotionCodeValidationCode
            codeValidation={promotionCodeField?.options?.codeValidation || {}}
            defaultCodeValidation={
              defaultPresetData?.options?.codeValidation || {}
            }
            isPresetEditor
            defaultLanguage={defaultLanguage}
            shopDefaultCodeValidation={shopDefaultData.codeValidation || {}}
            setCodeValidation={(data) =>
              updateComponent('codeValidation', data)
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.CTA_BUTTON:
        return (
          <PromotionCodeCTA
            ctaButton={promotionCodeField.options?.callToActionButton || {}}
            defaultCTAButton={
              defaultPresetData.options?.callToActionButton || {}
            }
            isPresetEditor
            defaultLanguage={defaultLanguage}
            shopDefaultCTA={shopDefaultData.callToActionButton || {}}
            setCTAButton={(data) => updateComponent('callToActionButton', data)}
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
    }
  }, [
    selectedOptionPath,
    shopDefaultData,
    promotionCodeField,
    defaultPresetData,
    updatePromotionCodeField,
    updateComponent,
    handleLeftMenuSelection,
  ]);
  return <div className='PresetPromotionCode'>{promotionCodeOptions}</div>;
};

export default PresetPromotionCode;
