import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './AnnouncementBar.scss';
import { useI18n } from '@shopify/react-i18n';
import { OfferWidgetSetupDtoAnnouncementBarOfferDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { useAppSelector } from 'core/hooks';
import { PreviewEntry } from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components';
import {
  getCurrentMessage,
  parseSmartTagCode,
} from 'features/settings/components/EditPresetSkeleton/utils/utils';
import {
  AnnouncementBarOfferPositionDtoEnum,
  DeviceTypeDtoEnum,
  PageTypeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import { usePrevious } from 'core/hooks/usePrevious';
import {
  WidgetsFetchTypeEnum,
  useConfigureWidgets,
} from 'features/settings/hooks/useConfigureWidgets';
import { isEqual } from 'lodash';

type AnnouncementBarProps = {
  data: any;
  previewType: DeviceTypeDtoEnum;
  defaultLanguage?: string;
  adminActivePath: string;
  getSelectionFromThePreview(path: string): void;
};

export const AnnouncementBar: React.FC<AnnouncementBarProps> = ({
  previewType,
  defaultLanguage,
  data,
  adminActivePath,
  getSelectionFromThePreview,
}) => {
  const [i18n] = useI18n();

  const { fontListData } = useConfigureWidgets(
    WidgetsFetchTypeEnum.PRESET_FONT_LIST
  );

  const {
    defaultIconLibary,
    defaultImageLibary,
    customIconLibary,
    customImageLibary,
    announcementBarTextMessages,
    selectedPage,
  } = useAppSelector((state) => state.widgets);
  const { smartTagsCodes } = useAppSelector((state) => state.offersWizard);

  const [currentData, setCurrentData] = useState<any>({});
  const [injectStyles, setInjectStyles] = useState<boolean>(false);
  const [shouldApplyStyles, setShouldApplyStyles] = useState<boolean>(false);

  const laDnAnnouncementBar = document.querySelector('la-dn-announcement-bar');

  const imagesArray = useMemo(
    () => [...defaultImageLibary, ...customImageLibary],
    [customImageLibary, defaultImageLibary]
  );
  const iconsArray = useMemo(
    () => [...defaultIconLibary, ...customIconLibary],
    [customIconLibary, defaultIconLibary]
  );

  const assetsArray = useMemo(
    () =>
      [...iconsArray, ...imagesArray].map((item) => ({
        id: item.id,
        url: item.url,
      })),
    [iconsArray, imagesArray]
  );

  const currentMessages = useMemo(
    () =>
      data?.offers
        ? data.offers
            .map((item: any) => ({
              token: item.offerId,
              index: parseInt(item.parentKey?.match(/\d+/)?.[0] || '0'),
              slot:
                item.parentKey ===
                AnnouncementBarOfferPositionDtoEnum.ANNOUNCEMENT_BAR_PRIMARY_BANNER
                  ? 0
                  : 1,
              message:
                item.offerId &&
                announcementBarTextMessages?.length &&
                announcementBarTextMessages.find(
                  (message) => message.offerId === item.offerId
                )?.[previewType.toLowerCase() as keyof PreviewEntry]
                  ? parseSmartTagCode(
                      announcementBarTextMessages.find(
                        (message) => message.offerId === item.offerId
                      )?.[previewType.toLowerCase() as keyof PreviewEntry] ||
                        '',
                      smartTagsCodes
                    )
                  : getCurrentMessage(
                      item.setup?.options?.message?.text?.entries,
                      defaultLanguage,
                      smartTagsCodes
                    ),
              icon: item.setup?.currentEntries?.includes('ICON')
                ? iconsArray.find(
                    (icon) =>
                      icon.id === item.setup?.options?.icon?.icon?.iconId
                  )?.url
                : undefined,
              image: item.setup?.currentEntries?.includes('IMAGE')
                ? imagesArray.find(
                    (image) =>
                      image.id === item.setup?.options?.image?.image?.imageId
                  )?.url
                : undefined,
            }))
            .sort((a: any, b: any) => {
              if (a.index === 0 && b.index !== 0) {
                return -1;
              } else if (a.index !== 0 && b.index === 0) {
                return 1;
              } else {
                return a.index - b.index;
              }
            })
        : [
            {
              token: 'example1',
              message: i18n.translate('example'),
              slot: 0,
              icon: iconsArray[0].url,
              image: null,
            },
            {
              token: 'example2',
              message: i18n.translate('example'),
              slot: 0,
              icon: null,
              image: imagesArray[0].url,
            },
            {
              token: 'example3',
              slot: 1,
              message: i18n.translate('example'),
              icon: iconsArray[0].url,
              image: null,
            },
            {
              token: 'example4',
              slot: 1,
              message: i18n.translate('example'),
              icon: null,
              image: imagesArray[0].url,
            },
          ],
    [
      i18n,
      data.options,
      data?.offers,
      iconsArray,
      imagesArray,
      defaultLanguage,
      announcementBarTextMessages,
      previewType,
      smartTagsCodes,
    ]
  );

  const prevData = usePrevious(data);
  const prevMessages = usePrevious(currentMessages);

  const primarySlots = useMemo(
    () =>
      data?.offers
        ? data?.offers.filter(
            (item: OfferWidgetSetupDtoAnnouncementBarOfferDto) =>
              item.parentKey ===
              AnnouncementBarOfferPositionDtoEnum.ANNOUNCEMENT_BAR_PRIMARY_BANNER
          )
        : [{ offerId: 'example1' }, { offerId: 'example2' }],
    [data?.offers]
  );

  const secondarySlots = useMemo(
    () =>
      data?.offers
        ? data?.offers
            .filter(
              (item: OfferWidgetSetupDtoAnnouncementBarOfferDto) =>
                item.parentKey !==
                AnnouncementBarOfferPositionDtoEnum.ANNOUNCEMENT_BAR_PRIMARY_BANNER
            )
            .map((item: OfferWidgetSetupDtoAnnouncementBarOfferDto) => ({
              ...item,
              ...(item.parentKey?.match(/\d+/)?.[0].length && {
                index: parseInt(item.parentKey?.match(/\d+/)?.[0] || '1') - 1,
              }),
            }))
            .sort((a: any, b: any) => a.index - b.index)
        : [
            { offerId: 'example3', index: 0 },
            { offerId: 'example4', index: 1 },
          ],
    [data?.offers]
  );

  const currentAdminMode = useMemo(
    () => (selectedPage === PageTypeDtoEnum.DRAWER_CART ? 'false' : 'true'),
    [selectedPage]
  );

  const elementToReplaceWith = useMemo(
    () =>
      `
  <la-dn-announcement-bar
                admin-mode="${currentAdminMode}"
                admin-active-path="${adminActivePath}"
                admin-mode-label='[{"component":"announcementBar","label":"${i18n.translate(
                  'announcementBar'
                )}","icon":"widget","subComponents":[{"component":"primaryBanner","label":"${i18n.translate(
        'primaryBanner'
      )}","icon":"component","subComponents":[{"component":"chevrons","label":"${i18n.translate(
        'chevrons'
      )}","icon":"component"},{"component":"indicators","label":"${i18n.translate(
        'indicators'
      )}","icon":"component"},{"component":"closeIcon","label":"${i18n.translate(
        'closeButton'
      )}","icon":"component"},{"component":"banner","label":"${i18n.translate(
        'offer'
      )}","icon":"offer","subComponents":[{"component":"message","label":"${i18n.translate(
        'message'
      )}","icon":"component","subComponents":[{"component":"link","label":"${i18n.translate(
        'link'
      )}","icon":"component"}]},{"component":"icon","label":"${i18n.translate(
        'icon'
      )}","icon":"component"},{"component":"image","label":"${i18n.translate(
        'image'
      )}","icon":"component"}]}]},{"component":"secondaryBanner","label":"${i18n.translate(
        'secondaryBanner'
      )}","icon":"component","subComponents":[{"component":"banner","label":"${i18n.translate(
        'offer'
      )}","icon":"offer","subComponents":[{"component":"message","label":"${i18n.translate(
        'message'
      )}","icon":"component","subComponents":[{"component":"link","label":"${i18n.translate(
        'link'
      )}","icon":"component"}]},{"component":"icon","label":"${i18n.translate(
        'icon'
      )}","icon":"component"},{"component":"image","label":"${i18n.translate(
        'image'
      )}","icon":"component"}]}]}]}]'
               state="closed"
                id="4"
                closeable="true"
                placeholder="announcement-bar-root"
                animation-on-close="fade"
                animation-on-cycle="slide"
                animation-on-highlight="shakeX"
                animation-on-goal-achieved="shakeX"
            >
                ${
                  primarySlots?.length
                    ? `<la-dn-announcement-bar-row primary="true">
                    ${primarySlots
                      .map((item: any, idx: number) =>
                        idx === 0
                          ? `<la-dn-announcement-bar-slot controls="true" cycle="true" interval="4000" slot-index="${idx}" key="${item.offerId}"></la-dn-announcement-bar-slot>`
                          : `<la-dn-announcement-bar-slot slot-index="${idx}" key="${item.offerId}"></la-dn-announcement-bar-slot>`
                      )
                      .join('')}
                  </la-dn-announcement-bar-row>`
                    : ''
                }
                ${
                  secondarySlots?.length
                    ? `<la-dn-announcement-bar-row>
                    ${secondarySlots
                      .map(
                        (item: any) =>
                          `<la-dn-announcement-bar-slot slot-index="${item.index}" key="${item.offerId}"></la-dn-announcement-bar-slot>`
                      )
                      .join('')}
                  </la-dn-announcement-bar-row>`
                    : ''
                }
            </la-dn-announcement-bar>
`,
    [adminActivePath, i18n, primarySlots, secondarySlots, currentAdminMode]
  );

  const showAnnouncement = useCallback(
    (data: any) => {
      const parser = new DOMParser();
      const container = document.querySelector('#announcement-bar-root');

      const barComponent = parser
        .parseFromString(elementToReplaceWith, 'text/html')
        .querySelector('la-dn-announcement-bar');
      const id = barComponent?.getAttribute('id');

      setCurrentData({ messages: data.messages, id });

      if (
        container &&
        barComponent &&
        !container.querySelector('la-dn-announcement-bar')
      ) {
        container?.appendChild(barComponent);
      } else if (
        container &&
        barComponent &&
        container.querySelector('la-dn-announcement-bar')
      ) {
        const bar = container.querySelector('la-dn-announcement-bar');
        bar?.setAttribute('admin-mode', currentAdminMode);
        if (bar) {
          bar.innerHTML = barComponent.innerHTML;
        }
      }
    },
    [currentAdminMode, elementToReplaceWith, setCurrentData]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      if ((window as any).AnnouncementBar) {
        document.dispatchEvent(
          new CustomEvent(`la:dn:announcement-bar:render`, {
            detail: currentData,
          })
        );
        setInjectStyles(true);
      } else {
        document.addEventListener('la:dn:announcement-bar:ready', () => {
          document.dispatchEvent(
            new CustomEvent(`la:dn:announcement-bar:render`, {
              detail: currentData,
            })
          );
          setInjectStyles(true);
        });
      }
    }, 0);

    return () => clearTimeout(timer);
  }, [currentData]);

  useEffect(() => {
    if (data && !isEqual(data, prevData)) {
      setShouldApplyStyles(true);
    }
  }, [data]);

  useEffect(() => {
    if (injectStyles && shouldApplyStyles) {
      laDnAnnouncementBar?.setAttribute('styles', JSON.stringify(data));
      setShouldApplyStyles(false);
    }
  }, [shouldApplyStyles, injectStyles]);

  useEffect(() => {
    if (injectStyles) {
      laDnAnnouncementBar?.setAttribute(
        'image-assets',
        JSON.stringify(assetsArray)
      );
    }
  }, [assetsArray, injectStyles]);

  useEffect(() => {
    if (injectStyles) {
      laDnAnnouncementBar?.setAttribute(
        'font-assets',
        JSON.stringify(fontListData)
      );
    }
  }, [fontListData, injectStyles]);

  useEffect(() => {
    if (injectStyles) {
      laDnAnnouncementBar?.setAttribute(
        'force-mobile',
        (previewType === DeviceTypeDtoEnum.MOBILE).toString()
      );
    }
  }, [injectStyles, previewType]);

  useEffect(() => {
    if (injectStyles) {
      laDnAnnouncementBar?.setAttribute('admin-active-path', adminActivePath);
    }
  }, [adminActivePath]);

  useEffect(() => {
    showAnnouncement({
      id: '4',
      messages: currentMessages,
    });
  }, [currentAdminMode]);

  useEffect(() => {
    if (data?.offers?.length) {
      data?.offers.forEach(
        (offer: OfferWidgetSetupDtoAnnouncementBarOfferDto) => {
          const prevOfferParentKey = prevData?.offers?.find(
            (item: OfferWidgetSetupDtoAnnouncementBarOfferDto) =>
              item.offerId === offer.offerId
          )?.parentKey;
          if (offer.parentKey !== prevOfferParentKey) {
            showAnnouncement({
              id: '4',
              messages: currentMessages,
            });
          }
        }
      );
    }
  }, [data?.offers]);

  useEffect(() => {
    if (
      currentMessages &&
      injectStyles &&
      !isEqual(currentMessages, prevMessages)
    ) {
      document.dispatchEvent(
        new CustomEvent('la:dn:announcement-bar:update', {
          detail: {
            id: '4',
            messages: currentMessages,
          },
        })
      );
    }
    laDnAnnouncementBar?.addEventListener(
      'la:dn:announcement-bar:admin-change',
      (e) => getSelectionFromThePreview((e as CustomEvent).detail)
    );
  }, [currentMessages]);

  return <div id='announcement-bar-root'></div>;
};
