import { I18n } from '@shopify/react-i18n';
import {
  PromotionListSortFieldDtoEnum,
  PromotionStatusDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { formatDistanceToNow } from 'date-fns';
import { Tone } from '@shopify/polaris/build/ts/src/components/Badge';

export const getProgressLeftTime = (estimatedTimeLeft: number) => {
  const formattedTimeLeft = formatDistanceToNow(
    new Date().getTime() + estimatedTimeLeft * 1000,
    { addSuffix: true }
  );
  return formattedTimeLeft;
};

export const getPromotionStatusColor = (
  status: PromotionStatusDtoEnum
): Tone => {
  switch (status) {
    case PromotionStatusDtoEnum.IN_PROGRESS_PUBLISHING:
    case PromotionStatusDtoEnum.IN_PROGRESS_UNPUBLISHING:
    case PromotionStatusDtoEnum.IN_PROGRESS_ROLLING_BACK:
    case PromotionStatusDtoEnum.DRAFT:
      return 'info';
    case PromotionStatusDtoEnum.SCHEDULED:
      return 'info-strong';
    case PromotionStatusDtoEnum.PUBLISHED_EDITED:
    case PromotionStatusDtoEnum.PUBLISHED:
      return 'success';
    default:
      return 'new';
  }
};

export const sortPopoverOptions = (i18n: I18n) => [
  {
    label: i18n.translate('PromotionTitle'),
    value: `${PromotionListSortFieldDtoEnum.NAME} asc` as const,
    directionLabel: i18n.translate('Ascending'),
  },
  {
    label: i18n.translate('PromotionTitle'),
    value: `${PromotionListSortFieldDtoEnum.NAME} desc` as const,
    directionLabel: i18n.translate('Descending'),
  },
  {
    label: i18n.translate('Token'),
    value: `${PromotionListSortFieldDtoEnum.TOKEN} asc` as const,
    directionLabel: i18n.translate('Ascending'),
  },
  {
    label: i18n.translate('Token'),
    value: `${PromotionListSortFieldDtoEnum.TOKEN} desc` as const,
    directionLabel: i18n.translate('Descending'),
  },
  {
    label: i18n.translate('Status'),
    value: `${PromotionListSortFieldDtoEnum.STATUS} asc` as const,
    directionLabel: i18n.translate('Ascending'),
  },
  {
    label: i18n.translate('Status'),
    value: `${PromotionListSortFieldDtoEnum.STATUS} desc` as const,
    directionLabel: i18n.translate('Descending'),
  },
  {
    label: i18n.translate('Schedule'),
    value: `${PromotionListSortFieldDtoEnum.SCHEDULE} asc` as const,
    directionLabel: i18n.translate('Ascending'),
  },
  {
    label: i18n.translate('Schedule'),
    value: `${PromotionListSortFieldDtoEnum.SCHEDULE} desc` as const,
    directionLabel: i18n.translate('Descending'),
  },
  {
    label: i18n.translate('Trigger'),
    value: `${PromotionListSortFieldDtoEnum.TRIGGER_TYPE} asc` as const,
    directionLabel: i18n.translate('Ascending'),
  },
  {
    label: i18n.translate('Trigger'),
    value: `${PromotionListSortFieldDtoEnum.TRIGGER_TYPE} desc` as const,
    directionLabel: i18n.translate('Descending'),
  },
  {
    label: i18n.translate('Type'),
    value: `${PromotionListSortFieldDtoEnum.PROMOTION_TYPE} asc` as const,
    directionLabel: i18n.translate('Ascending'),
  },
  {
    label: i18n.translate('Type'),
    value: `${PromotionListSortFieldDtoEnum.PROMOTION_TYPE} desc` as const,
    directionLabel: i18n.translate('Descending'),
  },
  {
    label: i18n.translate('Modified'),
    value: `${PromotionListSortFieldDtoEnum.MODIFICATION_DATE} asc` as const,
    directionLabel: i18n.translate('Ascending'),
  },
  {
    label: i18n.translate('Modified'),
    value: `${PromotionListSortFieldDtoEnum.MODIFICATION_DATE} desc` as const,
    directionLabel: i18n.translate('Descending'),
  },
];

export const sortColumnOptions = (
  index: number,
  direction: 'ascending' | 'descending'
) => {
  switch (true) {
    case index === 1 && direction === 'ascending':
      return `${PromotionListSortFieldDtoEnum.NAME} asc`;
    case index === 1 && direction === 'descending':
      return `${PromotionListSortFieldDtoEnum.NAME} desc`;

    case index === 2 && direction === 'ascending':
      return `${PromotionListSortFieldDtoEnum.TOKEN} asc`;
    case index === 2 && direction === 'descending':
      return `${PromotionListSortFieldDtoEnum.TOKEN} desc`;

    case index === 3 && direction === 'ascending':
      return `${PromotionListSortFieldDtoEnum.STATUS} asc`;
    case index === 3 && direction === 'descending':
      return `${PromotionListSortFieldDtoEnum.STATUS} desc`;

    case index === 4 && direction === 'ascending':
      return `${PromotionListSortFieldDtoEnum.SCHEDULE} asc`;
    case index === 4 && direction === 'descending':
      return `${PromotionListSortFieldDtoEnum.SCHEDULE} desc`;

    case index === 5 && direction === 'ascending':
      return `${PromotionListSortFieldDtoEnum.TRIGGER_TYPE} asc`;
    case index === 5 && direction === 'descending':
      return `${PromotionListSortFieldDtoEnum.TRIGGER_TYPE} desc`;

    case index === 6 && direction === 'ascending':
      return `${PromotionListSortFieldDtoEnum.PROMOTION_TYPE} asc`;
    case index === 6 && direction === 'descending':
      return `${PromotionListSortFieldDtoEnum.PROMOTION_TYPE} desc`;

    case index === 7 && direction === 'ascending':
      return `${PromotionListSortFieldDtoEnum.MODIFICATION_DATE} asc`;
    case index === 7 && direction === 'descending':
      return `${PromotionListSortFieldDtoEnum.MODIFICATION_DATE} desc`;
  }
};
