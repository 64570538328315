import {
  OfferWidgetSetupDtoPromotionalBadgeOfferDtoRead,
  PresetsSetupDto,
  PromotionSetupDtoRead,
  PromotionWidgetSetupDtoPromotionalBadgePromotionDto,
  PromotionalBadgeBadgeOfferDto,
  PromotionalBadgeGeneralOfferDto,
  PromotionalBadgeMessageOfferDto,
  PromotionalBadgeOfferFirstLevelDtoRead,
  PromotionalBadgePresetDto,
  PromotionalBadgeSystemDefaultsDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import React, { useCallback, useMemo } from 'react';
import { SelectedOptionPath } from '../../../../RightSideBarWrapper';
import { WidgetTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import PromotionalBadgeGeneral from '../../../EditPresetRightBar/components/PresetPromotionalBadge/components/PromotionalBadgeGeneral/PromotionalBadgeGeneral';
import PromotionalBadgeOffer from './components/PromotionalBadgeOffer/PromotionalBadgeOffer';
import PromotionalBadgeMessage from '../../../EditPresetRightBar/components/PresetPromotionalBadge/components/PromotionalBadgeMessage/PromotionalBadgeMessage';
import PromotionalBadgeBadge from '../../../EditPresetRightBar/components/PresetPromotionalBadge/components/PromotionalBadgeBadge/PromotionalBadgeBadge';
import { getLastSelectedOption } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import {
  WidgetListOptionsDtoEnum,
  WidgetListTypeDtoEnum,
} from 'features/settings/components/EditPresetSkeleton/enums/enums';

type PromotionPromotionalBadgeProps = {
  editPromotionState: PromotionSetupDtoRead;
  defaultPromotionData: PromotionSetupDtoRead;
  shopDefaultData: PromotionalBadgeSystemDefaultsDto;
  selectedOptionPath: SelectedOptionPath;
  updateEditPromotionState: (
    field: keyof PromotionSetupDtoRead,
    data: PromotionSetupDtoRead[keyof PromotionSetupDtoRead],
    presetSetup?: PresetsSetupDto
  ) => void;
  filterSupportedWidgets: (value: WidgetTypeDtoEnum) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const PromotionPromotionalBadge: React.FC<PromotionPromotionalBadgeProps> = ({
  editPromotionState,
  defaultPromotionData,
  shopDefaultData,
  selectedOptionPath,
  updateEditPromotionState,
  filterSupportedWidgets,
  handleLeftMenuSelection,
}) => {
  const { promotionalBadge, presetSetup } = editPromotionState;
  const offersData = editPromotionState.offers?.promotionalBadge;
  const widgetPreset = presetSetup?.promotionalBadge;
  const defaultOffersData = defaultPromotionData.offers?.promotionalBadge;
  const defaultPresetSetup = defaultPromotionData.presetSetup?.promotionalBadge;

  const updatePromotionalBadge = useCallback(
    (
      field: keyof PromotionWidgetSetupDtoPromotionalBadgePromotionDto,
      data: PromotionWidgetSetupDtoPromotionalBadgePromotionDto[keyof PromotionWidgetSetupDtoPromotionalBadgePromotionDto]
    ) =>
      updateEditPromotionState('promotionalBadge', {
        ...promotionalBadge,
        [field]: data,
      }),
    [promotionalBadge, updateEditPromotionState]
  );

  const updateOffer = useCallback(
    (data: OfferWidgetSetupDtoPromotionalBadgeOfferDtoRead, offerId: string) =>
      updateEditPromotionState('offers', {
        ...editPromotionState.offers,
        promotionalBadge: editPromotionState.offers?.promotionalBadge?.map(
          (offerData) => {
            if (offerData?.offerId === offerId) {
              return data;
            }
            return offerData;
          }
        ),
      }),

    [updateEditPromotionState, editPromotionState]
  );

  const updateComponent = useCallback(
    (
      field: keyof PromotionalBadgeOfferFirstLevelDtoRead,
      data: PromotionalBadgeOfferFirstLevelDtoRead[keyof PromotionalBadgeOfferFirstLevelDtoRead],
      offerId: string,
      preset?: PromotionalBadgePresetDto
    ) =>
      updateEditPromotionState(
        'offers',
        {
          ...editPromotionState.offers,
          promotionalBadge: editPromotionState.offers?.promotionalBadge?.map(
            (obj) => {
              if (obj.offerId === offerId) {
                return {
                  ...obj,
                  setup: {
                    ...obj.setup,
                    options: {
                      ...obj.setup?.options,
                      [field]: data,
                    },
                  },
                };
              }
              return obj;
            }
          ),
        },
        { ...presetSetup, promotionalBadge: preset }
      ),

    [updateEditPromotionState, editPromotionState]
  );

  const handleComponentVisability = useCallback(
    (
      offerData: OfferWidgetSetupDtoPromotionalBadgeOfferDtoRead,
      option: keyof PromotionalBadgeOfferFirstLevelDtoRead
    ) => {
      updateComponent(
        option,
        {
          ...offerData?.setup?.options?.[option],
          isHidden: !offerData?.setup?.options?.[option]?.isHidden,
        },
        offerData?.offerId as string
      );
    },
    [updateComponent]
  );

  const promotionalBadgeOptions = useMemo(() => {
    const offerData = offersData?.find(
      (offer) => offer.offerId === selectedOptionPath?.selectedOption?.offerId
    ) as OfferWidgetSetupDtoPromotionalBadgeOfferDtoRead;

    const defaultOfferData = defaultOffersData?.find(
      (offer) => offer.offerId === selectedOptionPath?.selectedOption?.offerId
    ) as OfferWidgetSetupDtoPromotionalBadgeOfferDtoRead;

    const selectedOption = getLastSelectedOption(selectedOptionPath);

    const updateMessageComponent = (data: PromotionalBadgeMessageOfferDto) => {
      updateComponent(
        'message',
        { ...offerData.setup?.options?.message, message: data.message },
        offerData?.offerId || '',
        {
          ...widgetPreset,
          options: {
            ...widgetPreset?.options,
            message: { ...widgetPreset?.options?.message, style: data.style },
          },
        }
      );
    };

    const updateBadgeComponent = (data: PromotionalBadgeBadgeOfferDto) => {
      updateEditPromotionState('presetSetup', presetSetup, {
        ...presetSetup,
        promotionalBadge: {
          ...presetSetup?.promotionalBadge,
          options: {
            ...presetSetup?.promotionalBadge?.options,
            badge: data,
          },
        },
      });
    };

    const updateOfferComponent = (data: PromotionalBadgeGeneralOfferDto) => {
      updateOffer(
        {
          ...offerData,
          setup: {
            ...offerData?.setup,
            general: data,
          },
        },
        offerData?.offerId as string
      );
    };

    switch (true) {
      case selectedOption?.name === WidgetListTypeDtoEnum.PROMOTIONAL_BADGE:
        return (
          <PromotionalBadgeGeneral
            general={promotionalBadge?.general || {}}
            onShowHide={
              promotionalBadge?.canBeHidden
                ? () =>
                    updatePromotionalBadge(
                      'isHidden',
                      !promotionalBadge?.isHidden
                    )
                : undefined
            }
            onRemove={() =>
              filterSupportedWidgets(WidgetTypeDtoEnum.PROMOTIONAL_BADGE)
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case !!selectedOption?.offerId:
        return (
          <PromotionalBadgeOffer
            offer={offerData?.setup?.general || {}}
            offerName={selectedOption?.name || ''}
            defaultOffer={defaultOfferData?.setup?.general || {}}
            shopDefaultOffer={shopDefaultData}
            handleLeftMenuSelection={handleLeftMenuSelection}
            onShowHide={
              offerData.canBeHidden
                ? () =>
                    updateOffer(
                      { ...offerData, isHidden: !offerData?.isHidden },
                      offerData?.offerId as string
                    )
                : undefined
            }
            setOffer={updateOfferComponent}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.MESSAGE:
        return (
          <PromotionalBadgeMessage
            key={offerData?.offerId}
            message={offerData?.setup?.options?.message || {}}
            presetSetup={widgetPreset?.options?.message || {}}
            offerId={offerData?.offerId}
            defaultMessage={{
              ...defaultOfferData?.setup?.options?.message,
              style: defaultPresetSetup?.options?.message?.style,
            }}
            defaultLanguage={editPromotionState.offers?.defaultLanguage || ''}
            shopDefaultMessage={shopDefaultData.textStyle || {}}
            handleLeftMenuSelection={handleLeftMenuSelection}
            onShowHide={
              offerData.setup?.options?.message?.canBeHidden
                ? () => handleComponentVisability(offerData, 'message')
                : undefined
            }
            setMessage={updateMessageComponent}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.BADGE:
        return (
          <PromotionalBadgeBadge
            key={offerData?.offerId}
            badge={widgetPreset?.options?.badge || {}}
            defaultBadge={defaultPresetSetup?.options?.badge || {}}
            shopDefaultData={shopDefaultData}
            handleLeftMenuSelection={handleLeftMenuSelection}
            setBadge={updateBadgeComponent}
            onShowHide={
              offerData.setup?.options?.badge?.canBeHidden
                ? () => handleComponentVisability(offerData, 'badge')
                : undefined
            }
          />
        );
    }
  }, [
    promotionalBadge,
    offersData,
    defaultOffersData,
    widgetPreset,
    defaultPresetSetup,
    editPromotionState,
    selectedOptionPath,
    updateComponent,
    handleLeftMenuSelection,
    updateOffer,
    handleComponentVisability,
  ]);

  return (
    <div className='PromotionPromotionalBadge'>{promotionalBadgeOptions}</div>
  );
};

export default PromotionPromotionalBadge;
