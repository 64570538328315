import React from 'react';
import { I18n } from '@shopify/react-i18n';
import { PromotionCodeListSortFieldDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';

export const sortPopoverOptions = (i18n: I18n) => [
  {
    label: i18n.translate('Code'),
    value: `${PromotionCodeListSortFieldDtoEnum.CODE} asc` as const,
    directionLabel: i18n.translate('Ascending'),
  },
  {
    label: i18n.translate('Code'),
    value: `${PromotionCodeListSortFieldDtoEnum.CODE} desc` as const,
    directionLabel: i18n.translate('Descending'),
  },
  {
    label: i18n.translate('Status'),
    value: `${PromotionCodeListSortFieldDtoEnum.STATUS} asc` as const,
    directionLabel: i18n.translate('Ascending'),
  },
  {
    label: i18n.translate('Status'),
    value: `${PromotionCodeListSortFieldDtoEnum.STATUS} desc` as const,
    directionLabel: i18n.translate('Descending'),
  },
  {
    label: i18n.translate('Usage'),
    value: `${PromotionCodeListSortFieldDtoEnum.USAGE} asc` as const,
    directionLabel: i18n.translate('Ascending'),
  },
  {
    label: i18n.translate('Usage'),
    value: `${PromotionCodeListSortFieldDtoEnum.USAGE} desc` as const,
    directionLabel: i18n.translate('Descending'),
  },
];

export const sortColumnOptions = (
  index: number,
  direction: 'ascending' | 'descending'
) => {
  switch (true) {
    case index === 0 && direction === 'ascending':
      return `${PromotionCodeListSortFieldDtoEnum.CODE} asc`;
    case index === 0 && direction === 'descending':
      return `${PromotionCodeListSortFieldDtoEnum.CODE} desc`;

    case index === 1 && direction === 'ascending':
      return `${PromotionCodeListSortFieldDtoEnum.STATUS} asc`;
    case index === 1 && direction === 'descending':
      return `${PromotionCodeListSortFieldDtoEnum.STATUS} desc`;

    case index === 2 && direction === 'ascending':
      return `${PromotionCodeListSortFieldDtoEnum.USAGE} asc`;
    case index === 2 && direction === 'descending':
      return `${PromotionCodeListSortFieldDtoEnum.USAGE} desc`;
  }
};
