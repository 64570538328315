import React from 'react';
import { Tooltip as PolarisTooltip } from '@shopify/polaris';
type TooltipProps = {
  content?: React.ReactNode;
  children: React.ReactNode;
};
export const Tooltip: React.FC<TooltipProps> = ({ content, children }) => {
  return (
    <>
      {content ? (
        <PolarisTooltip content={content}>{children}</PolarisTooltip>
      ) : (
        children
      )}
    </>
  );
};
