import {
  Banner,
  BlockStack,
  Button,
  Card,
  Checkbox,
  Icon,
  InlineStack,
  Page,
  Text,
  Tooltip,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { GrayBox, grayBoxInitialValues, Loader } from 'core/components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  EditIcon,
  DuplicateIcon,
  DeleteIcon,
  QuestionCircleIcon,
} from '@shopify/polaris-icons';
import { CartRulesRadios } from './components/CartRulesRadios/CartRulesRadios';
import { RuleSummary } from './components/RuleSummary/RuleSummary';
import { OfferTypeSpecialCases } from 'core/enums/GrayBoxEnum';
import { v4 as uuidv4 } from 'uuid';
import {
  OfferCartRuleDto,
  OfferCartRuleGroupDto,
  OfferCartRulesDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  OfferCartRuleGroupConnectorDtoEnum,
  OfferRuleTypeDtoEnum,
  OfferRuleValueTypeDtoEnum,
  OfferTargetTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { cloneDeep, isEqual } from 'lodash';
import {
  setCurrentConfigPage,
  setToastMessages,
} from 'core/store/offersWizardSlice';
import { useAppDispatch, useIsDebugOrLocal } from 'core/hooks';
import { ActionListInPopover } from 'features/promotions/components/ActionListInPopover/ActionListInPopover';
import './CartRules.scss';
import { RuleInfo } from './components/RuleInfo/RuleInfo';

type RuleValidityProps = {
  id: string;
  isValid: boolean;
};

export type CartRulesProps = {
  savedData?: OfferCartRulesDto;
  specialOfferType?: OfferTypeSpecialCases;
  productAmount?: number | string;
  onFormValidityChange(formIsValid: boolean): void;
  onCartRulesChange(data: OfferCartRulesDto): void;
  setConfigureComponent: (data: boolean) => void;
  configureComponent: boolean;
  ruleIndex: number;
  groupIndex: number;
  setRuleIndex: (data: number) => void;
  setGroupIndex: (data: number) => void;
  rulesSaved: OfferCartRulesDto;
  isChanged: boolean;
  setIsChanged: (data: boolean) => void;
  noValueRule?: boolean;
};
// product amount is connected with bogo offer type(there are bundles like 1+1 or 2+1)

export const CartRules: React.FC<CartRulesProps> = (props) => {
  const {
    savedData,
    specialOfferType,
    productAmount,
    noValueRule,
    onFormValidityChange,
    onCartRulesChange,
    setConfigureComponent,
    configureComponent,
    ruleIndex,
    groupIndex,
    setRuleIndex,
    setGroupIndex,
    rulesSaved,
    isChanged,
    setIsChanged,
  } = props;
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();
  const isDebugOrLocal = useIsDebugOrLocal();

  const [rules, setRules] = useState<OfferCartRulesDto>(rulesSaved);

  const totalRulesCount = useMemo(() => {
    return (rules.groups || []).reduce((sum, group) => {
      return sum + (group.rules?.length || 0);
    }, 0);
  }, [rules.groups]);

  const currentRule = useMemo(
    () =>
      typeof ruleIndex === 'number' &&
      typeof groupIndex === 'number' &&
      rules?.groups?.length
        ? rules?.groups?.[groupIndex]?.rules?.[ruleIndex]
        : null,
    [rules, groupIndex, ruleIndex]
  );

  const isSxGxChecked = useMemo(
    () => rules.applySameSetAsTargetProducts || null,
    [rules.applySameSetAsTargetProducts]
  );

  const [rulesValidity, setRulesValidity] = useState<RuleValidityProps[]>([]);

  const isAllRulesValid = useMemo(
    () =>
      rulesValidity?.every(
        (ruleValidity: RuleValidityProps) => ruleValidity.isValid
      ) || !rulesValidity.length,
    [rulesValidity]
  );

  const title = useMemo(
    () =>
      savedData?.groups?.[groupIndex]?.rules?.[ruleIndex]?.id ===
      rules?.groups?.[groupIndex]?.rules?.[ruleIndex]?.id
        ? i18n.translate('EditTitle')
        : i18n.translate('ConfigTitle'),
    [savedData, rules, groupIndex, ruleIndex, i18n]
  );

  const oneRuleIsRequired = useMemo(
    () =>
      specialOfferType &&
      [
        OfferTypeSpecialCases.BogoMixMatch,
        OfferTypeSpecialCases.BogoStrictMatch,
      ].includes(specialOfferType),
    [specialOfferType]
  );

  const oneOrNoRule = useMemo(
    () => specialOfferType === OfferTypeSpecialCases.SpendXGetX,
    [specialOfferType]
  );

  const handleRuleValidityChange = useCallback(
    (id: string, isValid: boolean) => {
      const rulesValidityCopy = cloneDeep(rulesValidity);
      const currentSectionIndex = rulesValidityCopy?.findIndex(
        (el: RuleValidityProps) => el.id === id
      );
      if (currentSectionIndex > -1) {
        rulesValidityCopy[currentSectionIndex].isValid = isValid;
      } else {
        rulesValidityCopy.push({
          id,
          isValid,
        });
      }
      setRulesValidity(rulesValidityCopy);
    },
    [rulesValidity, setRulesValidity]
  );

  const handleIsSxGxCheckedChange = useCallback(
    (newChecked: boolean) => {
      setRules((prev) => ({
        ...prev,
        applySameSetAsTargetProducts: newChecked,
      }));
      setIsChanged(true);
    },
    [setIsChanged, setRules]
  );

  const getDefaultValue = useCallback(
    (option: OfferRuleTypeDtoEnum, id: string) => {
      switch (option) {
        case OfferRuleTypeDtoEnum.MINIMUM_ITEMS_QUANTITY:
          return { ...grayBoxInitialValues, type: option, id };
        case OfferRuleTypeDtoEnum.MINIMUM_PURCHASE_AMOUNT:
          return {
            ...grayBoxInitialValues,
            value: {
              type: OfferRuleValueTypeDtoEnum.AT_LEAST,
              from: 10,
              to: null,
              exactly: 1,
            },
            appliesTo: {
              ...grayBoxInitialValues.appliesTo,
              type: OfferTargetTypeDtoEnum.ALL,
            },
            applyAfterDiscounts: true,
            id,
          };
        default:
          return {};
      }
    },
    []
  );

  const handleSetSelectedOption = useCallback(
    (checked: boolean, value: OfferRuleTypeDtoEnum) => {
      setRulesValidity([]);

      const currentSavedVersionType =
        savedData?.groups?.[groupIndex]?.rules?.[ruleIndex]?.type;

      const currentSavedVersionId =
        savedData?.groups?.[groupIndex]?.rules?.[ruleIndex]?.id;

      setRules((prev) => ({
        ...prev,
        groups: (prev.groups || []).map((group, indexGr) => {
          if (indexGr === groupIndex) {
            return {
              ...group,
              rules: group?.rules?.map((rule, indexR) => {
                if (indexR === ruleIndex) {
                  return currentSavedVersionType === value &&
                    currentSavedVersionId === rule.id
                    ? savedData?.groups?.[groupIndex]?.rules?.[ruleIndex]
                    : getDefaultValue(value, rule.id || '');
                }
                return rule;
              }) as OfferCartRuleDto[],
            };
          }
          return group;
        }) as OfferCartRuleGroupDto[],
      }));
      setIsChanged(true);
    },
    [
      savedData,
      groupIndex,
      ruleIndex,
      setRules,
      getDefaultValue,
      setIsChanged,
      setRulesValidity,
    ]
  );

  const handleUpdateGrayBoxValue = useCallback(
    (data: OfferCartRuleDto, idToReplace?: string) => {
      const ruleNeeded = rules?.groups?.[groupIndex].rules?.find(
        (rule: OfferCartRuleDto) => rule.id === idToReplace
      );
      if (ruleNeeded && data && !isEqual(ruleNeeded, data)) {
        setRules((prev) => ({
          ...prev,
          groups: (prev.groups || []).map((group, index) => {
            if (index === groupIndex) {
              return {
                ...group,
                rules: group.rules?.map((rule) =>
                  rule.id === idToReplace ? data : rule
                ),
              };
            }
            return group;
          }),
        }));
        setIsChanged(true);
      }
    },
    [rules, groupIndex, setRules, setIsChanged]
  );

  const handleDuplicateRule = useCallback(
    (groupIndex: number, ruleIdToDuplicate?: string) => {
      ruleIdToDuplicate &&
        setRules((prev) => {
          const updatedGroups = (prev.groups || []).map((group, index) => {
            if (index === groupIndex) {
              // Step 1: Find the rule to duplicate within the group
              const ruleIndex = group.rules?.findIndex(
                (rule) => rule.id === ruleIdToDuplicate
              );
              if (ruleIndex === undefined || ruleIndex === -1 || !group.rules) {
                return group; // If rule not found, return the group as is
              }

              // Step 2: Create a copy of the rule with a new unique ID
              const ruleToDuplicate = group.rules[ruleIndex];
              const duplicatedRule = {
                ...ruleToDuplicate,
                id: uuidv4(),
              };

              // Step 3: Insert the duplicated rule immediately after the original rule
              const updatedRules = [
                ...group.rules.slice(0, ruleIndex + 1),
                duplicatedRule,
                ...group.rules.slice(ruleIndex + 1),
              ];

              return {
                ...group,
                rules: updatedRules,
              };
            }
            return group;
          });

          return {
            ...prev,
            groups: updatedGroups,
          };
        });
      dispatch(
        setToastMessages({
          error: false,
          message: 'DuplicateRule',
        })
      );
      setIsChanged(true);
    },
    [setRules, setIsChanged]
  );

  const handleRemoveRule = useCallback(
    (groupIndex: number, idToReplace?: string) => {
      // Step 1: Update rulesValidity by removing the rule with idToReplace
      const rulesValidityCopy = cloneDeep(rulesValidity).filter(
        (ruleValidity: RuleValidityProps) => ruleValidity?.id !== idToReplace
      );
      setRulesValidity(rulesValidityCopy);

      if (idToReplace) {
        setRules((prev) => {
          // Step 2: Remove the rule from the group and remove empty groups
          const updatedGroups = (prev.groups || []).reduce<
            OfferCartRuleGroupDto[]
          >((acc, group, index) => {
            if (index === groupIndex) {
              const updatedRules = group.rules?.filter(
                (rule) => rule.id !== idToReplace
              );

              if (updatedRules?.length) {
                // Group is not empty, update the group
                acc.push({ ...group, rules: updatedRules });
              }
            } else {
              acc.push(group);
            }
            return acc;
          }, []);

          // Step 3: Reorder groups by updating the 'order' property
          const reorderedGroups = updatedGroups.map((group, index) => ({
            ...group,
            order: index + 1,
          }));

          // Step 4: Update connectors by removing the connector for the removed group
          const updatedConnectors =
            reorderedGroups.length < (prev.groups?.length || 0)
              ? prev.connectors?.slice(0, reorderedGroups.length - 1) || []
              : prev.connectors || [];

          return {
            ...prev,
            groups: reorderedGroups,
            connectors: updatedConnectors,
          };
        });
      }
      dispatch(
        setToastMessages({
          error: false,
          message: 'DeleteRule',
        })
      );
      setIsChanged(true);
    },
    [setRules, setIsChanged, setRulesValidity, rulesValidity]
  );

  const handleAddGroup = useCallback(
    async (connector?: OfferCartRuleGroupConnectorDtoEnum | null) => {
      !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
      const newRules = {
        ...rules,
        connectors: connector
          ? [...(rules.connectors || []), connector]
          : rules.connectors || [],
        groups: [
          ...(rules.groups || []),
          {
            order: (rules.groups?.length || 0) + 1,
            rules: [
              {
                ...grayBoxInitialValues,
                ...(specialOfferType === OfferTypeSpecialCases.CrossSell && {
                  type: OfferRuleTypeDtoEnum.MINIMUM_ITEMS_QUANTITY,
                }),
                ...(specialOfferType &&
                  [
                    OfferTypeSpecialCases.SpendXGetX,
                    OfferTypeSpecialCases.CrossSell,
                  ].includes(specialOfferType) && {
                    value: undefined,
                  }),
                id: uuidv4(),
              },
            ],
          },
        ],
      };
      onCartRulesChange(newRules);
      setGroupIndex(rules.groups?.length ? rules.groups?.length : 0);
      setRuleIndex(0);
      setConfigureComponent(true);
    },
    [
      rules,
      isDebugOrLocal,
      specialOfferType,
      setConfigureComponent,
      setGroupIndex,
      setRuleIndex,
      onCartRulesChange,
    ]
  );

  const handleAddRule = useCallback(
    async (groupIndex: number) => {
      !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
      const updatedGroups = (rules.groups || []).map((group, index) => {
        if (index === groupIndex) {
          // Step 1: Add the new rule to the group's rules array
          const updatedRules = [
            ...(group.rules || []),
            {
              ...grayBoxInitialValues,
              ...(specialOfferType === OfferTypeSpecialCases.CrossSell && {
                type: OfferRuleTypeDtoEnum.MINIMUM_ITEMS_QUANTITY,
              }),
              ...(specialOfferType &&
                [
                  OfferTypeSpecialCases.SpendXGetX,
                  OfferTypeSpecialCases.CrossSell,
                ].includes(specialOfferType) && {
                  value: undefined,
                }),
              id: uuidv4(),
            },
          ];

          return {
            ...group,
            rules: updatedRules,
          };
        }
        return group;
      });

      const newRules = {
        ...rules,
        groups: updatedGroups,
      };
      onCartRulesChange(newRules);
      setGroupIndex(groupIndex);
      setRuleIndex((updatedGroups[groupIndex].rules?.length || 0) - 1);
      setConfigureComponent(true);
    },
    [
      rules,
      isDebugOrLocal,
      specialOfferType,
      setConfigureComponent,
      setGroupIndex,
      setRuleIndex,
      onCartRulesChange,
    ]
  );

  const handleUpdateConnector = useCallback(
    (
      connectorIndex: number,
      newConnectorValue: OfferCartRuleGroupConnectorDtoEnum
    ) => {
      setRules((prev) => {
        // Step 1: Ensure that the connectors array exists
        const updatedConnectors = [...(prev.connectors || [])];

        // Step 2: Update the connector at the specified index
        if (connectorIndex >= 0 && connectorIndex < updatedConnectors.length) {
          updatedConnectors[connectorIndex] = newConnectorValue;
        }

        return {
          ...prev,
          connectors: updatedConnectors,
        };
      });

      setIsChanged(true);
    },
    [setRules, setIsChanged]
  );

  const grayBoxes = useMemo(() => {
    if (currentRule) {
      switch (specialOfferType) {
        case OfferTypeSpecialCases.BogoMixMatch:
        case OfferTypeSpecialCases.BogoStrictMatch:
          return (
            <GrayBox
              title={i18n.translate('CartRules.Products')}
              data={currentRule}
              updateValue={(data) =>
                handleUpdateGrayBoxValue(data, currentRule.id)
              }
              specialOfferType={specialOfferType}
              productAmount={productAmount}
              handleRuleValidityChange={handleRuleValidityChange}
              chidren={
                <CartRulesRadios
                  handleSetSelectedOption={handleSetSelectedOption}
                  selectedOption={currentRule?.type}
                  specialOfferType={specialOfferType}
                />
              }
            />
          );
        case OfferTypeSpecialCases.SpendXGetX:
          return (
            <GrayBox
              title={i18n.translate('CartRules.Products')}
              data={currentRule}
              updateValue={(data) =>
                handleUpdateGrayBoxValue(data, currentRule.id)
              }
              specialOfferType={specialOfferType}
              productAmount={productAmount}
              handleRuleValidityChange={handleRuleValidityChange}
              chidren={
                <CartRulesRadios
                  handleSetSelectedOption={handleSetSelectedOption}
                  selectedOption={currentRule?.type}
                  specialOfferType={specialOfferType}
                />
              }
            />
          );
        case OfferTypeSpecialCases.CrossSell:
          return (
            <GrayBox
              title={i18n.translate('CartRules.Products')}
              data={currentRule}
              updateValue={(data) =>
                handleUpdateGrayBoxValue(data, currentRule.id)
              }
              visitorsAlsoAdd={
                (rules?.groups?.[groupIndex]?.rules?.length || 0) >= 1
              }
              specialOfferType={specialOfferType}
              productAmount={productAmount}
              handleRuleValidityChange={handleRuleValidityChange}
              chidren={
                <CartRulesRadios
                  handleSetSelectedOption={handleSetSelectedOption}
                  selectedOption={currentRule?.type}
                  specialOfferType={specialOfferType}
                />
              }
            />
          );
        default:
          if (
            currentRule?.type === OfferRuleTypeDtoEnum.MINIMUM_PURCHASE_AMOUNT
          ) {
            return (
              <GrayBox
                title={i18n.translate('CartRules.Products')}
                data={currentRule}
                updateValue={(data) =>
                  handleUpdateGrayBoxValue(data, currentRule.id)
                }
                isMoney={true}
                handleRuleValidityChange={handleRuleValidityChange}
                chidren={
                  <CartRulesRadios
                    handleSetSelectedOption={handleSetSelectedOption}
                    selectedOption={currentRule?.type}
                    specialOfferType={specialOfferType}
                  />
                }
              />
            );
          } else if (
            currentRule?.type === OfferRuleTypeDtoEnum.MINIMUM_ITEMS_QUANTITY
          ) {
            return (
              <GrayBox
                title={i18n.translate('CartRules.Products')}
                data={currentRule}
                visitorsAlsoAdd={
                  (rules?.groups?.[groupIndex]?.rules?.length || 0) >= 1
                }
                updateValue={(data) =>
                  handleUpdateGrayBoxValue(data, currentRule.id)
                }
                handleRuleValidityChange={handleRuleValidityChange}
                chidren={
                  <CartRulesRadios
                    handleSetSelectedOption={handleSetSelectedOption}
                    selectedOption={currentRule?.type}
                    specialOfferType={specialOfferType}
                  />
                }
              />
            );
          }
      }
    }
  }, [
    i18n,
    currentRule,
    currentRule?.type,
    rules,
    groupIndex,
    specialOfferType,
    productAmount,
    handleSetSelectedOption,
    handleRuleValidityChange,
    handleUpdateGrayBoxValue,
  ]);

  useEffect(() => {
    if (isChanged) {
      onCartRulesChange(rules);
      setIsChanged(false);
    }
  }, [isChanged]);

  useEffect(() => {
    onFormValidityChange(isAllRulesValid);
  }, [isAllRulesValid]);

  useEffect(() => {
    setRules(rulesSaved);
  }, [rulesSaved]);

  useEffect(() => {
    configureComponent && dispatch(setCurrentConfigPage('cartRules'));
    return () => {
      dispatch(setCurrentConfigPage(null));
    };
  }, [configureComponent]);

  return (
    <div className='CartRules'>
      {!configureComponent ? (
        <Card roundedAbove='sm' padding='400'>
          <BlockStack gap='400'>
            <BlockStack gap='100'>
              <Text as='h2' variant='headingSm'>
                {i18n.translate('CartRules.Title')}
              </Text>
              <Text as='p' tone='subdued'>
                {i18n.translate('CartRules.Subtitle')}
              </Text>
            </BlockStack>
            {specialOfferType === OfferTypeSpecialCases.SpendXGetX && (
              <Checkbox
                label={
                  <InlineStack gap='100' blockAlign='center'>
                    <Text as='p'>
                      {i18n.translate('CartRules.SpendXGetXCheckboxLabel')}
                    </Text>
                    <Tooltip preferredPosition='mostSpace' content='no content'>
                      <Icon source={QuestionCircleIcon} />
                    </Tooltip>
                  </InlineStack>
                }
                checked={!!isSxGxChecked}
                onChange={handleIsSxGxCheckedChange}
              />
            )}
            {totalRulesCount >= 10 && (
              <Banner tone='warning'>{i18n.translate('Banner')}</Banner>
            )}
            {!isSxGxChecked &&
              (!rules.groups?.length ? (
                <Card
                  background='bg-surface-secondary'
                  padding='600'
                  roundedAbove='xs'
                >
                  <BlockStack gap='300' inlineAlign='center'>
                    <Text as='p' tone='subdued'>
                      {i18n.translate('CartRules.NoCartRules')}
                    </Text>
                    <Button
                      onClick={() => handleAddGroup(null)}
                      disabled={totalRulesCount >= 10}
                    >
                      {i18n.translate('CartRules.AddRule')}
                    </Button>
                  </BlockStack>
                </Card>
              ) : (
                <>
                  {rules.groups.map((group, groupIndex) => (
                    <RuleSummary
                      i18n={i18n}
                      key={groupIndex}
                      disableBtn={
                        !!(
                          totalRulesCount >= 10 ||
                          oneRuleIsRequired ||
                          oneOrNoRule
                        )
                      }
                      handleAddRule={() => handleAddRule(groupIndex)}
                      actionList={[
                        OfferCartRuleGroupConnectorDtoEnum.AND,
                        OfferCartRuleGroupConnectorDtoEnum.BUT_NOT,
                      ].map((connector) => ({
                        content: i18n.translate(`CartRules.${connector}`),
                        active:
                          rules?.connectors?.[groupIndex - 1] === connector,
                        onAction: () =>
                          handleUpdateConnector(groupIndex - 1, connector),
                      }))}
                      connector={
                        groupIndex
                          ? rules?.connectors?.[groupIndex - 1]
                          : undefined
                      }
                    >
                      {group.rules?.map((rule, ruleIndex) => (
                        <RuleInfo
                          key={rule.id}
                          rule={rule}
                          ruleIndex={ruleIndex}
                          productAmount={productAmount}
                          noValue={noValueRule}
                          onRuleClick={async () => {
                            !isDebugOrLocal &&
                              (await shopify.saveBar.leaveConfirmation());
                            setGroupIndex(groupIndex);
                            setRuleIndex(ruleIndex);
                            setConfigureComponent(true);
                          }}
                          i18n={i18n}
                          actionList={[
                            {
                              content: i18n.translate('Edit'),
                              onAction: async () => {
                                !isDebugOrLocal &&
                                  (await shopify.saveBar.leaveConfirmation());
                                setGroupIndex(groupIndex);
                                setRuleIndex(ruleIndex);
                                setConfigureComponent(true);
                              },
                              icon: EditIcon,
                            },
                            {
                              content: i18n.translate('Duplicate'),
                              onAction: () =>
                                handleDuplicateRule(groupIndex, rule.id),
                              icon: DuplicateIcon,
                              disabled:
                                totalRulesCount >= 10 ||
                                oneRuleIsRequired ||
                                oneOrNoRule,
                            },
                            {
                              content: i18n.translate('Delete'),
                              destructive: true,
                              icon: DeleteIcon,
                              disabled: oneRuleIsRequired,
                              onAction: () =>
                                handleRemoveRule(groupIndex, rule.id),
                            },
                          ]}
                        />
                      ))}
                    </RuleSummary>
                  ))}
                  <ActionListInPopover
                    width='96px'
                    disable={
                      totalRulesCount >= 10 || oneRuleIsRequired || oneOrNoRule
                    }
                    btnContent={i18n.translate('CartRules.AddRule')}
                    actionList={[
                      {
                        content: i18n.translate(
                          `CartRules.${OfferCartRuleGroupConnectorDtoEnum.AND}`
                        ),
                        onAction: () =>
                          handleAddGroup(
                            OfferCartRuleGroupConnectorDtoEnum.AND
                          ),
                      },
                      {
                        content: i18n.translate(
                          `CartRules.${OfferCartRuleGroupConnectorDtoEnum.BUT_NOT}`
                        ),
                        onAction: () =>
                          handleAddGroup(
                            OfferCartRuleGroupConnectorDtoEnum.BUT_NOT
                          ),
                      },
                    ]}
                  />
                </>
              ))}
          </BlockStack>
        </Card>
      ) : currentRule ? (
        <Page
          backAction={{
            onAction: async () => {
              !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
              setConfigureComponent(false);
            },
          }}
          title={title}
        >
          {grayBoxes}
        </Page>
      ) : (
        <Loader fullWidth size='large' />
      )}
    </div>
  );
};
