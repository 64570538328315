import { useI18n } from '@shopify/react-i18n';
import React, { useMemo } from 'react';
import './AutoSaveInfoTextIndicator.scss';

type AutoSaveInfoTextIndicatorProps = {
  validationError?: boolean;
  isLoading?: boolean;
};

export const AutoSaveInfoTextIndicator: React.FC<
  AutoSaveInfoTextIndicatorProps
> = (props) => {
  const { validationError, isLoading } = props;
  const [i18n] = useI18n();

  const savingChangesInfoText = useMemo(() => {
    if (validationError && !isLoading) {
      return (
        <p className='TopBarWarningText'>{i18n.translate('WarningText')}</p>
      );
    } else if (isLoading) {
      return <p className='TopBarInfoText'>{i18n.translate('IsSaving')}</p>;
    } else {
      return null;
    }
  }, [validationError, i18n, isLoading]);

  return savingChangesInfoText;
};
