import React, { useCallback, useEffect, useMemo, useState } from 'react';
import StyleComponentHeader from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components/StyleComponentHeader/StyleComponentHeader';
import { useI18n } from '@shopify/react-i18n';
import './SimplifiedTextEntries.scss';
import {
  LocalizedStringDto,
  MessageSetupDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { Button, Collapsible, TextField, Text } from '@shopify/polaris';
import { ChevronUpIcon, ChevronDownIcon } from '@shopify/polaris-icons';
import { PreviewEntry } from '../TextEntries/TextEntries';
import classNames from 'classnames';
type SimplifiedTextEntriesProps = {
  message: MessageSetupDto;
  title: string;
  messageLabel?: string;
  disabled?: boolean;
  defaultLanguage: string;
  hideHeader?: boolean;
  inherited?: boolean;
  setPreviewEntry?: (data: PreviewEntry) => void;
  setMessage: (value: MessageSetupDto) => void;
  onSystemReset: () => void;
};
export const SimplifiedTextEntries: React.FC<SimplifiedTextEntriesProps> = ({
  message,
  title,
  hideHeader,
  messageLabel,
  disabled,
  defaultLanguage,
  inherited,
  setPreviewEntry,
  setMessage,
  onSystemReset,
}) => {
  const [i18n] = useI18n();
  const [isTranslationsOpen, setIsTranslationsOpen] = useState<boolean>(false);

  const previewEntry = useMemo(() => {
    const defaultText = message.entries?.[0].text?.find(
      (text) => text.language === defaultLanguage
    )?.text;
    const preview = {
      mobile: defaultText,
      desktop: defaultText,
    };
    return preview;
  }, [message]);

  const handleUpdateEntry = useCallback(
    (value: string, language: string) => {
      const updatedText = message.entries?.[0]?.text?.map((textItem) => {
        if (textItem.language === language) {
          return {
            ...textItem,
            text: value,
          };
        }
        return textItem;
      });
      setMessage({
        ...message,
        entries: [{ ...message.entries?.[0], text: updatedText }],
      });
    },
    [message]
  );

  const getTextEntry = useCallback(
    (entry: LocalizedStringDto) => {
      return (
        <div key={entry?.language || 'en'} className='EntryRow'>
          {entry?.language === defaultLanguage ? (
            messageLabel ? (
              messageLabel
            ) : (
              <Text as='p'>
                {i18n.translate(`${entry?.language}`)}
                <Text tone='subdued' as='span'>
                  {i18n.translate(`Default`)}
                </Text>
              </Text>
            )
          ) : (
            i18n.translate(`${entry?.language}`)
          )}
          <div className='RightContent'>
            <TextField
              onChange={(value) =>
                handleUpdateEntry(value, entry?.language || '')
              }
              value={entry?.text}
              autoComplete=''
              label=''
              disabled={disabled}
            />
          </div>
        </div>
      );
    },
    [i18n, defaultLanguage, disabled, handleUpdateEntry]
  );

  useEffect(() => {
    setPreviewEntry?.(previewEntry as PreviewEntry);
  }, [previewEntry]);

  return (
    <div
      className={classNames('SimplifiedTextEntries', {
        HideHeader: hideHeader,
      })}
    >
      {!hideHeader && (
        <StyleComponentHeader
          buttons={[
            {
              external: true,
              content: i18n.translate(`ResetToSystemDefault`),
              disabled: disabled,
              onAction: () => onSystemReset(),
            },
          ]}
          inherited={inherited}
          label={title}
        />
      )}

      <div className='SimplifiedTextEntriesContent'>
        <div>
          {getTextEntry(
            message.entries?.[0]?.text?.find(
              (entry) => entry.language === defaultLanguage
            ) as LocalizedStringDto
          )}
        </div>
        <div>
          <Button
            onClick={() => setIsTranslationsOpen(!isTranslationsOpen)}
            icon={isTranslationsOpen ? ChevronUpIcon : ChevronDownIcon}
            variant='plain'
          >
            {i18n.translate('Translations')}
          </Button>
        </div>
        <Collapsible
          open={isTranslationsOpen}
          id='basic-collapsible'
          transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
          expandOnPrint
        >
          <div className='EntriesList'>
            {message.entries?.[0]?.text
              ?.filter((entry) => entry.language !== defaultLanguage)
              ?.map((entry) => getTextEntry(entry))}
          </div>
        </Collapsible>
      </div>
    </div>
  );
};
