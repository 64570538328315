import React, { useCallback, useMemo } from 'react';
import {
  GetSystemDefaultsResponseDto,
  PresetsSetupDto,
  PromotionSetupDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import PromotionProductBanner from './components/PromotionProductBanner/PromotionProductBanner';
import { SelectedOptionPath } from '../../RightSideBarWrapper';
import {
  LeftMenuWidget,
  isLeftMenuOptionSelected,
} from 'features/settings/components/EditPresetSkeleton/utils/leftMenuGenerators';
import { WidgetTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import PromotionPromotionalBadge from './components/PromotionPromotionalBadge/PromotionPromotionalBadge';
import PromotionAnnouncementBar from './components/PromotionAnnouncementBar/PromotionAnnouncementBar';
import PromotionNotification from './components/PromotionNotification/PromotionNotification';
import PromotionPromotionCode from './components/PromotionPromotionCode/PromotionPromotionCode';
import PromotionPromotionSummary from './components/PromotionPromotionSummary/PromotionPromotionSummary';
import PromotionOfferRulePopup from './components/PromotionOfferRulePopup/PromotionOfferRulePopup';
import { Box, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import PromotionPrice from './components/PromotionPrice/PromotionPrice';
import { WidgetListTypeDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import PromotionCartText from './components/PromotionCartText/PromotionCartText';
import PromotionDiscountLabel from './components/PromotionDiscountLabel/PromotionDiscountLabel';
import { WidgetOptionHeader } from '../components';

type EditPromotionRightBarProps = {
  currentWidget?: LeftMenuWidget;
  editPromotionState: PromotionSetupDto;
  defaultPromotionData: PromotionSetupDto;
  selectedOptionPath: SelectedOptionPath;
  shopDefaultData: GetSystemDefaultsResponseDto;
  smallScreen?: boolean;
  setEditPromotionState: (data: PromotionSetupDto) => void;
  filterSupportedWidgets: (value: WidgetTypeDtoEnum) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

export enum PromotionPriceWidgetTypesDtoEnum {
  PRODUCT_PAGE_PRICE = 'productPagePrice',
  COLLECTION_PAGE_PRICE = 'collectionPagePrice',
}

const EditPromotionRightBar: React.FC<EditPromotionRightBarProps> = ({
  currentWidget,
  editPromotionState,
  defaultPromotionData,
  selectedOptionPath,
  shopDefaultData,
  smallScreen,
  setEditPromotionState,
  filterSupportedWidgets,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();

  const updateEditPromotionState = useCallback(
    (
      field: keyof PromotionSetupDto,
      data: PromotionSetupDto[keyof PromotionSetupDto],
      presetSetup?: PresetsSetupDto
    ) =>
      setEditPromotionState({
        ...editPromotionState,
        [field]: data,
        ...(presetSetup && { presetSetup }),
      }),
    [setEditPromotionState, editPromotionState]
  );

  const rightSideOptions = useMemo(() => {
    const widgetName =
      isLeftMenuOptionSelected([currentWidget as LeftMenuWidget]) &&
      currentWidget?.name;
    const commonProps = {
      editPromotionState,
      defaultPromotionData,
      currentWidget,
      updateEditPromotionState,
      selectedOptionPath,
      handleLeftMenuSelection,
      filterSupportedWidgets,
    };
    const renderPriceWidget = (type: PromotionPriceWidgetTypesDtoEnum) => {
      return (
        <PromotionPrice
          {...commonProps}
          priceType={type}
          shopDefaultData={shopDefaultData[type] || {}}
        />
      );
    };
    switch (true) {
      case widgetName === WidgetListTypeDtoEnum.PRODUCT_BANNER:
        return (
          <PromotionProductBanner
            {...commonProps}
            shopDefaultData={shopDefaultData.productBanner || {}}
          />
        );
      case widgetName === WidgetListTypeDtoEnum.PROMOTIONAL_BADGE:
        return (
          <PromotionPromotionalBadge
            {...commonProps}
            shopDefaultData={shopDefaultData.promotionalBadge || {}}
          />
        );
      case widgetName === WidgetListTypeDtoEnum.NOTIFICATION:
        return (
          <PromotionNotification
            {...commonProps}
            shopDefaultData={shopDefaultData.notification || {}}
          />
        );
      case widgetName === WidgetListTypeDtoEnum.ANNOUNCEMENT_BAR:
        return (
          <PromotionAnnouncementBar
            {...commonProps}
            shopDefaultData={shopDefaultData.announcementBar || {}}
          />
        );
      case widgetName ===
        WidgetListTypeDtoEnum.DRAWER_CART_PROMOTION_CODE_FIELD:
        return (
          <PromotionPromotionCode
            {...commonProps}
            type={WidgetListTypeDtoEnum.DRAWER_CART_PROMOTION_CODE_FIELD}
            shopDefaultData={shopDefaultData.drawerCartPromotionCodeField || {}}
          />
        );
      case widgetName === WidgetListTypeDtoEnum.CART_PROMOTION_CODE_FIELD:
        return (
          <PromotionPromotionCode
            {...commonProps}
            type={WidgetListTypeDtoEnum.CART_PROMOTION_CODE_FIELD}
            shopDefaultData={shopDefaultData.cartPromotionCodeField || {}}
          />
        );
      case widgetName === WidgetListTypeDtoEnum.CART_PROMOTION_SUMMARY:
        return (
          <PromotionPromotionSummary
            {...commonProps}
            type={WidgetListTypeDtoEnum.CART_PROMOTION_SUMMARY}
            shopDefaultData={shopDefaultData.cartPromotionSummary || {}}
          />
        );
      case widgetName === WidgetListTypeDtoEnum.DRAWER_CART_PROMOTION_SUMMARY:
        return (
          <PromotionPromotionSummary
            {...commonProps}
            type={WidgetListTypeDtoEnum.DRAWER_CART_PROMOTION_SUMMARY}
            shopDefaultData={shopDefaultData.drawerCartPromotionSummary || {}}
          />
        );
      case widgetName === WidgetListTypeDtoEnum.OFFER_RULE_POPUP:
        return (
          <PromotionOfferRulePopup
            {...commonProps}
            shopDefaultData={shopDefaultData.offerRulePopup || {}}
          />
        );
      case widgetName === WidgetListTypeDtoEnum.DISCOUNT_LABEL:
        return (
          <PromotionDiscountLabel
            {...commonProps}
            shopDefaultData={shopDefaultData.discountLabel || {}}
          />
        );

      case widgetName === WidgetListTypeDtoEnum.CART_PAGE_CART_TEXT:
        return (
          <PromotionCartText
            {...commonProps}
            type={WidgetListTypeDtoEnum.CART_PAGE_CART_TEXT}
            shopDefaultData={shopDefaultData.cartPageCartText || {}}
          />
        );
      case widgetName === WidgetListTypeDtoEnum.DRAWER_CART_PAGE_CART_TEXT:
        return (
          <PromotionCartText
            {...commonProps}
            type={WidgetListTypeDtoEnum.DRAWER_CART_PAGE_CART_TEXT}
            shopDefaultData={shopDefaultData.drawerCartPageCartText || {}}
          />
        );

      case widgetName &&
        widgetName ===
          Object.values(PromotionPriceWidgetTypesDtoEnum).find(
            (widget) => widget === currentWidget?.name
          ):
        return renderPriceWidget(
          currentWidget?.name as PromotionPriceWidgetTypesDtoEnum
        );
      default:
        return (
          <>
            {smallScreen && (
              <WidgetOptionHeader
                name={i18n.translate('Back')}
                handleLeftMenuSelection={handleLeftMenuSelection}
              />
            )}
            <Box
              borderColor='border-brand'
              borderBlockEndWidth='025'
              padding='500'
            >
              <Text tone='subdued' as='p'>
                {i18n.translate('PromotionEmptyState')}
              </Text>
            </Box>
          </>
        );
    }
  }, [
    currentWidget,
    editPromotionState,
    defaultPromotionData,
    shopDefaultData,
    selectedOptionPath,
    updateEditPromotionState,
    handleLeftMenuSelection,
    filterSupportedWidgets,
  ]);
  return <>{rightSideOptions}</>;
};

export default EditPromotionRightBar;
