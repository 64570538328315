import {
  Avatar,
  Checkbox,
  SkeletonThumbnail,
  LegacyStack,
  Text,
} from '@shopify/polaris';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './ResourceListItem.module.scss';
import { ProductVariant } from 'core/api/appBridge';

type CheckedVariant = boolean | 'indeterminate';

export type Checked = {
  id: string;
  checked: boolean;
};

export type CheckedResourceListItem = {
  parent: Checked;
  variants?: Checked[];
};

type ResourceListItemProps = {
  resourceType: string;
  title?: string;
  id?: string;
  source?: string;
  showVariants?: boolean;
  variants?: Partial<ProductVariant>[];
  initialChecked?: boolean;
  initialCheckedVariants?: string[];
  onChecked?: (checked: CheckedResourceListItem) => void;
};

export const ResourceListItem: React.FC<ResourceListItemProps> = (props) => {
  const {
    resourceType,
    title,
    source,
    id,
    showVariants,
    variants,
    initialChecked,
    initialCheckedVariants,
    onChecked,
  } = props;
  const [checked, setChecked] = useState<CheckedVariant>(
    initialChecked || false
  );
  const [checkedVariants, setCheckedVariants] = useState<
    (string | undefined)[]
  >(initialCheckedVariants || []);

  const isProductWithVariants = useMemo(
    () => resourceType === 'product' && showVariants,
    [resourceType, showVariants]
  );

  const parsedVariants = useMemo(
    () =>
      variants?.map((variant) => ({
        ...variant,
        id: variant.id,
      })),
    [variants]
  );

  const toggleCheck = useCallback(() => {
    setChecked((prev) => {
      if (!checkedVariants.length) {
        setCheckedVariants(parsedVariants?.map((variant) => variant.id) || []);
        return !prev;
      } else {
        setCheckedVariants([]);
        return false;
      }
    });
  }, [checkedVariants, parsedVariants, setChecked, setCheckedVariants]);

  const toggleVariantCheck = useCallback(
    (variantId: string) => {
      setCheckedVariants((prev) =>
        prev.find((el) => el === variantId)
          ? prev.filter((el) => el !== variantId)
          : [...prev, variantId]
      );
    },
    [setCheckedVariants]
  );

  const isVariantChecked = useCallback(
    (id: string) => checkedVariants.includes(id) || false,
    [checkedVariants]
  );

  useEffect(() => {
    onChecked?.({
      parent: {
        id: id!,
        checked: !!checked,
      },
      variants: parsedVariants?.map((variant) => ({
        id: variant.id!,
        checked: checked && isVariantChecked(variant.id!),
      })),
    });
  }, [
    checked,
    checkedVariants,
    id,
    parsedVariants,
    onChecked,
    isVariantChecked,
  ]);

  useEffect(() => {
    if (!isProductWithVariants) {
      return;
    }

    if (
      parsedVariants?.length !== checkedVariants.length &&
      checkedVariants.length > 0
    ) {
      setChecked('indeterminate');
      return;
    }

    if (parsedVariants?.length === checkedVariants.length) {
      setChecked(true);
      return;
    }

    if (!checkedVariants.length) {
      setChecked(false);
      return;
    }
  }, [isProductWithVariants, parsedVariants, checkedVariants]);

  return (
    <div className={styles.ResourceListItem}>
      <div className={styles.ResourceListItem__Parent} onClick={toggleCheck}>
        <LegacyStack alignment='center'>
          <LegacyStack.Item>
            <Checkbox label='' checked={checked} />
          </LegacyStack.Item>
          <LegacyStack.Item>
            {source ? (
              <Avatar size='md' source={source} />
            ) : (
              <SkeletonThumbnail size='small' />
            )}
          </LegacyStack.Item>
          <LegacyStack.Item>
            <Text as='span'>{title}</Text>
          </LegacyStack.Item>
        </LegacyStack>
      </div>
      {isProductWithVariants &&
        parsedVariants?.map((variant: Partial<ProductVariant>) => (
          <div
            className={styles.ResourceListItem__Variant}
            key={variant.id}
            onClick={() => toggleVariantCheck(variant.id!)}
          >
            <LegacyStack alignment='center'>
              <LegacyStack.Item>
                <Checkbox label='' checked={isVariantChecked(variant.id!)} />
              </LegacyStack.Item>
              <LegacyStack.Item>
                <Text as='span'>{variant.title}</Text>
              </LegacyStack.Item>
            </LegacyStack>
          </div>
        ))}
    </div>
  );
};
