import { debounce } from 'lodash';
import { useCallback } from 'react';

export const useDebounce = (delay?: number) => {
  const debounceFn = useCallback(
    debounce((callback: any) => {
      callback();
    }, delay || 600),
    []
  );
  return { debounce: debounceFn };
};
