import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PromotionOverviewDto,
  PromotionSettingsDto,
} from 'core/api/adminPromotions/adminPromotionsApi';

export interface SettingsFormValidity {
  sectionName: keyof PromotionSettingsDto;
  isValid: boolean;
}

export type PromotionsSliceType = {
  fetchPromotionOverview: boolean;
  promotionOverview: PromotionOverviewDto;
  formValidity: SettingsFormValidity[];
  markdownActionModalShow: boolean;
};

const initialState: PromotionsSliceType = {
  fetchPromotionOverview: false,
  promotionOverview: {},
  formValidity: [],
  markdownActionModalShow: true,
};

const promotionsSlice = createSlice({
  name: 'promotions',
  initialState: initialState,
  reducers: {
    setPromotionSettingsValidityState: (
      state,
      action: PayloadAction<PromotionsSliceType['formValidity']>
    ) => {
      state.formValidity = action.payload;
    },
    setFetchPromotionOverview: (
      state,
      action: PayloadAction<PromotionsSliceType['fetchPromotionOverview']>
    ) => {
      state.fetchPromotionOverview = action.payload;
    },
    setPromotionOverview: (
      state,
      action: PayloadAction<PromotionsSliceType['promotionOverview']>
    ) => {
      state.promotionOverview = action.payload;
    },
    setMarkdownActionModalShow: (
      state,
      action: PayloadAction<PromotionsSliceType['markdownActionModalShow']>
    ) => {
      state.markdownActionModalShow = action.payload;
    },
  },
});

export const {
  setFetchPromotionOverview,
  setPromotionOverview,
  setPromotionSettingsValidityState,
  setMarkdownActionModalShow,
} = promotionsSlice.actions;

export default promotionsSlice.reducer;
