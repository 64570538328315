import React, { useMemo } from 'react';
import { BlockStack, Card, ChoiceList, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { AnnouncementBarPagePositionDto } from 'core/api/adminSettings/adminSettingsApi';
import { AnnouncementBarPagePositionDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { Element } from 'react-scroll';

type PagePositionSettingProps = {
  pagePosition: AnnouncementBarPagePositionDto;
  setPagePosition: (value: AnnouncementBarPagePositionDtoEnum) => void;
};
export const PagePositionSetting: React.FC<PagePositionSettingProps> = ({
  pagePosition,
  setPagePosition,
}) => {
  const [i18n] = useI18n();
  const pagePositionOptions = useMemo(
    () =>
      Object.values(AnnouncementBarPagePositionDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    []
  );

  return (
    <Element className='PagePositionSetting' name='PagePositionSetting'>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('PagePosition')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('AdjustPage')}
            </Text>
          </BlockStack>
          <ChoiceList
            title=''
            choices={pagePositionOptions}
            selected={[
              pagePosition || AnnouncementBarPagePositionDtoEnum.ABOVE_HEADER,
            ]}
            onChange={(value) =>
              setPagePosition(value[0] as AnnouncementBarPagePositionDtoEnum)
            }
          />
        </BlockStack>
      </Card>
    </Element>
  );
};
