import React, { useCallback, useMemo, useState } from 'react';
import { useI18n } from '@shopify/react-i18n';
import {
  Button,
  ButtonGroup,
  InlineStack,
  Text,
  BlockStack,
  Card,
} from '@shopify/polaris';
import {
  GrayBoxResourceTypeEnum,
  ResourceSelectionProps,
  SearchFieldWithGrayBox,
} from 'core/components/SearchFieldWithGrayBoxOfferWizard';
import { OfferTargetTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import {
  OfferTargetTypeDto,
  ShopifyObjectDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { ResourcePickerOptions, ResourceSelection } from 'core/api/appBridge';

type AppliesToProps = {
  selectList: ShopifyObjectDto[];
  selectedProductsApplication: OfferTargetTypeDto;
  setSelectedProductsApplication: (
    selectedProductsApplication: OfferTargetTypeDto
  ) => void;
  onResourceSelection(
    data: ResourceSelection<ResourcePickerOptions['type']>[]
  ): void;
  onFormValidityChange(formIsInvalid: boolean): void;
  hideComponent?: boolean;
};

export const AppliesTo: React.FC<AppliesToProps> = (props) => {
  const {
    selectList,
    selectedProductsApplication,
    setSelectedProductsApplication,
    onResourceSelection,
    onFormValidityChange,
    hideComponent,
  } = props;
  const [showVariants, setShowVariants] = useState<boolean>(false);
  const [i18n] = useI18n();

  const handleOnSelectionChange = useCallback(
    (list: ResourceSelectionProps[]) => {
      onResourceSelection(list);
    },
    [onResourceSelection]
  );

  const onSearchFieldValidityChange = useCallback(
    (formIsInvalid: boolean) => {
      onFormValidityChange(formIsInvalid);
    },
    [onFormValidityChange]
  );

  const grayBoxResourceType = useMemo(() => {
    setShowVariants(false);
    switch (selectedProductsApplication) {
      case OfferTargetTypeDtoEnum.ALL:
        break;
      case OfferTargetTypeDtoEnum.COLLECTIONS:
        return GrayBoxResourceTypeEnum.Collection;
      case OfferTargetTypeDtoEnum.VARIANTS:
        setShowVariants(true);
        return GrayBoxResourceTypeEnum.Product;
      case OfferTargetTypeDtoEnum.PRODUCTS:
        return GrayBoxResourceTypeEnum.Product;
    }
  }, [selectedProductsApplication]);

  const grayBox = useMemo(
    () =>
      grayBoxResourceType && (
        <SearchFieldWithGrayBox
          resourceType={grayBoxResourceType as GrayBoxResourceTypeEnum}
          showVariants={showVariants}
          selectList={selectList as unknown as ResourceSelectionProps[]}
          onSelectedChange={handleOnSelectionChange}
          onFormValidityChange={onSearchFieldValidityChange}
          hideComponent={hideComponent}
        />
      ),
    [
      grayBoxResourceType,
      showVariants,
      handleOnSelectionChange,
      onSearchFieldValidityChange,
      hideComponent,
    ]
  );

  const onRadioButtonValueChange = useCallback(
    (data: OfferTargetTypeDtoEnum) => {
      setSelectedProductsApplication(data);
    },
    [setSelectedProductsApplication]
  );

  return (
    <>
      {!hideComponent ? (
        <Card>
          <BlockStack gap='400'>
            <Text as='h2' variant='headingSm'>
              {i18n.translate('Products')}
            </Text>
            <InlineStack blockAlign='center' gap='200'>
              <Text as='p'>
                {i18n.translate('RadioButtonsGroupTitle') || 'Applies to'}
              </Text>
              <ButtonGroup variant='segmented'>
                {Object.entries(OfferTargetTypeDtoEnum).map(
                  ([key, value]: [string, OfferTargetTypeDtoEnum]) => (
                    <Button
                      key={key}
                      pressed={selectedProductsApplication === value}
                      onClick={() => onRadioButtonValueChange(value)}
                    >
                      {i18n.translate(`${key}`) || value}
                    </Button>
                  )
                )}
              </ButtonGroup>
            </InlineStack>
            {grayBox}
          </BlockStack>
        </Card>
      ) : (
        <>{grayBox}</>
      )}
    </>
  );
};
