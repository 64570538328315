import React, { useCallback, useEffect, useMemo } from 'react';
import { useI18n } from '@shopify/react-i18n';
import {
  Text,
  RadioButton,
  Icon,
  Card,
  BlockStack,
  Link,
  InlineStack,
  Banner,
} from '@shopify/polaris';

import { AlertCircleIcon } from '@shopify/polaris-icons';
import { OfferShippingRateTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { OfferShippingRateTypeDto } from 'core/api/adminPromotions/adminPromotionsApi';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import classNames from 'classnames';
type LocaleNumberFormattingSettingsModel = {
  thousandSymbol: string;
  decimalSymbol: string;
};
type ShippingAppliesToProps = {
  thresholdTextFieldValue: NumberFormatValues;
  setThresholdTextFieldValue: (data: NumberFormatValues) => void;
  deliveryMethodsOption: OfferShippingRateTypeDto;
  setDeliveryMethodsOption: (
    deliveryMethodsOption: OfferShippingRateTypeDto
  ) => void;
  updateValidity(index: number, isValid: boolean): void;
};

export const ShippingAppliesTo: React.FC<ShippingAppliesToProps> = (props) => {
  const {
    deliveryMethodsOption,
    setDeliveryMethodsOption,
    thresholdTextFieldValue,
    setThresholdTextFieldValue,
    updateValidity,
  } = props;
  const [i18n] = useI18n();
  const inputValueNotValid: boolean = useMemo(
    () => thresholdTextFieldValue.value === '',
    [thresholdTextFieldValue.value]
  );

  useEffect(() => {
    if (deliveryMethodsOption === OfferShippingRateTypeDtoEnum.SELECTED) {
      updateValidity(0, !inputValueNotValid);
    } else {
      updateValidity(0, true);
    }
  }, [inputValueNotValid, deliveryMethodsOption]);

  const localeNumberFormattingSettings: LocaleNumberFormattingSettingsModel =
    i18n.numberSymbols();
  const maxAllowedValueRule = useCallback((data: NumberFormatValues) => {
    if (!data.floatValue) {
      return true;
    }
    return data.floatValue <= 2147483647;
  }, []);

  const onRadioButtonValueChange = useCallback(
    (data: OfferShippingRateTypeDtoEnum) =>
      (_newValue: boolean, _id: string) => {
        setDeliveryMethodsOption(data);
      },
    [setDeliveryMethodsOption]
  );

  return (
    <>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='h2' variant='headingSm'>
              {i18n.translate('ShippingRates')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('ShippingRatesSubtitle', {
                link: <Link url='/'>{i18n.translate('FollowingArticle')}</Link>,
              })}
            </Text>
          </BlockStack>
          <BlockStack gap='025'>
            {Object.entries(OfferShippingRateTypeDtoEnum).map(
              ([key, value]: [string, OfferShippingRateTypeDtoEnum]) => (
                <RadioButton
                  key={key}
                  label={i18n.translate(`${key}`) || value}
                  name={i18n.translate(`${key}`) || value}
                  checked={deliveryMethodsOption === value}
                  onChange={onRadioButtonValueChange(value)}
                />
              )
            )}
          </BlockStack>
          {deliveryMethodsOption === 'ALL_THRESHOLD' && (
            <>
              <BlockStack gap='100'>
                <Text as='p'>
                  {' '}
                  {i18n.translate('Threshold') || 'Threshold'}
                </Text>
                <div
                  className={classNames(
                    'Polaris-TextField Polaris-TextField--hasValue',
                    {
                      'Polaris-TextField--error': inputValueNotValid,
                    }
                  )}
                  style={{ maxWidth: '100px' }}
                >
                  <NumberFormat
                    className='Polaris-TextField__Input'
                    value={thresholdTextFieldValue.value}
                    decimalSeparator={
                      localeNumberFormattingSettings.decimalSymbol
                    }
                    thousandSeparator={
                      localeNumberFormattingSettings.thousandSymbol
                    }
                    allowNegative={false}
                    allowEmptyFormatting
                    isNumericString
                    decimalScale={2}
                    prefix='$ '
                    onValueChange={setThresholdTextFieldValue}
                    isAllowed={maxAllowedValueRule}
                  />
                  <div className='Polaris-TextField__Backdrop'></div>
                </div>
                {inputValueNotValid && (
                  <Text as='p' tone='critical'>
                    <InlineStack gap='100'>
                      <span>
                        {' '}
                        <Icon source={AlertCircleIcon} />
                      </span>

                      {i18n.translate('ValidationMsg') || 'Value is required'}
                    </InlineStack>
                  </Text>
                )}
              </BlockStack>
              <Banner>{i18n.translate('HelpText')}</Banner>
            </>
          )}
        </BlockStack>
      </Card>
    </>
  );
};
