export enum CurrencyDtoEnum {
  REGULAR = 'regular',
  SUPERSCRIPT = 'superscript',
  SUBSCRIPT = 'subscript',
}
export enum StrikethroughPriceDtoEnum {
  HORIZONTAL = 'horizontal',
  DIAGONAL = 'diagonal',
  CROSSED = 'crossed',
  NO_LINE = 'noLine',
}
export enum DecimalFormatDtoEnum {
  REGULAR = 'regular',
  SUPERSCRIPT = 'superscript',
  SUBSCRIPT = 'subscript',
  SUBSCRIPT_AND_SEPARATOR = 'subscriptAndSeparator',
  SUPERSCRIPT_AND_SEPARATOR = 'superscriptAndSeparator',
}

export enum CurrencyCodeSideDtoEnum {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum DecimalPrecisionDtoEnum {
  NONE = 'none',
  ONE_DIGIT = '0.0',
  TWO_DIGITS = '0.00',
}

export enum TimeFormatDtoEnum {
  FORMAT_1 = 'x seconds',
  FORMAT_2 = 'x minutes',
  FORMAT_3 = 'x hours',
  FORMAT_4 = 'x days',
  FORMAT_5 = 'x days y hours z minutes v seconds',
  FORMAT_6 = 'x days, y hours, z minutes and v seconds',
  FORMAT_7 = 'x minutes and y seconds',
  FORMAT_8 = 'x min and y sec',
}
export enum LabelsStyletDtoEnum {
  FORMAT_1 = 'd, h, m, s',
  FORMAT_2 = 'dys, hrs, min, sec',
  FORMAT_3 = 'days, hours, minutes, seconds',
}

export enum SeparatorStyleDtoEnum {
  FORMAT_1 = 'Colon',
  FORMAT_2 = 'Slash',
  FORMAT_3 = 'Dash',
}
