import {
  TagCategoryDto,
  useGetPromotionsV6LookupTagsQuery,
} from 'core/api/adminPromotions/adminPromotionsApi';
import resolveEnvVar from 'env-var-resolver';
import { useEffect, useState } from 'react';

const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';
export const useTags = (shouldSkip: boolean, category: TagCategoryDto) => {
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';
  const [tagList, setTagList] = useState<string[]>();

  const { data: getTagListData } = useGetPromotionsV6LookupTagsQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      category,
    },
    { skip: !!shouldSkip }
  );

  useEffect(() => {
    setTagList(getTagListData || []);
  }, [getTagListData]);

  return { tagList };
};
