import {
  Badge,
  BlockStack,
  Card,
  Checkbox,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import React from 'react';
type ShopifyDiscountCodesFiltersProps = {
  overrideCountries: boolean;
  setOverrideCountries: (value: boolean) => void;
  overrideEligibility: boolean;
  setOverrideEligibility: (value: boolean) => void;
};
export const ShopifyDiscountCodesFilters: React.FC<
  ShopifyDiscountCodesFiltersProps
> = ({
  overrideCountries,
  setOverrideCountries,
  overrideEligibility,
  setOverrideEligibility,
}) => {
  const [i18n] = useI18n();
  return (
    <Card>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='p' fontWeight='bold'>
            {i18n.translate('ShopifyDiscountCodesFilters')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('OverrideShopifyDiscount')}
          </Text>
        </BlockStack>
        <Checkbox
          label={
            <InlineStack gap='200'>
              <Text as='p'>{i18n.translate('OverrideCountries')}</Text>
              <Badge tone='warning'>{i18n.translate('NotRecommended')}</Badge>
            </InlineStack>
          }
          helpText={i18n.translate('OverrideCountriesHelp')}
          checked={overrideCountries}
          onChange={(value) => setOverrideCountries(value)}
        />
        <Checkbox
          label={
            <InlineStack gap='200'>
              <Text as='p'>{i18n.translate('OverrideEligibility')}</Text>
              <Badge tone='warning'>{i18n.translate('NotRecommended')}</Badge>
            </InlineStack>
          }
          helpText={i18n.translate('OverrideEligibilityHelp')}
          checked={overrideEligibility}
          onChange={(value) => setOverrideEligibility(value)}
        />
      </BlockStack>
    </Card>
  );
};
