import { NinjaCartPresetDtoRead } from 'core/api/adminWidgets/adminWidgetsApi';
import {
  LineWeightTypeDtoEnum,
  SpacingTypeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';

export const ninjaCartMergeFunc = (widget: NinjaCartPresetDtoRead) => {
  if (widget) {
    const {
      isShopLevelWidget,
      canBeDragged,
      canBeRemoved,
      options,
      ...newWidget
    } = widget;

    return {
      ...newWidget,
      availableEntries: undefined,
      options: {
        defaultState: {
          ...options?.defaultState,
          canBeDragged: undefined,
          canBeRemoved: undefined,
          canBeHidden: undefined,
          general: {
            ...options?.defaultState?.general,
            border: {
              ...options?.defaultState?.general?.border,
              value: {
                ...options?.defaultState?.general?.border?.value,
                lineWeight: {
                  ...options?.defaultState?.general?.border?.value?.lineWeight,
                  customValue:
                    options?.defaultState?.general?.border?.value?.lineWeight
                      ?.type === LineWeightTypeDtoEnum.CUSTOM
                      ? options?.defaultState?.general?.border?.value
                          ?.lineWeight?.customValue
                      : undefined,
                },
              },
            },
            buttonsStyle: {
              ...options?.defaultState?.general?.buttonsStyle,
              primaryStyle: {
                ...options?.defaultState?.general?.buttonsStyle?.primaryStyle,
                textStyle: {
                  ...options?.defaultState?.general?.buttonsStyle?.primaryStyle
                    ?.textStyle,
                  style: {
                    ...options?.defaultState?.general?.buttonsStyle
                      ?.primaryStyle?.textStyle?.style,
                    fontSize: {
                      ...options?.defaultState?.general?.buttonsStyle
                        ?.primaryStyle?.textStyle?.style?.fontSize,
                      mobile: options?.defaultState?.general?.buttonsStyle
                        ?.primaryStyle?.textStyle?.style?.differentOnMobile
                        ? options?.defaultState?.general?.buttonsStyle
                            ?.primaryStyle?.textStyle?.style?.fontSize?.mobile
                        : undefined,
                    },
                    lineHeight: {
                      ...options?.defaultState?.general?.buttonsStyle
                        ?.primaryStyle?.textStyle?.style?.lineHeight,
                      mobile: options?.defaultState?.general?.buttonsStyle
                        ?.primaryStyle?.textStyle?.style?.differentOnMobile
                        ? options?.defaultState?.general?.buttonsStyle
                            ?.primaryStyle?.textStyle?.style.lineHeight?.mobile
                        : undefined,
                    },
                    fontWeight: {
                      ...options?.defaultState?.general?.buttonsStyle
                        ?.primaryStyle?.textStyle?.style?.fontWeight,
                      mobile: options?.defaultState?.general?.buttonsStyle
                        ?.primaryStyle?.textStyle?.style?.differentOnMobile
                        ? options?.defaultState?.general?.buttonsStyle
                            ?.primaryStyle?.textStyle?.style?.fontWeight?.mobile
                        : undefined,
                    },
                  },
                },
              },
              secondaryStyle: {
                ...options?.defaultState?.general?.buttonsStyle?.secondaryStyle,
                textStyle: {
                  ...options?.defaultState?.general?.buttonsStyle
                    ?.secondaryStyle?.textStyle,
                  style: {
                    ...options?.defaultState?.general?.buttonsStyle
                      ?.secondaryStyle?.textStyle?.style,
                    fontSize: {
                      ...options?.defaultState?.general?.buttonsStyle
                        ?.secondaryStyle?.textStyle?.style?.fontSize,
                      mobile: options?.defaultState?.general?.buttonsStyle
                        ?.secondaryStyle?.textStyle?.style?.differentOnMobile
                        ? options?.defaultState?.general?.buttonsStyle
                            ?.secondaryStyle?.textStyle?.style?.fontSize?.mobile
                        : undefined,
                    },
                    lineHeight: {
                      ...options?.defaultState?.general?.buttonsStyle
                        ?.secondaryStyle?.textStyle?.style?.lineHeight,
                      mobile: options?.defaultState?.general?.buttonsStyle
                        ?.secondaryStyle?.textStyle?.style?.differentOnMobile
                        ? options?.defaultState?.general?.buttonsStyle
                            ?.secondaryStyle?.textStyle?.style.lineHeight
                            ?.mobile
                        : undefined,
                    },
                    fontWeight: {
                      ...options?.defaultState?.general?.buttonsStyle
                        ?.secondaryStyle?.textStyle?.style?.fontWeight,
                      mobile: options?.defaultState?.general?.buttonsStyle
                        ?.secondaryStyle?.textStyle?.style?.differentOnMobile
                        ? options?.defaultState?.general?.buttonsStyle
                            ?.secondaryStyle?.textStyle?.style?.fontWeight
                            ?.mobile
                        : undefined,
                    },
                  },
                },
              },
            },
            cornerRadius: {
              ...options?.defaultState?.general?.cornerRadius,
              customValue:
                options?.defaultState?.general?.cornerRadius?.type ===
                LineWeightTypeDtoEnum.CUSTOM
                  ? options?.defaultState?.general.cornerRadius.customValue
                  : undefined,
            },
          },
          options: {
            ...options?.defaultState?.options,
            content: {
              ...options?.defaultState?.options?.content,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              general: {
                ...options?.defaultState?.options?.content?.general,
                background: {
                  ...options?.defaultState?.options?.content?.general
                    ?.background,
                  border: {
                    ...options?.defaultState?.options?.content?.general
                      ?.background?.border,
                    value: {
                      ...options?.defaultState?.options?.content?.general
                        ?.background?.border?.value,
                      lineWeight: {
                        ...options?.defaultState?.options?.content?.general
                          ?.background?.border?.value?.lineWeight,
                        customValue:
                          options?.defaultState?.options?.content?.general
                            ?.background?.border?.value?.lineWeight?.type ===
                          LineWeightTypeDtoEnum.CUSTOM
                            ? options?.defaultState?.options?.content?.general
                                ?.background?.border?.value?.lineWeight
                                ?.customValue
                            : undefined,
                      },
                    },
                  },
                },
              },
              options: {
                ...options?.defaultState?.options?.content?.options,
                cartItem: {
                  ...options?.defaultState?.options?.content?.options?.cartItem,
                  canBeDragged: undefined,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                  options: {
                    ...options?.defaultState?.options?.content?.options
                      ?.cartItem?.options,
                    cartText: {
                      ...options?.defaultState?.options?.content?.options
                        ?.cartItem?.options?.cartText,
                      canBeDragged: undefined,
                      canBeRemoved: undefined,
                      canBeHidden: undefined,
                      background: {
                        ...options?.defaultState?.options?.content?.options
                          ?.cartItem?.options?.cartText?.background,
                        border: {
                          ...options?.defaultState?.options?.content?.options
                            ?.cartItem?.options?.cartText?.background?.border,
                          value: {
                            ...options?.defaultState?.options?.content?.options
                              ?.cartItem?.options?.cartText?.background?.border
                              ?.value,
                            lineWeight: {
                              ...options?.defaultState?.options?.content
                                ?.options?.cartItem?.options?.cartText
                                ?.background?.border?.value?.lineWeight,
                              customValue:
                                options?.defaultState?.options?.content?.options
                                  ?.cartItem?.options?.cartText?.background
                                  ?.border?.value?.lineWeight?.type ===
                                LineWeightTypeDtoEnum.CUSTOM
                                  ? options?.defaultState?.options?.content
                                      ?.options?.cartItem?.options?.cartText
                                      ?.background?.border?.value?.lineWeight
                                      ?.customValue
                                  : undefined,
                            },
                          },
                        },
                      },
                      cornerRadius: {
                        ...options?.defaultState?.options?.content?.options
                          ?.cartItem?.options?.cartText?.cornerRadius,
                        customValue:
                          options?.defaultState?.options?.content?.options
                            ?.cartItem?.options?.cartText?.cornerRadius
                            ?.type === LineWeightTypeDtoEnum.CUSTOM
                            ? options?.defaultState?.options?.content?.options
                                ?.cartItem?.options?.cartText?.cornerRadius
                                .customValue
                            : undefined,
                      },
                      textStyle: {
                        ...options?.defaultState?.options?.content?.options
                          ?.cartItem?.options?.cartText?.textStyle,
                        style: {
                          ...options?.defaultState?.options?.content?.options
                            ?.cartItem?.options?.cartText?.textStyle?.style,
                          fontSize: {
                            ...options?.defaultState?.options?.content?.options
                              ?.cartItem?.options?.cartText?.textStyle?.style
                              ?.fontSize,
                            mobile: options?.defaultState?.options?.content
                              ?.options?.cartItem?.options?.cartText?.textStyle
                              ?.style?.differentOnMobile
                              ? options?.defaultState?.options?.content?.options
                                  ?.cartItem?.options?.cartText?.textStyle
                                  ?.style?.fontSize?.mobile
                              : undefined,
                          },
                          lineHeight: {
                            ...options?.defaultState?.options?.content?.options
                              ?.cartItem?.options?.cartText?.textStyle?.style
                              ?.lineHeight,
                            mobile: options?.defaultState?.options?.content
                              ?.options?.cartItem?.options?.cartText?.textStyle
                              ?.style?.differentOnMobile
                              ? options?.defaultState?.options?.content?.options
                                  ?.cartItem?.options?.cartText?.textStyle
                                  ?.style.lineHeight?.mobile
                              : undefined,
                          },
                          fontWeight: {
                            ...options?.defaultState?.options?.content?.options
                              ?.cartItem?.options?.cartText?.textStyle?.style
                              ?.fontWeight,
                            mobile: options?.defaultState?.options?.content
                              ?.options?.cartItem?.options?.cartText?.textStyle
                              ?.style?.differentOnMobile
                              ? options?.defaultState?.options?.content?.options
                                  ?.cartItem?.options?.cartText?.textStyle
                                  ?.style?.fontWeight?.mobile
                              : undefined,
                          },
                        },
                      },
                    },
                    productBadge: {
                      ...options?.defaultState?.options?.content?.options
                        ?.cartItem?.options?.productBadge,
                      canBeDragged: undefined,
                      canBeRemoved: undefined,
                      canBeHidden: undefined,
                      background: {
                        ...options?.defaultState?.options?.content?.options
                          ?.cartItem?.options?.productBadge?.background,
                        border: {
                          ...options?.defaultState?.options?.content?.options
                            ?.cartItem?.options?.productBadge?.background
                            ?.border,
                          value: {
                            ...options?.defaultState?.options?.content?.options
                              ?.cartItem?.options?.productBadge?.background
                              ?.border?.value,
                            lineWeight: {
                              ...options?.defaultState?.options?.content
                                ?.options?.cartItem?.options?.productBadge
                                ?.background?.border?.value?.lineWeight,
                              customValue:
                                options?.defaultState?.options?.content?.options
                                  ?.cartItem?.options?.productBadge?.background
                                  ?.border?.value?.lineWeight?.type ===
                                LineWeightTypeDtoEnum.CUSTOM
                                  ? options?.defaultState?.options?.content
                                      ?.options?.cartItem?.options?.productBadge
                                      ?.background?.border?.value?.lineWeight
                                      ?.customValue
                                  : undefined,
                            },
                          },
                        },
                      },
                      cornerRadius: {
                        ...options?.defaultState?.options?.content?.options
                          ?.cartItem?.options?.productBadge?.cornerRadius,
                        customValue:
                          options?.defaultState?.options?.content?.options
                            ?.cartItem?.options?.productBadge?.cornerRadius
                            ?.type === LineWeightTypeDtoEnum.CUSTOM
                            ? options?.defaultState?.options?.content?.options
                                ?.cartItem?.options?.productBadge?.cornerRadius
                                .customValue
                            : undefined,
                      },
                      shopSettings: {
                        ...options?.defaultState?.options?.content?.options
                          ?.cartItem?.options?.productBadge?.shopSettings,
                        stacking: {
                          ...options?.defaultState?.options?.content?.options
                            ?.cartItem?.options?.productBadge?.shopSettings
                            ?.stacking,
                          spacing: {
                            ...options?.defaultState?.options?.content?.options
                              ?.cartItem?.options?.productBadge?.shopSettings
                              ?.stacking?.spacing,
                            customValue:
                              options?.defaultState?.options?.content?.options
                                ?.cartItem?.options?.productBadge?.shopSettings
                                ?.stacking?.spacing?.type ===
                              SpacingTypeDtoEnum.CUSTOM
                                ? options?.defaultState?.options?.content
                                    ?.options?.cartItem?.options?.productBadge
                                    ?.shopSettings?.stacking?.spacing
                                    .customValue
                                : undefined,
                          },
                        },
                      },
                      stitch: {
                        ...options?.defaultState?.options?.content?.options
                          ?.cartItem?.options?.productBadge?.stitch,
                        value: {
                          ...options?.defaultState?.options?.content?.options
                            ?.cartItem?.options?.productBadge?.stitch?.value,
                          lineWeight: {
                            ...options?.defaultState?.options?.content?.options
                              ?.cartItem?.options?.productBadge?.stitch?.value
                              ?.lineWeight,
                            customValue:
                              options?.defaultState?.options?.content?.options
                                ?.cartItem?.options?.productBadge?.stitch?.value
                                ?.lineWeight?.type ===
                              LineWeightTypeDtoEnum.CUSTOM
                                ? options?.defaultState?.options?.content
                                    ?.options?.cartItem?.options?.productBadge
                                    ?.stitch?.value?.lineWeight?.customValue
                                : undefined,
                          },
                        },
                      },
                      textStyle: {
                        ...options?.defaultState?.options?.content?.options
                          ?.cartItem?.options?.productBadge?.textStyle,
                        style: {
                          ...options?.defaultState?.options?.content?.options
                            ?.cartItem?.options?.productBadge?.textStyle?.style,
                          fontSize: {
                            ...options?.defaultState?.options?.content?.options
                              ?.cartItem?.options?.productBadge?.textStyle
                              ?.style?.fontSize,
                            mobile: options?.defaultState?.options?.content
                              ?.options?.cartItem?.options?.productBadge
                              ?.textStyle?.style?.differentOnMobile
                              ? options?.defaultState?.options?.content?.options
                                  ?.cartItem?.options?.productBadge?.textStyle
                                  ?.style?.fontSize?.mobile
                              : undefined,
                          },
                          lineHeight: {
                            ...options?.defaultState?.options?.content?.options
                              ?.cartItem?.options?.productBadge?.textStyle
                              ?.style?.lineHeight,
                            mobile: options?.defaultState?.options?.content
                              ?.options?.cartItem?.options?.productBadge
                              ?.textStyle?.style?.differentOnMobile
                              ? options?.defaultState?.options?.content?.options
                                  ?.cartItem?.options?.productBadge?.textStyle
                                  ?.style.lineHeight?.mobile
                              : undefined,
                          },
                          fontWeight: {
                            ...options?.defaultState?.options?.content?.options
                              ?.cartItem?.options?.productBadge?.textStyle
                              ?.style?.fontWeight,
                            mobile: options?.defaultState?.options?.content
                              ?.options?.cartItem?.options?.productBadge
                              ?.textStyle?.style?.differentOnMobile
                              ? options?.defaultState?.options?.content?.options
                                  ?.cartItem?.options?.productBadge?.textStyle
                                  ?.style?.fontWeight?.mobile
                              : undefined,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            footer: {
              ...options?.defaultState?.options?.footer,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              general: {
                ...options?.defaultState?.options?.footer?.general,
                background: {
                  ...options?.defaultState?.options?.footer?.general
                    ?.background,
                  border: {
                    ...options?.defaultState?.options?.footer?.general
                      ?.background?.border,
                    value: {
                      ...options?.defaultState?.options?.footer?.general
                        ?.background?.border?.value,
                      lineWeight: {
                        ...options?.defaultState?.options?.footer?.general
                          ?.background?.border?.value?.lineWeight,
                        customValue:
                          options?.defaultState?.options?.footer?.general
                            ?.background?.border?.value?.lineWeight?.type ===
                          LineWeightTypeDtoEnum.CUSTOM
                            ? options?.defaultState?.options?.footer?.general
                                ?.background?.border?.value?.lineWeight
                                ?.customValue
                            : undefined,
                      },
                    },
                  },
                },
              },
              options: {
                ...options?.defaultState?.options?.footer?.options,
                ctaButtons: {
                  ...options?.defaultState?.options?.footer?.options
                    ?.ctaButtons,
                  canBeDragged: undefined,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                },
                promotionSummary: {
                  ...options?.defaultState?.options?.footer?.options
                    ?.promotionSummary,
                  canBeDragged: undefined,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                  general: {
                    ...options?.defaultState?.options?.footer?.options
                      ?.promotionSummary?.general,
                    background: {
                      ...options?.defaultState?.options?.footer?.options
                        ?.promotionSummary?.general?.background,
                      border: {
                        ...options?.defaultState?.options?.footer?.options
                          ?.promotionSummary?.general?.background?.border,
                        value: {
                          ...options?.defaultState?.options?.footer?.options
                            ?.promotionSummary?.general?.background?.border
                            ?.value,
                          lineWeight: {
                            ...options?.defaultState?.options?.footer?.options
                              ?.promotionSummary?.general?.background?.border
                              ?.value?.lineWeight,
                            customValue:
                              options?.defaultState?.options?.footer?.options
                                ?.promotionSummary?.general?.background?.border
                                ?.value?.lineWeight?.type ===
                              LineWeightTypeDtoEnum.CUSTOM
                                ? options?.defaultState?.options?.footer
                                    ?.options?.promotionSummary?.general
                                    ?.background?.border?.value?.lineWeight
                                    ?.customValue
                                : undefined,
                          },
                        },
                      },
                    },
                    cornerRadius: {
                      ...options?.defaultState?.options?.footer?.options
                        ?.promotionSummary?.general?.cornerRadius,
                      customValue:
                        options?.defaultState?.options?.footer?.options
                          ?.promotionSummary?.general?.cornerRadius?.type ===
                        LineWeightTypeDtoEnum.CUSTOM
                          ? options?.defaultState?.options?.footer?.options
                              ?.promotionSummary?.general?.cornerRadius
                              .customValue
                          : undefined,
                    },
                    textStyle: {
                      ...options?.defaultState?.options?.footer?.options
                        ?.promotionSummary?.general?.textStyle,
                      style: {
                        ...options?.defaultState?.options?.footer?.options
                          ?.promotionSummary?.general?.textStyle?.style,
                        fontSize: {
                          ...options?.defaultState?.options?.footer?.options
                            ?.promotionSummary?.general?.textStyle?.style
                            ?.fontSize,
                          mobile: options?.defaultState?.options?.footer
                            ?.options?.promotionSummary?.general?.textStyle
                            ?.style?.differentOnMobile
                            ? options?.defaultState?.options?.footer?.options
                                ?.promotionSummary?.general?.textStyle?.style
                                ?.fontSize?.mobile
                            : undefined,
                        },
                        lineHeight: {
                          ...options?.defaultState?.options?.footer?.options
                            ?.promotionSummary?.general?.textStyle?.style
                            ?.lineHeight,
                          mobile: options?.defaultState?.options?.footer
                            ?.options?.promotionSummary?.general?.textStyle
                            ?.style?.differentOnMobile
                            ? options?.defaultState?.options?.footer?.options
                                ?.promotionSummary?.general?.textStyle?.style
                                .lineHeight?.mobile
                            : undefined,
                        },
                        fontWeight: {
                          ...options?.defaultState?.options?.footer?.options
                            ?.promotionSummary?.general?.textStyle?.style
                            ?.fontWeight,
                          mobile: options?.defaultState?.options?.footer
                            ?.options?.promotionSummary?.general?.textStyle
                            ?.style?.differentOnMobile
                            ? options?.defaultState?.options?.footer?.options
                                ?.promotionSummary?.general?.textStyle?.style
                                ?.fontWeight?.mobile
                            : undefined,
                        },
                      },
                    },
                  },
                  options: {
                    ...options?.defaultState?.options?.footer?.options
                      ?.promotionSummary?.options,
                    total: {
                      ...options?.defaultState?.options?.footer?.options
                        ?.promotionSummary?.options?.total,
                      canBeDragged: undefined,
                      canBeRemoved: undefined,
                      canBeHidden: undefined,
                      sectionDivider: {
                        ...options?.defaultState?.options?.footer?.options
                          ?.promotionSummary?.options?.total?.sectionDivider,
                        value: {
                          ...options?.defaultState?.options?.footer?.options
                            ?.promotionSummary?.options?.total?.sectionDivider
                            ?.value,
                          lineWeight: {
                            ...options?.defaultState?.options?.footer?.options
                              ?.promotionSummary?.options?.total?.sectionDivider
                              ?.value?.lineWeight,
                            customValue:
                              options?.defaultState?.options?.footer?.options
                                ?.promotionSummary?.options?.total
                                ?.sectionDivider?.value?.lineWeight?.type ===
                              LineWeightTypeDtoEnum.CUSTOM
                                ? options?.defaultState?.options?.footer
                                    ?.options?.promotionSummary?.options?.total
                                    ?.sectionDivider?.value?.lineWeight
                                    ?.customValue
                                : undefined,
                          },
                        },
                      },
                      label: {
                        ...options?.defaultState?.options?.footer?.options
                          ?.promotionSummary?.options?.total?.label,
                        message: {
                          ...options?.defaultState?.options?.footer?.options
                            ?.promotionSummary?.options?.total?.label?.message,
                          settings: undefined,
                        },
                        style: {
                          ...options?.defaultState?.options?.footer?.options
                            ?.promotionSummary?.options?.total?.label?.style,
                          style: {
                            ...options?.defaultState?.options?.footer?.options
                              ?.promotionSummary?.options?.total?.label?.style
                              ?.style,
                            fontSize: {
                              ...options?.defaultState?.options?.footer?.options
                                ?.promotionSummary?.options?.total?.label?.style
                                ?.style?.fontSize,
                              mobile: options?.defaultState?.options?.footer
                                ?.options?.promotionSummary?.options?.total
                                ?.label?.style?.style?.differentOnMobile
                                ? options?.defaultState?.options?.footer
                                    ?.options?.promotionSummary?.options?.total
                                    ?.label?.style?.style?.fontSize?.mobile
                                : undefined,
                            },
                            lineHeight: {
                              ...options?.defaultState?.options?.footer?.options
                                ?.promotionSummary?.options?.total?.label?.style
                                ?.style?.lineHeight,
                              mobile: options?.defaultState?.options?.footer
                                ?.options?.promotionSummary?.options?.total
                                ?.label?.style?.style?.differentOnMobile
                                ? options?.defaultState?.options?.footer
                                    ?.options?.promotionSummary?.options?.total
                                    ?.label?.style?.style?.lineHeight?.mobile
                                : undefined,
                            },
                            fontWeight: {
                              ...options?.defaultState?.options?.footer?.options
                                ?.promotionSummary?.options?.total?.label?.style
                                ?.style?.fontWeight,
                              mobile: options?.defaultState?.options?.footer
                                ?.options?.promotionSummary?.options?.total
                                ?.label?.style?.style?.differentOnMobile
                                ? options?.defaultState?.options?.footer
                                    ?.options?.promotionSummary?.options?.total
                                    ?.label?.style?.style?.fontWeight?.mobile
                                : undefined,
                            },
                          },
                        },
                      },
                    },
                    taxesAndDuties: {
                      ...options?.defaultState?.options?.footer?.options
                        ?.promotionSummary?.options?.taxesAndDuties,
                      canBeDragged: undefined,
                      canBeRemoved: undefined,
                      canBeHidden: undefined,
                      description: {
                        ...options?.defaultState?.options?.footer?.options
                          ?.promotionSummary?.options?.taxesAndDuties
                          ?.description,
                        message: {
                          ...options?.defaultState?.options?.footer?.options
                            ?.promotionSummary?.options?.taxesAndDuties
                            ?.description?.message,
                          settings: undefined,
                        },
                        style: {
                          ...options?.defaultState?.options?.footer?.options
                            ?.promotionSummary?.options?.taxesAndDuties
                            ?.description?.style,
                          style: {
                            ...options?.defaultState?.options?.footer?.options
                              ?.promotionSummary?.options?.taxesAndDuties
                              ?.description?.style?.style,
                            fontSize: {
                              ...options?.defaultState?.options?.footer?.options
                                ?.promotionSummary?.options?.taxesAndDuties
                                ?.description?.style?.style?.fontSize,
                              mobile: options?.defaultState?.options?.footer
                                ?.options?.promotionSummary?.options
                                ?.taxesAndDuties?.description?.style?.style
                                ?.differentOnMobile
                                ? options?.defaultState?.options?.footer
                                    ?.options?.promotionSummary?.options
                                    ?.taxesAndDuties?.description?.style?.style
                                    ?.fontSize?.mobile
                                : undefined,
                            },
                            lineHeight: {
                              ...options?.defaultState?.options?.footer?.options
                                ?.promotionSummary?.options?.taxesAndDuties
                                ?.description?.style?.style?.lineHeight,
                              mobile: options?.defaultState?.options?.footer
                                ?.options?.promotionSummary?.options
                                ?.taxesAndDuties?.description?.style?.style
                                ?.differentOnMobile
                                ? options?.defaultState?.options?.footer
                                    ?.options?.promotionSummary?.options
                                    ?.taxesAndDuties?.description?.style?.style
                                    ?.lineHeight?.mobile
                                : undefined,
                            },
                            fontWeight: {
                              ...options?.defaultState?.options?.footer?.options
                                ?.promotionSummary?.options?.taxesAndDuties
                                ?.description?.style?.style?.fontWeight,
                              mobile: options?.defaultState?.options?.footer
                                ?.options?.promotionSummary?.options
                                ?.taxesAndDuties?.description?.style?.style
                                ?.differentOnMobile
                                ? options?.defaultState?.options?.footer
                                    ?.options?.promotionSummary?.options
                                    ?.taxesAndDuties?.description?.style?.style
                                    ?.fontWeight?.mobile
                                : undefined,
                            },
                          },
                        },
                      },
                      label: {
                        ...options?.defaultState?.options?.footer?.options
                          ?.promotionSummary?.options?.taxesAndDuties?.label,
                        settings: undefined,
                      },
                    },
                    subtotal: {
                      ...options?.defaultState?.options?.footer?.options
                        ?.promotionSummary?.options?.subtotal,
                      canBeDragged: undefined,
                      canBeRemoved: undefined,
                      canBeHidden: undefined,
                      label: {
                        ...options?.defaultState?.options?.footer?.options
                          ?.promotionSummary?.options?.subtotal?.label,
                        settings: undefined,
                      },
                    },
                    shipping: {
                      ...options?.defaultState?.options?.footer?.options
                        ?.promotionSummary?.options?.shipping,
                      canBeDragged: undefined,
                      canBeRemoved: undefined,
                      canBeHidden: undefined,
                      label: {
                        ...options?.defaultState?.options?.footer?.options
                          ?.promotionSummary?.options?.shipping?.label,
                        settings: undefined,
                      },
                    },
                    disclaimer: {
                      ...options?.defaultState?.options?.footer?.options
                        ?.promotionSummary?.options?.disclaimer,
                      canBeDragged: undefined,
                      canBeRemoved: undefined,
                      canBeHidden: undefined,
                      text: {
                        ...options?.defaultState?.options?.footer?.options
                          ?.promotionSummary?.options?.disclaimer?.text,
                        value: {
                          ...options?.defaultState?.options?.footer?.options
                            ?.promotionSummary?.options?.disclaimer?.text
                            ?.value,
                          message: {
                            ...options?.defaultState?.options?.footer?.options
                              ?.promotionSummary?.options?.disclaimer?.text
                              ?.value?.message,
                            settings: undefined,
                          },
                          style: {
                            ...options?.defaultState?.options?.footer?.options
                              ?.promotionSummary?.options?.disclaimer?.text
                              ?.value?.style,
                            style: {
                              ...options?.defaultState?.options?.footer?.options
                                ?.promotionSummary?.options?.disclaimer?.text
                                ?.value?.style?.style,
                              fontSize: {
                                ...options?.defaultState?.options?.footer
                                  ?.options?.promotionSummary?.options
                                  ?.disclaimer?.text?.value?.style?.style
                                  ?.fontSize,
                                mobile: options?.defaultState?.options?.footer
                                  ?.options?.promotionSummary?.options
                                  ?.disclaimer?.text?.value?.style?.style
                                  ?.differentOnMobile
                                  ? options?.defaultState?.options?.footer
                                      ?.options?.promotionSummary?.options
                                      ?.disclaimer?.text?.value?.style?.style
                                      ?.fontSize?.mobile
                                  : undefined,
                              },
                              lineHeight: {
                                ...options?.defaultState?.options?.footer
                                  ?.options?.promotionSummary?.options
                                  ?.disclaimer?.text?.value?.style?.style
                                  ?.lineHeight,
                                mobile: options?.defaultState?.options?.footer
                                  ?.options?.promotionSummary?.options
                                  ?.disclaimer?.text?.value?.style?.style
                                  ?.differentOnMobile
                                  ? options?.defaultState?.options?.footer
                                      ?.options?.promotionSummary?.options
                                      ?.disclaimer?.text?.value?.style?.style
                                      ?.lineHeight?.mobile
                                  : undefined,
                              },
                              fontWeight: {
                                ...options?.defaultState?.options?.footer
                                  ?.options?.promotionSummary?.options
                                  ?.disclaimer?.text?.value?.style?.style
                                  ?.fontWeight,
                                mobile: options?.defaultState?.options?.footer
                                  ?.options?.promotionSummary?.options
                                  ?.disclaimer?.text?.value?.style?.style
                                  ?.differentOnMobile
                                  ? options?.defaultState?.options?.footer
                                      ?.options?.promotionSummary?.options
                                      ?.disclaimer?.text?.value?.style?.style
                                      ?.fontWeight?.mobile
                                  : undefined,
                              },
                            },
                          },
                        },
                      },
                    },
                    discount: {
                      ...options?.defaultState?.options?.footer?.options
                        ?.promotionSummary?.options?.discount,
                      canBeDragged: undefined,
                      canBeRemoved: undefined,
                      canBeHidden: undefined,
                      key: undefined,
                      label: {
                        ...options?.defaultState?.options?.footer?.options
                          ?.promotionSummary?.options?.discount?.label,
                        settings: undefined,
                      },
                    },
                  },
                },
              },
            },
            header: {
              ...options?.defaultState?.options?.header,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              general: {
                ...options?.defaultState?.options?.header?.general,
                background: {
                  ...options?.defaultState?.options?.header?.general
                    ?.background,
                  border: {
                    ...options?.defaultState?.options?.header?.general
                      ?.background?.border,
                    value: {
                      ...options?.defaultState?.options?.header?.general
                        ?.background?.border?.value,
                      lineWeight: {
                        ...options?.defaultState?.options?.header?.general
                          ?.background?.border?.value?.lineWeight,
                        customValue:
                          options?.defaultState?.options?.header?.general
                            ?.background?.border?.value?.lineWeight?.type ===
                          LineWeightTypeDtoEnum.CUSTOM
                            ? options?.defaultState?.options?.header?.general
                                ?.background?.border?.value?.lineWeight
                                ?.customValue
                            : undefined,
                      },
                    },
                  },
                },
              },
              options: {
                ...options?.defaultState?.options?.header?.options,
                heading: {
                  ...options?.defaultState?.options?.header?.options?.heading,
                  canBeDragged: undefined,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                  text: {
                    ...options?.defaultState?.options?.header?.options?.heading
                      ?.text,
                    style: {
                      ...options?.defaultState?.options?.header?.options
                        ?.heading?.text?.style,
                      style: {
                        ...options?.defaultState?.options?.header?.options
                          ?.heading?.text?.style?.style,
                        fontSize: {
                          ...options?.defaultState?.options?.header?.options
                            ?.heading?.text?.style?.style?.fontSize,
                          mobile: options?.defaultState?.options?.header
                            ?.options?.heading?.text?.style?.style
                            ?.differentOnMobile
                            ? options?.defaultState?.options?.header?.options
                                ?.heading?.text?.style?.style?.fontSize?.mobile
                            : undefined,
                        },
                        lineHeight: {
                          ...options?.defaultState?.options?.header?.options
                            ?.heading?.text?.style?.style?.lineHeight,
                          mobile: options?.defaultState?.options?.header
                            ?.options?.heading?.text?.style?.style
                            ?.differentOnMobile
                            ? options?.defaultState?.options?.header?.options
                                ?.heading?.text?.style?.style?.lineHeight
                                ?.mobile
                            : undefined,
                        },
                        fontWeight: {
                          ...options?.defaultState?.options?.header?.options
                            ?.heading?.text?.style?.style?.fontWeight,
                          mobile: options?.defaultState?.options?.header
                            ?.options?.heading?.text?.style?.style
                            ?.differentOnMobile
                            ? options?.defaultState?.options?.header?.options
                                ?.heading?.text?.style?.style?.fontWeight
                                ?.mobile
                            : undefined,
                        },
                      },
                    },
                  },
                },
                itemCount: {
                  ...options?.defaultState?.options?.header?.options?.itemCount,
                  canBeDragged: undefined,
                  canBeRemoved: undefined,
                  canBeHidden: undefined,
                  background: {
                    ...options?.defaultState?.options?.header?.options
                      ?.itemCount?.background,
                    border: {
                      ...options?.defaultState?.options?.header?.options
                        ?.itemCount?.background?.border,
                      value: {
                        ...options?.defaultState?.options?.header?.options
                          ?.itemCount?.background?.border?.value,
                        lineWeight: {
                          ...options?.defaultState?.options?.header?.options
                            ?.itemCount?.background?.border?.value?.lineWeight,
                          customValue:
                            options?.defaultState?.options?.header?.options
                              ?.itemCount?.background?.border?.value?.lineWeight
                              ?.type === LineWeightTypeDtoEnum.CUSTOM
                              ? options?.defaultState?.options?.header?.options
                                  ?.itemCount?.background?.border?.value
                                  ?.lineWeight?.customValue
                              : undefined,
                        },
                      },
                    },
                  },
                  cornerRadius: {
                    ...options?.defaultState?.options?.header?.options
                      ?.itemCount?.cornerRadius,
                    customValue:
                      options?.defaultState?.options?.header?.options?.itemCount
                        ?.cornerRadius?.type === LineWeightTypeDtoEnum.CUSTOM
                        ? options?.defaultState?.options?.header?.options
                            ?.itemCount?.cornerRadius?.customValue
                        : undefined,
                  },
                  textStyle: {
                    ...options?.defaultState?.options?.header?.options
                      ?.itemCount?.textStyle,
                    style: {
                      ...options?.defaultState?.options?.header?.options
                        ?.itemCount?.textStyle?.style,
                      fontSize: {
                        ...options?.defaultState?.options?.header?.options
                          ?.itemCount?.textStyle?.style?.fontSize,
                        mobile: options?.defaultState?.options?.header?.options
                          ?.itemCount?.textStyle?.style?.differentOnMobile
                          ? options?.defaultState?.options?.header?.options
                              ?.itemCount?.textStyle?.style?.fontSize?.mobile
                          : undefined,
                      },
                      lineHeight: {
                        ...options?.defaultState?.options?.header?.options
                          ?.itemCount?.textStyle?.style?.lineHeight,
                        mobile: options?.defaultState?.options?.header?.options
                          ?.itemCount?.textStyle?.style?.differentOnMobile
                          ? options?.defaultState?.options?.header?.options
                              ?.itemCount?.textStyle?.style?.lineHeight?.mobile
                          : undefined,
                      },
                      fontWeight: {
                        ...options?.defaultState?.options?.header?.options
                          ?.itemCount?.textStyle?.style?.fontWeight,
                        mobile: options?.defaultState?.options?.header?.options
                          ?.itemCount?.textStyle?.style?.differentOnMobile
                          ? options?.defaultState?.options?.header?.options
                              ?.itemCount?.textStyle?.style?.fontWeight?.mobile
                          : undefined,
                      },
                    },
                  },
                },
              },
            },
          },
        },
        emptyState: {
          ...options?.emptyState,
          canBeDragged: undefined,
          canBeRemoved: undefined,
          canBeHidden: undefined,
          general: {
            ...options?.emptyState?.general,
            background: {
              ...options?.emptyState?.general?.background,
              border: {
                ...options?.emptyState?.general?.background?.border,
                value: {
                  ...options?.emptyState?.general?.background?.border?.value,
                  lineWeight: {
                    ...options?.emptyState?.general?.background?.border?.value
                      ?.lineWeight,
                    customValue:
                      options?.emptyState?.general?.background?.border?.value
                        ?.lineWeight?.type === LineWeightTypeDtoEnum.CUSTOM
                        ? options?.emptyState?.general?.background?.border
                            ?.value?.lineWeight?.customValue
                        : undefined,
                  },
                },
              },
            },
          },
          options: {
            ...options?.emptyState?.options,
            callToActionButton: {
              ...options?.emptyState?.options?.callToActionButton,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
            },
            header: {
              ...options?.emptyState?.options?.header,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
            },
            image: {
              ...options?.emptyState?.options?.image,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              cornerRadius: {
                ...options?.emptyState?.options?.image?.cornerRadius,
                customValue:
                  options?.emptyState?.options?.image?.cornerRadius?.type ===
                  LineWeightTypeDtoEnum.CUSTOM
                    ? options?.emptyState?.options?.image?.cornerRadius
                        ?.customValue
                    : undefined,
              },
            },
            text: {
              ...options?.emptyState?.options?.text,
              canBeDragged: undefined,
              canBeRemoved: undefined,
              canBeHidden: undefined,
              headline: {
                ...options?.emptyState?.options?.text?.headline,
                message: {
                  ...options?.emptyState?.options?.text?.headline?.message,
                  settings: undefined,
                },
                style: {
                  ...options?.emptyState?.options?.text?.headline?.style,
                  style: {
                    ...options?.emptyState?.options?.text?.headline?.style
                      ?.style,
                    fontSize: {
                      ...options?.emptyState?.options?.text?.headline?.style
                        ?.style?.fontSize,
                      mobile: options?.emptyState?.options?.text?.headline
                        ?.style?.style?.differentOnMobile
                        ? options?.emptyState?.options?.text?.headline?.style
                            ?.style?.fontSize?.mobile
                        : undefined,
                    },
                    lineHeight: {
                      ...options?.emptyState?.options?.text?.headline?.style
                        ?.style?.lineHeight,
                      mobile: options?.emptyState?.options?.text?.headline
                        ?.style?.style?.differentOnMobile
                        ? options?.emptyState?.options?.text?.headline?.style
                            ?.style?.lineHeight?.mobile
                        : undefined,
                    },
                    fontWeight: {
                      ...options?.emptyState?.options?.text?.headline?.style
                        ?.style?.fontWeight,
                      mobile: options?.emptyState?.options?.text?.headline
                        ?.style?.style?.differentOnMobile
                        ? options?.emptyState?.options?.text?.headline?.style
                            ?.style?.fontWeight?.mobile
                        : undefined,
                    },
                  },
                },
              },
              paragraph: {
                ...options?.emptyState?.options?.text?.paragraph,
                message: {
                  ...options?.emptyState?.options?.text?.paragraph?.message,
                  settings: undefined,
                },
                style: {
                  ...options?.emptyState?.options?.text?.paragraph?.style,
                  style: {
                    ...options?.emptyState?.options?.text?.paragraph?.style
                      ?.style,
                    fontSize: {
                      ...options?.emptyState?.options?.text?.paragraph?.style
                        ?.style?.fontSize,
                      mobile: options?.emptyState?.options?.text?.paragraph
                        ?.style?.style?.differentOnMobile
                        ? options?.emptyState?.options?.text?.paragraph?.style
                            ?.style?.fontSize?.mobile
                        : undefined,
                    },
                    lineHeight: {
                      ...options?.emptyState?.options?.text?.paragraph?.style
                        ?.style?.lineHeight,
                      mobile: options?.emptyState?.options?.text?.paragraph
                        ?.style?.style?.differentOnMobile
                        ? options?.emptyState?.options?.text?.paragraph?.style
                            ?.style?.lineHeight?.mobile
                        : undefined,
                    },
                    fontWeight: {
                      ...options?.emptyState?.options?.text?.paragraph?.style
                        ?.style?.fontWeight,
                      mobile: options?.emptyState?.options?.text?.paragraph
                        ?.style?.style?.differentOnMobile
                        ? options?.emptyState?.options?.text?.paragraph?.style
                            ?.style?.fontWeight?.mobile
                        : undefined,
                    },
                  },
                },
              },
            },
          },
        },
      },
    };
  }
};
