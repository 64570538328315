import React, { useMemo } from 'react';
import {
  Badge,
  Banner,
  BlockStack,
  Card,
  ChoiceList,
  Icon,
  InlineStack,
  Link,
  Text,
  Tooltip,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { InfoIcon } from '@shopify/polaris-icons';
import {
  CheckoutModeCheckoutSettingsDto,
  CheckoutModeDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { CheckoutModeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
type PreferredCheckoutModeProps = {
  checkoutMode: CheckoutModeCheckoutSettingsDto;
  isLegacyFlow: boolean;
  setCheckoutMode: (value: CheckoutModeDto) => void;
};
export const PreferredCheckoutMode: React.FC<PreferredCheckoutModeProps> = ({
  checkoutMode,
  isLegacyFlow,
  setCheckoutMode,
}) => {
  const [i18n] = useI18n();

  const modeOptions = useMemo(
    () =>
      Object.values(CheckoutModeDtoEnum).map((value) => ({
        label: (
          <InlineStack gap='200'>
            <Text as='p'>{i18n.translate(`${value}_TITLE`)}</Text>
            {value === CheckoutModeDtoEnum.SHOPIFY_FUNCTIONS && (
              <>
                <Badge>{i18n.translate('Recommended')}</Badge>
                {!checkoutMode.isDiscountCombinationSupported && (
                  <Badge tone='warning'>{i18n.translate('NotSupported')}</Badge>
                )}
              </>
            )}
          </InlineStack>
        ),
        disabled:
          value === CheckoutModeDtoEnum.SHOPIFY_FUNCTIONS &&
          !checkoutMode.isDiscountCombinationSupported,
        helpText: i18n.translate(`${value}_SUBTITLE`),
        value: value,
      })),
    [checkoutMode.isDiscountCombinationSupported, i18n]
  );

  return (
    <Card>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <InlineStack gap='200'>
            <Text fontWeight='semibold' as='h2'>
              {i18n.translate('Title')}
            </Text>
            {isLegacyFlow && (
              <Tooltip content={i18n.translate('LegacyTooltip')}>
                <Icon source={InfoIcon} />
              </Tooltip>
            )}
          </InlineStack>
          <Text as='p' tone='subdued'>
            {i18n.translate('Subtitle', {
              readArticle: <Link>{i18n.translate('ReadThisArticle')}</Link>,
            })}
          </Text>
        </BlockStack>
        <ChoiceList
          title=''
          choices={modeOptions}
          selected={[checkoutMode.mode as string]}
          onChange={(value) => setCheckoutMode(value[0] as CheckoutModeDto)}
        />
        {!checkoutMode.isDiscountCombinationSupported && (
          <Banner
            tone='warning'
            action={{ content: i18n.translate('EnableDiscountCombination') }}
            title={i18n.translate('EnableDiscountCombination')}
          >
            <Text as='p'>
              {i18n.translate('CombiningProductOrder', {
                learnMore: <Link>{i18n.translate('LearnMore')}</Link>,
              })}
            </Text>
          </Banner>
        )}
        {checkoutMode.shopifyFunctionsUpgradeAvailable && (
          <Banner
            tone='warning'
            action={{ content: i18n.translate('UpgradeShopifyFunctions') }}
            title={i18n.translate('UpgradeToLatest')}
          >
            <Text as='p'>
              {i18n.translate('UpgradeToLatestAbout', {
                learnMore: <Link>{i18n.translate('LearnMore')}</Link>,
              })}
            </Text>
          </Banner>
        )}
      </BlockStack>
    </Card>
  );
};
