import React, { useCallback, useMemo, useState } from 'react';
import { useI18n } from '@shopify/react-i18n';
import {
  OptionDtoTriggerCountdownClockDto,
  TimeSpanDto,
  TriggerCountdownClockDto,
  TriggerCountdownClockEndModeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  Badge,
  Banner,
  BlockStack,
  Box,
  Button,
  Card,
  ChoiceList,
  Collapsible,
  InlineStack,
  MediaCard,
  Text,
  TextField,
  VideoThumbnail,
} from '@shopify/polaris';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import { TriggerCountdownClockEndModeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { useSearchParams } from 'react-router-dom';

type SettingCountdownClockProps = {
  updateSettingsState: (data: OptionDtoTriggerCountdownClockDto) => void;
  onFormValidityChange(formIsValid: boolean): void;
  countdownClock?: OptionDtoTriggerCountdownClockDto;
};

export const SettingCountdownClock: React.FC<SettingCountdownClockProps> = ({
  updateSettingsState,
  onFormValidityChange,
  countdownClock,
}) => {
  const [i18n] = useI18n();
  const [, setSearchParams] = useSearchParams();
  const [time, setTime] = useState<keyof TimeSpanDto>('days');

  const modeOptions = useMemo(
    () =>
      Object.values(TriggerCountdownClockEndModeDtoEnum).map((value) => ({
        label: i18n.translate(`${value}_Title`),
        helpText: i18n.translate(`${value}_Subtitle`),
        value: value,
      })),
    [i18n]
  );

  const timeOptions = useMemo(
    () =>
      ['days', 'hours', 'minutes'].map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    [i18n]
  );

  const handleUpdateCountdown = useCallback(
    (
      field: keyof TriggerCountdownClockDto,
      data: TriggerCountdownClockDto[keyof TriggerCountdownClockDto]
    ) => {
      updateSettingsState({
        ...countdownClock,
        value: {
          ...countdownClock?.value,
          [field]: data,
        },
      });
    },
    [countdownClock]
  );

  const validateFixedMode = useCallback(() => {
    const value = countdownClock?.value?.fixedClockTime?.[time] || 1;
    const validateMins = value > 43200 ? 43200 : value < 1 ? 1 : value;
    const validateHours = value > 720 ? 720 : value < 1 ? 1 : value;
    const validateDays = value > 30 ? 30 : value < 1 ? 1 : value;
    handleUpdateCountdown('fixedClockTime', {
      ...countdownClock?.value?.fixedClockTime,
      [time]:
        time === 'days'
          ? validateDays
          : time === 'hours'
            ? validateHours
            : validateMins,
    });
  }, [time, countdownClock?.value?.fixedClockTime, handleUpdateCountdown]);

  const navigateToUrgencySettings = useCallback(() => {
    setSearchParams({
      modal: 'settings',
      path: 'manage-widgets',
      subPath: 'countdown-clock',
    });
  }, [setSearchParams]);

  return (
    <div className='SettingCountdownClock'>
      <Card>
        <BlockStack gap='400'>
          <InlineStack blockAlign='start' wrap={false} align='space-between'>
            <Box width='70%'>
              <BlockStack gap='100'>
                <InlineStack align='start' gap='200'>
                  <Text fontWeight='semibold' as='h2'>
                    {i18n.translate('Countdown')}
                  </Text>
                  <Badge tone={countdownClock?.enabled ? 'success' : 'enabled'}>
                    {i18n.translate(countdownClock?.enabled ? 'On' : 'Off')}
                  </Badge>
                </InlineStack>
                <Text as='p' tone='subdued'>
                  {i18n.translate('CountdownSubtitle')}
                </Text>
              </BlockStack>
            </Box>
            <Button
              onClick={() =>
                updateSettingsState({
                  ...countdownClock,
                  enabled: !countdownClock?.enabled,
                })
              }
            >
              {i18n.translate(countdownClock?.enabled ? 'TurnOff' : 'TurnOn')}
            </Button>
          </InlineStack>
          <Collapsible id='collapsible' open={!!countdownClock?.enabled}>
            <BlockStack gap='400'>
              <MediaCard
                title={i18n.translate('MediaCardTitle')}
                primaryAction={{
                  content: i18n.translate('LearnMore'),
                  onAction: () => null,
                }}
                description={i18n.translate(`MediaCardDescription`)}
                size='small'
              >
                <VideoThumbnail
                  videoLength={80}
                  thumbnailUrl='https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850'
                  onClick={() =>
                    window.open(
                      'https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley',
                      '_blank'
                    )
                  }
                />
              </MediaCard>
              <ChoiceList
                choices={modeOptions}
                title={''}
                selected={[countdownClock?.value?.mode as string]}
                onChange={(value) =>
                  handleUpdateCountdown(
                    'mode',
                    value[0] as TriggerCountdownClockEndModeDto
                  )
                }
              />
              {countdownClock?.value?.mode === 'FIXED' && (
                <InlineStack blockAlign='end' gap='300'>
                  <Box width='176px'>
                    <TextField
                      autoComplete=''
                      label={i18n.translate('SetClockTime')}
                      value={
                        countdownClock.value?.fixedClockTime?.[
                          time
                        ]?.toString() || '0'
                      }
                      type='number'
                      onChange={(value) =>
                        handleUpdateCountdown('fixedClockTime', {
                          ...countdownClock.value?.fixedClockTime,
                          [time]: +value,
                        })
                      }
                      onBlur={validateFixedMode}
                    />
                  </Box>

                  <SelectOptions
                    options={timeOptions}
                    selectedOption={time}
                    onOptionSelect={(value) => {
                      setTime(value as keyof TimeSpanDto);
                      updateSettingsState({
                        ...countdownClock,
                        value: {
                          ...countdownClock.value,
                          fixedClockTime: {
                            days: 0,
                            hours: 0,
                            minutes: 0,
                            [time]:
                              countdownClock.value?.fixedClockTime?.[time] || 1,
                          },
                        },
                      });
                    }}
                    label={''}
                  />
                </InlineStack>
              )}

              <Banner
                tone='info'
                title={i18n.translate('BannerTitle')}
                action={{
                  content: i18n.translate('ChangeUrgency'),
                  onAction: navigateToUrgencySettings,
                }}
              >
                {i18n.translate('BannerDescription')}
              </Banner>
            </BlockStack>
          </Collapsible>
        </BlockStack>
      </Card>
    </div>
  );
};
