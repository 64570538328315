import React from 'react';
import { BlockStack, Text, Modal } from '@shopify/polaris';
import './DeletePromotionModal.scss';
import { useI18n } from '@shopify/react-i18n';
type DeletePromotionModalProps = {
  onClose: () => void;
  onDeletePromotion: () => void;
  isOpen: boolean;
};
export const DeletePromotionModal: React.FC<DeletePromotionModalProps> = ({
  onClose,
  onDeletePromotion,
  isOpen,
}) => {
  const [i18n] = useI18n();
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={i18n.translate(`DeletePromotion`)}
      primaryAction={{
        content: i18n.translate(`Delete`),
        destructive: true,
        onAction: () => {
          onDeletePromotion();
          onClose();
        },
      }}
      secondaryActions={[
        {
          content: i18n.translate('Cancel'),
          onAction: onClose,
        },
      ]}
    >
      <div className='DeletePromotionModal'>
        <Modal.Section>
          <BlockStack gap='300'>
            <Text as='p'>{i18n.translate(`CantBeUndone`)}</Text>
          </BlockStack>
        </Modal.Section>
      </div>
    </Modal>
  );
};
