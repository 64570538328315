import { TrendIndicator as Indicator } from '@shopify/polaris-viz';
import React from 'react';
type TrendIndicatorProps = {
  value: number;
};
export const TrendIndicator: React.FC<TrendIndicatorProps> = ({ value }) => {
  return (
    <Indicator
      direction={value > 0 ? 'upward' : 'downward'}
      trend={value > 0 ? 'positive' : 'negative'}
      value={`${value}%`}
    />
  );
};
