import React, { useCallback } from 'react';
import {
  DeviceDisplayDto,
  ProductBannerGeneralOfferDto,
  ProductBannerPresetGeneralDto,
  ProductBannerSystemDefaultsDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import {
  Background,
  CornerRadius,
  DeviceDisplay,
  Link,
  ShopSettingsWrapper,
  Size,
} from '../../../../../components';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';

type ProductBannerEVSPProps = {
  general: ProductBannerGeneralOfferDto;
  defaultGeneral: ProductBannerGeneralOfferDto;
  shopDefaultGeneral: ProductBannerSystemDefaultsDto;
  title: string;
  presetSetup?: ProductBannerPresetGeneralDto;
  setGeneral: (data: ProductBannerGeneralOfferDto) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const ProductBannerEVSP: React.FC<ProductBannerEVSPProps> = ({
  general,
  defaultGeneral,
  shopDefaultGeneral,
  title,
  presetSetup,
  setGeneral,
  handleLeftMenuSelection,
}) => {
  const resetAllToSystemDefault = useCallback(() => {
    const { background, cornerRadius, link, order, size } = shopDefaultGeneral;
    setGeneral({
      ...general,
      background,
      cornerRadius,
      link,
      order,
      size,
    });
  }, [general, shopDefaultGeneral]);

  const handleOfferComponent = useCallback(
    (
      field: keyof ProductBannerGeneralOfferDto,
      data: ProductBannerGeneralOfferDto[keyof ProductBannerGeneralOfferDto]
    ) => {
      setGeneral({
        ...general,
        background: presetSetup?.background,
        cornerRadius: presetSetup?.cornerRadius,
        [field]: data,
      });
    },
    [general, setGeneral, presetSetup]
  );

  return (
    <div className='ProductBannerOffer'>
      <WidgetOptionHeader
        onDiscardChanges={() => setGeneral(defaultGeneral)}
        onResetToSystem={resetAllToSystemDefault}
        name={title}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <Size
          onSystemReset={() =>
            handleOfferComponent('size', shopDefaultGeneral.size)
          }
          size={general.size || {}}
          setSize={(data) => handleOfferComponent('size', data)}
          disableWidth
        />
      </div>
      <div className='RightSideSection'>
        <Link
          link={general?.link || {}}
          setLink={(data) => handleOfferComponent('link', data)}
          onSystemReset={() =>
            handleOfferComponent('link', shopDefaultGeneral.link)
          }
        />
      </div>
      <div className='RightSideSection'>
        <Background
          background={presetSetup?.background || {}}
          setBackground={(data) => handleOfferComponent('background', data)}
          onSystemReset={() =>
            handleOfferComponent('background', shopDefaultGeneral.background)
          }
        />
      </div>
      <div className='RightSideSection'>
        <CornerRadius
          cornerRadius={presetSetup?.cornerRadius || {}}
          setCornerRadius={(data) => handleOfferComponent('cornerRadius', data)}
          onSystemReset={() =>
            handleOfferComponent(
              'cornerRadius',
              shopDefaultGeneral.cornerRadius
            )
          }
        />
      </div>
      <ShopSettingsWrapper>
        <div className='RightSideSection'>
          <DeviceDisplay
            disabled
            deviceDisplay={
              general.shopSettings?.deviceDisplay as DeviceDisplayDto
            }
          />
        </div>
      </ShopSettingsWrapper>
    </div>
  );
};

export default ProductBannerEVSP;
