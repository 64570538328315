import React, { useCallback } from 'react';
import {
  Badge,
  BlockStack,
  Card,
  Icon,
  IconSource,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { ChevronRightIcon, LanguageIcon } from '@shopify/polaris-icons';
import './TranslationsComponent.scss';
import { MultiLanguageFeature } from '../MultiLanguageFeature/MultiLanguageFeature';
import { useSearchParams } from 'react-router-dom';
import { TranslationsMainSettingsDto } from 'core/api/adminSettings/adminSettingsApi';

type translationMainData = {
  translationMainData: TranslationsMainSettingsDto;
};

export const TranslationsComponent: React.FC<translationMainData> = ({
  translationMainData,
}) => {
  const [i18n] = useI18n();

  const [, setSearchParams] = useSearchParams();

  const onLevelClick = useCallback(
    (subPath: string, language: string) => {
      setSearchParams((params) => {
        params.set('subPath', subPath);
        params.set('language', language);
        return params;
      });
    },
    [setSearchParams]
  );
  return (
    <div className='TranslationsComponent'>
      <Card roundedAbove='sm' padding='400'>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='h2' variant='headingSm'>
              {i18n.translate('Translations')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('TranslationsDescription')}
            </Text>
          </BlockStack>
          <BlockStack gap='200'>
            {translationMainData?.locales?.map((locale) => (
              <div
                className='TranslationsLocale'
                key={locale.locale}
                onClick={() =>
                  onLevelClick('translations-settings', `${locale.locale}`)
                }
              >
                <InlineStack align='space-between'>
                  <InlineStack>
                    <Icon source={LanguageIcon as IconSource} tone='primary' />
                    <span className='Gap'>
                      <Text as='p' variant={'headingSm'}>
                        {locale.name}
                        {locale.isDefault && (
                          <span className={'Gap'}>
                            <Badge>{i18n.translate('Default')}</Badge>
                          </span>
                        )}
                      </Text>
                    </span>
                  </InlineStack>
                  <div className={'Link'}>
                    <Icon
                      source={ChevronRightIcon as IconSource}
                      tone='primary'
                    />
                  </div>
                </InlineStack>
              </div>
            ))}
          </BlockStack>
          {!translationMainData.planSupportsMultilanguage && (
            <MultiLanguageFeature />
          )}
        </BlockStack>
      </Card>
    </div>
  );
};
