import React, { useCallback, useMemo } from 'react';
import {
  NotificationExpandedStateLevelPresetDtoRead,
  NotificationExpandedStatePresetDtoRead,
  NotificationPresetDto,
  NotificationPresetDtoRead,
  NotificationSystemDefaultsDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { getLastSelectedOption } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import {
  WidgetListOptionsDtoEnum,
  WidgetListTypeDtoEnum,
} from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { NotificationStatesPromotionEntryTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { SelectedOptionPath } from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightSideBarWrapper';
import NotificationGeneral from '../NotificationGeneral/NotificationGeneral';
import NotificationHeader from '../NotificationHeader/NotificationHeader';
import NotificationFooter from '../NotificationFooter/NotificationFooter';
import NotificationImage from '../NotificationImage/NotificationImage';
import NotificationPresetOffer from '../NotificationPresetOffer/NotificationPresetOffer';

type PresetNotificationExpandedProps = {
  selectedOptionPath: SelectedOptionPath;
  notification: NotificationPresetDtoRead;
  defaultPresetData: NotificationPresetDtoRead;
  shopDefaultData: NotificationSystemDefaultsDto;
  defaultLanguage: string;
  updateNotification: (data: NotificationPresetDto) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const PresetNotificationExpanded: React.FC<PresetNotificationExpandedProps> = ({
  selectedOptionPath,
  notification,
  defaultPresetData,
  shopDefaultData,
  defaultLanguage,
  updateNotification,
  handleLeftMenuSelection,
}) => {
  const updateNotificationGeneral = useCallback(
    (
      field: keyof NotificationExpandedStatePresetDtoRead,
      data: NotificationExpandedStatePresetDtoRead[keyof NotificationExpandedStatePresetDtoRead]
    ) =>
      updateNotification({
        ...notification,
        options: {
          ...notification.options,
          expandedState: {
            ...notification.options?.expandedState,
            [field]: data,
          },
        },
      }),
    [notification]
  );

  const updateNotificationOption = useCallback(
    (
      field: keyof NotificationExpandedStateLevelPresetDtoRead,
      data: NotificationExpandedStateLevelPresetDtoRead[keyof NotificationExpandedStateLevelPresetDtoRead]
    ) => {
      updateNotification({
        ...notification,
        options: {
          ...notification.options,
          expandedState: {
            ...notification.options?.expandedState,
            options: {
              ...notification.options?.expandedState?.options,
              [field]: data,
            },
          },
        },
      });
    },
    [updateNotification, notification]
  );

  const handleOptionVisability = useCallback(
    (option: keyof NotificationExpandedStateLevelPresetDtoRead) =>
      updateNotificationOption(option, {
        ...notification.options?.expandedState?.options?.[option],
        isHidden:
          !notification.options?.expandedState?.options?.[option]?.isHidden,
      }),
    []
  );

  const notificationOptions = useMemo(() => {
    const selectedOption = getLastSelectedOption(selectedOptionPath);
    switch (true) {
      case selectedOption?.name === WidgetListTypeDtoEnum.NOTIFICATION:
        return (
          <NotificationGeneral
            shopDefaultGeneral={shopDefaultData?.expandedState?.general || {}}
            general={notification.options?.expandedState?.general || {}}
            defaultGeneral={
              defaultPresetData.options?.expandedState?.general || {}
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
            isPresetEditor
            setGeneral={(data) => updateNotificationGeneral('general', data)}
            notificationState={
              NotificationStatesPromotionEntryTypeDtoEnum.EXPANDED_STATE
            }
            onShowHide={
              notification.options?.expandedState?.canBeHidden
                ? () =>
                    updateNotificationGeneral(
                      'isHidden',
                      !notification.options?.expandedState?.isHidden
                    )
                : undefined
            }
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.HEADER:
        return (
          <NotificationHeader
            header={notification.options?.expandedState?.options?.header || {}}
            defaultHeader={
              defaultPresetData.options?.expandedState?.options?.header || {}
            }
            shopDefaultHeader={
              shopDefaultData.expandedState?.options?.header || {}
            }
            isPresetEditor
            notificationState={
              NotificationStatesPromotionEntryTypeDtoEnum.EXPANDED_STATE
            }
            setHeader={(data) => updateNotificationOption('header', data)}
            onShowHide={
              notification.options?.expandedState?.options?.header?.canBeHidden
                ? () => handleOptionVisability('header')
                : undefined
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
            defaultLanguage={defaultLanguage}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.FOOTER:
        return (
          <NotificationFooter
            footer={notification.options?.expandedState?.options?.footer || {}}
            defaultFooter={
              defaultPresetData.options?.expandedState?.options?.footer || {}
            }
            shopDefaultFooter={
              shopDefaultData.expandedState?.options?.footer || {}
            }
            defaultLanguage={defaultLanguage}
            isPresetEditor
            setFooter={(data) => updateNotificationOption('footer', data)}
            onShowHide={
              notification.options?.expandedState?.options?.footer?.canBeHidden
                ? () => handleOptionVisability('footer')
                : undefined
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.IMAGE:
        return (
          <NotificationImage
            image={notification.options?.expandedState?.options?.image || {}}
            defaultImage={
              defaultPresetData.options?.expandedState?.options?.image || {}
            }
            shopDefaultImage={
              shopDefaultData.expandedState?.options?.image || {}
            }
            isPresetEditor
            setImage={(data) => updateNotificationOption('image', data)}
            onShowHide={
              notification.options?.expandedState?.options?.image?.canBeHidden
                ? () => handleOptionVisability('image')
                : undefined
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
      case selectedOption?.name === WidgetListOptionsDtoEnum.OFFER:
        return (
          <NotificationPresetOffer
            offer={notification.options?.expandedState?.options?.offer || {}}
            defaultOffer={
              defaultPresetData.options?.expandedState?.options?.offer || {}
            }
            shopDefaultOffer={
              shopDefaultData.expandedState?.options?.offer || {}
            }
            setOffer={(data) => updateNotificationOption('offer', data)}
            onShowHide={
              notification.options?.expandedState?.options?.offer?.canBeHidden
                ? () => handleOptionVisability('offer')
                : undefined
            }
            handleLeftMenuSelection={handleLeftMenuSelection}
          />
        );
    }
  }, [
    selectedOptionPath,
    shopDefaultData,
    notification,
    defaultPresetData,
    updateNotification,
    handleLeftMenuSelection,
    handleOptionVisability,
  ]);
  return <div>{notificationOptions}</div>;
};

export default PresetNotificationExpanded;
